import { useSelector } from "react-redux";
import { selectDlgApp } from "@selectors";

import { Stack } from "@mui/material";
import { whiteColor } from "@styles";
import { PAGES_DELEGATIONS } from "@constants/delegationsConstants";
import ListPage from "@components/Delegations/ListPage";
import DroitsPage from "@components/Delegations/DroitsPage";

const Delegations = () => {
    const stateDelegations = useSelector(selectDlgApp);

    return (
        <Stack spacing={0.5} bgcolor={whiteColor[9]} borderRadius={2} p={0.5} height="100%" boxSizing="border-box">
            {{
                [PAGES_DELEGATIONS.LIST]: <ListPage />,
                [PAGES_DELEGATIONS.DROITS]: <DroitsPage />
            }[stateDelegations.page]}       
        </Stack>
    );
}

export default Delegations;