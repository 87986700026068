import { Box, Stack, TextField, Typography } from "@mui/material";
import { useController, useFormContext, useFormState } from "react-hook-form";
import PropTypes from "prop-types";
import { greyColor, redColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

const TextAreaHookFormInput = (props) => {
    const { name, label, autoFocus, maxLength, readOnly, multiline } = props;
    const { control, setValue } = useFormContext();
    const { field } = useController({ name, control });
    const { errors } = useFormState({ control });
    const { t } = useTranslation("global");

    const onChange = useCallback((event) => {
        if(event.target.value) {
            let lines = event.target.value.split(/(\r\n|\n|\r)/gm);
            for (var i = 0; i < lines.length; i++) {
                if (lines[i].length >= 80) {
                    let j = 0
                    let space = 80
                    while(j++ <= 80) {
                        if(lines[i].chartAt(j) === " ") {
                            space = j
                        }
                    }
                    lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || "");
                    lines[i] = lines[i].substring(0, space);
                }
            }
            setValue(lines.slice(0, 10).join('\n'));
        }
    }, [setValue])

    return (  
        <Stack sx={styles.stackInputStyle}>
            <Box sx={styles.boxInputStyle}>
                <TextField 
                    fullWidth 
                    label={label} 
                    size="small"
                    autoFocus={autoFocus} 
                    multiline={multiline}
                    inputProps={{ maxLength: 80, readOnly: readOnly }}
                    onChange={onChange}
                    {...field}
                />
            </Box>
            {errors[name] && 
                <Box sx={styles.boxInputErrorStyle}>
                    <Typography sx={styles.typoInputErrorStyle}>{t(errors[name].message)}</Typography>
                </Box>
            }
        </Stack>
    );
}

TextAreaHookFormInput.defaultProps = {
    label: "", 
    autoFocus: false, 
    maxLength: 50, 
    readOnly: false,
    multiline: false
}

TextAreaHookFormInput.propTypes = {
    name: PropTypes.string.isRequired, 
    label: PropTypes.string, 
    autoFocus: PropTypes.bool, 
    maxLength: PropTypes.number, 
    readOnly: PropTypes.bool,
    multiline: PropTypes.bool
}

const styles = {
    stackInputStyle: {
        borderRadius: 2,
        backgroundColor: redColor[1]
    },
    boxInputStyle: {
        borderRadius: 2,
        backgroundColor: whiteColor[9]
    },
    boxInputErrorStyle: {
        textAlign: "left",
        borderBottomLeftRadius: 8, 
        borderBottomRightRadius: 8,
        padding: 1
    },
    typoInputErrorStyle: {
        fontSize: "small", 
        color: greyColor[5]
    }
}

export default TextAreaHookFormInput;