import { Box, Stack } from "@mui/material";
import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { DialogContainer, HeaderDialogContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { DownloadForm } from "../Forms";
import { greenColor, greyColor } from "@styles";
import { usePlaGesTemDow } from "@hooks/gestionTemps/planning/hooksQueries";
import { FormProvider, useForm } from "react-hook-form";
import { useCallback, useEffect } from "react";
import { Loader } from "@components/Loaders";
import moment from "moment";

const defaultValues = {
    dateDebut: moment(),
    societe: "",
    filtreSociete: false,
    filtreEtablissement: false,
    filtreDirection: false,
    filtreService: false,
    filtreSecteur: false,
    filtreMetier: false,
    filtreCategorieProfessionnelle: false,
    filtreHoraire: false,
    tri: "AUC"
}

const PlanningDownloadDialog = (props) => {
    const { open, setOpen } = props;
    const methods = useForm({ mode: "all", defaultValues: defaultValues });
    const { isLoadingDownload, downloadPlanningActionner } = usePlaGesTemDow();

    useEffect(() => {
        if(open) {
            methods.reset(defaultValues);
        } 
    }, [methods, open])

    const telechargerPlanning = useCallback(async (data) => {
        await downloadPlanningActionner(data);
    }, [downloadPlanningActionner]);

    return (
        <DialogContainer open={open} taille="sm">
            <HeaderDialogContainer  
                actionsLeft={
                    <IconButtonBase icon={<Check fontSize="small" />} title="Télécharger avec ces paramètres" disabled={isLoadingDownload} action={() => methods.handleSubmit(telechargerPlanning)()} />
                }
                title="Paramètres de téléchargement"
                close={() => setOpen(false)}
            /> 
            <Stack spacing={0.5} bgcolor={greenColor["015"]} borderRadius={2} p={0.5}>
                {isLoadingDownload ?
                    <Box display="inline-block" justifyContent="center" p={2}>
                        <Loader size={17} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                    </Box>
                :
                    <FormProvider {...methods}>
                        <DownloadForm />
                    </FormProvider>
                }
            </Stack>
        </DialogContainer>
    );
}

PlanningDownloadDialog.dafaultProps = {
    telechargerPlanning: () => {}
}

PlanningDownloadDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    telechargerPlanning: PropTypes.func
}

export default PlanningDownloadDialog;