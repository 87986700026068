import { HeaderDialogContainer, TextContainer } from "@components/Containers";
import { greenColor } from "@styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ContrainteActionsMobile = (props) => {
    const { setOpen, service, societe } = props;
    const { t } = useTranslation("global");

    return (
        <>
            <HeaderDialogContainer 
                close={() => setOpen(false)}
                title={"Contraintes"}
            />
            <TextContainer bgColor={greenColor["015"]} p={1} textWeight="bold">
                {t("texts.associate_constraint", { service: service, societe: societe })}
            </TextContainer>
        </>
    );
}

ContrainteActionsMobile.defaultProps = {
    setOpen: () => {},
    service: "",
    societe: ""
}

ContrainteActionsMobile.propTypes = {
    setOpen: PropTypes.func,
    service: PropTypes.string,
    societe: PropTypes.string
}

export default ContrainteActionsMobile;