import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { innerJoin, tidy } from "@tidyjs/tidy";
import { useSnackbar } from "notistack";
import numeral from "numeral";
import { fetchAffActInd, fetchAffHorInd, addAffAct, addAffHor, addAffActCol, addAffHorCol, addCycAffHor, editAffAct, editAffHor, deleteAffHor, deleteAffAct } from "@api/affectations";
import { fetchSocV1 } from "@api/societes";
import { selectAffActInd, selectAffHorInd, selectCltApp, selectColAffActCol, selectColAffActInd, selectColAffHorCol, selectColAffHorInd, selectSocAffActCol, selectSocAffHorCol, selectUsrApp } from "@selectors";
import * as affActIndActions from "@reducers/gestionTemps/affectations/affActIndReducer";
import * as affHorIndActions from "@reducers/gestionTemps/affectations/affHorIndReducer";

export const useFetchAffHorInd = () => {
    const stateColAffHorInd = useSelector(selectColAffHorInd);
    const codeSociete = stateColAffHorInd?.collaborateurSelected?.codeSociete;
    const matricule = stateColAffHorInd?.collaborateurSelected?.matricule;
    const typePersonnel = stateColAffHorInd?.collaborateurSelected?.typePersonnel;
    const stateClient = useSelector(selectCltApp);
    const dispatch = useDispatch();

    const query = useQuery(['affHorInd', codeSociete, matricule], ({ signal }) => fetchAffHorInd(codeSociete, matricule, typePersonnel, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!matricule
    });

    useEffect(() => {
        if(query.data) {
            dispatch(affHorIndActions.setAffectations(query.data));
        }
    }, [dispatch, query.data]);

    return query;
}

export const useFetchAffActInd = () => {
    const stateColAffActInd = useSelector(selectColAffActInd);
    const codeSociete = stateColAffActInd?.collaborateurSelected?.codeSociete;
    const matricule = stateColAffActInd?.collaborateurSelected?.matricule;
    const typePersonnel = stateColAffActInd?.collaborateurSelected?.typePersonnel;
    const stateClient = useSelector(selectCltApp);
    const dispatch = useDispatch();

    const query = useQuery(['affActInd', codeSociete, matricule], ({ signal }) => fetchAffActInd(codeSociete, matricule, typePersonnel, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!matricule
    });

    useEffect(() => {
        if(query.data) {
            dispatch(affActIndActions.setAffectations(query.data));
        }
    }, [dispatch, query.data]);

    return query;
}

export const useAddAff = () => {
    const stateColAffHorInd = useSelector(selectColAffHorInd);
    const stateColAffActInd = useSelector(selectColAffActInd);
    const stateClient = useSelector(selectCltApp);
    const { mutateAsync: mutateAsyncHoraire, isLoading: isLoadingHoraire } = useMutation(addAffHor);
    const { mutateAsync: mutateAsyncActivite, isLoading: isLoadingActivite } = useMutation(addAffAct);
    const { enqueueSnackbar } = useSnackbar();

    const addAffHorActionner = useCallback(async (data) => {
        let isSuccessPost = false;
        const dataAffectation = {
            codeSociete: stateColAffHorInd?.collaborateurSelected?.codeSociete,
            matricule: stateColAffHorInd?.collaborateurSelected?.matricule,
            client: stateClient.client,
            jourEffet: data?.date.format("D"),
            moisEffet: data?.date.format("M"),
            anneeEffet: data?.date.format("YYYY"),
            codeHoraire: data?.horaire?.codeHoraire,
            codeStatut: data.statut,
            typePersonnel: stateColAffHorInd?.collaborateurSelected?.typePersonnel
        }
        try {
            await mutateAsyncHoraire(dataAffectation);
            enqueueSnackbar("Affectation ajoutée avec succès.", { variant: "success", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            isSuccessPost = true;
        } catch(e) {
            if(e?.response?.data?.details) {
                enqueueSnackbar(e?.response?.data?.details, { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            } else {
                enqueueSnackbar("Erreur inconnue", { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            }
        }

        return { isSuccessPost };
    }, [enqueueSnackbar, mutateAsyncHoraire, stateClient.client, stateColAffHorInd?.collaborateurSelected?.codeSociete, stateColAffHorInd?.collaborateurSelected?.matricule, stateColAffHorInd?.collaborateurSelected?.typePersonnel]);

    const addAffActActionner = useCallback(async (data) => {
        let isSuccessPost = false;
        const dataAffectation = {
            codeSociete: stateColAffActInd?.collaborateurSelected?.codeSociete,
            matricule: stateColAffActInd?.collaborateurSelected?.matricule,
            client: stateClient.client,
            jourEffet: data?.date.format("D"),
            moisEffet: data?.date.format("M"),
            anneeEffet: data?.date.format("YYYY"),
            codeActivite: data?.activite?.codeActivite,
            typePersonnel: stateColAffActInd?.collaborateurSelected?.typePersonnel
        }
        try {
            await mutateAsyncActivite(dataAffectation);
            enqueueSnackbar("Affectation ajoutée avec succès.", { variant: "success", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            isSuccessPost = true;
        } catch(e) {
            if(e?.response?.data?.details) {
                enqueueSnackbar(e?.response?.data?.details, { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            } else {
                enqueueSnackbar("Erreur inconnue", { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            }
        }

        return { isSuccessPost };
    }, [enqueueSnackbar, mutateAsyncActivite, stateClient.client, stateColAffActInd?.collaborateurSelected?.codeSociete, stateColAffActInd?.collaborateurSelected?.matricule, stateColAffActInd?.collaborateurSelected?.typePersonnel]);


    return { isLoadingHoraire, isLoadingActivite, addAffHorActionner, addAffActActionner };
}

export const useEditAff = () => {
    const stateAffectationsHoraires = useSelector(selectAffHorInd);
    const stateAffectationsActivites = useSelector(selectAffActInd);
    const stateColAffHorInd = useSelector(selectColAffHorInd);
    const stateColAffActInd = useSelector(selectColAffActInd);
    const stateClient = useSelector(selectCltApp);
    const { mutateAsync: mutateAsyncHoraire, isLoading: isLoadingHoraire } = useMutation(editAffHor);
    const { mutateAsync: mutateAsyncActivite, isLoading: isLoadingActivite } = useMutation(editAffAct);
    const { enqueueSnackbar } = useSnackbar();

    const editAffHorActionner = useCallback(async (data) => {
        let isSuccessEdit = false;
        const dataAffectation = {
            codeSociete: stateAffectationsHoraires?.affecationSelected?.codeSociete,
            matricule: stateAffectationsHoraires?.affecationSelected?.matricule,
            jourEffet: stateAffectationsHoraires?.affecationSelected?.jourEffet,
            moisEffet: stateAffectationsHoraires?.affecationSelected?.moisEffet,
            anneeEffet: stateAffectationsHoraires?.affecationSelected?.anneeEffet,
            client: stateClient.client,
            codeHoraire: data?.horaire?.codeHoraire,
            codeStatut: data.statut,
            typePersonnel: stateColAffHorInd?.collaborateurSelected?.typePersonnel
        }
        try {
            await mutateAsyncHoraire(dataAffectation);
            enqueueSnackbar("Affectation modifiée avec succès.", { variant: "success", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            isSuccessEdit = true;
        } catch(e) {
            if(e?.response?.data?.details) {
                enqueueSnackbar(e?.response?.data?.details, { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            } else {
                enqueueSnackbar("Erreur inconnue", { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            }
        }

        return { isSuccessEdit };
    }, [enqueueSnackbar, mutateAsyncHoraire, stateAffectationsHoraires?.affecationSelected?.anneeEffet, stateAffectationsHoraires?.affecationSelected?.codeSociete, stateAffectationsHoraires?.affecationSelected?.jourEffet, stateAffectationsHoraires?.affecationSelected?.matricule, stateAffectationsHoraires?.affecationSelected?.moisEffet, stateClient.client, stateColAffHorInd?.collaborateurSelected?.typePersonnel]);

    const editAffActActionner = useCallback(async (data) => {
        let isSuccessEdit = false;
        const dataAffectation = {
            codeSociete: stateAffectationsActivites?.affecationSelected?.codeSociete,
            matricule: stateAffectationsActivites?.affecationSelected?.matricule,
            jourEffet: stateAffectationsActivites?.affecationSelected?.jourEffet,
            moisEffet: stateAffectationsActivites?.affecationSelected?.moisEffet,
            anneeEffet: stateAffectationsActivites?.affecationSelected?.anneeEffet,
            client: stateClient.client,
            codeActivite: data?.activite?.codeActivite,
            typePersonnel: stateColAffActInd?.collaborateurSelected?.typePersonnel
        }
        try {
            await mutateAsyncActivite(dataAffectation);
            enqueueSnackbar("Affectation modifiée avec succès.", { variant: "success", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            isSuccessEdit = true;
        } catch(e) {
            if(e?.response?.data?.details) {
                enqueueSnackbar(e?.response?.data?.details, { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            } else {
                enqueueSnackbar("Erreur inconnue", { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            }
        }

        return { isSuccessEdit };
    }, [enqueueSnackbar, mutateAsyncActivite, stateAffectationsActivites?.affecationSelected?.anneeEffet, stateAffectationsActivites?.affecationSelected?.codeSociete, stateAffectationsActivites?.affecationSelected?.jourEffet, stateAffectationsActivites?.affecationSelected?.matricule, stateAffectationsActivites?.affecationSelected?.moisEffet, stateClient.client, stateColAffActInd?.collaborateurSelected?.typePersonnel]);

    return { isLoadingActivite, isLoadingHoraire, editAffHorActionner, editAffActActionner };
}

export const useDeleteAff = () => {
    const stateAffectationsHoraires = useSelector(selectAffHorInd);
    const stateAffectationsActivites = useSelector(selectAffActInd);
    const stateColAffHorInd = useSelector(selectColAffHorInd);
    const stateColAffActInd = useSelector(selectColAffActInd);
    const stateClient = useSelector(selectCltApp);
    const { mutateAsync: mutateAsyncHoraire, isLoading: isLoadingHoraire } = useMutation(deleteAffHor);
    const { mutateAsync: mutateAsyncActivite, isLoading: isLoadingActivite } = useMutation(deleteAffAct);
    const { enqueueSnackbar } = useSnackbar();

    const deleteAffHorActionner = useCallback(async () => {
        let isSuccessDelete = false;
        const dataAffectation = {
            codeSociete: stateAffectationsHoraires?.affecationSelected?.codeSociete,
            matricule: stateAffectationsHoraires?.affecationSelected?.matricule,
            jourEffet: stateAffectationsHoraires?.affecationSelected?.jourEffet,
            moisEffet: stateAffectationsHoraires?.affecationSelected?.moisEffet,
            anneeEffet: stateAffectationsHoraires?.affecationSelected?.anneeEffet,
            typePersonnel: stateColAffHorInd?.collaborateurSelected?.typePersonnel,
            client: stateClient.client
        }
        try {
            await mutateAsyncHoraire(dataAffectation);
            enqueueSnackbar("Affectation supprimée avec succès.", { variant: "success", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            isSuccessDelete = true;
        } catch(e) {
            if(e?.response?.data?.details) {
                enqueueSnackbar(e?.response?.data?.details, { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            } else {
                enqueueSnackbar("Erreur inconnue", { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            }
        }

        return { isSuccessDelete };
    }, [enqueueSnackbar, mutateAsyncHoraire, stateAffectationsHoraires?.affecationSelected?.anneeEffet, stateAffectationsHoraires?.affecationSelected?.codeSociete, stateAffectationsHoraires?.affecationSelected?.jourEffet, stateAffectationsHoraires?.affecationSelected?.matricule, stateAffectationsHoraires?.affecationSelected?.moisEffet, stateClient.client, stateColAffHorInd?.collaborateurSelected?.typePersonnel]);

    const deleteAffActActionner = useCallback(async () => {
        let isSuccessDelete = false;
        const dataAffectation = {
            codeSociete: stateAffectationsActivites?.affecationSelected?.codeSociete,
            matricule: stateAffectationsActivites?.affecationSelected?.matricule,
            jourEffet: stateAffectationsActivites?.affecationSelected?.jourEffet,
            moisEffet: stateAffectationsActivites?.affecationSelected?.moisEffet,
            anneeEffet: stateAffectationsActivites?.affecationSelected?.anneeEffet,
            typePersonnel: stateColAffActInd?.collaborateurSelected?.typePersonnel,
            client: stateClient.client
        }
        try {
            await mutateAsyncActivite(dataAffectation);
            enqueueSnackbar("Affectation supprimée avec succès.", { variant: "success", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            isSuccessDelete = true;
        } catch(e) {
            if(e?.response?.data?.details) {
                enqueueSnackbar(e?.response?.data?.details, { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            } else {
                enqueueSnackbar("Erreur inconnue", { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            }
        }

        return { isSuccessDelete };
    }, [enqueueSnackbar, mutateAsyncActivite, stateAffectationsActivites?.affecationSelected?.anneeEffet, stateAffectationsActivites?.affecationSelected?.codeSociete, stateAffectationsActivites?.affecationSelected?.jourEffet, stateAffectationsActivites?.affecationSelected?.matricule, stateAffectationsActivites?.affecationSelected?.moisEffet, stateClient.client, stateColAffActInd?.collaborateurSelected?.typePersonnel]);


    return { isLoadingHoraire, isLoadingActivite, deleteAffHorActionner, deleteAffActActionner };
}

export const useAddCycAffHor = () => {
    const stateColAffHorInd = useSelector(selectColAffHorInd);
    const stateClient = useSelector(selectCltApp);
    const stateUser = useSelector(selectUsrApp);
    const { isLoading: isLoadingHoraire, mutateAsync: mutateAsyncAffectation } = useMutation(addCycAffHor);
    const { isLoading: isLoadingSociete, mutateAsync: mutateAsyncSociete } = useMutation(fetchSocV1)
    const { enqueueSnackbar } = useSnackbar();

    const addCycAffHorActionner = async (data) => {
        let isSucceedAddCycle = false;
        const dataSociete = {
            matricule: stateColAffHorInd?.collaborateurSelected?.matricule,
            date: data?.dateDebut.format("DDMMYYYY"), 
            gestionTemps: "O", 
            client: stateClient.client,
            typePersonnel: stateColAffHorInd?.collaborateurSelected?.typePersonnel
        }
        try {
            const response = await mutateAsyncSociete(dataSociete);
            if(response.succeed) {
                let horairesString = ""
                data?.horaires.forEach((horaire) => {
                    if(horaire.horaire) {
                        horairesString += horaire.horaire.codeHoraire;
                    }
                    return horairesString
                })
                const dataFormated = {
                    matriculeHierarchique: stateUser.matricule,
                    matriculeCollaborateur: stateColAffHorInd?.collaborateurSelected?.matricule,
                    typePersonnel: stateColAffHorInd?.collaborateurSelected?.typePersonnel,
                    codeSociete: response.societes[0].codeSociete,
                    dateDebut: data?.dateDebut.format("DDMMYYYY"),
                    dateFin: data?.dateFin.format("DDMMYYYY"),
                    cycle: numeral(data.cycle).format("00"),
                    horaire: horairesString,
                    origine: "1",
                    client: stateClient.client
                }
                try {
                    await mutateAsyncAffectation(dataFormated);
                    enqueueSnackbar("Affectation ajoutée avec succès.", { variant: "success", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
                    isSucceedAddCycle = true;
                } catch(e) {
                    if(e?.response?.data?.details) {
                        enqueueSnackbar(e?.response?.data?.details, { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
                    } else {
                        enqueueSnackbar("Erreur inconnue", { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
                    }
                }
            }
            
        } catch(e) {
            if(e?.response?.data?.details) {
                enqueueSnackbar(e?.response?.data?.details, { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            } else {
                enqueueSnackbar("Erreur inconnue", { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            }
        }

        return { isSucceedAddCycle }
    }

    return { isLoadingHoraire, isLoadingSociete, addCycAffHorActionner };
}

export const useAddAffHorCol = () => {
    const stateClient = useSelector(selectCltApp);
    const stateUser = useSelector(selectUsrApp);
    const stateColAffHorCol = useSelector(selectColAffHorCol);
    const stateSocAffHorCol = useSelector(selectSocAffHorCol);
    const { isLoading: isLoadingHoraire, mutateAsync } = useMutation(addAffHorCol);
    const { enqueueSnackbar } = useSnackbar();

    const addAffHorColActionner = async (data) => {
        let isSucceed = false;
        const ids = stateColAffHorCol.collaborateursSelected.map((value) => ({ id: value }))
        const collaborateursSelected = tidy(stateColAffHorCol.collaborateurs, innerJoin(ids, { by: ['id'] }))
        const collaborateurs = collaborateursSelected.map((collaborateur) => ({
            matriculeCollaborateur: collaborateur.matricule,
            typePersonnel: collaborateur.typePersonnel,
            dateDebut: "00000000",
            dateFin: "00000000",
            cycle: "00",
            codeHoraire: ""
        }));
        const affectationsFiltered = data.affectations.filter((affectation) => affectation.dateEffet && affectation.horaire);
        const affectations = affectationsFiltered.map((affectation) => ({
            jourEffet: affectation.dateEffet.format("DD"),
            moisEffet: affectation.dateEffet.format("MM"),
            anneeEffet: affectation.dateEffet.format("YYYY"),
            codeHoraire: affectation.horaire.codeHoraire
        }));
        const dataFormated = {
            matriculeHierarchique: stateUser.matricule,
            codeSociete: stateSocAffHorCol?.societeSelected?.codeSociete,
            origine: "3",
            collaborateurs: collaborateurs,
            affectations: affectations,
            dateDebut: "",
            dateFin: "",
            cycle: "",
            horaire: "",
            client: stateClient.client
        }
        try {
            if(affectations.length > 0) {
                await mutateAsync(dataFormated);
                enqueueSnackbar("Affectations ajoutées avec succès.", { variant: "success", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
                isSucceed = true;
            } else {
                enqueueSnackbar("Veuillez compléter le formulaire avec des données valides.", { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            }
        } catch(e) {
            if(e?.response?.data?.details) {
                enqueueSnackbar(e?.response?.data?.details, { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            } else {
                enqueueSnackbar("Erreur inconnue", { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            }
        }

        return { isSucceed }
    }
     
    const addCycAffHorColActionner = async (data) => {
        let isSucceed = false;
        let response = [];
        let horairesString = ""
        data?.horaires.forEach((horaire) => {
            if(horaire.horaire) {
                horairesString += horaire.horaire.codeHoraire;
            }
            return horairesString
        })
        const dataFormated = {
            matriculeHierarchique: stateUser.matricule,
            codeSociete: stateSocAffHorCol?.societeSelected?.codeSociete,
            collaborateurs: [],
            affectations: [],
            dateDebut: data?.dateDebut.format("DDMMYYYY"),
            dateFin: data?.dateFin.format("DDMMYYYY"),
            cycle: numeral(data.cycle).format("00"),
            horaire: horairesString,
            origine: "2",
            client: stateClient.client
        }
        try {
            response = await mutateAsync(dataFormated);
            isSucceed = true;
        } catch(e) {
            if(e?.response?.data?.details) {
                enqueueSnackbar(e?.response?.data?.details, { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            } else {
                enqueueSnackbar("Erreur inconnue", { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            }
        }

        return { isSucceed, response }
    }

    return { isLoadingHoraire, addAffHorColActionner, addCycAffHorColActionner };
}

export const useAddAffActCol = () => {
    const stateClient = useSelector(selectCltApp);
    const stateUser = useSelector(selectUsrApp);
    const stateColAffActCol = useSelector(selectColAffActCol);
    const stateSocAffActCol = useSelector(selectSocAffActCol);
    const { isLoading: isLoadingActivite, mutateAsync } = useMutation(addAffActCol);
    const { enqueueSnackbar } = useSnackbar();

    const addAffActColActionner = async (data) => {
        let isSucceed = false;
        const ids = stateColAffActCol.collaborateursSelected.map((value) => ({ id: value }))
        const collaborateursSelected = tidy(stateColAffActCol.collaborateurs, innerJoin(ids, { by: ['id'] }))
        const collaborateurs = collaborateursSelected.map((collaborateur) => ({
            matriculeCollaborateur: collaborateur.matricule,
            typePersonnel: collaborateur.typePersonnel
        }));
        const affectationsFiltered = data.affectations.filter((affectation) => affectation.dateEffet && affectation.activite);
        const affectations = affectationsFiltered.map((affectation) => ({
            jourEffet: affectation.dateEffet.format("DD"),
            moisEffet: affectation.dateEffet.format("MM"),
            anneeEffet: affectation.dateEffet.format("YYYY"),
            codeActivite: affectation?.activite?.codeActivite
        }));
        const dataFormated = {
            matriculeHierarchique: stateUser.matricule,
            codeSociete: stateSocAffActCol?.societeSelected?.codeSociete,
            collaborateurs: collaborateurs,
            affectations: affectations,
            client: stateClient.client
        }
        try {
            if(affectations.length > 0) {
                await mutateAsync(dataFormated);
                enqueueSnackbar("Affectations ajoutées avec succès.", { variant: "success", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
                isSucceed = true;
            } else {
                enqueueSnackbar("Veuillez compléter le formulaire avec des données valides.", { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            }
        } catch(e) {
            if(e?.response?.data?.details) {
                enqueueSnackbar(e?.response?.data?.details, { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            } else {
                enqueueSnackbar("Erreur inconnue", { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            }
        }

        return { isSucceed }
    };
    

    return { isLoadingActivite, addAffActColActionner };
}
