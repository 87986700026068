import { useCallback, useState } from "react";
import { Box, Collapse, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { CollaborateursFwdc01List, CollaborateursFwgtp2List, CollaborateursFwgtp3List, CollaborateursFwgtpeList, CollaborateursFwmv01List } from "@components/App/Collaborateurs/Lists";
import { CollaborateursFwdc01Form, CollaborateursFwgtp2Form, CollaborateursFwgtp3Form, CollaborateursFwgtpeForm, CollaborateursFwmv01Form } from "@components/App/Collaborateurs/Forms";
import { CollaborateursFwdc01Trie, CollaborateursFwgtp2Trie, CollaborateursFwgtp3Trie, CollaborateursFwgtpeTrie, CollaborateursFwmv01Trie } from "@components/App/Collaborateurs/Tries";
import { useStateCollaborateurs } from "@hooks/collaborateurs/hooksStates";
import { useQueriesCollaborateurs } from "@hooks/collaborateurs/hooksQueries";
import { DialogContainer, HeaderDialogContainer } from "@components/Containers";
import { Loader } from "@components/Loaders";
import { CollaborateursFwdc01Actions, CollaborateursFwgtp2Actions, CollaborateursFwgtp3Actions, CollaborateursFwgtpeActions } from "./Actions";
import { TYPES_FICHIER } from "@constants/collaborateursConstants";
import { greenColor, greyColor } from "@styles";
import CollaborateursFwmv01Actions from "./Actions/CollaborateursFwmv01Actions";

const CollaborateursDialog = ({ 
    stateType = "", 
    listType = "",
    open = false, 
    dateValidite = null, 
    dataForm = null,
    codeSociete = "",
    allChecked = false, 
    tried = false, 
    headers = [],
    displayDate = false,
    collaborateursChecked = [],
    setOpen = () => {},  
    selectCollaborateur = () => {},
    selectCollaborateursChecked = () => {},
    selectAllCollaborateurs = () => {}
}) => {
    const stateCollaborateurs = useStateCollaborateurs(stateType);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openFiltre, setOpenFiltre] = useState(false);
    const [horaire, setHoraire] = useState(false);
    const { isLoading, isFetching } = useQueriesCollaborateurs(stateType, dateValidite, dataForm, codeSociete, open);

    const openTrie = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    return (
        <DialogContainer open={open} placement="top" taille="xl">
            <HeaderDialogContainer 
                actionsLeft={{
                    [TYPES_FICHIER.FWGTPE]: <CollaborateursFwgtpeActions stateType={stateType} displayDate={displayDate} dateValidite={dateValidite} isLoading={isLoading || isFetching} tried={tried} toggleTrie={openTrie} setOpenFiltre={setOpenFiltre} />,
                    [TYPES_FICHIER.FWGTP2]: <CollaborateursFwgtp2Actions stateType={stateType} dateValidite={dateValidite} isLoading={isLoading || isFetching} allChecked={allChecked} tried={tried} toggleTrie={openTrie} selectCollaborateursChecked={selectCollaborateursChecked} setOpenFiltre={setOpenFiltre} selectAllCollaborateurs={selectAllCollaborateurs} />,
                    [TYPES_FICHIER.FWGTP3]: <CollaborateursFwgtp3Actions stateType={stateType} dateValidite={dateValidite} isLoading={isLoading || isFetching} horaire={horaire} tried={tried} toggleTrie={openTrie} setOpenFiltre={setOpenFiltre} setHoraire={setHoraire} />,
                    [TYPES_FICHIER.FWDC01]: <CollaborateursFwdc01Actions stateType={stateType} dateValidite={dateValidite} isLoading={isLoading || isFetching} tried={tried} toggleTrie={openTrie} setOpenFiltre={setOpenFiltre} />,
                    [TYPES_FICHIER.FWMV01]: <CollaborateursFwmv01Actions stateType={stateType} isLoading={isLoading || isFetching} tried={tried} toggleTrie={openTrie} setOpenFiltre={setOpenFiltre} />
                }[listType]}
                title="Choisir un collaborateur"
                close={() => setOpen(false)}
            />
            {(isLoading || isFetching) ? 
                <Box p={2}>
                    <Loader size={27} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                </Box>
            :
                <Stack spacing={0.5}>
                    {{
                        [TYPES_FICHIER.FWGTPE]: <CollaborateursFwgtpeList data={stateCollaborateurs.collaborateursDisplayed} headers={headers} selectCollaborateur={selectCollaborateur} openFiltre={openFiltre} />,
                        [TYPES_FICHIER.FWGTP2]: <CollaborateursFwgtp2List data={stateCollaborateurs.collaborateursDisplayed} headers={headers} collaborateursChecked={collaborateursChecked} selectCollaborateur={selectCollaborateur} openFiltre={openFiltre} />,
                        [TYPES_FICHIER.FWGTP3]: <CollaborateursFwgtp3List data={stateCollaborateurs.collaborateursDisplayed} headers={headers} collaborateurSelected={stateCollaborateurs?.collaborateurSelected} selectCollaborateur={selectCollaborateur} horaire={horaire} openFiltre={openFiltre} />,
                        [TYPES_FICHIER.FWDC01]: <CollaborateursFwdc01List data={stateCollaborateurs.collaborateursDisplayed} headers={headers} selectCollaborateur={selectCollaborateur} openFiltre={openFiltre} />,
                        [TYPES_FICHIER.FWMV01]: <CollaborateursFwmv01List data={stateCollaborateurs.collaborateursDisplayed} headers={headers} selectCollaborateur={selectCollaborateur} openFiltre={openFiltre} />
                    }[listType]}
                </Stack>
            }
            {{
                [TYPES_FICHIER.FWGTPE]: <CollaborateursFwgtpeTrie stateType={stateType} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />,
                [TYPES_FICHIER.FWGTP2]: <CollaborateursFwgtp2Trie stateType={stateType} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />,
                [TYPES_FICHIER.FWGTP3]: <CollaborateursFwgtp3Trie stateType={stateType} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />,
                [TYPES_FICHIER.FWDC01]: <CollaborateursFwdc01Trie stateType={stateType} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />,
                [TYPES_FICHIER.FWMV01]: <CollaborateursFwmv01Trie stateType={stateType} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
            }[listType]}
            <Collapse in={openFiltre} timeout="auto">
                {{
                    [TYPES_FICHIER.FWGTPE]: <CollaborateursFwgtpeForm type={stateType} />,
                    [TYPES_FICHIER.FWGTP2]: <CollaborateursFwgtp2Form type={stateType} />,
                    [TYPES_FICHIER.FWGTP3]: <CollaborateursFwgtp3Form type={stateType} />,
                    [TYPES_FICHIER.FWDC01]: <CollaborateursFwdc01Form type={stateType} />,
                    [TYPES_FICHIER.FWMV01]: <CollaborateursFwmv01Form type={stateType} />
                }[listType]}
            </Collapse>
        </DialogContainer>
    );
}

CollaborateursDialog.defaultProps = {
    
}

CollaborateursDialog.propTypes = {
    stateType: PropTypes.string,
    listType: PropTypes.string,
    open: PropTypes.bool,
    dateValidite: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    dataForm: PropTypes.object,
    codeSociete: PropTypes.string,
    allChecked: PropTypes.bool,
    tried: PropTypes.bool,
    headers: PropTypes.array,
    displayDate: PropTypes.bool,
    collaborateursChecked: PropTypes.array,
    setOpen: PropTypes.func,
    selectCollaborateur: PropTypes.func,
    selectAllCollaborateurs: PropTypes.func,
    selectCollaborateursChecked: PropTypes.func
}

export default CollaborateursDialog;