import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import { ChevronLeft, ChevronRight, Refresh } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import * as plaHorActions from "@reducers/plagesHoraires/plaHorReducer";
import { selectPlaHor } from "@selectors";
import { usePlaHor } from "@hooks/gestionTemps/plagesHoraires/hooksQueries";
import { DialogContainer, HeaderDialogContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { ErrorBase } from "@components/Errors";
import { DateBar, InformationsBar, LegendeBar } from "../Bars";
import Plages from "../Plages";
import { greenColor } from "@styles";

const PlagesHorairesDialog = (props) => {
    const { open, setOpen, matricule, typePersonnel, prenomNom } = props;    
    const statePlagesHoraires = useSelector(selectPlaHor)
    const { data, isLoading, isFetching, isError, refetch } = usePlaHor(matricule, typePersonnel, open);
    const loadingOrError = isLoading || isFetching || isError;
    const dispatch = useDispatch();

    const precedent = useCallback(() => {
        dispatch(plaHorActions.setDatePlagesHoraires(moment(statePlagesHoraires.datePlagesHoraires, "DDMMYYYY").add(-1, "day").format("DDMMYYYY")));
    }, [dispatch, statePlagesHoraires.datePlagesHoraires]);

    const suivant = useCallback(() => {
        dispatch(plaHorActions.setDatePlagesHoraires(moment(statePlagesHoraires.datePlagesHoraires, "DDMMYYYY").add(1, "day").format("DDMMYYYY")));
    }, [dispatch, statePlagesHoraires.datePlagesHoraires]);

    return (
        <DialogContainer open={open} placement="top" taille="sm">
            <HeaderDialogContainer 
                actionsLeft={
                    <Stack direction={"row"} spacing={0.5}>
                        <IconButtonBase icon={<ChevronLeft fontSize="small" />} title="Précédent" disabled={loadingOrError} action={precedent} />
                        <IconButtonBase icon={<ChevronRight fontSize="small" />} title="Suivant" disabled={loadingOrError} action={suivant} />
                    </Stack>
                }
                actionsRight={
                    <IconButtonBase icon={<Refresh fontSize="small" />} title="Rafraichir" action={() => refetch()} />
                }
                title="Plages Horaires"
                close={() => setOpen(false)}
            />
            <Box bgcolor={greenColor["015"]} borderRadius={2} p={0.5}>
                <Stack spacing={0.5}>
                    {isError ?
                        <ErrorBase>Erreur lors de la récupération des données</ErrorBase>
                    :
                        <>
                            <InformationsBar 
                                data={data}
                                isLoading={isLoading || isFetching}
                                isError={isError}
                                matricule={matricule} 
                                prenomNom={prenomNom} 
                            />
                            <DateBar 
                                data={data} 
                                isLoading={isLoading || isFetching} 
                            />
                            <Plages 
                                data={data}
                                isLoading={isLoading || isFetching}
                            /> 
                            {!data?.noPlage && <LegendeBar isLoading={isLoading || isFetching} />}
                        </>
                    }
                </Stack>
            </Box>
        </DialogContainer>
    );
}

PlagesHorairesDialog.defaultProps = {
    matricule: "",
    prenomNom: "",
    typePersonnel: "P"
}

PlagesHorairesDialog.propTypes = {
    open: PropTypes.bool.isRequired, 
    setOpen: PropTypes.func.isRequired, 
    matricule: PropTypes.string,
    typePersonnel: PropTypes.string,
    prenomNom: PropTypes.string
}

export default PlagesHorairesDialog;