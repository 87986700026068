import { useSelector } from "react-redux";
import { Box, Grow, Stack, Typography } from "@mui/material";
import moment from "moment";
import { selectEntCol } from "@selectors";
import { getFilename } from "@utils";
import { DownloadButton } from "@components/Buttons";
import { greenColor, whiteColor } from "@styles";

const libellesStatut = {
    P: "planifié",
    E: "effectué"
}

const Informations = () => {
    const stateEntretiens = useSelector(selectEntCol);

    return (
        <Stack spacing={0.5} width="50%">
            <Box bgcolor={whiteColor[9]} borderRadius={2} p={0.5}>
                <Stack spacing={0.5}>
                    <Grow in={true} timeout={300}>
                        <Stack spacing={0.5} borderRadius={2} bgcolor={greenColor["05"]} p={0.5}>
                            <Box borderRadius={2} bgcolor={whiteColor[7]} pl={1} height={40} display="flex" alignItems="center">
                                <Box flexGrow={1}>
                                    <Typography fontSize="small" display="inline" fontWeight="bold">Société : </Typography>
                                    <Typography fontSize="small" display="inline">{stateEntretiens?.entretienSelected?.codeSociete} {stateEntretiens?.entretienSelected?.libelleSociete}</Typography>
                                </Box>
                            </Box>
                            <Box borderRadius={2} bgcolor={whiteColor[7]} pl={1} height={40} display="flex" alignItems="center">
                                <Typography fontSize="small">{stateEntretiens?.entretienSelected?.matricule} {stateEntretiens?.entretienSelected?.prenomNom}</Typography>
                            </Box>
                            <Stack direction="row" borderRadius={2} bgcolor={whiteColor[7]} height={40} alignItems="center" px={1}>
                                <Box flexGrow={1}>
                                    <Typography fontSize="small" display="inline" fontWeight="bold">Emploi : </Typography>
                                    <Typography fontSize="small" display="inline">{stateEntretiens?.entretienSelected?.codeEmploi} {stateEntretiens?.entretienSelected?.libelleEmploi}</Typography>
                                </Box>
                                {stateEntretiens?.entretienSelected?.presencePieceJointeEmploi && 
                                    <Box width={30}>
                                        <DownloadButton 
                                            variant="panelButton"
                                            chemin={stateEntretiens?.entretienSelected?.pieceJointeEmploi} 
                                            nom={""} 
                                        />
                                    </Box>
                                }
                            </Stack>
                        </Stack>
                    </Grow>
                    <Grow in={true} timeout={600}>
                        <Stack spacing={0.5} borderRadius={2} bgcolor={greenColor["05"]} p={0.5}>
                            <Box borderRadius={2} bgcolor={whiteColor[7]} pl={1} height={40} display="flex" alignItems="center">
                                <Typography fontSize="small" fontWeight="bold">{stateEntretiens?.entretienSelected?.designation}</Typography>
                            </Box>
                            <Box borderRadius={2} bgcolor={whiteColor[7]} pl={1} height={40} display="flex" alignItems="center">
                                <Typography fontSize="small" fontWeight="bold">Entretien {libellesStatut[stateEntretiens?.entretienSelected?.statut]} le {moment([stateEntretiens?.entretienSelected?.annee, stateEntretiens?.entretienSelected?.mois - 1, stateEntretiens?.entretienSelected?.jour]).format("DD/MM/YYYY")} à {moment([stateEntretiens?.entretienSelected?.annee, stateEntretiens?.entretienSelected?.mois - 1, stateEntretiens?.entretienSelected?.jour, stateEntretiens?.entretienSelected?.heure, stateEntretiens?.entretienSelected?.minute]).format("HH:mm")}</Typography>
                            </Box>
                            {stateEntretiens?.entretienSelected?.piecesJointes?.length > 0 &&
                                <>
                                    <Box borderRadius={2} bgcolor={whiteColor[7]} pl={1} height={40} display="flex" alignItems="center">
                                        <Typography fontSize="small" display="inline" fontWeight="bold">Pièces Jointes</Typography>
                                    </Box>
                                    {stateEntretiens?.entretienSelected?.piecesJointes?.map((pieceJointe) => (
                                        <Stack key={`PJ${pieceJointe.id}`} direction="row" borderRadius={2} bgcolor={whiteColor[7]} height={40} alignItems="center" px={1}>
                                            <Box flexGrow={1}>
                                                <Typography fontSize="small" display="inline">{getFilename(pieceJointe?.pieceJointe)}</Typography>
                                            </Box>
                                            <Box width={30}>
                                                <DownloadButton 
                                                    variant="panelButton"
                                                    chemin={pieceJointe?.pieceJointe} 
                                                    nom={getFilename(pieceJointe?.pieceJointe)} 
                                                />
                                            </Box>
                                        </Stack>
                                    ))}
                                </>
                            }
                        </Stack>
                    </Grow>
                </Stack>
            </Box>
        </Stack>
    )
}

export default Informations;