import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { TextContainer, TextIconContainer } from "@components/Containers";
import { greenColor, greyColor, whiteColor, yellowColor } from "@styles";
import { Loader } from "@components/Loaders";
import { DialogError } from "@components/Errors";
import { Check, Close } from "@mui/icons-material";
import { TextMotifAbsenceContainer, TextNombreHeuresAbsenceContainer, TextNombreJoursAbsenceContainer } from "@components/Containers/GestionAbsences";
import { useTranslation } from "react-i18next";

const JourContents = (props) => {
    const { data, isLoading, isError, errorMessage, prenomNom } = props;
    const { t } = useTranslation("global");

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader size={22} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return <DialogError error={errorMessage} />
    }

    return (
        <Stack spacing={0.5}>
            {prenomNom &&
                <TextContainer bgColor={greenColor["015"]} p={1}>{prenomNom}</TextContainer>
            }
            {data.length > 0 ?
                data.map((absence) => (
                    <Stack spacing={0.5} key={`DJSABS${absence.id}`} bgcolor={Number(absence.codeStatut) ? greenColor["015"] : yellowColor[2]} borderRadius={2} p={0.5}>
                        <TextIconContainer icon={Number(absence.codeStatut) ? <Check fontSize="small" /> : <Close fontSize="small" />} bgColor={whiteColor[5]} p={1} textWeight="bold">{t("titles.status")} : {absence.libelleStatut}</TextIconContainer>
                        <Stack spacing={0.5}>
                            {absence.libelle && <TextMotifAbsenceContainer libelleAbsence={absence.libelle} />}
                            <Stack spacing={0.5} bgcolor={whiteColor[5]} borderRadius={2} p={1}>
                                <TextNombreJoursAbsenceContainer nombreJours={absence.nombreJours} demiJournee={absence.ampm} />
                                <TextNombreHeuresAbsenceContainer heureDebut={absence.plageDebut} heureReprise={absence.plageReprise} nombreHeures={absence.nombreHeures} nombreJours={absence.nombreJours} />
                            </Stack>
                        </Stack>
                    </Stack>
                ))
            :
                <Box sx={styles.dialogTitleStyle}>
                    <Typography fontSize="small">{t("no_data.absence")}</Typography>
                </Box>
            }
        </Stack>
    );
}

JourContents.defaultProps = {
    isLoading: true,
    isError: false,
    errorMessage: "",
    prenomNom: "",
    data: []
}

JourContents.propTypes = {
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
    prenomNom: PropTypes.string,
    data: PropTypes.array
}

const styles = {
    dialogTitleStyle: {
        backgroundColor: "rgba(0,0,0,0.1)", 
        textAlign: "center",
        p: 2, 
        borderRadius: 2
    },
    titleStyle: {
        xs: "11px", 
        sm: "medium"
    },
    loaderStyle: {
        height: 52,
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
    }
}

export default JourContents;