import axios from "axios";
import { urls } from "@constants/appConstants";

export const fetchNtf = async (utilisateur, client) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetNotifications"
    };
    const body = {
        utilisateur: utilisateur
    };
    const response = await axios.post(urls.getNotifications, body, { headers: headers, params: { client: client } });
    return response.data;
}

