import { useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { downloadHeu } from "@api/heures";
import { selectCltApp, selectUsrApp } from "@selectors";
import { generateTableurHeuresCumulees, generateTableurHeuresDetailleesAvecMouvements, generateTableurHeuresDetailleesSansMouvements } from "@tableurs";

export const useDowHeu = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const { isLoading, mutateAsync } = useMutation(downloadHeu);    
    const { enqueueSnackbar } = useSnackbar();

    const downloadHeuresCumuleesActionner = async (data) => {
        const dateDebut = data?.dateDebut.format("DDMMYYYY");
        const dateFin = data?.dateFin.format("DDMMYYYY");
        const codeSociete = data?.societe ? data?.societe?.codeSociete : "";
        const codeEtablissement = data?.etablissement ? data?.etablissement?.code : "";
        const codeDirection = data?.direction ? data?.direction?.code : "";
        const codeService = data?.service ? data?.service?.codeService : "";
        const codeSecteur = data?.secteur ? data?.secteur?.code : "";
        const present = data.present ? "O" : "N";
        const formatedData = {
            option: "C",
            utilisateur: stateUser.utilisateurAS400,
            matricule: stateUser.matricule,
            dateDebut: dateDebut,
            dateFin: dateFin,
            codeSociete: codeSociete,
            codeEtablissement: codeEtablissement,
            codeDirection: codeDirection,
            codeService: codeService,
            codeSecteur: codeSecteur,
            present: present,
            horaire: "",
            jourSemaine: "",
            detailMouvements: "",
            client: stateClient.client
        }
        try {
            const response = await mutateAsync(formatedData);
            generateTableurHeuresCumulees("Heures Cumulées", response);
        } catch(e) {
            if(e?.response?.data?.details) {
                enqueueSnackbar(e?.response?.data?.details, { variant: "error" });
            } else {
                enqueueSnackbar("Erreur inconnue", { variant: "error" });
            }
        }
    };

    const downloadHeuresDetailleesActionner = async (data) => {
        const dateDebut = data?.dateDebut.format("DDMMYYYY");
        const dateFin = data?.dateFin.format("DDMMYYYY");
        const codeSociete = data?.societe ? data?.societe?.codeSociete : "";
        const codeEtablissement = data?.etablissement ? data?.etablissement?.code : "";
        const codeDirection = data?.direction ? data?.direction?.code : "";
        const codeService = data?.service ? data?.service?.codeService : "";
        const codeSecteur = data?.secteur ? data?.secteur?.code : "";
        const present = data.present ? "O" : "N";
        const horaire = data?.horaire ? data?.horaire?.codeHoraire : "";
        const detailMouvements = data.detailMouvements ? "O" : "N";
        const formatedData = {
            option: "D",
            utilisateur: stateUser.utilisateurAS400,
            matricule: stateUser.matricule,
            dateDebut: dateDebut,
            dateFin: dateFin,
            codeSociete: codeSociete,
            codeEtablissement: codeEtablissement,
            codeDirection: codeDirection,
            codeService: codeService,
            codeSecteur: codeSecteur,
            present: present,
            horaire: horaire,
            jourSemaine: data.joursSemaine,
            detailMouvements: detailMouvements,
            client: stateClient.client
        }
        try {
            const response = await mutateAsync(formatedData);
            data.detailMouvements ? generateTableurHeuresDetailleesAvecMouvements("Heures Détaillées", response) : generateTableurHeuresDetailleesSansMouvements("Heures Détaillées", response);
        } catch(e) {
            if(e?.response?.data?.details) {
                enqueueSnackbar(e?.response?.data?.details, { variant: "error" });
            } else {
                enqueueSnackbar("Erreur inconnue", { variant: "error" });
            }
        }
    };

    return { isLoading, downloadHeuresCumuleesActionner, downloadHeuresDetailleesActionner };
}
