import { Typography } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { heureIntVersHeureString } from "@utils";
import { useTranslation } from "react-i18next";

const TextNombreHeuresAbsenceContainer = (props) => {
    const { heureDebut, heureReprise, formatNombreHeures, nombreJours, nombreHeures } = props;
    const nombreHeuresFormater = formatNombreHeures === "decimal" ? heureIntVersHeureString(nombreHeures) : moment(nombreHeures, "HHmm").format("HH[h]mm");
    const libellePlageHoraire = "de " + moment(heureDebut, "HHmm").format("HH[h]mm") + " à " + moment(heureReprise, "HHmm").format("HH[h]mm");
    const { t } = useTranslation("global");

    return (
        Number(nombreJours) <= 1 && 
            <Typography fontSize="small">{t("texts.number_hours")} : {nombreHeuresFormater} {heureDebut !== "0000" && heureDebut && heureReprise !== "0000" && heureReprise && libellePlageHoraire}</Typography>
    );
}

TextNombreHeuresAbsenceContainer.defaultProps = {
    formatNombreHeures: "decimal",
    nombreJours: 0,
    nombreHeures: 0
}

TextNombreHeuresAbsenceContainer.propTypes = {
    heureDebut: PropTypes.string.isRequired,
    heureReprise: PropTypes.string.isRequired,
    formatNombreHeures: PropTypes.string,
    nombreJours: PropTypes.number,
    nombreHeures: PropTypes.number
}

export default TextNombreHeuresAbsenceContainer;