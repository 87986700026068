import PropTypes from "prop-types";
import { PlanningItemsTableMobile } from "../Tables/Items";

const jours = [
    {id: 0, libelle: "Lundi"},
    {id: 1, libelle: "Mardi"},
    {id: 2, libelle: "Mercredi"},
    {id: 3, libelle: "Jeudi"},
    {id: 4, libelle: "Vendredi"},
    {id: 5, libelle: "Samedi"},
    {id: 6, libelle: "Dimanche"}
];

const PlanningFormMobile = (props) => {
    const { modelize } = props; 

    return (
        jours.map((jour) => (
            <PlanningItemsTableMobile key={`JOURPLAGT${jour.id}`} idJour={jour.id} libelle={jour.libelle} modelize={modelize} />
        ))
    )
}

PlanningFormMobile.propTypes = {
    modelize: PropTypes.func.isRequired
}

export default PlanningFormMobile;