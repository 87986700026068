import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack } from "@mui/material";
import moment from "moment";
import * as temGesTemManActions from "@reducers/gestionTemps/temps/temGesTemManReducer";
import * as colTemGesTemActions from "@reducers/collaborateurs/colTemGesTemReducer";
import * as regGesTemActions from "@reducers/gestionTemps/regularisations/regGesTemReducer";
import * as plaHorActions from "@reducers/plagesHoraires/plaHorReducer"
import { selectColTemGesTem, selectRegGesTem, selectTemGesTemMan } from "@selectors";
import { useNavTemGesTem, useTemGesTem } from "@hooks/gestionTemps/temps/hooksQueries";
import { TempsActionsBar } from "@components/GestionTemps/Temps/ActionsBars";
import { TempsTable } from "@components/GestionTemps/Temps/Tables";
import { TempsInformationsList } from "@components/GestionTemps/Temps/Lists";
import { CollaborateursBar } from "@components/App/Collaborateurs/Bars";
import { PlagesHorairesDialog } from "@components/App/PlagesHoraires";
import { CollaborateursDialog, CollaborateursDialogMobile } from "@components/App/Collaborateurs/Dialogs";
import { DebitCreditsDialog, DetailJourDialog, HistoriqueTransactionsDialog, RegulariserDialog, RepasDialog, TransactionsDialog } from "@components/GestionTemps/Temps/Dialogs";
import { AnomaliesDialog, AnomaliesTransactionsDialog } from "@components/App/Anomalies";
import { ROLE_UTILISATEUR } from "@constants/userConstants";
import { TYPES_COLLABORATEURS, TYPES_FICHIER } from "@constants/collaborateursConstants";
import { greenColor, whiteColor } from "@styles";
import { useMedia } from "react-use";

const headerItems = [
    {id: 1, label: "Nom", xs: 1.5, display: true, firstData: true, lastData: false},
    {id: 2, label: "Matricule", xs: 0.75, display: true, firstData: false, lastData: false},
    {id: 3, label: "Badge", xs: 0.75, display: true, firstData: false, lastData: false},
    {id: 4, label: "Société", xs: 1, display: true, firstData: false, lastData: false},
    {id: 5, label: "Etablissement", xs: 1, display: true, firstData: false, lastData: false},
    {id: 6, label: "Direction", xs: 1.5, display: true, firstData: false, lastData: false},
    {id: 7, label: "Service", xs: 1.5, display: true, firstData: false, lastData: false},
    {id: 8, label: "Secteur", xs: 1, display: true, firstData: false, lastData: false},
    {id: 9, label: "Type", xs: 1, display: true, firstData: false, lastData: false},
    {id: 10, label: "Contôle horaire", xs: 0.75, display: true, firstData: false, lastData: false},
    {id: 11, label: "Présent", xs: 0.75, display: true, firstData: false, lastData: true},
];

const Temps = () => {
    const [openCollaborateursDialog, setOpenCollaborateursDialog] = useState(false);
    const [openRepasDialog, setOpenRepasDialog] = useState(false);
    const [openDebitCreditsDialog, setOpenDebitCreditsDialog] = useState(false);
    const [openTransactionsDialog, setOpenTransactionsDialog] = useState(false);
    const [openHistoriqueTransactionsDialog, setOpenHistoriqueTransactionsDialog] = useState(false);
    const [openRegularisationsDialog, setOpenRegularisationsDialog] = useState(false);
    const [openAnomaliesTransactionDialog, setOpenAnomaliesTransactionDialog] = useState(false);
    const [openJourDialog, setOpenJourDialog] = useState(false);
    const [openAllAnomaliesDialog, setOpenAllAnomaliesDialog] = useState(false);
    const [openPlagesHorairesDialog, setOpenPlagesHorairesDialog] = useState(false);
    const [transaction, setTransaction] = useState(null);
    const stateCollaborateurs = useSelector(selectColTemGesTem);
    const stateTemps = useSelector(selectTemGesTemMan);
    const stateRegularisation = useSelector(selectRegGesTem);
    const { isLoading: isLoadingTemps, isFetching, isError, refetch } = useTemGesTem("H", stateCollaborateurs?.collaborateurSelected?.matricule, stateCollaborateurs?.collaborateurSelected?.typePersonnel, stateTemps.dateTemps, ROLE_UTILISATEUR.MANAGER);
    const { isLoading: isLoadingNav, isError: isErrorNav, navigateTemps } = useNavTemGesTem("H", stateCollaborateurs?.collaborateurSelected?.matricule, stateCollaborateurs?.collaborateurSelected?.typePersonnel, ROLE_UTILISATEUR.MANAGER);
    const isLoading = isLoadingTemps || isLoadingNav || isFetching;
    const dispatch = useDispatch();
    const isMobile = useMedia('(max-width: 1350px)');

    const selectCollaborateur = useCallback((collaborateur) => {
        setOpenCollaborateursDialog(false);
        dispatch(temGesTemManActions.setDateTemps(""));
        dispatch(colTemGesTemActions.selectCollaborateur(collaborateur));
    }, [dispatch]);

    const precedent = useCallback(async () => {
        const date = moment(stateTemps.dateTemps, "DDMMYYYY").add(-1, "week").format("DDMMYYYY");
        await navigateTemps(date);
    }, [navigateTemps, stateTemps.dateTemps]);

    const suivant = useCallback(async () => {
        const date = moment(stateTemps.dateTemps, "DDMMYYYY").add(+1, "week").format("DDMMYYYY");
        await navigateTemps(date);
    }, [navigateTemps, stateTemps.dateTemps]);

    const selectDate =  useCallback(async (d) => {
        const date = d.format("DDMMYYYY");
        await navigateTemps(date);
    }, [navigateTemps]);

    const toggleHoraire = useCallback(() => {
        dispatch(temGesTemManActions.toggleHoraire());
    }, [dispatch]);

    const handleOpenTransactions = useCallback((jour) => {
        dispatch(temGesTemManActions.selectJour(jour));
        setOpenTransactionsDialog(true);
    }, [dispatch]);

    const handleOpenRegularisations = useCallback((jour) => {
        dispatch(regGesTemActions.setDateRegularisation(jour));
        setOpenRegularisationsDialog(true);
    }, [dispatch]);

    const handleOpenJour = useCallback((jour) => {
        dispatch(temGesTemManActions.selectJour(jour));
        setOpenJourDialog(true);
    }, [dispatch]);

    const handleOpenAnomaliesTransaction = useCallback((transaction) => {
        setTransaction(transaction);
        setOpenAnomaliesTransactionDialog(true);
    }, []);

    const handleOpenAllAnomalies = useCallback(() => {
        setOpenAllAnomaliesDialog(true);
    }, []);

    const handlePlagesHoraires = useCallback(() => {
        dispatch(plaHorActions.setDatePlagesHoraires(stateRegularisation.dateRegularisation));
        setOpenPlagesHorairesDialog(true);
    }, [dispatch, stateRegularisation.dateRegularisation])

    return (
        <Stack spacing={0.5} sx={styles.stackStyle}>
            <CollaborateursBar collaborateur={stateCollaborateurs?.collaborateurSelected} openDialog={() => setOpenCollaborateursDialog(true)} />
            {stateCollaborateurs.collaborateurSelected && 
                <Stack spacing={0.5} height={"calc(100% - 50px)"}>
                    <TempsActionsBar 
                        isLoading={isLoading}
                        isError={isError || isErrorNav}
                        title={stateCollaborateurs?.collaborateurSelected?.prenomNom ? `Temps de ${stateCollaborateurs?.collaborateurSelected?.prenomNom}` : ""}
                        date={stateTemps.dateTemps}
                        debitCreditPermanentLibelle={stateTemps.totaux?.debitCredit?.permanent?.libelle}
                        debitCreditHebdomadaireLibelle={stateTemps.totaux?.debitCredit?.hebdomadaire?.libelle}
                        horaire={stateTemps.horaire}
                        precedent={precedent}
                        suivant={suivant}
                        selectDate={selectDate}
                        refetch={refetch}
                        toggleHoraire={toggleHoraire}
                        setOpenHistoriqueTransactions={setOpenHistoriqueTransactionsDialog}
                        setOpenDebitCredits={setOpenDebitCreditsDialog}
                        setOpenRepas={setOpenRepasDialog}
                    />
                    <Box height={"calc(100% - 50px)"}>
                        <Stack spacing={0.5} direction={"row"} height={"100%"}>
                            <Box bgcolor={whiteColor[5]} p={0.5} borderRadius={2} flexGrow={1} overflow={"auto"} boxSizing={"border-box"}>
                                <TempsTable 
                                    isLoading={isLoading} 
                                    userType={ROLE_UTILISATEUR.MANAGER}
                                    openTransactions={handleOpenTransactions} 
                                    openRegularisations={handleOpenRegularisations} 
                                    openJour={handleOpenJour} 
                                />
                            </Box>
                            <Box bgcolor={whiteColor[5]} p={0.5} borderRadius={2} minWidth={228} overflow={"auto"} boxSizing={"border-box"}>
                                <TempsInformationsList 
                                    isLoading={isLoading} 
                                    userType={ROLE_UTILISATEUR.MANAGER} 
                                />
                            </Box>
                        </Stack>
                    </Box>
                </Stack>
            }
            {isMobile ? 
                <CollaborateursDialogMobile
                    stateType={TYPES_COLLABORATEURS.GESTION_TEMPS_TEMPS} 
                    listType={TYPES_FICHIER.FWGTPE}
                    open={openCollaborateursDialog}  
                    dateValidite={stateCollaborateurs?.dateValidite ? moment(stateCollaborateurs.dateValidite, "DDMMYYYY").format("DDMMYYYY") : moment().format("DDMMYYYY")}
                    headers={headerItems}
                    tried={stateCollaborateurs.tried}
                    setOpen={setOpenCollaborateursDialog}  
                    selectCollaborateur={selectCollaborateur}
                />
            :
                <CollaborateursDialog
                    stateType={TYPES_COLLABORATEURS.GESTION_TEMPS_TEMPS} 
                    listType={TYPES_FICHIER.FWGTPE}
                    open={openCollaborateursDialog}  
                    dateValidite={stateCollaborateurs?.dateValidite ? moment(stateCollaborateurs.dateValidite, "DDMMYYYY").format("DDMMYYYY") : moment().format("DDMMYYYY")}
                    headers={headerItems}
                    tried={stateCollaborateurs.tried}
                    setOpen={setOpenCollaborateursDialog}  
                    selectCollaborateur={selectCollaborateur}
                />
            }
            <RepasDialog 
                open={openRepasDialog} 
                setOpen={setOpenRepasDialog} 
                userType={ROLE_UTILISATEUR.MANAGER} 
            />
            <DebitCreditsDialog 
                open={openDebitCreditsDialog} 
                setOpen={setOpenDebitCreditsDialog} 
                userType={ROLE_UTILISATEUR.MANAGER} 
                origine="H"
            />
            <TransactionsDialog 
                open={openTransactionsDialog} 
                setOpen={setOpenTransactionsDialog} 
                openAnomalies={handleOpenAnomaliesTransaction} 
                userType={ROLE_UTILISATEUR.MANAGER} 
            />
            <HistoriqueTransactionsDialog 
                open={openHistoriqueTransactionsDialog} 
                setOpen={setOpenHistoriqueTransactionsDialog} 
                openAnomalies={handleOpenAnomaliesTransaction} 
                userType={ROLE_UTILISATEUR.MANAGER} 
            />
            <AnomaliesTransactionsDialog 
                open={openAnomaliesTransactionDialog} 
                setOpen={setOpenAnomaliesTransactionDialog} 
                transaction={transaction} 
                openAllAnomalies={handleOpenAllAnomalies} 
            />
            <AnomaliesDialog 
                open={openAllAnomaliesDialog} 
                setOpen={setOpenAllAnomaliesDialog} 
            />
            <DetailJourDialog 
                open={openJourDialog} 
                setOpen={setOpenJourDialog} 
                userType={ROLE_UTILISATEUR.MANAGER} 
            /> 
            <RegulariserDialog 
                open={openRegularisationsDialog} 
                setOpen={setOpenRegularisationsDialog} 
                openAnomalieTransaction={handleOpenAnomaliesTransaction} 
                openPlagesHoraires={handlePlagesHoraires} 
                refetch={refetch} 
            />
            <PlagesHorairesDialog 
                open={openPlagesHorairesDialog} 
                setOpen={setOpenPlagesHorairesDialog} 
                matricule={stateCollaborateurs?.collaborateurSelected?.matricule} 
                typePersonnel={stateCollaborateurs?.collaborateurSelected?.typePersonnel} 
                prenomNom={stateCollaborateurs?.collaborateurSelected?.prenomNom} 
            />
        </Stack>
    )
}

const styles = {
    stackStyle: {
        bgcolor: greenColor["015"],
        borderRadius: 2,
        padding: 0.5,
        position: "relative",
        height: "calc(100% - 8px)",
    }
};

export default Temps;