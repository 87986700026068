import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { HeaderDialogContainer } from "@components/Containers";
import { useTranslation } from "react-i18next";

const ActionsRecapitulatif = (props) => {
    const { isLoading, close, postDemandeAbsenceRecapitulatif } = props;
    const { t } = useTranslation("global");

    return (
        <HeaderDialogContainer  
            actionsLeft={
                <IconButtonBase icon={<Check fontSize="small" />} title={t("buttons.validate")} noClick={isLoading} action={() => postDemandeAbsenceRecapitulatif()} />
            }
            title={t("titles.validation")}
            close={() => close()} 
        />
    )
}

ActionsRecapitulatif.defaultProps = {
    isLoading: true,
    close: () => {},
    postDemandeAbsenceRecapitulatif: () => {}
}

ActionsRecapitulatif.propTypes = {
    isLoading: PropTypes.bool,
    close: PropTypes.func,
    postDemandeAbsenceRecapitulatif: PropTypes.func
}

export default ActionsRecapitulatif;