import { useSelector } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";
import moment from "moment";
import { selectEntSecMan } from "@selectors";
import { useChampDate } from "@hooks/gestionEntretiens/hooksUtils";
import { DateHookFormInput } from "@components/Inputs";
import { greenColor, whiteColor } from "@styles";

const ChampDate = () => {
    const { displayA, displayE, displayV } = useChampDate();
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;

    if(displayA || displayE) {
        return (
            <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                <Stack spacing={1} borderRadius={2} bgcolor={whiteColor[7]} p={0.5}>
                    <DateHookFormInput name="date" label="Date" displayError />
                </Stack>                
            </Box>
        );
    }

    if(displayV) {
        return (
            <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                <Box borderRadius={2} bgcolor={whiteColor[7]} p={1}>
                    <Typography fontSize="small">Date : {moment(sectionSelected?.date, "DDMMYYYY").format("DD/MM/YYYY")}</Typography>
                </Box>
            </Box>
        );
    }

    return null;
}

export default ChampDate;