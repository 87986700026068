import { useSelector } from "react-redux";
import { selectUsrApp } from "@selectors";
import { DialogContainer } from "@components/Containers";
import { QuestionLoginForm } from "../Forms";

const QuestionLoginDialog = () => {
    const stateUser = useSelector(selectUsrApp);
    
    return (
        <DialogContainer open={stateUser.openQuestion} placement="top" taille="sm">
            <QuestionLoginForm />
        </DialogContainer>
    );
}

export default QuestionLoginDialog;