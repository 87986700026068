import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { getWidgetRemoved } from "@utils";
import { selectDsh } from "@selectors";
import * as dshActions from "@reducers/dashboard/dshReducer"
import { IconButtonBase } from "@components/Buttons";
import { greenColor, redColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const data = {
    city: "Ville", 
    data: [
        { id: 1, date: "JJ/MM/AAAA", weather: [
            {dt: 1677855600, main: {temp: 10}, weather: [{icon: "01d"}]},
            {dt: 1677855601, main: {temp: 10}, weather: [{icon: "02d"}]},
            {dt: 1677855602, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855603, main: {temp: 10}, weather: [{icon: "04d"}]},
        ] }, 
        { id: 2, date: "JJ/MM/AAAA", weather: [
            {dt: 1677855604, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855605, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855606, main: {temp: 10}, weather: [{icon: "03d"}]},
        ] },
        { id: 3, date: "JJ/MM/AAAA", weather: [
            {dt: 1677855607, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855608, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855609, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855610, main: {temp: 10}, weather: [{icon: "03d"}]}
        ] },
        { id: 4, date: "JJ/MM/AAAA", weather: [
            {dt: 1677855611, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855612, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855613, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855614, main: {temp: 10}, weather: [{icon: "03d"}]},
        ] },
        { id: 5, date: "JJ/MM/AAAA", weather: [
            {dt: 1677855615, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855616, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855617, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855618, main: {temp: 10}, weather: [{icon: "03d"}]},
        ] },
        { id: 6, date: "JJ/MM/AAAA", weather: [
            {dt: 1677855619, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855620, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855621, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855622, main: {temp: 10}, weather: [{icon: "03d"}]}
        ] }
    ]
};

const MeteoWidgetConfigurationDelete = () => {
    const stateDashboard = useSelector(selectDsh);
    const dispatch = useDispatch();
    const { t } = useTranslation("global");

    const remove = useCallback(() => {
        const updateState = getWidgetRemoved("wMeteo", stateDashboard.widgetsActives, stateDashboard.widgetsNonActives, stateDashboard.widgetsConfiguration);
        dispatch(dshActions.editWidget(updateState));
    }, [dispatch, stateDashboard.widgetsActives, stateDashboard.widgetsConfiguration, stateDashboard.widgetsNonActives]);

    return ( 
        <Stack spacing={0.5} padding={0.5} height="200px" boxSizing="border-box" borderRadius={2} bgcolor={whiteColor[5]} position="relative">
            <Box bgcolor={greenColor["05"]} borderRadius={2} p={1} textAlign="center">
                <Typography fontSize="small" fontWeight="bold" color="white">{t("texts.city")}</Typography>
            </Box>
            <Stack spacing={0.5} direction="row" sx={{overflowX: "scroll"}} height="100%">
                {data.data.map((item) => (
                    <Box key={`DMETEO${item.id}`}>
                        <Stack spacing={0.5} height="100%">
                            <Box bgcolor={greenColor["05"]} px={1} py={0.5} borderRadius={2}>
                                <Typography fontSize="small" fontWeight="bold" color="white">{item.date}</Typography>
                            </Box>
                            <Stack spacing={0.5} direction="row" height="100%">
                                {item.weather.map((item, id) => (
                                    <Stack key={`METEO${id}`} bgcolor={greenColor["05"]} borderRadius={2} p={1.5} textAlign="center" justifyContent="center" display="flex" >
                                        <Typography fontSize="small" fontWeight="bold" color="white">hh:mm</Typography>
                                        <Typography fontSize="small">
                                            <img src={`${process.env.REACT_APP_ICON_URL}/${item.weather[0].icon}.png`} style={{verticalAlign: "middle"}} alt="Ico weather" width={30}></img>
                                        </Typography>
                                        <Typography fontSize="small" fontWeight="bold" color="white">
                                                {Math.round(item.main.temp)} °C
                                        </Typography>
                                    </Stack>
                                ))}
                            </Stack>
                        </Stack>
                    </Box>
                ))}
            </Stack>
            <Box position="absolute" right={6} top={3}>
                <IconButtonBase 
                    variant="deleteListButton"
                    icon={<Close fontSize="small" sx={{color: redColor[5]}} />} 
                    title={t("buttons.delete")} 
                    action={remove} 
                />
            </Box>
        </Stack>
    );
}

export default MeteoWidgetConfigurationDelete;