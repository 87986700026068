import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useController, useFormContext, useFormState } from "react-hook-form";
import { PatternFormat } from "react-number-format";
import { filter, tidy } from "@tidyjs/tidy";
import PropTypes from "prop-types";
import { selectColAffHorInd } from "@selectors";
import { IconButtonBase } from "@components/Buttons";
import { DateHookFormInput } from "@components/Inputs";
import { HorairesAffectationsCollectivesInput } from "@components/Inputs/Horaires";
import { greyColor, redColor, whiteColor } from "@styles";

const CycleAffectationIndividuelleForm = (props) => {
    const { controlledFields, remove } = props;
    const { control, setValue, clearErrors, watch, reset } = useFormContext();
    const { errors } = useFormState({ control });
    const { field: fieldCycle } = useController({ name: "cycle", control });
    const dateDebut = watch("dateDebut");
    const dateFin = watch("dateFin");
    const horaires = watch("horaires");
    const cycleMin = watch("cycleMin");
    const stateCollaborateurs = useSelector(selectColAffHorInd); 

    useEffect(() => {
        reset();
    }, [reset]);

    const handleChange = useCallback((d) => {
        clearErrors("horaires");
        const horaireCompleted = tidy(horaires, filter((d) => d.horaire));
        if(d) {
            setValue("cycleMin", horaireCompleted.length + 1);
        } else {
            setValue("cycleMin", horaireCompleted.length - 1);
        }
    }, [clearErrors, horaires, setValue]);

    const handleRemove = useCallback((id) => {
        remove(id);
        if(horaires[id]?.horaire) {
            setValue("cycleMin", cycleMin - 1);
        }
    }, [cycleMin, horaires, remove, setValue]);

    return (
        <Stack spacing={1}>
            <DateHookFormInput name="dateDebut" label="Date de début" />
            <DateHookFormInput name="dateFin" label="Date de fin" minDate={dateDebut} displayError />
            <Box bgcolor={redColor[1]} borderRadius={2}>
                <PatternFormat 
                    {...fieldCycle} 
                    fullWidth 
                    format="##" 
                    autoComplete="off" 
                    customInput={TextField} 
                    error={errors["cycle"]}
                    size="small" 
                    label="Cycle" 
                    sx={styles.numberInputStyle}    
                />
                {errors["cycle"] && 
                    <Box borderRadius={2} padding={1}>
                        <Typography sx={styles.typoInputErrorStyle}>{errors["cycle"]?.message}</Typography>
                    </Box>
                }
            </Box>
            {controlledFields.map((field, id) => (
                <Box key={`INPUTGPREG${field.id}`} bgcolor={whiteColor[9]} borderRadius={2} alignItems="center" justifyContent="center">
                    <Grid container>
                        <Grid item xs={controlledFields.length > 1 ? 10 : 12}>
                            <HorairesAffectationsCollectivesInput 
                                name={`horaires.${id}.horaire`} 
                                codeSociete={stateCollaborateurs?.collaborateurSelected?.codeSociete} 
                                date={dateFin} 
                                action={handleChange} 
                            />
                        </Grid>
                        {controlledFields.length > 1 && <Grid item xs={2} display="flex" alignItems="center" justifyContent="center" height={40}>
                            <IconButtonBase 
                                icon={<Close fontSize="small" />} 
                                title="Enlever l'horaire" 
                                colorIconButton={redColor[7]} 
                                action={() => handleRemove(id)} 
                            />
                        </Grid>}
                    </Grid>
                </Box> 
            ))}
            {errors["horaires"] && 
                <Box borderRadius={2} padding={1} bgcolor={redColor[1]}>
                    <Typography sx={styles.typoInputErrorStyle}>{errors["horaires"]?.message}</Typography>
                </Box>
            }
        </Stack>
    );
}

CycleAffectationIndividuelleForm.defaultProps = { 
    controlledFields: [],
    remove: () => {}
}

CycleAffectationIndividuelleForm.propTypes = {
    controlledFields: PropTypes.array,
    remove: PropTypes.func
}

const styles = {
    dateStyle: {
        borderRadius: 2,
        backgroundColor: whiteColor[9],
    },
    numberInputStyle: {
        borderRadius: 2,
        backgroundColor: whiteColor[9],
    },
    boxInputErrorStyle: {
        textAlign: "left",
        borderBottomLeftRadius: 8, 
        borderBottomRightRadius: 8,
        padding: 1,
    },
    typoInputErrorStyle: {
        fontSize: "small", 
        color: greyColor[5],
        fontWeight: "bold"
    }
}

export default CycleAffectationIndividuelleForm;