import { Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { blueColor, getGreenColor, getGreyColor, greenColor, greyColor, redColor, whiteColor, yellowColor } from "@styles";

const palette = {
    "white": { txtColor: getGreyColor("0.59"), bgColor: whiteColor[5], hoverColor: greyColor[0], hoverTxtColor: whiteColor[9] },
    "verywhite": { txtColor: getGreyColor("0.59"), bgColor: whiteColor[9], hoverColor: greyColor[0], hoverTxtColor: whiteColor[9] },
    "verywhite2": { txtColor: getGreyColor("0.59"), bgColor: whiteColor[9], hoverColor: greenColor["1"], hoverTxtColor: whiteColor[9] },
    "verywhite3": { txtColor: getGreyColor("0.59"), bgColor: whiteColor[7], hoverColor: whiteColor[9], hoverTxtColor: getGreyColor("0.59") },
    "green": { txtColor: getGreyColor("0.59"), bgColor: greenColor["02"], hoverColor: greenColor["03"], hoverTxtColor: whiteColor[9] },
    "green01": { txtColor: getGreyColor("0.59"), bgColor: getGreenColor("0.2"), hoverColor: getGreenColor("0.25"), hoverTxtColor: whiteColor[9] },
    "green02": { txtColor: getGreyColor("0.59"), bgColor: getGreenColor("0.22"), hoverColor: getGreenColor("0.27"), hoverTxtColor: whiteColor[9] },
    "green03": { txtColor: getGreyColor("0.59"), bgColor: getGreenColor("0.24"), hoverColor: getGreenColor("0.29"), hoverTxtColor: whiteColor[9] },
    "green04": { txtColor: getGreyColor("0.59"), bgColor: getGreenColor("0.26"), hoverColor: getGreenColor("0.31"), hoverTxtColor: whiteColor[9] },
    "green05": { txtColor: getGreyColor("0.59"), bgColor: getGreenColor("0.28"), hoverColor: getGreenColor("0.33"), hoverTxtColor: whiteColor[9] },
    "green06": { txtColor: getGreyColor("0.59"), bgColor: getGreenColor("0.3"), hoverColor: getGreenColor("0.35"), hoverTxtColor: whiteColor[9] },
    "green07": { txtColor: getGreyColor("0.59"), bgColor: getGreenColor("0.32"), hoverColor: getGreenColor("0.37"), hoverTxtColor: whiteColor[9] },
    "green1": { txtColor: getGreyColor("0.59"), bgColor: getGreenColor("0.2"), hoverColor: getGreenColor("0.25"), hoverTxtColor: whiteColor[9] },
    "green2": { txtColor: getGreyColor("0.59"), bgColor: getGreenColor("0.3"), hoverColor: getGreenColor("0.35"), hoverTxtColor: whiteColor[9] },
    "green3": { txtColor: getGreyColor("0.59"), bgColor: getGreenColor("0.4"), hoverColor: getGreenColor("0.45"), hoverTxtColor: whiteColor[9] },
    "green4": { txtColor: getGreyColor("0.59"), bgColor: getGreenColor("0.5"), hoverColor: getGreenColor("0.55"), hoverTxtColor: whiteColor[9] },
    "green5": { txtColor: getGreyColor("0.59"), bgColor: getGreenColor("0.6"), hoverColor: getGreenColor("0.65"), hoverTxtColor: whiteColor[9] },
    "green6": { txtColor: getGreyColor("0.59"), bgColor: getGreenColor("0.7"), hoverColor: getGreenColor("0.75"), hoverTxtColor: whiteColor[9] },
    "green7": { txtColor: getGreyColor("0.59"), bgColor: getGreenColor("0.8"), hoverColor: getGreenColor("0.85"), hoverTxtColor: whiteColor[9] },
    "yellow": { txtColor: getGreyColor("0.59"), bgColor: yellowColor[2], hoverColor: yellowColor[3], hoverTxtColor: whiteColor[9] },
    "blue": { txtColor: getGreyColor("0.59"), bgColor: blueColor["02"], hoverColor: blueColor["03"], hoverTxtColor: whiteColor[9] },
    "red": { txtColor: getGreyColor("0.59"), bgColor: redColor[1], hoverColor: redColor[2], hoverTxtColor: whiteColor[9] },
    "red2": { txtColor: whiteColor[9], bgColor: redColor[5], hoverColor: redColor[7], hoverTxtColor: whiteColor[9] },
}

const BaseButton = (props) => {
    const { 
        children, 
        iconLeft, 
        iconRight, 
        fontSize,
        size, 
        color, 
        width, 
        font, 
        px, 
        py, 
        textAlign, 
        verticalAlign, 
        horizontalAlign, 
        height, 
        maxHeight, 
        noClick, 
        action 
    } = props;

    return (  
        <Button 
            startIcon={iconLeft} 
            endIcon={iconRight}
            size={size} 
            sx={{ 
                width: width, 
                height: height,
                display: "flex",
                alignItems: verticalAlign,
                justifyContent: horizontalAlign, 
                textAlign: textAlign,
                maxHeight: maxHeight,
                paddingX: px, 
                paddingY: py, 
                borderRadius: 2, 
                boxShadow: "none", 
                color: palette[color].txtColor, 
                backgroundColor: palette[color].bgColor, 
                "&:hover": { 
                    color: palette[color].hoverTxtColor,
                    backgroundColor: palette[color].hoverColor 
                } 
            }} 
            disabled={noClick} 
            onClick={action}
        >
            <Typography sx={{fontSize: fontSize, flexGrow: 1, lineHeight: 1, textAlign: textAlign, fontFamily: font, textTransform: "none"}}>
                { children }
            </Typography>
        </Button>
    );
}

BaseButton.defaultProps = {
    children: <></>, 
    iconLeft: null, 
    iconRight: null, 
    fontSize: "small",
    size: "small", 
    color: "white", 
    width: "100%", 
    font: "", 
    px: 1, 
    py: 1, 
    textAlign: "center", 
    verticalAlign: "center", 
    horizontalAlign: "center", 
    height: "100%", 
    maxHeight: 35.5, 
    noClick: false, 
    action: () => {} 
}

BaseButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]), 
    iconLeft: PropTypes.oneOfType([PropTypes.element, PropTypes.bool, PropTypes.string, PropTypes.array]), 
    iconRight: PropTypes.oneOfType([PropTypes.element, PropTypes.bool, PropTypes.string, PropTypes.array]), 
    fontSize: PropTypes.string, 
    size: PropTypes.string, 
    color: PropTypes.string, 
    width: PropTypes.string, 
    font: PropTypes.string, 
    px: PropTypes.number, 
    py: PropTypes.number, 
    textAlign: PropTypes.string, 
    verticalAlign: PropTypes.string, 
    horizontalAlign: PropTypes.string, 
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), 
    maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), 
    noClick: PropTypes.bool, 
    action: PropTypes.func
}

export default BaseButton;