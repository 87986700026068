import { useDispatch, useSelector } from "react-redux";
import { selectTabApp } from "@selectors";
import * as userActions from "@reducers/user/usrAppReducer";
import * as tabAppActions from "@reducers/tabs/tabAppReducer";
import * as temGesTemColActions from "@reducers/gestionTemps/temps/temGesTemColReducer";
import * as temGesTemManActions from "@reducers/gestionTemps/temps/temGesTemManReducer";
import * as drgGesTemActions from "@reducers/gestionTemps/regularisations/drgGesTemReducer";
import * as regGesTemActions from "@reducers/gestionTemps/regularisations/regGesTemReducer";
import * as colAffActIndActions from "@reducers/collaborateurs/colAffActIndReducer";
import * as colAffActColActions from "@reducers/collaborateurs/colAffActColReducer";
import * as colAffHorIndActions from "@reducers/collaborateurs/colAffHorIndReducer";
import * as colAffHorColActions from "@reducers/collaborateurs/colAffHorColReducer";
import * as colTemGesTemActions from "@reducers/collaborateurs/colTemGesTemReducer";
import * as colPlaGesTemActions from "@reducers/collaborateurs/colPlaGesTemReducer";
import * as colCreGesTemActions from "@reducers/collaborateurs/colCreGesTemReducer";
import * as plaGloActions from "@reducers/planning/plaGloReducer";
import * as dabGesAbsManActions from "@reducers/gestionAbsences/demandes/dabGesAbsManReducer";
import * as delegationActions from "@reducers/delegations/dlgAppReducer";
import * as orgAppActions from "@reducers/organigramme/orgAppReducer";
import { addTabs, getTabs } from "@utils";
import { CODE_ACTION } from "@constants/tabsConstants";

export const useAddTab = (mobile = false) => {
    const stateTabs = useSelector(selectTabApp);
    const dispatch = useDispatch();

    return (action) => {
        if(action === CODE_ACTION.DECONNEXION) {
            dispatch(userActions.logoutUser());
        } else if(action === CODE_ACTION.PERSONNALISER_DASHBOARD) {
            dispatch(userActions.openConfiguration(true));
            dispatch(tabAppActions.setActiveTab(CODE_ACTION.ACCUEIL));
            dispatch(userActions.openMenuParametres(false));
        } else if(action === CODE_ACTION.POLICE) {
            dispatch(userActions.openPolice(true));	
            dispatch(userActions.openMenuParametres(false));
        } else if(action === CODE_ACTION.BACKGROUND) {
            dispatch(userActions.openBackground(true));
            dispatch(userActions.openMenuParametres(false));
        } else if(action === CODE_ACTION.PARAMETRES || action === CODE_ACTION.ABOUT || action === CODE_ACTION.CONFIDENTIALITE) {
            const tabs = addTabs(action, [...stateTabs.tabs]);
            dispatch(tabAppActions.editTabs(tabs));
            dispatch(tabAppActions.setActiveTab(action));
            dispatch(userActions.openMenuParametres(false));
        } else {
            const tabs = addTabs(action, [...stateTabs.tabs]);
            dispatch(tabAppActions.editTabs(tabs));
            dispatch(tabAppActions.setActiveTab(action));
        }
        if(mobile) {
            dispatch(userActions.openMenu(false));
        }
    }
}

export const useRmvTab = () => {
    const stateTabs = useSelector(selectTabApp);
    const dispatch = useDispatch();

    return (e, id, action) => {
        e.preventDefault();
        const { prevTab, tabs } = getTabs(stateTabs.tabs, id);
        dispatch(tabAppActions.closeTab({ prevTab, tabs }));
        if(action === "GT020030") {
            dispatch(temGesTemColActions.reset());
            dispatch(drgGesTemActions.reset());
        }
        if(action === "GT020031") {
            dispatch(temGesTemManActions.reset());
            dispatch(regGesTemActions.reset());
            dispatch(colTemGesTemActions.reset());
            dispatch(colAffActIndActions.reset());
            dispatch(colAffActColActions.reset());
            dispatch(colAffHorColActions.reset());
            dispatch(colAffHorIndActions.reset());
            dispatch(colPlaGesTemActions.reset());
            dispatch(colCreGesTemActions.reset());
        }
        if(action === "PL010063") {
            dispatch(plaGloActions.reset());
        }
        if(action === "AB020003") {
            dispatch(dabGesAbsManActions.reset());
        }
        if(action === "DE010013") {
            dispatch(delegationActions.reset());
        }
        if(action === "OR010065") {
            dispatch(orgAppActions.reset());
        }
    }
}
