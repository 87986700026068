import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Grow, Stack } from "@mui/material";
import { Check } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { selectDrgGesTem } from "@selectors";
import { useDrgGesTemDel } from "@hooks/gestionTemps/demandesRegularisations/hooksQueries";
import { IconButtonBase } from "@components/Buttons";
import { DialogContainer, HeaderDialogContainer, HeaderListContainer, ListContainer, TextContainer } from "@components/Containers";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, redColor, whiteColor, yellowColor } from "@styles";

const headerItems = [
    {id: 1, label: "Date d'émission", xs: 3, display: true},
    {id: 2, label: "Heure d'émission", xs: 3, display: true},
    {id: 3, label: "Date de régularisation", xs: 3, display: true},
    {id: 4, label: "Statut", xs: 3, display: true},
];

const statut = {
    E: {label: "En Cours", color: yellowColor[1]},
    A: {label: "Acceptée", color: greenColor["025"]}, 
    R: {label: "Refusée", color: redColor[1]}
}

const DemandeRegularisationDeleteDialog = (props) => {
    const { open, setOpen, refetch } = props;
    const stateDemandeRegularisation = useSelector(selectDrgGesTem);
    const { isLoading, deleteDemandeRegularisationActionner } = useDrgGesTemDel();

    const sendData = useCallback(async () => {
        const { isSuccess } = await deleteDemandeRegularisationActionner();
        if(isSuccess) {
            refetch();
            setOpen(false);
        }
    }, [deleteDemandeRegularisationActionner, refetch, setOpen]);
    
    return (
        <DialogContainer open={open} taille="sm">
            <HeaderDialogContainer  
                actionsLeft={
                    <IconButtonBase icon={<Check fontSize="small" />} title="Valider" disabled={isLoading} action={() => sendData()} />
                }
                title="Suppression d'une demande de régularisation"
                close={() => setOpen(false)}
            />
            {isLoading ?
            <Box sx={styles.loaderStyle}>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box> 
        :
            <Stack spacing={0.5} bgcolor={redColor[1]} borderRadius={2} p={0.5}>
                <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold">Voulez-vous vraiment supprimer la demande de régularisation ci-dessous ?</TextContainer>
                <HeaderListContainer itemIdReference="DREGULMAN" variant="light" headerItems={headerItems}  />
                <ListContainer spacing={0.5} length={1}>
                    {stateDemandeRegularisation.demandeRegularisationSelected &&
                        <Grow in={true} timeout={600}>
                            <Box bgcolor={whiteColor[5]} borderRadius={2} p={0.25}>
                                <Grid container spacing={0.5}>
                                    <Grid item xs={3}>
                                        <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height={"100%"}>{moment(stateDemandeRegularisation?.demandeRegularisationSelected?.dateDemande, "YYYYMMDD").format("DD/MM/YYYY")}</TextContainer>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height={"100%"}>{moment(stateDemandeRegularisation?.demandeRegularisationSelected?.heureDemande, "Hmmss").format("HH:mm:ss")}</TextContainer>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height={"100%"}>{moment([stateDemandeRegularisation?.demandeRegularisationSelected?.anneeRattachement, stateDemandeRegularisation?.demandeRegularisationSelected?.moisRattachement - 1, stateDemandeRegularisation?.demandeRegularisationSelected?.jourRattachement]).format("DD/MM/YYYY")}</TextContainer>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height={"100%"}>{statut[stateDemandeRegularisation?.demandeRegularisationSelected?.codeStatut] ? statut[stateDemandeRegularisation?.demandeRegularisationSelected?.codeStatut].label : "Non traitée"}</TextContainer>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grow>
                    }
                </ListContainer>
            </Stack>}
        </DialogContainer>
    );
}

DemandeRegularisationDeleteDialog.defaultProps = {
    open: false,
    setOpen: () => {},
    refetch: () => {}
}

DemandeRegularisationDeleteDialog.propTypes = {
    open: PropTypes.bool, 
    setOpen: PropTypes.func, 
    refetch: PropTypes.func
}

const styles = {
    loaderStyle: {
        height: 100,
        alignItems: "center",
        display: "flex"
    }
}

export default DemandeRegularisationDeleteDialog;