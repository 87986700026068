import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";
import { joiResolver } from "@hookform/resolvers/joi";
import PropTypes from "prop-types";
import { schemaFilterDemandeAbsence } from "../validations";
import { selectHisDabGesAbs } from "@selectors";
import * as hisDabGesAbsActions from "@reducers/gestionAbsences/historique/hisDabGesAbsReducer"
import { DateHookFormInput, SelectHookFormInput } from "@components/Inputs";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";
import { filtrerHisDabGesAbs } from "../filtres";
import { useTranslation } from "react-i18next";

const defaultValues = {
    colonne: "",
    operateur: "egale",
    date: null,
    txt: ""
}

const FiltreHistoriqueDemandesForm = (props) => {
    const { setOpen } = props;
    const [types, setTypes] = useState([]);
    const stateHistoriqueAbsence = useSelector(selectHisDabGesAbs); 
    const methods = useForm({ mode: "all", defaultValues: defaultValues, resolver: joiResolver(schemaFilterDemandeAbsence) });
    const colonne = methods.watch("colonne");
    const dispatch = useDispatch();
    const { t } = useTranslation("global");

    const colonneOptions = [
        { id: 1, value: "dateDemande", label: t("texts.request_date") },
        { id: 2, value: "dateAbsence", label: t("texts.absence_date") },
        { id: 4, value: "statut", label: t("texts.status") },
        { id: 5, value: "nature", label: t("texts.nature") },
        { id: 3, value: "type", label: t("texts.request_type") }
    ];

    const operateurOptions = [
        { id: 1, value: "egale", label: t("texts.equal") },
        { id: 2, value: "superieur", label: t("texts.superior") },
        { id: 3, value: "inferieur", label: t("texts.lower") }
    ];

    const optionsNature = [
        { id: 1, value: "Demande d'absence", label: t("titles.absence_request") },
        { id: 2, value: "Demande d'annulation d'absence", label: t("titles.cancellation_absence_request") }
    ];

    const optionsStatut = [
        { id: 1, value: "Acceptée", label: t("texts.accepted") },
        { id: 2, value: "Refusée", label: t("texts.refused") },
        { id: 3, value: "En cours", label: t("texts.in_progress") },
        { id: 4, value: "Non traité", label: t("texts.untreated") },
    ];

    useEffect(() => {
        if(colonne === "dateDemande" || colonne === "dateAbsence") {
            methods.resetField("date", moment());
        }
        if(colonne === "statut" || colonne === "nature" || colonne === "type") {
            methods.resetField("txt", "");
        }
    }, [colonne, methods]);

    useEffect(() => {
        const typesAbsence = [...new Set(stateHistoriqueAbsence.demandesAbsence.map((demandesAbsence) => (JSON.stringify({id: demandesAbsence.codeAbsence, value: demandesAbsence.codeAbsence, label: demandesAbsence.libelleAbsence}))))];
        setTypes(typesAbsence.map((typeAbsence) => (JSON.parse(typeAbsence))));
    }, [stateHistoriqueAbsence.demandesAbsence]);

    const filtrer = useCallback((data) => {
        setOpen(false);
        const historiqueFiltered = filtrerHisDabGesAbs(data, stateHistoriqueAbsence.demandesAbsence);
        dispatch(hisDabGesAbsActions.filtrer(historiqueFiltered));
    }, [dispatch, setOpen, stateHistoriqueAbsence.demandesAbsence]);
    
    return (
        <FormProvider {...methods}>
            <Stack spacing={1}>
                <SelectHookFormInput name="colonne" label={t("texts.columns")} options={colonneOptions} />
                {{
                    dateDemande: 
                        <>
                            <SelectHookFormInput name="operateur" label={t("texts.operator")} options={operateurOptions} />
                            <DateHookFormInput name="date" label={t("texts.request_date")} />
                        </>,
                    dateAbsence: 
                    <>
                        <SelectHookFormInput name="operateur" label={t("texts.operator")} options={operateurOptions} />
                        <DateHookFormInput name="date" label={t("texts.request_date")} />
                    </>,
                    statut: <SelectHookFormInput name="txt" label={t("texts.status")} options={optionsStatut} />,
                    nature: <SelectHookFormInput name="txt" label={t("texts.nature")} options={optionsNature} />,
                    type: <SelectHookFormInput name="txt" label={t("texts.request_type")} options={types} />
                }[colonne]} 
            </Stack>
            <Box sx={styles.boxButtonsMenuFiltre}>
                <Stack direction="row" spacing={1}>
                    <Tooltip title={t("buttons.validate")}>
                        <IconButton sx={styles.buttonStyleGreen} onClick={() => methods.handleSubmit(filtrer)()}>
                            <Check fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t("buttons.close")}>
                        <IconButton sx={styles.buttonStyleRed} onClick={() => setOpen(false)}>
                            <Close fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Box>
        </FormProvider>
    );
}

FiltreHistoriqueDemandesForm.defaultProps = {
    setOpen: () => {}
}

FiltreHistoriqueDemandesForm.propTypes = {
    setOpen: PropTypes.func
}

const styles = {
    boxButtonsMenuFiltre: {
        justifyContent: "center",
        display: "flex"
    },
    buttonStyleGreen: {
        boxShadow: "none", 
        backgroundColor: greenColor["02"],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: greenColor["03"],
            color: whiteColor[9],
        }
    },
    buttonStyleRed: {
        boxShadow: "none", 
        backgroundColor: redColor[1],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: redColor[2],
            color: whiteColor[9],
        }
    }
}

export default FiltreHistoriqueDemandesForm;