import { Stack } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { Loader } from "@components/Loaders";
import { useTraTemGesTem } from "@hooks/gestionTemps/transactions/hooksQueries";
import { useStateTemGesTem } from "@hooks/gestionTemps/temps/hooksStates";
import { DialogContainer, HeaderDialogContainer, HeaderListContainer } from "@components/Containers";
import { TransactionsList } from "@components/App/Transactions";
import { greenColor, greyColor } from "@styles";

const headers = [
    {id: 1, label: "Mouvement", xs: 1.5, display: true},
    {id: 2, label: "Date", xs: 1.5, display: true},
    {id: 3, label: "Heure Réelle", xs: 1.5, display: true},
    {id: 4, label: "Heure Ajustée", xs: 1.5, display: true},
    {id: 5, label: "Lecteur", xs: 1, display: true},
    {id: 6, label: "Activité", xs: 1.75, display: true},
    {id: 7, label: "Type Travail", xs: 2, display: true},
    {id: 8, label: "Anomalie", xs: 1.25, display: true},
];

const TransactionsDialog = (props) => {
    const { open, setOpen, openAnomalies, userType } = props;
    const stateTemps = useStateTemGesTem(userType);
    const jourString = moment(stateTemps.jourSelected, "DDMMYYYY").format("dddd");
    const jour = moment(stateTemps.jourSelected, "DDMMYYYY").format("D");
    const mois = moment(stateTemps.jourSelected, "DDMMYYYY").format("MMMM");
    const annee = moment(stateTemps.jourSelected, "DDMMYYYY").format("YYYY");
    const { data, isLoading, isFetching, isError, error } = useTraTemGesTem(userType, open);

    return (
        <DialogContainer open={open} placement="top" taille="lg">
            <HeaderDialogContainer 
                title={
                    stateTemps.jourSelected ? 
                        `Transactions du ${jourString} ${jour} ${mois} ${annee}`
                    :
                        <Loader size={17} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                    }
                close={() => setOpen(false)}
            />
            <Stack spacing={0.5}>
                <HeaderListContainer itemIdReference="TRTMPS" headerItems={headers} />
                <TransactionsList 
                    data={data} 
                    isLoading={isLoading || isFetching} 
                    isError={isError} 
                    errorMessage={error?.message} 
                    openAnomalies={openAnomalies} 
                />
            </Stack>
        </DialogContainer>
    );
}

TransactionsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    openAnomalies: PropTypes.func.isRequired,
    userType: PropTypes.string.isRequired
}

export default TransactionsDialog;