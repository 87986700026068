import { arrange, asc, desc, tidy } from "@tidyjs/tidy";
import numeral from "numeral";

export const trierComGesTem = (keyTrie, data) => {
    switch(keyTrie) {
        case "designationAsc":
            return tidy(data, arrange([asc("libelle")]));
        case "designationDesc": 
            return tidy(data, arrange([desc("libelle")]));
        case "valeurAsc":
            return tidy(data, arrange((ca, cb) => numeral(ca.valeur.chiffre).value() - numeral(cb.valeur.chiffre).value()));
        case "valeurDesc": 
            return tidy(data, arrange((ca, cb) => numeral(cb.valeur.chiffre).value() - numeral(ca.valeur.chiffre).value()));
        case "maximumAsc": 
            return tidy(data, arrange((ca, cb) => numeral(ca.maximum.chiffre).value() - numeral(cb.maximum.chiffre).value()));
        case "maximumDesc":
            return tidy(data, arrange((ca, cb) => numeral(cb.maximum.chiffre).value() - numeral(ca.maximum.chiffre).value()));
        default: 
            return data;
    }
};
