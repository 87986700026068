import { Box, Grid } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { ItemContainer, ListContainer } from "@components/Containers";
import { Loader } from "@components/Loaders";
import { ErrorBase } from "@components/Errors";
import { greenColor, greyColor } from "@styles";

const AnomaliesList = (props) => {
    const { data, isLoading, isError, errorMessage } = props;

    if(isLoading) {
        return (
            <Box p={2}>
                <Loader size={27} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return <ErrorBase>{errorMessage}</ErrorBase>;
    }
    
    return (
        <PerfectScrollbar>
            <ListContainer spacing={0.5} length={data.length} noDataLabel="Aucune anomalie">
                {data.map((anomalie) => (
                    <Box key={`ANO${anomalie.code}`} bgcolor={greenColor["05"]} borderRadius={2} p={0.3}>
                        <Grid container spacing={0.3}>
                            <ItemContainer firstData data={anomalie.code} xs={2} />
                            <ItemContainer data={anomalie.libelle} xs={8} />
                            <ItemContainer lastData data={anomalie.gravite} xs={2} />
                        </Grid>
                    </Box>  
                ))}
            </ListContainer>
        </PerfectScrollbar>
    )
}  

AnomaliesList.defaultProps = {
    data: [],
    isLoading: true,
    isError: false,
    errorMessage: ""
}

AnomaliesList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.string
}

export default AnomaliesList;