import { useState } from "react";
import { Box, IconButton, Menu, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

const MenuFreeButton = (props) => {
    const { children, title, icon, action } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    return ( 
        <>
            <Tooltip title={title} placement={"bottom"}>
                <Box component="span">
                    <IconButton size={"small"} sx={{ p: 0 }} onClick={(e) => setAnchorEl(e.currentTarget)}>
                        { icon }
                    </IconButton>
                </Box>
            </Tooltip>
            <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)} onClick={action} slotProps={{paper: {
                sx: {
                    borderRadius: 2,
                    overflow: "visible",
                    mt: 0.5,
                    "& .MuiAvatar-root": { width: 32, height: 32, ml: -0.5, mr: 1 },
                }}
            }} MenuListProps={{sx: {padding: 0}}}>
                { children }
            </Menu>
      </> 
    );
}

MenuFreeButton.defaultProps = {
    children: <></>, 
    title: "", 
    icon: <></>, 
    action: () => {} 
}

MenuFreeButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]), 
    title: PropTypes.string, 
    icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]), 
    action: PropTypes.func 
}

export default MenuFreeButton;