import { Stack } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { useAbsJouTemGesTem } from "@hooks/gestionTemps/temps/hooksQueries";
import { useStateTemGesTem } from "@hooks/gestionTemps/temps/hooksStates";
import { Loader } from "@components/Loaders";
import { DialogContainer, HeaderDialogContainer } from "@components/Containers";
import { AbsencesList } from "@components/GestionTemps/Temps/Lists";
import { greenColor, greyColor } from "@styles";

const DetailJourDialog = (props) => {
    const { open, setOpen, userType } = props;
    const stateTemps = useStateTemGesTem(userType);
    const jourString = moment(stateTemps.jourSelected, "DDMMYYYY").format("dddd");
    const jour = moment(stateTemps.jourSelected, "DDMMYYYY").format("D");
    const mois = moment(stateTemps.jourSelected, "DDMMYYYY").format("MMMM");
    const annee = moment(stateTemps.jourSelected, "DDMMYYYY").format("YYYY");
    const { data, isLoading, isFetching, isError, error } = useAbsJouTemGesTem(userType, open);

    return (
        <DialogContainer open={open} taille="sm">
            <HeaderDialogContainer 
                close={() => setOpen(false)}
                title={
                    stateTemps.jourSelected ? 
                        `Détail du ${jourString} ${jour} ${mois} ${annee}`
                    :
                        <Loader size={17} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                }
            />
            <Stack spacing={0.5}>
                <AbsencesList 
                    data={data}
                    isLoading={isLoading || isFetching}
                    isError={isError}
                    errorMessage={error?.message}
                />     
            </Stack>
        </DialogContainer>
    );
}

DetailJourDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    userType: PropTypes.string.isRequired
}

export default DetailJourDialog;