import axios from "axios";
import { urls } from "@constants/appConstants";

export const fetchSocV1 = async (data, client) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetSocietesV1"
    };
    const body = {
        matricule: data.matricule, 
        date: data.date, 
        gestionTemps: data.gestionTemps,
        typePersonnel: data.typePersonnel
    };
    const response = await axios.post(urls.getSocietesV1, body, { headers: headers, params: { client: client } });
    return response.data.societes;
}

export const fetchSocV2 = async (data, client) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetSocietesV2"
    };
    const body = {
        matricule: data.matricule, 
        utilisateur: data.utilisateur, 
        origine: data.origine
    };
    const response = await axios.post(urls.getSocietesV2, body, { headers: headers, params: { client: client } });
    return response.data.societes;
}
