import { Box, Stack } from "@mui/material";
import { Check } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { Loader } from "@components/Loaders";
import { DialogFullScreenContainer, HeaderDialogContainer, TextContainer } from "@components/Containers";
import { greenColor, greyColor, whiteColor, yellowColor } from "@styles";

const PointageDialog = (props) => {
    const { isLoading, dialogType, activite, actionSuivante, open, setOpen, pointer } = props;
  
    return (
        <DialogFullScreenContainer open={open} placement="top" taille="sm">
            <HeaderDialogContainer 
                actionsLeft={
                    <IconButtonBase icon={<Check fontSize="small" />} title="Valider" action={() => pointer()} />
                }
                title="Confirmation du mouvement"
                close={() => setOpen(false)}
            />
            {isLoading ? 
                <Box sx={styles.loaderStyle}><Loader size={17} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} /></Box>
            :
                <Stack spacing={0.5} bgcolor={greenColor["015"]} borderRadius={2} p={0.5}>
                    <TextContainer bgColor={yellowColor[2]} p={1} txtWeight="bold">Validez-vous ce mouvement ?</TextContainer>
                    
                    {{
                        pointage: <TextContainer bgColor={whiteColor[5]} p={1}>{actionSuivante === "E" ? "Entree" : "Départ"} le {moment().format("DD/MM/YYYY")} à {moment().format("HH:mm:ss")}</TextContainer>,
                        activites: 
                            <>
                                <TextContainer bgColor={whiteColor[5]} p={1}>Changement d'activité le {moment().format("DD/MM/YYYY")} à {moment().format("HH:mm:ss")}</TextContainer>
                                <TextContainer bgColor={whiteColor[5]} p={1}>Activite : {activite}</TextContainer>
                            </>
                    }[dialogType]}
                </Stack>
            }
        </DialogFullScreenContainer>
    );
}

PointageDialog.defaultProps = {
    isLoading: false,
    dialogType: "pointage",
    actionSuivante: "",
    open: false, 
    setOpen: () => {}, 
    pointer: () => {}
}

PointageDialog.propTypes = {
    isLoading: PropTypes.bool,
    dialogType: PropTypes.string,
    actionSuivante: PropTypes.string,
    open: PropTypes.bool, 
    setOpen: PropTypes.func, 
    pointer: PropTypes.func
}

const styles = {
    loaderStyle: {
        height: 52,
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
    },
    boxItemStyle: {
        backgroundColor: greyColor[0], 
        borderRadius: 2,
        p:1
    },
    boxWarningItemStyle: {
        backgroundColor: yellowColor[3], 
        borderRadius: 2,
        p:1
    },
}

export default PointageDialog;