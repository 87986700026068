import { useSelector } from "react-redux";
import { Skeleton, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { greenColor } from "@styles";
import { StatutActionsBar, StatutActionsBarMobile } from "../ActionsBar";
import InformationsBar from "./InformationsBar";
import { selectStaUsr } from "@selectors";
import { ErrorBase } from "@components/Errors";
import { useMedia } from "react-use";
import InformationsBarMobile from "./InformationsBarMobile";

const StatutBar = (props) => {
    const { isLoading, isError, handleOpenConfirmationPointage, setOpenActivitesDialog, setOpenInfosDialog } = props;
    const stateStatut = useSelector(selectStaUsr);
    const isMobile = useMedia('(max-width: 650px)');

    if(isLoading) {
        return <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={"100%"} height={56} />;
    }

    if(isError) {
        return <ErrorBase>Erreur lors de la récupération du statut.</ErrorBase>
    }

    return (
        <Stack direction="row" bgcolor={greenColor["015"]} spacing={0.5} borderRadius={2} p={0.5} minHeight={38}>
            {isMobile ? 
                <>
                    <InformationsBarMobile 
                        temps={stateStatut?.statut?.tempsEcoule} 
                        statut={stateStatut?.statut?.statut}
                        dateDernierMouvement={stateStatut?.statut?.dateDernierMouvement}
                        heureDernierMouvement={stateStatut?.statut?.heureDernierMouvement}
                    />
                    <StatutActionsBarMobile 
                        actionSuivante={stateStatut?.statut?.actionSuivante} 
                        handleOpenConfirmationPointage={handleOpenConfirmationPointage} 
                        setOpenActivitesDialog={setOpenActivitesDialog} 
                        setOpenInfosDialog={setOpenInfosDialog}
                    />
                </>
            :
                <>
                    <InformationsBar 
                        temps={stateStatut?.statut?.tempsEcoule} 
                        statut={stateStatut?.statut?.statut}
                        dateDernierMouvement={stateStatut?.statut?.dateDernierMouvement}
                        heureDernierMouvement={stateStatut?.statut?.heureDernierMouvement}
                    />
                    <StatutActionsBar 
                        actionSuivante={stateStatut?.statut?.actionSuivante} 
                        handleOpenConfirmationPointage={handleOpenConfirmationPointage} 
                        setOpenActivitesDialog={setOpenActivitesDialog} 
                    />
                </>
            }
        </Stack>
    );
};

StatutBar.defaultProps = {
    isLoading: true,
    isError: false,
    handleOpenConfirmationPointage: () => {},
    setOpenActivitesDialog: () => {},
    setOpenInfosDialog: () => {}
}

StatutBar.propTypes = {
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    handleOpenConfirmationPointage: PropTypes.func,
    setOpenActivitesDialog: PropTypes.func,
    setOpenInfosDialog: PropTypes.func
}

export default StatutBar;