import { Box, Stack, TextField, Typography } from "@mui/material";
import { useController, useFormContext, useFormState } from "react-hook-form";
import PropTypes from "prop-types";
import { greyColor, redColor, whiteColor } from "@styles";

const NumberHookFormInput = (props) => {
    const { name, label, autoFocus, length, readOnly } = props;
    const { control } = useFormContext();
    const { field } = useController({ name, control });
    const { errors } = useFormState({ control });

    return (  
        <Stack sx={styles.stackInputStyle}>
            <Box sx={styles.boxInputStyle}>
                <TextField 
                    {...field}
                    fullWidth 
                    label={label} 
                    size="small"
                    autoFocus={autoFocus} 
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*", maxLength: length, readOnly: readOnly }}
                />
            </Box>
            {errors[name] && 
                <Box sx={styles.boxInputErrorStyle}>
                    <Typography sx={styles.typoInputErrorStyle}>{errors[name].message}</Typography>
                </Box>
            }
        </Stack>
    );
}

NumberHookFormInput.defaultProps = {
    label: "",
    autoFocus: false, 
    length: 50, 
    readOnly: false
}

NumberHookFormInput.propTypes = {
    name: PropTypes.string.isRequired, 
    label: PropTypes.string, 
    autoFocus: PropTypes.bool, 
    length: PropTypes.number, 
    readOnly: PropTypes.bool, 
    light: PropTypes.bool
}

const styles = {
    stackInputStyle: {
        borderRadius: 2,
        backgroundColor: redColor[1]
    },
    boxInputStyle: {
        borderRadius: 2,
        backgroundColor: whiteColor[9]
    },
    boxInputErrorStyle: {
        textAlign: "left",
        borderBottomLeftRadius: 8, 
        borderBottomRightRadius: 8,
        padding: 1
    },
    typoInputErrorStyle: {
        fontSize: "small", 
        color: greyColor[5]
    }
}

export default NumberHookFormInput;