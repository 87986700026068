import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Grow, Stack, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import moment from "moment";
import { selectEntMan } from "@selectors";
import { DateTimeHookFormInput, SelectHookFormInput, TextHookFormInput } from "@components/Inputs";
import { ResponsablesHookFormInput } from "@components/Inputs/Responsables";
import { DownloadButton } from "@components/Buttons";
import { Loader } from "@components/Loaders";
import { getFilename } from "@utils";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";

const statutsRoute = {
    P: "Planifié",
    E: "Effectué"
}

const optionsStatut = [
    { id: 1, value: "P", label:"Planifié" },
    { id: 2, value: "E", label: "Effectué"}
]

const InformationsForm = ({ 
    edition = false, 
    isLoading = true
}) => {
    const stateEntretiens = useSelector(selectEntMan);
    const entretienSelected = stateEntretiens?.entretienSelected;
    const { watch, setValue, formState, trigger } = useFormContext();
    const commentaire = watch("commentaire");
    const statut = watch("statut");

    useEffect(() => {
        if(statut === "E" && entretienSelected?.statut === "E") {
            setValue("date", moment([entretienSelected?.annee, entretienSelected?.mois - 1, entretienSelected?.jour]));
            setValue("heure", moment([entretienSelected?.annee, entretienSelected?.mois - 1, entretienSelected?.jour, entretienSelected?.heure, entretienSelected?.minute]));
        }
    }, [entretienSelected?.annee, entretienSelected?.heure, entretienSelected?.jour, entretienSelected?.minute, entretienSelected?.mois, entretienSelected?.statut, setValue, statut]);

    useEffect(() => {
        trigger("datetime");
    }, [statut, trigger])

    if(isLoading) {
        return (
            <Box justifyContent="center" display="flex" alignItems="center" height="100%" p={2}>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    return (
        <Stack direction="row" spacing={0.5}>
            <Stack spacing={0.5} width="50%">
                <Grow in={true} timeout={300}>
                    <Stack spacing={0.5} borderRadius={2} bgcolor={greenColor["05"]} p={0.5}>
                        <Box borderRadius={2} bgcolor={whiteColor[7]} pl={1} height={40} display="flex" alignItems="center">
                            <Box flexGrow={1}>
                                <Typography fontSize="small" display="inline" fontWeight="bold">Société : </Typography>
                                <Typography fontSize="small" display="inline">{entretienSelected?.codeSociete} {entretienSelected?.libelleSociete}</Typography>
                            </Box>
                        </Box>
                        <Box borderRadius={2} bgcolor={whiteColor[7]} pl={1} height={40} display="flex" alignItems="center">
                            <Typography fontSize="small">{entretienSelected?.matricule} {entretienSelected?.prenomNom}</Typography>
                        </Box>
                        <Stack direction="row" borderRadius={2} bgcolor={whiteColor[7]} height={40} alignItems="center" px={1}>
                            <Box flexGrow={1}>
                                <Typography fontSize="small" display="inline" fontWeight="bold">Emploi : </Typography>
                                <Typography fontSize="small" display="inline">{entretienSelected?.codeEmploi} {entretienSelected?.libelleEmploi}</Typography>
                            </Box>
                            {entretienSelected?.presencePieceJointeEmploi && 
                                <Box width={30}>
                                    <DownloadButton chemin={entretienSelected?.pieceJointeEmploi} nom={""} />
                                </Box>
                            }
                        </Stack>
                    </Stack>
                </Grow>
                <Grow in={true} timeout={600}>
                    <Stack spacing={0.5} borderRadius={2} bgcolor={greenColor["05"]} p={0.5}>
                        <Box borderRadius={2} bgcolor={whiteColor[7]} pl={1} height={40} display="flex" alignItems="center">
                            <Typography fontSize="small" fontWeight="bold">{entretienSelected?.designation}</Typography>
                        </Box>
                        <Stack direction="row" spacing={0.5}>
                            {(edition && (entretienSelected?.statut === "P" || statut === "P")) ? 
                                <Stack bgcolor={redColor[2]} borderRadius={2} minHeight={40} alignItems="center" width="100%">
                                    <Stack bgcolor={whiteColor[9]} borderRadius={2} width="100%" boxSizing="border-box">
                                        <Box bgcolor={greenColor["05"]} borderRadius={2}>
                                            <Stack bgcolor={whiteColor[7]} borderRadius={2} spacing={0.5} direction="row" pl={1}>
                                                <Box display="flex" alignItems="center">
                                                    <Typography fontSize="small" noWrap fontWeight="bold">Date : </Typography>
                                                </Box> 
                                                <Box width="100%" boxSizing="border-box">
                                                    <DateTimeHookFormInput name="datetime" label="" />
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </Stack>
                                    {formState?.errors["datetime"] && 
                                        <Box sx={{borderBottomLeftRadius: 8, borderBottomRightRadius: 8}} width="100%" p={1} boxSizing="border-box">
                                            <Typography fontSize="small" color={greyColor[5]}>{formState?.errors["datetime"]?.message}</Typography>
                                        </Box>
                                    }
                                </Stack>
                            :
                                <Box borderRadius={2} bgcolor={whiteColor[7]} height={40} px={1} flexGrow={1} flexBasis="100%" display="flex" alignItems="center">
                                    <Box display="inline">
                                        <Typography fontSize="small" display="inline" fontWeight="bold">Date : </Typography>
                                        <Typography fontSize="small" display="inline">{moment([entretienSelected?.annee, entretienSelected?.mois - 1, entretienSelected?.jour]).format("DD/MM/YYYY")}</Typography>
                                    </Box>
                                </Box>
                            }
                            {edition ? 
                                <Box borderRadius={2} bgcolor={whiteColor[7]} height={40} pl={1} flexGrow={1} flexBasis="100%" display="flex" alignItems="center">
                                    <Stack spacing={0.5} direction="row"  width="100%">
                                        <Box display="flex" alignItems="center">
                                            <Typography fontSize="small" noWrap fontWeight="bold">Statut : </Typography>
                                        </Box>
                                        <Box width="100%" boxSizing="border-box">
                                            <SelectHookFormInput name="statut" label="" options={optionsStatut} />
                                        </Box>
                                    </Stack>
                                </Box>
                            :   
                                <Box borderRadius={2} bgcolor={whiteColor[7]} height={40} px={1} flexGrow={1} flexBasis="100%" display="flex" alignItems="center">
                                    <Box display="inline">
                                        <Typography fontSize="small" display="inline" fontWeight="bold">Statut : </Typography>
                                        <Typography fontSize="small" display="inline">{statutsRoute[entretienSelected?.statut]}</Typography>
                                    </Box>
                                </Box>
                            }
                        </Stack>
                        
                        <Stack direction="row" spacing={0.5}>
                            {edition ? 
                                <Stack bgcolor={redColor[2]} borderRadius={2} minHeight={40} alignItems="center" width="100%">
                                    <Stack bgcolor={whiteColor[9]} borderRadius={2} width="100%" boxSizing="border-box">
                                        <Box bgcolor={greenColor["05"]} borderRadius={2}>
                                            <Stack bgcolor={whiteColor[7]} borderRadius={2} spacing={0.5} direction="row" pl={1}>
                                                <Box display="flex" alignItems="center">
                                                    <Typography fontSize="small" noWrap fontWeight="bold">Responsable : </Typography>
                                                </Box>
                                                <Box width="100%" boxSizing="border-box">
                                                    <ResponsablesHookFormInput name="interlocuteur" label="" />
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </Stack>
                                    {formState?.errors["interlocuteur"] && 
                                        <Box sx={{borderBottomLeftRadius: 8, borderBottomRightRadius: 8}} width="100%" p={1} boxSizing="border-box">
                                            <Typography fontSize="small" color={greyColor[5]}>{formState?.errors["interlocuteur"]?.message}</Typography>
                                        </Box>
                                    }
                                </Stack>
                            :  
                            <Box borderRadius={2} bgcolor={whiteColor[7]} p={1} flexGrow={1} flexBasis="100%" display="flex" alignItems="center">
                                <Box display="inline">
                                    <Typography fontSize="small" display="inline" fontWeight="bold">Responsable : </Typography>
                                    <Typography fontSize="small" display="inline">{entretienSelected?.prenomNomInterlocuteur}</Typography>
                                </Box>
                            </Box>
                            }
                        </Stack>
                        <Box>
                            {edition ? 
                                <Box borderRadius={2} bgcolor={whiteColor[7]} height={40} pl={1} flexGrow={1} flexBasis="100%" display="flex" alignItems="center">
                                    <Stack spacing={0.5} direction="row"  width="100%">
                                        <Box display="flex" alignItems="center">
                                            <Typography fontSize="small" noWrap fontWeight="bold">Initiative : </Typography>
                                        </Box>
                                        <Box width="100%" boxSizing="border-box">
                                            <TextHookFormInput name="initiative" maxLength={60} />
                                        </Box>
                                    </Stack>
                                </Box>
                            :   
                                <Box borderRadius={2} bgcolor={whiteColor[7]} height={40} px={1} flexGrow={1} flexBasis="100%" display="flex" alignItems="center">
                                    <Box display="inline">
                                        <Typography fontSize="small" display="inline" fontWeight="bold">Initiative : </Typography>
                                        <Typography fontSize="small" display="inline">{entretienSelected?.initiative}</Typography>
                                    </Box>
                                </Box>
                            }
                        </Box>
                        <Box>
                            {edition ? 
                                <Box borderRadius={2} bgcolor={whiteColor[7]} height={40} pl={1} flexGrow={1} flexBasis="100%" display="flex" alignItems="center">
                                    <Stack spacing={0.5} direction="row"  width="100%">
                                        <Box display="flex" alignItems="center">
                                            <Typography fontSize="small" noWrap fontWeight="bold">Sujet : </Typography>
                                        </Box>
                                        <Box width="100%" boxSizing="border-box">
                                            <TextHookFormInput name="sujet" maxLength={80} />
                                        </Box>
                                    </Stack>
                                </Box>
                            :   
                                <Box borderRadius={2} bgcolor={whiteColor[7]} height={40} px={1} flexGrow={1} flexBasis="100%" display="flex" alignItems="center">
                                    <Box display="inline">
                                        <Typography fontSize="small" display="inline" fontWeight="bold">Sujet : </Typography>
                                        <Typography fontSize="small" display="inline">{entretienSelected?.sujet}</Typography>
                                    </Box>
                                </Box>
                            }
                        </Box>
                        {entretienSelected?.piecesJointes?.length > 0 &&
                            <>
                                <Box borderRadius={2} bgcolor={whiteColor[7]} pl={1} height={40} display="flex" alignItems="center">
                                    <Typography fontSize="small" display="inline" fontWeight="bold">Pièces Jointes</Typography>
                                </Box>
                                {entretienSelected?.piecesJointes?.map((pieceJointe) => (
                                    <Stack key={`PJ${pieceJointe.id}`} direction="row" borderRadius={2} bgcolor={whiteColor[7]} height={40} alignItems="center" px={1}>
                                        <Box flexGrow={1}>
                                            <Typography fontSize="small" display="inline">{getFilename(pieceJointe?.pieceJointe)}</Typography>
                                        </Box>
                                        <Box width={30}>
                                            <DownloadButton chemin={pieceJointe?.pieceJointe} nom={getFilename(pieceJointe?.pieceJointe)} />
                                        </Box>
                                    </Stack>
                                ))}
                            </>
                        }
                    </Stack>
                </Grow>
            </Stack>
            <Grow in={true} timeout={900}>
                <Stack spacing={0.5} borderRadius={2} bgcolor={greenColor["05"]} p={0.5} width="50%">
                    <Box borderRadius={2} bgcolor={whiteColor[7]} p={1}>
                        <Typography fontSize="small" display="inline" fontWeight="bold">Commentaires et observations</Typography>
                    </Box>
                    {edition ? 
                        <Stack spacing={0.5} borderRadius={2} bgcolor={whiteColor[7]} p={0.5} height="100%">
                            <Box>
                                <TextHookFormInput name="commentaire" multiline maxLength={800} />
                            </Box>
                            <Box>
                                <Typography fontSize="small" fontWeight="bold" textAlign="right">{commentaire.length}/800</Typography>
                            </Box>
                        </Stack>
                    :
                        <Stack spacing={0.5} borderRadius={2} bgcolor={whiteColor[7]} p={1} height="100%" whiteSpace={"pre-wrap"}>
                            <Typography fontSize="small">
                                {entretienSelected?.commentaire}
                            </Typography>
                        </Stack>
                    }
                </Stack>
            </Grow>
        </Stack>
    )
}

InformationsForm.propTypes = {
    edition: PropTypes.bool,
    isLoading: PropTypes.bool
}

export default InformationsForm;