import { useEffect, useState } from "react";
import { Person } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { Buffer } from "buffer";

const PhotoCollaborateur = ({ photo, nom, variant = "rounded", buffered = true }) => {
    const [initials, setInitials] = useState("");
    const [photoBase64, setPhotoBase64] = useState("");

    useEffect(() => {
        const initPhoto = () => {
            const prenomNom = nom.split(" ");
            if(prenomNom.length > 1) {
                setInitials(prenomNom[0][0]+prenomNom[1][0]);
            } else {
                setInitials("");
            }
            if(photo && buffered) {
                setPhotoBase64(Buffer.from(photo, "base64"));
            } else {
                setPhotoBase64(photo);
            }
        }
        initPhoto();
    }, [buffered, nom, photo]);

    return (  
        photo ? 
            <Avatar variant={variant} alt={`${initials}`} sx={styles.avatarStyle} src={`data:image/jpg;base64,${photoBase64}`} />
        :
            <Avatar variant={variant} alt={`No photo ${initials}`} sx={styles.avatarStyle}>
                <Person fontSize="large" />
            </Avatar>
    );
}

const styles = {
    avatarStyle: { 
        width: 70, 
        height: 70 
    }
};

export default PhotoCollaborateur;