import { useCallback, useState } from "react";
import { Typography, Box, Stack } from "@mui/material";
import { useController, useFormContext, useFormState } from "react-hook-form";
import { DatePicker, LocalizationProvider, frFR } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import PropTypes from "prop-types";
import { greyColor, redColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const DateHookFormInput = (props) => {
    const { name, label, minDate, defaultMonth, readOnly, displayError, action } = props;
    const { control } = useFormContext();
    const { field } = useController({ name, control });
    const { errors } = useFormState({ control });
    const [open, setOpen] = useState(false);
    const { t } = useTranslation("global");

    const handleChange = useCallback((date) => {
        action(date)
        field.onChange(date)
    }, [action, field])

    return (
        <Stack sx={styles.stackInputStyle}>
            <Box sx={styles.boxInputStyle}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"fr"} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <DatePicker 
                        {...field}
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        label={label} 
                        format="DD/MM/YYYY" 
                        minDate={minDate} 
                        views={["year", "month", "day"]}
                        defaultCalendarMonth={defaultMonth}
                        slotProps={{ 
                            textField: { 
                                fullWidth: true,
                                size: "small", 
                                readOnly: readOnly, 
                                error: errors[name],
                                onClick: () => setOpen(true) 
                            }
                        }}
                        onChange={handleChange}
                    />
                </LocalizationProvider>
            </Box>
            {(errors[name] && displayError) && 
                <Box sx={styles.boxInputErrorStyle}>
                    <Typography sx={styles.typoInputErrorStyle}>{t(errors[name].message)}</Typography>
                </Box>
            }
        </Stack>
    );
}

DateHookFormInput.defaultProps = {
    minDate: null,
    defaultMonth: null,
    readOnly: false,
    displayError: false,
    action: () => {}
}

DateHookFormInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    minDate: PropTypes.object,
    defaultMonth: PropTypes.object,
    readOnly: PropTypes.bool,
    displayError: PropTypes.bool,
    action: PropTypes.func
}

const styles = {
    stackInputStyle: {
        borderRadius: 2,
        backgroundColor: redColor[1]
    },
    boxInputStyle: {
        borderRadius: 2,
        backgroundColor: whiteColor[9]
    },
    boxInputErrorStyle: {
        textAlign: "left",
        borderBottomLeftRadius: 8, 
        borderBottomRightRadius: 8,
        padding: 1
    },
    typoInputErrorStyle: {
        fontSize: "small", 
        color: greyColor[5]
    }
}

export default DateHookFormInput;