import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useStatePlanning } from "@hooks/planning/hooksStates";
import { TextContainer } from "@components/Containers";
import { getGreyColor, greenColor, greyColor, whiteColor } from "@styles";

const EmetteurMobile = (props) => {
    const { type, openJour } = props;
    const statePlanning = useStatePlanning(type);

    return (  
        <Stack spacing={0.5} bgcolor={whiteColor[5]} borderRadius={2} p={0.5}>
            {statePlanning?.emetteur && 
                <>
                    <TextContainer bgColor={greenColor["015"]} p={1} tooltipPlacement="top" height={"100%"} verticalAlign="center" textSize="small" textWeight="bold">
                        {statePlanning.emetteur.prenomNom}
                    </TextContainer>
                    <Box>
                        <Grid container spacing={0.5}>
                            {statePlanning?.emetteur?.zones?.map((zone) => (
                                <Grid item xs={1.5} key={`ZES${zone.id}`}>
                                    {!zone.demande ? 
                                        <TextContainer bgColor={whiteColor[5]} p={1} height="100%" verticalAlign="center" textSize={11} horizontalAlign="center">
                                            {zone.zone}
                                        </TextContainer>
                                    :
                                        <Button 
                                            size={"small"} 
                                            sx={{ 
                                                minWidth: 0,
                                                width: "100%",
                                                height: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center", 
                                                textAlign: "center",
                                                borderRadius: 2, 
                                                boxShadow: "none", 
                                                color: getGreyColor("0.59"), 
                                                backgroundColor: whiteColor[5], 
                                                "&:hover": { 
                                                    color: whiteColor[9],
                                                    backgroundColor: greyColor[0] 
                                                } 
                                            }} 
                                            onClick={() => {openJour(zone.id, statePlanning?.emetteur?.matricule, statePlanning?.emetteur?.prenomNom)}}
                                        >
                                            <Typography sx={{fontSize: 11, flexGrow: 1, lineHeight: 1, textAlign: "center", textTransform: "none"}}>
                                                {zone.zone}
                                            </Typography>
                                        </Button>                                        
                                    }
                                </Grid>
                            ))}
                            <Grid item xs={1.5}>
                                <TextContainer bgColor={whiteColor[5]} p={1} height="100%" verticalAlign="center" horizontalAlign="center" textSize={11} textWeight="bold">
                                    {statePlanning?.emetteur?.totalHeuresSemaine}
                                </TextContainer>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            }
        </Stack>
    );
}

EmetteurMobile.propTypes = {
    type: "",
    openJour: () => {}
}

EmetteurMobile.propTypes = {
    type: PropTypes.string, 
    openJour: PropTypes.func
}

export default EmetteurMobile;