import { Box, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { PlanningFormMobile } from "@components/GestionTemps/Planning/Forms";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, whiteColor } from "@styles";
import { PlanningFooterTableMobile } from "./Footer";

const PlanningTableMobile = (props) => {
    const { isLoading, totalHeuresSemaine, totalHeuresAbsence, modelize } = props;

    return (
        <Stack spacing={0.5} borderRadius={2} height="100%">
            {isLoading ? 
                <Box sx={styles.loaderStyle}>
                    <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                </Box>
            :
                <Stack spacing={0.5}>
                    <PlanningFormMobile modelize={modelize} />
                </Stack> 
            }
            <PlanningFooterTableMobile 
                isLoading={isLoading} 
                totalHeuresSemaine={totalHeuresSemaine} 
                totalHeuresAbsence={totalHeuresAbsence} 
            />
        </Stack>
    );
}

PlanningTableMobile.defaultProps = {
    totalHeuresSemaine: "",
    totalHeuresAbsence: ""
}

PlanningTableMobile.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    totalHeuresSemaine: PropTypes.string,
    totalHeuresAbsence: PropTypes.string,
    modelize: PropTypes.func.isRequired
}

const styles = {
    stackStyleTable: {
        bgcolor: whiteColor[5],
        borderRadius: 2,
        padding: 0.5,
        position: "relative",
        maxHeight: "calc(100% - 97.5px)",
        boxSizing: "border-box"
    },
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        height: "calc(100% - 53px)",
    },
};

export default PlanningTableMobile;