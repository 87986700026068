import { Drawer, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { whiteColor } from "@styles";

const MenuFiltreContainerMobile = (props) => {
    const { children, open, placement, close } = props;

    return (
        <Drawer 
            variant="temporary" 
            open={open} 
            onClose={close} 
            anchor={placement} 
            PaperProps={{ 
                sx: { 
                    width: "100%", 
                    maxHeight: "80%", 
                    backgroundColor: "transparent", 
                    p: 1, 
                    boxSizing: "border-box", 
                    boxShadow: "none" 
                }
            }}
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 100
            }}
        >
            <Stack 
                spacing={1} 
                height="100%"
                bgcolor={whiteColor[9]} 
                overflow="auto"  
                borderRadius={2} 
                p={1} 
                boxSizing="border-box"
            >
                {children}
            </Stack>
        </Drawer>
    );
}

MenuFiltreContainerMobile.defaultProps = {
    children: <></>,
    open: false,
    placement: "bottom",
    close: () => {}
}

MenuFiltreContainerMobile.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType, PropTypes.array]),
    open: PropTypes.bool,
    placement: PropTypes.string,
    close: PropTypes.func
}

export default MenuFiltreContainerMobile;