import { Stack } from "@mui/material";
import { Event, FilterList, MobiledataOff, SwapVert } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { useResetTrieCollaborateurs, useSelectDateCollaborateurs } from "@hooks/collaborateurs/hooksUtils";
import { DatePickerLabelizedButton, IconButtonBase } from "@components/Buttons";

const CollaborateursFwgtpeActions = ({ 
    stateType = "", 
    dateValidite = "", 
    isLoading = false, 
    tried = false, 
    displayDate = false,
    toggleTrie = () => {}, 
    setOpenFiltre = () => {}
}) => {
    const resetTrie = useResetTrieCollaborateurs(stateType);
    const selectDate = useSelectDateCollaborateurs(stateType);

    return (
        <Stack direction="row" spacing={0.5}>
            <IconButtonBase variant="white5Grey9White9" icon={tried ? <MobiledataOff fontSize="small" /> : <SwapVert fontSize="small" />} disabled={isLoading} title={tried ? "Enlever le trie" : "Trier"} action={tried ? resetTrie : toggleTrie} />
            <IconButtonBase variant="white5Grey9White9" icon={<FilterList fontSize="small" />} disabled={isLoading} title="Filtrer" action={() => setOpenFiltre((prevOpen) => !prevOpen)} />
            {(displayDate) && 
                <DatePickerLabelizedButton 
                    icon={<Event fontSize="small" />} 
                    title="Choisir une date" 
                    noClick={isLoading} 
                    date={(dateValidite && displayDate) ? moment(dateValidite, "DDMMYYYY").format("DD/MM/YYYY") : ""} 
                    variant="white2Grey9White9"
                    action={selectDate} 
                />
            }
        </Stack>
    );
}

CollaborateursFwgtpeActions.propTypes = {
    stateType: PropTypes.string,
    dateValidite: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isLoading: PropTypes.bool,
    tried: PropTypes.bool,
    displayDate: PropTypes.bool,
    toggleTrie: PropTypes.func,
    setOpenFiltre: PropTypes.func,
}

export default CollaborateursFwgtpeActions;