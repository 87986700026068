import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    notificationDemandesAbsence: false,
    notificationDemandesRegularisation: false
}

const notAppSlice = createSlice({
    name: 'notApp',
    initialState: initialState,
    reducers: {
        setNotifications: (state, action) => {
            state.notificationDemandesAbsence = action.payload.notificationDemandesAbsence;
            state.notificationDemandesRegularisation = action.payload.notificationDemandesRegularisation
        },
        setNotificationDemandesAbsence: (state, action) => {
            state.notificationDemandesAbsence = action.payload;
        },
        setNotificationDemandesRegularisation: (state, action) => {
            state.notificationDemandesRegularisation = action.payload
        },
        reset: () => initialState,
    },
})

const { actions, reducer } = notAppSlice
export const { setNotifications, setNotificationDemandesAbsence, setNotificationDemandesRegularisation, reset } = actions
export default reducer