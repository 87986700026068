import { Grid, Grow, Stack, Box, Typography } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Loader } from "@components/Loaders";
import { HeaderListContainer, ItemContainer, ListContainer, PanelListContainer, TextWithLabelContainer } from "@components/Containers";
import { DownloadButton, IconButtonBase } from "@components/Buttons";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";
import { useMedia } from "react-use";
import { Refresh } from "@mui/icons-material";

const headerItems = [
    {id: 1, label: "Date", xs: 1.5, display: true, firstData: true, lastData: false},
    {id: 2, label: "Nature", xs: 1, display: true, firstData: false, lastData: false},
    {id: 3, label: "Nom", xs: 8.5, display: true, firstData: false, lastData: true},
];

const DocumentsList = ({ 
    data = [], 
    isLoading = true, 
    isError = false, 
    listed = false,
    refetch = () => {} 
}) => {
    const isMobile = useMedia('(max-width: 750px)');

    if(isLoading) {
        return (
            <Box  height="100%" justifyContent="center" display="flex" alignItems="center">
                <Loader size={27} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return (
            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center"> 
                <Stack direction="row" spacing={2} bgcolor={redColor[5]} py={1} px={2} borderRadius={2} alignItems="center">
                    <Typography fontSize="small" fontWeight="bold" color={whiteColor[9]}>
                        Erreur lors de la récupération des données
                    </Typography>
                    <IconButtonBase 
                        variant="white5Grey9White9"
                        icon={<Refresh fontSize="small" />}
                        title="Rafraichir"
                        action={() => refetch()}
                    />
                </Stack>
            </Box>
        );
    }

    return (
        (listed && !isMobile) ?
            <Stack spacing={0.5} height="calc(100% - 50px)"> 
                <HeaderListContainer itemIdReference="DOCLIST" headerItems={headerItems} />
                <PerfectScrollbar>
                    <ListContainer spacing={0.5} length={data.length} noDataLabel="Aucun document">
                        {data.map((document, id) => (
                            <Grow key={`DOCLIST${document.id}`} in={true} timeout={400+(200*id)}>
                                <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.3}>
                                    <Grid container spacing={0.3}>
                                        <ItemContainer firstData data={moment(document.dateDocument, "DDMMYYYY").format("DD/MM/YYYY")} xs={1.5} />
                                        <ItemContainer data={document.natureDocument} xs={1} />
                                        <ItemContainer lastData data={document.nom} xs={8.5} />
                                        <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
                                            <DownloadButton variant="listButton" chemin={document.chemin} nom={document.nom} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grow>
                        ))}
                    </ListContainer>
                </PerfectScrollbar>
            </Stack>
        :
            <PerfectScrollbar>
                <PanelListContainer length={data.length}>
                    {data.map((document, id) => (
                        <Grow key={`LDOC${document.id}`} in={true} timeout={400+(200*id)}>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
                                <Stack spacing={0.5} bgcolor={greenColor["05"]} padding={0.5} borderRadius={2}>
                                    <Box borderRadius={2} p={1} bgcolor={whiteColor[7]}>
                                        <Stack spacing={0}>
                                            <TextWithLabelContainer label="Date" value={moment(document.dateDocument, "DDMMYYYY").format("DD/MM/YYYY")} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Nature" value={document.natureDocument} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Nom" value={document.nom} weight={{label: "bold", value: "normal"}} />
                                        </Stack>
                                    </Box>
                                    <Box borderRadius={2} height={35.5} display="flex" alignItems="center" justifyContent="center" bgcolor={whiteColor[7]}>
                                        <DownloadButton variant="panelButton" chemin={document.chemin} nom={document.nom} />
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grow>
                    ))}
                </PanelListContainer>   
            </PerfectScrollbar> 
    );
}

DocumentsList.propTypes = {
    data: PropTypes.array, 
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool, 
    listed: PropTypes.bool,
    refetch: PropTypes.func
}

export default DocumentsList;