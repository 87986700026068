import { Box, Grid, Stack, Typography } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { blueColor, whiteColor } from "@styles";
import { useStatePlanning } from "@hooks/planning/hooksStates";
import { useMedia } from "react-use";
import { useTranslation } from "react-i18next";

const variants = {
    planning: { bg1: whiteColor[5], bg2: blueColor["015"], bg3: whiteColor[5], bg4: whiteColor[5] },
    absenceManager: { bg1: whiteColor[7], bg2: blueColor["05"], bg3: whiteColor[7], bg4: whiteColor[7] }
}

const formateJourLong = (jour) => {
    const momentDate = moment(jour.date, "YYYYMMDD");
    const libelleLong = momentDate.format("dddd")[0].toUpperCase() + momentDate.format("dddd").substring(1);
    return libelleLong;
};

const formateJourCourt = (jour) => {
    const momentDate = moment(jour.date, "YYYYMMDD");
    const libelleCourt = momentDate.format("dd")[0].toUpperCase() + momentDate.format("dd").substring(1);
    return libelleCourt;
};

const Header = ({ 
    type = "", 
    variant = "planning",
    displayEmetteur = true,
    jourCourt = false 
}) => {
    const statePlanning = useStatePlanning(type);
    const isLarge = useMedia('(min-width: 1146px)');
    const { t } = useTranslation("global");

    return (
        <Box>
            {statePlanning?.semaine && 
                <Grid container spacing={0.3}>
                    <Grid item xs={2.25}>
                        <Box bgcolor={variants[variant]?.bg1} sx={{ borderTopLeftRadius: 8, borderBottomLeftRadius: displayEmetteur ? 0 : 8 }} p={1} height="100%" boxSizing="border-box" display="flex" alignItems="center" justifyContent="left">
                            <Typography fontSize="small" fontWeight="bold">
                                {t("calendar.week_string", { count: 1 })} {statePlanning?.semaine?.numeroSemaine} {t("texts.of_year")} {statePlanning?.semaine?.anneeSemaine}
                            </Typography>
                        </Box>
                    </Grid>
                    {statePlanning?.semaine?.jours?.map((jour) => (
                        <Grid item xs={1.25} key={`JP${jour.id}`}>
                            <Stack direction={isLarge ? "row" : "column"} spacing={0.5} bgcolor={jour.libelleFerie ? variants[variant]?.bg2 : variants[variant]?.bg3} height="100%" display="flex" alignItems="center" justifyContent="center">
                                <Box>
                                    <Typography fontSize="small" fontWeight="bold">{jourCourt ? formateJourCourt(jour) : formateJourLong(jour)} </Typography>
                                </Box>
                                <Box>
                                    <Typography fontSize="small">{moment(jour.date, "YYYYMMDD").format("DD/MM")} </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                    ))}
                    <Grid item xs={1}>
                        <Box bgcolor={variants[variant]?.bg1} sx={{ borderTopRightRadius: 8, borderBottomRightRadius: displayEmetteur ? 0 : 8 }} p={1} height="100%" boxSizing="border-box" display="flex" alignItems="center" justifyContent="center">
                            <Typography fontSize="small" fontWeight="bold">{t("texts.total")}</Typography>
                        </Box>
                    </Grid>
                </Grid>  
            }
        </Box>
    );
}

Header.propTypes = {
    type: PropTypes.string,
    variant: PropTypes.string,
    displayEmetteur: PropTypes.bool,
    jourCourt: PropTypes.bool
}

export default Header;