import { arrange, asc, desc, tidy } from "@tidyjs/tidy";
import moment from "moment";

export const trierEntMan = (keyTrie, data) => {
    switch(keyTrie) {
        case "dateAsc":
            return tidy(data, arrange((da, db) => moment([da.annee, da.mois - 1, da.jour]).utc() - moment([db.annee, db.mois - 1, db.jour]).utc()));
        case "dateDesc": 
            return tidy(data, arrange((da, db) => moment([db.annee, db.mois - 1, db.jour]).utc() - moment([da.annee, da.mois - 1, da.jour]).utc()));
        case "societeAsc":
            return tidy(data, arrange(asc("libelleSociete")));
        case "societeDesc": 
            return tidy(data, arrange(desc("libelleSociete")));
        case "matriculeAsc":
            return tidy(data, arrange(asc("matricule")));
        case "matriculeDesc": 
            return tidy(data, arrange(desc("matricule")));
        case "nomAsc": 
            return tidy(data, arrange(asc("prenomNom")));
        case "nomDesc":
            return tidy(data, arrange(desc("prenomNom")));
        case "statutAsc": 
            return tidy(data, arrange(asc("statut")));
        case "statutDesc":
            return tidy(data, arrange(desc("statut")));
        default: 
            return data;
    }
};

export const trierEntCol = (keyTrie, data) => {
    switch(keyTrie) {
        case "dateAsc":
            return tidy(data, arrange((da, db) => moment([da.annee, da.mois - 1, da.jour]).utc() - moment([db.annee, db.mois - 1, db.jour]).utc()));
        case "dateDesc": 
            return tidy(data, arrange((da, db) => moment([db.annee, db.mois - 1, db.jour]).utc() - moment([da.annee, da.mois - 1, da.jour]).utc()));
        case "nomAsc": 
            return tidy(data, arrange(asc("prenomNom")));
        case "nomDesc":
            return tidy(data, arrange(desc("prenomNom")));
        case "statutAsc": 
            return tidy(data, arrange(asc("statut")));
        case "statutDesc":
            return tidy(data, arrange(desc("statut")));
        default: 
            return data;
    }
};
