import { TextContainer } from "@components/Containers";
import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { datify } from "@utils";
import { TextHookFormInput } from "@components/Inputs";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const DemandesAnnulationForm = (props) => {
    const { data, isLoading } = props;
    const { t } = useTranslation("global");

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    return (
        <Box>
            <Stack spacing={1}>
                <Stack spacing={0.5} bgcolor={greenColor["05"]} p={0.5} borderRadius={2}>
                    <TextContainer bgColor={whiteColor[7]} p={1} textWeight="bold">{t("texts.absence")} : {data?.codeAbsence} {data?.libelleAbsence}</TextContainer>
                    {data?.nombreJours > 1 ? 
                        <Box bgcolor={whiteColor[7]} p={1} borderRadius={2}>
                            <Typography fontWeight="bold" fontSize="small">{t("texts.start_date_absence")} : {datify(data?.dateDebut, "ddddDDmmmmyyyyw")}</Typography>
                            <Typography fontWeight="bold" fontSize="small">{t("texts.end_date_absence")}: {datify(data?.dateReprise, "ddddDDmmmmyyyyw")}</Typography>
                        </Box>
                    :
                        <Box bgcolor={whiteColor[7]} p={1} borderRadius={2}>
                            <Typography fontWeight="bold" fontSize="small">{t("texts.absence_date")} : {datify(data?.dateDebut, "ddddDDmmmmyyyyw")}</Typography>
                            <Typography fontWeight="bold" fontSize="small">{t("texts.number_hours")} : {data?.nombreHeures}</Typography>
                        </Box>
                    }
                </Stack>
                <Box>
                    <TextHookFormInput name="motif" label={t("texts.request_reason")} autoFocus={true} maxLength={50} />
                </Box>
            </Stack>  
        </Box>
    );
}

DemandesAnnulationForm.defaultProps = {
    data: null,
    isLoading: true
}

DemandesAnnulationForm.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool
}

const styles = {
    loaderStyle: {
        height: 100,
        alignItems: "center",
        display: "flex"
    },
}


export default DemandesAnnulationForm;