import { Box, Stack } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { PlanningHeaderTable, PlanningFooterTable } from "@components/GestionTemps/Planning/Tables";
import { PlanningForm } from "@components/GestionTemps/Planning/Forms";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, whiteColor } from "@styles";

const PlanningTable = (props) => {
    const { isLoading, totalHeuresSemaine, totalHeuresAbsence, modelize } = props;

    return (
        <Stack spacing={0.5} bgcolor={whiteColor[5]} borderRadius={2} p={0.5} height={"calc(100% - 66px)"}>
            {isLoading ? 
                <Box sx={styles.loaderStyle}>
                    <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                </Box>
            :
                <>
                    <PlanningHeaderTable />
                    <PerfectScrollbar>
                        <Stack spacing={0.5} overflow={"auto"}>
                            <PlanningForm modelize={modelize} />
                        </Stack> 
                    </PerfectScrollbar>
                </>
            }
            <PlanningFooterTable isLoading={isLoading} totalHeuresSemaine={totalHeuresSemaine} totalHeuresAbsence={totalHeuresAbsence} />
        </Stack>
    );
}

PlanningTable.defaultProps = {
    totalHeuresSemaine: "",
    totalHeuresAbsence: ""
}

PlanningTable.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    totalHeuresSemaine: PropTypes.string,
    totalHeuresAbsence: PropTypes.string,
    modelize: PropTypes.func.isRequired
}

const styles = {
    stackStyleTable: {
        bgcolor: whiteColor[5],
        borderRadius: 2,
        padding: 0.5,
        position: "relative",
        maxHeight: "calc(100% - 97.5px)",
        boxSizing: "border-box"
    },
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        height: "calc(100% - 53px)",
    },
};

export default PlanningTable;