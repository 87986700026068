import { urls } from "@constants/appConstants";
import axios from "axios";

export const fetchAffHor = async (codeSociete, jourEffet, moisEffet, anneeEffet, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetHorairesV1"
    };
    const body = {
        codeSociete: codeSociete,
        jourEffet: jourEffet,
        moisEffet: moisEffet,
        anneeEffet: anneeEffet
    }
    const response = await axios.post(urls.getHorairesV1, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.horaires;
}

export const fetchHor = async (body, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetHorairesV1"
    };
    const response = await axios.post(urls.getHorairesV1, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.horaires;
}
