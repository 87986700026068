import { validateDate, validateHeureString } from "@validation";
import Joi from "joi";

export const schemaFilterDrgGesTemCol = Joi.object({
    colonne: Joi.string().required().messages({ "string.empty": "Vous devez choisir un colonne pour filtrer" }),
    operateur: Joi
        .when("colonne", {is: "date", then: Joi.string()})
        .when("colonne", {is: "heure", then: Joi.string()}),
    date: Joi
        .when("colonne", {is: "date", then: validateDate.dateMoment().valide().messages({ "dateMoment.valide": "Date invalide" })}),
    txt: Joi    
        .when("colonne", {is: "heure", then: validateHeureString.heureMoment().valide().messages({ "heureMoment.valide": "Date invalide" })})
        .when("colonne", {is: "motif", then: Joi.string().required().messages({ "string.empty": "Vous devez entrer une valeur pour filtrer" })})
        .when("colonne", {is: "statut", then: Joi.string().required().messages({ "string.empty": "Vous devez choisir une valeur pour filtrer" })})
});

export const schemaFilterDrgGesTemMan = Joi.object({
    colonne: Joi.string().required().messages({ "string.empty": "Vous devez choisir un colonne pour filtrer" }),
    operateur: Joi
        .when("colonne", {is: "date", then: Joi.string()})
        .when("colonne", {is: "heure", then: Joi.string()}),
    date: Joi
        .when("colonne", {is: "date", then: validateDate.dateMoment().valide().messages({ "dateMoment.valide": "Date invalide" })}),
    txt: Joi    
        .when("colonne", {is: "heure", then: validateHeureString.heureMoment().valide().messages({ "heureMoment.valide": "Date invalide" })})
        .when("colonne", {is: "motif", then: Joi.string().required().messages({ "string.empty": "Vous devez entrer une valeur pour filtrer" })})
        .when("colonne", {is: "statut", then: Joi.string().required().messages({ "string.empty": "Vous devez choisir une valeur pour filtrer" })})
        .when("colonne", {is: "emetteur", then: Joi.string().required().messages({ "string.empty": "Vous devez choisir une valeur pour filtrer" })})
});