import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { ArrowLeft, Check, Info } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import moment from "moment";
import { selectEntMan, selectEntSecMan } from "@selectors";
import { usePostSousSection } from "@hooks/gestionEntretiens/hooksQueries";
import { useChampDate, useChampEvaluation, useMappingActions } from "@hooks/gestionEntretiens/hooksUtils";
import { IconButtonBase } from "@components/Buttons";
import { Loader } from "@components/Loaders";
import { ChampCommentaire, ChampDate, ChampEvaluation, ChampsComplementaires } from "./Champs";
import { CompetenceDialog } from "@components/GestionEntretiens/Manager/Dialogs";
import { schemaSousSection } from "@components/GestionEntretiens/validations";
import { greenColor, greyColor, whiteColor } from "@styles";

const defaultValues = {
    complement1: "",
    complement2: "",
    complement3: "",
    complement4: "",
    evaluation: "",
    date: null,
    commentaire: "",
    displayEvaluation: false,
    displayDate: false
}

const CompetenceForm = ({ 
    open = false, 
    goBack = () => {} 
}) => {
    const [openCompetence, setOpenCompetence] = useState(false);
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;
    const methods = useForm({ mode: "onSubmit", defaultValues: defaultValues, resolver: zodResolver(schemaSousSection) })
    const { displayE: displayEE } = useChampEvaluation();
    const { displayE: displayED } = useChampDate();  
    const { designation1, designation2 } = useMappingActions()
    const { isLoadingSousSection, postSousSection } = usePostSousSection(); 

    useEffect(() => {
        const champComplementaire1 = sectionSelected?.["contenuComplement1"] || "";
        const champComplementaire2 = sectionSelected?.["contenuComplement2"] || "";
        const champComplementaire3 = sectionSelected?.["contenuComplement3"] || "";
        const champComplementaire4 = sectionSelected?.["contenuComplement4"] || "";
        methods.setValue("complement1", champComplementaire1);
        methods.setValue("complement2", champComplementaire2);
        methods.setValue("complement3", champComplementaire3);
        methods.setValue("complement4", champComplementaire4);
        if(displayEE) {
            if(sectionSelected?.evaluation) {
                const evaluation = { value: sectionSelected?.evaluation, label: `${sectionSelected?.evaluation} - ${sectionSelected?.designationEvaluation}`, classement: sectionSelected?.classementEvaluation }
                methods.setValue("evaluation", JSON.stringify(evaluation));
            }
            methods.setValue("displayEvaluation", true);
        } else {
            methods.resetField("evaluation");
            methods.setValue("displayEvaluation", false);
        }
        if(displayED) {
            const date = sectionSelected?.date === "0" ?  moment([stateEntretiens?.entretienSelected?.annee, stateEntretiens?.entretienSelected?.mois - 1, stateEntretiens?.entretienSelected?.jour]) : moment(sectionSelected?.date, "DDMMYYYY");
            methods.setValue("date", date);
            methods.setValue("displayDate", true);
        } else {
            methods.resetField("date");
            methods.setValue("displayDate", false);
        }
    }, [displayED, displayEE, methods, sectionSelected, stateEntretiens?.entretienSelected?.annee, stateEntretiens?.entretienSelected?.jour, stateEntretiens?.entretienSelected?.mois]);

    const validate = useCallback(async (dataForm) => {
        await postSousSection(dataForm);
        goBack();
    }, [goBack, postSousSection]);

    if(isLoadingSousSection) {
        return (
            <>
                <Box borderRadius={2} bgcolor={greenColor["015"]} height={50} p={0.5}>
                    <Skeleton variant="rounded" animation="wave" width="100%" height="100%" sx={{borderRadius: 2}} />
                </Box>
                <Box justifyContent="center" display="flex" alignItems="center" height="calc(100vh - 140px)" p={2}>
                    <Box bgcolor={whiteColor[5]} borderRadius={2} width={50} height={50} justifyContent="center" display="flex" alignItems="center">
                        <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                    </Box>
                </Box>
            </>
        );
    }
    
    return (
        <FormProvider {...methods}>
            <Box borderRadius={2} bgcolor={greenColor["015"]} height={50} p={0.5}>
                <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[5]} height="100%" alignItems="center" px={1}>
                    <IconButtonBase icon={<ArrowLeft fontSize="small" />} title="Retour" action={() => goBack()} />
                    <Box flexGrow={1}>
                        <Typography fontSize="small" fontWeight="bold">{designation1}</Typography>
                        <Typography fontSize="small" fontWeight="bold">{designation2}</Typography>
                    </Box>
                    <IconButtonBase icon={<Info fontSize="small" />} title="Informations compétence" action={() => setOpenCompetence(true)} />
                    {stateEntretiens?.entretienSelected?.statut === "P" && 
                        <IconButtonBase icon={<Check fontSize="small" />} title="Valider" action={() => methods.handleSubmit(validate)()} />
                    }
                </Stack>
            </Box>
            <Box bgcolor={greenColor["015"]} borderRadius={2} p={0.5}>
                <Box borderRadius={2} bgcolor={whiteColor[5]} p={1}>
                    <Typography fontSize={"small"} fontWeight={"bold"}>{sectionSelected?.designationCompetence}</Typography>
                </Box>
            </Box>
            <PerfectScrollbar> 
                <Stack spacing={0.5}>
                    <ChampsComplementaires />
                    <ChampEvaluation />
                    <ChampDate />
                    <ChampCommentaire open={open} codeQuestion="0" />
                </Stack>
            </PerfectScrollbar>
            <CompetenceDialog open={openCompetence} setOpen={setOpenCompetence} />
        </FormProvider>
    );
}

CompetenceForm.propTypes = {
    open: PropTypes.bool,
    goBack: PropTypes.func
}

export default CompetenceForm;