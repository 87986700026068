import PropTypes from "prop-types";
import { useCntPla } from "@hooks/planning/hooksQueries";
import { DialogContainer } from "@components/Containers";
import ContrainteContents from "./Contents/ContrainteContents";
import { useStatePlanning } from "@hooks/planning/hooksStates";
import { ContrainteActions } from "./Actions";

const ContrainteDialog = (props) => {
    const { type, open, setOpen } = props;
    const statePlanning = useStatePlanning(type);    
    const { data, isLoading, isFetching, isError, error } = useCntPla(statePlanning?.emetteur?.societe.trim(), statePlanning?.emetteur?.service.trim(), open);

    return (
        <DialogContainer open={open} taille="sm">
            <ContrainteActions 
                service={statePlanning?.emetteur?.service}
                societe={statePlanning?.emetteur?.societe}
                setOpen={setOpen}
            />
            <ContrainteContents 
                data={data}
                isLoading={isLoading || isFetching}
                isError={isError}
                errorMessage={error?.message}
            />
        </DialogContainer>
    );
}

ContrainteDialog.defaultProps = {
    type: "",
    open: false, 
    setOpen: () => {}
}

ContrainteDialog.propTypes = {
    type: PropTypes.string,
    open: PropTypes.bool, 
    setOpen: PropTypes.func
}

export default ContrainteDialog;