import { useState } from "react";
import { Event } from "@mui/icons-material";
import { DatePicker, LocalizationProvider, frFR } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import PropTypes from "prop-types";
import IconDatePickerButton from "./IconDatePickerButton";

const DatePickerButton = ({ 
    icon = <Event fontSize="small" />, 
    title = "", 
    placement = "bottom", 
    variant = "white5Grey9White9", 
    value = null, 
    noClick = false, 
    action = () => {} 
}) => {
    const [open, setOpen] = useState(false);

    return (  
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"frFR"} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
            <DatePicker
                slots={{ field: IconDatePickerButton }}
                slotProps={{ field: { icon, title, placement, variant, noClick, setOpen } }}
                open={open}
                value={value}
                views={["year", "month", "day"]}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                onAccept={action}
            />
        </LocalizationProvider>
    );
}

DatePickerButton.propTypes = {
    icon: PropTypes.element, 
    title: PropTypes.string, 
    placement: PropTypes.string, 
    variant: PropTypes.string, 
    value: PropTypes.object, 
    noClick: PropTypes.bool, 
    action: PropTypes.func
}

export default DatePickerButton;