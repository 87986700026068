import { Box, Dialog, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { whiteColor } from "@styles";

const DialogFullScreenContainer = (props) => {
    const { children, open, bgColor, paddingBox, paddingStack } = props;

    return (  
        <Dialog open={open} PaperProps={{sx: {bgcolor: "rgba(0,0,0,0)", backdropFilter: "blur(5px)"}}} fullScreen>
            <Box padding={paddingBox}>
                <Stack spacing={1} padding={paddingStack} bgcolor={bgColor} borderRadius={2}>
                    {children} 
                </Stack>
            </Box>
        </Dialog>
    );
}

DialogFullScreenContainer.defaultProps = {
    bgColor: whiteColor[9],
    paddingBox: 1,
    paddingStack: 1
}

DialogFullScreenContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.array]).isRequired,
    open: PropTypes.bool.isRequired,
    bgColor: PropTypes.string,
    paddingBox: PropTypes.number,
    paddingStack: PropTypes.number
}

export default DialogFullScreenContainer;