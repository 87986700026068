import { useEffect, useState } from "react";
import { Avatar, Box, Stack, Tooltip, Typography } from "@mui/material";
import { PersonOff, PhotoCamera } from "@mui/icons-material";
import PropTypes from "prop-types";
import PhotoCollaborateur from "@components/App/PhotoCollaborateur";
import { DialogContainer, HeaderDialogContainer, TextContainer } from "@components/Containers";
import { greenColor, whiteColor } from "@styles";

const ItemDialog = ({ title, data }) => {
    const [emptyData, setEmptyData] = useState(false);

    useEffect(() => {
        let compteur = 0;
        data.forEach(element => {
            if(element.value) {
                compteur++;
            }
        });
        compteur ? setEmptyData(false) : setEmptyData(true);
    }, [data]);

    return (
        !emptyData &&
            <Stack spacing={0.5} borderRadius={2} bgcolor={greenColor["015"]} p={0.5}>
                <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold" horizontalAlign="center">{title}</TextContainer>
                <Box bgcolor={whiteColor[5]} p={1} borderRadius={2}>
                    <Stack direction="row" spacing={2}>
                        <Stack>
                            {data.map((data) => (
                                data.value && <Box key={`TITLE${data.id}`}>
                                    <Typography fontSize="small" fontWeight="bold">{data.title}</Typography>
                                </Box>
                            ))}
                        </Stack>
                        <Stack>
                            {data.map((data) => (
                                data.value && <Box key={`VAL${data.id}`}>
                                    <Typography fontSize="small">{data.value}</Typography>
                                </Box>
                            ))}
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
    );
}

const ItemSituation = ({ title, data }) => {
    return (
        data && (
            <Stack spacing={0.5} borderRadius={2} bgcolor={greenColor["015"]} p={0.5}>
                <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold" horizontalAlign="center">{title}</TextContainer>
                <Box bgcolor={whiteColor[5]} p={1} borderRadius={2}>
                    <Stack direction="row" spacing={2}>
                        <TextContainer bgColor={null} p={1} textWeight="bold" horizontalAlign="center">{data.libelleTitulaire}</TextContainer>
                        <Stack>
                            <Box display="inline-flex" alignItems="center">
                                <TextContainer bgColor={null} p={1}>{data.prenomNomTitularisation}</TextContainer>
                                <Tooltip placement="top" title={data.prenomNomTitularisation ? <PhotoCollaborateur photo={data.photoTitulaire} nom={data.prenomNomTitularisation} buffered={false} /> : <Avatar variant={"rounded"} alt={`No photo poste vacant`} sx={styles.avatarStyle}><PersonOff fontSize="large" /></Avatar>}>
                                    <PhotoCamera fontSize="small" />
                                </Tooltip>
                            </Box>
                            {data.prenomNomOccupation && <TextContainer bgColor={null} p={1}>{data.libelleOccupation}</TextContainer>}
                            {data.prenomNomOccupation && <Box display="inline-flex" alignItems="center">
                                <TextContainer bgColor={null} p={1}>{data.prenomNomOccupation}</TextContainer>
                                <Tooltip placement="top" title={data.prenomNomOccupation ? <PhotoCollaborateur photo={data.photoOccupant} nom={data.prenomNomOccupation} buffered={false} /> : <Avatar variant={"rounded"} alt={`No photo poste vacant`} sx={styles.avatarStyle}><PersonOff fontSize="large" /></Avatar>}>
                                    <PhotoCamera fontSize="small" />
                                </Tooltip>
                            </Box>}
                        </Stack>
                    </Stack>
                </Box>
            </Stack>)
    );
}

const DetailPosteDialog = (props) => {
    const { open, setOpen, poste } = props;
    
    return (
        <DialogContainer open={open} placement="top" taille="md">
            <HeaderDialogContainer 
                close={() => setOpen(false)}
                title="Détail du poste"
            />
            <Stack spacing={0.5}>
                <Box>
                    <ItemDialog title="Description" data={[
                        {id: "DES1", title: "Poste", value: `${poste?.codePoste} ${poste?.intituleOrganigramme}`}, 
                        {id: "DES2", title: "Niveau", value: poste?.niveau}, 
                        {id: "DES3", title: "Emploi", value: poste?.libelleEmploi}, 
                        {id: "DES4", title: "Titre ou Fonction", value: poste?.libelleFonction}, 
                    ]} />
                </Box>
                <Box>
                    <ItemSituation title="Situation" data={{
                        libelleTitulaire: poste?.libelleTitularisation, 
                        prenomNomTitularisation: poste?.prenomNomTitularisation, 
                        libelleOccupation: poste?.libelleOccupation, 
                        prenomNomOccupation: poste?.prenomNomOccupation,
                        photoTitulaire: poste?.photoMatricule,
                        photoOccupant: poste?.photoAutreMatricule
                    }} />
                </Box>
                <Box>
                    <ItemDialog title="Contact" data={[
                        {id: "CO1", title: "Téléphone fixe", value: poste?.numeroFixe}, 
                        {id: "CO2", title: "Téléphone mobile", value: poste?.numeroMobile}, 
                        {id: "CO3", title: "Télécopie", value: poste?.numeroFax}, 
                        {id: "CO4", title: "Adresse e-mail", value: poste?.mail}, 
                    ]} />
                </Box>
                <Box>
                    <ItemDialog title="Organisation" data={[
                        {id: "ORG1", title: "Direction", value: poste?.designationDirection}, 
                        {id: "ORG2", title: "Responsable", value: poste?.responsableDirection}, 
                        {id: "ORG3", title: "Service", value: poste?.libelleService}, 
                        {id: "ORG4", title: "Responsable", value: poste?.responsableService}, 
                        {id: "ORG5", title: "Secteur", value: poste?.designationSecteur}, 
                        {id: "ORG6", title: "Responsable", value: poste?.responsableSecteur}, 
                    ]} />
                </Box>
            </Stack>
        </DialogContainer>
    );
}

DetailPosteDialog.defaultProps = {
    open: false, 
    setOpen: () => {}, 
    poste: null
}

DetailPosteDialog.propTypes = {
    open: PropTypes.bool, 
    setOpen: PropTypes.func, 
    poste: PropTypes.object
}

const styles = {
    titleBoxStyle: {
        backgroundColor: greenColor["05"],
        borderRadius: 2,
        padding: 1,
    },
    itemListStyle: {
        backgroundColor: greenColor["02"],
        borderRadius: 2,
        padding: 1
    },
    avatarStyle: { 
        width: 70, 
        height: 70 
    }
}

export default DetailPosteDialog;