import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { fetchAno, fetchAnoTraGesTem, downloadAnoAct, downloadAnoCre, downloadAno } from "@api/anomalies";
import { selectCltApp, selectUsrApp } from "@selectors";
import { formToDataAnomalies, formToDataAnomaliesActivite, formToDataAnomaliesCredit } from "@integrations";
import { generateTableurAnomalie, generateTableurAnomalieActivite, generateTableurAnomalieCredit } from "@tableurs";
import { ROLE_UTILISATEUR } from "@constants/userConstants";

export const useFetchAnoGesTem = (open) => {
    const stateClient = useSelector(selectCltApp);

    return useQuery(['anoGesTem'], ({ signal }) => fetchAno(stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open
    });
}

export const useFetchAnoTraGesTem = (userType, enable) => {
    const stateClient = useSelector(selectCltApp);
    const stateUser = useSelector(selectUsrApp);
    const matricule = stateUser.matricule;

    return useQuery(['anoTraGesTem', matricule], ({ signal }) => fetchAnoTraGesTem(matricule, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!matricule && enable && userType === ROLE_UTILISATEUR.COLLABORATEUR
    });
}

export const useDownloadAnoGesTemBas = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const droitSalarie = stateUser.droitSalarie ? "O" : "N";
    const droitInterimaire = stateUser.droitInterimaire ? "O" : "N";
    const { isLoading, mutateAsync } = useMutation(downloadAno);
    const { enqueueSnackbar } = useSnackbar();

    const downloadActionner = async (data) => {
        const formatedData = formToDataAnomalies(data, stateUser.utilisateurAS400, stateUser.matricule, droitSalarie, droitInterimaire, stateClient.client);
        try {
            const response = await mutateAsync(formatedData);
            generateTableurAnomalie("Anomalies Gestion du Temps", response);
        } catch(e) {
            if(e?.response?.data?.details) {
                enqueueSnackbar(e?.response?.data?.details, { variant: "error" });
            } else {
                enqueueSnackbar("Erreur inconnue", { variant: "error" });
            }
        }
    };

    return { isLoading, downloadActionner };
}

export const useDownloadAnoGesTemCre = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const droitSalarie = stateUser.droitSalarie ? "O" : "N";
    const droitInterimaire = stateUser.droitInterimaire ? "O" : "N";
    const { isLoading, mutateAsync } = useMutation(downloadAnoCre);
    const { enqueueSnackbar } = useSnackbar();

    const downloadActionner = async (data) => {
        const formatedData = formToDataAnomaliesCredit(data, stateUser.utilisateurAS400, stateUser.matricule, droitSalarie, droitInterimaire, stateClient.client);
        try {
            const response = await mutateAsync(formatedData);
            generateTableurAnomalieCredit("Anomalies Crédit", response);
        } catch(e) {
            if(e?.response?.data?.details) {
                enqueueSnackbar(e?.response?.data?.details, { variant: "error" });
            } else {
                enqueueSnackbar("Erreur inconnue", { variant: "error" });
            }
        }
    };

    return { isLoading, downloadActionner };
}

export const useDownloadAnoGesTemAct = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const droitSalarie = stateUser.droitSalarie ? "O" : "N";
    const droitInterimaire = stateUser.droitInterimaire ? "O" : "N";
    const { isLoading, mutateAsync } = useMutation(downloadAnoAct);
    const { enqueueSnackbar } = useSnackbar();

    const downloadActionner = async (data) => {
        const formatedData = formToDataAnomaliesActivite(data, stateUser.utilisateurAS400, stateUser.matricule, droitSalarie, droitInterimaire, stateClient.client);
        try {
            const response = await mutateAsync(formatedData);
            generateTableurAnomalieActivite("Anomalies Gestion du Temps", response);
        } catch(e) {
            if(e?.response?.data?.details) {
                enqueueSnackbar(e?.response?.data?.details, { variant: "error" });
            } else {
                enqueueSnackbar("Erreur inconnue", { variant: "error" });
            }
        }
    };

    return { isLoading, downloadActionner };
}
