import { Box, Stack, Typography } from "@mui/material";
import { AccessTime, Info } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { DialogFullScreenContainer, HeaderDialogContainer } from "@components/Containers";
import { greenColor } from "@styles";

const InformationsDialogMobile = (props) => {
    const { open, setOpen, temps, statut, dateDernierMouvement, heureDernierMouvement } = props;
  
    return (
        <DialogFullScreenContainer open={open} placement="top" taille="sm">
            <HeaderDialogContainer 
                title="Informations"
                close={() => setOpen(false)}
            />
            <Stack spacing={2} direction="row" bgcolor={greenColor["015"]} borderRadius={2} p={1} alignItems="center" flexGrow={4}>
                <Info fontSize="small" />
                <Box>
                    {statut === "A" ? 
                        <Typography fontSize="small" fontWeight="bold">Dernier mouvement le {moment(dateDernierMouvement, "DDMMYYYY").format("DD/MM/YYYY")} à {moment(heureDernierMouvement, "HHmm").format("HH[h]mm")}</Typography>
                    :
                        <Typography fontSize="small" fontWeight="bold">A débuté le {moment(dateDernierMouvement, "DDMMYYYY").format("DD/MM/YYYY")} à {moment(heureDernierMouvement, "HHmm").format("HH[h]mm")}</Typography>
                    }
                </Box>
            </Stack>
            {(moment(temps, "HH[h]mm")?.isValid()) && 
                <Stack spacing={2} direction="row" bgcolor={greenColor["015"]} borderRadius={2} p={1} alignItems="center" flexGrow={2}>
                    <AccessTime fontSize="small" />
                    <Box>
                        {moment(temps, "HH[h]mm")?.isValid() && <Typography fontSize="small" fontWeight="bold">Temps écoulé : {moment(temps, "HH[h]mm")}</Typography>}
                    </Box>
                </Stack>
            }
        </DialogFullScreenContainer>
    );
}

InformationsDialogMobile.defaultProps = {
    open: false, 
    setOpen: () => {}, 
    temps: "", 
    statut: "", 
    dateDernierMouvement: "", 
    heureDernierMouvement: ""
}

InformationsDialogMobile.propTypes = {
    open: PropTypes.bool, 
    setOpen: PropTypes.func, 
    temps: PropTypes.string, 
    statut: PropTypes.string, 
    dateDernierMouvement: PropTypes.string, 
    heureDernierMouvement: PropTypes.string
}

export default InformationsDialogMobile;