import { ChevronLeft, ChevronRight, Event, Hub, ListAlt, OfflinePin, Refresh, Share, SquareFoot } from "@mui/icons-material";
import PropTypes from "prop-types";
import { DatePickerButton, IconButtonBase } from "@components/Buttons";
import { HeaderPanelContainer } from "@components/Containers";

const PlanningPanelActions = ({ 
    isLoading = true, 
    isError = false, 
    allServices = true,
    affichage = "V",
    precedent = () => {},
    suivant = () => {},
    selectDate = () => {},
    setOpenContraintes = () => {},
    toggleAffichage = () => {},
    toggleAll = () => {},
    refetch = () => {} 
}) => {
    return (
        <HeaderPanelContainer
            actionsLeft={
                <>
                    <IconButtonBase 
                        variant="green2Grey9Green5"
                        icon={<ChevronLeft fontSize="small" />} 
                        title="Semaine Précédente" 
                        disabled={isLoading || isError} 
                        action={() => precedent()} 
                    />
                    <IconButtonBase
                        variant="green2Grey9Green5" 
                        icon={<ChevronRight fontSize="small" />} 
                        title="Semaine Suivante" 
                        disabled={isLoading || isError} 
                        action={() => suivant()} 
                    />
                    <DatePickerButton 
                        icon={<Event fontSize="small" />} 
                        title="Choisir une date" 
                        timeout={600} 
                        noClick={isLoading || isError} 
                        variant="green2Grey9Green5"
                        action={selectDate} 
                    />
                </>
            } 
            actionsRight={
                <>
                    {!allServices && 
                        <IconButtonBase 
                            variant="green2Grey9Green5"
                            icon={<SquareFoot fontSize="small" />} 
                            title="Afficher les contraintes" 
                            disabled={isLoading || isError} 
                            action={() => setOpenContraintes(true)} 
                        />
                    }
                    <IconButtonBase 
                        variant="green2Grey9Green5"
                        icon={affichage === "T" ? <OfflinePin fontSize="small" /> : <ListAlt fontSize="small" />} 
                        title={affichage === "T" ? "Absences validées" : "Toutes les absences"} 
                        disabled={isLoading || isError} 
                        action={() => toggleAffichage()} 
                    />
                    <IconButtonBase 
                        variant="green2Grey9Green5"
                        icon={allServices ? <Share fontSize="small" /> : <Hub fontSize="small" />} 
                        title={allServices ? "Un service" : "Tous les services"} 
                        disabled={isLoading || isError} 
                        action={() => toggleAll()} 
                    />
                    <IconButtonBase 
                        variant="green2Grey9Green5"
                        icon={<Refresh fontSize="small" />} 
                        title="Rafraichir" 
                        action={() => refetch()} 
                    />
                </>
            }
            title="Planning"
            variant="white08"
        />
    )
}

PlanningPanelActions.defaultProps = {
    isLoading: true, 
    isError: false,
    allServices: true,
    affichage: "V",
    precedent: () => {},
    suivant: () => {},
    selectDate: () => {},
    setOpenContraintes: () => {},
    toggleAffichage: () => {},
    toggleAll: () => {},
    refetch: () => {}
}

PlanningPanelActions.propTypes = {
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool,
    allServices: PropTypes.bool,
    affichage: PropTypes.string,
    precedent: PropTypes.func,
    suivant: PropTypes.func,
    selectDate: PropTypes.func,
    setOpenContraintes: PropTypes.func,
    toggleAffichage: PropTypes.func,
    toggleAll: PropTypes.func,
    refetch: PropTypes.func
}

export default PlanningPanelActions;