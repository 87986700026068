import { useEffect } from "react";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { useFormContext, useFormState } from "react-hook-form";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";
import { CheckHookFormInput, DateHookFormInput, NumberHookFormInput, RadiosHookFormInput } from "@components/Inputs";
import { Loader } from "@components/Loaders";
import { DelegatairesHookFormInput } from "@components/Inputs/Delegataires";

const options = [
    {id: 1, value: "immediate", label: "Immédiate"},
    {id: 2, value: "delaiAttente", label: "Après un délai d'attente"}
]

const DelegationForm = (props) => {
    const { isLoading, isLoadingDelegataire, mode } = props;
    const { control, watch, setValue } = useFormContext();
    const { errors } = useFormState({ control });
    const dateDebut = watch("dateDebut")
    const typeDelegation = watch("typeDelegation");

    useEffect(() => {
        if(typeDelegation === "immediate") {
            setValue("delaiAttente", "");
        }
    }, [setValue, typeDelegation])

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    return (
        <Stack spacing={0.5}>
            <Stack spacing={0.5} bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                <Box bgcolor={whiteColor[7]} borderRadius={2} p={1}>
                    <Typography fontSize="small" fontWeight="bold" textAlign="center">Nature de la délégation</Typography>
                </Box>
                <Box bgcolor={whiteColor[7]} borderRadius={2} p={0.5}>
                    <Stack direction="row" spacing={0.5}>
                        <Box flexGrow={1} justifyContent="center" display="flex">
                            <CheckHookFormInput name="absence" label="Temps et absences" noClick={mode !== "ajout"} />
                        </Box>
                        <Box flexGrow={1} justifyContent="center" display="flex">
                            <CheckHookFormInput name="formation" label="Formation" noClick={mode !== "ajout"} />
                        </Box>
                        <Box flexGrow={1} justifyContent="center" display="flex">
                            <CheckHookFormInput name="noteFrais" label="Frais et ordres de mission" noClick={mode !== "ajout"} />
                        </Box>
                    </Stack>
                </Box>
                {(errors["absence"] && errors["formation"] && errors["noteFrais"]) && 
                    <Box sx={styles.itemInterneErrorBoxStyle}>
                        <Typography fontSize="small" color={greyColor[5]}>{errors["absence"]?.message}</Typography>
                    </Box>
                }
            </Stack>
            <Stack spacing={0.5} bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                <Box bgcolor={whiteColor[7]} borderRadius={2} p={1}>
                    <Typography fontSize="small" fontWeight="bold" textAlign="center">Période de la délégation</Typography>
                </Box>
                <Box>
                    <Stack direction="row" spacing={0.5}>
                        <Box flexGrow={1}>
                            {mode === "edition" ? 
                                <DateHookFormInput name="dateDebut" label={"Début de la délégation"} displayError />
                            :
                                <DateHookFormInput name="dateDebut" minDate={moment().startOf("day")} label={"Début de la délégation"} displayError />
                            }
                        </Box>
                        <Box flexGrow={1}>
                            <DateHookFormInput name="dateReprise" minDate={dateDebut} defaultMonth={dateDebut} label={"Fin de la délégation"} displayError />
                        </Box>
                    </Stack>
                </Box>
            </Stack>
            <Stack spacing={0.5} bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                <Box bgcolor={whiteColor[7]} borderRadius={2} p={1}>
                    <Typography fontSize="small" fontWeight="bold" textAlign="center">Type de la délégation</Typography>
                </Box>
                <Stack direction="row" spacing={0.5}>
                    <Box flexGrow={1}>
                        <Box bgcolor={whiteColor[7]} borderRadius={2} height="100%" display="flex" justifyContent="center">
                            <RadiosHookFormInput name="typeDelegation" options={options} row={true} />
                        </Box>
                    </Box>
                    {typeDelegation !== "immediate" &&
                        <Box flexGrow={1} display="flex" alignItems="center">
                            <Box width="100%">
                                <NumberHookFormInput name="delaiAttente" label="Délai d'attente (jours)" length={2} />
                            </Box>
                        </Box>
                    }
                    <Box flexGrow={1}>
                        <Box bgcolor={whiteColor[7]} p={0.5} borderRadius={2} height="100%" boxSizing="border-box" display="flex" justifyContent="center">
                            <CheckHookFormInput name="avertissementMail" label="Être informé par mail" />
                        </Box>
                    </Box>
                </Stack>
                {(errors["delegationImmediate"] && errors["delegationDelaiAttente"]) && 
                    <Box sx={styles.itemInterneErrorBoxStyle}>
                        <Typography color="white" fontSize="small" fontWeight="bold">{errors["delegationImmediate"].message}</Typography>
                    </Box>
                }
            </Stack>
            <Stack spacing={0.5} bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                <Box bgcolor={whiteColor[7]} borderRadius={2} p={1}>
                    <Typography fontSize="small" fontWeight="bold" textAlign="center">Délégataire</Typography>
                </Box>
                {(mode === "edition" && isLoadingDelegataire) ? 
                    <Box>
                        <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={"100%"} height={40} />
                    </Box>
                :
                    <DelegatairesHookFormInput name="destinataire" label="Délégataire" />
                }
            </Stack>
        </Stack>
    );
}

DelegationForm.defaultProps = {
    isLoading: false,
    isLoadingDelegataire: true,
    mode: "ajout"
}

DelegationForm.propTypes = {
    isLoading: PropTypes.bool,
    isLoadingDelegataire: PropTypes.bool,
    mode: PropTypes.string
}

const styles = {
    titleBoxStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.5)",
        borderRadius: 2,
        paddingX: 2,
        paddingY: 2
    },
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        height: "100%",
    },
    subContainerStyle: {
        bgcolor: greenColor["01"],
        padding: 1,
        alignItems: "center",
        position: "relative",
        borderRadius: 2,
        display: "flex"
    },
    panelGridStyle: {
        height: "100%"
    },
    skeletonStyle: {
        borderRadius: 1
    },
    itemInterneTitleBoxStyle: {
        backgroundColor: whiteColor[5],
        borderRadius: 2,
        padding: 1
    },
    itemInterneErrorBoxStyle: {
        backgroundColor: redColor[1],
        borderRadius: 2,
        paddingX: 1,
        paddingY: 1
    },
    itemInterneBoxStyle: {
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        borderRadius: 2,
        padding: 1
    },
    itemInterneCheckBoxStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.2)",
        borderRadius: 2,
        paddingX: 1,
        paddingY: 1
    },
    itemInterneNonCheckBoxStyle: {
        backgroundColor: "rgba(255,0,0,0.3)",
        borderRadius: 2,
        paddingX: 1,
        paddingY: 1
    },
    itemBoxStyle: {
        backgroundColor: greenColor["015"],
        borderRadius: 2,
        padding: 0.5
    }
}

export default DelegationForm;