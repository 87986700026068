import { Box, Grid, Grow, Stack, Typography } from "@mui/material";
import { HourglassEmpty, Info, ListAlt, QuestionAnswer, Refresh } from "@mui/icons-material";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import { greenColor, greyColor, redColor, whiteColor, yellowColor } from "@styles";
import { IconButtonBase } from "@components/Buttons";
import { HeaderListContainer, ItemContainer, ListContainer, PanelListContainer, TextWithLabelContainer } from "@components/Containers";
import { Loader } from "@components/Loaders";
import { PAGES_GESTION_ABSENCES } from "@constants/demandesAbsenceConstants";
import { useMedia } from "react-use";
import PropTypes from "prop-types";

const headerItems = [
    {id: 1, label: "Emission", xs: 2, display: true, firstData: true, lastData: false},
    {id: 2, label: "Date de l'absence", xs: 2, display: true, firstData: false, lastData: false},
    {id: 3, label: "Emetteur", xs: 2, display: true, firstData: false, lastData: false},
    {id: 4, label: "Nature", xs: 3, display: true, firstData: false, lastData: false},
    {id: 5, label: "Statut", xs: 2, display: true, firstData: false, lastData: true}
];

const colorStatutList = {
    "Acceptée": greenColor["05"],
    "Non traitée": yellowColor[6],
    "En cours": yellowColor[6],
    "Refusée": redColor[5]
}

const DemandesAbsenceList = ({
    data = [], 
    isLoading = true, 
    isError = false, 
    errorMessage = "", 
    listed = false, 
    all = false,
    goToWithSelection = () => {},
    filtrerParReponse = () => {},
    refetch = () => {}
}) => {
    const isMobile = useMedia('(max-width: 890px)');

    if(isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return (
            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center"> 
                <Stack direction="row" spacing={2} bgcolor={redColor[5]} py={1} px={2} borderRadius={2} alignItems="center">
                    <Typography fontSize="small" fontWeight="bold" color={whiteColor[9]}>
                        {errorMessage}
                    </Typography>
                    <IconButtonBase 
                        variant="white5Grey9White9"
                        icon={<Refresh fontSize="small" />}
                        title="Rafraichir"
                        action={() => refetch()}
                    />
                </Stack>
            </Box>
        );
    }

    if(data?.length === 0) {
        return (
            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center"> 
                <Stack direction="row" spacing={2} borderRadius={2} bgcolor={greenColor["07"]} py={1} px={2} alignItems="center"> 
                    <Typography fontSize="small" fontWeight="bold" color={whiteColor[9]}>
                        Aucune donnée
                    </Typography>
                    <IconButtonBase 
                        variant="white5Grey9White9"
                        icon={all ? <HourglassEmpty fontSize="small" /> : <ListAlt fontSize="small" />}
                        title={all ? "Absences en attente" : "Toutes les absences"}
                        action={() => filtrerParReponse()}
                    />
                </Stack>
            </Box>
        )
    }

    return (
        (listed && !isMobile) ? 
            <Stack spacing={0.5} height="calc(100% - 95.5px)">
                <HeaderListContainer itemIdReference="HABSMANA" headerItems={headerItems} />
                <Box height="100%">
                    <PerfectScrollbar> 
                        <ListContainer spacing={0.5} length={data.length}>
                            {data.map((demande, id) => (
                                <Grow key={`ABSMANA${demande.id}`} in={true} timeout={400+(200*id)}>
                                    <Box bgcolor={colorStatutList[demande.statut]} borderRadius={2} p={0.35}>
                                        <Grid container spacing={0.3}>     
                                            <ItemContainer firstData data={moment(demande.date, "DDMMYYYY").format("DD/MM/YYYY")} xs={2} />
                                            <ItemContainer data={moment(demande.dateDebut, "DDMMYYYY").format("DD/MM/YYYY")} xs={2} />
                                            <ItemContainer data={demande.prenomNom} xs={2} />  
                                            <ItemContainer data={demande.nature} xs={3} /> 
                                            <ItemContainer lastData data={demande.statut} xs={2} /> 
                                            <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
                                                <IconButtonBase 
                                                    variant="white5Grey9White9"
                                                    icon={demande.demandeReponse === "O" ? <QuestionAnswer fontSize="small" /> : <Info fontSize="small" />} 
                                                    title={demande.demandeReponse === "O" ? "Répondre" : "Afficher"} 
                                                    action={() => goToWithSelection(PAGES_GESTION_ABSENCES.REPONSE, demande)} 
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grow>
                            ))}
                        </ListContainer>
                    </PerfectScrollbar>
                </Box>
            </Stack>
        :
            <PerfectScrollbar>
                <PanelListContainer length={data.length}>
                    {data.map((demande, id) => (
                        <Grow key={`ABSM${demande.id}`} in={true} timeout={400+(200*id)}>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                <Stack spacing={0.5} bgcolor={colorStatutList[demande.statut]} padding={0.5} borderRadius={2}>
                                    <Box borderRadius={2} p={1} bgcolor={whiteColor[7]}>
                                        <Stack spacing={0}>
                                            <TextWithLabelContainer label="Emission" value={moment(demande.date, "DDMMYYYY").format("DD/MM/YYYY")} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Date de l'absence" value={moment(demande.dateDebut, "DDMMYYYY").format("DD/MM/YYYY")} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Emetteur" value={demande.prenomNom} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Nature" value={demande.nature} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Statut" value={demande.statut} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                        </Stack>
                                    </Box>
                                    <Box borderRadius={2} height={35.5} display="flex" alignItems="center" justifyContent="center" bgcolor={whiteColor[7]}>
                                        <IconButtonBase 
                                            variant="grey7White5"
                                            icon={demande.demandeReponse === "O" ? <QuestionAnswer fontSize="small" /> : <Info fontSize="small" />} 
                                            title={demande.demandeReponse === "O" ? "Répondre" : "Afficher"} 
                                            action={() => goToWithSelection(PAGES_GESTION_ABSENCES.REPONSE, demande)} 
                                        />
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grow>
                    ))}
                </PanelListContainer>    
            </PerfectScrollbar>
    );
}

DemandesAbsenceList.propTypes = {
    data: PropTypes.array, 
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool, 
    errorMessage: PropTypes.string, 
    listed: PropTypes.bool, 
    all: PropTypes.bool, 
    goToWithSelection: PropTypes.func,
    filtrerParReponse: PropTypes.func,
    refetch: PropTypes.func
}

export default DemandesAbsenceList;