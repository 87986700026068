import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    statut: null,
}

const staUsrSlice = createSlice({
    name: 'staUsr',
    initialState: initialState,
    reducers: {
        setStatut: (state, action) => {
            state.statut = action.payload
        }
    },
})

const { actions, reducer } = staUsrSlice
export const { setStatut } = actions
export default reducer