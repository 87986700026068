import axios from "axios";
import { urls } from "@constants/appConstants";

export const getActGesTemCol = async (typePersonnel, matriculeCollaborateur, date, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetActivitesCollaborateur"
    };
    const body = {
        typePersonnel: typePersonnel,
        matriculeCollaborateur: matriculeCollaborateur,
        date: date
    };
    const response = await axios.post(urls.getActivitesCollaborateur, body, { headers: headers, params: { client: client }, signal });
    return response.data.activites;
}

export const getActGesTemMan = async (matriculeHierarchique, typePersonnel, matriculeCollaborateur, date, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetActivitesManager"
    };
    const body = {
        matriculeHierarchique: matriculeHierarchique,
        typePersonnel: typePersonnel,
        matriculeCollaborateur: matriculeCollaborateur,
        date: date
    };
    const response = await axios.post(urls.getActivitesManager, body, { headers: headers, params: { client: client }, signal });
    return response.data.activites;
}

export const getActInp = async (codeSociete, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetSecteursActivitesV1"
    };
    const body = {
        codeSociete: codeSociete
    }
    const response = await axios.post(urls.getSecteursActivitesV1, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.activites;
}

export const getActAff = async (codeSociete, moisEffet, anneeEffet, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetSecteursActivitesV2"
    };
    const body = {
        codeSociete: codeSociete,
        moisEffet: moisEffet,
        anneeEffet: anneeEffet
    }
    const response = await axios.post(urls.getSecteursActivitesV2, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.activites;
}
