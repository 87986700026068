import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    date: "",
    affichage: "T",
    allServices: true,
    semaine: [],
    emetteur: null,
    societes: [],
    totaux: null,
    jourSelected: "",
    matriculeSelected: "",
    prenomNomSelected: ""
}

const plaDabColSlice = createSlice({
    name: 'plaDabCol',
    initialState: initialState,
    reducers: {
        setPlanning: (state, action) => {
            state.date = action.payload.date
            state.semaine = action.payload.semaine
            state.emetteur = action.payload.emetteur
            state.societes = action.payload.societes
            state.totaux = action.payload.totaux
            state.services = action.payload.services
        },
        setDate: (state, action) => {
            state.date = action.payload
        },
        toggleAffichage: (state) => {
            state.affichage = state.affichage === "T" ? "V" : "T";
        },
        toggleAll: (state) => {
            state.allServices = !state.allServices
        },
        selectJour: (state, action) => {
            state.jourSelected = action.payload.jour
            state.matriculeSelected = action.payload.matricule
            state.prenomNomSelected = action.payload.prenomNom
        }
    },
})

const { actions, reducer } = plaDabColSlice
export const { 
    setPlanning, 
    setDate, 
    selectJour, 
    toggleAffichage, 
    toggleAll 
} = actions
export default reducer