import { Stack } from "@mui/material";
import { Check, DoneAll, Event, FilterList, MobiledataOff, RemoveDone, SwapVert } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useResetTrieCollaborateurs, useSelectDateCollaborateurs } from "@hooks/collaborateurs/hooksUtils";
import { DatePickerLabelizedButton, IconButtonBase } from "@components/Buttons";
import moment from "moment";

const CollaborateursFwgtp2Actions = ({ 
    stateType = "", 
    dateValidite = "", 
    isLoading = false, 
    allChecked = false, 
    tried = false, 
    toggleTrie = () => {}, 
    setOpenFiltre = () => {},  
    selectCollaborateursChecked = () => {},
    selectAllCollaborateurs = () => {}, 
}) => {
    const resetTrie = useResetTrieCollaborateurs(stateType);
    const selectDate = useSelectDateCollaborateurs(stateType);

    return (
        <Stack direction="row" spacing={0.5}>
            <IconButtonBase variant="white5Grey9White9" icon={tried ? <MobiledataOff fontSize="small" /> : <SwapVert fontSize="small" />} disabled={isLoading} title={tried ? "Enlever le trie" : "Trier"} action={tried ? resetTrie : toggleTrie} />
            <IconButtonBase variant="white5Grey9White9" icon={<FilterList fontSize="small" />} disabled={isLoading} title="Filtrer" action={() => setOpenFiltre((prevOpen) => !prevOpen)} />
            <DatePickerLabelizedButton 
                icon={<Event fontSize="small" />} 
                title="Choisir une date" 
                noClick={isLoading} 
                date={dateValidite ? moment(dateValidite, "DDMMYYYY").format("DD/MM/YYYY") : moment().format("DD/MM/YYYY")} 
                variant="white2Grey9White9"
                action={selectDate} 
            />
            <IconButtonBase variant="white5Grey9White9" icon={<Check fontSize="small" />} disabled={isLoading} title="Valider la selection" action={() => selectCollaborateursChecked()} />
            <IconButtonBase variant="white5Grey9White9" icon={allChecked ? <RemoveDone fontSize="small" /> : <DoneAll fontSize="small" />} disabled={isLoading} title={allChecked ? "Tout décocher" : "Tout cocher"} action={selectAllCollaborateurs} />
        </Stack>
    );
}

CollaborateursFwgtp2Actions.propTypes = {
    stateType: PropTypes.string,
    dateValidite: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isLoading: PropTypes.bool,
    allChecked: PropTypes.bool,
    tried: PropTypes.bool,
    toggleTrie: PropTypes.func,
    selectCollaborateursChecked: PropTypes.func,
    setOpenFiltre: PropTypes.func,
    selectAllCollaborateurs: PropTypes.func
}

export default CollaborateursFwgtp2Actions;