import { Box, Grid, IconButton, Stack } from "@mui/material";
import { Info } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { PanelListContainer, TextWithLabelContainer } from "@components/Containers";
import { ErrorBase } from "@components/Errors";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, whiteColor } from "@styles";
import PerfectScrollbar from "react-perfect-scrollbar";

const TransactionsListMobile = (props) => {
    const { data, isLoading, isError, errorMessage, openAnomalies } = props;

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader size={20} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return (<ErrorBase>{errorMessage}</ErrorBase>);
    }

    return (
        <PerfectScrollbar>
            <PanelListContainer length={data.length}>
                {data.map((transaction) => (
                    <Grid key={`TR${transaction.id}`} item xs={12}>
                        <Stack spacing={0.5} bgcolor={greenColor["015"]} padding={0.5} borderRadius={2}>
                            <Box borderRadius={2} p={1} bgcolor={whiteColor[5]}>
                                <Stack spacing={0}>
                                    <TextWithLabelContainer label="Mouvement" value={transaction.libelleMouvement} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                    <TextWithLabelContainer label="Date" value={moment([transaction.anneeMouvement, transaction.moisMouvement - 1, transaction.jourMouvement]).format("DD/MM/YYYY")} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                    <TextWithLabelContainer label="Heure Réelle" value={`${moment(transaction.heureMouvement, "H").format("HH")}h${moment(transaction.minuteMouvement, "m").format("mm")}`} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                    <TextWithLabelContainer label="Heure Ajustée" value={`${moment(transaction.heureMouvementAjustee, "H").format("HH")}h${moment(transaction.minuteMouvementAjustee, "m").format("mm")}`} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                    <TextWithLabelContainer label="Lecteur" value={transaction.lecteurPresence} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                    <TextWithLabelContainer label="Activité" value={transaction.libelleActivite} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                    <TextWithLabelContainer label="Type Travail" value={transaction.libelleTypeTravail} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                </Stack>
                            </Box>
                            {transaction.anomalies.length > 0 && 
                                <Box borderRadius={2} height={35.5} display="flex" alignItems="center" justifyContent="center" bgcolor={whiteColor[5]}>
                                    <IconButton size="small" onClick={() => openAnomalies(transaction)}>
                                        <Info fontSize="small" />
                                    </IconButton>
                                </Box>
                            }
                        </Stack>
                    </Grid>
                ))}
            </PanelListContainer>
        </PerfectScrollbar>
    );
}

TransactionsListMobile.defaultProps = {
    data: [],
    isLoading: true,
    isError: false,
    errorMessage: "",
    openAnomalies: () => {}
}

TransactionsListMobile.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
    openAnomalies: PropTypes.func
}

const styles = {
    loaderStyle: {
        backgroundColor: greenColor["015"],
        borderRadius: 2,
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        p: 1,
    }
}

export default TransactionsListMobile;