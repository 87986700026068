import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";
import { useSolApp } from "@hooks/gestionAbsences/soldes/hooksQueries";
import { selectDabGesAbsMan, selectSolGesAbs } from "@selectors";
import * as sitSolAppActions from "@reducers/soldes/sitSolAppReducer";
import { SituationSoldeDialog, SoldesList, SoldesText } from "@components/App/Soldes";
import { whiteColor } from "@styles";

const Soldes = () => {
    const [openSituationSolde, setOpenSituationSolde] = useState(false);
    const stateAbsencesManager = useSelector(selectDabGesAbsMan);
    const stateSoldes = useSelector(selectSolGesAbs);

    const { data, isLoading, isFetching, isError, error } = useSolApp(stateAbsencesManager?.demandeAbsenceSelected?.codeSociete, stateAbsencesManager?.demandeAbsenceSelected?.matricule);
    const dispatch = useDispatch();

    const openSituation = useCallback((solde) => {
        dispatch(sitSolAppActions.selectSolde({periode: stateSoldes.periode, solde: solde, matricule: stateAbsencesManager?.demandeAbsenceSelected?.matricule}));
        setOpenSituationSolde(true);
    }, [dispatch, stateSoldes.periode, stateAbsencesManager?.demandeAbsenceSelected?.matricule]);

    return (
        <>
            <Stack spacing={0.5}>
                <Box bgcolor={whiteColor[8]} borderRadius={2} textAlign="center" p={1}>
                    <Typography fontSize={"small"} fontWeight="bold">Soldes de {stateAbsencesManager?.demandeAbsenceSelected?.prenomNom}</Typography>
                </Box>
                {!isError &&
                    <SoldesText 
                        isLoading={isLoading || isFetching}
                        periode={data?.periode}     
                        variant="absenceManager"
                    />
                }
                <Box>
                    <SoldesList 
                        data={data?.soldes}
                        isLoading={isLoading || isFetching}
                        isError={isError}
                        errorMessage={error?.message}
                        listed={false}
                        variant="absenceManager"
                        openSituation={openSituation}
                    />
                </Box>
            </Stack>
            <SituationSoldeDialog 
                open={openSituationSolde} 
                setOpen={setOpenSituationSolde} 
            />
        </>
    );
}

export default Soldes;