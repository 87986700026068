import { ChevronLeft, ChevronRight, Event, ListAlt, OfflinePin, Refresh } from "@mui/icons-material";
import PropTypes from "prop-types";
import { DatePickerButton, IconButtonBase } from "@components/Buttons";
import { HeaderDialogContainer, HeaderPanelContainer } from "@components/Containers";
import { Stack } from "@mui/material";

const HierarchieActionsMobile = (props) => {
    const { 
        isLoading, 
        isError, 
        setOpen, 
        affichage, 
        precedent, 
        suivant, 
        selectDate, 
        refetch, 
        toggleAffichage 
    } = props;

    return (
        <>
            <HeaderDialogContainer
                title="Planning N-1"
                close={() => setOpen(false)}
            />
            <HeaderPanelContainer
                actionsLeft={
                    <Stack direction="row" spacing={0.5}>
                        <IconButtonBase icon={<ChevronLeft fontSize="small" />} disabled={isLoading || isError} action={() => precedent()} />
                        <IconButtonBase icon={<ChevronRight fontSize="small" />} disabled={isLoading || isError} action={() => suivant()} />
                        <DatePickerButton icon={<Event fontSize="small" />} timeout={600} noClick={isLoading || isError} action={() => selectDate()} />
                    </Stack> 
                }
                actionsRight={
                    <Stack direction="row" spacing={0.5} justifyContent="right">
                        <IconButtonBase icon={affichage === "T" ? <OfflinePin fontSize="small" /> : <ListAlt fontSize="small" />} disabled={isLoading || isError} action={() => toggleAffichage()} />
                        <IconButtonBase icon={<Refresh fontSize="small" />} disabled={isLoading} action={() => refetch()} />
                    </Stack>
                }
            />
        </>
    );
}

HierarchieActionsMobile.defaultProps = {
    isLoading: true, 
    isError: false, 
    setOpen: () => {}, 
    affichage: "", 
    precedent: () => {}, 
    suivant: () => {}, 
    selectDate: () => {}, 
    refetch: () => {}, 
    toggleAffichage: () => {} 
}

HierarchieActionsMobile.propTypes = {
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool, 
    setOpen: PropTypes.func, 
    affichage: PropTypes.string, 
    precedent: PropTypes.func, 
    suivant: PropTypes.func, 
    selectDate: PropTypes.func, 
    refetch: PropTypes.func, 
    toggleAffichage: PropTypes.func 
}

export default HierarchieActionsMobile;