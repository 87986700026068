import { default as Fr } from 'country-flag-icons/react/3x2/FR';
import { default as Gb } from 'country-flag-icons/react/3x2/GB';
import { default as De } from 'country-flag-icons/react/3x2/DE';
import { default as It } from 'country-flag-icons/react/3x2/IT';
import { default as Nl } from 'country-flag-icons/react/3x2/NL';
import { default as Pt } from 'country-flag-icons/react/3x2/PT';
import { default as Es } from 'country-flag-icons/react/3x2/ES';

export const langs = {
    fr: "Français",
    en: "English",
    de: "Deutsch",
    pt: "Português",
    nl: "Nederlands",
    it: "Italiano",
    es: "Español",
};

export const routesLang50 = {
    fr: <Fr width={100} style={{borderRadius: 8}} />,
    en: <Gb width={100} style={{borderRadius: 8}} />,
    de: <De width={100} style={{borderRadius: 8}} />,
    pt: <Pt width={100} style={{borderRadius: 8}} />,
    nl: <Nl width={100} style={{borderRadius: 8}} />,
    it: <It width={100} style={{borderRadius: 8}} />,
    es: <Es width={100} style={{borderRadius: 8}} />
};
