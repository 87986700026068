import { Box, Skeleton, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { greenColor, greyColor, redColor, whiteColor, yellowColor } from "@styles";

const InformationsBarMobile = (props) => {
    const { statut } = props;

    return (
        <Stack spacing={2} direction="row" bgcolor={whiteColor[5]} borderRadius={2} p={1} alignItems="center" flexGrow={1}>
            <Box>
                <Skeleton sx={statut === "P" ? styles.skeletonGreenStyle : styles.skeletonRedStyle} animation="pulse" variant="circular" width={18} height={18} />
            </Box>
            <Box>
                <Typography fontSize="small" fontWeight="bold">{statut === "P" ? "Présent" : "Absent" }</Typography>
            </Box>
        </Stack>
    );
}

InformationsBarMobile.defaultProps = {
    temps: "", 
    statut: "", 
    dateDernierMouvement: "", 
    heureDernierMouvement: ""
}

InformationsBarMobile.propTypes = {
    temps: PropTypes.string, 
    statut: PropTypes.string, 
    dateDernierMouvement: PropTypes.string, 
    heureDernierMouvement: PropTypes.string
}

const styles = {
    loaderStyle: {
        height: 52,
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
    },
    paperStyle: {  
        width: "100%",
        background: "none",
        borderRadius: 2
    },
    boxStatutStyle: {
        borderRadius: 2, 
        boxShadow: "none", 
        backgroundColor: greyColor[0],
        padding: 2,
        color: greyColor[5],
        justifyContent: "start",
        alignItems: "center"
    },
    boxErrorStatutStyle: {
        borderRadius: 2, 
        boxShadow: "none", 
        backgroundColor: yellowColor[1],
        padding: 1,
        color: greyColor[5],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 36
    },
    buttonStyle: {
        borderRadius: 2, 
        boxShadow: "none", 
        backgroundColor: greenColor["02"],
        padding: 2,
        color: greyColor[5],
        justifyContent: "start",
        "&:hover": {
            backgroundColor: greenColor["03"],
            color: whiteColor[9],
        }
    },
    skeletonGreenStyle: {
        backgroundColor: greenColor["06"]
    },
    skeletonRedStyle: {
        backgroundColor: redColor[5]
    }
};


export default InformationsBarMobile;