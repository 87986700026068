import { Box, Drawer, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { whiteColor } from "@styles";

const MenuFiltreContainer = (props) => {
    const { children, open, placement, setOpen } = props;

    return (
        <Drawer open={open} onClose={() => setOpen(false)} anchor={placement} sx={styles.drawerStyle} PaperProps={styles.paperStyle}>
            <Box bgcolor={whiteColor[9]} borderRadius={2} width={"50%"}>
                <Stack spacing={2} p={1}>
                    {children}
                </Stack>
            </Box>
        </Drawer>
    );
}

MenuFiltreContainer.defaultProps = {
    children: <></>,
    open: false,
    placement: "bottom",
    setOpen: () => {}
}

MenuFiltreContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType, PropTypes.array]),
    open: PropTypes.bool,
    placement: PropTypes.string,
    setOpen: PropTypes.func
}

const styles = {
    drawerStyle: {
        zIndex: (theme) => theme.zIndex.drawer + 2
    },
    paperStyle: {
        sx: {
            backgroundColor: "rgba(0,0,0,0)", 
            boxShadow: "none",
            padding: 1,
            display: "flex",
            alignItems: "center"
        }
    },
    paperStyleMobile: {
        sx: {
            backgroundColor: "rgba(0,0,0,0)", 
            boxShadow: "none",
            padding: 0,
            display: "flex",
            alignItems: "center"
        }
    }
};

export default MenuFiltreContainer;