import { useSelector, useDispatch } from "react-redux";
import { Box, Drawer, Grid, Typography } from "@mui/material";
import { useCallback } from "react";
import PropTypes from "prop-types";
import { selectDsh } from "@selectors";
import * as dshActions from "@reducers/dashboard/dshReducer"
import { getWidgetAdded } from "@utils";
import { WelcomeWidgetConfigurationAdd } from "../Widgets/Welcome";
import { DateWidgetConfigurationAdd } from "../Widgets/Date";
import { MeteoWidgetConfigurationAdd } from "../Widgets/Meteo";
import { HorlogesWidgetConfigurationAdd } from "../Widgets/Horloges";
import { PlagesHoraireWidgetConfigurationAdd } from "../Widgets/PlagesHoraire";
import { StatutWidgetConfigurationAdd } from "../Widgets/Statut";
import { whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const WidgetsMenu = (props) => {
    const { open, setOpen, position } = props;
    const stateDashboard = useSelector(selectDsh);
    const dispatch = useDispatch();
    const { t } = useTranslation("global");

    const routeTuiles = {
        wBonjour: <WelcomeWidgetConfigurationAdd />,
        wDate: <DateWidgetConfigurationAdd />,
        wMeteo: <MeteoWidgetConfigurationAdd />,
        wHorloges: <HorlogesWidgetConfigurationAdd />,
        wPlagesHoraire: <PlagesHoraireWidgetConfigurationAdd  />,
        wStatut: <StatutWidgetConfigurationAdd />,
    };

    const close = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const add = useCallback((widget) => {
        const updateState = getWidgetAdded(widget, stateDashboard.widgetsActives, stateDashboard.widgetsNonActives, stateDashboard.widgetsConfiguration, position);
        dispatch(dshActions.editWidget(updateState));
        close();
    }, [close, dispatch, position, stateDashboard.widgetsActives, stateDashboard.widgetsConfiguration, stateDashboard.widgetsNonActives]);

    return (
        <Drawer open={open} onClose={close} anchor="bottom" sx={styles.drawerStyle} PaperProps={styles.paperStyle}>
            <Box sx={styles.boxMenuWidget} maxHeight={"50vh"}  overflow={"auto"}>
                <Grid container spacing={1}>
                    {stateDashboard.widgetsNonActives.length > 0 ? 
                        stateDashboard.widgetsNonActives.map((widget, id) => (
                            <Grid key={`WIDGETNOACT${id}`} item xs={3} sx={{cursor: "pointer"}} onClick={() => add(widget)}>
                                {routeTuiles[widget]}
                            </Grid>
                        ))
                    : 
                        <Grid item xs={12}>
                            <Box padding={2}>
                                <Typography fontSize="normal">{t("texts.all_widgets_added")}</Typography>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Drawer>
    );
}

WidgetsMenu.defaultProps = {
    open: false, 
    setOpen: () => {}, 
    position: 0
}

WidgetsMenu.propTypes = {
    open: PropTypes.bool, 
    setOpen: PropTypes.func, 
    position: PropTypes.number
}

const styles = {
    drawerStyle: {
        zIndex: 12000
    },
    boxMenuWidget: {
        backgroundColor: whiteColor[9],
        borderRadius: 2,
        display: "flex",
        justifyContent: "center",
        padding: 1
    },
    paperStyle: {
        sx: {
            backgroundColor: "rgba(0,0,0,0)", 
            boxShadow: "none",
            padding: 1
        }
    }
};

export default WidgetsMenu;