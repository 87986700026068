import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { Check } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { selectCltApp, selectUsrApp } from "@selectors";
import PropTypes from "prop-types";
import { useQuestionParametres } from "@hooks/securite/hooksQueries";
import { Loader } from "@components/Loaders";
import { IconButtonBase } from "@components/Buttons";
import { SecretHookFormInput, TextHookFormInput } from "@components/Inputs";
import { schemaQuestion } from "../validations";
import { HeaderDialogContainer } from "@components/Containers";
import { greenColor, greyColor } from "@styles";

const defaultValues = {
    password: "",
    question: "",
    reponse: ""
}

const QuestionForm = (props) => {
    const { setOpen } = props;
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const methods = useForm({ mode: "onChange", defaultValues: defaultValues, resolver: joiResolver(schemaQuestion) });
    const { isLoadingQst, editQstActionner } = useQuestionParametres(); 

    const handleUpdateQuestion = useCallback(async (data) => {
        const dataRequest = {
            matricule: stateUser.matricule,
            password: data.password,
            question: data.question,
            reponse: data.reponse,
            email: stateUser.email, 
            token: stateUser.token,
            client: stateClient.client
        }
        const { isSucceed } = await editQstActionner(dataRequest);
        if(isSucceed) {
            setOpen(false);
        }
    }, [editQstActionner, setOpen, stateClient.client, stateUser.email, stateUser.matricule, stateUser.token]);

    const handleEnter = useCallback((event) => {
        if(event.key === "Enter" || event.key === undefined) {
            methods.handleSubmit(handleUpdateQuestion)();
        }
    }, [handleUpdateQuestion, methods]);

    return (
            <>
                <HeaderDialogContainer 
                    actionsLeft={
                        <IconButtonBase icon={<Check fontSize="small" />} title="Verifier la réponse" action={() => methods.handleSubmit(handleUpdateQuestion)()} />
                    } 
                    title="Modification de la question et de la réponse de récupération"
                    close={() => setOpen(false)}
                />
                {isLoadingQst ? 
                    <Loader colorContainer={greyColor[1]} colorLoader={greenColor["1"]} />
                :
                    <FormProvider {...methods}>
                        <Stack spacing={1} onKeyUp={handleEnter}>
                            <SecretHookFormInput 
                                name="password"
                                label="Mot de passe actuel"  
                                autoFocus={true}
                            /> 
                            <TextHookFormInput
                                name="question"
                                label="Question de récupération"
                                maxLength={50}
                            />
                            <TextHookFormInput
                                name="reponse" 
                                label="Réponse de récupération" 
                                maxLength={30}
                            />
                        </Stack>
                    </FormProvider>
                }
            </>
    );
}

QuestionForm.defaultProps = {
    setOpen: () => {}
}

QuestionForm.propTypes = {
    setOpen: PropTypes.func
}

export default QuestionForm;