import { Box, Grid, Grow, Stack } from "@mui/material";
import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { PanelListContainer, TextWithLabelContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { greenColor, whiteColor } from "@styles";
import PerfectScrollbar from "react-perfect-scrollbar";

const CollaborateursFwgtpeListMobile = (props) => {
    const { data, displayDate, selectCollaborateur } = props;

    return (
        <Stack spacing={0.5}>
            <Box overflow="auto" height={displayDate ? "calc(100vh - 140px)" : "calc(100vh - 86px)"}>
                <PerfectScrollbar> 
                    <PanelListContainer length={data.length}>
                        {data.map((collaborateur, id) => (
                            <Grow key={`PRS${collaborateur.id}`} in={true} timeout={400+(200*id)}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Stack spacing={0.25} padding={0.5} borderRadius={2} bgcolor={greenColor["015"]}>
                                        <Box borderRadius={2} p={1} bgcolor={whiteColor[5]}>
                                            <Stack spacing={0}>
                                                <TextWithLabelContainer label="Nom" value={collaborateur.prenomNom} weight={{label: "bold", value: "normal"}} />
                                                <TextWithLabelContainer label="Matricule" value={collaborateur.matricule} weight={{label: "bold", value: "normal"}} />
                                                <TextWithLabelContainer label="Badge" value={collaborateur.badge} weight={{label: "bold", value: "normal"}} />
                                                <TextWithLabelContainer label="Société" value={collaborateur.libelleSociete} weight={{label: "bold", value: "normal"}} />
                                                <TextWithLabelContainer label="Etablissement" value={collaborateur.libelleEtablissement} weight={{label: "bold", value: "normal"}} />
                                                <TextWithLabelContainer label="Direction" value={collaborateur.libelleDirection} weight={{label: "bold", value: "normal"}} />
                                                <TextWithLabelContainer label="Service" value={collaborateur.libelleService} weight={{label: "bold", value: "normal"}} />
                                                <TextWithLabelContainer label="Secteur" value={collaborateur.libelleSecteur} weight={{label: "bold", value: "normal"}} />
                                                <TextWithLabelContainer label="Type Collaborateur" value={collaborateur.typePersonnel === "P" ? "Salarié" : "Intérimaire"} weight={{label: "bold", value: "normal"}} />
                                                <TextWithLabelContainer label="Contôle horaire" value={collaborateur.controleHoraire === "O" ? "Oui" : "Non"} weight={{label: "bold", value: "normal"}} />
                                                <TextWithLabelContainer label="Présent" value={collaborateur.presentADate === "O" ? "Oui" : "Non"} weight={{label: "bold", value: "normal"}} />
                                            </Stack>
                                        </Box>
                                        <Box borderRadius={2} height={35.5} display="flex" alignItems="center" justifyContent="center" bgcolor={whiteColor[5]}>
                                            <IconButtonBase icon={<Check fontSize="small" />} placement="left" title="Selectionner" action={() => selectCollaborateur(collaborateur)} />
                                        </Box>
                                    </Stack>
                                </Grid>
                            </Grow>
                        ))}
                    </PanelListContainer>
                </PerfectScrollbar>
            </Box>
        </Stack>
    );
}

CollaborateursFwgtpeListMobile.defaultProps = {
    data: [],
    displayDate: true,
    selectCollaborateur: () => {}
}

CollaborateursFwgtpeListMobile.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    displayDate: PropTypes.bool,
    selectCollaborateur: PropTypes.func
}

export default CollaborateursFwgtpeListMobile;