import axios from "axios";
import { urls } from "@constants/appConstants";
import moment from "moment";

export const fetchDlg = async (matricule, typeAAfficher, client, signal) => {
    const header = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetDelegations", 
    };
    const body = {
        matricule:  matricule,
        typeAAfficher: typeAAfficher
    };
    const response = await axios.post(urls.getDelegations, body, { headers: header, params: { client: client }, signal: signal });
    return response.data.delegations;
}

export const fetchDla = async (matricule, utilisateur, client, signal) => {
    const header = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetDelegataires", 
    };
    const body = {
        matricule:  matricule,
        utilisateur: utilisateur
    };
    const response = await axios.post(urls.getDelegataires, body, { headers: header, params: { client: client }, signal: signal });
    return response.data.delegataires;
}

export const fetchDrtDlg = async (matricule, client, signal) => {
    const header = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetDroits", 
    };
    const body = {
        matricule:  matricule
    };
    const response = await axios.post(urls.getDroitsDelegation, body, { headers: header, params: { client: client }, signal: signal });
    return response.data.societes;
}

export const addDlg = async (data) => {
    const header = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostDelegation", 
    };
    const body = {
        matricule:  data.matricule,
        absence: data.absence,
        formation: data.formation,
        noteFrais: data.noteFrais,
        dateDebut: data.dateDebut,
        dateReprise: data.dateReprise,
        delegationImmediate: data.delegationImmediate,
        delegationDelaitAttente: data.delegationDelaiAttente,
        avertissementMail: data.avertissementMail,
        delaitAttente: data.delaiAttente,
        destinataire: data.destinataire
    };
    const response = await axios.post(urls.postDelegation, body, { headers: header, params: { client: data.client } });
    return response.data;
}

export const editDlg = async (data) => {
    const header = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteEditDelegation", 
    };
    const body = {
        matricule:  data.matricule,
        absence: data.absence,
        formation: data.formation,
        noteFrais: data.noteFrais,
        dateDebut: data.dateDebut,
        dateReprise: data.dateReprise,
        delegationImmediate: data.delegationImmediate,
        delegationDelaitAttente: data.delegationDelaiAttente,
        avertissementMail: data.avertissementMail,
        delaitAttente: data.delaiAttente,
        destinataire: data.destinataire,
        numeroEnregistrement: data.id.padStart(5, "0"),
    };
    const response = await axios.post(urls.editDelegation, body, { headers: header, params: { client: data.client } });
    return response.data;
}

export const deleteDlg = async (data) => {
    const header = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteDeleteDelegation", 
    };
    const annee = moment(data.dateDebut, "DDMMYYYY").format("YYYY");
    const mois = moment(data.dateDebut, "DDMMYYYY").format("MM");
    const jour = moment(data.dateDebut, "DDMMYYYY").format("DD");
    const body = {
        responsable: data.responsable,
        nature: data.codeNature,
        annee: annee,
        mois: mois,
        jour: jour
    };
    const response = await axios.post(urls.deleteDelegation, body, { headers: header, params: { client: data.client } });
    return response.data;
}