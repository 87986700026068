import { Box, Grid, Stack, Typography } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { useStateTemGesTem } from "@hooks/gestionTemps/temps/hooksStates";
import { whiteColor } from "@styles";
import { TextContainer } from "@components/Containers";

const TempsHeaderTableMobile = (props) => {
    const { userType } = props;
    const stateTemps = useStateTemGesTem(userType);
    const date = moment(stateTemps.dateTemps, "DDMMYYYY");

    return (
        <Stack spacing={0.5}>
            <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold">
                Semaine {date.format("ww")} du {date.startOf("week").format("DD/MM/YYYY")} au {date.endOf('week').format("DD/MM/YYYY")}
            </TextContainer>
            <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold">
                B : Badgées / P : Payées
            </TextContainer>
            <Box>
                <Grid container spacing={0.5}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={4} textAlign="center">
                        <Box bgcolor={whiteColor[5]} p={1} borderRadius={2}>
                            <Typography fontSize={"small"} fontWeight="bold">Heures</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4} textAlign="center">
                        <Box bgcolor={whiteColor[5]} p={1} borderRadius={2}>
                            <Typography fontSize={"small"} fontWeight="bold">Heures nuit</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
            </Box>
            <Box>
                <Grid container spacing={0.5}>
                    <Grid item xs={2}></Grid>
                    <Grid item container xs={4} spacing={0.5}>
                        <Grid item xs={6} textAlign="center">
                            <Box bgcolor={whiteColor[5]} p={1} borderRadius={2}>
                                <Typography fontSize={"small"} fontWeight="bold">B</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} textAlign="center">
                            <Box bgcolor={whiteColor[5]} p={1} borderRadius={2}>
                                <Typography fontSize={"small"} fontWeight="bold">P</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item container xs={4} spacing={0.5}>
                        <Grid item xs={6} textAlign="center">
                            <Box bgcolor={whiteColor[5]} p={1} borderRadius={2}>
                                <Typography fontSize={"small"} fontWeight="bold">B</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} textAlign="center">
                            <Box bgcolor={whiteColor[5]} p={1} borderRadius={2}>
                                <Typography fontSize={"small"} fontWeight="bold">P</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
            </Box>
        </Stack>
    );
}

TempsHeaderTableMobile.propTypes = {
    userType: PropTypes.string.isRequired
}

export default TempsHeaderTableMobile;