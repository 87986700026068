import { Box, Grid, Grow, Stack } from "@mui/material";
import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { HeaderListContainer, ItemContainer, ListContainer } from "@components/Containers";
import { greenColor } from "@styles";
import PerfectScrollbar from "react-perfect-scrollbar";
import { IconButtonBase } from "@components/Buttons";

const CollaborateursFwdc01List = ({
    data = [], 
    headers = [], 
    openFiltre = false,
    selectCollaborateur = () => {}
}) => {
    return (
        <Stack spacing={0.5}>
            <HeaderListContainer itemIdReference="CBODYGT" headerItems={headers} />
            <PerfectScrollbar> 
                <Box maxHeight={openFiltre ? "calc(100vh - 228px)" : "calc(100vh - 206px)"}>
                    <ListContainer spacing={0.5} length={data.length} noDataLabel="Aucune personne">
                        {data.map((collaborateur, id) => (
                            <Grow key={`PRS${collaborateur.id}`} in={true} timeout={400+(200*id)}>
                                <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.3}>
                                    <Grid container spacing={0.3}>
                                        <ItemContainer firstData data={collaborateur.prenomNom} xs={2} />
                                        <ItemContainer data={collaborateur.matricule} xs={2} />
                                        <ItemContainer data={collaborateur.badge} xs={2} />
                                        <ItemContainer data={collaborateur.libelleSociete} xs={2} />
                                        <ItemContainer data={collaborateur.libelleService} xs={2} />
                                        <ItemContainer lastData data={collaborateur.typePersonnel === "P" ? "Salarié" : "Intérimaire"} xs={1} />
                                        <Grid item xs={1} textAlign="center" display="flex" alignItems="center" justifyContent="center">
                                            <IconButtonBase variant="white5Grey9White9" icon={<Check fontSize="small" />} title="Selectionner" action={() => selectCollaborateur(collaborateur)} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grow>
                        ))}
                    </ListContainer>
                </Box>
            </PerfectScrollbar>
        </Stack>
    );
}

CollaborateursFwdc01List.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    headers: PropTypes.array,
    openFiltre: PropTypes.bool,
    selectCollaborateur: PropTypes.func
}

export default CollaborateursFwdc01List;