import { useEffect, useState } from "react";
import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { redColor, whiteColor, yellowColor } from "@styles";
import { useStatePlanning } from "@hooks/planning/hooksStates";
import { useTranslation } from "react-i18next";

const verifyEffectif = (seuil, effectif, we = false) => {
    let statut = {bgColor: whiteColor[7], message: ""};
    if(seuil === effectif) {
        statut.bgColor = yellowColor[5];
        statut.message = "Effectif minimum atteint";
    } else if(seuil > effectif && !we) {
        statut.bgColor = redColor[5];
        statut.message = "Effectif minimum dépassé";
    } 
    return statut;
};

const variants = {
    planning: { bg1: whiteColor[5] },
    absenceManager: { bg1: whiteColor[7] }
}

const Totaux = ({ 
    type = "",
    variant = "planning"
}) => {
    const [effectifs, setEffectifs] = useState([]);
    const statePlanning = useStatePlanning(type);
    const { t } = useTranslation("global");

    useEffect(() => {
        if(!!statePlanning.totaux) {
            if(statePlanning.allServices) {
                setEffectifs(statePlanning.totaux.effectifDisponible.map((effectifDisponible, id) => {
                    return {id: id, content: effectifDisponible, statut: {bgColor: whiteColor[7], message: ""}};
                })); 
            } else {
                setEffectifs(statePlanning.totaux.effectifDisponibleUnService.map((effectifDisponibleUnService, id) => {
                    if(id < 5) {
                        return {id: id, content: `${effectifDisponibleUnService}/${statePlanning.totaux.seuilContrainteAbsence}`, statut: verifyEffectif(statePlanning.totaux.seuilContrainteAbsence, effectifDisponibleUnService)}
                    } else {
                        return {id: id, content: `${effectifDisponibleUnService}/${statePlanning.totaux.seuilContrainteAbsence}`, statut: verifyEffectif(statePlanning.totaux.seuilContrainteAbsence, effectifDisponibleUnService, true)}
                    }
                })); 
            }
        }
    }, [statePlanning.totaux, statePlanning.allServices]);

    
    return (  
        <Box>
            {statePlanning?.totaux && 
                <Stack spacing={0.5}>
                    <Box>
                        <Grid container spacing={0.3}>
                            <Grid item xs={2.25}>
                                <Box bgcolor={variants[variant]?.bg1} p={1} height={"100%"} sx={{ borderBottomLeftRadius: 8, borderTopLeftRadius: 8 }} boxSizing="border-box" display="flex" alignItems="center" justifyContent="left">
                                    <Typography fontSize="small" fontWeight="bold">{t("texts.available_staff")}</Typography>
                                </Box>
                            </Grid>
                            {effectifs.map((effectif) => (
                                <Grid item xs={1.25} key={"EFF"+effectif.id}>
                                    <Tooltip title={effectif.statut.message} placement="top">
                                        <Box bgcolor={effectif.statut.bgColor} sx={{ borderTopRightRadius: effectif.id === 6 ? 8 : 0, borderBottomRightRadius: effectif.id === 6 ? 8 : 0 }} p={1} height={"100%"} boxSizing="border-box" display="flex" alignItems="center" justifyContent="center">
                                            <Typography fontSize="small" fontWeight="bold">
                                                {effectif.content} 
                                            </Typography>
                                        </Box>
                                    </Tooltip>
                                </Grid>
                            ))}
                            <Grid item xs={1}></Grid>
                        </Grid>
                    </Box>
                </Stack>
            }
        </Box>
    );
}

Totaux.propTypes = {
    type: PropTypes.string,
    variant: PropTypes.string
}

export default Totaux;