import Joi from "joi";
import voca from "voca";
import { z } from "zod";

const validationNombreCaracteresSpeciaux = (character) => {
    let caracteresSpeciaux = ["$", "%", "&", "*", "-", "=", "?", "_", "'", ".", "(", ")", ":"];

    return caracteresSpeciaux.includes(character);
}

const getRulesJoi = (secureVariable) => {
    return Joi.extend((joi) => ({
        type: "string",
        base: joi.string(),
        messages: {
            "string.minuscule": "errors.input_password_min_minuscule",
            "string.majuscule": "errors.input_password_min_majuscule",
            "string.chiffre": "errors.input_password_min_int",
            "string.specialChar": "errors.input_password_min_special_chars",
            "string.forbiddenChar": "errors.input_password_forbidden_chars"
        },
        rules: {
            minuscule: {
                validate(value, helpers) {
                    return voca.countWhere(value, voca.isLowerCase) >= secureVariable.nombreMinimumMinuscules ? value : helpers.error("string.minuscule", { value });
                }
            },
            majuscule: {
                validate(value, helpers) {
                    return voca.countWhere(value, voca.isUpperCase) >= secureVariable.nombreMinimumMajuscules ? value : helpers.error("string.majuscule", { value });
                }
            },
            chiffre: {
                validate(value, helpers) {
                    return voca.countWhere(value, voca.isDigit) >= secureVariable.nombreMinimumChiffres ? value : helpers.error("string.chiffre", { value });
                }
            },
            specialChar: {
                validate(value, helpers) {
                    return voca.countWhere(value, validationNombreCaracteresSpeciaux) >= secureVariable.nombreMinimumSpecialChars ? value : helpers.error("string.specialChar", { value });
                }
            },
            forbiddenChar: {
                validate(value, helpers) {
                    return voca.matches(value, /^(\w|[$%&*\-=?_'.():])+$/) ? value : helpers.error("string.forbiddenChar", { value });
                }
            }
        }
    }));
}

export const schemaPassword = (secureVariable) => {
    const schemas = {
        L: Joi.object({
            newPassword1: Joi.string().required().min(secureVariable.longueurMinimumPassword).messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "string.min": "errors.input_password_min"
                }),
            newPassword2: Joi.string().required().valid(Joi.ref("newPassword1")).messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "any.only": "errors.input_password_repeat"
                }),
        }),
        R: Joi.object({
            newPassword1: getRulesJoi(secureVariable).string().required().min(secureVariable.longueurMinimumPassword).minuscule().majuscule().chiffre().specialChar().forbiddenChar().messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "string.min": "errors.input_password_min"
                }),
            newPassword2: Joi.string().required().valid(Joi.ref("newPassword1")).messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "any.only": "errors.input_password_repeat"
                }),
        }),
        N: Joi.object({
            newPassword1: Joi.string().required().min(secureVariable.longueurMinimumPassword).pattern(/^[0-9]+$/, "numbers").messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "string.min": "errors.input_password_min",
                    "string.pattern.name": "errors.input_password_only_int"
                }),
            newPassword2: Joi.string().required().valid(Joi.ref("newPassword1")).messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "any.only": "errors.input_password_repeat"
                }),
        })
    }

    return secureVariable ? schemas[secureVariable.typePassword] : schemas.L;
}


export const schemaLoginPasswordQuestion = (secureVariable) => {
    const schemas = {
        L: Joi.object({
            newPassword1: Joi.string().required().min(secureVariable.longueurMinimumPassword).messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "string.min": "errors.input_password_min"
                }),
            newPassword2: Joi.string().required().valid(Joi.ref("newPassword1")).messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "any.only": "errors.input_password_repeat"
                }),
            question: Joi.string().required().messages({ "string.empty": "Question obligatoire" }),
            reponse: Joi.string().required().messages({ "string.empty": "Réponse obligatoire" }),
        }),
        R: Joi.object({
            newPassword1: getRulesJoi(secureVariable).string().required().min(secureVariable.longueurMinimumPassword).minuscule().majuscule().chiffre().specialChar().forbiddenChar().messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "string.min": "errors.input_password_min",
                }),
            newPassword2: Joi.string().required().valid(Joi.ref("newPassword1")).messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "any.only": "errors.input_password_repeat"
                }),
            question: Joi.string().required().messages({ "string.empty": "Question obligatoire" }),
            reponse: Joi.string().required().messages({ "string.empty": "Réponse obligatoire" }),
        }),
        N: Joi.object({
            newPassword1: Joi.string().required().min(secureVariable.longueurMinimumPassword).pattern(/^[0-9]+$/, "numbers").messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "string.min": "errors.input_password_min",
                    "string.pattern.name": "errors.input_password_only_int"
                }),
            newPassword2: Joi.string().required().valid(Joi.ref("newPassword1")).messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "any.only": "errors.input_password_repeat"
                }),
            question: Joi.string().required().messages({ "string.empty": "Question obligatoire" }),
            reponse: Joi.string().required().messages({ "string.empty": "Réponse obligatoire" }),    
        })
    }

    return secureVariable ? schemas[secureVariable.typePassword] : schemas.L;
}

export const schemaReponse = Joi.object({
    reponse: Joi.string().required().messages({ "string.empty": "errors.input_response_required" }),
});

export const schemaQuestion = Joi.object({
    password: Joi.string().required().messages({ "string.empty": "errors.input_password_required"}),
    question: Joi.string().required().messages({ "string.empty": "errors.input_question_required" }),
    reponse: Joi.string().required().messages({ "string.empty": "errors.input_response_required" }),
});

export const schemaQestionLogin = Joi.object({
    question: Joi.string().required().messages({ "string.empty": "errors.input_question_required" }),
    reponse: Joi.string().required().messages({ "string.empty": "errors.input_response_required" }),
});


export const schemaLoginPassword = (secureVariable) => {
    const schemas = {
        L: Joi.object({
            newPassword1: Joi.string().required().min(secureVariable.longueurMinimumPassword).messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "string.min": "errors.input_password_min"
                }),
            newPassword2: Joi.string().required().valid(Joi.ref("newPassword1")).messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "any.only": "errors.input_password_repeat"
                }),
        }),
        R: Joi.object({
            newPassword1: getRulesJoi(secureVariable).string().required().min(secureVariable.longueurMinimumPassword).minuscule().majuscule().chiffre().specialChar().forbiddenChar().messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "string.min": "errors.input_password_min",
                }),
            newPassword2: Joi.string().required().valid(Joi.ref("newPassword1")).messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "any.only": "errors.input_password_repeat"
                }),
        }),
        N: Joi.object({
            newPassword1: Joi.string().required().min(secureVariable.longueurMinimumPassword).pattern(/^[0-9]+$/, "numbers").messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "string.min": "errors.input_password_min",
                    "string.pattern.name": "errors.input_password_only_int"
                }),
            newPassword2: Joi.string().required().valid(Joi.ref("newPassword1")).messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "any.only": "errors.input_password_repeat"
                }),
        })
    }

    return secureVariable ? schemas[secureVariable.typePassword] : schemas.L;
}


export const schemaParametresPassword = (secureVariable) => {
    const schemas = {
        L: Joi.object({
            oldPassword: Joi.string().required().messages({ "string.empty": "errors.input_password_required"}),
            newPassword1: Joi.string().required().min(secureVariable.longueurMinimumPassword).messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "string.min": "errors.input_password_min"
                }),
            newPassword2: Joi.string().required().valid(Joi.ref("newPassword1")).messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "any.only": "errors.input_password_repeat"
                }),
        }),
        R: Joi.object({
            oldPassword: Joi.string().required().messages({ "string.empty": "errors.input_password_required"}),
            newPassword1: getRulesJoi(secureVariable).string().required().min(secureVariable.longueurMinimumPassword).minuscule().majuscule().chiffre().specialChar().forbiddenChar().messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "string.min": "errors.input_password_min",
                }),
            newPassword2: Joi.string().required().valid(Joi.ref("newPassword1")).messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "any.only": "errors.input_password_repeat"
                }),
        }),
        N: Joi.object({
            oldPassword: Joi.string().required().messages({ "string.empty": "errors.input_password_required"}),
            newPassword1: Joi.string().required().min(secureVariable.longueurMinimumPassword).pattern(/^[0-9]+$/, "numbers").messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "string.min": "errors.input_password_min",
                    "string.pattern.name": "errors.input_password_only_int"
                }),
            newPassword2: Joi.string().required().valid(Joi.ref("newPassword1")).messages({ 
                    "string.empty": "errors.input_new_password_required",
                    "any.only": "errors.input_password_repeat"
                }),
        })
    }

    return secureVariable ? schemas[secureVariable.typePassword] : schemas.L;
}

export const schemaLogin = z.object({
    identifiant: z.string().min(1, "errors.input_identifiant_required"),
    identifiantCheck: z.boolean(),
    password: z.string(),
}).superRefine((data, ctx) => {
    if(data.identifiantCheck && !data.password) {
        ctx.addIssue({
            code: z.ZodIssueCode.invalid_string,
            message: "errors.input_password_required",
            path: ["password"]
        })
    }
});