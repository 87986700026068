import PropTypes from "prop-types";
import { DialogContainer, HeaderDialogContainer } from "@components/Containers";
import { useActPoiGesTem } from "@hooks/activites/hooksQueries";
import { ActivitesList } from "@components/App/Activites";
import { useTranslation } from "react-i18next";

const ActivitesDialog = ({
    open = false, 
    actionSuivante = "",
    setOpen = () => {}, 
    handleOpenConfirmationPointage = () => {}
}) => {
    const { data, isLoading, isFetching, isError } = useActPoiGesTem("P", open);
    const { t } = useTranslation("global");

    return (
        <DialogContainer open={open} placement="top" taille="xs" >
            <HeaderDialogContainer 
                title={t("titles.activity_list")}
                close={() => setOpen(false)}
            />
            <ActivitesList 
                data={data} 
                isLoading={isLoading || isFetching}
                isError={isError}
                actionSuivante={actionSuivante} 
                handleOpenConfirmationPointage={handleOpenConfirmationPointage} 
            />    
        </DialogContainer>
    );
}

ActivitesDialog.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    handleOpenConfirmationPointage: PropTypes.func,
    actionSuivante: PropTypes.string
}

export default ActivitesDialog;