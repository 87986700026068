import axios from "axios";
import { urls } from "@constants/appConstants";

export const downloadHeu = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteDownloadHeures"
    };
    const body = {
        option: data.option,
        utilisateur: data.utilisateur,
        matricule: data.matricule,
        dateDebut: data.dateDebut,
        dateFin: data.dateFin,
        codeSociete: data.codeSociete,
        codeEtablissement: data.codeEtablissement,
        codeDirection: data.codeDirection,
        codeService: data.codeService,
        codeSecteur: data.codeSecteur,
        present: data.present,
        horaire: data.horaire,
        jourSemaine: data.jourSemaine,
        detailMouvements: data.detailMouvements
    };
    const response = await axios.post(urls.downloadHeures, body, { headers: headers, params: { client: data.client } });
    return response.data.heures;
}