import { urls } from "@constants/appConstants";
import axios from "axios";

export const fetchColTmp = async (utilisateur, matriculeHierarchique, droitSalarie, droitInterimaire, date, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetCollaborateursTemps"
    };
    const body = {
        utilisateur: utilisateur,
        matriculeHierarchique: matriculeHierarchique,
        droitSalarie: droitSalarie,
        droitInterimaire: droitInterimaire,
        date: date
    };
    const response = await axios.post(urls.getCollaborateursTemps, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.collaborateurs;
}

export const fetchColAffInd = async (utilisateur, matriculeHierarchique, droitSalarie, droitInterimaire, date, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetCollaborateursAffectationsIndividuelles"
    };
    const body = {
        utilisateur: utilisateur,
        matriculeHierarchique: matriculeHierarchique,
        droitSalarie: droitSalarie,
        droitInterimaire: droitInterimaire,
        date: date
    };
    const response = await axios.post(urls.getCollaborateursAffectationsIndividuelles, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.collaborateurs;
}

export const fetchColAffCol = async (utilisateur, droitSalarie, droitInterimaire, codeSociete, matriculeHierarchique, date, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetCollaborateursAffectationsCollectives"
    };
    const body = {
        utilisateur: utilisateur,
        droitSalarie: droitSalarie,
        droitInterimaire: droitInterimaire,
        codeSociete: codeSociete,
        matriculeHierarchique: matriculeHierarchique,
        date: date
    };
    const response = await axios.post(urls.getCollaborateursAffectationsCollectives, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.collaborateurs;
}

export const fetchColPla = async (utilisateur, matriculeHierarchique, numeroSemaine, anneeSemaine, droitSalarie, droitInterimaire, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetCollaborateursPlanning"
    };
    const body = {
        origine: "1",
        validation: "N",
        numeroSemaine: numeroSemaine,
        anneeSemaine: anneeSemaine,
        utilisateur: utilisateur,
        matriculeHierarchique: matriculeHierarchique,
        droitSalarie: droitSalarie,
        droitInterimaire: droitInterimaire,
        matriculeCollaborateur: "",
        typePersonnel: "",
        modificationAffectationsHoraires: ""
    };
    const response = await axios.post(urls.getCollaborateursPlanning, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.collaborateurs;
}

export const fetchColCre = async (utilisateur, matriculeHierarchique, droitSalarie, droitInterimaire, date, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetCollaborateursCredits"
    };
    const body = {
        utilisateur: utilisateur,
        matriculeHierarchique: matriculeHierarchique,
        droitSalarie: droitSalarie,
        droitInterimaire: droitInterimaire,
        date: date
    };
    const response = await axios.post(urls.getCollaborateursCredits, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.collaborateurs;
}

export const fetchColMvt = async (data, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetCollaborateursMouvements"
    };
    const body = {
        utilisateur: data.utilisateur,
        matriculeHierarchique: data.matriculeHierarchique,
        droitSalarie: data.droitSalarie,
        droitInterimaire: data.droitInterimaire,
        dateDebut: data.dateDebut,
        dateFin: data.dateFin,
        societe: data.codeSociete,
        etablissement: data.codeEtablissement,
        direction: data.codeDirection,
        service: data.codeService,
        secteur: data.codeSecteur,
        emploi: data.codeEmploi,
        horaire: data.codeHoraire,
        typeJour: data.jour,
        typeEvenement: data.evenement,
        heureLimite: data.heureLimite,
        detailMouvements: data.detail
    };
    const response = await axios.post(urls.getCollaborateursMouvements, body, { headers: headers, params: { client: data.client }, signal: signal });
    return response.data.collaborateurs;
}
