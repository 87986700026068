import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Stack, Box, Drawer, Button } from "@mui/material";
import { Home, Logout, Close } from "@mui/icons-material";
import { selectUsrApp } from "@selectors";
import * as tabAppActions from "@reducers/tabs/tabAppReducer";
import * as userActions from "@reducers/user/usrAppReducer";
import { Menu } from "../Menu";
import { BaseButton } from "@components/Buttons";
import { routePolices } from "@constants/policesConstants";
import { redColor, whiteColor } from "@styles";
import { useMedia } from "react-use";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTranslation } from "react-i18next";

const MenuMobile = () => {
    const stateUser = useSelector(selectUsrApp);
    const dispatch = useDispatch();
	const isMiddle2 = useMedia('(min-width: 900px)');
	const isSmall = useMedia('(max-width: 900px)');
    const { t } = useTranslation("global");

    const logout = useCallback(() => {
        dispatch(userActions.logoutUser());
    }, [dispatch]);

    const handleAction = useCallback(() => {
        dispatch(tabAppActions.setActiveTab("ACC010000"));
        dispatch(userActions.openMenu(false));
    }, [dispatch])

    return (
        <Drawer variant="temporary" anchor={isMiddle2 ? "left" : "bottom"} open={stateUser.openMenu} onClose={() => dispatch(userActions.openMenu(false))} PaperProps={{ sx: { width: isMiddle2 ? 300 : "100%", height: isMiddle2 ? "100%" : "60%", backgroundColor: "transparent", p: 1, boxSizing: "border-box" }}} sx={{zIndex: (theme) => theme.zIndex.drawer + 1}} elevation={0}>
            <Stack bgcolor={whiteColor[9]} borderRadius={2} padding={1} boxSizing="border-box" height={isMiddle2 ? "calc(100vh - 16px)" : "100%"}>
                <PerfectScrollbar>
                    <Stack spacing={0.5} overflow="auto">
                        <Box>
                            <BaseButton iconLeft={<Home fontSize="small"/>} color="green" textAlign="left" font={routePolices[stateUser.police]} horizontalAlign="left" px={1.5} height={40} maxHeight={null} action={() => handleAction()}>Accueil</BaseButton>
                        </Box>
                        {stateUser.menu.map((nodes) => (<Menu key={`NODEMOBILE${nodes.action}`} nodes={nodes} mobile={true} />)) } 
                        <Box>
                            <BaseButton iconLeft={<Logout fontSize="small"/>} color="red" textAlign="left" font={routePolices[stateUser.police]} horizontalAlign="left" px={1.5} height={40} maxHeight={null} action={logout}>Déconnexion</BaseButton>
                        </Box>
                    </Stack>
                </PerfectScrollbar>
                {isSmall && 
                    <Box height="100px" display="flex" justifyContent="center" alignItems="center">
                        <Button 
                            variant="outlined"
                            startIcon={<Close />} 
                            size="large"
                            sx={{ 
                                width: 150, 
                                color: redColor[7], 
                                backgroundColor: redColor[1], 
                                borderRadius: 10,
                                border: "0px",
                                "&:hover": { 
                                    color: redColor[9],
                                    backgroundColor: redColor[3],
                                    border: "0px" 
                                }  
                            }}
                            onClick={() => dispatch(userActions.openMenu(false))}
                        >
                            {t("buttons.close")}
                        </Button>
                    </Box>
                }
            </Stack>
        </Drawer>    
    );
}

export default MenuMobile;