import PropTypes from "prop-types";
import { useActAffIndInp } from "@hooks/activites/hooksQueries";
import { Autocomplete, Box, Grid, List, Paper, Popper, TextField, Tooltip, Typography, autocompleteClasses, tooltipClasses } from "@mui/material";
import { useController, useFormContext, useFormState } from "react-hook-form";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, redColor, yellowColor } from "@styles";
import { TextContainer } from "@components/Containers";

const headerOptionsActivites = [
    {id: 1, title: "Code", xs: 6, key: "codeActivite"},
    {id: 2, title: "Designation", xs: 6, key: "libelleActivite"}
];

const OptionsComponent = (props, option) => {
    return (
        <Box key={option.id} component="li" {...props} sx={styles.optionsStyle} borderRadius={2} mb={0.5}>
            <Grid container spacing={0.5}>
                {headerOptionsActivites.map((headerOption) => (
                    <Grid item xs={headerOption.xs} key={`AUTOCOPTIONOPT${headerOption.id}`} display="flex" justifyContent="center">
                        <TextContainer bgColor="none" p={0}>{option[headerOption.key]}</TextContainer>
                    </Grid>
                ))}
            </Grid>
        </Box> 
    );
}

const ListboxComponent = (props) => {
    return (
        <Box px={0.5} pb={0}>
            <Box py={0.5}>
                <Grid container spacing={0.5}>
                    {headerOptionsActivites.map((headerOption) => (
                        <Grid item xs={headerOption.xs} key={`AUTOCOPTIONHEAD${headerOption.id}`}>
                            <TextContainer bgColor={greenColor["015"]} p={0.75} height={"100%"} textWeight="bold" horizontalAlign="center" verticalAlign="center" textAlign="center" textDecoration={"none"}>{headerOption.title}</TextContainer>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <List {...props} />
        </Box>
    );
}

const PopperComponent = (props) => {
    return (
        <Popper {...props} placement="bottom-start" />
    );
}

const PaperComponent = (props) => {
    return (
        <Paper {...props} style={{minWidth: "max-content"}}  />
    );
}

const InputComponent = (props, name, label, errors, disabled, disabledText, tooltipPlacement, isLoading) => {
    return (
        disabled ? 
            <Tooltip 
                placement={tooltipPlacement} 
                arrow 
                PopperProps={{
                    sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: yellowColor[9], 
                            fontSize: 12, 
                            fontWeight: "bold"
                        }, 
                        [`& .${tooltipClasses.arrow}`]: {
                            color: yellowColor[9]
                        }
                    }
                }} 
                title={disabledText}>
                <TextField {...props} error={errors[name]} label={label} />
            </Tooltip>
        :
            <TextField 
                {...props} 
                error={errors[name]} 
                label={label} 
                InputProps={{
                    ...props.InputProps, 
                    endAdornment: (
                        <>
                            {isLoading && 
                                <Box mr={2}>
                                    <Loader size={17} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                                </Box>
                            }
                            {props.InputProps.endAdornment}
                        </>
                    )
                }}   
            />

    );
}

const NoOptions = (isError) => {
    return (
        isError ? 
            <Typography fontSize={"small"} color={redColor[9]}>Erreur de récupération des données</Typography> 
        : 
            <Typography fontSize={"small"}>Aucune donnée</Typography>
    );
}

const Loading = () => {
    return (
        <Box>
            <Loader size={17} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
        </Box>
    );
}

const optionsMapping = (option, value) => {
    return option.id === value.id
}

const labelsMapping = (option) => option.libelleActivite ? `${option.codeActivite} - ${option.libelleActivite}` : "";

const ActivitesAffectationsIndividuellesInput = (props) => {
    const { 
        name, 
        label, 
        codeSociete,
        date,
        tooltipPlacement
    } = props;
    const { control } = useFormContext();
    const { field } = useController({ name: name, control });
    const { errors } = useFormState({ control });
    const { data, isLoading, isFetching, isError } = useActAffIndInp(codeSociete, date);
    const disabled = !date || !codeSociete || !date?.isValid();
    const disabledText = !date ? "Vous devez selectionner une date" : "Vous devez selectionner une société";
    const onLoad = isLoading || isFetching;

    return (
        <Autocomplete 
            {...field} 
            fullWidth 
            label={label} 
            size="small" 
            options={data || []}  
            loading={onLoad}
            disabled={disabled}
            loadingText={<Loading />}
            noOptionsText={<NoOptions isError={isError} />}
            getOptionLabel={labelsMapping}      
            isOptionEqualToValue={optionsMapping}
            PopperComponent={(props) => PopperComponent(props)}
            ListboxProps={{sx: { [`&.${autocompleteClasses.listbox}`]: {p: 0}}}}
            PaperComponent={(props) => PaperComponent(props)}
            ListboxComponent={(props) => ListboxComponent(props)}
            renderInput={(props) => InputComponent(props, name, label, errors, disabled, disabledText, tooltipPlacement, onLoad)}
            renderOption={(props, option) =>  OptionsComponent(props, option)}
            onChange={(_, d) => field.onChange(d)}
        />
    );
}

ActivitesAffectationsIndividuellesInput.defaultProps = {
    label: "",
    codeSociete: "",
    date: null,
    tooltipPlacement: "top"
}

ActivitesAffectationsIndividuellesInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    codeSociete: PropTypes.string,
    date: PropTypes.object,
    tooltipPlacement: PropTypes.string,
}

const styles = {
    optionsStyle: {
        [`&.${autocompleteClasses.option}`]: {
            px: 0, 
            backgroundColor: greenColor["015"],
            '&:hover': {
                backgroundColor: greenColor["035"]
            },
            '&[aria-selected="true"]': {
                border: "1px solid",
                borderColor: greyColor[2],
                backgroundColor: greenColor["035"]
            } 
        }
    }
}

export default ActivitesAffectationsIndividuellesInput;