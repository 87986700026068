import { Check } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { MotifRefusDemandeRegularisationForm } from "../Forms";
import { DialogContainer, HeaderDialogContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";

const defaultValues = {
    motifRefus: ""
}

const DemandeRegularisationMotifRefusDialog = (props) => {
    const { open, isLoading, setOpen, refuserDemande } = props;
    const methods = useForm({ mode: "all", defaultValues: defaultValues });

    return (
        <DialogContainer open={open} placement="top" taille="sm">
            <HeaderDialogContainer 
                actionsLeft={
                    <IconButtonBase icon={<Check fontSize="small" />} title="Valider" disabled={isLoading} action={() => methods.handleSubmit(refuserDemande)()} />
                }
                title="Motif du refus"
                close={() => setOpen(false)}
            />
            <FormProvider {...methods}>
                <MotifRefusDemandeRegularisationForm isLoading={isLoading} />
            </FormProvider>
        </DialogContainer>
    );
}

DemandeRegularisationMotifRefusDialog.defaultProps = {
    open: false,
    isLoading: false,
    setOpen: () => {},
    refuserDemande: () => {}
}

DemandeRegularisationMotifRefusDialog.propTypes = {
    open: PropTypes.bool,
    isLoading: PropTypes.bool,
    setOpen: PropTypes.func,
    refuserDemande: PropTypes.func
}

export default DemandeRegularisationMotifRefusDialog;