import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dateCredits: "",
    filtered: false,
    tried: false,
    horaire: false,
    credits: [],
    creditsDisplayed: []
};

const creGesTemSlice = createSlice({
    name: 'creGesTem',
    initialState: initialState,
    reducers: {
        setCredits: (state, action) => {
            state.credits = action.payload;
            state.creditsDisplayed = action.payload;
        },
        setDateCredits: (state, action) => {
            state.dateCredits = action.payload;
        },
        filtrer: (state, action) => {
            state.creditsDisplayed = action.payload;
            state.filtered = true;
        },
        trier: (state, action) => {
            state.creditsDisplayed = action.payload;
            state.tried = true;
        },
        deleteFiltre: (state) => {
            state.creditsDisplayed = state.credits;
            state.filtered = false
        },
        deleteTrie: (state) => {
            state.creditsDisplayed = state.credits;
            state.tried = false
        },
        toggleHoraire: (state) => {
            state.horaire = !state.horaire;
        }
    }
})

const { actions, reducer } = creGesTemSlice
export const { 
    setCredits, 
    setDateCredits,
    deleteFiltre, 
    deleteTrie, 
    filtrer, 
    trier,
    toggleHoraire
} = actions
export default reducer
