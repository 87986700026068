import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    parametres: null,
};

const secVarAppSlice = createSlice({
    name: "secVarApp",
    initialState: initialState,
    reducers: {
        setSecureVariable: (state, action) => {
            state.parametres = action.payload;
        }
    }
})

const { actions, reducer } = secVarAppSlice
export const { setSecureVariable } = actions
export default reducer