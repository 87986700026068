import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { Download } from "@mui/icons-material";
import { selectCltApp, selectUsrApp } from "@selectors";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { Loader } from "@components/Loaders";
import { downloadFile } from "@api/file";
import { greenColor, greyColor } from "@styles";
import IconButtonBase from "@components/Buttons/IconButtonBase";



const DownloadButton = ({ 
    chemin = "", 
    nom = "", 
    variant = undefined
}) => {
    const [onUpload, setOnUpload] = useState(false);
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const { enqueueSnackbar } = useSnackbar();

    const download = useCallback(async (chemin, fichier) => {
        try {
            setOnUpload(true);
            const response = await downloadFile(chemin, fichier, stateUser.token, stateClient.client);
            const blob = new Blob([response.data], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);
    
            window.open(url, "_blank");
            setOnUpload(false);
        } catch(error) {
            enqueueSnackbar("Erreur de téléchargement.", { variant: "error" });
            setOnUpload(false);
        }
    }, [enqueueSnackbar, stateClient.client, stateUser.token]);

    return (
        onUpload ? 
            <Box width="100%" height={30} alignItems="center" display="flex" justifyContent="center">
                <Loader size={17} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        :       
            <IconButtonBase 
                variant={variant}
                icon={<Download fontSize="small" />} 
                title="Visualiser" 
                action={() => download(chemin, nom)} 
            />
                           
    );
}

DownloadButton.propTypes = {
    chemin: PropTypes.string,
    nom: PropTypes.string,
    variant: PropTypes.string
}

export default DownloadButton;