import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import moment from "moment";
import { verificationIdt, login, editLosPwd, editPwd, editQst, editPwdQst, compareRep } from "@api/security";
import { selectCltApp, selectUsrApp } from "@selectors";
import * as userActions from "@reducers/user/usrAppReducer";
import * as typTraAppActions from "@reducers/typesTravail/typTraAppReducer";
import i18next from "@translations/config";

export const useLogin = () => {
    const stateClient = useSelector(selectCltApp);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, mutateAsync } = useMutation(login);
    const { enqueueSnackbar } = useSnackbar();

    const loginActionner = async (data) => {
        try {
            const response = await mutateAsync(data);
            if(response.auth) {
                dispatch(userActions.setUser(response));
                dispatch(typTraAppActions.setTypesTravail(response.typesTravail));
                i18next.changeLanguage(response.lang);
                navigate(`/${stateClient.client}/portail`);
            } else {
                dispatch(userActions.setSecurity({password: data.password, token: response.token}));
                if(response.edtPassword) {
                    dispatch(userActions.openPassword(true));
                } else if(response.edtPasswordQuestion) {
                    dispatch(userActions.openQuestionPassword(true));          
                } else if(response.edtQuestion) {
                    dispatch(userActions.openQuestion(true));
                } 
                enqueueSnackbar(response.details, { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "center" } });
            }
        } catch(e) {
            enqueueSnackbar(e.response.data.details, { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "center" } });
        }
    }

    return { isLoadingLogin: isLoading, loginActionner };
}

export const useVerifierIdentifiant = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { isLoading, mutateAsync } = useMutation(verificationIdt);

    const verifierIdentifiantActionner = async (data) => {
        let isSucceed = false;
        try {
            const response = await mutateAsync(data);
            if(response.succeed) {
                dispatch(userActions.setIdentity({matricule: response.matricule, identifiant: data.identifiant, question: response.question, email: response.mail}));
                isSucceed = true;
            } else {
                enqueueSnackbar(data.details, { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "center" } })
            }
        } catch(e) {
            enqueueSnackbar(e.response.data.details, { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "center" } });
        }

        return { isSucceed };
    }

    return { isLoadingVerification: isLoading, verifierIdentifiantActionner };
}

export const useVerifierReponse = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { isLoading, mutateAsync } = useMutation(compareRep);

    const verifierReponseActionner = async (data) => {
        let isSucceed = false;
        try {
            const response = await mutateAsync(data);
            if(response.succeed) {
                dispatch(userActions.setReponse({token: response.token, reponse: data.reponse}));
                isSucceed = true;
                enqueueSnackbar(response.details, { variant: "success", anchorOrigin: { vertical: "bottom", horizontal: "center" } })
            } else {
                enqueueSnackbar(response.details, { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "center" } })
            }
        } catch(e) {
            if(e.response.status === 403) {
                enqueueSnackbar(e.response.data.details, { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "center" } });
            } else {
                enqueueSnackbar("Erreur lors de la verification de la réponse", { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "center" } });
            }
        }

        return { isSucceed };
    }

    return { isLoadingVerification: isLoading, verifierReponseActionner };
}

export const useLostPassword = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { loginActionner } = useLogin();
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const { isLoading, mutateAsync } = useMutation(editLosPwd);
    
    const editLostPwdActionner = async (data) => {
        try { 
            const response = await mutateAsync(data)
            if(response.succeed) {
                const dataLogin = {
                    identifiant: stateUser.identifiant, 
                    password: data.password,
                    mail: stateUser.email,
                    date: moment().format("DDMMYYYY"),
                    gestionTemps: "O",
                    client: stateClient.client
                };
                dispatch(userActions.openLostPassword(false));
                dispatch(userActions.setOnLogin(true));
                enqueueSnackbar("Votre mot de passe a été mis à jour.", { variant: "success", anchorOrigin: { vertical: "bottom", horizontal: "center" } });
                loginActionner(dataLogin);
            } else {
                enqueueSnackbar(response.details, { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "center" } })
            }
        } catch(e) {
            if(e.response.status === 403) {
                enqueueSnackbar(e.response.data.details, { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "center" } });
            } else {
                enqueueSnackbar("Erreur lors de la modification du mot de passe.", { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "center" } });
            }
        }
    }

    return { isLoadingLostPwd: isLoading, editLostPwdActionner };
}

export const usePassword = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { loginActionner } = useLogin();
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const { isLoading, mutateAsync } = useMutation(editPwd);

    const editPwdActionner = async (data) => {
        try {
            const response = await mutateAsync(data)
            if(response.succeed) {
                const dataLogin = {
                    identifiant: stateUser.identifiant, 
                    password: data.password,
                    mail: stateUser.email,
                    date: moment().format("DDMMYYYY"),
                    gestionTemps: "O",
                    client: stateClient.client
                };
                dispatch(userActions.openPassword(false));
                dispatch(userActions.setOnLogin(true));
                enqueueSnackbar("Votre mot de passe a été mis à jour.", { variant: "success", anchorOrigin: { vertical: "bottom", horizontal: "center" } });
                loginActionner(dataLogin);
            } else {
                enqueueSnackbar(response.details, { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "center" } })
            }
        } catch(e) {
            if(e.response.status === 403) {
                enqueueSnackbar(e.response.data.details, { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "center" } });
            } else {
                enqueueSnackbar("Erreur lors de la modification du mot de passe.", { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "center" } });
            }
        }
    }

    return { isLoadingPwd: isLoading, editPwdActionner };
}

export const usePasswordParametres = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { isLoading, mutateAsync } = useMutation(editPwd);

    const editPwdActionner = async (data) => {
        let isSucceed = false;
        try {
            const response = await mutateAsync(data);
            if(response.succeed) {
                isSucceed = true;
                enqueueSnackbar("Votre mot de passe a été mis à jour.", { variant: "success", anchorOrigin: { vertical: "bottom", horizontal: "center" } });
            } else {
                enqueueSnackbar(response.details, { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "center" } })
            }
        } catch(e) {
            if(e.response.status === 403) {
                enqueueSnackbar(e.response.data.details, { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "center" } });
            } else {
                enqueueSnackbar("Erreur lors de la verification du mot de passe.", { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "center" } });
            }
        }

        return { isSucceed };
    }

    return { isLoadingPwd: isLoading, editPwdActionner };
}

export const useQuestion = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { loginActionner } = useLogin();
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const { isLoading, mutateAsync } = useMutation(editQst);
    
    const editQstActionner = async (data) => {
        try {
            const response = await mutateAsync(data);
            if(response.succeed) {
                const dataLogin = {
                    identifiant: stateUser.identifiant, 
                    password: data.password,
                    mail: stateUser.email,
                    date: moment().format("DDMMYYYY"),
                    gestionTemps: "O",
                    client: stateClient.client
                };
                dispatch(userActions.openQuestion(false));
                dispatch(userActions.setOnLogin(true));
                enqueueSnackbar("Votre question et votre réponse de récupération ont été mis à jour.", { variant: "success" });
                loginActionner(dataLogin);
            } else {
                enqueueSnackbar(response.details, { variant: "error" })
            }
        } catch(e) {
            if(e.response.status === 403) {
                enqueueSnackbar(e.response.data.details, { variant: "error" });
            } else {
                enqueueSnackbar("Erreur lors de la modification de la question et de la réponse de récupération.", { variant: "error" });
            }
        }
    }

    return { isLoadingQst: isLoading, editQstActionner };
};


export const useQuestionParametres = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { isLoading, mutateAsync } = useMutation(editQst);

    const editQstActionner = async (data) => {
        let isSucceed = false;
        try {
            const response = await mutateAsync(data);
            if(response.succeed) {
                isSucceed = true;
                enqueueSnackbar("Votre question et votre réponse de récupération ont été mis à jour.", { variant: "success" });
            } else {
                enqueueSnackbar(data.details, { variant: "error" })
            }
        } catch(e) {
            if(e.response.status === 403) {
                enqueueSnackbar(e.response.data.details, { variant: "error" });
            } else {
                enqueueSnackbar("Erreur lors de la modification de la question et de la réponse de récupération.", { variant: "error" });
            }
        }

        return { isSucceed };
    }

    return { isLoadingQst: isLoading, editQstActionner };
}

export const useQuestionPassword = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { loginActionner } = useLogin();
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const { isLoading, mutateAsync } = useMutation(editPwdQst);

    const editPwdQstActionner = async (data) => {
        try {
            const response = await mutateAsync(data);
            if(response.succeed) {
                const dataLogin = {
                    identifiant: stateUser.identifiant, 
                    password: data.password,
                    mail: stateUser.email,
                    date: moment().format("DDMMYYYY"),
                    gestionTemps: "O",
                    client: stateClient.client
                };
                dispatch(userActions.openQuestion(false));
                dispatch(userActions.setOnLogin(true));
                enqueueSnackbar("Votre question, votre réponse de récupération et votre mot de passe ont été mis à jour.", { variant: "success" });
                loginActionner(dataLogin);
            } else {
                enqueueSnackbar(response.details, { variant: "error" })
            }
        } catch(e) {
            if(e.response.status === 403) {
                enqueueSnackbar(e.response.data.details, { variant: "error" });
            } else {
                enqueueSnackbar("Erreur lors de la modification de la question, de la réponse de récupération et du mot de passe.", { variant: "error" });
            }
        }
    }

    return { isLoadingPwdQst: isLoading, editPwdQstActionner };
}