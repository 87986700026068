import { useSelector, useDispatch } from "react-redux";
import { Typography, Stack, Box, Drawer } from "@mui/material";
import { Logout, Menu } from "@mui/icons-material";
import { selectUsrApp } from "@selectors";
import * as userActions from "@reducers/user/usrAppReducer";
import NotificationsManagement from "@components/App/NotificationsManagement";
import { useAddTab } from "@hooks/tabs/hooksUtils";
import { ButtonBase, IconButtonBase } from "@components/Buttons";
import { optionsMenuParametres } from "@constants/appConstants";
import { routePolices } from "@constants/policesConstants";
import { greenColor, greyColor, whiteColor } from "@styles";
import { useMedia } from "react-use";
import { useTranslation } from "react-i18next";
import { CODE_ACTION } from "@constants/tabsConstants";

const TopBar = () => {
	const stateUser = useSelector(selectUsrApp);
	const dispatch = useDispatch();
	const addTabActionner = useAddTab();	
	const isMobile = useMedia('(max-width: 650px)')
	const isTabletPortrait = useMedia('(min-width: 651px) and (max-width: 1500px)');
	const isTabletLandscape = useMedia('(min-width: 900px) and (max-width: 1500px)');
    const isLarge = useMedia('(min-width: 1501px)');
	const { t } = useTranslation("global");
	const noBackground = stateUser.background === "image0";

    return (
        <Box height={64} display="flex" alignItems="center" bgcolor={noBackground ? greyColor[2] : greenColor["1"]} color={whiteColor[9]}>
            <Stack width="100%" direction="row" alignItems="center">
                {(isTabletLandscape) && 
					<Box px={2}>
						<IconButtonBase 
							variant="dialogButton" 
							icon={<Menu />}
							onClick={() => dispatch(userActions.openMenu(true))} 
						/>
					</Box>
				}
				<Box 
					width={isLarge ? 300 : 240} 
					borderRight={isMobile ? "none" : "1px solid"} 
					flexGrow={(isTabletPortrait || isLarge) ? 0 : 1}
				>
					<Box textAlign={isMobile ? "left" : "center"} px={2}>
						<Typography fontSize={17} fontFamily={routePolices[stateUser.police]} noWrap component="div">
							{isMobile ? t("titles.app_topbar_mobile") : t("titles.app_topbar_desktop")}
						</Typography>
					</Box>
				</Box>
                {(isTabletPortrait || isLarge) && 
					<Box flexGrow={1} px={2}>
						<Box>
							<Typography variant="h6" fontFamily={routePolices[stateUser.police]} noWrap component="div">
								{ stateUser.prenom + " " + stateUser.nom }
							</Typography>
						</Box>
					</Box>
				}
				<Stack direction="row" alignItems="center" px={1}>
					<NotificationsManagement />
					<Box px={0.5}>
						<IconButtonBase 
							variant="dialogButton"
							icon={
								<Box width={30} height={30} display="flex" alignItems="center" justifyContent="center">
									<Typography fontWeight="bold">CL</Typography>
								</Box>
							}
							title="Paramètres"
							action={() => dispatch(userActions.openMenuParametres(true))}
						/>
					</Box>
					<Drawer 
						variant="temporary" 
						anchor={isMobile ? "bottom" : "right"} 
						open={stateUser.openMenuParametres} 
						onClose={() => dispatch(userActions.openMenuParametres(false))} 
						PaperProps={{ sx: { backgroundColor: "transparent", p: 1, boxSizing: "border-box" }}} 
						sx={{zIndex: (theme) => theme.zIndex.drawer + 1}} 
						elevation={0}
					>
						<Box width={isMobile ? "100%" : 200} bgcolor={whiteColor[9]} overflow="auto" borderRadius={2}>
							<Stack spacing={0.5} p={0.5}>
								{optionsMenuParametres.map((option) => (
									<ButtonBase 
										key={`MENUPARAM${option.id}`}
										variant="green25Grey5Green7" 
										fullWidth
										startIcon={option.icon} 
										alignement="left"
										action={() => addTabActionner(option.action)} textAlign="left"
									>
										{t(option.label)}
									</ButtonBase>
								))}
								<ButtonBase 
									variant="red2Grey5Red5" 
									fullWidth
									startIcon={<Logout fontSize="small"/>} 
									alignement="left"
									action={() => addTabActionner(CODE_ACTION.DECONNEXION)} textAlign="left"
								>
									{t("buttons.logout")}
								</ButtonBase>
							</Stack>
						</Box>
					</Drawer>
				</Stack>
            </Stack>
        </Box>
    );
}

export default TopBar;