import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    horairesAffectations: []
};

const horAffGesTemSlice = createSlice({
    name: 'horAffGesTem',
    initialState: initialState,
    reducers: {
        setHorairesAffectations: (state, action) => {
            state.horairesAffectations = action.payload;
        },
        reset: () => initialState
    }
})

const { actions, reducer } = horAffGesTemSlice
export const { setHorairesAffectations, reset } = actions
export default reducer
