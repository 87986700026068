import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack } from "@mui/material";
import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { selectDsh, selectUsrApp } from "@selectors";
import * as userActions from "@reducers/user/usrAppReducer"
import { IconButtonBase } from "@components/Buttons";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor } from "@styles";
import { DialogContainer, HeaderDialogContainer } from "@components/Containers";
import { useCnfPos } from "@hooks/dashboard/hooksQueries";
import { ShortcutsActivesList, WidgetsActivesList } from "../Lists";
import { useTranslation } from "react-i18next";

const ConfigurationDialog = ({ 
    openMenuWidgets = () => {}, 
    refetch = () => {}
}) => {   
    const stateDashboard = useSelector(selectDsh);
    const stateUser = useSelector(selectUsrApp);
    const { isLoading, postConfigurationActionner } = useCnfPos();
    const dispatch = useDispatch();
    const { t } = useTranslation("global");

    const close = useCallback(async () => {
        dispatch(userActions.openConfiguration(false));
        refetch();
    }, [dispatch, refetch]);

    const save = useCallback(async () => {
        dispatch(userActions.openConfiguration(false));
        const data = { token: stateUser.token, shortcuts: stateDashboard.shortcutsConfiguration, widgets: stateDashboard.widgetsConfiguration };
        const { isSuccess } = await postConfigurationActionner(data);
        if(isSuccess) {
            refetch();
        }
    }, [dispatch, postConfigurationActionner, refetch, stateDashboard.shortcutsConfiguration, stateDashboard.widgetsConfiguration, stateUser.token]);

    return (
        <DialogContainer open={stateUser.openConfiguration} placement="top" taille="lg">
            <HeaderDialogContainer 
                actionsLeft={
                    <IconButtonBase 
                        variant="dialogButton"
                        icon={<Check fontSize="small" />} 
                        title={t("buttons.save_configuration")} 
                        action={save} 
                    />
                }
                title={t("titles.configuration")}
                close={close}
            />
            {isLoading ? 
                <Box p={2}>
                    <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                </Box> 
            : 
                <Stack spacing={0.5}>
                    <ShortcutsActivesList />
                    <WidgetsActivesList openMenu={openMenuWidgets} />
                </Stack>
            }
        </DialogContainer>
    );
}

ConfigurationDialog.propTypes = {
    openMenuWidgets: PropTypes.func,
    refetch: PropTypes.func
}

export default ConfigurationDialog;