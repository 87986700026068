import { useCallback, useState } from "react";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import { TabContentContainer } from "@components/Containers";
import DemandesAbsence from "@components/GestionAbsences/Collaborateur/Demandes/Absences/DemandesAbsence";
import { CODE_TABS_ABSENCES } from "@constants/tabsConstants";
import { greenColor, greyColor, whiteColor } from "@styles";
import DemandesAnnulation from "@components/GestionAbsences/Collaborateur/Demandes/Annulations/DemandesAnnulation";
import { useTranslation } from "react-i18next";

const Demandes = () => {
    const [activeTab, setActiveTab] = useState(CODE_TABS_ABSENCES.TAB_DEMANDE_ABSENCE);
    const { t } = useTranslation("global");

    const handleTabs = useCallback((_, tab) => {
        setActiveTab(tab);
    }, []);

    return (
        <Box bgcolor={whiteColor[9]} borderRadius={2} p={0.5} height="100%" boxSizing="border-box">       
            <Stack spacing={0.5} height={"100%"}>
                <Box width={"100%"}>
                    <Stack direction="row">
                        <Box width="100%">
                            <Tabs value={activeTab} onChange={handleTabs} 
                                indicatorColor=""
                                sx={styles.tabsStyles} 
                                centered
                            >
                                <Tab label={t("titles.absence_request")} value={CODE_TABS_ABSENCES.TAB_DEMANDE_ABSENCE} sx={styles.tabStyle} />
                                <Tab label={t("titles.cancel_request")} value={CODE_TABS_ABSENCES.TAB_DEMANDE_ANNULATION_ABSENCE} sx={styles.tabStyle} />
                            </Tabs>
                        </Box>
                    </Stack>
                </Box>
                <TabContentContainer value={activeTab} id={CODE_TABS_ABSENCES.TAB_DEMANDE_ABSENCE}>
                    <DemandesAbsence />
                </TabContentContainer>
                <TabContentContainer value={activeTab} id={CODE_TABS_ABSENCES.TAB_DEMANDE_ANNULATION_ABSENCE}>
                    <DemandesAnnulation /> 
                </TabContentContainer>
            </Stack>
        </Box>
    );
}

const styles = {
    tabStyle: {
        color: greyColor[9],
        fontWeight: "bold",
        marginRight: 0.5,
        backgroundColor: whiteColor[9],
        minHeight: 40,
        padding: 0,
        border: "none",
        borderRadius: 2,
        flexGrow: 1,
        textTransform: "none",
        "&:hover": {
            backgroundColor: greenColor["07"],
            color: whiteColor[9]
        },
        "&.Mui-selected": {
            backgroundColor: greenColor["07"],
            color: whiteColor[9]
        }
    },
    tabsStyles: {
        height: 40,
        minHeight: "",
        paddingLeft: 0.5,
        paddingY: 0.5,
        backgroundColor: greenColor["015"], 
        borderRadius: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    tabsIndicatorStyle: {
        sx: {
            display: "none", 
            height: 0
        }
    },
    paperStyle: {
        background: "none",
        width: "100%",
        height: "100%"
    },
    boxBackgroundStyle: {
        backgroundColor: whiteColor[9],
        padding: 1,
        borderRadius: 2,
        boxSizing: "border-box",
        height: "100%"
    }
};

export default Demandes;