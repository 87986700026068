import { Box, Skeleton, Typography } from "@mui/material";
import PropTypes from "prop-types";
import moment from "moment";
import { greenColor, greyColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const variants = {
    solde: { bg1: greenColor["02"], bg2: greenColor["05"], color: whiteColor[9] },
    absenceManager: { bg1: whiteColor[2], bg2: whiteColor[7], color: greyColor[9] }
}

const SoldesText = ({
    isLoading = true, 
    periode = "",
    variant = "solde"
}) => {
    const { t } = useTranslation("global");

    if(isLoading) {
        return (
            <Box bgcolor={greenColor["02"]} borderRadius={2} p={0.3}>
                <Box bgcolor={greenColor["05"]} borderRadius={2} p={1}>
                    <Skeleton variant="rounded" sx={{ borderRadius: 2 }} animation="wave" width="100%" height={19.5} />
                </Box>
            </Box>
        );
    }

    return (
        periode && 
            <Box bgcolor={variants[variant]?.bg1} borderRadius={2} p={0.3}>
                <Box bgcolor={variants[variant]?.bg2} borderRadius={2} p={1}>
                    <Typography fontSize="small" fontWeight="bold" color={variants[variant]?.color}>
                        {t("texts.solde_situation_date_1", { date: moment(periode, "MMYYYY").format("MMMM YYYY")})} 
                    </Typography>
                </Box>
            </Box>
    )
}

SoldesText.propTypes = {
    isLoading: PropTypes.bool, 
    periode: PropTypes.string,
    variant: PropTypes.string
}

export default SoldesText;