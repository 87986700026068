import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { HeaderPanelContainer } from "@components/Containers";
import { ChevronLeft, Refresh } from "@mui/icons-material";
import { PAGES_GESTION_ABSENCES } from "@constants/demandesAbsenceConstants";

const MultiSoldesActionsBar = ({ 
    isLoading = true,
    goTo = () => {},
    refetch = () => {}
}) => {
    return (
        <HeaderPanelContainer 
            actionsLeft={
                <IconButtonBase 
                    variant="white5Grey9White9"
                    icon={<ChevronLeft fontSize="small" />} 
                    title="Retour" 
                    disabled={isLoading}
                    action={() => goTo(PAGES_GESTION_ABSENCES.LIST)} 
                />                           
            }
            actionsRight={
                <IconButtonBase 
                    variant="white5Grey9White9"
                    icon={<Refresh fontSize="small" />} 
                    title="Rafraichir" 
                    disabled={isLoading}
                    action={() => refetch()} 
                />                           
            }
            variant="green07"
        />
    );
}

MultiSoldesActionsBar.propTypes = {
    isLoading: PropTypes.bool, 
    goTo: PropTypes.func,
    refetch: PropTypes.func
}

export default MultiSoldesActionsBar;