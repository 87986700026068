import { useCallback } from "react";
import { useController, useFormContext, useFormState } from "react-hook-form";
import { Box, Stack, TextField, Typography } from "@mui/material";
import MaskedInput from "react-text-mask";
import { greyColor, redColor, whiteColor } from "@styles";
import voca from "voca"
import PropTypes from "prop-types";

const sizeMapping = {
    xsmall: { muiLabel: "small", height: 32, top: "-5px", topShrink: 0 },
    small: { muiLabel: "small", height: undefined, top: undefined, topShrink: undefined }
}

export const TimeMaskedHookFormInput = (props) => {
    const { name, label, size, disabled, displayError, displayBgColorError } = props;
    const { control } = useFormContext();
    const { field } = useController({ name, control });
    const { errors } = useFormState({ control });

    const handleChange = useCallback((e) => {
        let value = voca.replaceAll(e.target.value, ":", "");
        field.onChange(value);
    }, [field]);

    return (                   
        <Stack sx={styles.stackInputStyle}>
            <Box sx={styles.boxInputStyle}>
                <MaskedInput 
                    {...field} 
                    ref={ref => {field.ref(ref ? ref.inputElement : null)}} 
                    mask={[/^([0-2])/, /\d/, ":", /[0-5]/, /\d/]} 
                    render={(ref, props) => (
                        <TextField 
                            inputRef={ref}
                            {...props}
                            fullWidth 
                            label={label} 
                            size="small"
                            autoFocus={false} 
                            InputProps={{ sx: { height: sizeMapping[size].height, bgcolor: !!errors[name] && displayBgColorError ? redColor[2] : undefined } }}
                            InputLabelProps={{ sx: { top: sizeMapping[size].top, "&[data-shrink='true']": { top: sizeMapping[size].topShrink } } }}
                            disabled={disabled}
                            error={!!errors[name]}
                        />
                    )}
                    onChange={handleChange}
                />
            </Box>
            {(errors[name] && displayError) && 
                <Box sx={styles.boxInputErrorStyle}>
                    <Typography sx={styles.typoInputErrorStyle}>{errors[name].message}</Typography>
                </Box>
            }
        </Stack>
    );
}

TimeMaskedHookFormInput.defaultProps = {
    label: "",
    size: "small",
    displayError: false,
    displayBgColorError: true,
    disabled: false
}

TimeMaskedHookFormInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    size: PropTypes.oneOf(["xsmall", "small"]),
    displayError: PropTypes.bool,
    displayBgColorError: PropTypes.bool,
    disabled: PropTypes.bool
}

const styles = {
    stackInputStyle: {
        borderRadius: 2,
        backgroundColor: redColor[1]
    },
    boxInputStyle: {
        borderRadius: 2,
        backgroundColor: whiteColor[9]
    },
    boxInputErrorStyle: {
        textAlign: "left",
        borderBottomLeftRadius: 8, 
        borderBottomRightRadius: 8,
        padding: 1
    },
    typoInputErrorStyle: {
        fontSize: "small", 
        color: greyColor[5]
    }
}

export default TimeMaskedHookFormInput;