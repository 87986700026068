import axios from "axios";
import { urls } from "@constants/appConstants";

export const fetchDrgCol = async (matricule, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetDemandesRegularisations"
    };
    const body = {
        matricule: matricule
    };
    const response = await axios.post(urls.getDemandesRegularisationsCollaborateur, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.demandesRegularisation;
}

export const fetchDrgMan = async (utilisateur, matriculeHierarchique, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetDemandesRegularisationsManager"
    };
    const body = {
        utilisateur: utilisateur,
        matriculeHierarchique: matriculeHierarchique
    };
    const response = await axios.post(urls.getDemandesRegularisationsManager, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.demandesRegularisation;
}

export const fetchDetDrgCol = async (matricule, typePersonnel, jourDemande, moisDemande, anneeDemande, heureDemande, jourRattachement, moisRattachement, anneeRattachement, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetDetailDemandeRegularisationCollaborateur"
    };
    const body = {
        matricule: matricule,
        typePersonnel: typePersonnel,
        jourDemande: jourDemande,
        moisDemande: moisDemande,
        anneeDemande: anneeDemande,
        heureDemande: heureDemande,
        jourRattachement: jourRattachement,
        moisRattachement: moisRattachement,
        anneeRattachement: anneeRattachement
    };
    const response = await axios.post(urls.getDetailDemandeRegularisationCollaborateur, body, { headers: headers, params: { client: client }, signal: signal });
    return { transactions: response.data.transactions, regularisations: response.data.regularisationsDemandees, suivi: response.data.suivi };
}

export const fetchDetDrgMan = async (matricule, personnel, jourDemande, moisDemande, anneeDemande, heureDemande, jourRattachement, moisRattachement, anneeRattachement, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetDetailDemandeRegularisationManager"
    };
    const body = {
        matricule: matricule,
        typePersonnel: personnel,
        jourDemande: jourDemande,
        moisDemande: moisDemande,
        anneeDemande: anneeDemande,
        heureDemande: heureDemande,
        jourRattachement: jourRattachement,
        moisRattachement: moisRattachement,
        anneeRattachement: anneeRattachement
    };
    const response = await axios.post(urls.getDetailDemandeRegularisationManager, body, { headers: headers, params: { client: client }, signal: signal });
    return {transactions: response.data.transactions, regularisations: response.data.regularisationsDemandees, suivi: response.data.suivi};
}

export const addDrg = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostDemandeRegularisationCollaborateur"
    };
    const body = {
        matricule: data.matricule,
        dateDemande: data.dateDemande,
        motifDemande: data.motifDemande,
        niveauValidation: data.niveauValidation,
        codeRetour: data.codeRetour, 
        regularisations: data.regularisations
    };
    const response = await axios.post(urls.postDemandeRegularisationCollaborateur, body, { headers: headers, params: { client: data.client } });
    return response.data;
}

export const deleteDrg = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteDeleteDemandeRegularisation"
    };
    const body = {
        utilisateur: data.utilisateur,
        matricule: data.matricule,
        dateDemande: data.date,
        heureDemande: data.heure
    };
    
    const response = await axios.post(urls.deleteDemandeRegularisation, body, { headers: headers, params: { client: data.client } });
    return response.data;
}

export const editRepDrg = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostReponseDemandeRegularisation"
    };
    const body = {
        matriculeHierarchique: data.matriculeHierarchique,
        matriculeDemandeur: data.matriculeDemandeur,
        dateDemande: data.dateDemande,
        heureDemande: data.heureDemande,
        reponse: data.reponse,
        motifRefus: data.motifRefus,
        niveauValidation: data.niveauValidation,
        numeroEnregistrement: data.numeroEnregistrement
    };
    const response = await axios.post(urls.postReponseDemandeRegularisationManager, body, { headers: headers, params: { client: data.client } });
    return response.data;
}