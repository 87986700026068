import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Grid, Stack } from "@mui/material";
import { Check, FilterListOff } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { filter, map, select, tidy } from "@tidyjs/tidy";
import moment from "moment";
import * as colPlaGesTemActions from "@reducers/collaborateurs/colPlaGesTemReducer";
import { useStateCollaborateurs } from "@hooks/collaborateurs/hooksStates";
import { IconButtonBase } from "@components/Buttons";
import { filtrerFwgtp3 } from "../utils";
import { TYPES_COLLABORATEURS } from "@constants/collaborateursConstants";
import { whiteColor } from "@styles";
import { HorairesHookFormInput } from "@components/Inputs/Horaires";
import { SocietesHookFormInput } from "@components/Inputs/Societes";
import { EtablissementsHookFormInput } from "@components/Inputs/Etablissements";
import { DirectionsHookFormInput } from "@components/Inputs/Directions";
import { ServicesHookFormInput } from "@components/Inputs/Services";
import { SecteursHookFormInput } from "@components/Inputs/Secteurs";
import { ActivitesHookFormInput } from "@components/Inputs/Activites";
import { CategoriesProfessionnellesHookFormInput } from "@components/Inputs/CategoriesProfessionnelles";
import { AutocompleteInput } from "@components/Inputs";

const defaultValues = {
    matricule: null,
    nom: null,
    badge: null,
    societe: null,
    etablissement: "",
    direction: "",
    service: "",
    secteur: "",
    categorieProfessionnelle: "",
    horaire: "",
    activite: "",
    controleHoraire: null,
};

const optionsBooleens = [
    {id: 1, value: "O", label: "Oui"},
    {id: 2, value: "N", label: "Non"}
];

const CollaborateursFwgtp3Form = (props) => {
    const { type } = props;
    const dispatch = useDispatch();
    const methods = useForm({ mode: "all", defaultValues: defaultValues });
    const stateCollaborateurs = useStateCollaborateurs(type);
    const [noms, setNoms] = useState([]);
    const [matricules, setMatricules] = useState([]);
    const [badges, setBadges] = useState([]);

    const handleSocietes = useCallback((d) => {
        methods.resetField("etablissement", "");
        methods.resetField("direction", "");
        methods.resetField("service", "");
        methods.resetField("activite", "");
        methods.resetField("horaire", "");
    }, [methods]);

    useEffect(() => {
        if(stateCollaborateurs.collaborateurs) {
            const nomsOptions = tidy(stateCollaborateurs.collaborateurs, select("prenomNom"), map((d, id) => ({id: id + 1, value: d.prenomNom, label: d.prenomNom})), filter(d => d.prenomNom !== ""));
            const matriculesOptions = tidy(stateCollaborateurs.collaborateurs, select("matricule"), map((d, id) => ({id: id + 1, value: d.matricule, label: d.matricule})), filter(d => d.matricule !== ""));
            const badgesOptions = tidy(stateCollaborateurs.collaborateurs, select("badge"), map((d, id) => ({id: id + 1, value: d.badge, label: d.badge})), filter(d => d.badge !== ""));
            setNoms(nomsOptions);
            setMatricules(matriculesOptions);
            setBadges(badgesOptions);
        }
    }, [stateCollaborateurs.collaborateurs]);

    const filtrer = useCallback((data) => {
        const collaborateursBrut = stateCollaborateurs.collaborateurs;
        const collaborateursFiltered = filtrerFwgtp3(data, collaborateursBrut);
        if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_PLANNING) {            
            dispatch(colPlaGesTemActions.filtrer(collaborateursFiltered));
        }
    }, [dispatch, stateCollaborateurs.collaborateurs, type]);

    const resetFiltre = useCallback(() => {
        methods.reset();
        if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_PLANNING) {
            dispatch(colPlaGesTemActions.resetFiltre());
        }
    }, [dispatch, methods, type])

    return (
        <FormProvider {...methods}>
            <Stack spacing={0.25}>
                <Box>
                    <Grid container spacing={0.75}>
                        <Grid container item xs={11}>
                            <Grid container spacing={0.75} pb={0.75}>
                                <Grid item xs={3}>
                                    <AutocompleteInput name={"nom"} label="Nom" options={noms} labelized />
                                </Grid>
                                <Grid item xs={3}>
                                    <AutocompleteInput name={"matricule"} label="Matricule" options={matricules} labelized />
                                </Grid>
                                <Grid item xs={3}>
                                    <AutocompleteInput name={"badge"} label="Badge" options={badges} labelized />
                                </Grid>
                                <Grid item xs={3}>
                                    <AutocompleteInput name={"controleHoraire"} label="Contrôle Horaire ?" options={optionsBooleens} labelized />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0.75} pb={0.75}> 
                                <Grid item xs={3}>
                                    <SocietesHookFormInput action={handleSocietes} />
                                </Grid>
                                <Grid item xs={6}>
                                    <EtablissementsHookFormInput codeSociete={methods.watch("societe")?.codeSociete} />
                                </Grid>
                                <Grid item xs={3}>
                                    <DirectionsHookFormInput codeSociete={methods.watch("societe")?.codeSociete} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0.75}>
                                <Grid item xs={3}>
                                    <ServicesHookFormInput codeSociete={methods.watch("societe")?.codeSociete} />
                                </Grid>
                                <Grid item xs={3}>
                                    <SecteursHookFormInput />
                                </Grid>
                                <Grid item xs={3}>
                                    <ActivitesHookFormInput codeSociete={methods.watch("societe")?.codeSociete} />                            
                                </Grid>
                                <Grid item xs={3}>
                                    <CategoriesProfessionnellesHookFormInput />                      
                                </Grid>
                                <Grid item xs={3}>
                                    <HorairesHookFormInput name="horaire" label="Horaire" codeSociete={methods.watch("societe")?.codeSociete} date={stateCollaborateurs.dateValidite ? moment(stateCollaborateurs.dateValidite, "DDMMYYYY") : ""} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <Box bgcolor={whiteColor[7]} borderRadius={2} width={"100%"} height={"100%"} display="flex" alignItems="center" justifyContent="center">
                                <IconButtonBase icon={<FilterListOff fontSize="small" />} title="Reset filtres" action={() => resetFiltre()} /> 
                                <IconButtonBase icon={<Check fontSize="small" />} title="Valider" action={() => methods.handleSubmit(filtrer)()} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
        </FormProvider>
    );
}

CollaborateursFwgtp3Form.propTypes = {
    type: PropTypes.string.isRequired
}

export default CollaborateursFwgtp3Form;