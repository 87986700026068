import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { usePlanGesTemDay } from "@hooks/gestionTemps/planning/hooksUtils";
import { heurify } from "@utils";
import { CheckHookFormInput } from "@components/Inputs";
import { blueColor, greenColor, greyColor, redColor, whiteColor, yellowColor } from "@styles";
import { PlanningTimeInput, PlanningTypeTravailInput } from "@components/Inputs/GestionTemps";
import { TextContainer } from "@components/Containers";
import { HoraireMenuButton } from "@components/Buttons/Horaires";
import { Check, Close, Edit } from "@mui/icons-material";
import { useState } from "react";

const PlanningItemsTableMobile = (props) => {
    const { idJour, libelle, modelize } = props; 
    const { 
        colorHoraire, 
        horaire, 
        absence, 
        totalHeuresAbsence,
        totalHeures,
        authorized,
        authorizedTypeTravail,
        typesTravails,
        plages
    } = usePlanGesTemDay(idJour);
    const [open, setOpen] = useState(false);

    return (
        <>
            <Stack spacing={0.25} bgcolor={whiteColor[5]} borderRadius={2} p={0.25}>            
                <Box bgcolor={greenColor["045"]} borderRadius={2} px={1} py={0.5} textAlign="center">
                    <Typography fontSize="small" fontWeight="bold">{libelle}</Typography>
                </Box>
                <Stack direction="row" spacing={0.5} bgcolor={greenColor["015"]} borderRadius={2} px={1} py={0.5}>
                    <Box>
                        <Typography fontSize="small" color={colorHoraire}>Horaire : {horaire}</Typography>
                    </Box>
                    {absence && 
                        <Stack direction="row" spacing={0.5}>
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <Typography fontSize="small"> | Absence : {absence}</Typography>
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <Typography fontSize="small">{heurify(totalHeuresAbsence, "h", false)}</Typography>
                            </Box>
                        </Stack>
                    }
                </Stack>
                {(heurify(plages.debut1) || heurify(plages.arret1)) &&
                    <Stack direction="row" spacing={0.25} bgcolor={yellowColor[3]} borderRadius={2} p={0.5}>
                        <Stack direction="row" bgcolor={greyColor[0]} spacing={0.5} borderRadius={2} px={1}>      
                            <TextContainer bgColor="none" verticalAlign="center" textSize={12} p={0.5} noWrap>{heurify(plages.debut1)}</TextContainer>
                            <TextContainer bgColor="none" verticalAlign="center" textSize={12} p={0.5} noWrap>-</TextContainer>
                            <TextContainer bgColor="none" verticalAlign="center" textSize={12} p={0.5} noWrap>{heurify(plages.arret1)}</TextContainer>
                        </Stack>
                        <Box width="100%" height="100%" bgcolor={greyColor[0]} borderRadius={2} display="flex" alignItems="center" justifyContent="center">
                            <TextContainer bgColor="none" verticalAlign="center" textSize={12} p={0.5} noWrap>{typesTravails.plage1}</TextContainer>
                        </Box>
                    </Stack>
                }
                {(heurify(plages.debut2) || heurify(plages.arret2)) &&
                    <Stack direction="row" spacing={0.25} bgcolor={blueColor["03"]} borderRadius={2} p={0.5}>
                        <Stack direction="row" bgcolor={greyColor[0]} spacing={0.5} borderRadius={2} px={1}>               
                            <TextContainer bgColor="none" verticalAlign="center" textSize={12} p={0.5} noWrap>{heurify(plages.debut2)}</TextContainer>
                            <TextContainer bgColor="none" verticalAlign="center" textSize={12} p={0.5} noWrap>-</TextContainer>
                            <TextContainer bgColor="none" verticalAlign="center" textSize={12} p={0.5} noWrap>{heurify(plages.arret2)}</TextContainer>
                        </Stack>   
                        <Box width="100%" height="100%" bgcolor={greyColor[0]} borderRadius={2} display="flex" alignItems="center" justifyContent="center">
                            <TextContainer bgColor="none" verticalAlign="center" textSize={12} p={0.5} noWrap>{typesTravails.plage2}</TextContainer>
                        </Box>
                    </Stack>
                }
                {(heurify(plages.debut3) || heurify(plages.arret3)) &&      
                    <Stack direction="row" spacing={0.25} bgcolor={blueColor["03"]} borderRadius={2} p={0.5}>
                        <Stack direction="row" bgcolor={greyColor[0]} spacing={0.5} borderRadius={2} px={1}>      
                            <TextContainer bgColor="none" verticalAlign="center" textSize={12} p={0.5} noWrap>{heurify(plages.debut3)}</TextContainer>
                            <TextContainer bgColor="none" verticalAlign="center" textSize={12} p={0.5} noWrap>-</TextContainer>
                            <TextContainer bgColor="none" verticalAlign="center" textSize={12} p={0.5} noWrap>{heurify(plages.arret3)}</TextContainer>
                        </Stack>
                    
                        <Box width="100%" height="100%" bgcolor={greyColor[0]} borderRadius={2} display="flex" alignItems="center" justifyContent="center">
                            <TextContainer bgColor="none" verticalAlign="center" textSize={12} p={0.5} noWrap>{typesTravails.plage3}</TextContainer>
                        </Box>
                    </Stack>
                }
                {(heurify(plages.debut4) || heurify(plages.arret4)) &&      
                    <Stack direction="row" spacing={0.25} bgcolor={yellowColor[3]} borderRadius={2} p={0.5}>
                        <Stack direction="row" bgcolor={greyColor[0]} spacing={0.5} borderRadius={2} px={1}>      
                            <TextContainer bgColor="none" verticalAlign="center" textSize={12} p={0.5} noWrap>{heurify(plages.debut4)}</TextContainer>
                            <TextContainer bgColor="none" verticalAlign="center" textSize={12} p={0.5} noWrap>-</TextContainer>
                            <TextContainer bgColor="none" verticalAlign="center" textSize={12} p={0.5} noWrap>{heurify(plages.arret4)}</TextContainer>
                        </Stack>
                        <Box width="100%" height="100%" bgcolor={greyColor[0]} borderRadius={2} display="flex" alignItems="center" justifyContent="center">
                            <TextContainer bgColor="none" verticalAlign="center" textSize={12} p={0.5} noWrap>{typesTravails.plage4}</TextContainer>
                        </Box>
                    </Stack>
                }
                <Box p={0.5} bgcolor={greenColor["015"]} borderRadius={2} px={1} py={0.5}>
                    <Typography fontSize="small">Total : {heurify(totalHeures, "h", false)}</Typography>
                </Box>
                {authorized && 
                    <Stack direction="row" spacing={0.5} p={0.5} bgcolor={greenColor["015"]} borderRadius={2} display="flex" alignItems="center" justifyContent="center">       
                        <IconButton size="small" sx={styles.buttonStyleGreen} onClick={() => setOpen(true)}>
                            <Edit fontSize="small" />
                        </IconButton>
                    </Stack>
                }  
            </Stack>
            <Drawer 
                variant="temporary" 
                open={open} 
                onClose={() => setOpen(false)} 
                anchor="top"
                PaperProps={{ 
                    sx: { 
                        width: "100%", 
                        maxHeight: "100%", 
                        backgroundColor: "transparent", 
                        p: 1, 
                        boxSizing: "border-box", 
                        boxShadow: "none" 
                    }
                }}
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 100
                }}
            >
                <Stack 
                    spacing={1} 
                    bgcolor={whiteColor[9]} 
                    borderRadius={2} 
                    p={1} 
                    boxSizing="border-box"
                >
                    <Stack spacing={0.5}>
                        <Stack spacing={0.5} overflow="auto" maxHeight="calc(100vh - 72px)">
                            <TextContainer bgColor={greenColor["015"]} p={1} textWeight="bold" horizontalAlign="center">{libelle}</TextContainer>
                            <TextContainer bgColor={yellowColor[5]} p={1} textWeight="bold" horizontalAlign="center">Plage 1</TextContainer>
                            <Stack spacing={0.5}>
                                <Stack direction="row" spacing={0.5}>
                                    <Box>
                                        <PlanningTimeInput idPlage={1} idJour={idJour} idLabel="debut" label="Début" />
                                    </Box>     
                                    <Box>
                                        <PlanningTimeInput idPlage={1} idJour={idJour} idLabel="arret" label="Fin" />
                                    </Box>       
                                </Stack>
                                <Box>
                                    {(authorizedTypeTravail) ? 
                                        <PlanningTypeTravailInput idPlage={1} idJour={idJour} />
                                    :
                                        <TextContainer bgColor={whiteColor[9]} verticalAlign="center" textSize={15} p={1} height={32} noWrap>{typesTravails.plage1}</TextContainer>
                                    }
                                </Box> 
                            </Stack>
                            <TextContainer bgColor={blueColor["03"]} p={1} textWeight="bold" horizontalAlign="center">Plage 2</TextContainer>
                            <Stack direction="row" spacing={0.5}>
                                <Box>
                                    <PlanningTimeInput idPlage={2} idJour={idJour} idLabel="debut" label="Début" />
                                </Box>     
                                <Box>
                                    <PlanningTimeInput idPlage={2} idJour={idJour} idLabel="arret" label="Fin" />
                                </Box>       
                            </Stack>
                            <Box>  
                                {(authorizedTypeTravail) ? 
                                    <PlanningTypeTravailInput idPlage={2} idJour={idJour} />
                                :
                                    <TextContainer bgColor={whiteColor[9]} verticalAlign="center" textSize={15} p={1} height={32} noWrap>{typesTravails.plage2}</TextContainer>
                                }
                            </Box>
                            <TextContainer bgColor={blueColor["03"]} p={1} textWeight="bold" horizontalAlign="center">Plage 3</TextContainer>
                            <Stack spacing={0.5}>
                                <Stack direction="row" spacing={0.5}>
                                    <Box>
                                        <PlanningTimeInput idPlage={3} idJour={idJour} idLabel="debut" label="Début" />
                                    </Box>     
                                    <Box>
                                        <PlanningTimeInput idPlage={3} idJour={idJour} idLabel="arret" label="Fin" />
                                    </Box>       
                                </Stack>
                                <Box>
                                    {(authorizedTypeTravail) ? 
                                        <PlanningTypeTravailInput idPlage={3} idJour={idJour} />
                                    :
                                        <TextContainer bgColor={whiteColor[9]} verticalAlign="center" textSize={15} p={1} height={32} noWrap>{typesTravails.plage3}</TextContainer>
                                    }
                                </Box> 
                            </Stack>
                            <TextContainer bgColor={yellowColor[5]} p={1} textWeight="bold" horizontalAlign="center">Plage 4</TextContainer>
                            <Stack spacing={0.5}>
                                <Stack direction="row" spacing={0.5}>
                                    <Box>
                                        <PlanningTimeInput idPlage={4} idJour={idJour} idLabel="debut" label="Début" />
                                    </Box>     
                                    <Box>
                                        <PlanningTimeInput idPlage={4} idJour={idJour} idLabel="arret" label="Fin" />
                                    </Box>       
                                </Stack>
                                <Box>
                                    {(authorizedTypeTravail) ? 
                                        <PlanningTypeTravailInput idPlage={4} idJour={idJour} />
                                    :
                                        <TextContainer bgColor={whiteColor[9]} verticalAlign="center" textSize={15} p={1} height={32} noWrap>{typesTravails.plage4}</TextContainer>
                                    }
                                </Box> 
                            </Stack>
                        </Stack>
                        <Box>
                            <Stack spacing={0.5} direction="row" borderRadius={2} width={"100%"} height={"100%"} display="flex" alignItems="center" justifyContent="center">
                                <Box>
                                    <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                                        {authorized && <CheckHookFormInput name={`rattachement${idJour + 1}`} size={16} fullWidth positionnement="center" />}
                                    </Box>
                                </Box>
                                <Box>
                                    {authorized && <HoraireMenuButton idJour={idJour} modelizePlanning={modelize}  />}
                                </Box>  
                                <IconButton sx={styles.buttonStyleGreen} onClick={() => setOpen(false)}>
                                    <Check fontSize="small" />
                                </IconButton>
                                <IconButton sx={styles.buttonStyleRed} onClick={() => setOpen(false)}>
                                    <Close fontSize="small" />
                                </IconButton>
                            </Stack>
                        </Box>
                    </Stack>
                </Stack>
            </Drawer>
        </>
    )
}

PlanningItemsTableMobile.defaultProps = {
    modelize: () => {}
}

PlanningItemsTableMobile.propTypes = {
    idJour: PropTypes.number.isRequired,
    libelle: PropTypes.string.isRequired,
    modelize: PropTypes.func
}

const styles = {
    buttonStyleGrey: {
        boxShadow: "none", 
        backgroundColor: greyColor[0],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: greyColor[2],
            color: whiteColor[9],
        }
    },
    buttonStyleGreen: {
        boxShadow: "none", 
        backgroundColor: greenColor["02"],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: greenColor["03"],
            color: whiteColor[9],
        }
    },
    buttonStyleRed: {
        boxShadow: "none", 
        backgroundColor: redColor[1],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: redColor[2],
            color: whiteColor[9],
        }
    }
}

export default PlanningItemsTableMobile;