import { filter, tidy } from "@tidyjs/tidy";
import { chiffreStrUSVersChiffreStrFR, datify } from "@utils";
import numeral from "numeral";

export const filtrerCreGesTem = (data, dataBrut) => {
    let dataFiltered = [...dataBrut];
    
    switch(data.colonne) {
        case "date":
            switch(data.operateur) {
                case "egale":
                    return tidy(dataFiltered, filter(credit => datify(credit.date, "moment").isSame(data.date)));
                case "superieur":
                    return tidy(dataFiltered, filter(credit => datify(credit.date, "moment").isAfter(data.date)));
                case "inferieur":
                    return tidy(dataFiltered, filter(credit => datify(credit.date, "moment").isBefore(data.date)));
                default: 
                    return dataFiltered
            }
        case "creditEnCours":
            switch(data.operateur) {
                case "egale":
                    return tidy(dataFiltered, filter(credit => numeral(chiffreStrUSVersChiffreStrFR(credit.enCoursChiffre)).value() === numeral(chiffreStrUSVersChiffreStrFR(data.number))));
                case "superieur":
                    return tidy(dataFiltered, filter(credit => numeral(chiffreStrUSVersChiffreStrFR(credit.enCoursChiffre)).value() > numeral(chiffreStrUSVersChiffreStrFR(data.number))));
                case "inferieur":
                    return tidy(dataFiltered, filter(credit => numeral(chiffreStrUSVersChiffreStrFR(credit.enCoursChiffre)).value() < numeral(chiffreStrUSVersChiffreStrFR(data.number))));
                default: 
                    return dataFiltered
            }
        case "creditAcquisJour":
            switch(data.operateur) {
                case "egale":
                    return tidy(dataFiltered, filter(credit => numeral(chiffreStrUSVersChiffreStrFR(credit.acquisJourChiffre)).value() === numeral(chiffreStrUSVersChiffreStrFR(data.number))));
                case "superieur":
                    return tidy(dataFiltered, filter(credit => numeral(chiffreStrUSVersChiffreStrFR(credit.acquisJourChiffre)).value() > numeral(chiffreStrUSVersChiffreStrFR(data.number))));
                case "inferieur":
                    return tidy(dataFiltered, filter(credit => numeral(chiffreStrUSVersChiffreStrFR(credit.acquisJourChiffre)).value() < numeral(chiffreStrUSVersChiffreStrFR(data.number))));
                default: 
                    return dataFiltered
            }
        case "creditAcquisCumul":
            switch(data.operateur) {
                case "egale":
                    return tidy(dataFiltered, filter(credit => numeral(chiffreStrUSVersChiffreStrFR(credit.acquisCumulChiffre)).value() === numeral(chiffreStrUSVersChiffreStrFR(data.number)).value()));
                case "superieur":
                    return tidy(dataFiltered, filter(credit => numeral(chiffreStrUSVersChiffreStrFR(credit.acquisCumulChiffre)).value() > numeral(chiffreStrUSVersChiffreStrFR(data.number)).value()));
                case "inferieur":
                    return tidy(dataFiltered, filter(credit => numeral(chiffreStrUSVersChiffreStrFR(credit.acquisCumulChiffre)).value() < numeral(chiffreStrUSVersChiffreStrFR(data.number)).value()));
                default: 
                    return dataFiltered
            }
        case "valeurRegularisation":
            switch(data.operateur) {
                case "egale":
                    return tidy(dataFiltered, filter(credit => numeral(chiffreStrUSVersChiffreStrFR(credit.valeurRegularisationChiffre)).value() === numeral(chiffreStrUSVersChiffreStrFR(data.number))));
                case "superieur":
                    return tidy(dataFiltered, filter(credit => numeral(chiffreStrUSVersChiffreStrFR(credit.valeurRegularisationChiffre)).value() > numeral(chiffreStrUSVersChiffreStrFR(data.number))));
                case "inferieur":
                    return tidy(dataFiltered, filter(credit => numeral(chiffreStrUSVersChiffreStrFR(credit.valeurRegularisationChiffre)).value() < numeral(chiffreStrUSVersChiffreStrFR(data.number))));
                default: 
                    return dataFiltered
            }
        case "dateRegularisation":
            switch(data.operateur) {
                case "egale":
                    return tidy(dataFiltered, filter(credit => datify(credit.dateRegularisation, "moment").isSame(data.date)));
                case "superieur":
                    return tidy(dataFiltered, filter(credit => datify(credit.dateRegularisation, "moment").isAfter(data.date)));
                case "inferieur":
                    return tidy(dataFiltered, filter(credit => datify(credit.dateRegularisation, "moment").isBefore(data.date)));
                default: 
                    return dataFiltered
            }
        case "utilisateurRegularisation":
            return tidy(dataFiltered, filter(credit => credit.utilisateurRegularisation === data.txt));
        default:
            return dataFiltered
    }
};
