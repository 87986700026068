import { Box, Grid, Stack, Typography } from "@mui/material";
import { blueColor, greenColor, yellowColor } from "@styles";

const headers = [
    {id: 0, libelle: "Horaire", xs: 1, color: greenColor["015"]}, 
    {id: 1, libelle: "Absence", xs: 1.5, color: greenColor["015"]}, 
    {id: 2, libelle: "Plage 1", xs: 3, color: yellowColor[3]}, 
    {id: 3, libelle: "Plage 2", xs: 3, color: blueColor["03"]}, 
    {id: 4, libelle: "Plage 3", xs: 3, color: blueColor["03"]}, 
    {id: 5, libelle: "Plage 4", xs: 3, color: yellowColor[3]}, 
    {id: 6, libelle: "Total", xs: 1, color: greenColor["015"]}, 
    {id: 7, libelle: "Rattachement", xs: 1.15, color: greenColor["015"]},
    {id: 8, libelle: "Modèle", xs: 1.15, color: greenColor["015"]}
];

const PlanningHeaderTable = () => {
    return (
        <Stack spacing={0.5} direction="row" bgcolor={greenColor["015"]} borderRadius={2} p={0.25}>
            <Box flexGrow={1}>
                <Grid container spacing={0.5} columns={19}>
                    <Grid item xs={1.2}></Grid>
                    {headers.map((header) => (
                        <Grid key={`HEADERPL${header.id}`} item xs={header.xs}>
                            <Box p={1} bgcolor={header.color} borderRadius={2} height={16} display="flex" alignItems="center" justifyContent="center">
                                <Typography fontSize="small" fontWeight="bold" noWrap>{header.libelle}</Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Stack>
    );
}

export default PlanningHeaderTable;