import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment';

const initialState = {
    dateValidite: moment().format("DDMMYYYY"),
    collaborateurs: [],
    collaborateursDisplayed: [],
    filtered: false,
    tried: false,    
    collaborateurSelected: null,
}

const colAffActIndSlice = createSlice({
    name: 'colAffActInd',
    initialState: initialState,
    reducers: {
        setCollaborateurs: (state, action) => {
            state.collaborateurs = action.payload;
            state.collaborateursDisplayed = action.payload;
            state.filtered = false;
            state.tried = false;
        },
        setDate: (state, action) => {
            state.dateValidite = action.payload;
        },
        filtrer: (state, action) => {
            state.collaborateursDisplayed = action.payload;
            state.filtered = true;
        },
        trier: (state, action) => {
            state.collaborateursDisplayed = action.payload;
            state.tried = true;
        },
        resetFiltre: (state) => {
            state.collaborateursDisplayed = state.collaborateurs;
            state.filtered = false;
        },
        resetTrie: (state) => {
            state.collaborateursDisplayed = state.collaborateurs;
            state.tried = false;
        },
        selectCollaborateur: (state, action) => {
            state.collaborateurSelected = action.payload
        },
        reset: () => initialState
    },
})

const { actions, reducer } = colAffActIndSlice
export const { 
    setCollaborateurs,
    setDate,
    filtrer,
    trier,
    resetFiltre, 
    resetTrie,
    selectCollaborateur, 
    reset 
} = actions
export default reducer