export const polices = [
    "OpenSans",
    "AppleGaramond",
    "Roboto",
    "Akaya",
    "Arial",
    "Verdana",
    "ComicSansMS",
    "Calibri",
    "Raleway"
];

export const routePolices = {
    "OpenSans": "Open Sans",
    "AppleGaramond": "AppleGaramond",
    "Roboto": "Roboto, sans-serif",
    "Akaya": "Akaya Telivigala, cursive",
    "Arial": "Arial",
    "Verdana": "Verdana",
    "ComicSansMs": "Comic Sans MS",
    "Calibri": "Calibri",
    "Raleway": "Raleway"
};
