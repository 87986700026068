import { selectEntCol, selectEntMan, selectEntSecCol, selectEntSecMan, selectEntTabCol, selectEntTabMan } from "@selectors";
import { useSelector } from "react-redux";

export const useTabEntChaCom = (codeTable) => {
    const stateTables = useSelector(selectEntTabMan);
    const table = stateTables?.champsComplementaires?.find((table) => table.codeTable === codeTable);
    let options = [];
    for(let i = 1; i <= 10; i++) {
        if(table?.[`valeur${i}`]) {
            options.push({ value: table?.[`valeur${i}`], label: table?.[`valeur${i}`] });
        }
    }

    return { label: table?.designation, options: options };
}

export const useTabEntChaCom2 = (ct1, ct2, ct3, ct4) => {
    const stateTables = useSelector(selectEntTabMan);
    const tables = stateTables?.champsComplementaires;
    const tableCC1 = tables[ct1] || [];
    const tableCC2 = tables[ct2] || [];
    const tableCC3 = tables[ct3] || [];
    const tableCC4 = tables[ct4] || [];
    let options = { optionsCC1: tableCC1, optionsCC2: tableCC2, optionsCC3: tableCC3, optionsCC4: tableCC4 };

    return options;
}

export const useTablesEntretiensEvaluations = () => {
    const stateTables = useSelector(selectEntTabMan);
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;
    let options = [];

    if(sectionSelected?.evaluationSection) {
        const code = sectionSelected?.codeTableEvaluation || "COMP";
        const table = stateTables?.grilleEvaluation[code] || [];
        options = table.options;
    }
    
    if(sectionSelected?.evaluationCompetences) {
        const codeCompetence = sectionSelected?.codeCleCompetence;
        let codeBuild = codeCompetence || "COMP";
        const table = stateTables?.grilleCompetencesEvaluation?.find((table) => table.codeCompetence === codeBuild);
        if(table) {
            for(let i = 1; i <= 6; i++) {
                const codeReponse = table[`codeReponse${i}`];
                const libelleReponse = table[`libelleReponse${i}`];
                const classement = table[`classementReponse${i}`];
                if(codeReponse) {
                    options.push({ value: codeReponse, label: `${codeReponse} - ${libelleReponse}`, classement: classement });
                }
            }
        }
    }

    return options;
};

export const useTablesEntretiensEvaluationsCollaborateur = () => {
    const stateTables = useSelector(selectEntTabCol);
    const stateSections = useSelector(selectEntSecCol);
    const sectionSelected = stateSections?.sectionSelected;
    let options = [];

    if(sectionSelected?.evaluationSection) {
        const code = sectionSelected?.codeTableEvaluation || "COMP";
        const table = stateTables?.grilleEvaluation[code] || [];
        options = table.options;
    }
    
    if(sectionSelected?.evaluationCompetences) {
        const codeCompetence = sectionSelected?.codeCleCompetence;
        let codeBuild = codeCompetence || "COMP";
        const table = stateTables?.grilleCompetencesEvaluation?.find((table) => table.codeCompetence === codeBuild);
        if(table) {
            for(let i = 1; i <= 6; i++) {
                const codeReponse = table[`codeReponse${i}`];
                const libelleReponse = table[`libelleReponse${i}`];
                const classement = table[`classementReponse${i}`];
                if(codeReponse) {
                    options.push({ value: codeReponse, label: `${codeReponse} - ${libelleReponse}`, classement: classement });
                }
            }
        }
    }

    return options;
};

export const useActionsSousSections = () => {
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;
    const presenceAjout = sectionSelected?.ajoutSousSectionManager && stateEntretiens?.entretienSelected?.statut === "P";
    const presenceValider = (sectionSelected?.typeSousSection === "A" && !sectionSelected?.ajoutSousSectionManager);
    const presenceCommentaire = sectionSelected?.commentaireCollaborateur?.length > 0;

    return {
        designation1: sectionSelected?.designationSection1,
        designation2: sectionSelected?.designationSection2,
        presenceAjout: presenceAjout,
        presenceValider: presenceValider,
        presenceCommentaire: presenceCommentaire
    }
}

export const useMappingActions = () => {
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;

    return {
        niveau: stateSections.niveau,
        designation1: sectionSelected?.designationSection1,
        designation2: sectionSelected?.designationSection2
    }
}

export const useMappingActionsCol = () => {
    const stateSections = useSelector(selectEntSecCol);
    const sectionSelected = stateSections?.sectionSelected;

    return {
        niveau: stateSections.niveau,
        designation1: sectionSelected?.designationSection1,
        designation2: sectionSelected?.designationSection2
    }
}

export const useChampComplementaireAjout = () => {
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;
    const display1 = sectionSelected?.designationComplement1;
    const display2 = sectionSelected?.designationComplement2;
    const display3 = sectionSelected?.designationComplement3;
    const display4 = sectionSelected?.designationComplement4;
    const display12 = display1 || display2;
    const display34 = display3 || display4;
    const displays = display1 || display2 || display3 || display4;
    const codeTable1 = sectionSelected?.tableValeurComplement1;
    const codeTable2 = sectionSelected?.tableValeurComplement2;
    const codeTable3 = sectionSelected?.tableValeurComplement3;
    const codeTable4 = sectionSelected?.tableValeurComplement4;
    const designation1 = sectionSelected?.designationComplement1;
    const designation2 = sectionSelected?.designationComplement2;
    const designation3 = sectionSelected?.designationComplement3;
    const designation4 = sectionSelected?.designationComplement4;
    const optionsCCG = useTabEntChaCom2(codeTable1, codeTable2, codeTable3, codeTable4);

    return {
        displayCC12: display12,
        displayCC34: display34,
        displayCCG: displays,
        codeTableCC1: codeTable1,
        codeTableCC2: codeTable2,
        codeTableCC3: codeTable3,
        codeTableCC4: codeTable4,
        designationCC1: designation1,
        designationCC2: designation2,
        designationCC3: designation3,
        designationCC4: designation4,
        optionsCC1: optionsCCG.optionsCC1,
        optionsCC2: optionsCCG.optionsCC2,
        optionsCC3: optionsCCG.optionsCC3,
        optionsCC4: optionsCCG.optionsCC4
    }
}

export const useChampsComplementaires2 = () => {
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;
    const display1 = sectionSelected?.designationComplement1;
    const display2 = sectionSelected?.designationComplement2;
    const display3 = sectionSelected?.designationComplement3;
    const display4 = sectionSelected?.designationComplement4;
    const display12 = display1 || display2;
    const display34 = display3 || display4;
    const displays = display1 || display2 || display3 || display4;
    const codeTable1 = sectionSelected?.tableValeurComplement1;
    const codeTable2 = sectionSelected?.tableValeurComplement2;
    const codeTable3 = sectionSelected?.tableValeurComplement3;
    const codeTable4 = sectionSelected?.tableValeurComplement4;
    const designation1 = sectionSelected?.designationComplement1;
    const designation2 = sectionSelected?.designationComplement2;
    const designation3 = sectionSelected?.designationComplement3;
    const designation4 = sectionSelected?.designationComplement4;
    const complement1 = sectionSelected?.contenuComplement1;
    const complement2 = sectionSelected?.contenuComplement2;
    const complement3 = sectionSelected?.contenuComplement3;
    const complement4 = sectionSelected?.contenuComplement4;
    const optionsCCG = useTabEntChaCom2(codeTable1, codeTable2, codeTable3, codeTable4);
    const displayA = stateSections.modeAjout && displays;
    const displayE = stateEntretiens?.entretienSelected?.statut === "P" && displays;

    return {
        displayA: displayA,
        displayE: displayE,
        displayV: displays,
        displayCC12: display12,
        displayCC34: display34,
        displayCC1: display1,
        displayCC2: display2,
        displayCC3: display3,
        displayCC4: display4,
        codeTableCC1: codeTable1,
        codeTableCC2: codeTable2,
        codeTableCC3: codeTable3,
        codeTableCC4: codeTable4,
        designationCC1: designation1,
        designationCC2: designation2,
        designationCC3: designation3,
        designationCC4: designation4,
        complementCC1: complement1,
        complementCC2: complement2,
        complementCC3: complement3,
        complementCC4: complement4,
        optionsCC1: optionsCCG.optionsCC1,
        optionsCC2: optionsCCG.optionsCC2,
        optionsCC3: optionsCCG.optionsCC3,
        optionsCC4: optionsCCG.optionsCC4
    }
}

export const useChampsComplementairesCol = () => {
    const stateSections = useSelector(selectEntSecCol);
    const sectionSelected = stateSections?.sectionSelected;
    const display1 = sectionSelected?.designationComplement1;
    const display2 = sectionSelected?.designationComplement2;
    const display3 = sectionSelected?.designationComplement3;
    const display4 = sectionSelected?.designationComplement4;
    const display12 = display1 || display2;
    const display34 = display3 || display4;
    const designation1 = sectionSelected?.designationComplement1;
    const designation2 = sectionSelected?.designationComplement2;
    const designation3 = sectionSelected?.designationComplement3;
    const designation4 = sectionSelected?.designationComplement4;
    const complement1 = sectionSelected?.contenuComplement1;
    const complement2 = sectionSelected?.contenuComplement2;
    const complement3 = sectionSelected?.contenuComplement3;
    const complement4 = sectionSelected?.contenuComplement4;
    const displays = display1 || display2 || display3 || display4;

    return {
        displays: displays,
        displayCC12: display12,
        displayCC34: display34,
        displayCC1: display1,
        displayCC2: display2,
        displayCC3: display3,
        displayCC4: display4,
        designationCC1: designation1,
        designationCC2: designation2,
        designationCC3: designation3,
        designationCC4: designation4,
        complementCC1: complement1,
        complementCC2: complement2,
        complementCC3: complement3,
        complementCC4: complement4
    }
}


export const useChampsComplementaires = () => {
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;
    const displayAjout = stateSections.modeAjout && (sectionSelected?.designationComplement1 || sectionSelected?.designationComplement2 || sectionSelected?.designationComplement3 || sectionSelected?.designationComplement4);
    const displayEdition = stateEntretiens?.entretienSelected?.statut === "P" && (sectionSelected?.designationComplement1 || sectionSelected?.designationComplement2 || sectionSelected?.designationComplement3 || sectionSelected?.designationComplement4);
    const displayVisu = sectionSelected?.designationComplement1 || sectionSelected?.designationComplement2 || sectionSelected?.designationComplement3 || sectionSelected?.designationComplement4;
    const display = displayAjout || displayEdition || displayVisu;
    const display12 = sectionSelected?.designationComplement1 || sectionSelected?.designationComplement2;
    const display23 = sectionSelected?.designationComplement3 || sectionSelected?.designationComplement4;

    return {
        displayEdition: displayEdition,
        displayAjout: displayAjout,
        displayVisu: displayVisu,
        display: display,
        display12: display12,
        display23: display23
    }
}

export const useChampsUnitaires = (id) => {
    const stateSections = useSelector(selectEntSecMan);
    const stateEntretiens = useSelector(selectEntMan);
    const sectionSelected = stateSections?.sectionSelected;
    const designation = sectionSelected?.[`designationComplement${id}`];
    const codeTableComplement = sectionSelected?.[`tableValeurComplement${id}`];
    const complement = sectionSelected?.[`contenuComplement${id}`];
    const displayAjout = stateSections.modeAjout && designation;
    const displayEdition = stateEntretiens?.entretienSelected?.statut === "P" && designation;
    const displayVisu = designation;
    
    return {
        designation: designation,
        codeTableComplement: codeTableComplement,
        complement: complement,
        displayAjout: displayAjout,
        displayEdition: displayEdition,
        displayVisu: displayVisu
    }
}

export const useChampDesignation = () => {
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;
    const designation1 = sectionSelected?.designationSousSection1;
    const designation2 = sectionSelected?.designationSousSection2;
    const displayA = stateEntretiens?.entretienSelected?.statut === "P" && stateSections.modeAjout;
    const displayV = !!designation1 || !!designation2;

    return {
        displayA: displayA,
        displayV: displayV,
        designation1: designation1,
        designation2: designation2
    }
}

export const useChampDesignationCollaborateur = () => {
    const stateSections = useSelector(selectEntSecCol);
    const sectionSelected = stateSections?.sectionSelected;
    const designation1 = sectionSelected?.designationSousSection1;
    const designation2 = sectionSelected?.designationSousSection2;

    return {
        designation1: designation1,
        designation2: designation2
    }
}

export const useChampCommentaire = () => {
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;    
    const displayA = stateSections?.modeAjout;    
    const displayE = stateEntretiens?.entretienSelected?.statut === "P";
    const displayV = sectionSelected?.commentaireManager?.length > 0;

    return {        
        displayA: displayA,
        displayE: displayE,
        displayV: displayV,
    }
}

export const useChampCommentaireCollaborateur = () => {
    const stateEntretiens = useSelector(selectEntCol);
    const stateSections = useSelector(selectEntSecCol);
    const displayA = stateSections?.modeAjout;    
    const displayE = stateEntretiens?.entretienSelected?.statut === "P";

    return {        
        displayA: displayA,
        displayE: displayE
    }
}

export const useChampEvaluation = () => {
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;    
    const evaluationSectionCompetence = sectionSelected?.evaluationSection || sectionSelected?.evaluationCompetences;
    const displayA = stateSections?.modeAjout && evaluationSectionCompetence;
    const displayE = stateEntretiens?.entretienSelected?.statut === "P" && evaluationSectionCompetence;
    const displayV = evaluationSectionCompetence;
    const optionsCE = useTablesEntretiensEvaluations();
    
    return {       
        displayA: displayA,
        displayE: displayE,
        displayV: displayV,
        optionsCE: optionsCE
    }
}

export const useChampEvaluationCollaborateur = () => {
    const stateSections = useSelector(selectEntSecCol);
    const sectionSelected = stateSections?.sectionSelected;    
    const evaluationSectionCompetence = sectionSelected?.evaluationSection || sectionSelected?.evaluationCompetences;
    const display = evaluationSectionCompetence;
    
    return {       
        display: display
    }
}

export const useChampQuestionAjout = () => {
    const options = useTablesEntretiensEvaluations();

    return {
        optionsCQ: options
    }
}

export const useChampQuestion = (idQuestion) => {
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected; 
    const evaluationQuestion = !!sectionSelected?.[`question${idQuestion}`]; 
    const displayA = stateSections?.modeAjout && evaluationQuestion;
    const displayE = stateEntretiens?.entretienSelected?.statut === "P" && evaluationQuestion;
    const displayV = evaluationQuestion;
    const options = useTablesEntretiensEvaluations();
    
    return {        
        displayA: displayA,
        displayE: displayE,
        displayV: displayV,
        options: options
    }
}

export const useChampQuestionCol = (idQuestion) => {
    const stateSections = useSelector(selectEntSecCol);
    const sectionSelected = stateSections?.sectionSelected; 
    const evaluationQuestion = !!sectionSelected?.[`question${idQuestion}`]; 
    const display = evaluationQuestion;
    const options = useTablesEntretiensEvaluationsCollaborateur();
    
    return {        
        display: display,
        options: options
    }
}

export const useChampDate = () => {
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;   
    const evaluationSectionCompetence = sectionSelected?.evaluationDate || sectionSelected?.niveauDate !== "0";
    const displayA = stateSections.modeAjout && evaluationSectionCompetence;
    const displayE = stateEntretiens?.entretienSelected?.statut === "P" && evaluationSectionCompetence;
    const displayV = sectionSelected?.date !== "0" && evaluationSectionCompetence;

    return {
        displayA: displayA,
        displayE: displayE,
        displayV: displayV,
    }
}

export const useChampDateCollaborateur = () => {
    const stateSections = useSelector(selectEntSecCol);
    const sectionSelected = stateSections?.sectionSelected;   
    const evaluationSectionCompetence = sectionSelected?.evaluationDate || sectionSelected?.niveauDate !== "0";
    const display = sectionSelected?.date !== "0" && evaluationSectionCompetence;

    return {
        display: display
    }
}

export const useChampDateAjout = () => {
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;   
    const display = sectionSelected?.evaluationDate || sectionSelected?.niveauDate !== "0";

    return {
        displayDT: display
    }
}

export const useCompetences = () => {
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected; 
    const stateTables = useSelector(selectEntTabMan);  

    if(sectionSelected?.evaluationCompetences) {
        const codeCompetence = sectionSelected?.codeCleCompetence;
        let codeBuild = codeCompetence || "COMP";
        const competence = stateTables?.grilleCompetencesEvaluation?.find((table) => table.codeCompetence === codeBuild);
        if(competence) {
            return competence;
        }
    }

    return null;
}


export const useCompetencesCol = () => {
    const stateSections = useSelector(selectEntSecCol);
    const sectionSelected = stateSections?.sectionSelected; 
    const stateTables = useSelector(selectEntTabCol);  

    if(sectionSelected?.evaluationCompetences) {
        const codeCompetence = sectionSelected?.codeCleCompetence;
        let codeBuild = codeCompetence || "COMP";
        const competence = stateTables?.grilleCompetencesEvaluation?.find((table) => table.codeCompetence === codeBuild);
        if(competence) {
            return competence;
        }
    }

    return null;
}

export const useQuestionnaire = () => {
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;
    const designation = sectionSelected?.questionDesignation;  
    const questions = [];

    for(let i = 1; i <= 6; i++) {
        const codeQuestion = sectionSelected?.[`codeQuestion${i}`];
        const question = sectionSelected?.[`question${i}`];
        const reponse = sectionSelected?.[`reponseQuestion${i}`];
        if(codeQuestion) {
            questions.push({ codeQuestion: codeQuestion, question: question, reponse: reponse });
        }
    }   

    return { designation: designation, questions: questions };
}

export const useButtonAjout = () => {
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;
    const display = stateEntretiens?.entretienSelected?.statut === "P" && sectionSelected?.ajoutSousSectionManager;
    const ajout = stateSections.modeAjout;

    return {
        display: display,
        ajout: ajout
    }
}

export const useButtonValider = () => {
    const stateEntretiens = useSelector(selectEntMan);
    const display = stateEntretiens?.entretienSelected?.statut === "P";

    return {
        display: display
    }
}

export const useButtonAnnuler = () => {
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const display = stateEntretiens?.entretienSelected?.statut === "P" && stateSections.modeAjout;

    return {
        display: display
    }
}

export const useButtonCompetence = () => {
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;
    const display = sectionSelected?.typeSectionSuivante === "CF";

    return {
        display: display
    }
}