import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useFetchAnoGesTem } from "@hooks/anomalies/hooksQueries";
import { DialogFullScreenContainer, HeaderDialogContainer } from "@components/Containers";
import { AnomaliesListMobile } from "@components/GestionTemps/Temps/Lists";

const AnomaliesDialogMobile = ({
    open = false, 
    setOpen = () => {}
}) => {
    const { data, isLoading, isFetching, isError, error } = useFetchAnoGesTem(open);

    return (
        <DialogFullScreenContainer open={open} placement="top" taille="sm">
            <HeaderDialogContainer
                title="Liste complète des anomalies"
                close={() => setOpen(false)}
            />
            <Stack spacing={0.5} maxHeight={"calc(100vh - 138px)"}>
                <AnomaliesListMobile
                    data={data}
                    isLoading={isLoading || isFetching}
                    isError={isError}
                    errorMessage={error?.message}
                />
            </Stack>
        </DialogFullScreenContainer>
    );
}

AnomaliesDialogMobile.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func
}

export default AnomaliesDialogMobile;