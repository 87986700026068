import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import moment from "moment";
import { joiResolver } from "@hookform/resolvers/joi";
import * as plaDabActions from "@reducers/planning/plaDabColReducer"
import { schemaDemandeAbsence } from "./validations";
import { useDabGesAbsPos } from "@hooks/gestionAbsences/demandes/hooksQueries";
import { AideDialog, AideDialogMobile, DemandeAbsenceDialog, DemandeAbsenceDialogMobile } from "./Dialogs";
import { DemandesAbsenceForm } from "./Forms";
import { ButtonBase } from "@components/Buttons";
import { Check, EventNote, Help } from "@mui/icons-material";
import { STEPS_DEMANDES_ABSENCE } from "@constants/demandesAbsenceConstants";
import { useQueryClient } from "@tanstack/react-query";
import { PlanningDialog, PlanningDialogMobile } from "./Dialogs/Planning";
import { useMedia } from "react-use";
import { useTranslation } from "react-i18next";

const defaultValues = {
    dateDebut: moment().startOf("day"),
    typeAbsence: "",
    type: "date",
    dateReprise: null,
    jourHeure: false,
    ampm: false,
    heureDebut: null,
    heureReprise: null,
    nombreHeures: null,
    typeMotif: "N",
    motif: "",
    typeFichier: "2",
    fichier: null,
}

const DemandesAbsence = () => {
    const [openAideDialog, setOpenAideDialog] = useState(false); 
    const [openDemandeAbsenceDialog, setOpenDemandeAbsenceDialog] = useState(false); 
    const [openPlanningDialog, setOpenPlanningDialog] = useState(false); 
    const [fichier, setFichier] = useState(null); 
    const [nombreJour, setNombreJour] = useState(0); 
    const [messageFirstCondition, setMessageFirstCondition] = useState(""); 
    const [messageSecondCondition, setMessageSecondCondition] = useState(""); 
    const [step, setStep] = useState(""); 
    const [condition, setCondition] = useState("N"); 
    const methods = useForm({ mode: "onChange", defaultValues: defaultValues, resolver: joiResolver(schemaDemandeAbsence) });    
    const { data, isLoadingDemande, isLoadingFile, isError, error, postDemAbsFor, postDemAbsPj } = useDabGesAbsPos();
    const dateDebut = methods.watch("dateDebut");
    const queryClient = useQueryClient();  
    const { enqueueSnackbar } = useSnackbar();   
    const isMobile = useMedia('(max-width: 800px)'); 
    const dispatch = useDispatch();
    const { t } = useTranslation("global");

    const refetchAll = useCallback(() => {
        queryClient.refetchQueries({queryKey: ["hisDabGesAbs"]});
        queryClient.refetchQueries({queryKey: ["solGesAbs"]});
        queryClient.refetchQueries({queryKey: ["calGesAbs"]});
    }, [queryClient]);

    const postDemandeAbsenceForm = useCallback(async (data) => {
        setFichier(data?.fichier);
        setMessageFirstCondition(data?.typeAbsence?.messageFisrtCondition);
        setMessageSecondCondition(data?.typeAbsence?.messageSecondCondition);
        setOpenDemandeAbsenceDialog(true);
        setCondition("N");
        setStep(STEPS_DEMANDES_ABSENCE.RECAPITULATIF);
        const { isSucceed, nombreJour } = await postDemAbsFor(data, "N", "N", "N");
        if(isSucceed) {
            setNombreJour(nombreJour);
        }
    }, [postDemAbsFor]);

    const postDemandeAbsenceRecapitulatif = useCallback(async () => {
        const data = methods.getValues();
        if(data.typeAbsence.afficherCondition === "O" && nombreJour >= data.typeAbsence.nombreJourMiniCondition) {
            setStep(STEPS_DEMANDES_ABSENCE.CONDITION);
        } else {
            const { isSucceed, codeErreur, refDate, refHeure } = await postDemAbsFor(data, "O", "N", "N");
            if(isSucceed) {
                await postDemAbsPj(data, refDate, refHeure);
                setOpenDemandeAbsenceDialog(false);
                enqueueSnackbar(t("success.absence_request_sended"), { variant: "success" });
                methods.reset(defaultValues);
                refetchAll();
            } else {
                if(codeErreur === 13) {
                    setCondition("N");
                    setStep(STEPS_DEMANDES_ABSENCE.CONTRAINTE);
                }
            }
        }
       
    }, [enqueueSnackbar, methods, nombreJour, postDemAbsFor, postDemAbsPj, refetchAll, t]);

    const postDemandeAbsenceCondition = useCallback(async () => {
        const data = methods.getValues();
        const { isSucceed, codeErreur, refDate, refHeure } = await postDemAbsFor(data, "O", "N", "A");
        if(isSucceed) {
            await postDemAbsPj(data, refDate, refHeure);
            setOpenDemandeAbsenceDialog(false);
            enqueueSnackbar(t("success.absence_request_sended"), { variant: "success" });
            methods.reset(defaultValues);
            refetchAll();
        } else {
            if(codeErreur === 13) {
                setCondition("A");
                setStep(STEPS_DEMANDES_ABSENCE.CONTRAINTE);
            }
        }
    }, [enqueueSnackbar, methods, postDemAbsFor, postDemAbsPj, refetchAll, t]);

    const postDemandeAbsenceContrainte = useCallback(async () => {
        const data = methods.getValues();
        const { isSucceed, refDate, refHeure } = await postDemAbsFor(data, "O", "O", condition);
        if(isSucceed) {
            await postDemAbsPj(data, refDate, refHeure);
            setOpenDemandeAbsenceDialog(false);
            enqueueSnackbar(t("success.absence_request_sended"), { variant: "success" });
            methods.reset(defaultValues);
            refetchAll();
        }
    }, [condition, enqueueSnackbar, methods, postDemAbsFor, postDemAbsPj, refetchAll, t]);

    const close = useCallback(() => {
        setOpenDemandeAbsenceDialog(false);
        setCondition("N");
        setStep(STEPS_DEMANDES_ABSENCE.RECAPITULATIF);
    }, []);

    const openPlanning = useCallback(() => {
        dispatch(plaDabActions.setDate(dateDebut.format("DDMMYYYY")))
        setOpenPlanningDialog(true)
    }, [dateDebut, dispatch]);

    return (
        <>  
            <Stack spacing={1} position="relative" height="100%">
                <FormProvider {...methods}>
                    <DemandesAbsenceForm />
                </FormProvider>
                <Box>
                    <Stack spacing={0.5} direction="row" justifyContent={"center"}>
                        <ButtonBase variant="green25Grey5Green7" fullWidth startIcon={<Check fontSize="small" />} action={() => methods.handleSubmit(postDemandeAbsenceForm)()}>
                            {t("buttons.send")} 
                        </ButtonBase>
                        <ButtonBase variant="green25Grey5Green7" fullWidth startIcon={<EventNote fontSize="small" />} action={() => openPlanning()}>
                            {t("buttons.planning")} 
                        </ButtonBase>
                        <ButtonBase variant="green25Grey5Green7" fullWidth startIcon={<Help fontSize="small" />} action={() => setOpenAideDialog(true)}>
                            {t("buttons.help")} 
                        </ButtonBase>
                    </Stack>
                </Box>
            </Stack>
            {isMobile ?
                <>
                    <AideDialogMobile 
                        open={openAideDialog}
                        setOpen={setOpenAideDialog}
                    />
                    <DemandeAbsenceDialogMobile 
                        open={openDemandeAbsenceDialog} 
                        setOpen={setOpenDemandeAbsenceDialog} 
                        isLoading={isLoadingDemande || isLoadingFile}
                        isError={isError}
                        error={error}
                        data={data}
                        fichier={fichier}
                        step={step}
                        close={close}
                        messageFisrtCondition={messageFirstCondition}
                        messageSecondCondition={messageSecondCondition}
                        postDemandeAbsenceRecapitulatif={postDemandeAbsenceRecapitulatif}
                        postDemandeAbsenceCondition={postDemandeAbsenceCondition}
                        postDemandeAbsenceContrainte={postDemandeAbsenceContrainte}
                    />
                    <PlanningDialogMobile
                        open={openPlanningDialog} 
                        setOpen={setOpenPlanningDialog} 
                    />
                </>
            :
                <>
                    <AideDialog 
                        open={openAideDialog}
                        setOpen={setOpenAideDialog}
                    />
                    <DemandeAbsenceDialog 
                        open={openDemandeAbsenceDialog} 
                        setOpen={setOpenDemandeAbsenceDialog} 
                        isLoading={isLoadingDemande || isLoadingFile}
                        isError={isError}
                        error={error}
                        data={data}
                        fichier={fichier}
                        step={step}
                        close={close}
                        messageFisrtCondition={messageFirstCondition}
                        messageSecondCondition={messageSecondCondition}
                        postDemandeAbsenceRecapitulatif={postDemandeAbsenceRecapitulatif}
                        postDemandeAbsenceCondition={postDemandeAbsenceCondition}
                        postDemandeAbsenceContrainte={postDemandeAbsenceContrainte}
                    />
                    <PlanningDialog
                        open={openPlanningDialog} 
                        setOpen={setOpenPlanningDialog} 
                    />
                </>
            }
        </>
    );
}

export default DemandesAbsence;