import axios from "axios";
import { urls } from "@constants/appConstants";

export const fetchAno = async (client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetAnomalies"
    };

    const response = await axios.get(urls.getAnomalies, { headers: headers, params: { client: client }, signal: signal });
    return response.data.anomalies;
}

export const fetchAnoTraGesTem = async (matricule, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetAnomaliesDemandeRegularisation"
    };
    const body = {
        matricule: matricule
    };
    const response = await axios.post(urls.getAnomaliesDemandeRegularisation, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.anomalies;
}

export const downloadAno = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteDownloadAnomaliesBase"
    };
    const body = {
        utilisateur: data.utilisateur,
        droitSalarie: data.droitSalarie,
        droitInterimaire: data.droitSalarie,
        matricule: data.matricule,
        dateDebut: data.dateDebut,
        dateFin: data.dateFin,
        codeAnomalie: data.codeAnomalie,
        gravite1: data.gravite1,
        gravite2: data.gravite2,
        typeMouvement: data.typeMouvement,
        codeSociete: data.codeSociete,
        codeEtablissement: data.codeEtablissement,
        codeDirection: data.codeDirection,
        codeService: data.codeService,
        codeSecteur: data.codeSecteur,
        anomalieAReactualiser: data.anomalieAReactualiser
    };
    const response = await axios.post(urls.downloadAnomaliesBase, body, { headers: headers, params: { client: data.client } });
    return response.data.anomalies;
}

export const downloadAnoCre = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteDownloadAnomaliesCredit"
    };
    const body = {
        utilisateur: data.utilisateur,
        droitSalarie: data.droitSalarie,
        droitInterimaire: data.droitSalarie,
        matricule: data.matricule,
        debitMaximum: data.debitMaximum,
        creditMaximum: data.creditMaximum,
        codeSociete: data.codeSociete,
        codeEtablissement: data.codeEtablissement,
        codeDirection: data.codeDirection,
        codeService: data.codeService,
        codeSecteur: data.codeSecteur
    };
    const response = await axios.post(urls.downloadAnomaliesCredit, body, { headers: headers, params: { client: data.client } });
    return response.data.anomalies;
}

export const downloadAnoAct = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteDownloadAnomaliesActivite"
    };
    const body = {
        utilisateur: data.utilisateur,
        droitSalarie: data.droitSalarie,
        droitInterimaire: data.droitSalarie,
        matricule: data.matricule,
        dateDebut: data.dateDebut,
        dateFin: data.dateFin,
        codeSociete: data.codeSociete,
        codeEtablissement: data.codeEtablissement,
        codeDirection: data.codeDirection,
        codeService: data.codeService,
        codeSecteur: data.codeSecteur,
        codeActivite: data.codeActivite,
        nombreHeuresDebut: data.nombreHeuresDebut,
        nombreHeuresFin: data.nombreHeuresFin
    };
    const response = await axios.post(urls.downloadAnomaliesActivite, body, { headers: headers, params: { client: data.client } });
    return response.data.anomalies;
}