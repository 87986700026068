import { arrange, asc, desc, tidy } from "@tidyjs/tidy";
import moment from "moment";

export const trierDrgGesTemMan = (keyTrie, data) => {
    switch(keyTrie) {
        case "dateEmissionAsc":
            return tidy(data, arrange((dra, drb) => moment(dra.dateDemande, "YYYYMMDD").utc() - moment(drb.dateDemande, "YYYYMMDD").utc()));
        case "dateEmissionDesc": 
            return tidy(data, arrange((dra, drb) => moment(drb.dateDemande, "YYYYMMDD").utc() - moment(dra.dateDemande, "YYYYMMDD").utc()));
        case "heureEmissionAsc":
            return tidy(data, arrange((dra, drb) => moment(dra.heureDemande, "Hmmss").utc() - moment(drb.heureDemande, "Hmmss").utc()));
        case "heureEmissionDesc": 
            return tidy(data, arrange((dra, drb) => moment(drb.heureDemande, "Hmmss").utc() - moment(dra.heureDemande, "Hmmss").utc()));
        case "dateRegularisationAsc":
            return tidy(data, arrange((dra, drb) => moment([dra.anneeRattachement, dra.moisRattachement - 1, dra.jourRattachement]).utc() - moment([drb.anneeRattachement, drb.moisRattachement - 1, drb.jourRattachement]).utc()));
        case "dateRegularisationDesc": 
            return tidy(data, arrange((dra, drb) => moment([drb.anneeRattachement, drb.moisRattachement - 1, drb.jourRattachement]).utc() - moment([dra.anneeRattachement, dra.moisRattachement - 1, dra.jourRattachement]).utc()));
        case "statutAsc": 
            return tidy(data, arrange([asc("libelleStatut")]));
        case "statutDesc":
            return tidy(data, arrange([desc("libelleStatut")]));
        case "motifAsc":
            return tidy(data, arrange([asc("observation")]));
        case "motifDesc":
            return tidy(data, arrange([desc("observation")]));
        case "emetteurAsc":
            return tidy(data, arrange([asc("emetteur")]));
        case "emetteurDesc":
            return tidy(data, arrange([desc("emetteur")]));
        default: 
            return data;
    }
};

export const trierDrgGesTemCol = (keyTrie, data) => {
    switch(keyTrie) {
        case "dateEmissionAsc":
            return tidy(data, arrange((dra, drb) => moment(dra.dateDemande, "YYYYMMDD").utc() - moment(drb.dateDemande, "YYYYMMDD").utc()));
        case "dateEmissionDesc": 
            return tidy(data, arrange((dra, drb) => moment(drb.dateDemande, "YYYYMMDD").utc() - moment(dra.dateDemande, "YYYYMMDD").utc()));
        case "heureEmissionAsc":
            return tidy(data, arrange((dra, drb) => moment(dra.heureDemande, "Hmmss").utc() - moment(drb.heureDemande, "Hmmss").utc()));
        case "heureEmissionDesc": 
            return tidy(data, arrange((dra, drb) => moment(drb.heureDemande, "Hmmss").utc() - moment(dra.heureDemande, "Hmmss").utc()));
        case "dateRegularisationAsc":
            return tidy(data, arrange((dra, drb) => moment([dra.anneeRattachement, dra.moisRattachement - 1, dra.jourRattachement]).utc() - moment([drb.anneeRattachement, drb.moisRattachement - 1, drb.jourRattachement]).utc()));
        case "dateRegularisationDesc": 
            return tidy(data, arrange((dra, drb) => moment([drb.anneeRattachement, drb.moisRattachement - 1, drb.jourRattachement]).utc() - moment([dra.anneeRattachement, dra.moisRattachement - 1, dra.jourRattachement]).utc()));
        case "statutAsc": 
            return tidy(data, arrange([asc("libelleStatut")]));
        case "statutDesc":
            return tidy(data, arrange([desc("libelleStatut")]));
        case "motifAsc":
            return tidy(data, arrange([asc("observation")]));
        case "motifDesc":
            return tidy(data, arrange([desc("observation")]));
        default: 
            return data;
    }

    return 
};
