import { useCallback } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { CheckHookFormInput, DateHookFormInput, SelectHookFormInput } from "@components/Inputs";
import { TextContainer } from "@components/Containers";
import { whiteColor } from "@styles";
import { SocietesHookFormInput } from "@components/Inputs/Societes";
import { EtablissementsHookFormInput } from "@components/Inputs/Etablissements";
import { DirectionsHookFormInput } from "@components/Inputs/Directions";
import { ServicesHookFormInput } from "@components/Inputs/Services";
import { SecteursHookFormInput } from "@components/Inputs/Secteurs";
import { AnomaliesHookFormInput } from "@components/Inputs/Anomalies";

const typesMouvement = [
    {id: 1, value: "E", label: "Entrée"},
    {id: 2, value: "S", label: "Sortie"},
    {id: 3, value: "T", label: "Tous"},
]

const gravites = [
    {id: 1, value: "1", label: "1"},
    {id: 2, value: "2", label: "2"},
    {id: 3, value: "3", label: "3"},
    {id: 4, value: "4", label: "4"},
    {id: 5, value: "5", label: "5"},
    {id: 6, value: "6", label: "6"},
    {id: 7, value: "7", label: "7"},
    {id: 8, value: "8", label: "8"},
    {id: 9, value: "9", label: "9"}
]

const AnomaliesBaseForm = () => {
    const { watch, resetField } = useFormContext();
    const societeSelected = watch("societe");

    const reset = useCallback(() => {
        resetField("etablissement");
        resetField("direction");
        resetField("service");
    }, [resetField]);

    return (
        <Stack spacing={1.5}>
            <Stack spacing={1}>
                <TextContainer p={1.25} bgColor={whiteColor[5]} horizontalAlign="center" textWeight="bold" textSize={15}>Période d'analyse</TextContainer>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <DateHookFormInput name="dateDebut" label={"Date de début"} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateHookFormInput name="dateFin" label={"Date de fin"} displayError />
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
            <Stack spacing={1}>
                <TextContainer p={1.25} bgColor={whiteColor[5]} horizontalAlign="center" textWeight="bold" textSize={15}>Critères de sélection</TextContainer>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <SocietesHookFormInput action={reset} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <EtablissementsHookFormInput codeSociete={societeSelected?.codeSociete} />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <DirectionsHookFormInput codeSociete={societeSelected?.codeSociete} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ServicesHookFormInput codeSociete={societeSelected?.codeSociete} />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <SecteursHookFormInput />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AnomaliesHookFormInput />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={1}>     
                        <Grid item xs={12} md={3}>
                            <SelectHookFormInput name="gravite1" label="Gravité de" options={gravites} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <SelectHookFormInput name="gravite2" label="À" options={gravites} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <SelectHookFormInput name="typeMouvement" label="Type de mouvement" options={typesMouvement} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box borderRadius={2} bgcolor={whiteColor[5]} height={40} display="flex" alignItems="center" px={2}>
                                <CheckHookFormInput name="anomalieAReactualiser" label="Anomalies à réactualiser" />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
        </Stack>
    );
}

export default AnomaliesBaseForm;