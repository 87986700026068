import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { Info, Login, Logout, Loop } from "@mui/icons-material";
import PropTypes from "prop-types";
import { MenuButton } from "@components/Buttons";
import { selectUsrApp } from "@selectors";

const StatutActionsBarMobile = (props) => {
    const { actionSuivante, handleOpenConfirmationPointage, setOpenActivitesDialog, setOpenInfosDialog } = props;
    const stateUser = useSelector(selectUsrApp);

    return (
        <Box display="flex" alignItems="center" p={1}>
            {(stateUser.autorisationEd && stateUser.autorisationAc) &&
                <MenuButton txtId="BTDABSMOB" options={[
                    {id: 1, icon: <Info fontSize="small" />, label: "Informations", action: () => setOpenInfosDialog(true)},
                    {id: 2, icon: actionSuivante === "D" ? <Logout fontSize="small" /> : <Login fontSize="small" />, label: actionSuivante === "D" ? "Départ" : "Entrée", action: () => handleOpenConfirmationPointage("pointage")},
                    {id: 3, icon: <Loop fontSize="small" />, label: "Changement d'activité", action: () => setOpenActivitesDialog(true)},
                ]} />
            }
            {(stateUser.autorisationEd && !stateUser.autorisationAc) &&
                <MenuButton txtId="BTDABSMOB" options={[
                    {id: 1, icon: <Info fontSize="small" />, label: "Informations", action: () => setOpenInfosDialog(true)},
                    {id: 2, icon: actionSuivante === "D" ? <Logout fontSize="small" /> : <Login fontSize="small" />, label: actionSuivante === "D" ? "Départ" : "Entrée", action: () => handleOpenConfirmationPointage("pointage")}
                ]} />
            }
            {(!stateUser.autorisationEd && stateUser.autorisationAc) &&
                <MenuButton txtId="BTDABSMOB" options={[
                    {id: 1, icon: <Info fontSize="small" />, label: "Informations", action: () => setOpenInfosDialog(true)},
                    {id: 2, icon: <Loop fontSize="small" />, label: "Changement d'activité", action: () => setOpenActivitesDialog(true)}
                ]} />
            }
            {(!stateUser.autorisationEd && !stateUser.autorisationAc) &&
                <MenuButton txtId="BTDABSMOB" options={[
                    {id: 1, icon: <Info fontSize="small" />, label: "Informations", action: () => setOpenInfosDialog(true)}
                ]} />
            }
        </Box>
    );
}

StatutActionsBarMobile.defaultProps = {
    actionSuivante: "",
    handleOpenConfirmationPointage: () => {},
    setOpenActivitesDialog: () => {},
    setOpenInfosDialog: () => {}
}

StatutActionsBarMobile.propTypes = {
    actionSuivante: PropTypes.string,
    handleOpenConfirmationPointage: PropTypes.func,
    setOpenActivitesDialog: PropTypes.func,
    setOpenInfosDialog: PropTypes.func
}

export default StatutActionsBarMobile;