import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dateRegularisation: "",
    activites: [],
    typesTravail: [],
    transactions: [],
    regularisations: [],
    suivi: [],
    autoriserTypesTravail: false
};

const regGesTemSlice = createSlice({
    name: 'regGesTem',
    initialState: initialState,
    reducers: {
        setDateRegularisation: (state, action) => {
            state.dateRegularisation = action.payload
        },
        setDetailRegularisation: (state, action) => {
            state.transactions = action.payload.transactions;
            state.regularisations = action.payload.regularisations;
            state.suivi = action.payload.suivi;
        },
        setActivites: (state, action) => {
            state.activites = action.payload;
        },
        setTypesTravail: (state, action) => {
            state.typesTravail = action.payload.typesTravail;
            state.autoriserTypesTravail = action.payload.authoriser;
        },
        reset: () => initialState
    }
})

const { actions, reducer } = regGesTemSlice
export const { 
    setDateRegularisation,
    setDetailRegularisation,
    setActivites,
    setTypesTravail,
    reset 
} = actions
export default reducer
