import { Box, Grid, Grow, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { greenColor } from "@styles";

const ParametresPanel = (props) => {
    const { value, timeout } = props;

    return (
        <Grow in={true} timeout={timeout}>
            <Grid item xs={12} sm={4}>
                <Box bgcolor={greenColor["02"]} borderRadius={2}>
                    <Stack spacing={0.5} padding={0.5} textAlign="center">
                        {value}
                    </Stack>
                </Box>
            </Grid>
        </Grow>
    );
}

ParametresPanel.defaultProps = {
    value: <></>,
    timeout: 0
}

ParametresPanel.propTypes = {
    value: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),
    timeout: PropTypes.number
}

export default ParametresPanel;