import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    listed: false,
    filtered: false,
    all: false,
    demandesAbsence: [],
    demandesAbsenceDisplayed: [],
    demandesAbsenceSelected: []
}

const hisDabGesAbsSlice = createSlice({
    name: 'hisDabGesAbs',
    initialState: initialState,
    reducers: {
        setDemandesAbsence: (state, action) => {
            state.demandesAbsence = action.payload;
            state.demandesAbsenceDisplayed = action.payload;
        },
        lister: (state) => {
            state.listed = !state.listed;
        },
        filtrer: (state, action) => {
            state.demandesAbsenceDisplayed = action.payload;
            state.filtered = true;
        },
        deleteFiltre: (state) => {
            state.demandesAbsenceDisplayed = state.demandesAbsence
            state.filtered = false
        }
    },
})

const { actions, reducer } = hisDabGesAbsSlice
export const { 
    setDemandesAbsence,
    lister, 
    filtrer, 
    deleteFiltre
} = actions
export default reducer