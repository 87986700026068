import { Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { selectDabGesAbsMan, selectUsrApp, selectPlaDabMan } from "@selectors";
import { usePlaDabMan } from "@hooks/planning/hooksQueries";
import * as plaDabManActions from "@reducers/planning/plaDabManReducer"
import PlanningContainer from "@components/App/Planning/Containers/PlanningContainer";
import { TYPES_PLANNING } from "@constants/planningConstants";
import { ContrainteDialog, JourDialog } from "@components/App/Planning/Dialogs";
import { PlanningPanelActions, PlanningPanelActionsMobile } from "./Actions";
import { useMedia } from "react-use";
import { TextContainer } from "@components/Containers";
import { greenColor } from "@styles";
import PlanningContainerMobile from "@components/App/Planning/Containers/PlanningContainerMobile";

const Planning = () => {
    const stateUser = useSelector(selectUsrApp);
    const statePlaDab = useSelector(selectPlaDabMan);
    const [openContraintes, setOpenContraintes] = useState(false);
    const [openJour, setOpenJour] = useState(false);
    const stateAbsencesManager = useSelector(selectDabGesAbsMan);
    const { isLoading, isFetching, isError, error, refetch } = usePlaDabMan(stateUser.matricule, stateAbsencesManager.demandeAbsenceSelected, statePlaDab.date, statePlaDab.affichage);
    const dispatch = useDispatch();
    const isMobile = useMedia('(max-width: 800px)');

    const precedent = useCallback(() => {
        dispatch(plaDabManActions.setDate(moment(statePlaDab.date, "DDMMYYYY").add(-1, "week").format("DDMMYYYY")));
    }, [dispatch, statePlaDab.date]);

    const suivant = useCallback(() => {
        dispatch(plaDabManActions.setDate(moment(statePlaDab.date, "DDMMYYYY").add(+1, "week").format("DDMMYYYY")));
    }, [dispatch, statePlaDab.date]);

    const selectDate = useCallback((date) => {
        dispatch(plaDabManActions.setDate(date.format("DDMMYYYY")));
    }, [dispatch]);

    const toggleAffichage = useCallback(() => {
        dispatch(plaDabManActions.toggleAffichage());
    }, [dispatch]);

    const toggleAll = useCallback(() => {
        dispatch(plaDabManActions.toggleAll());
    }, [dispatch]);

    const handleOpenJour = useCallback((idJour, matricule, prenomNom) => {
        const jour = {jour: moment(statePlaDab.semaine.jours[idJour].date, "YYYYMMDD").format("DDMMYYYY"), matricule: matricule, prenomNom: prenomNom}
        dispatch(plaDabManActions.selectJour(jour));
        setOpenJour(true);
    }, [dispatch, statePlaDab.semaine.jours]);

    return (
        <>
            <Stack spacing={0.5}>
                {isMobile ? 
                    <>
                        <TextContainer bgColor={greenColor["015"]} horizontalAlign="center" textWeight="bold">
                            Planning
                        </TextContainer>
                        <PlanningPanelActionsMobile
                            isLoading={isLoading || isFetching} 
                            isError={isError}
                            setOpenContraintes={setOpenContraintes} 
                            affichage={statePlaDab.affichage} 
                            allServices={statePlaDab.allServices} 
                            precedent={precedent} 
                            suivant={suivant} 
                            selectDate={selectDate} 
                            toggleAffichage={toggleAffichage} 
                            toggleAll={toggleAll} 
                            refetch={refetch}
                        />
                    </>
                :
                    <PlanningPanelActions
                        isLoading={isLoading || isFetching} 
                        isError={isError}
                        setOpenContraintes={setOpenContraintes} 
                        affichage={statePlaDab.affichage} 
                        allServices={statePlaDab.allServices} 
                        precedent={precedent} 
                        suivant={suivant} 
                        selectDate={selectDate} 
                        toggleAffichage={toggleAffichage} 
                        toggleAll={toggleAll} 
                        refetch={refetch}
                    />
                }
                {isMobile ? 
                    <PlanningContainerMobile
                        isLoading={isLoading || isFetching}
                        isError={isError}
                        errorMessage={error?.message}
                        type={TYPES_PLANNING.ABSENCE_MANAGER} 
                        openJour={handleOpenJour}
                    />
                :
                    <PlanningContainer
                        isLoading={isLoading || isFetching}
                        isError={isError}
                        errorMessage={error?.message}
                        type={TYPES_PLANNING.ABSENCE_MANAGER} 
                        jourCourt={true} 
                        variant="absenceManager"
                        openJour={handleOpenJour}
                    />
                }
            </Stack>
            <ContrainteDialog 
                type={TYPES_PLANNING.ABSENCE_MANAGER}
                open={openContraintes} 
                setOpen={setOpenContraintes} 
            />
            <JourDialog
                type={TYPES_PLANNING.ABSENCE_MANAGER} 
                open={openJour} 
                setOpen={setOpenJour} 
            /> 
        </>
    );
}

export default Planning;