import { urlsEntretien } from "@constants/appConstants";
import axios from "axios";

export const fetchEnt = async (utilisateur, matricule, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetEntretiens"
    };
    const response = await axios.post(urlsEntretien.getEntretiens, { utilisateur, matricule, typeUtilisateur: "MANAGER" }, { headers: headers, params: { client: client }, signal: signal });
    return response.data;
}

export const fetchEntCol = async (matricule, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetEntretiens"
    };
    const response = await axios.post(urlsEntretien.getEntretiens, { utilisateur: "", matricule, typeUtilisateur: "COLLABORATEUR" }, { headers: headers, params: { client: client }, signal: signal });
    return response.data;
}

export const fetchSecEnt = async (codeCle, codeEntretien, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetSectionsEntretienManager"
    };
    const response = await axios.post(urlsEntretien.getSectionsEntretien, { codeCle, codeEntretien }, { headers: headers, params: { client }, signal: signal });
    return response.data.sections;
}

export const fetchSecEntAcc = async (codeCle, codeEntretien, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetSectionsEntretienCollaborateur"
    };
    const response = await axios.post(urlsEntretien.getSectionsEntretienAccessibles, { codeCle, codeEntretien }, { headers: headers, params: { client }, signal: signal });
    return response.data.sections;
}

export const fetchSouSecEnt = async (codeCle, codeEntretien, rang, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetSectionsEntretienManager"
    };
    const response = await axios.post(urlsEntretien.getSousSectionsEntretien, { codeCle, codeEntretien, rang }, { headers: headers, params: { client }, signal: signal });
    return response.data.sections;
}

export const fetchSouSecCmpEnt = async (codeCle, codeEntretien, rang, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetSectionsEntretienManager"
    };
    const response = await axios.post(urlsEntretien.getSousSectionsCompetencesEntretien, { codeCle, codeEntretien, rang }, { headers: headers, params: { client }, signal: signal });
    return response.data.sections;
}

export const fetchCmpEnt = async (codeCle, codeEntretien, rang, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetSectionsEntretienManager"
    };
    const response = await axios.post(urlsEntretien.getCompetencesEntretien, { codeCle, codeEntretien, rang }, { headers: headers, params: { client }, signal: signal });
    return response.data.sections;
}

export const fetchQueEnt = async (codeCleQuestion, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetSectionsEntretienManager"
    };
    const response = await axios.post(urlsEntretien.getQuestionsEntretien, { codeCleQuestion }, { headers: headers, params: { client }, signal: signal });
    return response.data.questions;
}

export const fetchComEnt = async (codeSociete, matricule, codeCle, rang, codeSection, codeSousSection, codeCompetence, codeQuestion, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetSectionsEntretienManager"
    };
    const response = await axios.post(urlsEntretien.getCommentaireEntretien, { codeSociete, matricule, codeCle, rang, codeSection, codeSousSection, codeCompetence, codeQuestion }, { headers: headers, params: { client }, signal: signal });
    return response.data;
}

export const fetchComQueEnt = async (codeSociete, matricule, codeCle, rang, codeSection, codeSousSection, codeCompetence, codesQuestions, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetSectionsEntretienManager"
    };
    const response = await axios.post(urlsEntretien.getCommentairesQuestionnaireEntretien, { codeSociete, matricule, codeCle, rang, codeSection, codeSousSection, codeCompetence, codesQuestions }, { headers: headers, params: { client }, signal: signal });
    return response.data;
}

export const editEnt = async (data) => {
    const header = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteEditEntretienManager", 
    };
    const body = {
        codeSociete: data.codeSociete,
        matricule: data.matricule,
        annee: data?.annee,
        mois: data?.mois,
        jour: data?.jour,
        heure: data?.heure,
        minute: data?.minute,
        codeCle: data.codeCle,
        statut: data?.statut,
        interlocuteur: data?.interlocuteur,
        initiative: data?.initiative,
        sujet: data?.sujet,
        commentaire1: data.commentaire1,
        commentaire2: data.commentaire2,
        commentaire3: data.commentaire3,
        commentaire4: data.commentaire4,
        commentaire5: data.commentaire5,
        commentaire6: data.commentaire6,
        commentaire7: data.commentaire7,
        commentaire8: data.commentaire8,
        commentaire9: data.commentaire9,
        commentaire10: data.commentaire10
    };
    const response = await axios.post(urlsEntretien.putEntretien, body, { headers: header, params: { client: data.client } });
    return response.data;
}

export const deleteEnt = async (data) => {
    const header = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteDeleteEntretienManager", 
    };
    const body = {
        codeSociete: data.codeSociete,
        matricule: data.matricule,
        codeCle: data.codeCle
    };
    const response = await axios.post(urlsEntretien.deleteEntretien, body, { headers: header, params: { client: data.client } });
    return response.data;
}

export const deleteSouSecEnt = async (data) => {
    const header = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteDeleteEntretienManager", 
    };
    const body = {
        codeSociete: data.codeSociete,
        matricule: data.matricule,
        codeCle: data.codeCle,
        rang: data.rang,
        codeSection: data?.codeSection,
        codeSousSection: data?.codeSousSection,
        codeCompetence: data?.codeCompetence,
        codeQuestion: data?.codeQuestion,
        nombreSousSection: data.nombreSousSection
    };
    const response = await axios.post(urlsEntretien.deleteSousSectionEntretien, body, { headers: header, params: { client: data.client } });
    return response.data;
}

export const postSouSecEnt = async (data) => {
    const header = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostCommentaireEntretienManager", 
    };
    const body = {
        codeForm: data.codeForm,
        codeSociete: data.codeSociete,
        matricule: data.matricule,
        codeCle: data.codeCle,
        rang: data?.rang,
        codeSection: data?.codeSection,
        codeSousSection: data?.codeSousSection,
        codeCompetence: data.codeCompetence,
        codeQuestion: data?.codeQuestion,
        codeIdentificationSection: data?.codeIdentificationSection,
        commentaires: data?.commentaires,
        complement1: data?.complement1,
        complement2: data?.complement2,
        complement3: data?.complement3,
        complement4: data?.complement4,
        evaluation: data?.evaluation,
        classement: data?.classement,
        date: data?.date
    };
    const response = await axios.post(urlsEntretien.putSousSectionEntretien, body, { headers: header, params: { client: data.client } });
    return response.data;
}

export const postQueEnt = async (data) => {
    const header = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostCommentaireEntretienManager", 
    };
    const body = {
        codeSociete: data.codeSociete,
        matricule: data.matricule,
        codeCle: data.codeCle,
        rang: data?.rang,
        codeSection: data?.codeSection,
        codeSousSection: data?.codeSousSection,
        codeCompetence: data.codeCompetence,
        questionsCommentaires: data?.questionsCommentaires,
        complement1: data?.complement1,
        complement2: data?.complement2,
        complement3: data?.complement3,
        complement4: data?.complement4,
        reponseQuestion1: data?.reponseQuestion1,
        reponseQuestion2: data?.reponseQuestion2,
        reponseQuestion3: data?.reponseQuestion3,
        reponseQuestion4: data?.reponseQuestion4,
        reponseQuestion5: data?.reponseQuestion5,
        reponseQuestion6: data?.reponseQuestion6,
        date: data?.date
    };
    const response = await axios.post(urlsEntretien.putQuestionnaireEntretien, body, { headers: header, params: { client: data.client } });
    return response.data;
}

export const postComEntCol = async (data) => {
    const header = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostCommentaireEntretienCollaborateur", 
    };
    const body = {
        codeSociete: data.codeSociete,
        matricule: data.matricule,
        codeCle: data.codeCle,
        rang: data?.rang,
        codeSection: data?.codeSection,
        codeSousSection: data?.codeSousSection,
        codeCompetence: data?.codeCompetence,
        codeQuestion: data?.codeQuestion,
        commentaires: data?.commentaires
    };
    const response = await axios.post(urlsEntretien.putCommentaireCollaborateurEntretien, body, { headers: header, params: { client: data.client } });
    return response.data;
}

export const postComEntMan = async (data) => {
    const header = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostCommentaireEntretienManager", 
    };
    const body = {
        codeSociete: data.codeSociete,
        matricule: data.matricule,
        codeCle: data.codeCle,
        rang: data?.rang,
        codeSection: data?.codeSection,
        codeSousSection: data?.codeSousSection,
        codeCompetence: data?.codeCompetence,
        codeQuestion: data?.codeQuestion,
        commentaires: data?.commentaires
    };
    const response = await axios.post(urlsEntretien.putCommentaireManagerEntretien, body, { headers: header, params: { client: data.client } });
    return response.data;
}

export const addSouSecEnt = async (data) => {
    const header = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteAddSectionEntretienManager", 
    };
    const body = {
        codeSociete: data.codeSociete,
        matricule: data.matricule,
        codeCle: data.codeCle,
        codeEntretien: data?.codeEntretien,
        rang: data?.rang,
        codeSection: data?.codeSection,
        codeSousSection: data?.codeSousSection,
        codeCompetence: data.codeCompetence,
        codeQuestion: data?.codeQuestion,
        codeIdentificationSection: data?.codeIdentificationSection,
        designation1: data?.designation1,
        designation2: data?.designation2,
        commentaires: data?.commentaires,
        complement1: data?.complement1,
        complement2: data?.complement2,
        complement3: data?.complement3,
        complement4: data?.complement4,
        evaluation: data?.evaluation,
        classement: data?.classement,
        date: data?.date,
        origineSousSection: data?.origineSousSection
    };
    const response = await axios.post(urlsEntretien.postSousSectionEntretien, body, { headers: header, params: { client: data.client } });
    return response.data;
}

export const addSouSecQueEnt = async (data) => {
    const header = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteAddSectionEntretienManager", 
    };
    const body = {
        codeSociete: data.codeSociete,
        matricule: data.matricule,
        codeCle: data.codeCle,
        codeEntretien: data?.codeEntretien,
        rang: data?.rang,
        codeSection: data?.codeSection,
        codeSousSection: data?.codeSousSection,
        codeCompetence: data.codeCompetence,
        codeQuestion: data?.codeQuestion,
        codeIdentificationSection: data?.codeIdentificationSection,
        designation1: data?.designation1,
        designation2: data?.designation2,
        commentaire1: data?.commentaire1,
        commentaire2: data?.commentaire2,
        commentaire3: data?.commentaire3,
        commentaire4: data?.commentaire4,
        commentaire5: data?.commentaire5,
        commentaire6: data?.commentaire6,
        reponseQuestion1: data?.reponseQuestion1,
        reponseQuestion2: data?.reponseQuestion2,
        reponseQuestion3: data?.reponseQuestion3,
        reponseQuestion4: data?.reponseQuestion4,
        reponseQuestion5: data?.reponseQuestion5,
        reponseQuestion6: data?.reponseQuestion6,
        complement1: data?.complement1,
        complement2: data?.complement2,
        complement3: data?.complement3,
        complement4: data?.complement4,
        date: data?.date,
        origineSousSection: data?.origineSousSection
    };
    const response = await axios.post(urlsEntretien.postSousSectionQuestionnaireEntretien, body, { headers: header, params: { client: data.client } });
    return response.data;
}

export const downloadEnt = async (data) => {
    const header = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetFichierEntretienManager", 
    };
    const body = {
        codeSociete: data.codeSociete,
        matricule: data.matricule,
        codeCle: data.codeCle,
        jour: data.jour,
        mois: data.mois,
        annee: data.annee,
        heure: data.heure,
        minute: data.minute
    };
    const response = await axios.post(urlsEntretien.downloadEntretien, body, { headers: header, params: { client: data.client } });
    return response.data;
}

export const terminerEnt = async (data) => {
    const header = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteTerminerEntretienManager", 
    };
    const body = {
        codeSociete: data.codeSociete,
        matricule: data.matricule,
        codeCle: data.codeCle
    };
    const response = await axios.post(urlsEntretien.terminerEntretien, body, { headers: header, params: { client: data.client } });
    return response.data;
}