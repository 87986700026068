import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { greyColor } from "@styles";

const TextVariableContainer = (props) => {
    const { 
        children, 
        type, 
        bgColor, 
        p, 
        display, 
        textAlign, 
        height, 
        verticalAlign, 
        horizontalAlign, 
        textSize, 
        textWeight 
    } = props;

    return (  
        <Box bgcolor={bgColor} padding={p} borderRadius={2} height={height} boxSizing="border-box" textAlign={textAlign} display={display} alignItems={verticalAlign} justifyContent={horizontalAlign}>
            {type === "txt" && <Typography fontSize={textSize} fontWeight={textWeight}>{children}</Typography>}
            {type === "icon" && {children}}
        </Box>
    );
}

TextVariableContainer.defaultProps = {
    children: <></>, 
    type: "txt", 
    bgColor: greyColor[0], 
    p: 2, 
    display: "block", 
    textAlign: "left", 
    height: "", 
    verticalAlign: "top", 
    horizontalAlign: "left", 
    textSize: "small", 
    textWeight: "normal" 
}

TextVariableContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]), 
    type: PropTypes.string, 
    bgColor: PropTypes.string, 
    p: PropTypes.number, 
    display: PropTypes.string, 
    textAlign: PropTypes.string, 
    height: PropTypes.string, 
    verticalAlign: PropTypes.string, 
    horizontalAlign: PropTypes.string, 
    textSize: PropTypes.string, 
    textWeight: PropTypes.string 
}

export default TextVariableContainer