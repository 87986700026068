import { Add, DeviceHub, FilterList, FilterListOff, History, MobiledataOff, Refresh, SwapVert, Today, ViewList, ViewModule } from "@mui/icons-material";
import PropTypes from "prop-types";
import { HeaderPanelContainer } from "@components/Containers";
import { greenColor } from "@styles";
import { useMedia } from "react-use";
import { IconButtonBase } from "@components/Buttons";

const DelegationsActionsBar = ({
    isLoading = true,
    isError = false,
    tried = false,
    filtered = false,
    listed = false,
    type = "E",
    deleteTrie = () => {},
    deleteFiltre = () => {},
    setAnchorEl = () => {},
    setOpenFiltre = () => {},
    goTo = () => {},
    openAjout = () => {},
    toggleType = () => {},
    lister = () => {},
    refetch = () => {}
}) => {
    const isMobile = useMedia('(max-width: 650px)');

    return (
        <HeaderPanelContainer
            actionsLeft={
                <>
                    <IconButtonBase 
                        variant="white5Grey9White9" 
                        icon={tried ? <MobiledataOff fontSize="small" /> : <SwapVert fontSize="small" />} 
                        title={tried ? "Enlever le trie" : "Trier"} 
                        disabled={isLoading || isError} 
                        action={tried ? () => deleteTrie() : (e) => setAnchorEl(e.currentTarget)} 
                    />
                    <IconButtonBase 
                        variant="white5Grey9White9" 
                        icon={filtered ? <FilterListOff fontSize="small" /> : <FilterList fontSize="small" />} 
                        title={filtered ? "Enlever le filtre" : "Filtrer"} 
                        disabled={isLoading || isError} 
                        action={filtered ? () => deleteFiltre() : () => setOpenFiltre(true)} 
                    />
                    <IconButtonBase 
                        variant="white5Grey9White9" 
                        icon={<Add fontSize="small" />} 
                        title="Ajouter" 
                        disabled={isLoading || isError} 
                        action={() => openAjout()} 
                    />
                </>
            }
            actionsRight={
                <>
                    <IconButtonBase 
                        variant="white5Grey9White9" 
                        icon={<DeviceHub fontSize="small" />} 
                        title="Droits de validation" 
                        disabled={isLoading || isError} 
                        action={() => goTo("droits")} 
                    />
                    <IconButtonBase 
                        variant="white5Grey9White9" 
                        icon={type === "H" ? <Today fontSize="small" /> : <History fontSize="small" />} 
                        title={type === "H" ? "En cours" : "Historique"} 
                        disabled={isLoading || isError} 
                        action={() => toggleType()} 
                    />
                    {!isMobile && 
                        <IconButtonBase 
                            variant="white5Grey9White9" 
                            icon={listed ? <ViewModule fontSize="small" /> : <ViewList fontSize="small" />} 
                            title={listed ? "Panneaux" : "Liste"} 
                            disabled={isLoading || isError} 
                            action={() => lister()} 
                        />
                    }            
                    <IconButtonBase 
                        variant="white5Grey9White9" 
                        icon={<Refresh fontSize="small" />} 
                        title="Rafraichir" 
                        disabled={isLoading} 
                        action={() => refetch()} 
                    />
                </>
            }
            p={1}
            variant="green07"
        />
    )
}

DelegationsActionsBar.propTypes = {
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    tried: PropTypes.bool,
    filtered: PropTypes.bool,
    listed: PropTypes.bool,
    type: PropTypes.string,
    deleteTrie: PropTypes.func,
    deleteFiltre: PropTypes.func,
    setAnchorEl: PropTypes.func,
    setOpenFiltre: PropTypes.func,
    goTo: PropTypes.func,
    openAjout: PropTypes.func,
    toggleType: PropTypes.func,
    lister: PropTypes.func,
    refetch: PropTypes.func
}

export default DelegationsActionsBar;