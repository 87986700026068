import { Box, Button, Drawer, Grid, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { TextContainer } from "@components/Containers";
import { greenColor, redColor, whiteColor } from "@styles";
import { useMedia } from "react-use";
import { Close, Visibility } from "@mui/icons-material";
import { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

const CollaborateursText = (props) => {
    const { multiple, collaborateur, collaborateurs } = props;
    const [open, setOpen] = useState(false);
    const isMobile = useMedia('(max-width: 1115px)');
    const isLarge = useMedia('(min-width: 650px)');

    if(multiple) {
        return (
            isMobile ? 
                <>
                    {collaborateurs.length > 0 ?
                        <Box>
                            <Button 
                                variant="outlined" 
                                startIcon={<Visibility fontSize="small" />} 
                                size="small"
                                sx={{ 
                                    color: "white", 
                                    backgroundColor: greenColor["075"], 
                                    borderRadius: 10,
                                    border: "0px",
                                    "&:hover": { 
                                        color: "white",
                                        backgroundColor: greenColor["05"],
                                        border: "0px" 
                                    }  
                                }}
                                onClick={() => setOpen(true)}
                            >
                                Collaborateurs sélectionnés
                            </Button>
                            <Drawer variant="temporary" anchor={"bottom"} open={open} onClose={() => setOpen(false)} PaperProps={{ sx: { width: "100%", height: "60%", backgroundColor: "transparent", p: 1, boxSizing: "border-box" }}} sx={{zIndex: (theme) => theme.zIndex.drawer + 1}} elevation={0}>
                                <Stack bgcolor={whiteColor[9]} borderRadius={2} padding={1} boxSizing="border-box" height={"100%"}>
                                    <PerfectScrollbar>
                                        <Stack spacing={0.5} overflow="auto">
                                            {collaborateurs.map((collaborateur) => (
                                                <Box key={`COL${collaborateur.id}`} bgcolor={greenColor["015"]} borderRadius={5} py={0.5} px={2} sx={{ whiteSpace: "nowrap" }}>
                                                    <Typography fontSize="small" fontWeight="bold">{collaborateur.prenomNom} ({collaborateur.matricule})</Typography>
                                                </Box>
                                            ))}
                                        </Stack>
                                    </PerfectScrollbar>
                                    <Box height="100px" display="flex" justifyContent="center" alignItems="center">
                                        <Button 
                                            variant="outlined"
                                            startIcon={<Close />} 
                                            size="large"
                                            sx={{ 
                                                width: 150, 
                                                color: redColor[7], 
                                                backgroundColor: redColor[1], 
                                                borderRadius: 10,
                                                border: "0px",
                                                "&:hover": { 
                                                    color: redColor[9],
                                                    backgroundColor: redColor[3],
                                                    border: "0px" 
                                                }  
                                            }}
                                            onClick={() => setOpen(false)}
                                        >
                                            Fermer
                                        </Button>
                                    </Box>
                                </Stack>
                            </Drawer>
                        </Box>
                    :
                        <TextContainer p={0} bgColor="none">Veuillez sélectionner un ou plusieurs collaborateurs.</TextContainer>
                    }
                </>
            :
                <>
                    {collaborateurs.length > 0 ?
                        <Box>
                            <Grid container spacing={0.5} display="flex" alignItems="center">
                                <Grid item>
                                    <Box sx={{ whiteSpace: "nowrap"}}>
                                        <Typography fontSize="small">{isLarge && "Collaborateurs sélectionnés :"}</Typography>
                                    </Box>
                                </Grid>
                                {collaborateurs.map((collaborateur) => (
                                    <Grid item key={`COL${collaborateur.id}`}>
                                        <Box bgcolor={greenColor["015"]} borderRadius={5} py={0.5} px={2} sx={{ whiteSpace: "nowrap" }}>
                                            <Typography fontSize="small" fontWeight="bold">{collaborateur.prenomNom} ({collaborateur.matricule})</Typography>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    :
                        <TextContainer p={0} bgColor="none">Vous devez sélectionner un ou plusieurs collaborateurs pour voir leurs affectations.</TextContainer>
                    }
                </>
        )
    }

    return (
        collaborateur ?
            <TextContainer p={0} bgColor="none">{isLarge && "Collaborateur sélectionné :"} {collaborateur.prenomNom} ({collaborateur.matricule})</TextContainer>
        :
            <TextContainer p={0} bgColor="none">Vous devez selectionner un collaborateur pour voir ses données.</TextContainer>
    );
};

CollaborateursText.defaultProps = {
    multiple: false,
    collaborateur: null,
    collaborateurs: []
}

CollaborateursText.propTypes = {
    multiple: PropTypes.bool,
    collaborateur: PropTypes.object,
    collaborateurs: PropTypes.arrayOf(PropTypes.object)
}

export default CollaborateursText;