import { Box, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { useStateTemGesTem } from "@hooks/gestionTemps/temps/hooksStates";
import { chiffreUSVersChiffreFR, heurifySigned } from "@utils";
import { HeaderListContainer, ListContainer, TextContainer } from "@components/Containers";
import { ErrorBase } from "@components/Errors";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, whiteColor } from "@styles";
import { Visibility } from "@mui/icons-material";

const headers = [
    {
        id: 1,
        content: [
            {id: 1, label: "", xs: 4, display: false},
            {id: 2, label: "Crédit acquis", xs: 2, display: true},
            {id: 3, label: "Régularisations", xs: 6, display: true}
        ]
    },
    {
        id: 2,
        content: [
            {id: 1, label: "Date", xs: 2, display: true},
            {id: 2, label: "Crédit en cours", xs: 2, display: true},
            {id: 3, label: "Jour", xs: 1, display: true},
            {id: 4, label: "Cumul", xs: 1, display: true},
            {id: 5, label: "Valeur", xs: 1.5, display: true},
            {id: 6, label: "Date", xs: 1.5, display: true},
            {id: 7, label: "Utilisateur", xs: 1.5, display: true},
            {id: 8, label: "Commentaire", xs: 1.5, display: true},
        ]
    }
];

const HistoriqueDebitCreditsList = (props) => {
    const { userType, data, date, isLoading, isError, errorMessage } = props;
    const stateTemps = useStateTemGesTem(userType);

    if(isLoading) {
        return (
            <Box>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        )
    }

    if(isError) {
        return <ErrorBase>{errorMessage}</ErrorBase>;
    }

    return (
        <Stack spacing={0.5}>      
            <TextContainer bgColor={greenColor["015"]} p={1} textWeight="bold">Historique à partir du {moment(date, "DDMMYYYY").format("DD/MM/YYYY")}</TextContainer>
            <HeaderListContainer itemIdReference="DEBCREGT1" headerItems={headers[0].content} />
            <HeaderListContainer itemIdReference="DEBCREGT2" headerItems={headers[1].content} />
            <ListContainer spacing={0.5} length={data.length}>
                {data.map((historique) => (
                    <Box key={`HISTDECEGT${historique.id}`} bgcolor={greenColor["015"]} borderRadius={2} p={0.25}>
                        <Grid container spacing={0.5}>
                            <Grid item xs={2}>
                                <TextContainer bgColor={whiteColor[5]} p={1} horizontalAlign="center" height="100%">{moment(historique.date, "DDMMYYYY").format("DD/MM/YYYY")}</TextContainer>
                            </Grid>
                            <Grid item xs={2}>
                                <TextContainer bgColor={whiteColor[5]} p={1} horizontalAlign="center" height="100%">{stateTemps.horaire ? heurifySigned(historique.enCours.heure) : chiffreUSVersChiffreFR(historique.enCours.chiffre)}</TextContainer>
                            </Grid>
                            <Grid item xs={1}>
                                <TextContainer bgColor={whiteColor[5]} p={1} horizontalAlign="center" height="100%">{stateTemps.horaire ? heurifySigned(historique.acquisJour.heure) : chiffreUSVersChiffreFR(historique.acquisJour.chiffre)}</TextContainer>
                            </Grid>
                            <Grid item xs={1}>
                                <TextContainer bgColor={whiteColor[5]} p={1} horizontalAlign="center" height="100%">{stateTemps.horaire ? heurifySigned(historique.acquisCumul.heure) : chiffreUSVersChiffreFR(historique.acquisCumul.chiffre)}</TextContainer>
                            </Grid>
                            <Grid item xs={1.5}>
                                <TextContainer bgColor={whiteColor[5]} p={1} horizontalAlign="center" height="100%">{stateTemps.horaire ? heurifySigned(historique.valeurRegularisation.heure) : chiffreUSVersChiffreFR(historique.valeurRegularisation.chiffre)}</TextContainer>
                            </Grid>
                            <Grid item xs={1.5}>
                                <TextContainer bgColor={whiteColor[5]} p={1} horizontalAlign="center" height="100%">{historique.dateRegularisation ? moment(historique.dateRegularisation, "DDMMYYYY").format("DD/MM/YYYY") : ""}</TextContainer>
                            </Grid>
                            <Grid item xs={1.5}>
                                <TextContainer bgColor={whiteColor[5]} p={1} horizontalAlign="center" height="100%">{historique.utilisateurRegularisation}</TextContainer>
                            </Grid> 
                            <Grid item xs={1.5}>
                                <Box bgcolor={whiteColor[5]} borderRadius={2} height="100%" display="flex" alignItems="center" justifyContent="center">
                                    {historique.commentaireRegularisation && 
                                        <Tooltip title={historique.commentaireRegularisation}>
                                            <IconButton size="small">
                                                <Visibility fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </Box>
                            </Grid> 
                        </Grid>
                    </Box>
                ))} 
            </ListContainer>
        </Stack>
    );
}

HistoriqueDebitCreditsList.defaultProps = {
    data: [],
    isLoading: false, 
    isError: false, 
    errorMessage: ""
}

HistoriqueDebitCreditsList.propTypes = {
    userType: PropTypes.string.isRequired,
    data: PropTypes.array,
    date: PropTypes.string.isRequired,
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool, 
    errorMessage: PropTypes.string
}

export default HistoriqueDebitCreditsList;