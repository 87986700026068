import { useSelector } from "react-redux";
import { selectUsrApp } from "@selectors";
import { DialogContainer } from "@components/Containers";
import { LostPasswordForm } from "../Forms";

const LostPasswordDialog = () => {
    const stateUser = useSelector(selectUsrApp);

    return (
        <DialogContainer open={stateUser.openLostPassword} taille="sm" placement="top">
            <LostPasswordForm />
        </DialogContainer>
    );
    
}

export default LostPasswordDialog;