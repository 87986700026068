import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { selectCltApp } from "@selectors";
import { fetchRes } from "@api/responsables";

export const useResInp = (open) => {
    const stateClient = useSelector(selectCltApp);

    return useQuery(['resInp'], ({ signal }) => fetchRes(stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open
    });
}
