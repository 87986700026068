import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, Stack, Typography } from "@mui/material";
import { greenColor, whiteColor } from "@styles";
import { selectEntSecCol } from "@selectors";
import { useComQueEntCol } from "@hooks/gestionEntretiens/hooksQueries";
import PerfectScrollbar from "react-perfect-scrollbar";
import { IconButtonBase } from "@components/Buttons";
import { ArrowLeft } from "@mui/icons-material";
import ComplementsContent from "./ComplementsContent";
import DateContent from "./DateContent";
import QuestionContent from "./QuestionContent";

const Questionnaire = ({ 
    open = false, 
    goBack = () => {} 
}) => {
    const stateSections = useSelector(selectEntSecCol);
    const sectionSelected = stateSections?.sectionSelected;
    const { data, isLoading, isFetching, isError } = useComQueEntCol(open);

    return (
        <>
            <Box borderRadius={2} bgcolor={greenColor["05"]} height={50} p={0.5}>
                <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[7]} height="100%" alignItems="center" px={1}>
                    <IconButtonBase icon={<ArrowLeft fontSize="small" />} title="Retour" action={() => goBack()} />
                    <Box flexGrow={1}>
                        <Typography fontSize="small" fontWeight="bold">{sectionSelected?.designationSection1}</Typography>
                        <Typography fontSize="small" fontWeight="bold">{sectionSelected?.designationSection2}</Typography>
                    </Box>
                </Stack>
            </Box>
            <PerfectScrollbar> 
                <Stack spacing={0.5} bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                    <Box borderRadius={2} bgcolor={whiteColor[7]} p={1}>
                        <Typography fontSize={"small"} fontWeight={"bold"}>{sectionSelected?.designationSousSection1}</Typography>
                        <Typography fontSize={"small"} fontWeight={"bold"}>{sectionSelected?.designationSousSection2}</Typography>
                    </Box>
                    <ComplementsContent />
                    <DateContent /> 
                    <Box bgcolor={whiteColor[7]} borderRadius={2} p={1} flexGrow={1}>
                        <Typography fontSize="small" fontWeight="bold">{sectionSelected?.questionDesignation}</Typography>
                    </Box>
                    <QuestionContent idQuestion={1} commentaires={data} isLoading={isLoading || isFetching} isError={isError} />
                    <QuestionContent idQuestion={2} commentaires={data} isLoading={isLoading || isFetching} isError={isError} />
                    <QuestionContent idQuestion={3} commentaires={data} isLoading={isLoading || isFetching} isError={isError} />
                    <QuestionContent idQuestion={4} commentaires={data} isLoading={isLoading || isFetching} isError={isError} />
                    <QuestionContent idQuestion={5} commentaires={data} isLoading={isLoading || isFetching} isError={isError} />
                    <QuestionContent idQuestion={6} commentaires={data} isLoading={isLoading || isFetching} isError={isError} />
                </Stack>
            </PerfectScrollbar>
        </>
    );
}

Questionnaire.propTypes = {
    open: PropTypes.bool,
    goBack: PropTypes.func
}

export default Questionnaire;