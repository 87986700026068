import { useState } from "react";
import { Event } from "@mui/icons-material";
import { LocalizationProvider, MobileDatePicker, frFR } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import PropTypes from "prop-types";
import IconDatePickerButton from "./IconDatePickerButton";

const DatePickerButtonMobile = (props) => {
    const { icon, title, placement, timeout, value, noClick, action } = props;
    const [open, setOpen] = useState(false);

    return (  
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"frFR"} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
            <MobileDatePicker
                slots={{ field: IconDatePickerButton }}
                slotProps={{ field: { icon, title, placement, timeout, noClick, setOpen } }}
                open={open}
                value={value}
                views={["year", "month", "day"]}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                onAccept={action}
            />
        </LocalizationProvider>
    );
}

DatePickerButtonMobile.defaultProps = {
    icon: <Event fontSize="small" />, 
    title: "", 
    placement: "bottom", 
    timeout: 200, 
    value: null, 
    noClick: false, 
    action: () => {}
}

DatePickerButtonMobile.propTypes = {
    icon: PropTypes.element, 
    title: PropTypes.string, 
    placement: PropTypes.string, 
    timeout: PropTypes.number, 
    value: PropTypes.object, 
    noClick: PropTypes.bool, 
    action: PropTypes.func
}

export default DatePickerButtonMobile;