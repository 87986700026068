import PropTypes from "prop-types";
import { DialogFullScreenContainer, HeaderDialogContainer } from "@components/Containers";
import { useActPoiGesTem } from "@hooks/activites/hooksQueries";
import { ActivitesList } from "@components/App/Activites";

const ActivitesDialogMobile = (props) => {
    const { open, setOpen, actionSuivante, handleOpenConfirmationPointage } = props;
    const { data, isLoading, isFetching, isError } = useActPoiGesTem("P", open);

    return (
        <DialogFullScreenContainer open={open} placement="top" taille="xs" >
            <HeaderDialogContainer 
                title="Liste des activités"
                close={() => setOpen(false)}
            />
            <ActivitesList 
                data={data} 
                isLoading={isLoading || isFetching}
                isError={isError}
                actionSuivante={actionSuivante} 
                handleOpenConfirmationPointage={handleOpenConfirmationPointage} 
            />    
        </DialogFullScreenContainer>
    );
}

ActivitesDialogMobile.defaultProps = {
    open: false,
    setOpen: () => {},
    handleOpenConfirmationPointage: () => {},
    actionSuivante: ""
}

ActivitesDialogMobile.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    handleOpenConfirmationPointage: PropTypes.func,
    actionSuivante: PropTypes.string
}

export default ActivitesDialogMobile;