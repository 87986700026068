import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activitesAffectations: []
};

const actAffGesTemSlice = createSlice({
    name: 'actAffGesTem',
    initialState: initialState,
    reducers: {
        setActivitesAffectations: (state, action) => {
            state.activitesAffectations = action.payload;
        },
        reset: () => initialState
    }
})

const { actions, reducer } = actAffGesTemSlice
export const { setActivitesAffectations, reset } = actions
export default reducer
