import { Box, Grid, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { greenColor, greyColor, whiteColor } from "@styles";
import { TextContainer } from "@components/Containers";
import { Loader } from "@components/Loaders";
import { ErrorBase } from "@components/Errors";
import PhotoCollaborateur from "@components/App/PhotoCollaborateur";
import { Fragment } from "react";

const headerItems = [
    {id: 1, label: "Service", xs: 2, display: true},
    {id: 2, label: "Nature", xs: 2, display: true},
    {id: 3, label: "Hiérarchie", xs: 8, display: true},
];

const modeRoute = {
    D: "Validation directe",
    H: "Validation par hiérarchie",
    C: "Niveau de contrôle"
};

const DroitsList = ({ 
    data = [], 
    isLoading = true, 
    isError = false,
    errorMessage = ""
}) => {
    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return <ErrorBase>{errorMessage}</ErrorBase>;
    }

    return (
        <Box height="100%" overflow="auto">   
            <Stack spacing={0.5} bgcolor={greenColor["05"]} p={0.5} borderRadius={2}>
                {data.map((societe) => (
                    <Box key={`DDROITSOCLIST${societe.codeSociete}`}>
                        <Stack spacing={0.5}>
                            <TextContainer bgColor={whiteColor[7]} p={1} textWeight="bold" horizontalAlign="center">{societe.libelleSociete}</TextContainer>
                            <Box bgcolor={whiteColor[5]} p={0.3} borderRadius={2}>
                                <Grid container spacing={0.3}>
                                    {headerItems.map((item) => (
                                        <Grid key={`HDDROITSOCLIST${item.id}`} item xs={item.xs}>
                                            {item.display && 
                                                <Box 
                                                    bgcolor={greenColor["05"]} 
                                                    p={1} 
                                                    sx={{ borderTopLeftRadius: item.id === 1 ? 8 : 0, borderBottomLeftRadius: item.id === 1 ? 8 : 0, borderTopRightRadius: item.id === 3 ? 8 : 0, borderBottomRightRadius: item.id === 3 ? 8 : 0 }} 
                                                    height="100%" 
                                                    boxSizing="border-box" 
                                                    display="flex" 
                                                    justifyContent="center" 
                                                    alignItems="center"
                                                >
                                                    <Typography fontSize="small" fontWeight="bold">{item.label}</Typography>
                                                </Box>
                                            }
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                            <Box>                                    
                                <Stack spacing={0.3}>
                                    {societe.servicesCentreCouts.map((serviceCentreCout) => (
                                        serviceCentreCout.natures.map((nature) => (
                                            <Box 
                                                key={`DDROITNATLIST${serviceCentreCout.codeService}${serviceCentreCout.codeCentreCout}${nature.nature}`}
                                                bgcolor={whiteColor[7]}
                                                p={0.3}
                                                borderRadius={2}
                                            >
                                                <Grid container spacing={0.3}>
                                                    <Grid item xs={2}>
                                                        <Box 
                                                            bgcolor={greenColor["05"]} 
                                                            p={0.5} 
                                                            sx={{ borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }} 
                                                            boxSizing="border-box"
                                                            height="100%"
                                                            display="flex" 
                                                            justifyContent="center" 
                                                            alignItems="center"
                                                        >
                                                            {serviceCentreCout.codeService ? 
                                                                <Typography fontSize="small" fontWeight="bold">{serviceCentreCout.codeService} {serviceCentreCout.libelleService}</Typography> 
                                                            : 
                                                                <Typography fontSize="small" fontWeight="bold">{serviceCentreCout.codeCentreCout} {serviceCentreCout.libelleCentreCout}</Typography> 
                                                            }
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Box 
                                                            bgcolor={greenColor["05"]} 
                                                            p={0.5} 
                                                            height="100%" 
                                                            boxSizing="border-box"
                                                            display="flex" 
                                                            justifyContent="center" 
                                                            alignItems="center"
                                                        >
                                                                <Typography fontSize="small" fontWeight="bold">{nature.nature}</Typography>
                                                            </Box>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Stack spacing={0.3} direction="row" overflow="auto">
                                                            {nature.gestionnaires.map((gestionnaire, id) => (
                                                                <Stack 
                                                                    key={`DDROITGESTLIST${gestionnaire.id}`}
                                                                    direction="row" 
                                                                    spacing={1}  
                                                                    bgcolor={greenColor["05"]} 
                                                                    p={0.3} 
                                                                    sx={{ borderTopRightRadius: id === nature?.gestionnaires?.length - 1 ? 8 : 0, borderBottomRightRadius: id === nature?.gestionnaires?.length - 1 ? 8 : 0 }}
                                                                    minWidth={"230px"} 
                                                                    display="flex" 
                                                                    alignItems="center"
                                                                >
                                                                    <Box>
                                                                        <PhotoCollaborateur photo={gestionnaire.photo} nom={gestionnaire.prenomNom} buffered={false} />
                                                                    </Box>
                                                                    <Box>
                                                                        <Typography fontSize="11px">{gestionnaire.prenomNom}</Typography>
                                                                        <Typography fontSize="11px">Niveau {gestionnaire.niveau}</Typography>
                                                                        <Typography fontSize="11px">{modeRoute[gestionnaire.mode]}</Typography>
                                                                    </Box>
                                                                </Stack>
                                                            ))}
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        ))
                                    ))}
                                </Stack>
                            </Box>
                        </Stack>
                    </Box>
                ))}
            </Stack>
        </Box>
    );
}

DroitsList.propTypes = {
    data: PropTypes.array, 
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool,
    errorMessage: PropTypes.string
}

const styles = {
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        height: "100%",
    }
}

export default DroitsList;