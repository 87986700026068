import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import { usePlaDabCol } from "@hooks/planning/hooksQueries";
import * as plaDabColActions from "@reducers/planning/plaDabColReducer"
import { DialogContainer } from "@components/Containers";
import PlanningContainer from "@components/App/Planning/Containers/PlanningContainer";
import { TYPES_PLANNING } from "@constants/planningConstants";
import { selectPlaDabCol } from "@selectors";
import { ContrainteDialog, JourDialog } from "@components/App/Planning/Dialogs";
import { PlanningDialogActions } from "./Actions";

const PlanningDialog = (props) => {
    const { open, setOpen } = props;
    const statePlanning = useSelector(selectPlaDabCol);    
    const [openContraintes, setOpenContraintes] = useState(false);
    const [openJour, setOpenJour] = useState(false);
    const { isLoading, isFetching, isError, error, refetch } = usePlaDabCol(statePlanning.date, statePlanning.date, statePlanning.affichage, open);
    const dispatch = useDispatch();

    const precedent = useCallback(() => {
        dispatch(plaDabColActions.setDate(moment(statePlanning.date, "DDMMYYYY").add(-1, "week").format("DDMMYYYY")));
    }, [dispatch, statePlanning.date]);

    const suivant = useCallback(() => {
        dispatch(plaDabColActions.setDate(moment(statePlanning.date, "DDMMYYYY").add(+1, "week").format("DDMMYYYY")));
    }, [dispatch, statePlanning.date]);

    const selectDate = useCallback((date) => {
        dispatch(plaDabColActions.setDate(date.format("DDMMYYYY")));
    }, [dispatch]);

    const toggleAffichage = useCallback(() => {
        dispatch(plaDabColActions.toggleAffichage());
    }, [dispatch]);

    const toggleAll = useCallback(() => {
        dispatch(plaDabColActions.toggleAll());
    }, [dispatch]);

    const handleOpenJour = useCallback((idJour, matricule, prenomNom) => {
        const jour = {jour: moment(statePlanning.semaine.jours[idJour].date, "YYYYMMDD").format("DDMMYYYY"), matricule: matricule, prenomNom: prenomNom}
        dispatch(plaDabColActions.selectJour(jour));
        setOpenJour(true);
    }, [dispatch, statePlanning.semaine.jours]);

    return (
        <DialogContainer open={open} placement="top" taille="lg">
            <PlanningDialogActions 
                isLoading={isLoading || isFetching} 
                isError={isError} 
                setOpen={setOpen} 
                setOpenContraintes={setOpenContraintes} 
                affichage={statePlanning.affichage} 
                allServices={statePlanning.allServices} 
                precedent={precedent} 
                suivant={suivant} 
                selectDate={selectDate} 
                toggleAffichage={toggleAffichage} 
                toggleAll={toggleAll} 
                refetch={refetch} 
            />
            <PlanningContainer
                isLoading={isLoading || isFetching}
                isError={isError}
                errorMessage={error?.message}
                type={TYPES_PLANNING.ABSENCE_COLLABORATEUR} 
                jourCourt={true} 
                openJour={handleOpenJour}
            />
            <ContrainteDialog 
                type={TYPES_PLANNING.ABSENCE_COLLABORATEUR}
                open={openContraintes} 
                setOpen={setOpenContraintes} 
            />
            <JourDialog
                type={TYPES_PLANNING.ABSENCE_COLLABORATEUR} 
                open={openJour} 
                setOpen={setOpenJour} 
            /> 
        </DialogContainer>
    );
}

PlanningDialog.defaultProps = { 
    open: false,
    setOpen: () => {}
}

PlanningDialog.propTypes = { 
    open: PropTypes.bool,
    setOpen: PropTypes.func
}

export default PlanningDialog;