import axios from "axios";
import { urls } from "@constants/appConstants";
import { convertCheminAs400 } from "@utils";

/**
 * * Appel de la procédure PWABSP£
 * * Url : post/piece/jointe/demande/absence
 * @param {*} data Données nécessaire au stockage de la pièce jointe
 * @returns Données informationnelles sur la réussite de l'appel
 */
export const addPjtDab = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostPieceJointeCollaborateur"
    };
    const body = {
        matricule: data.matricule, 
        referenceDemandeDate: data.referenceDemandeDate, 
        referenceDemandeHeure: data.referenceDemandeHeure, 
        nomFichier: data.nomFichier, 
        fichier: data.contenuFichier 
    };

    return axios.post(urls.postPieceJointeDemandeAbsence, body, { headers: headers, params: { client: data.client } });
}

export const downloadFile = async (chemin, fichier, token, client) => {   
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteDownloadFile", 
        "HttpToken": token
    };

    const body = {
        chemin: convertCheminAs400(chemin), 
        fichier: fichier
    };

    return axios.post(urls.downloadFile, body, { headers: headers, params: { client: client }, responseType: "blob" });
};