import { filter, tidy } from "@tidyjs/tidy";
import moment from "moment";

export const filtrerDocApp = (data, dataBrut) => {
    switch(data.colonne) {
        case "date":
            switch(data.operateur) {
                case "egale":
                    return tidy(dataBrut, filter(document => moment(document.dateDocument, "DDMMYYYY").isSame(data.date)));
                case "superieur":
                    return tidy(dataBrut, filter(document => moment(document.dateDocument, "DDMMYYYY").isSameOrAfter(data.date)));
                case "inferieur":
                    return tidy(dataBrut, filter(document => moment(document.dateDocument, "DDMMYYYY").isSameOrBefore(data.date)));
                default: 
                    return dataBrut
            }
        case "nature":
            return tidy(dataBrut, filter(document => document.natureDocument === data.txt.codeArgument));
        case "nom":
            return tidy(dataBrut, filter(document => document.nom.toLowerCase().includes(data.txt.toLowerCase())));
        default:
            return dataBrut
    }
};
