import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Check } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { selectCltApp, selectSecVarApp, selectUsrApp } from "@selectors";
import * as userActions from "@reducers/user/usrAppReducer"
import { Loader } from "@components/Loaders";
import { IconButtonBase } from "@components/Buttons";
import { greenColor, greyColor } from "@styles";
import { Box, Stack } from "@mui/material";
import { SecretHookFormInput, TextInput } from "@components/Inputs";
import { useQuestionPassword } from "@hooks/securite/hooksQueries";
import { schemaLoginPasswordQuestion } from "../validations";
import { HeaderDialogContainer } from "@components/Containers";
import { useTranslation } from "react-i18next";

const defaultValuesQuestionPassword = {
    newPassword1: "",
    newPassword2: "",
    question: "",
    reponse: ""
}

const QuestionPasswordLoginForm = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const stateSecureVariable = useSelector(selectSecVarApp);
    const dispatch = useDispatch();
    const methods = useForm({ mode: "onChange", defaultValues: defaultValuesQuestionPassword, resolver: joiResolver(schemaLoginPasswordQuestion(stateSecureVariable.parametres)) });
    const { isLoadingPwdQst, editPwdQstActionner } = useQuestionPassword(); 
    const { t } = useTranslation("global");

    const handleUpdateQuestionPassword = useCallback((data) => {
        const dataRequest = {
            matricule: stateUser.matricule,
            oldPassword: stateUser.password,
            password: data.newPassword1,
            question: data.question,
            reponse: data.reponse,
            email: stateUser.email, 
            token: stateUser.token,
            client: stateClient.client
        }
        editPwdQstActionner(dataRequest);
    }, [editPwdQstActionner, stateClient.client, stateUser.email, stateUser.matricule, stateUser.password, stateUser.token]);

    const handleEnter = useCallback((event) => {
        if(event.key === "Enter" || event.key === undefined) {
            methods.handleSubmit(handleUpdateQuestionPassword)();
        }
    }, [handleUpdateQuestionPassword, methods]);

    return (
        <Stack spacing={1}>
            <HeaderDialogContainer 
                actionsLeft={
                    <IconButtonBase 
                        variant="dialogButton"
                        icon={<Check fontSize="small" />} 
                        title="Verifier la réponse" 
                        action={() => methods.handleSubmit(handleUpdateQuestionPassword)} 
                    />
                } 
                title={t("titles.edit_password_question")}
                close={() => dispatch(userActions.openQuestionPassword(false))}
            />
            {isLoadingPwdQst ? 
                <Box p={2}>
                    <Loader colorContainer={greyColor[1]} colorLoader={greenColor["1"]} />
                </Box>
            :
                <FormProvider {...methods}>
                    <Stack spacing={1} onKeyUp={handleEnter}>
                        <TextInput
                            name="question"
                            label={t("inputs.recovery_question")}
                            autoFocus={true} 
                            length={50}
                        />
                        <TextInput
                            name="reponse" 
                            label={t("inputs.recovery_response")}
                            length={30}
                        />
                        <SecretHookFormInput 
                            name="newPassword1"
                            label={t("inputs.password_new")}
                        /> 
                        <SecretHookFormInput 
                            name="newPassword2"
                            label={t("inputs.password_repeat")}
                        /> 
                    </Stack>
                </FormProvider>
            }
        </Stack>
    );
}


export default QuestionPasswordLoginForm;