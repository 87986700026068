import { useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { selectDsh } from "@selectors";
import { whiteColor } from "@styles";
import { PointageShortcutMobile } from "./Pointage";
import { ActivitesShortcutMobile } from "./Activites";

const ShortcutsMobile = (props) => {
    const { 
        isLoadingStatut, 
        isErrorStatut, 
        refetchStatut, 
        handleOpenConfirmationPointage,
        setOpenActivitesDialog  
    } = props;
    const stateDashboard = useSelector(selectDsh);

    const routeShortcuts = {
        sPointage: <PointageShortcutMobile key="SPOINTAGE" isLoadingStatut={isLoadingStatut} isErrorStatut={isErrorStatut} refetchStatut={refetchStatut} handleOpenConfirmationPointage={handleOpenConfirmationPointage} />,
        sActivite: <ActivitesShortcutMobile key="SACTIVITE" isLoadingStatut={isLoadingStatut} isErrorStatut={isErrorStatut} refetchStatut={refetchStatut} setOpenActivitesDialog={setOpenActivitesDialog} />
    }

    return (
        <Box bgcolor={whiteColor[9]} borderRadius={2} minHeight={58} display="flex" alignItems="center">
            <Stack direction="row" spacing={1} px={1}>    
                {stateDashboard.shortcuts.map((shortcut) => (
                    routeShortcuts[shortcut]
                ))}
            </Stack>
        </Box>
    );
}

ShortcutsMobile.defaultProps = {
    isLoadingStatut: true, 
    isErrorStatut: false, 
    refetchStatut: () => {}, 
    handleOpenConfirmationPointage: () => {},
    setOpenActivitesDialog: () => {}
}

ShortcutsMobile.propTypes = {
    isLoadingStatut: PropTypes.bool, 
    isErrorStatut: PropTypes.bool, 
    refetchStatut: PropTypes.func, 
    handleOpenConfirmationPointage: PropTypes.func, 
    setOpenActivitesDialog: PropTypes.func
}

export default ShortcutsMobile;