import { Box, Stack, Typography } from "@mui/material";
import { ArrowLeft } from "@mui/icons-material";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { useMappingActionsCol } from "@hooks/gestionEntretiens/hooksUtils";
import { IconButtonBase } from "@components/Buttons";
import { greenColor, whiteColor } from "@styles";
import CommentaireContent from "./CommentaireContent";

const Commentaire = ({ 
    open = false, 
    goBack = () => {} 
}) => {
    const { designation1, designation2 } = useMappingActionsCol()
    
    return (
        <>
            <Box borderRadius={2} bgcolor={greenColor["05"]} height={50} p={0.5}>
                <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[7]} height="100%" alignItems="center" px={1}>
                    <IconButtonBase icon={<ArrowLeft fontSize="small" />} title="Retour" action={() => goBack()} />
                    <Box flexGrow={1}>
                        <Typography fontSize="small" fontWeight="bold">{designation1}</Typography>
                        <Typography fontSize="small" fontWeight="bold">{designation2}</Typography>
                    </Box>
                </Stack>
            </Box>
            <PerfectScrollbar> 
                <Stack spacing={0.5}>
                    <CommentaireContent open={open} codeQuestion="0" />
                </Stack>
            </PerfectScrollbar>
        </>
    );
}

Commentaire.propTypes = {
    open: PropTypes.bool,
    goBack: PropTypes.func
}

export default Commentaire;