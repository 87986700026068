import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Badge, Business, BusinessCenter, Category, CorporateFare, Factory, Groups, LocalActivity, Looks3, Looks4, LooksOne, LooksTwo, Person, QueryBuilder, Share, WhereToVote, Work, WorkHistory } from "@mui/icons-material";
import PropTypes from "prop-types";
import * as colAffHorColActions from "@reducers/collaborateurs/colAffHorColReducer";
import * as colAffActColActions from "@reducers/collaborateurs/colAffActColReducer";
import { useStateCollaborateurs } from "@hooks/collaborateurs/hooksStates";
import { MenuTrieContainer } from "@components/Containers";
import { trierFwgtp2 } from "../utils";
import { TYPES_COLLABORATEURS } from "@constants/collaborateursConstants";

const optionsTrierFwgtp2 = [
    {id: 1, label: "Par nom", keyAsc: "nomAsc", keyDesc: "nomDesc", icon: <Person fontSize="small" />},
    {id: 2, label: "Par matricule", keyAsc: "matriculeAsc", keyDesc: "matriculeDesc", icon: <Person fontSize="small" />},
    {id: 3, label: "Par badge", keyAsc: "badgeAsc", keyDesc: "badgeDesc", icon: <Badge fontSize="small" />},
    {id: 4, label: "Par société", keyAsc: "societeAsc", keyDesc: "societeDesc", icon: <Factory fontSize="small" />},
    {id: 5, label: "Par établissement", keyAsc: "etablissementAsc", keyDesc: "etablissementDesc", icon: <Business fontSize="small" />},
    {id: 6, label: "Par direction", keyAsc: "directionAsc", keyDesc: "directionDesc", icon: <BusinessCenter fontSize="small" />},
    {id: 7, label: "Par service", keyAsc: "serviceAsc", keyDesc: "serviceDesc", icon: <Share fontSize="small" />},
    {id: 8, label: "Par secteur", keyAsc: "secteurAsc", keyDesc: "secteurDesc", icon: <CorporateFare fontSize="small" />},
    {id: 9, label: "Par type", keyAsc: "typePersonnelAsc", keyDesc: "typePersonnelDesc", icon: <CorporateFare fontSize="small" />},
    {id: 10, label: "Par poste 1", keyAsc: "poste1Asc", keyDesc: "poste1Desc", icon: <LooksOne fontSize="small" />},
    {id: 11, label: "Par poste 2", keyAsc: "poste2Asc", keyDesc: "poste2Desc", icon: <LooksTwo fontSize="small" />},
    {id: 12, label: "Par poste 3", keyAsc: "poste3Asc", keyDesc: "poste3Desc", icon: <Looks3 fontSize="small" />},
    {id: 13, label: "Par poste 4", keyAsc: "poste4Asc", keyDesc: "poste4Desc", icon: <Looks4 fontSize="small" />},
    {id: 14, label: "Par contrôle horaire", keyAsc: "controleHoraireAsc", keyDesc: "controleHoraireDesc", icon: <WorkHistory fontSize="small" />},
    {id: 15, label: "Par présence", keyAsc: "presenceAsc", keyDesc: "presenceDesc", icon: <WhereToVote fontSize="small" />},
    {id: 16, label: "Par métier", keyAsc: "metierAsc", keyDesc: "metierDesc", icon: <Work fontSize="small" />},
    {id: 17, label: "Par horaire", keyAsc: "horaireAsc", keyDesc: "horaireDesc", icon: <QueryBuilder fontSize="small" />},
    {id: 18, label: "Par équipe", keyAsc: "equipeAsc", keyDesc: "equipeDesc", icon: <Groups fontSize="small" />},
    {id: 19, label: "Par activite", keyAsc: "activiteAsc", keyDesc: "activiteDesc", icon: <LocalActivity fontSize="small" />},
    {id: 20, label: "Par catégorie professionnelle", keyAsc: "categorieProfessionnelleAsc", keyDesc: "categorieProfessionnelleDesc", icon: <Category fontSize="small" />},
];

const CollaborateursFwgtp2Trie = (props) => {
    const { stateType, anchorEl, setAnchorEl } = props;
    const stateCollaborateurs = useStateCollaborateurs(stateType);
    const dispatch = useDispatch();

    const trier = useCallback((keyTrie) => {
        const data = [...stateCollaborateurs.collaborateurs];
        const dataTried = trierFwgtp2(keyTrie, data);
        if(stateType === TYPES_COLLABORATEURS.AFFECTATIONS_HORAIRES_COLLECTIVES) {    
            dispatch(colAffHorColActions.trier(dataTried));
        }
        if(stateType === TYPES_COLLABORATEURS.AFFECTATIONS_ACTIVITES_COLLECTIVES) {
            dispatch(colAffActColActions.trier(dataTried));
        }
    }, [dispatch, stateCollaborateurs.collaborateurs, stateType]);

    return <MenuTrieContainer anchorEl={anchorEl} setAnchorEl={setAnchorEl} options={optionsTrierFwgtp2} trier={trier} />;
}

CollaborateursFwgtp2Trie.defaultProps = {
    anchorEl: null,
    setAnchorEl: () => {}
}

CollaborateursFwgtp2Trie.propTypes = {
    stateType: PropTypes.string,
    anchorEl: PropTypes.node,
    setAnchorEl: PropTypes.func
}

export default CollaborateursFwgtp2Trie;