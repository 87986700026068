import { filter, tidy } from "@tidyjs/tidy";
import numeral from "numeral";

export const filtrerComGesTem = (data, dataBrut) => {
    let dataFiltered = [...dataBrut];
    switch(data.colonne) {
        case "valeur":
            switch(data.operateur) {
                case "egale":
                    return tidy(dataFiltered, filter(compteur => numeral(compteur.valeur.chiffre).value() === Number(data.number)));
                case "superieur":
                    return tidy(dataFiltered, filter(compteur => numeral(compteur.valeur.chiffre).value() > Number(data.number)));
                case "inferieur":
                    return tidy(dataFiltered, filter(compteur => numeral(compteur.valeur.chiffre).value() < Number(data.number)));
                default: 
                    return dataFiltered
            }
        case "maximum":
            switch(data.operateur) {
                case "egale":
                    return tidy(dataFiltered, filter(compteur => numeral(compteur.maximum.chiffre).value() === Number(data.number)));
                case "superieur":
                    return tidy(dataFiltered, filter(compteur => numeral(compteur.maximum.chiffre).value() > Number(data.number)));
                case "inferieur":
                    return tidy(dataFiltered, filter(compteur => numeral(compteur.maximum.chiffre).value() < Number(data.number)));
                default: 
                    return dataFiltered
            }
        case "libelle":
            return  tidy(dataFiltered, filter(compteur => compteur.libelle === data.txt.libelle));
        default:
            return dataFiltered
    }
};
