import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import { DialogContainer, HeaderDialogContainer, HeaderListContainer } from "@components/Containers";
import { SocietesFwsociList } from "../Lists";

const headerItems = [
    {id: 1, label: "Code", xs: 2, display: true, firstData: true, lastData: false},
    {id: 2, label: "Raison Sociale", xs: 9, display: true, firstData: false, lastData: true}
];

const SocietesDialog = ({
    open = false, 
    societes = [],
    setOpen = () => {}, 
    selectSociete = () => {}
}) => {
    return (
        <DialogContainer open={open} placement="top" taille="sm">
            <HeaderDialogContainer 
                title="Choisir une société"
                close={() => setOpen(false)}
            />
            <Stack spacing={0.5}>
                <HeaderListContainer itemIdReference="HSOCIAFF" headerItems={headerItems} />
                <SocietesFwsociList data={societes} selectSociete={selectSociete} />
            </Stack>
        </DialogContainer>
    );
}

SocietesDialog.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    selectSociete: PropTypes.func,
    societes: PropTypes.array
}

export default SocietesDialog;