import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { Event, Notes, Person, QueryBuilder, Rule } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import moment from "moment";
import { selectDrgGesTem } from "@selectors";
import * as drgGesTemActions from "@reducers/gestionTemps/regularisations/drgGesTemReducer"
import * as plaHorActions from "@reducers/plagesHoraires/plaHorReducer"
import { useDrgQryMan, useDrgRepGesTem } from "@hooks/gestionTemps/demandesRegularisations/hooksQueries";
import { MenuFiltreContainer, MenuTrieContainer } from "@components/Containers";
import { DemandesRegularisationActionsBar } from "@components/GestionTemps/DemandesRegularisation/ActionsBars";
import { DemandesRegularisationManagerList } from "@components/GestionTemps/DemandesRegularisation/Lists";
import { trierDrgGesTemMan } from "@components/GestionTemps/DemandesRegularisation/tries";
import { FiltreDemandesRegularisationForm } from "@components/GestionTemps/DemandesRegularisation/Forms";
import { AnomaliesReponseRegularisationsDialog, AnomaliesReponseTransactionsDialog, DemandeRegularisationDetailDialog, DemandeRegularisationMotifRefusDialog, DemandeRegularisationReponseDialog, DemandeRegularisationSuiviDialog } from "@components/GestionTemps/DemandesRegularisation/Dialogs";
import { AnomaliesDialog, AnomaliesRegularisationsDialog, AnomaliesTransactionsDialog } from "@components/App/Anomalies";
import { PlagesHorairesDialog } from "@components/App/PlagesHoraires";
import { ROLE_UTILISATEUR } from "@constants/userConstants";
import { greenColor } from "@styles";

const optionsTrie = [
    {id: 1, label: "Par date d'émission", keyAsc: "dateEmissionAsc", keyDesc: "dateEmissionDesc", icon: <Event fontSize="small" />},
    {id: 2, label: "Par heure d'émission", keyAsc: "heureEmissionAsc", keyDesc: "heureEmissionDesc", icon: <QueryBuilder fontSize="small" />},
    {id: 3, label: "Par date de régularisation", keyAsc: "dateRegularisationAsc", keyDesc: "dateRegularisationDesc", icon: <Event fontSize="small" />},
    {id: 4, label: "Par statut", keyAsc: "statutAsc", keyDesc: "statutDesc", icon: <Rule fontSize="small" />},
    {id: 5, label: "Par motif", keyAsc: "motifAsc", keyDesc: "motifDesc", icon: <Notes fontSize="small" />},
    {id: 6, label: "Par emetteur", keyAsc: "emetteurAsc", keyDesc: "emetteurDesc", icon: <Person fontSize="small" />},
];

const DemandesRegularisation = () => {
    const stateDemandesRegularisation = useSelector(selectDrgGesTem);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openFiltre, setOpenFiltre] = useState(false);
    const [openDetail, setOpenDetail] = useState(false);
    const [openReponse, setOpenReponse] = useState(false);
    const [openSuivi, setOpenSuivi] = useState(false);
    const [openAnomalies, setOpenAnomalies] = useState(false);
    const [openAnomalieRegularisation, setOpenAnomalieRegularisation] = useState(false);
    const [openAnomalieTransaction, setOpenAnomalieTransaction] = useState(false);
    const [regularisation, setRegularisation] = useState(null);
    const [transaction, setTransaction] = useState(null);
    const [openPlagesHoraires, setOpenPlagesHoraires] = useState(false);
    const [openMotifRefus, setOpenMotifRefus] = useState(false);
    const { isLoading: isLoadingList, isFetching: isFetchingList, isError: isErrorList, refetch: refetchList } = useDrgQryMan(ROLE_UTILISATEUR.MANAGER, true);
    const { isLoading: isLoadingReponse, postReponseActionner } = useDrgRepGesTem();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const openTrie = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const toggleList = useCallback(() => {
        dispatch(drgGesTemActions.lister());
    }, [dispatch]);

    const toggleAll = useCallback(() => {
        dispatch(drgGesTemActions.filtrerParReponse());
    }, [dispatch]);

    const trier = useCallback((keyTrie) => {
        const data = [...stateDemandesRegularisation.demandesRegularisation];
        const dataTried = trierDrgGesTemMan(keyTrie, data);
        dispatch(drgGesTemActions.trier(dataTried));
    }, [dispatch, stateDemandesRegularisation.demandesRegularisation]);

    const deleteTrie = useCallback(() => {
        dispatch(drgGesTemActions.deleteTrie(ROLE_UTILISATEUR.MANAGER));
    }, [dispatch]);

    const deleteFiltre = useCallback(() => {
        dispatch(drgGesTemActions.deleteFiltre(ROLE_UTILISATEUR.MANAGER));
    }, [dispatch]);

    const handleDetail = useCallback((regularisation) => {
        dispatch(drgGesTemActions.selectDemandeRegularisation(regularisation));
        setOpenDetail(true);
    }, [dispatch]);

    const handleReponse = useCallback((regularisation) => {
        dispatch(drgGesTemActions.selectDemandeRegularisation(regularisation));
        setOpenReponse(true);
    }, [dispatch]);

    const handleOpenAnomaliesRegularisation = useCallback((r) => {
        setRegularisation(r);
        setOpenAnomalieRegularisation(true);
    }, []);

    const handleOpenAnomaliesTransaction = useCallback((t) => {
        setTransaction(t);
        setOpenAnomalieTransaction(true);
    }, []);

    const handleOpenAnomalies = useCallback(() => {
        setOpenAnomalies(true);
    }, []);

    const accepterDemande = useCallback(async () => {
        const { codeRetour } = await postReponseActionner("A");
        if(!codeRetour) {
            enqueueSnackbar("Demande acceptée avec succès", { variant: "success",  anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            refetchList();
            setOpenReponse(false);
        } else {
            if(codeRetour === "AT") {
                setOpenAnomalieTransaction(true);
            }
            if(codeRetour === "AR") {
                setOpenAnomalieRegularisation(true);
            }
        }
    }, [enqueueSnackbar, postReponseActionner, refetchList]);

    const refuserDemande = useCallback(async (data) => {
        const { codeRetour } = await postReponseActionner("R", "1", data.motifRefus);
        if(!codeRetour) {
            enqueueSnackbar("Demande refusée.", { variant: "success",  anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            refetchList();
            setOpenReponse(false);
            setOpenMotifRefus(false);
        } 
    }, [enqueueSnackbar, postReponseActionner, refetchList]);

    const postReponseWithAnomaliesTransactions = useCallback(async (niveauValidation) => {
        const { codeRetour } = await postReponseActionner("A", niveauValidation);
        if(!codeRetour) {
            if(niveauValidation === "4") {
                enqueueSnackbar("Anomalies supprimées avec succès.", { variant: "success", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            }
            if(niveauValidation === "5") {
                enqueueSnackbar("Les anomalies n'ont pas été supprimées.", { variant: "success", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            }
            refetchList();
            setOpenReponse(false);
            setOpenAnomalieTransaction(false);
        }
    }, [enqueueSnackbar, postReponseActionner, refetchList]);

    const postReponseWithAnomaliesRegularisations = useCallback(async (niveauValidation) => {
        const { codeRetour } = await postReponseActionner("A", niveauValidation);
        if(!codeRetour) {
            if(niveauValidation === "2") {
                enqueueSnackbar("Anomalies validées avec succès.", { variant: "success", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            }
            refetchList();
            setOpenAnomalieRegularisation(false);
        } else if(codeRetour === "AT") {
            setOpenAnomalieRegularisation(false);
            setOpenAnomalieTransaction(true);
        }
    }, [enqueueSnackbar, postReponseActionner, refetchList]);

    const handlePlagesHoraires = useCallback(() => {
        const date = moment([stateDemandesRegularisation?.demandeRegularisationSelected?.anneeRattachement, stateDemandesRegularisation?.demandeRegularisationSelected?.moisRattachement - 1, stateDemandesRegularisation?.demandeRegularisationSelected?.jourRattachement]).format("DDMMYYYY");
        dispatch(plaHorActions.setDatePlagesHoraires(date));
        setOpenPlagesHoraires(true);
    }, [dispatch, stateDemandesRegularisation?.demandeRegularisationSelected?.anneeRattachement, stateDemandesRegularisation?.demandeRegularisationSelected?.jourRattachement, stateDemandesRegularisation?.demandeRegularisationSelected?.moisRattachement])

    return (
        <>
            <Stack spacing={1} sx={styles.stackStyle}>
                <DemandesRegularisationActionsBar 
                    userType={ROLE_UTILISATEUR.MANAGER}
                    isLoading={isLoadingList || isFetchingList} 
                    isError={isErrorList} 
                    listed={stateDemandesRegularisation.listed} 
                    tried={stateDemandesRegularisation.tried}
                    filtered={stateDemandesRegularisation.filtered}
                    all={stateDemandesRegularisation.all} 
                    toggleList={toggleList} 
                    toggleAll={toggleAll} 
                    openTrie={openTrie} 
                    setOpenFiltre={setOpenFiltre} 
                    deleteTrie={deleteTrie}
                    deleteFiltre={deleteFiltre} 
                    refetch={refetchList}
                />
                <DemandesRegularisationManagerList 
                    data={stateDemandesRegularisation.demandesRegularisationDisplayed}
                    isLoading={isLoadingList || isFetchingList}
                    isError={isErrorList}
                    listed={stateDemandesRegularisation.listed}
                    openDetail={handleDetail}
                    openReponse={handleReponse}
                />
                <MenuTrieContainer 
                    anchorEl={anchorEl} 
                    setAnchorEl={setAnchorEl} 
                    options={optionsTrie} 
                    trier={trier} 
                />
                <MenuFiltreContainer open={openFiltre} setOpen={setOpenFiltre}>
                    <FiltreDemandesRegularisationForm userType={ROLE_UTILISATEUR.MANAGER} setOpen={setOpenFiltre} />
                </MenuFiltreContainer>
            </Stack>
            <DemandeRegularisationDetailDialog
                userType={ROLE_UTILISATEUR.MANAGER} 
                transactions={stateDemandesRegularisation.transactions}
                regularisations={stateDemandesRegularisation.regularisations}
                open={openDetail} 
                setOpen={setOpenDetail} 
                openPlagesHoraires={handlePlagesHoraires} 
                setOpenSuivi={setOpenSuivi} 
                openAnomalieTransaction={handleOpenAnomaliesTransaction} 
                openAnomaliesRegularisation={handleOpenAnomaliesRegularisation} 
                anomaliesDisplay={true}
            />
            <DemandeRegularisationReponseDialog 
                open={openReponse} 
                isLoadingReponse={isLoadingReponse} 
                setOpen={setOpenReponse} 
                setOpenMotifRefus={setOpenMotifRefus} 
                openPlagesHoraires={handlePlagesHoraires} 
                setOpenSuivi={setOpenSuivi} 
                openAnomalieTransaction={handleOpenAnomaliesTransaction} 
                openAnomaliesRegularisation={handleOpenAnomaliesRegularisation} 
                accepterDemande={accepterDemande} 
            />
            <DemandeRegularisationMotifRefusDialog 
                open={openMotifRefus} 
                isLoading={isLoadingReponse} 
                setOpen={setOpenMotifRefus} 
                refuserDemande={refuserDemande} 
            />
            <AnomaliesReponseTransactionsDialog 
                open={openAnomalieTransaction} 
                isLoading={isLoadingReponse} 
                setOpen={setOpenAnomalieTransaction} 
                postReponseWithAnomalies={postReponseWithAnomaliesTransactions} 
            />
            <AnomaliesReponseRegularisationsDialog 
                open={openAnomalieRegularisation} 
                isLoading={isLoadingReponse} 
                setOpen={setOpenAnomalieRegularisation} 
                postReponseWithAnomaliesRegularisations={postReponseWithAnomaliesRegularisations} 
            /> 
            <AnomaliesDialog 
                open={openAnomalies} 
                setOpen={setOpenAnomalies} 
            />                
            <AnomaliesTransactionsDialog 
                open={openAnomalieTransaction} 
                setOpen={setOpenAnomalieTransaction} 
                transaction={transaction} 
                openAllAnomalies={handleOpenAnomalies} 
            />
            <AnomaliesRegularisationsDialog 
                open={openAnomalieRegularisation} 
                setOpen={setOpenAnomalieRegularisation} 
                regularisation={regularisation} 
                openAllAnomalies={handleOpenAnomalies} 
            />
            <PlagesHorairesDialog 
                open={openPlagesHoraires} 
                setOpen={setOpenPlagesHoraires} 
                matricule={stateDemandesRegularisation?.demandeRegularisationSelected?.emetteur} 
                prenomNom={stateDemandesRegularisation?.demandeRegularisationSelected?.prenomNom} 
            />
            <DemandeRegularisationSuiviDialog 
                userType={ROLE_UTILISATEUR.MANAGER} 
                suivi={stateDemandesRegularisation.suivi}
                open={openSuivi} 
                setOpen={setOpenSuivi} 
            />
        </>
    );
};

const styles = {
    boxTitleStyle: {
        backgroundColor: "rgba(0,0,0,0.1)", 
        textAlign: "center",
        p:1,
        borderRadius: 2,
    },
    titleStyle: {
        xs: "11px", 
        sm: "medium"
    },
    stackStyle: {
        bgcolor: greenColor["015"],
        borderRadius: 2,
        padding: 0.5,
        position: "relative",
        height: "calc(100vh - 227px)"
    },
    panelGridStyle: {
        maxHeight: "327px",
    },
    cardStyleNoBackground: {
        backdropFilter: "blur(3px)",
        backgroundColor: "rgba(49,133,156,0.1)",
        width: "100%",
        height: "100%",
        borderRadius: 2
    },
    cardStyle: {
        backdropFilter: "blur(3px)",
        backgroundColor: "rgba(255,255,255,0.9)",
        width: "100%",
        height: "100%",
        borderRadius: 2
    },
    headerListStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.5)",
        color: "white",
        borderRadius: 2,
        padding: 2
    },
    itemListStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.2)",
        borderRadius: 2,
        paddingX: 2,
        paddingY: 1
    },
};

export default DemandesRegularisation;