import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { DialogError } from "@components/Errors";
import { DialogFullScreenContainer } from "@components/Containers";
import { HistoriqueDemandesActions } from "./Actions";
import { HistoriqueDemandesContent } from "./Contents";
import { useDabGesAbsDet } from "@hooks/gestionAbsences/demandes/hooksQueries";
import { selectUsrApp } from "@selectors";

const HistoriqueDemandesDialogMobile = (props) => {    
    const { open, setOpen, demandes, resetSelection } = props;
    const [idDemande, setIdDemande] = useState(0);
    const stateUser = useSelector(selectUsrApp);
    const { data, isLoading, isFetching, isError, error } = useDabGesAbsDet(stateUser.matricule, demandes[idDemande]?.date, demandes[idDemande]?.heure, open);

    const close = useCallback(async () => {
        setOpen(false);
        resetSelection();
    }, [resetSelection, setOpen]);

    const precedent = useCallback(() => {
        if(idDemande === 0) {
            setIdDemande(demandes.length - 1);
        } else {
            setIdDemande(idDemande - 1);
        }

    }, [demandes.length, idDemande]);

    const suivant = useCallback(() => {
        if(idDemande === (demandes.length - 1)) {
            setIdDemande(0);
        } else {
            setIdDemande(idDemande + 1);
        }
    }, [demandes.length, idDemande]);

    return (
        <DialogFullScreenContainer open={open} taille="sm" placement="top">
            <HistoriqueDemandesActions 
                isLoading={isLoading || isFetching}
                demandes={demandes}
                idDemande={idDemande}
                close={close}
                precedent={precedent}
                suivant={suivant}
            />
            {isError ? 
                <DialogError error={error?.message} />
            :
                <HistoriqueDemandesContent 
                    isLoading={isLoading || isFetching}
                    data={data}
                    demandes={demandes}
                    idDemande={idDemande}
                />
            }
        </DialogFullScreenContainer>
    );
}

HistoriqueDemandesDialogMobile.propTypes = {
    open: false, 
    demandes: [], 
    setOpen: () => {}, 
    resetSelection: () => {}
}

HistoriqueDemandesDialogMobile.propTypes = {
    open: PropTypes.bool, 
    demandes: PropTypes.array, 
    setOpen: PropTypes.func, 
    resetSelection: PropTypes.func
}

export default HistoriqueDemandesDialogMobile;