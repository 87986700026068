import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { greenColor, whiteColor } from "@styles";

const variants = {
    green: { bg: greenColor["07"], color: whiteColor[9] }
}

const NoData = ({
    label = "Aucune donnée",
    variant = "green",
    buttons = <></>
}) => {
    return(
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center"> 
            <Stack direction="row" spacing={2} borderRadius={2} bgcolor={variants[variant]?.bg} py={1} px={2} alignItems="center"> 
                <Typography fontSize="small" fontWeight="bold" color={variants[variant]?.color}>
                    { label }
                </Typography>
                { buttons }
            </Stack>
        </Box>
    );
}

NoData.propTypes = {
    label: PropTypes.string,
    variant: PropTypes.string,
    buttons: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array])
}

export default NoData;