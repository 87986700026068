import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { greyColor } from "@styles";

const TextIconContainer = (props) => {
    const { children, icon, bgColor, padding, textAlign, textSize, textWeight } = props;

    return (  
        <Box bgcolor={bgColor} padding={padding} textAlign={textAlign} borderRadius={2} display="inline-flex">
            {icon}
            <Typography fontSize={textSize} fontWeight={textWeight} marginLeft={1}>
                {children}
            </Typography>
        </Box>
    );
}

TextIconContainer.defaultProps = {
    children: "",
    icon: <></>,
    bgColor: greyColor[0],
    padding: 2,
    textAlign: "center",
    textSize: "small", 
    textWeight: "normal"
}

TextIconContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    icon: PropTypes.element,
    bgColor: PropTypes.string,
    padding: PropTypes.number,
    textAlign: PropTypes.string,
    textSize: PropTypes.string,
    textWeight: PropTypes.string
}

export default TextIconContainer;