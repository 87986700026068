import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { HeaderDialogContainer } from "@components/Containers";

const EditActions = (props) => {
    const { isLoading, setOpen, editDelegation } = props;

    return (
        <HeaderDialogContainer  
            actionsLeft={
                <IconButtonBase icon={<Check fontSize="small" />} title="Valider" disabled={isLoading} action={() => editDelegation()}/>
            }
            title="Modification d'une délégation"
            close={() => setOpen(false)}
        />
    );
}

EditActions.defaultProps = {
    isLoading: true, 
    isError: false,
    setOpen: () => {}, 
    editDelegation: () => {},
}

EditActions.propTypes = {
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool,
    setOpen: PropTypes.func, 
    editDelegation: PropTypes.func,
}

export default EditActions;