import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { fetchColTmp, fetchColAffInd, fetchColAffCol, fetchColPla, fetchColCre, fetchColMvt } from "@api/collaborateurs";
import { selectCltApp, selectUsrApp } from "@selectors";
import * as colTemGesTemActions from "@reducers/collaborateurs/colTemGesTemReducer";
import * as colPlaGesTemActions from "@reducers/collaborateurs/colPlaGesTemReducer";
import * as colAffHorIndActions from "@reducers/collaborateurs/colAffHorIndReducer";
import * as colAffHorColActions from "@reducers/collaborateurs/colAffHorColReducer";
import * as colAffActIndActions from "@reducers/collaborateurs/colAffActIndReducer";
import * as colAffActColActions from "@reducers/collaborateurs/colAffActColReducer";
import * as colCreGesTemActions from "@reducers/collaborateurs/colCreGesTemReducer";
import * as colMvtGesTemActions from "@reducers/collaborateurs/colMvtGesTemReducer";
import { TYPES_COLLABORATEURS } from "@constants/collaborateursConstants";

export const useCollaborateursQuery1Dialog = (type, date, open, enable) => { 
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const droitSalarie = stateUser.droitSalarie ? "O" : "N";
    const droitInterimaire = stateUser.droitInterimaire ? "O" : "N";
    const dispatch = useDispatch();

    const query = useQuery(['colQuery1', date], ({ signal }) => fetchColTmp(stateUser.utilisateurAS400, stateUser.matricule, droitSalarie, droitInterimaire, date, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!date && enable && open
    });

    useEffect(() => {
        if(query.data) {
            if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_TEMPS) {
                dispatch(colTemGesTemActions.setCollaborateurs(query.data));
            }
        }
    }, [dispatch, query.data, type]);

    return query;
}

export const useCollaborateursQuery2Dialog = (type, date, open, enable) => { 
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const droitSalarie = stateUser.droitSalarie ? "O" : "N";
    const droitInterimaire = stateUser.droitInterimaire ? "O" : "N";
    const dispatch = useDispatch();

    const query = useQuery(['colQuery1', date], ({ signal }) => fetchColAffInd(stateUser.utilisateurAS400, stateUser.matricule, droitSalarie, droitInterimaire, date, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!date && enable && open
    });

    useEffect(() => {
        if(query.data) {
            if(type === TYPES_COLLABORATEURS.AFFECTATIONS_HORAIRES_INDIVIDUELLES) {
                dispatch(colAffHorIndActions.setCollaborateurs(query.data));
            }
            if(type === TYPES_COLLABORATEURS.AFFECTATIONS_ACTIVITES_INDIVIDUELLES) {
                dispatch(colAffActIndActions.setCollaborateurs(query.data));
            }
            
        }
    }, [dispatch, query.data, type]);

    return query;
}

export const useCollaborateursQuery3Dialog = (type, date, codeSociete, open, enable) => { 
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const droitSalarie = stateUser.droitSalarie ? "O" : "N";
    const droitInterimaire = stateUser.droitInterimaire ? "O" : "N";
    const dispatch = useDispatch();

    const query = useQuery(['colQuery2', date, codeSociete], ({ signal }) => fetchColAffCol(stateUser.utilisateurAS400, droitSalarie, droitInterimaire, codeSociete, stateUser.matricule, date, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!codeSociete && enable && open
    });

    useEffect(() => {
        if(query.data) {
            if(type === TYPES_COLLABORATEURS.AFFECTATIONS_HORAIRES_COLLECTIVES) {
                dispatch(colAffHorColActions.setCollaborateurs(query.data));
            }
            if(type === TYPES_COLLABORATEURS.AFFECTATIONS_ACTIVITES_COLLECTIVES) {
                dispatch(colAffActColActions.setCollaborateurs(query.data));
            }
        }
    }, [dispatch, query.data, type]);

    return query;
}

export const useCollaborateursQuery4Dialog = (type, date, open, enable) => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const numeroSemaine = date ? String(moment(date, "DDMMYYYY").week()).padStart(2, "0") : "";
    const anneeSemaine = date ? moment(date, "DDMMYYYY").year() : "";
    const droitSalarie = stateUser.droitSalarie ? "O" : "N"; 
    const droitInterimaire = stateUser.droitInterimaire ? "O" : "N";
    const dispatch = useDispatch();

    const query = useQuery(['colQuery3', date], ({ signal }) => fetchColPla(stateUser.utilisateurAS400, stateUser.matricule, numeroSemaine, anneeSemaine, droitSalarie, droitInterimaire, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!date && enable && open
    });

    useEffect(() => {
        if(query.data) {
            if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_PLANNING) {
                dispatch(colPlaGesTemActions.setCollaborateurs(query.data));
            }
        }
    }, [dispatch, query.data, type]);

    return query;
}


export const useCollaborateursQuery5Dialog = (type, date, open, enable) => { 
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const droitSalarie = stateUser.droitSalarie ? "O" : "N"; 
    const droitInterimaire = stateUser.droitInterimaire ? "O" : "N";
    const dispatch = useDispatch();

    const query = useQuery(['colQuery4', date], ({ signal }) => fetchColCre(stateUser.utilisateurAS400, stateUser.matricule, droitSalarie, droitInterimaire, date, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!date && enable && open
    });

    useEffect(() => {
        if(query.data) {
            if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_CREDIT) {
                dispatch(colCreGesTemActions.setCollaborateurs(query.data));
            }
        }
    }, [dispatch, query.data, type]);

    return query;
}

export const useCollaborateursQuery6Dialog = (type, dataForm, open, enable) => { 
    const dispatch = useDispatch();

    const query = useQuery(['colQuery6'], ({ signal }) => fetchColMvt(dataForm, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: enable && open
    });

    useEffect(() => {
        if(query.data) {
            if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_MOUVEMENT) {
                dispatch(colMvtGesTemActions.setCollaborateurs(query.data));
            }
        }
    }, [dispatch, query.data, type]);

    return query;
}

export const useQueriesCollaborateurs = (stateType, dateValidite, dataForm, codeSociete, open) => {
    const query1 = stateType === TYPES_COLLABORATEURS.GESTION_TEMPS_TEMPS;
    const query2 = stateType === TYPES_COLLABORATEURS.AFFECTATIONS_HORAIRES_INDIVIDUELLES || stateType === TYPES_COLLABORATEURS.AFFECTATIONS_ACTIVITES_INDIVIDUELLES;
    const query3 = stateType === TYPES_COLLABORATEURS.AFFECTATIONS_HORAIRES_COLLECTIVES || stateType === TYPES_COLLABORATEURS.AFFECTATIONS_ACTIVITES_COLLECTIVES;
    const query4 = stateType === TYPES_COLLABORATEURS.GESTION_TEMPS_PLANNING;
    const query5 = stateType === TYPES_COLLABORATEURS.GESTION_TEMPS_CREDIT;
    const query6 = stateType === TYPES_COLLABORATEURS.GESTION_TEMPS_MOUVEMENT;
    const query1Result = useCollaborateursQuery1Dialog(stateType, dateValidite, open, query1);
    const query2Result = useCollaborateursQuery2Dialog(stateType, dateValidite, open, query2);
    const query3Result = useCollaborateursQuery3Dialog(stateType, dateValidite, codeSociete, open, query3);
    const query4Result = useCollaborateursQuery4Dialog(stateType, dateValidite, open, query4);
    const query5Result = useCollaborateursQuery5Dialog(stateType, dateValidite, open, query5);
    const query6Result = useCollaborateursQuery6Dialog(stateType, dataForm, open, query6);
    
    if(query1) {
        return query1Result;
    }
    if(query2) {
        return query2Result;
    }
    if(query3) {
        return query3Result;
    }
    if(query4) {
        return query4Result;
    }
    if(query5) {
        return query5Result;
    }
    if(query6) {
        return query6Result;
    }

    return null;
}




