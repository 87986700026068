import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { schemaFiltreDemandesAbsence } from "../../validations";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { selectDabGesAbsMan } from "@selectors";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import * as dabGesAbsManActions from "@reducers/gestionAbsences/demandes/dabGesAbsManReducer"
import { Check, Close } from "@mui/icons-material";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";
import { DateHookFormInput, SelectHookFormInput } from "@components/Inputs";
import { filtrerDabGesAbsMan } from "../../filtres";
import { distinct, map, tidy } from "@tidyjs/tidy";

const colonneOptions = [
    { id: 1, value: "dateEmission", label: "Date d'émission" },
    { id: 2, value: "dateAbsence", label: "Date de l'absence" },
    { id: 3, value: "emetteur", label: "Emetteur" },
    { id: 4, value: "nature", label: "Nature" },
    { id: 5, value: "statut", label: "Statut" }
]

const operateurOptions = [
    { id: 1, value: "egale", label: "Egale" },
    { id: 2, value: "superieur", label: "Supérieure" },
    { id: 3, value: "inferieur", label: "Inférieure" }
]

const optionsNature = [
    { id: 1, value: "Demande d'absence", label: "Demande d'absence" },
    { id: 2, value: "Demande d'annulation d'absence", label: "Demande d'annulation d'absence" }
]

const optionsStatut = [
    { id: 1, value: "Acceptée", label: "Acceptée" },
    { id: 2, value: "Refusée", label: "Refusée" },
    { id: 3, value: "En cours", label: "En cours" },
    { id: 4, value: "Non traité", label: "Non traité" },
]

const defaultValues = {
    colonne: "",
    operateur: "egale",
    date: null,
    txt: ""
}

const FiltreDemandesAbsenceForm = (props) => {
    const { setOpen } = props;
    const [emetteurs, setEmetteurs] = useState([]);
    const stateDemandesAbsence = useSelector(selectDabGesAbsMan);
    const methods = useForm({ mode: "all", defaultValues: defaultValues, resolver: joiResolver(schemaFiltreDemandesAbsence) });
    const colonne = methods.watch("colonne");
    const dispatch = useDispatch();

    useEffect(() => {
        if(colonne === "dateEmission" || colonne === "dateAbsence") {
            methods.resetField("date", moment());
        }
        if(colonne === "nature" || colonne === "statut" || colonne === "emetteur") {
            methods.resetField("txt", "");
        }
    }, [colonne, methods]);
    
    useEffect(() => {
        const result = tidy(stateDemandesAbsence.demandesAbsenceDisplayed, distinct(["matricule", "prenomNom"]), map((demande, id) => ({id: id, value: demande.matricule, label: `${demande.prenomNom} (${demande.matricule})`})));
        setEmetteurs(result);
    }, [stateDemandesAbsence.demandesAbsenceDisplayed]);

    const filtrer = useCallback((data) => {
        setOpen(false);
        const demandesFiltered = filtrerDabGesAbsMan(data, stateDemandesAbsence.demandesAbsence);
        dispatch(dabGesAbsManActions.filtrer(demandesFiltered))
    }, [dispatch, setOpen, stateDemandesAbsence.demandesAbsence]); 

    return (
        <FormProvider {...methods}>
            <Stack spacing={1} width="100%">
                <SelectHookFormInput name="colonne" label="Colonnes" options={colonneOptions} />
                {{
                    dateEmission: 
                        <>
                            <SelectHookFormInput name="operateur" label="Opérateur" options={operateurOptions} />
                            <DateHookFormInput name="date" label="Date d'émission" />
                        </>,
                    dateAbsence: 
                        <>
                            <SelectHookFormInput name="operateur" label="Opérateur" options={operateurOptions} />
                            <DateHookFormInput name="date" label="Date de l'absence" />
                        </>,
                    statut: <SelectHookFormInput name="txt" label="Statut" options={optionsStatut} />,
                    nature: <SelectHookFormInput name="txt" label="Nature" options={optionsNature} />,
                    emetteur: <SelectHookFormInput name="txt" label="Emetteur" options={emetteurs} />
                }[colonne]}
            </Stack>
            <Box sx={styles.boxButtonsMenuFiltre}>
                <Stack direction="row" spacing={1}>
                    <Tooltip title="Valider">
                        <IconButton sx={styles.buttonStyleGreen} onClick={methods.handleSubmit(filtrer)}>
                            <Check fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Fermer">
                        <IconButton sx={styles.buttonStyleRed} onClick={() => setOpen(false)}>
                            <Close fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Box>  
        </FormProvider>
    );
}

const styles = {
    boxButtonsMenuFiltre: {
        justifyContent: "center",
        display: "flex"
    },
    buttonStyleGreen: {
        boxShadow: "none", 
        backgroundColor: greenColor["02"],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: greenColor["03"],
            color: whiteColor[9],
        }
    },
    buttonStyleRed: {
        boxShadow: "none", 
        backgroundColor: redColor[1],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: redColor[2],
            color: whiteColor[9],
        }
    }
}

export default FiltreDemandesAbsenceForm;