import { useSelector } from "react-redux";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import { useFieldArray, useFormContext } from "react-hook-form";
import moment from "moment";
import PropTypes from "prop-types";
import { selectRegGesTem } from "@selectors";
import { Loader } from "@components/Loaders";
import { IconButtonBase } from "@components/Buttons";
import { TextContainer } from "@components/Containers";
import { DateHookFormInput, SelectHookFormInput, TimeMaskedHookFormInput } from "@components/Inputs";
import { greenColor, greyColor, redColor, whiteColor, yellowColor } from "@styles";

const mouvementOptions = [
    { id: 1, value: "", label: "Aucun" },
    { id: 2, value: "E", label: "Entrée" },
    { id: 3, value: "S", label: "Sortie" },
    { id: 4, value: "A", label: "Activité" }
];

const RegularisationForm = (props) => {
    const { isLoading, code, detailError } = props;
    const stateRegularisation = useSelector(selectRegGesTem);
    const { control, watch } = useFormContext();
    const { fields, append, remove } = useFieldArray({ control, name: "regularisations" });
    
    const watchFieldArray = watch("regularisations");
    const controlledFields = fields.map((field, id) => {
        return {
          ...field,
          ...watchFieldArray[id]
        };
    });
    
    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle} p={1}>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box> 
        );
    }

    return (
        <>  
            {(stateRegularisation?.regularisations[0]?.dateRegularisation && stateRegularisation?.regularisations[0]?.regularisateur) && 
                <TextContainer bgColor={whiteColor[5]} txtWeight="bold" p={1}>
                    Régularisé par {stateRegularisation?.regularisations[0]?.regularisateur} le {moment(String(stateRegularisation?.regularisations[0]?.dateRegularisation).padStart(8, "0"), "DDMMYYYY").format("DD/MM/YYYY")}
                </TextContainer>
            }
            {(code && detailError) && 
                <Box sx={styles.boxInputErrorStyle}>
                    <Typography sx={styles.typoInputErrorStyle}>{detailError}</Typography>
                </Box>
            }
            <Stack spacing={0.75}>
                {stateRegularisation.autoriserTypesTravail ? 
                    controlledFields.map((field, id) => (
                        <Box key={`INPUTGPREG${field.id}`} bgcolor={whiteColor[9]} borderRadius={2} p={0} height={40}>
                            <Grid container spacing={0.25} alignItems="center">
                                <Grid item xs={2}>
                                    <SelectHookFormInput name={`regularisations.${id}.mouvement`} color="green" label="Mouvement" options={mouvementOptions} />
                                </Grid>
                                <Grid item xs={2.3}>
                                    <DateHookFormInput name={`regularisations.${id}.date`} label="Date" defaultMonth={moment(stateRegularisation?.dateRegularisation, "DDMMYYYY")} />
                                </Grid>
                                <Grid item xs={1.3}>
                                    <TimeMaskedHookFormInput name={`regularisations.${id}.heure`} label="Heure" />                                
                                </Grid>
                                <Grid item xs={2.7}>
                                    <SelectHookFormInput name={`regularisations.${id}.activite`} color="green" label="Activité" options={stateRegularisation.activites} />
                                </Grid>
                                <Grid item xs={2.7}>
                                    <SelectHookFormInput name={`regularisations.${id}.typeTravail`} color="green" label="Type de travail" options={stateRegularisation.typesTravail} />
                                </Grid>   
                                <Grid item xs={1}>
                                    <Box borderRadius={2} height={31.5} alignItems="center" justifyContent="center" display="flex">
                                        <IconButtonBase 
                                            icon={<Close fontSize="small" />} 
                                            title="Enlever une ligne" 
                                            colorIconButton={redColor[7]} 
                                            action={() => remove(id)} 
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>  
                    ))
                :
                    controlledFields.map((field, id) => (
                        <Box key={`INPUTGPREG${field.id}`} bgcolor={whiteColor[9]} borderRadius={2} p={0} height={40}>
                            <Grid container spacing={0.25} alignItems="center">
                                <Grid item xs={2}>
                                    <SelectHookFormInput name={`regularisations.${id}.mouvement`} color="green" label="Mouvement" options={mouvementOptions} />
                                </Grid>
                                <Grid item xs={2.3}>
                                    <DateHookFormInput name={`regularisations.${id}.date`} label="Date" defaultMonth={moment(stateRegularisation?.dateRegularisation, "DDMMYYYY")} />
                                </Grid>
                                <Grid item xs={2}>
                                    <TimeMaskedHookFormInput name={`regularisations.${id}.heure`} label="Heure" />                                
                                </Grid>
                                <Grid item xs={4.7}>
                                    <SelectHookFormInput name={`regularisations.${id}.activite`} color="green" label="Activité" options={stateRegularisation.activites} />
                                </Grid>  
                                <Grid item xs={1}>
                                    <Box borderRadius={2} height={31.5} alignItems="center" justifyContent="center" display="flex">
                                        <IconButtonBase 
                                            icon={<Close fontSize="small" />} 
                                            title="Enlever une ligne" 
                                            colorIconButton={redColor[7]} 
                                            action={() => remove(id)} 
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>  
                    )) 
                }
            </Stack>
            <Box borderRadius={2} height={31.5} alignItems="center" justifyContent="center" display="flex">
                <IconButtonBase 
                    bgColor={whiteColor[5]} 
                    icon={<Add fontSize="small" />} 
                    title="Ajouter une ligne" 
                    colorIconButton={greenColor["1"]} 
                    action={() => {append({mouvement: "", date: null, heure: null, activite: "", typeTravail: ""})}} 
                />
            </Box>
        </>
    );
}

RegularisationForm.defaultProps = {
    isLoading: false, 
    code: "",
    detailError: ""
}

RegularisationForm.propTypes = {
    isLoading: PropTypes.bool,
    code: PropTypes.string, 
    detailError: PropTypes.string
}

const styles = {
    boxAnomaliesStyle: {
        padding: 1,
        borderRadius: 2,
        backgroundColor: yellowColor[1]
    },
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
    },
    boxInputErrorStyle2: {
        textAlign: "left",
        borderBottomLeftRadius: 8, 
        borderBottomRightRadius: 8,
        padding: 1
    },
    typoInputErrorStyle2: {
        fontSize: "small", 
        color: greyColor[5]
    },
    boxInputErrorStyle: {
        padding: 1,
        borderRadius: 2,
        backgroundColor: redColor[1]
    },
    typoInputErrorStyle: {
        fontSize: "small",
        fontWeight: "bold"
    }
}

export default RegularisationForm;