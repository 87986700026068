import { useSelector } from "react-redux";
import { selectColPlaGesTem } from "@selectors";
import moment from "moment";
import PropTypes from "prop-types";
import { TypesTravailPlanningInput } from "@components/Inputs/TypesTravail";

const PlanningTypeTravailInput = (props) => {
    const { idPlage, idJour } = props;
    const stateCollaborateursPlanning = useSelector(selectColPlaGesTem);

    return (
        <TypesTravailPlanningInput name={`tt${idPlage}${idJour + 1}`} size="xsmall" label="Type Travail" date={moment(stateCollaborateursPlanning.dateValidite, "DDMMYYYY").weekday(idJour).format("YYYYMMDD")} matricule={stateCollaborateursPlanning?.collaborateurSelected?.matricule} />
    );
}

PlanningTypeTravailInput.propTypes = {
    idPlage: PropTypes.number.isRequired,
    idJour: PropTypes.number.isRequired
}

export default PlanningTypeTravailInput;