import { Box, Stack } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { Loader } from "@components/Loaders";
import { DialogContainer, HeaderDialogContainer, TextContainer } from "@components/Containers";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";

const AnomaliesReponseRegularisationsDialog = (props) => {
    const { open, setOpen, isLoading, postReponseWithAnomaliesRegularisations } = props;
    
    return (
        <DialogContainer open={open} taille="sm">
            <HeaderDialogContainer  
                actionsLeft={
                    <Stack direction={"row"} spacing={0.5}>
                        <IconButtonBase icon={<Check fontSize="small" />} title="Oui" disabled={isLoading} action={() => postReponseWithAnomaliesRegularisations("2")} />
                        <IconButtonBase icon={<Close fontSize="small" />} title="Non" disabled={isLoading} action={() => setOpen(false)} />
                    </Stack>    
                }
                title="Demande de régularisation en anomalie"
                close={() => setOpen(false)}
            />
            {isLoading ? 
                <Box sx={styles.loaderStyle}>
                    <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                </Box> 
            :
                <Stack spacing={0.5} bgcolor={redColor[1]} borderRadius={2} p={0.5}>
                    <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold">Le demande de régularisation comporte des anomalies.</TextContainer>
                    <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold">Validez-vous ces anomalies ?</TextContainer>
                </Stack>
            }
        </DialogContainer>
    );
}

AnomaliesReponseRegularisationsDialog.defaultProps = {
    open: false, 
    isLoading: false, 
    setOpen: () => {}, 
    postReponseWithAnomaliesRegularisations: () => {}
}

AnomaliesReponseRegularisationsDialog.propTypes = {
    open: PropTypes.bool,
    isLoading: PropTypes.bool,  
    setOpen: PropTypes.func, 
    postReponseWithAnomaliesRegularisations: PropTypes.func
}

const styles = {
    itemInterneNonCheckBoxStyle: {
        backgroundColor: "rgba(255,0,0,0.3)",
        borderRadius: 2,
        paddingX: 1,
        paddingY: 1
    },
    headerListStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.5)",
        color: "white",
        borderRadius: 2,
        padding: 2
    },
    itemListStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.2)",
        borderRadius: 2,
        paddingX: 2,
        paddingY: 1
    },
    loaderStyle: {
        height: 100,
        alignItems: "center",
        display: "flex"
    },
}

export default AnomaliesReponseRegularisationsDialog;