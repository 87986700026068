import { Box, Skeleton, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { DownloadButton } from "@components/Buttons";
import { whiteColor } from "@styles";
import { ErrorBase } from "@components/Errors";

const PiecesJointesList = (props) => {
    const { data, isLoading, isError, errorMessage } = props;

    if(isLoading) {
        return (
            <Box>
                <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={"100%"} height={35} />
            </Box>
        );
    }

    if(isError) {
        return <ErrorBase>{errorMessage}</ErrorBase>;
    }

    return (
        data.piecesJointes > 0 ? 
            <Stack spacing={0.5}>
                {data.piecesJointes.map((pieceJointe) => (
                    <Box key={`PJHISTOSUIVI${pieceJointe.id}`} bgcolor={whiteColor[7]} paddingY={0.5} paddingX={1} borderRadius={2} display="inline-flex" alignItems="center">
                        <Box flexGrow={1}>
                            <Typography fontSize="small">{pieceJointe.fichier}</Typography>
                        </Box>
                        <DownloadButton chemin={pieceJointe.chemin} nom={pieceJointe.fichier} />
                    </Box>
                ))}
            </Stack>
        :
            <Box bgcolor={whiteColor[7]} borderRadius={2} p={1}>
                <Typography fontSize="small">Aucune pièce jointe</Typography>
            </Box>
    );
}

PiecesJointesList.defaultProps = {
    data: [],
    isLoading: true,
    isError: false,
    errorMessage: ""
}

PiecesJointesList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.string
}

export default PiecesJointesList;