import { useSelector } from "react-redux"; 
import { useQuery } from "@tanstack/react-query";
import { fetchSrv } from "@api/services";
import { selectCltApp, selectUsrApp } from "@selectors";

export const useSerInp = (codeSociete, type, open) => {
    const stateClient = useSelector(selectCltApp);
    const stateUser = useSelector(selectUsrApp);
    const body = { codeSociete: codeSociete, utilisateur: stateUser.utilisateurAS400, type: type };

    return useQuery(['serInp', codeSociete], ({ signal }) => fetchSrv(body, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open && !!codeSociete
    });
}
