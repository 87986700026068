import axios from "axios";
import { getLongueurPlageVariable, getNoPlage, getPositionPlageVariable, getTypePlage } from "@utils";
import { urls } from "@constants/appConstants";

export const fetchPlaHor = async (matricule, type, date, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetPlagesHoraires"
    };
    const body = {
        date: date,
        matricule: matricule,
        type: type,
    };
    const response = await axios.post(urls.getPlagesHoraires, body, { headers: headers, params: { client: client }, signal: signal });
    return {
        infos: {
            libelleFerie: response.data.plagesHoraire.libelleFerie,
            equipe: response.data.plagesHoraire.equipe,
            codeHoraire: response.data.plagesHoraire.codeHoraire,
            libelleHoraire: response.data.plagesHoraire.libelleHoraire,
            tempsContractuel: response.data.plagesHoraire.tempsContractuel,
            repas: response.data.plagesHoraire.repas,
        },
        matin: {
            longueur: getLongueurPlageVariable(response.data.plagesHoraire.plageDebutVariableMatin, response.data.plagesHoraire.plageRepriseVariableMatin),
            position: getPositionPlageVariable(response.data.plagesHoraire.plageDebutVariableMatin, response.data.plagesHoraire.plageRepriseVariableMatin),
            type: getTypePlage(response.data.plagesHoraire.plageDebutVariableMatin, response.data.plagesHoraire.plageRepriseVariableMatin),
            plages: {
                debutVariable: response.data.plagesHoraire.debutPlageVariableMatin, 
                debutFixe: response.data.plagesHoraire.debutPlageFixeMatin, 
                repriseFixe: response.data.plagesHoraire.reprisePlageFixeMatin, 
                repriseVariable: response.data.plagesHoraire.reprisePlageVariableMatin
            },
        },
        apresMidi: {
            longueur: getLongueurPlageVariable(response.data.plagesHoraire.plageDebutVariableApresMidi, response.data.plagesHoraire.plageRepriseVariableApresMidi),
            position: getPositionPlageVariable(response.data.plagesHoraire.plageDebutVariableApresMidi, response.data.plagesHoraire.plageRepriseVariableApresMidi),
            type: getTypePlage(response.data.plagesHoraire.plageDebutVariableApresMidi, response.data.plagesHoraire.plageRepriseVariableApresMidi),                            
            plages: {
                debutVariable: response.data.plagesHoraire.debutPlageVariableApresMidi, 
                debutFixe: response.data.plagesHoraire.debutPlageFixeApresMidi, 
                repriseFixe: response.data.plagesHoraire.reprisePlageFixeApresMidi, 
                repriseVariable: response.data.plagesHoraire.reprisePlageVariableApresMidi
            },
        },
        noPlage: getNoPlage(response.data.plagesHoraire)
    }
}