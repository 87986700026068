import { Box, Grid, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useStateTemGesTem } from "@hooks/gestionTemps/temps/hooksStates";
import { chiffreUSVersChiffreFR, heurifySigned } from "@utils";
import { HeaderListContainer, ListContainer, TextContainer } from "@components/Containers";
import { greenColor, whiteColor } from "@styles";

const headers = [
    {id: 1, label: "", xs: 1.5, display: false},
    {id: 2, label: "Lu", xs: 1.5, display: true},
    {id: 3, label: "Ma", xs: 1.5, display: true},
    {id: 4, label: "Me", xs: 1.5, display: true},
    {id: 5, label: "Je", xs: 1.5, display: true},
    {id: 6, label: "Ve", xs: 1.5, display: true},
    {id: 7, label: "Sa", xs: 1.5, display: true},
    {id: 8, label: "Di", xs: 1.5, display: true},
];

const DebitCreditsList = (props) => {
    const { userType } = props;
    const stateTemps = useStateTemGesTem(userType);

    return (
        <Stack spacing={0.5}>
            <HeaderListContainer itemIdReference="DEBCREGT" headerItems={headers} />
            <ListContainer spacing={0.5} length={2}>
                <Box bgcolor={greenColor["015"]} borderRadius={2} p={0.25}> 
                    <Grid container spacing={0.5}>
                        <Grid item xs={1.5}>
                            <TextContainer bgColor={greenColor["015"]} p={0.75} verticalAlign="center" textSize={11} textWeight="bold" height={"100%"}>Jour</TextContainer>
                        </Grid>
                        {stateTemps.debitCredits.map((debitCredit) => (
                            <Grid key={`DEBCRETMPJH${debitCredit.id}`} item xs={1.5}>
                                <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" horizontalAlign="center" textSize={11} textWeight="bold" height={"100%"}>{stateTemps.horaire ? heurifySigned(debitCredit.jour.heure) : chiffreUSVersChiffreFR(debitCredit.jour.chiffre)}</TextContainer>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Box bgcolor={greenColor["015"]} borderRadius={2} p={0.25}>
                    <Grid container spacing={0.5}>
                        <Grid item xs={1.5}>
                            <TextContainer bgColor={greenColor["015"]} p={0.75} verticalAlign="center" textSize={11} textWeight="bold" height={"100%"}>Cumul</TextContainer>
                        </Grid>
                        {stateTemps.debitCredits.map((debitCredit) => (
                            <Grid key={`DEBCRETMPJH${debitCredit.id}`} item xs={1.5} textAlign="center">
                                <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" horizontalAlign="center" textSize={11} textWeight="bold" height="100%">{stateTemps.horaire ? heurifySigned(debitCredit.cumul.heure) : chiffreUSVersChiffreFR(debitCredit.cumul.chiffre)}</TextContainer>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </ListContainer>
        </Stack>
    );
}

DebitCreditsList.propTypes = {
    userType: PropTypes.string.isRequired
}

export default DebitCreditsList;