import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { whiteColor } from "@styles";

const bgColor = {
    normale: whiteColor[7]
}

const ItemContainer = ({
    data = "",
    xs = 0, 
    variant = "normale",
    justifyContent = "left",
    fontSize = "small",
    firstData = false,
    lastData = false
}) => {
    return (  
        <Grid item xs={xs}>
            <Box 
                bgcolor={bgColor[variant]} 
                sx={{
                    borderTopLeftRadius: firstData ? 8 : 0, 
                    borderBottomLeftRadius: firstData ? 8 : 0, 
                    borderTopRightRadius: lastData ? 8 : 0, 
                    borderBottomRightRadius: lastData ? 8 : 0
                }} 
                p={1}
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent={justifyContent}
                boxSizing="border-box"
            >
                <Typography fontSize={fontSize}>{data}</Typography>
            </Box>
        </Grid>
    );
}

ItemContainer.propTypes = {
    data: PropTypes.string,
    xs: PropTypes.number,
    variant: PropTypes.string,
    justifyContent: PropTypes.string,
    fontSize: PropTypes.string,
    firstData: PropTypes.bool,
    lastData: PropTypes.bool
}

export default ItemContainer;