import { ChevronLeft, ChevronRight, CompareArrows, Event, Percent, QueryBuilder, Refresh, Restaurant, WorkHistory } from "@mui/icons-material";
import PropTypes from "prop-types";
import { IconButtonBase, WeekPickerButton } from "@components/Buttons";
import { HeaderPanelContainer } from "@components/Containers";
import { whiteColor } from "@styles";
import moment from "moment";
import { Skeleton, Stack } from "@mui/material";

const TempsActionsBar = (props) => {
    const { 
        isLoading, 
        isError,
        title, 
        date,
        debitCreditPermanentLibelle, 
        debitCreditHebdomadaireLibelle, 
        horaire,
        precedent,
        suivant,
        selectDate,
        refetch,
        toggleHoraire,
        setOpenHistoriqueTransactions,
        setOpenDebitCredits,
        setOpenRepas 
    } = props;

    if(isLoading) {
        return (
            <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={"100%"} height={46} />
        )
    }

    return (
        <HeaderPanelContainer
            actionsLeft={
                !isError &&
                    <Stack direction={"row"} spacing={0.5}>
                        <IconButtonBase icon={<ChevronLeft fontSize="small" />} title="Précédent" action={precedent} />
                        <IconButtonBase icon={<ChevronRight fontSize="small" />} title="Suivant" action={suivant} />
                        <WeekPickerButton icon={<Event fontSize="small" />} title="Choisir une semaine" timeout={600} date={date ? moment(date, "DDMMYYYY") : null} action={selectDate} />
                    </Stack>
            }
            actionsRight={
                <Stack direction={"row"} spacing={0.5} justifyContent={"right"}>
                    {!isError &&
                        <>
                            <IconButtonBase icon={<CompareArrows fontSize="small" />} title="Historique des transactions" action={() => setOpenHistoriqueTransactions(true)} />
                            {(debitCreditPermanentLibelle || debitCreditHebdomadaireLibelle) && (
                                <IconButtonBase icon={<WorkHistory fontSize="small" />} title="Débit/crédit" action={() => setOpenDebitCredits(true)} />
                            )}
                            <IconButtonBase icon={<Restaurant fontSize="small" />} title="Repas" action={() => setOpenRepas(true)} />
                            <IconButtonBase icon={horaire ? <Percent fontSize="small" /> : <QueryBuilder fontSize="small" />} title={horaire ? "Afficher en centième d'heure" : "Afficher en heure"} action={toggleHoraire} />
                        </>
                    }
                    <IconButtonBase icon={<Refresh fontSize="small" />} title="Rafraichir" action={refetch} />
                </Stack>
            }   
            title={title}
            bgColor={whiteColor[5]}
        /> 
    );
}

TempsActionsBar.dafaultProps = {
    isLoading: false,
    isError: false,
    title: "",
    date: "",
    debitCreditPermanentLibelle: "",
    debitCreditHebdomadaireLibelle: "",
    horaire: false
}

TempsActionsBar.propTypes = {
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    title: PropTypes.string,
    date: PropTypes.string,
    debitCreditPermanentLibelle: PropTypes.string,
    debitCreditHebdomadaireLibelle: PropTypes.string,
    horaire: PropTypes.bool,
    precedent: PropTypes.func.isRequired,
    suivant: PropTypes.func.isRequired,
    selectDate: PropTypes.func.isRequired,
    refetch: PropTypes.func.isRequired,
    toggleHoraire: PropTypes.func.isRequired,
    setOpenHistoriqueTransactions: PropTypes.func.isRequired,
    setOpenDebitCredits: PropTypes.func.isRequired,
    setOpenRepas: PropTypes.func.isRequired
}

export default TempsActionsBar;