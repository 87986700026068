import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { TextContainer } from "@components/Containers";
import PhotoCollaborateur from "@components/App/PhotoCollaborateur";
import { ButtonBase } from "@components/Buttons";
import { greenColor, greyColor, whiteColor } from "@styles";
import { useCollaborateur } from "@hooks/planning/hooksUtils";
import { useTranslation } from "react-i18next";

const detailsJours = {
    A: {libelle: "Absence en attente", type: "dynamic"},
    V: {libelle: "Absence validée", type: "dynamic"},
    B: {libelle: "Absence en attente", type: "static"},
    C: {libelle: "Absence validée", type: "static"}
}

const variants = {
    planning: { bg1: whiteColor[5], bg2: whiteColor[5], bg3: whiteColor[5], bg4: greenColor["02"], color: greyColor[9] },
    absenceManager: { bg1: whiteColor[7], bg2: whiteColor[7], bg3: whiteColor[7], bg4: greenColor["07"], color: whiteColor[9] }
}

const Collaborateurs = ({ 
    type = "", 
    emetteur= true, 
    hierarchie = false, 
    variant = "planning",
    openJour = () => {} 
}) => {
    const societes = useCollaborateur(type, emetteur, hierarchie);
    const { t } = useTranslation("global");

    return ( 
        hierarchie ? 
            <Box>
                {societes.length > 0 ?
                    <Stack spacing={0.5}>
                        {societes.map((collaborateur) => (
                            <Stack spacing={0.3} key={`SC${collaborateur.id}${collaborateur.matricule}`}>
                                <Box>
                                    <Grid container spacing={0.3}>
                                        <Grid item xs={2.25}>
                                            <Tooltip title={<PhotoCollaborateur photo={collaborateur.photo} nom={collaborateur.prenomNom} />} placement="top">
                                                <Box bgcolor={variants[variant]?.bg1} p={1} height="100%" sx={{ borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }} boxSizing="border-box" display="flex" alignItems="center" justifyContent="left">
                                                    <Typography fontSize="small" fontWeight="bold" sx={{ textDecoration: "none" }}>{collaborateur.prenomNom}</Typography>
                                                </Box>
                                            </Tooltip>
                                        </Grid>
                                        {collaborateur.zones.map((zone) => (
                                            <Grid item xs={1.25} key={`SCZS${zone.id}${collaborateur.matricule}`}>
                                                {!zone.absenceEnAttente ? 
                                                    <Box bgcolor={variants[variant]?.bg2} p={1} height="100%" boxSizing="border-box" display="flex" alignItems="center" justifyContent="center">
                                                        <Typography fontSize="small">{zone.zone}</Typography>
                                                    </Box>
                                                :
                                                    <Tooltip title={detailsJours[zone.absenceEnAttente]?.libelle} placement="top">
                                                        <Box component="span" width="100%" height="100%" display="flex">
                                                            <ButtonBase 
                                                                fullWidth
                                                                variant={zone.absenceEnAttente === "A" ? "yellow5Grey9Yellow7" : "white5Grey5White9"} 
                                                                disabled={detailsJours[zone.absenceEnAttente].type !== "dynamic"}
                                                                height={35.5}
                                                                borders="none"
                                                                action={() => {openJour(zone.id, collaborateur.matricule, collaborateur.prenomNom)}}
                                                            >
                                                                {zone.zone}
                                                            </ButtonBase>
                                                        </Box>
                                                    </Tooltip>
                                                }
                                            </Grid>
                                        ))}
                                        <Grid item xs={1}>
                                            <Box bgcolor={variants[variant]?.bg3} p={1} height="100%" sx={{ borderBottomRightRadius: 8, borderTopRightRadius: 8 }} boxSizing="border-box" display="flex" alignItems="center" justifyContent="center">
                                                <Typography fontSize="small" fontWeight="bold">{collaborateur.totalHeuresSemaine}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Stack>    
                        ))}
                    </Stack>
                : 
                    <TextContainer bgColor={whiteColor[5]} horizontalAlign="center">{t("no_data.no_hierarchique")}</TextContainer>
                }
            </Box>        
        :
            <Box>
                {societes.length > 0 && 
                    <Stack spacing={0.5}>
                        {societes.map((societe) => (
                            societe.services.map((service) => (
                                <Stack key={`C${societe.societe}${service.service}`} spacing={0.3}> 
                                    <Box bgcolor={variants[variant]?.bg4} borderRadius={2} p={1} height="100%" boxSizing="border-box" display="flex" alignItems="center" justifyContent="left">
                                        <Typography fontSize="small" fontWeight="bold" color={variants[variant]?.color}>{societe.societe} {service.service} {service.libelleService}</Typography>
                                    </Box>
                                    {service.collaborateurs.map((collaborateur) => (
                                        <Stack spacing={0.3} key={`SC${collaborateur.id}${collaborateur.matricule}`}>
                                            <Box>
                                                <Grid container spacing={0.3}>
                                                    <Grid item xs={2.25}>
                                                        <Tooltip title={<PhotoCollaborateur photo={collaborateur.photo} nom={collaborateur.prenomNom} />} placement="top">
                                                            <Box bgcolor={variants[variant]?.bg1} p={1} height="100%" sx={{ borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }} boxSizing="border-box" display="flex" alignItems="center" justifyContent="left">
                                                                <Typography fontSize="small" fontWeight="bold" sx={{ textDecoration: "none" }}>{collaborateur.prenomNom}</Typography>
                                                            </Box>
                                                        </Tooltip>
                                                    </Grid>
                                                    {collaborateur.zones.map((zone) => (
                                                        <Grid item xs={1.25} key={`SCZS${zone.id}${collaborateur.matricule}`}>
                                                            {!zone.absenceEnAttente ? 
                                                                <Box bgcolor={variants[variant]?.bg2} p={1} height="100%" boxSizing="border-box" display="flex" alignItems="center" justifyContent="center">
                                                                    <Typography fontSize="small">{zone.zone}</Typography>
                                                                </Box>
                                                            :
                                                                <Tooltip title={"detailsJours[zone.absenceEnAttente]?.libelle"} placement="top">
                                                                    <Box component="span" width="100%" height="100%" display="flex">
                                                                        <ButtonBase 
                                                                            fullWidth
                                                                            variant={zone.absenceEnAttente === "A" ? "yellow5Grey9Yellow7" : "white5Grey5White9"} 
                                                                            disabled={detailsJours[zone.absenceEnAttente].type !== "dynamic"}
                                                                            height={35.5}
                                                                            borders="none"
                                                                            action={() => {openJour(zone.id, collaborateur.matricule, collaborateur.prenomNom)}} 
                                                                        >
                                                                            {zone.zone}
                                                                        </ButtonBase>
                                                                    </Box>
                                                                </Tooltip>
                                                            }
                                                        </Grid>
                                                    ))}
                                                    <Grid item xs={1}>
                                                        <Box bgcolor={variants[variant]?.bg3} p={1} height="100%" sx={{ borderBottomRightRadius: 8, borderTopRightRadius: 8 }} boxSizing="border-box" display="flex" alignItems="center" justifyContent="center">
                                                            <Typography fontSize="small" fontWeight="bold">{collaborateur.totalHeuresSemaine}</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Stack>
                                    ))}
                                </Stack>
                            ))    
                        ))}
                    </Stack>
                }
            </Box>
    );
}

Collaborateurs.propTypes = {
    type: PropTypes.string, 
    emetteur: PropTypes.bool,
    hierarchie: PropTypes.bool, 
    variant: PropTypes.string, 
    openJour: PropTypes.func
}

export default Collaborateurs;