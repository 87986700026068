import { ChevronLeft, ChevronRight, CompareArrows, Event, Info, Percent, QueryBuilder, Refresh, Restaurant, WorkHistory } from "@mui/icons-material";
import PropTypes from "prop-types";
import { IconButtonBase, MenuButton, WeekPickerButtonMobile } from "@components/Buttons";
import { HeaderPanelContainer } from "@components/Containers";
import { whiteColor } from "@styles";
import moment from "moment";
import { Skeleton, Stack } from "@mui/material";

const TempsActionsBar = (props) => {
    const { 
        isLoading, 
        isError,
        date,
        debitCreditPermanentLibelle, 
        debitCreditHebdomadaireLibelle, 
        horaire,
        precedent,
        suivant,
        selectDate,
        refetch,
        toggleHoraire,
        setOpenHistoriqueTransactions,
        setOpenDebitCredits,
        setOpenRepas,
        setOpenInformationsDialog
    } = props;

    if(isLoading) {
        return (
            <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={"100%"} height={46} />
        )
    }

    return (
        <HeaderPanelContainer
            actionsLeft={
                !isError &&
                    <Stack direction={"row"} spacing={0.5}>
                        <IconButtonBase icon={<ChevronLeft fontSize="small" />} title="Précédent" action={precedent} />
                        <IconButtonBase icon={<ChevronRight fontSize="small" />} title="Suivant" action={suivant} />
                        <WeekPickerButtonMobile icon={<Event fontSize="small" />} title="Choisir une semaine" timeout={600} date={date ? moment(date, "DDMMYYYY") : null} action={selectDate} />
                    </Stack>
            }
            actionsRight={
                <Stack direction={"row"} spacing={0.5} justifyContent={"right"}>
                    {!isError && 
                        <>
                            {(debitCreditPermanentLibelle || debitCreditHebdomadaireLibelle) ?
                                <MenuButton txtId="BTDABSMOB" options={[
                                    {id: 1, icon: <Info fontSize="small" />, label: "Informations", action: () => setOpenInformationsDialog(true)},
                                    {id: 2, icon: <CompareArrows fontSize="small" />, label: "Historique des transactions", action: () => setOpenHistoriqueTransactions(true)},
                                    {id: 3, icon: <WorkHistory fontSize="small" />, label: "Débit/crédit", action: () => setOpenDebitCredits(true)},
                                    {id: 4, icon: <Restaurant fontSize="small" />, label: "Repas", action: () => setOpenRepas(true)},
                                    {id: 5, icon: horaire ? <Percent fontSize="small" /> : <QueryBuilder fontSize="small" />, label: horaire ? "Afficher en centième d'heure" : "Afficher en heure", action: toggleHoraire},
                                ]} />
                            :
                                <MenuButton txtId="BTDABSMOB" options={[
                                    {id: 1, icon: <Info fontSize="small" />, label: "Informations", action: () => setOpenInformationsDialog(true)},
                                    {id: 2, icon: <CompareArrows fontSize="small" />, label: "Historique des transactions", action: () => setOpenHistoriqueTransactions(true)},
                                    {id: 3, icon: <Restaurant fontSize="small" />, label: "Repas", action: () => setOpenRepas(true)},
                                    {id: 4, icon: horaire ? <Percent fontSize="small" /> : <QueryBuilder fontSize="small" />, label: horaire ? "Afficher en centième d'heure" : "Afficher en heure", action: toggleHoraire},
                                ]} />
                            }
                        </>
                    }
                    <IconButtonBase icon={<Refresh fontSize="small" />} title="Rafraichir" action={refetch} />
                </Stack>
            }   
            bgColor={whiteColor[5]}
        /> 
    );
}

TempsActionsBar.dafaultProps = {
    isLoading: false,
    isError: false,
    date: "",
    debitCreditPermanentLibelle: "",
    debitCreditHebdomadaireLibelle: "",
    horaire: false
}

TempsActionsBar.propTypes = {
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    date: PropTypes.string,
    debitCreditPermanentLibelle: PropTypes.string,
    debitCreditHebdomadaireLibelle: PropTypes.string,
    horaire: PropTypes.bool,
    precedent: PropTypes.func.isRequired,
    suivant: PropTypes.func.isRequired,
    selectDate: PropTypes.func.isRequired,
    refetch: PropTypes.func.isRequired,
    toggleHoraire: PropTypes.func.isRequired,
    setOpenHistoriqueTransactions: PropTypes.func.isRequired,
    setOpenDebitCredits: PropTypes.func.isRequired,
    setOpenRepas: PropTypes.func.isRequired,
    setOpenInformationsDialog: PropTypes.func.isRequired
}

export default TempsActionsBar;