import { Box, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { HeaderMobile } from "./Header";
import { EmetteurMobile } from "./Emetteur";
import { CollaborateursMobile } from "./Collaborateurs";
import { TotauxMobile } from "./Totaux";
import { Loader } from "@components/Loaders";
import { ErrorBase } from "@components/Errors";
import { greenColor, greyColor } from "@styles";
import PerfectScrollbar from "react-perfect-scrollbar";

const PlanningContainerMobile = (props) => {
    const { 
        isLoading,
        isError,
        errorMessage,
        type, 
        emetteur,
        displayEmetteur, 
        hierarchie, 
        displayCollaborateur, 
        displayTotaux, 
        maxHeight, 
        bgHeader, 
        bgContent, 
        bgTotaux, 
        openJour 
    } = props;

    if(isLoading) {
        return (
            <Box justifyContent={"center"} display={"flex"} alignItems={"center"} height={100}>
                <Loader size={17} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return <ErrorBase>{errorMessage}</ErrorBase>;
    }
    
    return (
            <Stack spacing={0.5}>
                <Box>
                    <Stack spacing={0.5} bgcolor={bgHeader} sx={styles.stackEmetteurStyle}>
                        <HeaderMobile type={type} />
                        {displayEmetteur && 
                            <EmetteurMobile type={type} openJour={openJour} />
                        }
                    </Stack>
                </Box>
                <Box overflow="auto" height={"100%"} maxHeight={maxHeight}>
                    <PerfectScrollbar>
                        <Stack spacing={0.5} bgcolor={bgContent} sx={styles.stackCollaborateurStyle}>
                            {displayCollaborateur && 
                                <CollaborateursMobile 
                                    type={type} 
                                    emetteur={emetteur}
                                    hierarchie={hierarchie} 
                                    openJour={openJour} 
                                />
                            }
                        </Stack>
                    </PerfectScrollbar>
                </Box> 
                <Box>
                    {displayTotaux && 
                        <Stack spacing={0.5} bgcolor={bgTotaux} sx={styles.stackTotauxStyle}>
                            <TotauxMobile type={type} />         
                        </Stack>
                    }
                </Box>       
            </Stack>
    );
}

PlanningContainerMobile.defaultProps = {
    isLoading: true,
    isError: false,
    errorMessage: "",
    type: "", 
    emetteur: true,
    displayEmetteur: true, 
    hierarchie: false, 
    displayCollaborateur: true, 
    displayTotaux: true, 
    maxHeight: "", 
    bgHeader: greenColor["02"], 
    bgContent: greenColor["02"], 
    bgTotaux: greenColor["02"], 
    jourCourt: false, 
    openJour: () => {} 
}

PlanningContainerMobile.propTypes = {
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
    type: PropTypes.string, 
    emetteur: PropTypes.bool,
    displayEmetteur: PropTypes.bool, 
    hierarchie: PropTypes.bool, 
    displayCollaborateur: PropTypes.bool, 
    displayTotaux: PropTypes.bool, 
    maxHeight: PropTypes.string, 
    bgHeader: PropTypes.string, 
    bgContent: PropTypes.string, 
    bgTotaux: PropTypes.string, 
    jourCourt: PropTypes.bool, 
    openJour: PropTypes.func 
}


const styles = {
    boxJourStyle: {
        backgroundColor: "rgba(0,0,0,0.1)", 
        p:1,
        borderRadius: 2,
    },
    boxJourBackgroundBlackStyle: {
        backgroundColor: "rgba(255,255,255,0.5)", 
        p:1,
        borderRadius: 2,
    },
    boxJourFerieStyle: {
        backgroundColor: "rgba(0,128,255,0.3)", 
        p:1,
        borderRadius: 2,
    },
    boxJourFerieBackgroundBlackStyle: {
        backgroundColor: "rgba(0,128,255,0.7)", 
        p:1,
        borderRadius: 2,
    },
    stackEmetteurStyle: {
        position: "sticky",
        p: 0.5,
        borderRadius: 2,
    },
    stackCollaborateurStyle: {
        p: 0.5,
        borderRadius: 2,
    },
    stackTotauxStyle: {
        p: 0.5,
        borderRadius: 2,
    },
    boxNomEmetteurStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.3)", 
        p:1,
        borderRadius: 2,
    },
    boxJourEmetteurStyle: {
        backgroundColor: "rgba(0,0,0,0.1)", 
        p:1,
        borderRadius: 2,
    },
    boxJourEmetteurBackgroundLightStyle: {
        backgroundColor: "rgba(255,255,255,0.5)", 
        p:1,
        borderRadius: 2,
    },
}

export default PlanningContainerMobile;