import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { DialogContainer, HeaderDialogContainer } from "@components/Containers";
import { useCompetences } from "@hooks/gestionEntretiens/hooksUtils";
import { greenColor } from "@styles";

const CompetenceDialog = ({ 
    open = false, 
    setOpen = () => {} 
}) => {
    const competence = useCompetences();
    const definition = competence.definition1 + " " + competence.definition2 + " " + competence.definition3 + " " + competence.definition4 + " " + competence.definition5 + " " + competence.definition6

    return (
        <DialogContainer open={open} taille="sm" placement="top">
            <HeaderDialogContainer
                title="Informations d'une compétence"
                close={() => setOpen(false)}
            />
            <Stack spacing={0.5} height="100%" overflow="auto">
                <Box p={1} display="inline" bgcolor={greenColor["015"]} borderRadius={2} >
                    <Typography fontSize="small" display="inline" fontWeight="bold">Compétence : </Typography>
                    <Typography fontSize="small" display="inline">{competence.codeCompetence} {competence.designation}</Typography>
                </Box>
                {definition.trim() &&
                    <Box p={1} bgcolor={greenColor["015"]} borderRadius={2} >
                        <Typography fontSize="small" fontWeight="bold">Définition</Typography>
                        <Typography fontSize="small" fontWeight="bold">{definition}</Typography>
                    </Box>
                }
            </Stack>
        </DialogContainer>
    );
}

CompetenceDialog.propTypes = {
    open: PropTypes.bool, 
    setOpen: PropTypes.func
}

export default CompetenceDialog;