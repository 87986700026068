import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment';

const initialState = {
    datePlagesHoraires: moment().format("DDMMYYYY"),
    matin: null,
    apresMidi: null,
    infos: null,
    noPlages: false
}

const plaHorSlice = createSlice({
    name: 'plaHor',
    initialState: initialState,
    reducers: {
        setDatePlagesHoraires: (state, action) => {
            state.datePlagesHoraires = action.payload;
        },
        setPlagesHoraires: (state, action) => {
            state.matin = action.payload.matin;
            state.apresMidi = action.payload.apresMidi;
            state.infos = action.payload.infos;
            state.noPlages = action.payload.noPlage;
        } 
    },
})

const { actions, reducer } = plaHorSlice
export const { setDatePlagesHoraires, setPlagesHoraires } = actions
export default reducer