
import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Grid, Box, Stack } from "@mui/material";
import { AccountCircle, Email, Key, QuestionMark } from "@mui/icons-material";
import { whiteColor } from "@styles";
import { selectUsrApp } from "@selectors";
import { PasswordDialog, QuestionDialog } from "@components/App/Login/Dialogs";
import { ButtonValue, ParametresPanel, BackgroundValue, PoliceValue, TextValue, LangValue } from "@components/Parametres";
import { useTranslation } from "react-i18next";

const Parametres = () => {
    const stateUser = useSelector(selectUsrApp);
    const [openDialogPassword, setOpenDialogPassword] = useState(false);
    const [openDialogQuestion, setOpenDialogQuestion] = useState(false);
    const { t } = useTranslation("global");

    const handleOpenDialogPassword = useCallback(() => {
        setOpenDialogPassword(true);
    }, []);

    const handleOpenDialogQuestion = useCallback(() => {
        setOpenDialogQuestion(true);
    }, []);

    const routeValues = {
        firstName: <TextValue title={t("titles.firstname")} value={stateUser.prenom} icon={<AccountCircle />} />,
        lastName: <TextValue title={t("titles.lastname")} value={stateUser.nom} icon={<AccountCircle />} />,
        login: <TextValue title={t("titles.username")} value={stateUser.identifiant} icon={<AccountCircle />} />,
        mail: <TextValue title={t("titles.mail")} value={stateUser.email} icon={<Email />} />,
        password: <ButtonValue title={t("titles.password")} icon={<Key />} value={t("buttons.edit_password")} action={handleOpenDialogPassword} />,
        question: <ButtonValue title={t("titles.recovery_question_response")} icon={<QuestionMark />} value={t("buttons.edit_password_question")} action={handleOpenDialogQuestion} />,
        background: <BackgroundValue />,
        police: <PoliceValue />,
        lang: <LangValue />
    }

    return (
        <Stack spacing={1} bgcolor={whiteColor[9]} borderRadius={2} height="100%" boxSizing="border-box">
            <Box>
                <Grid container item spacing={1} padding={1}>
                    <ParametresPanel value={routeValues.firstName} timeout={200} />
                    <ParametresPanel value={routeValues.lastName} timeout={400} />
                    <ParametresPanel value={routeValues.login} timeout={600} />
                    {stateUser.email && 
                        <ParametresPanel value={routeValues.mail} timeout={800} />
                    }
                    <ParametresPanel value={routeValues.password} timeout={1000} />
                    <ParametresPanel value={routeValues.question} timeout={1200} />
                    <ParametresPanel value={routeValues.background} timeout={1400} />
                    <ParametresPanel value={routeValues.police} timeout={1600} />
                    <ParametresPanel value={routeValues.lang} timeout={1800} />
                </Grid>
            </Box>
            <PasswordDialog open={openDialogPassword} setOpen={setOpenDialogPassword} />
            <QuestionDialog open={openDialogQuestion} setOpen={setOpenDialogQuestion} />
        </Stack>
    )
}

export default Parametres;