import { Box, Grid, Tooltip, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { greenColor } from "@styles";
import { useTranslation } from "react-i18next";

const CalendrierHeaderTable = (props) => {
    const { jours } = props;
    const { t } = useTranslation("global");
    
    return (
        <Box p={0.3} borderRadius={2} bgcolor={greenColor["03"]}>
            <Grid container spacing={0.3}>
                <Grid item xs={1.5}>
                    <Tooltip title={t("calendar.week_string", {count: 7})} placement="top">
                        <Box p={0.75} bgcolor={greenColor["05"]} sx={{borderTopLeftRadius: 8, borderBottomLeftRadius: 8}} boxSizing="border-box" display="flex" alignItems="center" justifyContent="center">
                            <Typography fontSize="small" fontWeight="bold" color="white">{t("calendar.week_letter")}</Typography>
                        </Box>
                    </Tooltip>
                </Grid>
                {jours.map((jour) => (
                    <Grid item xs={1.5} key={`JCAL${jour.id}`}>
                        <Tooltip title={jour.libelle} placement="top">
                            <Box p={0.75} bgcolor={greenColor["05"]} sx={{borderTopRightRadius: jour.id === 7 ? 8 : 0, borderBottomRightRadius: jour.id === 7 ? 8 : 0}} boxSizing="border-box" display="flex" alignItems="center" justifyContent="center">
                                <Typography fontSize="small" fontWeight="bold" color="white">{jour.libelleSmall}</Typography>
                            </Box>
                        </Tooltip>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

CalendrierHeaderTable.defaultProps = {
    jours: []
}

CalendrierHeaderTable.propTypes = {
    jours: PropTypes.array
}

export default CalendrierHeaderTable;