import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { TextContainer } from "@components/Containers";
import { getGreyColor, greenColor, whiteColor, yellowColor } from "@styles";
import { useCollaborateur } from "@hooks/planning/hooksUtils";
import { useTranslation } from "react-i18next";

const detailsJours = {
    A: {libelle: "Absence en attente", type: "dynamic"},
    V: {libelle: "Absence validée", type: "dynamic"},
    B: {libelle: "Absence en attente", type: "static"},
    C: {libelle: "Absence validée", type: "static"}
}

const CollaborateursMobile = (props) => {
    const { type, emetteur, hierarchie, openJour } = props;
    const societes = useCollaborateur(type, emetteur, hierarchie);
    const { t } = useTranslation("global");

    return ( 
        hierarchie ? 
            <Box>
                {societes.length > 0 ?
                    <Stack spacing={0.5}>
                        {societes.map((collaborateur) => (
                            <Stack spacing={0.5} key={`SC${collaborateur.id}${collaborateur.matricule}`}>
                                <TextContainer bgColor={greenColor["015"]} p={1} tooltipPlacement="top" bxHeight={"100%"} txtVAlign="center" txtSize="small" txtWeight="bold">
                                    {collaborateur.prenomNom}
                                </TextContainer>
                                <Box>
                                    <Grid container spacing={0.5}>
                                        {collaborateur.zones.map((zone) => (
                                            <Grid item xs={1.5} key={`SCZS${zone.id}${collaborateur.matricule}`}>
                                                {!zone.absenceEnAttente ? 
                                                    <TextContainer bgColor={whiteColor[5]} p={1} height="100%" verticalAlign="center" horizontalAlign="center" textSize={11}>
                                                        {zone.zone}
                                                    </TextContainer>
                                                :
                                                    <Button 
                                                        size={"small"} 
                                                        sx={{ 
                                                            minWidth: 0,
                                                            width: "100%",
                                                            height: "100%",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center", 
                                                            textAlign: "center",
                                                            borderRadius: 2, 
                                                            boxShadow: "none", 
                                                            color: getGreyColor("0.59"), 
                                                            backgroundColor: zone.absenceEnAttente === "A" ? yellowColor[2] : greenColor["02"], 
                                                            "&:hover": { 
                                                                color: whiteColor[9],
                                                                backgroundColor: zone.absenceEnAttente === "A" ? yellowColor[3] : greenColor["03"] 
                                                            } 
                                                        }} 
                                                        disabled={detailsJours[zone.absenceEnAttente].type !== "dynamic"}
                                                        onClick={() => {openJour(zone.id, collaborateur.matricule, collaborateur.prenomNom)}}
                                                    >
                                                        <Typography sx={{fontSize: 11, flexGrow: 1, lineHeight: 1, textAlign: "center", textTransform: "none"}}>
                                                            {zone.zone}
                                                        </Typography>
                                                    </Button>    
                                                }
                                            </Grid>
                                        ))}
                                        <Grid item xs={1.5}>
                                            <TextContainer bgColor={whiteColor[5]} p={1} height="100%" verticalAlign="center" horizontalAlign="center" textSize={11} textWeight="bold">
                                                {collaborateur.totalHeuresSemaine}
                                            </TextContainer>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Stack>    
                        ))}
                    </Stack>
                : 
                    <TextContainer bgColor={whiteColor[5]} horizontalAlign="center">{t("no_data.no_hierarchique")}</TextContainer>
                }
            </Box>        
        :
            <Box>
                {societes.length > 0 && 
                    <Stack spacing={0.5}>
                        {societes.map((societe) => (
                            societe.services.map((service) => (
                                <Stack key={`C${societe.societe}${service.service}`} spacing={0.5}> 
                                    <TextContainer bgColor={greenColor["02"]} p={1} verticalAlign="center" textSize="small" textWeight="bold">
                                        {societe.societe} {service.service} {service.libelleService}
                                    </TextContainer>
                                    {service.collaborateurs.map((collaborateur) => (
                                        <Stack spacing={0.5} bgcolor={whiteColor[5]} p={0.5} borderRadius={2} key={`SC${collaborateur.id}${collaborateur.matricule}`}>
                                            <TextContainer bgColor={greenColor["015"]} p={1} tooltipPlacement="top" bxHeight={"100%"} txtVAlign="center" txtSize="small" txtWeight="bold">
                                                {collaborateur.prenomNom}
                                            </TextContainer>
                                            <Box>
                                                <Grid container spacing={0.5}>
                                                    {collaborateur.zones.map((zone) => (
                                                        <Grid item xs={1.5} key={`SCZS${zone.id}${collaborateur.matricule}`}>
                                                            {!zone.absenceEnAttente ? 
                                                                <TextContainer bgColor={whiteColor[5]} p={1} height="100%" verticalAlign="center" horizontalAlign="center" textSize={11}>
                                                                    {zone.zone}
                                                                </TextContainer>
                                                            :
                                                                <Button 
                                                                    size={"small"} 
                                                                    sx={{ 
                                                                        minWidth: 0,
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center", 
                                                                        textAlign: "center",
                                                                        borderRadius: 2, 
                                                                        boxShadow: "none", 
                                                                        color: getGreyColor("0.59"), 
                                                                        backgroundColor: zone.absenceEnAttente === "A" ? yellowColor[2] : greenColor["02"], 
                                                                        "&:hover": { 
                                                                            color: whiteColor[9],
                                                                            backgroundColor: zone.absenceEnAttente === "A" ? yellowColor[3] : greenColor["03"] 
                                                                        } 
                                                                    }} 
                                                                    disabled={detailsJours[zone.absenceEnAttente].type !== "dynamic"}
                                                                    onClick={() => {openJour(zone.id, collaborateur.matricule, collaborateur.prenomNom)}}
                                                                >
                                                                    <Typography sx={{fontSize: 11, flexGrow: 1, lineHeight: 1, textAlign: "center", textTransform: "none"}}>
                                                                        {zone.zone}
                                                                    </Typography>
                                                                </Button>    
                                                            }
                                                        </Grid>
                                                    ))}
                                                    <Grid item xs={1.5}>
                                                        <TextContainer bgColor={whiteColor[5]} p={1} height="100%" verticalAlign="center" horizontalAlign="center" textSize={11} textWeight="bold">
                                                            {collaborateur.totalHeuresSemaine}
                                                        </TextContainer>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Stack>
                                    ))}
                                </Stack>
                            ))    
                        ))}
                    </Stack>
                }
            </Box>
    );
}

CollaborateursMobile.defaultProps = {
    type: "", 
    emetteur: true,
    hierarchie: false, 
    openJour: () => {}
}

CollaborateursMobile.propTypes = {
    type: PropTypes.string, 
    emetteur: PropTypes.bool,
    hierarchie: PropTypes.bool, 
    openJour: PropTypes.func
}

export default CollaborateursMobile;