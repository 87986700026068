import { useEffect } from "react";
import { Box, Stack } from "@mui/material";
import { Check } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { joiResolver } from "@hookform/resolvers/joi";
import { IconButtonBase } from "@components/Buttons";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor } from "@styles";
import { DialogContainer, HeaderDialogContainer } from "@components/Containers";
import { AffectationIndividuelleHoraireForm } from "../Horaires/Forms";
import { schemaAffectationsActivitesIndividuelles, schemaAffectationsHorairesIndividuelles } from "../validations";
import { AffectationIndividuelleActiviteForm } from "../Activites/Forms";
import { TYPES_AFFECTATIONS } from "@constants/affectationsConstants";

const defaultValuesHoraires = {
    date: null,
    horaire: null,
    statut: "D"
}

const defaultValuesActivites = {
    date: null,
    activite: null
}

const EditAffectationDialog = (props) => {
    const { isLoading, typeAffectations, open, setOpen, editAffectation } = props;
    const schema = typeAffectations === TYPES_AFFECTATIONS.HORAIRES_INDIVIDUEL ? schemaAffectationsHorairesIndividuelles : schemaAffectationsActivitesIndividuelles;
    const methods = useForm({ mode: "all", defaultValues: typeAffectations === TYPES_AFFECTATIONS.HORAIRES_INDIVIDUEL ? defaultValuesHoraires : defaultValuesActivites, resolver: joiResolver(schema) });

    useEffect(() => {
        if(open) {
            const defaultValues = typeAffectations === TYPES_AFFECTATIONS.HORAIRES_INDIVIDUEL ? defaultValuesHoraires : defaultValuesActivites;
            methods.reset(defaultValues);
        }
    }, [methods, open, typeAffectations]);
    
    return (
        <DialogContainer open={open} taille="xs">
            <HeaderDialogContainer  
                actionsLeft={
                    <IconButtonBase icon={<Check fontSize="small" />} title="Valider" disabled={isLoading} action={() => methods.handleSubmit(editAffectation)()} />
                }
                title="Modification d'une affectation"
                close={() => setOpen(false)}
            />
            {isLoading ? 
                <Box sx={styles.loaderStyle}>
                    <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                </Box> 
            : 
                <Stack spacing={0.5} bgcolor={greenColor["015"]} borderRadius={2} p={0.5}>
                    <FormProvider {...methods}>
                        {typeAffectations === TYPES_AFFECTATIONS.HORAIRES_INDIVIDUEL ?
                            <AffectationIndividuelleHoraireForm mode="edition" open={open} />
                        :
                            <AffectationIndividuelleActiviteForm mode="edition" />
                        }
                    </FormProvider>
                </Stack>
            }
        </DialogContainer>
    );
}

EditAffectationDialog.defaultValues = {
    isLoading: false,
    typeAffectations: TYPES_AFFECTATIONS.HORAIRES_INDIVIDUEL,
    open: false, 
    setOpen: () => {},
    editAffectation: () => {}
}

EditAffectationDialog.propTypes = {
    isLoading: PropTypes.bool,
    typeAffectations: PropTypes.string, 
    open: PropTypes.bool, 
    setOpen: PropTypes.func, 
    editAffectation: PropTypes.func
}

const styles = {
    itemInterneNonCheckBoxStyle: {
        backgroundColor: "rgba(255,0,0,0.3)",
        borderRadius: 2,
        paddingX: 1,
        paddingY: 1
    },
    headerListStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.5)",
        color: "white",
        borderRadius: 2,
        padding: 2
    },
    itemListStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.2)",
        borderRadius: 2,
        paddingX: 2,
        paddingY: 1
    },
    loaderStyle: {
        height: 100,
        alignItems: "center",
        display: "flex"
    },
}

export default EditAffectationDialog;