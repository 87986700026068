import { Typography, Stack, Box } from "@mui/material";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import { greenColor, whiteColor } from "@styles";

const formatHeure = (date) => {
    const heures = Number(date.getHours()) >= 10 ? date.getHours() : `0${date.getHours()}`;
    const minutes = Number(date.getMinutes()) >= 10 ? date.getMinutes() : `0${date.getMinutes()}`;
    return heures + "h" + minutes;
};

const HorlogesWidgetConfigurationAdd = () => {
    return (
        <Stack spacing={0.5} padding={0.5} height="200px"  boxSizing="border-box" borderRadius={2} bgcolor={greenColor["015"]}>
            <Stack spacing={0.5} overflow="auto">  
                <Box bgcolor={whiteColor[5]} padding={1} borderRadius={2}>
                    <Stack direction="row" spacing={2}>
                        <Box>
                            <Clock value={new Date()} size={80} renderMinuteMarks={false} hourHandWidth={3} hourMarksWidth={1} secondHandLength={70} />
                        </Box>
                        <Stack spacing={1} display="flex" justifyContent="center">
                            <Box>
                                <Typography fontSize="normal" fontWeight="bold">{formatHeure(new Date())}</Typography>
                            </Box>
                            <Box>
                                <Typography fontSize="normal" fontWeight="bold">Paris</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                </Box>
                <Box bgcolor={whiteColor[5]} padding={1} borderRadius={2}>
                    <Stack direction="row" spacing={2}>
                        <Box>
                            <Clock value={new Date()} size={80} renderMinuteMarks={false} hourHandWidth={3} hourMarksWidth={1} secondHandLength={70} />
                        </Box>
                        <Stack spacing={1} display="flex" justifyContent="center">
                            <Box>
                                <Typography fontSize="normal" fontWeight="bold">{formatHeure(new Date())}</Typography>
                            </Box>
                            <Box>
                                <Typography fontSize="normal" fontWeight="bold">Los Angeles</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Stack>
    );
}

export default HorlogesWidgetConfigurationAdd;