import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack } from "@mui/material";
import { Add, Check, Loop } from "@mui/icons-material";
import { filter, innerJoin, tidy } from "@tidyjs/tidy";
import moment from "moment";
import { FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { selectColAffHorCol, selectSocAffHorCol } from "@selectors";
import * as colAffHorColActions from "@reducers/collaborateurs/colAffHorColReducer";
import * as socAffHorColActions from "@reducers/societes/socAffHorColReducer";
import { SocietesBar } from "@components/App/Societes/Bars";
import { useSocAffHorCol } from "@hooks/societes/hooksQueries";
import { SocietesDialog } from "@components/App/Societes/Dialogs";
import { CollaborateursBar } from "@components/App/Collaborateurs/Bars";
import { CollaborateursDialogMobile } from "@components/App/Collaborateurs/Dialogs";
import { AffectationsCollectivesHoraireFormMobile } from "./Forms";
import { useAddAffHorCol } from "@hooks/gestionTemps/affectations/hooksQueries";
import { HeaderPanelContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { CycleAffectationsCollectivesDialog } from "../Dialogs";
import { TYPES_COLLABORATEURS, TYPES_FICHIER } from "@constants/collaborateursConstants";
import { greenColor, whiteColor } from "@styles";
import { TYPES_SOCIETES } from "@constants/societesConstants";
import { joiResolver } from "@hookform/resolvers/joi";
import { schemaAffectationsHorairesCollectives } from "../validations";
import PropTypes from "prop-types";

const headersFwgtp2 = [
    {id: 1, label: "Nom", xs: 1.5, display: true},
    {id: 2, label: "Matricule", xs: 0.75, display: true},
    {id: 3, label: "Badge", xs: 0.75, display: true},
    {id: 4, label: "Société", xs: 1, display: true},
    {id: 5, label: "Etablissement", xs: 1, display: true},
    {id: 6, label: "Direction", xs: 1.5, display: true},
    {id: 7, label: "Service", xs: 1.5, display: true},
    {id: 8, label: "Secteur", xs: 1, display: true},
    {id: 9, label: "Type", xs: 1, display: true},
    {id: 10, label: "Contôle horaire", xs: 0.75, display: true},
    {id: 11, label: "Présent", xs: 0.75, display: true},
];

const defaultValues = {
    affectations: [{ dateEffet: null, horaire: null }]
}

const MobileForm = (props) => {
    const { isLoading, isLoadingHoraire, setOpenCycle, postAffectations } = props;
    const { control, handleSubmit } = useFormContext();
    const { fields, append, remove } = useFieldArray({ control, name: "affectations" });

    return (
        <Stack spacing={0.5}>
            <Stack spacing={0.5}>
                <AffectationsCollectivesHoraireFormMobile 
                    fields={fields}
                    isLoading={isLoadingHoraire} 
                    remove={remove}
                />
            </Stack>
            <Box>
                <HeaderPanelContainer 
                    actionsLeft={
                        <Stack direction={"row"} spacing={0.5}>
                            <IconButtonBase icon={<Check fontSize="small" />} noClick={isLoading} action={() => handleSubmit(postAffectations)()} />
                            <IconButtonBase icon={<Loop fontSize="small" />} noClick={isLoading} action={() => setOpenCycle(true)} />
                        </Stack>
                    }
                    actionsRight={
                        <Stack direction={"row"} spacing={0.5} justifyContent="right">
                            <IconButtonBase icon={<Add fontSize="small" />} noClick={isLoading} action={() => append({dateEffet: null, horaire: ""})} />
                        </Stack>
                    }
                    bgColor={whiteColor[5]}
                />
            </Box>
        </Stack>
    );
}

const AffectationsHorairesCollectif = () => {;
    const [openSocietesDialog, setOpenSocietesDialog] = useState(false);
    const [openCollaborateursDialog, setOpenCollaborateursDialog] = useState(false);
    const [openCycle, setOpenCycle] = useState(false);
    const [collaborateursChecked, setCollaborateursChecked] = useState([]);
    const [collaborateurs, setCollaborateurs] = useState([]);
    const [all, setAll] = useState(false);
    const stateColAffHorCol = useSelector(selectColAffHorCol); 
    const stateSocAffHorCol = useSelector(selectSocAffHorCol); 
    const { isLoading, isFetching, isError } = useSocAffHorCol();
    const { isLoadingHoraire, addAffHorColActionner, addCycAffHorColActionner } = useAddAffHorCol();
    const methods = useForm({ mode: "onChange", defaultValues: defaultValues, resolver: joiResolver(schemaAffectationsHorairesCollectives) });
    const dispatch = useDispatch();

    useEffect(() => {
        if(stateSocAffHorCol.societes.length > 1) {
            dispatch(socAffHorColActions.resetSelection());
            dispatch(colAffHorColActions.resetSelection());
        }
        if(stateSocAffHorCol.societes.length === 1) {
            dispatch(socAffHorColActions.selectSociete(stateSocAffHorCol.societes[0]));
            dispatch(colAffHorColActions.resetSelection());
        }
    }, [dispatch, stateSocAffHorCol.societes]);

    useEffect(() => {
        if(openCollaborateursDialog) {
            setCollaborateursChecked(stateColAffHorCol.collaborateursSelected);
            setAll(false);
        }
    }, [openCollaborateursDialog, stateColAffHorCol.collaborateursSelected]);

    useEffect(() => {
        let ids = [];
        let collaborateursSelected = [];
        ids = stateColAffHorCol.collaborateursSelected.map((value) => ({ id: value }));
        collaborateursSelected = tidy(stateColAffHorCol.collaborateurs, innerJoin(ids, { by: ['id'] }));
        setCollaborateurs(collaborateursSelected);
    }, [stateColAffHorCol.collaborateurs, stateColAffHorCol.collaborateursSelected]);

    const selectSociete = useCallback((societe) => {
        dispatch(socAffHorColActions.selectSociete(societe));
        dispatch(colAffHorColActions.resetSelection());
        setOpenSocietesDialog(false);
    }, [dispatch]);

    const selectCollaborateur = useCallback((e) => {
        let collaborateursSelected = [...collaborateursChecked]
        if(e.target.checked) {
            collaborateursSelected = [...collaborateursChecked, Number(e.target.value)];
        } else {
            collaborateursSelected.splice(collaborateursChecked.indexOf(Number(e.target.value)), 1);
        }
        setCollaborateursChecked(collaborateursSelected);
    }, [collaborateursChecked]);

    const selectAllCollaborateurs = useCallback(() => {
        if(all) {
            setCollaborateursChecked([]);
            setAll(false);
        } else {
            const idCollaborateurs = stateColAffHorCol.collaborateursDisplayed.map((collaborateur) => collaborateur.id);
            setCollaborateursChecked(idCollaborateurs);
            setAll(true);
        }
    }, [all, stateColAffHorCol.collaborateursDisplayed]);

    const selectCollaborateursChecked = useCallback(() => {
        dispatch(colAffHorColActions.selectCollaborateurs(collaborateursChecked));
        setOpenCollaborateursDialog(false);
    }, [collaborateursChecked, dispatch]);

    const postAffectations = useCallback(async (data) => {
        const { isSucceed } = await addAffHorColActionner(data);
        if(isSucceed) {
            methods.reset(defaultValues);
        }
    }, [methods, addAffHorColActionner]);
    
    const postCycleAffectations = useCallback(async (data) => {
        const { isSucceed, response } = await addCycAffHorColActionner(data);
        if(isSucceed) {
            const dataForm = response.map((field) => {
                const date = moment([field.anneeEffet, field.moisEffet - 1, field.jourEffet]);
                const horaire = tidy(field.options, filter((hor) => hor.codeHoraire === field.codeHoraire));
                return { dateEffet: date, horaire: horaire[0] };
            });
            methods.setValue("affectations", dataForm);
            setOpenCycle(false);
        }
    }, [methods, addCycAffHorColActionner]);
    

    return (
        <Stack spacing={0.5} bgcolor={greenColor["015"]} borderRadius={2} p={0.5} height="100%" boxSizing="border-box">
            {stateColAffHorCol?.collaborateursSelected?.length > 0 && 
                <FormProvider {...methods}>
                    <MobileForm 
                        isLoading={isLoading}
                        isLoadingHoraire={isLoadingHoraire}
                        setOpenCycle={setOpenCycle}
                        postAffectations={postAffectations}
                    />
                </FormProvider>
            } 
            <SocietesBar 
                typeSociete={TYPES_SOCIETES.AFFECTATIONS_COLLECTIVES_HORAIRES} 
                isLoading={isLoading || isFetching}
                isError={isError}
                setOpenSociete={setOpenSocietesDialog}
                societes={stateSocAffHorCol.societes}
                societe={stateSocAffHorCol.societeSelected}
            />
            {stateSocAffHorCol.societeSelected && 
                <CollaborateursBar 
                    buttonTitle="Selectionner un ou plusieurs collaborateurs"
                    collaborateurs={collaborateurs}
                    multiple={true} 
                    openDialog={() => setOpenCollaborateursDialog(true)}
                />
            }
            <SocietesDialog 
                open={openSocietesDialog}
                setOpen={setOpenSocietesDialog}
                societes={stateSocAffHorCol.societesDisplayed}
                selectSociete={selectSociete}
            />
            {stateSocAffHorCol.societeSelected && 
                <CollaborateursDialogMobile 
                    stateType={TYPES_COLLABORATEURS.AFFECTATIONS_HORAIRES_COLLECTIVES}
                    listType={TYPES_FICHIER.FWGTP2} 
                    open={openCollaborateursDialog}  
                    codeSociete={stateSocAffHorCol?.societeSelected?.codeSociete}
                    dateValidite={stateColAffHorCol?.dateValidite ? moment(stateColAffHorCol.dateValidite, "DDMMYYYY").format("DDMMYYYY") : moment()}
                    headers={headersFwgtp2}
                    tried={stateColAffHorCol.tried}
                    allChecked={all}
                    collaborateursChecked={collaborateursChecked}
                    setOpen={setOpenCollaborateursDialog}  
                    selectCollaborateursChecked={selectCollaborateursChecked}
                    selectCollaborateur={selectCollaborateur}
                    selectAllCollaborateurs={selectAllCollaborateurs}
                />
            }
            <CycleAffectationsCollectivesDialog 
                isLoading={isLoadingHoraire}
                open={openCycle} 
                setOpen={setOpenCycle} 
                postCycleAffectations={postCycleAffectations} 
            />
        </Stack>
    );
};

MobileForm.defaultProps = {
    isLoading: true, 
    isLoadingHoraire: false, 
    setOpenCycle: () => {}, 
    postAffectations: () => {}
}

MobileForm.propTypes = {
    isLoading: PropTypes.bool, 
    isLoadingHoraire: PropTypes.bool, 
    setOpenCycle: PropTypes.func, 
    postAffectations: PropTypes.func
}

export default AffectationsHorairesCollectif;