import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query"
import { fetchHisDab, fetchDetDab, deleteHisDab } from "@api/demandesAbsence";
import { selectCltApp, selectUsrApp } from "@selectors";
import * as hisDabGesAbsActions from "@reducers/gestionAbsences/historique/hisDabGesAbsReducer";
import { useEffect } from "react";

export const useHisDabGesAbs = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const dispatch = useDispatch();

    const query = useQuery(["hisDabGesAbs"], ({ signal }) => fetchHisDab(stateUser.matricule, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false        
    });

    useEffect(() => {
        if(query.data) {
            dispatch(hisDabGesAbsActions.setDemandesAbsence(query.data));
        }
    }, [dispatch, query.data]);

    return query;
}

export const useHisDabGesAbsDetMan = (demande) => {
    const stateClient = useSelector(selectCltApp);

    return useQuery(['hisDabGesAbsDetMan', demande], ({ signal }) => fetchDetDab(demande?.matricule, demande?.date, demande?.heure, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false
    });
}

export const useDelDabGesAbs = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const { isLoading, mutateAsync } = useMutation(deleteHisDab);

    const deleteDemandes = async (demandes) => {
        let isSucceed = false;
        const demandesToDelete = demandes.filter((demande) => demande.supressionPossible === "O");
        const data = {
            matricule: stateUser.matricule,
            demandes: demandesToDelete,
            taille: demandesToDelete.length,
            client: stateClient.client
        }
        try {
            const response = await mutateAsync(data);
            if(response.succeed) {
                isSucceed = true;
            }
        } catch(e) {

        }

        return { isSucceed }
    }

    return { isLoading, deleteDemandes };
}

