import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useNtfApp } from "@hooks/user/hooksQueries";
import { selectCltApp, selectUsrApp } from "@selectors";
import * as notAppActions from "@reducers/notifications/notAppReducer"
import { BackgroundsDialog } from "@components/App/Backgrounds/Dialogs";
import { PolicesDialog } from "@components/App/Polices/Dialogs";
import { useBeforeUnload, useMedia } from "react-use";
import { BottomBar, SideBar, TopBar } from "@components/App/Root/Bars";
import { AppTabs, AppTabsMobileContent } from "@components/App/Root/Tabs";
import { MenuMobile } from "@components/App/Root/Menu";
import { useTranslation } from "react-i18next";
import { BackgroundContainer } from "@styles";

const AppRoot = () => {
	const stateUser = useSelector(selectUsrApp);
	const stateClient = useSelector(selectCltApp);
    const queryNotification = useNtfApp();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const isSmall = useMedia('(max-width: 900px)');
    const isMiddle = useMedia('(min-width: 900px)');
    const isLarge = useMedia('(min-width: 1501px)');
    const { t } = useTranslation("global");
    useBeforeUnload(true, t("warnings.logout"));

    useEffect(() => {
        if(queryNotification?.data?.succeed) {
            if(queryNotification.data.demandeAbsence && queryNotification.data.demandeRegularisation) {
                enqueueSnackbar(t("infos.notification_multiple"), { persist: true, variant: "warningDemandesAbsence" });
                dispatch(notAppActions.setNotifications({notificationDemandesAbsence: true, notificationDemandesRegularisation: true}));
            } else {
                if(queryNotification.data.demandeAbsence) {
                    enqueueSnackbar(t("infos.notification_absences"), { persist: true, variant: "warningDemandesAbsence" });
                    dispatch(notAppActions.setNotificationDemandesAbsence(true));
                }
                if(queryNotification.data.demandeRegularisation) {
                    enqueueSnackbar(t("infos.notification_regularisations"), { persist: true, variant: "warningDemandesRegularisation" });
                    dispatch(notAppActions.setNotificationDemandesRegularisation(true));
                }
            }
        }
        
    }, [dispatch, enqueueSnackbar, queryNotification.data, t]);

    useEffect(() => {
        document.title = t("titles.app_portail");
    }, [t]);

    if(!stateUser.isAuth) {
        return <Navigate to={`/${stateClient.client}/connexion`} />
    }

    return (
        <>
            <BackgroundContainer background={stateUser.background}>
                <Stack width="100%" height="100%">
                    <TopBar />
                    <Stack 
                        width="100%" 
                        direction="row" 
                        boxSizing="border-box" 
                        spacing={1} 
                        overflow="auto" 
                        height={isLarge || isMiddle ? "calc(100vh - 64px)" : "calc(100vh - 128px)"} 
                        p={1}
                    >
                        {isLarge && <SideBar />}
                        <Box width={isLarge ? "calc(100% - 264px)" : "100%"} height={"100%"} boxSizing={"border-box"}>
                            {isMiddle ? <AppTabs /> : <AppTabsMobileContent />}
                        </Box>
                    </Stack> 
                    {isSmall && <BottomBar />}
                </Stack>
            </BackgroundContainer>
            <BackgroundsDialog />
            <PolicesDialog />
            {!isLarge && <MenuMobile />}
        </>
    );
}

export default AppRoot;