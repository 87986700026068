import { Box, Grid, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { greenColor, greyColor } from "@styles";

const ListContainer = ({ 
    children = <></>, 
    spacing = 1, 
    length = 0, 
    noDataBgColor = greenColor["05"], 
    noDataLabelColor = greyColor[9], 
    noDataLabel = "Aucune donnée"
}) => {
    return (  
        length > 0 ?
            <Box overflow="auto">
                <Stack spacing={spacing}>
                    {children}
                </Stack>
            </Box>
        :
            <Grid container height={"100%"}>
                <Box width="100%" display="flex" alignItems="center" justifyContent="center"> 
                    <Box bgcolor={noDataBgColor} py={1} px={2} borderRadius={5}>
                        <Typography fontSize="small" fontWeight="bold" color={noDataLabelColor}>{noDataLabel}</Typography>
                    </Box>
                </Box>
            </Grid>
    );
}

ListContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.array]),
    spacing: PropTypes.number,
    length: PropTypes.number,
    noDataBgColor: PropTypes.string,
    noDataLabelColor: PropTypes.string,
    noDataLabel: PropTypes.string
}

export default ListContainer;