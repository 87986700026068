import { Box, Grid, Grow, Stack } from "@mui/material";
import { Info, QuestionAnswer } from "@mui/icons-material";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import PropTypes from "prop-types";
import { HeaderListContainer, ListContainer, PanelListContainer, TextContainer, TextWithLabelContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, redColor, whiteColor, yellowColor } from "@styles";
import { ErrorBase } from "@components/Errors";

const headers = [
    {id: 1, label: "Emetteur", xs: 2, display: true},
    {id: 2, label: "Date d'émission", xs: 1, display: true},
    {id: 3, label: "Heure d'émission", xs: 1, display: true},
    {id: 4, label: "Date de la régularisation", xs: 1, display: true},
    {id: 5, label: "Statut", xs: 1, display: true},
    {id: 6, label: "Motif de la demande", xs: 5, display: true},
];

const statut = {
    E: {label: "En Cours", color: yellowColor[1]},
    A: {label: "Acceptée", color: greenColor["025"]}, 
    R: {label: "Refusée", color: redColor[1]}
}

const DemandesRegularisationManagerList = (props) => {
    const { data, isLoading, isError, listed, openDetail, openReponse } = props;    

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader size={20} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return <ErrorBase>Erreur lors de la récupération des demandes.</ErrorBase>
    }

    return (
        listed ? 
            <>
                <HeaderListContainer itemIdReference="DEREGPAN" headerItems={headers} />
                <PerfectScrollbar>
                    <ListContainer spacing={0.5} txtId="DEREGPAN" headerItems={headers} length={data.length}>
                        {data.map((regularisation, id) => (
                            <Grow key={`REGPAN${regularisation.id}`} in={true} timeout={400+(200*id)}>
                                <Box bgcolor={statut[regularisation.codeStatut] ? statut[regularisation.codeStatut].color : yellowColor[1]} borderRadius={2} p={0.25}>
                                    <Grid container spacing={0.5}>
                                        <Grid item xs={2}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height="100%">{regularisation.prenomNom}</TextContainer>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height="100%">{moment(regularisation.dateDemande, "YYYYMMDD").format("DD/MM/YYYY")}</TextContainer>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height="100%">{moment(regularisation.heureDemande, "Hmmss").format("HH:mm:ss")}</TextContainer>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height="100%">{moment([regularisation.anneeRattachement, regularisation.moisRattachement - 1, regularisation.jourRattachement]).format("DD/MM/YYYY")}</TextContainer>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height="100%">{regularisation.libelleStatut}</TextContainer>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height="100%">{regularisation.observation}</TextContainer>
                                        </Grid>
                                        <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
                                            {regularisation.reponse ? 
                                                <IconButtonBase icon={<Info fontSize="small" />} title="Détail" action={() => openDetail(regularisation)} />
                                            :
                                                <IconButtonBase icon={<QuestionAnswer fontSize="small" />} title="Répondre" action={() => openReponse(regularisation)} />
                                            }
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grow>
                        ))}
                    </ListContainer>
                </PerfectScrollbar>
            </>
        : 
            <PerfectScrollbar>
                <PanelListContainer length={data.length}>
                    {data.map((regularisation, id) => (
                        <Grow key={`LREGPAN${regularisation.id}`} in={true} timeout={400+(200*id)}>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                <Stack spacing={0.5} bgcolor={statut[regularisation.codeStatut] ? statut[regularisation.codeStatut].color : yellowColor[1]} padding={0.5} borderRadius={2}>
                                    <Box borderRadius={2} p={1} bgcolor={whiteColor[5]}>
                                        <Stack spacing={0}>
                                            <TextWithLabelContainer label="Emetteur" value={regularisation.prenomNom} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Date d'émission" value={moment(regularisation.dateDemande, "YYYYMMDD").format("DD/MM/YYYY")} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Heure d'émission" value={moment(regularisation.heureDemande, "Hmmss").format("HH:mm:ss")} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Date de la régularisation" value={moment([regularisation.anneeRattachement, regularisation.moisRattachement - 1, regularisation.jourRattachement]).format("DD/MM/YYYY")} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Statut" value={statut[regularisation.codeStatut] ? statut[regularisation.codeStatut].label : "Non traitée"} weight={{label: "bold", value: "normal"}} />
                                            {regularisation.observation ? 
                                                <TextWithLabelContainer label="Motif de la demande" value={regularisation.observation} weight={{label: "bold", value: "normal"}} />
                                            :
                                                <Box width="100%" height={19.5} />
                                            }
                                        </Stack>
                                    </Box>
                                    <Box borderRadius={2} height={35.5} display="flex" alignItems="center" justifyContent="center" bgcolor={whiteColor[5]}>
                                        {regularisation.reponse ?
                                            <IconButtonBase icon={<Info fontSize="small" />} title="Détail" action={() => openDetail(regularisation)} /> 
                                        :
                                            <IconButtonBase icon={<QuestionAnswer fontSize="small" />} title="Répondre" action={() => openReponse(regularisation)} />
                                        }
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grow>
                    ))}
                </PanelListContainer>
            </PerfectScrollbar>
    )
}

DemandesRegularisationManagerList.defaultProps = {
    data: [],
    isLoading: true,
    isError: false,
    listed: false,
    openDetail: () => {},
    openReponse: () => {}
}

DemandesRegularisationManagerList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    listed: PropTypes.bool,
    openDetail: PropTypes.func,
    openReponse: PropTypes.func
}

const styles = {
    loaderStyle: {
        height: "100%",
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
    }
}

export default DemandesRegularisationManagerList;