import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { Login, Logout, Loop } from "@mui/icons-material";
import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { selectUsrApp } from "@selectors";

const StatutActionsBar = (props) => {
    const { actionSuivante, handleOpenConfirmationPointage, setOpenActivitesDialog } = props;
    const stateUser = useSelector(selectUsrApp);

    return (
        <Stack spacing={1} direction="row" p={1} alignItems="center" justifyContent="right">
            {stateUser.autorisationEd && <IconButtonBase icon={actionSuivante === "D" ? <Logout fontSize="small" /> : <Login fontSize="small" />} title={actionSuivante === "D" ? "Départ" : "Entrée" } action={() => handleOpenConfirmationPointage("pointage")} />}
            {stateUser.autorisationAc && <IconButtonBase icon={<Loop fontSize="small" />} title="Changement d'activité" action={() => setOpenActivitesDialog(true)} />}
        </Stack>
    );
}

StatutActionsBar.defaultProps = {
    actionSuivante: "",
    handleOpenConfirmationPointage: () => {},
    setOpenActivitesDialog: () => {}
}

StatutActionsBar.propTypes = {
    actionSuivante: PropTypes.string,
    handleOpenConfirmationPointage: PropTypes.func,
    setOpenActivitesDialog: PropTypes.func
}

export default StatutActionsBar;