import axios from "axios";
import moment from "moment";
import { urls } from "@constants/appConstants";

export const fetchTra = async (matricule, typePersonnel, date, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetTransactions"
    };
    const momentDate = moment(date, "DDMMYYYY");
    const anneeRattachement = momentDate.format("YYYY");
    const moisRattachement = momentDate.format("MM");
    const jourRattachement = momentDate.format("DD");
    const body = {
        matricule: matricule,
        typePersonnel: typePersonnel,
        anneeRattachement: anneeRattachement,
        moisRattachement: moisRattachement,
        jourRattachement: jourRattachement
    };
    const response = await axios.post(urls.getTransactions, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.transactions;
}

export const fetchHisTra = async (matricule, typePersonnel, anneeRattachement, moisRattachement, jourRattachement, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetHistoriqueTransactions"
    };
    const body = {
        matricule: matricule,
        typePersonnel: typePersonnel,
        anneeRattachement: anneeRattachement,
        moisRattachement: moisRattachement,
        jourRattachement: jourRattachement
    };
    const response = await axios.post(urls.getHistoriqueTransactions, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.transactions;
}