import { Box, Tooltip } from '@mui/material'; 
import Tree from 'react-d3-tree';
import PropTypes from "prop-types";
import { TextContainer } from '@components/Containers';
import { greenColor, greyColor, whiteColor } from '@styles';
import { Loader } from '@components/Loaders';
import { useCenteredTree } from '@hooks/organigramme/hooksUtils';
import { ErrorBase } from '@components/Errors';

const CustomNodeContent = ({ nodeDatum, toggleNode, foreignObjectProps, handleOpenDialogDetails }) => {
    return (
        <foreignObject { ...foreignObjectProps }>
            <div style={{ backgroundColor: whiteColor[9], width: "100%"}}>
                <div style={{ backgroundColor: greenColor["05"], borderRadius: 8, height: "100%", padding: 8, display: "flex" }}>
                    <div style={{ display: "flex", width: "100%" }} onClick={nodeDatum.children && toggleNode}>
                        {nodeDatum.attributes.photoMatricule ?
                            <img style={{borderRadius: 8, width: "70px", height: "70px"}} alt={`${nodeDatum.attributes.prenom}`} src={`data:image/jpg;base64,${nodeDatum.attributes.photoMatricule}`} />
                        :
                            <div style={{ width: "70px", height: "70px", backgroundColor: whiteColor[5], borderRadius: 8, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {nodeDatum.attributes.matricule ? 
                                    <div style={{ width: "30px"}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/>
                                        </svg> 
                                    </div>
                                : 
                                    <div style={{ width: "35px"}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 648 512">
                                            <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L353.3 251.6C407.9 237 448 187.2 448 128C448 57.3 390.7 0 320 0C250.2 0 193.5 55.8 192 125.2L38.8 5.1zM264.3 304.3C170.5 309.4 96 387.2 96 482.3c0 16.4 13.3 29.7 29.7 29.7H514.3c3.9 0 7.6-.7 11-2.1l-261-205.6z"/>
                                        </svg>
                                    </div>
                                }
                            </div>
                        }
                        <div style={{ marginLeft: 8, marginRight: 8, width: "100%" }}> 
                            <div style={{ padding: 6, backgroundColor: whiteColor[5], borderRadius: 8, marginBottom: 8 }}>{nodeDatum.attributes.codePoste} {nodeDatum.attributes.libellePoste}</div>
                            <div style={{ padding: 6, backgroundColor: whiteColor[5], borderRadius: 8 }}>
                                {nodeDatum.attributes.matricule ? 
                                    `${nodeDatum.attributes.matricule} ${nodeDatum.attributes.qualite} ${nodeDatum.attributes.prenom} ${nodeDatum.attributes.nom}`
                                : 
                                    `Poste vacant`
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{flexGrow: 1, width: "70px", height: "70px", backgroundColor: whiteColor[5], borderRadius: 8, display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Tooltip title={"Détails"} placement={"bottom"}>
                            <span>
                                <button style={{ background: "none", border: "none", cursor: "pointer" }} onClick={() => handleOpenDialogDetails(nodeDatum.attributes.codePoste)}>
                                    <div style={{width: "35px", height: "35px"}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
                                        </svg>
                                    </div>
                                </button>
                            </span>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </foreignObject>
    );
};

const OrganigrammeGraph = (props) => {
    const { isLoading, isError, errorMessage, postes, handleOpenDialogDetails } = props;
    const [translate, containerRef] = useCenteredTree();
    const nodeSize = { x: 450, y: 150 };
    const nodeSize2 = { x: 500, y: 250 };
    const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: -200, y: -75 }

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}><Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} /></Box>
        )
    }

    if(isError) {
        return (<ErrorBase>{errorMessage}</ErrorBase>);
    }

    return (
        postes?.built?.length > 0 ?
            <Box ref={containerRef} width="calc(100vw - 339px)" height="calc(100vh - 215px)">
                <Tree 
                    data={postes?.built[0]} 
                    orientation="vertical" 
                    initialDepth={1} 
                    translate={translate} 
                    zoom={0.5} 
                    nodeSize={nodeSize2} 
                    renderCustomNodeElement={
                        (rd3tProps) => <CustomNodeContent {...rd3tProps} foreignObjectProps={foreignObjectProps} handleOpenDialogDetails={handleOpenDialogDetails} />
                    } 
                    pathFunc="step" 
                    enableLegacyTransitions 
                />
            </Box>
        :
            <TextContainer bgColor={greenColor["015"]} height="100%" horizontalAlign="center" verticalAlign="center">Aucun poste</TextContainer>
    )
}

OrganigrammeGraph.defaultProps = {
    isLoading: true,
    isError: false,
    errorMessage: "",
    postes: null, 
    handleOpenDialogDetails: () => {}
}

OrganigrammeGraph.propTypes = {
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
    postes: PropTypes.object, 
    handleOpenDialogDetails: PropTypes.func
}

const styles = {
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        height: "100%",
    },
};

export default OrganigrammeGraph;