import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { ArrowLeft, Check, Close } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import PropTypes from "prop-types";
import { selectEntMan, selectEntSecMan } from "@selectors";
import * as entSecManActions from "@reducers/gestionEntretiens/entSecManReducer";
import { useAddSousSection } from "@hooks/gestionEntretiens/hooksQueries";
import { useChampDate, useChampEvaluation, useMappingActions } from "@hooks/gestionEntretiens/hooksUtils";
import { IconButtonBase } from "@components/Buttons";
import { Loader } from "@components/Loaders";
import { ChampCommentaire, ChampDate, ChampDesignation, ChampEvaluation, ChampsComplementaires } from "./Champs";
import { greenColor, greyColor, whiteColor } from "@styles";
import { schemaAjoutSousSection } from "@components/GestionEntretiens/validations";
import moment from "moment";

const defaultValues = {
    designation1: "",
    designation2: "",
    complement1: "",
    complement2: "",
    complement3: "",
    complement4: "",
    evaluation: "",
    date: null,
    commentaire: "",
    displayEvaluation: false,
    displayDate: false
}

const AjoutSectionForm = ({ 
    goBack = () => {} 
}) => {
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const methods = useForm({ mode: "onSubmit", defaultValues: defaultValues, resolver: zodResolver(schemaAjoutSousSection) })
    const { designation1, designation2 } = useMappingActions()
    const { displayA: displayAE } = useChampEvaluation();    
    const { displayA: displayAD } = useChampDate(); 
    const { isLoadingAjout, addSousSection } = useAddSousSection(); 
    const dispatch = useDispatch();

    useEffect(() => {
        methods.reset();
        if(displayAE) {
            methods.setValue("displayEvaluation", true);
        } else {
            methods.setValue("displayEvaluation", false);
        }
        if(displayAD) {
            methods.setValue("displayDate", true);
            methods.setValue("date",  moment([stateEntretiens?.entretienSelected?.annee, stateEntretiens?.entretienSelected?.mois - 1, stateEntretiens?.entretienSelected?.jour]))
        } else {
            methods.setValue("displayDate", false);
        }
    }, [displayAD, displayAE, methods, stateEntretiens?.entretienSelected?.annee, stateEntretiens?.entretienSelected?.jour, stateEntretiens?.entretienSelected?.mois]);

    const validate = useCallback(async (dataForm) => {
        await addSousSection(dataForm);
        goBack();
    }, [addSousSection, goBack]);

    if(isLoadingAjout) {
        return (
            <>
                <Box borderRadius={2} bgcolor={greenColor["015"]} height={50} p={0.5}>
                    <Skeleton variant="rounded" animation="wave" width="100%" height="100%" sx={{borderRadius: 2}} />
                </Box>
                <Box justifyContent="center" display="flex" alignItems="center" height="calc(100vh - 140px)" p={2}>
                    <Box bgcolor={whiteColor[5]} borderRadius={2} width={50} height={50} justifyContent="center" display="flex" alignItems="center">
                        <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                    </Box>
                </Box>
            </>
        );
    }

    return (
        <>
            <Box borderRadius={2} bgcolor={greenColor["015"]} height={50} p={0.5}>
                <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[5]} height="100%" alignItems="center" px={1}>
                    <IconButtonBase icon={<ArrowLeft fontSize="small" />} title="Retour" action={() => goBack()} />
                    <Box flexGrow={1}>
                        <Typography fontSize="small" fontWeight="bold">{designation1}</Typography>
                        <Typography fontSize="small" fontWeight="bold">{designation2}</Typography>
                    </Box>
                    {(stateEntretiens?.entretienSelected?.statut === "P" && stateSections.modeAjout) && 
                        <IconButtonBase icon={<Close fontSize="small" />} title="Annuler" action={() => dispatch(entSecManActions.setModeAjout(false))} />
                    }
                    {stateEntretiens?.entretienSelected?.statut === "P" && 
                        <IconButtonBase icon={<Check fontSize="small" />} title="Valider" action={() => methods.handleSubmit(validate)()} />
                    }
                </Stack>
            </Box>
            <Stack spacing={0.5}>
                <FormProvider {...methods}>
                    <ChampDesignation />
                    <ChampEvaluation />
                    <ChampsComplementaires />
                    <ChampDate />
                    <ChampCommentaire />
                </FormProvider>
            </Stack>
        </>
    );
}

AjoutSectionForm.propTypes = {
    goBack: PropTypes.func
}

export default AjoutSectionForm;