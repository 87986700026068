import { Box, Stack, Typography } from "@mui/material";

const PlageRepriseVariable = ({ plagesHoraire }) => {
    return (
        <Stack direction="row">
            <Stack direction="row" width={"85%"}>
                <Box width="50%" justifyContent="left"  display="flex">
                    <Typography fontSize="11px">{plagesHoraire.debutFixe}</Typography>
                </Box>
                <Box width="50%" justifyContent="right"  display="flex">
                    <Typography fontSize="11px">{plagesHoraire.repriseFixe}</Typography>
                </Box>
            </Stack>
            <Box width={"15%"} justifyContent="right" display="flex">
                <Typography fontSize="11px">{plagesHoraire.repriseVariable}</Typography>
            </Box>
        </Stack>
    );
}

export default PlageRepriseVariable;