import axios from "axios";
import { urls } from "@constants/appConstants";

export const fetchDebCre = async (origine, matricule, type, date, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetDebitsCredits"
    };
    const body = {
        origine: origine,
        matricule: matricule,
        type: type,
        date: date
    };
    const response = await axios.post(urls.getDebitsCredits, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.historiqueDebitCredit;
}
