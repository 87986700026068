import { useSelector } from "react-redux";
import { Box, IconButton } from "@mui/material";
import { DangerousRounded, Login, Logout } from "@mui/icons-material";
import PropTypes from "prop-types";
import { selectStaUsr } from "@selectors";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, redColor } from "@styles";

const PointageShortcut = (props) => {
    const { 
        isLoadingStatut, 
        isErrorStatut,
        handleOpenConfirmationPointage
    } = props;
    const stateStatut = useSelector(selectStaUsr);

    if(isLoadingStatut) {
        return (
            <IconButton sx={{bgcolor: greenColor["015"]}}>
                <Box width={24} height={24}>
                    <Loader size={24} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                </Box>
            </IconButton>
        );
    }

    if(isErrorStatut) {
        return (
            <IconButton sx={{bgcolor: redColor[1]}}>
                <DangerousRounded />
            </IconButton>
        );
    }

    if(!stateStatut.statut) {
        return (
            <IconButton sx={{bgcolor: redColor[1]}}>
                <DangerousRounded />
            </IconButton>
        );
    }

    return (
        <IconButton sx={{bgcolor: greenColor["015"]}} onClick={() => handleOpenConfirmationPointage("pointage")}>
            {stateStatut.statut.actionSuivante === "D" ? <Logout /> : <Login /> }
        </IconButton> 
    );
}

PointageShortcut.defaultProps = {
    isLoadingStatut: true, 
    isErrorStatut: false,
    handleOpenConfirmationPointage: () => {}
}

PointageShortcut.propTypes = {
    isLoadingStatut: PropTypes.bool, 
    isErrorStatut: PropTypes.bool,
    handleOpenConfirmationPointage: PropTypes.func
}

export default PointageShortcut;