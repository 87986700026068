import { Box, Tooltip } from "@mui/material";
import { IconButtonApp } from "@styles";
import PropTypes from "prop-types";

// eslint-disable-next-line no-unused-vars
const VARIANTS = /** @type {const} */([
    "grey7White5",
    "red7White5",
    "white9Green7", 
    "white5Grey9", 
    "white5Red5", 
    "white5Red9White9", 
    "white5Green9White9", 
    "white5Grey9White9",
    "green2Grey9Green5",
    "panelButton",
    "deletePanelButton",
    "listButton",
    "deleteListButton",
    "closeDialogButton",
    "dialogButton",
    "pageButton"
]);

// eslint-disable-next-line no-unused-vars
const PLACEMENTS = /** @type {const} */([
    "top", 
    "bottom", 
    "left", 
    "right"
]);

// eslint-disable-next-line no-unused-vars
const SIZES = /** @type {const} */([
    "large", 
    "medium", 
    "small"
]);

/**
 * Fonction onClick
 * 
 * @function action
 * 
 */

/**
 * IconButtonBase properties
 * 
 * @typedef {object} IconButtonProps
 * @property {React.ReactElement} icon - Icône du bouton
 * @property {string} title - Texte de la tooltip
 * @property {typeof VARIANTS[number]} variant - Variante de couleur
 * @property {typeof PLACEMENTS[number]} placement - Emplacement de la tooltip
 * @property {typeof SIZES[number]} size - Taille du bouton
 * @property {boolean} disabled - Désactiver le bouton
 * @property {action} action - Action onClick
 */

/** 
 * IconButtonBase component
 * 
 * @type {React.FC<IconButtonProps>} props
 * @returns {React.ReactElement} Retour du bouton
 */
const IconButtonBase = ({
    icon = <></>, 
    title = "", 
    variant = undefined,
    placement = "bottom", 
    size = "small",
    disabled = false, 
    action = () => {}
}) => {
    return (  
        <Tooltip title={title} placement={placement}>
            <Box component="span">
                <IconButtonApp size={size} variant={variant} disabled={disabled} onClick={action}>
                    { icon }
                </IconButtonApp>
            </Box>
        </Tooltip>
    );
}

IconButtonBase.propTypes = {
    icon: PropTypes.element,
    title: PropTypes.string,
    variant: PropTypes.oneOf([
        undefined, 
        "grey7White5",
        "red7White5",
        "white9Green7", 
        "white5Grey9", 
        "white5Red5", 
        "white5Red9White9", 
        "white5Green9White9", 
        "white5Grey9White9",
        "green2Grey9Green5",
        "panelButton",
        "deletePanelButton",
        "listButton",
        "deleteListButton",
        "closeDialogButton",
        "dialogButton",
        "pageButton"
    ]),
    placement: PropTypes.oneOf([
        "top",
        "bottom",
        "left",
        "right"
    ]),
    size: PropTypes.oneOf([
        "large", 
        "medium", 
        "small"
    ]),
    disabled: PropTypes.bool,
    action: PropTypes.func
}

export default IconButtonBase;