import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { ArrowLeft, Check } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { selectEntMan } from "@selectors";
import { usePostSousSection } from "@hooks/gestionEntretiens/hooksQueries";
import { useMappingActions } from "@hooks/gestionEntretiens/hooksUtils";
import { IconButtonBase } from "@components/Buttons";
import { Loader } from "@components/Loaders";
import { ChampCommentaire } from "./Champs";
import { greenColor, greyColor, whiteColor } from "@styles";

const defaultValues = {
    commentaire: ""
}

const schemaCommentaireSeul = z.object({
    commentaire: z.string().optional()
});

const CommentaireForm = ({ 
    open = false, 
    goBack = () => {}
}) => {
    const stateEntretiens = useSelector(selectEntMan);
    const methods = useForm({ mode: "onSubmit", defaultValues: defaultValues, resolver: zodResolver(schemaCommentaireSeul) }); 
    const { designation1, designation2 } = useMappingActions()
    const { isLoadingCommentaire, postCommentaire } = usePostSousSection();

    const validate = useCallback(async (dataForm) => {
        await postCommentaire(dataForm);
        goBack();
    }, [goBack, postCommentaire]);

    if(isLoadingCommentaire) {
        return (
            <>
                <Box borderRadius={2} bgcolor={greenColor["015"]} height={50} p={0.5}>
                    <Skeleton variant="rounded" animation="wave" width="100%" height="100%" sx={{borderRadius: 2}} />
                </Box>
                <Box justifyContent="center" display="flex" alignItems="center" height="calc(100vh - 140px)" p={2}>
                    <Box bgcolor={whiteColor[5]} borderRadius={2} width={50} height={50} justifyContent="center" display="flex" alignItems="center">
                        <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                    </Box>
                </Box>
            </>
        );
    }
    
    return (
        <FormProvider {...methods}>
            <Box borderRadius={2} bgcolor={greenColor["05"]} height={50} p={0.5}>
                <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[7]} height="100%" alignItems="center" px={1}>
                    <IconButtonBase icon={<ArrowLeft fontSize="small" />} title="Retour" action={() => goBack()} />
                    <Box flexGrow={1}>
                        <Typography fontSize="small" fontWeight="bold">{designation1}</Typography>
                        <Typography fontSize="small" fontWeight="bold">{designation2}</Typography>
                    </Box>
                    {stateEntretiens?.entretienSelected?.statut === "P" && 
                        <IconButtonBase icon={<Check fontSize="small" />} title="Valider" action={() => methods.handleSubmit(validate)()} />
                    }
                </Stack>
            </Box>
            <PerfectScrollbar> 
                <Stack spacing={0.5}>
                    <ChampCommentaire open={open} codeQuestion="0" />
                </Stack>
            </PerfectScrollbar>
        </FormProvider>
    );
}

CommentaireForm.propTypes = {
    open: PropTypes.bool,
    goBack: PropTypes.func
}

export default CommentaireForm;