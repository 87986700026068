import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import { Stack } from "@mui/material";
import moment from "moment";
import { selectPlaGlo, selectPlaHrc } from "@selectors";
import { useDownloadPlanning, usePlaGlo } from "@hooks/planning/hooksQueries";
import * as plaGloActions from "@reducers/planning/plaGloReducer"
import * as plaHrcActions from "@reducers/planning/plaHrcReducer"
import { greenColor, whiteColor } from "@styles";
import { PlanningActionsBar, PlanningActionsBarMobile } from "@components/Planning/ActionsBar";
import PlanningContainer from "@components/App/Planning/Containers/PlanningContainer";
import { TYPES_PLANNING } from "@constants/planningConstants";
import { HierarchieDialog, HierarchieDialogMobile, JourDialog, JourDialogMobile, ServicesDialog, ServicesDialogMobile } from "@components/App/Planning/Dialogs";
import { useMedia } from "react-use";
import { TextContainer } from "@components/Containers";
import PlanningContainerMobile from "@components/App/Planning/Containers/PlanningContainerMobile";

const Planning = () => {
    const statePlaGlo = useSelector(selectPlaGlo);
    const statePlaHrc = useSelector(selectPlaHrc);
    const [servicesSelected, setServicesSelected] = useState(statePlaGlo.servicesSelected);
    const [servicesNonSelected, setServicesNonSelected] = useState(statePlaGlo.servicesNonSelected);
    const [openServices, setOpenServices] = useState(false);
    const [openHierarchie, setOpenHierarchie] = useState(false);
    const [openJour, setOpenJour] = useState(false);
    const [openJourHrc, setOpenJourHrc] = useState(false);
    const { isLoading: isLoadingDownload, downloadPlanningActionner } = useDownloadPlanning();
    const { isLoading, isFetching, isError, error, refetch } = usePlaGlo(statePlaGlo.date, statePlaGlo.affichage, servicesSelected, servicesNonSelected, statePlaGlo.premierAppel);
    const dispatch = useDispatch();    
    const isMobile = useMedia('(max-width: 900px)');
    const isMedium = useMedia('(min-width: 901px) and (max-width: 1145px)');
    const isLarge = useMedia('(min-width: 1146px)');

    const handleOpenJour = useCallback((idJour, matricule, prenomNom) => {
        const jour = {jour: moment(statePlaGlo.semaine.jours[idJour].date, "YYYYMMDD").format("DDMMYYYY"), matricule: matricule, prenomNom: prenomNom}
        dispatch(plaGloActions.selectJour(jour));
        setOpenJour(true);
    }, [dispatch, statePlaGlo.semaine.jours]);

    const handleOpenJourHrc = useCallback((idJour, matricule, prenomNom) => {
        const jour = {jour: moment(statePlaHrc.semaine.jours[idJour].date, "YYYYMMDD").format("DDMMYYYY"), matricule: matricule, prenomNom: prenomNom}
        dispatch(plaHrcActions.selectJour(jour));
        setOpenJourHrc(true);
    }, [dispatch, statePlaHrc.semaine.jours]);
    
    const precedent = useCallback(() => {
        const date = moment(statePlaGlo.date, "DDMMYYYY").add(-1, "week").format("DDMMYYYY");
        dispatch(plaGloActions.setDate(date));
    }, [statePlaGlo.date, dispatch]);
    
    const suivant = useCallback(() => {
        const date = moment(statePlaGlo.date, "DDMMYYYY").add(+1, "week").format("DDMMYYYY")
        dispatch(plaGloActions.setDate(date));
    }, [statePlaGlo.date, dispatch]);

    const selectDate =  useCallback((date) => {
        dispatch(plaGloActions.setDate(date.format("DDMMYYYY")));
    }, [dispatch]);

    const checkAllServices = useCallback(() => {
        const services = statePlaGlo.servicesSelected.concat(statePlaGlo.servicesNonSelected)
        setServicesSelected(services);
        setServicesNonSelected([]);
    }, [statePlaGlo.servicesNonSelected, statePlaGlo.servicesSelected]);

    const toggleAffichage = useCallback(() => {
        dispatch(plaGloActions.toggleAffichage());
    }, [dispatch]);

    const telechargerPlanning = useCallback(async () => {
        await downloadPlanningActionner();
    }, [downloadPlanningActionner]);


    return (
        <Stack spacing={1} bgcolor={whiteColor[9]} borderRadius={2} p={1} height="100%" boxSizing="border-box">
            {isMobile ? 
                <>
                    <TextContainer p={2} bgColor={greenColor["015"]} horizontalAlign="center" textWeight="bold">Planning</TextContainer>
                    <PlanningActionsBarMobile 
                        isLoading={isLoading || isFetching}
                        isLoadingDownload={isLoadingDownload}
                        affichage={statePlaGlo.affichage}
                        allServices={statePlaGlo.allServices}
                        precedent={precedent}
                        suivant={suivant}
                        selectDate={selectDate}
                        checkAllServices={checkAllServices}
                        toggleAffichage={toggleAffichage}
                        setOpenHierarchie={setOpenHierarchie}
                        setOpenServices={setOpenServices}
                        telechargerPlanning={telechargerPlanning}
                        refetch={refetch}
                    />
                    <PlanningContainerMobile
                        isLoading={isLoading || isFetching}
                        isError={isError}
                        errorMessage={error?.message}
                        displayEmetteur={false}
                        emetteur={false}
                        maxHeight={"calc(100vh - 484px)"}
                        type={TYPES_PLANNING.GENERAL} 
                        jourCourt={true} 
                        openJour={handleOpenJour}
                    />
                    <JourDialogMobile
                        type={TYPES_PLANNING.GENERAL} 
                        open={openJour} 
                        setOpen={setOpenJour} 
                    /> 
                    <HierarchieDialogMobile 
                        open={openHierarchie}
                        setOpen={setOpenHierarchie}
                        openJour={handleOpenJourHrc}
                    />
                    <JourDialogMobile
                        type={TYPES_PLANNING.HIERARCHIE} 
                        open={openJourHrc} 
                        setOpen={setOpenJourHrc} 
                    /> 
                    <ServicesDialogMobile 
                        open={openServices}
                        setOpen={setOpenServices}
                        setServicesNonSelected={setServicesNonSelected}
                        setServicesSelected={setServicesSelected}
                    />
                </>
            :
                <>
                    <PlanningActionsBar 
                        isLoading={isLoading || isFetching}
                        isLoadingDownload={isLoadingDownload}
                        affichage={statePlaGlo.affichage}
                        allServices={statePlaGlo.allServices}
                        precedent={precedent}
                        suivant={suivant}
                        selectDate={selectDate}
                        checkAllServices={checkAllServices}
                        toggleAffichage={toggleAffichage}
                        setOpenHierarchie={setOpenHierarchie}
                        setOpenServices={setOpenServices}
                        telechargerPlanning={telechargerPlanning}
                        refetch={refetch}
                    />
                    <PlanningContainer
                        isLoading={isLoading || isFetching}
                        isError={isError}
                        errorMessage={error?.message}
                        displayEmetteur={false}
                        emetteur={false}
                        maxHeight={isLarge ? "calc(100vh - 314px)" : isMedium ? "calc(100vh - 332px)" : "calc(100vh - 328px)"}
                        type={TYPES_PLANNING.GENERAL} 
                        openJour={handleOpenJour}
                    />
                    <JourDialog
                        type={TYPES_PLANNING.GENERAL} 
                        open={openJour} 
                        setOpen={setOpenJour} 
                    /> 
                    <HierarchieDialog 
                        open={openHierarchie}
                        setOpen={setOpenHierarchie}
                        openJour={handleOpenJourHrc}
                    />
                    <JourDialog
                        type={TYPES_PLANNING.HIERARCHIE} 
                        open={openJourHrc} 
                        setOpen={setOpenJourHrc} 
                    /> 
                    <ServicesDialog 
                        open={openServices}
                        setOpen={setOpenServices}
                        setServicesNonSelected={setServicesNonSelected}
                        setServicesSelected={setServicesSelected}
                    />
                </>
            }
        </Stack>
    );
}

const styles = {
    stackStyle: {
        bgcolor: whiteColor[9],
        borderRadius: 2,
        padding: 1,
        position: "relative",
        marginTop: 1,
        height: "calc(100vh - 166px)"
    },
    boxErrorStyle: {
        backgroundColor: "rgba(255,0,0,0.1)", 
        textAlign: "center",
        p: 2, 
        height: "100%",
        borderRadius: 2
    }
};

export default Planning;