import axios from "axios";
import { urls } from "@constants/appConstants";

export const fetchTem = async (origine, matricule, type, date, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetTemps"
    };
    const body = {
        origine: origine,
        matricule: matricule,
        type: type,
        date: date
    };
    const response = await axios.post(urls.getTemps, body, { headers: headers, params: { client: client }, signal: signal });
    return {
        date: response.data.date,
        semaine: response.data.semaine,
        totaux: response.data.totaux,
        repas: response.data.repas,
        debitCredits: response.data.debitCredits
    }
}

export const fetchNavTem = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetTemps"
    };
    const body = {
        origine: data.origine,
        matricule: data.matricule,
        type: data.type,
        date: data.date
    };
    const response = await axios.post(urls.getTemps, body, { headers: headers, params: { client: data.client } });
    return {
        date: response.data.date,
        semaine: response.data.semaine,
        totaux: response.data.totaux,
        repas: response.data.repas,
        debitCredits: response.data.debitCredits
    }
}

export const fetchDetJouTem = async (matricule, date, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetAbsencesPlanning"
    };
    const body = {
        matricule: matricule,
        date: date
    };
    const response = await axios.post(urls.getAbsencesPlanning, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.absences;
}
