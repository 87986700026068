import { useSelector } from "react-redux";
import { Info } from "@mui/icons-material";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { selectPlaHor } from "@selectors";
import moment from "moment";
import { greenColor } from "@styles";
import { useTranslation } from "react-i18next";

const BandeauDate = () => {
    const statePlagesHoraire = useSelector(selectPlaHor);
    const { t } = useTranslation("global");
    const date = moment(statePlagesHoraire.datePlagesHoraires, "DDMMYYYY");
    const jourString = date.format("dddd");
    const jour = date.format("D");
    const mois = date.format("MMMM");
    const annee = date.format("YYYY");
    const semaine = date.format("ww");
    const dateString = `${jourString} ${jour} ${mois} ${annee} - ${t("calendar.week_string", { count: 1 })} ${semaine}`;

    return (
        <Stack direction="row" sx={styles.boxBandeauDateStyle}>
            <Box flexGrow={1}>
                <Typography fontSize={"small"} fontWeight="bold">{dateString}</Typography>
            </Box>
            {statePlagesHoraire.infos.libelleFerie && 
                <Tooltip title={statePlagesHoraire.infos.libelleFerie}> 
                    <Info color="info" fontSize="small" sx={{cursor: "pointer"}} />
                </Tooltip>   
            }
        </Stack>
    );
}

const styles = {
    boxBandeauDateStyle: {
        backgroundColor: greenColor["015"], 
        paddingX: 1,
        paddingY: 1,
        borderRadius: 2,
    }
}

export default BandeauDate;