import { useCallback, useState } from "react";
import { Box, Stack, Step, StepButton, StepContent, Stepper, Typography } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import PropTypes from "prop-types";
import { DialogContainer, HeaderDialogContainer } from "@components/Containers";
import { greenColor, greyColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";
import { IconButtonBase } from "@components/Buttons";

const AideDialog = (props) => {
    const { open, setOpen } = props;
    const [activeStep, setActiveStep] = useState(0);
    const [activeOption, setActiveOption] = useState(0);
    const { t } = useTranslation("global");

    const steps = [
        {
            id: 1,
            label: t("texts.help_dialog_label_1"),
            description: t("texts.help_dialog_description_1")
        },
        {
            id: 2,
            label: t("texts.help_dialog_label_2"),
            description: t("texts.help_dialog_description_2")
        },
        {
            id: 3,
            label: t("texts.help_dialog_label_3"),
            description: t("texts.help_dialog_description_3"),
            options: [
                { id: 1, label: t("texts.help_dialog_option_31_label"), description: t("texts.help_dialog_options_31_description")},
                { id: 2, label: t("texts.help_dialog_option_32_label"), description: t("texts.help_dialog_options_32_description")},
                { id: 3, label: t("texts.help_dialog_option_33_label"), description: t("texts.help_dialog_options_33_description")},
                { id: 4, label: t("texts.help_dialog_option_34_label"), description: t("texts.help_dialog_options_34_description")},
                { id: 5, label: t("texts.help_dialog_option_35_label"), description: t("texts.help_dialog_options_35_description")},
            ]
        },
        {
            id: 4,
            label: t("texts.help_dialog_label_4"),
            description: t("texts.help_dialog_description_4")
        },
        {
            id: 5,
            label: t("texts.help_dialog_label_5"),
            description: t("texts.help_dialog_description_5")
        }
    ];

    const handleSuivant = useCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }, []);
    
    const handleRetour = useCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }, []);

    const handleStep = useCallback((event) => {
        setActiveStep(Number(event.currentTarget.dataset.step));
    }, []);

    const handleSuivantOption = useCallback(() => {
        setActiveOption((prevActiveOption) => prevActiveOption + 1);
    }, []);
    
    const handleRetourOption = useCallback(() => {
        setActiveOption((prevActiveOption) => prevActiveOption - 1);
    }, []);

    const handleOption = useCallback((event) => {
        setActiveOption(Number(event.currentTarget.dataset.option));
    }, []);

    return (
        <DialogContainer open={open} taille="sm">
            <HeaderDialogContainer 
                title={t("titles.help_long")}
                close={() => setOpen(false)}
            />
            <Box p={1} borderRadius={2}>
                <Stepper activeStep={activeStep} orientation="vertical" nonLinear> 
                    {steps.map((step, id) => (
                        <Step key={`SA${step.id}`}>
                            <StepButton sx={styles.stepButtonStyle} data-step={id} onClick={handleStep} disableRipple>
                                <Box bgcolor={greenColor["07"]} p={1} borderRadius={2}>
                                    <Typography color="white" fontWeight="bold">{step.label}</Typography>
                                </Box>
                            </StepButton>
                            <StepContent>
                                <Box bgcolor={greenColor["07"]} p={1} borderRadius={2}>
                                    <Typography color="white">{step.description}</Typography>
                                </Box>
                                <Stack direction="row" spacing={1} paddingY={1}>
                                    {id < 4 && 
                                        <IconButtonBase variant="green2Grey9Green5" icon={<KeyboardArrowDown fontSize="small" />} title="Suivant" action={handleSuivant} />
                                    }
                                    {id > 0 && 
                                        <IconButtonBase variant="green2Grey9Green5" icon={<KeyboardArrowUp fontSize="small" />} title="Retour" action={handleRetour} />
                                    }
                                </Stack>
                                {step.id === 3 &&
                                    <Stepper activeStep={activeOption} orientation="vertical" nonLinear> 
                                        {step.options.map((option, id) => (
                                            <Step key={`SA${option.id}`}>
                                                <StepButton sx={styles.stepButtonStyle} data-option={id} onClick={handleOption} disableRipple>
                                                    <Box bgcolor={greenColor["07"]} p={1} borderRadius={2}>
                                                        <Typography color="white" fontWeight="bold">{option.label}</Typography>
                                                    </Box>
                                                </StepButton>
                                                <StepContent>
                                                    <Box bgcolor={greenColor["07"]} p={1} borderRadius={2}>
                                                        <Typography color="white">{option.description}</Typography>
                                                    </Box>
                                                    <Stack direction="row" spacing={1} paddingY={1}>
                                                        {id < 4 && 
                                                            <IconButtonBase variant="green2Grey9Green5" icon={<KeyboardArrowDown fontSize="small" />} title="Suivant" action={handleSuivantOption} />
                                                        }
                                                        {id > 0 && 
                                                            <IconButtonBase variant="green2Grey9Green5" icon={<KeyboardArrowUp fontSize="small" />} title="Retour" action={handleRetourOption} />
                                                        }
                                                    </Stack>
                                                </StepContent>
                                            </Step>
                                        ))}
                                    </Stepper>
                                }
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        </DialogContainer>
    );
}

AideDialog.defaultProps = {
    open: false, 
    setOpen: () => {}
}

AideDialog.propTypes = {
    open: PropTypes.bool, 
    setOpen: PropTypes.func
}

const styles = {
    stepButtonStyle: {
        fontWeight: "bold", 
        "& .MuiStepIcon-root": {
            color: whiteColor[5],
            "& .MuiStepIcon-text": {
                fill: greyColor[7],
            },
            "&.Mui-active": {
                "& .MuiStepIcon-text": {
                    fill: whiteColor[9],
                },
                color: greenColor["07"]
            }
        }
    }
}

export default AideDialog;