import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { greenColor, greyColor } from "@styles";

const PanelListContainer = ({
    children = <></>, 
    length = 0, 
    noDataBgColor = greenColor["05"], 
    noDataLabelColor = greyColor[9],
    noDataLabel = "Aucune donnée"
}) => {
    return (  
        length > 0 ?
            <Grid container spacing={0.5}>
                {children}
            </Grid>
        :
            <Grid container height="100%">
                <Box width="100%" display="flex" alignItems="center" justifyContent="center"> 
                    <Box borderRadius={2} bgcolor={noDataBgColor} py={1} px={2}> 
                        <Typography fontSize="small" fontWeight="bold" color={noDataLabelColor}>{noDataLabel}</Typography>
                    </Box>
                </Box>
            </Grid>
    );
}

PanelListContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.array]),
    length: PropTypes.number,
    noDataBgColor: PropTypes.string,
    noDataLabelColor: PropTypes.string,
    noDataLabel: PropTypes.string
}

export default PanelListContainer;