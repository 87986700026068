import { useCallback } from "react";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { DialogContainer } from "@components/Containers";
import { DeleteDemandesActions } from "./Actions";
import { DeleteDemandesContent } from "./Contents";
import { useDelDabGesAbs } from "@hooks/gestionAbsences/historique/hooksQueries";

const DeleteDemandesDialog = (props) => {
    const { open, setOpen, demandes, refetch, resetSelection } = props;
    const { enqueueSnackbar } = useSnackbar();
    const { isLoading, deleteDemandes } = useDelDabGesAbs();

    const close = useCallback(async () => {
        setOpen(false);
        resetSelection()
    }, [resetSelection, setOpen]);

    const handleDelete = useCallback(async () => {
        const { isSucceed } = await deleteDemandes(demandes);
        if(isSucceed) {
            enqueueSnackbar("Demandes supprimées avec succès", { variant: "success" });
            refetch();
        } else {
            enqueueSnackbar("Une erreur est survenue lors de la suppression des demandes", { variant: "error" });
        }
        close();
    }, [close, deleteDemandes, demandes, enqueueSnackbar, refetch]);

    return (
        <DialogContainer open={open} taille="sm" placement="top">
            <DeleteDemandesActions 
                close={close}
                handleDelete={handleDelete}
            />     
            <DeleteDemandesContent 
                isLoading={isLoading}
                demandes={demandes}
            />
        </DialogContainer>
    );
}

DeleteDemandesDialog.defaultProps = {
    open: false, 
    setOpen: () => {}, 
    demandes: [], 
    refetch: () => {}, 
    resetSelection: () => {}
}

DeleteDemandesDialog.propTypes = {
    open: PropTypes.bool, 
    setOpen: PropTypes.func, 
    demandes: PropTypes.array, 
    refetch: PropTypes.func, 
    resetSelection: PropTypes.func
}

export default DeleteDemandesDialog;