import { useDispatch, useSelector } from "react-redux";
import { ChevronLeft, ChevronRight, Refresh } from "@mui/icons-material";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { selectPlaHor, selectUsrApp } from "@selectors";
import moment from "moment";
import PropTypes from "prop-types";
import * as plaHorActions from "@reducers/plagesHoraires/plaHorReducer"
import { Loader } from "@components/Loaders";
import { ErrorBase } from "@components/Errors";
import { IconButtonBase } from "@components/Buttons";
import BandeauDate from "./components/BandeauDate";
import PlagesHoraires from "./components/PlagesHoraires";
import { getWhiteColor, greenColor, greyColor } from "@styles";
import { HeaderPanelContainer } from "@components/Containers";
import { useTranslation } from "react-i18next";

const Contenu = (props) => {
    const {
        isLoadingPlagesHoraires,
        isErrorPlagesHoraires,
        errorPlagesHoraireMessage
    } = props;
    const statePlagesHoraire = useSelector(selectPlaHor);
    const { t } = useTranslation("global");

    if(isLoadingPlagesHoraires) {
        return <Box sx={styles.loaderStyle}><Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} /></Box>
    }

    if(isErrorPlagesHoraires) {
        return <ErrorBase>{errorPlagesHoraireMessage}</ErrorBase>;
    }

    if(!statePlagesHoraire.infos || !statePlagesHoraire.matin || !statePlagesHoraire.apresMidi) {
        return (<ErrorBase>{t("errors.data_recovery_time_slot")}</ErrorBase>);
    }


    return (
        <Stack spacing={0.5} height="100%">
            <BandeauDate />
            {statePlagesHoraire.noPlages ?
                <Box sx={styles.noPlageStyle}>
                    <Typography fontSize="small">{t("no_data.time_slot")}</Typography>
                </Box>
            :
                <Box bgcolor={greenColor["015"]} borderRadius={2} display="flex" alignItems="center" justifyContent="center" height="100%">
                    <PlagesHoraires />
                </Box>
            }
        </Stack>
    );
}

const PlagesHoraireWidget = (props) => {
    const { 
        isLoadingPlagesHoraires,
        isErrorPlagesHoraires,
        errorPlagesHoraireMessage,
        refetchPlagesHoraires
    } = props;
    const stateUser = useSelector(selectUsrApp);
    const statePlagesHoraire = useSelector(selectPlaHor);
    const dispatch = useDispatch();
    const { t } = useTranslation("global");

    return (
        <Paper sx={stateUser.background ? styles.paperStyle : styles.paperStyleNoBackground} elevation={0}>
            <Stack spacing={0.5} padding={0.5} height="100%" boxSizing="border-box">
                <HeaderPanelContainer 
                    actionsLeft={
                        <>
                            <IconButtonBase icon={<ChevronLeft fontSize="small" />} title={t("buttons.previous")} action={() => dispatch(plaHorActions.setDatePlagesHoraires(moment(statePlagesHoraire.datePlagesHoraires, "DDMMYYYY").add(-1, "day").format("DDMMYYYY")))} />
                            <IconButtonBase icon={<ChevronRight fontSize="small" />} title={t("buttons.next")} action={() => dispatch(plaHorActions.setDatePlagesHoraires(moment(statePlagesHoraire.datePlagesHoraires, "DDMMYYYY").add(1, "day").format("DDMMYYYY")))} />          
                        </>
                    } 
                    actionsRight={
                        <IconButtonBase icon={<Refresh fontSize="small" />} title={t("buttons.refresh")} action={() => refetchPlagesHoraires()} />
                    } 
                    bgColor={greenColor["015"]} 
                    padding={0.5} 
                    title={t("titles.time_slot")} 
                />
                <Contenu isLoadingPlagesHoraires={isLoadingPlagesHoraires} isErrorPlagesHoraires={isErrorPlagesHoraires} errorPlagesHoraireMessage={errorPlagesHoraireMessage} />
            </Stack>
        </Paper>
    );
}

PlagesHoraireWidget.defaultProps = {
    isLoadingPlagesHoraires: true, 
    isErrorPlagesHoraires: false, 
    errorPlagesHoraireMessage: "", 
    refetchPlagesHoraires: () => {}
}

PlagesHoraireWidget.propTypes = {
    isLoadingPlagesHoraires: PropTypes.bool, 
    isErrorPlagesHoraires: PropTypes.bool, 
    errorPlagesHoraireMessage: PropTypes.string, 
    refetchPlagesHoraires: PropTypes.func
}

const styles = {
    paperStyleNoBackground: {
        backdropFilter: "blur(3px)",
        backgroundColor: greenColor["02"],
        width: "100%",
        height: "200px",
        borderRadius: 2
    },

    noPlageStyle: {  
        height: "102px",
        background: greenColor["02"],
        borderRadius: 2,
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
    },
    boxTitleStyle: {
        backgroundColor: "rgba(0,0,0,0.1)", 
        textAlign: "center",
        p: 0.5,
        borderRadius: 2,
    },
    boxPlagesHoraireStyle: {
        
        p: 1,
    },
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        height: "calc(200px - 46px)"
    },
    paperStyle: {
        backdropFilter: "blur(3px)",
        backgroundColor: getWhiteColor("0.95"),
        width: "100%",
        height: "200px",
        borderRadius: 2
    }
};

export default PlagesHoraireWidget;