import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalContainer } from "@components/Containers";
import { Box, Stack, Typography } from "@mui/material";
import { LostPasswordDialog, PasswordLoginDialog, QuestionLoginDialog, QuestionPasswordLoginDialog } from "@components/App/Login/Dialogs";
import { LoginForm } from "@components/App/Login/Forms";
import { greenColor } from "@styles";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { schemaLogin } from "@components/App/Login/validations";
import * as userActions from "@reducers/user/usrAppReducer"
import { ChevronLeft, Https } from "@mui/icons-material";
import { zodResolver } from "@hookform/resolvers/zod";
import { useLogin, useVerifierIdentifiant } from "@hooks/securite/hooksQueries";
import { selectCltApp, selectUsrApp } from "@selectors";
import moment from "moment";
import { IconButtonBase } from "@components/Buttons";

const defaultValuesLogin = {
    identifiant: "",
    identifiantCheck: false,
    password: ""
}

const Login = () => {
    const stateClient = useSelector(selectCltApp);
    const stateUser = useSelector(selectUsrApp);
    const { isLoadingVerification, verifierIdentifiantActionner } = useVerifierIdentifiant();
    const { isLoadingLogin, loginActionner } = useLogin();
    const methods = useForm({ mode: "all", defaultValues: defaultValuesLogin, resolver: zodResolver(schemaLogin) });
    const identifiantCheck = methods.watch("identifiantCheck");
    const dispatch = useDispatch();
    const { t } = useTranslation("global");

    useEffect(() => {
        document.title = t("titles.app_login");
    }, [t]);

    const handleContinue = useCallback(async (data) => {
        const dataRequest = {
            identifiant: data.identifiant, 
            client: stateClient.client
        };
        const { isSucceed } = await verifierIdentifiantActionner(dataRequest);
        if(isSucceed) {
            methods.setValue("identifiantCheck", true);
        }
    }, [methods, stateClient.client, verifierIdentifiantActionner]);

    const handleLogin = useCallback((data) => {
        const dataRequest = {
            identifiant: data.identifiant, 
            password: data.password,
            mail: stateUser.email,
            date: moment().format("DDMMYYYY"),
            client: stateClient.client
        }
        loginActionner(dataRequest);
    }, [loginActionner, stateClient.client, stateUser.email]);

    const handleEnter = useCallback((event) => {
        if(event.key === "Enter" || event.key === undefined) {
            if(identifiantCheck) {
                methods.handleSubmit(handleLogin)();
            } else {
                methods.handleSubmit(handleContinue)();
            }
        }
    }, [identifiantCheck, methods, handleLogin, handleContinue]);

    const handleRetour = useCallback(() => {
        methods.resetField("password");
        methods.setValue("identifiantCheck", false);
    }, [methods]);

    return (
        <>
            <GlobalContainer>
                <Stack direction="row" spacing={1} bgcolor={greenColor["05"]} borderRadius={2} px={1} height={45} display="flex" alignItems="center">
                    {identifiantCheck && 
                        <IconButtonBase 
                            variant="white5Grey9White9" 
                            icon={<ChevronLeft fontSize="small" />} 
                            title={t("buttons.retour")} 
                            disabled={isLoadingLogin} 
                            placement="top" 
                            action={() => handleRetour()} 
                        />
                    }
                    <Box textAlign="center" width="100%">
                        <Typography fontSize="normal" fontWeight={700}>{t("titles.login")}</Typography>
                    </Box>
                    {identifiantCheck && 
                        <IconButtonBase 
                            variant="white5Grey9White9" 
                            icon={<Https fontSize="small" />} 
                            title={t("buttons.lost_password")} 
                            disabled={isLoadingLogin} 
                            placement="top" 
                            action={() => dispatch(userActions.openLostPassword(true))} 
                        />
                    }
                </Stack>
                <FormProvider {...methods}>
                    <LoginForm 
                        isLoadingVerification={isLoadingVerification}
                        isLoadingLogin={isLoadingLogin}
                        handleEnter={handleEnter}
                        handleContinue={() => methods.handleSubmit(handleContinue)()} 
                        handleLogin={() => methods.handleSubmit(handleLogin)()}     
                    />
                </FormProvider>   
            </GlobalContainer>            
            <QuestionLoginDialog /> 
            <PasswordLoginDialog /> 
            <QuestionPasswordLoginDialog /> 
            <LostPasswordDialog /> 
        </>
    );
}



export default Login;