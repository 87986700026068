import { getErrorsMessagesInput } from "@utils";
import { validateDate } from "@validation";
import Joi from "joi";
import { TYPES_ERROR_INPUT } from "@constants/errorsConstants";

export const schemaHeuresDetaillees = Joi.object({
    dateDebut: validateDate.dateMoment().valide().messages({ 
        "object.base": getErrorsMessagesInput("Date de début", TYPES_ERROR_INPUT.REQUIRED), 
        "dateMoment.valide": getErrorsMessagesInput("Date de début", TYPES_ERROR_INPUT.DATE_INVALIDE),
    }),
    dateFin: validateDate.dateMoment().valide().greaterMoment().messages({ 
        "object.base": getErrorsMessagesInput("Date de fin", TYPES_ERROR_INPUT.REQUIRED),
        "dateMoment.valide": getErrorsMessagesInput("Date de fin", TYPES_ERROR_INPUT.DATE_INVALIDE),
        "dateMoment.greaterMoment": getErrorsMessagesInput("La date de fin", TYPES_ERROR_INPUT.DATE_SUPERIEUR),
    }),
    societe: Joi.object().allow("").allow(null),
    etablissement: Joi.object().allow("").allow(null),
    direction: Joi.object().allow("").allow(null),
    service: Joi.object().allow("").allow(null),
    secteur: Joi.object().allow("").allow(null),
    present: Joi.boolean(),
    horaire: Joi.object().allow("").allow(null),
    joursSemaine: Joi.string().allow(""),
    detailMouvements: Joi.boolean(),
});

export const schemaHeuresCumulees = Joi.object({
    dateDebut: validateDate.dateMoment().valide().messages({ 
        "object.base": getErrorsMessagesInput("Date de début", TYPES_ERROR_INPUT.REQUIRED), 
        "dateMoment.valide": getErrorsMessagesInput("Date de début", TYPES_ERROR_INPUT.DATE_INVALIDE),
    }),
    dateFin: validateDate.dateMoment().valide().greaterMoment().messages({ 
        "object.base": getErrorsMessagesInput("Date de fin", TYPES_ERROR_INPUT.REQUIRED),
        "dateMoment.valide": getErrorsMessagesInput("Date de fin", TYPES_ERROR_INPUT.DATE_INVALIDE),
        "dateMoment.greaterMoment": getErrorsMessagesInput("La date de fin", TYPES_ERROR_INPUT.DATE_SUPERIEUR),
    }),
    societe: Joi.object().allow("").allow(null),
    etablissement: Joi.object().allow("").allow(null),
    direction: Joi.object().allow("").allow(null),
    service: Joi.object().allow("").allow(null),
    secteur: Joi.object().allow("").allow(null),
    present: Joi.boolean().allow("").allow(null)
});