import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { fetchHor, fetchAffHor } from "@api/horaires";
import { selectCltApp } from "@selectors";
import * as horAffGesTemActions from "@reducers/gestionTemps/horaires/horAffGesTemReducer"

export const useHorBut = (codeSociete, date, open, client) => {
    const jour = date ? date.format("DD") : "";
    const mois = date ? date.format("MM") : "";
    const annee = date ? date.format("YYYY") : "";
    
    return useQuery(['horBut', codeSociete, date], ({ signal }) => fetchAffHor(codeSociete, jour, mois, annee, client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!date && open
    });
}

export const useHorInp = (codeSociete, date, open) => {
    const stateClient = useSelector(selectCltApp);
    const jourEffet = date ? date.format("DD") : "";
    const moisEffet = date ? date.format("MM") : "";
    const anneeEffet = date ? date.format("YYYY") : "";
    const body = { codeSociete, jourEffet, moisEffet, anneeEffet };

    return useQuery(['horInp', codeSociete, date], ({ signal }) => fetchHor(body, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!codeSociete && !!date && !date?.isValid() && open
    });
}

export const useHorAffGesTem = (codeSociete, date, open) => {
    const stateClient = useSelector(selectCltApp);
    const jourEffet = date ? date.format("DD") : "";
    const moisEffet = date ? date.format("MM") : "";
    const anneeEffet = date ? date.format("YYYY") : "";
    const body = { codeSociete, jourEffet, moisEffet, anneeEffet };
    const dispatch = useDispatch();

    const query = useQuery(['horAffGesTem', codeSociete, date], ({ signal }) => fetchHor(body, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!codeSociete && !!date && !!date?.isValid() && open
    });

    useEffect(() => {
        if(query.data) {
            dispatch(horAffGesTemActions.setHorairesAffectations(query.data));
        }
    }, [dispatch, query.data]);

    return query;
}
