import { Box, IconButton, Stack, Typography } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import PropTypes from "prop-types";
import { greenColor, greyColor, whiteColor } from "@styles";

const MenuTrieButtonMobile = (props) => {
    const { option, trier } = props;

    return (
        <Stack spacing={0.5} direction="row">
            <Stack direction="row" spacing={1} flexGrow={1} bgcolor={greenColor["015"]} borderRadius={2} p={1} display="flex" alignItems="center">
                <Box display="flex" alignItems="center">{option?.icon}</Box>
                <Box display="flex" alignItems="center">
                    <Typography fontSize="small" fontWeight="bold">{option?.label}</Typography>
                </Box>
            </Stack>
            <Stack direction="row" spacing={0.5} display="flex" alignItems="center">
                <Box>
                    <IconButton
                        size="medium"
                        sx={{ 
                            color: "black", 
                            backgroundColor: greenColor["035"], 
                            "&:hover": { 
                                color: greyColor[5],
                                backgroundColor: whiteColor[1] 
                            }  
                        }}
                        onClick={() => trier(option.keyAsc)} 
                    >
                        <ArrowUpward fontSize="small" />
                    </IconButton>
                </Box>
                <Box>
                    <IconButton 
                        size="medium"
                            sx={{ 
                                color: "black", 
                                backgroundColor: greenColor["035"], 
                                "&:hover": { 
                                    color: greyColor[5],
                                    backgroundColor: whiteColor[1] 
                                }  
                            }}
                        onClick={() => trier(option.keyDesc)}
                    >
                        <ArrowDownward fontSize="small" />
                    </IconButton>
                </Box>
            </Stack>
        </Stack>
    );
}

MenuTrieButtonMobile.defaultProps = {
    option: null,
    trier: () => {}
}

MenuTrieButtonMobile.propTypes = {
    option: PropTypes.object,
    trier: PropTypes.func
}

export default MenuTrieButtonMobile;