import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { expand, mutate, rename, tidy } from "@tidyjs/tidy";
import moment from "moment";
import PropTypes from "prop-types";
import { selectAffHorInd } from "@selectors";
import * as affHorIndActions from "@reducers/gestionTemps/affectations/affHorIndReducer"
import { DateHookFormInput, SelectHookFormInput } from "@components/Inputs";
import { schemaFilterAffectationsHorairesIndividuelles } from "../../validations";
import { filtrerAffectationsHorairesIndividuelles } from "../filtres";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";

const colonneOptions = [
    { id: 1, value: "date", label: "Date" },
    { id: 2, value: "horaire", label: "Horaire" },
    { id: 3, value: "statut", label: "Statut" },
]

const operateurOptions = [
    { id: 1, value: "egale", label: "Egale" },
    { id: 2, value: "superieur", label: "Après le" },
    { id: 3, value: "inferieur", label: "Avant le" }
]

const optionsStatut = [
    { id: 1, value: "D", label: "Décision" },
    { id: 2, value: "P", label: "Proposition" }
]

const defaultValues = {
    colonne: "",
    operateur: "egale",
    date: null,
    txt: ""
}

const FiltreAffectationsHorairesIndividuellesForm = (props) => {
    const { setOpen } = props;
    const [horaires, setHoraires] = useState([]);
    const stateAffHorInd = useSelector(selectAffHorInd); 
    const methods = useForm({ mode: "all", defaultValues: defaultValues, resolver: joiResolver(schemaFilterAffectationsHorairesIndividuelles) });
    const colonne = methods.watch("colonne");
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(colonne === "date") {
            methods.resetField("date", moment());
        }
        if(colonne === "statut" || colonne === "horaire") {
            methods.resetField("txt", "");
        }
    }, [colonne, methods]);

    useEffect(() => {
        const result = tidy(stateAffHorInd.affectations, expand("codeHoraire"), rename({codeHoraire: "value"}), mutate({id: (d, id) => id, label: (d) => d.value}));
        setHoraires(result);
    }, [stateAffHorInd.affectations]);

    const filtrer = useCallback((data) => {
        setOpen(false);
        const affectationsFiltered = filtrerAffectationsHorairesIndividuelles(data, stateAffHorInd.affectations);
        dispatch(affHorIndActions.filtrer(affectationsFiltered));
    }, [dispatch, setOpen, stateAffHorInd.affectations]);
    
    return (
        <FormProvider {...methods}>
            <Stack spacing={1}>
                <SelectHookFormInput name="colonne" label="Colonnes" options={colonneOptions} />
                {{
                    date: 
                        <>
                            <SelectHookFormInput name="operateur" label="Opérateur" options={operateurOptions} />
                            <DateHookFormInput name="date" label="Date d'émission" />
                        </>,
                    statut: <SelectHookFormInput name="txt" label="Statut" options={optionsStatut} />,
                    horaire: <SelectHookFormInput name="txt" label="Horaire" options={horaires} />
                }[colonne]}
            </Stack>
            <Box sx={styles.boxButtonsMenuFiltre}>
                <Stack direction="row" spacing={1}>
                    <Tooltip title="Valider">
                        <IconButton sx={styles.buttonStyleGreen} onClick={() => methods.handleSubmit(filtrer)()}>
                            <Check fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Fermer">
                        <IconButton sx={styles.buttonStyleRed} onClick={() => setOpen(false)}>
                            <Close fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Box>
        </FormProvider>
    );
}

FiltreAffectationsHorairesIndividuellesForm.defaultProps = {
    setOpen: () => {}
}

FiltreAffectationsHorairesIndividuellesForm.propTypes = {
    setOpen: PropTypes.func
}

const styles = {
    boxButtonsMenuFiltre: {
        justifyContent: "center",
        display: "flex"
    },
    buttonStyleGreen: {
        boxShadow: "none", 
        backgroundColor: greenColor["02"],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: greenColor["03"],
            color: whiteColor[9],
        }
    },
    buttonStyleRed: {
        boxShadow: "none", 
        backgroundColor: redColor[1],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: redColor[2],
            color: whiteColor[9],
        }
    }
}

export default FiltreAffectationsHorairesIndividuellesForm;