import PropTypes from "prop-types";
import { TimeMaskedHookFormInput } from "@components/Inputs";

const PlanningTimeInput = (props) => {
    const { idPlage, idJour, idLabel, label } = props;

    return (
        <TimeMaskedHookFormInput name={`${idLabel}${idPlage}${idJour + 1}`} label={label} size="xsmall" />
    )
}

PlanningTimeInput.defaultProps = {
    label: ""
}

PlanningTimeInput.propTypes = {
    idPlage: PropTypes.number.isRequired,
    idJour: PropTypes.number.isRequired,
    idLabel: PropTypes.oneOf(["debut", "arret"]).isRequired,
    label: PropTypes.string
}

export default PlanningTimeInput;