import { Box, Stack } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { TextContainer, TextWithLabelContainer } from "@components/Containers";
import PiecesJointesList from "../Lists/PiecesJointesList";
import { whiteColor } from "@styles";
import { TextDureeAbsenceContainer, TextMotifAbsenceContainer, TextNombreHeuresAbsenceContainer, TextNombreJoursAbsenceContainer } from "@components/Containers/GestionAbsences";

const Recapitulatif = (props) => {
    const { 
        data, 
        isLoading, 
        isError, 
        errorMessage, 
        demandeAbsenceSelected 
    } = props;

    return (
        <Stack spacing={0.5}>
            <TextContainer bgColor={whiteColor[7]} p={1} textWeight="bold" horizontalAlign="center">Informations</TextContainer>
            <Box>
                <Stack spacing={0.5}>
                    <TextContainer bgColor={whiteColor[7]} p={1} textWeight="bold">
                        Demande {demandeAbsenceSelected?.statut[0]?.toLowerCase()}{demandeAbsenceSelected?.statut?.substring(1)}
                    </TextContainer>
                    <Box bgcolor={whiteColor[7]} borderRadius={2} p={1}>
                        <TextWithLabelContainer value={demandeAbsenceSelected?.prenomNom} label="Demande émise par" twoPoints={false} weight={{value: "bold", label: "normal"}} />
                    </Box>
                    <TextContainer bgColor={whiteColor[7]} p={1}>
                        {demandeAbsenceSelected?.nature} émise le {moment(demandeAbsenceSelected?.date, "DDMMYYYY").format("DD/MM/YYYY")} à {moment(demandeAbsenceSelected?.heure, "HHmmss").format("HH:mm:ss")}
                    </TextContainer>
                    <TextMotifAbsenceContainer libelleAbsence={demandeAbsenceSelected?.libelleAbsence} />
                    <Box bgcolor={whiteColor[7]} p={1} borderRadius={2}>
                        <TextDureeAbsenceContainer dateDebut={demandeAbsenceSelected?.dateDebut} dateReprise={demandeAbsenceSelected?.dateReprise} nature={demandeAbsenceSelected?.nature} nombreJours={demandeAbsenceSelected?.nombreJours} />
                        <TextNombreJoursAbsenceContainer nombreJours={demandeAbsenceSelected?.nombreJours} demiJournee={demandeAbsenceSelected?.demiJournee} />
                        <TextNombreHeuresAbsenceContainer heureDebut={demandeAbsenceSelected?.heureDebut} heureReprise={demandeAbsenceSelected?.heureReprise} nombreJours={demandeAbsenceSelected?.nombreJours} nombreHeures={demandeAbsenceSelected?.nombreHeures} />
                    </Box>
                    {demandeAbsenceSelected?.motifDemande && 
                        <TextContainer bgColor={whiteColor[7]} p={1}>Motif de la demande : {demandeAbsenceSelected?.motifDemande}</TextContainer>
                    }
                    {demandeAbsenceSelected?.acceptationCondition1 && 
                        <TextContainer bgColor={whiteColor[7]} p={1}>Condition acceptée : {demandeAbsenceSelected?.acceptationCondition1} {demandeAbsenceSelected?.acceptationCondition2}</TextContainer>
                    }
                    <TextContainer bgColor={whiteColor[7]} p={1} textWeight="bold" verticalAlign="center">Pièces Jointes</TextContainer>
                    <PiecesJointesList 
                        data={data} 
                        isLoading={isLoading}
                        isError={isError}
                        errorMessage={errorMessage}
                    />
                </Stack> 
            </Box>  
        </Stack>
    );
}

Recapitulatif.defaultProps = {
    data: [], 
    isLoading: true, 
    isError: false, 
    errorMessage: "",
    demandeAbsenceSelected: null
}

Recapitulatif.propTypes = {
    data: PropTypes.array, 
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool, 
    errorMessage: PropTypes.string,
    demandeAbsenceSelected: PropTypes.object
}

export default Recapitulatif;