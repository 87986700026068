import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import moment from "moment";
import PropTypes from "prop-types";
import * as creGesTemActions from "@reducers/gestionTemps/credits/creGesTemReducer";
import { DateHookFormInput, NumberHookFormInput, SelectHookFormInput } from "@components/Inputs";
import { selectCreGesTem } from "@selectors";
import { schemaFilterCredits } from "../validations";
import { filtrerCreGesTem } from "../filtres";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";
import { expand, filter, mutate, rename, tidy } from "@tidyjs/tidy";

const colonneOptions = [
    { id: 1, value: "date", label: "Date" },
    { id: 2, value: "creditEnCours", label: "Crédit En Cours" },
    { id: 3, value: "creditAcquisJour", label: "Crédit Acquis Jour" },
    { id: 4, value: "creditAcquisCumul", label: "Crédit Acquis Cumul" },
    { id: 5, value: "valeurRegularisation", label: "Valeur Régularisation" },
    { id: 6, value: "dateRegularisation", label: "Date Régularisation" },
    { id: 7, value: "utilisateurRegularisation", label: "Utilisateur Régularisation" },
];

const operateurOptions = [
    { id: 1, value: "egale", label: "Egale" },
    { id: 2, value: "superieur", label: "Supérieure" },
    { id: 3, value: "inferieur", label: "Inférieure" }
];

const defaultValues = {
    colonne: "",
    operateur: "egale",
    date: null,
    number: 0,
    txt: ""
};

const FiltreCreditsForm = (props) => {
    const { setOpen } = props;
    const [utilisateursRegularisation, setUtilisateursRegularisation] = useState([])
    const stateCredits = useSelector(selectCreGesTem); 
    const methods = useForm({ mode: "all", defaultValues: defaultValues, resolver: joiResolver(schemaFilterCredits) });
    const colonne = methods.watch("colonne");
    const dispatch = useDispatch();

    useEffect(() => {
        if(colonne === "date" || colonne === "dateRegularisation") {
            methods.resetField("date", moment());
        }
        if(colonne === "creditEnCours" || colonne === "creditAcquisJour" || colonne === "creditAcquisCumul" || colonne === "valeurRegularisation") {
            methods.resetField("number", 0);
        }
        if(colonne === "utilisateurRegularisation") {
            methods.resetField("txt", "");
        }
    }, [colonne, methods]);

    useEffect(() => {
        const result = tidy(stateCredits.credits, expand("utilisateurRegularisation"), rename({utilisateurRegularisation: "value"}), mutate({id: (d, id) => id, label: (d) => d.value}), filter(d => d.value !== ""));
        setUtilisateursRegularisation(result);
    }, [stateCredits.credits]);

    const filtrer = useCallback((data) => {
        setOpen(false);
        const creditsFiltered = filtrerCreGesTem(data, stateCredits.credits);
        dispatch(creGesTemActions.filtrer(creditsFiltered));
    }, [dispatch, setOpen, stateCredits.credits]);
    
    return (
        <FormProvider {...methods}>
            <Stack spacing={1}>
                <SelectHookFormInput name="colonne" label="Colonnes" options={colonneOptions} />
                {{
                    date: 
                        <>
                            <SelectHookFormInput name="operateur" label="Opérateur" options={operateurOptions} />
                            <DateHookFormInput name="date" label="Date" />
                        </>,
                    dateRegularisation: 
                        <>
                            <SelectHookFormInput name="operateur" label="Opérateur" options={operateurOptions} />
                            <DateHookFormInput name="date" label="Date de régularisation" />
                        </>,
                    creditEnCours: 
                        <>
                            <SelectHookFormInput name="operateur" label="Opérateur" options={operateurOptions} />
                            <NumberHookFormInput name="number" label="Crédit En Cours" />
                        </>,
                    creditAcquisJour: 
                        <>
                            <SelectHookFormInput name="operateur" label="Opérateur" options={operateurOptions} />
                            <NumberHookFormInput name="number" label="Crédit Acquis Jour" />
                        </>,
                    creditAcquisCumul: 
                        <>
                            <SelectHookFormInput name="operateur" label="Opérateur" options={operateurOptions} />
                            <NumberHookFormInput name="number" label="Crédit Acquis Cumum" />
                        </>,
                    valeurRegularisation: 
                        <>
                            <SelectHookFormInput name="operateur" label="Opérateur" options={operateurOptions} />
                            <NumberHookFormInput name="number" label="Valeur Régularisation" />
                        </>,
                    utilisateurRegularisation: <SelectHookFormInput name="txt" label="Utilisateur Régularisation" options={utilisateursRegularisation} />
                }[colonne]}
            </Stack>
            <Box sx={styles.boxButtonsMenuFiltre}>
                <Stack direction="row" spacing={1}>
                    <Tooltip title="Valider">
                        <IconButton sx={styles.buttonStyleGreen} onClick={methods.handleSubmit(filtrer)}>
                            <Check fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Fermer">
                        <IconButton sx={styles.buttonStyleRed} onClick={() => setOpen(false)}>
                            <Close fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Box>
        </FormProvider>
    );
}

FiltreCreditsForm.defaultProps = {
    setOpen: () => {}
}

FiltreCreditsForm.propTypes = {
    setOpen: PropTypes.func
}

const styles = {
    boxButtonsMenuFiltre: {
        justifyContent: "center",
        display: "flex"
    },
    buttonStyleGreen: {
        boxShadow: "none", 
        backgroundColor: greenColor["02"],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: greenColor["03"],
            color: whiteColor[9],
        }
    },
    buttonStyleRed: {
        boxShadow: "none", 
        backgroundColor: redColor[1],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: redColor[2],
            color: whiteColor[9],
        }
    }
}

export default FiltreCreditsForm;