import { useCallback, useState } from "react";
import { Typography, Box, Stack, TextField } from "@mui/material";
import { useController, useFormContext, useFormState } from "react-hook-form";
import { DatePicker, LocalizationProvider, PickersDay, frFR } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";

const CustomPickersDay = styled(PickersDay, {shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered'})(({ isSelected, isHovered, day }) => ({
    borderRadius: 0,
    ...(isSelected && {
        backgroundColor: greenColor["025"],
        '&:hover, &:focus': {
            backgroundColor: greenColor["025"],
        },
    }),
    ...(isHovered && {
        backgroundColor: greenColor["025"],
        '&:hover, &:focus': {
            backgroundColor: greenColor["025"],
        },
    }),
    ...(day.day() === 0 && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
    ...(day.day() === 1 && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
}));

const isInSameWeek = (dayA, dayB) => {
    if (dayB == null) {
        return false;
    }
  
    return dayA.isSame(dayB, 'week');
};

const Day = (props) => {
    const { day, selectedDay, hoveredDay, ...other } = props;
  
    return (
        <CustomPickersDay
            {...other}
            day={day}
            sx={{ px: 2.5 }}
            disableMargin
            selected={false}
            isSelected={isInSameWeek(day, selectedDay)}
            isHovered={isInSameWeek(day, hoveredDay)}
        />
    );
}

const InputPicker = (props) => {
    const { date, ...other } = props;

    return (
        <TextField
            {...other}
            value={date.format("[Semaine] : ww, [Année] : YYYY")}
        />
    );
}

const WeekHookFormInput = (props) => {
    const { name, label, date, minDate, defaultMonth, displayError, action } = props;
    const { control } = useFormContext();
    const { field } = useController({ name, control });
    const { errors } = useFormState({ control });
    const [open, setOpen] = useState(false);
    const [hoveredDay, setHoveredDay] = useState(null);

    const handleChange = useCallback((date) => {
        action(date)
        field.onChange(date)
    }, [action, field])

    return (
        <Stack sx={styles.stackInputStyle}>
            <Box sx={styles.boxInputStyle}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"fr"} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <DatePicker 
                        {...field}
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        label={label} 
                        displayWeekNumber
                        minDate={minDate} 
                        views={["year", "month", "day"]}
                        showDaysOutsideCurrentMonth
                        value={date}
                        defaultCalendarMonth={defaultMonth}
                        slots={{ day: Day, textField: InputPicker }}
                        slotProps={{ 
                            textField: { 
                                fullWidth: true,
                                size: "small", 
                                readOnly: true, 
                                error: errors[name],
                                date: date,
                                onClick: () => setOpen(true) 
                            },
                            day: (ownerState) => ({
                                selectedDay: date,
                                hoveredDay,
                                onPointerEnter: () => setHoveredDay(ownerState.day),
                                onPointerLeave: () => setHoveredDay(null),
                            }), 
                        }}
                        onChange={handleChange}
                    />
                </LocalizationProvider>
            </Box>
            {(errors[name] && displayError) && 
                <Box sx={styles.boxInputErrorStyle}>
                    <Typography sx={styles.typoInputErrorStyle}>{errors[name].message}</Typography>
                </Box>
            }
        </Stack>
    );
}

WeekHookFormInput.defaultProps = {
    date: null,
    minDate: null,
    defaultMonth: null,
    readOnly: false,
    displayError: false,
    action: () => {}
}

WeekHookFormInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    date: PropTypes.object,
    minDate: PropTypes.object,
    defaultMonth: PropTypes.object,
    readOnly: PropTypes.bool,
    displayError: PropTypes.bool,
    action: PropTypes.func
}

const styles = {
    stackInputStyle: {
        borderRadius: 2,
        backgroundColor: redColor[1]
    },
    boxInputStyle: {
        borderRadius: 2,
        backgroundColor: whiteColor[9]
    },
    boxInputErrorStyle: {
        textAlign: "left",
        borderBottomLeftRadius: 8, 
        borderBottomRightRadius: 8,
        padding: 1
    },
    typoInputErrorStyle: {
        fontSize: "small", 
        color: greyColor[5]
    }
}

export default WeekHookFormInput;