import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    widgets: [],
    widgetsConfiguration: [],
    widgetsActives: [],
    widgetsNonActives: [],
    shortcuts: [],
    shortcutsConfiguration: [],
    shortcutsActives: [],
    shortcutsNonActives: []
}

const dshSlice = createSlice({
    name: 'dsh',
    initialState: initialState,
    reducers: {
        setDashboard: (state, action) => {
            state.widgets = action.payload.widgets;
            state.widgetsConfiguration = action.payload.widgets;
            state.widgetsActives = action.payload.widgetsActives;
            state.widgetsNonActives = action.payload.widgetsNonActives;
            state.shortcuts = action.payload.shortcuts;
            state.shortcutsConfiguration = action.payload.shortcuts;
            state.shortcutsActives = action.payload.shortcutsActives;
            state.shortcutsNonActives = action.payload.shortcutsNonActives;
        },
        editWidget: (state, action) => {
            state.widgetsConfiguration = action.payload.widgetsConfiguration;
            state.widgetsActives = action.payload.widgetsActives;
            state.widgetsNonActives = action.payload.widgetsNonActives;
        },
        editShortcut: (state, action) => {
            state.shortcutsConfiguration = action.payload.shortcutsConfiguration;
            state.shortcutsActives = action.payload.shortcutsActives;
            state.shortcutsNonActives = action.payload.shortcutsNonActives;
        }
    },
})

const { actions, reducer } = dshSlice
export const { setDashboard, editWidget, editShortcut } = actions
export default reducer