import axios from "axios";
import { urls } from "@constants/appConstants";

export const editHrl = async (horloges, token) => {
    const headers = {
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostHorloges", 
        "HttpToken": token
    };
    const body = {
        horloges: {
            list: horloges
        }
    };
    
    return axios.post(urls.postHorloges, body, { headers: headers });
}