import { useSelector } from "react-redux";
import { Box, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { selectPlaHor } from "@selectors";
import { whiteColor } from "@styles";

const DateBar = (props) => {
    const { data, isLoading } = props;
    const statePlagesHoraires = useSelector(selectPlaHor);
    const dateMoment = moment(statePlagesHoraires.datePlagesHoraires, "DDMMYYYY");
    const jourString = `${dateMoment.format("dddd").substring(0, 1).toUpperCase()}${dateMoment.format("dddd").substring(1)}`;
    const jour = dateMoment.format("D");
    const mois = dateMoment.format("MMMM");
    const annee = dateMoment.format("YYYY");
    const semaine = dateMoment.format("ww");

    if(isLoading) {
        return <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={"100%"} height={35.5} />;
    }

    return (
        <Stack direction="row" sx={styles.boxBandeauDateStyle}>
            <Box flexGrow={1}>
                <Typography fontSize={"small"} fontWeight="bold">{jourString} {jour} {mois} {annee} - semaine {semaine}</Typography>
            </Box>
            {data?.infos?.libelleFerie && 
                <Tooltip title={data?.infos?.libelleFerie}> 
                    <Info color="info" fontSize="small" sx={{cursor: "pointer"}} />
                </Tooltip>   
            }
        </Stack>
    );
}

DateBar.defaultProps = {
    data: null,
    isLoading: true
}

DateBar.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool
}

const styles = {
    boxBandeauDateStyle: {
        backgroundColor: whiteColor[5], 
        paddingX: 1,
        paddingY: 1,
        borderRadius: 2,
    }
}

export default DateBar;