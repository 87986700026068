import { IconButtonBase } from "@components/Buttons";
import { HeaderPanelContainer } from "@components/Containers";
import { Close, DoneAll, FilterList, FilterListOff, Info, Refresh, RemoveDone, ViewList, ViewModule } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useMedia } from "react-use";
import { useTranslation } from "react-i18next";

const HistoriqueDemandesActionsBar = ({ 
    isLoading = true, 
    isError = false, 
    listed = false,
    filtered = false,
    historique = [],
    demandesChecked = [],
    allChecked = false,
    setOpenFiltre = () => {},
    toggleList = () => {},
    deleteFiltre = () => {},
    selectAllDemandes = () => {},
    selectDemandesChecked = () => {},
    refetch = () => {}
}) => {
    const isMobile = useMedia('(max-width: 950px)');
    const { t } = useTranslation("global");

    return (
        <HeaderPanelContainer 
            actionsLeft={
                <>
                    <IconButtonBase 
                        variant="white5Grey9White9" 
                        icon={filtered ? <FilterListOff fontSize="small" /> : <FilterList fontSize="small" />} 
                        title={filtered ? t("buttons.delete_filter") : t("buttons.filter")} 
                        disabled={isLoading || isError}
                        action={filtered ? () => deleteFiltre() : () => setOpenFiltre(true)} 
                    /> 
                    {historique.length > 0 && 
                        <IconButtonBase 
                            variant="white5Grey9White9"
                            icon={allChecked ? <RemoveDone fontSize="small" /> : <DoneAll fontSize="small" />} 
                            title={allChecked ? t("buttons.uncheck_all") : t("buttons.check_all")} 
                            disabled={isLoading || isError}
                            action={() => selectAllDemandes()} 
                        />
                    }
                    {demandesChecked.length > 0 && 
                        <>
                            <IconButtonBase 
                                variant="white5Grey9White9"
                                title={t("buttons.details_absence_selected")} 
                                icon={<Info fontSize="small" />} 
                                disabled={isLoading || isError}
                                action={() => selectDemandesChecked("H")} 
                            />
                            <IconButtonBase 
                                variant="white5Grey9White9"
                                title={t("buttons.delete_absence_selected")} 
                                icon={<Close fontSize="small"/>} 
                                disabled={isLoading || isError}
                                action={() => selectDemandesChecked("D")} 
                            />
                        </>
                    }
                </> 
            }
            actionsRight={
                <>
                    {(!isMobile) && 
                        <IconButtonBase 
                            variant="white5Grey9White9"
                            icon={listed ? <ViewModule fontSize="small" /> : <ViewList fontSize="small" />} 
                            title={listed ? t("buttons.panels") : t("buttons.list")} 
                            disabled={isLoading || isError}
                            action={() => toggleList()} 
                        />
                    }
                    <IconButtonBase 
                        variant="white5Grey9White9"
                        icon={<Refresh fontSize="small" />} 
                        title={t("buttons.refresh")} 
                        disabled={isLoading}
                        action={() => refetch()} 
                    />
                </>
            }
            title={t("titles.absence_history")}
            variant="green07"
        />
    )
}


HistoriqueDemandesActionsBar.defaultProps = {
    isLoading: true, 
    isError: false, 
    listed: false,
    filtered: false,
    historique: [],
    demandesChecked: [],
    allChecked: false,
    setOpenFiltre: () => {},
    toggleList: () => {},
    deleteFiltre: () => {},
    selectAllDemandes: () => {},
    selectDemandesChecked: () => {},
    refetch: () => {} 
}

HistoriqueDemandesActionsBar.propTypes = {
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool, 
    listed: PropTypes.bool,
    filtered: PropTypes.bool,
    historique: PropTypes.array,
    demandesChecked: PropTypes.array,
    allChecked: PropTypes.bool,
    setOpenFiltre: PropTypes.func,
    toggleList: PropTypes.func,
    deleteFiltre: PropTypes.func,
    selectAllDemandes: PropTypes.func,
    selectDemandesChecked: PropTypes.func,
    refetch: PropTypes.func
}

export default HistoriqueDemandesActionsBar;