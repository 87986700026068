import { useState } from "react";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import PropTypes from "prop-types";
import IconButtonBase from "./IconButtonBase";

const VARIANTS = /** @type {const} */([
    "white9Green7", 
    "white5Grey9", 
    "white5Red5", 
    "white5Red9White9", 
    "white5Green9White9", 
    "white5Grey9White9",
    "green2Grey9Green5"
]);

const PLACEMENTS = /** @type {const} */([
    "top", 
    "bottom", 
    "left", 
    "right"
]);

const SIZES = /** @type {const} */([
    "large", 
    "medium", 
    "small"
]);

/**
 * IconButtonBase properties
 * 
 * @typedef {object} IconButtonProps
 * @property {string} itemIdReference - Id menu items
 * @property {React.ReactElement} icon - Icône du bouton
 * @property {string} title - Texte de la tooltip
 * @property {typeof VARIANTS[number]} variant - Variante de couleur
 * @property {typeof PLACEMENTS[number]} placement - Emplacement de la tooltip
 * @property {typeof SIZES[number]} size - Taille du bouton
 * @property {boolean} disabled - Désactiver le bouton
 * @property {array} options - Menu options
 */

/** 
 * IconButtonBase component
 * 
 * @type {React.FC<IconButtonProps>} props
 * @returns {React.ReactElement} Retour du bouton
 */
const MenuButton = ({
    itemIdReference = "", 
    icon = null, 
    title = "", 
    variant = undefined, 
    placement = "bottom", 
    size = "small", 
    disabled = false,
    options = [], 
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return ( 
        <>
            <IconButtonBase 
                variant={variant}
                icon={icon || <MoreVert fontSize={size} />} 
                title={title} 
                placement={placement}
                disabled={disabled}
                action={handleOpen} 
            />
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose} onClick={handleClose} slotProps={{ paper: { sx: { borderRadius: 2 } } }}>
                {options.map((option) => (
                    <MenuItem key={`${itemIdReference}${option.id}`} onClick={option.action}>
                        {option.icon && <ListItemIcon>
                            {option.icon}
                        </ListItemIcon>}
                        <ListItemText>{option.label}</ListItemText>
                    </MenuItem>
                ))}
            </Menu>
      </> 
    );
}

MenuButton.propTypes = {
    itemIdReference: PropTypes.string, 
    icon: PropTypes.element, 
    title: PropTypes.string, 
    variant: PropTypes.string, 
    placement: PropTypes.string, 
    size: PropTypes.string, 
    disabled: PropTypes.bool, 
    options: PropTypes.array, 
}

export default MenuButton;