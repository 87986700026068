import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { distinct, filter, map, select, tidy } from "@tidyjs/tidy";
import { Box, Grid, IconButton, Stack } from "@mui/material";
import { Check, Close, FilterListOff } from "@mui/icons-material";
import PropTypes from "prop-types";
import * as colCreGesTemActions from "@reducers/collaborateurs/colCreGesTemReducer";
import { useStateCollaborateurs } from "@hooks/collaborateurs/hooksStates";
import { AutocompleteInput, AutocompleteTableInput } from "@components/Inputs";
import { filtrerFwdc01 } from "../utils";
import { TYPES_COLLABORATEURS } from "@constants/collaborateursConstants";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";
import { MenuFiltreContainerMobile } from "@components/Containers";

const defaultValues = {
    matricule: null,
    nom: null,
    badge: null,
    societe: null,
    service: null,
    typePersonnel: null
};

const headerOptions = [
    {id: 1, title: "Code", xs: 2, key: "value"},
    {id: 2, title: "Désignation", xs: 10, key: "libelle"}
];

const optionsBooleens = [
    {id: 1, value: "P", label: "Salarié"},
    {id: 2, value: "I", label: "Intérimaire"}
];

const labelsMapping = (option) => option.libelle ? `${option.value} - ${option.libelle}` : "";

const CollaborateursFwdc01Menu = (props) => {
    const { type, open, close } = props;
    const dispatch = useDispatch();
    const methods = useForm({ mode: "all", defaultValues: defaultValues });
    const stateCollaborateurs = useStateCollaborateurs(type);
    const [noms, setNoms] = useState([]);
    const [matricules, setMatricules] = useState([]);
    const [badges, setBadges] = useState([]);
    const [societes, setSocietes] = useState([]);
    const [services, setServices] = useState([]);

    useEffect(() => {
        if(stateCollaborateurs.collaborateurs) {
            const nomsOptions = tidy(stateCollaborateurs.collaborateurs, select("prenomNom"), map((d, id) => ({id: id + 1, value: d.prenomNom, label: d.prenomNom})), filter(d => d.prenomNom !== ""));
            const matriculesOptions = tidy(stateCollaborateurs.collaborateurs, select("matricule"), map((d, id) => ({id: id + 1, value: d.matricule, label: d.matricule})), filter(d => d.matricule !== ""));
            const badgesOptions = tidy(stateCollaborateurs.collaborateurs, select("badge"), map((d, id) => ({id: id + 1, value: d.badge, label: d.badge})), filter(d => d.badge !== ""));
            const societesOptions = tidy(stateCollaborateurs.collaborateurs, select(["codeSociete", "libelleSociete"]), map((d, id) => ({id: id + 1, value: d.codeSociete, libelle: d.libelleSociete})), distinct(["value", "libelle"]), filter(d => d.value !== ""));
            const servicesOptions = tidy(stateCollaborateurs.collaborateurs, select(["codeService", "libelleService"]), map((d, id) => ({id: id + 1, value: d.codeService, libelle: d.libelleService})), distinct(["value", "libelle"]), filter(d => d.value !== ""));
            setNoms(nomsOptions);
            setMatricules(matriculesOptions);
            setBadges(badgesOptions);
            setSocietes(societesOptions);
            setServices(servicesOptions);
        }
    }, [stateCollaborateurs.collaborateurs]);

    const filtrer = useCallback((data) => {
        const collaborateursBrut = stateCollaborateurs.collaborateurs;
        const collaborateursFiltered = filtrerFwdc01(data, collaborateursBrut);

        if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_CREDIT) {
            dispatch(colCreGesTemActions.filtrer(collaborateursFiltered));
        }
    }, [dispatch, stateCollaborateurs.collaborateurs, type]);

    const resetFiltre = useCallback(() => {
        methods.reset();
        if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_CREDIT) {
            dispatch(colCreGesTemActions.resetFiltre());
        }
    }, [dispatch, methods, type])

    return(
        <MenuFiltreContainerMobile open={open} close={close}>
            <FormProvider {...methods}>
                <Box overflow="auto">
                    <Grid container spacing={0.5} pb={0.5}>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteInput name={"nom"} label="Nom" options={noms} labelized />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteInput name={"matricule"} label="Matricule" options={matricules} labelized />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteInput name={"badge"} label="Badge" options={badges} labelized />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteTableInput name={"societe"} label="Societe" options={societes} headerOptions={headerOptions} labelsMapping={labelsMapping} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteTableInput name={"service"} label="Service" options={services} headerOptions={headerOptions} labelsMapping={labelsMapping} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteInput name={"typePersonnel"} label="Type du personnel" options={optionsBooleens} labelized />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Stack spacing={0.5} direction="row" borderRadius={2} width={"100%"} height={"100%"} display="flex" alignItems="center" justifyContent="center">
                        <IconButton sx={styles.buttonStyleGrey} onClick={() => resetFiltre()}>
                            <FilterListOff fontSize="small" />
                        </IconButton>
                        <IconButton sx={styles.buttonStyleGreen} onClick={() => methods.handleSubmit(filtrer)()}>
                            <Check fontSize="small" />
                        </IconButton>
                        <IconButton sx={styles.buttonStyleRed} onClick={() => close()}>
                            <Close fontSize="small" />
                        </IconButton>
                    </Stack>
                </Box>
            </FormProvider>
        </MenuFiltreContainerMobile>
    );
}

CollaborateursFwdc01Menu.propTypes = {
    type: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
}

const styles = {
    buttonStyleGrey: {
        boxShadow: "none", 
        backgroundColor: greyColor[0],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: greyColor[2],
            color: whiteColor[9],
        }
    },
    buttonStyleGreen: {
        boxShadow: "none", 
        backgroundColor: greenColor["02"],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: greenColor["03"],
            color: whiteColor[9],
        }
    },
    buttonStyleRed: {
        boxShadow: "none", 
        backgroundColor: redColor[1],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: redColor[2],
            color: whiteColor[9],
        }
    }
}

export default CollaborateursFwdc01Menu;