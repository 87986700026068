import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import { selectPlaHrc } from "@selectors";
import { usePlaHrc } from "@hooks/planning/hooksQueries";
import { DialogContainer } from "@components/Containers";
import PlanningContainer from "../../Containers/PlanningContainer";
import { TYPES_PLANNING } from "@constants/planningConstants";
import { HierarchieActions } from "./Actions";

const HierarchieDialog = (props) => {
    const { open, setOpen, openJour } = props;
    const statePlaHrc = useSelector(selectPlaHrc);    
    const [affichage, setAffichage] = useState("V");
    const [date, setDate] = useState(""); 
    const { isLoading, isFetching, isError, error, refetch } = usePlaHrc(date, affichage, open);

    useEffect(() => {
        if(open) {
            setDate(statePlaHrc.date)
        }
    }, [open, statePlaHrc.date]);

    const precedent = useCallback(async () => {
        const dateNow = moment(date, "DDMMYYYY");
        setDate(dateNow.add(-1, "week").format("DDMMYYYY"));
    }, [date]);
    
    const suivant = useCallback(async () => {
        const dateNow = moment(date, "DDMMYYYY");
        setDate(dateNow.add(+1, "week").format("DDMMYYYY"));
    }, [date]);

    const selectDate =  useCallback((date) => {
        setDate(date.format("DDMMYYYY"));
    }, []);

    const toggleAffichage = useCallback(() => {
        const nextAffichage = affichage === "T" ? "V" : "T";
        setAffichage(nextAffichage);
    }, [affichage]);

    return (
        <DialogContainer open={open} placement="top" taille="md">
            <HierarchieActions 
                isLoading={isLoading || isFetching}
                isError={isError}
                setOpen={setOpen}
                affichage={affichage}
                precedent={precedent}
                suivant={suivant}
                selectDate={selectDate}
                refetch={refetch}
                toggleAffichage={toggleAffichage}
            />
            <PlanningContainer 
                isLoading={isLoading || isFetching}
                isError={isError}
                errorMessage={error?.message}
                type={TYPES_PLANNING.HIERARCHIE} 
                maxHeight={"calc(100vh - 221px)"} 
                displayEmetteur={false} 
                emetteur={false} 
                hierarchie={true} 
                displayTotaux={false} 
                jourCourt={true} 
                openJour={openJour} 
            />
        </DialogContainer>
    );
}

HierarchieDialog.defaultProps = {
    open: false, 
    setOpen: () => {},
    openJour: () => {}
}

HierarchieDialog.propTypes = {
    open: PropTypes.bool, 
    setOpen: PropTypes.func,
    openJour: PropTypes.func
}

export default HierarchieDialog;