import { useCallback, useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { Event } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { useStateTemGesTem } from "@hooks/gestionTemps/temps/hooksStates";
import { useHisTraTemGesTem } from "@hooks/gestionTemps/transactions/hooksQueries";
import { DialogFullScreenContainer, HeaderDialogContainer, TextContainer } from "@components/Containers";
import { TransactionsListMobile } from "@components/App/Transactions";
import { DatePickerButtonMobile } from "@components/Buttons";
import { greenColor } from "@styles";

const HistoriqueTransactionsDialogMobile = (props) => {
    const { open, setOpen, openAnomalies, userType } = props;
    const [date, setDate] = useState();
    const stateTemps = useStateTemGesTem(userType);
    const { data, isLoading, isFetching, isError, error } = useHisTraTemGesTem(userType, date, open);

    useEffect(() => {
        if(open) {
            setDate(stateTemps.dateTemps);
        }
    }, [open, stateTemps.dateTemps]);

    const selectDate =  useCallback((date) => {
        setDate(date.format("DDMMYYYY"));
    }, []);

    const close = useCallback(() => {
        setOpen(false);
        setDate("")
    }, [setOpen]);

    return (
        <DialogFullScreenContainer open={open} placement="top" taille="lg">
            <HeaderDialogContainer 
                actionsLeft={
                    <DatePickerButtonMobile icon={<Event fontSize="small" />} title="Choisir une date" timeout={600} action={selectDate} value={moment(date, "DDMMYYYY")} />
                }
                title={`Historique des transactions`}
                close={() => close()}
            />
            <Stack spacing={0.5}>
                <TextContainer bgColor={greenColor["015"]} p={1} textWeight="bold">Historique à partir du {moment(date, "DDMMYYYY").format("dddd DD MMMM YYYY")}</TextContainer>
                <Box overflow={"auto"} maxHeight={"calc(100vh - 167px)"}>
                    <TransactionsListMobile 
                        data={data} 
                        isLoading={isLoading || isFetching} 
                        isError={isError} 
                        errorMessage={error?.message} 
                        openAnomalies={openAnomalies}
                    />
                </Box>
            </Stack>
        </DialogFullScreenContainer>
    );
}

HistoriqueTransactionsDialogMobile.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    openAnomalies: PropTypes.func.isRequired,
    userType: PropTypes.string.isRequired
}

export default HistoriqueTransactionsDialogMobile;