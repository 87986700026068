import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const WidgetError = (props) => {
    const { error } = props;

    return(
        <Box sx={styles.boxErrorStyle}>
            <Typography fontSize="small">{error}</Typography>
        </Box>
    );
}

WidgetError.defaultProps = {
    error: ""
}

WidgetError.propTypes = {
    error: PropTypes.string
}

const styles = {
    boxErrorStyle: {
        backgroundColor: "rgba(255,0,0,0.1)",
        borderRadius: 2,
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        height: "100%"
    }
};

export default WidgetError;
