import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    typesAbsence: [],
    typeAbsenceSelected: null,
    formData: null,
    data: null,
    component: "",
    validationDemande: "N",
    validationContrainte: "N",
    validationCondition: "N",
    afficherCondition: "N",
    premierAppel: true,
    loading: true,
}

const dabGesAbsColSlice = createSlice({
    name: 'dabGesAbsCol',
    initialState: initialState,
    reducers: {
        setTypesAbsence: (state, action) => {
            state.typesAbsence = action.payload;
        },
        reset: () => initialState
    },
})

const { actions, reducer } = dabGesAbsColSlice
export const { 
    setTypesAbsence,
    reset
} = actions
export default reducer