import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query"
import { useSnackbar } from "notistack";
import { fetchDab, fetchDetDab, addDab, editRepDab, fetchDetDabMan } from "@api/demandesAbsence";
import { fetchDan, addDan } from "@api/demandesAnnulationsAbsence";
import { addPjtDab } from "@api/file";
import { fetchCntDab } from "@api/contraintes";
import { fetchTypAbs } from "@api/typesAbsence";
import { selectCltApp, selectUsrApp } from "@selectors";
import * as dabGesAbsColActions from "@reducers/gestionAbsences/demandes/dabGesAbsColReducer";
import * as dabGesAbsManActions from "@reducers/gestionAbsences/demandes/dabGesAbsManReducer";
import { fileToData } from "@integrations";

export const useTypAbsInp = (date, open) => {
    const dispatch = useDispatch();
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);

    const query = useQuery(["typAbsInp", date], ({ signal }) => fetchTypAbs(stateUser.matricule, date, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!date && open
    });

    useEffect(() => {
        if(query.data) {
            dispatch(dabGesAbsColActions.setTypesAbsence(query.data));
        }
    }, [dispatch, query.data]);

    return query;
}

export const useDabGesAbsMan = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const dispatch = useDispatch();
    
    const query = useQuery(["dabMan"], ({ signal }) => fetchDab(stateUser.matricule, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if(query.data) {
            dispatch(dabGesAbsManActions.setDemandesAbsence(query.data));
        }
    }, [dispatch, query.data]);

    return query;
}

export const useDabGesAbsPos = () => {
    const stateClient = useSelector(selectCltApp);
    const stateUser = useSelector(selectUsrApp);
    const { data, isLoading: isLoadingDemande, isError, error, mutateAsync: mutateAsyncDemande } = useMutation(addDab);
    const { isLoading: isLoadingFile, mutateAsync: mutateAsyncFile } = useMutation(addPjtDab);
    const { enqueueSnackbar } = useSnackbar();

    const postDemAbsFor = async (data, validationDemande = "N", validationContrainte = "N", validationCondition = "N") => {
        let isSucceed = false;
        let codeErreur = 0;
        let nombreJour = 0;
        let refDate = "";
        let refHeure = "";
        const ampm = data.ampm ? "PM" : "AM"
        const dataFormated = {
            matricule: stateUser.matricule, 
            type: data?.typeAbsence?.codeAbsence,
            dateDebut: data?.dateDebut?.isValid() ? data.dateDebut.format("DDMMYYYY") : "00000000", 
            dateReprise: data?.dateReprise?.isValid() ? data.dateReprise.format("DDMMYYYY") : "00000000",
            ampm: data.type === "ampm" ? ampm : "",
            heureDebut: data?.heureDebut?.isValid() ? data.heureDebut.format("HHmm") : "0000",
            heureReprise: data?.heureReprise?.isValid() ? data.heureReprise.format("HHmm") : "0000",
            nombreHeures: data?.nombreHeures?.isValid() ? data.nombreHeures.format("HHmm") : "0000",
            motifDemande: data.motif,
            validationDemande: validationDemande,
            validationContrainte: validationContrainte,
            validationCondition: validationCondition,
            codeAbsence: data?.typeAbsence?.codeAbsence,
            libelleAbsence: data?.typeAbsence?.libelleAbsence,
            client: stateClient.client
        }
        try {
            const response = await mutateAsyncDemande(dataFormated);
            isSucceed = true;
            nombreJour = parseFloat(response.nombreJours);
            refDate = response.referenceDemandeDate;
            refHeure = response.referenceDemandeHeure;
        } catch(e) {
            codeErreur = e?.response?.data?.codeRetour;
            refDate = e?.response?.data?.referenceDemandeDate;
            refHeure = e?.response?.data?.referenceDemandeHeure;
        }

        return { isSucceed, nombreJour, codeErreur, refDate, refHeure }
    } 

    const postDemAbsPj = async (data, referenceDemandeDate, referenceDemandeHeure) => {
        const formatedFile = data.fichier ? await fileToData(data.fichier) : null;
        if(formatedFile) {
            const nom = formatedFile.nom;
            const contenu = formatedFile.contenu
            const dataFormated = {
                matricule: stateUser.matricule, 
                referenceDemandeDate: referenceDemandeDate, 
                referenceDemandeHeure: referenceDemandeHeure, 
                nomFichier: nom, 
                contenuFichier: contenu, 
                client: stateClient.client
            }
            try {
                await mutateAsyncFile(dataFormated);
            } catch(e) {
                enqueueSnackbar("Erreur lors de l'envoi de la pièce jointe.", { variant: "error" });
            }
        }
    } 

    return { data, isLoadingDemande, isLoadingFile, isError, error, postDemAbsFor, postDemAbsPj };
}

export const useCntDabGesAbs = (societe, service, contrainte) => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    
    return useQuery(["cntDabGesAbs"], ({ signal }) => fetchCntDab(stateUser.matricule, societe, service, contrainte, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!societe && !!service && !!contrainte
    });
}

export const useDanAbsGesAbs = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);

    return useQuery(["danAbsGesAbs"], ({ signal }) => fetchDan(stateUser.matricule, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false
    });
}

export const useDanAbsGesAbsPos = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const { data, isLoading, isError, error, mutateAsync } = useMutation(addDan);
    const { enqueueSnackbar } = useSnackbar();

    const postRecapitulatifAnnulation = async (demandesSelected) => {
        let isSucceed = false;
        const dataFormated = {
            matricule: stateUser.matricule,
            validation: "0",
            absencesSelectionnees: demandesSelected,
            motif: "",
            client: stateClient.client
        }
        try {
            await mutateAsync(dataFormated);
            isSucceed = true;
        } catch(e) {
            enqueueSnackbar(e?.response?.data?.details, { variant: "error"});
        }

        return { isSucceed };
    }
    
    const postDemandeAnnulation = async (demandesSelected, motif) => {
        let isSucceed = false;
        const dataFormated = {
            matricule: stateUser.matricule,
            validation: "1",
            absencesSelectionnees: demandesSelected,
            motif: motif,
            client: stateClient.client
        }
        try {
            await mutateAsync(dataFormated);
            isSucceed = true;
            enqueueSnackbar("Demande d'annulation envoyée avec succès.", { variant: "success"});
        } catch(e) {
            enqueueSnackbar("Erreur lors de l'envoi de la demande d'annulation.", { variant: "error"});
        }

        return { isSucceed };
    }

    return { data, isLoading, isError, error, postDemandeAnnulation, postRecapitulatifAnnulation };
}

export const useDabGesAbsDet = (matricule, date, heure, open) => {
    const stateClient = useSelector(selectCltApp);

    return useQuery(['dabGesAbsDet', matricule, date, heure], ({ signal }) => fetchDetDab(matricule, date, heure, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!matricule && !!date && !!heure && open
    });
}

export const useDabGesAbsDetMan = (matricule, date, heure, open) => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);

    return useQuery(['dabGesAbsDetMan', matricule, date, heure], ({ signal }) => fetchDetDabMan(stateUser.matricule, matricule, date, heure, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!matricule && !!date && !!heure && open
    });
}

export const useDabGesAbsRep = () => {
    const { isLoading, mutateAsync } = useMutation(editRepDab)
    const { enqueueSnackbar } = useSnackbar();

    const postReponseDemandeAbsenceActionner = async (data) => {
        let isSucceed = false;
        let codeErreur = 0;
        let details = "";
        let libelleContrainte = "";
        try {
            const response = await mutateAsync(data);
            isSucceed = response.succeed;
            codeErreur = response.codeRetour;
            details = response.details;
            libelleContrainte = response.libelleContrainte;
        } catch(e) {
            enqueueSnackbar("Une erreur est survenue lors de l'envoi de la réponse", { variant: "error" });
        }

        return { isSucceed, codeErreur, details, libelleContrainte }
    }; 

    return { isLoading, postReponseDemandeAbsenceActionner }
}
