import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack } from "@mui/material";
import moment from "moment";
import { Event, Notes, QueryBuilder, Rule } from "@mui/icons-material";
import { selectDrgGesTem, selectTemGesTemCol, selectUsrApp } from "@selectors";
import * as drgGesTemActions from "@reducers/gestionTemps/regularisations/drgGesTemReducer"
import * as plaHorActions from "@reducers/plagesHoraires/plaHorReducer"
import { greenColor } from "@styles";
import { MenuFiltreContainer, MenuTrieContainer } from "@components/Containers";
import { useDrgQryCol } from "@hooks/gestionTemps/demandesRegularisations/hooksQueries";
import { trierDrgGesTemCol } from "@components/GestionTemps/DemandesRegularisation/tries";
import { DemandesRegularisationActionsBar } from "@components/GestionTemps/DemandesRegularisation/ActionsBars";
import { DemandesRegularisationCollaborateurList } from "@components/GestionTemps/DemandesRegularisation/Lists";
import { FiltreDemandesRegularisationForm } from "@components/GestionTemps/DemandesRegularisation/Forms";
import { DemandeRegularisationDeleteDialog, DemandeRegularisationDetailDialog, DemandeRegularisationDialog, DemandeRegularisationSuiviDialog } from "@components/GestionTemps/DemandesRegularisation/Dialogs";
import { AnomaliesDialog, AnomaliesRegularisationsDialog, AnomaliesTransactionsDialog } from "@components/App/Anomalies";
import { PlagesHorairesDialog } from "@components/App/PlagesHoraires";
import { TYPES_DEMANDE_REGULARISATION } from "@constants/demandesRegularisationConstants";
import { ROLE_UTILISATEUR } from "@constants/userConstants";

const optionsTrie = [
    {id: 1, label: "Par date d'émission", keyAsc: "dateEmissionAsc", keyDesc: "dateEmissionDesc", icon: <Event fontSize="small" />},
    {id: 2, label: "Par heure d'émission", keyAsc: "heureEmissionAsc", keyDesc: "heureEmissionDesc", icon: <QueryBuilder fontSize="small" />},
    {id: 3, label: "Par date de régularisation", keyAsc: "dateRegularisationAsc", keyDesc: "dateRegularisationDesc", icon: <Event fontSize="small" />},
    {id: 4, label: "Par statut", keyAsc: "statutAsc", keyDesc: "statutDesc", icon: <Rule fontSize="small" />},
    {id: 5, label: "Par motif", keyAsc: "motifAsc", keyDesc: "motifDesc", icon: <Notes fontSize="small" />},
];

const DemandesRegularisationMobile = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateDemandesRegularisation = useSelector(selectDrgGesTem);
    const stateTemps = useSelector(selectTemGesTemCol);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openFiltre, setOpenFiltre] = useState(false);
    const [openDetailDialog, setOpenDetailDialog] = useState(false);
    const [openSuiviDialog, setOpenSuiviDialog] = useState(false);
    const [openAnomaliesDialog, setOpenAnomaliesDialog] = useState(false);
    const [openAnomalieRegularisationDialog, setOpenAnomalieRegularisationDialog] = useState(false);
    const [openAnomalieTransactionDialog, setOpenAnomalieTransactionDialog] = useState(false);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [openDemandeRegularisationDialog, setOpenDemandeRegularisationDialog] = useState(false);
    const [openPlagesHorairesDialog, setOpenPlagesHorairesDialog] = useState(false);
    const [regularisation, setRegularisation] = useState(null);
    const [transaction, setTransaction] = useState(null);
    const { isLoading: isLoadingList, isFetching: isFetchingList, isError: isErrorList, refetch: refetchList } = useDrgQryCol(ROLE_UTILISATEUR.COLLABORATEUR, true);
    const dispatch = useDispatch();
    
    const handleOpenRegularisation = useCallback(() => {
        const dateRegularisation = moment(stateTemps?.semaine?.[0]?.date, "DDMMYYYY").isValid() ? stateTemps?.semaine?.[0]?.date : moment().format("DDMMYYYY");
        dispatch(drgGesTemActions.setDateDemandeRegularisation(dateRegularisation));
        setOpenDemandeRegularisationDialog(true);
    }, [dispatch, stateTemps.semaine]);
    
    const handleDetail = useCallback((regularisation) => {
        dispatch(drgGesTemActions.selectDemandeRegularisation(regularisation));
        setOpenDetailDialog(true);
    }, [dispatch]);

    const supprimer = useCallback((regularisation) => {
        dispatch(drgGesTemActions.selectDemandeRegularisation(regularisation));
        setOpenWarningDialog(true);
    }, [dispatch]);

    const openTrie = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const toggleList = useCallback(() => {
        dispatch(drgGesTemActions.lister());
    }, [dispatch]);

    const trier = useCallback((keyTrie) => {
        const data = [...stateDemandesRegularisation.demandesRegularisation];
        const dataTried = trierDrgGesTemCol(keyTrie, data);
        dispatch(drgGesTemActions.trier(dataTried));
    }, [dispatch, stateDemandesRegularisation.demandesRegularisation]);

    const deleteTrie = useCallback(() => {
        dispatch(drgGesTemActions.deleteTrie(ROLE_UTILISATEUR.COLLABORATEUR));
    }, [dispatch]);

    const deleteFiltre = useCallback(() => {
        dispatch(drgGesTemActions.deleteFiltre(ROLE_UTILISATEUR.COLLABORATEUR));
    }, [dispatch]);

    const handleOpenAnomaliesRegularisation = useCallback((r) => {
        setRegularisation(r);
        setOpenAnomalieRegularisationDialog(true);
    }, []);

    const handleOpenAnomaliesTransaction = useCallback((t) => {
        setTransaction(t);
        setOpenAnomalieTransactionDialog(true);
    }, []);

    const handleOpenAnomalies = useCallback(() => {
        setOpenAnomaliesDialog(true);
    }, []);

    const handlePlagesHoraires = useCallback(() => {
        dispatch(plaHorActions.setDatePlagesHoraires(stateDemandesRegularisation.dateDemandeRegularisation));
        setOpenPlagesHorairesDialog(true);
    }, [dispatch, stateDemandesRegularisation.dateDemandeRegularisation])

    return (
        <Stack spacing={1} bgcolor={greenColor["015"]} borderRadius={2} p={0.5} height="100%" boxSizing="border-box">
            <Box height="calc(100% - 54px)">
                <DemandesRegularisationCollaborateurList 
                    data={stateDemandesRegularisation.demandesRegularisationDisplayed}
                    isLoading={isLoadingList || isFetchingList}
                    isError={isErrorList}
                    listed={stateDemandesRegularisation.listed}
                    openDetail={handleDetail}
                    supprimer={supprimer}
                />
            </Box>
            <DemandesRegularisationActionsBar 
                userType={ROLE_UTILISATEUR.COLLABORATEUR}
                isLoading={isLoadingList || isFetchingList} 
                isError={isErrorList} 
                listed={stateDemandesRegularisation.listed} 
                tried={stateDemandesRegularisation.tried}
                filtered={stateDemandesRegularisation.filtered}
                all={stateDemandesRegularisation.all} 
                toggleList={toggleList} 
                openTrie={openTrie} 
                setOpenFiltre={setOpenFiltre} 
                deleteTrie={deleteTrie}
                deleteFiltre={deleteFiltre} 
                openDemandeRegularisation={handleOpenRegularisation}
                refetch={refetchList}
            />
            <MenuTrieContainer 
                anchorEl={anchorEl} 
                setAnchorEl={setAnchorEl} 
                options={optionsTrie} 
                trier={trier} 
            />
            <MenuFiltreContainer open={openFiltre} setOpen={setOpenFiltre}>
                <FiltreDemandesRegularisationForm userType={ROLE_UTILISATEUR.COLLABORATEUR} setOpen={setOpenFiltre} />
            </MenuFiltreContainer>
            <DemandeRegularisationDetailDialog  
                userType={ROLE_UTILISATEUR.COLLABORATEUR} 
                transactions={stateDemandesRegularisation.transactions}
                regularisations={stateDemandesRegularisation.regularisations}
                open={openDetailDialog}  
                setOpen={setOpenDetailDialog}  
                setOpenSuivi={setOpenSuiviDialog} 
                openAnomalieTransaction={handleOpenAnomaliesTransaction} 
                openAnomaliesRegularisation={handleOpenAnomaliesRegularisation} 
                anomaliesDisplay={true}
            />
            <AnomaliesDialog 
                open={openAnomaliesDialog} 
                setOpen={setOpenAnomaliesDialog} 
            />                
            <AnomaliesTransactionsDialog 
                open={openAnomalieTransactionDialog} 
                setOpen={setOpenAnomalieTransactionDialog} 
                transaction={transaction} 
                openAllAnomalies={handleOpenAnomalies} 
            />
            <AnomaliesRegularisationsDialog 
                open={openAnomalieRegularisationDialog} 
                setOpen={setOpenAnomalieRegularisationDialog} 
                regularisation={regularisation} 
                openAllAnomalies={handleOpenAnomalies} 
            />
            <DemandeRegularisationSuiviDialog 
                userType={ROLE_UTILISATEUR.COLLABORATEUR} 
                suivi={stateDemandesRegularisation.suivi}
                open={openSuiviDialog} 
                setOpen={setOpenSuiviDialog} 
            />
            <PlagesHorairesDialog 
                open={openPlagesHorairesDialog} 
                setOpen={setOpenPlagesHorairesDialog} 
                matricule={stateUser.matricule} 
                prenomNom={`${stateUser.nom} ${stateUser.prenom}`} 
            />
            <DemandeRegularisationDialog 
                type={TYPES_DEMANDE_REGULARISATION.REGULARISATION} 
                open={openDemandeRegularisationDialog} 
                setOpen={setOpenDemandeRegularisationDialog} 
                openPlagesHoraires={handlePlagesHoraires} 
                openAnomalies={handleOpenAnomaliesTransaction} 
                refetch={refetchList} 
            />
            <DemandeRegularisationDeleteDialog 
                open={openWarningDialog} 
                setOpen={setOpenWarningDialog} 
                refetch={refetchList} 
            />
        </Stack>
    );
};

export default DemandesRegularisationMobile;