import { useSelector, useDispatch } from "react-redux";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import { selectAffGesTem } from "@selectors";
import * as affGesTemActions from "@reducers/gestionTemps/affectations/affGesTemReducer"
import { TabContentContainer } from "@components/Containers";
import { greenColor, greyColor, whiteColor } from "@styles";
import { AffectationsActivitesCollectif, AffectationsActivitesIndividuel, AffectationsHorairesCollectif, AffectationsHorairesIndividuel } from "@components/GestionTemps/Affectations";

const Affectations = () => {
    const stateAffectations = useSelector(selectAffGesTem);
    const dispatch = useDispatch();

    return (
        <Stack spacing={0.5} borderRadius={2} height="100%" boxSizing="border-box">
            <Box>
                <Tabs value={stateAffectations.activeTab} onChange={(_, tab) => dispatch(affGesTemActions.setActiveTab(tab))}
                    indicatorColor=""
                    sx={styles.tabsStyles} 
                    centered
                >            
                    <Tab label="Horaires : Traitement Individuel" value="AFFHOI" sx={styles.tabStyle} />               
                    <Tab label="Horaires : Traitement Collectif" value="AFFHOC" sx={styles.tabStyle} />               
                    <Tab label="Activites : Traitement Individuel" value="AFFACI" sx={styles.tabStyle} />               
                    <Tab label="Activites : Traitement Collectif" value="AFFACC" sx={styles.tabStyle} />               
                </Tabs>
            </Box>
            <TabContentContainer value={stateAffectations.activeTab} id="AFFHOI">
                <AffectationsHorairesIndividuel />
            </TabContentContainer>
            <TabContentContainer value={stateAffectations.activeTab} id="AFFHOC">
                <AffectationsHorairesCollectif />
            </TabContentContainer>
            <TabContentContainer value={stateAffectations.activeTab} id="AFFACI">
                <AffectationsActivitesIndividuel />
            </TabContentContainer>
            <TabContentContainer value={stateAffectations.activeTab} id="AFFACC">
                <AffectationsActivitesCollectif />
            </TabContentContainer>
        </Stack>
    );
};

const styles = {
    stackStyle: {
        
    },
    tabStyle: {
        color: greyColor[9],
        fontWeight: "bold",
        marginRight: 0.5,
        minHeight: "",
        border: "none",
        borderRadius: 2,
        flexGrow: 1,
        textTransform: "none",
        "&:hover": {
            backgroundColor: whiteColor[5],
            color: greyColor[9]
        },
        "&.Mui-selected": {
            backgroundColor: whiteColor[5],
            color: greyColor[9]
        }
    },
    tabsStyles: {
        height: 41,
        minHeight: "",
        paddingLeft: 0.5,
        paddingY: 0.5,
        backgroundColor: greenColor["015"], 
        borderRadius: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    }
};

export default Affectations;