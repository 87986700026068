export const PAGES_GESTION_ABSENCES = {
    LIST: "list",
    SOLDES: "soldes",
    REPONSE: "reponse",
}

export const STEPS_DEMANDES_ABSENCE = {
    RECAPITULATIF: "RECAPITULATIF",
    CONDITION: "CONDITION",
    CONTRAINTE: "CONTRAINTE"
}

