import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Stack } from "@mui/material";
import { Tab, Menu } from "@mui/icons-material";
import * as userActions from "@reducers/user/usrAppReducer";
import { greenColor, greyColor, whiteColor } from "@styles";
import { AppTabsMobile } from "../Tabs";
import { useTranslation } from "react-i18next";

const BottomBar = () => {
    const dispatch = useDispatch();
    const [openMenuOngletsMobile, setOpenMenuOngletsMobile] = useState(false);
    const { t } = useTranslation("global");

    const handleOngletsToggle = useCallback(() => {
		setOpenMenuOngletsMobile((prevOpen) => !prevOpen);
    }, []);

    return(   
        <Box height="64px" bgcolor={greenColor["1"]} display="flex" justifyContent="center">
            <Stack direction="row" display="flex" alignItems="center">
                <Box px={0.5}>
                    <Button 
                        variant="outlined" 
                        startIcon={<Menu />} 
                        size="large"
                        sx={{ 
                            width: 150, 
                            color: "white", 
                            backgroundColor: greyColor[1], 
                            borderRadius: 10,
                            "&:hover": { 
                                color: greyColor[5],
                                backgroundColor: whiteColor[1] 
                            }  
                        }}
                        onClick={() => dispatch(userActions.openMenu(true))}
                    >
                        {t("buttons.menu")}
                    </Button>
                </Box>
                <Box px={0.5}>
                    <Button 
                        variant="outlined"
                        startIcon={<Tab />} 
                        size="large"
                        sx={{ 
                            width: 150, 
                            color: "white", 
                            backgroundColor: greyColor[1], 
                            borderRadius: 10,
                            "&:hover": { 
                                color: greyColor[5],
                                backgroundColor: whiteColor[1] 
                            }  
                        }}
                        onClick={handleOngletsToggle}
                    >
                        {t("buttons.onglets")}
                    </Button>
                </Box>
                <AppTabsMobile open={openMenuOngletsMobile} close={handleOngletsToggle} />
            </Stack>
        </Box>
    );
}

export default BottomBar;