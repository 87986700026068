import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { distinct, filter, map, select, tidy } from "@tidyjs/tidy";
import { Box, Grid, Stack } from "@mui/material";
import { Check, FilterListOff } from "@mui/icons-material";
import PropTypes from "prop-types";
import * as colTemGesTemActions from "@reducers/collaborateurs/colTemGesTemReducer";
import * as colAffHorIndActions from "@reducers/collaborateurs/colAffHorIndReducer";
import * as colAffActIndActions from "@reducers/collaborateurs/colAffActIndReducer";
import { useStateCollaborateurs } from "@hooks/collaborateurs/hooksStates";
import { IconButtonBase } from "@components/Buttons";
import { AutocompleteInput, AutocompleteTableInput } from "@components/Inputs";
import { filtrerFwgtpe } from "../utils";
import { TYPES_COLLABORATEURS } from "@constants/collaborateursConstants";
import { whiteColor } from "@styles";

const defaultValues = {
    societe: null,
    matricule: null,
    nom: null,
    badge: null,
    etablissement: null,
    direction: null,
    service: null,
    secteur: null,
    typePersonnel: null,
    controleHoraire: null,
    presentADate: null
};

const headerOptions = [
    {id: 1, title: "Code", xs: 2, key: "value"},
    {id: 2, title: "Désignation", xs: 10, key: "libelle"}
];

const optionsBooleens = [
    {id: 1, value: "O", label: "Oui"},
    {id: 2, value: "N", label: "Non"}
];

const optionsTypeCollaborateurs = [
    {id: 1, value: "P", label: "Salarié"},
    {id: 2, value: "I", label: "Intérimaire"}
];

const labelsMapping = (option) => option.libelle ? `${option.value} - ${option.libelle}` : "";

const CollaborateursFwgtpeForm = (props) => {
    const { type } = props;
    const dispatch = useDispatch();
    const methods = useForm({ mode: "all", defaultValues: defaultValues });
    const stateCollaborateurs = useStateCollaborateurs(type);
    const [noms, setNoms] = useState([]);
    const [matricules, setMatricules] = useState([]);
    const [badges, setBadges] = useState([]);
    const [societes, setSocietes] = useState([]);
    const [etablissements, setEtablissements] = useState([]);
    const [directions, setDirections] = useState([]);
    const [services, setServices] = useState([]);
    const [secteurs, setSecteurs] = useState([]);

    useEffect(() => {
        if(stateCollaborateurs.collaborateurs) {
            const nomsOptions = tidy(stateCollaborateurs.collaborateurs, select("prenomNom"), map((d, id) => ({id: id + 1, value: d.prenomNom, label: d.prenomNom})), filter(d => d.prenomNom !== ""));
            const matriculesOptions = tidy(stateCollaborateurs.collaborateurs, select("matricule"), map((d, id) => ({id: id + 1, value: d.matricule, label: d.matricule})), filter(d => d.matricule !== ""));
            const badgesOptions = tidy(stateCollaborateurs.collaborateurs, select("badge"), map((d, id) => ({id: id + 1, value: d.badge, label: d.badge})), filter(d => d.badge !== ""));
            const societesOptions = tidy(stateCollaborateurs.collaborateurs, select(["codeSociete", "libelleSociete"]), map((d, id) => ({id: id + 1, value: d.codeSociete, libelle: d.libelleSociete})), distinct(["value", "libelle"]), filter(d => d.value !== ""));
            const etablissementsOptions = tidy(stateCollaborateurs.collaborateurs, select(["codeEtablissement", "libelleEtablissement"]), map((d, id) => ({id: id + 1, value: d.codeEtablissement, libelle: d.libelleEtablissement})), distinct(["value", "libelle"]), filter(d => d.value !== ""));
            const directionsOptions = tidy(stateCollaborateurs.collaborateurs, select(["codeDirection", "libelleDirection"]), map((d, id) => ({id: id + 1, value: d.codeDirection, libelle: d.libelleDirection})), distinct(["value", "libelle"]), filter(d => d.value !== ""));
            const servicesOptions = tidy(stateCollaborateurs.collaborateurs, select(["codeService", "libelleService"]), map((d, id) => ({id: id + 1, value: d.codeService, libelle: d.libelleService})), distinct(["value", "libelle"]), filter(d => d.value !== ""));
            const secteursOptions = tidy(stateCollaborateurs.collaborateurs, select(["codeSecteur", "libelleSecteur"]), map((d, id) => ({id: id + 1, value: d.codeSecteur, libelle: d.libelleSecteur})), distinct(["value", "libelle"]), filter(d => d.value !== ""));
            setNoms(nomsOptions);
            setMatricules(matriculesOptions);
            setBadges(badgesOptions);
            setSocietes(societesOptions);
            setEtablissements(etablissementsOptions);
            setDirections(directionsOptions);
            setServices(servicesOptions);
            setSecteurs(secteursOptions);
        }
    }, [stateCollaborateurs.collaborateurs]);
    
    const filtrer = useCallback((data) => {
        const collaborateursBrut = stateCollaborateurs.collaborateurs;
        const collaborateursFiltered = filtrerFwgtpe(data, collaborateursBrut);

        if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_TEMPS) {
            dispatch(colTemGesTemActions.filtrer(collaborateursFiltered));
        }
        if(type === TYPES_COLLABORATEURS.AFFECTATIONS_HORAIRES_INDIVIDUELLES) {
            dispatch(colAffHorIndActions.filtrer(collaborateursFiltered));
        }
        if(type === TYPES_COLLABORATEURS.AFFECTATIONS_ACTIVITES_INDIVIDUELLES) {
            dispatch(colAffActIndActions.filtrer(collaborateursFiltered));
        }
    }, [dispatch, stateCollaborateurs.collaborateurs, type]);

    const resetFiltre = useCallback(() => {
        methods.reset();
        if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_TEMPS) {
            dispatch(colTemGesTemActions.resetFiltre());
        }
        if(type === TYPES_COLLABORATEURS.AFFECTATIONS_HORAIRES_INDIVIDUELLES) {
            dispatch(colAffHorIndActions.resetFiltre());
        }
        if(type === TYPES_COLLABORATEURS.AFFECTATIONS_ACTIVITES_INDIVIDUELLES) {
            dispatch(colAffActIndActions.resetFiltre());
        }
    }, [dispatch, methods, type])
    
    return (
        <FormProvider {...methods}>
            <Stack spacing={0.25}>
                <Box>
                    <Grid container spacing={0.5}>
                        <Grid container item xs={11}>
                            <Grid container spacing={0.5} pb={0.5}>
                                <Grid item xs={2.4}>
                                    <AutocompleteTableInput name={"societe"} label="Societe" options={societes} headerOptions={headerOptions} labelsMapping={labelsMapping} />
                                </Grid>
                                <Grid item xs={2.4}>
                                    <AutocompleteInput name={"nom"} label="Nom" options={noms} labelized />
                                </Grid>
                                <Grid item xs={2.4}>
                                    <AutocompleteInput name={"matricule"} label="Matricule" options={matricules} labelized />
                                </Grid>
                                <Grid item xs={2.4}>
                                    <AutocompleteInput name={"badge"} label="Badge" options={badges} labelized />
                                </Grid>
                                <Grid item xs={2.4}>
                                    <AutocompleteTableInput name={"etablissement"} label="Etablissement" options={etablissements} headerOptions={headerOptions} labelsMapping={labelsMapping} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0.5}>
                                <Grid item xs={2}>
                                    <AutocompleteTableInput name={"direction"} label="Direction" options={directions} headerOptions={headerOptions} labelsMapping={labelsMapping} />
                                </Grid>
                                <Grid item xs={2}>
                                    <AutocompleteTableInput name={"service"} label="Service" options={services} headerOptions={headerOptions} labelsMapping={labelsMapping} />
                                </Grid>
                                <Grid item xs={2}>
                                    <AutocompleteTableInput name={"secteur"} label="Secteur" options={secteurs} headerOptions={headerOptions} labelsMapping={labelsMapping} />
                                </Grid>
                                <Grid item xs={2}>
                                    <AutocompleteInput name={"typePersonnel"} label="Type collaborateur" options={optionsTypeCollaborateurs} labelized />
                                </Grid>
                                <Grid item xs={2}>
                                    <AutocompleteInput name={"controleHoraire"} label="Contrôle Horaire ?" options={optionsBooleens} labelized />
                                </Grid>
                                <Grid item xs={2}>
                                    <AutocompleteInput name={"presentADate"} label="Présent ?" options={optionsBooleens} labelized />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <Box bgcolor={whiteColor[7]} borderRadius={2} width={"100%"} height={"100%"} display="flex" alignItems="center" justifyContent="center">
                                <IconButtonBase icon={<FilterListOff fontSize="small" />} title="Reset filtres" action={() => resetFiltre()} />
                                <IconButtonBase icon={<Check fontSize="small" />} title="Valider" action={() => methods.handleSubmit(filtrer)()} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
        </FormProvider>
    );
}

CollaborateursFwgtpeForm.propTypes = {
    type: PropTypes.string.isRequired
}

export default CollaborateursFwgtpeForm;