import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import { selectEntSecCol } from "@selectors";
import { greenColor, whiteColor } from "@styles";
import { useChampDateCollaborateur } from "@hooks/gestionEntretiens/hooksUtils";

const DateContent = () => {
    const { display } = useChampDateCollaborateur();
    const stateSections = useSelector(selectEntSecCol);
    const sectionSelected = stateSections?.sectionSelected;

    if(display) {
        return (
            <Box bgcolor={greenColor["015"]} borderRadius={2} p={0.5}>
                <Box borderRadius={2} bgcolor={whiteColor[5]} p={1}>
                    <Typography fontSize="small">Date : {moment(sectionSelected?.date, "DDMMYYYY").format("DD/MM/YYYY")}</Typography>
                </Box>
            </Box>
        );
    }

    return null;
}

export default DateContent;