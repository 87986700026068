import { Box, Stack, Typography } from "@mui/material";
import { useChampsComplementairesCol } from "@hooks/gestionEntretiens/hooksUtils"
import { greenColor, whiteColor } from "@styles";

const ComplementsContent = () => {
    const { 
        displays, displayCC12, displayCC34, 
        displayCC1, displayCC2, displayCC3, displayCC4,
        designationCC1, designationCC2, designationCC3, designationCC4,
        complementCC1, complementCC2, complementCC3, complementCC4
    } = useChampsComplementairesCol(); 

    if(displays) {
        return (
            <Box height="100%" bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                <Stack spacing={0.5}>
                    {displayCC12 && 
                        <Stack spacing={0.5} direction="row">
                            {displayCC1 &&
                                <Box borderRadius={2} bgcolor={whiteColor[7]} p={1} flexGrow={1} display="inline">
                                    <Typography fontSize={"small"} fontWeight="bold" display="inline">{designationCC1} : </Typography>
                                    <Typography fontSize={"small"} display="inline">{complementCC1}</Typography>
                                </Box>
                            }
                            {displayCC2 &&
                                <Box borderRadius={2} bgcolor={whiteColor[7]} p={1} flexGrow={1} display="inline">
                                    <Typography fontSize={"small"} fontWeight="bold" display="inline">{designationCC2} : </Typography>
                                    <Typography fontSize={"small"} display="inline">{complementCC2}</Typography>
                                </Box>
                            }
                        </Stack>
                    }
                    {displayCC34 &&
                        <Stack spacing={0.5} direction="row" display="flex" alignItems="center">
                            {displayCC3 &&
                                <Box borderRadius={2} bgcolor={whiteColor[7]} p={1} flexGrow={1} display="inline">
                                    <Typography fontSize={"small"} fontWeight="bold" display="inline">{designationCC3} : </Typography>
                                    <Typography fontSize={"small"} display="inline">{complementCC3}</Typography>
                                </Box>
                            }
                            {displayCC4 &&
                                <Box borderRadius={2} bgcolor={whiteColor[7]} p={1} flexGrow={1} display="inline">
                                    <Typography fontSize={"small"} fontWeight="bold" display="inline">{designationCC4} : </Typography>
                                    <Typography fontSize={"small"} display="inline">{complementCC4}</Typography>
                                </Box>
                            }
                        </Stack>
                    }
                </Stack>
            </Box>
        );
    }

    return null;
}

export default ComplementsContent;