import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useStateTemGesTem } from "@hooks/gestionTemps/temps/hooksStates";
import { chiffreUSVersChiffreFR, heurify } from "@utils";
import { whiteColor } from "@styles";

const TempsFooterTableMobile = (props) => {
    const { userType } = props;
    const stateTemps = useStateTemGesTem(userType);

    return (
        <Grid container spacing={0.5}>
            <Grid item xs={2}></Grid>
            <Grid item container xs={4} spacing={0.5}>
                <Grid item xs={6}>
                    <Box bgcolor={whiteColor[5]} p={1} borderRadius={2} height={"100%"} boxSizing="border-box">
                        <Typography fontSize={"small"} fontWeight="bold">{stateTemps.horaire ? heurify(stateTemps?.totaux?.heuresJournee?.badgees?.heure, "h", false) : chiffreUSVersChiffreFR(stateTemps?.totaux?.heuresJournee?.badgees?.chiffre)}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box bgcolor={whiteColor[5]} p={1} borderRadius={2} height={"100%"} boxSizing="border-box">
                        <Typography fontSize={"small"} fontWeight="bold">{stateTemps.horaire ? heurify(stateTemps?.totaux?.heuresJournee?.payees?.heure, "h", false) : chiffreUSVersChiffreFR(stateTemps?.totaux?.heuresJournee?.payees?.chiffre)}</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid item container xs={4} spacing={0.5}>
                <Grid item xs={6}>
                    <Box bgcolor={whiteColor[5]} p={1} borderRadius={2} height={"100%"} boxSizing="border-box">
                        <Typography fontSize={"small"} fontWeight="bold">{stateTemps.horaire ? heurify(stateTemps?.totaux?.heuresNuit?.badgees?.heure, "h", false) : chiffreUSVersChiffreFR(stateTemps?.totaux?.heuresNuit?.badgees?.chiffre)}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box bgcolor={whiteColor[5]} p={1} borderRadius={2} height={"100%"} boxSizing="border-box">
                        <Typography fontSize={"small"} fontWeight="bold">{stateTemps.horaire ? heurify(stateTemps?.totaux?.heuresNuit?.payees?.heure, "h", false) : chiffreUSVersChiffreFR(stateTemps?.totaux?.heuresNuit?.payees?.chiffre)}</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
        </Grid>
    );
}

TempsFooterTableMobile.propTypes = {
    userType: PropTypes.string.isRequired
}

export default TempsFooterTableMobile;