import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import * as colAffHorIndActions from "@reducers/collaborateurs/colAffHorIndReducer";
import moment from "moment";
import { selectAffHorInd, selectColAffHorInd } from "@selectors";
import * as affHorIndActions from "@reducers/gestionTemps/affectations/affHorIndReducer";
import { greenColor } from "@styles";
import { ActionsAffectationsHorairesIndividuelles } from "./ActionsBar";
import { trierAffHorInd } from "./tries";
import { useAddAff, useAddCycAffHor, useFetchAffHorInd, useDeleteAff, useEditAff } from "@hooks/gestionTemps/affectations/hooksQueries";
import { AffectationsHorairesIndividuellesList } from "./Lists";
import { MenuFiltreContainer, MenuTrieContainer } from "@components/Containers";
import FiltreAffectationsHorairesIndividuellesForm from "./Forms/FiltreAffectationsHorairesIndividuellesForm";
import { Badge, Event, Person } from "@mui/icons-material";
import { AddAffectationDialog, CycleAffectationIndividuelleDialog, DeleteAffectationDialog, EditAffectationDialog } from "../Dialogs";
import { TYPES_COLLABORATEURS, TYPES_FICHIER } from "@constants/collaborateursConstants";
import { CollaborateursBar } from "@components/App/Collaborateurs/Bars";
import { CollaborateursDialogMobile } from "@components/App/Collaborateurs/Dialogs";
import { TYPES_AFFECTATIONS } from "@constants/affectationsConstants";

const headersFwgtpe = [
    {id: 1, label: "Nom", xs: 1.5, display: true},
    {id: 2, label: "Matricule", xs: 0.75, display: true},
    {id: 3, label: "Badge", xs: 0.75, display: true},
    {id: 4, label: "Société", xs: 1, display: true},
    {id: 5, label: "Etablissement", xs: 1, display: true},
    {id: 6, label: "Direction", xs: 1.5, display: true},
    {id: 7, label: "Service", xs: 1.5, display: true},
    {id: 8, label: "Secteur", xs: 1, display: true},
    {id: 9, label: "Type", xs: 1, display: true},
    {id: 10, label: "Contôle horaire", xs: 0.75, display: true},
    {id: 11, label: "Présent", xs: 0.75, display: true},
];

const optionsTrie = [
    {id: 1, label: "Par date", keyAsc: "dateAsc", keyDesc: "dateDesc", icon: <Event fontSize="small" />},
    {id: 2, label: "Par code horaire", keyAsc: "horaireAsc", keyDesc: "horaireDesc", icon: <Person fontSize="small" />},
    {id: 3, label: "Par statut", keyAsc: "statutAsc", keyDesc: "statutDesc", icon: <Badge fontSize="small" />}
];

const AffectationsHorairesIndividuelMobile = () => {
    const [openCollaborateursDialog, setOpenCollaborateursDialog] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openCycleDialog, setOpenCycleDialog] = useState(false);
    const [openFiltre, setOpenFiltre] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const stateColAffHorInd = useSelector(selectColAffHorInd);
    const stateAffHorInd = useSelector(selectAffHorInd);
    const { isLoading, isFetching, isError, refetch } = useFetchAffHorInd();
    const { isLoadingHoraire: isLoadingAdd, addAffHorActionner } = useAddAff();   
    const { isLoadingHoraire: isLoadingEdit, editAffHorActionner } = useEditAff();    
    const { isLoadingHoraire: isLoadingDelete, deleteAffHorActionner } = useDeleteAff();    
    const { isLoadingHoraire: isLoadingCycle, addCycAffHorActionner } = useAddCycAffHor()
    const dispatch = useDispatch();

    const selectCollaborateur = useCallback(async (collaborateur) => {
        setOpenCollaborateursDialog(false);
        dispatch(colAffHorIndActions.selectCollaborateur(collaborateur));
    }, [dispatch]);

    const lister = useCallback(() => {
        dispatch(affHorIndActions.lister());
    }, [dispatch]);

    const deleteFiltre = useCallback(() => {
        dispatch(affHorIndActions.deleteFiltre());
    }, [dispatch]);

    const deleteTrie = useCallback(() => {
        dispatch(affHorIndActions.deleteTrie());
    }, [dispatch]);

    const trier = useCallback((keyTrie) => {
        const data = [...stateAffHorInd.affectations];
        const dataTried = trierAffHorInd(keyTrie, data);
        dispatch(affHorIndActions.trier(dataTried));
    }, [dispatch, stateAffHorInd.affectations]);

    const openEdit = useCallback((affectation) => {
        dispatch(affHorIndActions.selectAffectation(affectation));
        setOpenEditDialog(true);
    }, [dispatch]);

    const openDelete = useCallback((affectation) => {
        dispatch(affHorIndActions.selectAffectation(affectation))
        setOpenDeleteDialog(true);
    }, [dispatch]);

    const addAffectation = useCallback(async (data) => {
        const { isSuccessPost } = await addAffHorActionner(data);
        if(isSuccessPost) {
            refetch();
            setOpenAddDialog(false);
        }
    }, [addAffHorActionner, refetch]);

    const editAffectation = useCallback(async (data) => {
        const { isSuccessEdit } = await editAffHorActionner(data);
        if(isSuccessEdit) {
            refetch();
            setOpenEditDialog(false);
        }
    }, [editAffHorActionner, refetch]);

    const deleteAffectation = useCallback(async () => {
        const { isSuccessDelete } = await deleteAffHorActionner();
        if(isSuccessDelete) {
            refetch();
            setOpenDeleteDialog(false);
        }
    }, [deleteAffHorActionner, refetch]);

    const addCyleAffectation = useCallback(async (data) => {
        const { isSucceedAddCycle } = await addCycAffHorActionner(data);
        if(isSucceedAddCycle) {
            refetch();
            setOpenCycleDialog(false);
        }
    }, [addCycAffHorActionner, refetch]);
    

    return (
        <>
            <Stack spacing={0.5} bgcolor={greenColor["015"]} borderRadius={2} p={0.5} height="100%" boxSizing="border-box">
                {stateColAffHorInd.collaborateurSelected &&
                    <>
                        <AffectationsHorairesIndividuellesList 
                            data={stateAffHorInd.affectationsDisplayed}    
                            isLoading={isLoading || isFetching}
                            isError={isError}
                            listed={stateAffHorInd.listed}
                            readOnly={stateColAffHorInd?.collaborateurSelected?.conditionService !== "M"}
                            typePersonnel={stateColAffHorInd?.collaborateurSelected?.typePersonnel}
                            openEdit={openEdit}
                            openDelete={openDelete}
                        />
                        <ActionsAffectationsHorairesIndividuelles 
                            readOnly={stateColAffHorInd?.collaborateurSelected?.conditionService !== "M"}
                            listed={stateAffHorInd.listed}
                            filtered={stateAffHorInd.filtered} 
                            tried={stateAffHorInd.tried}
                            deleteFiltre={deleteFiltre}
                            deleteTrie={deleteTrie}
                            lister={lister}
                            setOpenAdd={setOpenAddDialog}
                            setOpenCycle={setOpenCycleDialog}
                            setAnchorEl={setAnchorEl}
                            setOpenFiltre={setOpenFiltre}
                            refetch={refetch}
                        />     
                        <MenuTrieContainer 
                            anchorEl={anchorEl} 
                            setAnchorEl={setAnchorEl} 
                            options={optionsTrie} 
                            trier={trier} 
                        />        
                        <MenuFiltreContainer open={openFiltre} setOpen={setOpenFiltre}>
                            <FiltreAffectationsHorairesIndividuellesForm setOpen={setOpenFiltre} />
                        </MenuFiltreContainer>   
                    </>
                }
                <CollaborateursBar collaborateur={stateColAffHorInd?.collaborateurSelected} openDialog={() => setOpenCollaborateursDialog(true)} />
                <CollaborateursDialogMobile 
                    stateType={TYPES_COLLABORATEURS.AFFECTATIONS_HORAIRES_INDIVIDUELLES}
                    listType={TYPES_FICHIER.FWGTPE} 
                    open={openCollaborateursDialog}  
                    dateValidite={stateColAffHorInd?.dateValidite ? moment(stateColAffHorInd.dateValidite, "DDMMYYYY").format("DDMMYYYY") : moment()}
                    headers={headersFwgtpe}
                    displayDate={false}
                    tried={stateColAffHorInd.tried}
                    setOpen={setOpenCollaborateursDialog}  
                    selectCollaborateur={selectCollaborateur}
                />
            </Stack>
            <EditAffectationDialog 
                isLoading={isLoadingEdit}
                typeAffectations={TYPES_AFFECTATIONS.HORAIRES_INDIVIDUEL} 
                open={openEditDialog} 
                setOpen={setOpenEditDialog} 
                editAffectation={editAffectation} 
            />
            <AddAffectationDialog 
                isLoading={isLoadingAdd}
                typeAffectations={TYPES_AFFECTATIONS.HORAIRES_INDIVIDUEL} 
                open={openAddDialog} 
                setOpen={setOpenAddDialog} 
                addAffectation={addAffectation} 
            />
            <DeleteAffectationDialog 
                isLoading={isLoadingDelete}
                typeAffectations={TYPES_AFFECTATIONS.HORAIRES_INDIVIDUEL} 
                anneeEffet={stateAffHorInd?.affecationSelected?.anneeEffet}
                moisEffet={stateAffHorInd?.affecationSelected?.moisEffet}
                jourEffet={stateAffHorInd?.affecationSelected?.jourEffet}
                codeHoraire={stateAffHorInd?.affecationSelected?.codeHoraire}
                libelleStatut={stateAffHorInd?.affecationSelected?.libelleStatut}
                codeActivite={stateAffHorInd?.affecationSelected?.codeActivite}
                typePersonnel={stateColAffHorInd?.collaborateurSelected?.typePersonnel}
                open={openDeleteDialog} 
                setOpen={setOpenDeleteDialog} 
                deleteAffectation={deleteAffectation} 
            />
            <CycleAffectationIndividuelleDialog 
                isLoading={isLoadingCycle}
                open={openCycleDialog} 
                setOpen={setOpenCycleDialog} 
                addCyleAffectation={addCyleAffectation} 
            />
        </>
    );
};

export default AffectationsHorairesIndividuelMobile;