import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    tabs: [{label: "Accueil", action: "ACC010000"}],
    activeTab: "ACC010000"
}

const tabAppSlice = createSlice({
    name: 'tabApp',
    initialState: initialState,
    reducers: {
        editTabs: (state, action) => {
            state.tabs = action.payload;
        },
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
        closeTab: (state, action) => {
            state.tabs = action.payload.tabs;
            state.activeTab = action.payload.prevTab;
        },
        resetTabs: (state) => {
            state.tabs = [{label: "Accueil", action: "ACC010000"}]
            state.activeTab = "ACC010000";
        }
    },
})

const { actions, reducer } = tabAppSlice
export const { editTabs, setActiveTab, closeTab, resetTabs } = actions
export default reducer