import { urls } from "@constants/appConstants";
import axios from "axios";

/**
 * * Appel de la procédure PHPLA1
 * * Url : get/planning/demande/absence
 * @param {*} matriculeDemandeur Matricule du demandeur de l'absence
 * @param {*} dateDebut Date de la demande d'absence
 * @param {*} datePosition Date de la demande d'absence ou date suivant/précédent
 * @param {*} absencesAAfficher Toutes les absences ou absences validées
 * @param {*} client 
 * @param {*} signal 
 * @returns Données du planning
 */
export const fetchPlaDabCol = async (matriculeDemandeur, dateDebut, datePosition, absencesAAfficher, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetPlanningAbsence"
    };
    const body = {
        matriculeDemandeur: matriculeDemandeur,
        dateDebut: dateDebut,
        datePosition: datePosition,
        absencesAAfficher: absencesAAfficher
    };
    const response = await axios.post(urls.getPlanningAbsence, body, { headers: headers, params: { client: client }, signal: signal });
    return {
        date: datePosition,
        semaine: response.data.semaine,
        emetteur: response.data.emetteur,
        societes: response.data.societes,
        totaux: response.data.totaux,
    }
}

export const fetchPlaDabMan = async (matricule, matriculeDemandeur, dateDebut, datePosition, absencesAAfficher, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetPlanningManager"
    };
    const body = {
        matriculeHierarchique: matricule,
        matriculeDemandeur: matriculeDemandeur,
        dateDebut: dateDebut,
        datePosition: datePosition,
        absencesAAfficher: absencesAAfficher
    };

    const response = await axios.post(urls.getPlanningManager, body, { headers: headers, params: { client: client }, signal: signal });
    return {
        date: datePosition,
        semaine: response.data.semaine,
        emetteur: response.data.emetteur,
        societes: response.data.societes,
        totaux: response.data.totaux,
    }
}

export const fetchPlaHrc = async (matricule, datePosition, absencesAAfficher, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetPlanningHierarchie"
    };
    const body = {
        matricule: matricule,
        datePosition: datePosition,
        absencesAAfficher: absencesAAfficher
    };
    const response = await axios.post(urls.getPlanningHierarchie, body, { headers: headers, params: { client: client }, signal: signal });
    return {
        date: datePosition,
        semaine: response.data.semaine,
        collaborateurs: response.data.collaborateurs
    }
}

export const fetchPlaGlo = async (matricule, datePosition, absencesAAfficher, servicesATraiter, servicesNonTraiter, premierAppel, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetPlanningServices"
    };
    const body = {
        matricule: matricule,
        datePosition: datePosition,
        absencesAAfficher: absencesAAfficher,
        premierAppel: premierAppel,
        servicesATraiter: servicesATraiter,
        servicesNonTraiter: servicesNonTraiter
    };
    const response = await axios.post(urls.getPlanningServices, body, { headers: headers, params: { client: client }, signal: signal });
    let servicesSelected = [];
    let servicesNonSelected = [];
    let taille = 0;
    let allServices = false;
    response.data.services.forEach((societe) => {
        societe.services.forEach((service) => {
            if(service.aTraiter === "O") {
                servicesSelected.push({service: service.service, societe: societe.societe, id: service.service + societe.societe});
            } else {
                servicesNonSelected.push({service: service.service, societe: societe.societe, id: service.service + societe.societe});
            }
            taille++
        });
    });

    if(taille === servicesSelected.length) {
        allServices = true;
    }

    return {
        date: datePosition,
        semaine: response.data.semaine,
        societes: response.data.societes,
        totaux: response.data.totaux,
        services: response.data.services,
        servicesSelected: servicesSelected,
        servicesNonSelected: servicesNonSelected,
        allServices: allServices
    }
}

export const fetchPlaGesTem = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetPlanningGestionTemps"
    };
    const body = {
        numeroSemaine: data.numeroSemaine,
        anneeSemaine: data.anneeSemaine,
        utilisateur: data.utilisateur,
        matriculeHierarchique: data.matriculeHierarchique,
        droitSalarie: data.droitSalarie,
        droitInterimaire: data.droitInterimaire,
        matriculeCollaborateur: data.matriculeCollaborateur,
        typePersonnel: data.typePersonnel
    };
    const response = await axios.post(urls.getPlanningGestionTempsManager, body, { headers: headers, params: { client: data.client } });
    return response.data.planning;
}

export const fetchPlaGesTemNav = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetPlanningGestionTempsNavigation"
    };
    const body = {
        numeroSemaine: data.numeroSemaine,
        anneeSemaine: data.anneeSemaine,
        utilisateur: data.utilisateur,
        matriculeHierarchique: data.matriculeHierarchique,
        droitSalarie: data.droitSalarie,
        droitInterimaire: data.droitInterimaire,
        matriculeCollaborateur: data.matriculeCollaborateur,
        typePersonnel: data.typePersonnel
    };
    const response = await axios.post(urls.getPlanningGestionTempsNavigationManager, body, { headers: headers, params: { client: data.client } });
    return response.data.planning;
}

export const editPlaGesTem = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostPlanningGestionTemps"
    };
    const body = {
        numeroSemaine: data.numeroSemaine,
        anneeSemaine: data.anneeSemaine,
        utilisateur: data.utilisateur,
        matriculeHierarchique: data.matriculeHierarchique,
        droitSalarie: data.droitSalarie,
        droitInterimaire: data.droitInterimaire,
        matriculeCollaborateur: data.matriculeCollaborateur,
        typePersonnel: data.typePersonnel,
        modificationAffectationsHoraires: data.modificationAffectationsHoraires,
        planning: data.planning
    };
    const response = await axios.post(urls.postPlanningGestionTempsManager, body, { headers: headers, params: { client: data.client } });
    return response.data;
}

export const editPlaGesTemMod = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostPlanningGestionTempsModelize"
    };
    const body = {
        numeroSemaine: data.numeroSemaine,
        anneeSemaine: data.anneeSemaine,
        utilisateur: data.utilisateur,
        matriculeHierarchique: data.matriculeHierarchique,
        droitSalarie: data.droitSalarie,
        droitInterimaire: data.droitInterimaire,
        matriculeCollaborateur: data.matriculeCollaborateur,
        typePersonnel: data.typePersonnel,
        modificationAffectationsHoraires: data.modificationAffectationsHoraires,
        planning: data.planning
    };
    const response = await axios.post(urls.postPlanningGestionTempsModelizeManager, body, { headers: headers, params: { client: data.client } });
    return response.data;
}

export const verificationPlaGesTem = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostPlanningGestionTempsVerificatition"
    };
    const body = {
        numeroSemaine: data.numeroSemaine,
        anneeSemaine: data.anneeSemaine,
        utilisateur: data.utilisateur,
        matriculeHierarchique: data.matriculeHierarchique,
        droitSalarie: data.droitSalarie,
        droitInterimaire: data.droitInterimaire,
        matriculeCollaborateur: data.matriculeCollaborateur,
        typePersonnel: data.typePersonnel,
        modificationAffectationsHoraires: data.modificationAffectationsHoraires,
        planning: data.planning
    };
    const response = await axios.post(urls.postPlanningGestionTempsVerificationManager, body, { headers: headers, params: { client: data.client } });
    return response.data;
}

/**
 * * Appel de la procédure PHPLAB
 * * Url : get/absences/planning
 * @param {*} matricule Matricule de l'utilisateur
 * @param {*} date Date du jour demandé
 * @param {*} client 
 * @param {*} signal 
 * @returns Données informationnelles du jour (absence + infos)
 */
export const fetchDetJouPla = async (matricule, date, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetAbsencesPlanning"
    };
    const body = {
        matricule: matricule,
        date: date
    };

    const response = await axios.post(urls.getAbsencesPlanning, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.absences;
}

export const downloadPla = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteDownloadPlanning"
    };
    const body = {
        matricule: data.matricule,
        anneeDebut: data.annee,
        semaineDebut: data.semaine,
        absencesAAfficher: data.affichage
    }
    const response = await axios.post(urls.downloadPlanning, body, { headers: headers, params: { client: data.client } });
    return response.data;
}

export const downloadPlaGesTem = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteDownloadPlanningGestionTemps"
    };
    const body = {
        utilisateur: data.utilisateur,
        matriculeHierarchique: data.matriculeHierarchique,
        droitPersonnel: data.droitPersonnel,
        droitInterimaires: data.droitInterimaires,
        anneeDebut: data.anneeDebut,
        semaineDebut: data.semaineDebut,
        societe: data.societe,
        filtreSociete: data.filtreSociete,
        filtreEtablissement: data.filtreEtablissement,
        filtreDirection: data.filtreDirection,
        filtreService: data.filtreService,
        filtreSecteur: data.filtreSecteur,
        filtreMetier: data.filtreMetier,
        filtreCategorieProfessionnelle: data.filtreCategorieProfessionnelle,
        filtreHoraire: data.filtreHoraire
    }
    const response = await axios.post(urls.downloadPlanningGestionTemps, body, { headers: headers, params: { client: data.client } });
    return response.data;
}