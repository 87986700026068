import { DialogContainer } from "@components/Containers";
import PropTypes from "prop-types";
import { QuestionForm } from "../Forms";

const QuestionDialog = (props) => {
    const { open, setOpen } = props;

    return (
        <DialogContainer open={open} placement="top" taille="sm">
            <QuestionForm setOpen={setOpen} />
        </DialogContainer>
    );
}

QuestionDialog.defaultProps = {
    open: false,
    setOpen: () => {}
}

QuestionDialog.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func
}

export default QuestionDialog;