import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { selectCltApp, selectUsrApp } from "@selectors";
import * as creGesTemActions from "@reducers/gestionTemps/credits/creGesTemReducer";
import { fetchCreGesTem } from "@api/credits";

export const useCreGesTem = (date, badge, enable) => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const droitSalarie = stateUser.droitSalarie ? "O" : "N"; 
    const droitInterimaire = stateUser.droitInterimaire ? "O" : "N";
    const dispatch = useDispatch();

    const query = useQuery(['creGesTem', date, badge], ({ signal }) => fetchCreGesTem(stateUser.utilisateurAS400, stateUser.matricule, droitSalarie, droitInterimaire, date, badge, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!date && !!badge && enable
    });

    useEffect(() => {
        if(query.data) {
            dispatch(creGesTemActions.setCredits(query.data));
        }
    }, [dispatch, query.data])

    return query;
}
