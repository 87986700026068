import { useSelector } from "react-redux";
import { Box, Grid, Grow, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { selectEntCol } from "@selectors";
import { DialogFullScreenContainer, HeaderDialogContainer } from "@components/Containers";
import { greenColor } from "@styles";
import { DetailEntretien } from "../Informations";

const DetailDialogMobile = ({ 
    open = false, 
    setOpen = () => {}
}) => {
    const stateEntretiens = useSelector(selectEntCol);

    return (
        <DialogFullScreenContainer open={open} taille="lg">
            <HeaderDialogContainer
                title="Détail de l'entretien"
                close={() => setOpen(false)}
            />
            <Stack spacing={0.5} height="100%" overflow="auto">
                {stateEntretiens.entretienSelected ?
                    <DetailEntretien />
                :
                    <Grid container height={"100%"}>
                        <Grow in={true} timeout={500}>
                            <Box borderRadius={2} bgcolor={greenColor["015"]} width="100%" p={1} display="flex" alignItems="center" justifyContent="center"> 
                                <Typography fontSize="small">Aucun entretien sélectionné</Typography>
                            </Box>
                        </Grow>
                    </Grid>
                }
            </Stack>
        </DialogFullScreenContainer>
    );
}

DetailDialogMobile.propTypes = {
    open: PropTypes.bool, 
    setOpen: PropTypes.func
}

export default DetailDialogMobile;