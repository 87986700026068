import { Box, Grid, Stack } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { TextContainer } from "@components/Containers";
import { blueColor, whiteColor } from "@styles";
import { useStatePlanning } from "@hooks/planning/hooksStates";
import { useTranslation } from "react-i18next";

const formateJourCourt = (jour) => {
    const libelleCourt = moment(jour.date, "YYYYMMDD").format("dd")[0].toUpperCase() + moment(jour.date, "YYYYMMDD").format("dd").substring(1);
    return libelleCourt;
};

const getFirstDayOfWeek = (numeroSemaine, annee) => {
    let day = "";
    if(numeroSemaine && annee) {
        day = moment().day(1).year(annee).week(numeroSemaine).format("DD/MM");
    }
    return day;
}

const getLastDayOfWeek = (numeroSemaine, annee) => {
    let day = "";
    if(numeroSemaine && annee) {
        day = moment().day(7).year(annee).week(numeroSemaine).format("DD/MM");
    }
    return day;
}

const Header = (props) => {
    const { type } = props;
    const statePlanning = useStatePlanning(type);
    const { t } = useTranslation("global");

    return (
        <Stack spacing={0.5}>
            {statePlanning?.semaine && 
                <>
                    <TextContainer bgColor={whiteColor[5]} p={1} height={"100%"} verticalAlign="center" textSize="small" textWeight="bold">
                        {t("calendar.week_string", { count: 1 })} {statePlanning?.semaine?.numeroSemaine} {t("texts.of_year")} {statePlanning?.semaine?.anneeSemaine}
                    </TextContainer>
                    <TextContainer bgColor={whiteColor[5]} p={1} height={"100%"} verticalAlign="center" textSize="small" textWeight="bold">
                        {t("texts.from")} {getFirstDayOfWeek(statePlanning?.semaine?.numeroSemaine, statePlanning?.semaine?.anneeSemaine)} {t("texts.to")} {getLastDayOfWeek(statePlanning?.semaine?.numeroSemaine, statePlanning?.semaine?.anneeSemaine)}
                    </TextContainer>
                    <Box>
                        <Grid container spacing={0.5}>
                            {statePlanning?.semaine?.jours?.map((jour) => (
                                <Grid item xs={1.5} key={`JP${jour.id}`}>
                                    <Stack spacing={0.5} bgcolor={jour.libelleFerie ? blueColor["015"] : whiteColor[5]} borderRadius={2} height="100%" display="flex" alignItems="center" justifyContent="center">
                                        <TextContainer p={0} bgColor="none" textWeight="bold">
                                            {formateJourCourt(jour)}
                                        </TextContainer>
                                    </Stack>
                                </Grid>
                            ))}
                            <Grid item xs={1.5}>
                                <TextContainer bgColor={whiteColor[5]} p={1} height="100%" verticalAlign="center" horizontalAlign="center" textSize="small" textWeight="bold">
                                    {t("texts.total_short")}
                                </TextContainer>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            }
        </Stack>
    );
}

Header.defaultProps = {
    type: "",
    jourCourt: false
}

Header.propTypes = {
    type: PropTypes.string,
    jourCourt: PropTypes.bool
}

export default Header;