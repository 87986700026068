import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Grow, Stack, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { selectUsrApp } from "@selectors";
import * as userActions from "@reducers/user/usrAppReducer";
import { greenColor, whiteColor } from "@styles";
import { polices, routePolices } from "@constants/policesConstants";
import { editPol } from "@api/police";
import { useTranslation } from "react-i18next";

const PolicesList = () => {
    const stateUser = useSelector(selectUsrApp);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation("global");

    const savePolice = useCallback(async (event) => {
        const police = event.currentTarget.dataset.police;
        dispatch(userActions.openPolice(false));
        try {
            const response = await editPol(police, stateUser.token);
            if(response.data.succeed) {
                dispatch(userActions.setPolice(police));
                enqueueSnackbar(response.data.details, { variant: "success", anchorOrigin: { vertical: "bottom", horizontal: "center" } });
            } else {
                enqueueSnackbar(response.data.details, { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "center" } });
            }
        } catch(e) {
            enqueueSnackbar(t("errors.edit_police"), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "center" } });
        }
    }, [dispatch, enqueueSnackbar, stateUser.token, t]);

    return (  
        <Stack spacing={0.5}>
            <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                <Box bgcolor={whiteColor[7]} borderRadius={2} p={1}>
                    <Typography fontSize="small" fontWeight="bold">{t("texts.choose_police")}</Typography>
                </Box>
            </Box>
            <Box>
                <Grid container spacing={0.5}>
                    {polices.map((police, id) => (
                        <Grow key={`POL${police}`} in={true} timeout={200+(200*id)}>
                            <Grid item xs={12} sm={4} md={4}>
                                <Tooltip title={<Typography fontFamily={routePolices[police]}>{t("texts.example_police")}</Typography>}>
                                    <Box height="100px" bgcolor={greenColor["07"]} borderRadius={2} p={0.5}>
                                        <Box 
                                            height="100%"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            bgcolor={whiteColor[7]}
                                            borderRadius={2}
                                            sx={{
                                                cursor: "pointer",
                                            }}
                                            onClick={savePolice}
                                        >
                                            <Typography fontSize={25} fontFamily={routePolices[police]}>{police}</Typography>
                                        </Box>
                                    </Box>
                                </Tooltip>
                            </Grid>
                        </Grow>
                    ))} 
                </Grid>
            </Box>
        </Stack>
    );
}

export default PolicesList;