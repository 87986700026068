import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Stack, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import "react-clock/dist/Clock.css";
import { getWidgetRemoved } from "@utils";
import { selectDsh } from "@selectors";
import * as dshActions from "@reducers/dashboard/dshReducer"
import { IconButtonBase } from "@components/Buttons";
import { greenColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const HorlogesWidgetConfigurationDelete = () => {
    const stateDashboard = useSelector(selectDsh);
    const dispatch = useDispatch();
    const { t } = useTranslation("global");

    const remove = useCallback(() => {
        const updateState = getWidgetRemoved("wHorloges", stateDashboard.widgetsActives, stateDashboard.widgetsNonActives, stateDashboard.widgetsConfiguration);
        dispatch(dshActions.editWidget(updateState));
    }, [dispatch, stateDashboard.widgetsActives, stateDashboard.widgetsConfiguration, stateDashboard.widgetsNonActives]);

    return (
        <Stack spacing={0.5} padding={0.5} height="200px" boxSizing="border-box" borderRadius={2} bgcolor={whiteColor[5]} position="relative">
            <Stack spacing={0.5} overflow="auto">  
                <Box bgcolor={greenColor["05"]} p={0.5} borderRadius={2}>
                    <Stack spacing={0.5} display="flex" justifyContent="center" bgcolor={whiteColor[5]} borderRadius={2} p={0.5}>
                        <Box textAlign="center">
                            <Typography fontSize="large" fontWeight="bold">{t("location.paris_city")}</Typography>
                        </Box>
                        <Box textAlign="center" bgcolor={greenColor["03"]} borderRadius={2} p={0.5}>
                            <Typography fontSize="large" fontWeight="bold">10:10:10</Typography>
                        </Box>
                    </Stack>
                </Box>
                <Box bgcolor={greenColor["05"]} p={0.5} borderRadius={2}>
                    <Stack spacing={0.5} display="flex" justifyContent="center" bgcolor={whiteColor[5]} borderRadius={2} p={0.5}>
                        <Box textAlign="center">
                            <Typography fontSize="large" fontWeight="bold">{t("location.los_angeles_city")}</Typography>
                        </Box>
                        <Box textAlign="center" bgcolor={greenColor["03"]} borderRadius={2} p={0.5}>
                            <Typography fontSize="large" fontWeight="bold">01:10:10</Typography>
                        </Box>
                    </Stack>
                </Box>
            </Stack>
            <Box position="absolute" right={6} top={3}>
                <IconButtonBase 
                    variant="deleteListButton"
                    icon={<Close fontSize="small" />} 
                    title={t("buttons.delete")} 
                    action={remove}         
                />
            </Box> 
        </Stack>
    );
}

export default HorlogesWidgetConfigurationDelete;