import { useSelector } from "react-redux";
import { Add } from "@mui/icons-material";
import PropTypes from "prop-types";
import { selectDsh } from "@selectors";
import { WelcomeWidgetConfigurationDelete } from "../Widgets/Welcome";
import { DateWidgetConfigurationDelete } from "../Widgets/Date";
import { MeteoWidgetConfigurationDelete } from "../Widgets/Meteo";
import { HorlogesWidgetConfigurationDelete } from "../Widgets/Horloges";
import { PlagesHoraireWidgetConfigurationDelete } from "../Widgets/PlagesHoraire";
import { StatutWidgetConfigurationDelete } from "../Widgets/Statut";
import { Box, Grid, IconButton, Paper, Stack, Tooltip } from "@mui/material";
import { greenColor, greyColor, whiteColor } from "@styles";
import { IconButtonBase } from "@components/Buttons";

const WidgetsActivesList = (props) => {
    const { openMenu } = props;
    const stateDashboard = useSelector(selectDsh);
    
    const routeWidgets = {
        wBonjour: <WelcomeWidgetConfigurationDelete />,
        wDate: <DateWidgetConfigurationDelete />,
        wMeteo: <MeteoWidgetConfigurationDelete />,
        wHorloges: <HorlogesWidgetConfigurationDelete />,
        wPlagesHoraire: <PlagesHoraireWidgetConfigurationDelete />,
        wStatut: <StatutWidgetConfigurationDelete />,
    };

    return (
        <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
            <Grid container spacing={0.5}>
                {stateDashboard.widgetsConfiguration.map((widget, id) => (
                    <Grid key={`WIDGETACT${id}`} item xs={12} sm={6} md={6} lg={4} xl={3}>
                        {widget && routeWidgets[widget] ? 
                            routeWidgets[widget]
                        : 
                            <Box bgcolor={whiteColor[5]} borderRadius={2} minHeight="200px" display="flex" justifyContent="center" alignItems="center">
                                <IconButtonBase 
                                    variant="listButton"
                                    icon={<Add fontSize="small" />}
                                    title="Ajouter"
                                    action={() => openMenu(id)}
                                />
                            </Box>
                        }
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

WidgetsActivesList.defatulProps = {
    openMenu: () => {}
}

WidgetsActivesList.propTypes = {
    openMenu: PropTypes.func
}

const styles = {
    paperStyleNoBackground: {
        backdropFilter: "blur(3px)",
        backgroundColor: greyColor[0],
        width: "100%",
        height: "200px",
        borderRadius: 2
    }
}

export default WidgetsActivesList;