import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { joiResolver } from "@hookform/resolvers/joi";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor } from "@styles";
import { DialogContainer } from "@components/Containers";
import { EditActions } from "./Actions";
import moment from "moment";
import { DelegationForm } from "../Forms";
import { schemaDelegationEdit } from "@components/Delegations/validations";
import { dataToFormDelegation } from "@integrations";
import { selectDlgApp } from "@selectors";
import { useDla, useEditDelegation } from "@hooks/delegations/hooksQueries";
import { filter, tidy } from "@tidyjs/tidy";

const defaultValues = {
    absence: false, 
    formation: false, 
    noteFrais: false, 
    dateDebut: moment(), 
    dateReprise: null, 
    typeDelegation: "",
    avertissementMail: false, 
    delaiAttente: "", 
    destinataire: null
}

const EditDialog = (props) => {
    const { open, setOpen, setOpenEditDialog, refetch } = props;
    const stateDelegations = useSelector(selectDlgApp);
    const methods = useForm({ mode: "all", defaultValues: defaultValues, resolver: joiResolver(schemaDelegationEdit) });
    const { isLoading: isLoadingDelegataitre, isFetching: isFetchingDelegataires } = useDla(open);
    const { isLoading, editDelegationActionner } = useEditDelegation();

    useEffect(() => {
        if(open) {
            const delegationFormated = dataToFormDelegation(stateDelegations.delegationSelected);
            const delegataire = tidy(stateDelegations.delegataires, filter(delegataire => (delegataire.utilisateur === delegationFormated?.destinataire)));
            methods.reset({
                absence: delegationFormated.absence,
                formation: delegationFormated.formation,
                noteFrais: delegationFormated.noteFrais,
                dateDebut: delegationFormated.dateDebut,
                dateReprise: delegationFormated.dateReprise,
                typeDelegation: delegationFormated.typeDelegation,
                avertissementMail: delegationFormated.avertissementMail,
                delaiAttente: delegationFormated.delaiAttente,
                destinataire: delegataire?.[0]
            });
            console.log(stateDelegations.delegationSelected)
        }
    }, [methods, open, stateDelegations]);

    const editDelegation = useCallback(async (data) => {
        const { isSucceed } = await editDelegationActionner(data)
        if(isSucceed) {
            setOpenEditDialog(false);
            refetch();
        }
    }, [editDelegationActionner, refetch, setOpenEditDialog]);
    
    return (
        <DialogContainer open={open} taille="md">
            <EditActions 
                isLoading={isLoading}
                setOpen={setOpen}
                editDelegation={() => methods.handleSubmit(editDelegation)()}
            />
            {isLoading ? 
                <Box sx={styles.loaderStyle}>
                    <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                </Box> 
            : 
                <FormProvider {...methods}>
                    <DelegationForm mode="edition" isLoadingDelegataire={isLoadingDelegataitre || isFetchingDelegataires} />
                </FormProvider>
        }
        </DialogContainer>
    );
}

EditDialog.defaultValues = {
    open: false, 
    setOpen: () => {},
    setOpenEditDialog: () => {},
    refetch: () => {}
}

EditDialog.propTypes = {
    open: PropTypes.bool, 
    setOpen: PropTypes.func, 
    setOpenEditDialog: PropTypes.func,
    refetch: PropTypes.func
}

const styles = {
    itemInterneNonCheckBoxStyle: {
        backgroundColor: "rgba(255,0,0,0.3)",
        borderRadius: 2,
        paddingX: 1,
        paddingY: 1
    },
    headerListStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.5)",
        color: "white",
        borderRadius: 2,
        padding: 2
    },
    itemListStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.2)",
        borderRadius: 2,
        paddingX: 2,
        paddingY: 1
    },
    loaderStyle: {
        height: 100,
        alignItems: "center",
        display: "flex"
    },
}

export default EditDialog;