import { urls } from "@constants/appConstants";
import axios from "axios";

export const fetchCatPro = async (client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetCategoriesProfessionnelles"
    };
    const response = await axios.get(urls.getCategoriesProfessionnelles, { headers: headers, params: { client: client }, signal: signal });
    return response.data.categoriesProfessionnelles;
}