import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { fetchDlg, fetchDla, fetchDrtDlg, addDlg, editDlg, deleteDlg } from "@api/delegations";
import { selectCltApp, selectDlgApp, selectUsrApp } from "@selectors";
import * as dlgAppActions from "@reducers/delegations/dlgAppReducer";
import { formToDataDelegation } from "@integrations";

export const useDlgApp = (type) => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const dispatch = useDispatch();

    const query =  useQuery(['dlgApp', type], ({ signal }) => fetchDlg(stateUser.matricule, type, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if(query.data) {
            dispatch(dlgAppActions.setDelegations(query.data));
        }
    }, [dispatch, query.data]);

    return query;
}

export const useDrtDlgApp = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const matricule = stateUser.matricule;

    return useQuery(['droits', matricule], ({ signal }) => fetchDrtDlg(matricule, stateClient.client, signal), {
        retry: false, refetchOnWindowFocus: false
    });
}

export const useDlaInp = (open) => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);

    return useQuery(['dlaInp'], ({ signal }) => fetchDla(stateUser.matricule,  stateUser.utilisateurAS400, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open
    });
}

export const useDla = (open) => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const dispatch = useDispatch();

    const query = useQuery(['dla'], ({ signal }) => fetchDla(stateUser.matricule,  stateUser.utilisateurAS400, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open
    });

    useEffect(() => {
        if(query.data) {
            dispatch(dlgAppActions.setDelegataires(query.data));
        }
    }, [dispatch, query.data]);

    return query;
}

export const usePostDelegation = () => {
    const { isLoading, mutateAsync } = useMutation(addDlg);
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const { enqueueSnackbar } = useSnackbar();

    const postDelegationActionner = async (data) => {
        let isSucceed = false;
        const formatedData = formToDataDelegation(data, stateClient.client, stateUser.matricule);
        try {
            await mutateAsync(formatedData);
            enqueueSnackbar("Délégation ajoutée avec succès.", { variant: "success" });
            isSucceed = true;
        } catch(e) {
            enqueueSnackbar(e?.response?.data?.details, { variant: "error" });
        }
        
        return { isSucceed }
    }

    return { isLoading, postDelegationActionner };
}

export const useEditDelegation = () => {
    const { isLoading, mutateAsync } = useMutation(editDlg);
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const stateDelegations = useSelector(selectDlgApp);
    const { enqueueSnackbar } = useSnackbar();

    const editDelegationActionner = async (data) => {
        let isSucceed = false;
        const id = stateDelegations?.delegationSelected?.numeroEnregistrement;
        const formatedData = formToDataDelegation(data, stateClient.client, stateUser.matricule, id);
        try {
            await mutateAsync(formatedData)
            enqueueSnackbar("Délégation modifiée avec succès.", { variant: "success" });
            isSucceed = true;
        } catch(e) {
            enqueueSnackbar(e?.response?.data?.details, { variant: "error" });
        }

        return { isSucceed }
    }

    return { isLoading, editDelegationActionner };
}

export const useDeleteDelegation = () => {
    const stateClient = useSelector(selectCltApp);
    const { isLoading, mutateAsync } = useMutation(deleteDlg);
    const { enqueueSnackbar } = useSnackbar();

    const postDeleteDelegation = async (data) => {
        let isSucceed = false;
        const formatedData = {
            dateDebut: data.dateDebut,
            responsable: data.responsable,
            codeNature: data.codeNature,
            client: stateClient.client
        };
        try {
            const response = await mutateAsync(formatedData);
            if(response.succeed) {
                enqueueSnackbar("Délégation supprimée avec succès.", { variant: "success" });
                isSucceed = true;
            } else {
                enqueueSnackbar(response?.details, { variant: "error" });
            }
        } catch(e) {
            enqueueSnackbar(e?.response?.data?.details, { variant: "error" });
        }

        return { isSucceed }
    }

    return { isLoading, postDeleteDelegation };
}
