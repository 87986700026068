import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { fetchRegMan, editRegMan, copyReg } from "@api/regularisations";
import { selectCltApp, selectColTemGesTem, selectDrgGesTem, selectRegGesTem, selectUsrApp } from "@selectors";
import * as regGesTemActions from "@reducers/gestionTemps/regularisations/regGesTemReducer";
import { dataToFormRegularisations, dataToFormTransactions, formToDataRegulariser } from "@integrations";
import { ROLE_UTILISATEUR } from "@constants/userConstants";

export const useRegGesTemPos = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const stateRegularisation = useSelector(selectRegGesTem);
    const stateCollaborateurs = useSelector(selectColTemGesTem);
    const { isLoading, mutateAsync } = useMutation(editRegMan);

    const postRegularisationManager = async (data, niveauValidation = "1") => {
        let codeRetour = "";
        let details = "";
        let isSuccess = false;
        let regularisations = [];
        try {
            const formatedData = formToDataRegulariser(stateUser.utilisateurAS400, stateUser.matricule, stateCollaborateurs.collaborateurSelected.matricule, stateRegularisation.dateRegularisation, data, stateClient.client, niveauValidation);
            const result = await mutateAsync(formatedData);
            isSuccess = result.succeed;
            codeRetour = result.codeRetour;
            details = result.details;
        } catch(e) {
            codeRetour = e?.response?.data?.codeRetour;
            details = e?.response?.data?.details;
            regularisations = e?.response?.data?.regularisations;
        }

        return { codeRetour, details, isSuccess, regularisations };
    }

    return { isLoading, postRegularisationManager };;
}

export const useDetRegGesTem = (open) => {
    const stateClient = useSelector(selectCltApp);
    const stateUser = useSelector(selectUsrApp);
    const stateCollaborateurs = useSelector(selectColTemGesTem);
    const stateRegularisation = useSelector(selectRegGesTem);
    const matriculeCollaborateur = stateCollaborateurs?.collaborateurSelected?.matricule;
    const typePersonnel = stateCollaborateurs?.collaborateurSelected?.typePersonnel;
    const date = stateRegularisation.dateRegularisation;
    const dispatch = useDispatch();

    const query = useQuery(['regGesTemMan', date], ({ signal }) => fetchRegMan(stateUser.utilisateurAS400, matriculeCollaborateur, typePersonnel, date, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!date && open
    });

    useEffect(() => {
        if(query.data) {
            dispatch(regGesTemActions.setDetailRegularisation(query.data));
        }
    }, [dispatch, query.data]);

    return query;
}

export const useCopRegGesTem = (userType) => {
    const stateClient = useSelector(selectCltApp);
    const stateUser = useSelector(selectUsrApp);
    const stateCollaborateurs = useSelector(selectColTemGesTem);
    const stateRegularisation = useSelector(selectRegGesTem);
    const stateDemandesRegularisation = useSelector(selectDrgGesTem);
    const { isLoading, mutateAsync } = useMutation(copyReg);
    const { enqueueSnackbar } = useSnackbar();

    const copyTransactions = () => {
        let codeRetour = 0;
        let regularisations = [];
        if(userType === ROLE_UTILISATEUR.COLLABORATEUR) {
            if(stateDemandesRegularisation.transactions.length > 0) {
                regularisations = dataToFormTransactions(stateDemandesRegularisation.transactions);
                codeRetour = 1;
            } else {
                enqueueSnackbar("Aucun mouvement à copier", { variant: "warning", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            }
        }
        if(userType === ROLE_UTILISATEUR.MANAGER) {
            if(stateDemandesRegularisation.transactions.length > 0) {
                regularisations = dataToFormTransactions(stateRegularisation.transactions);
                codeRetour = 1;
            } else {
                enqueueSnackbar("Aucun mouvement à copier", { variant: "warning", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            }
        }
        return { codeRetour, regularisations };
    }

    const copyHorairesOrPlanning = async (type) => {
        let codeRetour = 0;
        let regularisations = [];
        const data = {
            matricule: userType === ROLE_UTILISATEUR.MANAGER ? stateCollaborateurs?.collaborateurSelected?.matricule : stateUser.matricule, 
            typePersonnel: userType === ROLE_UTILISATEUR.MANAGER ? stateCollaborateurs?.collaborateurSelected?.typePersonnel : "P", 
            dateRegularisation: userType === ROLE_UTILISATEUR.MANAGER ? stateRegularisation.dateRegularisation : stateDemandesRegularisation.dateDemandeRegularisation, 
            typeCopie: type, 
            client: stateClient.client
        }
        try {
            const result = await mutateAsync(data);
            if(result.succeed) {
                if(result.regularisations.length > 0) {
                    regularisations = dataToFormRegularisations(result.regularisations)
                    codeRetour = 1;
                } else {
                    enqueueSnackbar("Aucun mouvement à copier", { variant: "warning", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
                }
            } else {
                enqueueSnackbar(result.details, { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            }
        } catch(e) {
            enqueueSnackbar(e?.response?.data?.details, { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
        }

        return { codeRetour, regularisations };
    }

    return { isLoading, copyTransactions, copyHorairesOrPlanning };
};