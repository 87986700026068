import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { CalendarMonth, Check, CompareArrows, ContentCopy, QueryBuilder, ViewTimeline } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import moment from "moment";
import voca from "voca";
import PropTypes from "prop-types";
import { selectColTemGesTem, selectRegGesTem } from "@selectors";
import { useActRegGesTemMan } from "@hooks/activites/hooksQueries";
import { useTypTraRegGesTem } from "@hooks/typesTravail/hooksQueries";
import { useCopRegGesTem, useDetRegGesTem, useRegGesTemPos } from "@hooks/gestionTemps/regularisations/hooksQueries";
import { DatePickerButton, IconButtonBase, MenuButton } from "@components/Buttons";
import { DialogFullScreenContainer, HeaderDialogContainer, HeaderListContainer, HeaderPanelContainer, TextContainer } from "@components/Containers";
import * as regGesTemActions from "@reducers/gestionTemps/regularisations/regGesTemReducer";
import { dataToFormRegularisationsManager, dataToFormRegularisationsManagerSended } from "@integrations";
import { RegularisationForm } from "@components/GestionTemps/Temps/Forms";
import { TransactionsList } from "@components/App/Transactions";
import { RegularisationsList } from "@components/App/Regularisations";
import { AnomaliesTransactionsAlertesDialog } from "@components/App/Anomalies";
import { ROLE_UTILISATEUR } from "@constants/userConstants";
import { greenColor, whiteColor } from "@styles";

const headersTransactions = [
    {id: 1, label: "Mouvement", xs: 1.5, display: true},
    {id: 2, label: "Date", xs: 1.5, display: true},
    {id: 3, label: "Heure Réelle", xs: 1.5, display: true},
    {id: 4, label: "Heure Ajustée", xs: 1.5, display: true},
    {id: 5, label: "Lecteur", xs: 1, display: true},
    {id: 6, label: "Activité", xs: 1.75, display: true},
    {id: 7, label: "Type Travail", xs: 2, display: true},
    {id: 8, label: "Anomalie", xs: 1.25, display: true}
]

const headersRegularisations = [
    {id: 1, label: "Mouvement", xs: 2, display: true},
    {id: 2, label: "Date", xs: 2, display: true},
    {id: 3, label: "Heure", xs: 2, display: true},
    {id: 4, label: "Activité", xs: 3, display: true},
    {id: 5, label: "Type Travail", xs: 3, display: true}
]

const defaultValues = {
    regularisations: [
        {mouvement: "", date: null, heure: "", activite: "", typeTravail: "", anomalies: ""},
        {mouvement: "", date: null, heure: "", activite: "", typeTravail: "", anomalies: ""},
        {mouvement: "", date: null, heure: "", activite: "", typeTravail: "", anomalies: ""},
        {mouvement: "", date: null, heure: "", activite: "", typeTravail: "", anomalies: ""},
    ]
}

const RegulariserDialog = (props) => {
    const { open, setOpen, openAnomalieTransaction, openPlagesHoraires, refetch } = props;
    const stateCollaborateurs = useSelector(selectColTemGesTem);
    const stateRegularisation = useSelector(selectRegGesTem);
    const [code, setCode] = useState("");
    const [detailError, setDetailError] = useState("");
    const [openAnomalie, setOpenAnomalie] = useState(false);
    const [type, setType] = useState("DR");    
    const methods = useForm({ mode: "onChange", defaultValues: defaultValues });
    const { isLoading: isLoadingPostRegularisation, postRegularisationManager } = useRegGesTemPos();
    const { isLoading: isLoadingActivites, isFetching: isFetchingActivites } = useActRegGesTemMan(open);
    const { isLoading: isLoadingTypesTravail, isFetching: isFetchingTypesTravail } = useTypTraRegGesTem(open, ROLE_UTILISATEUR.MANAGER);
    const { isLoading: isLoadingCopy, copyTransactions, copyHorairesOrPlanning } = useCopRegGesTem(ROLE_UTILISATEUR.MANAGER);
    const { isLoading: isLoadingDetailRegularisation, isFetching: isFetchingDetailRegularisation, isError: isErrorDetailRegularisation, error: errorDetailRegularisation } = useDetRegGesTem(open);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    useEffect(() => {
        if(open && type === "DR") {
            if(stateRegularisation?.regularisations && stateRegularisation?.activites && stateRegularisation?.typesTravail) {
                if(stateRegularisation?.regularisations?.length > 0) {
                    const regularisations = dataToFormRegularisationsManager(stateRegularisation?.regularisations, stateRegularisation.activites, stateRegularisation.typesTravail)
                    methods.setValue("regularisations", regularisations);
                } else {
                    methods.resetField("regularisations");
                }
            }
        }
    }, [methods, open, stateRegularisation.activites, stateRegularisation?.regularisations, stateRegularisation.typesTravail, type])

    const copyTransactionsActionner = useCallback(() => {
        setCode("");
        setDetailError("");
        setType("CR");
        const { codeRetour, regularisations } = copyTransactions();
        if(codeRetour === 1) {
            methods.resetField("regularisations", defaultValues.regularisations);
            methods.setValue("regularisations", regularisations);
        }
    }, [copyTransactions, methods]);

    const copyHorairesOrPlanningActionner = useCallback(async (type) => {
        setCode("");
        setDetailError("");
        setType("CR");
        const { codeRetour, regularisations } = await copyHorairesOrPlanning(type);
        if(codeRetour === 1) {
            methods.resetField("regularisations", defaultValues.regularisations);
            methods.setValue("regularisations", regularisations);
        }
    }, [copyHorairesOrPlanning, methods]);

    const selectDate =  useCallback((date) => {
        setCode("");
        setDetailError("");
        setType("DR");
        dispatch(regGesTemActions.setDateRegularisation(moment(date, "DD/MM/YYYY").format("DDMMYYYY")));
    }, [dispatch]);

    const validate = useCallback(async (data) => {    
        setType("VR");
        const { isSuccess, codeRetour, details, regularisations } = await postRegularisationManager(data);
        if(isSuccess) {
            setOpen(false);
            setType("DR");
            enqueueSnackbar("Régularisation effectuée avec succès", { variant: "success", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            refetch();
        } else {
            if(codeRetour === "AT") {
                setOpenAnomalie(true);
                if(regularisations) {
                    const regularisationsFormated = dataToFormRegularisationsManagerSended(regularisations, stateRegularisation.activites, stateRegularisation.typesTravail);
                    methods.setValue("regularisations", regularisationsFormated);
                }
            }
            setCode(codeRetour);
            setDetailError(details);
        }
    }, [enqueueSnackbar, methods, postRegularisationManager, refetch, setOpen, stateRegularisation.activites, stateRegularisation.typesTravail]);

    const validateWithAnomaliesToDelete = useCallback(async (data) => {
        const { isSuccess } = await postRegularisationManager(data, "4");
        if(isSuccess) {
            setOpen(false);
            setOpenAnomalie(false);
            setType("DR");
            enqueueSnackbar("Régularisation effectuée avec succès et anomalies supprimées.", { variant: "success", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            refetch();
        } else {
            enqueueSnackbar("Une erreur est survenue lors de la validation des anomalies.", { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
        }
    }, [enqueueSnackbar, postRegularisationManager, refetch, setOpen])

    const validateWithAnomaliesToKeep = useCallback(async (data) => {
        const { isSuccess } = await postRegularisationManager(data, "5");
        if(isSuccess) {
            setOpen(false);
            setOpenAnomalie(false);
            setType("DR");
            enqueueSnackbar("Régularisation effectuée avec succès et anomalies gardées.", { variant: "success", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            refetch();
        } else {
            enqueueSnackbar("Une erreur est survenue lors de la validation des anomalies.", { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
        }
    }, [enqueueSnackbar, postRegularisationManager, refetch, setOpen])

    const hanldeValidate = useCallback((type) => {
        setCode("");
        setDetailError("");
        if(type === "C") {
            methods.handleSubmit(validateWithAnomaliesToKeep)()
        }
        if(type === "D") {
            methods.handleSubmit(validateWithAnomaliesToDelete)();
        }
        setOpenAnomalie(false);
    }, [methods, validateWithAnomaliesToDelete, validateWithAnomaliesToKeep]);

    const close = useCallback(() => {
        setOpen(false);
        setType("DR");
    }, [setOpen]);

    return (
        <DialogFullScreenContainer open={open}>
            <HeaderDialogContainer 
                actionsLeft={
                    <Stack direction={"row"} spacing={0.5}>
                        <IconButtonBase icon={<ViewTimeline fontSize="small" />} placement="top" title={"Plages horaires"} action={() => openPlagesHoraires()} />
                        <DatePickerButton title="Choisir une date" timeout={600} value={moment(stateRegularisation.dateRegularisation, "DDMMYYYY")} action={selectDate} />
                    </Stack>
                } 
                title={"Régularisation"} 
                close={() => close()}     
            />
            <Stack spacing={0.5} bgcolor={greenColor["015"]} borderRadius={2} p={0.5}>
                <Box bgcolor={whiteColor[5]} p={1} borderRadius={2}>
                    <Typography fontSize="small">Collaborateur : <Box component={"span"} fontWeight="bold" display="inline">{stateCollaborateurs?.collaborateurSelected?.matricule} {stateCollaborateurs?.collaborateurSelected?.prenomNom}</Box></Typography>
                </Box>
                <Box bgcolor={whiteColor[5]} p={1} borderRadius={2}>
                    <Typography fontSize="small">Jour régularisé : <Box component={"span"} fontWeight="bold" display="inline">{voca.titleCase(moment(stateRegularisation.dateRegularisation, "DDMMYYYY").format("dddd DD MMMM YYYY"))}</Box></Typography>
                </Box>
            </Stack>
            <Box>
                <Grid spacing={0.5} container>
                    <Grid item xs={5.5}>
                        <Stack spacing={0.5} bgcolor={greenColor["015"]} p={0.5} borderRadius={2}>
                            <HeaderPanelContainer bgColor={whiteColor[5]} title="Transactions" minHeight={30} /> 
                            <HeaderListContainer itemIdReference="TRTMPS" headerItems={headersTransactions} />
                            <TransactionsList 
                                data={stateRegularisation?.transactions} 
                                isLoading={isLoadingDetailRegularisation || isFetchingDetailRegularisation} 
                                isError={isErrorDetailRegularisation} 
                                errorMessage={errorDetailRegularisation?.message} 
                                openAnomalies={openAnomalieTransaction} 
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={6.5}>
                        <Stack spacing={1} bgcolor={greenColor["015"]} p={0.5} borderRadius={2}>
                            <HeaderPanelContainer 
                                actionsLeft={
                                    (stateCollaborateurs?.collaborateurSelected && stateCollaborateurs?.collaborateurSelected?.conditionService === "M") && 
                                        <IconButtonBase icon={<Check fontSize="small" />} title={"Valider"} placement="top" action={methods.handleSubmit(validate)} />
                                }
                                actionsRight={
                                    (stateCollaborateurs?.collaborateurSelected && stateCollaborateurs?.collaborateurSelected?.conditionService === "M") &&
                                        <MenuButton txtId="BTCOPREG" icon={<ContentCopy fontSize="small" />} title="Menu copie" placement="top" options={[
                                                {id: 1, icon: <CompareArrows fontSize="small" />, label: "Copier les mouvements", action: () => copyTransactionsActionner()},
                                                {id: 2, icon: <QueryBuilder fontSize="small" />, label: "Copier les horaires", action: () => copyHorairesOrPlanningActionner("H")},
                                                {id: 3, icon: <CalendarMonth fontSize="small" />, label: "Copier le planning", action: () => copyHorairesOrPlanningActionner("P")}
                                            ]} 
                                        />
                                }
                                bgColor={whiteColor[5]}
                                minHeight={30}
                                title={stateCollaborateurs?.collaborateurSelected?.conditionService === "M" ? "Faire une régularisation" : "Régularisations"}
                            />
                            <Stack spacing={0.5}>
                                {stateCollaborateurs?.collaborateurSelected?.conditionService === "M" ?
                                    <FormProvider {...methods}>
                                        <RegularisationForm 
                                            isLoading={isLoadingDetailRegularisation || isFetchingDetailRegularisation || isLoadingCopy || isLoadingPostRegularisation || isLoadingActivites || isFetchingActivites || isLoadingTypesTravail || isFetchingTypesTravail}
                                            code={code} 
                                            setCode={setCode} 
                                            detailError={detailError} 
                                            setDetailError={setDetailError} 
                                            type={type} 
                                            setType={setType} 
                                        />
                                    </FormProvider>
                                :
                                    <>
                                        {(stateRegularisation?.regularisations[0]?.dateRegularisation && stateRegularisation?.regularisations[0]?.regularisateur) && 
                                            <TextContainer bgColor={whiteColor[5]} txtWeight="bold" p={1}>
                                                Régularisé par {stateRegularisation.regularisations[0].regularisateur} le {moment(stateRegularisation?.regularisations[0]?.dateRegularisation, "DMMYYYY").format("DD/MM/YYYY")}
                                            </TextContainer>
                                        }
                                        <Stack spacing={0.5} p={0} borderRadius={2}>
                                            <HeaderListContainer itemIdReference="RETMPS" headerItems={headersRegularisations} />
                                            <RegularisationsList 
                                                data={stateRegularisation?.regularisations} 
                                                isLoading={isLoadingDetailRegularisation || isFetchingDetailRegularisation} 
                                                isError={isErrorDetailRegularisation} 
                                                errorMessage={errorDetailRegularisation?.message} 
                                                anomalies={false} 
                                            />
                                        </Stack>
                                    </> 
                                }
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <AnomaliesTransactionsAlertesDialog 
                open={openAnomalie} 
                setOpen={setOpenAnomalie} 
                userType={ROLE_UTILISATEUR.MANAGER}                 
                validate={hanldeValidate} 
                isLoadingAnomalie={isLoadingPostRegularisation} 
            />
        </DialogFullScreenContainer>
    );
}

RegulariserDialog.defaultProps = {
    open: false,
    setOpen: () => {},
    openAnomalieTransaction: () => {},
    openPlagesHoraires: () => {},
    refetch: () => {}
}

RegulariserDialog.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    openAnomalieTransaction: PropTypes.func,
    openPlagesHoraires: PropTypes.func,
    refetch: PropTypes.func
}

export default RegulariserDialog;