import { Grid } from "@mui/material";
import Loader from "./Loader";

const LoaderBackground = () => {
    return (
        <Grid container className="App" sx={styles.containerStyle} spacing={0}>
            <Grid item xs={11} sm={6} lg={4}>
                <Loader />
            </Grid>
        </Grid>
    );
}

const styles = {
    containerStyle: {
        background: "linear-gradient(90deg, rgba(74,178,207,1) 0%, rgba(49,133,156,1) 50%, rgba(0,94,121,1) 100%)", 
        height: "100vh",
        justifyContent: "center", 
        alignItems: "center"
    }
}

export default LoaderBackground;