import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Typography, Paper, Stack, Tooltip, IconButton, Box } from "@mui/material";
import { Edit } from "@mui/icons-material";
import moment from "moment-timezone";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import { Scrollbars } from 'react-custom-scrollbars';
import { selectUsrApp } from "@selectors";
import DHorloge from "./components/DHorloge";
import { getWhiteColor, greenColor } from "@styles";
import { useTranslation } from "react-i18next";

const formatHeure = (date) => {
    const heures = Number(date.getHours()) >= 10 ? date.getHours() : `0${date.getHours()}`;
    const minutes = Number(date.getMinutes()) >= 10 ? date.getMinutes() : `0${date.getMinutes()}`;
    return heures + "h" + minutes;
};

const ClockTimeZone = ({ timeZone }) => {
    const [heure, setHeure] = useState(null);
    const [city, setCity] = useState("");

    useEffect(() => {
        const year = moment.tz(timeZone).get("year")   
        const month = moment.tz(timeZone).get("month")   
        const day = moment.tz(timeZone).get("day")   
        const hour = moment.tz(timeZone).get("hour")
        const minutes = moment.tz(timeZone).get("minute")
        const seconds = moment.tz(timeZone).get("second")
        const date = new Date(year, month, day, hour, minutes, seconds)
        setHeure(date);
        const timeZoneSplit = timeZone.split("/");
        const villeSplit = timeZoneSplit[timeZoneSplit.length - 1].split("_");
        setCity(villeSplit.join("-"));
    }, [timeZone]);

    useEffect(() => {
        const timer = setInterval(() => {
            const year = moment.tz(timeZone).get("year")   
            const month = moment.tz(timeZone).get("month")   
            const day = moment.tz(timeZone).get("day")   
            const hour = moment.tz(timeZone).get("hour")
            const minutes = moment.tz(timeZone).get("minute")
            const seconds = moment.tz(timeZone).get("second")
            const date = new Date(year, month, day, hour, minutes, seconds)
            setHeure(date);
        }, 1000);
        return () => clearInterval(timer);
    }, [timeZone]);

    return (
        <Box bgcolor={greenColor["015"]} padding={1} borderRadius={2}>
            <Stack direction="row" spacing={2}>
                {heure && 
                    <>
                        <Box>
                            <Clock value={heure} size={80} renderMinuteMarks={false} hourHandWidth={3} hourMarksWidth={1} secondHandLength={70} />
                        </Box>
                        <Stack spacing={1} display="flex" justifyContent="center">
                            <Box>
                                <Typography fontSize="normal" fontWeight="bold">{formatHeure(heure)}</Typography>
                            </Box>
                            <Box>
                                <Typography fontSize="normal" fontWeight="bold">{city}</Typography>
                            </Box>
                        </Stack>
                    </>
                }
            </Stack>
        </Box>
    );
}

const HorlogesWidget = () => {
    const [myHour, setMyHour] = useState(moment().tz(moment.tz.guess(true)).toDate());
    const [openDialogAddHorloge, setOpenDialogAddHorloge] = useState(false);
    const stateUser = useSelector(selectUsrApp);
    const { t } = useTranslation("global");

    useEffect(() => {
        const timer = setInterval(() => {
            setMyHour(moment().tz(moment.tz.guess(true)).toDate());
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const handleOpenDialogAddHorloge = useCallback(() => {
        setOpenDialogAddHorloge(true);
    }, []);

    return (
        <Paper sx={stateUser.background ? styles.paperStyle : styles.paperStyleNoBackground} elevation={0}>
            <Box height="192px" padding={0.5} overflow="auto">  
            <Scrollbars autoHide>
            <Stack spacing={0.5}>
                <Box bgcolor={greenColor["015"]} padding={1} borderRadius={2}>
                    <Stack direction="row" spacing={2}>
                        <Box>
                            <Clock value={myHour} size={80} renderMinuteMarks={false} hourHandWidth={3} hourMarksWidth={1} secondHandLength={70} />
                        </Box>
                        <Stack spacing={1} display="flex" justifyContent="center">
                            <Box>
                                <Typography fontSize="normal" fontWeight="bold">{formatHeure(myHour)}</Typography>
                            </Box>
                            <Box>
                                <Typography fontSize="normal" fontWeight="bold">{t("location.paris_city")}</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                </Box>
                {stateUser.horloges.map((timeZone) => (
                    <ClockTimeZone key={timeZone} timeZone={timeZone} />
                ))}    
                <Box bgcolor={greenColor["015"]} minWidth="70px" padding={1} borderRadius={2} alignItems="center" display="flex" justifyContent="center">
                    <Tooltip title="Modifier">
                        <IconButton size="small" onClick={handleOpenDialogAddHorloge}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                </Box>
                </Stack>
                </Scrollbars>
            </Box>
            <DHorloge open={openDialogAddHorloge} setOpen={setOpenDialogAddHorloge} />
        </Paper>
    );
}

const styles = {
    paperStyle: {
        backdropFilter: "blur(3px)",
        backgroundColor: getWhiteColor("0.95"),
        width: "100%",
        height: "200px",
        borderRadius: 2
    },
    paperStyleNoBackground: {
        backdropFilter: "blur(3px)",
        backgroundColor: greenColor["02"],
        width: "100%",
        height: "200px",
        borderRadius: 2
    }
};

export default HorlogesWidget;