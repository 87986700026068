import { Grid, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useStateTemGesTem } from "@hooks/gestionTemps/temps/hooksStates";
import { chiffreUSVersChiffreFR, heurifySigned } from "@utils";
import { HeaderListContainer, ListContainer, TextContainer } from "@components/Containers";
import { greenColor, whiteColor } from "@styles";

const jours = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];

const headers = [
    {id: 1, label: "", xs: 4, display: false},
    {id: 2, label: "Jour", xs: 4, display: true},
    {id: 3, label: "Cumul", xs: 4, display: true},
];

const DebitCreditsList = (props) => {
    const { userType } = props;
    const stateTemps = useStateTemGesTem(userType);

    return (
        <Stack spacing={0.5}>
            <HeaderListContainer itemIdReference="DEBCREGT" headerItems={headers} />
            <ListContainer spacing={0.5} length={7}>
                {stateTemps.debitCredits.map((debitCredit, id) => (
                    <Stack key={`REPTMPH${debitCredit.id}`} direction="row" textAlign="center" bgcolor={greenColor["015"]} p={0.25} borderRadius={2}>
                        <Grid container spacing={0.5}>
                            <Grid item xs={4}>
                                <TextContainer bgColor={greenColor["015"]} p={0.75} verticalAlign="center" horizontalAlign="center" textSize={"small"} textWeight="bold" height={"100%"}>{jours[id]}</TextContainer>
                            </Grid>
                            <Grid item xs={4}>
                                <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" horizontalAlign="center" textSize={11} textWeight="bold" height={"100%"}>{stateTemps.horaire ? heurifySigned(debitCredit.jour.heure) : chiffreUSVersChiffreFR(debitCredit.jour.chiffre)}</TextContainer>
                            </Grid>
                            <Grid item xs={4}>
                                <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" horizontalAlign="center" textSize={11} textWeight="bold" height="100%">{stateTemps.horaire ? heurifySigned(debitCredit.cumul.heure) : chiffreUSVersChiffreFR(debitCredit.cumul.chiffre)}</TextContainer>
                            </Grid>
                        </Grid>
                    </Stack>
                ))}
            </ListContainer>
        </Stack>
    );
}

DebitCreditsList.propTypes = {
    userType: PropTypes.string.isRequired
}

export default DebitCreditsList;