import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack, Drawer, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import { Close } from "@mui/icons-material";
import { routePolices } from "@constants/policesConstants";
import { selectTabApp, selectUsrApp } from "@selectors";
import * as tabAppActions from "@reducers/tabs/tabAppReducer"
import { redColor, whiteColor } from "@styles";
import { BaseButton } from "@components/Buttons";

const AppTabsMobile = (props) => {
    const { open, close } = props;
    const stateUser = useSelector(selectUsrApp); 
    const stateTabs = useSelector(selectTabApp);
    const dispatch = useDispatch();

    const closeTab = useCallback((id) => {
        let tabs = [...stateTabs.tabs];

        let prevTab = tabs[`${id-1}`].action;
        dispatch(tabAppActions.setActiveTab(prevTab));

        tabs.splice(id, 1);

        dispatch(tabAppActions.editTabs(tabs));
    }, [dispatch, stateTabs.tabs]);

    const handleTabs = useCallback((id) => {
        dispatch(tabAppActions.setActiveTab(id))
        close();
    }, [close, dispatch])

    return(
        <Drawer variant="temporary" anchor={"bottom"} open={open} onClose={close} PaperProps={{ sx: { width: "100%", maxHeight: "50%", backgroundColor: "transparent", p: 1, boxSizing: "border-box", boxShadow: "none" }}} sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
            <Stack spacing={0.5} padding={1} overflow="auto" boxSizing="border-box" bgcolor={whiteColor[9]} height="100%" borderRadius={2}>
                {stateTabs.tabs.map((tab, id) => (
                    <Stack key={`TABMOBILE${tab.action}`} spacing={2} direction="row" alignItems="center">
                        <Box width="100%">
                            <BaseButton 
                                color={stateTabs.activeTab === tab.action ? "green" : "white"} 
                                textAlign="left" 
                                font={routePolices[stateUser.police]} 
                                horizontalAlign="left" 
                                px={1.5} 
                                height={40} 
                                maxHeight={null} 
                                action={() => handleTabs(tab.action)}
                            >
                                {tab.label}
                            </BaseButton>
                        </Box>
                        {tab.action !== "ACC010000" &&
                            <Box>
                                <IconButton 
                                    size="small"
                                    sx={{ 
                                        color: redColor[7], 
                                        backgroundColor: redColor[1], 
                                        "&:hover": { 
                                            color: redColor[9],
                                            backgroundColor: redColor[3],
                                        }  
                                    }}
                                    onClick={() => closeTab(id)}
                                >
                                   <Close fontSize="small" />
                                </IconButton>
                            </Box>
                        }
                    </Stack>
                )) } 
            </Stack>
        </Drawer>
    );
}

AppTabsMobile.defaultProps = {
    open: false, 
    close: () => {}
}

AppTabsMobile.propTypes = {
    open: PropTypes.bool, 
    close: PropTypes.func
}

export default AppTabsMobile;