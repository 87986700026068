import PropTypes from "prop-types";
import { DialogContainer } from "@components/Containers";
import Contrainte from "./Contents/Contrainte";
import Recapitulatif from "./Contents/Recapitulatif";
import Condition from "./Contents/Condition";
import { ActionsCondition, ActionsContrainte, ActionsRecapitulatif } from "./Actions";
import { STEPS_DEMANDES_ABSENCE } from "@constants/demandesAbsenceConstants";

const DemandeAbsenceDialog = (props) => {
    const { 
        data, 
        isLoading, 
        isError,
        error,
        open, 
        messageFisrtCondition, 
        messageSecondCondition,
        fichier,
        step,
        close,
        postDemandeAbsenceRecapitulatif,
        postDemandeAbsenceCondition,
        postDemandeAbsenceContrainte
    } = props; 

    return (
        <DialogContainer open={open} placement="top" taille="sm">
            {{
                [STEPS_DEMANDES_ABSENCE.RECAPITULATIF]: 
                    <>
                        <ActionsRecapitulatif isLoading={isLoading} close={close} postDemandeAbsenceRecapitulatif={postDemandeAbsenceRecapitulatif} />
                        <Recapitulatif isLoading={isLoading} isError={isError} errorMessage={error?.response?.data?.details} data={data} fichier={fichier} />
                    </>,
                [STEPS_DEMANDES_ABSENCE.CONDITION]: 
                    <>
                        <ActionsCondition isLoading={isLoading} close={close} postDemandeAbsenceCondition={postDemandeAbsenceCondition} />
                        <Condition isLoading={isLoading} messageFisrtCondition={messageFisrtCondition} messageSecondCondition={messageSecondCondition} />
                    </>,
                [STEPS_DEMANDES_ABSENCE.CONTRAINTE]: 
                    <>
                        <ActionsContrainte isLoading={isLoading} close={close} postDemandeAbsenceContrainte={postDemandeAbsenceContrainte} />
                        <Contrainte codeSociete={error?.response?.data?.codeSociete} codeService={error?.response?.data?.codeService} codeContrainte={error?.response?.data?.codeContrainte} />
                    </>
            }[step]}
        </DialogContainer>
    );
}

DemandeAbsenceDialog.defaultProps = {
    data: null, 
    isLoading: true, 
    isError: false, 
    error: null, 
    open: false, 
    messageFisrtCondition: "", 
    messageSecondCondition: "",
    fichier: null,
    step: STEPS_DEMANDES_ABSENCE.RECAPITULATIF,
    close: () => {},
    postDemandeAbsenceRecapitulatif: () => {}, 
    postDemandeAbsenceCondition: () => {},
    postDemandeAbsenceContrainte: () => {}
}

DemandeAbsenceDialog.propTypes = {
    data: PropTypes.object, 
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool, 
    error: PropTypes.object, 
    open: PropTypes.bool, 
    messageFisrtCondition: PropTypes.string, 
    messageSecondCondition: PropTypes.string,
    fichier: PropTypes.object,
    step: PropTypes.string,
    close: PropTypes.func,
    postDemandeAbsenceRecapitulatif: PropTypes.func, 
    postDemandeAbsenceCondition: PropTypes.func, 
    postDemandeAbsenceContrainte: PropTypes.func
}

export default DemandeAbsenceDialog;