import { useSelector } from "react-redux";
import { Box, Grid, Grow, Stack } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { selectDlgApp } from "@selectors";
import { DialogFullScreenContainer, HeaderDialogContainer, HeaderListContainer, ItemContainer, ListContainer, TextContainer } from "@components/Containers";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";
import { useDeleteDelegation } from "@hooks/delegations/hooksQueries";
import { useCallback } from "react";
import { Check } from "@mui/icons-material";
import { IconButtonBase } from "@components/Buttons";

const headerItems = [
    {id: 1, label: "Nature", xs: 3, display: true, firstData: true, lastData: false},
    {id: 2, label: "Du", xs: 3, display: true, firstData: false, lastData: false},
    {id: 3, label: "Au", xs: 3, display: true, firstData: false, lastData: false},
    {id: 4, label: "Délégataire", xs: 3, display: true, firstData: false, lastData: true},
];

const DeleteDialogMobile = (props) => {
    const { open, setOpen, setOpenWarningDelete, refetch } = props;
    const stateDelegations = useSelector(selectDlgApp);
    const { isLoading, postDeleteDelegation } = useDeleteDelegation(); 

    const deleteDelegation = useCallback(async () => {
        const { isSucceed } = await postDeleteDelegation(stateDelegations.delegationSelected)
        if(isSucceed) {
            setOpenWarningDelete(false);
            refetch();
        }
    }, [postDeleteDelegation, refetch, setOpenWarningDelete, stateDelegations.delegationSelected]);

    return (
        <DialogFullScreenContainer open={open} taille="sm">
            <HeaderDialogContainer  
                actionsLeft={
                    <IconButtonBase variant="white5Grey9White9" icon={<Check fontSize="small" />} title="Valider" disabled={isLoading} action={() => deleteDelegation()} />
                }
                title="Suppression d'une délégation"
                close={() => setOpen(false)}
            />
            {isLoading ? 
                <Box sx={styles.loaderStyle}>
                    <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                </Box> 
            : 
                <Stack spacing={0.5} bgcolor={redColor[5]} borderRadius={2} p={0.5}>
                    <TextContainer bgColor={whiteColor[7]} p={1} textWeight="bold">Voulez-vous vraiment supprimer la délégations ci-dessous ?</TextContainer>
                    <HeaderListContainer itemIdReference="DDELEG" variant="light" headerItems={headerItems} />
                    <ListContainer spacing={0.5} length={1}>
                        {stateDelegations.delegationSelected &&
                            <Grow in={true} timeout={600}>
                                <Box bgcolor={whiteColor[5]} borderRadius={2} p={0.3}>
                                    <Grid container spacing={0.3}>
                                        <ItemContainer firstData data={stateDelegations?.delegationSelected?.libelleNature} xs={3} />
                                        <ItemContainer data={moment(stateDelegations?.delegationSelected?.dateDebut, "DDMMYYYY").format("DD/MM/YYYY")} xs={3} />
                                        <ItemContainer data={moment(stateDelegations?.delegationSelected?.dateReprise, "DDMMYYYY").format("DD/MM/YYYY")} xs={3} />
                                        <ItemContainer lastData data={stateDelegations?.delegationSelected?.delegataireLong} xs={3} />
                                    </Grid>
                                </Box>
                            </Grow>
                        }
                    </ListContainer>
                </Stack>
            }
        </DialogFullScreenContainer>
    );
}

DeleteDialogMobile.defaultProps = {
    open: false, 
    setOpen: () => {}, 
    setOpenWarningDelete: () => {},
    refetch: () => {}
}

DeleteDialogMobile.propTypes = {
    open: PropTypes.bool, 
    setOpen: PropTypes.func, 
    setOpenWarningDelete: PropTypes.func,
    refetch: PropTypes.func
}

const styles = {
    itemInterneNonCheckBoxStyle: {
        backgroundColor: "rgba(255,0,0,0.3)",
        borderRadius: 2,
        paddingX: 1,
        paddingY: 1
    },
    headerListStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.5)",
        color: "white",
        borderRadius: 2,
        padding: 2
    },
    itemListStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.2)",
        borderRadius: 2,
        paddingX: 2,
        paddingY: 1
    },
    loaderStyle: {
        height: 100,
        alignItems: "center",
        display: "flex"
    },
}

export default DeleteDialogMobile;