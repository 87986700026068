import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { useStateTemGesTem } from "@hooks/gestionTemps/temps/hooksStates";
import { greenColor } from "@styles";

const TempsHeaderTable = (props) => {
    const { userType } = props;
    const stateTemps = useStateTemGesTem(userType);
    const date = moment(stateTemps.dateTemps, "DDMMYYYY");

    return (
        <Grid container spacing={0.5}>
            <Grid item xs={5}>
                <Box sx={styles.boxTableauTitleStyle}>
                    <Typography fontSize={"small"} fontWeight="bold">Semaine {date.format("ww")} du {date.startOf("week").format("DD/MM/YYYY")} au {date.endOf('week').format("DD/MM/YYYY")}</Typography>
                </Box>
            </Grid>
            <Grid item xs={2.5}>
                <Box sx={styles.boxTableauTitleStyle}>
                    <Grid container>
                        <Grid item xs={12} textAlign="center">
                            <Typography fontSize={"small"} fontWeight="bold">Nombre d'heures</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign="center">
                            <Typography fontSize={"small"}>Badgées</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign="center">
                            <Typography fontSize={"small"}>Payées</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={2.5}>
                <Box sx={styles.boxTableauTitleStyle}>
                    <Grid container>
                        <Grid item xs={12}  textAlign="center">
                            <Typography fontSize={"small"} fontWeight="bold">Heures de nuit</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign="center">
                            <Typography fontSize={"small"}>Badgées</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign="center">
                            <Typography fontSize={"small"}>Payées</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
}

TempsHeaderTable.propTypes = {
    userType: PropTypes.string.isRequired
}

const styles = {
    boxTableauTitleStyle: {
        backgroundColor: greenColor["015"],
        p:1,
        borderRadius: 2,
        height: "calc(100% - 16px)",
        display: "flex",
        alignItems: "center"
    }
}

export default TempsHeaderTable;