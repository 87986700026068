import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useDeleteSousSectionEntretien } from "@hooks/gestionEntretiens/hooksQueries";
import { DialogContainer, HeaderDialogContainer, TextContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, whiteColor, yellowColor } from "@styles";
import { selectEntSecMan } from "@selectors";

const DeleteSousSectionDialog = ({ 
    open = false, 
    sousSection = null,
    setOpen = () => {},
    goBack = () => {}
}) => {
    const stateSections = useSelector(selectEntSecMan);
    const { isLoadingDelete, deleteSousSectionEntretienActionner } = useDeleteSousSectionEntretien();

    const deleteEntretien = useCallback(async () => {
        const { isSucceed } = await deleteSousSectionEntretienActionner(sousSection, stateSections?.sections?.length)
        if(isSucceed) {
            setOpen(false);
            goBack();
        }
    }, [deleteSousSectionEntretienActionner, goBack, setOpen, sousSection, stateSections?.sections?.length]);

    return (
        <DialogContainer open={open} taille="xs" placement="top">
            <HeaderDialogContainer  
                actionsLeft={
                    <IconButtonBase 
                        variant="dialogButton"
                        icon={<Check fontSize="small" />} 
                        title="Valider" 
                        disabled={isLoadingDelete} 
                        action={() => deleteEntretien()} 
                    />   
                }
                title="Suppression d'une sous section"
                close={() => setOpen(false)}
                variant="warning"
            />
            {isLoadingDelete ? 
                <Box sx={styles.loaderStyle}>
                    <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                </Box> 
            : 
                <Box spacing={0.5} bgcolor={yellowColor[5]} borderRadius={2} p={0.5}>
                    <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold">Voulez-vous vraiment supprimer cette sous section ?</TextContainer>
                </Box>
            }
        </DialogContainer>
    );
}

DeleteSousSectionDialog.propTypes = {
    open: PropTypes.bool, 
    sousSection: PropTypes.object, 
    setOpen: PropTypes.func,
    goBack: PropTypes.func
}

const styles = {
    itemInterneNonCheckBoxStyle: {
        backgroundColor: "rgba(255,0,0,0.3)",
        borderRadius: 2,
        paddingX: 1,
        paddingY: 1
    },
    headerListStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.5)",
        color: "white",
        borderRadius: 2,
        padding: 2
    },
    itemListStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.2)",
        borderRadius: 2,
        paddingX: 2,
        paddingY: 1
    },
    loaderStyle: {
        height: 100,
        alignItems: "center",
        display: "flex"
    },
}

export default DeleteSousSectionDialog;