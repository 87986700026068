import moment from "moment";
import voca from "voca";
import { TYPES_ERROR_INPUT } from "@constants/errorsConstants";
import { LABLE_PAGE } from "@constants/tabsConstants";

/**
 * Retourne la lecture ou non d'un fichier de façon asynchrone
 * @param {*} file - fichier à lire
 * @returns promesse fichier si lu ou non
 */
export const readFileAsync = (file) => {
    return new Promise((resolve, notResolve) => {
        let reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        }
        reader.onerror = notResolve;
        reader.readAsDataURL(file);
    });
}

/**
 * Retourne le chemin converti avec slash vers un fichier de l'AS400
 * @param {string} cheminAs400 - chemin à convertir avec anti slash
 * @returns {string} chemin converti avec slash
 */
export const convertCheminAs400 = (cheminAs400) => {
    const mots = cheminAs400.split("\\");
    let cheminJson = "";

    mots.forEach((mot, index) => {
        if(index > 2) {
            cheminJson = cheminJson + "/" + mot;
        }
    });

    return cheminJson;
}

/**
 * Retourne une heure entière convertie en string
 * @param {*} heureInt - heure entière
 * @returns {string} heure convertie en string
 */
export const heureIntVersHeureString = (heureInt) => {
    let heureString = "";
    let hour = moment.duration(heureInt, "hours").hours();
    let minutes = moment.duration(heureInt, "hours").minutes();
    if(parseFloat(moment.duration(heureInt, "hours").seconds()) >= 30 && parseFloat(moment.duration(heureInt, "hours").seconds()) < 59) {
        minutes = parseInt(moment.duration(heureInt, "hours").minutes()) + 1;
    } 
    if(parseInt(moment.duration(heureInt, "hours").minutes()) < 10) {
        minutes = "0" + minutes;
    }
    heureString = `${hour}h${minutes}`
    return heureString;
}

/**
 * Ajoute la meme longueur à chaque colonne d'un tableur
 * @param {number} nombreColumns - nombre de colonne à formater
 * @returns {object[]} tableau d'objet des colonnes
 */
export const formateColumnsTableur = (nombreColumns) => {
    const columns = [];
    for(let i = 0; i < nombreColumns; i++) {
        columns.push({width: 3})
    }
    return columns;
};

/**
 * Retourne une heure 4 digits formatée en fonction d'une heure d'entrée 4 digits
 * @param {string} heureBrut - heure à formater
 * @param {":" | "h"} heureOperator - opérateur entre heures, minutes et secondes
 * @param {boolean} heureRetour2Digit - heure retournée sur 2 digits ?
 * @returns {string} heure formatée
 */
export const heurify4 = (heureBrut, heureOperator, heureRetour2Digit) => {
    const heure4Digit = voca.count(heureBrut) < 4 ? `0${heureBrut}` : heureBrut;
    const heures2Digit = voca.substr(heure4Digit, 0, 2);
    const heures1Digit = voca.substr(heures2Digit, 0, 1) === "0" ? voca.substr(heures2Digit, 1, 1) : heures2Digit;
    const minutes2Digit = voca.substr(heure4Digit, 2);

    if(heureRetour2Digit) {
        return `${heures2Digit}${heureOperator}${minutes2Digit}`;
    } else {
        return `${heures1Digit}${heureOperator}${minutes2Digit}`;
    }
}

/**
 * Retourne une heure 6 digits formatée en fonction d'une heure d'entrée 6 digits
 * @param {string} heureBrut - heure à formater
 * @param {":" | "h"} heureOperator - opérateur entre heures, minutes et secondes
 * @param {boolean} heureRetour2Digit - heure retournée sur 2 digits ?
 * @returns {string} heure formatée
 */
export const heurify6 = (heureBrut, heureOperator, heureRetour2Digit) => {
    const heure6Digit = voca.count(heureBrut) < 6 ? `0${voca.substr(heureBrut, 0, 1)}${voca.substr(heureBrut, 1, 2)}${voca.substr(heureBrut, 3)}` : heureBrut;
    const heures2Digit = voca.substr(heure6Digit, 0, 2);
    const heures1Digit = voca.substr(heures2Digit, 0, 1) === "0" ? voca.substr(heures2Digit, 1, 1) : heures2Digit;
    const minutes2Digit = voca.substr(heure6Digit, 2, 2);
    const secondes2Digit = voca.substr(heure6Digit, 4);

    if(heureRetour2Digit) {
        return `${heures2Digit}${heureOperator}${minutes2Digit}${heureOperator}${secondes2Digit}`;
    } else {
        return `${heures1Digit}${heureOperator}${minutes2Digit}${heureOperator}${secondes2Digit}`;
    }
}

/**
 * Retourne une heure formatée en fonction d'une heure d'entrée
 * @param {string} heureBrut - heure à formater
 * @param {":" | "h"} heureOperator - opérateur entre heures, minutes et secondes
 * @param {boolean} heureRetour2Digit - heure retournée sur 2 digits ?
 * @param {boolean} seconds - présence secondes ?
 * @returns {string} heure formatée
 */
export const heurify = (heureBrut, heureOperator = ":", heureRetour2Digit = true, seconds = false) => {
    if(!heureBrut || heureBrut === "0") {
        return "";
    }
    if(seconds) {
        return heurify6(heureBrut, heureOperator, heureRetour2Digit);
    } else {
        return heurify4(heureBrut, heureOperator, heureRetour2Digit);
    }
}

export const heurifyBuild = (heure, minutes, heureOperator) => {
    const heures2Digit = heure.length < 2 ? `0${heure}` : heure;
    const minutes2Digit = minutes.length < 2 ? `0${minutes}` : minutes;

    return `${heures2Digit}${heureOperator}${minutes2Digit}`;
}

/**
 * Retourne une heure signée en fonction d'une heure signée d'entrée
 * @param {string} heureBrut - heure signée à formater
 * @returns {string} heure formatée et signée
 */
export const heurifySigned = (heureBrut) => {
    const signe = heureBrut[0];
    if(heureBrut.length === 6) {
        const heure = Number(voca.substr(heureBrut, 1, 2));
        const reste = voca.substr(heureBrut, 3);
        return `${signe}${heure}${reste}`;
    } 
    if(heureBrut.length === 7) {
        const heure = Number(voca.substr(heureBrut, 1, 3));
        const reste = voca.substr(heureBrut, 4);
        return `${signe}${heure}${reste}`;
    }
}

/**
 * Retourne une date formatée au format selectionné en fonction d'une heure d'entrée
 * @param {string} dateBrut - date a formater
 * @param {"DD/MM/YYYY" | "ddddDDmmmmyyyyw" | "tddddDDmmmmyyyy" | "ddddDDmmmmyyyy" | "moment"} formatRetour - format retour de la fonction
 * @returns {string|object} date formatée
 */
export const datify = (dateBrut, formatRetour = "DD/MM/YYYY") => {
    if((!dateBrut || dateBrut === "0" || dateBrut === "00000000") && formatRetour !== "moment") {
        return "";
    }

    const date8Digit = voca.count(dateBrut) < 8 ? `0${dateBrut}` : dateBrut;
    const dateMoment = moment(date8Digit, "DDMMYYYY");
    const jour = dateMoment.format("DD");
    const jourLibelle = dateMoment.format("dddd");
    const mois = dateMoment.format("MM");    
    const moisLibelle = dateMoment.format("MMMM");
    const semaine = dateMoment.format("ww");
    const annee = dateMoment.format("YYYY");

    switch (formatRetour) {
        case "ddddDDmmmmyyyyw":
            return `${jourLibelle} ${jour} ${moisLibelle} ${annee} (Semaine ${semaine})`;
        case "tddddDDmmmmyyyy": 
            return voca.titleCase(`${jourLibelle} ${jour} ${moisLibelle} ${annee}`);
        case "ddddDDmmmmyyyy":
            return `${jourLibelle} ${jour} ${moisLibelle} ${annee}`;
        case "moment":
            return dateMoment;
        default: 
            return `${jour}/${mois}/${annee}`;
    }
}

/**
 * Retourne un chiffre converti au français à partir d'un chiffre au format américain
 * @param {number|string} chiffre - chiffre à convertir
 * @returns {number|string} chiffre converti
 */
export const chiffreUSVersChiffreFR = (chiffre) => {
    let retour = "";
    if(chiffre !== 0 && chiffre !== "") {
        retour = parseFloat(chiffre).toFixed(2).replace(".", ",");
    }
    return retour;
};

/**
 * Retourne un chiffre converti au français à partir d'un chiffre au format américain
 * @param {number|string} chiffre - chiffre à convertir
 * @returns {number|string} chiffre converti
 */
export const chiffreStrUSVersChiffreStrFR = (chiffre) => {
    let retour = "";
    if(chiffre !== 0 && chiffre !== "") {
        retour = chiffre.replace(".", ",");
    }
    return retour;
};

/**
 * Retourne un chiffre converti en heure
 * @param {number|string} chiffre - chiffre à convertir
 * @returns {number|string} chiffre converti
 */
export const chiffreUSVersHeure = (chiffre) => {
    let retour = "";
    if(chiffre !== 0 && chiffre !== "") {
        retour = parseFloat(chiffre).toFixed(2).replace(".", ":");
    }
    return retour;
};

export const getLongueurPlageVariable = (plageDebut, plageReprise) => {
    if(plageDebut === "O" && plageReprise === "O") {
        return "70%";
    }
    if(plageDebut === "O") {
        return "85%";
    }
    if(plageReprise === "O") {
        return "85%";
    }
    return "100%";
};

export const getPositionPlageVariable = (plageDebut, plageReprise) => {
    if(plageDebut === "O" && plageReprise === "O") {
        return "center";
    }
    if(plageDebut === "O") {
        return "right";
    }
    if(plageReprise === "O") {
        return "left";
    }
    return "center";
};

export const getTypePlage = (plageDebut, plageReprise) => {
    if(plageDebut === "O" && plageReprise === "O") {
        return "variable";
    }
    if(plageDebut === "O") {
        return "variableDebut";
    }
    if(plageReprise === "O") {
        return "variableReprise";
    }
    return "noVariable";
};

export const getNoPlage = (plages) => {
    const conditionMatin = !plages.debutPlageVariableMatin && !plages.debutPlageFixeMatin && !plages.reprisePlageFixeMatin && !plages.reprisePlageVariableMatin;
    const conditionApresMidi = !plages.debutPlageVariableApresMidi && !plages.debutPlageFixeApresMidi && !plages.reprisePlageFixeApresMidi && !plages.reprisePlageVariableApresMidi;
    return (conditionMatin && conditionApresMidi);
};

export const getWidgetAdded = (widget, widgetsActives, widgetsNonActives, widgetsConfiguration, positionSelectionnee) => {
    const emplacement = widgetsNonActives.findIndex((w) => w === widget);
    
    let updateWidgetsNonActives = [...widgetsNonActives];
    let updateWidgetsConfiguration = [...widgetsConfiguration];
    let updateWidgetsActives = [...widgetsActives, widget];

    if(emplacement >= 0) {
        updateWidgetsNonActives.splice(emplacement, 1);
    }

    updateWidgetsConfiguration.splice(positionSelectionnee, 1, widget)
    
    return { widgetsConfiguration: updateWidgetsConfiguration, widgetsActives: updateWidgetsActives, widgetsNonActives: updateWidgetsNonActives };
}

export const getWidgetRemoved = (widget, widgetsActives, widgetsNonActives, widgetsConfiguration) => {
    const emplacement = widgetsActives.findIndex((w) => w === widget);
    const emplacementConfiguration = widgetsConfiguration.findIndex((w) => w === widget);
    
    let updateWidgetsActives = [...widgetsActives];
    let updateWidgetsConfiguration = [...widgetsConfiguration];
    let updateWidgetsNonActives = [...widgetsNonActives, widget];
    
    if(emplacement >= 0) {
        updateWidgetsActives.splice(emplacement, 1);
    }

    if(emplacementConfiguration >= 0) {
        updateWidgetsConfiguration.splice(emplacementConfiguration, 1, "")
    }

    return { widgetsConfiguration: updateWidgetsConfiguration, widgetsActives: updateWidgetsActives, widgetsNonActives: updateWidgetsNonActives };
}

export const getShortcutAdded = (shortcut, shortcutsActives, shortcutsNonActives) => {
    const emplacement = shortcutsNonActives.findIndex((s) => s === shortcut);
    
    let updateShortcutsNonActives = [...shortcutsNonActives];
    let updateShortcutsActives = [...shortcutsActives];

    if(emplacement >= 0) {
        updateShortcutsNonActives.splice(emplacement, 1);
    }

    updateShortcutsActives.push(shortcut);

    return { shortcutsConfiguration: updateShortcutsActives, shortcutsActives: updateShortcutsActives, shortcutsNonActives: updateShortcutsNonActives };
}


export const getShortcutRemoved = (shortcut, shortcutsActives, shortcutsNonActives) => {
    const emplacement = shortcutsActives.findIndex((s) => s === shortcut);
    
    let updateShortcutsNonActives = [...shortcutsNonActives];
    let updateShortcutsActives = [...shortcutsActives];

    if(emplacement >= 0) {
        updateShortcutsActives.splice(emplacement, 1);
    }

    updateShortcutsNonActives.push(shortcut);

    return { shortcutsConfiguration: updateShortcutsActives, shortcutsActives: updateShortcutsActives, shortcutsNonActives: updateShortcutsNonActives };
}

export const getErrorsMessagesInput = (name, type) => {
    if(type === TYPES_ERROR_INPUT.REQUIRED) {
        return `${name} obligatoire.`;
    }

    if(type === TYPES_ERROR_INPUT.DATE_INVALIDE) {
        return `${name} invalide.`;
    }

    if(type === TYPES_ERROR_INPUT.DATE_SUPERIEUR) {
        return `${name} doit être supérieure à la date de début.`;
    }

    return "";
}   

export const getTabs = (stateTabs, id) => {
    let tabs = [...stateTabs];

    let prevTab = tabs[`${id-1}`].action;
    
    tabs.splice(id, 1);

    return {prevTab: prevTab, tabs: tabs};
};

export const addTabs = (action, tabs) => {
    const label = LABLE_PAGE[action] ? LABLE_PAGE[action] : "";
    const tab = { label: label, action: action };
    
    if(!tabs.some(tabR => tabR.action === action)) {
        tabs.push(tab);
    } 

    return tabs;
};

export const isOptionEqualToValue = (option, value) => {
    if(option.value === value.value) {
        return true
    } 
}

export const getOptionLabel = (option) => option.label ? option.label : "";

export const hasAnomalies = (regularisations) => {
    let hasAnomalie = false;
    regularisations.forEach((regularisation) => {
        if(regularisation.presenceAnomalie === "*") {
            hasAnomalie = true;
        }
    });
    
    return hasAnomalie;
};

export const commentify = (commentaire) => {
    const words = voca.split(commentaire, " ");
    let lines = [];
    let line = ""
    words.forEach((word, id) => {
        let wordEnCours = ` ${word.trim()}`;
        if(id === 0) {
            wordEnCours = word;
        }
        let lineEnCours = line + wordEnCours;
        
        if(voca.count(lineEnCours) > 80) {
            lines.push(line);
            line = word;
        }
        if(voca.count(lineEnCours) <= 80) {
            line += wordEnCours;
            if(words.length === id + 1) {
                lines.push(line);
            }
        } 
        
    });

    return lines;
};

export const getFilename = (chemin) => {
    const words = voca.split(chemin, "\\");

    return words.length > 0 ? words[words.length - 1] : "";
};