import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { selectEntSecMan } from "@selectors";
import { useChampEvaluation } from "@hooks/gestionEntretiens/hooksUtils";
import { SelectObjectHookFormInput } from "@components/Inputs";
import { greenColor, whiteColor } from "@styles";

const ChampEvaluation = () => {
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;
    const { displayA, displayE, displayV, optionsCE  } = useChampEvaluation();    

    if(displayA || displayE) {
        return (
            <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                <Box borderRadius={2} bgcolor={whiteColor[7]} p={0.5}>
                    <SelectObjectHookFormInput name="evaluation" label={"Évaluation"} options={optionsCE} />
                </Box> 
            </Box>
        );
    }

    if(displayV) {
        return (
            <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                <Box borderRadius={2} bgcolor={whiteColor[7]} p={1}>
                    {sectionSelected?.evaluation ? 
                        <Typography fontSize="small">Evaluation : {sectionSelected?.designationEvaluation}</Typography>
                    :
                        <Typography fontSize="small" fontWeight="bold">Aucune évaluation</Typography>
                    }
                </Box>
            </Box>
        );
    }

    return null;
}

export default ChampEvaluation;