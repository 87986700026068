import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { selectActAffGesTem, selectAffActInd, selectColAffActInd } from "@selectors";
import { TextContainer } from "@components/Containers";
import { DateHookFormInput } from "@components/Inputs";
import { whiteColor } from "@styles";
import { ActivitesAffectationsIndividuellesInput } from "@components/Inputs/Activites";

const AffectationIndividuelleActiviteForm = (props) => {
    const { mode } = props;
    const { watch, setValue } = useFormContext();
    const stateAffectations = useSelector(selectAffActInd); 
    const stateCollaborateurs = useSelector(selectColAffActInd); 
    const stateActivites = useSelector(selectActAffGesTem); 
    const date = watch("date");
    
    useEffect(() => {
        if(mode === "edition" && stateAffectations.affecationSelected) {
            const date = moment([stateAffectations?.affecationSelected?.anneeEffet, stateAffectations?.affecationSelected?.moisEffet - 1, stateAffectations?.affecationSelected?.jourEffet]);
            if(date.isValid()) {
                setValue("date", date);
            }
        }
        if(mode === "edition" && stateActivites.activitesAffectations) {
            const activite = stateActivites.activitesAffectations.find((activite) => activite.codeActivite === stateAffectations?.affecationSelected?.codeActivite);
            setValue("activite", activite);
        }
    }, [setValue, stateAffectations.affecationSelected, mode, stateActivites.activitesAffectations]);

    return (
        <Stack spacing={1}>
            {mode === "edition" ?
                <TextContainer p={1} bgColor={whiteColor[5]} textWeight="bold">Date de l'affectation : {date?.format("DD/MM/YYYY")}</TextContainer>
            :   
                <DateHookFormInput name="date" label="Date" /> 
            }
            <ActivitesAffectationsIndividuellesInput 
                name="activite" 
                label="Activité" 
                codeSociete={stateCollaborateurs?.collaborateurSelected?.codeSociete}
                date={date}
                tooltipPlacement="left"
            />
        </Stack>
    );
}

AffectationIndividuelleActiviteForm.defaultProps = { 
    mode: "ajout"
}

AffectationIndividuelleActiviteForm.propTypes = {
    mode: PropTypes.string,
}

export default AffectationIndividuelleActiviteForm;