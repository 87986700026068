import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Stack } from "@mui/material";
import * as colAffActIndActions from "@reducers/collaborateurs/colAffActIndReducer";
import * as affActIndActions from "@reducers/gestionTemps/affectations/affActIndReducer";
import { CollaborateursBar } from "@components/App/Collaborateurs/Bars";
import { selectAffActInd, selectColAffActInd } from "@selectors";
import { greenColor } from "@styles";
import { CollaborateursDialog } from "@components/App/Collaborateurs/Dialogs";
import { TYPES_COLLABORATEURS, TYPES_FICHIER } from "@constants/collaborateursConstants";
import moment from "moment";
import { Badge, Event, Person } from "@mui/icons-material";
import { AddAffectationDialog, DeleteAffectationDialog, EditAffectationDialog } from "../Dialogs";
import { ActionsAffectationsActivitesIndividuelles } from "./ActionsBar";
import { trierAffActInd } from "./tries";
import { MenuFiltreContainer, MenuTrieContainer } from "@components/Containers";
import { useAddAff, useFetchAffActInd, useDeleteAff, useEditAff } from "@hooks/gestionTemps/affectations/hooksQueries";
import { FiltreAffectationsActivitesIndividuellesForm } from "./Forms";
import { AffectationsAcitivesIndividuellesList } from "./Lists";
import { TYPES_AFFECTATIONS } from "@constants/affectationsConstants";

const headersFwgtpe = [
    {id: 1, label: "Nom", xs: 1.5, display: true, firstData: true, lastData: false},
    {id: 2, label: "Matricule", xs: 0.75, display: true, firstData: false, lastData: false},
    {id: 3, label: "Badge", xs: 0.75, display: true, firstData: false, lastData: false},
    {id: 4, label: "Société", xs: 1, display: true, firstData: false, lastData: false},
    {id: 5, label: "Etablissement", xs: 1, display: true, firstData: false, lastData: false},
    {id: 6, label: "Direction", xs: 1.5, display: true, firstData: false, lastData: false},
    {id: 7, label: "Service", xs: 1.5, display: true, firstData: false, lastData: false},
    {id: 8, label: "Secteur", xs: 1, display: true, firstData: false, lastData: false},
    {id: 9, label: "Type", xs: 1, display: true, firstData: false, lastData: false},
    {id: 10, label: "Contôle horaire", xs: 0.75, display: true, firstData: false, lastData: false},
    {id: 11, label: "Présent", xs: 0.75, display: true, firstData: false, lastData: true}
];

const optionsTrie = [
    {id: 1, label: "Par date", keyAsc: "dateAsc", keyDesc: "dateDesc", icon: <Event fontSize="small" />},
    {id: 2, label: "Par code horaire", keyAsc: "horaireAsc", keyDesc: "horaireDesc", icon: <Person fontSize="small" />},
    {id: 3, label: "Par statut", keyAsc: "statutAsc", keyDesc: "statutDesc", icon: <Badge fontSize="small" />}
];

const AffectationsActivitesIndividuel = () => {
    const [openCollaborateursDialog, setOpenCollaborateursDialog] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openFiltre, setOpenFiltre] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const stateColAffActInd = useSelector(selectColAffActInd);
    const stateAffActInd = useSelector(selectAffActInd);
    const { isLoading, isFetching, isError, refetch } = useFetchAffActInd();
    const { isLoadingActivite: isLoadingAdd, addAffActActionner } = useAddAff();   
    const { isLoadingActivite: isLoadingEdit, editAffActActionner } = useEditAff();    
    const { isLoadingActivite: isLoadingDelete, deleteAffActActionner } = useDeleteAff();   
    const dispatch = useDispatch();

    const selectCollaborateur = useCallback(async (collaborateur) => {
        setOpenCollaborateursDialog(false);
        dispatch(colAffActIndActions.selectCollaborateur(collaborateur));
    }, [dispatch]);

    const lister = useCallback(() => {
        dispatch(affActIndActions.lister());
    }, [dispatch]);

    const deleteFiltre = useCallback(() => {
        dispatch(affActIndActions.deleteFiltre());
    }, [dispatch]);

    const deleteTrie = useCallback(() => {
        dispatch(affActIndActions.deleteTrie());
    }, [dispatch]);

    const trier = useCallback((keyTrie) => {
        const data = [...stateAffActInd.affectations];
        const dataTried = trierAffActInd(keyTrie, data);
        dispatch(affActIndActions.trier(dataTried));
    }, [dispatch, stateAffActInd.affectations]);

    const openEdit = useCallback((affectation) => {
        dispatch(affActIndActions.selectAffectation(affectation));
        setOpenEditDialog(true);
    }, [dispatch]);

    const openDelete = useCallback((affectation) => {
        dispatch(affActIndActions.selectAffectation(affectation));
        setOpenDeleteDialog(true);
    }, [dispatch]);

    const addAffectation = useCallback(async (data) => {
        const { isSuccessPost } = await addAffActActionner(data);
        if(isSuccessPost) {
            refetch();
            setOpenAddDialog(false);
        }
    }, [addAffActActionner, refetch]);

    const editAffectation = useCallback(async (data) => {
        const { isSuccessEdit } = await editAffActActionner(data);
        if(isSuccessEdit) {
            refetch();
            setOpenEditDialog(false);
        }
    }, [editAffActActionner, refetch]);

    const deleteAffectation = useCallback(async () => {
        const { isSuccessDelete } = await deleteAffActActionner();
        if(isSuccessDelete) {
            refetch();
            setOpenDeleteDialog(false);
        }
    }, [deleteAffActActionner, refetch]);
    
    return (
        <>
            <Stack spacing={0.5} bgcolor={greenColor["015"]} borderRadius={2} p={0.5} height="100%" boxSizing="border-box">
                <CollaborateursBar collaborateur={stateColAffActInd?.collaborateurSelected} openDialog={() => setOpenCollaborateursDialog(true)} />
                {stateColAffActInd.collaborateurSelected && 
                    <>
                        <ActionsAffectationsActivitesIndividuelles 
                            readOnly={stateColAffActInd?.collaborateurSelected?.conditionService !== "M"}
                            listed={stateAffActInd.listed}
                            filtered={stateAffActInd.filtered} 
                            tried={stateAffActInd.tried}
                            deleteFiltre={deleteFiltre}
                            deleteTrie={deleteTrie}
                            lister={lister}
                            setOpenAdd={setOpenAddDialog}
                            setAnchorEl={setAnchorEl}
                            setOpenFiltre={setOpenFiltre}
                            refetch={refetch}
                        />
                        <AffectationsAcitivesIndividuellesList 
                            data={stateAffActInd.affectationsDisplayed}    
                            isLoading={isLoading || isFetching}
                            isError={isError}
                            listed={stateAffActInd.listed}
                            readOnly={stateColAffActInd?.collaborateurSelected?.conditionService !== "M"}
                            openEdit={openEdit}
                            openDelete={openDelete}
                        />    
                        <MenuTrieContainer 
                            anchorEl={anchorEl} 
                            setAnchorEl={setAnchorEl} 
                            options={optionsTrie} 
                            trier={trier} 
                        />        
                        <MenuFiltreContainer open={openFiltre} setOpen={setOpenFiltre}>
                            <FiltreAffectationsActivitesIndividuellesForm setOpen={setOpenFiltre} />
                        </MenuFiltreContainer>   
                    </>
                }
            </Stack>
            <CollaborateursDialog 
                stateType={TYPES_COLLABORATEURS.AFFECTATIONS_ACTIVITES_INDIVIDUELLES}
                listType={TYPES_FICHIER.FWGTPE} 
                open={openCollaborateursDialog}  
                dateValidite={stateColAffActInd?.dateValidite ? moment(stateColAffActInd.dateValidite, "DDMMYYYY").format("DDMMYYYY") : moment()}
                headers={headersFwgtpe}
                displayDate={false}
                tried={stateColAffActInd.tried}
                setOpen={setOpenCollaborateursDialog}  
                selectCollaborateur={selectCollaborateur}
            />
            <EditAffectationDialog 
                isLoading={isLoadingEdit}
                typeAffectations={TYPES_AFFECTATIONS.ACTIVITES_INDIVIDUEL} 
                open={openEditDialog} 
                setOpen={setOpenEditDialog} 
                editAffectation={editAffectation} 
            />
            <AddAffectationDialog 
                isLoading={isLoadingAdd}
                typeAffectations={TYPES_AFFECTATIONS.ACTIVITES_INDIVIDUEL} 
                open={openAddDialog} 
                setOpen={setOpenAddDialog} 
                addAffectation={addAffectation} 
            />
            <DeleteAffectationDialog 
                isLoading={isLoadingDelete}
                typeAffectations={TYPES_AFFECTATIONS.ACTIVITES_INDIVIDUEL} 
                anneeEffet={stateAffActInd?.affecationSelected?.anneeEffet}
                moisEffet={stateAffActInd?.affecationSelected?.moisEffet}
                jourEffet={stateAffActInd?.affecationSelected?.jourEffet}
                codeHoraire={stateAffActInd?.affecationSelected?.codeHoraire}
                libelleStatut={stateAffActInd?.affecationSelected?.libelleStatut}
                codeActivite={stateAffActInd?.affecationSelected?.codeActivite}
                typePersonnel={stateColAffActInd?.collaborateurSelected?.typePersonnel}
                open={openDeleteDialog} 
                setOpen={setOpenDeleteDialog} 
                deleteAffectation={deleteAffectation} 
            />
            
        </>
    );
};

export default AffectationsActivitesIndividuel;