import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import * as sitSolAppActions from "@reducers/soldes/sitSolAppReducer"
import * as dabGesAbsManActions from "@reducers/gestionAbsences/demandes/dabGesAbsManReducer"
import { useMulSolGesAbsMan } from "@hooks/gestionAbsences/soldes/hooksQueries";
import { MultiSoldesActionsBar } from "./ActionsBars";
import { MultiSoldesList, MultiSoldesListMobile } from "./Lists";
import { SituationSoldeDialog } from "@components/App/Soldes";
import { useMedia } from "react-use";

const MultiSoldesPage = () => {
    const [openSituationSolde, setOpenSituationSolde] = useState(false);
    const { data, isLoading, isFetching, isError, error, refetch } = useMulSolGesAbsMan()
    const dispatch = useDispatch();
    const isMobile = useMedia('(max-width: 650px)');

    const goTo = useCallback((page) => {
        dispatch(dabGesAbsManActions.goTo(page))
    }, [dispatch])

    const openSituation = useCallback((matricule, periode, solde) => {
        dispatch(sitSolAppActions.selectSolde({periode: periode, solde: solde, matricule: matricule}));
        setOpenSituationSolde(true);
    }, [dispatch]); 

    return (
        <>
            <MultiSoldesActionsBar 
                isLoading={isLoading || isFetching}
                goTo={goTo}
                refetch={refetch}
            />
            {isMobile ? 
                <MultiSoldesListMobile 
                    data={data}
                    isLoading={isLoading || isFetching}
                    isError={isError}
                    errorMessage={error?.message}
                    openSituation={openSituation}
                />
            :
                <MultiSoldesList 
                    data={data}
                    isLoading={isLoading || isFetching}
                    isError={isError}
                    errorMessage={error?.message}
                    openSituation={openSituation}
                    goTo={goTo}
                    refetch={refetch}
                />
            }
            <SituationSoldeDialog 
                open={openSituationSolde} 
                setOpen={setOpenSituationSolde} 
            />
        </>
    );
}

export default MultiSoldesPage;