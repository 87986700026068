import { filter, tidy } from "@tidyjs/tidy";
import moment from "moment";

export const filtrerDrgGesTemCol = (data, dataBrut) => {
    let dataFiltered = [...dataBrut];
    switch(data.colonne) {
        case "date":
            switch(data.operateur) {
                case "egale":
                    return tidy(dataFiltered, filter(regularisation => moment(regularisation.dateDemande, "YYYYMMDD").isSame(data.date)));
                case "superieur":
                    return tidy(dataFiltered, filter(regularisation => moment(regularisation.dateDemande, "YYYYMMDD").isSameOrAfter(data.date)));
                case "inferieur":
                    return tidy(dataFiltered, filter(regularisation => moment(regularisation.dateDemande, "YYYYMMDD").isSameOrBefore(data.date)));
                default: 
                    return dataFiltered
            }
        case "heure":
            switch(data.operateur) {
                case "egale":
                    return tidy(dataFiltered, filter(regularisation => moment(regularisation.heureDemande, "Hmm").isSame(moment(data.txt, "HH:mm"))));
                case "superieur":
                    return tidy(dataFiltered, filter(regularisation => moment(regularisation.heureDemande, "Hmm").isSameOrAfter(moment(data.txt, "HH:mm"))));
                case "inferieur":
                    return tidy(dataFiltered, filter(regularisation => moment(regularisation.heureDemande, "Hmm").isSameOrBefore(moment(data.txt, "HH:mm"))));
                default: 
                    return dataFiltered
            }
        case "dateRegularisation":
            switch(data.operateur) {
                case "egale":
                    return tidy(dataFiltered, filter(regularisation => moment([regularisation.anneeRattachement, regularisation.moisRattachement - 1, regularisation.jourRattachement]).isSame(data.date)));
                case "superieur":
                    return tidy(dataFiltered, filter(regularisation => moment([regularisation.anneeRattachement, regularisation.moisRattachement - 1, regularisation.jourRattachement]).isSameOrAfter(data.date)));
                case "inferieur":
                    return tidy(dataFiltered, filter(regularisation => moment([regularisation.anneeRattachement, regularisation.moisRattachement - 1, regularisation.jourRattachement]).isSameOrBefore(data.date)));
                default: 
                    return dataFiltered
            }
        case "motif":
            return  tidy(dataFiltered, filter(regularisation => regularisation.observation === data.txt));
        case "statut":
            return tidy(dataFiltered, filter(regularisation => regularisation.libelleStatut ===  data.txt));
        default:
            return dataFiltered
    }
}

export const filtrerDrgGesTemMan = (data, dataBrut) => {
    let dataFiltered = [...dataBrut];
    switch(data.colonne) {
        case "date":
            switch(data.operateur) {
                case "egale":
                    return tidy(dataFiltered, filter(regularisation => moment(regularisation.dateDemande, "YYYYMMDD").isSame(data.date)));
                case "superieur":
                    return tidy(dataFiltered, filter(regularisation => moment(regularisation.dateDemande, "YYYYMMDD").isSameOrAfter(data.date)));
                case "inferieur":
                    return tidy(dataFiltered, filter(regularisation => moment(regularisation.dateDemande, "YYYYMMDD").isSameOrBefore(data.date)));
                default: 
                    return dataFiltered
            }
        case "heure":
            switch(data.operateur) {
                case "egale":
                    return tidy(dataFiltered, filter(regularisation => moment(regularisation.heureDemande, "Hmm").isSame(moment(data.txt, "HH:mm"))));
                case "superieur":
                    return tidy(dataFiltered, filter(regularisation => moment(regularisation.heureDemande, "Hmm").isSameOrAfter(moment(data.txt, "HH:mm"))));
                case "inferieur":
                    return tidy(dataFiltered, filter(regularisation => moment(regularisation.heureDemande, "Hmm").isSameOrBefore(moment(data.txt, "HH:mm"))));
                default: 
                    return dataFiltered
            }
        case "emetteur":
            return  tidy(dataFiltered, filter(regularisation => regularisation.emetteur === data.txt));
        case "motif":
            return  tidy(dataFiltered, filter(regularisation => regularisation.observation === data.txt));
        case "statut":
            return tidy(dataFiltered, filter(regularisation => regularisation.libelleStatut ===  data.txt));
        default:
            return dataFiltered
    }
}
