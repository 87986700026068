import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import { selectCltApp, selectColMvtGesTem, selectUsrApp } from "@selectors";
import { greenColor, whiteColor } from "@styles";
import { CollaborateursDialog, CollaborateursDialogMobile } from "@components/App/Collaborateurs/Dialogs";
import { TYPES_COLLABORATEURS, TYPES_FICHIER } from "@constants/collaborateursConstants";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import { schemaMouvementsIndividuels } from "./validations";
import { joiResolver } from "@hookform/resolvers/joi";
import { MouvementsIndividuelsForm } from "./Forms";
import { HeaderPanelContainer } from "@components/Containers";
import { IconButtonBase, LoaderButton } from "@components/Buttons";
import { DownloadForOffline, FilterListOff } from "@mui/icons-material";
import { useDownloadMvt } from "@hooks/gestionTemps/mouvements/hooksQueries";
import { formToDataMouvement } from "@integrations";
import { useMedia } from "react-use";

const headerItems = [
    {id: 1, label: "Société", xs: 2, display: true, firstData: true, lastData: false},
    {id: 2, label: "Nom", xs: 3.5, display: true, firstData: false, lastData: false},
    {id: 3, label: "Matricule", xs: 2, display: true, firstData: false, lastData: false},
    {id: 4, label: "Badge", xs: 2, display: true, firstData: false, lastData: false},
    {id: 5, label: "Type Personnel", xs: 2, display: true, firstData: false, lastData: true}
];


const defaultValues = {
    dateDebut: moment(),
    dateFin: moment(),
    societe: null,
    etablissement: null,
    direction: null,
    service: null,
    secteur: null,
    emploi: null,
    horaire: null,
    jour: "0",
    evenement: "",
    heureLimite: "",
    detail: true
};

const MouvementsIndividuels = () => {
    const [openCollaborateursDialog, setOpenCollaborateursDialog] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const stateColMvtGesTem = useSelector(selectColMvtGesTem);
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const methods = useForm({ mode: "all", defaultValues: defaultValues, resolver: joiResolver(schemaMouvementsIndividuels) });
    const { isLoading, downloadActionner } = useDownloadMvt();
    const isMobile = useMedia('(max-width: 1115px)');

    const selectCollaborateur = useCallback(async (collaborateur) => {
        setOpenCollaborateursDialog(false);
        methods.reset();
        const dataFormated = {...dataForm, matriculeATraiter: collaborateur.matricule, typeMatriculeATraiter: collaborateur.typePersonnel}
        downloadActionner(dataFormated)
    }, [dataForm, downloadActionner, methods]);

    const valideForm = useCallback((data) => {
        const droitSalarie = stateUser.droitSalarie ? "O" : "N";
        const droitInterimaire = stateUser.droitInterimaire ? "O" : "N";
        const dataFormated = formToDataMouvement(data, stateUser.utilisateurAS400, stateUser.matricule, "", droitSalarie, droitInterimaire, stateClient.client);
        setDataForm(dataFormated);
        setOpenCollaborateursDialog(true);
    }, [stateClient.client, stateUser.droitInterimaire, stateUser.droitSalarie, stateUser.matricule, stateUser.utilisateurAS400]);
    
    return (
        <>
            <Stack spacing={0.5} sx={styles.stackStyle}>
                <HeaderPanelContainer 
                    actionsLeft={
                        <IconButtonBase icon={<FilterListOff fontSize="small" />} title={"Reset filtres"} action={() => methods.reset()} />
                    }
                    actionsRight={
                        <Box display="flex" justifyContent="right" alignItems="center">
                            <LoaderButton title="Exporter les mouvements au format excel" isLoading={isLoading} icon={<DownloadForOffline fontSize="small" />} action={() => methods.handleSubmit(valideForm)()} />
                        </Box>  
                    }   
                    bgColor={whiteColor[5]}
                /> 
                <FormProvider {...methods}>
                    <MouvementsIndividuelsForm />
                </FormProvider>
            </Stack>
            {isMobile ? 
                <CollaborateursDialogMobile 
                    stateType={TYPES_COLLABORATEURS.GESTION_TEMPS_MOUVEMENT}
                    listType={TYPES_FICHIER.FWMV01} 
                    open={openCollaborateursDialog}  
                    dataForm={dataForm}
                    headers={headerItems}
                    tried={stateColMvtGesTem.tried}
                    setOpen={setOpenCollaborateursDialog}  
                    selectCollaborateur={selectCollaborateur}
                />
            :
                <CollaborateursDialog 
                    stateType={TYPES_COLLABORATEURS.GESTION_TEMPS_MOUVEMENT}
                    listType={TYPES_FICHIER.FWMV01} 
                    open={openCollaborateursDialog}  
                    dataForm={dataForm}
                    headers={headerItems}
                    tried={stateColMvtGesTem.tried}
                    setOpen={setOpenCollaborateursDialog}  
                    selectCollaborateur={selectCollaborateur}
                />
            }
        </>
    );
};

const styles = {
    stackStyle: {
        overflow: "auto",
        bgcolor: greenColor["015"],
        borderRadius: 2,
        boxSizing: "border-box",
        padding: 0.5,
        position: "relative",
        height: "100%"
    }
};

export default MouvementsIndividuels;