import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { HeaderListContainer, ItemContainer, ListContainer, TextContainer } from "@components/Containers";
import { greenColor, greyColor, whiteColor, yellowColor } from "@styles";
import { Loader } from "@components/Loaders";

const headerItems = [
    {id: 1, label: "Date", xs: 5, display: true, firstData: true, lastData: false},
    {id: 2, label: "Statut", xs: 5, display: true, firstData: false, lastData: true},
    {id: 3, label: "", xs: 2, display: false, firstData: false, lastData: false},
];

const DeleteDemandesContent = (props) => {
    const { isLoading, demandes } = props;

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        )
    }

    return (
        <Stack spacing={0.5}>
            <Box bgcolor={yellowColor[6]} p={0.5} borderRadius={2}>
                <Stack spacing={0.5}>
                    <TextContainer bgColor={whiteColor[7]} p={1} textWeight="bold">Voulez-vous vraiment supprimer {demandes.length > 1 ? "ces demandes" : "cette demande"} de votre historique ?</TextContainer>
                    <Box bgcolor={whiteColor[7]} p={1} borderRadius={2}>
                        <Typography fontWeight="bold" fontSize="small">Les demandes non traitées et en cours seront annulées.</Typography> 
                        <Typography fontWeight="bold" fontSize="small">Votre hiérarchique sera averti par mail.</Typography> 
                    </Box>  
                </Stack> 
            </Box>
            <HeaderListContainer itemIdReference="HISTODRE" variant="yellow" headerItems={headerItems} />
            <ListContainer spacing={0.5} length={demandes.length}>
                {demandes.map((item) => (
                    <Box key={`HSGD${item.id}`} bgcolor={yellowColor[6]} borderRadius={2} p={0.3}>
                        <Grid container spacing={0.3}>
                            <ItemContainer firstData data={moment(item.date, "DDMMYYYY").format("DD/MM/YYYY")} fontSize="11px" xs={5} /> 
                            <ItemContainer data={item.statut} fontSize="11px" xs={5} /> 
                            <Grid item xs={2}>
                                <Box bgcolor={whiteColor[7]} sx={{ borderTopRightRadius: 8, borderBottomRightRadius: 8 }} height="100%" display="flex" justifyContent="center" alignItems="center">
                                    {item.supressionPossible === "O" ? 
                                        <Tooltip title="Sera supprimé">
                                            <Check fontSize="15px" color="black" />
                                        </Tooltip> 
                                    : 
                                        <Tooltip fontSize="15px" title="Ne sera pas supprimé">
                                            <Close color="black" />
                                        </Tooltip>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                ))}
            </ListContainer>
        </Stack>
    );
}

DeleteDemandesContent.defaultProps = {
    isLoading: false,
    demandes: []
}

DeleteDemandesContent.propTypes = {
    isLoading: PropTypes.bool,
    demandes: PropTypes.array
}

const styles = {
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        height: "218px",
    }
};

export default DeleteDemandesContent;