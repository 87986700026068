import axios from "axios";
import { urls } from "@constants/appConstants";

/**
 * * Appel de la procédure PWCTSE
 * * Url : get/contraintes
 * @param {*} matricule Matricule du demandeur
 * @param {*} societe Société du demandeur à la date de la demande
 * @param {*} service Service du demandeur à la date de la demande
 * @param {*} contrainte Contrainte du demandeur à la date de la demande
 * @param {*} client 
 * @param {*} signal 
 * @returns Données informationnelles de la contrainte
 */
export const fetchCntDab = async (matricule, societe, service, contrainte, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetContraintesV1"
    };
    const body = {
        matricule: matricule,
        societe: societe,
        service: service,
        contrainte: contrainte
    };
    const response = await axios.post(urls.getContraintesV1, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.contrainte;
}

/**
 * * Appel de la procédure PWCTSE
 * * Url : get/contraintes/planning
 * @param {*} matricule Matricule du demandeur
 * @param {*} societe Société du service (un seul service affiché)
 * @param {*} service Service affiché
 * @param {*} client 
 * @param {*} signal 
 * @returns Données informationnelles de la contrainte
 */
export const fetchCntPla = async (matricule, societe, service, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetContraintesV2"
    };
    const body = {
        matricule: matricule,
        societe: societe,
        service: service
    };
    const response = await axios.post(urls.getContraintesV2, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.contraintes;
}