import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { greyColor } from "@styles";

const TextContainer = (props) => {
    const { 
        children, 
        bgColor, 
        p, 
        height, 
        textDecoration, 
        textAlign, 
        verticalAlign, 
        horizontalAlign, 
        textSize, 
        textWeight,
        noWrap
    } = props;

    return (  
        <Box 
            bgcolor={bgColor} 
            padding={p} 
            borderRadius={2} 
            height={height} 
            boxSizing="border-box" 
            display="flex" 
            textAlign={textAlign} 
            alignItems={verticalAlign} 
            justifyContent={horizontalAlign}
        >
            <Typography fontSize={textSize} fontWeight={textWeight} sx={{ textDecoration: textDecoration }} noWrap={noWrap}>
                {children}
            </Typography>
        </Box>
    );
}

TextContainer.defaultProps = {
    children: <></>,
    bgColor: greyColor[0],
    p: 2,
    height: null,
    textDecoration: "none",
    textAlign: "left",
    verticalAlign: "top", 
    horizontalAlign: "left", 
    textSize: "small", 
    textWeight: "normal",
    noWrap: false
}

TextContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.array]),
    bgColor: PropTypes.string,
    p: PropTypes.number,
    height: PropTypes.string,
    textDecoration: PropTypes.string,
    textAlign: PropTypes.string,
    verticalAlign: PropTypes.string, 
    horizontalAlign: PropTypes.string, 
    textSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), 
    textWeight: PropTypes.string,
    noWrap: PropTypes.bool
}

export default TextContainer;