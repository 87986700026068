import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { fetchDebCre } from "@api/debitsCredits";
import { selectCltApp, selectColTemGesTem, selectUsrApp } from "@selectors";
import { ROLE_UTILISATEUR } from "@constants/userConstants";

export const useHisDebCreTemGesTem = (origine, type, date, historiser, enable) => {
    const stateCollaborateurs = useSelector(selectColTemGesTem);
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const matricule = type === ROLE_UTILISATEUR.MANAGER ? stateCollaborateurs?.collaborateurSelected?.matricule : stateUser.matricule;
    const typePersonnel = type === ROLE_UTILISATEUR.MANAGER ? stateCollaborateurs?.collaborateurSelected?.typePersonnel : "P";
    
    return useQuery(['hisDebCreTemGesTem', date], ({ signal }) => fetchDebCre(origine, matricule, typePersonnel, date, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!date && historiser && enable
    });
}
