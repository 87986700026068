import { Box, Grid, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { TextContainer } from "@components/Containers";
import { DownloadButton } from "@components/Buttons";
import { whiteColor } from "@styles";

const PiecesJointesList = (props) => {
    const { piecesJointes } = props;

    return (
        <Stack spacing={0.5}>
            <TextContainer bgColor={whiteColor[7]} p={1} horizontalAlign="center" textWeight="bold">Pièces Jointes</TextContainer>
            <Stack spacing={0.5}>
                {piecesJointes?.length > 0 ?
                    piecesJointes?.map((pieceJointe) => (
                        <Box key={`PJHISTOSUIVI${pieceJointe.id}`} bgcolor={whiteColor[7]} paddingY={0.5} paddingX={1} borderRadius={2} display="inline-flex" alignItems="center">
                            <Box flexGrow={1}>
                                <Typography fontSize="small">{pieceJointe.fichier}</Typography>
                            </Box>
                            <DownloadButton chemin={pieceJointe.chemin} nom={pieceJointe.fichier} />
                        </Box>
                    ))
                :
                    <Box bgcolor={whiteColor[7]} p={1} borderRadius={2}>
                        <Grid container alignItems="center" justifyContent="center">
                            <Typography fontSize="small">Aucune pièce jointe</Typography>
                        </Grid>
                    </Box>
                }
            </Stack>
        </Stack>
    );
}

PiecesJointesList.defaultProps = {
    piecesJointes: []
}

PiecesJointesList.propTypes = {
    piecesJointes: PropTypes.array
}

export default PiecesJointesList;