import { Box, Grid, Grow, Stack } from "@mui/material";
import { Close, Info, Summarize } from "@mui/icons-material";
import { useMedia } from "react-use";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import moment from "moment";
import { heurifyBuild } from "@utils";
import { HeaderListContainer, ItemContainer, ListContainer, PanelListContainer, TextWithLabelContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { ErrorData } from "@components/Errors";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, whiteColor } from "@styles";
import NoData from "@components/App/NoData";

const headers = [
    {id: 1, label: "Date", xs: 1, display: true, firstData: true, lastData: false},
    {id: 2, label: "Heure", xs: 0.75, display: true, firstData: false, lastData: false},
    {id: 3, label: "Société", xs: 2, display: true, firstData: false, lastData: false},
    {id: 4, label: "Matricule", xs: 0.75, display: true, firstData: false, lastData: false},
    {id: 5, label: "Nom", xs: 1.5, display: true, firstData: false, lastData: false},
    {id: 6, label: "Entretien", xs: 4, display: true, firstData: false, lastData: false},
    {id: 7, label: "Statut", xs: 0.75, display: true, firstData: false, lastData: true}
];

const statutsRoutes = {
    P: "Planifié",
    E: "Effectué"
};

const EntretiensList = ({ 
    data = [], 
    isLoading = true, 
    isError = false,
    openDetail = () => {}, 
    openSections = () => {},
    openWarning = () => {}
}) => {
    const isLarge = useMedia('(min-width: 650px)');

    if(isLoading) {
        return (
            <Box height="100%" justifyContent="center" display="flex" alignItems="center">
                <Loader size={27} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return <ErrorData />;
    }

    if(data?.length === 0) {
        return <NoData />;
    }

    return (
        isLarge ?
            <>
                <HeaderListContainer itemIdReference="ENT" headerItems={headers} />
                <PerfectScrollbar>
                    <ListContainer spacing={0.5} length={data.length} noDataLabel="Aucune donnée">
                        {data.map((entretien, id) => (
                            <Grow key={`CRES${entretien.id}`} in={true} timeout={400+(200*id)}>
                                <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.3}>
                                    <Grid container spacing={0.3}>
                                        <ItemContainer firstData data={moment([entretien.annee, entretien.mois - 1, entretien.jour]).format("DD/MM/YYYY")} xs={1} />
                                        <ItemContainer data={heurifyBuild(entretien.heure, entretien.minute, "h")} xs={0.75} />
                                        <ItemContainer data={entretien.libelleSociete} xs={2} />
                                        <ItemContainer data={entretien.matricule} xs={0.75} />
                                        <ItemContainer data={entretien.prenomNom} xs={1.5} />
                                        <ItemContainer data={entretien.designation} xs={4} />
                                        <ItemContainer lastData data={statutsRoutes[entretien.statut]} xs={0.75} />
                                        <Grid item xs={1.25} display="flex" justifyContent="center" alignItems="center">
                                            <Stack direction="row" spacing={0.5}>
                                                <IconButtonBase 
                                                    variant="listButton"
                                                    icon={<Info fontSize="small" />} 
                                                    title="Détail" 
                                                    action={() => openDetail(entretien)} 
                                                />
                                                <IconButtonBase 
                                                    variant="listButton"
                                                    icon={<Summarize fontSize="small" />} 
                                                    title="Contenu" 
                                                    action={() => openSections(entretien)} 
                                                />
                                                {(!entretien.presenceCommentaire) && 
                                                    <IconButtonBase 
                                                        variant="deleteListButton"
                                                        icon={<Close fontSize="small" />} 
                                                        title="Supprimer" 
                                                        action={() => openWarning(entretien)} 
                                                    /> 
                                                }
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grow>
                        ))}
                    </ListContainer>
                </PerfectScrollbar>
            </>
        :
            <PerfectScrollbar>
                <PanelListContainer length={data.length}>
                    {data.map((entretien, id) => (
                        <Grow key={`LCOMS${entretien.id}`} in={true} timeout={400+(200*id)}>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                <Stack spacing={0.5} bgcolor={greenColor["025"]} padding={0.5} borderRadius={2}>
                                    <Box borderRadius={2} p={1} bgcolor={whiteColor[5]}>
                                        <Stack spacing={0}>
                                            <TextWithLabelContainer label="Date" value={moment([entretien.annee, entretien.mois - 1, entretien.jour]).format("DD/MM/YYYY")} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Heure" value={heurifyBuild(entretien.heure, entretien.minutes, "h")} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Matricule" value={entretien.matricule} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Nom" value={entretien.prenomNom} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Entretien" value={entretien.designation} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Statut" value={entretien.statut} weight={{label: "bold", value: "normal"}} />
                                        </Stack>
                                    </Box>
                                    <Stack direction="row" spacing={0.5} borderRadius={2} height={35.5} display="flex" alignItems="center" justifyContent="center" bgcolor={whiteColor[5]}>
                                        <IconButtonBase 
                                            icon={<Info fontSize="small" />} 
                                            title="Détail" 
                                            action={() => openDetail(entretien)} 
                                        />
                                        <IconButtonBase 
                                            icon={<Summarize fontSize="small" />} 
                                            title="Contenu" 
                                            action={() => openSections(entretien)} 
                                        />
                                        {(!entretien.presenceCommentaire) && 
                                            <IconButtonBase 
                                                icon={<Close fontSize="small" />} 
                                                title="Supprimer" 
                                                action={() => openWarning(entretien)} 
                                            />     
                                        }                          
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grow>
                    ))}
                </PanelListContainer>
            </PerfectScrollbar>                
    )
}

EntretiensList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    openDetail: PropTypes.func, 
    openSections: PropTypes.func, 
    openWarning: PropTypes.func
}

export default EntretiensList;