import { useSelector, useDispatch } from "react-redux";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { Add, ArrowLeft, Refresh } from "@mui/icons-material";
import PropTypes from "prop-types";
import { selectEntMan, selectEntSecMan } from "@selectors";
import * as entSecManActions from "@reducers/gestionEntretiens/entSecManReducer";
import { useMappingActions } from "@hooks/gestionEntretiens/hooksUtils";
import { IconButtonBase } from "@components/Buttons";
import { AjoutSectionForm } from "@components/GestionEntretiens/Manager/Forms";
import { greenColor, redColor, whiteColor } from "@styles";
import { useComEntMan } from "@hooks/gestionEntretiens/hooksQueries";

const EmptyList = ({ 
    open = false, 
    goBack = () => {} 
}) => {
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;
    const { designation1, designation2 } = useMappingActions()
    const dispatch = useDispatch()
    const { data, isLoading, isFetching, isError, refetch } = useComEntMan(0, open);    

    if(stateSections.modeAjout) {
        return (<AjoutSectionForm goBack={goBack} />);
    }

    return (
        <>
            <Box borderRadius={2} bgcolor={greenColor["05"]} height={50} p={0.5}>
                <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[7]} height="100%" alignItems="center" px={1}>
                    <IconButtonBase icon={<ArrowLeft fontSize="small" />} title="Retour" action={() => goBack()} />
                    <Box flexGrow={1}>
                        <Typography fontSize="small" fontWeight="bold">{designation1}</Typography>
                        <Typography fontSize="small" fontWeight="bold">{designation2}</Typography>
                    </Box>
                    {(stateEntretiens?.entretienSelected?.statut === "P" && sectionSelected?.ajoutSousSectionManager) && <IconButtonBase icon={<Add fontSize="small" />} title="Ajouter une sous section" action={() => dispatch(entSecManActions.setModeAjout(true))} />}
                </Stack>
            </Box>
            <Box>
                {isLoading || isFetching ?
                    <Box>
                        <Skeleton variant="rounded" animation="wave" width="100%" height={48} sx={{borderRadius: 2}} />
                    </Box>
                : isError ?
                    <Box borderRadius={2} bgcolor={redColor[5]}>
                        <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[7]} height={48} alignItems="center" px={1}>
                            <Box flexGrow={1}>
                                <Typography fontSize="small" display="inline" fontWeight="bold">Une erreur est survenue lors de la récupération du commentaire</Typography>
                            </Box>
                            <Box>
                                <IconButtonBase icon={<Refresh fontSize="small" />} title="Rafraichir" action={() => refetch()} />
                            </Box>
                        </Stack>
                    </Box>
                :
                    <Box>
                        {(!!data?.commentaireManager || !!data?.commentaireCollaborateur) ? 
                            <Stack spacing={0.5} height="100%" whiteSpace={"pre-wrap"}>
                            {!!data?.commentaireManager && 
                                <Box borderRadius={2} bgcolor={whiteColor[7]} p={1}>
                                    <Typography fontSize="small" fontWeight="bold">Commentaire</Typography>
                                    <Typography fontSize="small">{data?.commentaireManager}</Typography>
                                </Box>
                            }
                            {!!data?.commentaireCollaborateur && 
                                <Box borderRadius={2} bgcolor={whiteColor[7]} p={1}>
                                    <Typography fontSize="small" fontWeight="bold">Commentaire de {stateEntretiens?.entretienSelected?.prenomNom}</Typography>
                                    <Typography fontSize="small">{data?.commentaireCollaborateur}</Typography>
                                </Box>
                            }
                            </Stack>
                        :
                            <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.5} height="100%" whiteSpace={"pre-wrap"} boxSizing={"border-box"}> 
                                <Box borderRadius={2} bgcolor={whiteColor[7]} p={1}>
                                    <Typography fontSize="small" fontWeight="bold">Aucun commentaire</Typography>
                                </Box>
                            </Box>
                        }
                    </Box>
                }
            </Box>
        </>
    );
}

EmptyList.propTypes = {
    open: PropTypes.bool,
    goBack: PropTypes.func
}

export default EmptyList;