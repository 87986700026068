import { Box, Grid, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Loader } from "@components/Loaders";
import { greyColor, whiteColor } from "@styles";

const SituationSoldeList = ({ 
    data = [], 
    isLoading = true 
}) => {
    if(isLoading) {
        return (
            <Box display="flex" alignItems="center" height="100%" borderRadius={2}>
                <Loader size={23} colorLoader={greyColor[9]} colorContainer={whiteColor[5]} />
            </Box>
        );
    }

    return(
        <Stack spacing={0.5}>
            {data.map((situation) => (
                <Box key={`SITSOL${situation.id}`} borderRadius={2} bgcolor={whiteColor[5]} p={1}>
                    <Grid container alignItems="center">
                        <Grid item xs={6}>
                            <Typography fontWeight="bold" fontSize="small">{situation.compteurLibelle}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontSize="small">{Number(situation.nombre).toLocaleString("fr-FR")}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            ))}
        </Stack>
    );
}

SituationSoldeList.defaultProps = {
    data: [], 
    isLoading: true
}

SituationSoldeList.propTypes = {
    data: PropTypes.array, 
    isLoading: PropTypes.bool
}

export default SituationSoldeList;