import axios from "axios";
import { urls } from "@constants/appConstants";

export const editBkg = async (image, token) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostBackground", 
        "HttpToken": token
    };
    const body = {
        background: image
    };

    return axios.post(urls.postBackground, body, { headers: headers });
}