import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

const PlagesNonVariables = (props) => {
    const { debutFixe, repriseFixe } = props;

    return (
        <Stack direction="row">
            <Stack direction="row" width={"100%"}>
                <Box width="50%" justifyContent="left"  display="flex">
                    <Typography fontSize="11px">{debutFixe}</Typography>
                </Box>
                <Box width="50%" justifyContent="right"  display="flex">
                    <Typography fontSize="11px">{repriseFixe}</Typography>
                </Box>
            </Stack>
        </Stack>
    );
}

PlagesNonVariables.defaultProps = {
    debutFixe: "",
    repriseFixe: ""
}

PlagesNonVariables.propTypes = {
    debutFixe: PropTypes.string,
    repriseFixe: PropTypes.string,
}

export default PlagesNonVariables;