import { arrange, asc, desc, filter, tidy } from "@tidyjs/tidy";
import { heurifyBuild } from "@utils";
import moment from "moment";

export const trierFwgtp2 = (keyTrie, data) => {
    switch(keyTrie) {
        case "nomAsc":
            return tidy(data, arrange(asc("prenomNom")));
        case "nomDesc": 
            return tidy(data, arrange(desc("prenomNom")));
        case "matriculeAsc":
            return tidy(data, arrange([asc("matricule")]));
        case "matriculeDesc": 
            return tidy(data, arrange([desc("matricule")]));
        case "badgeAsc":
            return tidy(data, arrange([asc("badge")]));
        case "badgeDesc": 
            return tidy(data, arrange([desc("badge")]));
        case "societeAsc": 
            return tidy(data, arrange([asc("libelleSociete")]));
        case "societeDesc":
            return tidy(data, arrange([desc("libelleSociete")]));
        case "etablissementAsc":
            return tidy(data, arrange([asc("libelleEtablissement")]));
        case "etablissementDesc":
            return tidy(data, arrange([desc("obserlibelleEtablissementvation")]));
        case "directionAsc":
            return tidy(data, arrange([asc("libelleDirection")]));
        case "directionDesc":
            return tidy(data, arrange([desc("libelleDirection")]));
        case "serviceAsc":
            return tidy(data, arrange([asc("libelleService")]));
        case "serviceDesc":
            return tidy(data, arrange([desc("libelleService")]));
        case "secteurAsc":
            return tidy(data, arrange([asc("libelleSecteur")]));
        case "secteurDesc":
            return tidy(data, arrange([desc("libelleSecteur")]));
        case "typePersonnelAsc":
            return tidy(data, arrange([asc("typePersonnel")]));
        case "typePersonnelDesc":
            return tidy(data, arrange([desc("typePersonnel")]));
        case "poste1Asc":
            return tidy(data, arrange([asc("libellePoste1")]));
        case "poste1Desc":
            return tidy(data, arrange([desc("libellePoste1")]));
        case "poste2Asc":
            return tidy(data, arrange([asc("libellePoste2")]));
        case "poste2Desc":
            return tidy(data, arrange([desc("libellePoste2")]));
        case "poste3Asc":
            return tidy(data, arrange([asc("libellePoste3")]));
        case "poste3Desc":
            return tidy(data, arrange([desc("libellePoste3")]));
        case "poste4Asc":
            return tidy(data, arrange([asc("libellePoste4")]));
        case "poste4Desc":
            return tidy(data, arrange([desc("libellePoste4")]));
        case "controleHoraireAsc":
            return tidy(data, arrange([asc("controleHoraire")]));
        case "controleHoraireDesc":
            return tidy(data, arrange([desc("controleHoraire")]));
        case "presenceAsc":
            return tidy(data, arrange([asc("presentADate")]));
        case "presenceDesc":
            return tidy(data, arrange([desc("presentADate")]));
        case "metierAsc":
            return tidy(data, arrange([asc("libelleMetier")]));
        case "metierDesc":
            return tidy(data, arrange([desc("libelleMetier")]));
        case "horaireAsc":
            return tidy(data, arrange([asc("libelleHoraire")]));
        case "horaireDesc":
            return tidy(data, arrange([desc("libelleHoraire")]));
        case "equipeAsc":
            return tidy(data, arrange([asc("libelleEquipe")]));
        case "equipeDesc":
            return tidy(data, arrange([desc("libelleEquipe")]));
        case "activiteAsc":
            return tidy(data, arrange([asc("libelleActivite")]));
        case "activiteDesc":
            return tidy(data, arrange([desc("libelleActivite")]));
        case "categorieProfessionnelleAsc":
            return tidy(data, arrange([asc("libelleCategorieProfessionnelle")]));
        case "categorieProfessionnelleDesc":
            return tidy(data, arrange([desc("libelleCategorieProfessionnelle")]));
        default: 
            return data;
    }
};

export const filtrerFwgtp2 = (data, dataBrut) => { 
    let filters = []
    if(data.matricule) {filters.push({key: "matricule", value: data.matricule.value})}
    if(data.nom) {filters.push({key: "prenomNom", value: data.nom.value})}
    if(data.badge) {filters.push({key: "badge", value: data.badge.value})}
    if(data.etablissement) {filters.push({key: "codeEtablissement", value: data.etablissement.value})}
    if(data.direction) {filters.push({key: "codeDirection", value: data.direction.value})}
    if(data.service) {filters.push({key: "codeService", value: data.service.value})}
    if(data.secteur) {filters.push({key: "codeSecteur", value: data.secteur.value})}
    if(data.typePersonnel) {filters.push({key: "typePersonnel", value: data.typePersonnel.value})}
    if(data.poste1) {filters.push({key: "codePoste1", value: data.poste1.value})}
    if(data.poste2) {filters.push({key: "codePoste2", value: data.poste2.value})}
    if(data.poste3) {filters.push({key: "codePoste3", value: data.poste3.value})}
    if(data.poste4) {filters.push({key: "codePoste4", value: data.poste4.value})}
    if(data.controleHoraire) {filters.push({key: "controleHoraire", value: data.controleHoraire.value})};
    if(data.presentADate) {filters.push({key: "presentADate", value: data.presentADate.value})}
    if(data.metier) {filters.push({key: "codeMetier", value: data.metier.value})}
    if(data.horaire) {filters.push({key: "codeHoraire", value: data.horaire.value})}
    if(data.equipe) {filters.push({key: "codeEquipe", value: data.equipe.value})}
    if(data.activite) {filters.push({key: "codeActivite", value: data.activite.value})}
    if(data.categorieProfessionnelle) {filters.push({key: "codeCategorieProfessionnelle", value: data.categorieProfessionnelle.value})}
    const result = tidy(dataBrut, filter((personne) => (!filters.find(x => x.key.split('.').reduce((keys, key) => keys[key], personne) !== x.value))))
    return result
};

export const trierFwgtp3 = (keyTrie, data) => {
    switch(keyTrie) {
        case "nomAsc":
            return tidy(data, arrange(asc("prenomNom")));
        case "nomDesc": 
            return tidy(data, arrange(desc("prenomNom")));
        case "matriculeAsc":
            return tidy(data, arrange([asc("matricule")]));
        case "matriculeDesc": 
            return tidy(data, arrange([desc("matricule")]));
        case "badgeAsc":
            return tidy(data, arrange([asc("badge")]));
        case "badgeDesc": 
            return tidy(data, arrange([desc("badge")]));
        case "typePersonnelAsc": 
            return tidy(data, arrange([asc("typePersonnel")]));
        case "typePersonnelDesc":
            return tidy(data, arrange([desc("typePersonnel")]));
        default: 
            return data;
    }
};

export const filtrerFwgtp3 = (data, dataBrut) => { 
    let dataFiltered = [...dataBrut];
    if(data.matricule) {
        dataFiltered = tidy(dataFiltered, filter((d) => d.matricule === data.matricule.value));
    }
    if(data.nom) {
        dataFiltered = tidy(dataFiltered, filter((d) => d.prenomNom === data.nom.value));
    }
    if(data.badge) {
        dataFiltered = tidy(dataFiltered, filter((d) => d.badge === data.badge.value));
    }
    if(data.controleHoraire) {
        dataFiltered = tidy(dataFiltered, filter((d) => d.controleHoraire === data.controleHoraire.value));
    }
    if(data.societe) {
        dataFiltered = tidy(dataFiltered, filter((d) => d.societes.includes(data.societe.codeSociete)));
    } 
    if(data.etablissement) {
        dataFiltered = tidy(dataFiltered, filter((d) => d.etablissements.includes(data.etablissement.code)));
    }
    if(data.direction) {
        dataFiltered = tidy(dataFiltered, filter((d) => d.directions.includes(data.direction.code)));
    }
    if(data.service) {
        dataFiltered = tidy(dataFiltered, filter((d) => d.services.includes(data.service.codeService)));
    }
    if(data.secteur) {
        dataFiltered = tidy(dataFiltered, filter((d) => d.secteurs.includes(data.secteur.code)));
    }
    if(data.activite) {
        dataFiltered = tidy(dataFiltered, filter((d) => d.activites.includes(data.activite.code)));
    }
    if(data.categorieProfessionnelle) {
        dataFiltered = tidy(dataFiltered, filter((d) => d.categoriesProfessionnelles.includes(data.categorieProfessionnelle.code)));
    }
    if(data.horaire) {
        dataFiltered = tidy(dataFiltered, filter((d) => d.horaires.includes(data.horaire.codeHoraire)));
    }
    return dataFiltered;
};

export const trierFwgtpe = (keyTrie, data) => {
    switch(keyTrie) {
        case "nomAsc":
            return tidy(data, arrange(asc("prenomNom")));
        case "nomDesc": 
            return tidy(data, arrange(desc("prenomNom")));
        case "matriculeAsc":
            return tidy(data, arrange([asc("matricule")]));
        case "matriculeDesc": 
            return tidy(data, arrange([desc("matricule")]));
        case "badgeAsc":
            return tidy(data, arrange([asc("badge")]));
        case "badgeDesc": 
            return tidy(data, arrange([desc("badge")]));
        case "societeAsc": 
            return tidy(data, arrange([asc("libelleSociete")]));
        case "societeDesc":
            return tidy(data, arrange([desc("libelleSociete")]));
        case "etablissementAsc":
            return tidy(data, arrange([asc("libelleEtablissement")]));
        case "etablissementDesc":
            return tidy(data, arrange([desc("obserlibelleEtablissementvation")]));
        case "directionAsc":
            return tidy(data, arrange([asc("libelleDirection")]));
        case "directionDesc":
            return tidy(data, arrange([desc("libelleDirection")]));
        case "serviceAsc":
            return tidy(data, arrange([asc("libelleService")]));
        case "serviceDesc":
            return tidy(data, arrange([desc("libelleService")]));
        case "secteurAsc":
            return tidy(data, arrange([asc("libelleSecteur")]));
        case "secteurDesc":
            return tidy(data, arrange([desc("libelleSecteur")]));
        case "typePersonnelAsc":
            return tidy(data, arrange([asc("typePersonnel")]));
        case "typePersonnelDesc":
            return tidy(data, arrange([desc("typePersonnel")]));
        case "controleHoraireAsc":
            return tidy(data, arrange([asc("controleHoraire")]));
        case "controleHoraireDesc":
            return tidy(data, arrange([desc("controleHoraire")]));
        case "presenceAsc":
            return tidy(data, arrange([asc("presentADate")]));
        case "presenceDesc":
            return tidy(data, arrange([desc("presentADate")]));
        default: 
            return data;
    }
};

export const filtrerFwgtpe = (data, dataBrut) => { 
    let filters = []
    if(data.societe) {filters.push({key: "codeSociete", value: data.societe.value})}
    if(data.matricule) {filters.push({key: "matricule", value: data.matricule.value})}
    if(data.nom) {filters.push({key: "prenomNom", value: data.nom.value})}
    if(data.badge) {filters.push({key: "badge", value: data.badge.value})}
    if(data.etablissement) {filters.push({key: "codeEtablissement", value: data.etablissement.value})}
    if(data.direction) {filters.push({key: "codeDirection", value: data.direction.value})}
    if(data.service) {filters.push({key: "codeService", value: data.service.value})}
    if(data.secteur) {filters.push({key: "codeSecteur", value: data.secteur.value})}
    if(data.typePersonnel) {filters.push({key: "typePersonnel", value: data.typePersonnel.value})}
    if(data.controleHoraire) {filters.push({key: "controleHoraire", value: data.controleHoraire.value})};
    if(data.presentADate) {filters.push({key: "presentADate", value: data.presentADate.value})}
    const result = tidy(dataBrut, filter((personne) => (!filters.find(x => x.key.split('.').reduce((keys, key) => keys[key], personne) !== x.value))))
    return result
};


export const trierFwdc01 = (keyTrie, data) => {
    switch(keyTrie) {
        case "nomAsc":
            return tidy(data, arrange(asc("prenomNom")));
        case "nomDesc": 
            return tidy(data, arrange(desc("prenomNom")));
        case "matriculeAsc":
            return tidy(data, arrange([asc("matricule")]));
        case "matriculeDesc": 
            return tidy(data, arrange([desc("matricule")]));
        case "badgeAsc":
            return tidy(data, arrange([asc("badge")]));
        case "badgeDesc": 
            return tidy(data, arrange([desc("badge")]));
        case "societeAsc": 
            return tidy(data, arrange([asc("libelleSociete")]));
        case "societeDesc":
            return tidy(data, arrange([desc("libelleSociete")]));
        case "serviceAsc":
            return tidy(data, arrange([asc("libelleService")]));
        case "serviceDesc":
            return tidy(data, arrange([desc("libelleService")]));
        case "typePersonnelAsc":
            return tidy(data, arrange([asc("typePersonnel")]));
        case "typePersonnelDesc":
            return tidy(data, arrange([desc("typePersonnel")]));
        default: 
            return data;
    }
};

export const filtrerFwdc01 = (data, dataBrut) => { 
    let filters = []
    if(data.matricule) {filters.push({key: "matricule", value: data.matricule.value})}
    if(data.nom) {filters.push({key: "prenomNom", value: data.nom.value})}
    if(data.badge) {filters.push({key: "badge", value: data.badge.value})}
    if(data.societe) {filters.push({key: "codeSociete", value: data.societe.value})}
    if(data.service) {filters.push({key: "codeService", value: data.service.value})}
    if(data.typePersonnel) {filters.push({key: "typePersonnel", value: data.typePersonnel.value})}
    const result = tidy(dataBrut, filter((personne) => (!filters.find(x => x.key.split('.').reduce((keys, key) => keys[key], personne) !== x.value))))
    return result
};

export const trierFwmv01 = (keyTrie, data) => {
    switch(keyTrie) {
        case "societeAsc":
            return tidy(data, arrange(asc("societe")));
        case "societeDesc": 
            return tidy(data, arrange(desc("societe")));
        case "nomAsc":
            return tidy(data, arrange(asc("prenomNom")));
        case "nomDesc": 
            return tidy(data, arrange(desc("prenomNom")));
        case "matriculeAsc":
            return tidy(data, arrange([asc("matricule")]));
        case "matriculeDesc": 
            return tidy(data, arrange([desc("matricule")]));
        case "badgeAsc":
            return tidy(data, arrange([asc("badge")]));
        case "badgeDesc": 
            return tidy(data, arrange([desc("badge")]));
        case "typePersonnelAsc":
            return tidy(data, arrange([asc("typePersonnel")]));
        case "typePersonnelDesc":
            return tidy(data, arrange([desc("typePersonnel")]));
        default: 
            return data;
    }
};

export const filtrerFwmv01 = (data, dataBrut) => { 
    let filters = []
    if(data.societe) {filters.push({key: "societe", value: data.societe.value})}
    if(data.matricule) {filters.push({key: "matricule", value: data.matricule.value})}
    if(data.nom) {filters.push({key: "prenomNom", value: data.nom.value})}
    if(data.badge) {filters.push({key: "badge", value: data.badge.value})}
    if(data.typePersonnel) {filters.push({key: "typePersonnel", value: data.typePersonnel.value})}
    const result = tidy(dataBrut, filter((personne) => (!filters.find(x => x.key.split('.').reduce((keys, key) => keys[key], personne) !== x.value))))
    return result
};

export const trierFhpee1 = (keyTrie, data) => {
    switch(keyTrie) {
        case "dateAsc":
            return tidy(data, arrange((da, db) => moment([da.annee, da.mois - 1, da.jour]).utc() - moment([db.annee, db.mois - 1, db.jour]).utc()));
        case "dateDesc": 
            return tidy(data, arrange((da, db) => moment([db.annee, db.mois - 1, db.jour]).utc() - moment([da.annee, da.mois - 1, da.jour]).utc()));
        case "heureAsc":
            return tidy(data, arrange((da, db) => moment(heurifyBuild(da.heure, da.minute, ""), "HHmm").utc() - moment(heurifyBuild(db.heure, db.minute, ""), "HHmm").utc()));
        case "heureDesc": 
            return tidy(data, arrange((da, db) => moment(heurifyBuild(db.heure, db.minute, ""), "HHmm").utc() - moment(heurifyBuild(da.heure, da.minute, ""), "HHmm").utc()));
        case "societeAsc":
            return tidy(data, arrange(asc("libelleSociete")));
        case "societeDesc": 
            return tidy(data, arrange(desc("libelleSociete")));
        case "nomAsc":
            return tidy(data, arrange(asc("prenomNom")));
        case "nomDesc": 
            return tidy(data, arrange(desc("prenomNom")));
        case "matriculeAsc":
            return tidy(data, arrange(asc("matricule")));
        case "matriculeDesc": 
            return tidy(data, arrange(desc("matricule")));
        case "designationAsc":
            return tidy(data, arrange(asc("designation")));
        case "designationDesc": 
            return tidy(data, arrange(desc("designation")));
        case "statutAsc":
            return tidy(data, arrange(asc("statut")));
        case "statutDesc": 
            return tidy(data, arrange(desc("statut")));
        default: 
            return data;
    }
};

export const filtrerFhpee1 = (data, dataBrut) => { 
    let result = dataBrut;
    if(data.date) {
        if(data.operateur === "egale") {result = tidy(result, filter((d) => moment([d.annee, d.mois - 1, d.jour]).isSame(data.date)))}
        if(data.operateur === "avant") {result = tidy(result, filter((d) => moment([d.annee, d.mois - 1, d.jour]).isSameOrBefore(data.date)))}
        if(data.operateur === "apres") {result = tidy(result, filter((d) => moment([d.annee, d.mois - 1, d.jour]).isSameOrAfter(data.date)))}
    }
    if(data.societe) {
        result = tidy(result, filter((d) => d.codeSociete === data.societe.value))
    }
    if(data.matricule) {
        result = tidy(result, filter((d) => d.matricule === data.matricule.value))
    }
    if(data.nom) {
        result = tidy(result, filter((d) => d.prenomNom === data.nom.value))
    }
    if(data.statut) {
        result = tidy(result, filter((d) => d.statut === data.statut))
    }
    return result

};

