import { useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import { Check, CheckCircleOutlineRounded } from "@mui/icons-material";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import { selectSecVarApp, selectUsrApp } from "@selectors";
import { SecretHookFormInput, NumericPadHookFormInput, TextInput } from "@components/Inputs";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@components/Buttons";

const ContinueButton = ({
    isLoading = false,
    action = () => {}
}) => {
    const { t } = useTranslation("global");

    if(isLoading) {
        return (
            <Box bgcolor={greenColor["05"]} borderRadius={2} width="100%" height={40} alignItems="center" display="flex" justifyContent="center">
                <Loader size={17} colorLoader={greyColor[9]} colorContainer={whiteColor[9]} />
            </Box>
        );
    }

    return (
        <ButtonBase 
            variant="green5" 
            fullWidth 
            startIcon={<Check fontSize="small" />} 
            action={action}
        >
            {t("buttons.continuer")}
        </ButtonBase>
    );
}

const LoginForm = ({
    isLoadingLogin = false,
    isLoadingVerification = false,
    handleEnter = () => {},
    handleContinue = () => {},
    handleLogin = () => {}
}) => {
    const stateUser = useSelector(selectUsrApp);
    const stateSecureVariable = useSelector(selectSecVarApp);
    const { watch } = useFormContext();
    const identifiantCheck = watch("identifiantCheck");
    const { t } = useTranslation("global");

    return (
        isLoadingLogin || stateUser.onLogin ? 
            <Box sx={styles.loaderStyle}><Loader colorContainer={greyColor[1]} colorLoader={greenColor["1"]} /></Box>
        :
            <>
                <Stack spacing={1} onKeyUp={handleEnter}>
                    <TextInput
                        name="identifiant"
                        label={t("inputs.username")}
                        autoFocus={!identifiantCheck} 
                        maxLength={10}
                        readOnly={identifiantCheck}
                    />
                    {identifiantCheck && (
                        stateSecureVariable.parametres.typePassword !== "N" ? 
                            <SecretHookFormInput 
                                name="password"
                                label={t("inputs.password")}
                                autoFocus={identifiantCheck}
                                autocomplete={false}
                            /> 
                        :
                            <NumericPadHookFormInput  
                                name={"password"} 
                                label={t("inputs.password")}
                                readOnly={true}
                            />
                        )
                    }
                </Stack>
                <Box>
                    {identifiantCheck ? 
                        <ButtonBase 
                            variant="green5" 
                            fullWidth 
                            startIcon={<CheckCircleOutlineRounded fontSize="small" />} 
                            action={handleLogin}
                        >
                            {t("buttons.connexion")} 
                        </ButtonBase>
                    :
                        <ContinueButton isLoading={isLoadingVerification} action={handleContinue} />
                    }
                </Box>
                </>
        
    );
}

ContinueButton.propTypes = {
    isLoading: PropTypes.bool,
    action: PropTypes.func
}

LoginForm.propTypes = {
    isLoadingLogin: PropTypes.bool,
    isLoadingVerification: PropTypes.bool,
    handleEnter: PropTypes.func,
    handleContinue: PropTypes.func,
    handleLogin: PropTypes.func
}

const styles = {
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        height: 86,
    },
}

export default LoginForm;