import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { fetchTra, fetchHisTra } from "@api/transactions";
import { selectCltApp, selectColTemGesTem, selectUsrApp } from "@selectors";
import { useStateTemGesTem } from "@hooks/gestionTemps/temps/hooksStates";
import { ROLE_UTILISATEUR } from "@constants/userConstants";

export const useTraTemGesTem = (userType, enable) => {
    const stateTemps = useStateTemGesTem(userType);
    const stateCollaborateurs = useSelector(selectColTemGesTem);
    const stateUser = useSelector(selectUsrApp);
    const stateClient= useSelector(selectCltApp);
    const matricule = userType === ROLE_UTILISATEUR.MANAGER ? stateCollaborateurs?.collaborateurSelected?.matricule : stateUser.matricule;
    const typePersonnel = userType === ROLE_UTILISATEUR.MANAGER ? stateCollaborateurs?.collaborateurSelected?.typePersonnel : "P";

    return useQuery(['traTemGesTem'], ({ signal }) => fetchTra(matricule, typePersonnel, stateTemps.jourSelected, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: enable
    });
}

export const useHisTraTemGesTem = (userType, date, enable) => {
    const stateUser = useSelector(selectUsrApp);
    const stateCollaborateurs = useSelector(selectColTemGesTem);
    const stateClient = useSelector(selectCltApp);
    const matricule = userType === ROLE_UTILISATEUR.MANAGER ? stateCollaborateurs?.collaborateurSelected?.matricule : stateUser.matricule;
    const typePersonnel = userType === ROLE_UTILISATEUR.MANAGER ? stateCollaborateurs?.collaborateurSelected?.typePersonnel : "P";
    const dateMoment = moment(date, "DDMMYYYY");
    const anneeRattachement = dateMoment.format("YYYY");
    const moisRattachement = dateMoment.format("MM");
    const jourRattachement = dateMoment.format("DD");
    
    return useQuery(['hisTraTemGesTem', date], ({ signal }) => fetchHisTra(matricule, typePersonnel, anneeRattachement, moisRattachement, jourRattachement, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!date && enable
    });
}
