import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";
import { ChevronLeft, ChevronRight, Refresh } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { useSitSolApp } from "@hooks/gestionAbsences/soldes/hooksQueries";
import { selectSitSolApp } from "@selectors";
import * as sitSolAppActions from "@reducers/soldes/sitSolAppReducer"
import { DialogContainer, HeaderDialogContainer, TextWithLabelContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import SituationSoldeList from "../Lists/SituationSoldeList";
import SoldesText from "../Texts/SoldesText";
import { greenColor, redColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";

const SituationSoldeDialog = (props) => {
    const { open, setOpen } = props;
    const stateSituationSolde = useSelector(selectSitSolApp);
    const { data, isLoading, isFetching, isError, error, refetch } = useSitSolApp(stateSituationSolde.matricule, stateSituationSolde?.solde?.codeAbsence, stateSituationSolde?.periode, open);
    const dateSolde = moment(stateSituationSolde?.solde?.date, "DDMMYYYY");
    const dispatch = useDispatch();
    const { t } = useTranslation("global");

    const precedent = useCallback(async () => {
        const periode = moment(stateSituationSolde.periode, "MMYYYY");
        dispatch(sitSolAppActions.setPeriode(periode.add(-1, "months").format("MMYYYY")));
    }, [dispatch, stateSituationSolde.periode]);

    const suivant = useCallback(async () => {
        const periode = moment(stateSituationSolde.periode, "MMYYYY");
        dispatch(sitSolAppActions.setPeriode(periode.add(1, "months").format("MMYYYY")));
    }, [dispatch, stateSituationSolde.periode]);

    return (
        <DialogContainer open={open} placement="top" taille="sm">
            <HeaderDialogContainer 
                actionsLeft={
                    <>
                        <IconButtonBase 
                            variant="white5Grey9White9" 
                            icon={<ChevronLeft fontSize="small" />} 
                            title={t("buttons.previous")} 
                            disabled={isLoading || isFetching} 
                            action={precedent} 
                        />
                        <IconButtonBase 
                            variant="white5Grey9White9" 
                            icon={<ChevronRight fontSize="small" />} 
                            title={t("buttons.next")} 
                            disabled={isLoading || isFetching} 
                            action={suivant} 
                        />
                    </>
                }
                actionsRight={
                    <IconButtonBase 
                        variant="white5Grey9White9" 
                        title={t("buttons.refresh")} 
                        icon={<Refresh fontSize="small" />} 
                        disabled={isLoading || isFetching} 
                        action={() => refetch()} 
                    />
                }
                title={stateSituationSolde.solde ? stateSituationSolde.solde.libelleAbsence : t("titles.balance_tracking")}
                close={() => setOpen(false)}
            />
            <Stack direction="row" spacing={0.5}>
                <Box width="50%">
                    <Stack spacing={0.5}>
                        <Box bgcolor={greenColor["02"]} p={0.3} borderRadius={2}>
                            <Box bgcolor={greenColor["05"]} p={1} borderRadius={2}>
                                <Typography fontSize="small" fontWeight="bold" color="white">
                                    {t("texts.solde_situation_date_2", {date: dateSolde.format("DD MMMM YYYY")})}
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Stack spacing={0.5} padding={0.5} borderRadius={2} bgcolor={greenColor["05"]}>
                                <Box p={1} bgcolor={whiteColor[5]} borderRadius={2}>
                                    <Typography fontSize="small" fontWeight="bold" textAlign="center">{stateSituationSolde?.solde?.libelleSolde}</Typography>
                                </Box>
                                <Box borderRadius={2} p={1} bgcolor={whiteColor[5]}>
                                    <Stack spacing={0}>
                                        <TextWithLabelContainer label={t("texts.balance")} value={Number(stateSituationSolde?.solde?.solde).toLocaleString("fr-FR")} labelWidth={true} twoPoints={false} />
                                        <TextWithLabelContainer label={t("texts.validated_request")} value={Number(stateSituationSolde?.solde?.demandesValidees).toLocaleString("fr-FR")} labelWidth={true} twoPoints={false} />
                                        <TextWithLabelContainer label={t("texts.pending_request")} value={Number(stateSituationSolde?.solde?.demandesEnCours).toLocaleString("fr-FR")} labelWidth={true} twoPoints={false} />
                                        <TextWithLabelContainer label={t("texts.new_balance")} value={Number(stateSituationSolde?.solde?.nouveauSolde).toLocaleString("fr-FR")} labelWidth={true} twoPoints={false} />
                                    </Stack>
                                </Box>
                            </Stack> 
                        </Box>
                    </Stack>
                </Box>
                <Box width="50%">
                    {isError ? 
                        <Box bgcolor={redColor[5]} borderRadius={2} boxSizing="border-box" height="100%" display="flex" alignItems="center" justifyContent="center" px={1}>
                            <Typography fontSize="small" fontWeight="bold" color={whiteColor[9]}>{error?.message}</Typography>
                        </Box>
                    :
                        <Stack spacing={0.5} height="100%">
                            <Box>
                                <SoldesText 
                                    isLoading={isLoading || isFetching}
                                    periode={stateSituationSolde.periode}   
                                />
                            </Box>
                            <Box borderRadius={2} bgcolor={greenColor["05"]} height={141.5} boxSizing="border-box" p={0.5} overflow={"auto"}>
                                <PerfectScrollbar>
                                    <SituationSoldeList data={data} isLoading={isLoading || isFetching} />
                                </PerfectScrollbar>
                            </Box>
                        </Stack>
                    }
                </Box>
            </Stack>
        </DialogContainer>
    );
}

SituationSoldeDialog.defaultProps = {
    open: false, 
    setOpen: () => {}
}

SituationSoldeDialog.propTypes = {
    open: PropTypes.bool, 
    setOpen: PropTypes.func
}

export default SituationSoldeDialog;