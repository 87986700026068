import { PAGES_DELEGATIONS } from '@constants/delegationsConstants'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    listed: false,
    filtered: false,
    tried: false,
    type: "E",
    page: PAGES_DELEGATIONS.LIST,
    delegations: [],
    delegationsDisplayed: [],
    delegationSelected: null,
    delegataires: [],
}

const dlgAppSlice = createSlice({
    name: 'dlgApp',
    initialState: initialState,
    reducers: {
        setDelegations: (state, action) => {
            state.delegations = action.payload;
            state.delegationsDisplayed = action.payload;
        },
        setDelegataires: (state, action) => {
            state.delegataires = action.payload;
        },
        lister: (state) => {
            state.listed = !state.listed
        },
        filtrer: (state, action) => {
            state.delegationsDisplayed = action.payload;
            state.filtered = true;
        },
        deleteFiltre: (state) => {
            state.delegationsDisplayed = state.delegations;
            state.filtered = false
        },
        trier: (state, action) => {
            state.delegationsDisplayed = action.payload;
            state.tried = true;
        },
        deleteTrie: (state) => {
            state.delegationsDisplayed = state.delegations;
            state.tried = false
        },
        toggleType: (state) => {
            state.type = state.type === "E" ? "H" : "E";
        },
        selectDelegation: (state, action) => {
            state.delegationSelected = action.payload;
        },
        goTo: (state, action) => {
            state.page = action.payload;
        },
        reset: () => initialState
    },
})

const { actions, reducer } = dlgAppSlice
export const { 
    setDelegations, 
    setDelegataires,
    lister, 
    filtrer, 
    deleteFiltre, 
    trier, 
    deleteTrie, 
    toggleType, 
    selectDelegation, 
    goTo,  
    reset 
} = actions
export default reducer