import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

const PlagesDebutVariables = (props) => {
    const { debutVariable, debutFixe, repriseFixe } = props;

    return (
        <Stack direction="row">
            <Box width={"15%"} justifyContent="left" display="flex">
                <Typography fontSize="11px">{debutVariable}</Typography>
            </Box>
            <Stack direction="row" width={"85%"}>
                <Box width="50%" justifyContent="left"  display="flex">
                    <Typography fontSize="11px">{debutFixe}</Typography>
                </Box>
                <Box width="50%" justifyContent="right"  display="flex">
                    <Typography fontSize="11px">{repriseFixe}</Typography>
                </Box>
            </Stack>
        </Stack>
    );
}

PlagesDebutVariables.defaultProps = {
    debutVariable: "",
    debutFixe: "",
    repriseFixe: ""
}

PlagesDebutVariables.propTypes = {
    debutVariable: PropTypes.string,
    debutFixe: PropTypes.string,
    repriseFixe: PropTypes.string
}

export default PlagesDebutVariables;