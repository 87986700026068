import { useCallback } from "react";
import { Box, Stack } from "@mui/material";
import { Add, Check } from "@mui/icons-material";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { Loader } from "@components/Loaders";
import { schemaCycleAffectation } from "../validations";
import { DialogContainer, HeaderDialogContainer } from "@components/Containers";
import { CycleAffectationCollectiveForm } from "../Horaires/Forms";
import { greenColor, greyColor } from "@styles";
import moment from "moment";

const defaultValues = {
    dateDebut: moment(),
    dateFin: null,
    horaires: [{ horaire: null }],
    cycleMin: 1,
    cycle: ""
}

const CycleAffectationsCollectivesDialog = (props) => {
    const { isLoading, open, setOpen, postCycleAffectations } = props;
    const methods = useForm({ mode: "all", defaultValues: defaultValues, resolver: joiResolver(schemaCycleAffectation) });
    const { fields, append, remove } = useFieldArray({ control: methods.control, name: "horaires" });
    
    const watchFieldArray = methods.watch("horaires");
    const controlledFields = fields.map((field, id) => {
        return {
          ...field,
          ...watchFieldArray[id]
        };
    });
    
    const handleAdd = useCallback(() => {
        append({horaire: null});
    }, [append])

    return (
        <DialogContainer open={open} taille="xs" placement="top">
            <HeaderDialogContainer  
                actionsLeft={
                    <Stack direction="row" spacing={0.5}>
                        <IconButtonBase icon={<Check fontSize="small" />} title="Valider" disabled={isLoading} action={() => methods.handleSubmit(postCycleAffectations)()} />
                        {controlledFields.length < 15 && <IconButtonBase icon={<Add fontSize="small" />} title="Ajouter un horaire" disabled={isLoading} action={() => handleAdd()} />}
                    </Stack>    
                }
                title="Cycle d'affectation"
                close={() => setOpen(false)}
            />
            {isLoading ? 
                <Box sx={styles.loaderStyle}><Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} /></Box> 
            : 
                <Stack spacing={0.5} bgcolor={greenColor["015"]} borderRadius={2} p={0.5}>
                    <FormProvider {...methods}>
                        <CycleAffectationCollectiveForm 
                            controlledFields={controlledFields} 
                            remove={remove} 
                            open={open} 
                        />
                    </FormProvider>
                </Stack>
            }
        </DialogContainer>
    );
}

CycleAffectationsCollectivesDialog.defaultProps = {
    isLoading: false,
    open: false,
    setOpen: () => {},
    postCycleAffectations: () => {}
}

CycleAffectationsCollectivesDialog.propTypes = {
    isLoading: PropTypes.bool,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    postCycleAffectations: PropTypes.func
}

const styles = {
    loaderStyle: {
        height: 100,
        alignItems: "center",
        display: "flex"
    }
}

export default CycleAffectationsCollectivesDialog;