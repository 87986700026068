import { BaseButton } from "@components/Buttons";
import { ChevronLeft, ChevronRight, Event, Refresh } from "@mui/icons-material";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { LocalizationProvider, MobileDatePicker, frFR } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

const PickerButton = (props) => {
    const { setOpen } = props;
    const { t } = useTranslation("global");
    
    return (
        <BaseButton color="green" height={40} maxHeight={null} action={() => setOpen(true)}>
            <Stack direction="row" display="flex" justifyContent="center" alignItems="center">
                <Box width={20}>
                    <Event />
                </Box>
                <Box flexGrow={1}>
                    <Typography>{t("buttons.select_month")}</Typography>
                </Box>
            </Stack>
        </BaseButton>
    )
}

const CalendrierActionsBarMobile = (props) => {
    const { 
        isLoading, 
        isError,
        date,
        precedent, 
        suivant,
        selectDate,
        refetch 
    } = props;
    const [open, setOpen] = useState(false);
    const { t } = useTranslation("global");

    const pickDate = useCallback((date) => {
        setOpen(false);
        selectDate(date);
    }, [selectDate]);

    if(isLoading) {
        return (
            <Stack spacing={0.5}>
                <Stack direction="row" spacing={0.5}>
                    <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={"100%"} height={40} />
                    <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={"100%"} height={40} />
                </Stack>
                <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={"100%"} height={40} />
                <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={"100%"} height={40} />
            </Stack>
        )
    }

    return (
        <Stack spacing={0.5}>
            {!isError &&
                <>
                    <Stack direction="row" spacing={0.5}>
                        <BaseButton color="green" height={40} maxHeight={null} action={precedent}>
                            <Stack direction="row" display="flex" justifyContent="center" alignItems="center">
                                <Box width={20}>
                                    <ChevronLeft />
                                </Box>
                                <Box flexGrow={1}>
                                    <Typography>{t("buttons.previous")}</Typography>
                                </Box>
                            </Stack>
                        </BaseButton>
                        <BaseButton color="green" height={40} maxHeight={null} action={suivant}>
                            <Stack direction="row" display="flex" justifyContent="center" alignItems="center">
                                <Box flexGrow={1}>
                                    <Typography>{t("buttons.next")}</Typography>
                                </Box>
                                <Box width={20}>
                                    <ChevronRight />
                                </Box>
                            </Stack>
                        </BaseButton>
                    </Stack>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"frFR"} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                        <MobileDatePicker
                            slots={{ field: PickerButton }}
                            slotProps={{ field: { setOpen } }}
                            open={open}
                            value={date}
                            views={["year", "month"]}
                            openTo="year"
                            onClose={() => setOpen(false)}
                            onOpen={() => setOpen(true)}
                            onMonthChange={pickDate}
                        />
                    </LocalizationProvider>
                </>
            }
            <BaseButton color="green" height={40} maxHeight={null} action={() => refetch()}>
                <Stack direction="row" display="flex" justifyContent="center" alignItems="center">
                    <Box width={20}>
                        <Refresh />
                    </Box>
                    <Box flexGrow={1}>
                        <Typography>{t("buttons.refresh")}</Typography>
                    </Box>
                </Stack>
            </BaseButton>
        </Stack>
    )
}


CalendrierActionsBarMobile.defaultProps = {
    isLoading: true, 
    isError: false, 
    date: null,
    precedent: () => {}, 
    suivant: () => {},
    selectDate: () => {},
    refetch: () => {}
}

CalendrierActionsBarMobile.propTypes = {
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool, 
    date: PropTypes.object,
    precedent: PropTypes.func, 
    suivant: PropTypes.func,
    selectDate: PropTypes.func,
    refetch: PropTypes.func
}

export default CalendrierActionsBarMobile;