import { Box } from "@mui/material";
import { Warning } from "@mui/icons-material";
import PropTypes from "prop-types";
import { Loader } from "@components/Loaders";
import IconButtonBase from "./IconButtonBase";
import { greenColor, greyColor, redColor } from "@styles";

const LoaderButton = (props) => {
    const { icon, title, isLoading, isError, errorMessage, action } = props;

    if(isLoading) {
        return (
            <Box width={30} height={30} alignItems="center" display="flex" justifyContent="center">
                <Loader size={17} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return (
            <IconButtonBase icon={<Warning fontSize="small" />} bgColor={redColor[2]} colorIconButton="white" title={errorMessage} />
        );
    }

    return (<IconButtonBase icon={icon} title={title} action={action} />);
}

LoaderButton.defaultProps = {
    icon: null, 
    title: "", 
    isLoading: true, 
    isError: false, 
    errorMessage: "", 
    action: () => {}
}

LoaderButton.propTypes = {
    icon: PropTypes.element, 
    title: PropTypes.string, 
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool, 
    errorMessage: PropTypes.string, 
    action: PropTypes.func
}

export default LoaderButton;