import { commentify, heurify, readFileAsync } from "@utils";
import moment from "moment";
import numeral from "numeral";
import voca from "voca";

/**
 * Formate les données provenant du formulaire des demandes de régularisation en données API
 * @param {string} matricule - matricule de l'utilisateur connecté
 * @param {string} dateDemande - date de la demande de régularisation DDMMYYYY
 * @param {*} data - données brutes du formulaire
 * @param {string} client - client de l'utilisateur connecté
 * @param {"N" | "O"} niveauValidation - validation de la demande
 * @param {string} codeRetour - code retour de la demande
 * @returns Retourne un objet pouvant formaté pour l'API
 */
export const formToDataDemandeRegularisation = (matricule, dateDemande, data, client, niveauValidation = "N", codeRetour = "") => {
    const regularisations = data.regularisations.map((regularisation, id) => ({id: id + 1, ...regularisation})).filter((regularisation) => (regularisation.mouvement || regularisation.date || regularisation.heure || regularisation.activite || regularisation.typeTravail)).map((regularisation) => {
        let heures = voca.substring(regularisation.heure, 0, 2);
        let minutes = voca.substring(regularisation.heure, 2);
        let heuresFormated = voca.replaceAll(heures, "_", "0")
        let minutesFormated = voca.replaceAll(minutes, "_", "0")
        return {
            id: String(regularisation.id).padStart(5, "0"),
            type: regularisation.mouvement,
            dateTransaction: moment(regularisation.date, "DD/MM/YYYY").isValid() ? moment(regularisation.date, "DD/MM/YYYY").format("DDMMYYYY") : "0",
            heureTransaction: regularisation.heure ? heuresFormated + minutesFormated : "0",
            activite: regularisation.activite,
            libelleActivite: "",
            typeTravail: regularisation.typeTravail,
            libelleTypeTravail: "",
            anomalie: ""
        }
    });

    return {
        matricule: matricule,
        dateDemande: dateDemande,
        motifDemande: data.motif,
        regularisations: regularisations,
        niveauValidation: niveauValidation,
        codeRetour: codeRetour,
        client: client
    }
};

export const formToDataRegulariser = (utilisateurHierarchique, matriculeHierarchique, matriculeCollaborateur, dateJourRegulariser, data, client, niveauValidation = "1") => {
    const regularisations = data.regularisations.map((regularisation, id) => ({id: id + 1, ...regularisation})).filter((regularisation) => (regularisation.mouvement || regularisation.date || regularisation.heure || regularisation.activite || regularisation.typeTravail)).map((regularisation) => {
        let heures = voca.substring(regularisation.heure, 0, 2);
        let minutes = voca.substring(regularisation.heure, 2);
        let heuresFormated = voca.replaceAll(heures, "_", "0")
        let minutesFormated = voca.replaceAll(minutes, "_", "0")
        
        return {
            id: String(regularisation.id).padStart(5, "0"),
            type: regularisation.mouvement,
            dateTransaction: moment(regularisation.date, "DD/MM/YYYY").isValid() ? moment(regularisation.date, "DD/MM/YYYY").format("DDMMYYYY") : "0",
            heureTransaction: regularisation.heure ? heuresFormated + minutesFormated : "0",
            activite: regularisation.activite,
            libelleActivite: "",
            typeTravail: regularisation.typeTravail,
            libelleTypeTravail: "",
            anomalie: ""
        }
    });

    const dateRegularisation = moment(dateJourRegulariser, "DDMMYYYY").format("YYYYMMDD")

    return {
        utilisateurHierarchique: utilisateurHierarchique,
        matriculeHierarchique: matriculeHierarchique,
        matriculeCollaborateur: matriculeCollaborateur,
        dateRegularisation: dateRegularisation,
        regularisations: regularisations,
        niveauValidation: niveauValidation,
        client: client
    }
};

export const dataToFormTransactions = (transactions) => {
    const regularisations = transactions.map((transaction, id) => {
        const dateTransaction = moment([transaction.anneeMouvement, transaction.moisMouvement - 1, transaction.jourMouvement]);
        const heuresTransaction = transaction.heureMouvement.length < 2 ? "0" + transaction.heureMouvement : transaction.heureMouvement;
        const minutesTransaction = transaction.minuteMouvement.length < 2 ? "0" + transaction.minuteMouvement : transaction.minuteMouvement;
        const heureTransaction = heuresTransaction + minutesTransaction;
        return {
            mouvement: transaction.typeMouvement ? transaction.typeMouvement : "",
            date: dateTransaction.isValid() ? dateTransaction : null,
            heure: heureTransaction,
            activite: transaction.codeActivite ? transaction.codeActivite : "",
            typeTravail: transaction.codeTypeTravail ? transaction.codeTypeTravail : ""
        }
    });
    
    return regularisations;
};

export const dataToFormRegularisations = (mouvements) => {
    const regularisations = mouvements.map((mouvement, id) => {
        const dateTransaction1 = mouvement.dateMouvement.length < 8 ? "0" + mouvement.dateMouvement : mouvement.dateMouvement;
        const dateTransaction2 = moment(dateTransaction1, "DDMMYYYY");
        const heuresTransaction = mouvement.heureMouvement.length < 4 ? "0" + mouvement.heureMouvement : mouvement.heureMouvement;
        const heureTransaction = heuresTransaction === "0" ? "" : voca.substring(heuresTransaction, 0, 2)  + voca.substring(heuresTransaction, 2);
   
        return {
            mouvement: mouvement.typeMouvement ? mouvement.typeMouvement : "",
            date: dateTransaction2.isValid() ? dateTransaction2 : null,
            heure: heureTransaction,
            activite: mouvement.codeActivite ? mouvement.codeActivite : "",
            typeTravail: mouvement.codeTypeTravail ? mouvement.codeTypeTravail : ""
        }
    });
    return regularisations;
};

export const dataToFormRegularisationsManager = (mouvements) => {
    const regularisations = mouvements.map((mouvement, id) => {
        const jourMouvement = mouvement.jourRegularisation.length < 2 ? "0" + mouvement.jourRegularisation : mouvement.jourRegularisation;
        const moisMouvement = mouvement.moisRegularisation.length < 2 ? "0" + mouvement.moisRegularisation : mouvement.moisRegularisation;
        const anneeMouvement = mouvement.anneeRegularisation;
        const dateMouvement = moment([anneeMouvement, moisMouvement - 1, jourMouvement]);
        const heuresMouvement = mouvement.heureRegularisation.length < 2 ? "0" + mouvement.heureRegularisation : mouvement.heureRegularisation;
        const minutesMouvement = mouvement.minuteRegularisation.length < 2 ? "0" + mouvement.minuteRegularisation : mouvement.minuteRegularisation;
        const heureMouvement = `${heuresMouvement}${minutesMouvement}`
        return {
            mouvement: mouvement.typeMouvement ? mouvement.typeMouvement : "",
            date: dateMouvement,
            heure: heureMouvement,
            activite: mouvement.codeActivite ? mouvement.codeActivite : "",
            typeTravail: mouvement.codeTypeTravail ? mouvement.codeTypeTravail : ""
        }
    });
    return regularisations;
};

export const dataToFormRegularisationsManagerSended = (mouvements) => {
    if(mouvements) {
        const regularisations = mouvements.map((mouvement, id) => {
            const dateMouvementFormated = mouvement.dateMouvement?.length < 8 ? "0" + mouvement.dateMouvement : mouvement.dateMouvement;
            const dateMouvement = moment(dateMouvementFormated, "DDMMYYYY");
            const heureMouvementFormated = mouvement.heureMouvement?.length < 4 ? "0" + mouvement.heureMouvement : mouvement.heureMouvement;
            const heureMouvement = moment(heureMouvementFormated, "HHmm").format("HHmm");
            return {
                mouvement: mouvement.typeMouvement ? mouvement.typeMouvement : "",
                date: dateMouvement,
                heure: heureMouvement,
                activite: mouvement.codeActivite ? mouvement.codeActivite : "",
                typeTravail: mouvement.codeTypeTravail ? mouvement.codeTypeTravail : ""
            }
        });
        
        return regularisations;
    }   
    return [];
};

export const formToDataAnomalies = (data, utilisateur, matricule, droitSalarie, droitInterimaire, client) => {
    const dateDebut = data?.dateDebut.format("YYYYMMDD");
    const dateFin = data?.dateFin.format("YYYYMMDD");
    const codeSociete = data?.societe ? data?.societe?.codeSociete : "";
    const codeEtablissement = data?.etablissement ? data?.etablissement?.code : "";
    const codeDirection = data?.direction ? data?.direction?.code : "";
    const codeService = data?.service ? data?.service?.codeService : "";
    const codeSecteur = data?.secteur ? data?.secteur?.code : "";
    const codeAnomalie = data?.anomalie ? data?.anomalie?.code : "";
    const typeMouvement = data?.typeMouvement === "T" ? "" : data.typeMouvement;  
    const gravite1 = data?.gravite1;
    const gravite2 = data?.gravite2;  
    const anomalieAReactualiser = data.anomalieAReactualiser ? "O" : "N";

    return {
        dateDebut: dateDebut,
        dateFin: dateFin,
        codeSociete: codeSociete,
        codeEtablissement: codeEtablissement,
        codeDirection: codeDirection,
        codeService: codeService,
        codeSecteur: codeSecteur,
        codeAnomalie: codeAnomalie,
        typeMouvement: typeMouvement,
        gravite1: gravite1,
        gravite2: gravite2,
        anomalieAReactualiser: anomalieAReactualiser,
        utilisateur: utilisateur,
        matricule: matricule,
        droitSalarie: droitSalarie,
        droitInterimaire: droitInterimaire,
        client: client
    }
};

export const formToDataAnomaliesCredit = (data, utilisateur, matricule, droitSalarie, droitInterimaire, client) => {
    const codeSociete = data?.societe ? data?.societe?.codeSociete : "";
    const codeEtablissement = data?.etablissement ? data?.etablissement?.code : "";
    const codeDirection = data?.direction ? data?.direction?.code : "";
    const codeService = data?.service ? data?.service?.codeService : "";
    const codeSecteur = data?.secteur ? data?.secteur?.code : "";
    const debitMaximum = numeral(data.debitCredit.debitMaximum).format("000.00").replace(",", "");
    const creditMaximum = numeral(data.debitCredit.creditMaximum).format("000.00").replace(",", "");

    return {
        codeSociete: codeSociete,
        codeEtablissement: codeEtablissement,
        codeDirection: codeDirection,
        codeService: codeService,
        codeSecteur: codeSecteur,
        debitMaximum: debitMaximum,
        creditMaximum: creditMaximum,
        utilisateur: utilisateur,
        matricule: matricule,
        droitSalarie: droitSalarie,
        droitInterimaire: droitInterimaire,
        client: client
    }
};

export const formToDataAnomaliesActivite = (data, utilisateur, matricule, droitSalarie, droitInterimaire, client) => {
    const dateDebut = data?.dateDebut.format("YYYYMMDD");
    const dateFin = data?.dateFin.format("YYYYMMDD");
    const codeSociete = data?.societe ? data?.societe?.codeSociete : "";
    const codeEtablissement = data?.etablissement ? data?.etablissement?.code : "";
    const codeDirection = data?.direction ? data?.direction?.code : "";
    const codeService = data?.service ? data?.service?.codeService : "";
    const codeSecteur = data?.secteur ? data?.secteur?.code : "";
    const codeActivite = data?.activite ? data?.activite.code : "";  
    const nombreHeuresDebut = numeral(data.nombreHeuresDebut).format("00.00").replace(",", "");
    const nombreHeuresFin = numeral(data.nombreHeuresFin).format("00.00").replace(",", "");

    return {
        dateDebut: dateDebut,
        dateFin: dateFin,
        codeSociete: codeSociete,
        codeEtablissement: codeEtablissement,
        codeDirection: codeDirection,
        codeService: codeService,
        codeSecteur: codeSecteur,
        codeActivite: codeActivite,
        nombreHeuresDebut: nombreHeuresDebut,
        nombreHeuresFin: nombreHeuresFin,
        utilisateur: utilisateur,
        matricule: matricule,
        droitSalarie: droitSalarie,
        droitInterimaire: droitInterimaire,
        client: client
    }
};


const getAmpm = (data) => {
    return data.ampm ? "PM" : "AM";
};

export const formToDataDemandeAbsence = (data, typeAbsence) => {
    const demande = {
        dateDebut: data.dateDebut?.isValid() ? data.dateDebut.format("DDMMYYYY") : "00000000", 
        typeAbsence: data.typeAbsence, 
        typeAbsenceObject: typeAbsence ? typeAbsence : null, 
        libelleAbsence: typeAbsence ? typeAbsence.libelleAbsence : "", 
        dateReprise: data.dateReprise?.isValid() ? data.dateReprise.format("DDMMYYYY") : "00000000", 
        ampm: data.type === "ampm" ? getAmpm(data) : "", 
        heureDebut: data.heureDebut?.isValid() ? data.heureDebut.format("HHmm") : "0000", 
        heureReprise: data.heureReprise?.isValid() ? data.heureReprise.format("HHmm") : "0000", 
        nombreHeures: data.nombreHeures?.isValid() ? data.nombreHeures.format("HHmm") : "0000", 
        motif: data.motif,
    }
    return demande;
}

const formateDelaiAttente = (delai) => {
    if(delai) {
        return delai < 10 ? `0${delai}` : String(delai);
    } else {
        return "00";
    }
};

export const formToDataDelegation = (data, client, matricule, id = "") => {
    const delegation = {
        absence: data.absence ? "O" : "N", 
        formation: data.formation ? "O" : "N", 
        noteFrais: data.noteFrais ? "O" : "N", 
        dateDebut: data.dateDebut?.isValid() ? data.dateDebut.format("DDMMYYYY") : "00000000", 
        dateReprise: data.dateReprise?.isValid() ? data.dateReprise.format("DDMMYYYY") : "00000000", 
        delegationImmediate: data.typeDelegation === "immediate" ? "O" : "N", 
        delegationDelaiAttente: data.typeDelegation === "delaiAttente" ? "O" : "N", 
        avertissementMail: data.avertissementMail ? "O" : "N", 
        delaiAttente: formateDelaiAttente(data.delaiAttente), 
        destinataire: data.destinataire ? data.destinataire.utilisateur : "",
        client: client,
        matricule: matricule,
        id: id
    }
    return delegation;
};

export const dataToFormDelegation = (data) => {
    const delegation = {
        absence: data.codeNature === "A", 
        formation: data.codeNature === "F", 
        noteFrais: data.codeNature === "P", 
        dateDebut: data.dateDebut !== "00000000" ? moment(data.dateDebut, "DDMMYYYY") : null, 
        dateReprise: data.dateReprise !== "00000000" ? moment(data.dateReprise, "DDMMYYYY") : null, 
        typeDelegation: data.type === "I" ? "immediate" : "delaiAttente", 
        avertissementMail: data.avertissementParMail === "O", 
        delaiAttente: Number(data.delaiAttente) > 0 ? data.delaiAttente : "", 
        destinataire: data.delegataire
    }
    return delegation;
};

export const fileToData = async (data) => {
    const contenu = await readFileAsync(data[0]);
    const fichier = {
        nom: data ? data[0].name : "", 
        contenu: contenu
    }
    return fichier;
}

export const formToDataPlanning = (data) => {
    const planning = {
        debu11: data.debu11 ? data.debu11 : "0",
        arre11: data.arre11 ? data.arre11 : "0",
        debu21: data.debu21 ? data.debu21 : "0",
        arre21: data.arre21 ? data.arre21 : "0",
        debu31: data.debu31 ? data.debu31 : "0",
        arre31: data.arre31 ? data.arre31 : "0",
        debu41: data.debu41 ? data.debu41 : "0",
        arre41: data.arre41 ? data.arre41 : "0",
        debu12: data.debu12 ? data.debu12 : "0",
        arre12: data.arre12 ? data.arre12 : "0",
        debu22: data.debu22 ? data.debu22 : "0",
        arre22: data.arre22 ? data.arre22 : "0",
        debu32: data.debu32 ? data.debu32 : "0",
        arre32: data.arre32 ? data.arre32 : "0",
        debu42: data.debu42 ? data.debu42 : "0",
        arre42: data.arre42 ? data.arre42 : "0",
        debu13: data.debu13 ? data.debu13 : "0",
        arre13: data.arre13 ? data.arre13 : "0",
        debu23: data.debu23 ? data.debu23 : "0",
        arre23: data.arre23 ? data.arre23 : "0",
        debu33: data.debu33 ? data.debu33 : "0",
        arre33: data.arre33 ? data.arre33 : "0",
        debu43: data.debu43 ? data.debu43 : "0",
        arre43: data.arre43 ? data.arre43 : "0",
        debu14: data.debu14 ? data.debu14 : "0",
        arre14: data.arre14 ? data.arre14 : "0",
        debu24: data.debu24 ? data.debu24 : "0",
        arre24: data.arre24 ? data.arre24 : "0",
        debu34: data.debu34 ? data.debu34 : "0",
        arre34: data.arre34 ? data.arre34 : "0",
        debu44: data.debu44 ? data.debu44 : "0",
        arre44: data.arre44 ? data.arre44 : "0",
        debu15: data.debu15 ? data.debu15 : "0",
        arre15: data.arre15 ? data.arre15 : "0",
        debu25: data.debu25 ? data.debu25 : "0",
        arre25: data.arre25 ? data.arre25 : "0",
        debu35: data.debu35 ? data.debu35 : "0",
        arre35: data.arre35 ? data.arre35 : "0",
        debu45: data.debu45 ? data.debu45 : "0",
        arre45: data.arre45 ? data.arre45 : "0",
        debu16: data.debu16 ? data.debu16 : "0",
        arre16: data.arre16 ? data.arre16 : "0",
        debu26: data.debu26 ? data.debu26 : "0",
        arre26: data.arre26 ? data.arre26 : "0",
        debu36: data.debu36 ? data.debu36 : "0",
        arre36: data.arre36 ? data.arre36 : "0",
        debu46: data.debu46 ? data.debu46 : "0",
        arre46: data.arre46 ? data.arre46 : "0",
        debu17: data.debu17 ? data.debu17 : "0",
        arre17: data.arre17 ? data.arre17 : "0",
        debu27: data.debu27 ? data.debu27 : "0",
        arre27: data.arre27 ? data.arre27 : "0",
        debu37: data.debu37 ? data.debu37 : "0",
        arre37: data.arre37 ? data.arre37 : "0",
        debu47: data.debu47 ? data.debu47 : "0",
        arre47: data.arre47 ? data.arre47 : "0",
        tt11: data.tt11 ? data.tt11.code : "",
        tt21: data.tt21 ? data.tt21.code : "",
        tt31: data.tt31 ? data.tt31.code : "",
        tt41: data.tt41 ? data.tt41.code : "",
        tt12: data.tt12 ? data.tt12.code : "",
        tt22: data.tt22 ? data.tt22.code : "",
        tt32: data.tt32 ? data.tt32.code : "",
        tt42: data.tt42 ? data.tt42.code : "",
        tt13: data.tt13 ? data.tt13.code : "",
        tt23: data.tt23 ? data.tt23.code : "",
        tt33: data.tt33 ? data.tt33.code : "",
        tt43: data.tt43 ? data.tt43.code : "",
        tt14: data.tt14 ? data.tt14.code : "",
        tt24: data.tt24 ? data.tt24.code : "",
        tt34: data.tt34 ? data.tt34.code : "",
        tt44: data.tt44 ? data.tt44.code : "",
        tt15: data.tt15 ? data.tt15.code : "",
        tt25: data.tt25 ? data.tt25.code : "",
        tt35: data.tt35 ? data.tt35.code : "",
        tt45: data.tt45 ? data.tt45.code : "",
        tt16: data.tt16 ? data.tt16.code : "",
        tt26: data.tt26 ? data.tt26.code : "",
        tt36: data.tt36 ? data.tt36.code : "",
        tt46: data.tt46 ? data.tt46.code : "",
        tt17: data.tt17 ? data.tt17.code : "",
        tt27: data.tt27 ? data.tt27.code : "",
        tt37: data.tt37 ? data.tt37.code : "",
        tt47: data.tt47 ? data.tt47.code : "",
        model1: data.model1 ? data.model1 : "",
        model2: data.model2 ? data.model2 : "",
        model3: data.model3 ? data.model3 : "",
        model4: data.model4 ? data.model4 : "",
        model5: data.model5 ? data.model5 : "",
        model6: data.model6 ? data.model6 : "",
        model7: data.model7 ? data.model7 : "",
        rattachement1: data.rattachement1 ? "O" : "N",
        rattachement2: data.rattachement2 ? "O" : "N",
        rattachement3: data.rattachement3 ? "O" : "N",
        rattachement4: data.rattachement4 ? "O" : "N",
        rattachement5: data.rattachement5 ? "O" : "N",
        rattachement6: data.rattachement6 ? "O" : "N",
        rattachement7: data.rattachement7 ? "O" : "N",
    }

    const modificationAffectationsHoraires = data.modificationAffectationsHoraires ? "O" : "N";
    return {planning: planning, modificationAffectationsHoraires: modificationAffectationsHoraires};
};

const findValueInOptions = (code, options) => options.find(option => option.code === code) 

export const dataToFormPlanning = (data, options) => {
    const planning = {
        debu11: heurify(data.heures[0][0], ""),
        arre11: heurify(data.heures[0][1], ""),
        debu21: heurify(data.heures[0][2], ""),
        arre21: heurify(data.heures[0][3], ""),
        debu31: heurify(data.heures[0][4], ""),
        arre31: heurify(data.heures[0][5], ""),
        debu41: heurify(data.heures[0][6], ""),
        arre41: heurify(data.heures[0][7], ""),
        debu12: heurify(data.heures[1][0], ""),
        arre12: heurify(data.heures[1][1], ""),
        debu22: heurify(data.heures[1][2], ""),
        arre22: heurify(data.heures[1][3], ""),
        debu32: heurify(data.heures[1][4], ""),
        arre32: heurify(data.heures[1][5], ""),
        debu42: heurify(data.heures[1][6], ""),
        arre42: heurify(data.heures[1][7], ""),
        debu13: heurify(data.heures[2][0], ""),
        arre13: heurify(data.heures[2][1], ""),
        debu23: heurify(data.heures[2][2], ""),
        arre23: heurify(data.heures[2][3], ""),
        debu33: heurify(data.heures[2][4], ""),
        arre33: heurify(data.heures[2][5], ""),
        debu43: heurify(data.heures[2][6], ""),
        arre43: heurify(data.heures[2][7], ""),
        debu14: heurify(data.heures[3][0], ""),
        arre14: heurify(data.heures[3][1], ""),
        debu24: heurify(data.heures[3][2], ""),
        arre24: heurify(data.heures[3][3], ""),
        debu34: heurify(data.heures[3][4], ""),
        arre34: heurify(data.heures[3][5], ""),
        debu44: heurify(data.heures[3][6], ""),
        arre44: heurify(data.heures[3][7], ""),
        debu15: heurify(data.heures[4][0], ""),
        arre15: heurify(data.heures[4][1], ""),
        debu25: heurify(data.heures[4][2], ""),
        arre25: heurify(data.heures[4][3], ""),
        debu35: heurify(data.heures[4][4], ""),
        arre35: heurify(data.heures[4][5], ""),
        debu45: heurify(data.heures[4][6], ""),
        arre45: heurify(data.heures[4][7], ""),
        debu16: heurify(data.heures[5][0], ""),
        arre16: heurify(data.heures[5][1], ""),
        debu26: heurify(data.heures[5][2], ""),
        arre26: heurify(data.heures[5][3], ""),
        debu36: heurify(data.heures[5][4], ""),
        arre36: heurify(data.heures[5][5], ""),
        debu46: heurify(data.heures[5][6], ""),
        arre46: heurify(data.heures[5][7], ""),
        debu17: heurify(data.heures[6][0], ""),
        arre17: heurify(data.heures[6][1], ""),
        debu27: heurify(data.heures[6][2], ""),
        arre27: heurify(data.heures[6][3], ""),
        debu37: heurify(data.heures[6][4], ""),
        arre37: heurify(data.heures[6][5], ""),
        debu47: heurify(data.heures[6][6], ""),
        arre47: heurify(data.heures[6][7], ""),
        tt11: data.typesTravail[0][0] ? findValueInOptions(data.typesTravail[0][0], options) : null,
        tt21: data.typesTravail[1][0] ? findValueInOptions(data.typesTravail[1][0], options) : null,
        tt31: data.typesTravail[2][0] ? findValueInOptions(data.typesTravail[2][0], options) : null,
        tt41: data.typesTravail[3][0] ? findValueInOptions(data.typesTravail[3][0], options) : null,
        tt12: data.typesTravail[0][1] ? findValueInOptions(data.typesTravail[0][1], options) : null,
        tt22: data.typesTravail[1][1] ? findValueInOptions(data.typesTravail[1][1], options) : null,
        tt32: data.typesTravail[2][1] ? findValueInOptions(data.typesTravail[2][1], options) : null,
        tt42: data.typesTravail[3][1] ? findValueInOptions(data.typesTravail[3][1], options) : null,
        tt13: data.typesTravail[0][2] ? findValueInOptions(data.typesTravail[0][2], options) : null,
        tt23: data.typesTravail[1][2] ? findValueInOptions(data.typesTravail[1][2], options) : null,
        tt33: data.typesTravail[2][2] ? findValueInOptions(data.typesTravail[2][2], options) : null,
        tt43: data.typesTravail[3][2] ? findValueInOptions(data.typesTravail[3][2], options) : null,
        tt14: data.typesTravail[0][3] ? findValueInOptions(data.typesTravail[0][3], options) : null,
        tt24: data.typesTravail[1][3] ? findValueInOptions(data.typesTravail[1][3], options) : null,
        tt34: data.typesTravail[2][3] ? findValueInOptions(data.typesTravail[2][3], options) : null,
        tt44: data.typesTravail[3][3] ? findValueInOptions(data.typesTravail[3][3], options) : null,
        tt15: data.typesTravail[0][4] ? findValueInOptions(data.typesTravail[0][4], options) : null,
        tt25: data.typesTravail[1][4] ? findValueInOptions(data.typesTravail[1][4], options) : null,
        tt35: data.typesTravail[2][4] ? findValueInOptions(data.typesTravail[2][4], options) : null,
        tt45: data.typesTravail[3][4] ? findValueInOptions(data.typesTravail[3][4], options) : null,
        tt16: data.typesTravail[0][5] ? findValueInOptions(data.typesTravail[0][5], options) : null,
        tt26: data.typesTravail[1][5] ? findValueInOptions(data.typesTravail[1][5], options) : null,
        tt36: data.typesTravail[2][5] ? findValueInOptions(data.typesTravail[2][5], options) : null,
        tt46: data.typesTravail[3][5] ? findValueInOptions(data.typesTravail[3][5], options) : null,
        tt17: data.typesTravail[0][6] ? findValueInOptions(data.typesTravail[0][6], options) : null,
        tt27: data.typesTravail[1][6] ? findValueInOptions(data.typesTravail[1][6], options) : null,
        tt37: data.typesTravail[2][6] ? findValueInOptions(data.typesTravail[2][6], options) : null,
        tt47: data.typesTravail[3][6] ? findValueInOptions(data.typesTravail[3][6], options) : null,
        model1: data.modeles[0],
        model2: data.modeles[1],
        model3: data.modeles[2],
        model4: data.modeles[3],
        model5: data.modeles[4],
        model6: data.modeles[5],
        model7: data.modeles[6],
        rattachement1: data.rattachements[0] === "O",
        rattachement2: data.rattachements[1] === "O",
        rattachement3: data.rattachements[2] === "O",
        rattachement4: data.rattachements[3] === "O",
        rattachement5: data.rattachements[4] === "O",
        rattachement6: data.rattachements[5] === "O",
        rattachement7: data.rattachements[6] === "O",
        modificationAffectationsHoraires: false
    }

    return planning;
};

export const dataToFormPlanningGestionTemps = (data, options) => {
    const planning = {
        debut11: heurify(data.lundi.debut1, ""),
        arret11: heurify(data.lundi.arret1, ""),
        debut21: heurify(data.lundi.debut2, ""),
        arret21: heurify(data.lundi.arret2, ""),
        debut31: heurify(data.lundi.debut3, ""),
        arret31: heurify(data.lundi.arret3, ""),
        debut41: heurify(data.lundi.debut4, ""),
        arret41: heurify(data.lundi.arret4, ""),
        debut12: heurify(data.mardi.debut1, ""),
        arret12: heurify(data.mardi.arret1, ""),
        debut22: heurify(data.mardi.debut2, ""),
        arret22: heurify(data.mardi.arret2, ""),
        debut32: heurify(data.mardi.debut3, ""),
        arret32: heurify(data.mardi.arret3, ""),
        debut42: heurify(data.mardi.debut4, ""),
        arret42: heurify(data.mardi.arret4, ""),
        debut13: heurify(data.mercredi.debut1, ""),
        arret13: heurify(data.mercredi.arret1, ""),
        debut23: heurify(data.mercredi.debut2, ""),
        arret23: heurify(data.mercredi.arret2, ""),
        debut33: heurify(data.mercredi.debut3, ""),
        arret33: heurify(data.mercredi.arret3, ""),
        debut43: heurify(data.mercredi.debut4, ""),
        arret43: heurify(data.mercredi.arret4, ""),
        debut14: heurify(data.jeudi.debut1, ""),
        arret14: heurify(data.jeudi.arret1, ""),
        debut24: heurify(data.jeudi.debut2, ""),
        arret24: heurify(data.jeudi.arret2, ""),
        debut34: heurify(data.jeudi.debut3, ""),
        arret34: heurify(data.jeudi.arret3, ""),
        debut44: heurify(data.jeudi.debut4, ""),
        arret44: heurify(data.jeudi.arret4, ""),
        debut15: heurify(data.vendredi.debut1, ""),
        arret15: heurify(data.vendredi.arret1, ""),
        debut25: heurify(data.vendredi.debut2, ""),
        arret25: heurify(data.vendredi.arret2, ""),
        debut35: heurify(data.vendredi.debut3, ""),
        arret35: heurify(data.vendredi.arret3, ""),
        debut45: heurify(data.vendredi.debut4, ""),
        arret45: heurify(data.vendredi.arret4, ""),
        debut16: heurify(data.samedi.debut1, ""),
        arret16: heurify(data.samedi.arret1, ""),
        debut26: heurify(data.samedi.debut2, ""),
        arret26: heurify(data.samedi.arret2, ""),
        debut36: heurify(data.samedi.debut3, ""),
        arret36: heurify(data.samedi.arret3, ""),
        debut46: heurify(data.samedi.debut4, ""),
        arret46: heurify(data.samedi.arret4, ""),
        debut17: heurify(data.dimanche.debut1, ""),
        arret17: heurify(data.dimanche.arret1, ""),
        debut27: heurify(data.dimanche.debut2, ""),
        arret27: heurify(data.dimanche.arret2, ""),
        debut37: heurify(data.dimanche.debut3, ""),
        arret37: heurify(data.dimanche.arret3, ""),
        debut47: heurify(data.dimanche.debut4, ""),
        arret47: heurify(data.dimanche.arret4, ""),
        tt11: data.lundi.typeTravail1 ? findValueInOptions(data.lundi.typeTravail1, options) : null,
        tt21: data.lundi.typeTravail2 ? findValueInOptions(data.lundi.typeTravail2, options) : null,
        tt31: data.lundi.typeTravail3 ? findValueInOptions(data.lundi.typeTravail3, options) : null,
        tt41: data.lundi.typeTravail4 ? findValueInOptions(data.lundi.typeTravail4, options) : null,
        tt12: data.mardi.typeTravail1 ? findValueInOptions(data.mardi.typeTravail1, options) : null,
        tt22: data.mardi.typeTravail2 ? findValueInOptions(data.mardi.typeTravail2, options) : null,
        tt32: data.mardi.typeTravail3 ? findValueInOptions(data.mardi.typeTravail3, options) : null,
        tt42: data.mardi.typeTravail4 ? findValueInOptions(data.mardi.typeTravail4, options) : null,
        tt13: data.mercredi.typeTravail1 ? findValueInOptions(data.mercredi.typeTravail1, options) : null,
        tt23: data.mercredi.typeTravail2 ? findValueInOptions(data.mercredi.typeTravail2, options) : null,
        tt33: data.mercredi.typeTravail3 ? findValueInOptions(data.mercredi.typeTravail3, options) : null,
        tt43: data.mercredi.typeTravail4 ? findValueInOptions(data.mercredi.typeTravail4, options) : null,
        tt14: data.jeudi.typeTravail1 ? findValueInOptions(data.jeudi.typeTravail1, options) : null,
        tt24: data.jeudi.typeTravail2 ? findValueInOptions(data.jeudi.typeTravail2, options) : null,
        tt34: data.jeudi.typeTravail3 ? findValueInOptions(data.jeudi.typeTravail3, options) : null,
        tt44: data.jeudi.typeTravail4 ? findValueInOptions(data.jeudi.typeTravail4, options) : null,
        tt15: data.vendredi.typeTravail1 ? findValueInOptions(data.vendredi.typeTravail1, options) : null,
        tt25: data.vendredi.typeTravail2 ? findValueInOptions(data.vendredi.typeTravail2, options) : null,
        tt35: data.vendredi.typeTravail3 ? findValueInOptions(data.vendredi.typeTravail3, options) : null,
        tt45: data.vendredi.typeTravail4 ? findValueInOptions(data.vendredi.typeTravail4, options) : null,
        tt16: data.samedi.typeTravail1 ? findValueInOptions(data.samedi.typeTravail1, options) : null,
        tt26: data.samedi.typeTravail2 ? findValueInOptions(data.samedi.typeTravail2, options) : null,
        tt36: data.samedi.typeTravail3 ? findValueInOptions(data.samedi.typeTravail3, options) : null,
        tt46: data.samedi.typeTravail4 ? findValueInOptions(data.samedi.typeTravail4, options) : null,
        tt17: data.dimanche.typeTravail1 ? findValueInOptions(data.dimanche.typeTravail1, options) : null,
        tt27: data.dimanche.typeTravail2 ? findValueInOptions(data.dimanche.typeTravail2, options) : null,
        tt37: data.dimanche.typeTravail3 ? findValueInOptions(data.dimanche.typeTravail3, options) : null,
        tt47: data.dimanche.typeTravail4 ? findValueInOptions(data.dimanche.typeTravail4, options) : null,
        model1: data.modeleLundi,
        model2: data.modeleMardi,
        model3: data.modeleMercredi,
        model4: data.modeleJeudi,
        model5: data.modeleVendredi,
        model6: data.modeleSamedi,
        model7: data.modeleDimanche,
        rattachement1: data.rattachementLundi === "O",
        rattachement2: data.rattachementMardi === "O",
        rattachement3: data.rattachementMercredi === "O",
        rattachement4: data.rattachementJeudi === "O",
        rattachement5: data.rattachementVendredi === "O",
        rattachement6: data.rattachementSamedi === "O",
        rattachement7: data.rattachementDimanche === "O",
        modificationAffectationsHoraires: false
    }

    return planning;
};

export const formToDataPlanningGestionTemps = (data) => {
    const planning = {
        lundi: {
            debut1: data.debut11 ? data.debut11 : "0",
            arret1: data.arret11 ? data.arret11 : "0",
            debut2: data.debut21 ? data.debut21 : "0",
            arret2: data.arret21 ? data.arret21 : "0",
            debut3: data.debut31 ? data.debut31 : "0",
            arret3: data.arret31 ? data.arret31 : "0",
            debut4: data.debut41 ? data.debut41 : "0",
            arret4: data.arret41 ? data.arret41 : "0",
            typeTravail1: data.tt11 ? data.tt11.code : "",
            typeTravail2: data.tt21 ? data.tt21.code : "",
            typeTravail3: data.tt31 ? data.tt31.code : "",
            typeTravail4: data.tt41 ? data.tt41.code : "",
        },
        mardi: {
            debut1: data.debut12 ? data.debut12 : "0",
            arret1: data.arret12 ? data.arret12 : "0",
            debut2: data.debut22 ? data.debut22 : "0",
            arret2: data.arret22 ? data.arret22 : "0",
            debut3: data.debut32 ? data.debut32 : "0",
            arret3: data.arret32 ? data.arret32 : "0",
            debut4: data.debut42 ? data.debut42 : "0",
            arret4: data.arret42 ? data.arret42 : "0",
            typeTravail1: data.tt12 ? data.tt12.code : "",
            typeTravail2: data.tt22 ? data.tt22.code : "",
            typeTravail3: data.tt32 ? data.tt32.code : "",
            typeTravail4: data.tt42 ? data.tt42.code : "",
        },
        mercredi: {
            debut1: data.debut13 ? data.debut13 : "0",
            arret1: data.arret13 ? data.arret13 : "0",
            debut2: data.debut23 ? data.debut23 : "0",
            arret2: data.arret23 ? data.arret23 : "0",
            debut3: data.debut33 ? data.debut33 : "0",
            arret3: data.arret33 ? data.arret33 : "0",
            debut4: data.debut43 ? data.debut43 : "0",
            arret4: data.arret43 ? data.arret43 : "0",
            typeTravail1: data.tt13 ? data.tt13.code : "",
            typeTravail2: data.tt23 ? data.tt23.code : "",
            typeTravail3: data.tt33 ? data.tt33.code : "",
            typeTravail4: data.tt43 ? data.tt43.code : "",
        },
        jeudi: {
            debut1: data.debut14 ? data.debut14 : "0",
            arret1: data.arret14 ? data.arret14 : "0",
            debut2: data.debut24 ? data.debut24 : "0",
            arret2: data.arret24 ? data.arret24 : "0",
            debut3: data.debut34 ? data.debut34 : "0",
            arret3: data.arret34 ? data.arret34 : "0",
            debut4: data.debut44 ? data.debut44 : "0",
            arret4: data.arret44 ? data.arret44 : "0",
            typeTravail1: data.tt14 ? data.tt14.code : "",
            typeTravail2: data.tt24 ? data.tt24.code : "",
            typeTravail3: data.tt34 ? data.tt34.code : "",
            typeTravail4: data.tt44 ? data.tt44.code : "",
        },
        vendredi: {
            debut1: data.debut15 ? data.debut15 : "0",
            arret1: data.arret15 ? data.arret15 : "0",
            debut2: data.debut25 ? data.debut25 : "0",
            arret2: data.arret25 ? data.arret25 : "0",
            debut3: data.debut35 ? data.debut35 : "0",
            arret3: data.arret35 ? data.arret35 : "0",
            debut4: data.debut45 ? data.debut45 : "0",
            arret4: data.arret45 ? data.arret45 : "0",
            typeTravail1: data.tt15 ? data.tt15.code : "",
            typeTravail2: data.tt25 ? data.tt25.code : "",
            typeTravail3: data.tt35 ? data.tt35.code : "",
            typeTravail4: data.tt45 ? data.tt45.code : "",
        },
        samedi: {
            debut1: data.debut16 ? data.debut16 : "0",
            arret1: data.arret16 ? data.arret16 : "0",
            debut2: data.debut26 ? data.debut26 : "0",
            arret2: data.arret26 ? data.arret26 : "0",
            debut3: data.debut36 ? data.debut36 : "0",
            arret3: data.arret36 ? data.arret36 : "0",
            debut4: data.debut46 ? data.debut46 : "0",
            arret4: data.arret46 ? data.arret46 : "0",
            typeTravail1: data.tt16 ? data.tt16.code : "",
            typeTravail2: data.tt26 ? data.tt26.code : "",
            typeTravail3: data.tt36 ? data.tt36.code : "",
            typeTravail4: data.tt46 ? data.tt46.code : "",
        },
        dimanche: {
            debut1: data.debut17 ? data.debut17 : "0",
            arret1: data.arret17 ? data.arret17 : "0",
            debut2: data.debut27 ? data.debut27 : "0",
            arret2: data.arret27 ? data.arret27 : "0",
            debut3: data.debut37 ? data.debut37 : "0",
            arret3: data.arret37 ? data.arret37 : "0",
            debut4: data.debut47 ? data.debut47 : "0",
            arret4: data.arret47 ? data.arret47 : "0",
            typeTravail1: data.tt17 ? data.tt17.code : "",
            typeTravail2: data.tt27 ? data.tt27.code : "",
            typeTravail3: data.tt37 ? data.tt37.code : "",
            typeTravail4: data.tt47 ? data.tt47.code : "",
        },
        modeleLundi: data.model1 ? data.model1 : "",
        modeleMardi: data.model2 ? data.model2 : "",
        modeleMercredi: data.model3 ? data.model3 : "",
        modeleJeudi: data.model4 ? data.model4 : "",
        modeleVendredi: data.model5 ? data.model5 : "",
        modeleSamedi: data.model6 ? data.model6 : "",
        modeleDimanche: data.model7 ? data.model7 : "",
        rattachementLundi: data.rattachement1 ? "O" : "N",
        rattachementMardi: data.rattachement2 ? "O" : "N",
        rattachementMercredi: data.rattachement3 ? "O" : "N",
        rattachementJeudi: data.rattachement4 ? "O" : "N",
        rattachementVendredi: data.rattachement5 ? "O" : "N",
        rattachementSamedi: data.rattachement6 ? "O" : "N",
        rattachementDimanche: data.rattachement7 ? "O" : "N",
    }

    // const modificationAffectationsHoraires = data.modificationAffectationsHoraires ? "O" : "N";
    return planning;
};

export const formToDataMouvement = (data, utilisateur, matriculeHierarchique, matriculeCollaborateur, droitSalarie, droitInterimaire, client) => {
    const dateDebut = data?.dateDebut.format("DDMMYYYY");
    const dateFin = data?.dateFin.format("DDMMYYYY");
    const codeSociete = data?.societe ? data?.societe?.codeSociete : "";
    const codeEtablissement = data?.etablissement ? data?.etablissement?.code : "";
    const codeDirection = data?.direction ? data?.direction?.code : "";
    const codeService = data?.service ? data?.service?.codeService : "";
    const codeSecteur = data?.secteur ? data?.secteur?.code : "";
    const codeEmploi = data?.emploi ? data?.emploi?.code : "";
    const codeHoraire = data?.horaire ? data?.horaire?.codeHoraire : "";

    return {
        dateDebut: dateDebut,
        dateFin: dateFin,
        codeSociete: codeSociete,
        codeEtablissement: codeEtablissement,
        codeDirection: codeDirection,
        codeService: codeService,
        codeSecteur: codeSecteur,
        codeEmploi: codeEmploi,
        codeHoraire: codeHoraire,
        droitSalarie: droitSalarie,
        droitInterimaire: droitInterimaire,
        matriculeATraiter: matriculeCollaborateur,
        typeMatriculeATraiter: matriculeCollaborateur ? "P" : "",
        jour: data.jour === "0" ? "" : data.jour,
        evenement: data.evenement,
        heureLimite: data.heureLimite,
        detail: data.detail ? "O" : "N",
        utilisateur: utilisateur,
        matriculeHierarchique: matriculeHierarchique,
        client: client
    }
};

export const formToDataEntretien = (data, codeSociete, matricule, codeCle, client) => {
    const annee = data?.datetime?.year();
    const mois = data?.datetime?.month() + 1;
    const jour = data?.datetime?.date();
    const heure = data?.datetime?.hour();
    const minute = data?.datetime?.minute();
    const interlocuteur = data?.interlocuteur?.value;
    const commentaires = commentify(data?.commentaire);

    return {
        client: client,
        codeSociete: codeSociete,
        matricule: matricule,
        codeCle: codeCle,
        annee: annee,
        mois: mois,
        jour: jour,
        heure: heure,
        minute: minute,
        statut: data?.statut,
        interlocuteur: interlocuteur,
        initiative: data?.initiative,
        sujet: data?.sujet,
        commentaire1: commentaires[0] ? commentaires[0] : "",
        commentaire2: commentaires[1] ? commentaires[1] : "",
        commentaire3: commentaires[2] ? commentaires[2] : "",
        commentaire4: commentaires[3] ? commentaires[3] : "",
        commentaire5: commentaires[4] ? commentaires[4] : "",
        commentaire6: commentaires[5] ? commentaires[5] : "",
        commentaire7: commentaires[6] ? commentaires[6] : "",
        commentaire8: commentaires[7] ? commentaires[7] : "",
        commentaire9: commentaires[8] ? commentaires[8] : "",
        commentaire10: commentaires[9] ? commentaires[9] : ""
    }
};

export const formToDataCS = (data, otherData) => {    
    const commentaires = commentify(data?.commentaire);

    return {
        codeSociete: otherData.codeSociete,
        matricule: otherData.matricule,
        codeCle: otherData.codeCle,
        rang: otherData.rang,
        codeSection: otherData.codeSection,
        codeSousSection: otherData.codeSousSection || 0,
        codeCompetence: otherData.codeCompetence,
        codeQuestion: 0,
        commentaires: commentaires,
        client: otherData.client
    }
};

export const formToDataSS = (data, otherData) => {    
    const commentaires = commentify(data?.commentaire);
    const champComplementaire1 = data?.complement1 || "";
    const champComplementaire2 = data?.complement2 || "";
    const champComplementaire3 = data?.complement3 || "";
    const champComplementaire4 = data?.complement4 || "";
    const evaluationBrut = !!data?.evaluation ? JSON.parse(data?.evaluation) : "";
    const evaluation = evaluationBrut?.value || "";
    const classement = evaluationBrut?.classement || "";
    const date = data?.date?.format("DDMMYYYY") || "0";

    return {
        codeSociete: otherData.codeSociete,
        matricule: otherData.matricule,
        codeCle: otherData.codeCle,
        rang: otherData.rang,
        codeSection: otherData.codeSection,
        codeSousSection: otherData.codeSousSection || 0,
        codeCompetence: otherData.codeCompetence,
        codeQuestion: otherData.codeQuestion,        
        codeIdentificationSection: otherData.codeIdentificationSection,
        commentaires: commentaires,
        complement1: champComplementaire1,
        complement2: champComplementaire2,
        complement3: champComplementaire3,
        complement4: champComplementaire4,
        evaluation: evaluation,
        classement: classement,
        date: date,
        client: otherData.client
    }
};

export const formToDataQ = (data, otherData) => {    
    const champComplementaire1 = data?.complement1 || "";
    const champComplementaire2 = data?.complement2 || "";
    const champComplementaire3 = data?.complement3 || "";
    const champComplementaire4 = data?.complement4 || "";
    const date = data?.date?.format("DDMMYYYY") || "0";
    const reponseQuestion1Brut = !!data?.reponseQuestion1 ? JSON.parse(data?.reponseQuestion1) : "";
    const reponseQuestion2Brut = !!data?.reponseQuestion2 ? JSON.parse(data?.reponseQuestion2) : "";
    const reponseQuestion3Brut = !!data?.reponseQuestion3 ? JSON.parse(data?.reponseQuestion3) : "";
    const reponseQuestion4Brut = !!data?.reponseQuestion4 ? JSON.parse(data?.reponseQuestion4) : "";
    const reponseQuestion5Brut = !!data?.reponseQuestion5 ? JSON.parse(data?.reponseQuestion5) : "";
    const reponseQuestion6Brut = !!data?.reponseQuestion6 ? JSON.parse(data?.reponseQuestion6) : "";
    const reponseQuestion1 = reponseQuestion1Brut?.value || "";
    const reponseQuestion2 = reponseQuestion2Brut?.value || "";
    const reponseQuestion3 = reponseQuestion3Brut?.value || "";
    const reponseQuestion4 = reponseQuestion4Brut?.value || "";
    const reponseQuestion5 = reponseQuestion5Brut?.value || "";
    const reponseQuestion6 = reponseQuestion6Brut?.value || "";
    let questionsCommentaires = [];

    for(let i = 1; i <= 6; i++) {
        const commentaires = commentify(data?.[`commentaire${i}`]);
        const codeQuestion = otherData?.[`codeQuestion${i}`]
        questionsCommentaires.push({ codeQuestion: codeQuestion, commentaires: commentaires });
    }

    return {
        codeSociete: otherData.codeSociete,
        matricule: otherData.matricule,
        codeCle: otherData.codeCle,
        rang: otherData.rang,
        codeSection: otherData.codeSection,
        codeSousSection: otherData.codeSousSection || 0,
        codeCompetence: otherData.codeCompetence,
        questionsCommentaires: questionsCommentaires,
        complement1: champComplementaire1,
        complement2: champComplementaire2,
        complement3: champComplementaire3,
        complement4: champComplementaire4,
        reponseQuestion1: reponseQuestion1,
        reponseQuestion2: reponseQuestion2,
        reponseQuestion3: reponseQuestion3,
        reponseQuestion4: reponseQuestion4,
        reponseQuestion5: reponseQuestion5,
        reponseQuestion6: reponseQuestion6,
        date: date,
        client: otherData.client
    }
};

export const formToDataASSQ = (formData, otherData) => {
    const commentaire1 = commentify(formData?.commentaire1);
    const commentaire2 = commentify(formData?.commentaire2);
    const commentaire3 = commentify(formData?.commentaire3);
    const commentaire4 = commentify(formData?.commentaire4);
    const commentaire5 = commentify(formData?.commentaire5);
    const commentaire6 = commentify(formData?.commentaire6);
    const designation1 = formData?.designation1 || "";
    const designation2 = formData?.designation2 || "";
    const complement1 = formData?.complement1 || "";
    const complement2 = formData?.complement2 || "";
    const complement3 = formData?.complement3 || "";
    const complement4 = formData?.complement4 || "";
    const date = formData?.date || "0";
    const reponseQuestion1Brut = !!formData?.reponseQuestion1 ? JSON.parse(formData?.reponseQuestion1) : "";
    const reponseQuestion2Brut = !!formData?.reponseQuestion2 ? JSON.parse(formData?.reponseQuestion2) : "";
    const reponseQuestion3Brut = !!formData?.reponseQuestion3 ? JSON.parse(formData?.reponseQuestion3) : "";
    const reponseQuestion4Brut = !!formData?.reponseQuestion4 ? JSON.parse(formData?.reponseQuestion4) : "";
    const reponseQuestion5Brut = !!formData?.reponseQuestion5 ? JSON.parse(formData?.reponseQuestion5) : "";
    const reponseQuestion6Brut = !!formData?.reponseQuestion6 ? JSON.parse(formData?.reponseQuestion6) : "";
    const reponseQuestion1 = reponseQuestion1Brut?.value || "";
    const reponseQuestion2 = reponseQuestion2Brut?.value || "";
    const reponseQuestion3 = reponseQuestion3Brut?.value || "";
    const reponseQuestion4 = reponseQuestion4Brut?.value || "";
    const reponseQuestion5 = reponseQuestion5Brut?.value || "";
    const reponseQuestion6 = reponseQuestion6Brut?.value || "";

    return {
        client: otherData.client,
        codeSociete: otherData.codeSociete,
        matricule: otherData.matricule,
        codeCle: otherData.codeCle,
        codeEntretien: otherData.codeEntretien,
        rang: otherData.rang,
        codeSection: otherData.codeSection,
        codeSousSection: otherData.codeSousSection || "0",
        codeCompetence: otherData.codeCompetence,
        codeQuestion: otherData.codeQuestion,
        designation1: designation1,
        designation2: designation2,
        complement1: complement1,
        complement2: complement2,
        complement3: complement3,
        complement4: complement4,
        reponseQuestion1: reponseQuestion1,
        reponseQuestion2: reponseQuestion2,
        reponseQuestion3: reponseQuestion3,
        reponseQuestion4: reponseQuestion4,
        reponseQuestion5: reponseQuestion5,
        reponseQuestion6: reponseQuestion6,
        commentaire1: commentaire1,
        commentaire2: commentaire2,
        commentaire3: commentaire3,
        commentaire4: commentaire4,
        commentaire5: commentaire5,
        commentaire6: commentaire6,
        date: date,
        origineSousSection: "M",
        codeIdentificationSection: otherData.codeIdentificationSection
    }
}

export const formToDataASS = (data, otherData) => {    
    const commentaires = commentify(data?.commentaire);
    const designation1 = data?.designation1 || "";
    const designation2 = data?.designation2 || "";
    const complement1 = data?.complement1 || "";
    const complement2 = data?.complement2 || "";
    const complement3 = data?.complement3 || "";
    const complement4 = data?.complement4 || "";
    const evaluationBrut = !!data?.evaluation ? JSON.parse(data?.evaluation) : "";
    const evaluation = evaluationBrut?.value || "";
    const classement = evaluationBrut?.classement || "";
    const date = data?.date || "0";

    return {
        codeSociete: otherData.codeSociete,
        matricule: otherData.matricule,
        codeCle: otherData.codeCle,
        codeEntretien: otherData.codeEntretien,
        rang: otherData.rang,
        codeSection: otherData.codeSection,
        codeSousSection: otherData.codeSousSection || 0,
        codeCompetence: otherData.codeCompetence,
        codeQuestion: otherData.codeQuestion,        
        codeIdentificationSection: otherData.codeIdentificationSection,
        designation1: designation1,
        designation2: designation2,
        commentaires: commentaires,
        complement1: complement1,
        complement2: complement2,
        complement3: complement3,
        complement4: complement4,
        evaluation: evaluation,
        classement: classement,
        date: date,
        origineSousSection: "M",
        client: otherData.client
    }
};

export const formToDataASSCol = (data, otherData) => {    
    const commentaires = commentify(data?.commentaire);
    const designation1 = data?.designation1 || "";
    const designation2 = data?.designation2 || "";

    return {
        codeSociete: otherData.codeSociete,
        matricule: otherData.matricule,
        codeCle: otherData.codeCle,
        codeEntretien: otherData.codeEntretien,
        rang: otherData.rang,
        codeSection: otherData.codeSection,
        codeSousSection: otherData.codeSousSection || 0,
        codeCompetence: otherData.codeCompetence,
        codeQuestion: otherData.codeQuestion,        
        codeIdentificationSection: otherData.codeIdentificationSection,
        designation1: designation1,
        designation2: designation2,
        commentaires: commentaires,
        complement1: "",
        complement2: "",
        complement3: "",
        complement4: "",
        evaluation: "",
        classement: "",
        date: "0",
        origineSousSection: "C",
        client: otherData.client
    }
};