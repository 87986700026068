import { useSelector } from "react-redux";
import { Box, Paper, Skeleton, Stack, Typography } from "@mui/material";
import { Info, Refresh } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { selectStaUsr, selectUsrApp } from "@selectors";
import { Loader } from "@components/Loaders";
import { ErrorBase } from "@components/Errors";
import { IconButtonBase } from "@components/Buttons";
import { getWhiteColor, greenColor, greyColor, redColor, whiteColor } from "@styles";
import { HeaderPanelContainer } from "@components/Containers";
import { useTranslation } from "react-i18next";

const Contenu = (props) => {
    const { 
        isLoadingStatut, 
        isErrorStatut,
        errorMessage 
    } = props;
    const stateStatut = useSelector(selectStaUsr);
    const { t } = useTranslation("global");

    if(isLoadingStatut) {
        return <Box sx={styles.loaderStyle}><Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} /></Box>
    }

    if(isErrorStatut) {
        return <ErrorBase>{errorMessage}</ErrorBase>;
    }

    if(!stateStatut.statut) {
        return <ErrorBase>{t("errors.unknown_status")}</ErrorBase>;
    }

    return (
        <Stack spacing={0.5} height="100%">
            <Box>
                <Stack p={1.5} spacing={2} direction="row" sx={styles.boxStatutStyle}>
                    <Box>
                        <Skeleton sx={stateStatut.statut.statut === "P" ? styles.skeletonGreenStyle : styles.skeletonRedStyle} animation="pulse" variant="circular" width={18} height={18} />
                    </Box>
                    <Box>
                        <Typography fontSize="small">{stateStatut.statut.statut === "P" ? t("texts.present") : t("texts.absent") }</Typography>
                    </Box>
                </Stack>
            </Box>
            <Box height="100%" borderRadius={2} bgcolor={greenColor["015"]} p={1} color={greyColor[5]} display="flex" alignItems="center">
                <Stack spacing={2} direction="row" alignItems="center">
                    <Info fontSize="small" />
                    {stateStatut.statut.statut === "A" ? 
                        <Typography fontSize="small">{t("texts.last_movement")} {moment(stateStatut.statut.dateDernierMouvement, "DDMMYYYY").format("DD/MM/YYYY")} {t("texts.at")} {moment(stateStatut.statut.heureDernierMouvement, "HHmm").format("HH[h]mm")}</Typography>
                    :
                        <Typography fontSize="small">{t("texts.started")} {moment(stateStatut.statut.dateDernierMouvement, "DDMMYYYY").format("DD/MM/YYYY")} {t("texts.at")} {moment(stateStatut.statut.heureDernierMouvement, "HHmm").format("HH[h]mm")}</Typography>
                    }
                </Stack>
            </Box>
        </Stack>
    );
}

const StatutWidget = (props) => {
    const { 
        isLoadingStatut, 
        isErrorStatut, 
        refetchStatut,
    } = props;
    const stateUser = useSelector(selectUsrApp);
    const { t } = useTranslation("global");

    return (
        <Paper sx={stateUser.background ? styles.paperStyle : styles.paperStyleNoBackground} elevation={0}>
            <Stack spacing={0.5} padding={0.5} height="100%" boxSizing="border-box">
                <HeaderPanelContainer 
                    actionsRight={
                        <IconButtonBase icon={<Refresh fontSize="small" />} title={t("buttons.refresh")} action={() => refetchStatut()} />
                    } 
                    title={t("titles.status")}
                    padding={0.5} 
                    bgColor={greenColor["015"]} 
                />
                <Contenu isLoadingStatut={isLoadingStatut} isErrorStatut={isErrorStatut} />
            </Stack>
        </Paper>
    );
}

Contenu.defaultProps = {
    isLoadingStatut: true, 
    isErrorStatut: false
}

StatutWidget.defaultProps = {
    isLoadingStatut: true, 
    isErrorStatut: false, 
    refetchStatut: () => {}
}

Contenu.propTypes = {
    isLoadingStatut: PropTypes.bool, 
    isErrorStatut: PropTypes.bool,
    errorMessage: PropTypes.string
}

StatutWidget.propTypes = {
    isLoadingStatut: PropTypes.bool, 
    isErrorStatut: PropTypes.bool,
    errorMessage: PropTypes.string, 
    refetchStatut: PropTypes.func
}

const styles = {
    paperStyleNoBackground: {
        backdropFilter: "blur(3px)",
        backgroundColor: greenColor["02"],
        width: "100%",
        height: "200px",
        borderRadius: 2
    },
    paperStyle: {
        backdropFilter: "blur(3px)",
        backgroundColor: getWhiteColor("0.95"),
        width: "100%",
        height: "200px",
        borderRadius: 2
    },
    boxTitleStyle: {
        backgroundColor: greyColor[0], 
        textAlign: "center",
        p: 0.5,
        borderRadius: 2,
    },
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        height: "130px"
    },
    boxStyle: {
        backgroundColor: greyColor[0], 
        p:0.5,
        borderRadius: 2
    },
    boxStatutConfigurationStyle: {
        borderRadius: 2, 
        boxShadow: "none", 
        backgroundColor: whiteColor[5],
        color: greyColor[5],
        justifyContent: "start",
        alignItems: "center"
    },
    boxStatutStyle: {
        borderRadius: 2, 
        boxShadow: "none", 
        backgroundColor: greenColor["015"],
        color: greyColor[5],
        justifyContent: "start",
        alignItems: "center"
    },
    skeletonGreenStyle: {
        backgroundColor: greenColor["06"]
    },
    skeletonRedStyle: {
        backgroundColor: redColor[5]
    }
};

export default StatutWidget;