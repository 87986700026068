import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { HeaderDialogContainer } from "@components/Containers";

const ServicesActions = (props) => {
    const { setOpen, valider } = props;

    return (
        <HeaderDialogContainer 
            close={() => setOpen(false)} 
            title="Selection d'un ou plusieurs services" 
            actionsLeft={
                <IconButtonBase icon={<Check fontSize="small" />} title="Valider" action={() => valider()} />
            } 
        />
    );
}

ServicesActions.defaultProps = {
    setOpen: () => {},
    valider: () => {}
}

ServicesActions.propTypes = {
    setOpen: PropTypes.func,
    valider: PropTypes.func
}

export default ServicesActions;