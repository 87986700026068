import { Grid, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { DialogFullScreenContainer, HeaderDialogContainer, TextContainer } from "@components/Containers";
import { chiffreUSVersChiffreFR, heurify } from "@utils";
import { useStateTemGesTem } from "@hooks/gestionTemps/temps/hooksStates";
import { greenColor, whiteColor } from "@styles";

const jours = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];

const RepasDialogMobile = (props) => {
    const { open, setOpen, userType } = props;
    const stateTemps = useStateTemGesTem(userType);

    return (
        <DialogFullScreenContainer open={open} placement="top" taille="md">
            <HeaderDialogContainer 
                title="Repas de la semaine"
                close={() => setOpen(false)}
            />
            <Stack spacing={0.5}>
                {stateTemps.repas.map((repas, id) => (
                    <Stack key={`REPTMPH${repas.id}`} direction="row" textAlign="center" bgcolor={greenColor["015"]} p={0.25} borderRadius={2}>
                        <Grid container spacing={0.5}>
                            <Grid item xs={6}>
                                <TextContainer bgColor={greenColor["015"]} p={0.75} verticalAlign="center" horizontalAlign="center" textSize={"small"} textWeight="bold" height={"100%"}>
                                    {jours[id]}
                                </TextContainer>
                            </Grid>
                            <Grid item xs={6}>
                                <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" horizontalAlign="center" textSize={"small"} textWeight="bold" height={"100%"}>
                                    {stateTemps.horaire ? heurify(repas.heuresRepas.heure, "h", false) : chiffreUSVersChiffreFR(repas.heuresRepas.chiffre)}
                                </TextContainer>
                            </Grid>
                        </Grid>
                    </Stack>
                ))}
            </Stack>
        </DialogFullScreenContainer>
    );
}

RepasDialogMobile.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    userType: PropTypes.string.isRequired
}

export default RepasDialogMobile;