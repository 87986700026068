import { Box, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { useController, useFormContext, useFormState } from "react-hook-form";
import PropTypes from "prop-types";
import { greyColor, redColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const TextHookFormInput = ({
    name = "", 
    label = "", 
    autoFocus = false, 
    maxLength = 50, 
    readOnly = false, 
    multiline = false,
    errorPlacement = "left"
}) => {
    const { control } = useFormContext();
    const { field } = useController({ name, control });
    const { errors } = useFormState({ control });
    const { t } = useTranslation("global");

    return (  
        <Tooltip 
            open={Boolean(errors?.[name])} 
            title={t(errors?.[name]?.message)} 
            slotProps={{
                tooltip: {
                    sx: {
                        backgroundColor: redColor[5]
                    }
                },
                arrow: {
                    sx: {
                        color: redColor[5]
                    }
                }
            }}
            placement={errorPlacement}
            arrow
        >
            <Stack sx={styles.stackInputStyle}>
                <Box sx={styles.boxInputStyle}>
                    <TextField 
                        fullWidth 
                        label={label} 
                        size="small"
                        autoFocus={autoFocus} 
                        multiline={multiline}
                        inputProps={{ maxLength: maxLength, readOnly: readOnly }}
                        {...field}
                    />
                </Box>
                {/* {errors[name] && 
                    <Box sx={styles.boxInputErrorStyle}>
                        <Typography sx={styles.typoInputErrorStyle}>{t(errors[name].message)}</Typography>
                    </Box>
                } */}
            </Stack>
        </Tooltip>
    );
}

TextHookFormInput.defaultProps = {
    label: "", 
    autoFocus: false, 
    maxLength: 50, 
    readOnly: false,
    multiline: false
}

TextHookFormInput.propTypes = {
    name: PropTypes.string.isRequired, 
    label: PropTypes.string, 
    autoFocus: PropTypes.bool, 
    maxLength: PropTypes.number, 
    readOnly: PropTypes.bool,
    multiline: PropTypes.bool,
    errorPlacement: PropTypes.string
}

const styles = {
    stackInputStyle: {
        borderRadius: 2,
        backgroundColor: redColor[1]
    },
    boxInputStyle: {
        borderRadius: 2,
        backgroundColor: whiteColor[9]
    },
    boxInputErrorStyle: {
        textAlign: "left",
        borderBottomLeftRadius: 8, 
        borderBottomRightRadius: 8,
        padding: 1
    },
    typoInputErrorStyle: {
        fontSize: "small", 
        color: greyColor[5]
    }
}

export default TextHookFormInput;