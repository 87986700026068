import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { HeaderDialogContainer } from "@components/Containers";
import { useTranslation } from "react-i18next";

const ActionsContrainte = (props) => {
    const { isLoading, close, postDemandeAbsenceContrainte } = props;
    const { t } = useTranslation("global");

    return (
        <HeaderDialogContainer  
            actionsLeft={
                <IconButtonBase icon={<Check fontSize="small" />} title={t("buttons.validation_despite_constraint")} noClick={isLoading} action={() => postDemandeAbsenceContrainte()} />
            }
            title={t("titles.validation")}
            close={() => close()} 
        />
    )
}

ActionsContrainte.defaultProps = {
    isLoading: true,
    close: () => {},
    postDemandeAbsenceContrainte: () => {}
}

ActionsContrainte.propTypes = {
    isLoading: PropTypes.bool,
    close: PropTypes.func,
    postDemandeAbsenceContrainte: PropTypes.func
}

export default ActionsContrainte;