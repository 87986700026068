import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Collapse, Stack, Typography } from "@mui/material";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import PropTypes from "prop-types";
import { selectEntSecCol } from "@selectors";
import { useChampQuestionCol } from "@hooks/gestionEntretiens/hooksUtils";
import { getGreyColor, greenColor, whiteColor } from "@styles";
import CommentaireQuestionContent from "./CommentaireQuestionContent";

const QuestionContent = ({ 
    idQuestion = 1, 
    commentaires = null, 
    isLoading = false, 
    isError = false
}) => {
    const [open, setOpen] = useState(false);
    const [reponse, setReponse] = useState("");
    const stateSections = useSelector(selectEntSecCol);
    const sectionSelected = stateSections?.sectionSelected;
    const { display, options } = useChampQuestionCol(idQuestion); 
    
    useEffect(() => {
        if(display) {
            const reponseQuestionKey = `reponseQuestion${idQuestion}`;
            const ligneCible = options?.find((t) => t.value === sectionSelected?.[reponseQuestionKey]);
            setReponse(ligneCible?.label);
        }
    }, [display, idQuestion, options, sectionSelected]);

    if(display) {
        return (
            <Box bgcolor={whiteColor[7]} borderRadius={2} boxSizing={"border-box"}>
                <Button fullWidth sx={{ color: open ? whiteColor[9] : getGreyColor("0.7"), bgcolor: open ? greenColor["08"] : undefined, height: 50, borderRadius: 2, boxShadow: open ? 2 : 0, "&:hover": { color: whiteColor[9], backgroundColor: greenColor["08"], boxShadow: 2 }}} onClick={() => setOpen((prevOpen) => !prevOpen)}>
                    <Stack direction="row" alignItems="center" px={1} height={"100%"} width={"100%"}>
                        <Box flexGrow={1}>
                            <Typography fontSize="small" fontWeight={open ? "bold" : "normal"} sx={{flexGrow: 1, textAlign: "left", textTransform: "none"}}>{sectionSelected?.[`question${idQuestion}`]}</Typography>
                        </Box>
                        <Box bgcolor={greenColor["025"]} borderRadius={2} px={1}>
                            <Typography fontSize="small" fontWeight={"bold"}>{reponse}</Typography>
                        </Box>
                        <Box alignItems="center" display="flex">
                            {open ?
                                <ArrowDropDown fontSize="small" />
                            :
                                <ArrowRight fontSize="small" />
                            }
                        </Box>
                    </Stack>
                </Button>
                <Collapse in={open}>
                    <Stack spacing={1} p={1} pt={1.5}>
                        <Box borderRadius={2} bgcolor={greenColor["05"]} p={0.5}>
                            <Box borderRadius={2} bgcolor={whiteColor[7]} p={1}>
                                <Typography fontSize="small" fontWeight={"bold"}>Réponse : {reponse}</Typography>
                            </Box>
                        </Box>
                        <CommentaireQuestionContent idCommentaire={idQuestion} commentaires={commentaires} isLoading={isLoading} isError={isError} />
                    </Stack>
                </Collapse>
            </Box>
        );
    }

    return null;
}

QuestionContent.propTypes = {
    idQuestion: PropTypes.number,
    commentaires: PropTypes.object,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool
}

export default QuestionContent;