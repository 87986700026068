import { styled } from '@mui/system';
import { Box, Button, Checkbox, IconButton, TextField } from '@mui/material';
import { backgrounds } from '@constants/appConstants';

export const greenColor = {
    "01": "rgba(49, 133, 156, 0.1)",
    "015": "rgba(49, 133, 156, 0.15)",
    "02":"rgba(49, 133, 156, 0.2)",
    "025":"rgba(49, 133, 156, 0.25)",
    "03":"rgba(49, 133, 156, 0.3)",
    "035":"rgba(49, 133, 156, 0.35)",
    "04":"rgba(49, 133, 156, 0.4)",
    "045":"rgba(49, 133, 156, 0.45)",
    "05":"rgba(49, 133, 156, 0.5)",
    "055":"rgba(49, 133, 156, 0.55)",
    "06":"rgba(49, 133, 156, 0.6)",
    "065":"rgba(49, 133, 156, 0.65)",
    "07":"rgba(49, 133, 156, 0.7)",
    "075":"rgba(49, 133, 156, 0.75)",
    "08":"rgba(49, 133, 156, 0.8)",
    "085":"rgba(49, 133, 156, 0.85)",
    "09":"rgba(49, 133, 156, 0.9)",
    "095":"rgba(49, 133, 156, 0.95)",
    "1":"rgba(49, 133, 156, 1)"
}

export const getGreenColor = (value) => `rgba(49, 133, 156, ${value})`;

export const redColor = [
    "rgba(255, 0, 0, 0.1)",
    "rgba(255, 0, 0, 0.2)",
    "rgba(255, 0, 0, 0.3)",
    "rgba(255, 0, 0, 0.4)",
    "rgba(255, 0, 0, 0.5)",
    "rgba(255, 0, 0, 0.6)",
    "rgba(255, 0, 0, 0.7)",
    "rgba(255, 0, 0, 0.8)",
    "rgba(255, 0, 0, 0.9)",
    "rgba(255, 0, 0, 1)"
]

export const getRedColor = (value) => `rgba(255, 0, 0, ${value})`;

export const yellowColor = [
    "rgba(249, 180, 45, 0.1)",
    "rgba(249, 180, 45, 0.2)",
    "rgba(249, 180, 45, 0.3)",
    "rgba(249, 180, 45, 0.4)",
    "rgba(249, 180, 45, 0.5)",
    "rgba(249, 180, 45, 0.6)",
    "rgba(249, 180, 45, 0.7)",
    "rgba(249, 180, 45, 0.8)",
    "rgba(249, 180, 45, 0.9)",
    "rgba(249, 180, 45, 1)"
]

export const getYellowColor = (value) => `rgba(249, 180, 45, ${value})`;

export const whiteColor = [
    "rgba(255, 255, 255, 0.1)",
    "rgba(255, 255, 255, 0.2)",
    "rgba(255, 255, 255, 0.3)",
    "rgba(255, 255, 255, 0.4)",
    "rgba(255, 255, 255, 0.5)",
    "rgba(255, 255, 255, 0.6)",
    "rgba(255, 255, 255, 0.7)",
    "rgba(255, 255, 255, 0.8)",
    "rgba(255, 255, 255, 0.9)",
    "rgba(255, 255, 255, 1)"
]

export const getWhiteColor = (value) => `rgba(255, 255, 255, ${value})`;

export const greyColor = [
    "rgba(0, 0, 0, 0.1)",
    "rgba(0, 0, 0, 0.2)",
    "rgba(0, 0, 0, 0.3)",
    "rgba(0, 0, 0, 0.4)",
    "rgba(0, 0, 0, 0.5)",
    "rgba(0, 0, 0, 0.6)",
    "rgba(0, 0, 0, 0.7)",
    "rgba(0, 0, 0, 0.8)",
    "rgba(0, 0, 0, 0.9)",
    "rgba(0, 0, 0, 1)"
]

export const getGreyColor = (value) => `rgba(0, 0, 0, ${value})`;

export const blueColor = {
    "01": "rgba(0, 128, 255, 0.1)",
    "015": "rgba(0, 128, 255, 0.15)",
    "02": "rgba(0, 128, 255, 0.2)",
    "025": "rgba(0, 128, 255, 0.25)",
    "03": "rgba(0, 128, 255, 0.3)",
    "035": "rgba(0, 128, 255, 0.35)",
    "04": "rgba(0, 128, 255, 0.4)",
    "045": "rgba(0, 128, 255, 0.45)",
    "05": "rgba(0, 128, 255, 0.5)",
    "055": "rgba(0, 128, 255, 0.55)",
    "06": "rgba(0, 128, 255, 0.6)",
    "065": "rgba(0, 128, 255, 0.65)",
    "07": "rgba(0, 128, 255, 0.7)",
    "075": "rgba(0, 128, 255, 0.75)",
    "08": "rgba(0, 128, 255, 0.8)",
    "085": "rgba(0, 128, 255, 0.85)",
    "09": "rgba(0, 128, 255, 0.9)",
    "095": "rgba(0, 128, 255, 0.95)",
    "1": "rgba(0, 128, 255, 1)"
}

export const BackgroundContainer = styled(Box)(({ background }) => ({
    width: "100%",
    height: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: greenColor["1"],
    backgroundImage: `url(${backgrounds[background]})`
}));

const iconButtonBgColor = {
    undefined: { bg: undefined, color: undefined, hoverBg: undefined, hoverColor: undefined },
    grey7White5: { bg: undefined, color: greyColor[7], hoverBg: whiteColor[5], hoverColor: greyColor[9] },
    red7White5: { bg: undefined, color: redColor[7], hoverBg: whiteColor[5], hoverColor: redColor[9] },
    white9Green7: { bg: whiteColor[9], color: greenColor["07"], hoverBg: greenColor["09"], hoverColor: whiteColor[9] },
    white5Grey9: { bg: whiteColor[5], color: greyColor[9], hoverBg: greenColor["09"], hoverColor: whiteColor[9] },
    white5Red5: { bg: whiteColor[5], color: redColor[9], hoverBg: redColor[5], hoverColor: whiteColor[9] },
    white5Red9White9: { bg: whiteColor[5], color: redColor[9], hoverBg: whiteColor[9], hoverColor: redColor[9] },
    white5Green9White9: { bg: whiteColor[5], color: greenColor["1"], hoverBg: whiteColor[9], hoverColor: greenColor["1"] },
    white5Grey9White9: { bg: whiteColor[5], color: greyColor[9], hoverBg: whiteColor[9], hoverColor: greyColor[9] },
    white2Grey9White9: { bg: whiteColor[2], color: greyColor[9], hoverBg: whiteColor[9], hoverColor: greyColor[9] },
    green2Grey9Green5: { bg: greenColor["02"], color: greyColor[9], hoverBg: greenColor["05"], hoverColor: greyColor[9] },
    panelButton: { bg: undefined, color: greyColor[7], hoverBg: greyColor[1], hoverColor: greyColor[9] },
    deletePanelButton: { bg: undefined, color: redColor[7], hoverBg: greyColor[1], hoverColor: redColor[9] },
    listButton: { bg: whiteColor[5], color: greyColor[9], hoverBg: whiteColor[9], hoverColor: greyColor[9] },
    deleteListButton: { bg: whiteColor[5], color: redColor[9], hoverBg: whiteColor[9], hoverColor: redColor[9] },
    closeDialogButton: { bg: whiteColor[5], color: redColor[9], hoverBg: whiteColor[9], hoverColor: redColor[9] },
    dialogButton: { bg: whiteColor[5], color: greyColor[9], hoverBg: whiteColor[9], hoverColor: greyColor[9] },
    pageButton: { bg: whiteColor[5], color: greyColor[9], hoverBg: whiteColor[9], hoverColor: greyColor[9] },
}

export const IconButtonApp = styled(IconButton)(({ variant }) => ({
    backgroundColor: iconButtonBgColor[variant]?.bg,
    color: iconButtonBgColor[variant]?.color,
    "&:hover, &.Mui-focusVisible": { 
        backgroundColor: iconButtonBgColor[variant]?.hoverBg, 
        color: iconButtonBgColor[variant]?.hoverColor
    }
}));

const buttonBgColor = {
    undefined: { bg: undefined, color: undefined, hoverBg: undefined, hoverColor: undefined },
    green25Grey5Green7: { bg: greenColor["025"], color: greyColor[5], hoverBg: greenColor["07"], hoverColor: whiteColor[9] },
    green5White9Green7: { bg: greenColor["05"], color: whiteColor[9], hoverBg: greenColor["07"], hoverColor: whiteColor[9] },
    green7White9Green9: { bg: greenColor["07"], color: whiteColor[9], hoverBg: greenColor["09"], hoverColor: whiteColor[9] },
    red2Grey5Red5: { bg: redColor[2], color: greyColor[5], hoverBg: redColor[5], hoverColor: whiteColor[9] },
    white2Grey5White9: { bg: whiteColor[2], color: greyColor[5], hoverBg: whiteColor[9], hoverColor: greyColor[9] },
    white5Grey5White9: { bg: whiteColor[5], color: greyColor[5], hoverBg: whiteColor[9], hoverColor: greyColor[9] },
    white5Grey5Green7: { bg: whiteColor[5], color: greyColor[5], hoverBg: greenColor["07"], hoverColor: whiteColor[9] },
    white7Grey9White9: { bg: whiteColor[7], color: greyColor[9], hoverBg: whiteColor[9], hoverColor: greyColor[9] },
    yellow5Grey9Yellow7: { bg: yellowColor[5], color: greyColor[9], hoverBg: yellowColor[7], hoverColor: greyColor[9] },
    blue2Grey5Blue5: { bg: blueColor["02"], color: greyColor[5], hoverBg: blueColor["05"], hoverColor: whiteColor[9] },
    green5: { bg: greenColor["05"], color: greyColor[5], hoverBg: greenColor["07"], hoverColor: whiteColor[9] },
    primary: { bg: greenColor["025"], color: greyColor[5], hoverBg: greenColor["07"], hoverColor: whiteColor[9] },
    secondary: { bg: whiteColor[5], color: greyColor[5], hoverBg: whiteColor[7], hoverColor: greyColor[9] },
    sideBarPrimary: { bg: greenColor["025"], color: greyColor[5], hoverBg: greenColor["07"], hoverColor: whiteColor[9] },
    sideBarSecondary: { bg: greenColor["06"], color: whiteColor[9], hoverBg: greenColor["08"], hoverColor: whiteColor[9] },
    sideBarError: { bg: redColor[2], color: greyColor[5], hoverBg: redColor[5], hoverColor: whiteColor[9] },
    sideBarNoBackgroundPrimary: { bg: greyColor[2], color: whiteColor[9], hoverBg: whiteColor[4], hoverColor: greyColor[9] },
    sideBarNoBackgroundSecondary: { bg: whiteColor[1], color: whiteColor[9], hoverBg: whiteColor[3], hoverColor: whiteColor[9] },
    sideBarNoBackgroundError: { bg: redColor[4], color: whiteColor[9], hoverBg: redColor[7], hoverColor: whiteColor[9] },
}

const buttonAlignement = {
    left: "left",
    right: "right",
    center: "center"
}

const buttonBorders = {
    left: { topLeft: 8, topRight: 0, bottomLeft: 8,  bottomRight: 0 },
    right: { topLeft: 0, topRight: 8, bottomLeft: 0,  bottomRight: 8 },
    all: { topLeft: 8, topRight: 8, bottomLeft: 8, bottomRight: 8 },
    none: { topLeft: 0, topRight: 0, bottomLeft: 0, bottomRight: 0 }
}

export const ButtonApp = styled(Button)(({ variantApp, alignement, height, borders }) => ({
    borderTopLeftRadius: buttonBorders[borders]?.topLeft,
    borderTopRightRadius: buttonBorders[borders]?.topRight,
    borderBottomLeftRadius: buttonBorders[borders]?.bottomLeft,
    borderBottomRightRadius: buttonBorders[borders]?.bottomRight,
    paddingLeft: 16,
    paddingRight: 16,
    textAlign: "left",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    lineHeight: "0.9rem",
    height: height,
    justifyContent: buttonAlignement[alignement],
    backgroundColor: buttonBgColor[variantApp]?.bg,
    color: buttonBgColor[variantApp]?.color,
    "&:hover": {
        backgroundColor: buttonBgColor[variantApp]?.hoverBg,
        color: buttonBgColor[variantApp]?.hoverColor
    },
    ".MuiButton-endIcon": {
        position: "absolute",
        right: "1rem"
    }
}));

const checkBoxSize = {
    small: { fontSize: 16, width: 30, height: 30 }
}

const checkBoxColor = {
    grey7White5: { bg: undefined, color: greyColor[7], hoverBg: whiteColor[9], hoverColor: greyColor[9] },
    white5Grey7White5: { bg: whiteColor[5], color: greyColor[7], hoverBg: whiteColor[9], hoverColor: greyColor[9] }
}

export const CheckBoxButton = styled(Checkbox)(({ variant, size }) => ({
    "& .MuiSvgIcon-root": { 
        fontSize: checkBoxSize[size]?.fontSize,
        color: checkBoxColor[variant]?.color
    },
    "&.MuiCheckbox-root": {
        width: checkBoxSize[size]?.width,
        height: checkBoxSize[size]?.height,
        backgroundColor: checkBoxColor[variant]?.bg,
    },
    "&:hover": {
        backgroundColor: checkBoxColor[variant]?.hoverBg,
    }
}));

const inputVariants = {
    white: { label: greyColor[9], font: greyColor[9], bg: whiteColor[9], hoverBg: greenColor["04"], readOnlyBg: greyColor[1] },
    grey: { label: whiteColor[9], font: whiteColor[9], bg: whiteColor[3], hoverBg: whiteColor[5], readOnlyBg: greyColor[1] },
    green: { label: greyColor[9], font: greyColor[9], bg: greenColor["05"], hoverBg: greenColor["07"], readOnlyBg: greyColor[1] }
}

export const TextFieldStyled = styled(TextField)(({ variantApp }) => ({
    height: 40,
    "& label": {
        color: inputVariants[variantApp]?.label,
        // top: "-8px"
    },
    "& .MuiInputLabel-shrink": {
        color: inputVariants[variantApp]?.label,
        // top: "0"
    },
    "& label.Mui-focused": {
        color: inputVariants[variantApp]?.label,
    },
    "& .Mui-focused": {
        color: inputVariants[variantApp]?.font
    },
    "&:has([readonly]) ": {
        "& .MuiOutlinedInput-root": {
            backgroundColor: inputVariants[variantApp]?.readOnlyBg
        },
        "& .MuiOutlinedInput-input": {
            cursor: "not-allowed"
        }
    },
    "& .MuiOutlinedInput-root": {
        height: 40,
        backgroundColor: inputVariants[variantApp]?.bg,
        borderRadius: 8,
        color: inputVariants[variantApp]?.font,
        "& fieldset": {
            borderRadius: 8,
            // backgroundColor: inputVariants[variantApp]?.bg,
            color: inputVariants[variantApp]?.font
        },
        "&:hover fieldset": {
            backgroundColor: inputVariants[variantApp]?.hoverBg,
        }
    },
    "& .MuiOutlinedInput-input": {
        paddingTop: 8.5,
        paddingBottom: 8.5,
        paddingLeft: 14,
        paddingRight: 14,
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px #FFFF inset",
            WebkitTextFillColor: greyColor[9],
        }
    },
}));
