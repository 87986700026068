import { useSelector, useDispatch } from "react-redux";
import { selectUsrApp } from "@selectors";
import * as userActions from "@reducers/user/usrAppReducer";
import { DialogContainer, HeaderDialogContainer } from "@components/Containers";
import { BackgroundsList } from "../Lists";
import { useTranslation } from "react-i18next";

const BackgroundsDialog = () => {
    const stateUser = useSelector(selectUsrApp);
    const dispatch = useDispatch();
    const { t } = useTranslation("global");

    return (
        <DialogContainer open={stateUser.openBackground} taille="sm">
            <HeaderDialogContainer 
                title={t("titles.edit_background")} 
                close={() => dispatch(userActions.openBackground(false))} 
            />
            <BackgroundsList />
        </DialogContainer> 
    );
}

export default BackgroundsDialog;