import Joi from "joi";
import { z } from "zod";
import moment from "moment";

export const mt = z.custom((val) => (moment.isMoment(val) && val.isValid()) ? true : false, "L'entrée n'est pas de type date");


export const validateDate = Joi.extend((joi) => ({
    type: "dateMoment",
    base: joi.object(),
    messages: {
        "object.base": "Date obligatoire",
        "dateMoment.valide": "Date invalide",
        "dateMoment.greaterMoment": "Cette date doit être suprérieur à la date de début.",
    },
    rules: {
        requis: {
            validate(value, helpers) {
                return value ? value : helpers.error("dateMoment.requis", { value });
            }
        },
        valide: {
            validate(value, helpers) {
                return moment(value).isValid() ? value : helpers.error("dateMoment.valide", { value });
            }
        },
        valideOrNull: {
            validate(value, helpers) {
                return moment(value).isValid() || !value ? value : helpers.error("dateMoment.valide", { value });
            }
        },
        minDate: {
            validate(value, helpers) {
                return moment(value).isSameOrAfter(moment().startOf("day")) ? value : helpers.error("dateMoment.minDate", { value });
            }
        },
        maxDate: {
            validate(value, helpers) {
                return moment(value).isSameOrBefore(moment().startOf("day")) ? value : helpers.error("dateMoment.maxDate", { value });
            }
        },
        minDateDebut: {
            validate(value, helpers) {
                if(value) {
                    return moment(value).isSameOrAfter(helpers.state.ancestors[0].dateDebut) ? value : helpers.error("dateMoment.minDateDebut", { value });
                }
                return value;
            }
        },
        greaterMoment: {
            validate(value, helpers) {
                return moment(value).isAfter(helpers.state.ancestors[0].dateDebut) ? value : helpers.error("dateMoment.greaterMoment", { value });
            }
        }
    }
}));

export const validateHeure = Joi.extend((joi) => ({
    type: "heureMoment",
    base: joi.object(),
    messages: {
        "object.base": "Heure obligatoire",
        "heureMoment.valide": "Heure invalide",
    },
    rules: {
        valide: {
            validate(value, helpers) {
                return moment(value).isValid() ? value : helpers.error("heureMoment.valide", { value });
            }
        },
        minHeure: {
            validate(value, helpers) {
                return moment(value).isSameOrAfter(moment().startOf("day")) ? value : helpers.error("heureMoment.minHeure", { value });
            }
        },
        greaterHeureMoment: {
            validate(value, helpers) {
                return moment(value).isAfter(helpers.state.ancestors[0].heureDebut) ? value : helpers.error("heureMoment.greaterHeureMoment", { value });
            }
        }
    }
}));

export const validateHeureString = Joi.extend((joi) => ({
    type: "heureMoment",
    base: joi.string(),
    messages: {
        "string.empty": "Heure obligatoire",
        "heureMoment.valide": "Heure invalide",
    },
    rules: {
        valide: {
            validate(value, helpers) {
                return moment(value, "HH:mm").isValid() ? value : helpers.error("heureMoment.valide", { value });
            }
        },
        minHeure: {
            validate(value, helpers) {
                return moment(value, "HH:mm").isSameOrAfter(moment().startOf("day")) ? value : helpers.error("heureMoment.minHeure", { value });
            }
        },
        greaterHeureMoment: {
            validate(value, helpers) {
                return moment(value, "HH:mm").isAfter(helpers.state.ancestors[0].heureDebut) ? value : helpers.error("heureMoment.greaterHeureMoment", { value });
            }
        }
    }
}));

export const validateFile = Joi.extend((joi) => ({
    type: "fileAbsence",
    base: joi.object(),
    messages: {
        "array.base": "Fichier obligatoire",
        "file.valide": "Fichier non valide",
    },
    rules: {
        mimeType: {
            validate(value, helpers) {
                return value[0].type === "application/pdf" ? value : helpers.error("fileAbsence.mimeType", { value });
            }
        },
        size: {
            validate(value, helpers) {
                return value[0].size < 1024*1024*20 ? value : helpers.error("fileAbsence.size", { value });
            }
        },
        obligatoire: {
            validate(value, helpers) {
                return value ? value : helpers.error("fileAbsence.obligatoire", { value });
            }
        }
    }
}));
