import { Box, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { greenColor, greyColor } from "@styles";
import { ErrorBase } from "@components/Errors";
import { Loader } from "@components/Loaders";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@components/Buttons";

const ActivitesList = ({
    data = [], 
    actionSuivante = "",
    isLoading = false, 
    isError = false,  
    handleOpenConfirmationPointage = () => {}
}) => {
    const { t } = useTranslation("global");

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader size={27} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }   

    if(isError) {
        return <ErrorBase>{t("errors.data_recovery_activity_list")}</ErrorBase>;
    }

    return (
        <Stack spacing={0.5} bgcolor={greenColor["07"]} borderRadius={2} p={0.5}>
            {data.map((activite) => (
                actionSuivante === "E" ? (activite.presence !== "O" && 
                    <Box key={`ACTTMP${activite.id}`}>
                        <ButtonBase fullWidth variant="white5Grey5White9" action={() => handleOpenConfirmationPointage("activites", activite)}>
                            {activite.libelle}
                        </ButtonBase>
                    </Box>)
                :
                    <Box key={`ACTTMP${activite.id}`}>
                        <ButtonBase fullWidth  variant="white5Grey5White9" action={() => handleOpenConfirmationPointage("activites", activite)}>
                            {activite.libelle}
                        </ButtonBase>
                    </Box>
            ))}
        </Stack>
    );
}

ActivitesList.propTypes = {
    data: PropTypes.array, 
    actionSuivante: PropTypes.string,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    handleOpenConfirmationPointage: PropTypes.func
}

const styles = {
    loaderStyle: {
        height: 52,
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
    },
}

export default ActivitesList;