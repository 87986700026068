import { useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { selectDsh } from "@selectors";
import { whiteColor } from "@styles";
import { PointageShortcut } from "./Pointage";
import { ActivitesShortcut } from "./Activites";

const Shortcuts = (props) => {
    const { 
        isLoadingStatut, 
        isErrorStatut, 
        refetchStatut, 
        handleOpenConfirmationPointage,
        setOpenActivitesDialog  
    } = props;
    const stateDashboard = useSelector(selectDsh);

    const routeShortcuts = {
        sPointage: <PointageShortcut key="SPOINTAGE" isLoadingStatut={isLoadingStatut} isErrorStatut={isErrorStatut} refetchStatut={refetchStatut} handleOpenConfirmationPointage={handleOpenConfirmationPointage} />,
        sActivite: <ActivitesShortcut key="SACTIVITE" isLoadingStatut={isLoadingStatut} isErrorStatut={isErrorStatut} refetchStatut={refetchStatut} setOpenActivitesDialog={setOpenActivitesDialog} />
    }

    return (
        <Box bgcolor={whiteColor[9]} borderRadius={2} minHeight={48}>
            <Stack direction="row" spacing={0.5} padding={0.5}>    
                {stateDashboard.shortcuts.map((shortcut) => (
                    routeShortcuts[shortcut]
                ))}
            </Stack>
        </Box>
    );
}

Shortcuts.defaultProps = {
    isLoadingStatut: true, 
    isErrorStatut: false, 
    refetchStatut: () => {}, 
    handleOpenConfirmationPointage: () => {},
    setOpenActivitesDialog: () => {}
}

Shortcuts.propTypes = {
    isLoadingStatut: PropTypes.bool, 
    isErrorStatut: PropTypes.bool, 
    refetchStatut: PropTypes.func, 
    handleOpenConfirmationPointage: PropTypes.func, 
    setOpenActivitesDialog: PropTypes.func
}

export default Shortcuts;