import PropTypes from "prop-types";
import { Checkbox, FormControlLabel } from "@mui/material";

const CheckInput = (props) => {
    const { label, size, noClick, checked, onCheck } = props;

    return (
        <FormControlLabel 
            control={
                <Checkbox 
                    sx={{"& .MuiSvgIcon-root": { fontSize: size } }} 
                    disabled={noClick} 
                    checked={checked} 
                    onChange={onCheck} 
                />
            } 
            componentsProps={{typography: { fontSize: "small" }}} 
            sx={{ mr: label ? undefined : 0, ml: label ? undefined : 0 }}
            label={label} 
        />
    );
}

CheckInput.defaultProps = {
    size: 16,
    noClick: false,
    checked: false,
    onCheck: () => {}
}

CheckInput.propTypes = {
    label: PropTypes.string.isRequired,
    size: PropTypes.number,
    noClick: PropTypes.bool,
    checked: PropTypes.bool,
    onCheck: PropTypes.func
}

export default CheckInput;