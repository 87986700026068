import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query"
import { fetchDoc, fetchNatDoc } from "@api/documents";
import { selectCltApp, selectUsrApp } from "@selectors";
import * as docAppActions from "@reducers/documents/docAppReducer"

export const useDoc = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const dispatch = useDispatch();

    const query = useQuery(["doc"], ({ signal }) => fetchDoc(stateUser.matricule, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if(query.data) {
            dispatch(docAppActions.setDocuments(query.data));
        }
    }, [dispatch, query.data]);

    return query;
}

export const useNatDocInp = (open) => {
    const stateClient = useSelector(selectCltApp);

    return useQuery(["natDocApp"], ({ signal }) => fetchNatDoc(stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open
    });
}
