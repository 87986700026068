import { useCallback } from "react";
import { Box, Grid, Stack, TextField } from "@mui/material";
import { useController, useFormContext, useFormState } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { TextContainer } from "@components/Containers";
import { ErrorBase } from "@components/Errors";
import { greyColor, redColor, whiteColor } from "@styles";
import { DateHookFormInput } from "@components/Inputs";
import { SocietesHookFormInput } from "@components/Inputs/Societes";
import { EtablissementsHookFormInput } from "@components/Inputs/Etablissements";
import { DirectionsHookFormInput } from "@components/Inputs/Directions";
import { ServicesHookFormInput } from "@components/Inputs/Services";
import { SecteursHookFormInput } from "@components/Inputs/Secteurs";
import { ActivitesHookFormInput } from "@components/Inputs/Activites";

const TInputNombreHeuresDebut = () => {
    const { control } = useFormContext(); 
    const { field } = useController({ name: "nombreHeuresDebut", control });

    return (
        <NumericFormat fullWidth name={field.name} getInputRef={field.ref} isAllowed={(values) => {const {floatValue} = values; return floatValue >= 0.01 && floatValue <= 99.99 }} value={field.value} inputProps={{maxLength: 5}} decimalSeparator="," decimalScale={2} customInput={TextField} size="small" label={"Nombre d'heures de"} onChange={field.onChange} sx={styles.numberInputStyle} />
    );
}

const TInputNombreHeuresFin = () => {
    const { control } = useFormContext();
    const { field } = useController({ name: "nombreHeuresFin", control });

    return (
        <NumericFormat fullWidth name={field.name} getInputRef={field.ref} isAllowed={(values) => {const {floatValue} = values; return floatValue >= 0.01 && floatValue <= 99.99 }} value={field.value} inputProps={{maxLength: 5}} decimalSeparator="," decimalScale={2} customInput={TextField} size="small" label={"À"} onChange={field.onChange} sx={styles.numberInputStyle} />
    );
}

const AnomaliesActiviteForm = () => {  
    const { control, watch, resetField } = useFormContext();
    const { errors } = useFormState({ control });
    const societeSelected = watch("societe");

    const reset = useCallback(() => {
        resetField("etablissement");
        resetField("direction");
        resetField("service");
        resetField("activite");
    }, [resetField]);

    return (
        <Stack spacing={1.5}>
            <Stack spacing={1}>
                <TextContainer p={1.25} bgColor={whiteColor[5]} horizontalAlign="center" textWeight="bold" textSize={15}>Période d'analyse</TextContainer>
                {(errors["dateDebut"]) && <ErrorBase horizontalAlign="left">{errors["dateDebut"]?.message}</ErrorBase>}
                {(errors["dateFin"]) && <ErrorBase horizontalAlign="left">{errors["dateFin"]?.message}</ErrorBase>}
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <DateHookFormInput name="dateDebut" label={"Date de début"} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateHookFormInput name="dateFin" label={"Date de fin"} displayError />
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
            <Stack spacing={1}>
                <TextContainer p={1.25} bgColor={whiteColor[5]} horizontalAlign="center" textWeight="bold" textSize={15}>Critères de sélection</TextContainer>
                {errors["societe"] && <ErrorBase horizontalAlign="left">Société obligatoire</ErrorBase>}
                {(!errors["societe"] && errors["activite"]) && <ErrorBase horizontalAlign="left">Activité obligatoire</ErrorBase>}
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <SocietesHookFormInput action={reset} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <EtablissementsHookFormInput codeSociete={societeSelected?.codeSociete} />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <DirectionsHookFormInput codeSociete={societeSelected?.codeSociete} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ServicesHookFormInput codeSociete={societeSelected?.codeSociete} />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={1}> 
                        <Grid item xs={12} md={6}>
                            <SecteursHookFormInput />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ActivitesHookFormInput codeSociete={societeSelected?.codeSociete} />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <TInputNombreHeuresDebut />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TInputNombreHeuresFin />
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
        </Stack>
    );
}

const styles = {
    autocompleteStyle: {
        borderRadius: 2,
        backgroundColor: whiteColor[5],
        "& .MuiOutlinedInput-root": { carretColor: greyColor[5], color: greyColor[9],
            "& fieldset": { color: greyColor[5], borderRadius: 2 },
            "&.Mui-error": {
                borderRadius: 2,
                backgroundColor: redColor[2],
            }
        }
    },
    dateStyle: {
        borderRadius: 2,
        backgroundColor: whiteColor[5],
        "& .MuiOutlinedInput-root": { carretColor: greyColor[5], color: greyColor[9],
            "& fieldset": { color: greyColor[5], borderRadius: 2 },
            "&.Mui-error": {
                borderRadius: 2,
                backgroundColor: redColor[2],
            }
        }
    },
    numberInputStyle: {
        borderRadius: 2,
        backgroundColor: whiteColor[5],
        "& .MuiOutlinedInput-root": { carretColor: greyColor[5], color: greyColor[9],
            "& fieldset": { color: greyColor[5], borderRadius: 2 },
            "&.Mui-error": {
                borderRadius: 2,
                backgroundColor: redColor[2],
            }
        }
    }
}

export default AnomaliesActiviteForm;