import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment';

const initialState = {
    dateValidite: moment().format("DDMMYYYY"),
    collaborateurs: [],
    collaborateursDisplayed: [],
    filtered: false,
    tried: false,    
    collaborateurSelected: null,
}

const colPlaGesTemSlice = createSlice({
    name: 'colPlaGesTem',
    initialState: initialState,
    reducers: {
        setCollaborateurs: (state, action) => {
            state.collaborateurs = action.payload;
            state.collaborateursDisplayed = action.payload;
            state.filtered = false;
            state.tried = false;
        },
        setDate: (state, action) => {
            state.dateValidite = action.payload;
        },
        filtrer: (state, action) => {
            state.collaborateursDisplayed = action.payload;
            state.filtered = true;
        },
        trier: (state, action) => {
            state.collaborateursDisplayed = action.payload;
            state.tried = true;
        },
        resetFiltre: (state) => {
            state.collaborateursDisplayed = state.collaborateurs;
            state.filtered = false;
        },
        resetTrie: (state) => {
            state.collaborateursDisplayed = state.collaborateurs;
            state.tried = false;
        },
        selectCollaborateur: (state, action) => {
            state.collaborateurSelected = action.payload
        },
        resetCollaborateur: (state, action) => {
            state.collaborateurSelected = null;
        },
        reset: () => initialState
    },
})

const { actions, reducer } = colPlaGesTemSlice
export const { 
    setCollaborateurs,
    setDate,
    filtrer,
    trier,
    resetFiltre,
    resetTrie,
    selectCollaborateur,
    resetCollaborateur,
    reset 
} = actions
export default reducer