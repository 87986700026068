import { useSelector } from "react-redux";
import { Box, Grid, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { DialogContainer, HeaderDialogContainer, TextContainer } from "@components/Containers";
import { ROLE_UTILISATEUR } from "@constants/userConstants";
import { greenColor, redColor, whiteColor, yellowColor } from "@styles";
import { selectDrgGesTem } from "@selectors";
import moment from "moment";
import { heurify } from "@utils";

const libelleReponse = {
    A: "Acceptée",
    R: "Refusée"
}

const colorStatut = {
    "": yellowColor[5],
    A: greenColor["045"],
    R: redColor[1]
}

const booleanString = {
    "": "Non défini",
    O: "Oui",
    N: "Non"
}

const DemandeRegularisationSuiviDialog = (props) => {
    const { userType, suivi, open, setOpen } = props;
    const stateDemandeRegularisation = useSelector(selectDrgGesTem);

    return (
        <DialogContainer open={open} placement="top" taille="md">
            <HeaderDialogContainer 
                title="Suivi de la demande de régularisation"
                close={() => setOpen(false)}
            />
            <Box bgcolor={greenColor["045"]} borderRadius={2}>  
                <Stack spacing={0.5} bgcolor={whiteColor[5]} p={0.5} borderRadius={2}>
                    <Box>
                        <Grid container spacing={0.5}>
                            <Grid item xs={12} sm={9}>
                                {userType === ROLE_UTILISATEUR.COLLABORATEUR ?
                                    <TextContainer bgColor={whiteColor[5]} p={1}>Demande émise le <Box component={"span"} fontWeight="bold" display="inline">{moment(stateDemandeRegularisation?.demandeRegularisationSelected?.dateDemande, "YYYYMMDD").format("DD/MM/YYYY")}</Box> à <Box component={"span"} fontWeight="bold" display="inline">{moment(stateDemandeRegularisation?.demandeRegularisationSelected?.heureDemande, "HHmmss").format("HH:mm:ss")}</Box></TextContainer>
                                :
                                    <TextContainer bgColor={whiteColor[5]} p={1}>Demande émise par <Box component={"span"} fontWeight="bold" display="inline">{stateDemandeRegularisation?.demandeRegularisationSelected?.prenomNom}</Box> le <Box component={"span"} fontWeight="bold" display="inline">{moment(stateDemandeRegularisation?.demandeRegularisationSelected?.dateDemande, "YYYYMMDD").format("DD/MM/YYYY")}</Box> à <Box component={"span"} fontWeight="bold" display="inline">{heurify(stateDemandeRegularisation?.demandeRegularisationSelected?.heureDemande, ":", true, true)}</Box></TextContainer>
                                }
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextContainer bgColor={colorStatut[stateDemandeRegularisation?.demandeRegularisationSelected?.codeStatut] ? colorStatut[stateDemandeRegularisation?.demandeRegularisationSelected?.codeStatut] : yellowColor[1]} p={1} horizontalAlign="center" textWeight="bold">{stateDemandeRegularisation?.demandeRegularisationSelected?.libelleStatut}</TextContainer>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box bgcolor={whiteColor[5]} borderRadius={2} p={1}>
                        <Typography component="div" fontSize="small">Régularisation de la journée du <Box fontWeight="bold" display="inline">{moment([stateDemandeRegularisation?.demandeRegularisationSelected?.anneeRattachement, stateDemandeRegularisation?.demandeRegularisationSelected?.moisRattachement - 1, stateDemandeRegularisation?.demandeRegularisationSelected?.jourRattachement]).format("dddd DD MMMM YYYY")}</Box></Typography>
                    </Box>
                    {stateDemandeRegularisation?.demandeRegularisationSelected?.observation && 
                        <TextContainer bgColor={whiteColor[5]} p={1}>
                            Motif de la demande : {stateDemandeRegularisation?.demandeRegularisationSelected?.observation}
                        </TextContainer>
                    }
                </Stack>
            </Box>
            {suivi.map((item) => (
                <Box key={`LHS${item.id}`} bgcolor={colorStatut[item.codeReponse]} borderRadius={2}>  
                    <Stack spacing={0.5} bgcolor={whiteColor[5]} p={0.5} borderRadius={2}>
                        <Box>
                            <Grid container spacing={0.5}>
                                <Grid item xs={12} sm={10}>
                                    <Box padding={1} bgcolor={whiteColor[5]} borderRadius={2}>
                                        <Typography fontWeight="bold" fontSize="small">Destinataire : {item.destinataire}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Box textAlign="center" padding={1} bgcolor={whiteColor[5]} borderRadius={2}>
                                        <Typography fontWeight="bold" fontSize="small">Réponse {item.id}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box padding={1} bgcolor={whiteColor[5]} borderRadius={2}>
                            <Typography fontSize="small">Averti : {booleanString[item.destinataireAverti]}</Typography>
                            <Typography fontSize="small">{item.codeReponse ? libelleReponse[item.codeReponse] + " le " + moment(item.dateReponse, "YYYYMMDD").format("DD/MM/YYYY") + " à " + moment(item.heureReponse, "Hmmss").format("HH:mm:ss") : "Pas encore de réponse"}</Typography>
                            {item.observations && <Typography fontSize="small">Message : {item.observations}</Typography>}
                            {item.motifRefus && <Typography fontSize="small">Motif de refus : {item.motifRefus}</Typography>}
                        </Box>
                    </Stack>
                </Box>
            ))}
        </DialogContainer>
    );
}

DemandeRegularisationSuiviDialog.defaultProps = {
    suivi: [],
    open: false,
    setOpen: () => {}
}

DemandeRegularisationSuiviDialog.propTypes = {
    userType: PropTypes.string.isRequired,
    suivi: PropTypes.array,
    open: PropTypes.bool,
    setOpen: PropTypes.func
}

export default DemandeRegularisationSuiviDialog;