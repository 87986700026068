import axios from "axios";
import moment from "moment";
import { urls } from "@constants/appConstants";

export const fetchRegCol = async (matricule, typePersonnel, date, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetDetailRegularisationCollaborateur"
    };
    const momentDate = moment(date, "DDMMYYYY");
    const anneeRattachement = momentDate.format("YYYY");
    const moisRattachement = momentDate.format("MM");
    const jourRattachement = momentDate.format("DD");
    const body = {
        matricule: matricule,
        typePersonnel: typePersonnel,
        anneeRattachement: anneeRattachement,
        moisRattachement: moisRattachement,
        jourRattachement: jourRattachement
    };
    const response = await axios.post(urls.getDetailRegularisationCollaborateur, body, { headers: headers, params: { client: client }, signal: signal });
    return {transactions: response.data.transactions, regularisations: response.data.regularisations, suivi: []};
}

export const fetchRegMan = async (utilisateurHierarchique, matriculeCollaborateur, typePersonnel, date, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetDetailRegularisationManager"
    };
    const momentDate = moment(date, "DDMMYYYY");
    const anneeRattachement = momentDate.format("YYYY");
    const moisRattachement = momentDate.format("MM");
    const jourRattachement = momentDate.format("DD");
    const body = {
        utilisateurHierarchique: utilisateurHierarchique,
        matriculeCollaborateur: matriculeCollaborateur,
        typePersonnel: typePersonnel,
        anneeRattachement: anneeRattachement,
        moisRattachement: moisRattachement,
        jourRattachement: jourRattachement
    };
    const response = await axios.post(urls.getDetailRegularisationManager, body, { headers: headers, params: { client: client }, signal: signal });
    return { transactions: response.data.transactions, regularisations: response.data.regularisations, autorisation: response.data.autorisationHierarchique };
}

export const editRegMan = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostRegularisationManager"
    };
    const body = {
        utilisateurHierarchique: data.utilisateurHierarchique,
        matriculeHierarchique: data.matriculeHierarchique,
        matriculeCollaborateur: data.matriculeCollaborateur,
        dateRegularisation: data.dateRegularisation,
        niveauValidation: data.niveauValidation,
        regularisations: data.regularisations
    };
    const response = await axios.post(urls.postRegularisationManager, body, { headers: headers, params: { client: data.client } });
    return response.data;
}

export const copyReg = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteCopyRegularisations"
    };
    const body = {
        matricule: data.matricule,
        typePersonnel: data.typePersonnel,
        dateRegularisation: data.dateRegularisation,
        typeCopie: data.typeCopie
    };
    const response = await axios.post(urls.copyRegularisations, body, { headers: headers, params: { client: data.client } });
    return response.data;
}