import { Typography } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export const TextDureeAbsenceContainer = (props) => {
    const { dateDebut, dateReprise, nature, nombreJours } = props;
    const { t } = useTranslation("global");

    return (  
        Number(nombreJours) > 1 ?
            <Typography fontSize="small">Du {moment(dateDebut, "DDMMYYYY").format("dddd DD MMMM YYYY")} {nature === "Demande d'absence" ? ", reprise le" : "au"} {moment(dateReprise, "DDMMYYYY").format("dddd DD MMMM YYYY")}</Typography> 
        :
            <Typography fontSize="small">Le {moment(dateDebut, "DDMMYYYY").format("dddd DD MMMM YYYY")}</Typography>
    );
}

TextDureeAbsenceContainer.defaultProps = {
    dateDebut: "", 
    dateReprise: "", 
    nature: "Demande d'absence", 
    nombreJours: 0
}

TextDureeAbsenceContainer.propTypes = {
    dateDebut: PropTypes.string, 
    dateReprise: PropTypes.string, 
    nature: PropTypes.string, 
    nombreJours: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default TextDureeAbsenceContainer;