import { DatePicker, LocalizationProvider, PickersDay, frFR } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useState } from "react";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import IconDatePickerButton from "@components/Buttons/IconDatePickerButton";
import { greenColor, greyColor, whiteColor } from "@styles";

const CustomPickersDay = styled(PickersDay, {shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered'})(({ isSelected, isHovered, day }) => ({
    borderRadius: 0,
    ...(isSelected && {
        backgroundColor: greenColor["025"],
        '&:hover, &:focus': {
            backgroundColor: greenColor["025"],
        },
    }),
    ...(isHovered && {
        backgroundColor: greenColor["025"],
        '&:hover, &:focus': {
            backgroundColor: greenColor["025"],
        },
    }),
    ...(day.day() === 0 && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
    ...(day.day() === 1 && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
}));

const isInSameWeek = (dayA, dayB) => {
    if (dayB == null) {
        return false;
    }
  
    return dayA.isSame(dayB, 'week');
};

const Day = ({
    day, 
    selectedDay, 
    hoveredDay, 
    ...other
}) => {  
    return (
        <CustomPickersDay
            {...other}
            day={day}
            sx={{ px: 2.5 }}
            disableMargin
            selected={false}
            isSelected={isInSameWeek(day, selectedDay)}
            isHovered={isInSameWeek(day, hoveredDay)}
        />
    );
}

const bgColor = {
    white5Green9White9: whiteColor[5],
    white2Grey9White9: whiteColor[3]
}

const color = {
    white5Green9White9: greyColor[5],
    white2Grey9White9: greyColor[9]
}

const WeekPickerLabelizedButton = ({
    date = null, 
    icon = <></>, 
    title = "", 
    placement = "bottom", 
    noClick = false, 
    variant = "white5Green9White9",
    action = () => {}
}) => {
    const [open, setOpen] = useState(false);
    const [hoveredDay, setHoveredDay] = useState(null);

    return (  
        <Box bgcolor={noClick ? "none" : bgColor[variant]} borderRadius={20} display="flex" alignItems="center">
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"frFR"} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DatePicker
                    slots={{ field: IconDatePickerButton, day: Day }}
                    slotProps={{ 
                        field: { icon, title, placement, noClick, variant, setOpen }, 
                        day: (ownerState) => ({
                            selectedDay: date,
                            hoveredDay,
                            onPointerEnter: () => setHoveredDay(ownerState.day),
                            onPointerLeave: () => setHoveredDay(null),
                        }), 
                        desktopPaper: {sx: { px:1 }}
                    }}
                    showDaysOutsideCurrentMonth
                    displayWeekNumber
                    open={open}
                    views={["year", "month", "day"]}
                    value={date}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    onAccept={(date) => {action(date)}}
                />
            </LocalizationProvider>
            <Box color={color[variant]} pr={2} pl={1} component="p" m={0} display="flex" alignItems="center">
                <Typography fontSize="small" fontWeight="bold">Semaine : {date.week()} - Année : {date.year()}</Typography>
            </Box>
        </Box>
    );
}

WeekPickerLabelizedButton.propTypes = {
    date: PropTypes.object, 
    icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]), 
    title: PropTypes.string, 
    placement: PropTypes.string, 
    noClick: PropTypes.bool, 
    variant: PropTypes.string, 
    action: PropTypes.func
}

export default WeekPickerLabelizedButton;