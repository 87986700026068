import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { HeaderDialogContainer } from "@components/Containers";
import { useTranslation } from "react-i18next";

const ActionsCondition = ({
    isLoading = true, 
    close = () => {}, 
    postDemandeAbsenceCondition = () => {}
}) => {
    const { t } = useTranslation("global");

    return (
        <HeaderDialogContainer  
            actionsLeft={
                <IconButtonBase 
                    variant="white5Grey9White9"
                    icon={<Check fontSize="small" />} 
                    title={t("buttons.accept_condition")} 
                    disabled={isLoading} 
                    action={() => postDemandeAbsenceCondition()} 
                />
            }
            title={t("titles.validation_absence_long")}
            close={() => close()} 
            variant="error"
        />
    )
}

ActionsCondition.propTypes = {
    isLoading: PropTypes.bool,
    close: PropTypes.func,
    postDemandeAbsenceCondition: PropTypes.func
}

export default ActionsCondition;