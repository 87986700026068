import { Dialog, Stack } from "@mui/material";
import PropTypes from "prop-types";

const DialogContainer = ({
    open = false,
    children = <></>,
    placement = "",
    taille = "md"
}) => {
    const positionStyle = placement === "top" ? styles.containerDialogStyle : {};

    return (  
        <Dialog open={open} PaperProps={{sx: { borderRadius: 2 }}} sx={{backdropFilter: "blur(5px)", ...positionStyle}} fullWidth maxWidth={taille}>
            <Stack spacing={0.5} p={0.5} overflow="auto">
                {children} 
            </Stack>
        </Dialog>
    );
}

DialogContainer.propTypes = {
    open: PropTypes.bool, 
    children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.array]),
    placement: PropTypes.string,
    taille: PropTypes.oneOf(["xl", "lg", "md", "sm", "xs"])
}

const styles = {
    containerDialogStyle: {
        "& .MuiDialog-container": {
            alignItems: "start"
        }
    }
}

export default DialogContainer;