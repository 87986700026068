import { useSelector } from "react-redux";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import { useFieldArray, useFormContext, useFormState } from "react-hook-form";
import { selectSocAffActCol } from "@selectors";
import { DateHookFormInput } from "@components/Inputs";
import { IconButtonBase } from "@components/Buttons";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";
import PropTypes from "prop-types";
import { HeaderListContainer } from "@components/Containers";
import { ActivitesAffectationsCollectivesInput } from "@components/Inputs/Activites";

const headerItems = [
    {id: 1, label: "Date d'effet", xs: 5, display: true},
    {id: 2, label: "Activite", xs: 5, display: true}
];

const AffectationsCollectivesActiviteForm = (props) => {
    const { isLoading } = props;
    const { control, watch } = useFormContext();
    const { errors } = useFormState({ control });
    const { fields, append, remove } = useFieldArray({ control, name: "affectations" });
    const stateSocietes = useSelector(selectSocAffActCol); 
    const watchFieldArray = watch("affectations");
    const controlledFields = fields.map((field, id) => {
        return {
          ...field,
          ...watchFieldArray[id]
        };
    });
    

    return (
        isLoading ? 
            <Box sx={styles.loaderStyle}>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box> 
        :
            <>
                <Box>
                    <HeaderListContainer itemIdReference="HAFFCC" headerItems={headerItems} />
                </Box>
                <Box>
                    <Stack spacing={0.5}>
                        {controlledFields.map((field, id) => (
                            <Box key={`INPUTAFFCC${field.id}`} p={0.5} bgcolor={greenColor["015"]} borderRadius={2}>
                                <Grid container spacing={0.5}>
                                    <Grid item xs={5}>
                                        <DateHookFormInput name={`affectations.${id}.dateEffet`} label="Date d'effet" /> 
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Box bgcolor={redColor[2]} borderRadius={2}>
                                            <ActivitesAffectationsCollectivesInput name={`affectations.${id}.activite`} label="Activité" codeSociete={stateSocietes?.societeSelected?.codeSociete} date={watchFieldArray[id]?.dateEffet} />
                                            {errors?.affectations?.[id]?.activite && <Box sx={styles.boxInputErrorStyle}>
                                                    <Typography sx={styles.typoInputErrorStyle}>Veuillez selectionner une activité</Typography>
                                                </Box>
                                            }
                                        </Box>
                                    </Grid>
                                    {controlledFields.length > 1 && 
                                        <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
                                            <IconButtonBase 
                                                icon={<Close fontSize="small" />} 
                                                title="Enlever l'activité" 
                                                colorIconButton={redColor[7]} 
                                                bgColor={whiteColor[5]} 
                                                action={() => remove(id)} 
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </Box>
                        ))}
                        <Box p={1} display="flex" justifyContent="center">
                            <IconButtonBase 
                                icon={<Add fontSize="small" />} 
                                title="Ajouter une affectation" 
                                bgColor={greenColor["01"]} 
                                action={() => append({dateEffet: null, activite: ""})} 
                            />
                        </Box>
                    </Stack>
                </Box>
            </>
    );
}

AffectationsCollectivesActiviteForm.defaultProps = {
    isLoading: false
}

AffectationsCollectivesActiviteForm.propTypes = {
    isLoading: PropTypes.bool
}

const styles = {
    stackInputStyle: {
        borderRadius: 2,
        backgroundColor: redColor[1]
    },
    boxInputStyle: {
        borderRadius: 2,
        backgroundColor: whiteColor[9]
    },
    loaderStyle: {
        height: 100,
        alignItems: "center",
        display: "flex"
    },
    boxInputErrorStyle: {
        textAlign: "left",
        borderBottomLeftRadius: 8, 
        borderBottomRightRadius: 8,
        padding: 1
    },
    typoInputErrorStyle: {
        fontSize: "small", 
        color: greyColor[5]
    }
}

export default AffectationsCollectivesActiviteForm;