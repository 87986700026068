import { useSelector } from "react-redux";
import moment from "moment";
import { Box, Button, Grid, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { CalendrierHeaderTable } from "./Header";
import { getGreyColor, greenColor, greyColor, whiteColor, yellowColor } from "@styles";
import { ErrorBase } from "@components/Errors";
import { Loader } from "@components/Loaders";
import { selectCalGesAbs } from "@selectors";
import { TextContainer } from "@components/Containers";
import { useTranslation } from "react-i18next";

const verifyAbsencesValidees = (absences) => {
    let retour = false;
    absences.forEach(absence => {
        if(Number(absence.codeStatut)) {
            retour = true;
        }
    });
    return retour;
}

const verifyAbsencesEnAttentes = (absences) => {
    let retour = false;
    absences.forEach(absence => {
        if(!Number(absence.codeStatut)) {
            retour = true;
        }
    });
    return retour;
}

const CalendrierTableMobile = (props) => {
    const { isLoading, isError, errorMessage, semaines, openJour } = props;
    const stateCalendrier = useSelector(selectCalGesAbs);
    const { t } = useTranslation("global");

    const jours = [
        {id: 1, libelle: t("calendar.day1"), libelleSmall: t("calendar.day1Small")}, 
        {id: 2, libelle: t("calendar.day2"), libelleSmall: t("calendar.day2Small")}, 
        {id: 3, libelle: t("calendar.day3"), libelleSmall: t("calendar.day3Small")},
        {id: 4, libelle: t("calendar.day4"), libelleSmall: t("calendar.day4Small")}, 
        {id: 5, libelle: t("calendar.day5"), libelleSmall: t("calendar.day4Small")}, 
        {id: 6, libelle: t("calendar.day6"), libelleSmall: t("calendar.day6Small")}, 
        {id: 7, libelle: t("calendar.day7"), libelleSmall: t("calendar.day7Small")}
    ];

    if(isLoading) {
        return (
            <Box justifyContent={"center"} display={"flex"} alignItems={"center"} height={`calc(${stateCalendrier.hauteur}px - 66px)`}>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return <ErrorBase>{errorMessage}</ErrorBase>;
    }

    return (
        <Stack spacing={0.25}>
            <CalendrierHeaderTable jours={jours} />         
            {semaines.map((semaine) => (
                <Box key={`CALS${semaine.semaine}`} p={0.25} borderRadius={2} bgcolor={greenColor["015"]}>
                    <Grid container spacing={0.5}>
                        <Grid item xs={1.5}>
                            <TextContainer p={0.75} bgColor={greenColor["02"]} horizontalAlign="center" verticalAlign="center" textWeight="bold">
                                {semaine.semaine}
                            </TextContainer>
                        </Grid>
                        {semaine.jours.map((jour) => (
                            <Grid key={`CALJ${jour.id}`} item xs={1.5}>
                                <Box component="span" width="100%" height="100%" display="flex">
                                    <Button 
                                        sx={{ 
                                            minWidth: 0,
                                            width: "100%",
                                            height: 31.5, 
                                            maxHeight: 35.5,
                                            padding: 1, 
                                            textTransform: "none", 
                                            borderRadius: 2, 
                                            boxShadow: "none", 
                                            color: getGreyColor("0.59"), 
                                            backgroundColor: whiteColor[5], 
                                            "&:hover": { 
                                                color: whiteColor[9],
                                                backgroundColor: greyColor[0] 
                                            } 
                                        }} 
                                        onClick={() => openJour(jour)}
                                    >
                                        <Stack spacing={0}>
                                            <TextContainer p={0} bgColor={null}>{moment(jour.date, "DDMMYYYY").format("DD")}</TextContainer>
                                            {jour.absences.length > 0 &&
                                                <Stack spacing={0.2} direction="row">
                                                    {verifyAbsencesValidees(jour.absences) && <Box width={"100%"} height={5} bgcolor={greenColor["1"]} borderRadius={1}></Box>}
                                                    {verifyAbsencesEnAttentes(jour.absences) && <Box width={"100%"} height={5} bgcolor={yellowColor[9]} borderRadius={1}></Box>}
                                                </Stack>
                                            }
                                        </Stack>
                                    </Button>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            ))}
        </Stack>
    );
}

CalendrierTableMobile.defaultProps = {
    isLoading: true, 
    isError: false, 
    errorMessage: "",
    semaines: [],
    openJour: () => {}
}

CalendrierTableMobile.propTypes = {
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
    semaines: PropTypes.array,
    openJour: PropTypes.func
}

export default CalendrierTableMobile;