import { useCallback, useState } from "react";
import { Box, Grid, Stack, Tooltip } from "@mui/material";
import { Cancel, Visibility, VisibilityOff } from "@mui/icons-material";
import { useController, useFormContext, useFormState } from "react-hook-form";
import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { greenColor, redColor, TextFieldStyled } from "@styles";
import { useEffectOnce } from "react-use";
import { useTranslation } from "react-i18next";

const NumericPadHookFormInput = ({ 
    name = "", 
    label = "", 
    autoFocus = false, 
    maxLength = 10, 
    readOnly = false, 
    errorPlacement = "left"
}) => {
    const [numerics, setNumerics] = useState([]);
    const [showSecret, setShowSecret] = useState(false);
    const { control, resetField, setValue } = useFormContext();
    const { field } = useController({ name, control });
    const { errors } = useFormState({ control });
    const { t } = useTranslation("global");

    useEffectOnce(() => {
        const numericsRandom = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].sort(() => Math.random() - 0.5);
        setNumerics(numericsRandom);
    })

    const handleShowSecret = useCallback(() => {
        setShowSecret((prevShowSecret) => !prevShowSecret);
    }, []);

    const handleReset = useCallback(() => {
        resetField(name, { defaultValue: "" });
    }, [name, resetField]);

    return (
        <Stack borderRadius={2} bgcolor={greenColor["05"]}>
            <Tooltip 
                open={Boolean(errors?.[name])} 
                title={t(errors?.[name]?.message)} 
                slotProps={{
                    tooltip: {
                        sx: {
                            backgroundColor: redColor[5]
                        }
                    },
                    arrow: {
                        sx: {
                            color: redColor[5]
                        }
                    }
                }}
                placement={errorPlacement}
                arrow
            >
                <Stack direction="row" spacing={0.5}  >
                    <Box flexGrow={1}>
                        <TextFieldStyled
                            fullWidth
                            label={label}
                            size="small"
                            autoFocus={autoFocus}
                            type={showSecret ? "text" : "password"}
                            autoComplete="new-password"
                            inputProps={{ maxLength: maxLength, readOnly: readOnly }}
                            {...field}
                        />
                    </Box>
                    <Box pr={0.5} display="flex" alignItems="center" justifyContent="center">
                        <Stack direction="row" spacing={0.5}>
                            <IconButtonBase 
                                variant="white5Grey9White9" 
                                icon={<Cancel fontSize="small" />}
                                title="Vider le champ" 
                                action={handleReset} 
                            />
                            <IconButtonBase 
                                variant="white5Grey9White9" 
                                icon={showSecret ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />} 
                                title="Voir le mot de passe" 
                                action={handleShowSecret} 
                            />
                        </Stack> 
                    </Box>
                </Stack>
            </Tooltip>
            <Stack spacing={0.5} alignItems="center" py={1}>
                <Box width="100%">
                    <Grid container spacing={1}> 
                        {numerics.map((chiffre, id) => (        
                            id < 5 &&   
                                <Grid key={`NUM${chiffre}`} item xs={2.4}>
                                    <IconButtonBase 
                                        variant="white5Grey9White9" 
                                        icon={<Box width={20}>{ chiffre }</Box>} 
                                        action={() => setValue("password", field.value + chiffre)} 
                                    />
                                </Grid>
                        ))}
                    </Grid>
                </Box>
                <Box width="100%">
                    <Grid container spacing={1}> 
                        {numerics.map((chiffre, id) => (
                            id >= 5 &&   
                                <Grid key={`NUM${chiffre}`} item xs={2.4}>
                                    <IconButtonBase 
                                        variant="white5Grey9White9" 
                                        icon={<Box width={20}>{ chiffre }</Box>} 
                                        action={() => setValue("password", field.value + chiffre)} 
                                    />
                                </Grid>
                        ))}
                    </Grid>
                </Box>
            </Stack>
        </Stack>
    );
}

NumericPadHookFormInput.propTypes = {
    name: PropTypes.string, 
    label: PropTypes.string, 
    autoFocus: PropTypes.bool, 
    maxLength: PropTypes.number, 
    readOnly: PropTypes.bool, 
    errorPlacement: PropTypes.string
}

export default NumericPadHookFormInput;