import { Box, Grid, Grow, Stack, Typography } from "@mui/material";
import { datify, heureIntVersHeureString } from "@utils";
import PropTypes from "prop-types";
import { HeaderListContainer, ItemContainer, ListContainer } from "@components/Containers";
import { ErrorBase } from "@components/Errors";
import { Loader } from "@components/Loaders";
import { CheckBoxButton, greenColor, greyColor, redColor } from "@styles";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTranslation } from "react-i18next";

const DemandesAnnulationList = (props) => {
    const { data, isLoading, isError, errorMessage, demandesSelected, select } = props;
    const { t } = useTranslation("global");

    const headerItems = [
        {id: 1, label: "", xs: 1, display: false},
        {id: 2, label: t("texts.absence_date"), xs: 4, display: true, firstData: true, lastData: false},
        {id: 3, label: t("texts.absence_type"), xs: 4, display: true, firstData: false, lastData: false},
        {id: 4, label: t("texts.hours"), xs: 2, display: true, firstData: false, lastData: false},
        {id: 5, label: t("texts.days"), xs: 1, display: true, firstData: false, lastData: true}
    ];

    if(isLoading) {
        return (
            <Box justifyContent="center" display="flex" alignItems="center" height={218}>
                <Loader size={27} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return <ErrorBase>{errorMessage}</ErrorBase>;
    }

    if(data.length === 0) {
        return (
            <Stack spacing={1} position="relative" height="100%"> 
                <Grid container height="100%">
                    <Grow in={true} timeout={500}>
                        <Grid item xs={12} sx={styles.panelNoDataStyle}> 
                            <Typography fontSize="small" marginLeft={1}>{t("no_data.generic")}</Typography>
                        </Grid>
                    </Grow>
                </Grid>
            </Stack>
        );
    }

    return(
        <Stack spacing={0.5} flexGrow={1}>
            <HeaderListContainer itemIdReference="LISTDANU" headerItems={headerItems} />
            <Box height={175}>
                <PerfectScrollbar> 
                    <ListContainer spacing={0.5} length={data.length}>
                        {data.map((demandeAbsence, id) => (
                            <Grow key={`LISTDANU${demandeAbsence.id}`} in={true} timeout={400+(200*id)}>
                                <Box bgcolor={demandeAbsence.absenceEnDemande === "N" ? greenColor["05"] : redColor[5]} p={0.3} borderRadius={2}>
                                    <Grid container spacing={0.3}>
                                        <Grid xs={1} item display="flex" justifyContent="center" alignItems="center">
                                            {demandeAbsence.absenceEnDemande === "N" && 
                                                <CheckBoxButton 
                                                    variant="white5Grey7White5"
                                                    size="small"
                                                    checked={demandesSelected.includes(Number(demandeAbsence.numeroEnregistrement))} 
                                                    value={demandeAbsence.numeroEnregistrement} 
                                                    onChange={select} 
                                                />
                                            }
                                        </Grid>
                                        <ItemContainer firstData data={datify(moment([demandeAbsence.annee, demandeAbsence.mois - 1, demandeAbsence.jour]).format("DDMMYYYY"), "tddddDDmmmmyyyy")} xs={4} />  
                                        <ItemContainer data={demandeAbsence.libelle} xs={4} />  
                                        <ItemContainer data={heureIntVersHeureString(demandeAbsence.nombreHeures)} xs={2} />  
                                        <ItemContainer lastData data={demandeAbsence.nombreJours} xs={1} />
                                    </Grid>
                                </Box>
                            </Grow>
                        ))}
                    </ListContainer>
                </PerfectScrollbar>
            </Box>
        </Stack>
    );
}

DemandesAnnulationList.defaultProps = {
    data: [],
    isLoading: true,
    isError: false,
    errorMessage: "",
    demandesSelected: [],
    select: () => {}
}

DemandesAnnulationList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
    demandesSelected: PropTypes.array,
    select: PropTypes.func
}

const styles = {
    panelStackStyle: {
        backdropFilter: "blur(3px)",
        backgroundColor:"rgba(0,0,0,0.1)",
        borderRadius: 2
    },
    panelStackOnDemandeStyle: {
        backdropFilter: "blur(3px)",
        backgroundColor:"rgba(255,0,0,0.2)",
        borderRadius: 2
    },
    panelTitleStackStyle: {
        backdropFilter: "blur(3px)",
        backgroundColor:"rgba(49, 133, 156, 0.3)",
        borderRadius: 2,
        padding: 1.5
    },
    panelBoxListStyle: {
        backgroundColor:"rgba(255,255,255,0)",
        borderRadius: 2,
    },
    panelNoDataStyle: {
        backgroundColor: greenColor["015"],
        borderRadius: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        padding: 2
    },
    checkBoxStyle: { 
        "& .MuiSvgIcon-root": { 
            fontSize: 14 
        } 
    },
    buttonStyle: {
        borderRadius: 2, 
        boxShadow: "none", 
        backgroundColor: greyColor[0],
        width: "100%",
        padding: 1,
        color: greyColor[4],
        "&:hover": {
            backgroundColor: greyColor[1],
        }
    },
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center"
    },
}

export default DemandesAnnulationList;