import { Box, Grid, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import PropTypes from "prop-types";
import { greenColor, greyColor, redColor, whiteColor, yellowColor } from "@styles";
import { useTranslation } from "react-i18next";
import { IconButtonBase } from "@components/Buttons";

const bgColor = {
    normale: greenColor["07"],
    warning: yellowColor[6],
    error: redColor[5]
}

const color = {
    normale: whiteColor[9],
    warning: greyColor[9],
    error: whiteColor[9]
}

export const HeaderDialogContainer = ({
    actionsLeft = null,
    actionsRight = null,
    positionnement = [3, 6, 3],
    title = "",
    variant = "normale",
    p = 2,
    align = "center",
    textTitle = true,
    close = () => {}
}) => {
    const { t } = useTranslation("global");
    
    return (  
        <Box bgcolor={whiteColor[9]} borderRadius={2}>
            <Box bgcolor={bgColor[variant]} p={p} textAlign={align} borderRadius={2} color="white">
                <Grid container item xs={12} alignItems="center">
                    <Grid item xs={positionnement[0]} textAlign="left">
                        <Stack direction="row" spacing={0.5}>
                            {actionsLeft}
                        </Stack>
                    </Grid>
                    <Grid item xs={positionnement[1]}>
                        {textTitle ?
                            <Typography color={color[variant]} fontWeight="bold">{title}</Typography>
                        :
                            title
                        }
                    </Grid>
                    <Grid item xs={positionnement[2]}>
                        <Stack direction="row" spacing={0.5} justifyContent="right">
                            {actionsRight}
                            <IconButtonBase 
                                variant="closeDialogButton" 
                                icon={<Close fontSize="small"/>} 
                                title={t("buttons.close")} 
                                placement="top" 
                                action={close} 
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

HeaderDialogContainer.propTypes = {
    actionsLeft: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.array]),
    actionsRight: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.array]),
    positionnement: PropTypes.arrayOf(PropTypes.number),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
    variant: PropTypes.string,
    p: PropTypes.number,
    align: PropTypes.string,
    textTitle: PropTypes.bool,
    close: PropTypes.func
}

export default HeaderDialogContainer;