import { Box, Grid, Grow, Stack, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import PropTypes from "prop-types";
import { TextVariableContainer, PanelListContainer, TextContainer, TextWithLabelContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { Loader } from "@components/Loaders";
import { ErrorBase } from "@components/Errors";
import { greenColor, greyColor, whiteColor } from "@styles";

const MultiSoldesListMobile = (props) => {
    const { data, isLoading, isError, errorMessage, openSituation } = props;

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return <ErrorBase>{errorMessage}</ErrorBase>;
    }

    return (
        <PerfectScrollbar>
            <PanelListContainer length={data.length}>
                {data.map((collaborateur, id) => (
                    <Grow key={`COL${collaborateur.id}`} in={true} timeout={400+(200*id)}>
                        <Grid item xs={12}>
                            <Stack spacing={0.5} borderRadius={2} p={0.5} bgcolor={greenColor["015"]}>
                                <Box bgcolor={greenColor["015"]} boxSizing="border-box" display="flex" justifyContent="center" p={1} borderRadius={2} alignItems="center">
                                    <Typography fontSize="small" fontWeight="bold">{collaborateur.prenomNom}</Typography>
                                </Box>
                                <Stack spacing={0.5} width="100%">
                                    {collaborateur.periode && <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold">Situation à fin {moment(collaborateur.periode, "MMYYYY").format("MMMM YYYY")}, sauf *</TextContainer>}
                                    {collaborateur.soldes.length > 0 ?
                                        <Stack direction="row" spacing={0.5} overflow="auto" boxSizing="border-box" width="100%">
                                            {collaborateur.soldes.map((solde) => (
                                                solde.codeAbsence &&
                                                    <Box key={`COLSOL${solde.id}`} minWidth={200}>
                                                        <Stack spacing={0.25} padding={0.5} borderRadius={2} bgcolor={greenColor["015"]}>
                                                            <TextVariableContainer type={"txt"} p={1} bgColor={whiteColor[5]} textAlign="center" textWeight="bold">{solde.libelleSolde}</TextVariableContainer>
                                                            <Box borderRadius={2} p={1} bgcolor={whiteColor[5]}>
                                                                <Stack spacing={0}>
                                                                    <TextWithLabelContainer label="Solde" value={Number(solde.solde).toLocaleString("fr-FR")} labelWidth={true} twoPoints={false} />
                                                                    <TextWithLabelContainer label="Demandes validées" value={Number(solde.demandesValidees).toLocaleString("fr-FR")} labelWidth={true} twoPoints={false} />
                                                                    <TextWithLabelContainer label="Demandes en attente" value={Number(solde.demandesEnCours).toLocaleString("fr-FR")} labelWidth={true} twoPoints={false} />
                                                                    <TextWithLabelContainer label="Nouveaux Soldes" value={Number(solde.nouveauSolde).toLocaleString("fr-FR")} labelWidth={true} twoPoints={false} />
                                                                </Stack>
                                                            </Box>
                                                            <Box borderRadius={2} height={35.5} display="flex" alignItems="center" justifyContent="center" bgcolor={whiteColor[5]}>
                                                                <IconButtonBase icon={<Info fontSize="small" />} title="Afficher" action={() => openSituation(collaborateur.matricule, collaborateur.periode, solde)} />
                                                            </Box>
                                                        </Stack>
                                                    </Box>
                                            ))}
                                        </Stack>
                                    :
                                        <Grid container height={"100%"}>
                                            <Grow in={true} timeout={500}>
                                                <Box borderRadius={2} bgcolor={whiteColor[5]} width="100%" p={1} display="flex" alignItems="center" justifyContent="center"> 
                                                    <Typography fontSize="small">Aucun solde</Typography>
                                                </Box>
                                            </Grow>
                                        </Grid>
                                    }
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grow>
                ))}
            </PanelListContainer>
        </PerfectScrollbar>
    );
}

MultiSoldesListMobile.defaultProps = {
    data: [], 
    isLoading: true, 
    isError: false, 
    errorMessage: "", 
    openSituation: () => {}
}

MultiSoldesListMobile.propTypes = {
    data: PropTypes.array, 
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool, 
    errorMessage: PropTypes.string, 
    openSituation: PropTypes.func
}

const styles = {
    loaderStyle: {
        height: "100%",
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
    }
}

export default MultiSoldesListMobile;