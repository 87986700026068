import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

const initialState = {
    date: moment().format("DDMMYYYY"),
    affichage: "T",
    allServices: true,
    semaine: [],
    societes: [],
    totaux: null,
    services: [],
    servicesSelected: [],
    servicesNonSelected: [],
    jourSelected: "",
    matriculeSelected: "",
    prenomNomSelected: "",
    premierAppel: 1
}

const plaGloSlice = createSlice({
    name: 'plaGlo',
    initialState: initialState,
    reducers: {
        setPlanning: (state, action) => {
            state.semaine = action.payload.semaine
            state.societes = action.payload.societes
            state.totaux = action.payload.totaux
            state.services = action.payload.services
            state.servicesSelected = action.payload.servicesSelected
            state.servicesNonSelected = action.payload.servicesNonSelected
            state.premierAppel = 0
            state.allServices = action.payload.allServices
        },
        setDate: (state, action) => {
            state.date = action.payload
        },
        toggleAffichage: (state) => {
            state.affichage = state.affichage === "T" ? "V" : "T";
        },
        selectJour: (state, action) => {
            state.jourSelected = action.payload.jour
            state.matriculeSelected = action.payload.matricule
            state.prenomNomSelected = action.payload.prenomNom
        },
        reset: () => initialState
    },
})

const { actions, reducer } = plaGloSlice
export const { 
    setPlanning,  
    setDate,
    toggleAffichage, 
    selectJour, 
    reset 
} = actions
export default reducer