import { Box } from "@mui/material";
import PropTypes from "prop-types";

const TabContentContainer = (props) => {  
    const { children, value, id, overflow, height } = props;

    return (  
        value === id && <Box sx={styles.tabPanelStyle} overflow={overflow} height={height}>{children}</Box>
    );
}

TabContentContainer.defaultProps = {
    children: <></>,
    value: "nothing", 
    id: "", 
    overflow: "auto", 
    height: "100%" 
}

TabContentContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]), 
    value: PropTypes.string, 
    id: PropTypes.string, 
    overflow: PropTypes.string, 
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) 
}

const styles = {
    tabPanelStyle: {
        width: "100%",
        padding: 0,
    }
}

export default TabContentContainer;