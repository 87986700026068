import { Typography } from "@mui/material";
import PropTypes from "prop-types";

const TextWithLabelContainer = (props) => {
    const { label, value, twoPoints, alignement, size, weight, labelWidth } = props;

    return (  
        <Typography component="div" fontSize="small" display={labelWidth ? "inline-flex" : "inline"}>
            <Typography display={"inline"} fontSize={size.label} fontWeight={weight.label} textAlign={alignement.label} flexGrow={labelWidth && 1} noWrap>
                {label} {twoPoints && " : "} 
            </Typography>
            <Typography display="inline" fontSize={size.value} fontWeight={weight.value} textAlign={alignement.value}>
                {value}
            </Typography>
        </Typography>
    );
}

TextWithLabelContainer.defaultProps = {
    label: "",
    value: "",
    twoPoints: true,
    alignement: { label: "left", value: "left" },
    size: { label: "small", value: "small" },
    weight: { label: "normal", value: "normal" },
    labelWidth: false
}

TextWithLabelContainer.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    twoPoints: PropTypes.bool,
    alignement: PropTypes.shape({
        label: PropTypes.oneOf(["left", "right", "center"]),
        value: PropTypes.oneOf(["left", "right", "center"])
    }),
    size: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    weight: PropTypes.shape({
        label: PropTypes.oneOf(["bold", "normal"]),
        value: PropTypes.oneOf(["bold", "normal"])
    }),
    labelWidth: PropTypes.bool
}

export default TextWithLabelContainer;