import { useSelector } from "react-redux";
import { selectDabGesAbsMan } from "@selectors";
import { Stack } from "@mui/material";
import { whiteColor } from "@styles";
import { PAGES_GESTION_ABSENCES } from "@constants/demandesAbsenceConstants";
import DetailPage from "@components/GestionAbsences/Manager/DetailPage";
import ListPage from "@components/GestionAbsences/Manager/ListPage";
import MultiSoldesPage from "@components/GestionAbsences/Manager/MultiSoldesPage";

const AbsencesManager = () => {

    const stateAbsencesManager = useSelector(selectDabGesAbsMan);

    return (
        <Stack spacing={0.5} bgcolor={whiteColor[9]} borderRadius={2} p={0.5} height="100%" boxSizing="border-box">
            {{
                [PAGES_GESTION_ABSENCES.LIST]: <ListPage />,
                [PAGES_GESTION_ABSENCES.SOLDES]: <MultiSoldesPage />,
                [PAGES_GESTION_ABSENCES.REPONSE]: <DetailPage />
            }[stateAbsencesManager.page]}
        </Stack>
    );
}

export default AbsencesManager;