import { useState } from "react";
import PropTypes from "prop-types";
import AutocompleteAsyncInput from "../AutocompleteAsyncInput";
import { useActAffColInp } from "@hooks/activites/hooksQueries";

const headerOptionsActivites = [
    {id: 1, title: "Code", xs: 6, key: "codeActivite"},
    {id: 2, title: "Designation", xs: 6, key: "libelleActivite"}
];

const labelsMapping = (option) => option.libelleActivite ? `${option.codeActivite} - ${option.libelleActivite}` : "";

const ActivitesAffectationsCollectivesInput = (props) => {
    const { name, label, codeSociete, date, action } = props;
    const [open, setOpen] = useState(false);
    const query = useActAffColInp(codeSociete, date, open);

    return (
        <AutocompleteAsyncInput
            name={name}
            label={label}
            headerOptions={headerOptionsActivites} 
            query={query} 
            tooltipPlacement="left" 
            disabled={!!(!codeSociete || !date || !date?.isValid())} 
            disabledText={!codeSociete ? "Vous devez selectionner une société." : "Vous devez selectionner une date"} 
            open={open} 
            onChange={action}
            setOpen={setOpen} 
            labelsMapping={labelsMapping} 
        />
    );
}

ActivitesAffectationsCollectivesInput.defaultProps = {
    name: "activite",
    label: "Activite",
    date: "",
    codeSociete: "",
    action: () => {}
}

ActivitesAffectationsCollectivesInput.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    codeSociete: PropTypes.string,
    action: PropTypes.func
}

export default ActivitesAffectationsCollectivesInput;