import { useCallback } from "react";
import { Box, Stack } from "@mui/material";
import { Add, Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { IconButtonBase } from "@components/Buttons";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor } from "@styles";
import { DialogContainer, HeaderDialogContainer } from "@components/Containers";
import { schemaCycleAffectation } from "../validations";
import { CycleAffectationIndividuelleForm } from "../Horaires/Forms";
import moment from "moment";

const defaultValues = {
    dateDebut: moment(),
    dateFin: null,
    horaires: [{horaire: null}],
    cycleMin: 1,
    cycle: ""
}

const CycleAffectationIndividuelleDialog = (props) => {
    const { isLoading, open, setOpen, addCyleAffectation } = props;
    const methods = useForm({ mode: "all", defaultValues: defaultValues, resolver: joiResolver(schemaCycleAffectation) });
    const { fields, append, remove } = useFieldArray({ control: methods.control, name: "horaires" });
    
    const watchFieldArray = methods.watch("horaires");
    const controlledFields = fields.map((field, id) => {
        return {
          ...field,
          ...watchFieldArray[id]
        };
    });

    const handleAdd = useCallback(() => {
        append({horaire: null});
    }, [append])

    return (
        <DialogContainer open={open} taille="xs" placement="top">
            <HeaderDialogContainer  
                actionsLeft={
                    <Stack direction="row" spacing={0.5}>
                        <IconButtonBase icon={<Check fontSize="small" />} title="Valider" disabled={isLoading} action={() => methods.handleSubmit(addCyleAffectation)()} />
                        {controlledFields.length < 15 && <IconButtonBase icon={<Add fontSize="small" />} title="Ajouter un horaire" disabled={isLoading} action={() => handleAdd()} />}
                    </Stack>    
                }
                title="Cycle d'affectation"
                close={() => setOpen(false)}
            />
            {isLoading ? 
                <Box sx={styles.loaderStyle}><Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} /></Box> 
            : 
                <Stack spacing={0.5} bgcolor={greenColor["015"]} borderRadius={2} p={0.5}>
                    <FormProvider {...methods}>
                        <CycleAffectationIndividuelleForm controlledFields={controlledFields} remove={remove} open={open} />
                    </FormProvider>
                </Stack>
            }
        </DialogContainer>
    );
}

CycleAffectationIndividuelleDialog.defaultProps = { 
    isLoading: false,
    open: false,
    setOpen: () => {},
    addCyleAffectation: () => {}
}

CycleAffectationIndividuelleDialog.propTypes = {
    isLoading: PropTypes.bool,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    addCyleAffectation: PropTypes.func
}


const styles = {
    itemInterneNonCheckBoxStyle: {
        backgroundColor: "rgba(255,0,0,0.3)",
        borderRadius: 2,
        paddingX: 1,
        paddingY: 1
    },
    headerListStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.5)",
        color: "white",
        borderRadius: 2,
        padding: 2
    },
    itemListStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.2)",
        borderRadius: 2,
        paddingX: 2,
        paddingY: 1
    },
    loaderStyle: {
        height: 100,
        alignItems: "center",
        display: "flex"
    },
}

export default CycleAffectationIndividuelleDialog;