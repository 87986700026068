import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    listed: false,
    filtered: false,
    tried: false,
    compteurs: [],
    compteursDisplayed: []
};

const comGesTemSlice = createSlice({
    name: 'comGesTem',
    initialState: initialState,
    reducers: {
        setCompteurs: (state, action) => {
            state.compteurs = action.payload;
            state.compteursDisplayed = action.payload;
        },
        lister: (state) => {
            state.listed = !state.listed;
        },
        filtrer: (state, action) => {
            state.compteursDisplayed = action.payload;
            state.filtered = true;
        },
        trier: (state, action) => {
            state.compteursDisplayed = action.payload;
            state.tried = true;
        },
        deleteFiltre: (state) => {
            state.compteursDisplayed = state.compteurs;
            state.filtered = false
        },
        deleteTrie: (state) => {
            state.compteursDisplayed = state.compteurs;
            state.tried = false
        }
    }
})

const { actions, reducer } = comGesTemSlice
export const { 
    setCompteurs, 
    lister, 
    deleteFiltre, 
    deleteTrie, 
    filtrer, 
    trier
} = actions
export default reducer
