import { Box, Stack } from "@mui/material";
import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { Loader } from "@components/Loaders";
import { IconButtonBase } from "@components/Buttons";
import { DialogContainer, HeaderDialogContainer, TextContainer } from "@components/Containers";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";

const ContrainteDialog = (props) => {
    const { isLoading, open, setOpen, contrainte, sendReponse } = props;

    return (
        <DialogContainer open={open} taille="sm">
            <HeaderDialogContainer 
                actionsLeft={
                    <IconButtonBase icon={<Check fontSize="small" />} title="Valider" disabled={isLoading} action={() => sendReponse({reponse: "A", motif: "", contrainteValidee: "O"})} />
                }
                title="Validation de la demande d'absence"
                close={() => setOpen(false)} 
            />
            {isLoading ?
                <Box sx={styles.loaderStyle}>
                    <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                </Box>
            :
                <Stack spacing={0.5} bgcolor={redColor[1]} p={0.5} borderRadius={2}>
                    {contrainte && 
                        <>
                            <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold">{contrainte?.message}</TextContainer>
                            <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold">{contrainte?.libelle}</TextContainer>
                        </>
                    }
                </Stack>  
            }
        </DialogContainer>
    );
}

ContrainteDialog.defaultProps = {
    isLoading: false,
    open: false, 
    setOpen: () => {}, 
    contrainte: null,
    sendReponse: () => {}
}

ContrainteDialog.propTypes = {
    isLoading: PropTypes.bool,
    open: PropTypes.bool, 
    setOpen: PropTypes.func, 
    contrainte: PropTypes.object,
    sendReponse: PropTypes.func
}

const styles = {
    loaderStyle: {
        height: 100,
        alignItems: "center",
        display: "flex"
    },
}

export default ContrainteDialog;