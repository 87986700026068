import { Box, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { TextContainer } from "@components/Containers";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const Condition = ({ 
    isLoading = true,
    messageFisrtCondition = "", 
    messageSecondCondition = ""
}) => {  
    const { t } = useTranslation("global");

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    return (
        <Stack spacing={0.5} bgcolor={redColor[5]} p={0.5} borderRadius={2}>
            <TextContainer bgColor={whiteColor[7]} p={1} textWeight="bold">{t("texts.accept_condition_question")}</TextContainer>
            {(messageFisrtCondition || messageSecondCondition) &&  
                <TextContainer bgColor={whiteColor[7]} p={1}>{messageFisrtCondition} {messageSecondCondition}</TextContainer>
            }
        </Stack>
    );
}

Condition.propTypes = {
    isLoading: PropTypes.bool,
    messageFisrtCondition: PropTypes.string, 
    messageSecondCondition: PropTypes.string
}

const styles = {
    loaderStyle: {
        height: 100,
        alignItems: "center",
        display: "flex"
    }
}

export default Condition;