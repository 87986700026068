import { useSelector } from "react-redux";
import { Box, Button, Grow, Skeleton, Stack, Typography } from "@mui/material";
import { ArrowRight, Refresh } from "@mui/icons-material";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { selectEntSecMan } from "@selectors";
import { useSecEntMan } from "@hooks/gestionEntretiens/hooksQueries";
import { IconButtonBase } from "@components/Buttons";
import { ErrorBase } from "@components/Errors";
import { Loader } from "@components/Loaders";
import { blueColor, getGreyColor, greenColor, greyColor, redColor, whiteColor } from "@styles";

const SectionsList = ({ 
    open = false, 
    goSection = () => {}
}) => {
    const stateSections = useSelector(selectEntSecMan);
    const { isLoading, isFetching, isError, refetch } = useSecEntMan(open);

    if(isLoading || isFetching) {
        return (
            <>
                <Box borderRadius={2} bgcolor={greenColor["05"]} height={50} p={0.5}>
                    <Skeleton variant="rounded" animation="wave" width="100%" height="100%" sx={{borderRadius: 2}} />
                </Box>
                <Box justifyContent="center" display="flex" alignItems="center" height="calc(100vh - 140px)" p={2}>
                    <Box bgcolor={whiteColor[7]} borderRadius={2} width={50} height={50} justifyContent="center" display="flex" alignItems="center">
                        <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                    </Box>
                </Box>
            </>
        );
    }

    if(isError) {
        return (
            <>
                <Box borderRadius={2} bgcolor={redColor[5]} height={50} p={0.5}>
                    <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[5]} height="100%" alignItems="center" px={1}>
                        <Box flexGrow={1}>
                            <Typography fontSize="small" display="inline" fontWeight="bold">Une erreur est survenue lors de la récupération des données</Typography>
                        </Box>
                        <Box>
                            <IconButtonBase icon={<Refresh fontSize="small" />} title="Rafraichir" action={() => refetch()} />
                        </Box>
                    </Stack>
                </Box>
                <ErrorBase>Erreur lors de la récupération des données</ErrorBase>
            </>
        );
    }

    return (
        <>
            <Box borderRadius={2} bgcolor={greenColor["05"]} height={50} p={0.5}>
                <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[7]} height="100%" alignItems="center" px={2}>
                    <Typography fontSize="small" display="inline" fontWeight="bold">Sections</Typography>
                </Stack>
            </Box>
            <PerfectScrollbar>
                <Grow in={true} timeout={300}>
                    <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                        <Stack spacing={0.5}>
                            {stateSections?.sections?.map((section, id) => (
                                section?.sectionTitre ?
                                    <Stack key={`SEC${id}`} direction="row" borderRadius={2} bgcolor={(section?.sectionComplete && !section.sectionTitre) ? blueColor["05"] : whiteColor[7]} height={50} alignItems="center" px={2}>
                                        <Box flexGrow={1}>
                                            <Typography fontSize="small" fontWeight={section?.sectionTitre ? "bold" : undefined}>{section?.designationSection1}</Typography>
                                            <Typography fontSize="small" fontWeight={section?.sectionTitre ? "bold" : undefined}>{section?.designationSection2}</Typography>
                                        </Box>
                                    </Stack>
                                :
                                    <Button key={`SEC${id}`} sx={{ color: getGreyColor("0.7"), bgcolor: whiteColor[7], height: 50, borderRadius: 2, "&:hover": { color: whiteColor[9], backgroundColor: greenColor["07"], boxShadow: 2 }}} onClick={() => goSection(2, section)}>
                                        <Stack direction="row" alignItems="center" px={1} height={"100%"} width={"100%"}>
                                            <Box flexGrow={1}>
                                                <Typography fontSize="small" fontWeight={section?.sectionTitre ? "bold" : undefined} sx={{flexGrow: 1, textAlign: "left", textTransform: "none"}}>{section?.designationSection1}</Typography>
                                                <Typography fontSize="small" fontWeight={section?.sectionTitre ? "bold" : undefined} sx={{flexGrow: 1, textAlign: "left", textTransform: "none"}}>{section?.designationSection2}</Typography>
                                            </Box>
                                            {section?.sectionComplete && !section.sectionTitre &&
                                                <Box bgcolor={greenColor["025"]} borderRadius={2} px={1}>
                                                    <Typography fontSize="small" fontWeight="bold" sx={{textAlign: "left", textTransform: "none"}}>Complète</Typography>
                                                </Box>
                                            }
                                            <Box alignItems="center" display="flex">
                                                <ArrowRight fontSize="small" />
                                            </Box>
                                        </Stack>   
                                    </Button>     
                            ))}      
                        </Stack>  
                    </Box>    
                </Grow>
            </PerfectScrollbar>  
        </>
    )
}

SectionsList.propTypes = {
    open: PropTypes.bool,
    goSection: PropTypes.func
}

export default SectionsList;