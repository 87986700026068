import { ButtonApp } from "@styles";
import PropTypes from "prop-types";

const VARIANTS = /** @type {const} */([
    "green25Grey5Green7",
    "green5White9Green7",
    "green7White9Green9",
    "red2Grey5Red5",
    "white2Grey5White9",
    "white5Grey5White9",
    "white5Grey5Green7",
    "white7Grey9White9",
    "yellow5Grey9Yellow7",
    "blue2Grey5Blue5",
    "primary",
    "secondary",
    "sideBarPrimary",
    "sideBarSecondary",
    "sideBarError",
    "sideBarNoBackgroundPrimary",
    "sideBarNoBackgroundSecondary",
    "sideBarNoBackgroundError"
]);

const SIZES = /** @type {const} */([
    "large", 
    "medium", 
    "small"
]);

const ALIGNEMENTS = /** @type {const} */([
    "left", 
    "right", 
    "center"
]);

const BORDERS = /** @type {const} */([
    "left", 
    "right", 
    "all",
    "none"
]);

/**
 * Fonction onClick
 * 
 * @function action
 * 
 */

/**
 * IconButtonBase properties
 * 
 * @typedef {object} IconButtonProps
 * @property {React.ReactElement} children - Icône du bouton
 * @property {React.ReactElement} startIcon - Icône du bouton
 * @property {React.ReactElement} endIcon - Icône du bouton
 * @property {typeof VARIANTS[number]} variant - Variante de couleur
 * @property {typeof SIZES[number]} size - Taille du bouton
 * @property {boolean} disabled - Désactiver le bouton
 * @property {boolean} fullWidth - Taille du bouton en width = 100%
 * @property {typeof ALIGNEMENTS[number]} alignement - Emplacement de l'icône de début et du texte
 * @property {number} height - Hauteur minimum du bouton
 * @property {typeof BORDERS[number]} borders - Emplacement des angle de bordure
 * @property {action} action - Action onClick
 */

/** 
 * IconButtonBase component
 * 
 * @type {React.FC<IconButtonProps>} props
 * @returns {React.ReactElement} Retour du bouton
 */
const BaseButton = ({
    children = <></>,
    startIcon = null, 
    endIcon = null,
    variant = undefined,
    size = "small",
    disabled = false, 
    fullWidth = false,
    alignement = "center",
    height = 40,
    borders = "all",
    action = () => {}
}) => {
    return (  
        <ButtonApp 
            startIcon={startIcon} 
            endIcon={endIcon} 
            size={size} 
            variantApp={variant} 
            disabled={disabled} 
            fullWidth={fullWidth}
            alignement={alignement}
            height={height}
            borders={borders}
            onClick={action}
        >
            { children }
        </ButtonApp>
    );
}

BaseButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]), 
    startIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool, PropTypes.string, PropTypes.array]), 
    endIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool, PropTypes.string, PropTypes.array]), 
    variant: PropTypes.oneOf([
        "green25Grey5Green7",
        "green5White9Green7",
        "green7White9Green9",
        "red2Grey5Red5",
        "white2Grey5White9",
        "white5Grey5White9",
        "white5Grey5Green7",
        "white7Grey9White9",
        "yellow5Grey9Yellow7",
        "blue2Grey5Blue5",
        "primary",
        "secondary",
        "sideBarPrimary",
        "sideBarSecondary",
        "sideBarError",
        "sideBarNoBackgroundPrimary",
        "sideBarNoBackgroundSecondary",
        "sideBarNoBackgroundError"
    ]), 
    size: PropTypes.oneOf([
        "large", 
        "medium", 
        "small"
    ]), 
    disabled: PropTypes.bool, 
    fullWidth: PropTypes.bool, 
    alignement: PropTypes.oneOf([
        "left", 
        "right", 
        "center"
    ]), 
    height: PropTypes.number,
    borders: PropTypes.oneOf([
        "left", 
        "right", 
        "all",
        "none"
    ]),
    action: PropTypes.func
}

export default BaseButton;