import axios from "axios";
import { urls } from "@constants/appConstants";

export const fetchCreGesTem = async (utilisateur, matricule, droitSalarie, droitInterimaire, date, badge, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetCredits"
    };
    const body = {
        utilisateur: utilisateur,
        matriculeHierarchique: matricule,
        droitSalarie: droitSalarie,
        droitInterimaire: droitInterimaire,
        date: date,
        badge: badge
    };
    const response = await axios.post(urls.getCredits, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.credits;
}
