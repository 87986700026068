import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    client: "",
    valide: false
};

const cltAppSlice = createSlice({
    name: "cltApp",
    initialState: initialState,
    reducers: {
        setClient: (state, action) => {
            state.client = action.payload;
        }
    }
})

const { actions, reducer } = cltAppSlice
export const { setClient } = actions
export default reducer