import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import moment from "moment";
import { fetchSta, editSta } from "@api/statut";
import { selectCltApp, selectStaUsr, selectUsrApp } from "@selectors";
import * as staUsrActions from "@reducers/statut/staUsrReducer";

export const useStaUsrApp = (type) => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const matricule = stateUser.matricule;
    const dispatch = useDispatch();

    const query =  useQuery(['staUsrApp', matricule], ({ signal }) => fetchSta(matricule, type, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!matricule
    });

    useEffect(() => {
        if(query.data) {
            dispatch(staUsrActions.setStatut(query.data));
        }
    });

    return query;
}

export const useStaPoi = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const stateStatut = useSelector(selectStaUsr);
    const { isLoading, isError, mutateAsync } = useMutation(editSta);
    const { enqueueSnackbar } = useSnackbar();

    const pointer = async () => {
        const data = {
            type: "P",
            date: moment().format("DDMMYYYY"),
            heure: moment().format("HHmmss"),
            activite: "",
            option: "ED",
            matricule: stateUser.matricule,
            client: stateClient.client
        }
        try {
            await mutateAsync(data);
            enqueueSnackbar("Mouvement envoyé avec succès", { variant: "success" });
        } catch(e) {
            if(e?.response?.data?.details) {
                enqueueSnackbar(e?.response?.data?.details, { variant: "error" });
            } else {
                enqueueSnackbar("Une erreur est survenue lors l'envoi du mouvement", { variant: "error" });
            }
        }
    };

    const pointerActivites = async (activite) => {
        const data = {
            type: "P",
            date: moment().format("DDMMYYYY"),
            heure: moment().format("HHmmss"),
            activite: activite,
            option: stateStatut.statut.actionSuivante === "D" ? "AC" : "ED",
            matricule: stateUser.matricule,
            client: stateClient.client
        }
        try {
            await mutateAsync(data);
            enqueueSnackbar("Mouvement envoyé avec succès", { variant: "success" });
        } catch(e) {
            if(e?.response?.data?.details) {
                enqueueSnackbar(e?.response?.data?.details, { variant: "error" });
            } else {
                enqueueSnackbar("Une erreur est survenue lors l'envoi du mouvement", { variant: "error" });
            }
        }
    };


    return { isLoading, isError, pointer, pointerActivites };
}
