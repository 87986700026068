import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

const initialState = {
    date: moment().format("MMYYYY"),
    hauteur: 289
}

const calGesAbsSlice = createSlice({
    name: 'calGesAbs',
    initialState: initialState,
    reducers: {
        setDate: (state, action) => {
            state.date = action.payload
        }
    },
})

const { actions, reducer } = calGesAbsSlice
export const { setDate } = actions
export default reducer