import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Grid, IconButton, Stack } from "@mui/material";
import { Check, Close, FilterListOff } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { filter, map, select, tidy } from "@tidyjs/tidy";
import moment from "moment";
import * as colPlaGesTemActions from "@reducers/collaborateurs/colPlaGesTemReducer";
import { useStateCollaborateurs } from "@hooks/collaborateurs/hooksStates";
import { filtrerFwgtp3 } from "../utils";
import { TYPES_COLLABORATEURS } from "@constants/collaborateursConstants";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";
import { HorairesHookFormInput } from "@components/Inputs/Horaires";
import { SocietesHookFormInput } from "@components/Inputs/Societes";
import { EtablissementsHookFormInput } from "@components/Inputs/Etablissements";
import { DirectionsHookFormInput } from "@components/Inputs/Directions";
import { ServicesHookFormInput } from "@components/Inputs/Services";
import { SecteursHookFormInput } from "@components/Inputs/Secteurs";
import { ActivitesHookFormInput } from "@components/Inputs/Activites";
import { CategoriesProfessionnellesHookFormInput } from "@components/Inputs/CategoriesProfessionnelles";
import { AutocompleteInput } from "@components/Inputs";
import { MenuFiltreContainerMobile } from "@components/Containers";

const defaultValues = {
    matricule: null,
    nom: null,
    badge: null,
    societe: null,
    etablissement: "",
    direction: "",
    service: "",
    secteur: "",
    categorieProfessionnelle: "",
    horaire: "",
    activite: "",
    controleHoraire: null,
};

const optionsBooleens = [
    {id: 1, value: "O", label: "Oui"},
    {id: 2, value: "N", label: "Non"}
];

const CollaborateursFwgtp3Menu = (props) => {
    const { type, open, close } = props;
    const dispatch = useDispatch();
    const methods = useForm({ mode: "all", defaultValues: defaultValues });
    const stateCollaborateurs = useStateCollaborateurs(type);
    const [noms, setNoms] = useState([]);
    const [matricules, setMatricules] = useState([]);
    const [badges, setBadges] = useState([]);

    const handleSocietes = useCallback((d) => {
        methods.resetField("etablissement", "");
        methods.resetField("direction", "");
        methods.resetField("service", "");
        methods.resetField("activite", "");
        methods.resetField("horaire", "");
    }, [methods]);

    useEffect(() => {
        if(stateCollaborateurs.collaborateurs) {
            const nomsOptions = tidy(stateCollaborateurs.collaborateurs, select("prenomNom"), map((d, id) => ({id: id + 1, value: d.prenomNom, label: d.prenomNom})), filter(d => d.prenomNom !== ""));
            const matriculesOptions = tidy(stateCollaborateurs.collaborateurs, select("matricule"), map((d, id) => ({id: id + 1, value: d.matricule, label: d.matricule})), filter(d => d.matricule !== ""));
            const badgesOptions = tidy(stateCollaborateurs.collaborateurs, select("badge"), map((d, id) => ({id: id + 1, value: d.badge, label: d.badge})), filter(d => d.badge !== ""));
            setNoms(nomsOptions);
            setMatricules(matriculesOptions);
            setBadges(badgesOptions);
        }
    }, [stateCollaborateurs.collaborateurs]);

    const filtrer = useCallback((data) => {
        const collaborateursBrut = stateCollaborateurs.collaborateurs;
        const collaborateursFiltered = filtrerFwgtp3(data, collaborateursBrut);
        if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_PLANNING) {            
            dispatch(colPlaGesTemActions.filtrer(collaborateursFiltered));
        }
    }, [dispatch, stateCollaborateurs.collaborateurs, type]);

    const resetFiltre = useCallback(() => {
        methods.reset();
        if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_PLANNING) {
            dispatch(colPlaGesTemActions.resetFiltre());
        }
    }, [dispatch, methods, type])

    return(
        <MenuFiltreContainerMobile open={open} close={close}>
            <FormProvider {...methods}>
                <Box overflow="auto">
                    <Grid container spacing={0.5} pb={0.5}>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteInput name="nom" label="Nom" options={noms} labelized />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteInput name="matricule" label="Matricule" options={matricules} labelized />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteInput name="badge" label="Badge" options={badges} labelized />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteInput name="controleHoraire" label="Contrôle Horaire ?" options={optionsBooleens} labelized />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <SocietesHookFormInput action={handleSocietes} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <EtablissementsHookFormInput codeSociete={methods.watch("societe")?.codeSociete} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <DirectionsHookFormInput codeSociete={methods.watch("societe")?.codeSociete} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <ServicesHookFormInput codeSociete={methods.watch("societe")?.codeSociete} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <SecteursHookFormInput />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <ActivitesHookFormInput codeSociete={methods.watch("societe")?.codeSociete} />                            
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <CategoriesProfessionnellesHookFormInput />                      
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <HorairesHookFormInput name="horaire" label="Horaire" codeSociete={methods.watch("societe")?.codeSociete} date={stateCollaborateurs.dateValidite ? moment(stateCollaborateurs.dateValidite, "DDMMYYYY") : ""} />
                        </Grid>
                    </Grid>
                </Box>    
                <Box>
                    <Stack spacing={0.5} direction="row" borderRadius={2} width={"100%"} height={"100%"} display="flex" alignItems="center" justifyContent="center">
                        <IconButton sx={styles.buttonStyleGrey} onClick={() => resetFiltre()}>
                            <FilterListOff fontSize="small" />
                        </IconButton>
                        <IconButton sx={styles.buttonStyleGreen} onClick={() => methods.handleSubmit(filtrer)()}>
                            <Check fontSize="small" />
                        </IconButton>
                        <IconButton sx={styles.buttonStyleRed} onClick={() => close()}>
                            <Close fontSize="small" />
                        </IconButton>
                    </Stack>
                </Box>    
            </FormProvider>
        </MenuFiltreContainerMobile>
    );
}

CollaborateursFwgtp3Menu.propTypes = {
    type: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
}

const styles = {
    buttonStyleGrey: {
        boxShadow: "none", 
        backgroundColor: greyColor[0],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: greyColor[2],
            color: whiteColor[9],
        }
    },
    buttonStyleGreen: {
        boxShadow: "none", 
        backgroundColor: greenColor["02"],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: greenColor["03"],
            color: whiteColor[9],
        }
    },
    buttonStyleRed: {
        boxShadow: "none", 
        backgroundColor: redColor[1],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: redColor[2],
            color: whiteColor[9],
        }
    }
}

export default CollaborateursFwgtp3Menu;