import { Box, Stack, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Add, ArrowLeft } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useMappingActionsCol } from "@hooks/gestionEntretiens/hooksUtils";
import * as entSecColActions from "@reducers/gestionEntretiens/entSecColReducer";
import { IconButtonBase } from "@components/Buttons";
import { greenColor, whiteColor } from "@styles";
import { AjoutSectionForm } from "../Forms";
import { selectEntSecCol } from "@selectors";
import CommentaireContent from "../Content/CommentaireContent";

const EmptyList = ({ 
    open = false, 
    accessibles = false, 
    goBack = () => {},
}) => {
    const stateSections = useSelector(selectEntSecCol);
    const sectionSelected = stateSections?.sectionSelected;
    const { designation1, designation2 } = useMappingActionsCol()
    const dispatch = useDispatch()

    if(accessibles && stateSections.modeAjout) {
        return (<AjoutSectionForm goBack={goBack} />);
    }

    return (
        <>
            <Box borderRadius={2} bgcolor={greenColor["05"]} height={50} p={0.5}>
                <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[7]} height="100%" alignItems="center" px={1}>
                    <IconButtonBase icon={<ArrowLeft fontSize="small" />} title="Retour" action={() => goBack()} />
                    <Box flexGrow={1}>
                        <Typography fontSize="small" fontWeight="bold">{designation1}</Typography>
                        <Typography fontSize="small" fontWeight="bold">{designation2}</Typography>
                    </Box>
                    {(accessibles && sectionSelected?.ajoutSousSectionCollaborateur) && 
                        <IconButtonBase 
                            icon={<Add fontSize="small" />} 
                            title="Ajouter une sous section" 
                            action={() => dispatch(entSecColActions.setModeAjout(true))} 
                        />
                    }
                </Stack>
            </Box>
            <Box>
                <CommentaireContent open={open} codeQuestion={0} />
            </Box>
        </>
    );
}

EmptyList.propTypes = {
    open: PropTypes.bool,
    accessibles: PropTypes.bool,
    goBack: PropTypes.func
}

export default EmptyList;