import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Badge, Factory, Person } from "@mui/icons-material";
import PropTypes from "prop-types";
import * as colPlaGesTemActions from "@reducers/collaborateurs/colPlaGesTemReducer";
import { useStateCollaborateurs } from "@hooks/collaborateurs/hooksStates";
import { MenuTrieContainer } from "@components/Containers";
import { trierFwgtp3 } from "../utils";
import { TYPES_COLLABORATEURS } from "@constants/collaborateursConstants";

const optionsTrierFwgtp3 = [
    {id: 1, label: "Par nom", keyAsc: "nomAsc", keyDesc: "nomDesc", icon: <Person fontSize="small" />},
    {id: 2, label: "Par matricule", keyAsc: "matriculeAsc", keyDesc: "matriculeDesc", icon: <Person fontSize="small" />},
    {id: 3, label: "Par badge", keyAsc: "badgeAsc", keyDesc: "badgeDesc", icon: <Badge fontSize="small" />},
    {id: 4, label: "Par type de salarié", keyAsc: "typePersonnelAsc", keyDesc: "typePersonnelDesc", icon: <Factory fontSize="small" />},
];

const CollaborateursFwgtp3Trie = (props) => {
    const { stateType, anchorEl, setAnchorEl } = props;
    const stateCollaborateurs = useStateCollaborateurs(stateType);
    const dispatch = useDispatch();

    const trier = useCallback((keyTrie) => {
        const data = [...stateCollaborateurs.collaborateurs];
        const dataTried = trierFwgtp3(keyTrie, data);
        if(stateType === TYPES_COLLABORATEURS.GESTION_TEMPS_PLANNING) {    
            dispatch(colPlaGesTemActions.trier(dataTried));
        }
    }, [dispatch, stateCollaborateurs.collaborateurs, stateType]);

    return <MenuTrieContainer anchorEl={anchorEl} setAnchorEl={setAnchorEl} options={optionsTrierFwgtp3} trier={trier} />;
}

CollaborateursFwgtp3Trie.defaultProps = {
    anchorEl: null,
    setAnchorEl: () => {}
}

CollaborateursFwgtp3Trie.propTypes = {
    stateType: PropTypes.string,
    anchorEl: PropTypes.node,
    setAnchorEl: PropTypes.func
}

export default CollaborateursFwgtp3Trie;