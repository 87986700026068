import { useSelector, useDispatch } from "react-redux";
import { selectUsrApp } from "@selectors";
import * as userActions from "@reducers/user/usrAppReducer";
import { DialogContainer, HeaderDialogContainer } from "@components/Containers";
import { PolicesList } from "../Lists";
import { useTranslation } from "react-i18next";

const PolicesDialog = () => {
    const stateUser = useSelector(selectUsrApp);
    const dispatch = useDispatch();
    const { t } = useTranslation("global");

    return (
        <DialogContainer open={stateUser.openPolice} taille="sm">
            <HeaderDialogContainer 
                title={t("titles.edit_police")}
                close={() => dispatch(userActions.openPolice(false))}  
            />
            <PolicesList />
        </DialogContainer> 
    );
}

export default PolicesDialog;