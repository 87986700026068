import { validateDate } from "@validation";
import Joi from "joi";

export const schemaAnomalieBase = Joi.object({
    dateDebut: validateDate.dateMoment().valide().messages({ 
        "object.base": "Date obligatoire", 
        "dateMoment.valide": "Date invalide",
    }),
    dateFin: validateDate.dateMoment().valide().minDateDebut().messages({ 
        "object.base": "Date obligatoire", 
        "dateMoment.valide": "Date invalide",
        "dateMoment.minDateDebut": "La date de fin doit être supérieure ou égale à la date de début"
    }),
    societe: Joi.object().allow(null),
    etablissement: Joi.object().allow(null),
    direction: Joi.object().allow(null),
    service: Joi.object().allow(null),
    secteur: Joi.object().allow(null),
    typeMouvement: Joi.string().required(),
    anomalie: Joi.object().allow(null),
    gravite1: Joi.string().required(),
    gravite2: Joi.string().required(),
    anomalieAReactualiser: Joi.bool()
});

export const schemaAnomalieCredit = Joi.object({
    societe: Joi.object().allow(null).messages({ "object.base": "Vous devez choisir un horaire" }),
    etablissement: Joi.object().allow(null).messages({ "object.base": "Vous devez choisir un horaire" }),
    direction: Joi.object().allow(null).messages({ "object.base": "Vous devez choisir un horaire" }),
    service: Joi.object().allow(null).messages({ "object.base": "Vous devez choisir un horaire" }),
    secteur: Joi.object().allow(null).messages({ "object.base": "Vous devez choisir un horaire" }),
    debitCredit: Joi.object({
        debitMaximum: Joi.string().empty(""),
        creditMaximum: Joi.string().empty("")
    }).messages({ "object.missing": "Renseigner au moins un seuil débit/crédit" }).or("debitMaximum", "creditMaximum")
});

export const schemaAnomalieActivite = Joi.object({
    dateDebut: validateDate.dateMoment().valide().messages({ 
        "object.base": "Date obligatoire", 
        "dateMoment.valide": "Date invalide",
    }),
    dateFin: validateDate.dateMoment().valide().minDateDebut().messages({ 
        "object.base": "Date obligatoire", 
        "dateMoment.valide": "Date invalide",
        "dateMoment.minDateDebut": "La date de fin doit être supérieure ou égale à la date de début"
    }),
    societe: Joi.object().messages({ "object.base": "Vous devez choisir une société" }),
    etablissement: Joi.object().allow(null),
    direction: Joi.object().allow(null),
    service: Joi.object().allow(null),
    secteur: Joi.object().allow(null),
    activite: Joi.object().messages({ "object.base": "Vous devez choisir une activité" }),
    nombreHeuresDebut: Joi.string(),
    nombreHeuresFin: Joi.string()
});