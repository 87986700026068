import { Box, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { TextContainer } from "@components/Containers";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, whiteColor } from "@styles";
import { DialogError } from "@components/Errors";
import { TextDureeAbsenceContainer, TextMotifAbsenceContainer, TextNombreHeuresAbsenceContainer, TextNombreJoursAbsenceContainer } from "@components/Containers/GestionAbsences";
import { useTranslation } from "react-i18next";

const Recapitulatif = ({ 
    isLoading = true,
    isError = false,
    errorMessage = "", 
    data = null, 
    fichier = null
}) => {
    const { t } = useTranslation("global");

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return <DialogError error={errorMessage} />;
    }

    return (
        <Stack spacing={0.5} bgcolor={greenColor["07"]} p={0.5} borderRadius={2}>
            <TextContainer bgColor={whiteColor[7]} horizontalAlign="center" p={1} textWeight="bold">{t("titles.request_resume")}</TextContainer>
            <TextMotifAbsenceContainer libelleAbsence={data?.libelleAbsence} />
            <Stack spacing={0.5} bgcolor={whiteColor[7]} borderRadius={2} p={1}>
                <TextDureeAbsenceContainer dateDebut={data?.dateDebut} dateReprise={data?.dateReprise} nature={data?.nature} nombreJours={data?.nombreJours} />
                <TextNombreJoursAbsenceContainer nombreJours={data?.nombreJours} demiJournee={data?.demiJournee} />
                <TextNombreHeuresAbsenceContainer heureDebut={data?.heureDebut} heureReprise={data?.heureReprise} formatNombreHeures="string" nombreJours={data?.nombreJours} nombreHeures={data?.nombreHeures} />
            </Stack>
            {data?.motifDemande && 
                <TextContainer bgColor={whiteColor[5]} p={1}>{t("texts.request_reason")} : {data?.motifDemande}</TextContainer>
            }
            {data?.conditionFirst && 
                <TextContainer bgColor={whiteColor[5]} p={1}>{t("texts.condition_accepted")} : {data?.conditionFirst} {data?.conditionSecond}</TextContainer>
            }
            {fichier &&
                <TextContainer bgColor={whiteColor[5]} p={1}>{t("titles.attachment")} : {fichier?.[0]?.name}</TextContainer>
            }
        </Stack>
    );
}

Recapitulatif.propTypes = {
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool, 
    errorMessage: PropTypes.string,
    data: PropTypes.object, 
    fichier: PropTypes.object
}

const styles = {
    loaderStyle: {
        height: 100,
        alignItems: "center",
        display: "flex"
    }
};

export default Recapitulatif;