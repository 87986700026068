import { useCallback } from "react";
import { Menu, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { MenuTrieButton } from "@components/Buttons";

const MenuTrieContainer = (props) => {
    const { anchorEl, setAnchorEl, options, trier } = props;
    const open = Boolean(anchorEl);

    const handleTrie = useCallback((keyTrie) => {
        trier(keyTrie);
        setAnchorEl(null);
    }, [setAnchorEl, trier]);
    
    return (
        <Menu anchorEl={anchorEl} open={open} elevation={2} onClose={() => setAnchorEl(null)} slotProps={{ paper: styles.paperMenuStyle }} MenuListProps={styles.menuListStyle}>
            <Stack spacing={0.5} padding={0.5}>
                {options.map((option) => (
                    <MenuTrieButton key={`MTR${option.id}`} option={option} trier={handleTrie} />
                ))}
            </Stack>
        </Menu>
    );
}

MenuTrieContainer.defaultProps = {
    anchorEl: null,
    setAnchorEl: () => {},
    options: [],
    trier: () => {}
}

MenuTrieContainer.propTypes = {
    anchorEl: PropTypes.instanceOf(Element),
    setAnchorEl: PropTypes.func,
    options: PropTypes.array,
    trier: PropTypes.func
}

const styles = {
    paperMenuStyle: {
        sx: {
            borderRadius: 2
        }
    }, 
    menuListStyle: {
        sx: {
            padding: 0
        }
    }
}

export default MenuTrieContainer;