export const TYPES_COLLABORATEURS = {
    GESTION_TEMPS_TEMPS: "gestionTempsTemps",
    GESTION_TEMPS_PLANNING: "gestionTempsPlanning",
    AFFECTATIONS_HORAIRES_INDIVIDUELLES: "affectationsHorairesIndividuelles",
    AFFECTATIONS_HORAIRES_COLLECTIVES: "affectationsHorairesCollectives",
    AFFECTATIONS_ACTIVITES_INDIVIDUELLES: "affectationsActivitesIndividuelles",
    AFFECTATIONS_ACTIVITES_COLLECTIVES: "affectationsActivitesCollectives",
    GESTION_TEMPS_CREDIT: "credit",
    GESTION_TEMPS_MOUVEMENT: "mouvement",
    ENTRETIENS: "entretiens",
}

export const TYPES_FICHIER = {
    FWGTPE: "fwgtpe",
    FWGTP2: "fwgtp2",
    FWGTP3: "fwgtp3",
    FWDC01: "fwdc01",
    FWMV01: "fwmv01",
    FHPEE1: "fhpee1"
}
