import { useCallback } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { whiteColor } from "@styles";
import { TextContainer } from "@components/Containers";
import { SocietesHookFormInput } from "@components/Inputs/Societes";
import { useFormContext } from "react-hook-form";
import { EtablissementsHookFormInput } from "@components/Inputs/Etablissements";
import { DirectionsHookFormInput } from "@components/Inputs/Directions";
import { ServicesHookFormInput } from "@components/Inputs/Services";
import { SecteursHookFormInput } from "@components/Inputs/Secteurs";
import { HorairesHookFormInput } from "@components/Inputs/Horaires";
import { EmploisHookFormInput } from "@components/Inputs/Emplois";
import { CheckHookFormInput, DateHookFormInput, SelectHookFormInput, TimeMaskedHookFormInput } from "@components/Inputs";

const joursSemaine = [
    {id: 1, value: "0", label: "Tous"},
    {id: 2, value: "1", label: "Lundi"},
    {id: 3, value: "2", label: "Mardi"},
    {id: 4, value: "3", label: "Mercredi"},
    {id: 5, value: "4", label: "Jeudi"},
    {id: 6, value: "5", label: "Vendredi"},
    {id: 7, value: "6", label: "Samedi"},
    {id: 8, value: "7", label: "Dimanche"},
]

const evenements = [
    {id: 1, value: "1", label: "Entrée avant"},
    {id: 2, value: "2", label: "Entrée après"},
    {id: 3, value: "3", label: "Sortie avant"},
    {id: 4, value: "4", label: "Sortie après"},
    {id: 5, value: "5", label: "Arrivée en avance"},
    {id: 6, value: "6", label: "Arrivée en retard"},
    {id: 7, value: "7", label: "Départ en avance"},
    {id: 8, value: "8", label: "Départ en retard"},
]

const evenementsLabel = {
    1: "Entrée avant",
    2: "Entrée après",
    3: "Sortie avant",
    4: "Sortie après",
    5: "Arrivée en avance",
    6: "Arrivée en retard",
    7: "Départ en avance",
    8: "Départ en retard",
}

const MouvementsCollectifsForm = () => {
    const { watch, resetField } = useFormContext();
    const societeSelected = watch("societe");
    const evt = watch("evenement");
    const dateDebut = watch("dateDebut");
    const dateFin = watch("dateFin");

    const reset = useCallback(() => {
        resetField("etablissement");
        resetField("direction");
        resetField("service");
        resetField("horaire");
    }, [resetField]);

    return (
        <Stack spacing={1}>
            <Stack spacing={1}>
                <TextContainer p={1.25} bgColor={whiteColor[5]} horizontalAlign="center" textWeight="bold" textSize={15}>Période d'analyse</TextContainer>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <DateHookFormInput name="dateDebut" label="Date de début" displayError />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateHookFormInput name="dateFin" label="Date de fin" displayError />
                        </Grid>
                    </Grid>
                </Box>
                <TextContainer p={1.25} bgColor={whiteColor[5]} horizontalAlign="center" textWeight="bold" textSize={15}>Critères de sélection</TextContainer>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <SocietesHookFormInput action={reset} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <EtablissementsHookFormInput codeSociete={societeSelected?.codeSociete} />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <DirectionsHookFormInput codeSociete={societeSelected?.codeSociete} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ServicesHookFormInput codeSociete={societeSelected?.codeSociete} />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <SecteursHookFormInput />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <EmploisHookFormInput dateDebut={dateDebut ? dateDebut.format("YYYYMMDD") : ""} dateFin={dateFin ? dateFin.format("YYYYMMDD") : ""} />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <HorairesHookFormInput codeSociete={societeSelected?.codeSociete} date={dateFin} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectHookFormInput name="jour" label="Jour de la semaine" options={joursSemaine} />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <SelectHookFormInput name="evenement" label="Evenement" options={evenements} /> 
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TimeMaskedHookFormInput name="heureLimite" label={evenementsLabel[evt] ? evenementsLabel[evt] : "Heure événement"} displayError displayBgColorError={false} />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <Box borderRadius={2} bgcolor={whiteColor[5]} height={40} display="flex" alignItems="center" px={2}>
                                <CheckHookFormInput name="detail" label="Détail des mouvements" />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
        </Stack>
    );
}

export default MouvementsCollectifsForm;