import { filter, tidy } from "@tidyjs/tidy";
import moment from "moment";

export const filtrerAffActInd = (data, dataBrut) => {
    switch(data.colonne) {
        case "date":
            switch(data.operateur) {
                case "egale":
                    return tidy(dataBrut, filter(affectation => moment([affectation.anneeEffet, affectation.moisEffet - 1, affectation.jourEffet]).isSame(data.date)));
                case "superieur":
                    return tidy(dataBrut, filter(affectation => moment([affectation.anneeEffet, affectation.moisEffet - 1, affectation.jourEffet]).isSameOrAfter(data.date)));
                case "inferieur":
                    return tidy(dataBrut, filter(affectation => moment([affectation.anneeEffet, affectation.moisEffet - 1, affectation.jourEffet]).isSameOrBefore(data.date)));
                default: 
                    return dataBrut
            }
        case "activite":
            return  tidy(dataBrut, filter(affectation => affectation.codeActivite === data.txt));
        default:
            return dataBrut
    }
};