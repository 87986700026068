import { forwardRef } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { SnackbarContent } from "notistack";
import { Info } from "@mui/icons-material";

const SnackbarWarning = forwardRef((props, ref) => {
    const { message } = props

    return (
        <SnackbarContent ref={ref} role="alert" sx={styles.snackbarContentStyle}>
            <Stack spacing={1} direction="row" padding={2} alignItems="center" backgroundColor="rgba(232,161,7,1)" width="100%" borderRadius={2}>
                <Box display="flex">
                    <Info fontSize="small" sx={styles.iconStyle} />
                </Box> 
                <Box>
                    <Typography fontSize="small" color="white">{message}</Typography>
                </Box>
            </Stack>
        </SnackbarContent>
    )
});

const styles = {
    snackbarContentStyle: {
        backgroundColor: "rgba(255,255,255,0.7)", 
        borderRadius: 2
    },
    iconStyle: {
        color: "white"
    }
}

export default SnackbarWarning;