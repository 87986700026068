import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { selectDsh } from "@selectors";
import { IconButtonBase } from "@components/Buttons";
import { getShortcutRemoved } from "@utils";
import * as dshActions from "@reducers/dashboard/dshReducer"
import { redColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const PointageShortcutConfigurationDelete = () => {
    const stateDashboard = useSelector(selectDsh);
    const dispatch = useDispatch();
    const { t } = useTranslation("global");

    const remove = useCallback(() => {
        const updateState = getShortcutRemoved("sPointage", stateDashboard.shortcutsActives, stateDashboard.shortcutsNonActives);
        dispatch(dshActions.editShortcut(updateState));
    }, [dispatch, stateDashboard.shortcutsActives, stateDashboard.shortcutsNonActives]);

    return (
        <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[5]} px={1} alignItems="center" height={40}>
            <Box>
                <Typography fontSize="small" fontWeight="bold">{t("texts.shortcuts_movement")}</Typography>
            </Box>
            <Box>
                <IconButtonBase 
                    variant="listButton"
                    icon={<Close sx={{color: redColor[5], fontSize: 17}} />} 
                    title={t("buttons.delete")} 
                    action={remove} 
                />
            </Box> 
        </Stack>
    );
}

export default PointageShortcutConfigurationDelete;