import { useSelector } from "react-redux";
import { selectTypTraApp } from "@selectors";
import PropTypes from 'prop-types';
import { Autocomplete, Box, Grid, List, Paper, TextField, Typography, autocompleteClasses } from "@mui/material";
import { useController, useFormContext, useFormState } from "react-hook-form";
import { useCallback, useState } from "react";
import { TextContainer } from "@components/Containers";
import { greenColor, greyColor } from "@styles";

const headerOptionsTypeTravail = [
    {id: 1, title: "Code", xs: 3, key: "codeArgument"},
    {id: 2, title: "Désignation", xs: 9, key: "designation"}
];

const OptionsComponent = (props, option) => {
    return (
        <Box key={option.id} component="li" {...props} sx={styles.optionsStyle} borderRadius={2} mb={0.5}>
            <Grid container spacing={0.5}>
                {headerOptionsTypeTravail.map((headerOption, id) => (
                    <Grid item xs={headerOption.xs} key={`AUTOCOPTIONOPT${id}`} display="flex" justifyContent="center">
                        <TextContainer bgColor="none" p={0}>{option[headerOption.key]}</TextContainer>
                    </Grid>
                ))}
            </Grid>
        </Box> 
    );
}

const InputComponent = (props, name, label, errors) => {
    return (
        <TextField 
            {...props} 
            error={errors[name]} 
            label={label} 
            sx={{ 
                "& .MuiInputBase-root": { 
                    height: 32, 
                    "&.MuiOutlinedInput-root": { 
                        pt: 0.25 
                    } 
                } 
            }} 
            InputLabelProps={{ 
                sx: { 
                    top: "-5px", 
                    "&[data-shrink='true']": { 
                        top: 0 
                    } 
                } 
            }} 
        />
    );
}

const PaperComponent = (props) => {
    return (
        <Paper {...props} style={{minWidth: "fit-content"}}  />
    );
}

const ListboxComponent = (props) => {
    return (
        <Box px={0.5} py={0}>
            <Box py={0.5}>
                <Grid container spacing={0.5}>
                    {headerOptionsTypeTravail.map((headerOption) => (
                        <Grid item xs={headerOption.xs} key={`AUTOCOPTIONHEAD${headerOption.id}`}>
                            <TextContainer bgColor={greenColor["015"]} p={0.75} height={"100%"} textWeight="bold" horizontalAlign="center" verticalAlign="center" textAlign="center" textDecoration={"none"}>{headerOption.title}</TextContainer>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <List {...props} />
        </Box>
    );
}

const NoOptions = () => {
    return (
        <Typography fontSize={"small"}>Aucune donnée</Typography>
    );
}

const optionsMapping = (option, value) => {
    return option.codeArgument === value.codeArgument || value === ""
}

const labelsMapping = (option) => option.designation ? option.designation : "";

const TypesTravailPlanningInput = (props) => {
    const { name, label, disabled, required, onChange } = props;
    const stateTypesTravail = useSelector(selectTypTraApp);
    const { control } = useFormContext();
    const { field } = useController({ name: name, control, rules: { required: required } });
    const { errors } = useFormState({ control });
    const [open, setOpen] = useState(false);

    const handleChange = useCallback((d) => {
        onChange(d);
        field.onChange(d);
    }, [field, onChange]);

    return (
        <Autocomplete 
            {...field} 
            fullWidth 
            label={label} 
            size="small" 
            options={stateTypesTravail.typesTravail} 
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionLabel={labelsMapping}      
            isOptionEqualToValue={optionsMapping}
            noOptionsText={<NoOptions />}
            disabled={disabled}
            ListboxProps={{sx: { [`&.${autocompleteClasses.listbox}`]: {p: 0}}}}
            ListboxComponent={(props) => ListboxComponent(props)}
            PaperComponent={(props) => PaperComponent(props)}
            renderInput={(props) => InputComponent(props, name, label, errors)}
            renderOption={(props, option) => OptionsComponent(props, option)}
            onChange={(_, d) => handleChange(d)}
        />
    );
}

TypesTravailPlanningInput.defaultProps = {
    label: "",
    size: undefined,
    disabled: false,
    onChange: () => {}
}

TypesTravailPlanningInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    size: PropTypes.oneOf(["xsmall", "small"]),
    disabled: PropTypes.bool,
    onChange: PropTypes.func
}

const styles = {
    optionsStyle: {
        [`&.${autocompleteClasses.option}`]: {
            px: 0, 
            backgroundColor: greenColor["015"],
            '&:hover': {
                backgroundColor: greenColor["035"]
            },
            '&[aria-selected="true"]': {
                border: "1px solid",
                borderColor: greyColor[2],
                backgroundColor: greenColor["035"]
            } 
        }
    }
}

export default TypesTravailPlanningInput;