import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import { selectSolGesAbs, selectUsrApp } from "@selectors";
import * as solGesAbsActions from "@reducers/soldes/solGesAbsReducer";
import * as sitSolAppActions from "@reducers/soldes/sitSolAppReducer";
import { SituationSoldeDialog, SoldesActionsBar, SoldesList, SoldesText } from "@components/App/Soldes";
import { whiteColor } from "@styles";
import { useSolApp } from "@hooks/gestionAbsences/soldes/hooksQueries";
import { ErrorBase } from "@components/Errors";
import { useTranslation } from "react-i18next";

const Soldes = () => {
    const [openSituationSolde, setOpenSituationSolde] = useState(false);
    const stateSoldes = useSelector(selectSolGesAbs);
    const stateUser = useSelector(selectUsrApp);
    const { data, isLoading, isFetching, isError, error, refetch } = useSolApp(stateUser.societe, stateUser.matricule);
    const dispatch = useDispatch();
    const { t } = useTranslation("global");

    const openSituation = useCallback((solde) => {
        dispatch(sitSolAppActions.selectSolde({periode: stateSoldes.periode, solde: solde, matricule: stateUser.matricule}));
        setOpenSituationSolde(true);
    }, [dispatch, stateSoldes.periode, stateUser.matricule]);

    const lister = useCallback(() => {
        dispatch(solGesAbsActions.lister());
    }, [dispatch]);

    return (
        <>
            <Box bgcolor={whiteColor[9]} borderRadius={2} p={0.5} boxSizing="border-box" height="100%">
                <Stack spacing={0.5} height="100%">
                    {stateUser.societe ? 
                        <>
                            <SoldesActionsBar
                                isLoading={isLoading || isFetching}
                                isError={isError}
                                listed={stateSoldes.listed}
                                lister={lister}
                                refetch={refetch}
                            />
                            {!isError &&
                                <SoldesText 
                                    isLoading={isLoading || isFetching}
                                    periode={data?.periode}     
                                />
                            }
                            <Box height="100%">
                                <SoldesList 
                                    data={data?.soldes}
                                    isLoading={isLoading || isFetching}
                                    isError={isError}
                                    errorMessage={error?.message}
                                    listed={stateSoldes.listed}
                                    openSituation={openSituation}
                                />
                            </Box>
                        </>
                    :
                        <ErrorBase>{t("errors.generic_no_society")}</ErrorBase>
                    }
                </Stack>
            </Box>
            <SituationSoldeDialog 
                open={openSituationSolde} 
                setOpen={setOpenSituationSolde} 
            />
        </>
    )
}

export default Soldes;