import { useState } from "react";
import { Event } from "@mui/icons-material";
import { DatePicker, LocalizationProvider, frFR } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import PropTypes from "prop-types";
import IconDatePickerButton from "./IconDatePickerButton";

const MonthPickerButton = ({
    icon = <Event fontSize="small" />, 
    title = "", 
    placement = "bottom", 
    value = null, 
    noClick = false, 
    variant = undefined,
    action = () => {}
}) => {
    const [open, setOpen] = useState(false);

    return (  
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="frFR" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
            <DatePicker
                slots={{ field: IconDatePickerButton }}
                slotProps={{ field: { icon, title, placement, noClick, variant, setOpen } }}
                open={open}
                value={value}
                views={["year", "month"]}
                openTo="year"
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                onMonthChange={action}
            />
        </LocalizationProvider>
    );
}

MonthPickerButton.propTypes = {
    icon: PropTypes.element, 
    title: PropTypes.string, 
    placement: PropTypes.string, 
    value: PropTypes.object, 
    noClick: PropTypes.bool, 
    variant: PropTypes.string,
    action: PropTypes.func
}

export default MonthPickerButton;