import axios from "axios";
import moment from "moment";

export const fetchMto = async (coords, signal) => {
    const url = `${process.env.REACT_APP_API_URL}/forecast?lat=${coords.latitude}&lon=${coords.longitude}&units=metric&appid=${process.env.REACT_APP_API_KEY}`;
    
    const response = await axios.get(url, { signal: signal });

    const date = response.data.list.map((item) => {
        return moment(item.dt_txt, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
    });

    const meteo = date.filter((value, id, self) => { return self.indexOf(value) === id }).map((itemDate, id) => {
        const weather = response.data.list.filter((item) => {
            return moment(item.dt_txt, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") === itemDate;
        });
        return { id: id+1, date: itemDate, weather: weather };
    });

    const data = { city: response.data.city.name, meteo: meteo }

    return data;
}