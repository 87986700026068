import { Box, IconButton, Stack, TextField, Typography } from "@mui/material";
import { useCallback } from "react";
import { useController, useFormContext, useFormState } from "react-hook-form";
import { Close, FileUploadRounded } from "@mui/icons-material";
import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const styleInput = {
    "white": { bgColor: whiteColor[9], hoverBgColor: greenColor["02"] },
    "green": { bgColor: greenColor["015"], hoverBgColor: greenColor["025"] }
}

const FileHookFormInput = (props) => {
    const { name, label, color } = props;
    const { control, setValue } = useFormContext();    
    const { field } = useController({ name, control });
    const { errors } = useFormState({ control });
    const { t } = useTranslation("global");

    const remove = useCallback(() => {
        setValue(name, null);
    }, [name, setValue]);

    const handleFile = useCallback((event) => {
        field.onChange(event.target.files)
    }, [field]);

    return (
        <Stack sx={styles.stackInputStyle}>
            <Box sx={styles.boxInputStyle}>
                <Stack direction="row" spacing={1} sx={{
                        borderRadius: 2,
                        backgroundColor: styleInput[color].bgColor,
                    }} >  
                    <TextField 
                        fullWidth 
                        label={label}
                        size="small"
                        value={field?.value ? field?.value?.[0]?.name : ""} 
                        inputProps={{ readOnly: true }}  
                    /> 
                    <Box sx={styles.boxInputButtonStyle}>
                        <Stack direction="row" spacing={0}>
                            <Box>
                                <IconButton component="label">
                                    <FileUploadRounded fontSize="small" />
                                    <input {...field} value={field?.fileName} hidden accept="application/pdf" type="file" onChange={(e) => handleFile(e)} />
                                </IconButton> 
                            </Box>
                            {field?.value && <Box>
                                <IconButtonBase size="normal" icon={<Close fontSize="small" />} title="Enlever le fichier" placement="top" action={remove} />
                            </Box>}
                        </Stack>
                    </Box>
                </Stack>  
                
            </Box>
            {errors[name] && 
                <Box sx={styles.boxInputErrorStyle}>
                    <Typography sx={styles.typoInputErrorStyle}>{t(errors[name].message)}</Typography>
                </Box>
            }
        </Stack>
    );
}

FileHookFormInput.defaultProps = {
    label: "", 
    color: "white"
}

FileHookFormInput.propTypes = {
    name: PropTypes.string.isRequired, 
    label: PropTypes.string, 
    color: PropTypes.string
}

const styles = {
    stackInputStyle: {
        borderRadius: 2,
        backgroundColor: redColor[1]
    },
    boxInputStyle: {
        borderRadius: 2,
        backgroundColor: whiteColor[9]
    },
    boxInputErrorStyle: {
        textAlign: "left",
        borderBottomLeftRadius: 8, 
        borderBottomRightRadius: 8,
        padding: 1
    },
    typoInputErrorStyle: {
        fontSize: "small", 
        color: greyColor[5]
    },
    boxInputButtonStyle: {
        display: "flex",
        alignItems: "center", 
        justifyContent: "center",
        paddingRight: 0.5
    },
}

export default FileHookFormInput;