import { Box, Grid, Stack } from "@mui/material";
import { Restaurant } from "@mui/icons-material";
import PropTypes from "prop-types";
import { DialogContainer, HeaderDialogContainer, HeaderListContainer, ListContainer, TextContainer } from "@components/Containers";
import { chiffreUSVersChiffreFR, heurify } from "@utils";
import { useStateTemGesTem } from "@hooks/gestionTemps/temps/hooksStates";
import { greenColor, whiteColor } from "@styles";

const headers = [
    {id: 1, label: "", xs: 1.5, display: false},
    {id: 2, label: "Lu", xs: 1.5, display: true},
    {id: 3, label: "Ma", xs: 1.5, display: true},
    {id: 4, label: "Me", xs: 1.5, display: true},
    {id: 5, label: "Je", xs: 1.5, display: true},
    {id: 6, label: "Ve", xs: 1.5, display: true},
    {id: 7, label: "Sa", xs: 1.5, display: true},
    {id: 8, label: "Di", xs: 1.5, display: true},
];

const RepasDialog = (props) => {
    const { open, setOpen, userType } = props;
    const stateTemps = useStateTemGesTem(userType);

    return (
        <DialogContainer open={open} placement="top" taille="md">
            <HeaderDialogContainer 
                title="Repas de la semaine"
                close={() => setOpen(false)}
            />
            <Stack spacing={0.5}>
                <HeaderListContainer itemIdReference="REPASGT" headerItems={headers} />
                <ListContainer spacing={0.5} length={1}>
                    <Box bgcolor={greenColor["015"]} borderRadius={2} p={0.25}>
                        <Grid container spacing={0.5}>
                            <Grid item xs={1.5}>
                                <Box sx={styles.boxIconStyle}>
                                    <Restaurant sx={{fontSize: 15}} />
                                </Box>
                            </Grid>
                            {stateTemps.repas.map((repas) => (
                                <Grid key={`REPTMPH${repas.id}`} item xs={1.5} textAlign="center">
                                    <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" horizontalAlign="center" textSize={"small"} textWeight="bold" height={"100%"}>
                                        {stateTemps.horaire ? heurify(repas.heuresRepas.heure, "h", false) : chiffreUSVersChiffreFR(repas.heuresRepas.chiffre)}
                                    </TextContainer>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </ListContainer>
            </Stack>
        </DialogContainer>
    );
}

RepasDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    userType: PropTypes.string.isRequired
}

const styles = {
    boxIconStyle: {
        backgroundColor: greenColor["015"], 
        borderRadius: 2,
        p: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}

export default RepasDialog;