import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { TextContainer } from "@components/Containers";
import { greenColor, greyColor, whiteColor } from "@styles";
import { Loader } from "@components/Loaders";
import { DialogError } from "@components/Errors";
import { useTranslation } from "react-i18next";

const ContrainteContents = (props) => {
    const { data, isLoading, isError, errorMessage } = props;
    const { t } = useTranslation("global");

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader size={22} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return <DialogError error={errorMessage} />
    }

    return (
        <Stack spacing={0.5}>
            {data.length > 0 ?
                data.map((contrainte) => (
                    <Stack key={`CP${contrainte.id}`} spacing={0.5} bgcolor={greenColor["015"]} borderRadius={2} p={0.5}>
                        <TextContainer bgColor={greenColor["015"]} p={1} textWeight="bold">Contrainte : {contrainte.codeContrainte} {contrainte.libelleContrainte}</TextContainer>
                        <TextContainer bgColor={whiteColor[5]} p={1}>Définition : {contrainte.definitionContrainte1} {contrainte.definitionContrainte2} {contrainte.definitionContrainte3} {contrainte.definitionContrainte4} {contrainte.definitionContrainte5}</TextContainer>
                        <TextContainer bgColor={whiteColor[5]} p={1}>Effectif minimum : {contrainte.effectifMinimum}</TextContainer>
                        <Stack spacing={0.5}>
                            <TextContainer bgColor={greenColor["015"]} p={1} textWeight="bold">Personnel associé</TextContainer>
                            {contrainte.collaborateurs.map((item) => (
                                <TextContainer key={`CTPL${item.id}`} bgColor={whiteColor[5]} p={1}>{item.prenomNom} : {item.type === "P" ? "Salarié" : "Intérimaire"}</TextContainer>
                            ))}
                        </Stack>
                    </Stack>
                ))  
            :
                <Box sx={styles.boxNoDataStyle}>
                    <Typography fontSize="small">{t("no_data.constraint")}</Typography>
                </Box>
            }
        </Stack>
    );
}

ContrainteContents.defaultProps = {
    isLoading: true,
    isError: false,
    errorMessage: "",
    data: []
}

ContrainteContents.propTypes = {
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
    data: PropTypes.array
}

const styles = {
    boxNoDataStyle: {
        backgroundColor: "rgba(0,0,0,0.1)", 
        borderRadius: 2,
        p:1
    },
    loaderStyle: {
        height: 52,
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
    }
}

export default ContrainteContents;