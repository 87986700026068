import { useCallback, useState } from "react";
import { TextField, Autocomplete, autocompleteClasses, Typography, Box, Tooltip, tooltipClasses, Paper, Grid, List } from "@mui/material";
import { useController, useFormContext, useFormState } from "react-hook-form";
import { greenColor, greyColor, redColor } from "@styles";
import PropTypes from "prop-types";
import { TextContainer } from "@components/Containers";

const sizeMapping = {
    xsmall: { muiLabel: "small", height: 32, pt: 0.25, top: "-5px", topShrink: 0 },
    small: { muiLabel: "small", height: undefined, pt: undefined, top: undefined, topShrink: undefined }
}

const OptionsComponent = (props, option, headerOptions) => {
    return (
        <Box key={option.id} component="li" {...props} sx={styles.optionsStyle} borderRadius={2} mb={0.5}>
            <Grid container spacing={0.5}>
                {headerOptions.map((headerOption, id) => (
                    <Grid item xs={headerOption.xs} key={`AUTOCOPTIONOPT${id}`} display="flex" justifyContent="center">
                        <TextContainer bgColor="none" p={0}>{option[headerOption.key]}</TextContainer>
                    </Grid>
                ))}
            </Grid>
        </Box> 
    );
}

const ListboxComponent = (props, headerOptions) => {
    return (
        <Box px={0.5} pb={0}>
            <Box py={0.5}>
                <Grid container spacing={0.5}>
                    {headerOptions.map((headerOption) => (
                        <Grid item xs={headerOption.xs} key={`AUTOCOPTIONHEAD${headerOption.id}`}>
                            <TextContainer bgColor={greenColor["015"]} p={0.75} height={"100%"} textWeight="bold" horizontalAlign="center" verticalAlign="center" textAlign="center" textDecoration={"none"}>{headerOption.title}</TextContainer>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <List {...props} />
        </Box>
    );
}

const PaperComponent = (props) => {
    return (
        <Paper {...props} style={{minWidth: "fit-content"}}  />
    );
}

const InputComponent = (props, name, label, size, errors, showErrors, tooltipPlacement) => {
    return (
        <Tooltip placement={tooltipPlacement} open={!!errors[name]} arrow PopperProps={{sx: {[`& .${tooltipClasses.tooltip}`]: {backgroundColor: redColor[8], fontSize: 12, fontWeight: "bold"}, [`& .${tooltipClasses.arrow}`]: {color: redColor[8],}}}} title={showErrors ? errors[name]?.message : ""}>
            <TextField {...props} error={errors[name]} label={label} sx={{ "& .MuiInputBase-root": { height: sizeMapping[size].height, "&.MuiOutlinedInput-root": { pt: sizeMapping[size].pt } } }} InputLabelProps={{ sx: { top: sizeMapping[size].top, "&[data-shrink='true']": { top: sizeMapping[size].topShrink } } }} />
        </Tooltip>
    );
}

const NoOptions = () => {
    return (
        <Typography fontSize={"small"}>Aucune donnée</Typography>
    );
}

const optionsMapping = (option, value) => {
    return option.id === value.id
}

const labelsMapping = (option) => option.libelle ? option.libelle : "";

const AutocompleteTableInput = (props) => {
    const { name, label, options, headerOptions, size, tooltipPlacement, showErrors, disabled, required, onChange, optionsMapping, labelsMapping } = props;
    const [open, setOpen] = useState(false);
    const { control } = useFormContext();
    const { field } = useController({ name: name, control, rules: { required: required } });
    const { errors } = useFormState({ control });

    const handleChange = useCallback((d) => {
        onChange(d);
        field.onChange(d);
    }, [field, onChange]);

    return (
        <Autocomplete 
            {...field} 
            fullWidth 
            label={label} 
            size="small" 
            options={options} 
            open={open} 
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionLabel={labelsMapping}      
            isOptionEqualToValue={optionsMapping}
            noOptionsText={<NoOptions />}
            disabled={disabled}
            ListboxProps={{sx: { [`&.${autocompleteClasses.listbox}`]: {p: 0}}}}
            PaperComponent={(props) => PaperComponent(props)}
            ListboxComponent={(props) => ListboxComponent(props, headerOptions)}
            renderInput={(props) => InputComponent(props, name, label, size, errors, showErrors, tooltipPlacement)}
            renderOption={(props, option) => OptionsComponent(props, option, headerOptions)}
            onChange={(_, d) => handleChange(d)}
        />
    )
}

AutocompleteTableInput.defaultProps = {
    label: "",
    options: [],
    headerOptions: [],
    size: "small",
    tooltipPlacement: "top",
    showErrors: false,
    disabled: false,
    required: false,
    onChange: () => {},
    optionsMapping: optionsMapping,
    labelsMapping: labelsMapping
}

AutocompleteTableInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.array,
    headerOptions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        xs: PropTypes.number,
        key: PropTypes.string
    })),
    size: PropTypes.oneOf(["xsmall", "small"]),
    tooltipPlacement: PropTypes.string,
    showErrors: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    optionsMapping: PropTypes.func,
    labelsMapping: PropTypes.func
}

const styles = {
    optionsStyle: {
        [`&.${autocompleteClasses.option}`]: {
            px: 0, 
            backgroundColor: greenColor["015"],
            '&:hover': {
                backgroundColor: greenColor["035"]
            },
            '&[aria-selected="true"]': {
                border: "1px solid",
                borderColor: greyColor[2],
                backgroundColor: greenColor["035"]
            } 
        }
    }
}

export default AutocompleteTableInput;