import { Skeleton, Stack } from "@mui/material";
import { ChevronLeft, ChevronRight, Refresh } from "@mui/icons-material";
import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { HeaderPanelContainer } from "@components/Containers";
import { whiteColor } from "@styles";

const PlagesHorairesActionsBarMobile = (props) => {
    const { 
        isLoading, 
        isError, 
        precedent,
        suivant,
        refetch 
    } = props;

    if(isLoading) {
        return (
            <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={"100%"} height={46} />
        )
    }

    return (
        <HeaderPanelContainer 
            actionsLeft={
                !isError &&
                    <Stack direction="row" spacing={0.5}>
                        <IconButtonBase icon={<ChevronLeft fontSize="small" />} action={() => precedent()} />
                        <IconButtonBase icon={<ChevronRight fontSize="small" />} action={() => suivant()} />
                    </Stack>
            }
            actionsRight={
                <Stack direction="row" spacing={0.5} justifyContent="right">
                    <IconButtonBase icon={<Refresh fontSize="small" />} action={() => refetch()} />
                </Stack>
            }   
            bgColor={whiteColor[5]}
        />
    );
}

PlagesHorairesActionsBarMobile.defaultProps = {
    isLoading: true, 
    isError: false, 
    precedent: () => {},
    suivant: () => {},
    refetch: () => {}
}

PlagesHorairesActionsBarMobile.propTypes = {
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool, 
    precedent: PropTypes.func,
    suivant: PropTypes.func,
    refetch: PropTypes.func
}

export default PlagesHorairesActionsBarMobile;