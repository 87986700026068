import { Box, Grid, Grow, Stack, Typography } from "@mui/material";
import { datify, heureIntVersHeureString } from "@utils";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { selectCalGesAbs } from "@selectors";
import { PanelListContainer, TextWithLabelContainer } from "@components/Containers";
import { CheckBoxButton } from "@components/Buttons";
import { ErrorBase } from "@components/Errors";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTranslation } from "react-i18next";

const DemandesAnnulationListMobile = (props) => {
    const { data, isLoading, isError, errorMessage, demandesSelected, select } = props;
    const stateCalendrier = useSelector(selectCalGesAbs);
    const { t } = useTranslation("global");

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle} height={`calc(${stateCalendrier.hauteur}px - 118px)`}>
                <Loader size={17} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return <ErrorBase>{errorMessage}</ErrorBase>;
    }

    if(data.length === 0) {
        return (
            <Stack spacing={1} position="relative" height="100%"> 
                <Grid container height="100%">
                    <Grow in={true} timeout={500}>
                        <Grid item xs={12} sx={styles.panelNoDataStyle}> 
                            <Typography fontSize="small" marginLeft={1}>{t("no_data.generic")}</Typography>
                        </Grid>
                    </Grow>
                </Grid>
            </Stack>
        );
    }

    return(
        <Box maxHeight="100%" overflow="auto">
            <PerfectScrollbar>
                <PanelListContainer length={data.length}>
                    {data.map((demandeAbsence, id) => (
                        <Grow key={`LISTDANU${demandeAbsence.id}`} in={true} timeout={400+(200*id)}>
                            <Grid item xs={12} sm={6}>
                                <Stack spacing={0.5} bgcolor={demandeAbsence.absenceEnDemande === "N" ? greenColor["015"] : redColor[2]} padding={0.5} borderRadius={2}>
                                    <Box borderRadius={2} p={1} bgcolor={whiteColor[5]}>
                                        <Stack spacing={0}>
                                            <TextWithLabelContainer label={t("texts.absence_date")} value={datify(moment([demandeAbsence.annee, demandeAbsence.mois - 1, demandeAbsence.jour]).format("DDMMYYYY"), "tddddDDmmmmyyyy")} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label={t("texts.absence_type")} value={demandeAbsence.libelle} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label={t("texts.hours")} value={heureIntVersHeureString(demandeAbsence.nombreHeures)} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label={t("texts.days")} value={demandeAbsence.nombreJours} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                        </Stack>
                                    </Box>
                                    {demandeAbsence.absenceEnDemande === "N" && 
                                        <Box borderRadius={2} height={35.5} display="flex" alignItems="center" justifyContent="center" bgcolor={whiteColor[5]}>
                                            <CheckBoxButton sx={{"& .MuiSvgIcon-root": { fontSize: 16 } }} checked={demandesSelected.includes(Number(demandeAbsence.numeroEnregistrement))} value={demandeAbsence.numeroEnregistrement} onChange={select} />
                                        </Box>
                                    }
                                </Stack>
                            </Grid>
                        </Grow>
                    ))}
                </PanelListContainer>
            </PerfectScrollbar>
        </Box>
    );
}

DemandesAnnulationListMobile.defaultProps = {
    data: [],
    isLoading: true,
    isError: false,
    errorMessage: "",
    demandesSelected: [],
    select: () => {}
}

DemandesAnnulationListMobile.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
    demandesSelected: PropTypes.array,
    select: PropTypes.func
}

const styles = {
    panelStackStyle: {
        backdropFilter: "blur(3px)",
        backgroundColor:"rgba(0,0,0,0.1)",
        borderRadius: 2
    },
    panelStackOnDemandeStyle: {
        backdropFilter: "blur(3px)",
        backgroundColor:"rgba(255,0,0,0.2)",
        borderRadius: 2
    },
    panelTitleStackStyle: {
        backdropFilter: "blur(3px)",
        backgroundColor:"rgba(49, 133, 156, 0.3)",
        borderRadius: 2,
        padding: 1.5
    },
    panelBoxListStyle: {
        backgroundColor:"rgba(255,255,255,0)",
        borderRadius: 2,
    },
    panelNoDataStyle: {
        backgroundColor: greenColor["015"],
        borderRadius: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        padding: 2
    },
    checkBoxStyle: { 
        "& .MuiSvgIcon-root": { 
            fontSize: 14 
        } 
    },
    buttonStyle: {
        borderRadius: 2, 
        boxShadow: "none", 
        backgroundColor: greyColor[0],
        width: "100%",
        padding: 1,
        color: greyColor[4],
        "&:hover": {
            backgroundColor: greyColor[1],
        }
    },
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center"
    },
}

export default DemandesAnnulationListMobile;