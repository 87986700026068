import { Stack } from "@mui/material";
import { Check, Close, WarningRounded } from "@mui/icons-material";
import PropTypes from "prop-types";
import { DialogContainer, HeaderDialogContainer, TextContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { redColor, whiteColor } from "@styles";

const PlanningAlertesDialog = (props) => {
    const { open, alertes, bloquant, setOpen, postPlanning } = props;

    return (
        <DialogContainer open={open} taille="sm">
            <HeaderDialogContainer  
                actionsLeft={
                    bloquant !== "O" && 
                        <Stack direction={"row"} spacing={0.5}>
                            <IconButtonBase icon={<Check fontSize="small" />} title="Valider" action={() => postPlanning()} />
                            <IconButtonBase icon={<Close fontSize="small" />} title="Ne pas valider" action={() => setOpen(false)} />
                        </Stack>    
                }
                title={<WarningRounded fontSize="large" />}
                close={() => setOpen(false)}
            /> 
            <Stack spacing={0.5} bgcolor={redColor[1]} borderRadius={2} p={0.5}>
                {alertes.length > 0 && 
                    <>
                        {alertes[0] && <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold">{alertes[0]}</TextContainer>}
                        {alertes[1] && <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold">{alertes[1]}</TextContainer>}
                        {alertes[2] && <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold">{alertes[2]}</TextContainer>}
                        {alertes[3] && <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold">{alertes[3]}</TextContainer>}
                    </>
                }
            </Stack>
        </DialogContainer>
    );
}

PlanningAlertesDialog.dafaultProps = {
    alertes: [],
    bloquant: "N",
    postPlanning: () => {}
}

PlanningAlertesDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    alertes: PropTypes.arrayOf(PropTypes.string),
    bloquant: PropTypes.string,
    setOpen: PropTypes.func.isRequired,
    postPlanning: PropTypes.func
}

export default PlanningAlertesDialog;