import { useState } from "react";
import PropTypes from "prop-types";
import { useHorInp } from "@hooks/gestionTemps/horaires/hooksQueries";
import AutocompleteAsyncInput from "../AutocompleteAsyncInput";

const headerOptionsHoraire = [
    {id: 1, title: "Code", xs: 2, key: "codeHoraire"},
    {id: 2, title: "Désignation", xs: 6, key: "designation"},
    {id: 3, title: "Équipe", xs: 2, key: "libelleHoraireEquipe"},
    {id: 4, title: "Base", xs: 2, key: "baseMensuelle"}
];

const labelsMapping = (option) => option.designation ? `${option.codeHoraire} - ${option.designation}` : "";

const HorairesAffectationsCollectivesInput = (props) => {
    const { name, label, codeSociete, date, action } = props;
    const [open, setOpen] = useState(false);
    const query = useHorInp(codeSociete, date, open);

    return (
        <AutocompleteAsyncInput
            name={name}
            label={label}
            headerOptions={headerOptionsHoraire} 
            query={query} 
            tooltipPlacement="left" 
            disabled={!!(!codeSociete || !date || !date?.isValid())} 
            disabledText={!codeSociete ? "Vous devez selectionner une société." : "Vous devez selectionner une date"} 
            open={open} 
            onChange={action}
            setOpen={setOpen} 
            labelsMapping={labelsMapping} 
        />
    );
}

HorairesAffectationsCollectivesInput.defaultProps = {
    name: "horaire",
    label: "Horaire",
    date: "",
    codeSociete: "",
    action: () => {}
}

HorairesAffectationsCollectivesInput.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    codeSociete: PropTypes.string,
    action: PropTypes.func
}

export default HorairesAffectationsCollectivesInput;