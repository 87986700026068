import axios from "axios";
import { urls } from "@constants/appConstants";

export const fetchCnf = async (token, signal) => {
    const headers = {
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetConfiguration", 
        "HttpToken": token
    };
    const response = await axios.get(urls.getConfiguration, { headers: headers, signal: signal });
    return {
        widgets: response.data.widgets,
        widgetsActives: response.data.widgetsActives,
        widgetsNonActives: response.data.widgetsNonActives,
        shortcuts: response.data.shortcuts,
        shortcutsActives: response.data.shortcutsActives,
        shortcutsNonActives: response.data.shortcutsNonActives
    }
}

export const editCnf = async (data) => {
    const headers = {
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostConfiguration", 
        "HttpToken": data.token
    };
    const body = {
        widgets: data.widgets,
        shortcuts: data.shortcuts
    };
    const response = await axios.post(urls.postConfiguration, body, { headers: headers });
    return response.data;
}