import { ChevronLeft, ChevronRight, Event, Hub, ListAlt, OfflinePin, Refresh, Share, SquareFoot } from "@mui/icons-material";
import PropTypes from "prop-types";
import { DatePickerButtonMobile, IconButtonBase, MenuButton } from "@components/Buttons";
import { HeaderDialogContainer, HeaderPanelContainer } from "@components/Containers";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

const PlanningDialogActionsMobile = (props) => {
    const { 
        isLoading, 
        isError, 
        setOpen, 
        allServices,
        affichage,
        precedent,
        suivant,
        selectDate,
        setOpenContraintes,
        toggleAffichage,
        toggleAll,
        refetch 
    } = props;
    const { t } = useTranslation("global");

    return (
        <>
            <HeaderDialogContainer 
                title={t("titles.planning")}
                close={() => setOpen(false)}
            />
            <HeaderPanelContainer
                actionsLeft={
                    <Stack direction="row" spacing={0.5}>
                        <IconButtonBase icon={<ChevronLeft fontSize="small" />} title={t("buttons.previous_week")} disabled={isLoading || isError} action={() => precedent()} />
                        <IconButtonBase icon={<ChevronRight fontSize="small" />} title={t("buttons.next_week")} disabled={isLoading || isError} action={() => suivant()} />
                        <DatePickerButtonMobile icon={<Event fontSize="small" />} title={t("buttons.select_date")} timeout={600} noClick={isLoading || isError} action={selectDate} />
                    </Stack>
                } 
                actionsRight={
                    !allServices ? 
                        <MenuButton txtId="BTHISTO" options={[
                            {id: 1, icon: <SquareFoot fontSize="small" />, label: t("buttons.show_constraints"), action: () => setOpenContraintes(true)},
                            {id: 2, icon: affichage === "T" ? <OfflinePin fontSize="small" /> : <ListAlt fontSize="small" />, label: affichage === "T" ? t("buttons.validated_absences") : t("buttons.all_absences"), action: () => toggleAffichage()},
                            {id: 2, icon: allServices ? <Share fontSize="small" /> : <Hub fontSize="small" />, label: allServices ? t("buttons.one_service") : t("buttons.all_services"), action: () => toggleAll()},
                            {id: 2, icon: <Refresh fontSize="small" />, label: t("buttons.refresh"), action: () => refetch()}
                        ]} />
                    :
                        <MenuButton txtId="BTHISTO" options={[
                            {id: 1, icon: affichage === "T" ? <OfflinePin fontSize="small" /> : <ListAlt fontSize="small" />, label: affichage === "T" ? t("buttons.validated_absences") : t("buttons.all_absences"), action: () => toggleAffichage()},
                            {id: 2, icon: allServices ? <Share fontSize="small" /> : <Hub fontSize="small" />, label: allServices ? t("buttons.one_service") : t("buttons.all_services"), action: () => toggleAll()},
                            {id: 2, icon: <Refresh fontSize="small" />, label: t("buttons.refresh"), action: () => refetch()}
                        ]} />
                }
            />
        </>
    )
}

PlanningDialogActionsMobile.defaultProps = {
    isLoading: true, 
    isError: false,
    setOpen: () => {},
    allServices: true,
    affichage: "V",
    precedent: () => {},
    suivant: () => {},
    selectDate: () => {},
    setOpenContraintes: () => {},
    toggleAffichage: () => {},
    toggleAll: () => {},
    refetch: () => {}
}

PlanningDialogActionsMobile.propTypes = {
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool,
    setOpen: PropTypes.func,
    allServices: PropTypes.bool,
    affichage: PropTypes.string,
    precedent: PropTypes.func,
    suivant: PropTypes.func,
    selectDate: PropTypes.func,
    setOpenContraintes: PropTypes.func,
    toggleAffichage: PropTypes.func,
    toggleAll: PropTypes.func,
    refetch: PropTypes.func
}

export default PlanningDialogActionsMobile;