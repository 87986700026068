import { Box, Skeleton, Stack } from "@mui/material";
import { ChevronLeft, ChevronRight, DownloadForOffline, Event, GroupRemove, Hub, ListAlt, OfflinePin, Refresh, Rule } from "@mui/icons-material";
import PropTypes from "prop-types";
import { DatePickerButton, IconButtonBase } from "@components/Buttons";
import { HeaderPanelContainer } from "@components/Containers";
import { greenColor, greyColor } from "@styles";
import { Loader } from "@components/Loaders";

const PlanningActionsBar = (props) => {
    const { 
        isLoading,
        isLoadingDownload,
        affichage,
        allServices,
        precedent,
        suivant,        
        selectDate,
        setOpenHierarchie,
        toggleAffichage,
        checkAllServices,
        setOpenServices,
        telechargerPlanning,
        refetch
    } = props;
    
    if(isLoading) {
        return (
            <Box>
                <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={"100%"} height={46} />
            </Box>
        )
    }

    return (
        <HeaderPanelContainer 
            actionsLeft={
                <Stack direction="row" spacing={0.5}>
                    <IconButtonBase icon={<ChevronLeft fontSize="small" />} title="Semaine Précédente" action={() => precedent()} />
                    <IconButtonBase icon={<ChevronRight fontSize="small" />} title="Semaine Suivante" action={() => suivant()} />
                    <DatePickerButton icon={<Event fontSize="small" />} title="Choisir une date" action={selectDate} />
                </Stack>
            }
            actionsRight={
                <Stack direction="row" spacing={0.5} justifyContent="right">
                    <IconButtonBase icon={<GroupRemove fontSize="small"/>} title="Planning N-1" action={() => setOpenHierarchie(true)} />
                    <IconButtonBase icon={affichage === "T" ? <OfflinePin fontSize="small" /> : <ListAlt fontSize="small" />} title={affichage === "T" ? "Absences validées" : "Toutes les absences"} action={() => toggleAffichage()} />
                    {!allServices && <IconButtonBase icon={<Hub fontSize="small" />} title="Tous les services" action={() => checkAllServices()} />}
                    <IconButtonBase icon={<Rule fontSize="small" />} title="Selectionner un ou plusieurs services" action={() => setOpenServices(true)} />
                    {isLoadingDownload ? 
                        <Box width={30} height={12.5} display="inline-block" justifyContent="center">
                            <Loader size={17} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                        </Box>
                    :
                        <IconButtonBase icon={<DownloadForOffline fontSize="small" />} title={"Exporter le planning au format xlsx"} action={() => telechargerPlanning()} />
                    }
                    <IconButtonBase icon={<Refresh fontSize="small" />} title="Rafraichir" action={() => refetch()} />
                </Stack>  
            }
            bgColor={greenColor["015"]}
            p={1}
        />
    );
}

PlanningActionsBar.defaultProps = {
    isLoading: true,
    isLoadingDownload: true,
    affichage: "T",
    allServices: true,
    precedent: () => {},
    suivant: () => {},
    selectDate: () => {},
    setOpenHierarchie: () => {},
    toggleAffichage: () => {},
    checkAllServices: () => {},
    setOpenServices: () => {},
    telechargerPlanning: () => {},
    refetch: () => {}
}

PlanningActionsBar.propTypes = {
    isLoading: PropTypes.bool,
    isLoadingDownload: PropTypes.bool,
    affichage: PropTypes.string,
    allServices: PropTypes.bool,
    precedent: PropTypes.func,
    suivant: PropTypes.func,    
    selectDate: PropTypes.func,
    setOpenHierarchie: PropTypes.func,
    toggleAffichage: PropTypes.func,
    checkAllServices: PropTypes.func,
    setOpenServices: PropTypes.func,
    telechargerPlanning: PropTypes.func,
    refetch: PropTypes.func
}

export default PlanningActionsBar;