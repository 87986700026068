import { ChevronLeft } from "@mui/icons-material";
import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { HeaderPanelContainer } from "@components/Containers";
import { PAGES_DELEGATIONS } from "@constants/delegationsConstants";

const DroitsActionsBar = ({
    isLoading = true,
    goTo = () => {}
}) => {
    return (
        <HeaderPanelContainer
            actionsLeft={
                <IconButtonBase 
                    variant="pageButton"
                    icon={<ChevronLeft fontSize="small" />} 
                    title="Retour" 
                    disabled={isLoading}
                    action={() => goTo(PAGES_DELEGATIONS.LIST)}
                />
            }
            title="Droits"
            variant="green07"
        />
    )
}

DroitsActionsBar.propTypes = {
    isLoading: PropTypes.bool,
    goTo: PropTypes.func
}

export default DroitsActionsBar;