import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import moment from "moment";
import { fetchPlaGesTem, fetchPlaGesTemNav, editPlaGesTem, editPlaGesTemMod, verificationPlaGesTem, downloadPlaGesTem } from "@api/planning";
import { selectCltApp, selectColPlaGesTem, selectPlaGesTem, selectUsrApp } from "@selectors";
import * as plaGesTemActions from "@reducers/gestionTemps/planning/plaGesTemReducer"
import * as colPlaGesTem from "@reducers/collaborateurs/colPlaGesTemReducer"
import { generateTableurPlanningGestionTemps } from "@tableurs";

export const usePlaGesTem = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const stateCollaborateursPlanning = useSelector(selectColPlaGesTem);
    const { mutateAsync, isLoading, isError, error } = useMutation(fetchPlaGesTem);
    const dispatch = useDispatch();

    const getPlanning = async (collaborateur) => {
        const numeroSemaine = stateCollaborateursPlanning.dateValidite ? moment(stateCollaborateursPlanning.dateValidite, "DDMMYYYY").week() : "";
        const anneeSemaine = stateCollaborateursPlanning.dateValidite ? moment(stateCollaborateursPlanning.dateValidite, "DDMMYYYY").year() : "";
        const dataPlanning = {
            numeroSemaine: String(numeroSemaine).padStart(2, "0"),
            anneeSemaine: anneeSemaine,
            utilisateur: stateUser.utilisateurAS400,
            matriculeHierarchique: stateUser.matricule,
            droitSalarie: stateUser.droitSalarie ? "O" : "N",
            droitInterimaire: stateUser.droitInterimaire ? "O" : "N",
            matriculeCollaborateur: collaborateur?.matricule,
            typePersonnel: collaborateur?.typePersonnel,
            client: stateClient.client
        };
        const response = await mutateAsync(dataPlanning);
        dispatch(plaGesTemActions.setFwgtp4({...response, modificationAffectationsHoraires: false}));
    };

    return { isLoading, isError, error , getPlanning }
}

export const usePlaGesTemPos = () => {
    const [alertes, setAlertes] = useState(["", "", "", ""]);
    const [bloquant, setBloquant] = useState("");
    const stateCollaborateursPlanning = useSelector(selectColPlaGesTem);
    const stateClient = useSelector(selectCltApp);
    const stateUser = useSelector(selectUsrApp);
    const statePlaGesTem = useSelector(selectPlaGesTem);
    const { mutateAsync: mutateAsyncPostPlanning, isLoading: isLoadingPost, isError: isErrorPost } = useMutation(editPlaGesTem);
    const { mutateAsync: mutateAsyncPostPlanningWithVerification, isLoading: isLoadingPostWithVerification, isError: isErrorPostWithVerification } = useMutation(verificationPlaGesTem);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const postPlanning = async (data) => {
        const numeroSemaine = stateCollaborateursPlanning.dateValidite ? moment(stateCollaborateursPlanning.dateValidite, "DDMMYYYY").week() : "";
        const anneeSemaine = stateCollaborateursPlanning.dateValidite ? moment(stateCollaborateursPlanning.dateValidite, "DDMMYYYY").year() : "";
        const dataPlanning = {
            numeroSemaine: String(numeroSemaine).padStart(2, "0"),
            anneeSemaine: anneeSemaine,
            utilisateur: stateUser.utilisateurAS400,
            matriculeHierarchique: stateUser.matricule,
            droitSalarie: stateUser.droitSalarie ? "O" : "N",
            droitInterimaire: stateUser.droitInterimaire ? "O" : "N",
            matriculeCollaborateur: stateCollaborateursPlanning?.collaborateurSelected?.matricule,
            typePersonnel: stateCollaborateursPlanning?.collaborateurSelected?.typePersonnel,
            modificationAffectationsHoraires: statePlaGesTem.modificationAffectationsHoraires ? "O" : "N",
            planning: data,
            client: stateClient.client
        };
        try {
            const response = await mutateAsyncPostPlanning(dataPlanning);
            dispatch(plaGesTemActions.setFwgtp4({...response.planning, modificationAffectationsHoraires: false}));
        } catch(e) {
            enqueueSnackbar(e?.response?.data?.details, { variant: "error" });
        }
    };

    const postPlanningWithVerification = async (data) => {
        let codeRetour = "";
        let zoneErreur = "";
        const numeroSemaine = stateCollaborateursPlanning.dateValidite ? moment(stateCollaborateursPlanning.dateValidite, "DDMMYYYY").week() : "";
        const anneeSemaine = stateCollaborateursPlanning.dateValidite ? moment(stateCollaborateursPlanning.dateValidite, "DDMMYYYY").year() : "";
        const dataPlanning = {
            numeroSemaine: String(numeroSemaine).padStart(2, "0"),
            anneeSemaine: anneeSemaine,
            utilisateur: stateUser.utilisateurAS400,
            matriculeHierarchique: stateUser.matricule,
            droitSalarie: stateUser.droitSalarie ? "O" : "N",
            droitInterimaire: stateUser.droitInterimaire ? "O" : "N",
            matriculeCollaborateur: stateCollaborateursPlanning?.collaborateurSelected?.matricule,
            typePersonnel: stateCollaborateursPlanning?.collaborateurSelected?.typePersonnel,
            modificationAffectationsHoraires: statePlaGesTem.modificationAffectationsHoraires ? "O" : "N",
            planning: data,
            client: stateClient.client
        };
        try {
            const response = await mutateAsyncPostPlanningWithVerification(dataPlanning);
            dispatch(plaGesTemActions.setFwgtp4({...response.planning, modificationAffectationsHoraires: false}));
        } catch(e) {
            if(e?.response?.status === 501) {
                if(e?.response?.data?.codeRetour === "AL") {
                    const alertesPlanning = [e?.response?.data?.alerte1, e?.response?.data?.alerte2, e?.response?.data?.alerte3, e?.response?.data?.alerte4];
                    setAlertes(alertesPlanning);
                } else {
                    enqueueSnackbar(e?.response?.data?.details, { variant: "error" });
                }
                const bloquantPlanning = e?.response?.data?.bloquant;
                setBloquant(bloquantPlanning);
                codeRetour = e?.response?.data?.codeRetour;
                zoneErreur = e?.response?.data?.zoneErreur;
            } else {
                enqueueSnackbar(e?.response?.data?.details, { variant: "error" });
                codeRetour = e?.response?.status;
            }
        }
        
        return { codeRetour, zoneErreur };
    };

    return { isLoadingPost, isLoadingPostWithVerification, isErrorPost, isErrorPostWithVerification, alertes, bloquant, postPlanning, postPlanningWithVerification }
}

export const usePlaGesTemNav = () => {
    const stateCollaborateursPlanning = useSelector(selectColPlaGesTem);
    const stateClient = useSelector(selectCltApp);
    const stateUser = useSelector(selectUsrApp);
    
    const dispatch = useDispatch();
    const { mutateAsync, isLoading: isLoadingNavigation, isError: isErrorNavigation } = useMutation(fetchPlaGesTemNav);
    const { enqueueSnackbar } = useSnackbar();

    const navigatePlanning = async (numeroSemaine, anneeSemaine) => {
        const dataPlanning = {
            numeroSemaine: String(numeroSemaine).padStart(2, "0"),
            anneeSemaine: anneeSemaine,
            utilisateur: stateUser.utilisateurAS400,
            matriculeHierarchique: stateUser.matricule,
            droitSalarie: stateUser.droitSalarie ? "O" : "N",
            droitInterimaire: stateUser.droitInterimaire ? "O" : "N",
            matriculeCollaborateur: stateCollaborateursPlanning?.collaborateurSelected?.matricule,
            typePersonnel: stateCollaborateursPlanning?.collaborateurSelected?.typePersonnel,
            client: stateClient.client
        };
        try {
            const response = await mutateAsync(dataPlanning);
            dispatch(colPlaGesTem.setDate(moment(response.dateLundi, "YYYYMMDD").format("DDMMYYYY")));
            dispatch(plaGesTemActions.setFwgtp4({...response, modificationAffectationsHoraires: false}));
        } catch(e) {
            enqueueSnackbar("Erreur lors de la récupération du planning.", { variant: "error" })
        }
    };

    return { isLoadingNavigation, isErrorNavigation, navigatePlanning }
}

export const usePlaGesTemMod = () => {
    const stateCollaborateursPlanning = useSelector(selectColPlaGesTem);
    const stateClient = useSelector(selectCltApp);
    const stateUser = useSelector(selectUsrApp);
    const statePlaGesTem = useSelector(selectPlaGesTem);
    const dispatch = useDispatch();
    const { mutateAsync, isLoading: isLoadingModelization, isError: isErrorModelization } = useMutation(editPlaGesTemMod);
    const { enqueueSnackbar } = useSnackbar();

    const modelizePlanning = async (data) => {
        const numeroSemaine = stateCollaborateursPlanning.dateValidite ? moment(stateCollaborateursPlanning.dateValidite, "DDMMYYYY").week() : "";
        const anneeSemaine = stateCollaborateursPlanning.dateValidite ? moment(stateCollaborateursPlanning.dateValidite, "DDMMYYYY").year() : "";
        const dataPlanning = {
            numeroSemaine: String(numeroSemaine).padStart(2, "0"),
            anneeSemaine: anneeSemaine,
            utilisateur: stateUser.utilisateurAS400,
            matriculeHierarchique: stateUser.matricule,
            droitSalarie: stateUser.droitSalarie ? "O" : "N",
            droitInterimaire: stateUser.droitInterimaire ? "O" : "N",
            matriculeCollaborateur: stateCollaborateursPlanning?.collaborateurSelected?.matricule,
            typePersonnel: stateCollaborateursPlanning?.collaborateurSelected?.typePersonnel,
            modificationAffectationsHoraires: statePlaGesTem.modificationAffectationsHoraires ? "O" : "N",
            planning: data,
            client: stateClient.client
        };
        try {
            const response = await mutateAsync(dataPlanning);
            dispatch(plaGesTemActions.setFwgtp4(response.planning));
        } catch(e) {
            enqueueSnackbar("Erreur lors de la récupération du planning.", { variant: "error" })
        }
    };

    return { isLoadingModelization, isErrorModelization, modelizePlanning }
}

export const usePlaGesTemDow = () => {
    const stateClient = useSelector(selectCltApp);
    const stateUser = useSelector(selectUsrApp);
    const { isLoading: isLoadingDownload, mutateAsync } = useMutation(downloadPlaGesTem);
    const { enqueueSnackbar } = useSnackbar();

    const downloadPlanningActionner = async (data) => {
        const dataFormated = {
            utilisateur: stateUser.utilisateurAS400,
            matriculeHierarchique: stateUser.matricule,
            droitPersonnel: "O",
            droitInterimaires: "N",
            anneeDebut: data.dateDebut ? String(data.dateDebut.year()) : "",
            semaineDebut: data.dateDebut ? String(data.dateDebut.isoWeek()).padStart(2, "0") : "",
            societe: data.societe ? data.societe.codeSociete : "",
            filtreSociete: data.filtreSociete ? "O" : "N",
            filtreEtablissement: data.filtreEtablissement? "O" : "N",
            filtreDirection: data.filtreDirection? "O" : "N",
            filtreService: data.filtreService? "O" : "N",
            filtreSecteur: data.filtreSecteur? "O" : "N",
            filtreMetier: data.filtreMetier? "O" : "N",
            filtreCategorieProfessionnelle: data.filtreCategorieProfessionnelle? "O" : "N",
            filtreHoraire: data.filtreHoraire? "O" : "N",
            client: stateClient.client
        };
        try {
            const response = await mutateAsync(dataFormated);
            generateTableurPlanningGestionTemps("Planning graphique", response?.planning?.headers, response?.planning?.data, data.tri);
        } catch(e) {
            enqueueSnackbar("Erreur lors de la récupération du planning", { variant: "error" })
        }
    };

    return { isLoadingDownload, downloadPlanningActionner }
}
