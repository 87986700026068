import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const DialogError = (props) => {
    const { error } = props;

    return(
        <Box sx={styles.boxErrorStyle}>
            <Typography>{error}</Typography>
        </Box>
    );
}

DialogError.defaultProps = {
    error: ""
}

DialogError.propTypes = {
    error: PropTypes.string
}

const styles = {
    boxErrorStyle: {
        backgroundColor: "rgba(255,0,0,0.1)", 
        textAlign: "center",
        p: 2, 
        borderRadius: 2
    }
};

export default DialogError;