import { useSelector } from "react-redux";
import { useCallback, useState } from "react";
import { Box, Grid, Grow, Stack, Typography } from "@mui/material";
import { Check, Edit } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import PropTypes from "prop-types";
import moment from "moment";
import { selectEntMan, selectUsrApp } from "@selectors";
import { useEditEntretien } from "@hooks/gestionEntretiens/hooksQueries";
import { DialogFullScreenContainer, HeaderDialogContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { InformationsForm } from "@components/GestionEntretiens/Manager/Forms";
import { schemaEntretiens } from "@components/GestionEntretiens/validations";
import { greenColor } from "@styles";

const defaultValues = {
    date: null,
    heure: null,
    statut: "",
    interlocuteur: null,
    initiative: "",
    sujet: "",
    commentaire: ""
}

const DetailDialogMobile = ({ 
    open = false, 
    setOpen = () => {}, 
    refetchEntMan = () => {} 
}) => {
    const [edition, setEdition] = useState(false);
    const stateUser = useSelector(selectUsrApp);
    const stateEntretiens = useSelector(selectEntMan);
    const methods = useForm({ mode: "all", defaultValues: defaultValues, resolver: joiResolver(schemaEntretiens) });
    const { isLoadingEdit, editEntretienActionner } = useEditEntretien();

    const setEdit = useCallback(() => {
        setEdition((prevEdition) => !prevEdition);
        const entretienSelected = stateEntretiens?.entretienSelected;
        if(entretienSelected?.statut === "P") {
            methods.setValue("date", moment([entretienSelected?.annee, entretienSelected?.mois - 1, entretienSelected?.jour]));
            methods.setValue("heure", moment([entretienSelected?.annee, entretienSelected?.mois - 1, entretienSelected?.jour, entretienSelected?.heure, entretienSelected?.minute]));
        }
        methods.setValue("statut", entretienSelected?.statut);
        methods.setValue("interlocuteur", { value: entretienSelected?.interlocuteur, label: entretienSelected?.prenomNomInterlocuteur });
        methods.setValue("initiative", entretienSelected?.initiative);
        methods.setValue("sujet", entretienSelected?.sujet);
        const commentaire = entretienSelected?.commentaire1 + entretienSelected?.commentaire2 + entretienSelected?.commentaire3 + entretienSelected?.commentaire4 + entretienSelected?.commentaire5 + entretienSelected?.commentaire6 + entretienSelected?.commentaire7 + entretienSelected?.commentaire8 + entretienSelected?.commentaire9 + entretienSelected?.commentaire10
        methods.setValue("commentaire", commentaire);
    }, [methods, stateEntretiens?.entretienSelected]);

    const editEntretien = useCallback(async (dataForm) => {
        const entretienSelected = stateEntretiens?.entretienSelected;
        const { isSucceed } = await editEntretienActionner(dataForm, entretienSelected?.codeSociete, entretienSelected?.matricule, entretienSelected?.codeCle, entretienSelected?.statut)
        if(isSucceed) {
            setEdition(false);
            refetchEntMan();
        }
    }, [editEntretienActionner, refetchEntMan, stateEntretiens?.entretienSelected]);
    
    const close = useCallback(() => {
        setOpen(false);
        setEdition(false);
    }, [setOpen]);

    return (
        <DialogFullScreenContainer open={open} taille="lg">
            <HeaderDialogContainer
                actionsLeft={
                    <>
                        {(stateUser.utilisateurAS400 === stateEntretiens?.entretienSelected?.utilisateur || stateUser.utilisateurAS400 === stateEntretiens?.entretienSelected?.interlocuteur) && 
                            <IconButtonBase icon={<Edit fontSize="small" />} title="Editer l'entretien" action={() => setEdit()} />
                        }
                        {edition && <IconButtonBase icon={<Check fontSize="small" />} title="Valider la modification" action={() => methods.handleSubmit(editEntretien)()} />}
                    </>
                }
                title="Détail de l'entretien"
                close={() => close()}
            />
            <Stack spacing={0.5} height="100%" overflow="auto">
                {stateEntretiens.entretienSelected ?
                    <FormProvider {...methods}>
                        <InformationsForm edition={edition} isLoading={isLoadingEdit} />
                    </FormProvider>
                :
                    <Grid container height={"100%"}>
                        <Grow in={true} timeout={500}>
                            <Box borderRadius={2} bgcolor={greenColor["015"]} width="100%" p={1} display="flex" alignItems="center" justifyContent="center"> 
                                <Typography fontSize="small">Aucun entretien sélectionné</Typography>
                            </Box>
                        </Grow>
                    </Grid>
                }
            </Stack>
        </DialogFullScreenContainer>
    );
}

DetailDialogMobile.propTypes = {
    open: PropTypes.bool, 
    setOpen: PropTypes.func,
    refetchEntMan: PropTypes.func
}

export default DetailDialogMobile;