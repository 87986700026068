import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { BaseButton } from "@components/Buttons";
import * as userActions from "@reducers/user/usrAppReducer";
import { selectUsrApp } from "@selectors";
import { backgrounds } from "@constants/appConstants";
import { useTranslation } from "react-i18next";
import { whiteColor } from "@styles";

const BackgroundValue = () => {
    const stateUser = useSelector(selectUsrApp);
    const dispatch = useDispatch();
    const { t } = useTranslation("global");

    const handleBackground = useCallback(() => {
        dispatch(userActions.openBackground(true));
    }, [dispatch]);

    return (
        <Stack direction="row" spacing={0.5}>
            <Box>
                <Avatar variant="rounded" sx={{borderRadius: 2, height: 72, width: 106}} alt={t("titles.wallpaper")} src={backgrounds[stateUser.background]}></Avatar>
            </Box>
            <Stack spacing={0.5} flexGrow={1}>
                <Box bgcolor={whiteColor[5]} borderRadius={2} height={34} display="flex" alignItems="center" justifyContent="center">
                    <Typography fontWeight="bold" fontSize="1.2rem">{t("titles.wallpaper")}</Typography>
                </Box>
                <Box>
                    <BaseButton fontSize="medium" height={34} action={handleBackground}>{t("buttons.edit_wallpaper")}</BaseButton>
                </Box>
            </Stack>
        </Stack>
    );
}

export default BackgroundValue;