import { validateDate, validateFile, validateHeure } from "@validation";
import Joi from "joi";

export const schemaDemandeAbsence = Joi.object({
    dateDebut: validateDate.dateMoment().valide().minDate().required().messages({ 
        "object.base": "errors.input_start_date_required",
        "dateMoment.valide": "errors.input_date_invalid", 
        "dateMoment.minDate": "errors.input_date_min_today" 
    }),
    typeAbsence: Joi.object().required().messages({ "object.base": "errors.input_absence_reason_required" }),
    type: Joi.string().required().valid("date", "heure", "plage", "ampm"),
    dateReprise: Joi.any().when("type", {
        is: "date",
        then: validateDate.dateMoment().valide().greaterMoment(Joi.ref("$dateDebut")).messages({ 
            "object.base": "errors.input_recovery_date_required", 
            "dateMoment.valide": "errors.input_date_invalid",
            "dateMoment.greaterMoment": "errors.input_date_min_start_date"
        }),
        otherwise: Joi.optional()
    }),
    jourHeure: Joi.boolean(),
    ampm: Joi.boolean(),
    heureDebut: Joi.any().when("type", {
        is: "plage",
        then: validateHeure.heureMoment().valide().required().messages({
            "object.base": "errors.input_start_time_required",
            "heureMoment.valide": "errors.input_time_invalid", 
        }),
        otherwise: Joi.optional()
    }),
    heureReprise: Joi.any().when("type", {
        is: "plage",
        then: validateHeure.heureMoment().valide().greaterHeureMoment().required().messages({
            "object.base": "errors.input_end_time_required",
            "heureMoment.valide": "errors.input_time_invalid", 
            "heureMoment.greaterHeureMoment": "errors.input_time_min_start_time", 
        }),
        otherwise: Joi.optional()
    }),
    nombreHeures: Joi.any().when("type", {
        is: "heure",
        then: validateHeure.heureMoment().valide().required().messages({
            "object.base": "errors.input_number_hours_required",
            "heureMoment.valide": "errors.input_number_hours_invalid", 
        }),
        otherwise: Joi.optional()
    }),
    typeMotif: Joi.string().required().valid("O", "N"),
    motif: Joi.any().when("typeMotif", { 
        is: "O", 
        then: Joi.string().required().messages({ "string.empty": "errors.inputs_reason_required"}), 
        otherwise: Joi.optional(),
    }),
    typeFichier: Joi.string().required().valid("1", "2"),
    fichier: Joi.any().when("typeFichier", {
        is: "1", 
        then: validateFile.fileAbsence().mimeType(["application/pdf"]).size().obligatoire().messages({ 
            "fileAbsence.mimeType": "errors.input_attachment_format_pdf",  
            "fileAbsence.size": "errors.input_attachment_size_20", 
            "object.base": "errors.input_attachment_required"
        }),
        otherwise: Joi.optional()
    })
});