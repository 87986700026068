import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import PropTypes from "prop-types";
import { selectAffHorInd, selectColAffHorInd, selectHorAffGesTem } from "@selectors";
import { TextContainer } from "@components/Containers";
import { DateHookFormInput, RadiosHookFormInput } from "@components/Inputs";
import { HorairesAffectationsIndividuellesInput } from "@components/Inputs/Horaires";
import { whiteColor } from "@styles";

const statuts = [
    { id: 1, value: "D", label: "Décision" },
    { id: 2, value: "P", label: "Proposition" }
];

const AffectationIndividuelleHoraireForm = (props) => {
    const { mode, open } = props;
    const { watch, setValue } = useFormContext();
    const stateAffectations = useSelector(selectAffHorInd); 
    const stateCollaborateurs = useSelector(selectColAffHorInd); 
    const stateHoraires = useSelector(selectHorAffGesTem); 
    const date = watch("date");

    useEffect(() => {
        if(mode === "edition" && stateAffectations.affecationSelected) {
            if(stateCollaborateurs?.collaborateurSelected?.typePersonnel === "P") {
                setValue("statut", stateAffectations?.affecationSelected?.codeStatut);
            }
            const date = moment([stateAffectations?.affecationSelected?.anneeEffet, stateAffectations?.affecationSelected?.moisEffet - 1, stateAffectations?.affecationSelected?.jourEffet]);
            if(date.isValid()) {
                setValue("date", date);
            }
        }
        if(mode === "edition" && stateHoraires.horairesAffectations) {
            const horaire = stateHoraires.horairesAffectations.find((horaire) => horaire.codeHoraire === stateAffectations?.affecationSelected?.codeHoraire);
            setValue("horaire", horaire);
        }
    }, [setValue, stateAffectations.affecationSelected, stateAffectations.affecationSelected?.codeStatut, mode, stateHoraires.horairesAffectations, stateCollaborateurs?.collaborateurSelected?.typePersonnel]);

    return (
        <Stack spacing={1}>
            {mode === "edition" ?
                <TextContainer p={1} bgColor={whiteColor[5]} textWeight="bold">Date de l'affectation : {date?.format("DD/MM/YYYY")}</TextContainer>
            :
                <DateHookFormInput name="date" label="Date" /> 
            }
            <HorairesAffectationsIndividuellesInput
                name="horaire" 
                label="Horaire"
                codeSociete={stateCollaborateurs?.collaborateurSelected?.codeSociete} 
                date={date} 
                open={open}
                tooltipPlacement="left"
            />
            {stateCollaborateurs?.collaborateurSelected?.typePersonnel === "P" &&
                <Box bgcolor={whiteColor[5]} borderRadius={2} py={1} px={2}>
                    <RadiosHookFormInput name="statut" row={2} options={statuts} />
                </Box>
            }
        </Stack>
    );
}

AffectationIndividuelleHoraireForm.defaultProps = { 
    mode: "ajout",
    open: false
}

AffectationIndividuelleHoraireForm.propTypes = {
    mode: PropTypes.string,
    open: PropTypes.bool
}

export default AffectationIndividuelleHoraireForm;