import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { TextHookFormInput } from "@components/Inputs";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor } from "@styles";

const MotifRefusDemandeRegularisationForm = (props) => {
    const { isLoading } = props;

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader size={17} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    return (
        <TextHookFormInput name={"motifRefus"} label="Motif de refus" maxLength={50} />
    );
}


MotifRefusDemandeRegularisationForm.defaultProps = {
    isLoading: false,

}

MotifRefusDemandeRegularisationForm.propTypes = {
    isLoading: PropTypes.bool,

}

const styles = {
    loaderStyle: {
        height: 52,
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
    }
}

export default MotifRefusDemandeRegularisationForm;