import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { HeaderPanelContainer } from "@components/Containers";
import { FilterList, FilterListOff, FormatListBulleted, HourglassEmpty, ListAlt, MobiledataOff, Refresh, SwapVert, ViewList, ViewModule } from "@mui/icons-material";
import { PAGES_GESTION_ABSENCES } from "@constants/demandesAbsenceConstants";
import { useMedia } from "react-use";

const DemandesAbsenceActionsBar = ({ 
    isLoading = true,
    isError = false,
    listed = false,
    tried = false,
    filtered = false,
    all = false,
    deleteTrie = () => {},
    deleteFiltre = () => {},
    openTrie = () => {},
    setOpenFiltre = () => {},
    goTo = () => {},
    filtrerParReponse = () => {},
    lister = () => {},
    refetch = () => {}
}) => {
    const isMobile = useMedia('(max-width: 890px)');

    return (
        <HeaderPanelContainer 
            actionsLeft={
                <>
                    <IconButtonBase 
                        variant="white5Grey9White9"
                        icon={tried ? <MobiledataOff fontSize="small" /> : <SwapVert fontSize="small" />} 
                        title={tried ? "Enlever le trie" : "Trier"} 
                        disabled={isLoading || isError}
                        action={tried ? () => deleteTrie() : openTrie} 
                    /> 
                    <IconButtonBase 
                        variant="white5Grey9White9"
                        icon={filtered ? <FilterListOff fontSize="small" /> : <FilterList fontSize="small" />} 
                        title={filtered ? "Enlever le filtre" : "Filtrer"}
                        disabled={isLoading || isError}
                        action={filtered ? () => deleteFiltre() : () => setOpenFiltre(true)} 
                    /> 
                </>
            }
            actionsRight={
                <>      
                    <IconButtonBase 
                        variant="white5Grey9White9"
                        icon={<FormatListBulleted fontSize="small" />} 
                        title="Soldes"
                        disabled={isLoading || isError} 
                        action={() => goTo(PAGES_GESTION_ABSENCES.SOLDES)} 
                    />                
                    <IconButtonBase
                        variant="white5Grey9White9"
                        icon={all ? <HourglassEmpty fontSize="small" /> : <ListAlt fontSize="small" />} 
                        title={all ? "Absences en attente" : "Toutes les absences"} 
                        disabled={isLoading || isError} 
                        action={() => filtrerParReponse()} 
                    />                  
                    {!isMobile && 
                        <IconButtonBase 
                            variant="white5Grey9White9"
                            icon={listed ? <ViewModule fontSize="small" /> : <ViewList fontSize="small" />} 
                            title={listed ? "Panneaux" : "Liste"} 
                            disabled={isLoading || isError} 
                            action={() => lister()} 
                        />
                    }              
                    <IconButtonBase 
                        variant="white5Grey9White9"
                        icon={<Refresh fontSize="small" />} 
                        title="Rafraichir" 
                        disabled={isLoading} 
                        action={() => refetch()} 
                    /> 
                </>                            
            }
            variant="green07"
        />
    );
}

DemandesAbsenceActionsBar.propTypes = {
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool,
    listed: PropTypes.bool,
    tried: PropTypes.bool,
    filtered: PropTypes.bool,
    all: PropTypes.bool,
    deleteTrie: PropTypes.func,
    deleteFiltre: PropTypes.func,
    openTrie: PropTypes.func,
    setOpenFiltre: PropTypes.func,
    goTo: PropTypes.func,
    filtrerParReponse: PropTypes.func,
    lister: PropTypes.func,
    refetch: PropTypes.func
}

export default DemandesAbsenceActionsBar;