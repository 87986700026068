import axios from "axios";
import { urls } from "@constants/appConstants";

export const fetchSta = async (matricule, type, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetStatut"
    };
    const body = {
        matricule: matricule,
        type: type,
    };
    const response = await axios.post(urls.getStatut, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.statut;
}

export const editSta = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostStatut"
    };
    const body = {
        matricule: data.matricule,
        type: data.type,
        date: data.date,
        heure: data.heure,
        activite: data.activite,
        option: data.option
    };
    const response = await axios.post(urls.postStatut, body, { headers: headers, params: { client: data.client } });
    return response.data;
}