import { arrange, asc, desc, tidy } from "@tidyjs/tidy";
import moment from "moment";

export const trierDocApp = (keyTrie, data) => {
    switch(keyTrie) {
        case "dateAsc":
            return tidy(data, arrange((da, db) => moment(da.dateDocument, "DDMMYYYY").utc() - moment(db.dateDocument, "DDMMYYYY").utc()));
        case "dateDesc": 
            return tidy(data, arrange((da, db) => moment(db.dateDocument, "DDMMYYYY").utc() - moment(da.dateDocument, "DDMMYYYY").utc()));
        case "natureAsc": 
            return tidy(data, arrange([asc("natureDocument")]));
        case "natureDesc":
            return tidy(data, arrange([desc("natureDocument")]));
        case "nomAsc":
            return tidy(data, arrange([asc("nom")]));
        case "nomDesc":
            return tidy(data, arrange([desc("nom")]));
        default: 
            return data;
    }
};
