import { useCallback } from "react";
import { useSelector } from "react-redux";
import { selectPlaHor } from "@selectors";
import PlagesVariables from "../PlagesVariables";
import PlageDebutVariable from "../PlageDebutVariable";
import PlageRepriseVariable from "../PlageRepriseVariable";
import PlageNonVariable from "../PlageNonVariable";
import { Box, Stack, Typography } from "@mui/material";
import { Restaurant } from "@mui/icons-material";
import { greenColor, redColor } from "@styles";

const PlagesHoraires = () => {
    const statePlagesHoraire = useSelector(selectPlaHor);

    const plagesMatinRoute = useCallback((type) => {
        if(type === "variable") {
            return <PlagesVariables plagesHoraire={statePlagesHoraire.matin.plages} />;
        }
        if(type === "variableDebut") {
            return <PlageDebutVariable plagesHoraire={statePlagesHoraire.matin.plages} />
        }
        if(type === "variableReprise") {
            return <PlageRepriseVariable plagesHoraire={statePlagesHoraire.matin.plages} />
        }
        return <PlageNonVariable plagesHoraire={statePlagesHoraire.matin.plages} />
    }, [statePlagesHoraire]);

    const plagesApresMidiRoute = useCallback((type) =>  {
        if(type === "variable") {
            return <PlagesVariables plagesHoraire={statePlagesHoraire.apresMidi.plages} />
        }
        if(type === "variableDebut") {
            return <PlageDebutVariable plagesHoraire={statePlagesHoraire.apresMidi.plages} />
        }
        if(type === "variableReprise") {
            return <PlageRepriseVariable plagesHoraire={statePlagesHoraire.apresMidi.plages} />
        }
        return <PlageNonVariable plagesHoraire={statePlagesHoraire.apresMidi.plages} />
    }, [statePlagesHoraire]);

    return (
        <Box p={1} width="100%">
            {(statePlagesHoraire.matin.plages.debutVariable || statePlagesHoraire.matin.plages.debutFixe || statePlagesHoraire.matin.plages.repriseFixe || statePlagesHoraire.matin.plages.repriseVariable) && (<Stack spacing={0.5}>
                <Box paddingX={1} paddingTop={1}>
                    <Box width="100%" bgcolor={greenColor["03"]} height={5} borderRadius={6} justifyContent={statePlagesHoraire.matin.position} display="flex">
                        <Box width={statePlagesHoraire.matin.longueur} bgcolor={redColor[3]} height={5} borderRadius={6}></Box>
                    </Box>
                </Box>
                {plagesMatinRoute(statePlagesHoraire.matin.type)} 
            </Stack>)}
            {statePlagesHoraire.infos.repas &&
                <Box display={"inline-flex"} alignItems="center" justifyContent="center" width="100%">
                    <Restaurant fontSize="small" />
                    <Typography fontSize={"11px"} paddingX={1}>{statePlagesHoraire.infos.repas}</Typography> 
                </Box>
            }
            {(statePlagesHoraire.apresMidi.plages.debutVariable || statePlagesHoraire.apresMidi.plages.debutFixe || statePlagesHoraire.apresMidi.plages.repriseFixe || statePlagesHoraire.apresMidi.plages.repriseVariable) && (<Stack spacing={0.5}>
                <Box paddingX={1} paddingTop={1}>
                    <Box width="100%" bgcolor={greenColor["03"]} height={5} borderRadius={6} justifyContent={statePlagesHoraire.apresMidi.position} display="flex">
                        <Box width={statePlagesHoraire.apresMidi.longueur} bgcolor={redColor[3]} height={5} borderRadius={6}></Box>
                    </Box>
                </Box>
                {plagesApresMidiRoute(statePlagesHoraire.apresMidi.type)} 
            </Stack>)}
        </Box>
    )
}

export default PlagesHoraires;