
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectDsh } from "@selectors";
import { getShortcutAdded } from "@utils";
import * as dshActions from "@reducers/dashboard/dshReducer"
import { ButtonBase } from "@components/Buttons";
import { useTranslation } from "react-i18next";

const PointageShortcutConfigurationAdd = () => {
    const stateDashboard = useSelector(selectDsh);
    const dispatch = useDispatch();
    const { t } = useTranslation("global");

    const add = useCallback(() => {
        const updateState = getShortcutAdded("sPointage", stateDashboard.shortcutsActives, stateDashboard.shortcutsNonActives);
        dispatch(dshActions.editShortcut(updateState));
    }, [dispatch, stateDashboard.shortcutsActives, stateDashboard.shortcutsNonActives]);

    return (
        <ButtonBase 
            variant="primary"
            action={add}
        >
            {t("texts.shortcuts_activities")}
        </ButtonBase>
    );
}

export default PointageShortcutConfigurationAdd;