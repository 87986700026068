import { useSelector } from "react-redux";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useFormContext, useFormState } from "react-hook-form";
import PropTypes from "prop-types";
import { selectSocAffHorCol } from "@selectors";
import { DateHookFormInputMobile } from "@components/Inputs";
import { IconButtonBase } from "@components/Buttons";
import { Loader } from "@components/Loaders";
import { HeaderListContainer } from "@components/Containers";
import { HorairesAffectationsCollectivesInput } from "@components/Inputs/Horaires";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";
import PerfectScrollbar from "react-perfect-scrollbar";

const headerItems = [
    {id: 1, label: "Date d'effet", xs: 5, display: true},
    {id: 2, label: "Horaire", xs: 5, display: true}
];

const AffectationsCollectivesHoraireFormMobile = (props) => {
    const { fields, isLoading, remove } = props;
    const { control, watch } = useFormContext();
    const { errors } = useFormState({ control });
    const stateSocietes = useSelector(selectSocAffHorCol); 
    const watchFieldArray = watch("affectations");
    const controlledFields = fields.map((field, id) => {
        return {
          ...field,
          ...watchFieldArray[id]
        };
    });

    return (   
        isLoading ? 
            <Box sx={styles.loaderStyle}>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box> 
        :
            <Stack spacing={0.5}>
                <HeaderListContainer itemIdReference="HAFFCC" headerItems={headerItems} />
                <Box overflow="auto" >
                    <PerfectScrollbar>
                        <Stack spacing={0.5}>
                            {controlledFields.map((field, id) => (
                                <Box key={`INPUTAFFCC${field.id}`} p={0.5} bgcolor={greenColor["015"]} borderRadius={2}>
                                    <Grid container spacing={0.5}>
                                        <Grid item xs={5}>
                                            <DateHookFormInputMobile name={`affectations.${id}.dateEffet`} label="Date d'effet" /> 
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Box bgcolor={redColor[2]} borderRadius={2}>
                                                <HorairesAffectationsCollectivesInput name={`affectations.${id}.horaire`} label="Horaire" codeSociete={stateSocietes?.societeSelected?.codeSociete} date={watchFieldArray[id]?.dateEffet} />
                                                {errors?.affectations?.[id]?.horaire && <Box sx={styles.boxInputErrorStyle}>
                                                        <Typography sx={styles.typoInputErrorStyle}>Veuillez selectionner un horaire</Typography>
                                                    </Box>
                                                }
                                            </Box>
                                        </Grid>
                                        {controlledFields.length > 1 && 
                                            <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
                                                <IconButtonBase 
                                                    icon={<Close fontSize="small" />} 
                                                    title="Enlever l'horaire" 
                                                    colorIconButton={redColor[7]} 
                                                    bgColor={whiteColor[5]} 
                                                    action={() => remove(id)} 
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                </Box>
                            ))}
                        </Stack>
                    </PerfectScrollbar>
                </Box>
            </Stack>
    );
}

AffectationsCollectivesHoraireFormMobile.defaultProps = {
    fields: [],
    isLoading: false,
    remove: () => {}
}

AffectationsCollectivesHoraireFormMobile.propTypes = {
    fields: PropTypes.array,
    isLoading: PropTypes.bool,
    remove: PropTypes.func
}

const styles = {
    stackInputStyle: {
        borderRadius: 2,
        backgroundColor: redColor[1]
    },
    boxInputStyle: {
        borderRadius: 2,
        backgroundColor: whiteColor[9]
    },
    loaderStyle: {
        height: 100,
        alignItems: "center",
        display: "flex"
    },
    boxInputErrorStyle: {
        textAlign: "left",
        borderBottomLeftRadius: 8, 
        borderBottomRightRadius: 8,
        padding: 1
    },
    typoInputErrorStyle: {
        fontSize: "small", 
        color: greyColor[5]
    }
}

export default AffectationsCollectivesHoraireFormMobile;