import { Box, Grid, Grow, Stack, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { HeaderListContainer, ItemContainer, ListContainer } from "@components/Containers";
import { CheckBoxButton, greenColor, whiteColor } from "@styles";
import PerfectScrollbar from "react-perfect-scrollbar";

const CollaborateursFwgtp2List = ({
    data = [], 
    headers = [], 
    collaborateursChecked = [], 
    openFiltre = false,
    selectCollaborateur = () => {}
}) => {
    return (
        <Stack spacing={0.5}>
            <HeaderListContainer itemIdReference="CBODYGT" headerItems={headers} />
            <PerfectScrollbar> 
                <Box maxHeight={openFiltre ? "calc(100vh - 378px)" : "calc(100vh - 206px)"}>
                    <ListContainer spacing={0.5} length={data.length} noDataLabel="Aucune personne">
                        {data.map((collaborateur, id) => (
                            <Grow key={`PRS${collaborateur.id}`} in={true} timeout={400+(200*id)}>
                                <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.3}>
                                    <Grid container spacing={0.3}>
                                        <ItemContainer firstData data={collaborateur.prenomNom} xs={1.5} />
                                        <ItemContainer data={collaborateur.matricule} xs={0.75} />
                                        <ItemContainer data={collaborateur.badge} xs={0.75} />
                                        <ItemContainer data={collaborateur.libelleSociete} xs={1} />
                                        <ItemContainer data={collaborateur.libelleEtablissement} xs={1} />
                                        <ItemContainer data={collaborateur.libelleDirection} xs={1.5} />
                                        <ItemContainer data={collaborateur.libelleService} xs={1.5} />
                                        <ItemContainer data={collaborateur.libelleSecteur} xs={1} />
                                        <ItemContainer data={collaborateur.typePersonnel === "P" ? "Salarié" : "Intérimaire"} xs={1} />
                                        <ItemContainer data={collaborateur.controleHoraire === "O" ? "Oui" : "Non"} xs={0.75} />
                                        <ItemContainer lastData data={collaborateur.presentADate === "O" ? "Oui" : "Non"} xs={0.75} />
                                        <Grid item xs={0.5} textAlign="center" display="flex" alignItems="center" justifyContent="center">
                                            <Tooltip title="Selectionner" placement="left">
                                                <Box component="span">
                                                    <CheckBoxButton  
                                                        variant="white5Grey7White5"
                                                        size="small" 
                                                        bgcolor={whiteColor[5]} 
                                                        checked={collaborateursChecked.includes(collaborateur.id)} 
                                                        value={collaborateur.id} 
                                                        onChange={selectCollaborateur} 
                                                    />
                                                </Box>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grow>
                        ))}
                    </ListContainer>
                </Box>
            </PerfectScrollbar>
        </Stack>
    );
}

CollaborateursFwgtp2List.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    headers: PropTypes.array,
    selectCollaborateur: PropTypes.func,
    openFiltre: PropTypes.bool,
    collaborateursChecked: PropTypes.array
}

export default CollaborateursFwgtp2List;