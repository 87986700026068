import { Box, Paper, Stack, Typography } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import moment from "moment";
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from "prop-types";
import { selectUsrApp } from "@selectors";
import { useSelector } from "react-redux";
import { useMeteo } from "@hooks/meteo/hooksQueries";
import { Loader } from "@components/Loaders";
import { IconButtonBase } from "@components/Buttons";
import { HeaderPanelContainer, TextContainer } from "@components/Containers";
import { WidgetError } from "@components/Errors";
import { getWhiteColor, greenColor, greyColor, whiteColor } from "@styles";
import { useGeolocation } from "react-use";

const Meteo = (props) => {
    const { coordonnees } = props;
    const { data, isLoading, isFetching, isError, error, refetch } = useMeteo(coordonnees);
    
    if(isLoading || isFetching) {
        return <Box sx={styles.loaderStyle}><Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} /></Box>;
    }

    if(isError) {
        return <WidgetError error={error.message} />;
    }

    return (
        <Stack spacing={0.5} padding={0.5} height="100%" boxSizing="border-box">
            <HeaderPanelContainer 
                actionsRight={
                    <IconButtonBase icon={<Refresh fontSize="small" />} title="Rafraichir" action={refetch} />
                }
                padding={0.5}
                bgColor={greenColor["015"]}
                title={data.city}
            />
            <Box height="100%" overflow="auto">
                <Scrollbars autoHide>
                    <Stack spacing={0.5} direction="row" height="100%">
                        {data.meteo.map((item) => (
                            <Box key={`DMETEO${item.id}`}>
                                <Stack spacing={0.5} height="100%">
                                    <TextContainer bgColor={greenColor["015"]} p={1}>{item.date}</TextContainer>
                                    <Stack spacing={0.5} direction="row" height="100%">
                                        {item.weather.map((item, id) => (
                                            <Stack textAlign="center" justifyContent="center" display="flex" key={`METEO${id}`} sx={styles.boxMeteoStyle}>
                                                <Typography fontSize="small">{moment(item.dt_txt, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}</Typography>
                                                <Typography fontSize="small">
                                                    <img src={`${process.env.REACT_APP_ICON_URL}/${item.weather[0].icon}.png`} style={{verticalAlign: "middle"}} alt="Ico weather" width={30}></img>
                                                </Typography>
                                                <Typography fontSize="small">
                                                        {Math.round(item.main.temp)} °C
                                                </Typography>
                                            </Stack>
                                        ))}
                                    </Stack>
                                </Stack>
                            </Box>
                        ))}
                    </Stack>
                </Scrollbars>
            </Box>
        </Stack>
    )
}

const Contenu = () => {
    const coordonnees = useGeolocation({enableHighAccuracy: true});

    if(coordonnees.loading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(coordonnees?.error?.POSITION_UNAVAILABLE === coordonnees?.error?.code && coordonnees?.error) {
        return <WidgetError error={"Géolocalisation non disponible avec votre navigateur"} />;
    }
    
    if(coordonnees?.error?.PERMISSION_DENIED === coordonnees?.error?.code && coordonnees?.error) {
        return <WidgetError error={"Géolocalisation non activée"} />;
    }

    return (
        <Meteo coordonnees={coordonnees} />
    );
}

const MeteoWidget = () => {
    const stateUser = useSelector(selectUsrApp);

    return (
        <Paper sx={stateUser.background ? styles.paperStyle : styles.paperStyleNoBackground} elevation={0}>
            <Contenu />
        </Paper>
    );
}

Meteo.propTypes = {
    coordonnees: PropTypes.object
}

const styles = {
    paperStyle: {
        backgroundColor: getWhiteColor("0.95"),
        backdropFilter: "blur(3px)",
        width: "100%",
        height: "200px",
        borderRadius: 2
    },
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        height: "100%"
    },
    boxMeteoStyle: {
        p: 1.5, 
        borderRadius: 2, 
        backgroundColor: greenColor["015"]
    },
    boxMeteoConfigurationStyle: {
        p: 1.5, 
        borderRadius: 2, 
        backgroundColor: whiteColor[5]
    },
    widgetTitleStyle: {
        backgroundColor: greyColor[0], 
        pl: 1,
        alignItems: "center",
        display: "flex", 
        borderRadius: 1
    },
    paperStyleNoBackground: {
        backdropFilter: "blur(3px)",
        backgroundColor: greenColor["02"],
        width: "100%",
        height: "200px",
        borderRadius: 2
    },
    widgetTitleConfigurationStyle: {
        backgroundColor: whiteColor[5], 
        pl: 1,
        alignItems: "center",
        display: "flex", 
        borderRadius: 1
    }
};

export default MeteoWidget;