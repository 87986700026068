import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { selectCltApp } from "@selectors";
import { fetchEmpPla } from "@api/emplois";

export const useEmpPlaInp = (dateDebut, dateFin, open) => {
    const stateClient = useSelector(selectCltApp);
    const body = { dateDebut: dateDebut, dateFin: dateFin };

    return useQuery(['empInp', dateDebut, dateFin], ({ signal }) => fetchEmpPla(body, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open && !!dateDebut && !!dateFin
    });
}
