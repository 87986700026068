import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    periode: "",
    solde: null,
    matricule: "" 
}

const sitSolAppSlice = createSlice({
    name: 'sitSolApp',
    initialState: initialState,
    reducers: {
        selectSolde: (state, action) => {
            state.periode = action.payload.periode;
            state.solde = action.payload.solde;
            state.matricule = action.payload.matricule;
        },
        setPeriode: (state, action) => {
            state.periode = action.payload;
        },
    },
})

const { actions, reducer } = sitSolAppSlice
export const { selectSolde, setPeriode } = actions
export default reducer