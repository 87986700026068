import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";
import { ArrowLeft, Info } from "@mui/icons-material";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { selectEntSecCol } from "@selectors";
import { useMappingActionsCol } from "@hooks/gestionEntretiens/hooksUtils";
import { IconButtonBase } from "@components/Buttons";
import { CompetenceDialog } from "@components/GestionEntretiens/Collaborateur/Dialogs";
import { greenColor, whiteColor } from "@styles";
import ComplementsContent from "./ComplementsContent";
import EvaluationContent from "./EvaluationContent";
import DateContent from "./DateContent";
import CommentaireContent from "./CommentaireContent";

const Competence = ({ 
    open = false, 
    goBack = () => {}
}) => {
    const [openCompetence, setOpenCompetence] = useState(false);
    const stateSections = useSelector(selectEntSecCol);
    const sectionSelected = stateSections?.sectionSelected;
    const { designation1, designation2 } = useMappingActionsCol()
    
    return (
        <>
            <Box borderRadius={2} bgcolor={greenColor["05"]} height={50} p={0.5}>
                <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[7]} height="100%" alignItems="center" px={1}>
                    <IconButtonBase icon={<ArrowLeft fontSize="small" />} title="Retour" action={() => goBack()} />
                    <Box flexGrow={1}>
                        <Typography fontSize="small" fontWeight="bold">{designation1}</Typography>
                        <Typography fontSize="small" fontWeight="bold">{designation2}</Typography>
                    </Box>
                    <IconButtonBase icon={<Info fontSize="small" />} title="Informations compétence" action={() => setOpenCompetence(true)} />
                </Stack>
            </Box>
            <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                <Box borderRadius={2} bgcolor={whiteColor[7]} p={1}>
                    <Typography fontSize={"small"} fontWeight={"bold"}>{sectionSelected?.designationCompetence}</Typography>
                </Box>
            </Box>
            <PerfectScrollbar> 
                <Stack spacing={0.5}>
                    <ComplementsContent />
                    <EvaluationContent />
                    <DateContent />
                    <CommentaireContent open={open} codeQuestion={0} />
                </Stack>
            </PerfectScrollbar>
            <CompetenceDialog open={openCompetence} setOpen={setOpenCompetence} />
        </>
    );
}

Competence.propTypes = {
    open: PropTypes.bool,
    goBack: PropTypes.func
}

export default Competence;