import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Grow, Stack, Typography } from "@mui/material";
import { Close, Download } from "@mui/icons-material";
import PropTypes from "prop-types";
import voca from "voca";
import { selectEntCol, selectEntSecCol } from "@selectors";
import * as entSecColActions from "@reducers/gestionEntretiens/entSecColReducer";
import * as entColActions from "@reducers/gestionEntretiens/entColReducer";
import { DialogFullScreenContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { Informations } from "@components/GestionEntretiens/Collaborateur/Informations";
import { MappingSectionsAccessibles } from "@components/GestionEntretiens/Collaborateur/Mapping";
import { greenColor, greyColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";
import { useDownloadEntretien } from "@hooks/gestionEntretiens/hooksQueries";
import { Loader } from "@components/Loaders";
import DeleteSousSectionDialog from "./DeleteSousSectionDialog";

const SectionsAccessiblesDialog = ({ 
    open = false, 
    setOpen = () => {}
}) => {
    const [sousSection, setSousSection] = useState(null);
    const [openWarningDeleteSousSection, setOpenWarningDeleteSousSection] = useState(false);
    const stateEntretiens = useSelector(selectEntCol);
    const stateSections = useSelector(selectEntSecCol);
    const { isLoadingDownload, downloadEntretienCollaborateurActionner } = useDownloadEntretien();
    const dispatch = useDispatch();
    const { t } = useTranslation("global");

    useEffect(() => {
        if(open) {
            dispatch(entSecColActions.setNiveau(1));
        }
    }, [dispatch, open]);
    
    const close = useCallback(() => {
        setOpen(false);
        dispatch(entSecColActions.reset());
        dispatch(entColActions.selectEntretien(null));
    }, [dispatch, setOpen]);

    const goSection = useCallback((level, section, idQuestion = 1, question = false) => {
        dispatch(entSecColActions.setNiveau(level));
        if(level === 2 && section) {
            const flow = section?.typeFlow;
            const typeNiv2 = voca.substr(flow, 0, 2);
            const typeNiv3 = voca.substr(flow, 2, 2);
            const typeNiv4 = voca.substr(flow, 4, 2);
            dispatch(entSecColActions.setFlows({ flow, typeNiv2, typeNiv3, typeNiv4 }));
        }
        if(question) {
            dispatch(entSecColActions.selectQuestion(idQuestion));
        }
        dispatch(entSecColActions.selectSection(section));
    }, [dispatch]);

    const goBack = useCallback(() => {
        const niveauCible = stateSections.niveau - 1;
        dispatch(entSecColActions.setNiveau(niveauCible));
        dispatch(entSecColActions.setModeAjout(false));
    }, [dispatch, stateSections]);
    
    const openWarningSousSection = useCallback((sousSection) => {
        setSousSection(sousSection);
        setOpenWarningDeleteSousSection(true);
    }, []);

    return (
        <>
            <DialogFullScreenContainer open={open} taille="lg" placement="top" bgColor="rgba(0,0,0,0)" paddingBox={0}>
                <Box bgcolor={whiteColor[9]} borderRadius={2} p={0.5} height={62}>
                    <Box bgcolor={greenColor["07"]} p={0.5} textAlign="center" borderRadius={2} height="100%" boxSizing="border-box">
                        <Box height="100%" boxSizing="border-box" display="flex" alignItems="center">
                            <Grid container item xs={12} alignItems="center">
                                <Grid item xs={3} textAlign="left">
                                    <Stack direction="row" spacing={0.5} px={1}>
                                        {isLoadingDownload ? 
                                            <Box width={30} height={30} alignItems="center" display="flex" justifyContent="center">
                                                <Loader size={17} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                                            </Box>
                                        :
                                            <IconButtonBase 
                                                variant="dialogButton"
                                                icon={<Download fontSize="small" />} 
                                                title="Télécharger l'entretien" 
                                                action={() => downloadEntretienCollaborateurActionner()} 
                                            />
                                        }
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography fontWeight="bold" color={whiteColor[9]}>Contenu de l'entretien</Typography>
                                </Grid>
                                <Grid item xs={3} textAlign="right">
                                    <Box px={1}>
                                        <IconButtonBase 
                                            variant="closeDialogButton"
                                            icon={<Close fontSize="small"/>} 
                                            title={t("buttons.close")} 
                                            action={() => close()} 
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
                <Stack spacing={0.5} height="100%">
                    {stateEntretiens.entretienSelected ?
                        <Stack direction="row" spacing={1} height={"calc(100vh - 94px)"}>
                            <Informations />
                            <Stack spacing={0.5} borderRadius={2} bgcolor={whiteColor[9]} width="50%" p={0.5}>
                                <MappingSectionsAccessibles 
                                    open={open}
                                    goSection={goSection}
                                    goBack={goBack}
                                    openWarningSousSection={openWarningSousSection}
                                />
                            </Stack>
                        </Stack>
                    :
                        <Grid container height={"100%"}>
                            <Grow in={true} timeout={500}>
                                <Box borderRadius={2} bgcolor={greenColor["015"]} width="100%" p={1} display="flex" alignItems="center" justifyContent="center"> 
                                    <Typography fontSize="small">Aucun entretien sélectionné</Typography>
                                </Box>
                            </Grow>
                        </Grid>
                    }
                </Stack>
            </DialogFullScreenContainer>
            <DeleteSousSectionDialog
                open={openWarningDeleteSousSection} 
                setOpen={setOpenWarningDeleteSousSection} 
                goBack={goBack}
                sousSection={sousSection}
            />
        </>
    );
}

SectionsAccessiblesDialog.propTypes = {
    open: PropTypes.bool, 
    setOpen: PropTypes.func
}

export default SectionsAccessiblesDialog;