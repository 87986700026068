import { Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { useDanAbsGesAbs, useDanAbsGesAbsPos } from "@hooks/gestionAbsences/demandes/hooksQueries";
import { Check } from "@mui/icons-material";
import { ButtonBase } from "@components/Buttons";
import { AnnulationDemandesAbsenceDialog } from "./Dialogs";
import { DemandesAnnulationList, DemandesAnnulationListMobile } from "./Lists";
import { useMedia } from "react-use";
import { useTranslation } from "react-i18next";

const DemandesAnnulation = () => {
    const [openAnnulationAbsence, setOpenAnnulationAbsence] = useState(false);    
    const [demandesSelected, setDemandesSelected] = useState([]);
    const { data, isLoading, isFetching, isError, error, refetch } = useDanAbsGesAbs();
    const { data: dataAnnulation, isLoading: isLoadingAnnulation, isError: isErrorAnnulation, error: errorAnnulation, postDemandeAnnulation, postRecapitulatifAnnulation } = useDanAbsGesAbsPos();
    const isMobile = useMedia('(max-width: 650px)');
    const { t } = useTranslation("global");

    const select = useCallback((event) => {
        let updatedList = [...demandesSelected];
        if(event.target.checked) {
            updatedList = [...demandesSelected, Number(event.target.value)];
        } else {
            updatedList.splice(demandesSelected.indexOf(Number(event.target.value)), 1);
        }
        setDemandesSelected(updatedList);
    }, [demandesSelected]);


    const handleOpenDemandeAnnulationDialog = useCallback(async () => {
        const { isSucceed } = await postRecapitulatifAnnulation(demandesSelected);
        if(isSucceed) {
            setOpenAnnulationAbsence(true);
        }
    }, [demandesSelected, postRecapitulatifAnnulation]);

    const handleConfirm = useCallback(async (data) => {
        const { isSucceed } = await postDemandeAnnulation(demandesSelected, data.motif);
        if(isSucceed) {
            setOpenAnnulationAbsence(false);
            setDemandesSelected([]);
            refetch();
        }
    }, [demandesSelected, postDemandeAnnulation, refetch]);

    return(
        <>
            <Stack spacing={0.5}>          
                {isMobile ? 
                    <DemandesAnnulationListMobile
                        data={data}
                        isLoading={isLoading || isFetching}
                        isError={isError}
                        errorMessage={error?.message}
                        demandesSelected={demandesSelected}
                        select={select}
                    />
                :
                    <DemandesAnnulationList
                        data={data}
                        isLoading={isLoading || isFetching}
                        isError={isError}
                        errorMessage={error?.message}
                        demandesSelected={demandesSelected}
                        select={select}
                    />
                }
                {(!isLoading && !isFetching) && 
                    <ButtonBase 
                        variant="green25Grey5Green7"
                        startIcon={<Check color={demandesSelected.length > 0 ? "inherit" : "disabled" } />} 
                        disabled={demandesSelected.length === 0} 
                        action={handleOpenDemandeAnnulationDialog} 
                    >
                        {t("buttons.validate_selection")}
                    </ButtonBase>  
                }
            </Stack>            
            <AnnulationDemandesAbsenceDialog 
                data={dataAnnulation}
                isLoading={isLoadingAnnulation} 
                isError={isErrorAnnulation} 
                error={errorAnnulation}
                open={openAnnulationAbsence} 
                setOpen={setOpenAnnulationAbsence} 
                handleConfirm={handleConfirm}
            />   
        </>
    );
}

export default DemandesAnnulation;