import { useSelector } from "react-redux";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { selectPlaHor } from "@selectors";
import { blueColor, whiteColor } from "@styles";
import { Info } from "@mui/icons-material";

const DateBarMobile = (props) => {
    const { data, isLoading } = props;
    const statePlagesHoraires = useSelector(selectPlaHor);
    const dateMoment = moment(statePlagesHoraires.datePlagesHoraires, "DDMMYYYY");
    const jourString = `${dateMoment.format("dddd").substring(0, 1).toUpperCase()}${dateMoment.format("dddd").substring(1)}`;
    const jour = dateMoment.format("D");
    const mois = dateMoment.format("MMMM");
    const annee = dateMoment.format("YYYY");
    const semaine = dateMoment.format("ww");

    if(isLoading) {
        return <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={"100%"} height={35.5} />;
    }

    return (
        <Stack sx={styles.boxBandeauDateStyle}>
            <Box flexGrow={1}>
                <Typography fontSize={"small"} fontWeight="bold">{jourString} {jour} {mois} {annee} - semaine {semaine}</Typography>
            </Box>
            {data?.infos?.libelleFerie &&   
                <Stack direction="row" spacing={1} flexGrow={1} display="flex" alignItems="center">
                    <Info fontSize="small" sx={{ color: blueColor["07"] }} />
                    <Typography fontSize={"small"} fontWeight="bold">{data?.infos?.libelleFerie}</Typography>
                </Stack>
            }
        </Stack>
    );
}

DateBarMobile.defaultProps = {
    data: null,
    isLoading: true
}

DateBarMobile.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool
}

const styles = {
    boxBandeauDateStyle: {
        backgroundColor: whiteColor[5], 
        paddingX: 1,
        paddingY: 1,
        borderRadius: 2,
    }
}

export default DateBarMobile;