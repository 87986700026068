import { Box, CircularProgress, circularProgressClasses } from "@mui/material";
import PropTypes from "prop-types";
import { greyColor, whiteColor } from "@styles";

const Loader = (props) => {
    const { size, thickness, colorLoader, colorContainer, animationDuration } = props;
    
    return (
        <Box sx={styles.boxLoaderStyle} height={size}>
            <Box sx={styles.boxCircularProgressStyle}>
                <CircularProgress
                    variant="determinate"
                    sx={{color: colorContainer}}
                    size={size}
                    thickness={thickness}
                    value={100}
                />
            </Box>
            <Box sx={styles.boxCircularProgressStyle}>
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{...styles.secondCircularStyle, animationDuration: animationDuration, color: colorLoader}}
                    size={size}
                    thickness={thickness}     
                />
            </Box> 
        </Box>
    );
}

Loader.defaultProps = {
    size: 27, 
    thickness: 3, 
    colorLoader: whiteColor[9], 
    colorContainer: greyColor[5], 
    animationDuration: "700ms"
}

Loader.propTypes = {
    size: PropTypes.number, 
    thickness: PropTypes.number, 
    colorLoader: PropTypes.string, 
    colorContainer: PropTypes.string, 
    animationDuration: PropTypes.string
}

const styles = {
    boxLoaderStyle: { 
        position: "relative", 
        width: "100%", 
        textAlign: "center" 
    },
    boxCircularProgressStyle: {
        width: "100%",
        position: "absolute"
    },
    secondCircularStyle: {
        [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round"
        }, 
    }
}

export default Loader;