import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment';

const initialState = {
    dateValidite: moment().format("DDMMYYYY"),
    collaborateurs: [],
    collaborateursDisplayed: [],
    filtered: false,
    tried: false,    
    allChecked: false,
    collaborateursSelected: [],
}

const colAffActColSlice = createSlice({
    name: 'colAffActCol',
    initialState: initialState,
    reducers: {
        setCollaborateurs: (state, action) => {
            state.collaborateurs = action.payload;
            state.collaborateursDisplayed = action.payload;
            state.filtered = false;
            state.tried = false;
            state.allChecked = false;
        },
        setDate: (state, action) => {
            state.dateValidite = action.payload;
        },
        filtrer: (state, action) => {
            state.collaborateursDisplayed = action.payload;
            state.filtered = true;
            state.collaborateursSelected = [];
        },
        trier: (state, action) => {
            state.collaborateursDisplayed = action.payload;
            state.tried = true;
        },
        resetFiltre: (state) => {
            state.collaborateursDisplayed = state.collaborateurs;
            state.filtered = false;
        },
        resetTrie: (state) => {
            state.collaborateursDisplayed = state.collaborateurs;
            state.tried = false;
        },
        resetSelection: (state) => {
            state.allChecked = false;
            state.collaborateursSelected = [];
        },
        selectCollaborateurs: (state, action) => {
            state.collaborateursSelected = action.payload
        },
        reset: () => initialState
    },
})

const { actions, reducer } = colAffActColSlice
export const { 
    setCollaborateurs,
    setDate,
    filtrer,
    trier,
    resetFiltre,
    resetTrie,
    resetSelection,
    selectCollaborateurs,
    reset 
} = actions
export default reducer