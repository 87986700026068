import { urls } from "@constants/appConstants";
import axios from "axios";

export const fetchAffHorInd = async (codeSociete, matricule, typePersonnel, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetAffectationsHoraires"
    };
    const body = {
        codeSociete: codeSociete,
        matricule: matricule,
        typePersonnel: typePersonnel
    };
    const response = await axios.post(urls.getAffectationsHoraires, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.affectations;
}

export const fetchAffActInd = async (codeSociete, matricule, typePersonnel, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetAffectationsActivites"
    };
    const body = {
        codeSociete: codeSociete,
        matricule: matricule,
        typePersonnel: typePersonnel
    };
    const response = await axios.post(urls.getAffectationsActivites, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.affectations;
}

export const addAffHor = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostAffectationHoraire"
    };
    const body = {
        codeSociete: data.codeSociete,
        matricule: data.matricule,
        jourEffet: data.jourEffet,
        moisEffet: data.moisEffet,
        anneeEffet: data.anneeEffet,
        codeHoraire: data.codeHoraire,
        codeStatut: data.codeStatut,
        typePersonnel: data.typePersonnel
    };
    const response = await axios.post(urls.postAffectationHoraire, body, { headers: headers, params: { client: data.client } });
    return response.data.affectations;
}

export const addCycAffHor = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostCycleAffectationHoraire"
    };
    const body = {
        matriculeHierarchique: data.matriculeHierarchique,
        matriculeCollaborateur: data.matriculeCollaborateur,
        typePersonnel: data.typePersonnel,
        codeSociete: data.codeSociete,
        dateDebut: data.dateDebut,
        dateFin: data.dateFin,
        cycle: data.cycle,
        horaire: data.horaire,
        origine: data.origine
    };
    const response = await axios.post(urls.postCycleAffectationHoraire, body, { headers: headers, params: { client: data.client } });
    return response.data.affectations;
}

export const addAffHorCol = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostAffectationsCollectivesHoraire"
    };
    const body = {
        matriculeHierarchique: data.matriculeHierarchique,
        codeSociete: data.codeSociete,
        origine: data.origine,
        collaborateurs: data.collaborateurs,
        affectations: data.affectations,
        dateDebut: data.dateDebut,
        dateFin: data.dateFin,
        cycle: data.cycle,
        horaire: data.horaire
    };
    const response = await axios.post(urls.postAffectationsCollectivesHoraire, body, { headers: headers, params: { client: data.client } });
    return response.data.affectations;
}

export const addAffAct = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostAffectationActivite"
    };
    const body = {
        codeSociete: data.codeSociete,
        matricule: data.matricule,
        jourEffet: data.jourEffet,
        moisEffet: data.moisEffet,
        anneeEffet: data.anneeEffet,
        codeActivite: data.codeActivite,
        typePersonnel: data.typePersonnel
    };
    const response = await axios.post(urls.postAffectationActivite, body, { headers: headers, params: { client: data.client } });
    return response.data.affectations;
}

export const addAffActCol = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostAffectationsCollectivesActivite"
    };
    const body = {
        matriculeHierarchique: data.matriculeHierarchique,
        codeSociete: data.codeSociete,
        collaborateurs: data.collaborateurs,
        affectations: data.affectations
    };
    const response = await axios.post(urls.postAffectationsCollectivesActivite, body, { headers: headers, params: { client: data.client } });
    return response.data.affectations;
}

export const editAffHor = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteEditAffectationHoraire"
    };
    const body = {
        codeSociete: data.codeSociete,
        matricule: data.matricule,
        jourEffet: data.jourEffet,
        moisEffet: data.moisEffet,
        anneeEffet: data.anneeEffet,
        codeHoraire: data.codeHoraire,
        codeStatut: data.codeStatut,
        typePersonnel: data.typePersonnel
    };
    const response = await axios.post(urls.editAffectationHoraire, body, { headers: headers, params: { client: data.client } });
    return response.data.affectations;
}

export const editAffAct = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteEditAffectationActivite"
    };
    const body = {
        codeSociete: data.codeSociete,
        matricule: data.matricule,
        jourEffet: data.jourEffet,
        moisEffet: data.moisEffet,
        anneeEffet: data.anneeEffet,
        codeActivite: data.codeActivite,
        typePersonnel: data.typePersonnel
    };
    const response = await axios.post(urls.editAffectationActivite, body, { headers: headers, params: { client: data.client } });
    return response.data.affectations;
}

export const deleteAffHor = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteDeleteAffectationHoraire"
    };
    const body = {
        codeSociete: data.codeSociete,
        matricule: data.matricule,
        jourEffet: data.jourEffet,
        moisEffet: data.moisEffet,
        anneeEffet: data.anneeEffet,
        typePersonnel: data.typePersonnel
    };
    const response = await axios.post(urls.deleteAffectationHoraire, body, { headers: headers, params: { client: data.client } });
    return response.data.affectations;
}

export const deleteAffAct = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteDeleteAffectationActivite"
    };
    const body = {
        codeSociete: data.codeSociete,
        matricule: data.matricule,
        jourEffet: data.jourEffet,
        moisEffet: data.moisEffet,
        anneeEffet: data.anneeEffet,
        typePersonnel: data.typePersonnel
    };
    const response = await axios.post(urls.deleteAffectationActivite, body, { headers: headers, params: { client: data.client } });
    return response.data.affectations;
}