import { Box, Skeleton, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { greenColor, redColor, whiteColor } from "@styles";

const LegendeBar = (props) => {
    const { isLoading } = props;

    if(isLoading) {
        return <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={"100%"} height={35.5} />;
    }

    return (
        <Stack direction="row" spacing={3} bgcolor={whiteColor[5]} borderRadius={2} padding={1}>
            <Box>
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Box sx={styles.boxLegend} bgcolor={greenColor["03"]}></Box>
                    <Box>
                        <Typography fontSize="small">Plages variables</Typography>
                    </Box>
                </Stack>
            </Box>
            <Box>
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Box sx={styles.boxLegend} bgcolor={redColor[3]}></Box>
                    <Box>
                        <Typography fontSize="small">Plages fixes</Typography>
                    </Box>
                </Stack>
            </Box>
        </Stack>
    );
}

LegendeBar.defaultProps = {
    isLoading: true
}

LegendeBar.propTypes = {
    isLoading: PropTypes.bool
}

const styles = {
    boxLegend: {
        borderRadius: 2,
        width: "20px",
        height: "20px",
    }
}

export default LegendeBar;