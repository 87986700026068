import { TextField, MenuItem, Stack, Box, Typography } from "@mui/material";
import { useController, useFormContext, useFormState } from "react-hook-form";
import PropTypes from "prop-types";
import { greyColor, redColor, whiteColor } from "@styles";

const SelectHookFormInput = (props) => {
    const { name, label, options, defaultValue } = props;
    const { control } = useFormContext();
    const { field } = useController({ name, control, defaultValue: "" });
    const { errors } = useFormState({ control });

    return (
        <Stack sx={styles.stackInputStyle}>
            <Box sx={styles.boxInputStyle}>
                <TextField fullWidth select label={label} size="small" {...field}>
                    {defaultValue && <MenuItem key={`SELECTOPT0`} value={defaultValue.value}>{defaultValue.label}</MenuItem>}
                    {options.map((option, id) => (
                        <MenuItem key={`SELECTOPT${id}`} value={option.value}>{option.label}</MenuItem>
                    ))}
                </TextField>
            </Box>
            {errors[name] && 
                <Box sx={styles.boxInputErrorStyle}>
                    <Typography sx={styles.typoInputErrorStyle}>{errors[name].message}</Typography>
                </Box>
            }
        </Stack>
    );
}


SelectHookFormInput.defaultProps = {
    options: [],
    defaultValue: null
}

SelectHookFormInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.array,
    defaultValue: PropTypes.object
}

const styles = {
    stackInputStyle: {
        borderRadius: 2,
        backgroundColor: redColor[1]
    },
    boxInputStyle: {
        borderRadius: 2,
        backgroundColor: whiteColor[9]
    },
    boxInputErrorStyle: {
        textAlign: "left",
        borderBottomLeftRadius: 8, 
        borderBottomRightRadius: 8,
        padding: 1
    },
    typoInputErrorStyle: {
        fontSize: "small", 
        color: greyColor[5]
    }
}

export default SelectHookFormInput;