import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { distinct, map, tidy } from "@tidyjs/tidy";
import moment from "moment";
import PropTypes from "prop-types";
import * as drgGesTemActions from "@reducers/gestionTemps/regularisations/drgGesTemReducer";
import { DateHookFormInput, SelectHookFormInput, TextHookFormInput, TimeMaskedHookFormInput } from "@components/Inputs";
import { ROLE_UTILISATEUR } from "@constants/userConstants";
import { schemaFilterDrgGesTemCol, schemaFilterDrgGesTemMan } from "@components/GestionTemps/DemandesRegularisation/validations";
import { filtrerDrgGesTemCol, filtrerDrgGesTemMan } from "@components/GestionTemps/DemandesRegularisation/filtres";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";
import { selectDrgGesTem } from "@selectors";

const colonneOptionsCollaborateur = [
    { id: 1, value: "date", label: "Date d'émission" },
    { id: 2, value: "heure", label: "Heure d'émission" },
    { id: 3, value: "dateRegularisation", label: "Date de la régularisation" },
    { id: 4, value: "statut", label: "Statut" },
    { id: 4, value: "motif", label: "Motif" }
];

const colonneOptionsManager = [
    { id: 1, value: "date", label: "Date d'émission" },
    { id: 2, value: "heure", label: "Heure d'émission" },
    { id: 3, value: "dateRegularisation", label: "Date de la régularisation" },
    { id: 4, value: "statut", label: "Statut" },
    { id: 5, value: "motif", label: "Motif" },
    { id: 6, value: "emetteur", label: "Emetteur" }
];

const operateurOptions = [
    { id: 1, value: "egale", label: "Egale" },
    { id: 2, value: "superieur", label: "Supérieure" },
    { id: 3, value: "inferieur", label: "Inférieure" }
];

const optionsStatut = [
    { id: 1, value: "Acceptée", label: "Acceptée" },
    { id: 2, value: "Refusée", label: "Refusée" },
    { id: 3, value: "En cours", label: "En cours" },
    { id: 4, value: "Non traitée", label: "Non traitée" },
];

const defaultValues = {
    colonne: "",
    operateur: "egale",
    date: null,
    txt: ""
};

const FiltreDemandesRegularisationForm = (props) => {
    const { userType, setOpen } = props;
    const [emetteurs, setEmetteurs] = useState([]);
    const stateDemandesRegularisation = useSelector(selectDrgGesTem); 
    const schema = userType === ROLE_UTILISATEUR.COLLABORATEUR ? schemaFilterDrgGesTemCol : schemaFilterDrgGesTemMan;
    const colonneOptions = userType === ROLE_UTILISATEUR.COLLABORATEUR ? colonneOptionsCollaborateur : colonneOptionsManager;
    const methods = useForm({ mode: "all", defaultValues: defaultValues, resolver: joiResolver(schema) });
    const colonne = methods.watch("colonne");
    const dispatch = useDispatch();

    useEffect(() => {
        if(colonne === "date" || colonne === "dateRegularisation") {
            methods.resetField("date", moment());
        }
        if(colonne === "heure") {
            methods.resetField("heure", moment());
        }
        if(colonne === "statut" || colonne === "motif" || colonne === "emetteur") {
            methods.resetField("txt", "");
        }
    }, [colonne, methods]);

    useEffect(() => {
        if(userType === ROLE_UTILISATEUR.MANAGER) {
            const result = tidy(stateDemandesRegularisation.demandesRegularisationDisplayed, distinct(["emetteur", "prenomNom"]), map((demande, id) => ({id: id, value: demande.emetteur, label: `${demande.prenomNom} (${demande.emetteur})`})));
            setEmetteurs(result);
        }
    }, [stateDemandesRegularisation.demandesRegularisationDisplayed, userType]);
    
    const filtrer = useCallback((data) => {
        setOpen(false);
        if(userType === ROLE_UTILISATEUR.COLLABORATEUR) {
            const regularisationFiltered = filtrerDrgGesTemCol(data, stateDemandesRegularisation.demandesRegularisationDisplayed);
            dispatch(drgGesTemActions.filtrer(regularisationFiltered));
        }
        if(userType === ROLE_UTILISATEUR.MANAGER) {
            const regularisationFiltered = filtrerDrgGesTemMan(data, stateDemandesRegularisation.demandesRegularisationDisplayed);
            dispatch(drgGesTemActions.filtrer(regularisationFiltered));
        }
    }, [dispatch, setOpen, stateDemandesRegularisation.demandesRegularisationDisplayed, userType]);
    
    return (
        <FormProvider {...methods}>
            <Stack spacing={1}>
                <SelectHookFormInput name="colonne" label="Colonnes" options={colonneOptions} />
                {{
                    date: 
                        <>
                            <SelectHookFormInput name="operateur" label="Opérateur" options={operateurOptions} />
                            <DateHookFormInput name="date" label="Date d'émission" />
                        </>,
                    heure: 
                        <>
                            <SelectHookFormInput name="operateur" label="Opérateur" options={operateurOptions} />
                            <TimeMaskedHookFormInput name="txt" label="Heure d'émission" />
                        </>,
                    dateRegularisation: 
                        <>
                            <SelectHookFormInput name="operateur" label="Opérateur" options={operateurOptions} />
                            <DateHookFormInput name="date" label="Date de la régularisation" />
                        </>,
                    statut: <SelectHookFormInput name="txt" label="Statut" options={optionsStatut} />,
                    motif: <TextHookFormInput name="txt" label="Motif" maxLength={50} />,
                    emetteur: userType === ROLE_UTILISATEUR.MANAGER && <SelectHookFormInput name="txt" label="Emetteur" options={emetteurs} />
                }[colonne]}
            </Stack>
            <Box sx={styles.boxButtonsMenuFiltre}>
                <Stack direction="row" spacing={1}>
                    <Tooltip title="Valider">
                        <IconButton sx={styles.buttonStyleGreen} onClick={methods.handleSubmit(filtrer)}>
                            <Check fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Fermer">
                        <IconButton sx={styles.buttonStyleRed} onClick={() => setOpen(false)}>
                            <Close fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Box>
        </FormProvider>
    );
}

FiltreDemandesRegularisationForm.defaultProps = {
    setOpen: () => {}
}

FiltreDemandesRegularisationForm.propTypes = {
    userType: PropTypes.string.isRequired,
    setOpen: PropTypes.func
}

const styles = {
    boxButtonsMenuFiltre: {
        justifyContent: "center",
        display: "flex"
    },
    buttonStyleGreen: {
        boxShadow: "none", 
        backgroundColor: greenColor["02"],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: greenColor["03"],
            color: whiteColor[9],
        }
    },
    buttonStyleRed: {
        boxShadow: "none", 
        backgroundColor: redColor[1],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: redColor[2],
            color: whiteColor[9],
        }
    }
}

export default FiltreDemandesRegularisationForm;