import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import { selectSolGesAbs, selectUsrApp } from "@selectors";
import * as solGesAbsActions from "@reducers/soldes/solGesAbsReducer";
import * as sitSolAppActions from "@reducers/soldes/sitSolAppReducer";
import { SoldesActionsBar, SoldesList, SoldesText } from "@components/App/Soldes";
import { useSolApp } from "@hooks/gestionAbsences/soldes/hooksQueries";
import { SituationSoldeDialogMobile } from "@components/App/Soldes/Dialogs";

const SoldesMobile = () => {
    const [openSituationSolde, setOpenSituationSolde] = useState(false);
    const stateSoldes = useSelector(selectSolGesAbs);
    const stateUser = useSelector(selectUsrApp);
    const { data, isLoading, isFetching, isError, error, refetch } = useSolApp(stateUser.societe, stateUser.matricule);
    const dispatch = useDispatch();

    const openSituation = useCallback((solde) => {
        dispatch(sitSolAppActions.selectSolde({periode: stateSoldes.periode, solde: solde, matricule: stateUser.matricule}));
        setOpenSituationSolde(true);
    }, [dispatch, stateSoldes.periode, stateUser.matricule]);

    const lister = useCallback(() => {
        dispatch(solGesAbsActions.lister());
    }, [dispatch]);

    return (
        <>
            <Stack spacing={0.5} sx={styles.paperStyle}>
                <SoldesActionsBar
                    isLoading={isLoading || isFetching}
                    isError={isError}
                    listed={stateSoldes.listed}
                    lister={lister}
                    refetch={refetch}
                />
                {!isError &&
                    <SoldesText 
                        isLoading={isLoading || isFetching}
                        periode={data?.periode}     
                    />
                }
                <Box overflow="auto" height={"100%"}>
                    <SoldesList 
                        data={data?.soldes}
                        isLoading={isLoading || isFetching}
                        isError={isError}
                        errorMessage={error?.message}
                        listed={stateSoldes.listed}
                        openSituation={openSituation}
                    />
                </Box>
            </Stack>
            <SituationSoldeDialogMobile 
                open={openSituationSolde} 
                setOpen={setOpenSituationSolde} 
            />
        </>
    )
}

const styles = {
    boxTitleStyle: {
        backgroundColor: "rgba(0,0,0,0.1)", 
        textAlign: "center",
        p:1,
        borderRadius: 2,
        minHeight: 30
    },
    boxDateTitleStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.3)", 
        borderRadius: 2,
        marginTop: 1,
        p:1
    },
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        height: "100%"
    },
    paperStyle: {
        background: "none",
        width: "100%",
        height: "100%"
    }
};

export default SoldesMobile;