import { arrange, asc, desc, tidy } from "@tidyjs/tidy";
import moment from "moment";

export const trierAffActInd = (keyTrie, data) => {
    switch(keyTrie) {
        case "dateAsc":
            return tidy(data, arrange((da, db) => moment([da.anneeEffet, da.moisEffet - 1, da.jourEffet]).utc() - moment([db.anneeEffet, db.moisEffet - 1, db.jourEffet]).utc()));
        case "dateDesc": 
            return tidy(data, arrange((da, db) => moment([db.anneeEffet, db.moisEffet - 1, db.jourEffet]).utc() - moment([da.anneeEffet, da.moisEffet - 1, da.jourEffet]).utc()));
        case "activiteAsc":
            return tidy(data, arrange([asc("codeActivite")]));
        case "activiteDesc": 
            return tidy(data, arrange([desc("codeActivite")]));
        default: 
            return data;
    }
};
