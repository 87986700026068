import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import moment from "moment";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, whiteColor } from "@styles";
import { ErrorBase } from "@components/Errors";

const SuiviList = (props) => {
    const { data, isLoading, isError, errorMessage } = props;

    if(isLoading) {
        return (
            <Box display="flex" alignItems="center" height={299} borderRadius={2}>
                <Loader colorLoader={whiteColor[9]} colorContainer={greyColor[9]} />
            </Box>
        );
    }

    if(isError) {
        return (
            <ErrorBase>{errorMessage}</ErrorBase>
        );
    }

    return (
        <Stack spacing={0.5}>
            {data.map((item) => (
                <Stack key={`S${item.id}`} spacing={0.5} bgcolor={whiteColor[7]} borderRadius={2} p={1}>
                    <Box>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box>
                                <Typography fontWeight="bold" fontSize="small">Destinataire : {item.destinataire}</Typography>
                            </Box>
                            <Box flexGrow={1}></Box>
                            <Box textAlign="center" padding={0.5} bgcolor={greenColor["05"]} borderRadius={2}>
                                <Typography fontWeight="bold" fontSize="small" color={whiteColor[9]}>Réponse {item.id}</Typography>
                            </Box>
                        </Stack>
                    </Box>
                    <Box>
                        <Typography fontSize="small">Averti : {item.destinataireAverti === "O" ? "Oui" : "Non"}</Typography>
                        <Typography fontSize="small">{item.reponse ? item.reponse + " le " + moment(item.dateReponse, "DDMMYYYY").format("DD/MM/YYYY") + " à " + moment(item.heureReponse, "HHmmss").format("HH:mm:ss") : "Pas encore de réponse"}</Typography>
                        {item.details ? <Typography fontSize="small">Message : {item.details}</Typography> : null}
                        {item.motifRefus ? <Typography fontSize="small">Motif de refus : {item.motifRefus}</Typography> : null}
                    </Box>
                </Stack>
            ))}
        </Stack>
    );
}

SuiviList.defaultProps = {
    data: [],
    isLoading: true, 
    isError: false, 
    errorMessage: ""
}

SuiviList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool, 
    errorMessage: PropTypes.string
}

const styles = {
    boxListItemStyle: {
        backgroundColor: "rgba(255,255,255,0.5)", 
        borderRadius: 2,
        p:1
    },
}

export default SuiviList;