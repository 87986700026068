import { useSelector } from "react-redux";
import { Box, Grid, Grow } from "@mui/material";
import PropTypes from "prop-types";
import { selectDsh } from "@selectors";
import { WelcomeWidget } from "./Welcome";
import { DateWidget } from "./Date";
import { MeteoWidget } from "./Meteo";
import { HorlogesWidget } from "./Horloges";
import { PlagesHoraireWidget } from "./PlagesHoraire";
import { StatutWidget } from "./Statut";

const Widgets = (props) => {
    const { 
        isLoadingStatut, 
        isErrorStatut, 
        errorStatutMessage,
        refetchStatut,
        isLoadingPlagesHoraires, 
        isErrorPlagesHoraires, 
        errorPlagesHoraireMessage, 
        refetchPlagesHoraires, 
    } = props;
    const stateDashboard = useSelector(selectDsh);

    const routeTuiles = {
        wBonjour: <WelcomeWidget />,
        wDate: <DateWidget />,
        wMeteo: <MeteoWidget />,
        wHorloges: <HorlogesWidget />,
        wPlagesHoraire: <PlagesHoraireWidget isLoadingPlagesHoraires={isLoadingPlagesHoraires} isErrorPlagesHoraires={isErrorPlagesHoraires} errorPlagesHoraireMessage={errorPlagesHoraireMessage} refetchPlagesHoraires={refetchPlagesHoraires} />,
        wStatut: <StatutWidget isLoadingStatut={isLoadingStatut} isErrorStatut={isErrorStatut} errorMessage={errorStatutMessage} refetchStatut={refetchStatut} />,
    }

    return (
        <Box bgcolor={"rgba(255,255,255,0)"} overflow="auto">                  
            <Grid container spacing={1}>    
                {stateDashboard.widgets.map((widget, id) => (
                    <Grow key={`WIDGET${id}`} in={true} timeout={300+(200*id)}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3} sx={styles.widgetStyle}>
                            {routeTuiles[widget]} 
                        </Grid>
                    </Grow>
                ))}
            </Grid>  
        </Box> 
    );
}

Widgets.defaultProps = {
    isLoadingStatut: true, 
    isErrorStatut: false, 
    errorStatutMessage: "",
    refetchStatut: () => {}, 
    isLoadingPlagesHoraires: true, 
    isErrorPlagesHoraires: false, 
    errorPlagesHoraireMessage: "", 
    refetchPlagesHoraires: () => {}
}

Widgets.propTypes = {
    isLoadingStatut: PropTypes.bool, 
    isErrorStatut: PropTypes.bool, 
    errorStatutMessage: PropTypes.string,
    refetchStatut: PropTypes.func, 
    isLoadingPlagesHoraires: PropTypes.bool, 
    isErrorPlagesHoraires: PropTypes.bool, 
    errorPlagesHoraireMessage: PropTypes.string, 
    refetchPlagesHoraires: PropTypes.func
}

const styles = {
    widgetStyle: {
        minHeight: 200
    },
    paperStyle: {
        backgroundColor: "rgba(255,255,255,0)",
        width: "100%",
        maxHeight: "calc(100vh - 224px)",
        borderRadius: 2,
        overflow: "auto"
    },
    paperStyleMobile: {
        backgroundColor: "rgba(255,255,255,0)",
        width: "100%",
        maxHeight: "calc(100vh - 180px)",
        borderRadius: 2,
        overflow: "auto"
    },
    paperStyleLoading: {
        backgroundColor: "rgba(255,255,255,0)",
        height: "calc(100vh - 196px)",
        width: "100%",
        borderRadius: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
};

export default Widgets;