import { urls } from "@constants/appConstants";
import axios from "axios";

export const downloadMvt = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteDownloadMouvements"
    };
    const body = {
        utilisateur: data.utilisateur,
        matriculeHierarchique: data.matriculeHierarchique,
        droitSalarie: data.droitSalarie,
        droitInterimaire: data.droitInterimaire,
        dateDebut: data.dateDebut,
        dateFin: data.dateFin,
        societe: data.codeSociete,
        etablissement: data.codeEtablissement,
        direction: data.codeDirection,
        service: data.codeService,
        secteur: data.codeSecteur,
        emploi: data.codeEmploi,
        horaire: data.codeHoraire,
        matriculeATraiter: data.matriculeATraiter,
        typeMatriculeATraiter: data.typeMatriculeATraiter,
        typeJour: data.jour,
        typeEvenement: data.evenement,
        heureLimite: data.heureLimite,
        detailMouvements: data.detail
    };
    const response = await axios.post(urls.downloadMouvements, body, { headers: headers, params: { client: data.client } });
    return response.data.mouvements;
}