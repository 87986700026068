import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { fetchSecVar } from "@api/security";
import { fetchNtf } from "@api/notifications";
import { selectCltApp, selectUsrApp } from "@selectors";
import * as secVarAppActions from "@reducers/secureVariables/secVarAppReducer";

export const useSecVarApp = (clientSuccess) => {
    const dispatch = useDispatch();
    const stateClient = useSelector(selectCltApp);
    const query = useQuery(['secVarApp', stateClient.client], () => fetchSecVar(stateClient.client), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: clientSuccess && !!stateClient.client
    });

    useEffect(() => {
        if(query.data && query.isSuccess) {
            dispatch(secVarAppActions.setSecureVariable(query.data));
        }
    }, [dispatch, query.data, query.isSuccess]);

    return query;
}

export const useNtfApp = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);

    const query = useQuery(["notApp"], () => fetchNtf(stateUser.utilisateurAS400, stateClient.client), {
        retry: false, 
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        refetchInterval: 60000,
        enabled: stateUser.isAuth
    });
    
    return query;
}
