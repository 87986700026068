import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Grow, Skeleton, Stack, Typography } from "@mui/material";
import { Add, ArrowLeft, ArrowRight, Close, Refresh } from "@mui/icons-material";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { selectEntSecCol } from "@selectors";
import { useSouSecCmpNiv2EntCol } from "@hooks/gestionEntretiens/hooksQueries";
import { useMappingActionsCol } from "@hooks/gestionEntretiens/hooksUtils";
import { IconButtonBase } from "@components/Buttons";
import { Loader } from "@components/Loaders";
import { ErrorBase } from "@components/Errors";
import { getGreyColor, greenColor, greyColor, redColor, whiteColor } from "@styles";
import { AjoutSectionForm } from "../Forms";
import * as entSecColActions from "@reducers/gestionEntretiens/entSecColReducer";

const SousSectionsCompetencesList = ({ 
    accessibles = false, 
    goSection = () => {}, 
    goBack = () => {},
    validate = () => {},
    openWarning = () => {}
}) => {
    const stateSections = useSelector(selectEntSecCol);
    const sectionSelected = stateSections?.sectionSelected;
    const { isLoading, isFetching, isError, refetch } = useSouSecCmpNiv2EntCol();
    const { designation1, designation2 } = useMappingActionsCol();
    const dispatch = useDispatch();

    if(stateSections.modeAjout) {
        return (
            <AjoutSectionForm goBack={goBack} validate={validate} />
        )
    }

    if(isLoading || isFetching) {
        return (
            <>
                <Box borderRadius={2} bgcolor={greenColor["05"]} height={50} p={0.5}>
                    <Skeleton variant="rounded" animation="wave" width="100%" height="100%" sx={{borderRadius: 2}} />
                </Box>
                <Box justifyContent="center" display="flex" alignItems="center" height="calc(100vh - 140px)" p={2}>
                    <Box bgcolor={whiteColor[7]} borderRadius={2} width={50} height={50} justifyContent="center" display="flex" alignItems="center">
                        <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                    </Box>
                </Box>
            </>
        );
    }

    if(isError) {
        return (
            <>
                <Box borderRadius={2} bgcolor={redColor[5]} height={50} p={0.5}>
                    <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[7]} height="100%" alignItems="center" px={1}>
                        <Box flexGrow={1}>
                            <Typography fontSize="small" display="inline" fontWeight="bold">Une erreur est survenue lors de la récupération des données</Typography>
                        </Box>
                        <Box>
                            <IconButtonBase icon={<Refresh fontSize="small" />} title="Rafraichir" action={() => refetch()} />
                        </Box>
                    </Stack>
                </Box>
                <ErrorBase>Erreur lors de la récupération des données</ErrorBase>
            </>
        );
    }

    return (
        <>
            <Box borderRadius={2} bgcolor={greenColor["05"]} height={50} p={0.5}>
                <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[7]} height="100%" alignItems="center" px={1}>
                    <IconButtonBase icon={<ArrowLeft fontSize="small" />} title="Retour" action={() => goBack()} />
                    <Box flexGrow={1}>
                        <Typography fontSize="small" fontWeight="bold">{designation1}</Typography>
                        <Typography fontSize="small" fontWeight="bold">{designation2}</Typography>
                    </Box>
                    {(accessibles && sectionSelected?.ajoutSousSectionManager) && 
                        <IconButtonBase 
                            icon={<Add fontSize="small" />} 
                            title="Ajouter une sous section" 
                            action={() => dispatch(entSecColActions.setModeAjout(true))} 
                        />
                    }
                </Stack>
            </Box>
            <PerfectScrollbar>
                <Grow in={true} timeout={300}>
                    <Stack spacing={0.5}>
                        <Stack spacing={0.5} bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                            {stateSections?.sections?.map((sousSection, id) => (
                                <Stack key={`SSEC${id}`} direction="row" spacing={1} alignItems="center" pr={(accessibles && sousSection?.origineSousSection === "C") ? 1 : 0}>
                                    <Box flexGrow={1}>
                                        <Button 
                                            sx={{ 
                                                color: getGreyColor("0.7"), 
                                                bgcolor: sousSection?.origineSousSection === "C" ? redColor[2] : whiteColor[7], 
                                                height: 50, 
                                                borderRadius: 2, 
                                                "&:hover": { 
                                                    color: whiteColor[9], 
                                                    backgroundColor: sousSection?.origineSousSection === "C" ? redColor[5] : greenColor["07"], 
                                                    boxShadow: 2 
                                                }
                                            }} 
                                            fullWidth
                                            onClick={() => goSection(3, sousSection)}
                                        >
                                            <Stack direction="row" alignItems="center" px={1} height={"100%"} width={"100%"}>
                                                <Box flexGrow={1}>
                                                    <Typography fontSize="small" sx={{flexGrow: 1, textAlign: "left", textTransform: "none"}}>{sousSection?.designationSousSection1}</Typography>
                                                    <Typography fontSize="small" sx={{flexGrow: 1, textAlign: "left", textTransform: "none"}}>{sousSection?.designationSousSection2}</Typography>
                                                </Box>
                                                <Box bgcolor={sousSection?.succesEvaluation ? greenColor["05"] : redColor[5]} borderRadius={2} px={1}>
                                                    <Typography fontSize="small" fontWeight="bold" sx={{textAlign: "left", textTransform: "none"}}>{sousSection?.designationEvaluation}</Typography>
                                                </Box>
                                                <Box alignItems="center" display="flex">
                                                    <ArrowRight fontSize="small" />
                                                </Box>
                                            </Stack>
                                        </Button>
                                    </Box>
                                    {(accessibles && sousSection?.origineSousSection === "C") &&
                                        <Box>
                                            <IconButtonBase 
                                                variant="deleteListButton"
                                                icon={<Close fontSize="small" />} 
                                                title="Supprimer" 
                                                action={() => openWarning(sousSection)} 
                                            />
                                        </Box>
                                    }
                                </Stack>
                            ))}          
                        </Stack>    
                    </Stack>
                </Grow>
            </PerfectScrollbar>
        </>
    )
}

SousSectionsCompetencesList.propTypes = {
    accessibles: PropTypes.bool,
    goSection: PropTypes.func,
    goBack: PropTypes.func,
    validate: PropTypes.func,
    openWarning: PropTypes.func
}

export default SousSectionsCompetencesList;