import { useState } from "react";
import { Box, Button, Collapse, Stack, Typography } from "@mui/material";
import { ArrowDownward, ArrowDropDown, ArrowRight, ArrowUpward } from "@mui/icons-material";
import PropTypes from "prop-types";
import { BaseButton } from "@components/Buttons";
import { getGreyColor, greenColor, whiteColor } from "@styles";

const MenuTrieButton = (props) => {
    const { option, trier } = props;
    const [open, setOpen] = useState(false);

    return (
        <Stack bgcolor={greenColor["02"]} borderRadius={2}>
            <Box bgcolor={whiteColor[9]} borderRadius={2}>
                <Button 
                    endIcon={open ? <ArrowDropDown fontSize="small"/> : <ArrowRight fontSize="small"/>}
                    size="small" 
                    fullWidth
                    sx={{ 
                        height: 35,
                        borderRadius: 2, 
                        color: getGreyColor("0.59"), 
                        backgroundColor: greenColor["02"], 
                        "&:hover": { 
                            color: whiteColor[9],
                            backgroundColor: greenColor["03"] 
                        } 
                    }} 
                    onClick={() => setOpen((prevOpen) => !prevOpen)}
                >
                    <Typography fontSize="small" fontWeight="bold" pl={0.5} flexGrow={1} lineHeight={1} textAlign="left" textTransform="none">
                        {option?.label}
                    </Typography>
                </Button>
            </Box>
            <Box>
                <Collapse in={open} timeout="auto">
                    <Stack spacing={0.25} padding={0.25}>
                        <Box>
                            <BaseButton color="white" textAlign="left" iconRight={<ArrowUpward fontSize="small" />} action={() => trier(option.keyAsc)}>
                                Ordre croissant
                            </BaseButton>
                        </Box>
                        <Box>
                            <BaseButton color="white" textAlign="left" iconRight={<ArrowDownward fontSize="small" />} action={() => trier(option.keyDesc)}>
                                Ordre décroissant
                            </BaseButton>
                        </Box>
                    </Stack>
                </Collapse>
            </Box>
        </Stack>
    );
}

MenuTrieButton.defaultProps = {
    option: null,
    trier: () => {}
}

MenuTrieButton.propTypes = {
    option: PropTypes.object,
    trier: PropTypes.func
}

export default MenuTrieButton;