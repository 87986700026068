import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Badge, Box, Drawer, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Close, Notifications, Visibility } from "@mui/icons-material";
import { selectNotApp, selectTabApp } from "@selectors";
import * as tabAppActions from "@reducers/tabs/tabAppReducer"
import * as notAppActions from "@reducers/notifications/notAppReducer"
import * as temGesTemManActions from "@reducers/gestionTemps/temps/temGesTemManReducer"
import { IconButtonBase } from "@components/Buttons";
import { TextContainer } from "@components/Containers";
import { CODE_ACTION, CODE_TABS_GESTION_TEMPS_MANAGER } from "@constants/tabsConstants";
import { addTabs } from "@utils";
import { greenColor, greyColor, whiteColor, yellowColor } from "@styles";
import { useMedia } from "react-use";
import { useTranslation } from "react-i18next";

const NotificationsManagement = () => {
	const [openMenuNotifs, setOpenMenuNotifs] = useState(false);
	const stateTabs = useSelector(selectTabApp);
	const stateNotifications = useSelector(selectNotApp);
	const dispatch = useDispatch();
    const isLarge = useMedia('(min-width: 650px)');
	const { t } = useTranslation("global");

	const handleTabs = useCallback((action) => {
		const tabs = addTabs(action, [...stateTabs.tabs]);
		dispatch(tabAppActions.editTabs(tabs));
		dispatch(tabAppActions.setActiveTab(action));
	}, [dispatch, stateTabs.tabs]); 

	const handleNotificationDemandesAbsence = useCallback(() => { 
		handleTabs(CODE_ACTION.ABSENCES_MANAGER);
		dispatch(notAppActions.setNotificationDemandesAbsence(false));
	}, [dispatch, handleTabs]);

	const handleNotificationDemandesRegularisation = useCallback(() => { 
		handleTabs(CODE_ACTION.GESTION_TEMPS_MANAGER);
		dispatch(temGesTemManActions.setTabActive(CODE_TABS_GESTION_TEMPS_MANAGER.TAB_DEMANDES_REGULARISATION));
		dispatch(notAppActions.setNotificationDemandesRegularisation(false));
	}, [dispatch, handleTabs]);

	return (
		<>
			<Box px={0.5}>
				<Tooltip title={t("buttons.notifications")} placement="bottom">
					<Box component="span">
						{stateNotifications.notificationDemandesAbsence || stateNotifications.notificationDemandesRegularisation ?
							<Badge overlap="circular" color="warning" anchorOrigin={{vertical: "top", horizontal: "left"}} variant="dot">
								<IconButtonBase
									variant="dialogButton"
									size="medium"
									icon={<Notifications />}
									title="Notifications"
									action={(e) => setOpenMenuNotifs(e.currentTarget)}>
								</IconButtonBase>
							</Badge>
						:
							<IconButtonBase
								variant="dialogButton"
								size="medium"
								icon={<Notifications />}
								title="Notifications"
								action={(e) => setOpenMenuNotifs(e.currentTarget)}>
							</IconButtonBase>	
						}
					</Box>
				</Tooltip>
			</Box>
			<Drawer 
				variant="temporary" 
				anchor={isLarge ? "right" : "bottom"} 
				open={openMenuNotifs} 
				onClose={() => setOpenMenuNotifs(false)} 
				PaperProps={{ sx: { backgroundColor: "transparent", p: 1, boxSizing: "border-box" }}} 
				sx={{zIndex: (theme) => theme.zIndex.drawer + 1}} 
				elevation={0}
			>
				<Box width={isLarge ? 400 : "100%"} bgcolor={whiteColor[9]} overflow="auto" borderRadius={2}>
					<Stack spacing={0.5} p={0.5}>
						{!stateNotifications.notificationDemandesAbsence && !stateNotifications.notificationDemandesRegularisation ? 
							<Box bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>  
								<Stack direction="row" spacing={1} alignItems="center">
									<Box flexGrow={1}>
										<Box p={1} bgcolor={whiteColor[3]} borderRadius={2}>
                         					<Typography fontSize="small" fontWeight="bold">Aucune notification</Typography>
										</Box>
									</Box>
									<Box display="flex" justifyContent="center">
										<IconButtonBase 
											icon={<Close fontSize="small" />} 
											title="Fermer" 
											action={() => setOpenMenuNotifs(false)} 
										/>
									</Box>
								</Stack>
                     		</Box>
						:
							<>
				 				{stateNotifications.notificationDemandesAbsence &&
				 					<Box bgcolor={yellowColor[5]} borderRadius={2} p={0.5}>
				 						<Stack direction="row" spacing={1} alignItems="center">
				 							<Box flexGrow={1}>
				 								<Box p={1} bgcolor={whiteColor[3]} borderRadius={2}>
													<Typography fontSize="small" fontWeight="bold">Demandes d'absence en attentes.</Typography>
												</Box>
				 							</Box>
				 							<Box display="flex" justifyContent="center">
				 								<IconButtonBase 
													icon={<Visibility fontSize="small" />} 
													title="Voir les demandes" 
													action={() => handleNotificationDemandesAbsence()} 
												/>
				 								<IconButtonBase 
													icon={<Close fontSize="small" />} 
													title="Supprimer" 
													action={() => dispatch(notAppActions.setNotificationDemandesAbsence(false))} 
												/>
				 							</Box>
				 						</Stack>
				 					</Box>
				 				}
				 				{stateNotifications.notificationDemandesRegularisation &&
				 					<Box bgcolor={yellowColor[5]} borderRadius={2} p={0.5}>
				 						<Stack direction="row" spacing={1} alignItems="center">
				 							<Box flexGrow={1}>
											 	<Box p={1} bgcolor={whiteColor[3]} borderRadius={2}>
												 	<Typography fontSize="small" fontWeight="bold">Demandes de régularisation en attentes.</Typography>
												</Box>
											</Box>
				 							<Box>
				 								<IconButtonBase 
													icon={<Visibility fontSize="small" />} 
													title="Afficher" 
													action={() => handleNotificationDemandesRegularisation()} 
												/>
				 								<IconButtonBase 
													icon={<Close fontSize="small" />} 
													title="Supprimer" 
													action={() => dispatch(notAppActions.setNotificationDemandesRegularisation(false))} 
												/>
				 							</Box>
				 						</Stack>
				 					</Box>
				 				}
				 			</>
						}
					</Stack>
				</Box>
			</Drawer>
		</>
	);
}

export default NotificationsManagement;