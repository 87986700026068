import PropTypes from "prop-types";
import { TextContainer } from "@components/Containers";
import { TYPES_SOCIETES } from "../constants";
import { Skeleton } from "@mui/material";

const SocietesText = (props) => {
    const { type, isError, isLoading, societes, societe } = props;

    if(isError) {
        return (<TextContainer p={0} bgColor="none">Erreur lors de la récupération des sociétés.</TextContainer>);
    }

    if(isLoading) {
        return (<Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={"100%"} height={25} />);
    }

    if(societes.length === 0) {
        return (<TextContainer p={0} bgColor="none">Aucune société trouvée.</TextContainer>);
    }

    if(type === TYPES_SOCIETES.ORGANIGRAMME) {
        return (
            societe ?
                <TextContainer p={0} bgColor="none" textWeight="bold" horizontalAlign="center" textSize="normal">Organigramme {societe?.libelleSociete}</TextContainer>
            :
                <TextContainer p={0} bgColor="none">Vous devez selectionner une société.</TextContainer>
        );
    }

    return (
        societe ?
            <TextContainer p={0} bgColor="none">Société sélectionnée : {societe?.libelleSociete} ({societe?.codeSociete})</TextContainer>
        :
            <TextContainer p={0} bgColor="none">Vous devez selectionner une société.</TextContainer>
    )
}

SocietesText.defaultProps= {
    type: "temps",
    isError: false,
    isLoading: true,
    societes: [], 
    societe: null
}

SocietesText.propTypes = {
    type: PropTypes.string,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    societes: PropTypes.array, 
    societe: PropTypes.object
}

export default SocietesText;