import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import moment from "moment";
import { selectCalGesAbs } from "@selectors";
import * as calGesAbsActions from "@reducers/gestionAbsences/calendrier/calGesAbsReducer"
import { greenColor } from "@styles";
import { useCalGesAbs } from "@hooks/gestionAbsences/calendrier/hooksQueries";
import { TextContainer } from "@components/Containers";
import { CalendrierActionsBarMobile } from "@components/GestionAbsences/Collaborateur/Calendrier/ActionsBar";
import { CalendrierTableMobile } from "@components/GestionAbsences/Collaborateur/Calendrier/Tables";
import { JourCalendrierDialogMobile } from "@components/GestionAbsences/Collaborateur/Calendrier/Dialogs";

const CalendrierMobile = () => {
    const [jour, setJour] = useState();
    const [openDialogJour, setOpenDialogJour] = useState(false);
    const stateCalendrier = useSelector(selectCalGesAbs);
    const { data, isLoading, isFetching, isError, error, refetch } = useCalGesAbs(stateCalendrier.date);
    const date = moment(stateCalendrier.date, "MMYYYY");
    const dispatch = useDispatch();

    const openJour = useCallback((jour) => {
        setJour(jour);
        setOpenDialogJour(true);
    }, []);

    const precedent = useCallback(async () => {
        const date = moment(stateCalendrier.date, "MMYYYY");
        if(date.isValid()) {
            dispatch(calGesAbsActions.setDate(date.add(-1, "months").format("MMYYYY")))
        }
    }, [dispatch, stateCalendrier.date]);

    const suivant = useCallback(async () => {
        const date = moment(stateCalendrier.date, "MMYYYY");
        if(date.isValid()) {
            dispatch(calGesAbsActions.setDate(date.add(1, "months").format("MMYYYY")))
        }
    }, [dispatch, stateCalendrier.date]);

    const selectDate = useCallback(async (date) => {
        if(date.isValid()) {
            const dateFormated = date.format("MMYYYY");
            dispatch(calGesAbsActions.setDate(dateFormated))
        }
    }, [dispatch]);

    return (
        <>
            <Stack spacing={0.5} sx={styles.paperStyle}>
                <TextContainer bgColor={greenColor["015"]} verticalAlign="center" horizontalAlign="center" textWeight="bold">
                    {date?.isValid() ? date.format("MMMM") : ""}
                </TextContainer>
                <Box>
                    <CalendrierTableMobile 
                        isLoading={isLoading || isFetching}
                        isError={isError}
                        errorMessage={error?.message}
                        openJour={openJour}
                        semaines={data}
                    />
                </Box>
                <CalendrierActionsBarMobile 
                    isLoading={isLoading || isFetching}
                    isError={isError}
                    precedent={precedent}
                    suivant={suivant}
                    selectDate={selectDate}
                    date={date}
                    refetch={refetch}
                />
            </Stack>
            <JourCalendrierDialogMobile 
                open={openDialogJour} 
                setOpen={setOpenDialogJour} 
                jour={jour} 
            />
        </>
    )
}

const styles = {
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
    },
    paperStyle: {
        background: "none",
        width: "100%",
        height: "100%",
        minHeight: 289
    }
};



export default CalendrierMobile;