import { useCallback, useState } from "react";
import { Box, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { CollaborateursFwdc01ListMobile, CollaborateursFwgtp2ListMobile, CollaborateursFwgtp3ListMobile, CollaborateursFwgtpeListMobile, CollaborateursFwmv01ListMobile } from "@components/App/Collaborateurs/Lists";
import { CollaborateursFwdc01TrieMobile, CollaborateursFwgtp2TrieMobile, CollaborateursFwgtp3TrieMobile, CollaborateursFwgtpeTrieMobile, CollaborateursFwmv01TrieMobile } from "@components/App/Collaborateurs/Tries";
import { useStateCollaborateurs } from "@hooks/collaborateurs/hooksStates";
import { useQueriesCollaborateurs } from "@hooks/collaborateurs/hooksQueries";
import { DialogFullScreenContainer } from "@components/Containers";
import { Loader } from "@components/Loaders";
import { CollaborateursFwdc01ActionsMobile, CollaborateursFwgtp2ActionsMobile, CollaborateursFwgtp3ActionsMobile, CollaborateursFwgtpeActionsMobile, CollaborateursFwmv01ActionsMobile } from "./Actions";
import { TYPES_FICHIER } from "@constants/collaborateursConstants";
import { greenColor, greyColor } from "@styles";
import { CollaborateursFwdc01Menu, CollaborateursFwgtp2Menu, CollaborateursFwgtp3Menu, CollaborateursFwgtpeMenu, CollaborateursFwmv01Menu } from "../Menus";

const CollaborateursDialogMobile = (props) => {
    const { 
        stateType, 
        listType,
        open, 
        dateValidite, 
        dataForm,
        codeSociete,
        allChecked, 
        tried, 
        headers,
        displayDate,
        setOpen,  
        collaborateursChecked,
        selectCollaborateur,
        selectCollaborateursChecked,
        selectAllCollaborateurs
    } = props;    
    const stateCollaborateurs = useStateCollaborateurs(stateType);
    const [openTri, setOpenTri] = useState(false);
    const [openFiltre, setOpenFiltre] = useState(false);
    const [horaire, setHoraire] = useState(false);
    const { isLoading, isFetching } = useQueriesCollaborateurs(stateType, dateValidite, dataForm, codeSociete, open);

    const closeTri = useCallback(() => {
        setOpenTri(false);
    }, []);

    const closeFiltre = useCallback(() => {
        setOpenFiltre(false);
    }, [setOpenFiltre]);

    return (
        <>
            <DialogFullScreenContainer open={open} placement="top" taille="xl">
                {{
                    [TYPES_FICHIER.FWGTPE]: <CollaborateursFwgtpeActionsMobile stateType={stateType} displayDate={displayDate} dateValidite={dateValidite} isLoading={isLoading || isFetching} tried={tried} setOpenTri={setOpenTri} setOpenFiltre={setOpenFiltre} setOpen={setOpen} />,
                    [TYPES_FICHIER.FWGTP2]: <CollaborateursFwgtp2ActionsMobile stateType={stateType} dateValidite={dateValidite} isLoading={isLoading || isFetching} allChecked={allChecked} tried={tried} setOpenTri={setOpenTri} selectCollaborateursChecked={selectCollaborateursChecked} setOpenFiltre={setOpenFiltre} selectAllCollaborateurs={selectAllCollaborateurs} setOpen={setOpen} />,
                    [TYPES_FICHIER.FWGTP3]: <CollaborateursFwgtp3ActionsMobile stateType={stateType} dateValidite={dateValidite} isLoading={isLoading || isFetching} tried={tried} setOpenTri={setOpenTri} setOpenFiltre={setOpenFiltre} setHoraire={setHoraire} setOpen={setOpen} />,
                    [TYPES_FICHIER.FWDC01]: <CollaborateursFwdc01ActionsMobile stateType={stateType} dateValidite={dateValidite} isLoading={isLoading || isFetching} tried={tried} setOpenTri={setOpenTri} setOpenFiltre={setOpenFiltre} setOpen={setOpen} />,
                    [TYPES_FICHIER.FWMV01]: <CollaborateursFwmv01ActionsMobile stateType={stateType} tried={tried} setOpenTri={setOpenTri} setOpenFiltre={setOpenFiltre} setOpen={setOpen} />
                }[listType]}
                {(isLoading || isFetching) ? 
                    <Box p={1.5}>
                        <Loader size={17} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                    </Box>
                :
                    <Stack spacing={0.5}>
                        {{
                            [TYPES_FICHIER.FWGTPE]: <CollaborateursFwgtpeListMobile data={stateCollaborateurs.collaborateursDisplayed} displayDate={displayDate} selectCollaborateur={selectCollaborateur} />,
                            [TYPES_FICHIER.FWGTP2]: <CollaborateursFwgtp2ListMobile stateType={stateType} data={stateCollaborateurs.collaborateursDisplayed} collaborateursChecked={collaborateursChecked} selectCollaborateur={selectCollaborateur} />,
                            [TYPES_FICHIER.FWGTP3]: <CollaborateursFwgtp3ListMobile data={stateCollaborateurs.collaborateursDisplayed} headers={headers} collaborateurSelected={stateCollaborateurs?.collaborateurSelected} selectCollaborateur={selectCollaborateur} horaire={horaire} />,
                            [TYPES_FICHIER.FWDC01]: <CollaborateursFwdc01ListMobile data={stateCollaborateurs.collaborateursDisplayed} selectCollaborateur={selectCollaborateur} />,
                            [TYPES_FICHIER.FWMV01]: <CollaborateursFwmv01ListMobile data={stateCollaborateurs.collaborateursDisplayed} selectCollaborateur={selectCollaborateur} />
                        }[listType]}
                    </Stack>
                }
                {{
                    [TYPES_FICHIER.FWGTPE]: <CollaborateursFwgtpeTrieMobile stateType={stateType} open={openTri} close={closeTri} />,
                    [TYPES_FICHIER.FWGTP2]: <CollaborateursFwgtp2TrieMobile stateType={stateType} open={openTri} close={closeTri} />,
                    [TYPES_FICHIER.FWGTP3]: <CollaborateursFwgtp3TrieMobile stateType={stateType} open={openTri} close={closeTri} />,
                    [TYPES_FICHIER.FWDC01]: <CollaborateursFwdc01TrieMobile stateType={stateType} open={openTri} close={closeTri} />,
                    [TYPES_FICHIER.FWMV01]: <CollaborateursFwmv01TrieMobile stateType={stateType} open={openTri} close={closeTri} />
                }[listType]}
            </DialogFullScreenContainer>
            {{
                [TYPES_FICHIER.FWGTPE]: <CollaborateursFwgtpeMenu type={stateType} open={openFiltre} close={closeFiltre} />,
                [TYPES_FICHIER.FWGTP2]: <CollaborateursFwgtp2Menu type={stateType} open={openFiltre} close={closeFiltre} />,
                [TYPES_FICHIER.FWGTP3]: <CollaborateursFwgtp3Menu type={stateType} open={openFiltre} close={closeFiltre} />,
                [TYPES_FICHIER.FWDC01]: <CollaborateursFwdc01Menu type={stateType} open={openFiltre} close={closeFiltre} />,
                [TYPES_FICHIER.FWMV01]: <CollaborateursFwmv01Menu type={stateType} open={openFiltre} close={closeFiltre} />
            }[listType]}
        </>
    );
}

CollaborateursDialogMobile.defaultProps = {
    dateValidite: null,
    dataForm: null,
    codeSociete: "",
    allChecked: false,
    displayDate: true,
    collaborateursChecked: [],
    selectCollaborateur: () => {},
    selectAllCollaborateurs: () => {},
    selectCollaborateursChecked: () => {}
}

CollaborateursDialogMobile.propTypes = {
    stateType: PropTypes.string.isRequired,
    listType: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    dateValidite: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    dataForm: PropTypes.object,
    codeSociete: PropTypes.string,
    allChecked: PropTypes.bool,
    tried: PropTypes.bool.isRequired,
    headers: PropTypes.array.isRequired,
    displayDate: PropTypes.bool,
    setOpen: PropTypes.func.isRequired,
    collaborateursChecked: PropTypes.array,
    selectCollaborateur: PropTypes.func,
    selectAllCollaborateurs: PropTypes.func,
    selectCollaborateursChecked: PropTypes.func
}

export default CollaborateursDialogMobile;