import axios from "axios";
import { urls } from "@constants/appConstants";

export const editPol = async (police, token) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostPolice", 
        "HttpToken": token
    };
    const body = {
        police: police
    };

    return axios.post(urls.postPolice, body, { headers: headers });
}