import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

const initialState = {
    date: moment().format("DDMMYYYY"),
    affichage: "T",
    allServices: true,
    semaine: [],
    collaborateurs: [],
    jourSelected: "",
    matriculeSelected: "",
    prenomNomSelected: ""
}

const plaHrcSlice = createSlice({
    name: 'plaHrc',
    initialState: initialState,
    reducers: {
        setPlanning: (state, action) => {
            state.semaine = action.payload.semaine
            state.collaborateurs = action.payload.collaborateurs
        },
        selectJour: (state, action) => {
            state.jourSelected = action.payload.jour
            state.matriculeSelected = action.payload.matricule
            state.prenomNomSelected = action.payload.prenomNom
        },
    },
})

const { actions, reducer } = plaHrcSlice
export const { 
    setPlanning, 
    selectJour, 
} = actions
export default reducer