import { Box, Grid, Stack } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { useStateTemGesTem } from "@hooks/gestionTemps/temps/hooksStates";
import { chiffreUSVersChiffreFR, heurifySigned } from "@utils";
import { PanelListContainer, TextContainer, TextWithLabelContainer } from "@components/Containers";
import { ErrorBase } from "@components/Errors";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, whiteColor } from "@styles";
import PerfectScrollbar from "react-perfect-scrollbar";

const HistoriqueDebitCreditsList = (props) => {
    const { userType, data, date, isLoading, isError, errorMessage } = props;
    const stateTemps = useStateTemGesTem(userType);

    if(isLoading) {
        return (
            <Box>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        )
    }

    if(isError) {
        return <ErrorBase>{errorMessage}</ErrorBase>;
    }

    return (
        <Stack spacing={0.5} maxHeight={"calc(100vh - 108px)"}>                
            <TextContainer bgColor={greenColor["015"]} p={1} textWeight="bold">Historique à partir du {moment(date, "DDMMYYYY").format("DD/MM/YYYY")}</TextContainer>
            <PerfectScrollbar>
                <PanelListContainer length={data.length}>
                    {data.map((historique) => (
                        <Grid key={`HISTDECEGT${historique.id}`} item xs={12}>
                            <Stack spacing={0.5} bgcolor={greenColor["015"]} padding={0.5} borderRadius={2}>
                                <Box borderRadius={2} p={1} bgcolor={whiteColor[5]}>
                                    <Stack spacing={0}>
                                        <TextWithLabelContainer label="Date" value={moment(historique.date, "DDMMYYYY").format("DD/MM/YYYY")} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                        <TextWithLabelContainer label="Crédit en cours" value={stateTemps.horaire ? heurifySigned(historique.enCours.heure) : chiffreUSVersChiffreFR(historique.enCours.chiffre)} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                        <TextWithLabelContainer label="Jour Crédit Acquis" value={stateTemps.horaire ? heurifySigned(historique.acquisJour.heure) : chiffreUSVersChiffreFR(historique.acquisJour.chiffre)} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                        <TextWithLabelContainer label="Cumul Crédit Acquis" value={stateTemps.horaire ? heurifySigned(historique.acquisCumul.heure) : chiffreUSVersChiffreFR(historique.acquisCumul.chiffre)} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                        <TextWithLabelContainer label="Valeur Régularisation" value={stateTemps.horaire ? heurifySigned(historique.veleurRegularisation.heure) : chiffreUSVersChiffreFR(historique.veleurRegularisation.chiffre)} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                        <TextWithLabelContainer label="Date Régularisation" value={historique.dateRegularisation ? moment(historique.dateRegularisation, "DDMMYYYY").format("DD/MM/YYYY") : ""} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                        <TextWithLabelContainer label="Utilisateur Régularisation" value={historique.utilisateurRegularisation} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                        <TextWithLabelContainer label="Commentaire Régularisation" value={historique.commentaireRegularisation} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                    </Stack>
                                </Box>
                            </Stack>
                        </Grid>
                    ))} 
                </PanelListContainer>  
            </PerfectScrollbar>
                
        </Stack>
    );
}

HistoriqueDebitCreditsList.defaultProps = {
    data: [],
    isLoading: false, 
    isError: false, 
    errorMessage: ""
}

HistoriqueDebitCreditsList.propTypes = {
    userType: PropTypes.string.isRequired,
    data: PropTypes.array,
    date: PropTypes.string.isRequired,
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool, 
    errorMessage: PropTypes.string
}

export default HistoriqueDebitCreditsList;