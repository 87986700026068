import { TextContainer } from "@components/Containers";
import { CheckHookFormInput, RadiosHookFormInput, WeekHookFormInput } from "@components/Inputs";
import { SocietesHookFormInput } from "@components/Inputs/Societes";
import { Box, Stack } from "@mui/material";
import { greenColor } from "@styles";
import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";


const options = [
    {id: 1, value: "AUC", label: "Aucun"},
    {id: 2, value: "MAT", label: "Trier par matricule"},
    {id: 3, value: "NOM", label: "Trier par nom"}
]

const PlanningForm = () => {
    const { setValue, watch } = useFormContext();
    const dateDebut = watch("dateDebut");

    const selectDate = useCallback((date) => {
        setValue("dateDebut", date);
    }, [setValue]);

    return (
        <Stack spacing={1}>
            <Box>
                <WeekHookFormInput name="dateDebut" title="Choisir une semaine" date={dateDebut} action={selectDate} /> 
            </Box>
            <Box>
                <SocietesHookFormInput name="societe" label="Société" />
            </Box>
            <Stack spacing={1}>
                <TextContainer bgColor={greenColor["025"]} p={1} horizontalAlign="center" verticalAlign="center" textWeight="bold">Subdivisions</TextContainer>
                <Stack direction="row" spacing={1} display="flex" justifyContent="center">
                    <Box>
                        <Box>
                            <CheckHookFormInput name="filtreSociete" label="Société" />
                        </Box>
                        <Box>
                            <CheckHookFormInput name="filtreEtablissement" label="Etablissement" />
                        </Box>
                    </Box>
                    <Box>
                        <Box>
                            <CheckHookFormInput name="filtreDirection" label="Direction" />
                        </Box>
                        <Box>
                            <CheckHookFormInput name="filtreService" label="Service" />
                        </Box>
                    </Box>
                    <Box>
                        <Box>
                            <CheckHookFormInput name="filtreSecteur" label="Secteur" />
                        </Box>
                        <Box>
                            <CheckHookFormInput name="filtreMetier" label="Métier" />
                        </Box>
                    </Box>
                    <Box>
                        <Box>
                            <CheckHookFormInput name="filtreCategorieProfessionnelle" label="Catégorie Professionnelle" />
                        </Box>
                        <Box>
                            <CheckHookFormInput name="filtreHoraire" label="Horaire" />
                        </Box>
                    </Box>
                </Stack>
            </Stack>
            <Stack spacing={1}>
                <TextContainer bgColor={greenColor["025"]} p={1} horizontalAlign="center" verticalAlign="center" textWeight="bold">Options de tri</TextContainer>
                <Box display="flex" justifyContent="center">
                    <RadiosHookFormInput name="tri" options={options} row={true} />
                </Box>
            </Stack>
        </Stack>
    )
}

PlanningForm.propTypes = {
    modelize: PropTypes.func.isRequired
}

export default PlanningForm;