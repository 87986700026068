import { selectUsrApp } from "@selectors";
import { greenColor, greyColor, whiteColor } from "@styles";
import { useSelector } from "react-redux";

export const useBgLayersSideBar = (pair, open) => {
    const stateUser = useSelector(selectUsrApp);
    const noBackground = stateUser.background === "image0";

    if(noBackground) {
        return { 
            layer1: open ? greyColor[3] : undefined,
            layer2: undefined,
            buttonPair: "sideBarNoBackgroundSecondary",
            buttonNotPair: "sideBarNoBackgroundPrimary"
        }
    }

    return { 
        layer1: pair ? whiteColor[5] : greenColor["025"],
        layer2: open ? greenColor["045"] : whiteColor[9],
        buttonPair: "sideBarSecondary",
        buttonNotPair: open ? "sideBarSecondary" : "sideBarPrimary"
    }
}