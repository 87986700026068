import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";
import { ChevronLeft, ChevronRight, Refresh } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { useSitSolApp } from "@hooks/gestionAbsences/soldes/hooksQueries";
import { selectSitSolApp } from "@selectors";
import * as sitSolAppActions from "@reducers/soldes/sitSolAppReducer"
import { TextContainer, TextVariableContainer, HeaderDialogContainer, TextWithLabelContainer, DialogFullScreenContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import SituationSoldeList from "../Lists/SituationSoldeList";
import SoldesText from "../Texts/SoldesText";
import { greenColor, redColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const SituationSoldeDialogMobile = (props) => {
    const { open, setOpen } = props;
    const stateSituationSolde = useSelector(selectSitSolApp);
    const { data, isLoading, isFetching, isError, error, refetch } = useSitSolApp(stateSituationSolde.matricule, stateSituationSolde?.solde?.codeAbsence, stateSituationSolde?.periode, open);
    const dateSolde = moment(stateSituationSolde?.solde?.date, "DDMMYYYY");
    const dispatch = useDispatch();
    const { t } = useTranslation("global");

    const precedent = useCallback(async () => {
        const periode = moment(stateSituationSolde.periode, "MMYYYY");
        dispatch(sitSolAppActions.setPeriode(periode.add(-1, "months").format("MMYYYY")));
    }, [dispatch, stateSituationSolde.periode]);

    const suivant = useCallback(async () => {
        const periode = moment(stateSituationSolde.periode, "MMYYYY");
        dispatch(sitSolAppActions.setPeriode(periode.add(1, "months").format("MMYYYY")));
    }, [dispatch, stateSituationSolde.periode]);

    return (
        <DialogFullScreenContainer open={open} placement="top" taille="sm">
            <HeaderDialogContainer 
                actionsLeft={
                    <Stack direction="row" spacing={0.5}>
                        <IconButtonBase icon={<ChevronLeft fontSize="small" />} title={t("buttons.previous")} disabled={isLoading || isFetching} action={precedent} />
                        <IconButtonBase icon={<ChevronRight fontSize="small" />} title={t("buttons.next")} disabled={isLoading || isFetching} action={suivant} />
                    </Stack>
                }
                actionsRight={
                    <IconButtonBase title={t("buttons.refresh")} icon={<Refresh fontSize="small" />} disabled={isLoading || isFetching} action={() => refetch()} />
                }
                title={stateSituationSolde.solde ? stateSituationSolde.solde.libelleAbsence : t("titles.balance_tracking")}
                close={() => setOpen(false)}
            />
            <Stack spacing={1}>
                <Box>
                    <Stack spacing={0.5}>
                        <Box>
                            <TextContainer bgColor={greenColor["015"]} p={1} textWeight="bold">
                                {t("texts.solde_situation_date_2", {date: dateSolde.format("DD MMMM YYYY")})}
                            </TextContainer>
                        </Box>
                        <Box>
                            <Stack spacing={0.25} padding={0.5} borderRadius={2} bgcolor={greenColor["015"]}>
                                <TextVariableContainer type={"txt"} p={1} bgColor={whiteColor[5]} textAlign="center" textWeight="bold">{stateSituationSolde?.solde?.libelleSolde}</TextVariableContainer>
                                <Box borderRadius={2} p={1} bgcolor={whiteColor[5]}>
                                    <Stack spacing={0}>
                                        <TextWithLabelContainer label={t("texts.balance")} value={Number(stateSituationSolde?.solde?.solde).toLocaleString("fr-FR")} labelWidth={true} twoPoints={false} />
                                        <TextWithLabelContainer label={t("texts.validated_request")} value={Number(stateSituationSolde?.solde?.demandesValidees).toLocaleString("fr-FR")} labelWidth={true} twoPoints={false} />
                                        <TextWithLabelContainer label={t("texts.pending_request")} value={Number(stateSituationSolde?.solde?.demandesEnCours).toLocaleString("fr-FR")} labelWidth={true} twoPoints={false} />
                                        <TextWithLabelContainer label={t("texts.new_balance")} value={Number(stateSituationSolde?.solde?.nouveauSolde).toLocaleString("fr-FR")} labelWidth={true} twoPoints={false} />
                                    </Stack>
                                </Box>
                            </Stack> 
                        </Box>
                    </Stack>
                </Box>
                <Box>
                    {isError ? 
                        <Box bgcolor={redColor[5]} borderRadius={2} boxSizing="border-box" height="100%" display="flex" alignItems="center" justifyContent="center" px={1}>
                            <Typography fontSize="small" fontWeight="bold" color={whiteColor[9]}>{error?.message}</Typography>
                        </Box>
                    :
                        <Stack spacing={0.5}>
                            <Box>
                                <SoldesText 
                                    isLoading={isLoading || isFetching}
                                    periode={stateSituationSolde.periode}   
                                />
                            </Box>
                            <Box>
                                <Box borderRadius={2} bgcolor={greenColor["015"]} height="100%" boxSizing="border-box" p={0.5} overflow={"auto"}>
                                    <SituationSoldeList data={data} isLoading={isLoading || isFetching} />
                                </Box>
                            </Box>
                        </Stack>
                    }
                </Box>
            </Stack>
        </DialogFullScreenContainer>
    );
}

SituationSoldeDialogMobile.defaultProps = {
    open: false, 
    setOpen: () => {}
}

SituationSoldeDialogMobile.propTypes = {
    open: PropTypes.bool, 
    setOpen: PropTypes.func
}

export default SituationSoldeDialogMobile;