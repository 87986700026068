import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import moment from "moment";
import { useCltApp } from "@hooks/client/hooksQueries";
import { useSecVarApp } from "@hooks/user/hooksQueries";
import Login from "@views/Login";
import AppRoot from "../AppRoot";
import { LoaderBackground } from "@components/Loaders";
import { GlobalError } from "@components/Errors";
import { useTranslation } from "react-i18next";
import i18next from "@translations/config";
import "moment/locale/fr.js";
import "moment/locale/en-gb.js";
import "moment/locale/de.js";
import "moment/locale/es.js";
import "moment/locale/it.js";
import "moment/locale/pt.js";
import "moment/locale/nl.js";

const localsRoute = {
    "fr": "fr",
    "en": "en-gb",
    "de": "de",
    "es": "es",
    "it": "it",
    "pt": "pt",
    "nl": "nl"
}

const AppRoute = () => {
    const { isLoading: isLoadingClient, isFetching: isFetchingClient, isError: isErrorClient, isSuccess: isSuccessClient } = useCltApp();
    const { isLoading: isLoadingSecureVariable, isFetching: isFetchingSecureVariable, isError: isErrorSecureVariable } = useSecVarApp(isSuccessClient);
    const { t } = useTranslation("global");
    moment.locale(localsRoute[i18next.language]);

    if(isLoadingClient || isFetchingClient || (isSuccessClient && (isLoadingSecureVariable || isFetchingSecureVariable))) {
        return (<LoaderBackground />);
    }

    if(isErrorClient) {
        return (<GlobalError error={t("errors.page_not_found")} />);
    }

    if(isErrorSecureVariable) {
        return (<GlobalError error={t("errors.data_recovery_security_params")} />);
    }

	return (
        <Router>
            <Routes>
                <Route key="connexion" path="/:client/connexion" element={<Login />} />
                <Route key="portail" path="/:client/portail" element={<AppRoot />} />
                <Route key="notFound" path="*" element={<GlobalError error={t("errors.page_not_found")} />} />
            </Routes>
        </Router>
	);
}

export default AppRoute;