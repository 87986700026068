import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { fetchCnf, editCnf } from "@api/configuration";
import * as dshActions from "@reducers/dashboard/dshReducer";

export const useDshApp = (token) => {
    const dispatch = useDispatch();
    
    const query = useQuery(['dshApp', token], ({ signal }) => fetchCnf(token, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if(query.data) {
            dispatch(dshActions.setDashboard(query.data));
        }
    }, [dispatch, query.data])

    return query;
}


export const useCnfPos = () => {
    const { mutateAsync, isLoading } = useMutation(editCnf);
    const { enqueueSnackbar } = useSnackbar();

    const postConfigurationActionner = async (data) => {
        let isSuccess = false;
        try {
            const repsonse = await mutateAsync(data);
            if(repsonse.succeed) {
                enqueueSnackbar("Dashboard mis à jour avec succès", { variant: "success" });
                isSuccess = true;
            } else {
                enqueueSnackbar(data.details, { variant: "error" });
            }            
        } catch(e) {
            enqueueSnackbar("Une erreur est survenue lors de la modification du dashboard", { variant: "error" });
        }

        return { isSuccess };
    }

    return { isLoading, postConfigurationActionner };
}