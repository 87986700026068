import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack } from '@mui/material'; 
import { selectCltApp, selectOrgApp, selectSocOrg } from '@selectors';
import * as socOrgActions from "@reducers/societes/socOrgReducer";
import { SocietesBar } from '@components/App/Societes/Bars';
import { SocietesDialog } from '@components/App/Societes/Dialogs';
import { useSocOrg } from '@hooks/societes/hooksQueries';
import { useOrg } from '@hooks/organigramme/hooksQueries';
import { DetailPosteDialog } from '@components/Organigramme/Dialogs';
import { OrganigrammeGraph } from '@components/Organigramme/Graphs';
import { TYPES_SOCIETES } from '@constants/societesConstants';
import { greenColor, whiteColor } from '@styles';

const Organigramme = () => {
    const stateOrganigramme = useSelector(selectOrgApp);
    const stateClient = useSelector(selectCltApp);
    const stateSocOrg = useSelector(selectSocOrg);
    const { isLoading: isLoadingSociete, isFetching: isFetchingSociete, isError: isErrorSociete } = useSocOrg();
    const { data, isLoading, isFetching, isError, error } = useOrg(stateSocOrg?.societeSelected?.codeSociete, stateClient.client);
    const [openSocietesDialog, setOpenSocietesDialog] = useState(false);
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [posteSelectionnee, setPosteSelectionnee] = useState(null);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(stateSocOrg.societes.length > 1) {
            dispatch(socOrgActions.resetSelection());
        }
        if(stateSocOrg.societes.length === 1) {
            dispatch(socOrgActions.selectSociete(stateSocOrg.societes[0]));
        }
    }, [dispatch, stateSocOrg.societes]);

    const handleOpenDialogDetails = useCallback((id) => {
        const poste = stateOrganigramme.postes.filter((item) => item.id === id)[0];
        if(poste) {
            setPosteSelectionnee(poste);
            setOpenDetailsDialog(true);
        }
    }, [stateOrganigramme.postes]);

    const selectSociete = useCallback((societe) => {
        setOpenSocietesDialog(false);
        dispatch(socOrgActions.selectSociete(societe));
    }, [dispatch]);

    return (
        <Stack spacing={1} sx={styles.stackStyle}>
            <SocietesBar 
                typeSociete={TYPES_SOCIETES.ORGANIGRAMME} 
                isLoading={isLoadingSociete || isFetchingSociete}
                isError={isErrorSociete}
                setOpenSociete={setOpenSocietesDialog}
                societes={stateSocOrg.societes}
                societe={stateSocOrg.societeSelected}
                bgcolor={greenColor["015"]}
            />
            {stateSocOrg.societeSelected &&
                <>
                    <Box sx={styles.panelGridStyle}>
                        <OrganigrammeGraph 
                            isLoading={isLoading || isFetching}
                            isError={isError}
                            errorMessage={error?.message}
                            postes={data} 
                            handleOpenDialogDetails={handleOpenDialogDetails} 
                        />
                    </Box>
                    <DetailPosteDialog open={openDetailsDialog} setOpen={setOpenDetailsDialog} poste={posteSelectionnee} />
                </>
            }
            <SocietesDialog 
                open={openSocietesDialog}
                setOpen={setOpenSocietesDialog}
                societes={stateSocOrg.societesDisplayed}
                selectSociete={selectSociete}
            />
        </Stack>
       
    )
}

const styles = {
    panelGridStyle: {
        bgcolor: whiteColor[8],
        marginX: "0!important",
        marginTop: 1,
        borderRadius: 2,
        height: "calc(100vh - 220px)"
    },
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        height: "100%",
    },
    stackStyle: {
        bgcolor: whiteColor[9],
        borderRadius: 2,
        padding: 1,
        position: "relative",
        height: "calc(100vh - 166px)"
    }
};

export default Organigramme;