import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { distinct,  filter, map, select, tidy } from "@tidyjs/tidy";
import PropTypes from "prop-types";
import { selectEntCol } from "@selectors";
import * as entColActions from "@reducers/gestionEntretiens/entColReducer";
import { DateHookFormInput, SelectHookFormInput } from "@components/Inputs";
import { schemaFilterEntretiens } from "@components/GestionEntretiens/validations";
import { filtrerEntCol } from "@components/GestionEntretiens/filtres";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";

const colonneOptions = [
    { id: 1, value: "date", label: "Date" },
    { id: 2, value: "nom", label: "Nom" },
    { id: 3, value: "statut", label: "Statut" }
];

const operateurOptions = [
    { id: 1, value: "egale", label: "À la date du" },
    { id: 2, value: "superieur", label: "Après le" },
    { id: 3, value: "inferieur", label: "Avant le" }
];

const statutsOptions = [
    { id: 1, value: "P", label: "Planifié" },
    { id: 2, value: "E", label: "Effectué" }
];

const defaultValues = {
    colonne: "",
    operateur: "egale",
    date: null,
    txt: ""
};

const FiltreEntretiensForm = ({ 
    setOpen = () => {} 
}) => {
    const stateEntretiens = useSelector(selectEntCol); 
    const [noms, setNoms] = useState([]);
    const methods = useForm({ mode: "all", defaultValues: defaultValues, resolver: zodResolver(schemaFilterEntretiens) });
    const colonne = methods.watch("colonne");
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(stateEntretiens.entretiens) {            
            const nomsOptions = tidy(stateEntretiens.entretiens, select("prenomNom"), map((d, id) => ({id: id + 1, value: d.prenomNom, label: d.prenomNom})), distinct(["value"]), filter(d => d.prenomNom !== ""));
            setNoms(nomsOptions);
        }
    }, [stateEntretiens.entretiens]);

    useEffect(() => {
        methods.resetField("date");
        methods.resetField("operateur");
        methods.resetField("txt");
    }, [colonne, methods]);

    const filtrer = useCallback((data) => {
        setOpen(false);
        const entretiensFiltered = filtrerEntCol(data, stateEntretiens.entretiens);
        dispatch(entColActions.filtrer(entretiensFiltered));
    }, [dispatch, setOpen, stateEntretiens.entretiens]);
    
    return (
        <FormProvider {...methods}>
            <Stack spacing={1}>
                <SelectHookFormInput name="colonne" label="Colonnes" options={colonneOptions} />
                {{
                    date: 
                        <Stack direction="row" spacing={1}>
                            <SelectHookFormInput name="operateur" label="Opérateur" options={operateurOptions} />
                            <Box flexGrow={1}>
                                <DateHookFormInput name="date" label="Date" />
                            </Box>
                        </Stack>,
                    nom: <SelectHookFormInput name="txt" label="Nom" options={noms} />,
                    statut: <SelectHookFormInput name="txt" label="Statut" options={statutsOptions} />
                }[colonne]}
            </Stack>
            <Box sx={styles.boxButtonsMenuFiltre}>
                <Stack direction="row" spacing={1}>
                    <Tooltip title="Valider">
                        <IconButton sx={styles.buttonStyleGreen} onClick={methods.handleSubmit(filtrer)}>
                            <Check fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Fermer">
                        <IconButton sx={styles.buttonStyleRed} onClick={() => setOpen(false)}>
                            <Close fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Box>
        </FormProvider>
    );
}

FiltreEntretiensForm.propTypes = {
    setOpen: PropTypes.func
}

const styles = {
    boxButtonsMenuFiltre: {
        justifyContent: "center",
        display: "flex"
    },
    buttonStyleGreen: {
        boxShadow: "none", 
        backgroundColor: greenColor["02"],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: greenColor["03"],
            color: whiteColor[9],
        }
    },
    buttonStyleRed: {
        boxShadow: "none", 
        backgroundColor: redColor[1],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: redColor[2],
            color: whiteColor[9],
        }
    }
}

export default FiltreEntretiensForm;