import { useSelector } from "react-redux";
import { selectColAffActCol, selectColAffActInd, selectColAffHorCol, selectColAffHorInd, selectColCreGesTem, selectColMvtGesTem, selectColPlaGesTem, selectColTemGesTem } from "@selectors";
import { TYPES_COLLABORATEURS } from "@constants/collaborateursConstants";

export const useStateCollaborateurs = (type) => {
    const stateColTemGesTem = useSelector(selectColTemGesTem);
    const stateColPlaGesTem = useSelector(selectColPlaGesTem);
    const stateColAffHorInd = useSelector(selectColAffHorInd);
    const stateColAffHorCol = useSelector(selectColAffHorCol);
    const stateColAffActInd = useSelector(selectColAffActInd);
    const stateColAffActCol = useSelector(selectColAffActCol);
    const stateColCreGesTem = useSelector(selectColCreGesTem);
    const stateColMvtGesTem = useSelector(selectColMvtGesTem);

    if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_TEMPS) {
        return stateColTemGesTem;
    }
    if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_PLANNING) {
        return stateColPlaGesTem;
    }
    if(type === TYPES_COLLABORATEURS.AFFECTATIONS_HORAIRES_INDIVIDUELLES) {
        return stateColAffHorInd;
    }
    if(type === TYPES_COLLABORATEURS.AFFECTATIONS_HORAIRES_COLLECTIVES) {
        return stateColAffHorCol;
    }
    if(type === TYPES_COLLABORATEURS.AFFECTATIONS_ACTIVITES_INDIVIDUELLES) {
        return stateColAffActInd;
    }
    if(type === TYPES_COLLABORATEURS.AFFECTATIONS_ACTIVITES_COLLECTIVES) {
        return stateColAffActCol;
    }
    if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_CREDIT) {
        return stateColCreGesTem;
    }
    if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_MOUVEMENT) {
        return stateColMvtGesTem;
    }
    
    return null;
}
