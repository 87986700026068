import { useCallback } from "react";
import PropTypes from "prop-types";
import MenuLevel from "./MenuLevel";
import MenuItem from "./MenuItem";

const Menu = ({ 
    nodes = null, 
    mobile = false
}) => {
    const hasOptions = useCallback((item) => {
        if (item === undefined) {
            return false;
        }

        return item.options.length !== 0;
    }, []);

    return (hasOptions(nodes) ? <MenuLevel item={nodes} mobile={mobile} /> : <MenuItem item={nodes} mobile={mobile} />);
}

Menu.propTypes = {
    nodes: PropTypes.any, 
    mobile: PropTypes.bool
}

export default Menu;