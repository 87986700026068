import { Box, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { SocietesText } from "../Texts";
import { redColor, whiteColor } from "@styles";
import { Factory } from "@mui/icons-material";
import { LoaderButton } from "@components/Buttons";


const SocietesBar = (props) => {
    const { typeSociete, isLoading, isError, setOpenSociete, bgcolor, societes, societe } = props;
    
    return (
        <Stack direction="row" spacing={1} sx={styles.stackStyle} bgcolor={isError ? redColor[1] : bgcolor}>
            <Box px={1} py={societes.length > 1 ? 0 : 0.5} flexGrow={1}>
                <SocietesText type={typeSociete} isError={isError} isLoading={isLoading} societes={societes} societe={societe} />
            </Box>
            <Box>
                {societes.length > 1 && 
                    <LoaderButton 
                        icon={<Factory fontSize="small" />} 
                        title="Selectionner une société" 
                        isLoading={isLoading} 
                        isError={isError} 
                        errorMessage="Erreur dans la récupération des sociétés." 
                        action={() => { setOpenSociete(true) }} 
                    />
                }
            </Box>
        </Stack>
    )
}

SocietesBar.defaultProps= {
    isLoading: true,
    isError: false,
    bgcolor: whiteColor[5],
    societes: [],
    societe: null,
    setOpenSociete: () => {}
}

SocietesBar.propTypes = {
    typeSociete: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    bgcolor: PropTypes.string,
    societes: PropTypes.array,
    societe: PropTypes.object,
    setOpenSociete: PropTypes.func
}

const styles = {
    titleStyle: {
        xs: "11px", 
        sm: "medium"
    },
    stackStyle: {
        alignItems: "center",
        borderRadius: 2,
        padding: 1,
        position: "relative",
    },
    cardStyle: {
        backdropFilter: "blur(3px)",
        backgroundColor: "rgba(255,255,255,0.9)",
        width: "100%",
        height: "100%",
        borderRadius: 2
    }
};


export default SocietesBar;