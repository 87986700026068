import { Box, Grid, IconButton, Stack } from "@mui/material";
import { Info } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { PanelListContainer, TextWithLabelContainer } from "@components/Containers";
import { ErrorBase } from "@components/Errors";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, whiteColor } from "@styles";
import PerfectScrollbar from "react-perfect-scrollbar";

const RegularisationsListMobile = (props) => {
    const { data, isLoading, isError, errorMessage, anomalies, openAnomalies } = props;

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader size={20} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return (<ErrorBase>{errorMessage}</ErrorBase>);
    }

    return (
        <PerfectScrollbar>
            <PanelListContainer length={data?.length}>
                {data.map((regularisation, id) => (
                    <Grid key={`REGU${regularisation.id}`} item xs={12}>
                        <Stack spacing={0.5} bgcolor={greenColor["015"]} borderRadius={2} p={0.5}>    
                            <Box bgcolor={whiteColor[5]} borderRadius={2} p={1}>
                                <Stack spacing={0}>      
                                    <TextWithLabelContainer label="Mouvement" value={regularisation.libelleMouvement} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                    <TextWithLabelContainer label="Date" value={moment([regularisation.anneeRegularisation, regularisation.moisRegularisation - 1, regularisation.jourRegularisation]).format("DD/MM/YYYY")} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                    <TextWithLabelContainer label="Heure" value={`${moment(regularisation.heureRegularisation, "H").format("HH")}h${moment(regularisation.minuteRegularisation, "m").format("mm")}`} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                    <TextWithLabelContainer label="Activité" value={regularisation.libelleActivite} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                    <TextWithLabelContainer label="Type Travail" value={regularisation.libelleTypeTravail} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                    {anomalies &&
                                        <Box borderRadius={2} height={35.5} display="flex" alignItems="center" justifyContent="center" bgcolor={whiteColor[5]}>
                                            {regularisation.anomalies.length > 0 &&
                                                <IconButton size="small" onClick={() => openAnomalies(regularisation)}>
                                                    <Info fontSize="small" />
                                                </IconButton>
                                            }
                                        </Box>
                                    }
                                </Stack>
                            </Box>
                        </Stack>
                    </Grid>
                ))}
            </PanelListContainer>
        </PerfectScrollbar>
    );
}

RegularisationsListMobile.defaultProps = {
    data: [],
    isLoading: true,
    isError: false,
    errorMessage: "",
    anomalies: true,
    openAnomalies: () => {}
}

RegularisationsListMobile.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
    anomalies: PropTypes.bool,
    openAnomalies: PropTypes.func
}

const styles = {
    loaderStyle: {
        backgroundColor: greenColor["015"],
        borderRadius: 2,
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        p: 1,
    }
}

export default RegularisationsListMobile;