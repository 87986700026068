import { useSelector } from "react-redux";
import { selectTemGesTemCol, selectTemGesTemMan } from "@selectors";
import { ROLE_UTILISATEUR } from "@constants/userConstants";

export const useStateTemGesTem = (type) => {
    const stateTempsCollaborateur = useSelector(selectTemGesTemCol);
    const stateTempsManager = useSelector(selectTemGesTemMan);

    if(type === ROLE_UTILISATEUR.MANAGER) {
        return stateTempsManager;
    }
    
    return stateTempsCollaborateur;
}
