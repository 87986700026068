import { useEffect, useState } from "react";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { AccessTime, Info } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { greenColor, greyColor, redColor, whiteColor, yellowColor } from "@styles";

const InformationsBar = (props) => {
    const { temps, statut, dateDernierMouvement, heureDernierMouvement } = props;
    const [tempsEcoule, setTempsEcoule] = useState();

    useEffect(() => {
        setTempsEcoule(moment(temps, "HH[h]mm"));
    }, [temps])

    useEffect(() => {
        const getTempsEcoule = setInterval(() => {
            setTempsEcoule((prevTempsEcoule) => moment(prevTempsEcoule, "HH[h]mm").add(1, "minute"));
        }, 60000);
        return () => clearInterval(getTempsEcoule);
    }, []);

    return (
        <>
            <Stack spacing={2} direction="row" bgcolor={whiteColor[5]} borderRadius={2} p={1} alignItems="center" flexGrow={1}>
                <Box>
                    <Skeleton sx={statut === "P" ? styles.skeletonGreenStyle : styles.skeletonRedStyle} animation="pulse" variant="circular" width={18} height={18} />
                </Box>
                <Box>
                    <Typography fontSize="small" fontWeight="bold">{statut === "P" ? "Présent" : "Absent" }</Typography>
                </Box>
            </Stack>
            <Stack spacing={2} direction="row" bgcolor={whiteColor[5]} borderRadius={2} p={1} alignItems="center" flexGrow={4}>
                <Info fontSize="small" />
                <Box>
                    {statut === "A" ? 
                        <Typography fontSize="small" fontWeight="bold">Dernier mouvement le {moment(dateDernierMouvement, "DDMMYYYY").format("DD/MM/YYYY")} à {moment(heureDernierMouvement, "HHmm").format("HH[h]mm")}</Typography>
                    :
                        <Typography fontSize="small" fontWeight="bold">A débuté le {moment(dateDernierMouvement, "DDMMYYYY").format("DD/MM/YYYY")} à {moment(heureDernierMouvement, "HHmm").format("HH[h]mm")}</Typography>
                    }
                </Box>
            </Stack>
            {(tempsEcoule?.isValid()) && 
                <Stack spacing={2} direction="row" bgcolor={whiteColor[5]} borderRadius={2} p={1} alignItems="center" flexGrow={2}>
                    <AccessTime fontSize="small" />
                    <Box>
                        {tempsEcoule?.isValid() && <Typography fontSize="small" fontWeight="bold">Temps écoulé : {tempsEcoule?.format("HH[h]mm")}</Typography>}
                    </Box>
                </Stack>
            }
        </>
    );
}

InformationsBar.defaultProps = {
    temps: "", 
    statut: "", 
    dateDernierMouvement: "", 
    heureDernierMouvement: ""
}

InformationsBar.propTypes = {
    temps: PropTypes.string, 
    statut: PropTypes.string, 
    dateDernierMouvement: PropTypes.string, 
    heureDernierMouvement: PropTypes.string
}

const styles = {
    loaderStyle: {
        height: 52,
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
    },
    paperStyle: {  
        width: "100%",
        background: "none",
        borderRadius: 2
    },
    boxStatutStyle: {
        borderRadius: 2, 
        boxShadow: "none", 
        backgroundColor: greyColor[0],
        padding: 2,
        color: greyColor[5],
        justifyContent: "start",
        alignItems: "center"
    },
    boxErrorStatutStyle: {
        borderRadius: 2, 
        boxShadow: "none", 
        backgroundColor: yellowColor[1],
        padding: 1,
        color: greyColor[5],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 36
    },
    buttonStyle: {
        borderRadius: 2, 
        boxShadow: "none", 
        backgroundColor: greenColor["02"],
        padding: 2,
        color: greyColor[5],
        justifyContent: "start",
        "&:hover": {
            backgroundColor: greenColor["03"],
            color: whiteColor[9],
        }
    },
    skeletonGreenStyle: {
        backgroundColor: greenColor["06"]
    },
    skeletonRedStyle: {
        backgroundColor: redColor[5]
    }
};


export default InformationsBar;