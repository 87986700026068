import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, whiteColor, yellowColor } from "@styles";
import { ErrorBase } from "@components/Errors";
import { Check, Close } from "@mui/icons-material";
import { TextIconContainer } from "@components/Containers";
import { TextMotifAbsenceContainer, TextNombreHeuresAbsenceContainer, TextNombreJoursAbsenceContainer } from "@components/Containers/GestionAbsences";

const AbsencesList = (props) => {
    const { data, isLoading, isError, errorMessage } = props;

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader size={17} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return <ErrorBase>{errorMessage}</ErrorBase>
    }

    return (
        data.length > 0 ?
            data.map((absence) => (
                <Stack spacing={0.5} key={`DJSABS${absence.id}`} bgcolor={Number(absence.codeStatut) ? greenColor["015"] : yellowColor[2]} borderRadius={2} p={0.5}>
                    <TextIconContainer icon={Number(absence.codeStatut) ? <Check fontSize="small" /> : <Close fontSize="small" />} bgColor={whiteColor[5]} padding={1} textWeight="bold">Statut : {absence.libelleStatut}</TextIconContainer>
                    <Stack spacing={0.5}>
                        {absence.libelle && <TextMotifAbsenceContainer libelleAbsence={absence.libelle} />}
                        <Stack spacing={0.5} bgcolor={whiteColor[5]} borderRadius={2} p={1}>
                            <TextNombreJoursAbsenceContainer nombreJours={absence.nombreJours} demiJournee={absence.ampm} />
                            <TextNombreHeuresAbsenceContainer heureDebut={absence.plageDebut} heureReprise={absence.plageReprise} nombreHeures={absence.nombreHeures} nombreJours={absence.nombreJours} />
                        </Stack>
                    </Stack>
                </Stack>
            ))
        :
            <Box sx={styles.dialogTitleStyle}>
                <Typography fontSize="small">Aucune absence</Typography>
            </Box>
        
    );
}

AbsencesList.defaultProps = {
    data: [],
    errorMessage: ""
}

AbsencesList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string
}

const styles = {
    dialogTitleStyle: {
        backgroundColor: greenColor["015"], 
        textAlign: "center",
        p: 1, 
        borderRadius: 2
    },
    loaderStyle: {
        p: 2,
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
    }
}

export default AbsencesList;