import { LocalizationProvider, MobileDatePicker, PickersDay, frFR } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useState } from "react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import IconDatePickerButton from "@components/Buttons/IconDatePickerButton";
import { greenColor, whiteColor } from "@styles";

const CustomPickersDay = styled(PickersDay, {shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered'})(({ isSelected, isHovered, day }) => ({
    borderRadius: 0,
    ...(isSelected && {
        backgroundColor: greenColor["025"],
        '&:hover, &:focus': {
            backgroundColor: greenColor["025"],
        },
    }),
    ...(isHovered && {
        backgroundColor: greenColor["025"],
        '&:hover, &:focus': {
            backgroundColor: greenColor["025"],
        },
    }),
    ...(day.day() === 0 && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
    ...(day.day() === 1 && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
}));

const isInSameWeek = (dayA, dayB) => {
    if (dayB == null) {
        return false;
    }
  
    return dayA.isSame(dayB, 'week');
};

const Day = (props) => {
    const { day, selectedDay, hoveredDay, ...other } = props;
  
    return (
        <CustomPickersDay
            {...other}
            day={day}
            sx={{ px: 2.5 }}
            disableMargin
            selected={false}
            isSelected={isInSameWeek(day, selectedDay)}
            isHovered={isInSameWeek(day, hoveredDay)}
        />
    );
}

const WeekPickerLabelizedButtonMobile = (props) => {
    const { date, icon, title = "", placement = "bottom", timeout = 200, noClick = false, action = () => {} } = props;
    const [open, setOpen] = useState(false);
    const [hoveredDay, setHoveredDay] = useState(null);

    return (  
        <Box display={"inline-flex"} borderRadius={20} bgcolor={whiteColor[5]} alignItems={"center"}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"frFR"} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                <MobileDatePicker
                    slots={{ field: IconDatePickerButton, day: Day }}
                    slotProps={{ 
                        field: { icon, title, placement, timeout, noClick, setOpen }, 
                        day: (ownerState) => ({
                            selectedDay: date,
                            hoveredDay,
                            onPointerEnter: () => setHoveredDay(ownerState.day),
                            onPointerLeave: () => setHoveredDay(null),
                        }), 
                        desktopPaper: {sx: { px:1 }}
                    }}
                    showDaysOutsideCurrentMonth
                    displayWeekNumber
                    open={open}
                    views={["year", "month", "day"]}
                    value={date}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    onAccept={(date) => {action(date)}}
                />
            </LocalizationProvider>
            <Box pr={2} component={"p"} m={0} fontSize="small" fontWeight={"bold"}>Semaine : {date.week()} - Année : {date.year()}</Box>
        </Box>
    );
}

WeekPickerLabelizedButtonMobile.defaultProps = {
    date: null, 
    icon: <></>, 
    title: "", 
    placement: "bottom", 
    timeout: 200, 
    noClick: false, 
    action: () => {}
}

WeekPickerLabelizedButtonMobile.propTypes = {
    date: PropTypes.object, 
    icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]), 
    title: PropTypes.string, 
    placement: PropTypes.string, 
    timeout: PropTypes.number, 
    noClick: PropTypes.bool, 
    action: PropTypes.func
}

export default WeekPickerLabelizedButtonMobile;