import moment from "moment";
import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { DialogContainer, HeaderDialogContainer } from "@components/Containers";
import { greenColor, greyColor, whiteColor, yellowColor } from "@styles";
import { TextMotifAbsenceContainer, TextNombreHeuresAbsenceContainer, TextNombreJoursAbsenceContainer } from "@components/Containers/GestionAbsences";
import { useTranslation } from "react-i18next";

const JourCalendrierDialog = (props) => {
    const { open, setOpen, jour } = props;
    const { t } = useTranslation("global");
    const date = moment(jour?.date, "DDMMYYYY");
    const jourString = date.format("dddd");
    const jourInt = date.format("D");
    const mois = date.format("MMMM");
    const annee = date.format("YYYY");
    const libelleFerie = jour?.type !== "" ? "("+jour?.libelleFerie+")" : "";
    const dateString = `${jourString} ${jourInt} ${mois} ${annee} ${libelleFerie}`;

    return (
        <DialogContainer open={open} taille="sm">
            <HeaderDialogContainer
                close={() => setOpen(false)}
                title={jour ? dateString : ""}
            />
            {jour ?
                <Stack spacing={2}>
                    {jour?.absences?.length > 0 ?
                        <Stack spacing={0.5}>
                            {jour.absences.map((absence) => (
                                <Stack spacing={0} key={`DJSABS${absence.id}`}>
                                    <Box display="flex">
                                        <Box  bgcolor={Number(absence.codeStatut) ? greenColor["05"] : yellowColor[6]} sx={{borderTopLeftRadius: 8, borderTopRightRadius: 8}} pt={0.5} pr={0.5} pl={0.5}  display="flex" justifyContent="center">
                                            <Box bgcolor={whiteColor[7]} py={1} px={2} borderRadius={2}>
                                                <Typography fontSize="small" fontWeight="bold">
                                                    {absence.libelleStatut}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Stack spacing={0.5}  bgcolor={Number(absence.codeStatut) ? greenColor["05"] : yellowColor[6]} sx={{borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopRightRadius: 8}} p={0.5}>
                                        {absence.libelle && <TextMotifAbsenceContainer libelleAbsence={absence.libelle} />}
                                        <Stack spacing={0.5} bgcolor={whiteColor[7]} borderRadius={2} p={1}>
                                            <TextNombreJoursAbsenceContainer nombreJours={absence.nombreJours} demiJournee={absence.ampm} />
                                            <TextNombreHeuresAbsenceContainer heureDebut={absence.plageDebut} heureReprise={absence.plageReprise} nombreHeures={absence.nombreHeures} nombreJours={absence.nombreJours} />
                                        </Stack>
                                    </Stack>
                                </Stack>
                            ))}
                            {jour.absences.map((absence) => (
                                <Stack spacing={0} key={`DJSABS${absence.id}`}>
                                    <Box display="flex">
                                        <Box  bgcolor={Number(absence.codeStatut) ? greenColor["05"] : yellowColor[6]} sx={{borderTopLeftRadius: 8, borderTopRightRadius: 8}} pt={0.5} pr={0.5} pl={0.5}  display="flex" justifyContent="center">
                                            <Box bgcolor={whiteColor[7]} py={1} px={2} borderRadius={2}>
                                                <Typography fontSize="small" fontWeight="bold">
                                                    {absence.libelleStatut}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Stack spacing={0.5}  bgcolor={Number(absence.codeStatut) ? greenColor["05"] : yellowColor[6]} sx={{borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopRightRadius: 8}} p={0.5}>
                                        {absence.libelle && <TextMotifAbsenceContainer libelleAbsence={absence.libelle} />}
                                        <Stack spacing={0.5} bgcolor={whiteColor[7]} borderRadius={2} p={1}>
                                            <TextNombreJoursAbsenceContainer nombreJours={absence.nombreJours} demiJournee={absence.ampm} />
                                            <TextNombreHeuresAbsenceContainer heureDebut={absence.plageDebut} heureReprise={absence.plageReprise} nombreHeures={absence.nombreHeures} nombreJours={absence.nombreJours} />
                                        </Stack>
                                    </Stack>
                                </Stack>
                            ))}
                            {jour.absences.map((absence) => (
                                <Stack spacing={0} key={`DJSABS${absence.id}`}>
                                    <Box display="flex">
                                        <Box  bgcolor={Number(absence.codeStatut) ? greenColor["05"] : yellowColor[6]} sx={{borderTopLeftRadius: 8, borderTopRightRadius: 8}} pt={0.5} pr={0.5} pl={0.5}  display="flex" justifyContent="center">
                                            <Box bgcolor={whiteColor[7]} py={1} px={2} borderRadius={2}>
                                                <Typography fontSize="small" fontWeight="bold">
                                                    {absence.libelleStatut}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Stack spacing={0.5}  bgcolor={Number(absence.codeStatut) ? greenColor["05"] : yellowColor[6]} sx={{borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopRightRadius: 8}} p={0.5}>
                                        {absence.libelle && <TextMotifAbsenceContainer libelleAbsence={absence.libelle} />}
                                        <Stack spacing={0.5} bgcolor={whiteColor[7]} borderRadius={2} p={1}>
                                            <TextNombreJoursAbsenceContainer nombreJours={absence.nombreJours} demiJournee={absence.ampm} />
                                            <TextNombreHeuresAbsenceContainer heureDebut={absence.plageDebut} heureReprise={absence.plageReprise} nombreHeures={absence.nombreHeures} nombreJours={absence.nombreJours} />
                                        </Stack>
                                    </Stack>
                                </Stack>
                            ))}
                        </Stack>
                    :
                        <Box sx={styles.dialogTitleStyle}>
                            <Typography fontSize="small" fontWeight="bold" color="white">{t("no_data.absence")}</Typography>
                        </Box>
                    }
                </Stack> 
            :
                <Stack spacing={2} padding={2}>
                    <Box sx={styles.dialogTitleStyle}>
                        <Typography fontSize="small" fontWeight="bold" color="white">{t("no_data.day")}</Typography>
                    </Box>
                </Stack>
            }
        </DialogContainer>
    );
}

JourCalendrierDialog.defaultProps = {
    open: false,
    setOpen: () => {},
    jour: null
}

JourCalendrierDialog.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    jour: PropTypes.object
}

const styles = {
    dialogTitleStyle: {
        backgroundColor: greenColor["07"], 
        textAlign: "center",
        p: 2, 
        borderRadius: 2
    },
    boxListItemStyle: {
        backgroundColor: greyColor[0], 
        borderRadius: 2,
        padding: 1
    },
    boxListItemValiderStyle: {
        backgroundColor: greenColor["03"], 
        borderRadius: 2,
        padding: 1
    },
    boxListItemEnAttenteStyle: {
        backgroundColor: yellowColor[2], 
        borderRadius: 2,
        padding: 1
    }
}

export default JourCalendrierDialog;