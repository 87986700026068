import { filter, tidy } from "@tidyjs/tidy";
import moment from "moment";

export const filtrerEntMan = (data, dataBrut) => {
    let dataFiltered = [...dataBrut];
    
    switch(data.colonne) {
        case "date":
            switch(data.operateur) {
                case "egale":
                    return tidy(dataFiltered, filter(entretiens => moment([entretiens.annee, entretiens.mois - 1, entretiens.jour]).isSame(data.date)));
                case "superieur":
                    return tidy(dataFiltered, filter(entretiens => moment([entretiens.annee, entretiens.mois - 1, entretiens.jour]).isAfter(data.date)));
                case "inferieur":
                    return tidy(dataFiltered, filter(entretiens => moment([entretiens.annee, entretiens.mois - 1, entretiens.jour]).isBefore(data.date)));
                default: 
                    return dataFiltered
            }
        case "societe":
            return tidy(dataFiltered, filter(entretiens => entretiens.codeSociete === data.txt));
        case "matricule":
            return tidy(dataFiltered, filter(entretiens => entretiens.matricule === data.txt));
        case "nom":
            return tidy(dataFiltered, filter(entretiens => entretiens.prenomNom === data.txt));
        case "statut":
            return tidy(dataFiltered, filter(entretiens => entretiens.statut === data.txt));
        default:
            return dataFiltered
    }
};

export const filtrerEntCol = (data, dataBrut) => {
    let dataFiltered = [...dataBrut];
    
    switch(data.colonne) {
        case "date":
            switch(data.operateur) {
                case "egale":
                    return tidy(dataFiltered, filter(entretiens => moment([entretiens.annee, entretiens.mois - 1, entretiens.jour]).isSame(data.date)));
                case "superieur":
                    return tidy(dataFiltered, filter(entretiens => moment([entretiens.annee, entretiens.mois - 1, entretiens.jour]).isAfter(data.date)));
                case "inferieur":
                    return tidy(dataFiltered, filter(entretiens => moment([entretiens.annee, entretiens.mois - 1, entretiens.jour]).isBefore(data.date)));
                default: 
                    return dataFiltered
            }
        case "nom":
            return tidy(dataFiltered, filter(entretiens => entretiens.prenomNom === data.txt));
        case "statut":
            return tidy(dataFiltered, filter(entretiens => entretiens.statut === data.txt));
        default:
            return dataFiltered
    }
};
