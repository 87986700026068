import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    filtered: false,
    tried: false,
    horaire: false,
    entretiens: [],
    entretiensDisplayed: [],
    entretienSelected: null,
};

const entManSlice = createSlice({
    name: 'entMan',
    initialState: initialState,
    reducers: {
        setEntretiens: (state, action) => {
            state.entretiens = action.payload;
            state.entretiensDisplayed = action.payload;
        },
        filtrer: (state, action) => {
            state.entretiensDisplayed = action.payload;
            state.filtered = true;
        },
        trier: (state, action) => {
            state.entretiensDisplayed = action.payload;
            state.tried = true;
        },
        deleteFiltre: (state) => {
            state.entretiensDisplayed = state.entretiens;
            state.filtered = false
        },
        deleteTrie: (state) => {
            state.entretiensDisplayed = state.entretiens;
            state.tried = false
        },
        selectEntretien: (state, action) => {
            state.entretienSelected = action.payload;
        }
    }
})

const { actions, reducer } = entManSlice
export const { 
    setEntretiens, 
    deleteFiltre, 
    deleteTrie, 
    filtrer, 
    trier,
    selectEntretien
} = actions
export default reducer
