import PropTypes from "prop-types";
import { useDetJouPla } from "@hooks/planning/hooksQueries";
import { DialogFullScreenContainer } from "@components/Containers";
import JourActions from "./Actions/JourActions";
import JourContents from "./Contents/JourContents";
import { useStatePlanning } from "@hooks/planning/hooksStates";

const JourDialogMobile = (props) => {
    const { type, open, setOpen } = props;
    const statePlanning = useStatePlanning(type);    
    const { data, isLoading, isFetching, isError, error } = useDetJouPla(statePlanning.matriculeSelected, statePlanning.jourSelected, open);

    return (
        <DialogFullScreenContainer open={open} taille="sm">
            <JourActions 
                setOpen={setOpen}
                jourSelected={statePlanning.jourSelected}
            />
            <JourContents 
                data={data}
                isLoading={isLoading || isFetching}
                isError={isError}
                errorMessage={error?.message}
                prenomNom={statePlanning.prenomNomSelected}
            />
        </DialogFullScreenContainer>
    );
}

JourDialogMobile.defaultProps = {
    type: "",
    open: false, 
    setOpen: () => {}
}

JourDialogMobile.propTypes = {
    type: PropTypes.string,
    open: PropTypes.bool, 
    setOpen: PropTypes.func
}

export default JourDialogMobile;