import { Box, Grid, Stack, Typography } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { TextContainer } from "@components/Containers";
import { greenColor, whiteColor } from "@styles";
import { useMedia } from "react-use";

const SuiviList = ({
    suivi = []
}) => {
    const isMobile = useMedia('(max-width: 650px)');

    return (
        <>
            <TextContainer bgColor={whiteColor[7]} p={1} textWeight="bold" horizontalAlign="center">Suivi</TextContainer>
            {suivi?.length > 0 ?
                suivi?.map((item, id) => (
                    <Stack key={`LHS${item.id}`} spacing={0.5} bgcolor={whiteColor[7]} p={1} borderRadius={2}>
                        <Box>
                            {isMobile ? 
                                <Grid container spacing={1}>
                                    <Grid item xs={12} textAlign="center">
                                        <Box padding={0.5} borderRadius={2} bgcolor={greenColor["07"]}>
                                            <Typography fontWeight="bold" fontSize="small" color="white">Réponse {id+1}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography fontWeight="bold" fontSize="small">Destinataire : {item.destinataire}</Typography>
                                    </Grid>
                                </Grid>
                            :
                                <Grid container>
                                    <Grid item xs={10}>
                                        <Typography fontWeight="bold" fontSize="small">Destinataire : {item.destinataire}</Typography>
                                    </Grid>
                                    <Grid item xs={2} textAlign="center" padding={0.5} bgcolor={greenColor["07"]} borderRadius={2}>
                                        <Typography fontWeight="bold" fontSize="small" color="white">Réponse {id+1}</Typography>
                                    </Grid>
                                </Grid>
                            }
                        </Box>
                        <Typography fontSize="small">Averti : {item.destinataireAverti === "O" ? "Oui" : "Non"}</Typography>
                        <Typography fontSize="small">{item.reponse ? item.reponse + " le " + moment(item.dateReponse, "DDMMYYYY").format("DD/MM/YYYY") + " à " + moment(item.heureReponse, "HHmmss").format("HH:mm:ss") : "Pas encore de réponse"}</Typography>
                        {item.details ? <Typography fontSize="small">Message : {item.details}</Typography> : null}
                        {item.motifRefus ? <Typography fontSize="small">Motif de refus : {item.motifRefus}</Typography> : null}
                    </Stack>
                ))
            :   
                <Box bgcolor={whiteColor[7]} p={1} borderRadius={2}>
                    <Grid container alignItems="center" justifyContent="center">
                        <Typography fontSize="small">Aucune donnée</Typography>
                    </Grid>
                </Box>
            }
        </>
    );
}

SuiviList.propTypes = {
    suivi: PropTypes.array
}

export default SuiviList;