import { Stack } from "@mui/material";
import { Event, FilterList, MobiledataOff, PendingActions, QueryBuilder, SwapVert } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useResetTrieCollaborateurs, useSelectDateCollaborateurs } from "@hooks/collaborateurs/hooksUtils";
import { IconButtonBase, WeekPickerLabelizedButton } from "@components/Buttons";

const CollaborateursFwgtp3Actions = ({ 
    stateType = "", 
    dateValidite = "", 
    isLoading = false, 
    horaire = false, 
    tried = false, 
    toggleTrie = () => {}, 
    setOpenFiltre = () => {},  
    setHoraire = () => {}
}) => {
    const resetTrie = useResetTrieCollaborateurs(stateType);
    const selectDate = useSelectDateCollaborateurs(stateType);

    return (
        <Stack direction="row" spacing={0.5}>
            <IconButtonBase variant="white5Grey9White9" icon={tried ? <MobiledataOff fontSize="small" /> : <SwapVert fontSize="small" />} disabled={isLoading} title={tried ? "Enlever le trie" : "Trier"} action={tried ? resetTrie : toggleTrie} />
            <IconButtonBase variant="white5Grey9White9" icon={<FilterList fontSize="small" />} disabled={isLoading} title="Filtrer" action={() => setOpenFiltre((prevOpen) => !prevOpen)} />
            <WeekPickerLabelizedButton 
                icon={<Event fontSize="small" />} 
                title="Choisir une semaine" 
                noClick={isLoading} 
                date={dateValidite} 
                variant="white2Grey9White9"
                action={selectDate} 
            />
            <IconButtonBase variant="white5Grey9White9" icon={horaire ? <QueryBuilder fontSize="small" /> : <PendingActions fontSize="small" />} disabled={isLoading} title={horaire ? "Heures" : "Horaires"} action={() => setHoraire((prevOpen) => !prevOpen)} />
        </Stack>
    );
}

CollaborateursFwgtp3Actions.propTypes = {
    stateType: PropTypes.string.isRequired,
    dateValidite: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    isLoading: PropTypes.bool.isRequired,
    horaire: PropTypes.bool,
    tried: PropTypes.bool.isRequired,
    toggleTrie: PropTypes.func.isRequired,
    setOpenFiltre: PropTypes.func.isRequired,
    setHoraire: PropTypes.func
}

export default CollaborateursFwgtp3Actions;