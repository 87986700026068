import { useSelector } from "react-redux";
import { Box, Grow, Stack, Typography } from "@mui/material";
import moment from "moment";
import { selectEntCol } from "@selectors";
import { DownloadButton } from "@components/Buttons";
import { getFilename } from "@utils";
import { greenColor, whiteColor } from "@styles";

const statutsRoute = {
    P: "Planifié",
    E: "Effectué"
}

const DetailEntretien = () => {
    const stateEntretiens = useSelector(selectEntCol);
    const entretienSelected = stateEntretiens?.entretienSelected;

    return (
        <Stack direction="row" spacing={0.5}>
            <Stack spacing={0.5} width="50%">
                <Grow in={true} timeout={300}>
                    <Stack spacing={0.5} borderRadius={2} bgcolor={greenColor["05"]} p={0.5}>
                        <Box borderRadius={2} bgcolor={whiteColor[7]} pl={1} height={40} display="flex" alignItems="center">
                            <Box flexGrow={1}>
                                <Typography fontSize="small" display="inline" fontWeight="bold">Société : </Typography>
                                <Typography fontSize="small" display="inline">{entretienSelected?.codeSociete} {entretienSelected?.libelleSociete}</Typography>
                            </Box>
                        </Box>
                        <Box borderRadius={2} bgcolor={whiteColor[7]} pl={1} height={40} display="flex" alignItems="center">
                            <Typography fontSize="small">{entretienSelected?.matricule} {entretienSelected?.prenomNom}</Typography>
                        </Box>
                        <Stack direction="row" borderRadius={2} bgcolor={whiteColor[7]} height={40} alignItems="center" px={1}>
                            <Box flexGrow={1}>
                                <Typography fontSize="small" display="inline" fontWeight="bold">Emploi : </Typography>
                                <Typography fontSize="small" display="inline">{entretienSelected?.codeEmploi} {entretienSelected?.libelleEmploi}</Typography>
                            </Box>
                            {entretienSelected?.presencePieceJointeEmploi && 
                                <Box width={30}>
                                    <DownloadButton chemin={entretienSelected?.pieceJointeEmploi} nom={""} />
                                </Box>
                            }
                        </Stack>
                    </Stack>
                </Grow>
                <Grow in={true} timeout={600}>
                    <Stack spacing={0.5} borderRadius={2} bgcolor={greenColor["05"]} p={0.5}>
                        <Box borderRadius={2} bgcolor={whiteColor[7]} pl={1} height={40} display="flex" alignItems="center">
                            <Typography fontSize="small" fontWeight="bold">{entretienSelected?.designation}</Typography>
                        </Box>
                        <Stack direction="row" spacing={0.5}>
                            <Box borderRadius={2} bgcolor={whiteColor[7]} height={40} px={1} flexGrow={1} flexBasis="100%" display="flex" alignItems="center">
                                <Box display="inline">
                                    <Typography fontSize="small" display="inline" fontWeight="bold">Date : </Typography>
                                    <Typography fontSize="small" display="inline">{moment([entretienSelected?.annee, entretienSelected?.mois - 1, entretienSelected?.jour]).format("DD/MM/YYYY")}</Typography>
                                </Box>
                            </Box> 
                            <Box borderRadius={2} bgcolor={whiteColor[7]} height={40} px={1} flexGrow={1} flexBasis="100%" display="flex" alignItems="center">
                                <Box display="inline">
                                    <Typography fontSize="small" display="inline" fontWeight="bold">Statut : </Typography>
                                    <Typography fontSize="small" display="inline">{statutsRoute[entretienSelected?.statut]}</Typography>
                                </Box>
                            </Box>
                        </Stack>
                        <Stack direction="row" spacing={0.5}> 
                            <Box borderRadius={2} bgcolor={whiteColor[7]} p={1} flexGrow={1} flexBasis="100%" display="flex" alignItems="center">
                                <Box display="inline">
                                    <Typography fontSize="small" display="inline" fontWeight="bold">Responsable : </Typography>
                                    <Typography fontSize="small" display="inline">{entretienSelected?.prenomNomInterlocuteur}</Typography>
                                </Box>
                            </Box>
                        </Stack>
                        <Box>  
                            <Box borderRadius={2} bgcolor={whiteColor[7]} height={40} px={1} flexGrow={1} flexBasis="100%" display="flex" alignItems="center">
                                <Box display="inline">
                                    <Typography fontSize="small" display="inline" fontWeight="bold">Initiative : </Typography>
                                    <Typography fontSize="small" display="inline">{entretienSelected?.initiative}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Box borderRadius={2} bgcolor={whiteColor[7]} height={40} px={1} flexGrow={1} flexBasis="100%" display="flex" alignItems="center">
                                <Box display="inline">
                                    <Typography fontSize="small" display="inline" fontWeight="bold">Sujet : </Typography>
                                    <Typography fontSize="small" display="inline">{entretienSelected?.sujet}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        {entretienSelected?.piecesJointes?.length > 0 &&
                            <>
                                <Box borderRadius={2} bgcolor={whiteColor[7]} pl={1} height={40} display="flex" alignItems="center">
                                    <Typography fontSize="small" display="inline" fontWeight="bold">Pièces Jointes</Typography>
                                </Box>
                                {entretienSelected?.piecesJointes?.map((pieceJointe) => (
                                    <Stack key={`PJ${pieceJointe.id}`} direction="row" borderRadius={2} bgcolor={whiteColor[7]} height={40} alignItems="center" px={1}>
                                        <Box flexGrow={1}>
                                            <Typography fontSize="small" display="inline">{getFilename(pieceJointe?.pieceJointe)}</Typography>
                                        </Box>
                                        <Box width={30}>
                                            <DownloadButton chemin={pieceJointe?.pieceJointe} nom={getFilename(pieceJointe?.pieceJointe)} />
                                        </Box>
                                    </Stack>
                                ))}
                            </>
                        }
                    </Stack>
                </Grow>
            </Stack>
            <Grow in={true} timeout={900}>
                <Stack spacing={0.5} borderRadius={2} bgcolor={greenColor["05"]} p={0.5} width="50%">
                    <Box borderRadius={2} bgcolor={whiteColor[7]} p={1}>
                        <Typography fontSize="small" display="inline" fontWeight="bold">Commentaires et observations</Typography>
                    </Box>
                    <Stack spacing={0.5} borderRadius={2} bgcolor={whiteColor[7]} p={1} height="100%" whiteSpace={"pre-wrap"}>
                        <Typography fontSize="small">
                            {entretienSelected?.commentaire}
                        </Typography>
                    </Stack>
                </Stack>
            </Grow>
        </Stack>
    )
}

export default DetailEntretien;