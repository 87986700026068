import { HeaderDialogContainer } from "@components/Containers";
import moment from "moment";
import PropTypes from "prop-types";

const JourActions = (props) => {
    const { setOpen, jourSelected } = props;
    const jourString = moment(jourSelected, "DDMMYYYY").format("dddd")[0].toUpperCase() + moment(jourSelected, "DDMMYYYY").format("dddd").substring(1);
    const jour = moment(jourSelected, "DDMMYYYY").format("D");
    const mois = moment(jourSelected, "DDMMYYYY").format("MMMM");
    const annee = moment(jourSelected, "DDMMYYYY").format("YYYY");

    return (
        <HeaderDialogContainer 
            close={() => setOpen(false)}
            title={jourSelected ? `${jourString} ${jour} ${mois} ${annee}` : ""}
        />
    );
}

JourActions.defaultProps = {
    setOpen: () => {},
    jourSelected: ""
}

JourActions.propTypes = {
    setOpen: PropTypes.func,
    jourSelected: PropTypes.string
}

export default JourActions;