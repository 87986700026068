import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Assignment, Badge, Business, BusinessCenter, CorporateFare, Factory, Person, Share, WhereToVote, WorkHistory } from "@mui/icons-material";
import PropTypes from "prop-types";
import * as colTemGesTemActions from "@reducers/collaborateurs/colTemGesTemReducer";
import * as colAffHorIndActions from "@reducers/collaborateurs/colAffHorIndReducer";
import * as colAffActIndActions from "@reducers/collaborateurs/colAffActIndReducer";
import { useStateCollaborateurs } from "@hooks/collaborateurs/hooksStates";
import { MenuTrieContainerMobile } from "@components/Containers";
import { trierFwgtpe } from "../utils";
import { TYPES_COLLABORATEURS } from "@constants/collaborateursConstants";
import { MenuTrieButtonMobile } from "@components/Buttons";

const optionsTrierFwgtpe = [
    {id: 1, label: "Par nom", keyAsc: "nomAsc", keyDesc: "nomDesc", icon: <Person fontSize="small" />},
    {id: 2, label: "Par matricule", keyAsc: "matriculeAsc", keyDesc: "matriculeDesc", icon: <Person fontSize="small" />},
    {id: 3, label: "Par badge", keyAsc: "badgeAsc", keyDesc: "badgeDesc", icon: <Badge fontSize="small" />},
    {id: 4, label: "Par société", keyAsc: "societeAsc", keyDesc: "societeDesc", icon: <Factory fontSize="small" />},
    {id: 5, label: "Par établissement", keyAsc: "etablissementAsc", keyDesc: "etablissementDesc", icon: <Business fontSize="small" />},
    {id: 6, label: "Par direction", keyAsc: "directionAsc", keyDesc: "directionDesc", icon: <BusinessCenter fontSize="small" />},
    {id: 7, label: "Par service", keyAsc: "serviceAsc", keyDesc: "serviceDesc", icon: <Share fontSize="small" />},
    {id: 8, label: "Par secteur", keyAsc: "secteurAsc", keyDesc: "secteurDesc", icon: <CorporateFare fontSize="small" />},
    {id: 9, label: "Par type", keyAsc: "typePersonnelAsc", keyDesc: "typePersonnelDesc", icon: <Assignment fontSize="small" />},
    {id: 10, label: "Par contrôle horaire", keyAsc: "controleHoraireAsc", keyDesc: "controleHoraireDesc", icon: <WorkHistory fontSize="small" />},
    {id: 11, label: "Par présence", keyAsc: "presenceAsc", keyDesc: "presenceDesc", icon: <WhereToVote fontSize="small" />},
];

const CollaborateursFwgtpeTrieMobile = (props) => {
    const { stateType, open, close } = props;
    const stateCollaborateurs = useStateCollaborateurs(stateType);
    const dispatch = useDispatch();

    const trier = useCallback((keyTrie) => {
        const data = [...stateCollaborateurs.collaborateurs];
        const dataTried = trierFwgtpe(keyTrie, data);
        if(stateType === TYPES_COLLABORATEURS.GESTION_TEMPS_TEMPS) {
            dispatch(colTemGesTemActions.trier(dataTried));
        }
        if(stateType === TYPES_COLLABORATEURS.AFFECTATIONS_HORAIRES_INDIVIDUELLES) {
            dispatch(colAffHorIndActions.trier(dataTried));
        }
        if(stateType === TYPES_COLLABORATEURS.AFFECTATIONS_ACTIVITES_INDIVIDUELLES) {
            dispatch(colAffActIndActions.trier(dataTried));
        }
        close();
    }, [close, dispatch, stateCollaborateurs.collaborateurs, stateType]);

    return (
        <MenuTrieContainerMobile open={open} close={close}>
            {optionsTrierFwgtpe.map((option) => (
                <MenuTrieButtonMobile key={`MTR${option.id}`} option={option} trier={trier} />
            ))}
        </MenuTrieContainerMobile>  
    );
}

CollaborateursFwgtpeTrieMobile.defaultProps = {
    open: false,
    close: () => {}
}

CollaborateursFwgtpeTrieMobile.propTypes = {
    stateType: PropTypes.string.isRequired,
    open: PropTypes.node,
    close: PropTypes.func
}

export default CollaborateursFwgtpeTrieMobile;