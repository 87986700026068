import { arrange, asc, desc, tidy } from "@tidyjs/tidy";
import moment from "moment";

export const trierDlgApp = (keyTrie, data) => {
    switch(keyTrie) {
        case "natureAsc": 
            return tidy(data, arrange([asc("libelleNature")]));
        case "natureDesc":
            return tidy(data, arrange([desc("libelleNature")]));
        case "dateDebutAsc":
            return tidy(data, arrange((da, db) => moment(da.dateDebut, "DDMMYYYY").isSameOrAfter(moment(db.dateDebut, "DDMMYYYY"))));
        case "dateDebutDesc": 
            return tidy(data, arrange((da, db) => moment(da.dateDebut, "DDMMYYYY").isSameOrBefore(moment(db.dateDebut, "DDMMYYYY"))));
        case "dateRepriseAsc": 
            return tidy(data, arrange((da, db) => {
                if(da.dateReprise === "00000000") {
                    return 1;
                } else if(db.dateReprise === "00000000") {
                    return -1;
                } else {
                    return moment(da.dateReprise, "DDMMYYYY").isSameOrAfter(moment(db.dateReprise, "DDMMYYYY"));
                }
            }));
        case "dateRepriseDesc":
            return tidy(data, arrange((da, db) => {
                if(da.dateReprise === "00000000") {
                    return 1;
                } else if(db.dateReprise === "00000000") {
                    return -1;
                } else {
                    return moment(da.dateReprise, "DDMMYYYY").isSameOrBefore(moment(db.dateReprise, "DDMMYYYY"));
                }
            }));
        case "delegataireAsc":
            return tidy(data, arrange((da, db) => da.delegataire > db.delegataire));
        case "delegataireDesc":
            return tidy(data, arrange((da, db) => da.delegataire < db.delegataire));
        default: 
            return data;
    }
};
