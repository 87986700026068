import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activeTab: "AFFHOI"
};

const affGesTemSlice = createSlice({
    name: 'affGesTem',
    initialState: initialState,
    reducers: {
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
        reset: () => initialState
    }
})

const { actions, reducer } = affGesTemSlice
export const { setActiveTab, reset } = actions
export default reducer
