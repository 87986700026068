import { useSelector, useDispatch } from "react-redux";
import { Box, Stack } from "@mui/material";
import * as mvtGesTemActions from "@reducers/gestionTemps/mouvements/mvtGesTemReducer"
import { TabContentContainer, TextContainer } from "@components/Containers";
import { MouvementsCollectifs, MouvementsIndividuels } from "@components/GestionTemps/Mouvements";
import { greenColor } from "@styles";
import { selectMvtGesTem } from "@selectors";
import { useCallback, useState } from "react";
import { MenuButton } from "@components/Buttons";
import { CompareArrows, MultipleStop } from "@mui/icons-material";

const MouvementMobile = () => {
    const [titreMobile, setTitreMobile] = useState("Traitement Individuel")
    const stateMouvements = useSelector(selectMvtGesTem);
    const dispatch = useDispatch();

    const handleTabs = useCallback((_, tab) => {
        let titre = "";
        if(tab === "MVTIND") {
            titre = "Traitement Individuel";
        }
        if(tab === "MVTCOL") {
            titre = "Traitement Collectif";
        }
        setTitreMobile(titre);
        dispatch(mvtGesTemActions.setActiveTab(tab));
    }, [dispatch]);

    return (
        <Stack spacing={0.5} borderRadius={2} height="100%" boxSizing="border-box">
            <Stack spacing={1} direction="row" bgcolor={greenColor["015"]} borderRadius={2} p={0.5} height={46} boxSizing={"border-box"} display={"flex"} alignItems="center">
                <Box flexGrow={1} boxSizing="border-box" height="100%">
                    <TextContainer bgColor={greenColor["015"]} textSize={12} p={2} height="100%" verticalAlign="center" horizontalAlign="center" textWeight="bold">
                        {titreMobile}
                    </TextContainer>
                </Box>
                <Box pr={1} pl={0.5}>
                    <MenuButton txtId="BTDABSMOB" options={[
                        {id: 1, icon: <CompareArrows fontSize="small" />, label: "Traitement Individuel", action: (e) => handleTabs(e, "MVTIND")},
                        {id: 2, icon: <MultipleStop fontSize="small" />, label: "Traitement Collectif", action: (e) => handleTabs(e, "MVTCOL")}
                    ]} />
                </Box>
            </Stack>
            <TabContentContainer value={stateMouvements.activeTab} id="MVTIND">
                <MouvementsIndividuels />
            </TabContentContainer>
            <TabContentContainer value={stateMouvements.activeTab} id="MVTCOL">
                <MouvementsCollectifs />
            </TabContentContainer>
        </Stack>
    );
};

export default MouvementMobile;