import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import PropTypes from "prop-types";
import { DialogContainer } from '@components/Containers'
import { DialogError } from "@components/Errors";
import { AnnulationDemandesAbsenceActions } from "./Actions";
import DemandesAnnulationForm from "../Forms/DemandesAnnulationForm";
import { schemaMotifDemandeAnnulation } from "../validations";
import { useTranslation } from "react-i18next";

const defaultValues = {
    motif: ""
}

const AnnulationDemandesAbsenceDialog = ({ 
    data = [], 
    isLoading = true, 
    isError = false, 
    open = false, 
    setOpen = () => {}, 
    handleConfirm = () => {} 
}) => {
    const methods = useForm({ mode: "all", defaultValues: defaultValues, resolver: joiResolver(schemaMotifDemandeAnnulation) });
    const { t } = useTranslation("global");

    return (
        <DialogContainer open={open} taille="sm" placement="top">
            <AnnulationDemandesAbsenceActions 
                isLoading={isLoading}
                isError={isError}
                setOpen={setOpen}
                handleConfirm={() => methods.handleSubmit(handleConfirm)()}
            />
            {isError ?
                <DialogError error={t("errors.generic_send_request")} />
            :
                <FormProvider {...methods}>
                    <DemandesAnnulationForm
                        data={data}
                        isLoading={isLoading}
                    />
                </FormProvider>
            }
        </DialogContainer>
    );
}

AnnulationDemandesAbsenceDialog.propTypes = {
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]), 
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool,
    open: PropTypes.bool, 
    setOpen: PropTypes.func, 
    handleConfirm: PropTypes.func
}

export default AnnulationDemandesAbsenceDialog;