import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import PropTypes from "prop-types";
import { DialogFullScreenContainer } from '@components/Containers'
import { DialogError } from "@components/Errors";
import { AnnulationDemandesAbsenceActions } from "./Actions";
import DemandesAnnulationForm from "../Forms/DemandesAnnulationForm";
import { schemaMotifDemandeAnnulation } from "../validations";

const defaultValues = {
    motif: ""
}

const AnnulationDemandesAbsenceDialogMobile = (props) => {
    const { data, isLoading, isError, open, setOpen, handleConfirm } = props;
    const methods = useForm({ mode: "all", defaultValues: defaultValues, resolver: joiResolver(schemaMotifDemandeAnnulation) });

    return (
        <DialogFullScreenContainer open={open} taille="sm" placement="top">
            <AnnulationDemandesAbsenceActions 
                isLoading={isLoading}
                isError={isError}
                setOpen={setOpen}
                handleConfirm={() => methods.handleSubmit(handleConfirm)()}
            />
            {isError ?
                <DialogError error={"Une erreur est survenue lors de l'envoi de la demande."} />
            :
                <FormProvider {...methods}>
                    <DemandesAnnulationForm
                        data={data}
                        isLoading={isLoading}
                    />
                </FormProvider>
            }
        </DialogFullScreenContainer>
    );
}

AnnulationDemandesAbsenceDialogMobile.defaultProps = {
    data: [], 
    isLoading: true, 
    isError: false,
    open: false, 
    setOpen: () => {}, 
    handleConfirm: () => {}, 
}

AnnulationDemandesAbsenceDialogMobile.propTypes = {
    data: PropTypes.array, 
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool,
    open: PropTypes.bool, 
    setOpen: PropTypes.func, 
    handleConfirm: PropTypes.func
}

export default AnnulationDemandesAbsenceDialogMobile;