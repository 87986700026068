import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { Check, DeleteOutline, Save } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useFetchAnoTraGesTem } from "@hooks/anomalies/hooksQueries";
import { Loader } from "@components/Loaders";
import { DialogContainer, HeaderDialogContainer, HeaderListContainer, ItemContainer, ListContainer } from "@components/Containers";
import { ErrorBase } from "@components/Errors";
import { heurify } from "@utils";
import { ROLE_UTILISATEUR } from "@constants/userConstants";
import { greenColor, greyColor, IconButtonApp, redColor, whiteColor } from "@styles";

const headers = [
    {id: 1, label: "Type", xs: 2, display: true, firstData: true, lastData: false},
    {id: 2, label: "Heure", xs: 1.5, display: true, firstData: false, lastData: false},
    {id: 3, label: "Libelle", xs: 7, display: true, firstData: false, lastData: false},
    {id: 4, label: "Gravité", xs: 1.5, display: true, firstData: false, lastData: true}
];

const TextDialogCollaborateur = ({
    data = [], 
    isLoading = false, 
    isError = false, 
    errorMessage = "", 
    codeError = ""
}) => {
    if(isLoading) {
        return (
            <Box p={2}>
                <Loader size={27} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return <ErrorBase>{errorMessage}</ErrorBase>;
    }

    return (
        <Stack spacing={0.5} bgcolor={redColor[5]} p={0.5} borderRadius={2}>
            {codeError === "AN" ?
                <Stack spacing={0.5} bgcolor={whiteColor[7]} borderRadius={2} p={1}>
                    <Box>
                        <Typography fontSize="small">Des anomalies sont présentes.</Typography>
                    </Box> 
                    <Box>
                        <Typography fontSize="small">Voulez-vous les supprimer ?</Typography>
                    </Box>
                </Stack>
            :
                <Box bgcolor={whiteColor[7]} borderRadius={2} p={1}>
                    <Typography fontSize="small">Des anomalies sont présentes.</Typography>
                </Box> 
            }
            <HeaderListContainer itemIdReference="REGANOP" variant="light" headerItems={headers} />
            <ListContainer spacing={0.5} length={data.length} noDataLabel="Aucune anomalie">
                {data.map((anomalie) => (
                    <Box key={`REGANO${anomalie.id}`} bgcolor={whiteColor[5]} borderRadius={2} p={0.3}>
                        <Grid container spacing={0.3}>
                            <ItemContainer firstData data={anomalie.libelleMouvement} xs={2} />
                            <ItemContainer data={heurify(anomalie.heure)} xs={1.5} />
                            <ItemContainer data={anomalie.libelleAnomalie} xs={7} />
                            <ItemContainer lastData data={anomalie.gravite} xs={1.5} />
                        </Grid>
                    </Box>
                ))}
            </ListContainer>
            </Stack>
     
    )
}

const TextDialogManager = () => {
    return (
        <Box bgcolor={redColor[5]} p={0.5} borderRadius={2}>
            <Stack spacing={0.5} bgcolor={whiteColor[7]} borderRadius={2} p={1}>
                <Box>
                    <Typography fontSize="small">Des anomalies de transactions sont présentes.</Typography>
                </Box> 
                <Box>
                    <Typography fontSize="small">Voulez-vous les supprimer ?</Typography>
                </Box> 
            </Stack>
        </Box>
    )
}

const AnomaliesTransactionsAlertesDialog = ({
    open = false, 
    isLoadingAnomalie = false,
    userType = "",
    code = "",
    setOpen = () => {},   
    validate = () => {} 
}) => {
    const { data, isLoading, isFetching, isError, error } = useFetchAnoTraGesTem(userType, open);

    return (
        <DialogContainer open={open} taille="sm">
            <HeaderDialogContainer 
                actionsLeft={
                    userType === ROLE_UTILISATEUR.MANAGER ?
                        <Stack direction={"row"} spacing={0.5}>
                            <Tooltip title="Supprimer les anomalies">
                                <IconButtonApp variant="white5Red9White9" onClick={() => validate("D")}>
                                    <DeleteOutline fontSize="small" />
                                </IconButtonApp>
                            </Tooltip>
                            <Tooltip title="Supprimer les anomalies">
                                <IconButtonApp variant="white5Green9White9" onClick={() => validate("C")}>
                                    <Save fontSize="small" />
                                </IconButtonApp>
                            </Tooltip>
                        </Stack>
                    :
                        <Stack direction={"row"} spacing={0.5}>
                            {code === "AN" ?
                                <>
                                    <Tooltip title="Supprimer les anomalies">
                                        <IconButtonApp variant="white5Red9White9" onClick={() => validate("D")}>
                                            <DeleteOutline fontSize="small" />
                                        </IconButtonApp>
                                    </Tooltip>
                                    <Tooltip title="Garder les anomalies">
                                        <IconButtonApp variant="white5Green9White9" onClick={() => validate("C")}>
                                            <Save fontSize="small" />
                                        </IconButtonApp>
                                    </Tooltip>
                                </>
                            :
                                <Tooltip title="Valider">
                                    <IconButtonApp variant="white5Green9White9" size="small" onClick={() => validate("V")}>
                                        <Check fontSize="small" />
                                    </IconButtonApp>
                                </Tooltip>
                            }
                        </Stack>
                } 
                title="Anomalie(s)" 
                close={() => setOpen(false)} 
                variant="error"
            />
            {{
                [ROLE_UTILISATEUR.COLLABORATEUR]: <TextDialogCollaborateur data={data} isLoading={isLoading || isFetching || isLoadingAnomalie} isError={isError} errorMessage={error?.message} codeError={code}  />,
                [ROLE_UTILISATEUR.MANAGER]: <TextDialogManager />
            }[userType]}
        </DialogContainer>
    );
}

TextDialogCollaborateur.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
    codeError: PropTypes.string
}

AnomaliesTransactionsAlertesDialog.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    userType: PropTypes.string,
    code: PropTypes.string,
    validate: PropTypes.func,
    isLoadingAnomalie: PropTypes.bool
}

export default AnomaliesTransactionsAlertesDialog;