import { Box, Grid, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { whiteColor } from "@styles";

const GlobalContainer = ({ 
    children = <></>, 
    bgcolor = whiteColor[9]
}) => {
    return (  
        <Grid 
            container 
            className="App" 
            spacing={0} 
            sx={{ background: "linear-gradient(90deg, rgba(74,178,207,1) 0%, rgba(49,133,156,1) 50%, rgba(0,94,121,1) 100%)" }}
            height="100vh"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={12} md={6} lg={4}>
                <Box>
                    <Stack spacing={1} p={0.5} bgcolor={bgcolor} borderRadius={2}>
                        {children}
                    </Stack>
                </Box>
            </Grid>
        </Grid>
    );
}

GlobalContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]), 
    bgcolor: PropTypes.string
}

export default GlobalContainer;