import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    societes: [],
    societesDisplayed: [],
    filtered: false,
    tried: false,
    societeSelected: null,
}

const socAffHorColSlice = createSlice({
    name: 'socAffHorCol',
    initialState: initialState,
    reducers: {
        setSocietes: (state, action) => {
            state.societes = action.payload;
            state.societesDisplayed = action.payload;
        },
        filtrer: (state, action) => {
            state.societesDisplayed = action.payload;
            state.filtered = true;
        },
        trier: (state, action) => {
            state.societesDisplayed = action.payload;
            state.tried = true;
        },
        resetFiltre: (state) => {
            state.societesDisplayed = state.societes;
            state.filtered = false;
        },
        resetTrie: (state) => {
            state.societesDisplayed = state.societes;
            state.tried = false;
        },
        selectSociete: (state, action) => {
            state.societeSelected = action.payload
        },
        resetSelection: (state) => {
            state.societeSelected = null
        },
        reset: () => initialState
    },
})

const { actions, reducer } = socAffHorColSlice
export const { 
    setSocietes,
    filtrer,
    trier,
    resetFiltre,
    resetTrie,
    selectSociete,
    resetSelection,
    reset 
} = actions
export default reducer