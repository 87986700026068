import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useStatePlanning } from "@hooks/planning/hooksStates";
import PhotoCollaborateur from "@components/App/PhotoCollaborateur";
import { whiteColor } from "@styles";
import { ButtonBase } from "@components/Buttons";

const variants = {
    planning: { bg1: whiteColor[5], bg2: whiteColor[5], bg3: whiteColor[5] },
    absenceManager: { bg1: whiteColor[7], bg2: whiteColor[7], bg3: whiteColor[7] }
}

const Emetteur = ({ 
    type = "", 
    variant = "planning",
    openJour= () => {} 
}) => {
    const statePlanning = useStatePlanning(type);

    return (  
        <Box>
            {statePlanning?.emetteur && 
                <Stack spacing={0.5}>
                    <Box>
                        <Grid container spacing={0.3}>
                            <Grid item xs={2.25}>
                                <Tooltip title={<PhotoCollaborateur photo={statePlanning?.emetteur?.photo} nom={statePlanning?.emetteur?.prenomNom} />} placement="top">
                                    <Box bgcolor={variants[variant]?.bg1} p={1} height="100%" sx={{ borderBottomLeftRadius: 8 }} boxSizing="border-box" display="flex" alignItems="center" justifyContent="center">
                                        <Typography fontSize="small" fontWeight="bold" sx={{ textDecoration: "none" }}>{statePlanning.emetteur.prenomNom}</Typography>
                                    </Box>
                                </Tooltip>
                            </Grid>
                            {statePlanning?.emetteur?.zones?.map((zone) => (
                                <Grid item xs={1.25} key={`ZES${zone.id}`}>
                                    {!zone.demande ? 
                                        <Box bgcolor={variants[variant]?.bg2} p={1} height="100%" boxSizing="border-box" display="flex" alignItems="center" justifyContent="center">
                                            <Typography fontSize="small">
                                                {zone.zone}
                                            </Typography>
                                        </Box>
                                    :
                                        <ButtonBase 
                                            variant="white7Grey9White9"
                                            fullWidth
                                            borders="none"
                                            height={35.5}
                                            action={() => {openJour(zone.id, statePlanning?.emetteur?.matricule, statePlanning?.emetteur?.prenomNom)}}
                                        >
                                            {zone.zone}
                                        </ButtonBase>
                                    }
                                </Grid>
                            ))}
                            <Grid item xs={1}>
                                <Box bgcolor={variants[variant]?.bg3} p={1} height="100%" sx={{ borderBottomRightRadius: 8 }} boxSizing="border-box" display="flex" alignItems="center" justifyContent="center">
                                    <Typography fontSize="small" fontWeight="bold">
                                        {statePlanning?.emetteur?.totalHeuresSemaine}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Stack>
            }
        </Box>
    );
}

Emetteur.propTypes = {
    type: PropTypes.string, 
    variant: PropTypes.string, 
    openJour: PropTypes.func
}

export default Emetteur;