import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Autocomplete, Box, TextField } from "@mui/material";
import { Check } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { selectUsrApp } from "@selectors";
import * as userActions from "@reducers/user/usrAppReducer"
import { IconButtonBase } from "@components/Buttons";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor } from "@styles";
import { editHrl } from "@api/horloges";
import { DialogContainer, HeaderDialogContainer } from "@components/Containers";
import { useTranslation } from "react-i18next";

const DHorloge = ({ open, setOpen }) => {
    const stateUser = useSelector(selectUsrApp);
    const { control, handleSubmit } = useForm();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation("global");

    const saveHorloge = useCallback(async (data) => {
        setLoading(true);
        setOpen(false);
        try {
            const response = await editHrl(data.timeZones, stateUser.token);
            if(response.data.succeed) {
                dispatch(userActions.setHorloges(data.timeZones));
                enqueueSnackbar(response.data.details, { variant: "success" });
            } else {
                enqueueSnackbar(response.data.details, { variant: "error" });
            }
        } catch(e) {
            enqueueSnackbar("Erreur lors de la mise à jour des horloges", { variant: "error" });
        }
        setLoading(false);
    }, [dispatch, enqueueSnackbar, setOpen, stateUser.token]);

    return (
        <DialogContainer open={open} placement="top" taille="sm">
            <HeaderDialogContainer 
                actionsLeft={ 
                    <IconButtonBase icon={<Check fontSize="small" />} title={t("buttons.save_clocks")} action={handleSubmit(saveHorloge)} />
                }
                close={() => setOpen(false)}
                title={t("titles.add_clocks")}
            />
            {loading ? 
                <Box sx={styles.loaderStyle}><Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} /></Box>
            : 
                <Box component={"form"} paddingTop={1}>
                    <Controller 
                        control={control}
                        name="timeZones"
                        render={({field: { ref, onChange, ...field }}) => 
                            <Autocomplete 
                                multiple
                                size="small" 
                                defaultValue={stateUser.horloges}
                                sx={styles.inputStyle}
                                options={moment.tz.names()}
                                renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label={t("inputs.time_zone")} />}
                                onChange={(_,data) => onChange(data)} 
                            />
                        }
                    />
                </Box>
            }
        </DialogContainer>
    );
}

const styles = {
    loaderStyle: {
        height: 100,
        alignItems: "center",
        display: "flex"
    },
    inputStyle: {
        width: "100%", 
        "& .MuiOutlinedInput-notchedOutline": { 
            borderColor: greyColor[2],
            borderRadius: 2
        }
    }
}

export default DHorloge;