import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { greenColor, redColor, whiteColor, yellowColor } from "@styles";

const bgColor = {
    normale: greenColor["05"],
    light: whiteColor[5],
    red: redColor[5],
    yellow: yellowColor[6],
}

const itemsBgColor = {
    normale: whiteColor[7],
    light: whiteColor[7],
    red: whiteColor[7],
    yellow: whiteColor[7]
}

const HeaderListContainer = ({
    itemIdReference = "", 
    headerItems = [], 
    variant = "normale"
}) => {
    return (  
        <Box bgcolor={bgColor[variant]} borderRadius={2} p={0.3}>
            <Grid container spacing={0.3}>
                {headerItems.map((item, id) => (
                    <Grid key={`LH${itemIdReference}${item.id}`} item xs={item.xs}>
                        {item.display && 
                            <Box 
                                bgcolor={itemsBgColor[variant]} 
                                sx={{
                                    borderTopLeftRadius: item.firstData ? 8 : 0, 
                                    borderBottomLeftRadius: item.firstData ? 8 : 0, 
                                    borderTopRightRadius: item.lastData ? 8 : 0, 
                                    borderBottomRightRadius: item.lastData ? 8 : 0
                                }} 
                                p={1}
                                height="100%"
                                boxSizing="border-box"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Typography fontSize="small" fontWeight="bold" textAlign="center">{item.label}</Typography>
                            </Box>
                        }
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

HeaderListContainer.propTypes = {
    itemIdReference: PropTypes.string, 
    headerItems: PropTypes.array, 
    variant: PropTypes.string
}

export default HeaderListContainer;
