import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { HeaderDialogContainer } from "@components/Containers";
import { useTranslation } from "react-i18next";
import { whiteColor } from "@styles";

const bgStatutList = {
    "Acceptée": "normale",
    "Non traitée": "warning",
    "En cours": "warning",
    "Refusée": "error"
}

const colorStatutList = {
    "Acceptée": "white",
    "Non traitée": "black",
    "En cours": "black",
    "Refusée": "white"
}

const HistoriqueDemandesActions = (props) => {
    const { isLoading, demandes, idDemande, close, precedent, suivant } = props;
    const title = demandes?.[idDemande]?.nature === "Demande d'absence" ? "Demande d'absence" : "Demande d'annulation d'absence"
    const { t } = useTranslation("global");

    return (
        <HeaderDialogContainer 
            actionsLeft={
                <Stack direction="row" spacing={0.5}>
                    {demandes.length > 1 && 
                        <IconButtonBase 
                            variant="white5Grey9White9"
                            icon={<ChevronLeft fontSize="small" />} 
                            title={t("buttons.previous_absence_request")} 
                            disabled={isLoading} 
                            action={precedent} 
                        />
                    }
                    {demandes.length > 1 && 
                        <IconButtonBase 
                            variant="white5Grey9White9"
                            icon={<ChevronRight fontSize="small" />} 
                            title={t("buttons.next_absence_request")} 
                            disabled={isLoading} 
                            action={suivant} 
                        />
                    }
                </Stack>
            } 
            close={close} 
            textTitle={false}
            variant={bgStatutList[demandes?.[idDemande]?.statut]}
            title={
                <Stack spacing={0.5}>
                    <Box>
                        <Typography fontWeight="bold" color={colorStatutList[demandes?.[idDemande]?.statut]}>{title}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Box bgcolor={whiteColor[5]} borderRadius={5} px={2} py={1}>
                            <Typography fontSize="small" fontWeight="bold" color="black">{demandes?.[idDemande]?.statut}</Typography>
                        </Box>
                    </Box>
                </Stack>
            } 
        />
    );
}

HistoriqueDemandesActions.defaultProps = {
    isLoading: true,
    demandes: [],
    idDemande: 0,
    close: () => {},
    precedent: () => {},
    suivant: () => {}
}

HistoriqueDemandesActions.propTypes = {
    isLoading: PropTypes.bool,
    demandes: PropTypes.array,
    idDemande: PropTypes.number,
    close: PropTypes.func,
    precedent: PropTypes.func,
    suivant: PropTypes.func
}

export default HistoriqueDemandesActions;