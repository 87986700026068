import axios from "axios";
import { urls } from "@constants/appConstants";

export const fetchDoc = async (matricule, client, signal) => {
    const header = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetDocuments"
    };
    const body = {
        matricule:  matricule
    };
    const response = await axios.post(urls.getDocuments, body, { headers: header, params: { client: client }, signal: signal});
    return response.data.documents;
}

export const fetchNatDoc = async (client, signal) => {
    const header = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetNaturesDocuments", 
    };
    
    const response = await axios.get(urls.getNatureDocuments, { headers: header, params: { client: client }, signal: signal });
    return response.data.natures;
}