import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { fetchTypTra } from "@api/typesTravail";
import { selectCltApp, selectColTemGesTem, selectDrgGesTem, selectRegGesTem, selectUsrApp } from "@selectors";
import * as drgGesTemActions from "@reducers/gestionTemps/regularisations/drgGesTemReducer";
import * as regGesTemActions from "@reducers/gestionTemps/regularisations/regGesTemReducer";
import { ROLE_UTILISATEUR } from "@constants/userConstants";

export const useTypTraRegGesTem = (open, userType) => {
    const stateClient = useSelector(selectCltApp);
    const stateUser = useSelector(selectUsrApp);
    const stateCollaborateurs = useSelector(selectColTemGesTem);
    const stateDemandesRegularisation = useSelector(selectDrgGesTem);
    const stateRegularisation = useSelector(selectRegGesTem);
    const date = userType === ROLE_UTILISATEUR.MANAGER ? stateRegularisation.dateRegularisation : stateDemandesRegularisation.dateDemandeRegularisation;
    const dateFormatee = moment(date, "DDMMYYYY").format("YYYYMMDD"); 
    const matriculeCollaborateur = userType === ROLE_UTILISATEUR.MANAGER ? stateCollaborateurs?.collaborateurSelected?.matricule : stateUser.matricule;
    const dispatch = useDispatch(); 

    const query = useQuery(['typTraRegGesTem', date], ({ signal }) => fetchTypTra(matriculeCollaborateur, dateFormatee, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open
    });

    useEffect(() => {
        if(query.data) {
            if(query.data.authorized) {
                let data = [{id: 1, value: "", label: "Aucun"}]
                query.data.typeTravail.forEach((tt) => {data.push({id: tt.id, value: tt.codeArgument, label: tt.designation})});
                const typesTravail = { typesTravail: data, authoriser: true };
                if(userType === ROLE_UTILISATEUR.COLLABORATEUR) {
                    dispatch(drgGesTemActions.setTypesTravail(typesTravail));
                }
                if(userType === ROLE_UTILISATEUR.MANAGER) {
                    dispatch(regGesTemActions.setTypesTravail(typesTravail));
                }
            } else {
                const typesTravail = { typesTravail: [], authoriser: true };
                if(userType === ROLE_UTILISATEUR.COLLABORATEUR) {
                    dispatch(drgGesTemActions.setTypesTravail(typesTravail));
                }
                if(userType === ROLE_UTILISATEUR.MANAGER) {
                    dispatch(regGesTemActions.setTypesTravail(typesTravail));
                }
            }
        }
    }, [dispatch, query.data, userType]);
    
    return query;
}
