import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sections: [],
    sectionSelected: null,
    sectionSelectedNiveau1: null,
    sectionSelectedNiveau2: null,
    sectionSelectedNiveau3: null,
    niveau: 1,
    modeAjout: false,
    idQuestion: 1,
    flow: "",
    typeNiv2: "",
    typeNiv3: "",
    typeNiv4: ""
};

const entSecManSlice = createSlice({
    name: 'entSecMan',
    initialState: initialState,
    reducers: {
        setSections: (state, action) => {
            state.sections = action.payload;
        },
        selectSection: (state, action) => {
            state.sectionSelected = action.payload;
        },
        selectSectionNiveau1: (state, action) => {
            state.sectionSelectedNiveau1 = action.payload;
        },
        selectSectionNiveau2: (state, action) => {
            state.sectionSelectedNiveau2 = action.payload;
        },
        selectSectionNiveau3: (state, action) => {
            state.sectionSelectedNiveau3 = action.payload;
        },
        setNiveau: (state, action) => {
            state.niveau = action.payload;
        },
        selectQuestion: (state, action) => {
            state.idQuestion = action.payload;
        },
        setModeAjout: (state, action) => {
            state.modeAjout = action.payload;
        },
        setFlows: (state, action) => {
            state.flow = action.payload.flow;
            state.typeNiv2 = action.payload.typeNiv2;
            state.typeNiv3 = action.payload.typeNiv3;
            state.typeNiv4 = action.payload.typeNiv4;
        },
        reset: () => initialState
    }
})

const { actions, reducer } = entSecManSlice
export const { 
    setSections, 
    selectSection,
    selectSectionNiveau1,
    selectSectionNiveau2,
    selectSectionNiveau3,
    setNiveau,
    selectQuestion,
    setModeAjout,
    setFlows,
    reset
} = actions
export default reducer
