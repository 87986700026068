import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import * as entManActions from "@reducers/gestionEntretiens/entManReducer";
import * as entColActions from "@reducers/gestionEntretiens/entColReducer";
import * as entTabManActions from "@reducers/gestionEntretiens/entTabManReducer";
import * as entTabColActions from "@reducers/gestionEntretiens/entTabColReducer";
import * as entSecManActions from "@reducers/gestionEntretiens/entSecManReducer";
import * as entSecColActions from "@reducers/gestionEntretiens/entSecColReducer";
import { selectCltApp, selectEntCol, selectEntMan, selectEntSecCol, selectEntSecMan, selectUsrApp } from "@selectors";
import { addSouSecEnt, addSouSecQueEnt, deleteEnt, deleteSouSecEnt, downloadEnt, editEnt, fetchCmpEnt, fetchComEnt, fetchComQueEnt, fetchEnt, fetchEntCol, fetchQueEnt, fetchSecEnt, fetchSecEntAcc, fetchSouSecCmpEnt, fetchSouSecEnt, postComEntCol, postComEntMan, postQueEnt, postSouSecEnt, terminerEnt } from "@api/entretiens";
import { formToDataASS, formToDataASSCol, formToDataASSQ, formToDataCS, formToDataEntretien, formToDataQ, formToDataSS } from "@integrations";
import { useSnackbar } from "notistack";
import { downloadFile } from "@api/file";
import v from "voca";

export const useEntMan = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const dispatch = useDispatch();

    const query = useQuery(['entMan'], ({ signal }) => fetchEnt(stateUser.utilisateurAS400, stateUser.matricule, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if(query.data) {
            dispatch(entManActions.setEntretiens(query.data.entretiens));
            dispatch(entTabManActions.setTables({
                champsComplementaires: query.data.champsComplementaires, 
                grilleEvaluation: query.data.grilleEvaluation,
                grilleCompetencesEvaluation: query.data.grilleCompetencesEvaluation
            }));
        }
    }, [dispatch, query.data]);

    return query;
}

export const useEntCol = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const dispatch = useDispatch();

    const query = useQuery(['entCol'], ({ signal }) => fetchEntCol(stateUser.matricule, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if(query.data) {
            dispatch(entColActions.setEntretiens(query.data.entretiens));
            dispatch(entTabColActions.setTables({
                champsComplementaires: query.data.champsComplementaires, 
                grilleEvaluation: query.data.grilleEvaluation,
                grilleCompetencesEvaluation: query.data.grilleCompetencesEvaluation
            }));
        }
    }, [dispatch, query.data]);

    return query;
}

export const useSecEntMan = () => {
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const codeCle = stateEntretiens?.entretienSelected?.codeCle;
    const codeEntretien = stateEntretiens?.entretienSelected?.codeEntretien;
    const client = stateClient.client;
    const niveau = stateSections?.niveau;
    const dispatch = useDispatch();

    const query = useQuery(['entSecMan', codeCle, niveau], ({ signal }) => fetchSecEnt(codeCle, codeEntretien, client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: niveau === 1
    });
    
    useEffect(() => {
        if(query?.data) {
            dispatch(entSecManActions.setSections(query?.data));
        }
    }, [dispatch, query?.data]);

    return query;
}

export const useSecEntCol = () => {
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntCol);
    const stateSections = useSelector(selectEntSecCol);
    const codeCle = stateEntretiens?.entretienSelected?.codeCle;
    const codeEntretien = stateEntretiens?.entretienSelected?.codeEntretien;
    const client = stateClient.client;
    const niveau = stateSections?.niveau;
    const dispatch = useDispatch();

    const query = useQuery(['entSecCol', codeCle, niveau], ({ signal }) => fetchSecEnt(codeCle, codeEntretien, client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: niveau === 1
    });
    
    useEffect(() => {
        if(query?.data) {
            dispatch(entSecColActions.setSections(query?.data));
        }
    }, [dispatch, query?.data]);

    return query;
}

export const useSecEntAcc = () => {
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntCol);
    const stateSections = useSelector(selectEntSecCol);
    const codeCle = stateEntretiens?.entretienSelected?.codeCle;
    const codeEntretien = stateEntretiens?.entretienSelected?.codeEntretien;
    const client = stateClient.client;
    const niveau = stateSections?.niveau;
    const dispatch = useDispatch();

    const query = useQuery(['entSecAcc', codeCle, niveau], ({ signal }) => fetchSecEntAcc(codeCle, codeEntretien, client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: niveau === 1
    });
    
    useEffect(() => {
        if(query?.data) {
            dispatch(entSecColActions.setSections(query?.data));
        }
    }, [dispatch, query?.data]);

    return query;
}

export const useSouSecNiv2EntMan = () => {
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;
    const codeCle = stateEntretiens?.entretienSelected?.codeCle;
    const codeEntretien = stateEntretiens?.entretienSelected?.codeEntretien;
    const rang = sectionSelected?.rang;
    const client = stateClient.client;
    const niveau = stateSections?.niveau;
    const dispatch = useDispatch();

    const query = useQuery(['entSouSecNiv2Man', codeCle, niveau], ({ signal }) => fetchSouSecEnt(codeCle, codeEntretien, rang, client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: niveau === 2 && stateSections?.typeNiv2 === "SL"
    });
    
    useEffect(() => {
        if(query?.data) {
            dispatch(entSecManActions.setSections(query?.data));
        }
    }, [dispatch, query?.data]);

    return query;
}

export const useSouSecNiv2EntCol = () => {
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntCol);
    const stateSections = useSelector(selectEntSecCol);
    const sectionSelected = stateSections?.sectionSelected;
    const codeCle = stateEntretiens?.entretienSelected?.codeCle;
    const codeEntretien = stateEntretiens?.entretienSelected?.codeEntretien;
    const rang = sectionSelected?.rang;
    const client = stateClient.client;
    const niveau = stateSections?.niveau;
    const dispatch = useDispatch();

    const query = useQuery(['entSouSecNiv2Col', codeCle, niveau], ({ signal }) => fetchSouSecEnt(codeCle, codeEntretien, rang, client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: niveau === 2 && stateSections?.typeNiv2 === "SL"
    });
    
    useEffect(() => {
        if(query?.data) {
            dispatch(entSecColActions.setSections(query?.data));
        }
    }, [dispatch, query?.data]);

    return query;
}

export const useSouSecCmpNiv2EntMan = (open) => {
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;
    const codeCle = stateEntretiens?.entretienSelected?.codeCle;
    const codeEntretien = stateEntretiens?.entretienSelected?.codeEntretien;
    const rang = sectionSelected?.rang;
    const client = stateClient.client;
    const niveau = stateSections?.niveau;
    const dispatch = useDispatch();

    const query = useQuery(['entSouSecCmpNiv2Man', codeCle, niveau], ({ signal }) => fetchSouSecCmpEnt(codeCle, codeEntretien, rang, client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open && niveau === 2 && stateSections?.typeNiv2 === "GL"
    });
    
    useEffect(() => {
        if(query?.data) {
            dispatch(entSecManActions.setSections(query?.data));
        }
    }, [dispatch, query?.data]);

    return query;
}

export const useSouSecCmpNiv2EntCol = (open) => {
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntCol);
    const stateSections = useSelector(selectEntSecCol);
    const sectionSelected = stateSections?.sectionSelected;
    const codeCle = stateEntretiens?.entretienSelected?.codeCle;
    const codeEntretien = stateEntretiens?.entretienSelected?.codeEntretien;
    const rang = sectionSelected?.rang;
    const client = stateClient.client;
    const niveau = stateSections?.niveau;
    const dispatch = useDispatch();

    const query = useQuery(['entSouSecCmpNiv2Col', codeCle, niveau], ({ signal }) => fetchSouSecCmpEnt(codeCle, codeEntretien, rang, client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open && niveau === 2 && stateSections?.typeNiv2 === "GL"
    });
    
    useEffect(() => {
        if(query?.data) {
            dispatch(entSecColActions.setSections(query?.data));
        }
    }, [dispatch, query?.data]);

    return query;
}

export const useCmpNiv2EntMan = () => {
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;
    const codeCle = stateEntretiens?.entretienSelected?.codeCle;
    const codeEntretien = stateEntretiens?.entretienSelected?.codeEntretien;
    const rang = sectionSelected?.rang;
    const client = stateClient.client;
    const niveau = stateSections?.niveau;
    const dispatch = useDispatch();

    const query = useQuery(['entCmpNiv2Man', codeCle, niveau], ({ signal }) => fetchCmpEnt(codeCle, codeEntretien, rang, client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: niveau === 2 && stateSections?.typeNiv2 === "CL"
    });
    
    useEffect(() => {
        if(query?.data) {
            dispatch(entSecManActions.setSections(query?.data));
        }
    }, [dispatch, query?.data]);

    return query;
}

export const useCmpNiv2EntCol = () => {
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntCol);
    const stateSections = useSelector(selectEntSecCol);
    const sectionSelected = stateSections?.sectionSelected;
    const codeCle = stateEntretiens?.entretienSelected?.codeCle;
    const codeEntretien = stateEntretiens?.entretienSelected?.codeEntretien;
    const rang = sectionSelected?.rang;
    const client = stateClient.client;
    const niveau = stateSections?.niveau;
    const dispatch = useDispatch();

    const query = useQuery(['entCmpNiv2Col', codeCle, niveau], ({ signal }) => fetchCmpEnt(codeCle, codeEntretien, rang, client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: niveau === 2 && stateSections?.typeNiv2 === "CL"
    });
    
    useEffect(() => {
        if(query?.data) {
            dispatch(entSecColActions.setSections(query?.data));
        }
    }, [dispatch, query?.data]);

    return query;
}

export const useCmpNiv3EntMan = () => {
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;
    const codeCle = stateEntretiens?.entretienSelected?.codeCle;
    const codeEntretien = stateEntretiens?.entretienSelected?.codeEntretien;
    const rang = sectionSelected?.rang;
    const client = stateClient.client;
    const niveau = stateSections?.niveau;
    const dispatch = useDispatch();

    const query = useQuery(['entCmpNiv3Man', codeCle, niveau], ({ signal }) => fetchCmpEnt(codeCle, codeEntretien, rang, client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: niveau === 3 && stateSections?.typeNiv3 === "CL"
    });
    
    useEffect(() => {
        if(query?.data) {
            dispatch(entSecManActions.setSections(query?.data));
        }
    }, [dispatch, query?.data]);

    return query;
}

export const useCmpNiv3EntCol = () => {
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntCol);
    const stateSections = useSelector(selectEntSecCol);
    const sectionSelected = stateSections?.sectionSelected;
    const codeCle = stateEntretiens?.entretienSelected?.codeCle;
    const codeEntretien = stateEntretiens?.entretienSelected?.codeEntretien;
    const rang = sectionSelected?.rang;
    const client = stateClient.client;
    const niveau = stateSections?.niveau;
    const dispatch = useDispatch();

    const query = useQuery(['entCmpNiv3Col', codeCle, niveau], ({ signal }) => fetchCmpEnt(codeCle, codeEntretien, rang, client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: niveau === 3 && stateSections?.typeNiv3 === "CL"
    });
    
    useEffect(() => {
        if(query?.data) {
            dispatch(entSecColActions.setSections(query?.data));
        }
    }, [dispatch, query?.data]);

    return query;
}


export const useQueEntMan = (open) => {
    const stateClient = useSelector(selectCltApp);
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;
    const client = stateClient.client;
    const codeQuestion = sectionSelected?.codeQuestion;

    const query = useQuery(['entQueEntMan'], ({ signal }) => fetchQueEnt(codeQuestion, client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!open && stateSections?.modeAjout
    });
    
    return query;
}

export const useComEntMan = (codeQuestion, open) => {
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;
    const codeSociete = stateEntretiens?.entretienSelected?.codeSociete;
    const matricule = stateEntretiens?.entretienSelected?.matricule;
    const codeCle = stateEntretiens?.entretienSelected?.codeCle;
    const rang = sectionSelected?.rang;
    const codeSection = sectionSelected?.codeCleSection;
    const codeSousSection = sectionSelected?.codeCleSousSection;
    const codeCompetence = sectionSelected?.codeCleCompetence;
    const client = stateClient.client;

    const query = useQuery(['entComEntMan'], ({ signal }) => fetchComEnt(codeSociete, matricule, codeCle, rang, codeSection, codeSousSection, codeCompetence, codeQuestion, client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!open && !stateSections?.modeAjout
    });

    return query;
}

export const useComEntManCol = (codeQuestion, open) => {
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntCol);
    const stateSections = useSelector(selectEntSecCol);
    const sectionSelected = stateSections?.sectionSelected;
    const codeSociete = stateEntretiens?.entretienSelected?.codeSociete;
    const matricule = stateEntretiens?.entretienSelected?.matricule;
    const codeCle = stateEntretiens?.entretienSelected?.codeCle;
    const rang = sectionSelected?.rang;
    const codeSection = sectionSelected?.codeCleSection;
    const codeSousSection = sectionSelected?.codeCleSousSection;
    const codeCompetence = sectionSelected?.codeCleCompetence;
    const client = stateClient.client;

    const query = useQuery(['entComEntManCol'], ({ signal }) => fetchComEnt(codeSociete, matricule, codeCle, rang, codeSection, codeSousSection, codeCompetence, codeQuestion, client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!open && !stateSections?.modeAjout
    });

    return query;
}

export const useComEntCol = (codeQuestion, open) => {
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntCol);
    const stateSections = useSelector(selectEntSecCol);
    const sectionSelected = stateSections?.sectionSelected;
    const codeSociete = stateEntretiens?.entretienSelected?.codeSociete;
    const matricule = stateEntretiens?.entretienSelected?.matricule;
    const codeCle = stateEntretiens?.entretienSelected?.codeCle;
    const rang = sectionSelected?.rang;
    const codeSection = sectionSelected?.codeCleSection;
    const codeSousSection = sectionSelected?.codeCleSousSection;
    const codeCompetence = sectionSelected?.codeCleCompetence;
    const client = stateClient.client;

    const query = useQuery(['entComEntCol'], ({ signal }) => fetchComEnt(codeSociete, matricule, codeCle, rang, codeSection, codeSousSection, codeCompetence, codeQuestion, client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open && !stateSections?.modeAjout
    });

    return query;
}

export const useComQueEntMan = (open) => {
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;
    const codeSociete = stateEntretiens?.entretienSelected?.codeSociete;
    const matricule = stateEntretiens?.entretienSelected?.matricule;
    const codeCle = stateEntretiens?.entretienSelected?.codeCle;
    const rang = sectionSelected?.rang;
    const codeSection = sectionSelected?.codeCleSection;
    const codeSousSection = sectionSelected?.codeCleSousSection;
    const codeCompetence = sectionSelected?.codeCleCompetence;
    const client = stateClient.client;
    const codesQuestions = [
        sectionSelected?.codeQuestion1,
        sectionSelected?.codeQuestion2,
        sectionSelected?.codeQuestion3,
        sectionSelected?.codeQuestion4,
        sectionSelected?.codeQuestion5,
        sectionSelected?.codeQuestion6,
    ]

    const query = useQuery(['entComQueEntMan'], ({ signal }) => fetchComQueEnt(codeSociete, matricule, codeCle, rang, codeSection, codeSousSection, codeCompetence, codesQuestions, client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open && !stateSections?.modeAjout
    });

    return query;
}

export const useComQueEntCol = (open) => {
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntCol);
    const stateSections = useSelector(selectEntSecCol);
    const sectionSelected = stateSections?.sectionSelected;
    const codeSociete = stateEntretiens?.entretienSelected?.codeSociete;
    const matricule = stateEntretiens?.entretienSelected?.matricule;
    const codeCle = stateEntretiens?.entretienSelected?.codeCle;
    const rang = sectionSelected?.rang;
    const codeSection = sectionSelected?.codeCleSection;
    const codeSousSection = sectionSelected?.codeCleSousSection;
    const codeCompetence = sectionSelected?.codeCleCompetence;
    const client = stateClient.client;
    const codesQuestions = [
        sectionSelected?.codeQuestion1,
        sectionSelected?.codeQuestion2,
        sectionSelected?.codeQuestion3,
        sectionSelected?.codeQuestion4,
        sectionSelected?.codeQuestion5,
        sectionSelected?.codeQuestion6,
    ]

    const query = useQuery(['entComQueEntCol'], ({ signal }) => fetchComQueEnt(codeSociete, matricule, codeCle, rang, codeSection, codeSousSection, codeCompetence, codesQuestions, client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open && !stateSections?.modeAjout
    });

    return query;
}

export const useEditEntretien = () => {
    const { isLoading, mutateAsync } = useMutation(editEnt);
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntMan);
    const entretienSelected = stateEntretiens?.entretienSelected;
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const editEntretienActionner = async (data) => {
        let isSucceed = false;
        const formatedData = formToDataEntretien(data, entretienSelected?.codeSociete, entretienSelected?.matricule, entretienSelected?.codeCle, stateClient.client);
        try {
            const response = await mutateAsync(formatedData)
            dispatch(entManActions.selectEntretien(response?.entretien));
            enqueueSnackbar("Entretien modifiée avec succès.", { variant: "success" });
            isSucceed = true;
        } catch(e) {
            enqueueSnackbar(e?.response?.data?.details, { variant: "error" });
        }

        return { isSucceed }
    }

    return { isLoadingEdit: isLoading, editEntretienActionner };
}

export const useDeleteEntretien = () => {
    const { isLoading, mutateAsync } = useMutation(deleteEnt);
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntMan);
    const { enqueueSnackbar } = useSnackbar();

    const deleteEntretienActionner = async () => {
        let isSucceed = false;
        const formatedData = {
            codeSociete: stateEntretiens?.entretienSelected?.codeSociete,
            matricule: stateEntretiens?.entretienSelected?.matricule,
            codeCle: stateEntretiens?.entretienSelected?.codeCle,
            client: stateClient.client
        };
        try {
            await mutateAsync(formatedData)
            enqueueSnackbar("Entretien supprimé avec succès.", { variant: "success" });
            isSucceed = true;
        } catch(e) {
            enqueueSnackbar(e?.response?.data?.details, { variant: "error" });
        }

        return { isSucceed }
    }

    return { isLoadingDelete: isLoading, deleteEntretienActionner };
}

export const useDeleteSousSectionEntretien = () => {
    const { isLoading, mutateAsync } = useMutation(deleteSouSecEnt);
    const stateClient = useSelector(selectCltApp);
    const stateSections = useSelector(selectEntSecMan);
    const { enqueueSnackbar } = useSnackbar();

    const deleteSousSectionEntretienActionner = async (data, nombreSousSection) => {
        let isSucceed = false;
        const formatedData = {
            codeSociete: data?.codeSociete,
            matricule: data?.matricule,
            codeCle: data?.codeCle,
            rang: data?.rang,
            nombreSousSection: nombreSousSection,
            codeSection: data?.codeCleSection,
            codeSousSection: data?.codeCleSousSection,
            codeCompetence: data?.codeCleCompetence,
            codeQuestion: data?.codeCleQuestion ? stateSections?.sectionSelected?.codeCleQuestion : 0,
            client: stateClient.client
        };
        try {
            await mutateAsync(formatedData)
            enqueueSnackbar("Sous section supprimée avec succès.", { variant: "success" });
            isSucceed = true;
        } catch(e) {
            enqueueSnackbar(e?.response?.data?.details, { variant: "error" });
        }

        return { isSucceed }
    }

    return { isLoadingDelete: isLoading, deleteSousSectionEntretienActionner };
}

export const useDeleteSousSectionEntretienCol = () => {
    const { isLoading, mutateAsync } = useMutation(deleteSouSecEnt);
    const stateClient = useSelector(selectCltApp);
    const { enqueueSnackbar } = useSnackbar();

    const deleteSousSectionEntretienActionner = async (data, nombreSousSection) => {
        let isSucceed = false;
        const formatedData = {
            codeSociete: data?.codeSociete,
            matricule: data?.matricule,
            codeCle: data?.codeCle,
            rang: data?.rang,
            nombreSousSection: nombreSousSection,
            codeSection: data?.codeCleSection,
            codeSousSection: data?.codeCleSousSection,
            codeCompetence: data?.codeCleCompetence,
            codeQuestion: data?.codeCleQuestion ? data?.codeCleQuestion : 0,
            client: stateClient.client
        };
        try {
            await mutateAsync(formatedData)
            enqueueSnackbar("Sous section supprimée avec succès.", { variant: "success" });
            isSucceed = true;
        } catch(e) {
            enqueueSnackbar(e?.response?.data?.details, { variant: "error" });
        }

        return { isSucceed }
    }

    return { isLoadingDelete: isLoading, deleteSousSectionEntretienActionner };
}

export const usePostSousSection = () => {
    const { isLoading: isLoadingSousSection, mutateAsync: mutateAsyncSousSection } = useMutation(postSouSecEnt);
    const { isLoading: isLoadingCommentaire, mutateAsync: mutateAsyncCommentaire } = useMutation(postComEntMan);
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const entretienSelected = stateEntretiens?.entretienSelected;
    const { enqueueSnackbar } = useSnackbar();
    const otherData = {
        codeSociete: entretienSelected?.codeSociete,
        matricule: entretienSelected?.matricule,
        codeCle: entretienSelected?.codeCle,
        rang: stateSections?.sectionSelected?.rang,
        codeSection: stateSections?.sectionSelected?.codeCleSection,
        codeSousSection: stateSections?.sectionSelected?.codeCleSousSection,
        codeCompetence: stateSections?.sectionSelected?.codeCleCompetence,
        codeQuestion: stateSections?.sectionSelected?.codeCleQuestion ? stateSections?.sectionSelected?.codeCleQuestion : 0,
        codeIdentificationSection: stateSections?.sectionSelected?.codeIdentificationSection,
        client: stateClient.client
    }

    const postCommentaire = async (data) => {
        const formatedData = formToDataCS(data, otherData);
        try {
            await mutateAsyncCommentaire(formatedData);
            enqueueSnackbar("Commentaire modifiée avec succès.", { variant: "success" });
        } catch(e) {
            enqueueSnackbar("Erreur lors de la modification du contenu", { variant: "error" });
        }
    }

    const postSousSection = async (data) => {
        const formatedData = formToDataSS(data, otherData);
        try {
            await mutateAsyncSousSection(formatedData);
            enqueueSnackbar("Sous section modifiée avec succès.", { variant: "success" });
        } catch(e) {
            enqueueSnackbar("Erreur lors de la modification du contenu", { variant: "error" });
        }
    }

    return { isLoadingSousSection: isLoadingSousSection, isLoadingCommentaire: isLoadingCommentaire, postCommentaire, postSousSection };
}

export const usePostSousSectionCol = () => {
    const { isLoading, mutateAsync } = useMutation(postComEntCol);
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntCol);
    const stateSections = useSelector(selectEntSecCol);
    const entretienSelected = stateEntretiens?.entretienSelected;
    const { enqueueSnackbar } = useSnackbar();
    const otherData = {
        codeSociete: entretienSelected?.codeSociete,
        matricule: entretienSelected?.matricule,
        codeCle: entretienSelected?.codeCle,
        rang: stateSections?.sectionSelected?.rang,
        codeSection: stateSections?.sectionSelected?.codeCleSection,
        codeSousSection: stateSections?.sectionSelected?.codeCleSousSection,
        codeCompetence: stateSections?.sectionSelected?.codeCleCompetence,
        codeQuestion: stateSections?.sectionSelected?.codeCleQuestion ? stateSections?.sectionSelected?.codeCleQuestion : 0,
        codeIdentificationSection: stateSections?.sectionSelected?.codeIdentificationSection,
        client: stateClient.client
    }

    const postCommentaire = async (data) => {
        const formatedData = formToDataCS(data, otherData);
        try {
            await mutateAsync(formatedData);
            enqueueSnackbar("Commentaire modifiée avec succès.", { variant: "success" });
        } catch(e) {
            enqueueSnackbar("Erreur lors de la modification du contenu", { variant: "error" });
        }
    }

    return { isLoading, postCommentaire };
}

export const usePostQuestionnaire = () => {
    const { isLoading: isLoadingQuestionnaire, mutateAsync: mutateAsyncQuestionnaire } = useMutation(postQueEnt);
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const entretienSelected = stateEntretiens?.entretienSelected;
    const sectionSelected = stateSections?.sectionSelected;
    const { enqueueSnackbar } = useSnackbar();
    const otherData = {
        codeSociete: entretienSelected?.codeSociete,
        matricule: entretienSelected?.matricule,
        codeCle: entretienSelected?.codeCle,
        rang: sectionSelected?.rang,
        codeSection: sectionSelected?.codeCleSection,
        codeSousSection: sectionSelected?.codeCleSousSection,
        codeCompetence: sectionSelected?.codeCleCompetence,
        codeIdentificationSection: sectionSelected?.codeIdentificationSection,
        codeQuestion1: sectionSelected?.codeQuestion1,
        codeQuestion2: sectionSelected?.codeQuestion2,
        codeQuestion3: sectionSelected?.codeQuestion3,
        codeQuestion4: sectionSelected?.codeQuestion4,
        codeQuestion5: sectionSelected?.codeQuestion5,
        codeQuestion6: sectionSelected?.codeQuestion6,
        client: stateClient.client
    }

    const postQuestionnaire = async (data) => {
        const formatedData = formToDataQ(data, otherData);
        try {
            await mutateAsyncQuestionnaire(formatedData);
            enqueueSnackbar("Sous section modifiée avec succès.", { variant: "success" });
        } catch(e) {
            enqueueSnackbar("Erreur lors de la modification du contenu", { variant: "error" });
        }
    }

    return { isLoadingQuestionnaire, postQuestionnaire };
}

export const useAddSousSection = () => {
    const { isLoading: isLoadingSousSection, mutateAsync: mutateAsyncSousSection } = useMutation(addSouSecEnt);
    const { isLoading: isLoadingQuestionnaire, mutateAsync: mutateAsyncQuestionnaire } = useMutation(addSouSecQueEnt);
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const entretienSelected = stateEntretiens?.entretienSelected;
    const { enqueueSnackbar } = useSnackbar();
    const otherData = {
        codeSociete: entretienSelected?.codeSociete,
        matricule: entretienSelected?.matricule,
        codeCle: entretienSelected?.codeCle,
        codeEntretien: entretienSelected?.codeEntretien,
        rang: stateSections?.sectionSelected?.rang,
        codeSection: stateSections?.sectionSelected?.codeCleSection,
        codeSousSection: stateSections?.sectionSelected?.codeCleSousSection,
        codeCompetence: stateSections?.sectionSelected?.codeCleCompetence,
        codeQuestion: stateSections?.sectionSelected?.codeCleQuestion ? stateSections?.sectionSelected?.codeCleQuestion : "0",
        codeIdentificationSection: stateSections?.sectionSelected?.codeIdentificationSection,
        client: stateClient.client
    }

    const addSousSection = async (data) => {
        const formatedData = formToDataASS(data, otherData);
        try {
            await mutateAsyncSousSection(formatedData);
            enqueueSnackbar("Sous section ajoutée avec succès.", { variant: "success" });
        } catch(e) {
            enqueueSnackbar("Erreur lors de la modification du contenu", { variant: "error" });
        }
    }

    const addSousSectionQuestionnaire = async (data) => {
        const formatedData = formToDataASSQ(data, otherData);
        try {
            await mutateAsyncQuestionnaire(formatedData);
            enqueueSnackbar("Sous section ajoutée avec succès.", { variant: "success" });
        } catch(e) {
            enqueueSnackbar("Erreur lors de la modification du contenu", { variant: "error" });
        }
    }

    return { isLoadingAjout: isLoadingSousSection, isLoadingQuestionnaire: isLoadingQuestionnaire, addSousSection, addSousSectionQuestionnaire };
}

export const useAddSousSectionCol = () => {
    const { isLoading, mutateAsync } = useMutation(addSouSecEnt);
    const stateClient = useSelector(selectCltApp);
    const stateEntretiens = useSelector(selectEntCol);
    const stateSections = useSelector(selectEntSecCol);
    const entretienSelected = stateEntretiens?.entretienSelected;
    const { enqueueSnackbar } = useSnackbar();
    const otherData = {
        codeSociete: entretienSelected?.codeSociete,
        matricule: entretienSelected?.matricule,
        codeCle: entretienSelected?.codeCle,
        codeEntretien: entretienSelected?.codeEntretien,
        rang: stateSections?.sectionSelected?.rang,
        codeSection: stateSections?.sectionSelected?.codeCleSection,
        codeSousSection: stateSections?.sectionSelected?.codeCleSousSection,
        codeCompetence: stateSections?.sectionSelected?.codeCleCompetence,
        codeQuestion: stateSections?.sectionSelected?.codeCleQuestion ? stateSections?.sectionSelected?.codeCleQuestion : "0",
        codeIdentificationSection: stateSections?.sectionSelected?.codeIdentificationSection,
        client: stateClient.client
    }

    const addSousSection = async (data) => {
        const formatedData = formToDataASSCol(data, otherData);
        try {
            await mutateAsync(formatedData);
            enqueueSnackbar("Sous section ajoutée avec succès.", { variant: "success" });
        } catch(e) {
            enqueueSnackbar("Erreur lors de la modification du contenu", { variant: "error" });
        }
    }

    return { isLoading, addSousSection };
}

export const useDownloadEntretien = () => {
    const { isLoading, mutateAsync } = useMutation(downloadEnt);
    const stateClient = useSelector(selectCltApp);
    const stateUser = useSelector(selectUsrApp);
    const stateEntretienManager = useSelector(selectEntMan);
    const stateEntretienCollaborateur = useSelector(selectEntCol);
    const { enqueueSnackbar } = useSnackbar();
    
    const downloadEntretienManagerActionner = async () => {
        let isSucceed = false;
        const formatedData = {
            codeSociete: stateEntretienManager?.entretienSelected?.codeSociete,
            matricule: stateEntretienManager?.entretienSelected?.matricule,
            codeCle: stateEntretienManager?.entretienSelected?.codeCle,
            jour: v.padLeft(stateEntretienManager?.entretienSelected?.jour, 2, "0"),
            mois: v.padLeft(stateEntretienManager?.entretienSelected?.mois, 2, "0"),
            annee: stateEntretienManager?.entretienSelected?.annee,
            heure: v.padLeft(stateEntretienManager?.entretienSelected?.heure, 2, "0"),
            minute: v.padLeft(stateEntretienManager?.entretienSelected?.minute, 2, "0"),
            client: stateClient.client
        };
        try {
            const responsePath = await mutateAsync(formatedData);
            const responseFile = await downloadFile(responsePath?.chemin, "Entretien", stateUser.token, stateClient.client);
            const blob = new Blob([responseFile.data], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);
            window.open(url, "_blank");
        } catch(e) {
            enqueueSnackbar("Erreur lors du téléchargement", { variant: "error" });
        }

        return { isSucceed }
    }

    const downloadEntretienCollaborateurActionner = async () => {
        let isSucceed = false;
        const formatedData = {
            codeSociete: stateEntretienCollaborateur?.entretienSelected?.codeSociete,
            matricule: stateEntretienCollaborateur?.entretienSelected?.matricule,
            codeCle: stateEntretienCollaborateur?.entretienSelected?.codeCle,
            jour: v.padLeft(stateEntretienCollaborateur?.entretienSelected?.jour, 2, "0"),
            mois: v.padLeft(stateEntretienCollaborateur?.entretienSelected?.mois, 2, "0"),
            annee: stateEntretienCollaborateur?.entretienSelected?.annee,
            heure: v.padLeft(stateEntretienCollaborateur?.entretienSelected?.heure, 2, "0"),
            minute: v.padLeft(stateEntretienCollaborateur?.entretienSelected?.minute, 2, "0"),
            client: stateClient.client
        };
        try {
            const responsePath = await mutateAsync(formatedData);
            const responseFile = await downloadFile(responsePath?.chemin, "Entretien", stateUser.token, stateClient.client);
            const blob = new Blob([responseFile.data], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);
            window.open(url, "_blank");
        } catch(e) {
            enqueueSnackbar("Erreur lors du téléchargement", { variant: "error" });
        }

        return { isSucceed }
    }

    return { isLoadingDownload: isLoading, downloadEntretienManagerActionner, downloadEntretienCollaborateurActionner };
}

export const useTerminerEntretien = () => {
    const { isLoading, mutateAsync } = useMutation(terminerEnt);
    const stateClient = useSelector(selectCltApp);
    const stateEntretien = useSelector(selectEntMan);
    const { enqueueSnackbar } = useSnackbar();
    
    const terminerEntretienActionner = async () => {
        let isSucceed = false;
        const formatedData = {
            codeSociete: stateEntretien?.entretienSelected?.codeSociete,
            matricule: stateEntretien?.entretienSelected?.matricule,
            codeCle: stateEntretien?.entretienSelected?.codeCle,
            client: stateClient.client
        };
        try {
            await mutateAsync(formatedData);
            isSucceed = true;
            enqueueSnackbar("Entretien terminé avec succès.", { variant: "success" });
        } catch(e) {
            enqueueSnackbar("Erreur lors de l'action.", { variant: "error" });
        }

        return { isSucceed }
    }

    return { isLoadingTerminer: isLoading, terminerEntretienActionner };
}
