import { Box, Grid, Stack, Typography } from "@mui/material";
import { ListAlt } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { DialogContainer, HeaderDialogContainer, HeaderListContainer, ItemContainer, ListContainer } from "@components/Containers";
import { redColor, whiteColor } from "@styles";
import { IconButtonBase } from "@components/Buttons";

const headersAnomalies = [
    {id: 1, label: "Code", xs: 2, display: true, firstData: true, lastData: false},
    {id: 2, label: "Désignation", xs: 8, display: true, firstData: false, lastData: false},
    {id: 3, label: "Gravité", xs: 2, display: true, firstData: false, lastData: true}
];

const headersTransaction = [
    {id: 1, label: "Mouvement", xs: 1.5, display: true, firstData: true, lastData: false},
    {id: 2, label: "Date", xs: 1.5, display: true, firstData: false, lastData: false},
    {id: 3, label: "Heure Réelle", xs: 1.5, display: true, firstData: false, lastData: false},
    {id: 4, label: "Heure Ajustée", xs: 1.5, display: true, firstData: false, lastData: false},
    {id: 5, label: "Lecteur", xs: 2, display: true, firstData: false, lastData: false},
    {id: 6, label: "Activité", xs: 2, display: true, firstData: false, lastData: false},
    {id: 7, label: "Type Travail", xs: 2, display: true, firstData: false, lastData: true}
];

const AnomaliesTransactionsDialog = ({
    open = false, 
    transaction = null,
    setOpen = () => {},  
    openAllAnomalies = () => {}
}) => {
    return (
        <DialogContainer open={open} placement="top" taille="md">
            <HeaderDialogContainer 
                actionsLeft={
                    <IconButtonBase variant="white5Grey9White9" icon={<ListAlt fontSize="small" />} title="Afficher la liste complète des anomalies" action={openAllAnomalies} />
                }
                title="Anomalie(s)"
                close={() => setOpen(false)}
                variant="error"
            />
            {transaction && 
                <>
                    <Stack spacing={0.5} bgcolor={redColor[5]} p={0.5} borderRadius={2}>
                        <Box bgcolor={whiteColor[7]} borderRadius={2} p={1}>
                            <Typography fontSize="small" fontWeight="bold" textAlign="center">Transaction</Typography>
                        </Box>
                        <HeaderListContainer itemIdReference="HISTOANOGT1" variant="light" headerItems={headersTransaction} />
                        <ListContainer spacing={0.5} length={1}>
                            <Box bgcolor={whiteColor[5]} borderRadius={2} p={0.3}>
                                <Grid container spacing={0.3}>
                                    <ItemContainer firstData data={transaction.libelleMouvement} xs={1.5} />
                                    <ItemContainer data={moment([transaction.anneeMouvement, transaction.moisMouvement - 1, transaction.jourMouvement]).format("DD/MM/YYYY")} xs={1.5} />
                                    <ItemContainer data={`${moment(transaction.heureMouvement, "H").format("HH")}h${moment(transaction.minuteMouvement, "m").format("mm")}`} xs={1.5} />
                                    <ItemContainer data={`${moment(transaction.heureMouvementAjustee, "H").format("HH")}h${moment(transaction.minuteMouvementAjustee, "m").format("mm")}`} xs={1.5} />
                                    <ItemContainer data={transaction.lecteurPresence} xs={2} />
                                    <ItemContainer data={transaction.libelleActivite} xs={2} />
                                    <ItemContainer lastData data={transaction.libelleTypeTravail} xs={2} />
                                </Grid>
                            </Box>
                        </ListContainer>
                    </Stack>
                    <Stack spacing={0.5} bgcolor={redColor[5]} p={0.5} borderRadius={2}>
                        <Box bgcolor={whiteColor[7]} borderRadius={2} p={1}>
                            <Typography fontSize="small" fontWeight="bold" textAlign="center">Anomalie(s)</Typography>
                        </Box>
                        <HeaderListContainer itemIdReference="HISTOANOGT2" variant="light" headerItems={headersAnomalies} />
                        <ListContainer spacing={0.5} length={transaction.anomalies.length} noDataLabel="Aucune anomalie">
                            {transaction.anomalies.map((anomalie) => (
                                <Box key={`ANO${anomalie.code}`} bgcolor={whiteColor[5]} borderRadius={2} p={0.3}>
                                    <Grid container spacing={0.3}>
                                        <ItemContainer firstData data={anomalie.codeAnomalie} xs={2} />
                                        <ItemContainer data={anomalie.libelleAnomalie} xs={8} />
                                        <ItemContainer lastData data={anomalie.gravite} xs={2} />
                                    </Grid>
                                </Box>
                            ))}
                        </ListContainer>
                    </Stack>
                </>
            }
        </DialogContainer>
    );
}

AnomaliesTransactionsDialog.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    transaction: PropTypes.object,
    openAllAnomalies: PropTypes.func
}

export default AnomaliesTransactionsDialog;