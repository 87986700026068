import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query"
import { useSnackbar } from "notistack";
import moment from "moment";
import { fetchPlaDabCol, fetchPlaDabMan, fetchPlaHrc, fetchPlaGlo, fetchDetJouPla, downloadPla } from "@api/planning";
import { fetchCntPla } from "@api/contraintes";
import { selectCltApp, selectPlaGlo, selectUsrApp } from "@selectors";
import * as plaDabColActions from "@reducers/planning/plaDabColReducer"
import * as plaDabManActions from "@reducers/planning/plaDabManReducer"
import * as plaGloActions from "@reducers/planning/plaGloReducer"
import * as plaHrcActions from "@reducers/planning/plaHrcReducer"
import { generateTableurPlanning } from "@tableurs";

export const usePlaDabCol = (dateDebut, datePosition, absencesAAfficher, open) => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const dispatch = useDispatch();

    const query = useQuery(['plaDabCol', datePosition, absencesAAfficher], ({ signal }) => fetchPlaDabCol(stateUser.matricule, dateDebut, datePosition, absencesAAfficher, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!dateDebut && !!datePosition && open
    });

    useEffect(() => {
        if(query.data) {
            dispatch(plaDabColActions.setPlanning(query.data));
        }
    }, [dispatch, query.data]);

    return query;
}

export const usePlaDabMan = (matricule, demande, date, affichage) => {
    const stateClient = useSelector(selectCltApp);
    const matriculeDemandeur = demande.matricule;
    const dispatch = useDispatch();
    
    const query = useQuery(['plaDabMan', date, affichage], ({ signal }) => fetchPlaDabMan(matricule, matriculeDemandeur, date, date, affichage, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!date
    });

    useEffect(() => {
        if(query.data) {
            dispatch(plaDabManActions.setPlanning(query.data));
        }
    }, [dispatch, query.data]);

    return query;
}

export const usePlaGlo = (date, affichage, servicesSelected, servicesNonSelected, premierAppel) => {
    const stateUser = useSelector(selectUsrApp);    
    const stateClient = useSelector(selectCltApp); 
    const dispatch = useDispatch();

    const query = useQuery(['plaGlo', date, affichage, servicesSelected], ({ signal }) => fetchPlaGlo(stateUser.matricule, date, affichage, servicesSelected, servicesNonSelected, premierAppel, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if(query.data) {
            dispatch(plaGloActions.setPlanning(query.data));
        }
    }, [dispatch, query.data]);

    return query;
}

export const usePlaHrc = (date, affichage, open) => {
    const stateClient = useSelector(selectCltApp);
    const stateUser = useSelector(selectUsrApp);
    const dispatch = useDispatch();

    const query = useQuery(['plaHrc', date, affichage], ({ signal }) => fetchPlaHrc(stateUser.matricule, date, affichage, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open && !!date
    });

    useEffect(() => {
        if(query.data) {
            dispatch(plaHrcActions.setPlanning(query.data));
        }
    }, [dispatch, query.data]);

    return query;
}

export const useCntPla = (societe, service, open) => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);

    return useQuery(['cntPla', societe, service], ({ signal }) => fetchCntPla(stateUser.matricule, societe, service, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open
    });
}

export const useDetJouPla = (matricule, date, open) => {
    const stateClient = useSelector(selectCltApp);

    return useQuery(['detJouPla', matricule, date], ({ signal }) => fetchDetJouPla(matricule, date, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open
    });
}

export const useDownloadPlanning = () => {
    const stateUser = useSelector(selectUsrApp);  
    const stateClient = useSelector(selectCltApp);     
    const statePlanning = useSelector(selectPlaGlo);     
    const { isLoading, mutateAsync } = useMutation(downloadPla);
    const { enqueueSnackbar } = useSnackbar();

    const downloadPlanningActionner = async () => {
        const data = {
            matricule: stateUser.matricule,
            annee: moment(statePlanning.date, "DDMMYYYY").format("YYYY"),
            semaine: moment(statePlanning.date, "DDMMYYYY").format("WW"),
            affichage: statePlanning.affichage,
            client: stateClient.client
        }
        try {
            const response = await mutateAsync(data);
            generateTableurPlanning("Planning graphique", response.semaines, response.societes);
        } catch(e) {
            enqueueSnackbar(e?.reponse?.data?.details, { variant: "error" })
        }
    }

    return { isLoading, downloadPlanningActionner };
}
