import { Radio, FormControlLabel, RadioGroup, Typography } from "@mui/material";
import { useController, useFormContext } from "react-hook-form";
import PropTypes from "prop-types";

const RadiosHookFormInput = (props) => {
    const { name, options, row } = props;
    const { control } = useFormContext();
    const { field } = useController({ name, control });

    return (
        <RadioGroup name={name} row={row} {...field}>
            {options.map((option) => (
                <FormControlLabel 
                    key={option.id} 
                    sx={styles.inputStyle} 
                    value={option.value} 
                    label={<Typography fontSize="small">{option.label}</Typography>} 
                    control={<Radio  />}  
                />
            ))}
        </RadioGroup>
    );
}

RadiosHookFormInput.defaultProps = {
    options: [],
    row: false
}

RadiosHookFormInput.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array,
    row: PropTypes.bool
}

const styles = {
    inputStyle: {
        '& .MuiSvgIcon-root': {
            fontSize: 18,
        }
    }
};

export default RadiosHookFormInput;