import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { BaseButton } from "@components/Buttons";
import { CopyAll } from "@mui/icons-material";
import { Box, Grid, IconButton, Paper, Stack } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { selectCltApp } from "@selectors";
import { Loader } from "@components/Loaders";
import { ErrorBase } from "@components/Errors";
import { TextContainer } from "@components/Containers";
import { greenColor, greyColor } from "@styles";
import { FloatingFocusManager, FloatingPortal, autoPlacement, autoUpdate, useClick, useDismiss, useFloating, useInteractions, useRole } from "@floating-ui/react";
import { usePlanGesTemMdl } from "@hooks/gestionTemps/planning/hooksUtils";
import { useHorBut } from "@hooks/gestionTemps/horaires/hooksQueries";

const headerOptionsHoraire = [
    {id: 1, title: "Code", xs: 2, key: "codeHoraire"},
    {id: 2, title: "Désignation", xs: 10, key: "designation"}
];

const ContentMenu = (props) => {
    const { open, setIsOpen, idJour, modelizePlanning } = props;
    const { societe, date } = usePlanGesTemMdl(idJour);
    const stateClient = useSelector(selectCltApp);
    const { isLoading, isFetching, data, isError, error } = useHorBut(societe, moment(date, "YYYYMMDD"), open, stateClient.client);

    const selectHoraire = useCallback((codeHoraire) => { 
        modelizePlanning(idJour, codeHoraire);
        setIsOpen(false);
    }, [idJour, modelizePlanning, setIsOpen]);

    if(isLoading || isFetching) {
        return (
            <Box p={2} width={50} boxSizing={"border-box"}>
                <Loader size={17} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return (
            <ErrorBase>{error?.message}</ErrorBase>
        );
    }

    return (
        <Stack p={0.5} spacing={0.5} maxHeight={48*4.5} width={250} boxSizing={"border-box"}>
            <Box>
                <Grid container spacing={0.5}>
                    {headerOptionsHoraire.map((headerOption) => (
                        <Grid item xs={headerOption.xs} key={`AUTOCOPTIONHEAD${headerOption.id}`}>
                            <TextContainer bgColor={greenColor["035"]} p={0.75} height={"100%"} textWeight="bold" horizontalAlign="center" verticalAlign="center" textAlign="center" textDecoration={"none"}>{headerOption.title}</TextContainer>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Stack spacing={0.5} overflow={"auto"}>
                {data.map((option) => (
                    <BaseButton key={option.id} px={0} py={0.5} color="green" action={() => selectHoraire(option["codeHoraire"])}>
                        <Box borderRadius={2}>
                            <Grid container spacing={0.5}>
                                {headerOptionsHoraire.map((headerOption) => (
                                    <Grid item xs={headerOption.xs} key={`AUTOCOPTIONOPT${headerOption.id}`} display="flex" justifyContent="center">
                                        <TextContainer bgColor="none" p={0}>{option[headerOption.key]}</TextContainer>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box> 
                    </BaseButton>
                ))}
            </Stack>
        </Stack>
    )
}

const HoraireMenuButton = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const { idJour, modelizePlanning } = props;
    const { refs, floatingStyles, context } = useFloating({ 
        open: isOpen, 
        onOpenChange: setIsOpen, 
        whileElementsMounted: autoUpdate, 
        middleware: [
            autoPlacement({alignment: "start"})
        ]
    });
    const click = useClick(context);
    const dismiss = useDismiss(context);
    const role = useRole(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([
        click,
        dismiss,
        role
    ]);

    return (
        <Stack direction={"row"} alignItems="center">
            <IconButton ref={refs.setReference} {...getReferenceProps()}>
                <CopyAll fontSize="small" />
            </IconButton>
            {isOpen && (
                <FloatingPortal>
                    <FloatingFocusManager context={context} modal={false}>
                        <Paper ref={refs.setFloating} {...getFloatingProps()} style={{...floatingStyles, zIndex: 10000}}> 
                            <ContentMenu open={isOpen} setIsOpen={setIsOpen} idJour={idJour} modelizePlanning={modelizePlanning} />
                        </Paper>
                    </FloatingFocusManager>
                </FloatingPortal>
            )}
        </Stack>
    )
}

HoraireMenuButton.defaultProps = {
    modelizePlanning: () => {}
}

ContentMenu.propTypes = {
    modelizePlanning: () => {}
}

ContentMenu.propTypes = {
    open: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.bool.isRequired,
    idJour: PropTypes.number.isRequired,
    modelizePlanning: PropTypes.func
}

HoraireMenuButton.propTypes = {
    idJour: PropTypes.number.isRequired,
    modelizePlanning: PropTypes.func
}

export default HoraireMenuButton;