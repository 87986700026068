import { Surfing, Folder, AccessTime, School, EventNote, GroupWork, Schema, Settings, Dashboard, Wallpaper, Abc, Info, Security, SpeakerNotes } from "@mui/icons-material";
import image1 from "../images/image1.jpg";
import image2 from "../images/image2.jpg";
import image3 from "../images/image3.jpg";
import image4 from "../images/image4.jpg";
import image5 from "../images/image5.jpg";
import image6 from "../images/image6.jpg";
import image7 from "../images/image7.jpg";
import image8 from "../images/image8.jpg";
import image9 from "../images/image9.jpg";
import image10 from "../images/image10.jpg";
import { CODE_ACTION } from "./tabsConstants";

export const appMenu = {
    "AB": <Surfing fontSize="small"/>,
    "DO": <Folder fontSize="small"/>,
    "FO": <School fontSize="small"/>,
    "GT": <AccessTime fontSize="small"/>,
    "PL": <EventNote fontSize="small"/>,
    "DE": <GroupWork fontSize="small"/>,
    "EN": <SpeakerNotes fontSize="small"/>,
    "OR": <Schema fontSize="small"/>
};

export const optionsMenuParametres = [
    {id: 1, color: "green", icon: <Settings fontSize="small"/>, label: "buttons.parameters", action: CODE_ACTION.PARAMETRES },
    {id: 2, color: "green", icon: <Dashboard fontSize="small"/>, label: "buttons.personalize", action: CODE_ACTION.PERSONNALISER_DASHBOARD },
    {id: 3, color: "green", icon: <Wallpaper fontSize="small"/>, label: "buttons.wallpaper", action: CODE_ACTION.BACKGROUND },
    {id: 4, color: "green", icon: <Abc fontSize="small"/>, label: "buttons.police", action: CODE_ACTION.POLICE },
    {id: 5, color: "green", icon: <Info fontSize="small"/>, label: "buttons.about", action: CODE_ACTION.ABOUT },
    {id: 6, color: "green", icon: <Security fontSize="small"/>, label: "buttons.confidentiality", action: CODE_ACTION.CONFIDENTIALITE }
];

export const images = [
    {id: 1, src: "", alt: "image0"},
    {id: 2, src: image1, alt: "image1"},
    {id: 3, src: image2, alt: "image2"},
    {id: 4, src: image3, alt: "image3"},
    {id: 5, src: image4, alt: "image4"},
    {id: 6, src: image5, alt: "image5"},
    {id: 7, src: image6, alt: "image6"},
    {id: 8, src: image7, alt: "image7"},
    {id: 9, src: image8, alt: "image8"},
    {id: 10, src: image9, alt: "image9"},
    {id: 11, src: image10, alt: "image10"},
];

export const backgrounds = {
    "image0": "",
    "image1": image1,
    "image2": image2,
    "image3": image3,
    "image4": image4,
    "image5": image5,
    "image6": image6,
    "image7": image7,
    "image8": image8,
    "image9": image9,
    "image10": image10,
};

export const locale = {
    months : "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split('_'),
    monthsShort : "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split('_'),
    monthsParseExact : true,
    weekdays : "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split('_'),
    weekdaysShort : "dim._lun._mar._mer._jeu._ven._sam.".split('_'),
    weekdaysMin : "Di_Lu_Ma_Me_Je_Ve_Sa".split('_'),
    weekdaysParseExact : true,
    longDateFormat : {
        LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'D MMMM YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        LLLL : 'dddd D MMMM YYYY HH:mm'
    },
    calendar : {
        sameDay : '[Aujourd’hui à] LT',
        nextDay : '[Demain à] LT',
        nextWeek : 'dddd [à] LT',
        lastDay : '[Hier à] LT',
        lastWeek : 'dddd [dernier à] LT',
        sameElse : 'L'
    },
    relativeTime : {
        future : 'dans %s',
        past : 'il y a %s',
        s : 'quelques secondes',
        m : 'une minute',
        mm : '%d minutes',
        h : 'une heure',
        hh : '%d heures',
        d : 'un jour',
        dd : '%d jours',
        M : 'un mois',
        MM : '%d mois',
        y : 'un an',
        yy : '%d ans'
    },
    dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
    ordinal : function (number) {
        return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse : /PD|MD/,
    isPM : function (input) {
        return input.charAt(0) === 'M';
    },
    meridiem : function (hours, minutes, isLower) {
        return hours < 12 ? 'PD' : 'MD';
    },
    week : {
        dow : 1,
        doy : 4 
    }
};

export const BUTTON_VARIANTS = [
    "green25Grey5Green7",
    "green5White9Green7",
    "red2Grey5Red5",
    "white2Grey5White9",
    "white5Grey5White9",
    "white5Grey5Green7",
    "blue2Grey5Blue5"
]

export const urls = {
    getDemandesAbsenceHistorique: `${process.env.REACT_APP_HOST}/get/demandes/absence/historique`,
    getDemandesAbsenceAnnulation: `${process.env.REACT_APP_HOST}/get/demandes/absence/annulation`,
    getDemandesAbsenceManager: `${process.env.REACT_APP_HOST}/get/demandes/absence/manager`,
    getDetailDemandeAbsenceCollaborateur: `${process.env.REACT_APP_HOST}/get/detail/demande/absence/collaborateur`,
    getDetailDemandeAbsenceManager: `${process.env.REACT_APP_HOST}/get/detail/demande/absence/manager`,
    getTypesAbsence: `${process.env.REACT_APP_HOST}/get/types/absence`,
    getActivitesCollaborateur: `${process.env.REACT_APP_HOST}/get/activites/collaborateur`,
    getActivitesManager: `${process.env.REACT_APP_HOST}/get/activites/manager`,
    getAnomalies: `${process.env.REACT_APP_HOST}/get/anomalies`,
    getCalendrier: `${process.env.REACT_APP_HOST}/get/calendrier`,
    getCategoriesProfessionnelles: `${process.env.REACT_APP_HOST}/get/categories/professionnelles`,
    getCheckClient: `${process.env.REACT_APP_HOST}/check/client`,
    getConfiguration: `${process.env.REACT_APP_HOST}/get/configuration`,
    getCollaborateursAffectationsIndividuelles: `${process.env.REACT_APP_HOST}/get/collaborateurs/affectations/individuelles`,
    getCollaborateursAffectationsCollectives: `${process.env.REACT_APP_HOST}/get/collaborateurs/affectations/collectives`,
    getCollaborateursCredits: `${process.env.REACT_APP_HOST}/get/collaborateurs/credits`,
    getCollaborateursMouvements: `${process.env.REACT_APP_HOST}/get/collaborateurs/mouvements`,
    getCollaborateursPlanning: `${process.env.REACT_APP_HOST}/get/collaborateurs/planning`,
    getCollaborateursTemps: `${process.env.REACT_APP_HOST}/get/collaborateurs/temps`,
    getContraintesV1: `${process.env.REACT_APP_HOST}/get/contraintes/v1`,
    getContraintesV2: `${process.env.REACT_APP_HOST}/get/contraintes/v2`,
    getDirections: `${process.env.REACT_APP_HOST}/get/directions`,
    getDelegataires: `${process.env.REACT_APP_HOST}/get/delegataires`,
    getDocuments: `${process.env.REACT_APP_HOST}/get/documents`,
    getEmplois: `${process.env.REACT_APP_HOST}/get/emplois`,
    getEtablissements: `${process.env.REACT_APP_HOST}/get/etablissements`,
    getResponsables: `${process.env.REACT_APP_HOST}/get/responsables`,
    getHorairesV1: `${process.env.REACT_APP_HOST}/get/horaires/v1`,
    getNatureDocuments: `${process.env.REACT_APP_HOST}/get/natures/documents`,
    getNotifications: `${process.env.REACT_APP_HOST}/get/notifications`,
    getParametresSecurite: `${process.env.REACT_APP_HOST}/get/parametres/securite`,
    getPlagesHoraires: `${process.env.REACT_APP_HOST}/get/plages/horaires`,
    getSecteurs: `${process.env.REACT_APP_HOST}/get/secteurs`,
    getSecteursActivitesV1: `${process.env.REACT_APP_HOST}/get/secteurs/activites/v1`,
    getSecteursActivitesV2: `${process.env.REACT_APP_HOST}/get/secteurs/activites/v2`,
    getServicesV2: `${process.env.REACT_APP_HOST}/get/services/v2`,
    getSocietesV1: `${process.env.REACT_APP_HOST}/get/societes/v1`,
    getSocietesV2: `${process.env.REACT_APP_HOST}/get/societes/v2`,
    getMultiSoldes: `${process.env.REACT_APP_HOST}/get/multi/soldes`,
    getSituationSolde: `${process.env.REACT_APP_HOST}/get/situation/solde`,
    getSoldes: `${process.env.REACT_APP_HOST}/get/soldes`,
    getStatut: `${process.env.REACT_APP_HOST}/get/statut`,
    getCheckToken: `${process.env.REACT_APP_HOST}/check/token`,
    getTypesTravailV1: `${process.env.REACT_APP_HOST}/get/types/travail/v1`,
    getCheckIdentifiant: `${process.env.REACT_APP_HOST}/check/identifiant`,
    getDelegations: `${process.env.REACT_APP_HOST}/get/delegations`,
    getDroitsDelegation: `${process.env.REACT_APP_HOST}/get/droits/delegation`,
    getOrganigramme: `${process.env.REACT_APP_HOST}/get/organigramme`,
    getAbsencesPlanning: `${process.env.REACT_APP_HOST}/get/absences/planning`,
    getPlanningAbsence: `${process.env.REACT_APP_HOST}/get/planning/absence`,
    getPlanningHierarchie: `${process.env.REACT_APP_HOST}/get/planning/hierarchie`,
    getPlanningManager: `${process.env.REACT_APP_HOST}/get/planning/manager`,
    getPlanningServices: `${process.env.REACT_APP_HOST}/get/planning/services`,
    getTemps: `${process.env.REACT_APP_HOST}/get/temps`,
    getAffectationsActivites: `${process.env.REACT_APP_HOST}/get/affectations/activites`,
    getAffectationsHoraires: `${process.env.REACT_APP_HOST}/get/affectations/horaires`,
    getAnomaliesDemandeRegularisation: `${process.env.REACT_APP_HOST}/get/anomalies/demande/regularisation`,
    getCompteurs: `${process.env.REACT_APP_HOST}/get/compteurs`,
    getCredits: `${process.env.REACT_APP_HOST}/get/credits`,
    getDebitsCredits: `${process.env.REACT_APP_HOST}/get/debits/credits`,
    getDemandesRegularisationsCollaborateur: `${process.env.REACT_APP_HOST}/get/demandes/regularisations/collaborateur`,
    getDetailDemandeRegularisationCollaborateur: `${process.env.REACT_APP_HOST}/get/detail/demande/regularisation/collaborateur`,
    getDemandesRegularisationsManager: `${process.env.REACT_APP_HOST}/get/demandes/regularisations/manager`,
    getDetailDemandeRegularisationManager: `${process.env.REACT_APP_HOST}/get/detail/demande/regularisation/manager`,
    getPlanningGestionTempsManager: `${process.env.REACT_APP_HOST}/get/planning/gestion/temps/manager`,
    getPlanningGestionTempsNavigationManager: `${process.env.REACT_APP_HOST}/get/planning/gestion/temps/navigation/manager`,
    getDetailRegularisationCollaborateur: `${process.env.REACT_APP_HOST}/get/detail/regularisation/collaborateur`,
    getDetailRegularisationManager: `${process.env.REACT_APP_HOST}/get/detail/regularisation/manager`,
    getHistoriqueTransactions: `${process.env.REACT_APP_HOST}/get/historique/transactions`,
    getTransactions: `${process.env.REACT_APP_HOST}/get/transactions`,
    postDemandeAbsence: `${process.env.REACT_APP_HOST}/post/demande/absence`,
    postDemandeAnnulationAbsence: `${process.env.REACT_APP_HOST}/post/demande/annulation`,
    postPieceJointeDemandeAbsence: `${process.env.REACT_APP_HOST}/post/piece/jointe/demande/absence`,
    postReponseDemandeAbsence: `${process.env.REACT_APP_HOST}/post/reponse/demande/absence`,
    postConnexion: `${process.env.REACT_APP_HOST}/connexion`,
    postBackground: `${process.env.REACT_APP_HOST}/post/background`,
    postConfiguration: `${process.env.REACT_APP_HOST}/post/configuration`,
    postHorloges: `${process.env.REACT_APP_HOST}/post/horloges`,
    postPolice: `${process.env.REACT_APP_HOST}/post/police`,
    postLang: `${process.env.REACT_APP_HOST}/post/lang`,
    postComparePassword: `${process.env.REACT_APP_HOST}/post/secure/compare/password`,
    postCompareReponse: `${process.env.REACT_APP_HOST}/post/secure/compare/reponse`,
    postSecureLostPassword: `${process.env.REACT_APP_HOST}/post/secure/lost/password`,
    postSecurePassword: `${process.env.REACT_APP_HOST}/post/secure/password`,
    postSecurePasswordQuestion: `${process.env.REACT_APP_HOST}/post/secure/password/question/reponse`,
    postSecureQuestion: `${process.env.REACT_APP_HOST}/post/secure/question/reponse`,
    postStatut: `${process.env.REACT_APP_HOST}/post/statut`,
    postDelegation: `${process.env.REACT_APP_HOST}/post/delegation`,
    postAffectationActivite: `${process.env.REACT_APP_HOST}/post/affectation/activite`,
    postAffectationsCollectivesActivite: `${process.env.REACT_APP_HOST}/post/affectations/collectives/activite`,
    postAffectationHoraire: `${process.env.REACT_APP_HOST}/post/affectation/horaire`,
    postAffectationsCollectivesHoraire: `${process.env.REACT_APP_HOST}/post/affectations/collectives/horaire`,
    postCycleAffectationHoraire: `${process.env.REACT_APP_HOST}/post/cycle/affectation/horaire`,
    postDemandeRegularisationCollaborateur: `${process.env.REACT_APP_HOST}/post/demande/regularisation/collaborateur`,
    postReponseDemandeRegularisationManager: `${process.env.REACT_APP_HOST}/post/reponse/demande/regularisation/manager`,
    postPlanningGestionTempsManager: `${process.env.REACT_APP_HOST}/post/planning/gestion/temps/manager`,
    postPlanningGestionTempsModelizeManager: `${process.env.REACT_APP_HOST}/post/planning/gestion/temps/modelize/manager`,
    postPlanningGestionTempsVerificationManager: `${process.env.REACT_APP_HOST}/post/planning/gestion/temps/verification/manager`,    
    postRegularisationManager: `${process.env.REACT_APP_HOST}/post/regularisation/manager`,
    editDelegation: `${process.env.REACT_APP_HOST}/edit/delegation`,
    editAffectationActivite: `${process.env.REACT_APP_HOST}/edit/affectation/activite`,
    editAffectationHoraire: `${process.env.REACT_APP_HOST}/edit/affectation/horaire`,
    deleteDemandeAbsenceHistorique: `${process.env.REACT_APP_HOST}/delete/demande/absence/historique`,
    deleteDelegation: `${process.env.REACT_APP_HOST}/delete/delegation`,
    deleteAffectationActivite: `${process.env.REACT_APP_HOST}/delete/affectation/activite`,
    deleteAffectationHoraire: `${process.env.REACT_APP_HOST}/delete/affectation/horaire`,
    deleteDemandeRegularisation: `${process.env.REACT_APP_HOST}/delete/demande/regularisation`,
    copyRegularisations: `${process.env.REACT_APP_HOST}/copy/regularisations`,
    downloadFile: `${process.env.REACT_APP_HOST}/download/file`,
    downloadPlanning: `${process.env.REACT_APP_HOST}/download/planning`,
    downloadAnomaliesActivite: `${process.env.REACT_APP_HOST}/download/anomalies/activite`,
    downloadAnomaliesBase: `${process.env.REACT_APP_HOST}/download/anomalies/base`,
    downloadAnomaliesCredit: `${process.env.REACT_APP_HOST}/download/anomalies/credit`,
    downloadHeures: `${process.env.REACT_APP_HOST}/download/heures`,
    downloadMouvements: `${process.env.REACT_APP_HOST}/download/mouvements`,
    downloadPlanningGestionTemps: `${process.env.REACT_APP_HOST}/download/planning/gestion/temps`,
};

export const urlsEntretien = {
    getEntretiens: `${process.env.REACT_APP_HOST}/get/entretiens`,
    getSectionsEntretien: `${process.env.REACT_APP_HOST}/get/sections/entretien`,
    getSectionsEntretienAccessibles: `${process.env.REACT_APP_HOST}/get/sections/accessibles/entretien`,
    getSousSectionsEntretien: `${process.env.REACT_APP_HOST}/get/sous/sections/entretien`,
    getSousSectionsCompetencesEntretien: `${process.env.REACT_APP_HOST}/get/sous/sections/competences/entretien`,
    getQuestionsEntretien: `${process.env.REACT_APP_HOST}/get/questions/entretien`,
    getCompetencesEntretien: `${process.env.REACT_APP_HOST}/get/competences/entretien`,
    getCommentaireEntretien: `${process.env.REACT_APP_HOST}/get/commentaire/entretien`,
    getCommentairesQuestionnaireEntretien: `${process.env.REACT_APP_HOST}/get/commentaires/questionnaire/entretien`,
    putEntretien: `${process.env.REACT_APP_HOST}/put/entretien`,
    putSousSectionEntretien: `${process.env.REACT_APP_HOST}/put/sous/section/entretien`,
    putQuestionnaireEntretien: `${process.env.REACT_APP_HOST}/put/questionnaire/entretien`,
    putCommentaireManagerEntretien: `${process.env.REACT_APP_HOST}/put/commentaire/manager/entretien`,
    putCommentaireCollaborateurEntretien: `${process.env.REACT_APP_HOST}/put/commentaire/collaborateur/entretien`,
    postSousSectionEntretien: `${process.env.REACT_APP_HOST}/post/sous/section/entretien`,
    postSousSectionQuestionnaireEntretien: `${process.env.REACT_APP_HOST}/post/sous/section/questionnaire/entretien`,
    deleteEntretien: `${process.env.REACT_APP_HOST}/delete/entretien`,
    deleteSousSectionEntretien: `${process.env.REACT_APP_HOST}/delete/sous/section/entretien`,
    downloadEntretien: `${process.env.REACT_APP_HOST}/get/fichier/entretien`,
    terminerEntretien: `${process.env.REACT_APP_HOST}/put/terminer/entretien`
}