import { FilterList, FilterListOff, MobiledataOff, Refresh, SwapVert, ViewList, ViewModule } from "@mui/icons-material";
import PropTypes from "prop-types";
import { HeaderPanelContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { useMedia } from "react-use";

const DocumentsActionsBar = ({ 
    isLoading = true,
    isError = false,
    tried = false,
    filtered = false,
    listed = false,
    lister = () => {},
    deleteFiltre = () => {},
    deleteTrie = () => {},
    setAnchorEl = () => {},
    setOpenFiltre = () => {},
    refetch = () => {}
}) => {
    const isMobile = useMedia('(max-width: 750px)');

    return (
        <HeaderPanelContainer 
            actionsLeft={
                <>
                    <IconButtonBase 
                        variant="pageButton"
                        icon={tried ? <MobiledataOff fontSize="small" /> : <SwapVert fontSize="small" />} 
                        title={tried ? "Enlever le trie" : "Trier"} 
                        disabled={isLoading || isError}
                        action={tried ? () => deleteTrie() : (e) => setAnchorEl(e.currentTarget)} 
                    /> 
                    <IconButtonBase 
                        variant="pageButton"
                        icon={filtered ? <FilterListOff fontSize="small" /> : <FilterList fontSize="small" />} 
                        title={filtered ? "Enlever le filtre" : "Filtrer"}
                        disabled={isLoading || isError}
                        action={filtered ? () => deleteFiltre() : () => setOpenFiltre(true)} 
                    /> 
                </>
            }
            actionsRight={
                <>
                    {!isMobile && 
                        <IconButtonBase 
                            variant="pageButton"
                            icon={listed ? <ViewModule fontSize="small" /> : <ViewList fontSize="small" />} 
                            title={listed ? "Panneaux" : "Liste"} 
                            disabled={isLoading || isError}
                            action={() => lister()} 
                        />
                    }       
                    <IconButtonBase 
                        variant="pageButton"
                        icon={<Refresh fontSize="small" />} 
                        title="Rafraichir" 
                        disabled={isLoading}
                        action={() => refetch()} 
                    />                           
                </>
            }
            variant="green07"
        />
    );
}

DocumentsActionsBar.propTypes = {
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    tried: PropTypes.bool,
    filtered: PropTypes.bool,
    listed: PropTypes.bool,
    lister: PropTypes.func,
    deleteFiltre: PropTypes.func,
    deleteTrie: PropTypes.func,
    setAnchorEl: PropTypes.func, 
    setOpenFiltre: PropTypes.func, 
    refetch: PropTypes.func
}

export default DocumentsActionsBar;