import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    typesTravail: []
};

const typTraAppSlice = createSlice({
    name: 'typTraApp',
    initialState: initialState,
    reducers: {
        setTypesTravail: (state, action) => {
            state.typesTravail = action.payload;
        },
        reset: () => initialState
    }
})

const { actions, reducer } = typTraAppSlice
export const { setTypesTravail, reset } = actions
export default reducer
