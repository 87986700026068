import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { fetchSocV1, fetchSocV2 } from "@api/societes";
import { selectCltApp, selectUsrApp } from "@selectors";
import * as socAffHorColActions from "@reducers/societes/socAffHorColReducer";
import * as socAffActColActions from "@reducers/societes/socAffActColReducer";
import * as socOrgActions from "@reducers/societes/socOrgReducer";

export const useSocAffHorCol = (type) => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const dispatch = useDispatch();
    const data = { matricule: stateUser.matricule, utilisateur: stateUser.utilisateurAS400, origine: "G" };

    const query = useQuery(["socAffHorCol"], () => fetchSocV2(data, stateClient.client), {
        retry: false, 
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if(query.data) {
            dispatch(socAffHorColActions.setSocietes(query.data));
        }
    }, [dispatch, query.data, type]);

    return query;
}

export const useSocAffActCol = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const dispatch = useDispatch();
    const data = { matricule: stateUser.matricule, utilisateur: stateUser.utilisateurAS400, origine: "G" };

    const query = useQuery(["socAffActCol"], () => fetchSocV2(data, stateClient.client), {
        retry: false, 
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if(query.data) {
            dispatch(socAffActColActions.setSocietes(query.data));
        }
    }, [dispatch, query.data]);

    return query;
}

export const useSocOrg = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const dispatch = useDispatch();
    const data = { matricule: stateUser.matricule, date: moment().format("DDMMYYYY"), gestionTemps: "N", typePersonnel: "P" };
    const query = useQuery(["socOrg"], () => fetchSocV1(data, stateClient.client), {
        retry: false, 
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if(query.data) {
            dispatch(socOrgActions.setSocietes(query.data));
        }
    }, [dispatch, query.data, query.societes]);

    return query;
}

export const useSocInp = (open) => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const data = { matricule: stateUser.matricule, utilisateur: stateUser.utilisateurAS400, origine: "G" };
    
    return useQuery(["socInp"], () => fetchSocV2(data, stateClient.client), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!open
    });
}
