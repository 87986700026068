import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchTem, fetchDetJouTem, fetchNavTem } from "@api/temps";
import { selectCltApp, selectColTemGesTem, selectUsrApp } from "@selectors";
import * as temGesTemColActions from "@reducers/gestionTemps/temps/temGesTemColReducer";
import * as temGesTemManActions from "@reducers/gestionTemps/temps/temGesTemManReducer";
import { useStateTemGesTem } from "@hooks/gestionTemps/temps/hooksStates";
import { ROLE_UTILISATEUR } from "@constants/userConstants";
import { enqueueSnackbar } from "notistack";

export const useTemGesTem = (origine, matricule, type, date, userType) => {
    const stateClient = useSelector(selectCltApp);    
    const dispatch = useDispatch();
    
    const query = useQuery(['temGesTem', matricule], ({ signal }) => fetchTem(origine, matricule, type, date, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!matricule
    });

    useEffect(() => {
        if(query.data) {
            if(userType === ROLE_UTILISATEUR.COLLABORATEUR) {
                dispatch(temGesTemColActions.setTemps(query.data));
            }
            if(userType === ROLE_UTILISATEUR.MANAGER) {
                dispatch(temGesTemManActions.setTemps(query.data));
            }
        }
    }, [dispatch, query.data, userType]);

    return query;
}

export const useNavTemGesTem = (origine, matricule, type, userType) => {
    const stateClient = useSelector(selectCltApp);
    const { mutateAsync, isLoading, isError } = useMutation(fetchNavTem);
    const dispatch = useDispatch();

    const navigateTemps = async (date) => {
        const dataFormated = {
            origine: origine,
            matricule: matricule,
            type: type,
            date: date,
            client: stateClient.client
        }
        try {
            const response = await mutateAsync(dataFormated)
            if(userType === ROLE_UTILISATEUR.COLLABORATEUR) {
                dispatch(temGesTemColActions.setTemps({
                    date: response.date,
                    semaine: response.semaine,
                    totaux: response.totaux,
                    repas: response.repas,
                    debitCredits: response.debitCredits
                }));
            }
            if(userType === ROLE_UTILISATEUR.MANAGER) {
                dispatch(temGesTemManActions.setTemps({
                    date: response.date,
                    semaine: response.semaine,
                    totaux: response.totaux,
                    repas: response.repas,
                    debitCredits: response.debitCredits
                }));
            }
        } catch(e) {
            enqueueSnackbar("Erreur lors de la récupération des données.", { variant: "error" });
        }
    };

    return { isLoading, isError, navigateTemps }
}

export const useAbsJouTemGesTem = (type, open) => {
    const stateUser = useSelector(selectUsrApp);
    const stateCollaborateurs = useSelector(selectColTemGesTem);
    const stateClient = useSelector(selectCltApp);
    const stateTemps = useStateTemGesTem(type); 
    const matricule = type === ROLE_UTILISATEUR.MANAGER ? stateCollaborateurs?.collaborateurSelected?.matricule : stateUser.matricule;

    return useQuery(['absJouTemGesTem'], ({ signal }) => fetchDetJouTem(matricule, stateTemps.jourSelected, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open
    });
}
