import axios from "axios";
import { urls } from "@constants/appConstants";

export const fetchDan = async (matricule, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetDemandesAbsenceAnnulationCollaborateur"
    };
    const body = {
        matricule: matricule
    };

    const response = await axios.post(urls.getDemandesAbsenceAnnulation, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.demandesAbsences;
};

export const addDan = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostDemandeAnnulationCollaborateur"
    };
    const body = {
        matricule: data.matricule,
        validation: data.validation,
        absencesSelectionnees: data.absencesSelectionnees,
        motif: data.motif
    };
    const response = await axios.post(urls.postDemandeAnnulationAbsence, body, { headers: headers, params: { client: data.client } });
    return response.data.reponseDemandeAnnulation;
};