import { Box, Stack, Typography } from "@mui/material";
import { whiteColor } from "@styles";
import { langs, routesLang50 } from "@constants/langConstants";
import { useTranslation } from "react-i18next";

const LangValue = () => {
    const { t, i18n } = useTranslation("global");

    return (
        <Stack direction="row" spacing={0.5}>
            <Box borderRadius={2} display="flex" justifyContent="center" alignItems="center">
                {routesLang50[i18n.language]}
            </Box>
            <Stack spacing={0.5} flexGrow={1}>
                <Box bgcolor={whiteColor[5]} borderRadius={2} height={34} display="flex" alignItems="center" justifyContent="center">
                    <Typography fontWeight="bold" fontSize="1.2rem">{t("titles.language")}</Typography>
                </Box>
                <Box bgcolor={whiteColor[5]} borderRadius={2} height={34} display="flex" alignItems="center" justifyContent="center">
                    <Typography fontWeight={400}>{langs[i18n.language]}</Typography>
                </Box>
            </Stack>
        </Stack>
    );
}

export default LangValue;