import { HeaderDialogContainer } from "@components/Containers";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ContrainteActions = (props) => {
    const { setOpen, service, societe } = props;
    const { t } = useTranslation("global");

    return (
        <HeaderDialogContainer 
            close={() => setOpen(false)}
            title={t("texts.associate_constraint", { service: service, societe: societe })}
        />
    );
}

ContrainteActions.defaultProps = {
    setOpen: () => {},
    service: "",
    societe: ""
}

ContrainteActions.propTypes = {
    setOpen: PropTypes.func,
    service: PropTypes.string,
    societe: PropTypes.string
}

export default ContrainteActions;