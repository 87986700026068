import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { fetchOrg } from "@api/organigramme";
import * as orgAppActions from "@reducers/organigramme/orgAppReducer";

export const useOrg = (societe, client) => {
    const dispatch = useDispatch();
    const query = useQuery(["org", societe], () => fetchOrg(societe, client), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!societe
    });

    useEffect(() => {
        if(query.data) {
            dispatch(orgAppActions.setPostes(query.data.brut));
        }
    }, [dispatch, query.data]);

    return query;
}

