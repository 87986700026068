import { useSelector } from "react-redux";
import { Paper, Stack, Typography } from "@mui/material";
import { routePolices } from "@constants/policesConstants";
import { getWhiteColor, greenColor } from "@styles";
import { selectUsrApp } from "@selectors";
import { useTranslation } from "react-i18next";

const WelcomeWidgetConfigurationAdd = () => {
    const stateUser = useSelector(selectUsrApp);
    const { t } = useTranslation("global");

    return (
        <Paper sx={styles.paperStyleNoBackground} elevation={0}>
            <Stack spacing={0.5} paddingX={2} height="100%" justifyContent="center">
                <Typography variant="h5" fontFamily={routePolices[stateUser.police]}>
                    {t("texts.hello")} { stateUser.prenom }, 
                </Typography>
                <Typography  variant="h5" fontFamily={routePolices[stateUser.police]}>
                    {t("texts.welcome")}
                </Typography>
            </Stack>
        </Paper>
    );
}

const styles = {
    paperStyleNoBackground: {
        backdropFilter: "blur(3px)",
        backgroundColor: greenColor["02"],
        width: "100%",
        height: "200px",
        borderRadius: 2
    },
    paperStyle: {
        backdropFilter: "blur(3px)",
        backgroundColor: getWhiteColor("0.95"),
        width: "100%",
        height: "200px",
        borderRadius: 2
    }
};

export default WelcomeWidgetConfigurationAdd;