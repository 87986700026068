import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import { Home, Logout } from "@mui/icons-material";
import * as tabAppActions from "@reducers/tabs/tabAppReducer"
import * as userActions from "@reducers/user/usrAppReducer"
import Scrollbars from "react-custom-scrollbars";
import { Menu } from "../Menu";
import { greyColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";
import { selectUsrApp } from "@selectors";
import { ButtonBase } from "@components/Buttons";

const SideBar = () => {    
    const stateUser = useSelector(selectUsrApp)
    const dispatch = useDispatch();
    const { t } = useTranslation("global");
    const noBackground = stateUser.background === "image0";

    const logout = useCallback(() => {
        dispatch(userActions.logoutUser());
    }, [dispatch]);

    return (
        <Box width={300} bgcolor={noBackground ? greyColor[2] : whiteColor[9]} overflow="auto" borderRadius={2}>
            <Scrollbars autoHide>
                <Box>
                    <Stack spacing={0.5} p={0.5}>
                        <Box>
                            <ButtonBase 
                                variant={noBackground ? "sideBarNoBackgroundPrimary" : "sideBarPrimary"}
                                fullWidth 
                                startIcon={<Home fontSize="small"/>} 
                                alignement="left" 
                                action={() => dispatch(tabAppActions.setActiveTab("ACC010000"))}
                            >
                                {t("navigation.ACC010000")}
                            </ButtonBase>
                        </Box>
                        {stateUser.menu.map((nodes, id) => (<Menu key={`NODE${nodes.action}`} nodes={nodes} id={id} />)) } 
                        <Box>
                            <ButtonBase 
                                variant={noBackground ? "sideBarNoBackgroundError" : "sideBarError"}
                                fullWidth 
                                startIcon={<Logout fontSize="small" />} 
                                alignement="left" 
                                action={logout}
                            >
                                {t("navigation.DEC010000")}
                            </ButtonBase>
                        </Box>
                    </Stack>
                </Box>
            </Scrollbars>
        </Box> 
    );
}

export default SideBar;