import { ChevronLeft, ChevronRight, Event, Hub, ListAlt, OfflinePin, Refresh, Share, SquareFoot } from "@mui/icons-material";
import PropTypes from "prop-types";
import { DatePickerButton, IconButtonBase } from "@components/Buttons";
import { HeaderDialogContainer } from "@components/Containers";
import { useTranslation } from "react-i18next";

const PlanningDialogActions = (props) => {
    const { 
        isLoading, 
        isError, 
        setOpen, 
        allServices,
        affichage,
        precedent,
        suivant,
        selectDate,
        setOpenContraintes,
        toggleAffichage,
        toggleAll,
        refetch 
    } = props;
    const { t } = useTranslation("global");

    return (
        <HeaderDialogContainer 
            actionsLeft={
                <>
                    <IconButtonBase icon={<ChevronLeft fontSize="small" />} title={t("buttons.previous_week")} disabled={isLoading || isError} action={() => precedent()} />
                    <IconButtonBase icon={<ChevronRight fontSize="small" />} title={t("buttons.next_week")} disabled={isLoading || isError} action={() => suivant()} />
                    <DatePickerButton icon={<Event fontSize="small" />} title={t("buttons.select_date")} timeout={600} noClick={isLoading || isError} action={selectDate} />
                </>
            } 
            actionsRight={
                <>
                    {!allServices && <IconButtonBase icon={<SquareFoot fontSize="small" />} title={t("buttons.show_constraints")} disabled={isLoading || isError} action={() => setOpenContraintes(true)} />}
                    <IconButtonBase icon={affichage === "T" ? <OfflinePin fontSize="small" /> : <ListAlt fontSize="small" />} title={affichage === "T" ? t("buttons.validated_absences") : t("buttons.all_absences")} disabled={isLoading || isError} action={() => toggleAffichage()} />
                    <IconButtonBase icon={allServices ? <Share fontSize="small" /> : <Hub fontSize="small" />} title={allServices ? t("buttons.one_service") : t("buttons.all_services")} disabled={isLoading || isError} action={() => toggleAll()} />
                    <IconButtonBase icon={<Refresh fontSize="small" />} title={t("buttons.refresh")} action={() => refetch()} />
                </>
            }
            title={t("titles.planning")}
            close={() => setOpen(false)}
        />
    )
}

PlanningDialogActions.defaultProps = {
    isLoading: true, 
    isError: false,
    setOpen: () => {},
    allServices: true,
    affichage: "V",
    precedent: () => {},
    suivant: () => {},
    selectDate: () => {},
    setOpenContraintes: () => {},
    toggleAffichage: () => {},
    toggleAll: () => {},
    refetch: () => {}
}

PlanningDialogActions.propTypes = {
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool,
    setOpen: PropTypes.func,
    allServices: PropTypes.bool,
    affichage: PropTypes.string,
    precedent: PropTypes.func,
    suivant: PropTypes.func,
    selectDate: PropTypes.func,
    setOpenContraintes: PropTypes.func,
    toggleAffichage: PropTypes.func,
    toggleAll: PropTypes.func,
    refetch: PropTypes.func
}

export default PlanningDialogActions;