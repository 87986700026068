import { Box, Tooltip, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { greyColor } from "@styles";

const TextTooltipContainer = (props) => {
    const { 
        children, 
        bgColor, 
        p, 
        tooltipTitle, 
        tooltipPlacement, 
        height, 
        textDecoration, 
        verticalAlign, 
        horizontalAlign, 
        textSize, 
        textWeight,
        type 
    } = props;

    if(type === "free") {
        return (  
            <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
                <Box bgcolor={bgColor} padding={p} borderRadius={2} height={height} boxSizing="border-box" display="flex" alignItems={verticalAlign} justifyContent={horizontalAlign}>
                    {children}
                </Box>
            </Tooltip>
        );
    }

    return (  
        <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
            <Box bgcolor={bgColor} padding={p} borderRadius={2} height={height} boxSizing="border-box" display="flex" alignItems={verticalAlign} justifyContent={horizontalAlign}>
                <Typography fontSize={textSize} fontWeight={textWeight} sx={{ textDecoration: textDecoration }}>
                    {children}
                </Typography>
            </Box>
        </Tooltip>
    );
}

TextTooltipContainer.defaultProps = {
    children: <></>,
    bgColor: greyColor[0],
    p: 2,
    tooltipTitle: "",
    tooltipPlacement: "top",
    height: null,
    textDecoration: "none",
    verticalAlign: "top", 
    horizontalAlign: "left", 
    textSize: "small", 
    textWeight: "normal",
    type: ""
}

TextTooltipContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.array]),
    bgColor: PropTypes.string,
    p: PropTypes.number,
    tooltipTitle: PropTypes.string,
    tooltipPlacement: PropTypes.string,
    height: PropTypes.string,
    textDecoration: PropTypes.string,
    verticalAlign: PropTypes.string, 
    horizontalAlign: PropTypes.string, 
    textSize: PropTypes.string, 
    textWeight: PropTypes.string,
    type: PropTypes.string
}

export default TextTooltipContainer;