import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppRoute from "./views/AppRoutes";
import { store, persistor } from "./store/store";
import { greenColor, redColor, whiteColor } from "./styles";
import "./App.css";
import { SnackbarError, SnackbarSuccess, SnackbarWarning, SnackbarWarningNotificationDemandesAbsence, SnackbarWarningNotificationDemandesRegularisation } from "@components/SnackbarVariants";
import 'react-perfect-scrollbar/dist/css/styles.css';
import { I18nextProvider } from "react-i18next";
import i18next from "@translations/config";

const queryClient = new QueryClient();

const theme = createTheme({
    palette: {
        primary: {
            main: "rgba(49, 133, 156, 1)"
        },
        action: {
            main: "rgba(0, 0, 0, 0.1)",
            second: "rgba(0, 0, 0, 0.2)",
            third: "rgba(0, 0, 0, 0.3)",
            fourth: "rgba(0, 0, 0, 0.4)",
            fifth: "rgba(0, 0, 0, 0.5)",
            sixth: "rgba(0, 0, 0, 0.6)",
            seventh: "rgba(0, 0, 0, 0.7)",
            eight: "rgba(0, 0, 0, 0.8)",
            ninth: "rgba(0, 0, 0, 0.9)",
            tenth: "rgba(0, 0, 0, 1)",
        },
        grey1: {
            main: "rgba(0, 0, 0, 0.1)",
        },
        greyLight: {
            main: "rgba(211,211,211,0.5)"
        },
        badgeGrey: {
            main: "rgba(0, 0, 0, 0.7)",
        }, 
        
        buttonGreen: {
            main: "rgba(49, 133, 156, 0.3)",
            second: "rgba(49, 133, 156, 1)"
        },
        buttonYellow: {
            main: "rgba(232, 161, 7, 0.3)",
            second: "rgba(232, 161, 7, 1)"
        },
        buttonYellowHover: {
            main: "rgba(232, 161, 7, 0.5)",
        },
        buttonGreenHover: {
            main: "rgba(49, 133, 156, 0.5)",
        },
        iconSuccess: {
            main: "rgba(49, 133, 156, 0.7)",
        },
        buttonRed: {
            main: "rgba(255,0,0,0.2)",
            second: "rgba(255,0,0,0.5)"
        },
        buttonRedHover: {
            main: "rgba(255,0,0,0.4)"
        },
        buttonRedLigth: {
            main: "rgba(255,0,0,0.2)"
        },
        iconError: {
            main: "rgba(255,0,0,0.7)"
        },
        
    },
    components: {
        MuiAutocomplete: {
            styleOverrides: {           
                paper: {
                    borderRadius: 8
                },
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    backgroundColor: whiteColor[9],
                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: greenColor["1"],
                    },
                    [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: greenColor["1"],
                    }
                },
                notchedOutline: {
                    borderColor: greenColor["05"],
                },
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& label.Mui-focused': {
                        color: greenColor["1"],
                    },
                },
                '&.Mui-error': {
                    borderColor: redColor[2]
                }
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 8
                }
            }
        }
    }

});

const App = () => { 
    return(
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <I18nextProvider i18n={i18next} defaultNS={"global"}>
                        <ThemeProvider theme={theme}>
                            <SnackbarProvider 
                                maxSnack={3} 
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                                Components={{ 
                                    error: SnackbarError, 
                                    success: SnackbarSuccess, 
                                    warning: SnackbarWarning, 
                                    warningDemandesAbsence: SnackbarWarningNotificationDemandesAbsence, 
                                    warningDemandesRegularisation: SnackbarWarningNotificationDemandesRegularisation 
                                }}
                            >
                                <AppRoute/>
                            </SnackbarProvider>
                        </ThemeProvider>
                    </I18nextProvider>
                </PersistGate>
            </Provider>
        </QueryClientProvider>
    );
}

export default App;
