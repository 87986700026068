import { useState } from "react";
import { Box } from "@mui/material";
import { MobileDatePicker, LocalizationProvider, frFR } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import PropTypes from "prop-types";
import IconDatePickerButton from "./IconDatePickerButton";
import { whiteColor } from "@styles";

const DatePickerLabelizedButtonMobile = (props) => {
    const { icon, title, placement, timeout, noClick, minDate, date, action } = props;
    const [open, setOpen] = useState(false);

    return (  
        <Box display={"inline-flex"} borderRadius={20} bgcolor={whiteColor[5]} alignItems={"center"}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"frFR"} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                <MobileDatePicker
                    slots={{ field: IconDatePickerButton }}
                    slotProps={{ field: { icon, title, placement, timeout, noClick, setOpen } }}
                    open={open}
                    minDate={minDate}
                    views={["year", "month", "day"]}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    onAccept={action}
                />
            </LocalizationProvider>
            <Box pr={2} component={"p"} m={0} fontSize="small" fontWeight={"bold"}>{date}</Box>
        </Box>
    );
}

DatePickerLabelizedButtonMobile.defaultProps = {
    icon: <></>, 
    title: "", 
    placement: "bottom", 
    timeout: 200,
    noClick: false,
    minDate: null,
    date: "",
    action: () => {} 
}

DatePickerLabelizedButtonMobile.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]), 
    title: PropTypes.string, 
    placement: PropTypes.string, 
    timeout: PropTypes.number,
    noClick: PropTypes.bool,
    minDate: PropTypes.object,
    date: PropTypes.string,
    action: PropTypes.func 
}

export default DatePickerLabelizedButtonMobile;