import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux"
import PropTypes from "prop-types";
import { selectPlaGlo } from "@selectors";
import { DialogContainer } from "@components/Containers";
import ServicesActions from "./Actions/ServicesActions";
import ServicesContents from "./Contents/ServicesContents";

const ServicesDialog = (props) => {
    const { open, setOpen, setServicesSelected, setServicesNonSelected } = props;
    const statePlaGlo = useSelector(selectPlaGlo);
    const [servicesSelectedInterne, setServicesSelectedInterne] = useState([])
    
    useEffect(() => {
        if(open) {
            setServicesSelectedInterne(statePlaGlo.servicesSelected);
        }
    }, [open, statePlaGlo.servicesSelected]);

    const handleCheckService = useCallback((event) => {
        let updatedService = [...servicesSelectedInterne];
        const services = statePlaGlo.servicesSelected.concat(statePlaGlo.servicesNonSelected)
        const service = services.find((serv) => serv.id === event.target.value)
        if(event.target.checked) {
            updatedService.push(service);
        } else {
            const id = servicesSelectedInterne.findIndex((item) => item.id === event.target.value);
            updatedService.splice(id, 1);
        }
        setServicesSelectedInterne(updatedService);
    }, [servicesSelectedInterne, statePlaGlo.servicesNonSelected, statePlaGlo.servicesSelected]);
    
    const valider = useCallback(() => {
        const services = statePlaGlo.servicesSelected.concat(statePlaGlo.servicesNonSelected)
        let servicesSelected = [];
        let servicesNonSelected = [];
        services.forEach((element) => {
            if(servicesSelectedInterne.some((item) => item.id === element.id)) {
                servicesSelected.push(element);
            } else {
                servicesNonSelected.push(element);
            }
        });
        setServicesSelected(servicesSelected);
        setServicesNonSelected(servicesNonSelected);
        setOpen(false);
    }, [statePlaGlo.servicesSelected, statePlaGlo.servicesNonSelected, setServicesSelected, setServicesNonSelected, setOpen, servicesSelectedInterne]);

    return (
        <DialogContainer open={open} placement="top" taille="sm">
            <ServicesActions 
                setOpen={setOpen}
                valider={valider}
            />
            <ServicesContents 
                services={statePlaGlo.services}
                servicesSelectedInterne={servicesSelectedInterne}
                handleCheckService={handleCheckService}
            />
        </DialogContainer>
    );
}

ServicesDialog.defaultProps = {
    open: false, 
    setOpen: () => {}, 
    setServicesSelected: () => {}, 
    setServicesNonSelected: () => {}
}

ServicesDialog.propTypes = {
    open: PropTypes.bool, 
    setOpen: PropTypes.func, 
    setServicesSelected: PropTypes.func, 
    setServicesNonSelected: PropTypes.func
}


export default ServicesDialog;