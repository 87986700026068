import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Grid, IconButton, Stack } from "@mui/material";
import { Check, Close, FilterListOff } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import * as colAffHorColActions from "@reducers/collaborateurs/colAffHorColReducer";
import * as colAffActColActions from "@reducers/collaborateurs/colAffActColReducer";
import { useStateCollaborateurs } from "@hooks/collaborateurs/hooksStates";
import { distinct, filter, map, select, tidy } from "@tidyjs/tidy";
import { TYPES_COLLABORATEURS } from "@constants/collaborateursConstants";
import { filtrerFwgtp2 } from "../utils";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";
import { AutocompleteInput, AutocompleteTableInput } from "@components/Inputs";
import { MenuFiltreContainerMobile } from "@components/Containers";

const defaultValues = {
    matricule: null,
    nom: null,
    badge: null,
    etablissement: null,
    direction: null,
    service: null,
    secteur: null,
    typePersonnel: null,
    poste1: null,
    poste2: null,
    poste3: null,
    poste4: null,
    controleHoraire: null,
    presentADate: null,
    metier: null,
    horaire: null,
    equipe: null,
    activite: null,
    categorieProfessionnelle: null
};

const headerOptions = [
    {id: 1, title: "Code", xs: 2, key: "value"},
    {id: 2, title: "Désignation", xs: 10, key: "libelle"}
];

const optionsBooleens = [
    {id: 1, value: "O", label: "Oui"},
    {id: 2, value: "N", label: "Non"}
];

const optionsTypeCollaborateurs = [
    {id: 1, value: "P", label: "Salarié"},
    {id: 2, value: "I", label: "Intérimaire"}
];

const labelsMapping = (option) => option.libelle ? `${option.value} - ${option.libelle}` : "";

const CollaborateursFwgtp2Menu = (props) => {
    const { type, open, close } = props;
    const dispatch = useDispatch();
    const methods = useForm({ mode: "all", defaultValues: defaultValues });
    const stateCollaborateurs = useStateCollaborateurs(type);
    const [noms, setNoms] = useState([]);
    const [matricules, setMatricules] = useState([]);
    const [badges, setBadges] = useState([]);
    const [etablissements, setEtablissements] = useState([]);
    const [directions, setDirections] = useState([]);
    const [services, setServices] = useState([]);
    const [secteurs, setSecteurs] = useState([]);
    const [poste1, setPoste1] = useState([]);
    const [poste2, setPoste2] = useState([]);
    const [poste3, setPoste3] = useState([]);
    const [poste4, setPoste4] = useState([]);
    const [metiers, setMetiers] = useState([]);
    const [horaires, setHoraires] = useState([]);
    const [equipes, setEquipes] = useState([]);
    const [activites, setActivites] = useState([]);
    const [categorieProfessionnelles, setCategorieProfessionnelles] = useState([]);

    useEffect(() => {
        if(stateCollaborateurs.collaborateurs) {
            const nomsOptions = tidy(stateCollaborateurs.collaborateurs, select("prenomNom"), map((d, id) => ({id: id + 1, value: d.prenomNom, label: d.prenomNom})), filter(d => d.prenomNom !== ""));
            const matriculesOptions = tidy(stateCollaborateurs.collaborateurs, select("matricule"), map((d, id) => ({id: id + 1, value: d.matricule, label: d.matricule})), filter(d => d.matricule !== ""));
            const badgesOptions = tidy(stateCollaborateurs.collaborateurs, select("badge"), map((d, id) => ({id: id + 1, value: d.badge, label: d.badge})), filter(d => d.badge !== ""));
            const etablissementsOptions = tidy(stateCollaborateurs.collaborateurs, select(["codeEtablissement", "libelleEtablissement"]), map((d, id) => ({id: id + 1, value: d.codeEtablissement, libelle: d.libelleEtablissement})), distinct(["value", "libelle"]), filter(d => d.value !== ""));
            const directionsOptions = tidy(stateCollaborateurs.collaborateurs, select(["codeDirection", "libelleDirection"]), map((d, id) => ({id: id + 1, value: d.codeDirection, libelle: d.libelleDirection})), distinct(["value", "libelle"]), filter(d => d.value !== ""));
            const servicesOptions = tidy(stateCollaborateurs.collaborateurs, select(["codeService", "libelleService"]), map((d, id) => ({id: id + 1, value: d.codeService, libelle: d.libelleService})), distinct(["value", "libelle"]), filter(d => d.value !== ""));
            const secteursOptions = tidy(stateCollaborateurs.collaborateurs, select(["codeSecteur", "libelleSecteur"]), map((d, id) => ({id: id + 1, value: d.codeSecteur, libelle: d.libelleSecteur})), distinct(["value", "libelle"]), filter(d => d.value !== ""));
            const poste1Options = tidy(stateCollaborateurs.collaborateurs, select(["codePoste1", "libellePoste1"]), map((d, id) => ({id: id + 1, value: d.codePoste1, libelle: d.libellePoste1})), distinct(["value", "libelle"]), filter(d => d.value !== ""));
            const poste2Options = tidy(stateCollaborateurs.collaborateurs, select(["codePoste2", "libellePoste2"]), map((d, id) => ({id: id + 1, value: d.codePoste2, libelle: d.libellePoste2})), distinct(["value", "libelle"]), filter(d => d.value !== ""));
            const poste3Options = tidy(stateCollaborateurs.collaborateurs, select(["codePoste3", "libellePoste3"]), map((d, id) => ({id: id + 1, value: d.codePoste3, libelle: d.libellePoste3})), distinct(["value", "libelle"]), filter(d => d.value !== ""));
            const poste4Options = tidy(stateCollaborateurs.collaborateurs, select(["codePoste4", "libellePoste4"]), map((d, id) => ({id: id + 1, value: d.codePoste4, libelle: d.libellePoste4})), distinct(["value", "libelle"]), filter(d => d.value !== ""));
            const metiersOptions = tidy(stateCollaborateurs.collaborateurs, select(["codeMetier", "libelleMetier"]), map((d, id) => ({id: id + 1, value: d.codeMetier, libelle: d.libelleMetier})), distinct(["value", "libelle"]), filter(d => d.value !== ""));
            const horairesOptions = tidy(stateCollaborateurs.collaborateurs, select(["codeHoraire", "libelleHoraire"]), map((d, id) => ({id: id + 1, value: d.codeHoraire, libelle: d.libelleHoraire})), distinct(["value", "libelle"]), filter(d => d.value !== ""));
            const equipesOptions = tidy(stateCollaborateurs.collaborateurs, select(["codeEquipe", "libelleEquipe"]), map((d, id) => ({id: id + 1, value: d.codeEquipe, libelle: d.libelleEquipe})), distinct(["value", "libelle"]), filter(d => d.value !== ""));
            const activitesOptions = tidy(stateCollaborateurs.collaborateurs, select(["codeActivite", "libelleActivite"]), map((d, id) => ({id: id + 1, value: d.codeActivite, libelle: d.libelleActivite})), distinct(["value", "libelle"]), filter(d => d.value !== ""));
            const categoriesProfessionnellesOptions = tidy(stateCollaborateurs.collaborateurs, select(["codeCategorieProfessionnelle", "libelleCategorieProfessionnelle"]), map((d, id) => ({id: id + 1, value: d.codeCategorieProfessionnelle, libelle: d.libelleCategorieProfessionnelle})), distinct(["value", "libelle"]), filter(d => d.value !== ""));
            setNoms(nomsOptions);
            setMatricules(matriculesOptions);
            setBadges(badgesOptions);
            setEtablissements(etablissementsOptions);
            setDirections(directionsOptions);
            setServices(servicesOptions);
            setSecteurs(secteursOptions);
            setPoste1(poste1Options);
            setPoste2(poste2Options);
            setPoste3(poste3Options);
            setPoste4(poste4Options);
            setMetiers(metiersOptions);
            setHoraires(horairesOptions);
            setEquipes(equipesOptions);
            setActivites(activitesOptions);
            setCategorieProfessionnelles(categoriesProfessionnellesOptions);
        }
    }, [stateCollaborateurs.collaborateurs]);

    const filtrer = useCallback((data) => {
        const collaborateursBrut = stateCollaborateurs.collaborateurs;
        const collaborateursFiltered = filtrerFwgtp2(data, collaborateursBrut);
        if(type === TYPES_COLLABORATEURS.AFFECTATIONS_HORAIRES_COLLECTIVES) {            
            dispatch(colAffHorColActions.filtrer(collaborateursFiltered));
        }
        if(type === TYPES_COLLABORATEURS.AFFECTATIONS_ACTIVITES_COLLECTIVES) {
            dispatch(colAffActColActions.filtrer(collaborateursFiltered));
        }
    }, [dispatch, stateCollaborateurs.collaborateurs, type]);

    const resetFiltre = useCallback(() => {
        methods.reset();
        if(type === TYPES_COLLABORATEURS.AFFECTATIONS_HORAIRES_COLLECTIVES) {
            dispatch(colAffHorColActions.resetFiltre());
        }
        if(type === TYPES_COLLABORATEURS.AFFECTATIONS_ACTIVITES_COLLECTIVES) {
            dispatch(colAffActColActions.resetFiltre());
        }
    }, [dispatch, methods, type])

    return(
        <MenuFiltreContainerMobile open={open} close={close}>
            <FormProvider {...methods}>
                <Box overflow="auto">
                    <Grid container spacing={0.5} pb={0.5}>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteInput name={"nom"} label="Nom" options={noms} labelized />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteInput name={"matricule"} label="Matricule" options={matricules} labelized />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteInput name={"badge"} label="Badge" options={badges} labelized />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteTableInput name={"etablissement"} label="Etablissement" options={etablissements} headerOptions={headerOptions} labelsMapping={labelsMapping} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteTableInput name={"direction"} label="Direction" options={directions} headerOptions={headerOptions} labelsMapping={labelsMapping} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteTableInput name={"service"} label="Service" options={services} headerOptions={headerOptions} labelsMapping={labelsMapping} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteTableInput name={"secteur"} label="Secteur" options={secteurs} headerOptions={headerOptions} labelsMapping={labelsMapping} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteInput name={"typePersonnel"} label="Type collaborateur" options={optionsTypeCollaborateurs} labelized />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteInput name={"controleHoraire"} label="Contrôle Horaire ?" options={optionsBooleens} labelized />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteInput name={"presentADate"} label="Présent ?" options={optionsBooleens} labelized />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteTableInput name={"horaire"} label="Horaire" options={horaires} headerOptions={headerOptions} labelsMapping={labelsMapping} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteTableInput name={"poste1"} label="Poste 1" options={poste1} headerOptions={headerOptions} labelsMapping={labelsMapping} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteTableInput name={"poste2"} label="Poste 2" options={poste2} headerOptions={headerOptions} labelsMapping={labelsMapping} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteTableInput name={"poste3"} label="Poste 3" options={poste3} headerOptions={headerOptions} labelsMapping={labelsMapping} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteTableInput name={"poste4"} label="Poste 4" options={poste4} headerOptions={headerOptions} labelsMapping={labelsMapping} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteTableInput name={"metier"} label="Métier" options={metiers} headerOptions={headerOptions} labelsMapping={labelsMapping} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteTableInput name={"equipe"} label="Équipe" options={equipes} headerOptions={headerOptions} labelsMapping={labelsMapping} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteTableInput name={"activite"} label="Activité" options={activites} headerOptions={headerOptions} labelsMapping={labelsMapping} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteTableInput name={"categorieProfessionnelle"} label="Catégorie Professionnelle" options={categorieProfessionnelles} headerOptions={headerOptions} labelsMapping={labelsMapping} />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Stack spacing={0.5} direction="row" borderRadius={2} width={"100%"} height={"100%"} display="flex" alignItems="center" justifyContent="center">
                        <IconButton sx={styles.buttonStyleGrey} onClick={() => resetFiltre()}>
                            <FilterListOff fontSize="small" />
                        </IconButton>
                        <IconButton sx={styles.buttonStyleGreen} onClick={() => methods.handleSubmit(filtrer)()}>
                            <Check fontSize="small" />
                        </IconButton>
                        <IconButton sx={styles.buttonStyleRed} onClick={() => close()}>
                            <Close fontSize="small" />
                        </IconButton>
                    </Stack>
                </Box>
            </FormProvider>
        </MenuFiltreContainerMobile>
    );
}

CollaborateursFwgtp2Menu.propTypes = {
    type: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
}

const styles = {
    buttonStyleGrey: {
        boxShadow: "none", 
        backgroundColor: greyColor[0],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: greyColor[2],
            color: whiteColor[9],
        }
    },
    buttonStyleGreen: {
        boxShadow: "none", 
        backgroundColor: greenColor["02"],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: greenColor["03"],
            color: whiteColor[9],
        }
    },
    buttonStyleRed: {
        boxShadow: "none", 
        backgroundColor: redColor[1],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: redColor[2],
            color: whiteColor[9],
        }
    }
}

export default CollaborateursFwgtp2Menu;