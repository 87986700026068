import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack } from "@mui/material";
import { selectAffGesTem } from "@selectors";
import * as affGesTemActions from "@reducers/gestionTemps/affectations/affGesTemReducer"
import { TabContentContainer, TextContainer } from "@components/Containers";
import { greenColor } from "@styles";
import { AffectationsActivitesCollectifMobile, AffectationsActivitesIndividuelMobile, AffectationsHorairesCollectifMobile, AffectationsHorairesIndividuelMobile } from "@components/GestionTemps/Affectations";
import { MenuButton } from "@components/Buttons";
import { Groups, Person } from "@mui/icons-material";

const Affectations = () => {
    const [titreMobile, setTitreMobile] = useState("Horaires Individuels")
    const stateAffectations = useSelector(selectAffGesTem);
    const dispatch = useDispatch();

    const handleTabs = useCallback((_, tab) => {
        let titre = "";
        if(tab === "AFFHOI") {
            titre = "Horaires Individuels";
        }
        if(tab === "AFFHOC") {
            titre = "Horaires Collectifs";
        }
        if(tab === "AFFACI") {
            titre = "Activites Individuelles";
        }
        if(tab === "AFFACC") {
            titre = "Activites Collectives";
        }
        setTitreMobile(titre);
        dispatch(affGesTemActions.setActiveTab(tab));
    }, [dispatch]);

    return (
        <Stack spacing={0.5} borderRadius={2} height="100%" boxSizing="border-box">
            <Stack spacing={1} direction="row" bgcolor={greenColor["015"]} borderRadius={2} p={0.5} height={46} boxSizing={"border-box"} display={"flex"} alignItems="center">
                <Box flexGrow={1} boxSizing="border-box" height="100%">
                    <TextContainer bgColor={greenColor["015"]} textSize={15} p={2} height="100%" verticalAlign="center" horizontalAlign="center" textWeight="bold">
                        {titreMobile}
                    </TextContainer>
                </Box>
                <Box pr={1} pl={0.5}>
                    <MenuButton txtId="BTDABSMOB" options={[
                        {id: 1, icon: <Person fontSize="small" />, label: "Horaires Individuels", action: (e) => handleTabs(e, "AFFHOI")},
                        {id: 2, icon: <Groups fontSize="small" />, label: "Horaires Collectifs", action: (e) => handleTabs(e, "AFFHOC")},
                        {id: 3, icon: <Person fontSize="small" />, label: "Activités Individuelles", action: (e) => handleTabs(e, "AFFACI")},
                        {id: 4, icon: <Groups fontSize="small" />, label: "Activitéd Collectives", action: (e) => handleTabs(e, "AFFACC")},
                    ]} />
                </Box>
            </Stack>
            <TabContentContainer value={stateAffectations.activeTab} id="AFFHOI">
                <AffectationsHorairesIndividuelMobile />
            </TabContentContainer>
            <TabContentContainer value={stateAffectations.activeTab} id="AFFHOC">
                <AffectationsHorairesCollectifMobile /> 
            </TabContentContainer>
            <TabContentContainer value={stateAffectations.activeTab} id="AFFACI">
                <AffectationsActivitesIndividuelMobile />
            </TabContentContainer>
            <TabContentContainer value={stateAffectations.activeTab} id="AFFACC">
                <AffectationsActivitesCollectifMobile />
            </TabContentContainer>
        </Stack>
    );
};

export default Affectations;