import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Stack } from "@mui/material";
import { Add, CalendarMonth, Check, ChevronLeft, CompareArrows, ContentCopy, Event, QueryBuilder, ViewTimeline } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { selectDrgGesTem } from "@selectors";
import * as drgGesTemActions from "@reducers/gestionTemps/regularisations/drgGesTemReducer";
import { useCopRegGesTem } from "@hooks/gestionTemps/regularisations/hooksQueries";
import { useDetDrgTemQryCol, useDrgDemGesTem } from "@hooks/gestionTemps/demandesRegularisations/hooksQueries";
import { useActRegGesTemCol } from "@hooks/activites/hooksQueries";
import { useTypTraRegGesTem } from "@hooks/typesTravail/hooksQueries";
import { DatePickerButton, IconButtonBase, MenuButton } from "@components/Buttons";
import { DialogFullScreenContainer, HeaderDialogContainer, HeaderListContainer, HeaderPanelContainer, TextContainer } from "@components/Containers";
import { TransactionsList } from "@components/App/Transactions";
import { RegularisationsList } from "@components/App/Regularisations";
import { AnomaliesTransactionsAlertesDialog } from "@components/App/Anomalies";
import { DemandeRegularisationForm } from "../Forms";
import { greenColor, whiteColor } from "@styles";
import { TYPES_DEMANDE_REGULARISATION } from "@constants/demandesRegularisationConstants";
import { ROLE_UTILISATEUR } from "@constants/userConstants";

const headersTransactions = [
    {id: 1, label: "Mouvement", xs: 1.5, display: true},
    {id: 2, label: "Date", xs: 1.5, display: true},
    {id: 3, label: "Heure Réelle", xs: 1.5, display: true},
    {id: 4, label: "Heure Ajustée", xs: 1.5, display: true},
    {id: 5, label: "Lecteur", xs: 1, display: true},
    {id: 6, label: "Activité", xs: 1.75, display: true},
    {id: 7, label: "Type Travail", xs: 2, display: true},
    {id: 8, label: "Anomalie", xs: 1.25, display: true}
]

const headersRegularisations = [
    {id: 1, label: "Mouvement", xs: 2, display: true},
    {id: 2, label: "Date", xs: 2, display: true},
    {id: 3, label: "Heure", xs: 2, display: true},
    {id: 4, label: "Activité", xs: 3, display: true},
    {id: 5, label: "Type Travail", xs: 3, display: true}
]

const defaultValues = {
    motif: "",
    regularisations: [
        {mouvement: "", date: null, heure: "", activite: "", typeTravail: "", anomalies: ""},
        {mouvement: "", date: null, heure: "", activite: "", typeTravail: "", anomalies: ""},
        {mouvement: "", date: null, heure: "", activite: "", typeTravail: "", anomalies: ""},
        {mouvement: "", date: null, heure: "", activite: "", typeTravail: "", anomalies: ""},
    ]
}

const DemandeRegularisationDialog = (props) => {
    const { type, open, setOpen, openAnomalieTransaction, openPlagesHoraires, refetch } = props;
    const stateDemandesRegularisation = useSelector(selectDrgGesTem);
    const [mode, setMode] = useState("V");
    const [code, setCode] = useState("");
    const [detailError, setDetailError] = useState("");
    const [openAnomalie, setOpenAnomalie] = useState(false);
    const methods = useForm({ mode: "onChange", defaultValues: defaultValues });
    const { isLoading: isLoadingPostDemandeRegularisation, postDemande } = useDrgDemGesTem();
    const { isLoading: isLoadingActivites, isFetching: isFetchingActivites } = useActRegGesTemCol(open);
    const { isLoading: isLoadingTypesTravail, isFetching: isFetchingTypesTravail } = useTypTraRegGesTem(open, ROLE_UTILISATEUR.COLLABORATEUR);
    const { isLoading: isLoadingDetailRegularisation, isFetching: isFetchingDetailRegularisation, isError: isErrorDetailRegularisation, error: errorDetailRegularisation, refetch: refetchDetailRegularisation } = useDetDrgTemQryCol(open, true);
    const { isLoading: isLoadingCopy, copyTransactions, copyHorairesOrPlanning } = useCopRegGesTem(ROLE_UTILISATEUR.COLLABORATEUR);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const toggleMode = useCallback(() => {
        if(mode === "E") {
            setMode("V");
            methods.reset(defaultValues)
            refetchDetailRegularisation();
            setCode("");
            setDetailError("");
        } else {
            setMode("E");
        }
    }, [methods, mode, refetchDetailRegularisation]);

    const copyTransactionsActionner = useCallback(() => {
        setCode("");
        setDetailError("");
        const { codeRetour, regularisations } = copyTransactions();
        if(codeRetour === 1) {
            methods.resetField("regularisations", defaultValues.regularisations);
            methods.setValue("regularisations", regularisations);
        }
    }, [copyTransactions, methods]);

    const copyHorairesOrPlanningActionner = useCallback(async (type) => {
        setCode("");
        setDetailError("");
        const { codeRetour, regularisations } = await copyHorairesOrPlanning(type);
        if(codeRetour === 1) {
            methods.resetField("regularisations", defaultValues.regularisations);
            methods.setValue("regularisations", regularisations);
        }
    }, [copyHorairesOrPlanning, methods]);

    const selectDate =  useCallback((date) => {
        setCode("");
        setDetailError("");
        dispatch(drgGesTemActions.setDateDemandeRegularisation(moment(date, "DD/MM/YYYY").format("DDMMYYYY")));
        methods.resetField("regularisations");
    }, [dispatch, methods]);

    const postDemandeActionner = useCallback(async (data) => {    
        const { openDAnomalie, codeRetour, details } = await postDemande(data);
        if(codeRetour || openDAnomalie) {
            setOpenAnomalie(openDAnomalie);
            setCode(codeRetour);
            setDetailError(details);
        } else {
            const { isSuccess } = await postDemande(data, "O");
            if(isSuccess) {
                methods.resetField("motif", defaultValues.motif)
                methods.resetField("regularisations", defaultValues.regularisations)
                enqueueSnackbar("Demande de régularisation envoyée avec succès", { variant: "success", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
                setOpen(false);
                setMode("V");
                if(type === TYPES_DEMANDE_REGULARISATION.REGULARISATION) {
                    refetch();
                }
            } else {
                enqueueSnackbar("Une erreur est survenue lors de la demande de régularisation", { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            }
        }
    }, [postDemande, methods, enqueueSnackbar, setOpen, type, refetch]);

    const validateWithAnomalies = useCallback(async (data) => {
        const { isSuccess } = await postDemande(data, "O");
        if(isSuccess) {
            methods.resetField("motif", defaultValues.motif)
            methods.resetField("regularisations", defaultValues.regularisations)
            enqueueSnackbar("Demande de régularisation envoyée avec succès", { variant: "success", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            setOpenAnomalie(false);
            setOpen(false);
            setMode("V");
            refetch();
        } else {
            enqueueSnackbar("Une erreur est survenue lors de la demande de régularisation", { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
        }
    }, [enqueueSnackbar, methods, postDemande, refetch, setOpen]);

    const validateWithAnomaliesToDelete = useCallback(async (data) => {
        const { isSuccess } = await postDemande(data, "O", "DA");
        if(isSuccess) {
            methods.resetField("motif", defaultValues.motif)
            methods.resetField("regularisations", defaultValues.regularisations)
            enqueueSnackbar("Demande de régularisation envoyée avec succès", { variant: "success", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            setOpenAnomalie(false);
            setOpen(false);
            setMode("V");
            refetch();
        } else {
            enqueueSnackbar("Une erreur est survenue lors de la demande de régularisation", { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
        }
    }, [enqueueSnackbar, methods, postDemande, refetch, setOpen])

    const validateWithAnomaliesToKeep = useCallback(async (data) => {
        const { isSuccess } = await postDemande(data, "O", "CA");
        if(isSuccess) {
            methods.resetField("motif", defaultValues.motif)
            methods.resetField("regularisations", defaultValues.regularisations)
            enqueueSnackbar("Demande de régularisation envoyée avec succès", { variant: "success", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
            setOpenAnomalie(false);
            setOpen(false);
            setMode("V");
            refetch();
        } else {
            enqueueSnackbar("Une erreur est survenue lors de la demande de régularisation", { variant: "error", anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
        }
    }, [enqueueSnackbar, methods, postDemande, refetch, setOpen])

    const hanldeValidate = useCallback((type) => {
        setCode("");
        setDetailError("");
        if(type === "C") {
            methods.handleSubmit(validateWithAnomaliesToKeep)
        }
        if(type === "D") {
            methods.handleSubmit(validateWithAnomaliesToDelete);
        }
        if(type === "V") {
            methods.handleSubmit(validateWithAnomalies)();
        }
        setOpenAnomalie(false);
    }, [methods, validateWithAnomalies, validateWithAnomaliesToDelete, validateWithAnomaliesToKeep]);

    const close = useCallback(() => {
        setOpen(false);
        setMode("V");
    }, [setOpen]);

    return (
        <DialogFullScreenContainer open={open}>
            <HeaderDialogContainer 
                actionsLeft={
                    <Stack direction={"row"} spacing={0.5}>
                        <IconButtonBase icon={<ViewTimeline fontSize="small" />} placement="top" title={"Plages horaires"} action={() => openPlagesHoraires()} />
                        {type === TYPES_DEMANDE_REGULARISATION.REGULARISATION && 
                            <DatePickerButton icon={<Event fontSize="small" />} title="Choisir une date" timeout={600} value={stateDemandesRegularisation ? moment(stateDemandesRegularisation.dateDemandeRegularisation, "DMMYYYY") : null} action={selectDate} />
                        }
                    </Stack>
                }   
                title={"Demande de régularisation"} 
                close={() => close()} 
            />
            <Box>
                <Grid spacing={0.5} container>
                    <Grid item xs={5.5}>
                        <Stack spacing={0.5} bgcolor={greenColor["015"]} p={0.5} borderRadius={2}>
                            <HeaderPanelContainer bgColor={whiteColor[5]} title="Transactions" minHeight={30} /> 
                            <HeaderListContainer itemIdReference="TRTMPS" headerItems={headersTransactions} />
                            <TransactionsList 
                                data={stateDemandesRegularisation.transactions} 
                                isLoading={isLoadingDetailRegularisation || isFetchingDetailRegularisation} 
                                isError={isErrorDetailRegularisation}
                                errorMessage={errorDetailRegularisation?.message}
                                openAnomalies={openAnomalieTransaction}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={6.5}>
                        <Stack spacing={1} bgcolor={greenColor["015"]} p={0.5} borderRadius={2}>
                            <HeaderPanelContainer 
                                actionsLeft={
                                    type === TYPES_DEMANDE_REGULARISATION.REGULARISATION ?
                                        (<IconButtonBase icon={<Check fontSize="small" />} title={"Valider"} placement="top" action={methods.handleSubmit(postDemandeActionner)} />)
                                    :
                                        (
                                            <>
                                                <IconButtonBase icon={mode === "V" ? <Add sx={{fontSize: 19.5}} /> : <ChevronLeft fontSize="small" />} placement="top" title={mode === "V" ? "Faire une demande de régularisation" : "Retour"} action={toggleMode} />
                                                {mode === "E" && <IconButtonBase icon={<Check fontSize="small" />} title={"Valider"} placement="top" action={methods.handleSubmit(postDemandeActionner)} />}
                                            </>
                                        )
                                }
                                actionsRight={
                                    type === TYPES_DEMANDE_REGULARISATION.REGULARISATION ?
                                        (
                                            <MenuButton txtId="BTCOPREG" icon={<ContentCopy fontSize="small" />} title="Menu copie" placement="top" options={[
                                                {id: 1, icon: <CompareArrows fontSize="small" />, label: "Copier les mouvements", action: () => copyTransactionsActionner()},
                                                {id: 2, icon: <QueryBuilder fontSize="small" />, label: "Copier les horaires", action: () => copyHorairesOrPlanningActionner("H")},
                                                {id: 3, icon: <CalendarMonth fontSize="small" />, label: "Copier le planning", action: () => copyHorairesOrPlanningActionner("P")}
                                            ]} />
                                        )
                                    :
                                        (
                                            <>
                                                {mode === "E" && <MenuButton txtId="BTCOPREG" icon={<ContentCopy fontSize="small" />} title="Menu copie" placement="top" options={[
                                                    {id: 1, icon: <CompareArrows fontSize="small" />, label: "Copier les mouvements", action: () => copyTransactionsActionner()},
                                                    {id: 2, icon: <QueryBuilder fontSize="small" />, label: "Copier les horaires", action: () => copyHorairesOrPlanningActionner("H")},
                                                    {id: 3, icon: <CalendarMonth fontSize="small" />, label: "Copier le planning", action: () => copyHorairesOrPlanningActionner("P")}
                                                ]} />}
                                            </>
                                        )
                                }
                                bgColor={whiteColor[5]}
                                minHeight={30}
                                title={(type === TYPES_DEMANDE_REGULARISATION.TEMPS && mode === "V") ? "Régularisations" : "Ma demande de régularisation"}
                            />
                            <Stack spacing={0.5}>
                                {(type === TYPES_DEMANDE_REGULARISATION.TEMPS && mode === "V") ?
                                    <>
                                        {(stateDemandesRegularisation?.regularisations[0]?.dateRegularisation && stateDemandesRegularisation?.regularisations[0]?.regularisateur) && 
                                            <TextContainer bgColor={whiteColor[5]} textWeight="bold" p={1}>
                                                Régularisé par {stateDemandesRegularisation?.regularisations[0]?.regularisateur} le {moment(stateDemandesRegularisation?.regularisations[0]?.dateRegularisation, "DMMYYYY").format("DD/MM/YYYY")}
                                            </TextContainer>
                                        }
                                        <Stack spacing={0.5} p={0} borderRadius={2}>
                                            <HeaderListContainer itemIdReference="RETMPS" headerItems={headersRegularisations} />
                                            <RegularisationsList 
                                                data={stateDemandesRegularisation?.regularisations}
                                                isLoading={isLoadingDetailRegularisation || isFetchingDetailRegularisation}
                                                isError={isErrorDetailRegularisation}
                                                errorMessage={errorDetailRegularisation?.message}
                                                anomalies={false}
                                            />  
                                        </Stack> 
                                    </> 
                                :
                                    <Stack spacing={0.5}>
                                        <FormProvider {...methods}>
                                            <DemandeRegularisationForm 
                                                isLoading={isLoadingCopy || isLoadingPostDemandeRegularisation || isLoadingActivites || isFetchingActivites || isLoadingTypesTravail || isFetchingTypesTravail}
                                                code={code} 
                                                detailError={detailError} 
                                            />
                                        </FormProvider>
                                    </Stack>
                                }
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <AnomaliesTransactionsAlertesDialog 
                open={openAnomalie} 
                setOpen={setOpenAnomalie}
                userType={ROLE_UTILISATEUR.COLLABORATEUR} 
                code={code} 
                validate={hanldeValidate} 
                isLoadingAnomalie={isLoadingPostDemandeRegularisation} 
            />
        </DialogFullScreenContainer>
    );
}

DemandeRegularisationDialog.defaultProps = {
    open: false,
    setOpen: () => {},
    openAnomalieTransaction: () => {},
    openPlagesHoraires: () => {},
    refetch: () => {}
}

DemandeRegularisationDialog.propTypes = {
    type: PropTypes.string.isRequired,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    openAnomalieTransaction: PropTypes.func,
    openPlagesHoraires: PropTypes.func,
    refetch: PropTypes.func
}

export default DemandeRegularisationDialog;