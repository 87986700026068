import { Box, Grid, Grow } from "@mui/material";
import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { ItemContainer, ListContainer } from "@components/Containers";
import { greenColor } from "@styles";
import PerfectScrollbar from "react-perfect-scrollbar";
import { IconButtonBase } from "@components/Buttons";

const SocietesFwsociList = (props) => {
    const { data, selectSociete } = props;

    return (
        <PerfectScrollbar> 
            <Box maxHeight="calc(100vh - 194px)">
                <ListContainer spacing={0.5} length={data.length} noDataLabel="Aucune société">
                    {data.map((societe, id) => (
                        <Grow key={`PRS${societe.id}`} in={true} timeout={400+(200*id)}>
                            <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.3}>
                                <Grid container spacing={0.3}>
                                    <ItemContainer firstData data={societe.codeSociete} xs={2} />
                                    <ItemContainer lastData data={societe.libelleSociete} xs={9} />
                                    <Grid item xs={1} textAlign="center" display="flex" alignItems="center" justifyContent="center">
                                        <IconButtonBase variant="white5Grey9White9" icon={<Check fontSize="small" />} title="Selectionner" action={() => selectSociete(societe)} />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grow>
                    ))}
                </ListContainer>
            </Box>
        </PerfectScrollbar>
    );
}

SocietesFwsociList.defaultProps = {
    data: [],
    selectSociete: () => {}
}

SocietesFwsociList.propTypes = {
    data: PropTypes.array,
    selectSociete: PropTypes.func

}

export default SocietesFwsociList;