import { useCallback } from "react";
import { Box, Grid, Stack, TextField } from "@mui/material";
import { useController, useFormContext, useFormState } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { TextContainer } from "@components/Containers";
import { ErrorBase } from "@components/Errors";
import { greyColor, redColor, whiteColor } from "@styles";
import { SocietesHookFormInput } from "@components/Inputs/Societes";
import { EtablissementsHookFormInput } from "@components/Inputs/Etablissements";
import { DirectionsHookFormInput } from "@components/Inputs/Directions";
import { ServicesHookFormInput } from "@components/Inputs/Services";
import { SecteursHookFormInput } from "@components/Inputs/Secteurs";

const AnomaliesCreditForm = () => {
    const { control, watch, resetField } = useFormContext();
    const { field: fieldDebitMaximum } = useController({ name: "debitCredit.debitMaximum", control });
    const { field: fieldCreditMaximum } = useController({ name: "debitCredit.creditMaximum", control });
    const { errors } = useFormState({ control });
    const societeSelected = watch("societe");

    const reset = useCallback(() => {
        resetField("etablissement");
        resetField("direction");
        resetField("service");
    }, [resetField]);

    return (
        <Stack spacing={1.5}>
            <Stack spacing={1}>
                <TextContainer p={1.25} bgColor={whiteColor[5]} horizontalAlign="center" textWeight="bold" textSize={15}>Critères de sélection</TextContainer>
                {(errors["debitCredit"]) && <ErrorBase>Renseigner au moins un seuil débit/crédit</ErrorBase>}
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <SocietesHookFormInput action={reset} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <EtablissementsHookFormInput codeSociete={societeSelected?.codeSociete} />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <DirectionsHookFormInput codeSociete={societeSelected?.codeSociete} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ServicesHookFormInput codeSociete={societeSelected?.codeSociete} />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <SecteursHookFormInput />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <NumericFormat 
                                fullWidth 
                                name={"debitCredit.debitMaximum"} 
                                label={"Débit Maximum"} 
                                size="small"
                                getInputRef={fieldDebitMaximum.ref} 
                                isAllowed={(values) => {
                                    const {formattedValue, floatValue} = values; 
                                    return formattedValue === "" || (floatValue >= 0 && floatValue <= 999.99) 
                                }} 
                                value={fieldDebitMaximum.value} 
                                inputProps={{maxLength: 6}} 
                                error={errors["debitCredit.debitMaximum"]} 
                                decimalSeparator="," 
                                decimalScale={2} 
                                customInput={TextField} 
                                onChange={fieldDebitMaximum.onChange} 
                                sx={styles.numberInputStyle} 
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <NumericFormat 
                                fullWidth 
                                name={"debitCredit.creditMaximum"}
                                label={"Crédit Maximum"}
                                size="small"   
                                getInputRef={fieldCreditMaximum.ref} 
                                isAllowed={(values) => {
                                    const {formattedValue, floatValue} = values; 
                                    return formattedValue === "" || (floatValue >= 0 && floatValue <= 999.99) 
                                }} 
                                value={fieldCreditMaximum.value} 
                                inputProps={{maxLength: 6}} 
                                error={errors["debitCredit.creditMaximum"]} 
                                decimalSeparator="," 
                                decimalScale={2} 
                                customInput={TextField} 
                                onChange={fieldCreditMaximum.onChange} 
                                sx={styles.numberInputStyle} 
                            />

                        </Grid>
                    </Grid>
                </Box>
            </Stack>
        </Stack>
    );
}

const styles = {
    autocompleteStyle: {
        borderRadius: 2,
        backgroundColor: whiteColor[5],
        "& .MuiOutlinedInput-root": { carretColor: greyColor[5], color: greyColor[9],
            "& fieldset": { color: greyColor[5], borderRadius: 2 },
            "&.Mui-error": {
                borderRadius: 2,
                backgroundColor: redColor[2],
            }
        }
    },
    numberInputStyle: {
        borderRadius: 2,
        backgroundColor: whiteColor[5],
        "& .MuiOutlinedInput-root": { carretColor: greyColor[5], color: greyColor[9],
            "& fieldset": { color: greyColor[5], borderRadius: 2 },
            "&.Mui-error": {
                borderRadius: 2,
                backgroundColor: redColor[2],
            }
        }
    }
}

export default AnomaliesCreditForm;