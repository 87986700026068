import { Box, Grid, Stack } from "@mui/material";
import moment from "moment";
import { useMedia } from "react-use";
import { Demandes } from "./Demandes";
import { greenColor, whiteColor } from "@styles";
import { TabContentContainer, TextContainer } from "@components/Containers";
import { MenuButton } from "@components/Buttons";
import { Assessment, CalendarMonth, HighlightOff, History, Surfing } from "@mui/icons-material";
import { useCallback, useState } from "react";
import { CODE_TABS_ABSENCES_MOBILE } from "@constants/tabsConstants";
import DemandesAbsence from "@components/GestionAbsences/Collaborateur/Demandes/Absences/DemandesAbsence";
import DemandesAnnulation from "@components/GestionAbsences/Collaborateur/Demandes/Annulations/DemandesAnnulation";
import { Calendrier, CalendrierMobile } from "./Calendrier";
import { Soldes, SoldesMobile } from "./Soldes";
import { HistoriqueDemandes, HistoriqueDemandesMobile } from "./HistoriqueDemandes";
import PerfectScrollbar from "react-perfect-scrollbar";

moment.locale('fr');

const AbsencesCollaborateur = () => {
    const [activeTab, setActiveTab] = useState(CODE_TABS_ABSENCES_MOBILE.TAB_DEMANDE_ABSENCE);
    const [titreMobile, setTitreMobile] = useState("Demande d'absence")
    const isMobile = useMedia('(max-width: 650px)');

    const handleTabs = useCallback((_, tab) => {
        let titre = "";
        if(tab === CODE_TABS_ABSENCES_MOBILE.TAB_DEMANDE_ABSENCE) {
            titre = "Demande d'absence";
        }
        if(tab === CODE_TABS_ABSENCES_MOBILE.TAB_DEMANDE_ANNULATION_ABSENCE) {
            titre = "Demande d'annulation";
        }
        if(tab === CODE_TABS_ABSENCES_MOBILE.TAB_CALENDRIER) {
            titre = "Calendrier";
        }
        if(tab === CODE_TABS_ABSENCES_MOBILE.TAB_SOLDES) {
            titre = "Soldes";
        }
        if(tab === CODE_TABS_ABSENCES_MOBILE.TAB_HISTORIQUE_ABSENCE) {
            titre = "Historique des demandes";
        }
        setTitreMobile(titre);
        setActiveTab(tab);
    }, []);

    return (
        isMobile ? 
            <Box bgcolor={whiteColor[9]} p={1} borderRadius={2} boxSizing="border-box" height="100%">       
                <Stack spacing={0.5} height={"100%"}>
                    <Box height={46}>
                        <Stack spacing={1} direction="row" bgcolor={greenColor["015"]} borderRadius={2} p={0.25} height="46px" boxSizing={"border-box"} display={"flex"} alignItems="center">
                            <Box flexGrow={1} boxSizing="border-box" height="100%">
                                <TextContainer bgColor={greenColor["015"]} textSize={15} p={2} height="100%" verticalAlign="center" horizontalAlign="center" textWeight="bold">
                                    {titreMobile}
                                </TextContainer>
                            </Box>
                            <Box pr={0.5}>
                                <MenuButton txtId="BTDABSMOB" options={[
                                    {id: 1, icon: <Surfing fontSize="small" />, label: "Demande d'absence", action: (e) => handleTabs(e, CODE_TABS_ABSENCES_MOBILE.TAB_DEMANDE_ABSENCE)},
                                    {id: 2, icon: <HighlightOff fontSize="small" />, label: "Demande d'annulation", action: (e) => handleTabs(e, CODE_TABS_ABSENCES_MOBILE.TAB_DEMANDE_ANNULATION_ABSENCE)},
                                    {id: 3, icon: <CalendarMonth fontSize="small" />, label: "Calendrier", action: (e) => handleTabs(e, CODE_TABS_ABSENCES_MOBILE.TAB_CALENDRIER)},
                                    {id: 4, icon: <Assessment fontSize="small" />, label: "Soldes", action: (e) => handleTabs(e, CODE_TABS_ABSENCES_MOBILE.TAB_SOLDES)},
                                    {id: 5, icon: <History fontSize="small" />, label: "Historique des demandes", action: (e) => handleTabs(e, CODE_TABS_ABSENCES_MOBILE.TAB_HISTORIQUE_ABSENCE)}
                                ]} />
                            </Box>
                        </Stack>
                    </Box>
                    <TabContentContainer value={activeTab} id={CODE_TABS_ABSENCES_MOBILE.TAB_DEMANDE_ABSENCE}>
                        <DemandesAbsence />
                    </TabContentContainer>
                    <TabContentContainer value={activeTab} id={CODE_TABS_ABSENCES_MOBILE.TAB_DEMANDE_ANNULATION_ABSENCE}>
                        <DemandesAnnulation /> 
                    </TabContentContainer>
                    <TabContentContainer value={activeTab} id={CODE_TABS_ABSENCES_MOBILE.TAB_CALENDRIER}>
                        <CalendrierMobile /> 
                    </TabContentContainer>
                    <TabContentContainer value={activeTab} id={CODE_TABS_ABSENCES_MOBILE.TAB_SOLDES}>
                        <SoldesMobile /> 
                    </TabContentContainer>
                    <TabContentContainer value={activeTab} id={CODE_TABS_ABSENCES_MOBILE.TAB_HISTORIQUE_ABSENCE}>
                        <HistoriqueDemandesMobile /> 
                    </TabContentContainer>
                </Stack>
            </Box>
        :
            <PerfectScrollbar>
                <Grid container item spacing={1}>
                    <Grid item xs={12} sm={12} lg={6}>
                        <Demandes />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                        <Calendrier /> 
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} height={320}>
                        <Soldes />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} height={320}>
                        <HistoriqueDemandes />
                    </Grid>
                </Grid>
            </PerfectScrollbar>
    )
}

export default AbsencesCollaborateur;