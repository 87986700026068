import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Autocomplete, Box, Grid, List, Paper, Popper, Stack, TextField, Typography, autocompleteClasses } from "@mui/material";
import { useController, useFormContext, useFormState } from "react-hook-form";
import { Loader } from "@components/Loaders";
import { TextContainer } from "@components/Containers";
import { useFetchAnoGesTem } from "@hooks/anomalies/hooksQueries";
import { greenColor, greyColor, redColor } from "@styles";

const headerOptionsAnomalies = [
    {id: 1, title: "Code", xs: 2, key: "code"},
    {id: 2, title: "Désignation", xs: 8, key: "libelle"},
    {id: 3, title: "Gravité", xs: 2, key: "gravite"}
];

const OptionsComponent = (props, option) => {
    return (
        <Box key={option.id} component="li" {...props} sx={styles.optionsStyle} borderRadius={2} mb={0.5}>
            <Grid container spacing={0.5}>
                {headerOptionsAnomalies.map((headerOption) => (
                    <Grid item xs={headerOption.xs} key={`AUTOCOPTIONOPT${headerOption.id}`} display="flex" justifyContent="center">
                        <TextContainer bgColor="none" p={0}>{option[headerOption.key]}</TextContainer>
                    </Grid>
                ))}
            </Grid>
        </Box> 
    );
}

const ListboxComponent = (props, isLoading) => {
    return (
        isLoading ?
            <Box py={2}>
                <Loader size={17} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        :
            <Box px={0.5} pb={0}>
                <Box py={0.5}>
                    <Grid container spacing={0.5}>
                        {headerOptionsAnomalies.map((headerOption) => (
                            <Grid item xs={headerOption.xs} key={`AUTOCOPTIONHEAD${headerOption.id}`}>
                                <TextContainer bgColor={greenColor["015"]} p={0.75} height={"100%"} textWeight="bold" horizontalAlign="center" verticalAlign="center" textAlign="center" textDecoration={"none"}>{headerOption.title}</TextContainer>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <List {...props} />
            </Box>
    );
}

const PopperComponent = (props) => {
    return (
        <Popper {...props} placement="bottom-start" />
    );
}

const PaperComponent = (props) => {
    return (
        <Paper {...props} style={{minWidth: "max-content"}}  />
    );
}

const InputComponent = (props, name, label, errors) => {
    return (
        <Stack sx={styles.stackInputStyle}>
            <TextField {...props} error={errors[name]} label={label} />
            {(errors[name]) && 
                <Box sx={styles.boxInputErrorStyle}>
                    <Typography sx={styles.typoInputErrorStyle}>{errors[name].message}</Typography>
                </Box>
            }
        </Stack>
    );
}

const NoOptions = (isError) => {
    return (
        isError ? 
            <Typography fontSize={"small"} color={redColor[9]}>Erreur de récupération des données</Typography> 
        : 
            <Typography fontSize={"small"}>Aucune donnée</Typography>
    );
}

const Loading = () => {
    return (
        <Box>
            <Loader size={17} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
        </Box>
    );
}

const optionsMapping = (option, value) => {
    if(option.id === value.id) {
        return true
    } 
}

const labelsMapping = (option) => option.libelle ? `${option.code} - ${option.libelle}` : "";

const AnomaliesHookFormInput = (props) => {
    const { name, label, action } = props;
    const { control } = useFormContext();
    const { field } = useController({ name: name, control });
    const { errors } = useFormState({ control });
    const [open, setOpen] = useState(false);
    const { data, isLoading, isFetching, isError, refetch } = useFetchAnoGesTem(open);
    const loading = isLoading || isFetching;

    const handleOpen = useCallback(() => {
        refetch();
        setOpen(true)
    }, [refetch, setOpen]);

    const handleAction = useCallback((d) => {
        action(d);
        field.onChange(d);
    }, [action, field]);

    return (
        <Autocomplete 
            {...field} 
            fullWidth 
            label={label} 
            size="small" 
            onOpen={() => handleOpen()}
            onClose={() => setOpen(false)}
            options={data || []}  
            loading={loading}
            loadingText={<Loading />}
            noOptionsText={<NoOptions isError={isError} />}
            getOptionLabel={labelsMapping}      
            isOptionEqualToValue={optionsMapping}
            PopperComponent={(props) => PopperComponent(props)}
            ListboxProps={{sx: { [`&.${autocompleteClasses.listbox}`]: {p: 0}}}}
            PaperComponent={(props) => PaperComponent(props)}
            ListboxComponent={(props) => ListboxComponent(props, loading)}
            renderInput={(props) => InputComponent(props, name, label, errors)}
            renderOption={(props, option) =>  OptionsComponent(props, option)}
            onChange={(_, d) => handleAction(d)}
        /> 
    );
}

AnomaliesHookFormInput.defaultProps = {
    name: "anomalie",
    label: "Anomalie",
    action: () => {}
}

AnomaliesHookFormInput.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    action: PropTypes.func
}

const styles = {
    optionsStyle: {
        [`&.${autocompleteClasses.option}`]: {
            px: 0, 
            backgroundColor: greenColor["015"],
            '&:hover': {
                backgroundColor: greenColor["035"]
            },
            '&[aria-selected="true"]': {
                border: "1px solid",
                borderColor: greyColor[2],
                backgroundColor: greenColor["035"]
            } 
        }
    },
    stackInputStyle: {
        borderRadius: 2,
        backgroundColor: redColor[1]
    },
    boxInputErrorStyle: {
        textAlign: "left",
        borderBottomLeftRadius: 8, 
        borderBottomRightRadius: 8,
        padding: 1
    },
    typoInputErrorStyle: {
        fontSize: "small", 
        color: greyColor[5]
    }
}

export default AnomaliesHookFormInput;