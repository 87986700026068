import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Typography, Paper, Stack } from "@mui/material";
import moment from "moment";
import { routePolices } from "@constants/policesConstants";
import { selectUsrApp } from "@selectors";
import { getWhiteColor, greenColor } from "@styles";

const DateWidgetConfigurationAdd = () => {
    const [heure, setHeure] = useState(moment().format("HH:mm"));
    const stateUser = useSelector(selectUsrApp);
    const date = `${moment().format("dddd")} ${moment().format("DD")} ${moment().format("MMMM")} ${moment().format("YYYY")}`;

    useEffect(() => {
        const timer = setTimeout(() => {
            setHeure(moment().format("HH:mm"));
        }, 60000);
        return () => clearTimeout(timer);
    }, [heure]);

    return (
        <Paper sx={styles.paperStyleNoBackground} elevation={0}> 
            <Stack spacing={0.5} height="100%" alignItems="center" justifyContent="center">
                <Typography variant="h6" fontFamily={routePolices[stateUser.police]}>
                    { date }
                </Typography>
                <Typography variant="h6" fontFamily={routePolices[stateUser.police]}>
                    { heure }
                </Typography>
            </Stack>
        </Paper>
    );
}

const styles = {
    paperStyleNoBackground: {
        backdropFilter: "blur(3px)",
        backgroundColor: greenColor["02"],
        width: "100%",
        height: "200px",
        borderRadius: 2
    },
    paperStyle: {
        backdropFilter: "blur(3px)",
        backgroundColor: getWhiteColor("0.95"),
        width: "100%",
        height: "200px",
        borderRadius: 2
    }
};

export default DateWidgetConfigurationAdd;