import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import global_fr from "@translations/fr/global.json";
import global_en from "@translations/en/global.json";
import LanguageDetector from 'i18next-browser-languagedetector';

i18next.use(LanguageDetector).use(initReactI18next).init({
    detection: {
        order: ["localStorage"]
    },
    supportedLngs: ['fr', 'en', 'de', 'pt', 'it', 'es', 'nl'],
    interpolation: { escapeValue: false },
    fallbackLng: 'fr',
    resources: {
        fr: { global: global_fr },
        en: { global: global_en }
    }
});

export default i18next;