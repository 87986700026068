import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { fetchSol, fetchSitSol, fetchMulSol } from "@api/soldes";
import { selectCltApp, selectUsrApp } from "@selectors";
import * as solGesAbsActions from "@reducers/soldes/solGesAbsReducer";

export const useSolApp = (societe, matricule) => {
    const stateClient = useSelector(selectCltApp);
    const dispatch = useDispatch();

    const query = useQuery(["solApp"], ({ signal }) => fetchSol(societe, matricule, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!societe && !!matricule 
    });

    useEffect(() => {
        if(query.data) {
            dispatch(solGesAbsActions.setPeriode(query.data.periode));
        }
    }, [dispatch, query.data]);

    return query;
}

export const useSitSolApp = (matricule, absence, date, open) => {
    const stateClient = useSelector(selectCltApp);

    return useQuery(['sitSolApp', matricule, absence, date], ({ signal }) => fetchSitSol(matricule, absence, date, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!absence && !!date && open
    });
}

export const useMulSolGesAbsMan = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);

    return useQuery(["mulSolGesAbsMan"], ({ signal }) => fetchMulSol(stateUser.matricule, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
    });
}
