import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuFiltreContainer, MenuTrieContainer } from "@components/Containers";
import { Stack } from "@mui/material";
import { whiteColor } from "@styles";
import * as docAppActions from "@reducers/documents/docAppReducer"
import { DocumentsActionsBar } from "@components/Documents/ActionsBar";
import { useDoc } from "@hooks/documents/hooksQueries";
import { selectDocApp } from "@selectors";
import { trierDocApp } from "@components/Documents/tries";
import { Event, HighlightAlt, HistoryEdu } from "@mui/icons-material";
import DocumentsList from "@components/Documents/Lists";
import FiltreDocumentsForm from "@components/Documents/Forms/FiltreDocumentsForm";

const optionsTrie = [
    {id: 1, label: "Par date", keyAsc: "dateAsc", keyDesc: "dateDesc", icon: <Event fontSize="small" />},
    {id: 2, label: "Par nature", keyAsc: "natureAsc", keyDesc: "natureDesc", icon: <HighlightAlt fontSize="small" />},
    {id: 3, label: "Par nom", keyAsc: "nomAsc", keyDesc: "nomDesc", icon: <HistoryEdu fontSize="small" />}
];

const Documents = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openFiltre, setOpenFiltre] = useState(false);
    const { isLoading, isFetching, isError, refetch } = useDoc();
    const stateDocuments = useSelector(selectDocApp);
    const dispatch = useDispatch();

    const lister = useCallback(() => {
        dispatch(docAppActions.lister())
    }, [dispatch]);

    const trier = useCallback((keyTrie) => {
        const data = [...stateDocuments.documents];
        const dataTried = trierDocApp(keyTrie, data);
        dispatch(docAppActions.trier(dataTried));
    }, [dispatch, stateDocuments.documents]);

    const deleteFiltre = useCallback(() => {
        dispatch(docAppActions.deleteFiltre())
    }, [dispatch]);

    const deleteTrie = useCallback(() => {
        dispatch(docAppActions.deleteTrie())
    }, [dispatch]);

    return ( 
        <Stack spacing={0.5} bgcolor={whiteColor[9]} borderRadius={2} p={0.5} height="100%" boxSizing="border-box">
            <DocumentsActionsBar 
                isLoading={isLoading || isFetching}
                isError={isError}
                tried={stateDocuments.tried}
                filtered={stateDocuments.filtered}
                listed={stateDocuments.listed}
                lister={lister}
                deleteFiltre={deleteFiltre}
                deleteTrie={deleteTrie}
                setAnchorEl={setAnchorEl}
                setOpenFiltre={setOpenFiltre}
                refetch={refetch}
            />
            <DocumentsList 
                data={stateDocuments.documentsDisplayed}
                isLoading={isLoading || isFetching}
                isError={isError}
                listed={stateDocuments.listed}
                refetch={refetch}
            />
            <MenuTrieContainer 
                anchorEl={anchorEl} 
                setAnchorEl={setAnchorEl} 
                options={optionsTrie} 
                trier={trier} 
            /> 
            <MenuFiltreContainer open={openFiltre} setOpen={setOpenFiltre}>
                <FiltreDocumentsForm setOpen={setOpenFiltre} />
            </MenuFiltreContainer>
        </Stack> 
    );     
}

export default Documents;