import { useSelector } from "react-redux";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Check, Close, Group, ViewTimeline } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { selectDrgGesTem } from "@selectors";
import { IconButtonBase } from "@components/Buttons";
import { useDetDrgQryMan } from "@hooks/gestionTemps/demandesRegularisations/hooksQueries";
import { DialogContainer, HeaderDialogContainer, HeaderListContainer, HeaderPanelContainer, TextContainer } from "@components/Containers";
import { TransactionsList } from "@components/App/Transactions";
import { RegularisationsList } from "@components/App/Regularisations";
import { heurify } from "@utils";
import { greenColor, redColor, whiteColor, yellowColor } from "@styles";

const headersTransactions = [
    {id: 1, label: "Mouvement", xs: 1.5, display: true},
    {id: 2, label: "Date", xs: 1.5, display: true},
    {id: 3, label: "Heure Réelle", xs: 1.5, display: true},
    {id: 4, label: "Heure Ajustée", xs: 1.5, display: true},
    {id: 5, label: "Lecteur", xs: 1, display: true},
    {id: 6, label: "Activité", xs: 1.75, display: true},
    {id: 7, label: "Type Travail", xs: 2, display: true},
    {id: 8, label: "Anomalie", xs: 1.25, display: true}
]

const headersRegularisations = [
    {id: 1, label: "Mouvement", xs: 2, display: true},
    {id: 2, label: "Date", xs: 2, display: true},
    {id: 3, label: "Heure", xs: 2, display: true},
    {id: 4, label: "Activité", xs: 2.25, display: true},
    {id: 5, label: "Type Travail", xs: 2.25, display: true},
    {id: 6, label: "Anomalie", xs: 1.5, display: true}
]

const colorStatut = {
    A: greenColor["015"],
    R: redColor[1]
}

const DemandeRegularisationReponseDialog = (props) => {
    const { 
        open, 
        setOpen, 
        isLoadingReponse,
        setOpenMotifRefus, 
        openPlagesHoraires,
        setOpenSuivi,
        openAnomalieTransaction,
        openAnomaliesRegularisation,
        accepterDemande
    } = props;
    const stateDemandeRegularisation = useSelector(selectDrgGesTem);
    const { data, isLoading, isFetching, isError, error } = useDetDrgQryMan(true, open);
    
    return (
        <DialogContainer open={open} placement="top" taille="xl">
            <HeaderDialogContainer 
                actionsLeft={
                    <Stack direction={"row"} spacing={0.5}>
                        <IconButtonBase icon={<ViewTimeline fontSize="small" />} placement="top" title={"Plages horaires"} timeout={200} noClick={isLoading || isFetching || isLoadingReponse} action={() => openPlagesHoraires()} />
                        <IconButtonBase icon={<Group fontSize="small" />} title="Suivi" noClick={isLoading || isFetching || isLoadingReponse} action={() => setOpenSuivi(true)} />
                        <IconButtonBase icon={<Check fontSize="small" />} title="Accepter" noClick={isLoading || isFetching || isLoadingReponse} action={() => accepterDemande()} />
                        <IconButtonBase icon={<Close fontSize="small" />} title="Refuser" noClick={isLoading || isFetching || isLoadingReponse} action={() => setOpenMotifRefus(true)} />
                    </Stack>
                }   
                title="Détail d'un demande de régularisation"
                close={() => setOpen(false)}
            />
            <Box>
                <Grid spacing={0.5} container>
                    <Grid item xs={12}>
                        <Box bgcolor={greenColor["045"]} borderRadius={2}>  
                            <Stack spacing={0.5} bgcolor={whiteColor[5]} p={0.5} borderRadius={2}>
                                <Box>
                                    <Grid container spacing={0.5}>
                                        <Grid item xs={12} sm={9}>
                                            <TextContainer bgColor={whiteColor[5]} p={1}>
                                                Demande émise par <Box component={"span"} fontWeight="bold" display="inline">{stateDemandeRegularisation?.demandeRegularisationSelected?.prenomNom}</Box> le <Box component={"span"} fontWeight="bold" display="inline">{moment(stateDemandeRegularisation?.demandeRegularisationSelected?.dateDemande, "YYYYMMDD").format("DD/MM/YYYY")}</Box> à <Box component={"span"} fontWeight="bold" display="inline">{heurify(stateDemandeRegularisation?.demandeRegularisationSelected?.heureDemande, ":", true, true)}</Box>
                                            </TextContainer>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextContainer 
                                                bgColor={colorStatut[stateDemandeRegularisation?.demandeRegularisationSelected?.codeStatut] ? colorStatut[stateDemandeRegularisation?.demandeRegularisationSelected?.codeStatut] : yellowColor[1]} 
                                                p={1} 
                                                horizontalAlign="center" 
                                                textWeight="bold"
                                            >
                                                {stateDemandeRegularisation?.demandeRegularisationSelected?.libelleStatut}
                                            </TextContainer>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box bgcolor={whiteColor[5]} borderRadius={2} p={1}>
                                    <Typography component="div" fontSize="small">
                                        Régularisation de la journée du <Box component={"span"} fontWeight="bold" display="inline">{moment([stateDemandeRegularisation?.demandeRegularisationSelected?.anneeRattachement, stateDemandeRegularisation?.demandeRegularisationSelected?.moisRattachement - 1, stateDemandeRegularisation?.demandeRegularisationSelected?.jourRattachement]).format("dddd DD MMMM YYYY")}</Box>
                                    </Typography>
                                </Box>
                                {stateDemandeRegularisation?.demandeRegularisationSelected?.observation && 
                                    <TextContainer bgColor={whiteColor[5]} p={1}>
                                        Motif de la demande : {stateDemandeRegularisation?.demandeRegularisationSelected?.observation}
                                    </TextContainer>
                                }
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack spacing={0.5} bgcolor={greenColor["015"]} p={0.5} borderRadius={2}>
                            <HeaderPanelContainer bgColor={whiteColor[5]} title="Transactions" minHeight={30} /> 
                            <HeaderListContainer itemIdReference="TRTMPS" headerItems={headersTransactions} />
                            <TransactionsList 
                                data={data?.transactions} 
                                isLoading={isLoading || isFetching} 
                                isError={isError} 
                                errorMessage={error?.message} 
                                openAnomalies={openAnomalieTransaction} 
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack spacing={0.5} bgcolor={greenColor["015"]} p={0.5} borderRadius={2}>
                            <HeaderPanelContainer bgColor={whiteColor[5]} title="Regularisations" minHeight={30} /> 
                            <HeaderListContainer itemIdReference="RETMPS" headerItems={headersRegularisations} />
                            <RegularisationsList 
                                data={data?.regularisations} 
                                isLoading={isLoading || isFetching} 
                                isError={isError} 
                                errorMessage={error?.message} 
                                openAnomalies={openAnomaliesRegularisation}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </DialogContainer>
    );
}

DemandeRegularisationReponseDialog.defaultProps = {
    open: false, 
    setOpen: () => {}, 
    isLoadingReponse: false,
    setOpenMotifRefus: () => {}, 
    openPlagesHoraires: () => {},
    setOpenSuivi: () => {},
    openAnomalieTransaction: () => {},
    openAnomaliesRegularisation: () => {},
    accepterDemande: () => {}
}

DemandeRegularisationReponseDialog.propTypes = {
    open: PropTypes.bool, 
    setOpen: PropTypes.func, 
    isLoadingReponse: PropTypes.bool,
    setOpenMotifRefus: PropTypes.func, 
    openPlagesHoraires: PropTypes.func,
    setOpenSuivi: PropTypes.func,
    openAnomalieTransaction: PropTypes.func,
    openAnomaliesRegularisation: PropTypes.func,
    accepterDemande: PropTypes.func
}

export default DemandeRegularisationReponseDialog;