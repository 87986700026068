import { useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query"
import { verificationClt } from "@api/security";
import * as cltAppActions from "@reducers/client/cltAppReducer";
import { useEffect } from "react";

export const useCltApp = () => {
    const url = window.location.pathname.split("/");
    const clientUrl = url[1];
    const dispatch = useDispatch();
    const query = useQuery(['cltApp'], () => verificationClt(clientUrl), {
        retry: false, 
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if(query.isSuccess) {
            dispatch(cltAppActions.setClient(clientUrl));
        }
    }, [clientUrl, dispatch, query.isSuccess]);

    return query;
}

