import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    codeSociete: null,
    libelleSociete: null,
    societes: [],
    postes: [],
    uneSociete: false,
}

const orgAppReducer = createSlice({
    name: 'orgApp',
    initialState: initialState,
    reducers: {
        setPostes: (state, action) => {
            state.postes = action.payload;
        },
        reset: () => initialState
    },
})

const { actions, reducer } = orgAppReducer
export const { setPostes, reset } = actions
export default reducer