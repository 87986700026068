import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activeTab: "HEUC",
};

const heuGesTemSlice = createSlice({
    name: "heuGesTem",
    initialState: initialState,
    reducers: {
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        }
    }
})

const { actions, reducer } = heuGesTemSlice
export const { setActiveTab } = actions
export default reducer