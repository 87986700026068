import { useCallback } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { CheckHookFormInput, DateHookFormInput, SelectHookFormInput } from "@components/Inputs";
import { TextContainer } from "@components/Containers";
import { whiteColor } from "@styles";
import { HorairesHookFormInput } from "@components/Inputs/Horaires";
import { SocietesHookFormInput } from "@components/Inputs/Societes";
import { EtablissementsHookFormInput } from "@components/Inputs/Etablissements";
import { DirectionsHookFormInput } from "@components/Inputs/Directions";
import { ServicesHookFormInput } from "@components/Inputs/Services";
import { SecteursHookFormInput } from "@components/Inputs/Secteurs";

const joursSemaine = [
    {id: 1, value: "", label: "Aucun"},
    {id: 2, value: "1", label: "Lundi"},
    {id: 3, value: "2", label: "Mardi"},
    {id: 4, value: "3", label: "Mercredi"},
    {id: 5, value: "4", label: "Jeudi"},
    {id: 6, value: "5", label: "Vendredi"},
    {id: 7, value: "6", label: "Samedi"},
    {id: 8, value: "7", label: "Dimanche"},
]

const HeuresDetailleesForm = () => {
    const { watch, resetField } = useFormContext();
    const societeSelected = watch("societe");
    const dateFin = watch("dateFin");
    const dateDebut = watch("dateFin");

    const reset = useCallback(() => {
        resetField("etablissement");
        resetField("direction");
        resetField("service");
        resetField("horaire");
    }, [resetField]);

    return (
        <Stack spacing={1.5}>
            <Stack spacing={1}>
                <TextContainer p={1.25} bgColor={whiteColor[5]} horizontalAlign="center" textWeight="bold" textSize={15}>Période d'analyse</TextContainer>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <DateHookFormInput name="dateDebut" label={"Date de début"} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateHookFormInput name="dateFin" label={"Date de fin"} minDate={dateDebut} displayError />
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
            <Stack spacing={1}>
                <TextContainer p={1.25} bgColor={whiteColor[5]} horizontalAlign="center" textWeight="bold" textSize={15}>Critères de sélection</TextContainer>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4}>
                            <SocietesHookFormInput action={reset} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <EtablissementsHookFormInput codeSociete={societeSelected?.codeSociete} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <DirectionsHookFormInput codeSociete={societeSelected?.codeSociete} />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4}>
                            <ServicesHookFormInput codeSociete={societeSelected?.codeSociete} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SecteursHookFormInput />
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
            <Stack spacing={1}>
                <TextContainer p={1.25} bgColor={whiteColor[5]} horizontalAlign="center" textWeight="bold" textSize={15}>Options de traitement</TextContainer>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={3}>
                            <Box borderRadius={2} bgcolor={whiteColor[5]} height={40} display="flex" alignItems="center" px={2}>
                                <CheckHookFormInput name="present" label="Collaborateur Présent ?" />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <HorairesHookFormInput name="horaire" label="Horaire" codeSociete={societeSelected?.codeSociete} date={dateFin} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <SelectHookFormInput name="joursSemaine" label="Jour de la semaine" options={joursSemaine} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box borderRadius={2} bgcolor={whiteColor[5]} height={40} display="flex" alignItems="center" px={2}>
                                <CheckHookFormInput name="detailMouvements" label="Détail des mouvements ?" />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
        </Stack>
    );
}

export default HeuresDetailleesForm;