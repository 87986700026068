import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activitesPointage: []
};

const actPoiGesTemSlice = createSlice({
    name: 'actPoiGesTem',
    initialState: initialState,
    reducers: {
        setActivitesPointage: (state, action) => {
            state.activitesRegularisation = action.payload;
        },
        reset: () => initialState
    }
})

const { actions, reducer } = actPoiGesTemSlice
export const { setActivitesPointage, reset } = actions
export default reducer
