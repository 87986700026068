import { useCallback, useState } from "react";
import { Box, Stack } from "@mui/material";
import moment from "moment";
import { selectDrgGesTem, selectTemGesTemCol, selectUsrApp } from "@selectors";
import { useDispatch, useSelector } from "react-redux";
import * as temGesTemColActions from "@reducers/gestionTemps/temps/temGesTemColReducer"
import * as plaHorActions from "@reducers/plagesHoraires/plaHorReducer"
import * as drgGesTemActions from "@reducers/gestionTemps/regularisations/drgGesTemReducer"
import PerfectScrollbar from "react-perfect-scrollbar";
import { TempsActionsBarMobile } from "@components/GestionTemps/Temps/ActionsBars";
import { useNavTemGesTem, useTemGesTem } from "@hooks/gestionTemps/temps/hooksQueries";
import { TempsTableMobile } from "@components/GestionTemps/Temps/Tables";
import { AnomaliesTransactionsDialogMobile, DebitCreditsDialogMobile, DetailJourDialogMobile, HistoriqueTransactionsDialogMobile, InformationsDialogMobile, InformationsJourDialogMobile, RepasDialogMobile, TransactionsDialogMobile } from "@components/GestionTemps/Temps/Dialogs";
import { AnomaliesDialogMobile } from "@components/App/Anomalies";
import { greenColor } from "@styles";
import { PlagesHorairesDialogMobile } from "@components/App/PlagesHoraires";
import { DemandeRegularisationDialogMobile } from "@components/GestionTemps/DemandesRegularisation/Dialogs";
import { TYPES_DEMANDE_REGULARISATION } from "@constants/demandesRegularisationConstants";
import { ROLE_UTILISATEUR } from "@constants/userConstants";

const TempsMobile = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateTemps = useSelector(selectTemGesTemCol);
    const stateDemandesRegularisation = useSelector(selectDrgGesTem);
    const [openRepasDialog, setOpenRepasDialog] = useState(false);
    const [openDebitCreditsDialog, setOpenDebitCreditsDialog] = useState(false);
    const [openHistoriqueTransactionsDialog, setOpenHistoriqueTransactionsDialog] = useState(false);
    const [openTransactionsDialog, setOpenTransactionsDialog] = useState(false);
    const [openDemandeRegularisationsDialog, setOpenDemandeRegularisationsDialog] = useState(false);
    const [openAnomaliesTransactionDialog, setOpenAnomaliesTransactionDialog] = useState(false);
    const [openPlagesHorairesDialog, setOpenPlagesHorairesDialog] = useState(false);
    const [openJourDialog, setOpenJourDialog] = useState(false);
    const [openAllAnomaliesDialog, setOpenAllAnomaliesDialog] = useState(false);
    const [openInformationsDialog, setOpenInformationsDialog] = useState(false);
    const [openInformationsJourDialog, setOpenInformationsJourDialog] = useState(false);
    const [libelleFerie, setLibelleFerie] = useState("");
    const [transaction, setTransaction] = useState(null);
    const { isLoading: isLoadingTemps, isFetching, isError, refetch } = useTemGesTem("M", stateUser.matricule, "P", stateTemps.dateTemps, ROLE_UTILISATEUR.COLLABORATEUR);
    const { isLoading: isLoadingNav, isError: isErrorNav, navigateTemps } = useNavTemGesTem("M", stateUser.matricule, "P", ROLE_UTILISATEUR.COLLABORATEUR);
    const isLoading = isLoadingTemps || isLoadingNav || isFetching;
    const dispatch = useDispatch();

    const precedent = useCallback(async () => {
        const date = moment(stateTemps.dateTemps, "DDMMYYYY").add(-1, "week").format("DDMMYYYY");
        await navigateTemps(date);
    }, [navigateTemps, stateTemps.dateTemps]);

    const suivant = useCallback(async () => {
        const date = moment(stateTemps.dateTemps, "DDMMYYYY").add(+1, "week").format("DDMMYYYY");
        await navigateTemps(date);
    }, [navigateTemps, stateTemps.dateTemps]);

    const selectDate =  useCallback(async (d) => {
        const date = d.format("DDMMYYYY");
        await navigateTemps(date);
    }, [navigateTemps]);

    const toggleHoraire = useCallback(() => {
        dispatch(temGesTemColActions.toggleHoraire());
    }, [dispatch]);

    const handleOpenTransactions = useCallback((jour) => {
        dispatch(temGesTemColActions.selectJour(jour));
        setOpenTransactionsDialog(true);
    }, [dispatch]);

    const handleOpenRegularisations = useCallback((jour) => {
        dispatch(drgGesTemActions.setDateDemandeRegularisation(jour));
        setOpenDemandeRegularisationsDialog(true);
    }, [dispatch]);

    const handleOpenInformationsJour = useCallback((jour, ferie) => {
        dispatch(temGesTemColActions.selectJour(jour));
        setLibelleFerie(ferie);
        setOpenInformationsJourDialog(true);
    }, [dispatch]);

    const handleOpenJour = useCallback((jour) => {
        dispatch(temGesTemColActions.selectJour(jour));
        setOpenJourDialog(true);
    }, [dispatch]);

    const handleOpenAnomaliesTransaction = useCallback((transaction) => {
        setTransaction(transaction);
        setOpenAnomaliesTransactionDialog(true);
    }, []);

    const handleOpenAllAnomalies = useCallback(() => {
        setOpenAllAnomaliesDialog(true);
    }, []);

    const handlePlagesHoraires = useCallback(() => {
        dispatch(plaHorActions.setDatePlagesHoraires(stateDemandesRegularisation.dateDemandeRegularisation));
        setOpenPlagesHorairesDialog(true);
    }, [dispatch, stateDemandesRegularisation.dateDemandeRegularisation])

    return (
        <Stack spacing={0.5} bgcolor={greenColor["015"]} borderRadius={2} p={0.5} height="100%" boxSizing="border-box">
            <Box height={"calc(100% - 50px)"}>
                <Stack spacing={0.5} direction={"row"} height={"100%"}>
                    <Box overflow={"auto"} boxSizing={"border-box"} width={"100%"}>
                        <PerfectScrollbar>
                            <TempsTableMobile 
                                isLoading={isLoading} 
                                userType={ROLE_UTILISATEUR.COLLABORATEUR}
                                openTransactions={handleOpenTransactions} 
                                openRegularisations={handleOpenRegularisations} 
                                openJour={handleOpenJour} 
                                openInformationsJour={handleOpenInformationsJour}
                            />
                        </PerfectScrollbar>
                    </Box>
                </Stack>
            </Box>
            <TempsActionsBarMobile 
                isLoading={isLoading}
                isError={isError || isErrorNav}
                date={stateTemps.dateTemps}
                debitCreditPermanentLibelle={stateTemps.totaux?.debitCredit?.permanent?.libelle}
                debitCreditHebdomadaireLibelle={stateTemps.totaux?.debitCredit?.hebdomadaire?.libelle}
                horaire={stateTemps.horaire}
                precedent={precedent}
                suivant={suivant}
                selectDate={selectDate}
                refetch={refetch}
                toggleHoraire={toggleHoraire}
                setOpenHistoriqueTransactions={setOpenHistoriqueTransactionsDialog}
                setOpenDebitCredits={setOpenDebitCreditsDialog}
                setOpenRepas={setOpenRepasDialog}
                setOpenInformationsDialog={setOpenInformationsDialog}
            />
            <RepasDialogMobile 
                open={openRepasDialog} 
                setOpen={setOpenRepasDialog} 
                userType={ROLE_UTILISATEUR.COLLABORATEUR} 
            />
            <DebitCreditsDialogMobile 
                open={openDebitCreditsDialog} 
                setOpen={setOpenDebitCreditsDialog} 
                userType={ROLE_UTILISATEUR.COLLABORATEUR} 
                origine="M"
            />
            <TransactionsDialogMobile 
                open={openTransactionsDialog} 
                setOpen={setOpenTransactionsDialog} 
                openAnomalies={handleOpenAnomaliesTransaction} 
                userType={ROLE_UTILISATEUR.COLLABORATEUR} 
            />
            <HistoriqueTransactionsDialogMobile 
                open={openHistoriqueTransactionsDialog} 
                setOpen={setOpenHistoriqueTransactionsDialog} 
                openAnomalies={handleOpenAnomaliesTransaction} 
                userType={ROLE_UTILISATEUR.COLLABORATEUR} 
            />
            <AnomaliesTransactionsDialogMobile 
                open={openAnomaliesTransactionDialog} 
                setOpen={setOpenAnomaliesTransactionDialog} 
                transaction={transaction} 
                openAllAnomalies={handleOpenAllAnomalies} 
            />
            <AnomaliesDialogMobile 
                open={openAllAnomaliesDialog} 
                setOpen={setOpenAllAnomaliesDialog} 
            />
            <DetailJourDialogMobile 
                open={openJourDialog} 
                setOpen={setOpenJourDialog} 
                userType={ROLE_UTILISATEUR.COLLABORATEUR} 
            /> 
            <DemandeRegularisationDialogMobile 
                type={TYPES_DEMANDE_REGULARISATION.TEMPS} 
                userType={ROLE_UTILISATEUR.COLLABORATEUR}
                open={openDemandeRegularisationsDialog} 
                setOpen={setOpenDemandeRegularisationsDialog} 
                openTransactions={handleOpenTransactions}
                openPlagesHoraires={handlePlagesHoraires} 
                openAnomalieTransaction={handleOpenAnomaliesTransaction} 
                refetch={refetch} 
            />
            <PlagesHorairesDialogMobile 
                open={openPlagesHorairesDialog} 
                setOpen={setOpenPlagesHorairesDialog} 
                matricule={stateUser.matricule} 
                prenomNom={`${stateUser.nom} ${stateUser.prenom}`} 
            />
            <InformationsDialogMobile 
                open={openInformationsDialog}
                setOpen={setOpenInformationsDialog}
                isLoading={isLoading}
                userType={ROLE_UTILISATEUR.COLLABORATEUR}
            />
            <InformationsJourDialogMobile 
                open={openInformationsJourDialog}
                setOpen={setOpenInformationsJourDialog}
                libelleFerie={libelleFerie}
                userType={ROLE_UTILISATEUR.COLLABORATEUR}
            />
        </Stack>
    )
}

export default TempsMobile;