import { mt } from "@validation";
import moment from "moment";
import { z } from "zod";

export const schemaFilterEntretiens = z.object({
    colonne: z.string().min(1, "Vous devez selectionner une colonne à filtrer"),
    operateur: z.string(),
    date: z.any(),
    txt: z.string()
}).superRefine((data, ctx) => {
    if(data.colonne === "date") {
        if(data.operateur.length === 0) {
            ctx.addIssue({
                code: z.ZodIssueCode.invalid_string,
                message: "Vous devez choisir un opérateur",
                path: ["operateur"]
            })
        }
        if(!moment.isMoment(data.date)) {
            ctx.addIssue({
                code: z.ZodIssueCode.invalid_date,
                message: "Date invalide",
                path: ["date"]
            })
        }
        if(moment.isMoment(data.date)) { 
            if(!data?.date?.isValid()) {
                ctx.addIssue({
                    code: z.ZodIssueCode.invalid_date,
                    message: "Date invalide",
                    path: ["date"]
                })
            }
        }
    }
    if(data.colonne === "societe" || data.colonne === "matricule" || data.colonne === "nom" || data.colonne === "statut") {
        if(data.txt.length === 0) {
            ctx.addIssue({
                code: z.ZodIssueCode.invalid_string,
                message: "Vous devez choisir une valeur pour filtrer",
                path: ["txt"]
            })
        }
    }
});

const dateSchema = z.object({
    datetime: mt,
    statut: z.enum(["E", "P"])
}).superRefine((data, ctx) => {
    if(data?.statut === "E" && data?.datetime?.isSameOrAfter(moment())) {
        ctx.addIssue({
            code: z.ZodIssueCode.invalid_date,
            message: "La date doit être inférieur à aujourd'hui",
            path: ["datetime"]
        })
    }
    if(data?.statut === "P" && data?.datetime?.isSameOrBefore(moment())) {
        ctx.addIssue({
            code: z.ZodIssueCode.invalid_date,
            message: "La date doit être supérieur à aujourd'hui",
            path: ["datetime"]
        })
    }
});

const otherSchema = z.object({   
    interlocuteur:  z.object({ value: z.string(), label: z.string() }, { message: "Responsable obligatoire" }),
    initiative: z.string(),
    sujet: z.string(),
    commentaire: z.string()
})

export const schemaEntretiens = z.intersection(dateSchema, otherSchema)

const schemaChampDesignation = z.object({
    designationSousSection1: z.string(),
    displayDesignation1: z.boolean()
}).refine((data) => {
    return !(data.displayDesignation1 && !!data.designationSousSection1);
}, { message: "Désignation 1 obligatoire", path: ["designationSousSection1"] })

const schemaChampEvaluation = z.object({
    champEvaluation: z.string(),
    displayChampEvaluation: z.boolean()
}).refine((data) => {
    return !(data.displayChampEvaluation && !!data.champEvaluation);
}, { message: "Évaluation obligatoire", path: ["champEvaluation"] })

const schemaChampDate = z.object({
    date: mt.nullable(),
    displayDate: z.boolean()
}).refine((data) => {
    return !(data.displayDate && !data.date);
}, { message: "Date obligatoire", path: ["date"] })

const schemaChampQuestion1 = z.object({
    champQuestion1: z.string(),
    displayChampQuestion1: z.boolean()
}).refine((data) =>  {
    return !(data.displayChampQuestion1 && !data.champQuestion1);
}, { message: "Réponse obligatoire", path: ["champQuestion1"] })

const schemaChampQuestion2 = z.object({
    champQuestion2: z.string(),
    displayChampQuestion2: z.boolean()
}).refine((data) => {
    return !(data.displayChampQuestion2 && !data.champQuestion2);
}, { message: "Réponse obligatoire", path: ["champQuestion2"] })

const schemaChampQuestion3 = z.object({
    champQuestion3: z.string(),
    displayChampQuestion3: z.boolean()
}).refine((data) => {
    return !(data.displayChampQuestion3 && !data.champQuestion3);
}, { message: "Réponse obligatoire", path: ["champQuestion3"] })

const schemaChampQuestion4 = z.object({
    champQuestion4: z.string(),
    displayChampQuestion4: z.boolean()
}).refine((data) => {
    return !(data.displayChampQuestion4 && !data.champQuestion4);
}, { message: "Réponse obligatoire", path: ["champQuestion4"] })

const schemaChampQuestion5 = z.object({
    champQuestion5: z.string(),
    displayChampQuestion5: z.boolean()
}).refine((data) => {
    return !(data.displayChampQuestion5 && !data.champQuestion5);
}, { message: "Réponse obligatoire", path: ["champQuestion5"] })

const schemaChampQuestion6 = z.object({
    champQuestion6: z.string(),
    displayChampQuestion6: z.boolean()
}).refine((data) => {
    return !(data.displayChampQuestion6 && !data.champQuestion6);
}, { message: "Réponse obligatoire", path: ["champQuestion6"] })


export const schemaSections2 = z.object({
    designationSousSection2: z.string().optional(),
    champComplementaire1: z.string().optional(),
    champComplementaire2: z.string().optional(),
    champComplementaire3: z.string().optional(),
    champComplementaire4: z.string().optional(),
    commentaire: z.string().optional() 
})
.and(schemaChampDesignation).and(schemaChampEvaluation).and(schemaChampDate)
.and(schemaChampQuestion1).and(schemaChampQuestion2).and(schemaChampQuestion3)
.and(schemaChampQuestion4).and(schemaChampQuestion5).and(schemaChampQuestion6);

const schemaQuestion1 = z.object({
    reponseQuestion1: z.string(),
    displayQuestion1: z.boolean() 
}).refine((val) => !val.displayQuestion1 || (val.displayQuestion1 && !!val.reponseQuestion1), { message: "Réponse obligatoire", path: ["question1"] });;

const schemaQuestion2 = z.object({
    reponseQuestion2: z.string(),
    displayQuestion2: z.boolean() 
}).refine((val) => !val.displayQuestion2 || (val.displayQuestion2 && !!val.reponseQuestion2), { message: "Réponse obligatoire", path: ["question2"] });

const schemaQuestion3 = z.object({
    reponseQuestion3: z.string(),
    displayQuestion3: z.boolean() 
}).refine((val) => !val.displayQuestion3 || (val.displayQuestion3 && !!val.reponseQuestion3), { message: "Réponse obligatoire", path: ["question3"] });;

const schemaQuestion4 = z.object({
    reponseQuestion4: z.string(),
    displayQuestion4: z.boolean() 
}).refine((val) => !val.displayQuestion4 || (val.displayQuestion4 && !!val.reponseQuestion4), { message: "Réponse obligatoire", path: ["question4"] });;

const schemaQuestion5 = z.object({
    reponseQuestion5: z.string(),
    displayQuestion5: z.boolean() 
}).refine((val) => !val.displayQuestion5 || (val.displayQuestion5 && !!val.reponseQuestion5), { message: "Réponse obligatoire", path: ["question5"] });;

const schemaQuestion6 = z.object({
    reponseQuestion6: z.string(),
    displayQuestion6: z.boolean() 
}).refine((val) => !val.displayQuestion6 || (val.displayQuestion6 && !!val.reponseQuestion6), { message: "Réponse obligatoire", path: ["question6"] });;

export const schemaQuestionnaire = z.object({
    complement1: z.string().optional(),
    complement2: z.string().optional(),
    complement3: z.string().optional(),
    complement4: z.string().optional(),
    date: mt.nullable(),
    commentaire1: z.string().optional(), 
    commentaire2: z.string().optional(), 
    commentaire3: z.string().optional(), 
    commentaire4: z.string().optional(), 
    commentaire5: z.string().optional(), 
    commentaire6: z.string().optional()
}).and(schemaQuestion1).and(schemaQuestion2).and(schemaQuestion3).and(schemaQuestion4).and(schemaQuestion5).and(schemaQuestion6);

export const schemaAjoutQuestionnaire = z.object({
    designation1: z.string().trim().min(1, "Désignation Obligatoire"),
    designation2: z.string().optional(),
    complement1: z.string().optional(),
    complement2: z.string().optional(),
    complement3: z.string().optional(),
    complement4: z.string().optional(),
    date: mt.nullable(),
    commentaire1: z.string().optional(), 
    commentaire2: z.string().optional(), 
    commentaire3: z.string().optional(), 
    commentaire4: z.string().optional(), 
    commentaire5: z.string().optional(), 
    commentaire6: z.string().optional()
}).and(schemaQuestion1).and(schemaQuestion2).and(schemaQuestion3).and(schemaQuestion4).and(schemaQuestion5).and(schemaQuestion6);

const schemaEvaluation = z.object({
    evaluation: z.string(),
    displayEvaluation: z.boolean()
}).superRefine((data, ctx) => {
    if(data.displayEvaluation && !data.evaluation) {
        ctx.addIssue({
            code: z.ZodIssueCode.invalid_string,
            message: "Evaluation obligatoire",
            path: ["evaluation"]
        })
    }
});

const schemaDate = z.object({
    date: z.custom((val) => !val || (moment.isMoment(val) && val.isValid()), "Date invalide"),
    displayDate: z.boolean()
}).superRefine((data, ctx) => {
    if(data.displayDate && !data.date) {
        ctx.addIssue({
            code: z.ZodIssueCode.invalid_date,
            message: "Date obligatoire",
            path: ["date"]
        })
    }
});

export const schemaSousSection = z.object({
    complement1: z.string().optional(),
    complement2: z.string().optional(),
    complement3: z.string().optional(),
    complement4: z.string().optional(),
    commentaire: z.string().optional()
}).and(schemaEvaluation).and(schemaDate);

export const schemaAjoutSousSection = z.object({
    designation1: z.string().trim().min(1, "Désignation Obligatoire"),
    designation2: z.string().optional(),
    complement1: z.string().optional(),
    complement2: z.string().optional(),
    complement3: z.string().optional(),
    complement4: z.string().optional(),
    commentaire: z.string().optional()
}).and(schemaEvaluation).and(schemaDate);

export const schemaAjoutSousSectionCollaborateur = z.object({
    designation1: z.string().trim().min(1, "Désignation Obligatoire"),
    designation2: z.string().optional(),
    commentaire: z.string().optional()
});
