import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Stack, Box, Grid, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import moment from "moment";
import { TypesAbsencesInput } from "@components/Inputs/GestionAbsences";
import { DateHookFormInput, DateHookFormInputMobile, FileHookFormInput, SwitchHookFormInput, TextHookFormInput, TimeHookFormInput } from "@components/Inputs";
import { yellowColor } from "@styles";
import { useTitle, useAmpm, useDateReprise, useHeures, useJourHeure, useLargeur, useMotif, usePieceJointe, usePlage } from "@hooks/gestionAbsences/demandes/hooksUtils";
import { useMedia } from "react-use";
import { useTranslation } from "react-i18next";

const DemandesAbsenceForm = () => {
    const { watch, resetField, setValue } = useFormContext();
    const typeAbsence = watch("typeAbsence");
    const jourHeure = watch("jourHeure");
    const dateDebut = watch("dateDebut");
    const titleDateDebut = useTitle(typeAbsence, jourHeure);
    const largeur = useLargeur(typeAbsence);
    const displayDateReprise = useDateReprise(typeAbsence, jourHeure);
    const displayAmpm = useAmpm(typeAbsence, jourHeure);
    const displayPlage = usePlage(typeAbsence, jourHeure);
    const displayHeures = useHeures(typeAbsence, jourHeure);
    const displayJourHeure = useJourHeure(typeAbsence)
    const { label: labelMotif, obligatoire: requiredMotif, display: displayMotif } = useMotif(typeAbsence)
    const { label: labelPieceJointe, obligatoire: requiredPieceJointe, display: displayPieceJointe } = usePieceJointe(typeAbsence); 
    const isMobile = useMedia('(max-width: 650px)');
    const { t } = useTranslation("global");

    useEffect(() => {
        if(displayAmpm) {
            setValue("type", "ampm");
        }
        if(displayHeures) {
            setValue("type", "heure");
        }
        if(displayPlage) {
            setValue("type", "plage");
        }
        if(displayDateReprise) {
            setValue("type", "date");
        }
        if(requiredMotif) {
            setValue("typeMotif", "O");
        } else {
            setValue("typeMotif", "N");
        }
        if(requiredPieceJointe) {
            setValue("typeFichier", "1");
        } else {
            setValue("typeFichier", "2");
        }
    }, [displayAmpm, displayDateReprise, displayHeures, displayPlage, requiredMotif, requiredPieceJointe, setValue]);

    const resetFieldsDate = useCallback(() => {
        resetField("typeAbsence");
        resetField("dateReprise");
        resetField("ampm");
        resetField("heureDebut");
        resetField("heureReprise");
        resetField("nombreHeures");
        resetField("motif");
        resetField("fichier");
    }, [resetField]);

    const resetFieldsTypeAbsence = useCallback(() => {
        resetField("dateReprise");
        resetField("ampm");
        resetField("heureDebut");
        resetField("heureReprise");
        resetField("nombreHeures");
        resetField("motif");
        resetField("fichier");
    }, [resetField]);
    
    return (
        <Box flexDirection="column" flexGrow={1}>
            <Stack spacing={1.5}>
                <Box bgcolor={yellowColor[2]} p={0.3} borderRadius={2}>
                    <Box bgcolor={yellowColor[3]} px={1} borderRadius={2} display="inline-flex" alignItems="center" width="100%" boxSizing="border-box">
                        <Info fontSize="small" />
                        <Typography p={1} fontSize="small" fontWeight="bold">
                            {t("texts.warning_absences_form")}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Grid container spacing={0.5}>
                        <Grid item xs={12} sm={12} lg={6}>
                            {isMobile ? 
                                <DateHookFormInputMobile name="dateDebut" label={t(titleDateDebut)} minDate={moment().startOf("days")} action={resetFieldsDate} displayError />
                            :    
                                <DateHookFormInput name="dateDebut" label={t(titleDateDebut)} minDate={moment().startOf("days")} action={resetFieldsDate} displayError />
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                            <TypesAbsencesInput name="typeAbsence" label={t("inputs.absence_type_required")} date={dateDebut} action={resetFieldsTypeAbsence} />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={largeur}>
                            {(displayDateReprise) && 
                                <>
                                    {isMobile ? 
                                        <DateHookFormInputMobile name="dateReprise" label={t("inputs.recovery_date_required")} minDate={dateDebut ? dateDebut.startOf("days") : ""} displayError />
                                    :
                                        <DateHookFormInput name="dateReprise" label={t("inputs.recovery_date_required")} minDate={dateDebut ? dateDebut.startOf("days") : ""} displayError />
                                    }
                                </>
                            }
                            {displayAmpm && <SwitchHookFormInput name="ampm" labelLeft={t("inputs.morning")} labelRight={t("inputs.afternoon")} />}
                            {displayPlage &&  
                                <Grid container item spacing={0.5}>
                                    <Grid item xs={6}>
                                        <TimeHookFormInput name="heureDebut" label={t("inputs.start_hour")} displayError />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TimeHookFormInput name="heureReprise" label={t("inputs.recovery_hour")} displayError />
                                    </Grid>
                                </Grid>
                            }
                            {displayHeures && <TimeHookFormInput name="nombreHeures" label={t("inputs.number_hours_required")} displayError />}
                        </Grid> 
                        {displayJourHeure && 
                            <Grid item xs={12} sm={12} lg={4}>
                                <SwitchHookFormInput name="jourHeure" labelLeft={t("inputs.split")} />
                            </Grid>
                        }
                        <Grid item xs={12} sm={12} lg={12}>
                            {displayMotif && <TextHookFormInput name="motif" label={t(labelMotif)} maxLength={50} />}
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                            {displayPieceJointe && <FileHookFormInput name="fichier" label={t(labelPieceJointe)} color="green" />}
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
        </Box>
    );
}

export default DemandesAbsenceForm;