import { Stack } from "@mui/material";
import { Refresh, ViewList, ViewModule } from "@mui/icons-material";
import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { HeaderPanelContainer } from "@components/Containers";
import { useMedia } from "react-use";
import { useTranslation } from "react-i18next";

const SoldesActionsBar = ({
    isLoading = true, 
    isError = false, 
    listed = false, 
    lister = () => {}, 
    refetch = () => {}
}) => {
    const isMobile = useMedia('(max-width: 650px)');
    const { t } = useTranslation("global");

    return (
        <HeaderPanelContainer 
            actionsRight={
                <Stack direction="row" spacing={0.5} justifyContent="right">
                    {(!isMobile) && 
                        <IconButtonBase 
                            variant="white5Grey9White9" 
                            icon={listed ? <ViewModule fontSize="small" /> : <ViewList fontSize="small" />} 
                            title={listed ? t("buttons.panels") : t("buttons.list")} 
                            disabled={isLoading || isError} 
                            action={() => lister()} 
                        />
                    }
                    <IconButtonBase 
                        variant="white5Grey9White9" 
                        icon={<Refresh fontSize="small" />} 
                        title={t("buttons.refresh")} 
                        disabled={isLoading} 
                        action={() => refetch()} 
                    />
                </Stack>
            }   
            title={t("titles.balance")}
            variant="green07"
        />
    );
}

SoldesActionsBar.propTypes = {
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool, 
    listed: PropTypes.bool, 
    lister: PropTypes.func, 
    refetch: PropTypes.func
}

export default SoldesActionsBar;