import { Box, Grid, Grow, Stack } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { HeaderListContainer, ListContainer, PanelListContainer, TextContainer, TextWithLabelContainer } from "@components/Containers";
import { Loader } from "@components/Loaders";
import { ErrorBase } from "@components/Errors";
import { greenColor, greyColor, whiteColor } from "@styles";
import { useMedia } from "react-use";

const headers = [
    {id: 1, label: "Désignation", xs: 4, display: true},
    {id: 2, label: "Valeur", xs: 4, display: true},
    {id: 3, label: "Valeur Maximale", xs: 4, display: true},
];

const CompteursList = (props) => {
    const { data, isLoading, isError, listed, horaire } = props;    
    const isMobile = useMedia('(max-width: 650px)');

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader size={20} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return <ErrorBase>Erreur lors de la récupération des demandes.</ErrorBase>
    }

    return (
        (listed && !isMobile) ? 
            <>
                <HeaderListContainer itemIdReference="COMGT" headerItems={headers} />
                <PerfectScrollbar>
                    <ListContainer spacing={0.5} length={data.length} noDataLabel="Aucune demane">
                        {data.map((compteur, id) => (
                            <Grow key={`COMS${compteur.id}`} in={true} timeout={400+(200*id)}>
                                <Box bgcolor={greenColor["025"]} borderRadius={2} p={0.25}>
                                    <Grid container spacing={0.5}>
                                        <Grid item xs={4}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height="100%">{compteur.libelle}</TextContainer>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height="100%">{horaire ? compteur.valeur.heure : compteur.valeur.chiffre}</TextContainer>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height="100%">{horaire ? compteur.maximum.heure : compteur.maximum.chiffre}</TextContainer>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grow>
                        ))}
                    </ListContainer>
                </PerfectScrollbar>
            </>
        : 
            <PerfectScrollbar>
                <PanelListContainer length={data.length}>
                    {data.map((compteur, id) => (
                        <Grow key={`LCOMS${compteur.id}`} in={true} timeout={400+(200*id)}>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                <Stack spacing={0.5} bgcolor={greenColor["025"]} padding={0.5} borderRadius={2}>
                                    <Box borderRadius={2} p={1} bgcolor={whiteColor[5]}>
                                        <Stack spacing={0}>
                                            <TextWithLabelContainer label="Désignation" value={compteur.libelle} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Valeur" value={horaire ? compteur.valeur.heure : compteur.valeur.chiffre} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Valeur maximale" value={horaire ? compteur.maximum.heure : compteur.maximum.chiffre} weight={{label: "bold", value: "normal"}} />
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grow>
                    ))}
                </PanelListContainer>
            </PerfectScrollbar>
    )
}


CompteursList.defaultProps = {
    data: [],
    listed: false,
    isLoading: true,
    isError: false,
    horaire: false
}

CompteursList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    listed: PropTypes.bool,
    horaire: PropTypes.bool
}

const styles = {
    loaderStyle: {
        height: "100%",
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
    }
}

export default CompteursList;