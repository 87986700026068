import { Stack } from "@mui/material";
import { FilterList, MobiledataOff, SwapVert } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useResetTrieCollaborateurs } from "@hooks/collaborateurs/hooksUtils";
import { IconButtonBase } from "@components/Buttons";
import { HeaderDialogContainer } from "@components/Containers";

const CollaborateursFwmv01ActionsMobile = (props) => {
    const { 
        stateType,
        tried, 
        setOpenFiltre,
        setOpenTri,
        setOpen 
    } = props;
    const resetTrie = useResetTrieCollaborateurs(stateType);

    return (
        <HeaderDialogContainer 
            actionsLeft={
                <Stack direction={"row"} spacing={0.5}>
                    {tried ? 
                        <IconButtonBase icon={<MobiledataOff fontSize="small" />} action={resetTrie} /> 
                    : 
                        <IconButtonBase icon={<SwapVert fontSize="small" />} action={() => setOpenTri(true)} />
                    }
                    <IconButtonBase icon={<FilterList fontSize="small" />} action={() => setOpenFiltre((prevOpen) => !prevOpen)} />
                </Stack>
            }
            p={1}
            close={() => setOpen(false)}
        />
    );
}

CollaborateursFwmv01ActionsMobile.propTypes = {
    stateType: PropTypes.string.isRequired,
    tried: PropTypes.bool.isRequired,
    setOpenFiltre: PropTypes.func.isRequired,    
    setOpenTri: PropTypes.func.isRequired,
    setOpen: PropTypes.func.isRequired
}

export default CollaborateursFwmv01ActionsMobile;