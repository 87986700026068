import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAuth: false, 
    identifiant: "", 
    matricule: "",
    utilisateurAS400: "",
    menu: [],
    prenom: "",
    nom: "",
    email: "",
    token: "",
    configuration: [],
    background: "",
    openConfiguration: false,
    openBackground: false,
    openPolice: false,
    openQuestion: false,
    openPassword: false,
    openQuestionPassword: false,
    openLostPassword: false,
    openMenu: false,
    openMenuParametres: false,
    tentative: 0,
    police: "",
    horloges: [],
    societe: "",
    autorisationEd: false,
    autorisationAc: false,
    autorisationRe: false,
    password: "",
    position: null,
    notifications: [],
    question: "",
    reponse: "",
    onLogin: false,
    droitSalarie: false,
    droitInterimaire: false
};

const usrAppSlice = createSlice({
    name: 'usrApp',
    initialState: initialState,
    reducers: {
        setUser: (state, action) => {
            state.isAuth = true;
            state.identifiant = action.payload.identifiant;
            state.matricule = action.payload.matricule;
            state.utilisateurAS400 = action.payload.utilisateurAS400;
            state.menu = action.payload.menu;
            state.prenom = action.payload.prenom;
            state.nom = action.payload.nom;
            state.email = action.payload.email;
            state.token = action.payload.token;
            state.background = action.payload.background;
            state.societe = action.payload.societe;   
            state.police = action.payload.police;
            state.horloges = action.payload.horloges;
            state.autorisationEd = action.payload.autorisationEd;
            state.autorisationAc = action.payload.autorisationAc;
            state.autorisationRe = action.payload.autorisationRe;
            state.password = action.payload.password;
            state.onLogin = false;
            state.droitSalarie = action.payload.droitSalarie;
            state.droitInterimaire = action.payload.droitInterimaire;
        },
        setIdentity: (state, action) => {
            state.matricule = action.payload.matricule;
            state.identifiant = action.payload.identifiant;
            state.question = action.payload.question;
            state.email = action.payload.email;
        },
        setSecurity: (state, action) => {
            state.password = action.payload.password;
            state.token = action.payload.token;
        },
        setReponse: (state, action) => {
            state.token = action.payload.token;
            state.reponse = action.payload.reponse;
        },
        setOnLogin: (state, action) => {
            state.onLogin = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setSociete: (state, action) => {
            state.societe = action.payload;
        },
        setBackground: (state, action) => {
            state.background = action.payload;
            state.openBackground = false;
        },
        setConfiguration: (state, action) => {
            state.configuration = action.payload;
            state.openConfiguration = false;
        },
        setPolice: (state, action) => {
            state.police = action.payload;
            state.openPolice = false;
        },
        setHorloges: (state, action) => {
            state.horloges = action.payload;
        },
        openConfiguration: (state, action) => {
            state.openConfiguration = action.payload;
        },
        openBackground: (state, action) => {
            state.openBackground = action.payload;
        },
        openPolice: (state, action) => {
            state.openPolice = action.payload;
        },
        openQuestion: (state, action) => {
            state.openQuestion = action.payload;
        },
        openPassword: (state, action) => {
            state.openPassword = action.payload;
        },
        openQuestionPassword: (state, action) => {
            state.openQuestionPassword = action.payload;
        },
        openLostPassword: (state, action) => {
            state.openLostPassword = action.payload;
        },
        openMenu: (state, action) => {
            state.openMenu = action.payload;
        },
        openMenuParametres: (state, action) => {
            state.openMenuParametres = action.payload;
        },
        validerVerification: (state, action) => {
            state.isAuth = action.payload;
        },
        setTentative: (state, action) => {
            state.tentative = action.payload;
        },
        setUserGt: (state, action) => {
            state.userGT = action.payload;
        },
        setPosition: (state, action) => {
            state.position = action.payload;
        },
        setNotifications: (state, action) => {
            state.notifications = action.payload;
        },
        logoutUser: () => initialState,
    }
})

const { actions, reducer } = usrAppSlice
export const { 
    setUser, 
    setIdentity,
    setSecurity,
    setReponse,
    setOnLogin,
    setToken, 
    setSociete, 
    setBackground, 
    setConfiguration, 
    setPolice, 
    setHorloges, 
    openConfiguration, 
    openBackground, 
    openPolice, 
    openQuestion,
    openPassword,
    openQuestionPassword,
    openLostPassword,
    openMenu,
    openMenuParametres,
    validerVerification, 
    setTentative,
    setUserGt, 
    setPosition, 
    setNotifications,
    logoutUser 
} = actions
export default reducer
