import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import { selectDsh } from "@selectors";
import { PointageShortcutConfigurationDelete } from "../Shortcuts/Pointage";
import { ActivitesShortcutConfigurationDelete } from "../Shortcuts/Activites";
import { ButtonBase } from "@components/Buttons";
import { greenColor } from "@styles";
import { Add, Close } from "@mui/icons-material";
import { ShortcutsMenu } from "../Menus";
import { useTranslation } from "react-i18next";

const ShortcutsActivesList = () => {
    const [open, setOpen] = useState(false);
    const stateDashboard = useSelector(selectDsh);
    const { t } = useTranslation("global");

    const routeShortcuts = {
        sPointage: <PointageShortcutConfigurationDelete />,
        sActivite: <ActivitesShortcutConfigurationDelete />,
    }

    return (
        <Stack spacing={0.5} bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
            <Stack direction="row" spacing={0.5} overflow="auto">
                {stateDashboard.shortcutsActives.map((shortcut, id) => (
                    <Box key={`SHORTCUTACT${id}`}>
                        {shortcut && routeShortcuts[shortcut] && 
                            routeShortcuts[shortcut]
                        }
                    </Box>
                ))}
                <Box>
                    <ButtonBase 
                        variant="secondary"
                        startIcon={open ? <Close fontSize="small" /> : <Add fontSize="small" />} 
                        action={() => setOpen((prevOpen) => !prevOpen)}
                    >
                        {open ? t("buttons.close") : t("buttons.add")}
                    </ButtonBase>
                </Box>
            </Stack>
            <ShortcutsMenu open={open} />
        </Stack>
    );
}

export default ShortcutsActivesList;