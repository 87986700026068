import { validateDate } from "@validation";
import Joi from "joi";

const isValidateNature = (obj, helpers) => {
    if(!helpers.state.ancestors[0].absence && !helpers.state.ancestors[0].formation && !helpers.state.ancestors[0].noteFrais) {
        return helpers.error("nature.invalid");
    }
    return obj;
}

export const schemaDelegation = Joi.object({
    absence: Joi.boolean().custom(isValidateNature).messages({"nature.invalid": "Vous devez selectionner au moins une nature de délégation"}),
    formation: Joi.boolean().custom(isValidateNature).messages({"nature.invalid": "Vous devez selectionner au moins une nature de délégation"}),
    noteFrais: Joi.boolean().custom(isValidateNature).messages({"nature.invalid": "Vous devez selectionner au moins une nature de délégation"}),
    dateDebut: validateDate.dateMoment().requis().valide().minDate().messages({ "dateMoment.valide": "Date invalide", "dateMoment.requis": "Date de début obligatoire", "dateMoment.minDate": "La date doit être supérieur à aujourd'hui"  }),
    dateReprise: validateDate.dateMoment().valideOrNull().minDateDebut().messages({ "dateMoment.valide": "Date invalide", "dateMoment.minDateDebut": "La date doit être supérieur à la date de début" }),
    typeDelegation: Joi.string().required(), 
    avertissementMail: Joi.boolean(), 
    delaiAttente: Joi.number().when("typeDelegation", { is: "delaiAttente", then: Joi.number().required(), otherwise: Joi.number().valid("") }).messages({"number.base": "Le délai d'attente doit être un chiffre compris entre 0 et 99"}), 
    destinataire: Joi.object().required().messages({"object.base": "Vous devez choisir un délégataire"})
});

export const schemaDelegationEdit = Joi.object({
    absence: Joi.boolean().custom(isValidateNature).messages({"nature.invalid": "Vous devez selectionner au moins une nature de délégation"}),
    formation: Joi.boolean().custom(isValidateNature).messages({"nature.invalid": "Vous devez selectionner au moins une nature de délégation"}),
    noteFrais: Joi.boolean().custom(isValidateNature).messages({"nature.invalid": "Vous devez selectionner au moins une nature de délégation"}),
    dateDebut: validateDate.dateMoment().requis().valide().messages({ "dateMoment.valide": "Date invalide", "dateMoment.requis": "Date de début obligatoire", "dateMoment.minDate": "La date doit être supérieur à aujourd'hui"  }),
    dateReprise: validateDate.dateMoment().valideOrNull().minDateDebut().messages({ "dateMoment.valide": "Date invalide", "dateMoment.minDateDebut": "La date doit être supérieur à la date de début" }),
    typeDelegation: Joi.string().required(), 
    avertissementMail: Joi.boolean(), 
    delaiAttente: Joi.number().when("typeDelegation", { is: "delaiAttente", then: Joi.number().required(), otherwise: Joi.number().valid("") }).messages({"number.base": "Le délai d'attente doit être un chiffre compris entre 0 et 99"}), 
    destinataire: Joi.object().required().messages({"object.base": "Vous devez choisir un délégataire"})
});


export const schemaFiltreDelegations = Joi.object({
    colonne: Joi.string().required().messages({ "string.empty": "Vous devez choisir un colonne pour filtrer" }),
    operateur: Joi
        .when("colonne", {is: "dateDebut", then: Joi.string().required()})
        .when("colonne", {is: "dateReprise", then: Joi.string().required()}),
    date: Joi
        .when("colonne", {is: "dateDebut", then: validateDate.dateMoment().valide().messages({ "dateMoment.valide": "Date invalide" })})
        .when("colonne", {is: "dateReprise", then: validateDate.dateMoment().valide().messages({ "dateMoment.valide": "Date invalide" })}),
    txt: Joi
        .when("colonne", {is: "nature", then: Joi.string().required().messages({ "string.empty": "Vous devez choisir une valeur pour filtrer" })})
        .when("colonne", {is: "delegataire", then: Joi.object().required().messages({ "object.base": "Vous devez choisir un délégataire pour filtrer" })})
});