import { arrange, asc, tidy } from "@tidyjs/tidy";
import { formateColumnsTableur } from "@utils";
import moment from "moment";
import numeral from "numeral";

numeral.locale('fr');

export const modelizeAnomalies = (worksheet, anomalies) => {
    const columns = [
        { header: 'Société', key: 'societe', width: 10 },
        { header: 'Matricule', key: 'matricule', width: 10 },
        { header: 'Type de Personnel', key: 'typePersonnel', width: 10 },
        { header: 'Nom', key: 'nom', width: 10 },
        { header: 'Prénom', key: 'prenom', width: 10 },
        { header: 'Badge', key: 'badge', width: 10 },
        { header: 'Code Anomalie', key: 'codeAnomalie', width: 10 },
        { header: 'Anomalie : Libellé', key: 'libelleAnomalie', width: 10 },
        { header: 'Gravité', key: 'gravite', width: 10 },
        { header: 'Date Mouvement', key: 'dateMouvement', width: 10 },
        { header: 'Heure Mouvement', key: 'heureMouvement', width: 10 },
        { header: 'Type Mouvement', key: 'typeMouvement', width: 10 },
        { header: 'Régularisation', key: 'regularisation', width: 10 },
        { header: 'Établissement', key: 'codeEtablissement', width: 10 },
        { header: 'Établissement : Libellé', key: 'libelleEtablissement', width: 10 },
        { header: 'Direction', key: 'codeDirection', width: 10 },
        { header: 'Direction : Libellé', key: 'libelleDirection', width: 10 },
        { header: 'Service', key: 'codeService', width: 10 },
        { header: 'Service : Libellé', key: 'libelleService', width: 10 },
        { header: 'Secteur', key: 'codeSecteur', width: 10 },
        { header: 'Secteur : Libellé', key: 'libelleSecteur', width: 10 }
    ];
    worksheet.columns = columns;

    anomalies.forEach((anomalie, id) => {
        const dateString = anomalie.dateMouvement.length < 8 ? "0" + anomalie.dateMouvement : anomalie.dateMouvement;
        const dateMouvement = moment(dateString, "DDMMYYYY").isValid() ? moment(dateString, "DDMMYYYY").format("DD/MM/YYYY") : "";

        const heureString = anomalie.heureMouvement.length < 4 ? "0" + anomalie.heureMouvement : anomalie.heureMouvement;
        const heureMouvement = moment(heureString, "HHmm").isValid() ? moment(heureString, "HHmm").format("HH:mm") : "";

        const row = {
            societe: anomalie.codeSociete,
            matricule: anomalie.matricule,
            typePersonnel: anomalie.type,
            nom: anomalie.nom,
            prenom: anomalie.prenom,
            badge: anomalie.badge,
            codeAnomalie: anomalie.codeAnomalie,
            libelleAnomalie: anomalie.libelleAnomalie,
            gravite: Number(anomalie.gravite),
            dateMouvement: dateMouvement,
            heureMouvement: anomalie.heureMouvement === "0" ? "" : heureMouvement,
            typeMouvement: anomalie.typeMouvement,
            regularisation: anomalie.regularisation,
            codeEtablissement: anomalie.codeEtablissement,
            libelleEtablissement: anomalie.libelleEtablissement,
            codeDirection: anomalie.codeDirection,
            libelleDirection: anomalie.libelleDirection,
            codeService: anomalie.codeService,
            libelleService: anomalie.libelleService,
            codeSecteur: anomalie.codeSecteur,
            libelleSecteur: anomalie.libelleSecteur
        }

        worksheet.addRow(row)
    });
}

export const modelizeAnomaliesCredit = (worksheet, anomalies) => {
    const columns = [
        { header: 'Société', key: 'societe', width: 10 },
        { header: 'Matricule', key: 'matricule', width: 10 },
        { header: 'Type de Personnel', key: 'typePersonnel', width: 10 },
        { header: 'Nom', key: 'nom', width: 10 },
        { header: 'Prénom', key: 'prenom', width: 10 },
        { header: 'Badge', key: 'badge', width: 10 },
        { header: 'Date Mouvement', key: 'dateMouvement', width: 10 },
        { header: 'Crédit Acquis', key: 'creditAcquis', width: 10 },
        { header: 'Crédit en Cours', key: 'creditEnCours', width: 10 },
        { header: 'Établissement', key: 'codeEtablissement', width: 10 },
        { header: 'Établissement : Libellé', key: 'libelleEtablissement', width: 10 },
        { header: 'Direction', key: 'codeDirection', width: 10 },
        { header: 'Direction : Libellé', key: 'libelleDirection', width: 10 },
        { header: 'Service', key: 'codeService', width: 10 },
        { header: 'Service : Libellé', key: 'libelleService', width: 10 },
        { header: 'Secteur', key: 'codeSecteur', width: 10 },
        { header: 'Secteur : Libellé', key: 'libelleSecteur', width: 10 }
    ];
    worksheet.columns = columns;

    anomalies.forEach((anomalie, id) => {
        const dateString = anomalie.date.length < 8 ? "0" + anomalie.date : anomalie.date;
        const dateMouvement = moment(dateString, "DDMMYYYY").isValid() ? moment(dateString, "DDMMYYYY").format("DD/MM/YYYY") : "";

        const row = {
            societe: anomalie.codeSociete,
            matricule: anomalie.matricule,
            typePersonnel: anomalie.type,
            nom: anomalie.nom,
            prenom: anomalie.prenom,
            badge: anomalie.badge,
            dateMouvement: dateMouvement,
            creditAcquis: anomalie.creditAcquis,
            creditEnCours: anomalie.creditEnCours,
            codeEtablissement: anomalie.codeEtablissement,
            libelleEtablissement: anomalie.libelleEtablissement,
            codeDirection: anomalie.codeDirection,
            libelleDirection: anomalie.libelleDirection,
            codeService: anomalie.codeService,
            libelleService: anomalie.libelleService,
            codeSecteur: anomalie.codeSecteur,
            libelleSecteur: anomalie.libelleSecteur
        }

        worksheet.addRow(row)
    });
}

export const modelizeAnomaliesActivite = (worksheet, anomalies) => {
    const columns = [
        { header: 'Société', key: 'societe', width: 10 },
        { header: 'Matricule', key: 'matricule', width: 10 },
        { header: 'Type de Personnel', key: 'typePersonnel', width: 10 },
        { header: 'Nom', key: 'nom', width: 10 },
        { header: 'Prénom', key: 'prenom', width: 10 },
        { header: 'Badge', key: 'badge', width: 10 },
        { header: 'Date Mouvement', key: 'dateMouvement', width: 10 },
        { header: 'Nombre d\'heures', key: 'nombreHeures', width: 10 },
        { header: 'Activité', key: 'codeActivite', width: 10 },
        { header: 'Activité : Libellé', key: 'libelleActivite', width: 10 },
        { header: 'Établissement', key: 'codeEtablissement', width: 10 },
        { header: 'Établissement : Libellé', key: 'libelleEtablissement', width: 10 },
        { header: 'Direction', key: 'codeDirection', width: 10 },
        { header: 'Direction : Libellé', key: 'libelleDirection', width: 10 },
        { header: 'Service', key: 'codeService', width: 10 },
        { header: 'Service : Libellé', key: 'libelleService', width: 10 },
        { header: 'Secteur', key: 'codeSecteur', width: 10 },
        { header: 'Secteur : Libellé', key: 'libelleSecteur', width: 10 }
    ];
    worksheet.columns = columns;

    anomalies.forEach((anomalie, id) => {
        const dateString = anomalie.date.length < 8 ? "0" + anomalie.date : anomalie.date;
        const dateMouvement = moment(dateString, "DDMMYYYY").isValid() ? moment(dateString, "DDMMYYYY").format("DD/MM/YYYY") : "";

        const row = {
            societe: anomalie.codeSociete,
            matricule: anomalie.matricule,
            typePersonnel: anomalie.type,
            nom: anomalie.nom,
            prenom: anomalie.prenom,
            badge: anomalie.badge,
            dateMouvement: dateMouvement,
            nombreHeures: anomalie.nombreHeures,
            codeActivite: anomalie.codeActivite,
            libelleActivite: anomalie.libelleActivite,
            codeEtablissement: anomalie.codeEtablissement,
            libelleEtablissement: anomalie.libelleEtablissement,
            codeDirection: anomalie.codeDirection,
            libelleDirection: anomalie.libelleDirection,
            codeService: anomalie.codeService,
            libelleService: anomalie.libelleService,
            codeSecteur: anomalie.codeSecteur,
            libelleSecteur: anomalie.libelleSecteur
        }

        worksheet.addRow(row)
    });
}

export const modelizeHeuresCumulees = (worksheet, heures) => {
    const columns = [
        { header: 'Société', key: 'societe', width: 10 },
        { header: 'Matricule', key: 'matricule', width: 10 },
        { header: 'Collaborateur', key: 'collaborateur', width: 10 },
        { header: 'Qualité', key: 'qualite', width: 10 },
        { header: 'Nom', key: 'nom', width: 10 },
        { header: 'Prénom', key: 'prenom', width: 10 },
        { header: 'Sexe', key: 'sexe', width: 10 },
        { header: 'Date Naissance', key: 'dateNaissance', width: 10 },
        { header: 'Age', key: 'age', width: 10 },
        { header: 'Date Entrée', key: 'dateEntree', width: 10 },
        { header: 'Badge', key: 'badge', width: 10 },
        { header: 'Contrôle Horaire', key: 'controleHoraire', width: 10 },
        { header: 'Date Début', key: 'dateDebut', width: 10 },
        { header: 'Date Fin', key: 'dateFin', width: 10 },
        { header: 'Heures Badgées', key: 'heuresBadgees', width: 10 },
        { header: 'Heures Payées', key: 'heuresPayees', width: 10 },
        { header: 'Absence Crédit', key: 'absenceCredit', width: 10 },
        { header: 'Écart Crédit', key: 'ecartCredit', width: 10 },
        { header: 'Coefficient Crédit', key: 'coefficientCredit', width: 10 },
        { header: 'Crédit En Cours', key: 'creditEnCours', width: 10 },
        { header: 'Régularisation Crédit', key: 'regularisationCredit', width: 10 },
        { header: 'Crédit Acquis', key: 'creditAcquis', width: 10 },
        { header: 'Absence', key: 'absence', width: 10 },
        { header: 'Absence TTE', key: 'absenceTte', width: 10 },
        { header: 'Temps Travail', key: 'tempsTravail', width: 10 },
        { header: 'Écart Horaire', key: 'ecartHoraire', width: 10 },
        { header: 'Écart Heures En Plus', key: 'ecartHeuresEnPlus', width: 10 },
        { header: 'Écart Exceptionnel', key: 'ecartExceptionnel', width: 10 },
        { header: 'Heures Nuit Badgées', key: 'heuresNuitBadgees', width: 10 },
        { header: 'Heures Nuit Payées', key: 'heuresNuitPayees', width: 10 },
        { header: 'Pause Repas', key: 'pauseRepas', width: 10 },
        { header: 'Établissement', key: 'etablissement', width: 10 },
        { header: 'Libellé Établissement', key: 'libelleEtablissement', width: 10 },
        { header: 'Direction', key: 'direction', width: 10 },
        { header: 'Libellé Direction', key: 'libelleDirection', width: 10 },
        { header: 'Service', key: 'service', width: 10 },
        { header: 'Libellé Service', key: 'libelleService', width: 10 },
        { header: 'Secteur', key: 'secteur', width: 10 },
        { header: 'Libellé Secteur', key: 'libelleSecteur', width: 10 },
        { header: 'Emploi', key: 'emploi', width: 10 },
        { header: 'Libellé Emploi', key: 'libelleEmploi', width: 10 },
        { header: 'Catégorie', key: 'categorie', width: 10 },
        { header: 'Libellé Catégorie', key: 'libelleCategorie', width: 10 },
        { header: 'Type De Contrat', key: 'typeContrat', width: 10 },
        { header: 'Libellé Type De Contrat', key: 'libelleTypeContrat', width: 10 },
        { header: 'Durée Du Contrat', key: 'dureeContrat', width: 10 }
    ];
    worksheet.columns = columns;

    heures.forEach((heure, id) => {
        const dateNaissanceString = heure.dateNaissance.length < 8 ? "0" + heure.dateNaissance : heure.dateNaissance;
        const dateEntreeString = heure.dateEntree.length < 8 ? "0" + heure.dateEntree : heure.dateEntree;
        const dateDebutString = heure.dateDebut.length < 8 ? "0" + heure.dateDebut : heure.dateDebut;
        const dateFinString = heure.dateFin.length < 8 ? "0" + heure.dateFin : heure.dateFin;
        const dateNaissance = moment(dateNaissanceString, "DDMMYYYY").isValid() ? moment(dateNaissanceString, "DDMMYYYY").format("DD/MM/YYYY") : "";
        const dateEntree = moment(dateEntreeString, "DDMMYYYY").isValid() ? moment(dateEntreeString, "DDMMYYYY").format("DD/MM/YYYY") : "";
        const dateDebut = moment(dateDebutString, "DDMMYYYY").isValid() ? moment(dateDebutString, "DDMMYYYY").format("DD/MM/YYYY") : "";
        const dateFin = moment(dateFinString, "DDMMYYYY").isValid() ? moment(dateFinString, "DDMMYYYY").format("DD/MM/YYYY") : "";

        const row = {
            societe: heure.codeSociete,
            matricule: heure.matricule,
            collaborateur: heure.typePersonnel,
            qualite: heure.qualite,
            nom: heure.nom,
            prenom: heure.prenom,
            sexe: heure.sexe,
            dateNaissance: dateNaissance,
            age: numeral(heure.age).value(),
            dateEntree: dateEntree,
            badge: heure.badge,
            controleHoraire: heure.controleHoraire,
            dateDebut: dateDebut,
            dateFin: dateFin,
            heuresBadgees: numeral(heure.heuresBadgees.replace(".", ",")).value(),
            heuresPayees: numeral(heure.heuresPayees.replace(".", ",")).value(),
            absenceCredit: numeral(heure.absenceCredit.replace(".", ",")).value(),
            ecartCredit: numeral(heure.ecartCredit.replace(".", ",")).value(),
            coefficientCredit: numeral(heure.coefficientCredit.replace(".", ",")).value(),
            creditEnCours: numeral(heure.creditEnCours.replace(".", ",")).value(),
            regularisationCredit: numeral(heure.regularisationCredit.replace(".", ",")).value(),
            creditAcquis: numeral(heure.creditAcquis.replace(".", ",")).value(),
            absence: numeral(heure.heuresAbsence.replace(".", ",")).value(),
            absenceTte: numeral(heure.heuresAbsenceTte.replace(".", ",")).value(),
            tempsTravail: numeral(heure.tempsTravailEffectif.replace(".", ",")).value(),
            ecartHoraire: numeral(heure.ecartHoraire.replace(".", ",")).value(),
            ecartHeuresEnPlus: numeral(heure.ecartHeuresEnPlus.replace(".", ",")).value(),
            ecartExceptionnel: numeral(heure.ecartExceptionnel.replace(".", ",")).value(),
            heuresNuitBadgees: numeral(heure.heuresNuitBadgees.replace(".", ",")).value(),
            heuresNuitPayees: numeral(heure.heuresNuitPayees.replace(".", ",")).value(),
            pauseRepas: numeral(heure.pauseRepas.replace(".", ",")).value(),
            etablissement: heure.codeEtablissement,
            libelleEtablissement: heure.libelleEtablissement,
            direction: heure.codeDirection,
            libelleDirection: heure.libelleDirection,
            service: heure.codeService,
            libelleService: heure.libelleService,
            secteur: heure.codeSecteur,
            libelleSecteur: heure.libelleSecteur,
            emploi: heure.codeEmploi,
            libelleEmploi: heure.libelleEmploi,
            categorie: heure.codeCategorie,
            libelleCategorie: heure.libelleCategorie,
            typeContrat: heure.codeTypeContrat,
            libelleTypeContrat: heure.libelleTypeContrat,
            dureeContrat: heure.dureeContrat
        }

        worksheet.addRow(row)
    });
}

export const modelizeHeuresDetailleesAvecMouvements = (worksheet, heures) => {
    const columns = [
        { header: 'Société', key: 'societe', width: 10 },
        { header: 'Matricule', key: 'matricule', width: 10 },
        { header: 'Collaborateur', key: 'collaborateur', width: 10 },
        { header: 'Qualité', key: 'qualite', width: 10 },
        { header: 'Nom', key: 'nom', width: 10 },
        { header: 'Prénom', key: 'prenom', width: 10 },
        { header: 'Sexe', key: 'sexe', width: 10 },
        { header: 'Date Naissance', key: 'dateNaissance', width: 10 },
        { header: 'Age', key: 'age', width: 10 },
        { header: 'Date Entrée', key: 'dateEntree', width: 10 },
        { header: 'Badge', key: 'badge', width: 10 },
        { header: 'Contrôle Horaire', key: 'controleHoraire', width: 10 },
        { header: 'Date Début', key: 'dateDebut', width: 10 },
        { header: 'Date Fin', key: 'dateFin', width: 10 },
        { header: 'Jour Semaine', key: 'jourSemaine', width: 10 },
        { header: 'Mouvement Prévisionnel', key: 'mouvementPrevu', width: 10 },
        { header: 'Heure Prévisionnelle', key: 'heurePrevue', width: 10 },
        { header: 'Lecteur', key: 'lecteur', width: 10 },
        { header: 'Mouvement Réel', key: 'mouvementReel', width: 10 },
        { header: 'Heure Réelle', key: 'heureReelle', width: 10 },
        { header: 'Heure Ajustée', key: 'heureAjustee', width: 10 },
        { header: 'Mouvement Régularisé', key: 'mouvementRegularise', width: 10 },
        { header: 'Heure Régularisée', key: 'heureRegularisee', width: 10 },
        { header: 'Date Régularisation', key: 'dateRegularisation', width: 10 },
        { header: 'Utilisateur', key: 'utilisateurRegularisation', width: 10 },
        { header: 'Heures Badgées', key: 'heuresBadgees', width: 10 },
        { header: 'Heures Payées', key: 'heuresPayees', width: 10 },
        { header: 'Absence Crédit', key: 'absenceCredit', width: 10 },
        { header: 'Écart Crédit', key: 'ecartCredit', width: 10 },
        { header: 'Coefficient Crédit', key: 'coefficientCredit', width: 10 },
        { header: 'Crédit En Cours', key: 'creditEnCours', width: 10 },
        { header: 'Régularisation Crédit', key: 'regularisationCredit', width: 10 },
        { header: 'Crédit Acquis', key: 'creditAcquis', width: 10 },
        { header: 'Date Régularisation Crédit', key: 'dateRegularisationCredit', width: 10 },
        { header: 'Utilisateur', key: 'utilisateurRegularisationCredit', width: 10 },
        { header: 'Commentaire', key: 'commentaire', width: 10 },
        { header: 'Absence', key: 'absence', width: 10 },
        { header: 'Type Absence', key: 'typeAbsence', width: 10 },
        { header: 'Absence TTE', key: 'absenceTte', width: 10 },
        { header: 'Type Absence TTE', key: 'typeAbsenceTte', width: 10 },
        { header: 'Temps Travail', key: 'tempsTravail', width: 10 },
        { header: 'Écart Horaire', key: 'ecartHoraire', width: 10 },
        { header: 'Écart Heures En Plus', key: 'ecartHeuresEnPlus', width: 10 },
        { header: 'Écart Exceptionnel', key: 'ecartExceptionnel', width: 10 },
        { header: 'Heures Nuit Badgées', key: 'heuresNuitBadgees', width: 10 },
        { header: 'Heures Nuit Payées', key: 'heuresNuitPayees', width: 10 },
        { header: 'Pause Repas', key: 'pauseRepas', width: 10 },
        { header: 'Horaire', key: 'codeHoraire', width: 10 },
        { header: 'Libellé Horaire', key: 'libelleHoraire', width: 10 },
        { header: 'Équipe', key: 'codeEquipe', width: 10 },
        { header: 'Libellé Équipe', key: 'libelleEquipe', width: 10 },
        { header: 'Centre De Coûts', key: 'centreCouts', width: 10 },
        { header: 'Établissement', key: 'etablissement', width: 10 },
        { header: 'Libellé Établissement', key: 'libelleEtablissement', width: 10 },
        { header: 'Direction', key: 'direction', width: 10 },
        { header: 'Libellé Direction', key: 'libelleDirection', width: 10 },
        { header: 'Service', key: 'service', width: 10 },
        { header: 'Libellé Service', key: 'libelleService', width: 10 },
        { header: 'Secteur', key: 'secteur', width: 10 },
        { header: 'Libellé Secteur', key: 'libelleSecteur', width: 10 },
        { header: 'Emploi', key: 'emploi', width: 10 },
        { header: 'Libellé Emploi', key: 'libelleEmploi', width: 10 },
        { header: 'Catégorie', key: 'categorie', width: 10 },
        { header: 'Libellé Catégorie', key: 'libelleCategorie', width: 10 },
        { header: 'Type De Contrat', key: 'typeContrat', width: 10 },
        { header: 'Libellé Type De Contrat', key: 'libelleTypeContrat', width: 10 },
        { header: 'Durée Du Contrat', key: 'dureeContrat', width: 10 }
    ];
    worksheet.columns = columns;

    heures.forEach((heure, id) => {
        const dateNaissanceString = heure.dateNaissance.length < 8 ? "0" + heure.dateNaissance : heure.dateNaissance;
        const dateEntreeString = heure.dateEntree.length < 8 ? "0" + heure.dateEntree : heure.dateEntree;
        const dateDebutString = heure.dateDebut.length < 8 ? "0" + heure.dateDebut : heure.dateDebut;
        const dateFinString = heure.dateFin.length < 8 ? "0" + heure.dateFin : heure.dateFin;
        const dateRegularisationString = heure.dateRegularisation.length < 8 ? "0" + heure.dateRegularisation : heure.dateRegularisation;
        const dateRegularisationCreditString = heure.dateRegularisationCredit.length < 8 ? "0" + heure.dateRegularisationCredit : heure.dateRegularisationCredit;
        const heurePrevueString = heure.heurePrevue.length < 4 ? "0" + heure.heurePrevue : heure.heurePrevue;
        const heureReelleString = heure.heureReelle.length < 4 ? "0" + heure.heureReelle : heure.heureReelle;
        const heureAjusteeString = heure.heureAjustee.length < 4 ? "0" + heure.heureAjustee : heure.heureAjustee;
        const heureRegulariseeString = heure.heureRegularisee.length < 4 ? "0" + heure.heureRegularisee : heure.heureRegularisee;
        const dateNaissance = moment(dateNaissanceString, "DDMMYYYY").isValid() ? moment(dateNaissanceString, "DDMMYYYY").format("DD/MM/YYYY") : "";
        const dateEntree = moment(dateEntreeString, "DDMMYYYY").isValid() ? moment(dateEntreeString, "DDMMYYYY").format("DD/MM/YYYY") : "";
        const dateDebut = moment(dateDebutString, "DDMMYYYY").isValid() ? moment(dateDebutString, "DDMMYYYY").format("DD/MM/YYYY") : "";
        const dateFin = moment(dateFinString, "DDMMYYYY").isValid() ? moment(dateFinString, "DDMMYYYY").format("DD/MM/YYYY") : "";
        const dateRegularisation = moment(dateRegularisationString, "DDMMYYYY").isValid() ? moment(dateRegularisationString, "DDMMYYYY").format("DD/MM/YYYY") : "";
        const dateRegularisationCredit = moment(dateRegularisationCreditString, "DDMMYYYY").isValid() ? moment(dateRegularisationCreditString, "DDMMYYYY").format("DD/MM/YYYY") : "";
        const heurePrevue = moment(heurePrevueString, "HHmm").isValid() && heurePrevueString !== "00" ? moment(heurePrevueString, "HHmm").format("HH:mm") : "";
        const heureReelle = moment(heureReelleString, "HHmm").isValid() && heureReelleString !== "00" ? moment(heureReelleString, "HHmm").format("HH:mm") : "";
        const heureAjustee = moment(heureAjusteeString, "HHmm").isValid() && heureAjusteeString !== "00" ? moment(heureAjusteeString, "HHmm").format("HH:mm") : "";
        const heureRegularisee = moment(heureRegulariseeString, "HHmm").isValid() && heureRegulariseeString !== "00" ? moment(heureRegulariseeString, "HHmm").format("HH:mm") : "";

        const row = {
            societe: heure.codeSociete,
            matricule: heure.matricule,
            collaborateur: heure.typePersonnel,
            qualite: heure.qualite,
            nom: heure.nom,
            prenom: heure.prenom,
            sexe: heure.sexe,
            dateNaissance: dateNaissance,
            age: numeral(heure.age).value(),
            dateEntree: dateEntree,
            badge: heure.badge,
            controleHoraire: heure.controleHoraire,
            dateDebut: dateDebut,
            dateFin: dateFin,
            jourSemaine: heure.jourSemaine,
            mouvementPrevu: heure.libelleMouvementPrevu,
            heurePrevue: heurePrevue,
            lecteur: heure.lecteur,
            mouvementReel: heure.libelleMouvementReel,
            heureReelle: heureReelle,
            heureAjustee: heureAjustee,
            mouvementRegularise: heure.libelleMouvementRegularise,
            heureRegularisee: heureRegularisee,
            dateRegularisation: dateRegularisation,
            utilisateurRegularisation: heure.utilisateurRegularisation,
            heuresBadgees: numeral(heure.heuresBadgees.replace(".", ",")).value(),
            heuresPayees: numeral(heure.heuresPayees.replace(".", ",")).value(),
            absenceCredit: numeral(heure.absenceCredit.replace(".", ",")).value(),
            ecartCredit: numeral(heure.ecartCredit.replace(".", ",")).value(),
            coefficientCredit: numeral(heure.coefficientCredit.replace(".", ",")).value(),
            creditEnCours: numeral(heure.creditEnCours.replace(".", ",")).value(),
            regularisationCredit: numeral(heure.regularisationCredit.replace(".", ",")).value(),
            creditAcquis: numeral(heure.creditAcquis.replace(".", ",")).value(),
            dateRegularisationCredit: dateRegularisationCredit,
            utilisateurRegularisationCredit: heure.utilisateurRegularisationCredit,
            commentaire: heure.commentaire,
            absence: numeral(heure.heuresAbsence.replace(".", ",")).value(),
            typeAbsence: heure.typeAbsence,
            absenceTte: numeral(heure.heuresAbsenceTte.replace(".", ",")).value(),
            typeAbsenceTte: heure.typeAbsenceTte,
            tempsTravail: numeral(heure.tempsTravailEffectif.replace(".", ",")).value(),
            ecartHoraire: numeral(heure.ecartHoraire.replace(".", ",")).value(),
            ecartHeuresEnPlus: numeral(heure.ecartHeuresEnPlus.replace(".", ",")).value(),
            ecartExceptionnel: numeral(heure.ecartExceptionnel.replace(".", ",")).value(),
            heuresNuitBadgees: numeral(heure.heuresNuitBadgees.replace(".", ",")).value(),
            heuresNuitPayees: numeral(heure.heuresNuitPayees.replace(".", ",")).value(),
            pauseRepas: numeral(heure.pauseRepas.replace(".", ",")).value(),
            codeHoraire: heure.codeHoraire,
            libelleHoraire: heure.libelleHoraire,
            codeEquipe: heure.codeEquipe,
            libelleEquipe: heure.libelleEquipe,
            centreCouts: heure.centreCouts,
            etablissement: heure.codeEtablissement,
            libelleEtablissement: heure.libelleEtablissement,
            direction: heure.codeDirection,
            libelleDirection: heure.libelleDirection,
            service: heure.codeService,
            libelleService: heure.libelleService,
            secteur: heure.codeSecteur,
            libelleSecteur: heure.libelleSecteur,
            emploi: heure.codeEmploi,
            libelleEmploi: heure.libelleEmploi,
            categorie: heure.codeCategorie,
            libelleCategorie: heure.libelleCategorie,
            typeContrat: heure.codeTypeContrat,
            libelleTypeContrat: heure.libelleTypeContrat,
            dureeContrat: heure.dureeContrat
        }

        worksheet.addRow(row)
    });
}

export const modelizeHeuresDetailleesSansMouvements = (worksheet, heures) => {
    const columns = [
        { header: 'Société', key: 'societe', width: 10 },
        { header: 'Matricule', key: 'matricule', width: 10 },
        { header: 'Collaborateur', key: 'collaborateur', width: 10 },
        { header: 'Qualité', key: 'qualite', width: 10 },
        { header: 'Nom', key: 'nom', width: 10 },
        { header: 'Prénom', key: 'prenom', width: 10 },
        { header: 'Sexe', key: 'sexe', width: 10 },
        { header: 'Date Naissance', key: 'dateNaissance', width: 10 },
        { header: 'Age', key: 'age', width: 10 },
        { header: 'Date Entrée', key: 'dateEntree', width: 10 },
        { header: 'Badge', key: 'badge', width: 10 },
        { header: 'Contrôle Horaire', key: 'controleHoraire', width: 10 },
        { header: 'Date Début', key: 'dateDebut', width: 10 },
        { header: 'Date Fin', key: 'dateFin', width: 10 },
        { header: 'Jour Semaine', key: 'jourSemaine', width: 10 },
        { header: 'Heures Badgées', key: 'heuresBadgees', width: 10 },
        { header: 'Heures Payées', key: 'heuresPayees', width: 10 },
        { header: 'Absence Crédit', key: 'absenceCredit', width: 10 },
        { header: 'Écart Crédit', key: 'ecartCredit', width: 10 },
        { header: 'Coefficient Crédit', key: 'coefficientCredit', width: 10 },
        { header: 'Crédit En Cours', key: 'creditEnCours', width: 10 },
        { header: 'Régularisation Crédit', key: 'regularisationCredit', width: 10 },
        { header: 'Crédit Acquis', key: 'creditAcquis', width: 10 },
        { header: 'Date Régularisation Crédit', key: 'dateRegularisationCredit', width: 10 },
        { header: 'Utilisateur', key: 'utilisateurRegularisationCredit', width: 10 },
        { header: 'Commentaire', key: 'commentaire', width: 10 },
        { header: 'Absence', key: 'absence', width: 10 },
        { header: 'Type Absence', key: 'typeAbsence', width: 10 },
        { header: 'Absence TTE', key: 'absenceTte', width: 10 },
        { header: 'Type Absence TTE', key: 'typeAbsenceTte', width: 10 },
        { header: 'Temps Travail', key: 'tempsTravail', width: 10 },
        { header: 'Écart Horaire', key: 'ecartHoraire', width: 10 },
        { header: 'Écart Heures En Plus', key: 'ecartHeuresEnPlus', width: 10 },
        { header: 'Écart Exceptionnel', key: 'ecartExceptionnel', width: 10 },
        { header: 'Heures Nuit Badgées', key: 'heuresNuitBadgees', width: 10 },
        { header: 'Heures Nuit Payées', key: 'heuresNuitPayees', width: 10 },
        { header: 'Pause Repas', key: 'pauseRepas', width: 10 },
        { header: 'Horaire', key: 'codeHoraire', width: 10 },
        { header: 'Libellé Horaire', key: 'libelleHoraire', width: 10 },
        { header: 'Équipe', key: 'codeEquipe', width: 10 },
        { header: 'Libellé Équipe', key: 'libelleEquipe', width: 10 },
        { header: 'Centre De Coûts', key: 'centreCouts', width: 10 },
        { header: 'Établissement', key: 'etablissement', width: 10 },
        { header: 'Libellé Établissement', key: 'libelleEtablissement', width: 10 },
        { header: 'Direction', key: 'direction', width: 10 },
        { header: 'Libellé Direction', key: 'libelleDirection', width: 10 },
        { header: 'Service', key: 'service', width: 10 },
        { header: 'Libellé Service', key: 'libelleService', width: 10 },
        { header: 'Secteur', key: 'secteur', width: 10 },
        { header: 'Libellé Secteur', key: 'libelleSecteur', width: 10 },
        { header: 'Emploi', key: 'emploi', width: 10 },
        { header: 'Libellé Emploi', key: 'libelleEmploi', width: 10 },
        { header: 'Catégorie', key: 'categorie', width: 10 },
        { header: 'Libellé Catégorie', key: 'libelleCategorie', width: 10 },
        { header: 'Type De Contrat', key: 'typeContrat', width: 10 },
        { header: 'Libellé Type De Contrat', key: 'libelleTypeContrat', width: 10 },
        { header: 'Durée Du Contrat', key: 'dureeContrat', width: 10 }
    ];
    worksheet.columns = columns;

    heures.forEach((heure, id) => {
        const dateNaissanceString = heure.dateNaissance.length < 8 ? "0" + heure.dateNaissance : heure.dateNaissance;
        const dateEntreeString = heure.dateEntree.length < 8 ? "0" + heure.dateEntree : heure.dateEntree;
        const dateDebutString = heure.dateDebut.length < 8 ? "0" + heure.dateDebut : heure.dateDebut;
        const dateFinString = heure.dateFin.length < 8 ? "0" + heure.dateFin : heure.dateFin;
        const heurePrevueString = heure.heurePrevue.length < 4 ? "0" + heure.heurePrevue : heure.heurePrevue;
        const heureReelleString = heure.heureReelle.length < 4 ? "0" + heure.heureReelle : heure.heureReelle;
        const heureAjusteeString = heure.heureAjustee.length < 4 ? "0" + heure.heureAjustee : heure.heureAjustee;
        const heureRegulariseeString = heure.heureRegularisee.length < 4 ? "0" + heure.heureRegularisee : heure.heureRegularisee;
        const dateRegularisationString = heure.dateRegularisation.length < 8 ? "0" + heure.dateRegularisation : heure.dateRegularisation;
        const dateRegularisationCreditString = heure.dateRegularisationCredit.length < 8 ? "0" + heure.dateRegularisationCredit : heure.dateRegularisationCredit;
        const dateNaissance = moment(dateNaissanceString, "DDMMYYYY").isValid() ? moment(dateNaissanceString, "DDMMYYYY").format("DD/MM/YYYY") : "";
        const dateEntree = moment(dateEntreeString, "DDMMYYYY").isValid() ? moment(dateEntreeString, "DDMMYYYY").format("DD/MM/YYYY") : "";
        const dateDebut = moment(dateDebutString, "DDMMYYYY").isValid() ? moment(dateDebutString, "DDMMYYYY").format("DD/MM/YYYY") : "";
        const dateFin = moment(dateFinString, "DDMMYYYY").isValid() ? moment(dateFinString, "DDMMYYYY").format("DD/MM/YYYY") : "";
        const dateRegularisation = moment(dateRegularisationString, "DDMMYYYY").isValid() ? moment(dateRegularisationString, "DDMMYYYY").format("DD/MM/YYYY") : "";
        const dateRegularisationCredit = moment(dateRegularisationCreditString, "DDMMYYYY").isValid() ? moment(dateRegularisationCreditString, "DDMMYYYY").format("DD/MM/YYYY") : "";
        const heurePrevue = moment(heurePrevueString, "HHmm").isValid() && heurePrevueString !== "00" ? moment(heurePrevueString, "HHmm").format("HH:mm") : "";
        const heureReelle = moment(heureReelleString, "HHmm").isValid() && heureReelleString !== "00" ? moment(heureReelleString, "HHmm").format("HH:mm") : "";
        const heureAjustee = moment(heureAjusteeString, "HHmm").isValid() && heureAjusteeString !== "00" ? moment(heureAjusteeString, "HHmm").format("HH:mm") : "";
        const heureRegularisee = moment(heureRegulariseeString, "HHmm").isValid() && heureRegulariseeString !== "00" ? moment(heureRegulariseeString, "HHmm").format("HH:mm") : "";

        const row = {
            societe: heure.codeSociete,
            matricule: heure.matricule,
            collaborateur: heure.typePersonnel,
            qualite: heure.qualite,
            nom: heure.nom,
            prenom: heure.prenom,
            sexe: heure.sexe,
            dateNaissance: dateNaissance,
            age: numeral(heure.age).value(),
            dateEntree: dateEntree,
            badge: heure.badge,
            controleHoraire: heure.controleHoraire,
            dateDebut: dateDebut,
            dateFin: dateFin,
            jourSemaine: heure.jourSemaine,
            mouvementPrevu: heure.mouvementPrevu,
            heurePrevue: heurePrevue,
            lecteur: heure.lecteur,
            mouvementReel: heure.mouvementReel,
            heureReelle: heureReelle,
            heureAjustee: heureAjustee,
            mouvementRegularise: heure.mouvementRegularise,
            heureRegularisee: heureRegularisee,
            dateRegularisation: dateRegularisation,
            utilisateurRegularisation: heure.utilisateurRegularisation,
            heuresBadgees: numeral(heure.heuresBadgees.replace(".", ",")).value(),
            heuresPayees: numeral(heure.heuresPayees.replace(".", ",")).value(),
            absenceCredit: numeral(heure.absenceCredit.replace(".", ",")).value(),
            ecartCredit: numeral(heure.ecartCredit.replace(".", ",")).value(),
            coefficientCredit: numeral(heure.coefficientCredit.replace(".", ",")).value(),
            creditEnCours: numeral(heure.creditEnCours.replace(".", ",")).value(),
            regularisationCredit: numeral(heure.regularisationCredit.replace(".", ",")).value(),
            creditAcquis: numeral(heure.creditAcquis.replace(".", ",")).value(),
            dateRegularisationCredit: dateRegularisationCredit,
            utilisateurRegularisationCredit: heure.utilisateurRegularisationCredit,
            commentaire: heure.commentaire,
            absence: numeral(heure.heuresAbsence.replace(".", ",")).value(),
            typeAbsence: heure.typeAbsence,
            absenceTte: numeral(heure.heuresAbsenceTte.replace(".", ",")).value(),
            typeAbsenceTte: heure.typeAbsenceTte,
            tempsTravail: numeral(heure.tempsTravailEffectif.replace(".", ",")).value(),
            ecartHoraire: numeral(heure.ecartHoraire.replace(".", ",")).value(),
            ecartHeuresEnPlus: numeral(heure.ecartHeuresEnPlus.replace(".", ",")).value(),
            ecartExceptionnel: numeral(heure.ecartExceptionnel.replace(".", ",")).value(),
            heuresNuitBadgees: numeral(heure.heuresNuitBadgees.replace(".", ",")).value(),
            heuresNuitPayees: numeral(heure.heuresNuitPayees.replace(".", ",")).value(),
            pauseRepas: numeral(heure.pauseRepas.replace(".", ",")).value(),
            codeHoraire: heure.codeHoraire,
            libelleHoraire: heure.libelleHoraire,
            codeEquipe: heure.codeEquipe,
            libelleEquipe: heure.libelleEquipe,
            centreCouts: heure.centreCouts,
            etablissement: heure.codeEtablissement,
            libelleEtablissement: heure.libelleEtablissement,
            direction: heure.codeDirection,
            libelleDirection: heure.libelleDirection,
            service: heure.codeService,
            libelleService: heure.libelleService,
            secteur: heure.codeSecteur,
            libelleSecteur: heure.libelleSecteur,
            emploi: heure.codeEmploi,
            libelleEmploi: heure.libelleEmploi,
            categorie: heure.codeCategorie,
            libelleCategorie: heure.libelleCategorie,
            typeContrat: heure.codeTypeContrat,
            libelleTypeContrat: heure.libelleTypeContrat,
            dureeContrat: heure.dureeContrat
        }

        worksheet.addRow(row)
    });
}

export const modelizePlanning = (worksheet, semaines, societes) => {
    const columns = formateColumnsTableur(64);
    columns.unshift({width: 35});
    worksheet.columns = columns;
    
    const allBorder = { top: { style: "thin" }, left: { style: "thin" }, bottom: { style: "thin" }, right: { style: "thin" } };
    const borderTopCorners = { top: { style: "thin" }, left: { style:"thin" }, right: { style:"thin" } };
    const borderBottomCorners = { left: { style: "thin" }, bottom: { style: "thin" }, right: { style: "thin" } };
    const fontBold = { bold: true };
    const alignCenter = { vertical: "middle", horizontal: "center" };
    const legendColors = { P: "C6D9F1", A: "FEE683", E: "CC0000", D: "996633", X: "77933C" };
    const legendPatterns = { type: "pattern", pattern: "solid" };

    worksheet.getRow(1).height = 40;

    worksheet.mergeCells("A1:BM1");
    worksheet.getCell("BM1").value = "NIVA® e-RH : Planning";
    worksheet.getCell("BM1").font = { name: "Book Antiqua", size: 22 };
    worksheet.getCell("BM1").alignment = alignCenter;

    worksheet.mergeCells("A7:B8");
    worksheet.getCell("A8").value = "PERSONNEL";
    worksheet.getCell("A8").border = allBorder;
    worksheet.getCell("A8").font = fontBold;
    worksheet.getCell("A8").alignment = alignCenter;
    
    worksheet.views = [{state: "frozen", xSplit: 2, ySplit: 8}];

    worksheet.getCell("D3").fill = { ...legendPatterns, fgColor: { argb: legendColors.P } }; 
    worksheet.getCell("D3").border = allBorder;
    worksheet.mergeCells("E3:H3");
    worksheet.getCell("H3").value = "Présences";
    worksheet.getCell("H3").border = allBorder;

    worksheet.getCell("D4").fill = { ...legendPatterns, fgColor: { argb: legendColors.A } };
    worksheet.getCell("D4").border = allBorder;
    worksheet.mergeCells("E4:H4");
    worksheet.getCell("H4").value = "Absences";
    worksheet.getCell("H4").border = allBorder;

    worksheet.getCell("I3").fill = { ...legendPatterns, fgColor: { argb: legendColors.E } };
    worksheet.getCell("I3").border = allBorder;
    worksheet.mergeCells("J3:T3");
    worksheet.getCell("T3").value = "Absences en attente de validation";
    worksheet.getCell("T3").border = allBorder;

    worksheet.getCell("I4").fill = { ...legendPatterns, fgColor: { argb: legendColors.D } };
    worksheet.getCell("I4").border = allBorder;
    worksheet.mergeCells("J4:T4");
    worksheet.getCell("T4").value = "Absences demi-journées";
    worksheet.getCell("T4").border = allBorder;

    worksheet.getCell("U3").fill = { ...legendPatterns, fgColor: { argb: legendColors.X } };
    worksheet.getCell("U3").border = allBorder;
    worksheet.mergeCells("V3:AK3");
    worksheet.getCell("AK3").value = "Absences demi-journées en attente de validation";
    worksheet.getCell("AK3").border = allBorder;
    
    const cellsSemaines = [
        { range: "C6:I6", cell: "I6" }, 
        { range: "J6:P6", cell: "P6" }, 
        { range: "Q6:W6", cell: "W6" }, 
        { range: "X6:AD6", cell: "AD6" }, 
        { range: "AE6:AK6", cell: "AK6" }, 
        { range: "AL6:AR6", cell: "AR6" }, 
        { range: "AS6:AY6", cell: "AY6" }, 
        { range: "AZ6:BF6", cell: "BF6" }, 
        { range: "BG6:BM6", cell: "BM6" }
    ];
    const cellsSemainesDate = [
        { range: "C7:I7", cell: "I7" }, 
        { range: "J7:P7", cell: "P7" }, 
        { range: "Q7:W7", cell: "W7" }, 
        { range: "X7:AD7", cell: "AD7" }, 
        { range: "AE7:AK7", cell: "AK7" }, 
        { range: "AL7:AR7", cell: "AR7" }, 
        { range: "AS7:AY7", cell: "AY7" }, 
        { range: "AZ7:BF7", cell: "BF7" }, 
        { range: "BG7:BM7", cell: "BM7" }
    ];

    semaines.forEach((semaine, id) => {
        worksheet.mergeCells(cellsSemaines[id].range);
        worksheet.mergeCells(cellsSemainesDate[id].range);
    
        worksheet.getCell(cellsSemaines[id].cell).value = `Semaine ${semaine.numeroSemaine}/${semaine.anneeSemaine}`;
        worksheet.getCell(cellsSemaines[id].cell).font = fontBold;
        worksheet.getCell(cellsSemaines[id].cell).alignment = alignCenter;
        worksheet.getCell(cellsSemaines[id].cell).border = borderTopCorners;
        
        worksheet.getCell(cellsSemainesDate[id].cell).value = `Du ${moment(semaine.dateLundi, "DDMMYYYY").format("DD/MM")} au ${moment(semaine.dateDimanche, "DDMMYYYY").format("DD/MM")}`;
        worksheet.getCell(cellsSemainesDate[id].cell).font = fontBold;
        worksheet.getCell(cellsSemainesDate[id].cell).alignment = alignCenter;
        worksheet.getCell(cellsSemainesDate[id].cell).border = borderBottomCorners;
        
        semaine.jours.forEach((jour) => {
            worksheet.getRow(8).getCell(jour.id + 2).value = Number(jour.numero);
            worksheet.getRow(8).getCell(jour.id + 2).numFmt = "00";
            worksheet.getRow(8).getCell(jour.id + 2).font = fontBold;
            worksheet.getRow(8).getCell(jour.id + 2).alignment = alignCenter;
            worksheet.getRow(8).getCell(jour.id + 2).border = allBorder;
        });
    });

    societes.forEach((societe) => {

        let lastLineSociete = worksheet.lastRow.number + 1;
        worksheet.getRow(lastLineSociete).getCell(1).value = { richText: [{ text : `Société ${societe.societe} ${societe.libelleSociete}`, font: { bold: true, color: { argb: "002096" } } }] };
        worksheet.mergeCells(lastLineSociete, 1, lastLineSociete, 2);

        societe.services.forEach((service) => {

            let lastLineService = worksheet.lastRow.number + 1;
            worksheet.getRow(lastLineService).getCell(1).value = { richText: [{ text : `Service ${service.service} ${service.libelleService}`, font: { bold: true, color: { argb: "002096" } } }] };
            worksheet.mergeCells(lastLineService, 1, lastLineService, 2);

            service.collaborateurs.forEach((collaborateur) => {

                let lastLineCollaborateur = worksheet.lastRow.number + 1;
                worksheet.getRow(lastLineCollaborateur).getCell(1).value = collaborateur.prenomNom;
                worksheet.getRow(lastLineCollaborateur).getCell(2).value = collaborateur.type;
                worksheet.getRow(lastLineCollaborateur).getCell(2).font = fontBold;

                collaborateur.infosJours.forEach((infoJour, id) => {

                    worksheet.getRow(lastLineCollaborateur).getCell(id + 3).fill = { ...legendPatterns, fgColor: { argb: legendColors[infoJour] } };
                    worksheet.getRow(lastLineCollaborateur).getCell(id + 3).border = allBorder;
                
                });

            });
            
        });
      
    });
}

export const modelizePlanningGestionTemps = (worksheet, headers, collaborateurs, tri) => {
    worksheet.views = [{state: "frozen", xSplit: 4, ySplit: 3}];

    const fontStyleBold = { bold: true };
    const fillStyleSemaine1 = { type: "pattern", pattern: "solid", fgColor: { argb: "8DB4E2" } };
    const fillStyleSemaine2 = { type: "pattern", pattern: "solid", fgColor: { argb: "C5D9F1" } };
    const fillStyleJour1 = { type: "pattern", pattern: "solid", fgColor: { argb: "FABF8F" } };
    const fillStyleJour2 = { type: "pattern", pattern: "solid", fgColor: { argb: "FDE9D9" } };

    worksheet.mergeCells("E1:BC1");
    worksheet.getCell("E1").value = headers?.enteteS1;
    worksheet.getCell("E1").font = fontStyleBold;
    worksheet.getCell("E1").fill = fillStyleSemaine1;
    worksheet.mergeCells("BD1:DB1");
    worksheet.getCell("BD1").value = headers?.enteteS2;
    worksheet.getCell("BD1").font = fontStyleBold;
    worksheet.getCell("BD1").fill = fillStyleSemaine2;
    worksheet.mergeCells("DC1:FA1");
    worksheet.getCell("DC1").value = headers?.enteteS3;
    worksheet.getCell("DC1").font = fontStyleBold;
    worksheet.getCell("DC1").fill = fillStyleSemaine1;
    worksheet.mergeCells("FB1:GZ1");
    worksheet.getCell("FB1").value = headers?.enteteS4;
    worksheet.getCell("FB1").font = fontStyleBold;
    worksheet.getCell("FB1").fill = fillStyleSemaine2;
    worksheet.mergeCells("HA1:IY1");
    worksheet.getCell("HA1").value = headers?.enteteS5;
    worksheet.getCell("HA1").font = fontStyleBold;
    worksheet.getCell("HA1").fill = fillStyleSemaine1;
    worksheet.mergeCells("IZ1:KX1");
    worksheet.getCell("IZ1").value = headers?.enteteS6;
    worksheet.getCell("IZ1").font = fontStyleBold;
    worksheet.getCell("IZ1").fill = fillStyleSemaine2;
    worksheet.mergeCells("KY1:MW1");
    worksheet.getCell("KY1").value = headers?.enteteS7;
    worksheet.getCell("KY1").font = fontStyleBold;
    worksheet.getCell("KY1").fill = fillStyleSemaine1;
    worksheet.mergeCells("MX1:OV1");
    worksheet.getCell("MX1").value = headers?.enteteS8;
    worksheet.getCell("MX1").font = fontStyleBold;
    worksheet.getCell("MX1").fill = fillStyleSemaine2;
    worksheet.mergeCells("OW1:QU1");
    worksheet.getCell("OW1").value = headers?.enteteS9;
    worksheet.getCell("OW1").font = fontStyleBold;
    worksheet.getCell("OW1").fill = fillStyleSemaine1;

    worksheet.mergeCells("F2:L2");
    worksheet.getCell("F2").value = headers?.enteteS1J1
    worksheet.getCell("F2").font = fontStyleBold;
    worksheet.getCell("F2").fill = fillStyleJour1;
    worksheet.mergeCells("M2:S2");
    worksheet.getCell("M2").value = headers?.enteteS1J2
    worksheet.getCell("M2").font = fontStyleBold;
    worksheet.getCell("M2").fill = fillStyleJour2;
    worksheet.mergeCells("T2:Z2");
    worksheet.getCell("T2").value = headers?.enteteS1J3
    worksheet.getCell("T2").font = fontStyleBold;
    worksheet.getCell("T2").fill = fillStyleJour1;
    worksheet.mergeCells("AA2:AG2");
    worksheet.getCell("AA2").value = headers?.enteteS1J4
    worksheet.getCell("AA2").font = fontStyleBold;
    worksheet.getCell("AA2").fill = fillStyleJour2;
    worksheet.mergeCells("AH2:AN2");
    worksheet.getCell("AH2").value = headers?.enteteS1J5
    worksheet.getCell("AH2").font = fontStyleBold;
    worksheet.getCell("AH2").fill = fillStyleJour1;
    worksheet.mergeCells("AO2:AU2");
    worksheet.getCell("AO2").value = headers?.enteteS1J6
    worksheet.getCell("AO2").font = fontStyleBold;
    worksheet.getCell("AO2").fill = fillStyleJour2;
    worksheet.mergeCells("AV2:BB2");
    worksheet.getCell("AV2").value = headers?.enteteS1J7
    worksheet.getCell("AV2").font = fontStyleBold;
    worksheet.getCell("AV2").fill = fillStyleJour1;

    worksheet.mergeCells("BE2:BK2");
    worksheet.getCell("BE2").value = headers?.enteteS2J1
    worksheet.getCell("BE2").font = fontStyleBold;
    worksheet.getCell("BE2").fill = fillStyleJour1;
    worksheet.mergeCells("BL2:BR2");
    worksheet.getCell("BL2").value = headers?.enteteS2J2
    worksheet.getCell("BL2").font = fontStyleBold;
    worksheet.getCell("BL2").fill = fillStyleJour2;
    worksheet.mergeCells("BS2:BY2");
    worksheet.getCell("BS2").value = headers?.enteteS2J3
    worksheet.getCell("BS2").font = fontStyleBold;
    worksheet.getCell("BS2").fill = fillStyleJour1;
    worksheet.mergeCells("BZ2:CF2");
    worksheet.getCell("BZ2").value = headers?.enteteS2J4
    worksheet.getCell("BZ2").font = fontStyleBold;
    worksheet.getCell("BZ2").fill = fillStyleJour2;
    worksheet.mergeCells("CG2:CM2");
    worksheet.getCell("CG2").value = headers?.enteteS2J5
    worksheet.getCell("CG2").font = fontStyleBold;
    worksheet.getCell("CG2").fill = fillStyleJour1;
    worksheet.mergeCells("CN2:CT2");
    worksheet.getCell("CN2").value = headers?.enteteS2J6
    worksheet.getCell("CN2").font = fontStyleBold;
    worksheet.getCell("CN2").fill = fillStyleJour2;
    worksheet.mergeCells("CU2:DA2");
    worksheet.getCell("CU2").value = headers?.enteteS2J7
    worksheet.getCell("CU2").font = fontStyleBold;
    worksheet.getCell("CU2").fill = fillStyleJour1;

    worksheet.mergeCells("DD2:DJ2");
    worksheet.getCell("DD2").value = headers?.enteteS3J1
    worksheet.getCell("DD2").font = fontStyleBold;
    worksheet.getCell("DD2").fill = fillStyleJour1;
    worksheet.mergeCells("DK2:DQ2");
    worksheet.getCell("DK2").value = headers?.enteteS3J2
    worksheet.getCell("DK2").font = fontStyleBold;
    worksheet.getCell("DK2").fill = fillStyleJour2;
    worksheet.mergeCells("DR2:DX2");
    worksheet.getCell("DR2").value = headers?.enteteS3J3
    worksheet.getCell("DR2").font = fontStyleBold;
    worksheet.getCell("DR2").fill = fillStyleJour1;
    worksheet.mergeCells("DY2:EE2");
    worksheet.getCell("DY2").value = headers?.enteteS3J4
    worksheet.getCell("DY2").font = fontStyleBold;
    worksheet.getCell("DY2").fill = fillStyleJour2;
    worksheet.mergeCells("EF2:EL2");
    worksheet.getCell("EF2").value = headers?.enteteS3J5
    worksheet.getCell("EF2").font = fontStyleBold;
    worksheet.getCell("EF2").fill = fillStyleJour1;
    worksheet.mergeCells("EM2:ES2");
    worksheet.getCell("EM2").value = headers?.enteteS3J6
    worksheet.getCell("EM2").font = fontStyleBold;
    worksheet.getCell("EM2").fill = fillStyleJour2;
    worksheet.mergeCells("ET2:EZ2");
    worksheet.getCell("ET2").value = headers?.enteteS3J7
    worksheet.getCell("ET2").font = fontStyleBold;
    worksheet.getCell("ET2").fill = fillStyleJour1;

    worksheet.mergeCells("FC2:FI2");
    worksheet.getCell("FC2").value = headers?.enteteS4J1
    worksheet.getCell("FC2").font = fontStyleBold;
    worksheet.getCell("FC2").fill = fillStyleJour1;
    worksheet.mergeCells("FJ2:FP2");
    worksheet.getCell("FJ2").value = headers?.enteteS4J2
    worksheet.getCell("FJ2").font = fontStyleBold;
    worksheet.getCell("FJ2").fill = fillStyleJour2;
    worksheet.mergeCells("FQ2:FW2");
    worksheet.getCell("FQ2").value = headers?.enteteS4J3
    worksheet.getCell("FQ2").font = fontStyleBold;
    worksheet.getCell("FQ2").fill = fillStyleJour1;
    worksheet.mergeCells("FX2:GD2");
    worksheet.getCell("FX2").value = headers?.enteteS4J4
    worksheet.getCell("FX2").font = fontStyleBold;
    worksheet.getCell("FX2").fill = fillStyleJour2;
    worksheet.mergeCells("GE2:GK2");
    worksheet.getCell("GE2").value = headers?.enteteS4J5
    worksheet.getCell("GE2").font = fontStyleBold;
    worksheet.getCell("GE2").fill = fillStyleJour1;
    worksheet.mergeCells("GL2:GR2");
    worksheet.getCell("GL2").value = headers?.enteteS4J6
    worksheet.getCell("GL2").font = fontStyleBold;
    worksheet.getCell("GL2").fill = fillStyleJour2;
    worksheet.mergeCells("GS2:GY2");
    worksheet.getCell("GS2").value = headers?.enteteS4J7
    worksheet.getCell("GS2").font = fontStyleBold;
    worksheet.getCell("GS2").fill = fillStyleJour1;

    worksheet.mergeCells("HB2:HH2");
    worksheet.getCell("HB2").value = headers?.enteteS5J1
    worksheet.getCell("HB2").font = fontStyleBold;
    worksheet.getCell("HB2").fill = fillStyleJour1;
    worksheet.mergeCells("HI2:HO2");
    worksheet.getCell("HI2").value = headers?.enteteS5J2
    worksheet.getCell("HI2").font = fontStyleBold;
    worksheet.getCell("HI2").fill = fillStyleJour2;
    worksheet.mergeCells("HP2:HV2");
    worksheet.getCell("HP2").value = headers?.enteteS5J3
    worksheet.getCell("HP2").font = fontStyleBold;
    worksheet.getCell("HP2").fill = fillStyleJour1;
    worksheet.mergeCells("HW2:IC2");
    worksheet.getCell("HW2").value = headers?.enteteS5J4
    worksheet.getCell("HW2").font = fontStyleBold;
    worksheet.getCell("HW2").fill = fillStyleJour2;
    worksheet.mergeCells("ID2:IJ2");
    worksheet.getCell("ID2").value = headers?.enteteS5J5
    worksheet.getCell("ID2").font = fontStyleBold;
    worksheet.getCell("ID2").fill = fillStyleJour1;
    worksheet.mergeCells("IK2:IQ2");
    worksheet.getCell("IK2").value = headers?.enteteS5J6
    worksheet.getCell("IK2").font = fontStyleBold;
    worksheet.getCell("IK2").fill = fillStyleJour2;
    worksheet.mergeCells("IR2:IX2");
    worksheet.getCell("IR2").value = headers?.enteteS5J7
    worksheet.getCell("IR2").font = fontStyleBold;
    worksheet.getCell("IR2").fill = fillStyleJour1;

    worksheet.mergeCells("JA2:JG2");
    worksheet.getCell("JA2").value = headers?.enteteS6J1
    worksheet.getCell("JA2").font = fontStyleBold;
    worksheet.getCell("JA2").fill = fillStyleJour1;
    worksheet.mergeCells("JH2:JN2");
    worksheet.getCell("JH2").value = headers?.enteteS6J2
    worksheet.getCell("JH2").font = fontStyleBold;
    worksheet.getCell("JH2").fill = fillStyleJour2;
    worksheet.mergeCells("JO2:JU2");
    worksheet.getCell("JO2").value = headers?.enteteS6J3
    worksheet.getCell("JO2").font = fontStyleBold;
    worksheet.getCell("JO2").fill = fillStyleJour1;
    worksheet.mergeCells("JV2:KB2");
    worksheet.getCell("JV2").value = headers?.enteteS6J4
    worksheet.getCell("JV2").font = fontStyleBold;
    worksheet.getCell("JV2").fill = fillStyleJour2;
    worksheet.mergeCells("KC2:KI2");
    worksheet.getCell("KC2").value = headers?.enteteS6J5
    worksheet.getCell("KC2").font = fontStyleBold;
    worksheet.getCell("KC2").fill = fillStyleJour1;
    worksheet.mergeCells("KJ2:KP2");
    worksheet.getCell("KJ2").value = headers?.enteteS6J6
    worksheet.getCell("KJ2").font = fontStyleBold;
    worksheet.getCell("KJ2").fill = fillStyleJour2;
    worksheet.mergeCells("KQ2:KW2");
    worksheet.getCell("KQ2").value = headers?.enteteS6J7
    worksheet.getCell("KQ2").font = fontStyleBold;
    worksheet.getCell("KQ2").fill = fillStyleJour1;

    worksheet.mergeCells("KZ2:LF2");
    worksheet.getCell("KZ2").value = headers?.enteteS7J1
    worksheet.getCell("KZ2").font = fontStyleBold;
    worksheet.getCell("KZ2").fill = fillStyleJour1;
    worksheet.mergeCells("LG2:LM2");
    worksheet.getCell("LG2").value = headers?.enteteS7J2
    worksheet.getCell("LG2").font = fontStyleBold;
    worksheet.getCell("LG2").fill = fillStyleJour2;
    worksheet.mergeCells("LN2:LT2");
    worksheet.getCell("LN2").value = headers?.enteteS7J3
    worksheet.getCell("LN2").font = fontStyleBold;
    worksheet.getCell("LN2").fill = fillStyleJour1;
    worksheet.mergeCells("LU2:MA2");
    worksheet.getCell("LU2").value = headers?.enteteS7J4
    worksheet.getCell("LU2").font = fontStyleBold;
    worksheet.getCell("LU2").fill = fillStyleJour2;
    worksheet.mergeCells("MB2:MH2");
    worksheet.getCell("MB2").value = headers?.enteteS7J5
    worksheet.getCell("MB2").font = fontStyleBold;
    worksheet.getCell("MB2").fill = fillStyleJour1;
    worksheet.mergeCells("MI2:MO2");
    worksheet.getCell("MI2").value = headers?.enteteS7J6
    worksheet.getCell("MI2").font = fontStyleBold;
    worksheet.getCell("MI2").fill = fillStyleJour2;
    worksheet.mergeCells("MP2:MV2");
    worksheet.getCell("MP2").value = headers?.enteteS7J7
    worksheet.getCell("MP2").font = fontStyleBold;
    worksheet.getCell("MP2").fill = fillStyleJour1;

    worksheet.mergeCells("MY2:NE2");
    worksheet.getCell("MY2").value = headers?.enteteS8J1
    worksheet.getCell("MY2").font = fontStyleBold;
    worksheet.getCell("MY2").fill = fillStyleJour1;
    worksheet.mergeCells("NF2:NL2");
    worksheet.getCell("NF2").value = headers?.enteteS8J2
    worksheet.getCell("NF2").font = fontStyleBold;
    worksheet.getCell("NF2").fill = fillStyleJour2;
    worksheet.mergeCells("NM2:NS2");
    worksheet.getCell("NM2").value = headers?.enteteS8J3
    worksheet.getCell("NM2").font = fontStyleBold;
    worksheet.getCell("NM2").fill = fillStyleJour1;
    worksheet.mergeCells("NT2:NZ2");
    worksheet.getCell("NT2").value = headers?.enteteS8J4
    worksheet.getCell("NT2").font = fontStyleBold;
    worksheet.getCell("NT2").fill = fillStyleJour2;
    worksheet.mergeCells("OA2:OG2");
    worksheet.getCell("OA2").value = headers?.enteteS8J5
    worksheet.getCell("OA2").font = fontStyleBold;
    worksheet.getCell("OA2").fill = fillStyleJour1;
    worksheet.mergeCells("OH2:ON2");
    worksheet.getCell("OH2").value = headers?.enteteS8J6
    worksheet.getCell("OH2").font = fontStyleBold;
    worksheet.getCell("OH2").fill = fillStyleJour2;
    worksheet.mergeCells("OO2:OU2");
    worksheet.getCell("OO2").value = headers?.enteteS8J7
    worksheet.getCell("OO2").font = fontStyleBold;
    worksheet.getCell("OO2").fill = fillStyleJour1;
    
    worksheet.mergeCells("OX2:PD2");
    worksheet.getCell("OX2").value = headers?.enteteS9J1
    worksheet.getCell("OX2").font = fontStyleBold;
    worksheet.getCell("OX2").fill = fillStyleJour1;
    worksheet.mergeCells("PE2:PK2");
    worksheet.getCell("PE2").value = headers?.enteteS9J2
    worksheet.getCell("PE2").font = fontStyleBold;
    worksheet.getCell("PE2").fill = fillStyleJour2;
    worksheet.mergeCells("PL2:PR2");
    worksheet.getCell("PL2").value = headers?.enteteS9J3
    worksheet.getCell("PL2").font = fontStyleBold;
    worksheet.getCell("PL2").fill = fillStyleJour1;
    worksheet.mergeCells("PS2:PY2");
    worksheet.getCell("PS2").value = headers?.enteteS9J4
    worksheet.getCell("PS2").font = fontStyleBold;
    worksheet.getCell("PS2").fill = fillStyleJour2;
    worksheet.mergeCells("PZ2:QF2");
    worksheet.getCell("PZ2").value = headers?.enteteS9J5
    worksheet.getCell("PZ2").font = fontStyleBold;
    worksheet.getCell("PZ2").fill = fillStyleJour1;
    worksheet.mergeCells("QG2:QM2");
    worksheet.getCell("QG2").value = headers?.enteteS9J6
    worksheet.getCell("QG2").font = fontStyleBold;
    worksheet.getCell("QG2").fill = fillStyleJour2;
    worksheet.mergeCells("QN2:QT2");
    worksheet.getCell("QN2").value = headers?.enteteS9J7
    worksheet.getCell("QN2").font = fontStyleBold;
    worksheet.getCell("QN2").fill = fillStyleJour1;

    worksheet.getCell("A3").value = "Matricule"
    worksheet.getCell("B3").value = "Type"
    worksheet.getCell("C3").value = "Nom, Prénom"
    worksheet.getCell("D3").value = "Badge"

    for(let iSemaine = 0; iSemaine < 9; iSemaine++) {
    
        worksheet.getRow(3).getCell(5 + (51*iSemaine)).value = "Base Hebdo"
        worksheet.getRow(3).getCell(55 + (51*iSemaine)).value = "Total"

        for(let idJour = 0; idJour < 7; idJour++) {
            worksheet.getRow(3).getCell(6 + (51*iSemaine) + (7*idJour)).value = "Type de jour"
            worksheet.getRow(3).getCell(7 + (51*iSemaine) + (7*idJour)).value = "Activité"
            worksheet.getRow(3).getCell(8 + (51*iSemaine) + (7*idJour)).value = "Présence"
            worksheet.getRow(3).getCell(9 + (51*iSemaine) + (7*idJour)).value = "Plage horaire"
            worksheet.getRow(3).getCell(10 + (51*iSemaine) + (7*idJour)).value = "Type de travail"
            worksheet.getRow(3).getCell(11 + (51*iSemaine) + (7*idJour)).value = "Horaire"
            worksheet.getRow(3).getCell(12 + (51*iSemaine) + (7*idJour)).value = "Absence"
        }
    }

    let collaborateursTried = collaborateurs;

    if(tri === "MAT") {
        collaborateursTried = tidy(collaborateurs, arrange(asc("matricule")));
    }

    if(tri === "NOM") {
        collaborateursTried = tidy(collaborateurs, arrange(asc("nom")));
    }

    collaborateursTried.forEach((collaborateur) => {
        let lastLineSociete = worksheet.lastRow.number + 1;
        worksheet.getRow(lastLineSociete).getCell(1).value = collaborateur.matricule;
        worksheet.getRow(lastLineSociete).getCell(2).value = collaborateur.type === "P" ? "Personnel" : "Intérimaire";
        worksheet.getRow(lastLineSociete).getCell(3).value = collaborateur.nomPrenom;
        worksheet.getRow(lastLineSociete).getCell(4).value = collaborateur.badge;

        for(let iSemaine = 0; iSemaine < 9; iSemaine++) {
    
            worksheet.getRow(lastLineSociete).getCell(5 + (51*iSemaine)).value = collaborateur[`baseHedboS${iSemaine + 1}`]
            worksheet.getRow(lastLineSociete).getCell(55 + (51*iSemaine)).value = collaborateur[`totalS${iSemaine + 1}`]
    
            for(let idJour = 0; idJour < 7; idJour++) {
                worksheet.getRow(lastLineSociete).getCell(6 + (51*iSemaine) + (7*idJour)).value = collaborateur[`typeJourS${iSemaine + 1}J${idJour + 1}`]
                worksheet.getRow(lastLineSociete).getCell(7 + (51*iSemaine) + (7*idJour)).value = collaborateur[`activiteS${iSemaine + 1}J${idJour + 1}`]
                worksheet.getRow(lastLineSociete).getCell(8 + (51*iSemaine) + (7*idJour)).value = collaborateur[`presenceS${iSemaine + 1}J${idJour + 1}`]
                worksheet.getRow(lastLineSociete).getCell(9 + (51*iSemaine) + (7*idJour)).value = collaborateur[`plageS${iSemaine + 1}J${idJour + 1}`]
                worksheet.getRow(lastLineSociete).getCell(10 + (51*iSemaine) + (7*idJour)).value = collaborateur[`typeTravailS${iSemaine + 1}J${idJour + 1}`]
                worksheet.getRow(lastLineSociete).getCell(11 + (51*iSemaine) + (7*idJour)).value = collaborateur[`horaireS${iSemaine + 1}J${idJour + 1}`]
                worksheet.getRow(lastLineSociete).getCell(12 + (51*iSemaine) + (7*idJour)).value = collaborateur[`absenceS${iSemaine + 1}J${idJour + 1}`]
            }
        }
    })
   
}

export const modelizeMouvements = (worksheet, mouvements, detail) => {
    const columns = [
        { header: 'Société', key: 'societe', width: 10 },
        { header: 'Matricule', key: 'matricule', width: 10 },
        { header: 'Type de Personnel', key: 'typePersonnel', width: 15 },
        { header: 'Nom', key: 'nom', width: 20 },
        { header: 'Prénom', key: 'prenom', width: 20 },
        { header: 'Badge', key: 'badge', width: 10 },
        { header: 'Evénement', key: 'evt', width: 20 },
        { header: 'Nombre', key: 'nombre', width: 10 },
        { header: detail === "O" ? 'Ecart' : 'Heure moyenne d\'arrivée', key: 'ecart', width: 10 },
        { header: 'Date', key: 'date', width: 10 },
        { header: 'Heure Réelle', key: 'heureReelle', width: 15 },
        { header: 'Heure Ajustée', key: 'heureAjustee', width: 15 },
        { header: 'Anomalie', key: 'anomalie', width: 10 },
        { header: 'Établissement', key: 'codeEtablissement', width: 15 },
        { header: 'Établissement : Libellé', key: 'libelleEtablissement', width: 20 },
        { header: 'Direction', key: 'codeDirection', width: 15 },
        { header: 'Direction : Libellé', key: 'libelleDirection', width: 20 },
        { header: 'Service', key: 'codeService', width: 15 },
        { header: 'Service : Libellé', key: 'libelleService', width: 20 },
        { header: 'Secteur', key: 'codeSecteur', width: 15 },
        { header: 'Secteur : Libellé', key: 'libelleSecteur', width: 20 }
    ];
    worksheet.columns = columns;

    mouvements.forEach((mouvement, id) => {
        const dateString = mouvement.date.length < 8 ? "0" + mouvement.date : mouvement.date;
        const dateMouvement = moment(dateString, "DDMMYYYY").isValid() ? moment(dateString, "DDMMYYYY").format("DD/MM/YYYY") : "";
        const heureReelleString = mouvement.heureReelle.length < 4 ? "0" + mouvement.heureReelle : mouvement.heureReelle;
        const heureReelleMouvement = moment(heureReelleString, "HHmm").isValid() ? moment(heureReelleString, "HHmm").format("HH:mm") : "";
        const heureAjusteeString = mouvement.heureAjustee.length < 4 ? "0" + mouvement.heureAjustee : mouvement.heureAjustee;
        const heureAjusteeMouvement = moment(heureAjusteeString, "HHmm").isValid() ? moment(heureAjusteeString, "HHmm").format("HH:mm") : "";
        let ecartMouvement = "00:00"
        if(mouvement.ecart.length === 4) {
            ecartMouvement = moment(mouvement.ecart, "HHmm").format("HH:mm");
        }
        if(mouvement.ecart.length === 3) {
            let ecartString3 = "0" + mouvement.ecart;
            ecartMouvement = moment(ecartString3, "HHmm").format("HH:mm");
        }
        if(mouvement.ecart.length === 2) {
            let ecartString3 = "00" + mouvement.ecart;
            ecartMouvement = moment(ecartString3, "HHmm").format("HH:mm");
        }
        if(mouvement.ecart.length === 1) {
            let ecartString3 = "000" + mouvement.ecart;
            ecartMouvement = moment(ecartString3, "HHmm").format("HH:mm");
        }

        const row = {
            societe: mouvement.societe,
            matricule: mouvement.matricule,
            typePersonnel: mouvement.typePersonnel,
            nom: mouvement.nom,
            prenom: mouvement.prenom,
            badge: mouvement.badge,
            evt: mouvement.evt,
            nombre: Number(mouvement.nombre),
            ecart: ecartMouvement,
            date: dateMouvement,
            heureReelle: mouvement.heureReelle === "0" ? "" : heureReelleMouvement,
            heureAjustee: mouvement.heureAjustee === "0" ? "" : heureAjusteeMouvement,
            anomalie: mouvement.anomalie,
            codeEtablissement: mouvement.codeEtablissement,
            libelleEtablissement: mouvement.libelleEtablissement,
            codeDirection: mouvement.codeDirection,
            libelleDirection: mouvement.libelleDirection,
            codeService: mouvement.codeService,
            libelleService: mouvement.libelleService,
            codeSecteur: mouvement.codeSecteur,
            libelleSecteur: mouvement.libelleSecteur
        }

        worksheet.addRow(row)
    });
}