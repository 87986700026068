import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Badge, Factory, Person, Share, WhereToVote } from "@mui/icons-material";
import PropTypes from "prop-types";
import * as colCreGesTemActions from "@reducers/collaborateurs/colCreGesTemReducer";
import { useStateCollaborateurs } from "@hooks/collaborateurs/hooksStates";
import { MenuTrieContainer } from "@components/Containers";
import { trierFwdc01 } from "../utils";
import { TYPES_COLLABORATEURS } from "@constants/collaborateursConstants";

const optionsTrierFwgtpe = [
    {id: 1, label: "Par nom", keyAsc: "nomAsc", keyDesc: "nomDesc", icon: <Person fontSize="small" />},
    {id: 2, label: "Par matricule", keyAsc: "matriculeAsc", keyDesc: "matriculeDesc", icon: <Person fontSize="small" />},
    {id: 3, label: "Par badge", keyAsc: "badgeAsc", keyDesc: "badgeDesc", icon: <Badge fontSize="small" />},
    {id: 4, label: "Par société", keyAsc: "societeAsc", keyDesc: "societeDesc", icon: <Factory fontSize="small" />},
    {id: 5, label: "Par service", keyAsc: "serviceAsc", keyDesc: "serviceDesc", icon: <Share fontSize="small" />},
    {id: 6, label: "Par type de personnel", keyAsc: "typePersonnelAsc", keyDesc: "typePersonnelDesc", icon: <WhereToVote fontSize="small" />},
];

const CollaborateursFwdc01Trie = (props) => {
    const { stateType, anchorEl, setAnchorEl } = props;
    const stateCollaborateurs = useStateCollaborateurs(stateType);
    const dispatch = useDispatch();

    const trier = useCallback((keyTrie) => {
        const data = [...stateCollaborateurs.collaborateurs];
        const dataTried = trierFwdc01(keyTrie, data);
        if(stateType === TYPES_COLLABORATEURS.GESTION_TEMPS_CREDIT) {
            dispatch(colCreGesTemActions.trier(dataTried));
        }
    }, [dispatch, stateCollaborateurs.collaborateurs, stateType]);

    return <MenuTrieContainer anchorEl={anchorEl} setAnchorEl={setAnchorEl} options={optionsTrierFwgtpe} trier={trier} />;
}

CollaborateursFwdc01Trie.defaultProps = {
    anchorEl: null,
    setAnchorEl: () => {}
}

CollaborateursFwdc01Trie.propTypes = {
    stateType: PropTypes.string.isRequired,
    anchorEl: PropTypes.node,
    setAnchorEl: PropTypes.func
}

export default CollaborateursFwdc01Trie;