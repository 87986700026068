import { useSelector } from "react-redux";
import { selectDabGesAbsCol } from "@selectors";

export const useTitle = (code, jourHeure) => {
    const stateDemandeAbsence = useSelector(selectDabGesAbsCol)
    let title = "";
    const typeAbsence = stateDemandeAbsence.typesAbsence.find((type) => type.codeAbsence === code);
    if(typeAbsence) {
        const condition1 = typeAbsence.masqueSaisie === "H";
        const condition2 = typeAbsence.masqueSaisie === "J" && jourHeure;
        if(condition1 || condition2) {
            title = "inputs.date_selection_required";
        } else {             
            title = "inputs.start_date_selection_required";
        }
    } else {
        title = "inputs.start_date_selection_required";
    }

    return title;
};

export const useMotif = (typeAbsence) => {
    if(typeAbsence) {
        const condition1 = typeAbsence.motifAbsenceObligatoire === "O";
        if(condition1) {
            return { label: "inputs.request_reason_required", obligatoire: true, display: true };
        } else {
            return { label: "inputs.request_reason", obligatoire: false, display: true };
        }
    } else {
        return { label: "inputs.request_reason", obligatoire: false, display: false };
    }
};

export const usePieceJointe = (typeAbsence) => {
    if(typeAbsence) {
        const condition1 = typeAbsence.pieceJointe === "1";
        if(condition1) {
            return { label: "inputs.attachment_required", obligatoire: true, display: true };
        } else {
            return { label: "inputs.attachment", obligatoire: false, display: true };
        }
    } else {
        return { label: "inputs.attachment", obligatoire: false, display: false}
    }
    
};

export const useLargeur = (typeAbsence) => {
    if(typeAbsence) {
        const condition1 = typeAbsence.masqueSaisie === "J" && typeAbsence.journeeFractionnable === "O";
        return condition1 ? 8 : 12;
    } else {
        return 8;
    }
};  

export const useDateReprise = (typeAbsence, jourHeure) => {
    if(typeAbsence) {
        const condition1 = typeAbsence.masqueSaisie === "J" && typeAbsence.journeeFractionnable !== "O";
        const condition2 = typeAbsence.masqueSaisie === "J" && typeAbsence.journeeFractionnable === "O" && !jourHeure;
        return (condition1 || condition2);
    } else { 
        return false;
    }
};

export const useJourHeure = (typeAbsence) => {
    if(typeAbsence) {
        const condition1 = typeAbsence.masqueSaisie === "J" && typeAbsence.journeeFractionnable === "O";
        return condition1;
    } else {
        return false;
    }
};

export const useAmpm = (typeAbsence, jourHeure) => {
    if(typeAbsence) {
        const condition1 = typeAbsence.masqueSaisie === "H" && typeAbsence.typeFraction === "1";
        const condition2 = typeAbsence.masqueSaisie === "J" && typeAbsence.journeeFractionnable === "O" && jourHeure && typeAbsence.typeFraction === "1"
        return (condition1 || condition2);
    } else {
        return false;
    }
};

export const usePlage = (typeAbsence, jourHeure) => {
    if(typeAbsence) {
        const condition1 = typeAbsence.masqueSaisie === "H" && typeAbsence.typeFraction === "2";
        const condition2 = typeAbsence.masqueSaisie === "J" && typeAbsence.journeeFractionnable === "O" && jourHeure && typeAbsence.typeFraction === "2";
        return (condition1 || condition2);
    } else {
        return false;
    }
};

export const useHeures = (typeAbsence, jourHeure) => {
    if(typeAbsence) {
        const condition1 = typeAbsence.masqueSaisie === "H" && typeAbsence.typeFraction === "3";
        const condition2 = typeAbsence.masqueSaisie === "J" && typeAbsence.journeeFractionnable === "O" && jourHeure && typeAbsence.typeFraction === "3";
        return (condition1 || condition2);
    } else {
        return false;
    }
};