import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { usePlanGesTemDay } from "@hooks/gestionTemps/planning/hooksUtils";
import { heurify } from "@utils";
import { CheckHookFormInput } from "@components/Inputs";
import { blueColor, greenColor, whiteColor, yellowColor } from "@styles";
import { PlanningTimeInput, PlanningTypeTravailInput } from "@components/Inputs/GestionTemps";
import { TextContainer } from "@components/Containers";
import { HoraireMenuButton } from "@components/Buttons/Horaires";

const PlanningItemsTable = (props) => {
    const { idJour, libelle, modelize } = props; 
    const { 
        colorHoraire, 
        horaire, 
        absence, 
        totalHeuresAbsence,
        totalHeures,
        authorized,
        authorizedTypeTravail,
        typesTravails,
        plages
    } = usePlanGesTemDay(idJour);

    return (
        <Stack spacing={0.5} direction="row" bgcolor={greenColor["015"]} borderRadius={2} p={0.25}>
            <Box flexGrow={1}>
                <Grid container spacing={0.5} columns={19}>
                    <Grid item xs={1.2}>
                        <Box bgcolor={greenColor["015"]} borderRadius={2} minHeight="100%" display="flex" justifyContent="center" alignItems="center">
                            <Typography fontSize="small" fontWeight="bold">{libelle}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Box p={0.5} bgcolor={whiteColor[5]} borderRadius={2} minHeight="100%" boxSizing="border-box" display="flex" alignItems="center" justifyContent="center">
                            <Typography fontSize="small" color={colorHoraire}>{horaire}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1.5}>
                        <Box borderRadius={2} bgcolor={whiteColor[5]} minHeight="100%" boxSizing="border-box" display="flex" alignItems="center" justifyContent="center">
                            <Grid container spacing={0.5}>
                                <Grid item xs={6}>
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        <Typography fontSize="small">{absence}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        <Typography fontSize="small">{heurify(totalHeuresAbsence, "h", false)}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box bgcolor={yellowColor[3]} borderRadius={2} p={0.5}>
                            <Grid container spacing={0.5}>
                                {authorized ? 
                                    <>
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <PlanningTimeInput idPlage={1} idJour={idJour} idLabel="debut" label={"Début"} />
                                            </Grid>
                                        </Grid> 
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <PlanningTimeInput idPlage={1} idJour={idJour} idLabel="arret" label={"Fin"} />
                                            </Grid>
                                        </Grid>    
                                    </>
                                :
                                    <>
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <TextContainer bgColor={whiteColor[9]} verticalAlign="center" textSize={15} p={1} height={32} noWrap>{heurify(plages.debut1)}</TextContainer>
                                            </Grid>
                                        </Grid> 
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <TextContainer bgColor={whiteColor[9]} verticalAlign="center" textSize={15} p={1} height={32} noWrap>{heurify(plages.arret1)}</TextContainer>
                                            </Grid>
                                        </Grid>
                                        
                                    </> 
                                }   
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        {(authorized && authorizedTypeTravail) ? 
                                            <PlanningTypeTravailInput idPlage={1} idJour={idJour} />
                                        :
                                            <TextContainer bgColor={whiteColor[9]} verticalAlign="center" textSize={15} p={1} height={32} noWrap>{typesTravails.plage1}</TextContainer>
                                        }
                                    </Grid>
                                </Grid> 
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box bgcolor={blueColor["03"]} borderRadius={2} p={0.5}>
                            <Grid container spacing={0.5}>
                                {authorized ? 
                                    <>
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <PlanningTimeInput idPlage={2} idJour={idJour} idLabel="debut" label={"Début"} />
                                            </Grid>
                                        </Grid> 
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <PlanningTimeInput idPlage={2} idJour={idJour} idLabel="arret" label={"Fin"} />
                                            </Grid>
                                        </Grid>
                                    </>
                                :
                                    <>
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <TextContainer bgColor={whiteColor[9]} verticalAlign="center" textSize={15} p={1} height={32} noWrap>{heurify(plages.debut2)}</TextContainer>
                                            </Grid>
                                        </Grid> 
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <TextContainer bgColor={whiteColor[9]} verticalAlign="center" textSize={15} p={1} height={32} noWrap>{heurify(plages.arret2)}</TextContainer>
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        {(authorized && authorizedTypeTravail) ? 
                                            <PlanningTypeTravailInput idPlage={2} idJour={idJour} />
                                        :
                                            <TextContainer bgColor={whiteColor[9]} verticalAlign="center" textSize={15} p={1} height={32} noWrap>{typesTravails.plage2}</TextContainer>
                                        }
                                    </Grid>
                                </Grid> 
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box bgcolor={blueColor["03"]} borderRadius={2} p={0.5}>
                            <Grid container spacing={0.5}>
                                {authorized ? 
                                    <>
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <PlanningTimeInput idPlage={3} idJour={idJour} idLabel="debut" label={"Début"} />
                                            </Grid>
                                        </Grid> 
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <PlanningTimeInput idPlage={3} idJour={idJour} idLabel="arret" label={"Fin"} />
                                            </Grid>
                                        </Grid>
                                    </>
                                :
                                    <>
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <TextContainer bgColor={whiteColor[9]} verticalAlign="center" textSize={15} p={1} height={32} noWrap>{heurify(plages.debut3)}</TextContainer>
                                            </Grid>
                                        </Grid> 
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <TextContainer bgColor={whiteColor[9]} verticalAlign="center" textSize={15} p={1} height={32} noWrap>{heurify(plages.arret3)}</TextContainer>
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        {(authorized && authorizedTypeTravail) ? 
                                            <PlanningTypeTravailInput idPlage={3} idJour={idJour} />
                                        :
                                            <TextContainer bgColor={whiteColor[9]} verticalAlign="center" textSize={15} p={1} height={32} noWrap>{typesTravails.plage3}</TextContainer>
                                        }
                                    </Grid>
                                </Grid> 
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box bgcolor={yellowColor[3]} borderRadius={2} p={0.5}>
                            <Grid container spacing={0.5}>
                                {authorized ? 
                                    <>
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <PlanningTimeInput idPlage={4} idJour={idJour} idLabel="debut" label={"Début"} />
                                            </Grid>
                                        </Grid> 
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <PlanningTimeInput idPlage={4} idJour={idJour} idLabel="arret" label={"Fin"} />
                                            </Grid>
                                        </Grid>
                                    </>
                                :
                                    <>
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <TextContainer bgColor={whiteColor[9]} verticalAlign="center" textSize={15} p={1} height={32} noWrap>{heurify(plages.debut4)}</TextContainer>
                                            </Grid>
                                        </Grid> 
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <TextContainer bgColor={whiteColor[9]} verticalAlign="center" textSize={15} p={1} height={32} noWrap>{heurify(plages.arret4)}</TextContainer>
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        {(authorized && authorizedTypeTravail) ? 
                                            <PlanningTypeTravailInput idPlage={4} idJour={idJour} />
                                        :
                                            <TextContainer bgColor={whiteColor[9]} verticalAlign="center" textSize={15} p={1} height={32} noWrap>{typesTravails.plage4}</TextContainer>
                                        }
                                    </Grid>
                                </Grid> 
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Box p={0.5} bgcolor={whiteColor[5]} borderRadius={2} minHeight="100%" boxSizing="border-box" display="flex" alignItems="center" justifyContent="center">
                            <Typography fontSize="small">{heurify(totalHeures, "h", false)}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1.15}>
                        <Box flexGrow={1} display="flex" alignItems="center" justifyContent="center" spacing={0.25} borderRadius={2} bgcolor={whiteColor[5]} direction={"row"} minHeight="100%" boxSizing="border-box">
                            <Tooltip title="Rattachement">
                                <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                                    {authorized && <CheckHookFormInput name={`rattachement${idJour + 1}`} size={16} fullWidth positionnement="center" />}
                                </Box>
                            </Tooltip>
                        </Box>
                    </Grid>
                    <Grid item xs={1.15}>
                        <Box flexGrow={1} display="flex" alignItems="center" justifyContent="center" spacing={0.25} borderRadius={2} bgcolor={whiteColor[5]} direction={"row"} minHeight="100%" boxSizing="border-box">
                            {authorized && <HoraireMenuButton idJour={idJour} modelizePlanning={modelize}  />}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
    )
}

PlanningItemsTable.defaultProps = {
    modelize: () => {}
}

PlanningItemsTable.propTypes = {
    idJour: PropTypes.number.isRequired,
    libelle: PropTypes.string.isRequired,
    modelize: PropTypes.func
}

export default PlanningItemsTable;