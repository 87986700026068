import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import PropTypes from "prop-types";
import { selectDocApp } from "@selectors";
import * as docAppActions from "@reducers/documents/docAppReducer"
import moment from "moment";
import { schemaFilterDocument } from "../validations";
import { DateHookFormInput, SelectHookFormInput, TextHookFormInput } from "@components/Inputs";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";
import { filtrerDocApp } from "../filtres";
import { NatureDocumentsHookFormInput } from "@components/Inputs/Documents";

const colonneOptions = [
    { id: 1, value: "date", label: "Date" },
    { id: 2, value: "nature", label: "Nature" },
    { id: 3, value: "nom", label: "Nom" }
]

const operateurOptions = [
    { id: 1, value: "egale", label: "Egale" },
    { id: 2, value: "superieur", label: "Supérieure" },
    { id: 3, value: "inferieur", label: "Inférieure" }
]

const defaultValues = {
    colonne: "",
    operateur: "egale",
    date: null,
    txt: ""
}

const FiltreDocumentsForm = (props) => {
    const { setOpen } = props;
    const stateDocuments = useSelector(selectDocApp);
    const methods = useForm({ mode: "all", defaultValues: defaultValues, resolver: joiResolver(schemaFilterDocument) });
    const colonne = methods.watch("colonne");
    const dispatch = useDispatch();

    useEffect(() => {
        if(colonne === "date") {
            methods.resetField("date", moment());
        }
        if(colonne === "nature" || colonne === "nom") {
            methods.resetField("txt", "");
        }
    }, [colonne, methods]);

    const filtrer = useCallback((data) => {
        setOpen(false);
        const documentsFiltered = filtrerDocApp(data, stateDocuments.documents);
        dispatch(docAppActions.filtrer(documentsFiltered));
    }, [setOpen, stateDocuments.documents, dispatch]);

    return (
        <FormProvider {...methods}>
            <Stack spacing={1} width="100%">
                <SelectHookFormInput name="colonne" label="Colonnes" options={colonneOptions} />
                {{
                    date: 
                        <>
                            <SelectHookFormInput name="operateur" label="Opérateur" options={operateurOptions} />
                            <DateHookFormInput name="date" label="Date du document" />
                        </>,
                    nature: <NatureDocumentsHookFormInput name="txt" label="Nature du document" />,
                    nom: <TextHookFormInput name="txt" label="Nom" />
                }[colonne]}
            </Stack> 
            <Box sx={styles.boxButtonsMenuFiltre}>
                <Stack direction="row" spacing={1}>
                    <Tooltip title="Valider">
                        <IconButton sx={styles.buttonStyleGreen} onClick={() => methods.handleSubmit(filtrer)()}>
                            <Check fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Fermer">
                        <IconButton sx={styles.buttonStyleRed} onClick={() => setOpen(false)}>
                            <Close fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Box>  
        </FormProvider>     
    );
}

FiltreDocumentsForm.defaultProps = {
    setOpen: () => {}
}

FiltreDocumentsForm.propTypes = {
    setOpen: PropTypes.func
}

const styles = {
    boxButtonsMenuFiltre: {
        justifyContent: "center",
        display: "flex"
    },
    buttonStyleGreen: {
        boxShadow: "none", 
        backgroundColor: greenColor["02"],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: greenColor["03"],
            color: whiteColor[9],
        }
    },
    buttonStyleRed: {
        boxShadow: "none", 
        backgroundColor: redColor[1],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: redColor[2],
            color: whiteColor[9],
        }
    },
    skeletonStyle: {
        borderRadius: 2,
        width: "100%", 
        height: 40
    },
    errorBoxStyle: {
        bgcolor: redColor[0], 
        height: 48, 
        borderRadius: 2, 
        alignItems: "center", 
        justifyContent: "center", 
        display: "flex"
    }
}

export default FiltreDocumentsForm;