import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useDrtDlgApp } from "@hooks/delegations/hooksQueries";
import * as dlgAppActions from "@reducers/delegations/dlgAppReducer"
import DroitsActionsBar from "./ActionsBar/DroitsActionsBar";
import { DroitsList, DroitsListMobile } from "./Lists";
import { useMedia } from "react-use";


const DroitsPage = () => {
    const { data, isLoading, isFetching, isError, error } = useDrtDlgApp();
    const dispatch = useDispatch();
    const isMobile = useMedia('(max-width: 650px)');

    const goTo = useCallback(() => {
        dispatch(dlgAppActions.goTo("list"));
    }, [dispatch])

    return (
        <>
            <DroitsActionsBar
                isLoading={isLoading}
                goTo={goTo}
            />
            {isMobile ? 
                <DroitsListMobile 
                    data={data}
                    isLoading={isLoading || isFetching}
                    isError={isError}
                    errorMessage={error?.message}
                />
            :
                <DroitsList 
                    data={data}
                    isLoading={isLoading || isFetching}
                    isError={isError}
                    errorMessage={error?.message}
                />
            }
        </>
    );
}

export default DroitsPage;