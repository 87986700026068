import { useCallback, useState } from "react";
import { Box, Stack, Tooltip } from "@mui/material";
import { Cancel, Visibility, VisibilityOff } from "@mui/icons-material";
import { useController, useFormContext, useFormState } from "react-hook-form";
import PropTypes from "prop-types";
import { greenColor, redColor, TextFieldStyled } from "@styles";
import { useTranslation } from "react-i18next";
import { IconButtonBase } from "@components/Buttons";

const SecretHookFormInput = ({ 
    name = "", 
    label = "", 
    autoFocus = false, 
    maxLength = 10, 
    readOnly = false, 
    autocomplete = true,
    errorPlacement = "left",
    variant = "white"
}) => {
    const { control, resetField } = useFormContext();
    const { field } = useController({ name, control });
    const { errors } = useFormState({ control });    
    const [showSecret, setShowSecret] = useState(false);
    const { t } = useTranslation("global");

    const handleShowSecret = useCallback(() => {
        setShowSecret((prevShowSecret) => !prevShowSecret);
    }, []);

    const handleReset = useCallback(() => {
        resetField(name, { defaultValue: "" });
    }, [name, resetField]); 
    
    return (  
        <Tooltip 
            open={Boolean(errors?.[name])} 
            title={t(errors?.[name]?.message)} 
            slotProps={{
                tooltip: {
                    sx: {
                        backgroundColor: redColor[5]
                    }
                },
                arrow: {
                    sx: {
                        color: redColor[5]
                    }
                }
            }}
            placement={errorPlacement}
            arrow
        >
            <Stack direction="row" spacing={0.5} borderRadius={2} bgcolor={greenColor["05"]}>
                <Box flexGrow={1}>
                    <TextFieldStyled
                        fullWidth
                        label={label}
                        size="small"
                        autoFocus={autoFocus}
                        type={showSecret ? "text" : "password"}
                        autoComplete={autocomplete ? "new-password" : "" }
                        inputProps={{ maxLength: maxLength, readOnly: readOnly }}
                        variantApp={variant}
                        {...field}
                    />
                </Box>
                <Box pr={0.5} display="flex" alignItems="center" justifyContent="center">
                    <Stack direction="row" spacing={0.5}>
                        <IconButtonBase 
                            variant="white5Grey9White9" 
                            icon={<Cancel fontSize="small" />} 
                            title="Vider le champ" 
                            action={() => handleReset()} 
                        />
                        <IconButtonBase 
                            variant="white5Grey9White9" 
                            icon={showSecret ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />} 
                            title={showSecret ? "Cacher le mot de passe" : "Voir le mot de passe"} 
                            action={() => handleShowSecret()}
                        />
                    </Stack>
                </Box>
            </Stack>
        </Tooltip>
    );
}

SecretHookFormInput.propTypes = {
    name: PropTypes.string, 
    label: PropTypes.string, 
    autoFocus: PropTypes.bool, 
    maxLength: PropTypes.number, 
    readOnly: PropTypes.bool, 
    autocomplete: PropTypes.bool,
    errorPlacement: PropTypes.string,
    variant: PropTypes.string
}

export default SecretHookFormInput;