import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { HeaderDialogContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { useTranslation } from "react-i18next";

const AnnulationDemandesAbsenceActions = (props) => {
    const { isLoading, isError, setOpen, handleConfirm } = props;
    const { t } = useTranslation("global");

    return (
        <HeaderDialogContainer 
            actionsLeft={
                <IconButtonBase
                    variant="white5Grey9White9" 
                    icon={<Check fontSize="small" />} 
                    title={t("buttons.validate")} 
                    disabled={isLoading || isError} 
                    action={() => handleConfirm()} 
                />
            }    
            title={t("titles.cancellation_absence_request")} 
            close={() => setOpen(false)}     
        />
    );
}

AnnulationDemandesAbsenceActions.defaultProps = {
    isLoading: true, 
    isError: false, 
    setOpen: () => {}, 
    handleConfirm: () => {}
}

AnnulationDemandesAbsenceActions.propTypes = {
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool, 
    setOpen: PropTypes.func, 
    handleConfirm: PropTypes.func
}

export default AnnulationDemandesAbsenceActions;