import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activeTab: "MVTIND"
};

const mvtGesTemSlice = createSlice({
    name: 'mvtGesTem',
    initialState: initialState,
    reducers: {
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
        reset: () => initialState
    }
})

const { actions, reducer } = mvtGesTemSlice
export const { setActiveTab, reset } = actions
export default reducer
