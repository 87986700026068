import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { HeaderDialogContainer } from "@components/Containers";

const DeleteActions = ({ 
    isLoading = false, 
    setOpen = () => {}, 
    deleteDelegation = () => {} 
}) => {
    return (
        <HeaderDialogContainer  
            actionsLeft={
                <IconButtonBase 
                    variant="dialogButton"
                    icon={<Check fontSize="small" />} 
                    title="Valider" 
                    disabled={isLoading} 
                    action={() => deleteDelegation()} 
                />   
            }
            title="Suppression d'une délégation"
            close={() => setOpen(false)}
        />
    );
}

DeleteActions.propTypes = {
    isLoading: PropTypes.bool, 
    setOpen: PropTypes.func, 
    deleteDelegation: PropTypes.func
}

export default DeleteActions;