import { useCallback, useState } from 'react';
import { useSelector } from "react-redux";
import { Box, Stack, Tab, Tabs } from '@mui/material';
import { selectStaUsr, selectUsrApp } from '@selectors';
import { TabContentContainer, TextContainer } from '@components/Containers';
import { useStaPoi, useStaUsrApp } from '@hooks/statut/hooksQueries';
import { ActivitesDialog, PointageDialog, StatutBar } from '@components/App/Statut';
import { CODE_TABS_GESTION_TEMPS } from '@constants/tabsConstants';
import { greenColor, greyColor, whiteColor } from '@styles';
import { ActivitesDialogMobile, InformationsDialogMobile, PointageDialogMobile } from '@components/App/Statut/Dialogs';
import { useMedia } from 'react-use';
import { MenuButton } from '@components/Buttons';
import { AccessTime, AutoFixHigh, AvTimer, QueryBuilder } from '@mui/icons-material';
import { Temps, TempsMobile } from './Temps';
import { PlagesHoraire, PlagesHoraireMobile } from './PlagesHoraire';
import { DemandesRegularisation, DemandesRegularisationMobile } from './DemandesRegularisation';
import { Compteurs, CompteursMobile } from './Compteurs';

const GestionTempsCollaborateur = () => { 
    const stateUser = useSelector(selectUsrApp);
    const stateStatut = useSelector(selectStaUsr);
    const [activeTab, setActiveTab] = useState(CODE_TABS_GESTION_TEMPS.TAB_MON_TEMPS);
    const [openPointageDialog, setOpenPointageDialog] = useState(false);
    const [openActivitesDialog, setOpenActivitesDialog] = useState(false);
    const [openInfosDialog, setOpenInfosDialog] = useState(false);
    const [activite, setActivite] = useState("");
    const [dialogType, setDialogType] = useState("");
    const [titreMobile, setTitreMobile] = useState("Mon Temps")
    const { isLoading: isLoadingStatut, isFetching: isFetchingStatut, isError: isErrorStatut, refetch } = useStaUsrApp("P");
    const { isLoading: isLoadingPointage, pointer, pointerActivites } = useStaPoi();
    const isLoading = isLoadingPointage || isLoadingStatut || isFetchingStatut;
    const isMobile = useMedia('(max-width: 1024px)');

    const handleTabs = useCallback((_, tab) => {
        let titre = "";
        if(tab === CODE_TABS_GESTION_TEMPS.TAB_MON_TEMPS) {
            titre = "Mon Temps";
        }
        if(tab === CODE_TABS_GESTION_TEMPS.TAB_PLAGES_HORAIRE) {
            titre = "Plages Horaire";
        }
        if(tab === CODE_TABS_GESTION_TEMPS.TAB_DEMANDES_REGULARISATION) {
            titre = "Demandes de régularisation";
        }
        if(tab === CODE_TABS_GESTION_TEMPS.TAB_COMPTEURS) {
            titre = "Compteurs";
        }
        setTitreMobile(titre);
        setActiveTab(tab);
    }, []);
    
    const pointerActionner = useCallback(async () => {
        if(dialogType === "pointage") {
            await pointer();
        }
        if(dialogType === "activites") {
            await pointerActivites(activite);
        }
        setOpenPointageDialog(false);
        setOpenActivitesDialog(false);
        refetch();
    }, [activite, dialogType, pointer, pointerActivites, refetch]);

    const handleOpenConfirmationPointage = useCallback((dialogType, act = "") => {
        setDialogType(dialogType);
        setActivite(act);
        setOpenPointageDialog(true);
    }, []);

    return (
        <>
            <Stack spacing={0.5} bgcolor={whiteColor[9]} borderRadius={2} p={1} height="100%" boxSizing="border-box">
                {(stateUser.autorisationEd || stateUser.autorisationAc) && 
                    <StatutBar 
                        isLoading={isLoading} 
                        isError={isErrorStatut}
                        handleOpenConfirmationPointage={handleOpenConfirmationPointage} 
                        setOpenActivitesDialog={setOpenActivitesDialog} 
                        setOpenInfosDialog={setOpenInfosDialog}
                    />
                }
                {isMobile ?
                    <>
                        <Stack spacing={1} direction="row" bgcolor={greenColor["015"]} borderRadius={2} p={0.5} height={46} boxSizing={"border-box"} display={"flex"} alignItems="center">
                            <Box flexGrow={1} boxSizing="border-box" height="100%">
                                <TextContainer bgColor={greenColor["015"]} textSize={12} p={2} height="100%" verticalAlign="center" horizontalAlign="center" textWeight="bold">
                                    {titreMobile}
                                </TextContainer>
                            </Box>
                            <Box pr={1} pl={0.5}>
                                <MenuButton txtId="BTDABSMOB" options={[
                                    {id: 1, icon: <AccessTime fontSize="small" />, label: "Mon Temps", action: (e) => handleTabs(e, CODE_TABS_GESTION_TEMPS.TAB_MON_TEMPS)},
                                    {id: 2, icon: <QueryBuilder fontSize="small" />, label: "Plages Horaire", action: (e) => handleTabs(e, CODE_TABS_GESTION_TEMPS.TAB_PLAGES_HORAIRE)},
                                    {id: 3, icon: <AutoFixHigh fontSize="small" />, label: "Demandes de régularisation", action: (e) => handleTabs(e, CODE_TABS_GESTION_TEMPS.TAB_DEMANDES_REGULARISATION)},
                                    {id: 4, icon: <AvTimer fontSize="small" />, label: "Compteurs", action: (e) => handleTabs(e, CODE_TABS_GESTION_TEMPS.TAB_COMPTEURS)},
                                ]} />
                            </Box>
                        </Stack>
                        <TabContentContainer value={activeTab} id={CODE_TABS_GESTION_TEMPS.TAB_MON_TEMPS}>
                            <TempsMobile />
                        </TabContentContainer>
                        <TabContentContainer value={activeTab} id={CODE_TABS_GESTION_TEMPS.TAB_PLAGES_HORAIRE}>
                            <PlagesHoraireMobile /> 
                        </TabContentContainer>
                        {stateUser.autorisationRe && 
                            <TabContentContainer value={activeTab} id={CODE_TABS_GESTION_TEMPS.TAB_DEMANDES_REGULARISATION}>
                                <DemandesRegularisationMobile />
                            </TabContentContainer>
                        }
                        <TabContentContainer value={activeTab} id={CODE_TABS_GESTION_TEMPS.TAB_COMPTEURS}>
                            <CompteursMobile />
                        </TabContentContainer>
                    </>
                :
                    <>
                        <Box>
                            <Tabs value={activeTab} onChange={handleTabs}
                                indicatorColor=""
                                sx={styles.tabsStyles} 
                                centered
                            >
                                <Tab label="Mon Temps" value={CODE_TABS_GESTION_TEMPS.TAB_MON_TEMPS} sx={styles.tabStyle} />     
                                <Tab label="Plages Horaire" value={CODE_TABS_GESTION_TEMPS.TAB_PLAGES_HORAIRE} sx={styles.tabStyle} />               
                                {stateUser.autorisationRe && <Tab label="Demandes de régularisation" value={CODE_TABS_GESTION_TEMPS.TAB_DEMANDES_REGULARISATION} sx={styles.tabStyle} />}
                                <Tab label="Compteurs" value={CODE_TABS_GESTION_TEMPS.TAB_COMPTEURS} sx={styles.tabStyle} />
                            </Tabs>
                        </Box>
                        <TabContentContainer value={activeTab} id={CODE_TABS_GESTION_TEMPS.TAB_MON_TEMPS}>
                            <Temps />
                        </TabContentContainer>
                        <TabContentContainer value={activeTab} id={CODE_TABS_GESTION_TEMPS.TAB_PLAGES_HORAIRE}>
                            <PlagesHoraire /> 
                        </TabContentContainer>
                        {stateUser.autorisationRe && 
                            <TabContentContainer value={activeTab} id={CODE_TABS_GESTION_TEMPS.TAB_DEMANDES_REGULARISATION}>
                                <DemandesRegularisation />
                            </TabContentContainer>
                        }
                        <TabContentContainer value={activeTab} id={CODE_TABS_GESTION_TEMPS.TAB_COMPTEURS}>
                            <Compteurs />
                        </TabContentContainer>
                    </>
                }
            </Stack>  
            {isMobile ?
                <>
                    <PointageDialogMobile 
                        isLoading={isLoadingPointage} 
                        actionSuivante={stateStatut?.statut?.actionSuivante}
                        open={openPointageDialog} 
                        setOpen={setOpenPointageDialog} 
                        dialogType={dialogType}
                        activite={activite?.libelle}
                        pointer={pointerActionner} 
                    />
                    <ActivitesDialogMobile 
                        open={openActivitesDialog} 
                        actionSuivante={stateStatut?.statut?.actionSuivante}
                        setOpen={setOpenActivitesDialog} 
                        handleOpenConfirmationPointage={handleOpenConfirmationPointage}
                    />
                    <InformationsDialogMobile 
                        open={openInfosDialog}
                        setOpen={setOpenInfosDialog}
                        temps={stateStatut?.statut?.tempsEcoule} 
                        statut={stateStatut?.statut?.statut}
                        dateDernierMouvement={stateStatut?.statut?.dateDernierMouvement}
                        heureDernierMouvement={stateStatut?.statut?.heureDernierMouvement}
                    />
                </>
            :
                <>
                    <PointageDialog 
                        isLoading={isLoadingPointage} 
                        actionSuivante={stateStatut?.statut?.actionSuivante}
                        open={openPointageDialog} 
                        setOpen={setOpenPointageDialog} 
                        dialogType={dialogType}
                        activite={activite?.libelle}
                        pointer={pointerActionner} 
                    />
                    <ActivitesDialog 
                        open={openActivitesDialog} 
                        actionSuivante={stateStatut?.statut?.actionSuivante}
                        setOpen={setOpenActivitesDialog} 
                        handleOpenConfirmationPointage={handleOpenConfirmationPointage}
                    />
                </>
            }
        </>
    )
}

const styles = {
    stackStyle: {
        
    },
    tabTypographyStyle: {
        xs: "11px", 
        sm: "small"
    },
    tabStyle: {
        color: greyColor[9],
        fontWeight: "bold",
        marginRight: 0.5,
        minHeight: "",
        border: "none",
        borderRadius: 2,
        flexGrow: 1,
        textTransform: "none",
        "&:hover": {
            backgroundColor: whiteColor[5],
            color: greyColor[9]
        },
        "&.Mui-selected": {
            backgroundColor: whiteColor[5],
            color: greyColor[9]
        }
    },
    tabsStyles: {
        height: 41,
        minHeight: "",
        paddingLeft: 0.5,
        paddingY: 0.5,
        backgroundColor: greenColor["015"], 
        borderRadius: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}

export default GestionTempsCollaborateur;