import { FilterList, FilterListOff, MobiledataOff, Refresh, SwapVert } from "@mui/icons-material";
import PropTypes from "prop-types";
import { HeaderPanelContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";

const EntretiensActionsBar = ({ 
    isLoading = true, 
    isError = false, 
    tried = false,
    filtered = false, 
    openTrie = () => {}, 
    setOpenFiltre = () => {},
    deleteTrie = () => {}, 
    deleteFiltre = () => {},
    refetch = () => {} 
}) => {
    return (
        <HeaderPanelContainer 
            actionsLeft={
                <>
                    <IconButtonBase 
                        variant="pageButton"
                        icon={tried ? <MobiledataOff fontSize="small" /> : <SwapVert fontSize="small" />} 
                        title={tried ? "Enlever le trie" : "Trier"}
                        disabled={isLoading || isError}
                        action={tried ? () => deleteTrie() : openTrie} 
                    /> 
                    <IconButtonBase 
                        variant="pageButton"
                        icon={filtered ? <FilterListOff fontSize="small" /> : <FilterList fontSize="small" />} 
                        title={filtered ? "Enlever le filtre" : "Filtrer"}
                        disabled={isLoading || isError}
                        action={filtered ? () => deleteFiltre() : () => setOpenFiltre(true)} 
                    /> 
                </>
            }
            actionsRight={                
                <IconButtonBase 
                    variant="pageButton"
                    icon={<Refresh fontSize="small" />} 
                    title="Rafraichir" 
                    disabled={isLoading}
                    action={() => refetch()} 
                />                  
            }   
            variant="green07"
        />
    );
}

EntretiensActionsBar.propTypes = {
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool, 
    tried: PropTypes.bool,
    filtered: PropTypes.bool, 
    openTrie: PropTypes.func, 
    setOpenFiltre: PropTypes.func, 
    deleteTrie: PropTypes.func,
    deleteFiltre: PropTypes.func, 
    refetch: PropTypes.func
}

export default EntretiensActionsBar;