import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { routePolices } from "@constants/policesConstants";
import { getWidgetRemoved } from "@utils";
import { selectDsh, selectUsrApp } from "@selectors";
import * as dshActions from "@reducers/dashboard/dshReducer"
import { IconButtonBase } from "@components/Buttons";
import { whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const WelcomeWidgetConfigurationDelete = () => {
    const stateDashboard = useSelector(selectDsh);
    const stateUser = useSelector(selectUsrApp);
    const dispatch = useDispatch();
    const { t } = useTranslation("global");

    const remove = useCallback(() => {
        const updateState = getWidgetRemoved("wBonjour", stateDashboard.widgetsActives, stateDashboard.widgetsNonActives, stateDashboard.widgetsConfiguration);
        dispatch(dshActions.editWidget(updateState));
    }, [dispatch, stateDashboard.widgetsActives, stateDashboard.widgetsConfiguration, stateDashboard.widgetsNonActives]);

    return ( 
        <Stack spacing={0.5} padding={0.5} borderRadius={2} bgcolor={whiteColor[5]} height="200px" boxSizing="border-box" position="relative">
            <Box height="100%" paddingX={2} display="flex" alignItems="center">
                <Box>
                    <Typography variant="h5" fontFamily={routePolices[stateUser.police]}>
                        {t("texts.hello")} { stateUser.prenom }, 
                    </Typography>
                    <Typography  variant="h5" fontFamily={routePolices[stateUser.police]}>
                        {t("texts.welcome")}
                    </Typography>
                </Box>
            </Box>
            <Box position="absolute" right={6} top={3}>
                <IconButtonBase 
                    variant="deleteListButton"
                    icon={<Close fontSize="small" />} 
                    title={t("buttons.delete")} 
                    action={remove} 
                />
            </Box>
        </Stack> 
    );
}

export default WelcomeWidgetConfigurationDelete;