import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Stack } from "@mui/material";
import * as colCreGesTemActions from "@reducers/collaborateurs/colCreGesTemReducer";
import * as creGesTemActions from "@reducers/gestionTemps/credits/creGesTemReducer";
import { CollaborateursBar } from "@components/App/Collaborateurs/Bars";
import { greenColor, whiteColor } from "@styles";
import { CollaborateursDialogMobile } from "@components/App/Collaborateurs/Dialogs";
import { TYPES_COLLABORATEURS, TYPES_FICHIER } from "@constants/collaborateursConstants";
import { selectColCreGesTem, selectCreGesTem } from "@selectors";
import moment from "moment";
import { CreditsList } from "@components/GestionTemps/Credits/Lists";
import { useCreGesTem } from "@hooks/gestionTemps/credits/hooksQueries";
import { CreditsActionsBar } from "@components/GestionTemps/Credits/ActionsBars";
import { trierCreGesTem } from "@components/GestionTemps/Credits/tries";
import { MenuFiltreContainer, MenuTrieContainer, TextContainer } from "@components/Containers";
import { FiltreCreditsForm } from "@components/GestionTemps/Credits/Forms";
import { EventAvailable, QuestionAnswerOutlined } from "@mui/icons-material";
import "numeral/locales/fr";
import numeral from "numeral";

const headerItems = [
    {id: 1, label: "Nom", xs: 2, display: true},
    {id: 2, label: "Matricule", xs: 2, display: true},
    {id: 3, label: "Badge", xs: 2, display: true},
    {id: 4, label: "Société", xs: 2, display: true},
    {id: 5, label: "Service", xs: 2, display: true},
    {id: 6, label: "Type Personnel", xs: 1, display: true},
];

const optionsTrie = [
    {id: 1, label: "Par date", keyAsc: "dateAsc", keyDesc: "dateDesc", icon: <EventAvailable fontSize="small" />},
    {id: 2, label: "Par crédit en cours", keyAsc: "creditEnCoursAsc", keyDesc: "creditEnCoursDesc", icon: <EventAvailable fontSize="small" />},
    {id: 3, label: "Par crédit acquis jour", keyAsc: "creditAcquisJourAsc", keyDesc: "creditAcquisJourDesc", icon: <QuestionAnswerOutlined fontSize="small" />},
    {id: 4, label: "Par crédit acquis cumul", keyAsc: "creditAcquisCumulAsc", keyDesc: "creditAcquisCumulDesc", icon: <QuestionAnswerOutlined fontSize="small" />},
    {id: 5, label: "Par valeur régularisation", keyAsc: "valeurRegularisationAsc", keyDesc: "valeurRegularisationDesc", icon: <QuestionAnswerOutlined fontSize="small" />},
    {id: 6, label: "Par date regularisation", keyAsc: "dateRegularisationAsc", keyDesc: "dateRegularisationDesc", icon: <QuestionAnswerOutlined fontSize="small" />},
    {id: 7, label: "Par utilisateur régularisation", keyAsc: "utilisateurRegularisationAsc", keyDesc: "utilisateurRegularisationDesc", icon: <QuestionAnswerOutlined fontSize="small" />}
];

numeral.locale("fr");

const Credit = () => {
    const [openCollaborateursDialog, setOpenCollaborateursDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openFiltre, setOpenFiltre] = useState(false);
    const stateCollaborateursCredit = useSelector(selectColCreGesTem);
    const stateCredits = useSelector(selectCreGesTem);
    const { isLoading, isFetching, isError, refetch } = useCreGesTem(stateCredits?.dateCredits, stateCollaborateursCredit?.collaborateurSelected?.badge,true);
    const dispatch = useDispatch();

    const openTrie = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const trier = useCallback((keyTrie) => {
        const data = [...stateCredits.creditsDisplayed];
        const dataTried = trierCreGesTem(keyTrie, data);
        dispatch(creGesTemActions.trier(dataTried));
    }, [dispatch, stateCredits.creditsDisplayed]);

    const toggleHoraire = useCallback(() => {
        dispatch(creGesTemActions.toggleHoraire());
    }, [dispatch]);

    const deleteTrie = useCallback(() => {
        dispatch(creGesTemActions.deleteTrie());
    }, [dispatch]);

    const deleteFiltre = useCallback(() => {
        dispatch(creGesTemActions.deleteFiltre());
    }, [dispatch]);

    const selectCollaborateur = useCallback(async (collaborateur) => {
        setOpenCollaborateursDialog(false);
        dispatch(colCreGesTemActions.selectCollaborateur(collaborateur));
        dispatch(creGesTemActions.setDateCredits(stateCollaborateursCredit?.dateValidite));
    }, [dispatch, stateCollaborateursCredit?.dateValidite]);

    const selectDate = useCallback(async (date) => {
        dispatch(creGesTemActions.setDateCredits(date.format("DDMMYYYY")));
    }, [dispatch]);

    return (
        <Stack spacing={0.5} bgcolor={greenColor["015"]} borderRadius={2} p={0.5} height="100%" boxSizing="border-box">
            {stateCollaborateursCredit.collaborateurSelected && 
                <Stack spacing={0.5} height={"calc(100% - 50px)"}>
                    <TextContainer p={1} bgColor={whiteColor[5]} textWeight="bold">
                        Crédit à partir du {moment(stateCredits?.dateCredits, "DDMMYYYY").format("DD/MM/YYYY")}
                    </TextContainer>
                    <CreditsList 
                        data={stateCredits.creditsDisplayed}
                        isLoading={isLoading || isFetching}
                        isError={isError}
                        horaire={stateCredits.horaire}
                    />
                    <CreditsActionsBar 
                        date={stateCredits?.dateCredits}
                        isLoading={isLoading || isFetching}
                        isError={isError}
                        horaire={stateCredits.horaire}
                        filtered={stateCredits.filtered}
                        tried={stateCredits.tried}
                        openTrie={openTrie}
                        setOpenFiltre={setOpenFiltre}
                        toggleHoraire={toggleHoraire}
                        deleteTrie={deleteTrie}
                        deleteFiltre={deleteFiltre}
                        selectDate={selectDate}
                        refetch={refetch}
                    />
                    <MenuTrieContainer 
                        anchorEl={anchorEl} 
                        setAnchorEl={setAnchorEl} 
                        options={optionsTrie} 
                        trier={trier} 
                    />
                    <MenuFiltreContainer open={openFiltre} setOpen={setOpenFiltre}>
                        <FiltreCreditsForm setOpen={setOpenFiltre} />
                    </MenuFiltreContainer>
                </Stack>
            }
            <CollaborateursBar 
                collaborateur={stateCollaborateursCredit?.collaborateurSelected}  
                openDialog={() => setOpenCollaborateursDialog(true)} 
            />
            <CollaborateursDialogMobile 
                stateType={TYPES_COLLABORATEURS.GESTION_TEMPS_CREDIT}
                listType={TYPES_FICHIER.FWDC01} 
                open={openCollaborateursDialog}  
                dateValidite={stateCollaborateursCredit?.dateValidite ? moment(stateCollaborateursCredit.dateValidite, "DDMMYYYY").format("DDMMYYYY") : moment()}
                headers={headerItems}
                tried={stateCollaborateursCredit.tried}
                setOpen={setOpenCollaborateursDialog}  
                selectCollaborateur={selectCollaborateur}
            />
        </Stack>
    );
};

export default Credit;