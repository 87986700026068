import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Check } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { selectCltApp, selectSecVarApp, selectUsrApp } from "@selectors";
import { usePasswordParametres } from "@hooks/securite/hooksQueries";
import { Loader } from "@components/Loaders";
import { IconButtonBase } from "@components/Buttons";
import { greenColor, greyColor } from "@styles";
import { schemaParametresPassword } from "../validations";
import { HeaderDialogContainer } from "@components/Containers";
import { SecretHookFormInput } from "@components/Inputs";
import { Stack } from "@mui/material";

const defaultValues = {
    oldPassword: "",
    newPassword1: "",
    newPassword2: ""
}

const PasswordForm = ({ setOpen }) => {
    const stateUser = useSelector(selectUsrApp);
    const stateSecureVariable = useSelector(selectSecVarApp);
    const stateClient = useSelector(selectCltApp);
    const methods = useForm({ mode: "onChange", defaultValues: defaultValues, resolver: joiResolver(schemaParametresPassword(stateSecureVariable.parametres)) });
    const { isLoadingPwd, editPwdActionner } = usePasswordParametres(); 

    const handleUpdatePassword = useCallback(async (data) => {
        const dataRequest = {
            matricule: stateUser.matricule,
            oldPassword: data.oldPassword,
            password: data.newPassword1,
            email: stateUser.email, 
            token: stateUser.token,
            client: stateClient.client
        }
        const { isSucceed } = await editPwdActionner(dataRequest);
        if(isSucceed) {
            setOpen(false);
        }
    }, [editPwdActionner, setOpen, stateClient.client, stateUser.email, stateUser.matricule, stateUser.token]);

    const handleEnter = useCallback((event) => {
        if(event.key === "Enter" || event.key === undefined) {
            methods.handleSubmit(handleUpdatePassword)();
        }
    }, [handleUpdatePassword, methods]);

    return (
            <>
                <HeaderDialogContainer 
                    actionsLeft={
                        <IconButtonBase icon={<Check fontSize="small" />} title="Verifier la réponse" action={() => methods.handleSubmit(handleUpdatePassword)()} />
                    } 
                    title="Modification du mot de passe"
                    close={() => setOpen(false)}
                />
                {isLoadingPwd ? 
                    <Loader colorContainer={greyColor[1]} colorLoader={greenColor["1"]} />
                :
                    <FormProvider {...methods}>
                        <Stack spacing={1} onKeyUp={handleEnter}>
                            <SecretHookFormInput 
                                name="oldPassword"
                                label="Mot de passe actuel"  
                                autoFocus={true}
                            /> 
                            <SecretHookFormInput 
                                name="newPassword1"
                                label="Nouveau mot de passe"  
                            /> 
                            <SecretHookFormInput 
                                name="newPassword2"
                                label="Répéter le mot de passe"  
                            /> 
                        </Stack>
                    </FormProvider>
                }
            </>
    );
}

export default PasswordForm;