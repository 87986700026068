import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { fetchDir } from "@api/directions";
import { selectCltApp } from "@selectors";

export const useDirInp = (codeSociete, type, open) => {
    const stateClient = useSelector(selectCltApp);
    const body = { codeSociete: codeSociete, type: type };

    return useQuery(['dirInp', codeSociete], ({ signal }) => fetchDir(body, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open && !!codeSociete
    });
}
