import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Badge, Factory, Person, Share, WhereToVote } from "@mui/icons-material";
import PropTypes from "prop-types";
import * as colCreGesTemActions from "@reducers/collaborateurs/colCreGesTemReducer";
import { useStateCollaborateurs } from "@hooks/collaborateurs/hooksStates";
import { MenuTrieContainerMobile } from "@components/Containers";
import { trierFwdc01 } from "../utils";
import { TYPES_COLLABORATEURS } from "@constants/collaborateursConstants";
import { MenuTrieButtonMobile } from "@components/Buttons";

const optionsTrierFwgtpe = [
    {id: 1, label: "Par nom", keyAsc: "nomAsc", keyDesc: "nomDesc", icon: <Person fontSize="small" />},
    {id: 2, label: "Par matricule", keyAsc: "matriculeAsc", keyDesc: "matriculeDesc", icon: <Person fontSize="small" />},
    {id: 3, label: "Par badge", keyAsc: "badgeAsc", keyDesc: "badgeDesc", icon: <Badge fontSize="small" />},
    {id: 4, label: "Par société", keyAsc: "societeAsc", keyDesc: "societeDesc", icon: <Factory fontSize="small" />},
    {id: 5, label: "Par service", keyAsc: "serviceAsc", keyDesc: "serviceDesc", icon: <Share fontSize="small" />},
    {id: 6, label: "Par type de personnel", keyAsc: "typePersonnelAsc", keyDesc: "typePersonnelDesc", icon: <WhereToVote fontSize="small" />},
];

const CollaborateursFwdc01TrieMobile = (props) => {
    const { stateType, open, close } = props;
    const stateCollaborateurs = useStateCollaborateurs(stateType);
    const dispatch = useDispatch();

    const trier = useCallback((keyTrie) => {
        const data = [...stateCollaborateurs.collaborateurs];
        const dataTried = trierFwdc01(keyTrie, data);
        if(stateType === TYPES_COLLABORATEURS.GESTION_TEMPS_CREDIT) {
            dispatch(colCreGesTemActions.trier(dataTried));
        }
        close();
    }, [close, dispatch, stateCollaborateurs.collaborateurs, stateType]);

    return (
        <MenuTrieContainerMobile open={open} close={close}>
            {optionsTrierFwgtpe.map((option) => (
                <MenuTrieButtonMobile key={`MTR${option.id}`} option={option} trier={trier} />
            ))}
        </MenuTrieContainerMobile>
    );
}

CollaborateursFwdc01TrieMobile.defaultProps = {
    open: false,
    close: () => {}
}

CollaborateursFwdc01TrieMobile.propTypes = {
    stateType: PropTypes.string.isRequired,
    open: PropTypes.bool,
    close: PropTypes.func
}

export default CollaborateursFwdc01TrieMobile;