import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { HeaderDialogContainer } from "@components/Containers";

const DeleteDemandesActions = (props) => {
    const { close, handleDelete } = props;

    return (
        <HeaderDialogContainer 
            actionsLeft={
                <IconButtonBase 
                    variant="white5Grey9White9"
                    icon={<Check fontSize="small" />} 
                    title="Supprimer les demandes" 
                    action={handleDelete}
                />
            }  
            title="Confirmation"
            close={close}  
            variant="warning"
        />
    );
}

DeleteDemandesActions.defaultProps = {
    close: () => {},
    handleDelete: () => {}
}

DeleteDemandesActions.propTypes = {
    close: PropTypes.func,
    handleDelete: PropTypes.func
}

export default DeleteDemandesActions;