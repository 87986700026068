import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    listed: false,
    filtered: false,
    tried: false,
    documents: [],
    documentsDisplayed: []
}

const docAppSlice = createSlice({
    name: 'docApp',
    initialState: initialState,
    reducers: {
        setDocuments: (state, action) => {
            state.documents = action.payload;
            state.documentsDisplayed = action.payload;
        },
        lister: (state) => {
            state.listed = !state.listed;
        },
        filtrer: (state, action) => {
            state.documentsDisplayed = action.payload;
            state.filtered = true;
        },
        trier: (state, action) => {
            state.documentsDisplayed = action.payload;
            state.tried = true;
        },
        deleteFiltre: (state) => {
            state.documentsDisplayed = state.documents;
            state.filtered = false
        },
        deleteTrie: (state) => {
            state.documentsDisplayed = state.documents;
            state.tried = false
        }
    },
})

const { actions, reducer } = docAppSlice
export const { 
    setDocuments,
    lister, 
    filtrer, 
    trier,  
    deleteFiltre, 
    deleteTrie 
} = actions
export default reducer