import { Box, Grid, Grow, Stack, Tooltip } from "@mui/material";
import { Close, Info } from "@mui/icons-material";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { IconButtonBase, MenuButton } from "@components/Buttons";
import { HeaderListContainer, ItemContainer, ListContainer, PanelListContainer, TextWithLabelContainer } from "@components/Containers";
import { Loader } from "@components/Loaders";
import { ErrorBase } from "@components/Errors";
import { CheckBoxButton, greenColor, greyColor, redColor, whiteColor, yellowColor } from "@styles";
import { useMedia } from "react-use";
import { useTranslation } from "react-i18next";

const colorStatutList = {
    "Acceptée": greenColor["05"],
    "Non traitée": yellowColor[5],
    "En cours": yellowColor[5],
    "Refusée": redColor[4]
}

const LabelDateDebut = ({ dateDebut, dateReprise }) => {
    let label = "";

    if(dateDebut && dateReprise) {
        label = `Du ${moment(dateDebut, "DDMMYYYY").format("DD/MM/YYYY")} au ${moment(dateReprise, "DDMMYYYY").format("DD/MM/YYYY")}`;
    } else {
        label = moment(dateDebut, "DDMMYYYY").format("DD/MM/YYYY")
    }

    return (
        label
    )
}

const HistoriqueDemandesList = ({
    data = [], 
    isLoading = true, 
    isError = false, 
    listed = false,
    error = null, 
    demandesChecked = [], 
    openInfos = () => {}, 
    openDelete = () => {}, 
    select = () => {}
}) => {
    const isMobile = useMedia('(max-width: 950px)');
    const { t } = useTranslation("global");

    const headerItems = [
        {id: 1, label: "", xs: 1, display: false, firstData: false, lastData: false},
        {id: 2, label: t("texts.request_date"), xs: 2, display: true, firstData: true, lastData: false},
        {id: 3, label: t("texts.absence_date"), xs: 2, display: true, firstData: false, lastData: false},
        {id: 4, label: t("texts.status"), xs: 2, display: true, firstData: false, lastData: false},
        {id: 5, label: t("texts.nature"), xs: 2, display: true, firstData: false, lastData: false},
        {id: 6, label: t("texts.absence_type"), xs: 2, display: true, firstData: false, lastData: true}
    ];

    if(isLoading) {
        return (
            <Box justifyContent="center" display="flex" alignItems="center" height={253}>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return <ErrorBase>{error?.message}</ErrorBase>;
    }
   
    return (
        <Box>
            {(listed && !isMobile) ? 
                <Stack spacing={0.5}>
                    <HeaderListContainer itemIdReference="HISTO" headerItems={headerItems} />
                    <Box height={189}>
                        <PerfectScrollbar> 
                            <ListContainer spacing={0.5} length={data.length}>
                                {data.map((demande, id) => (
                                    <Grow key={`LISTHIST${demande.id}`} in={true} timeout={400+(200*id)}>
                                        <Box bgcolor={colorStatutList[demande.statut]} borderRadius={2} p={0.3}>
                                            <Grid container spacing={0.3}>
                                                <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">                                        
                                                    <CheckBoxButton 
                                                        variant="white5Grey7White5"
                                                        size="small" 
                                                        bgcolor={whiteColor[5]} 
                                                        checked={demandesChecked.includes(demande.id)} 
                                                        value={demande.id} 
                                                        onChange={select} 
                                                    />
                                                </Grid>
                                                <ItemContainer firstData data={moment(demande.date, "DDMMYYYY").format("DD/MM/YYYY")} fontSize="11px" xs={2} /> 
                                                <ItemContainer data={<LabelDateDebut dateDebut={demande.dateDebut} dateReprise={demande.dateReprise} />} fontSize="11px" xs={2} /> 
                                                <ItemContainer data={demande.statut} fontSize="11px" xs={2} /> 
                                                <ItemContainer data={demande.nature} fontSize="11px" xs={2} /> 
                                                <ItemContainer lastData data={demande.libelleAbsence} fontSize="11px" xs={2} /> 
                                                <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
                                                    {demande.supressionPossible === "O" ? 
                                                        <MenuButton txtId="BTHISTO" variant="white5Grey9White9" options={[
                                                            {id: 1, icon: <Info fontSize="small" />, label: t("buttons.show"), action: () => openInfos(demande)},
                                                            {id: 2, icon: <Close fontSize="small" />, label: t("buttons.delete"),action: () => openDelete(demande)}
                                                        ]} />
                                                    : 
                                                        <MenuButton txtId="BTHISTO" options={[
                                                            {id: 1, icon: <Info fontSize="small" />, label: t("buttons.show"), action: () => openInfos(demande)},
                                                        ]} />
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grow>
                                ))}
                            </ListContainer>
                        </PerfectScrollbar>
                    </Box>
                </Stack>
            :
                <Box height={253}>
                    <PerfectScrollbar>
                        <PanelListContainer length={data.length}>
                            {data.map((demande, id) => (
                                <Grow key={`LH${demande.id}`} in={true} timeout={200+(200*id)}>
                                    <Grid item xs={12} sm={6}>
                                        <Stack spacing={0.5} bgcolor={colorStatutList[demande.statut]} padding={0.5} borderRadius={2}>
                                            <Box borderRadius={2} p={1} bgcolor={whiteColor[5]}>
                                                <Stack spacing={0}>
                                                    <TextWithLabelContainer label={t("texts.request_date")} value={moment(demande.date, "DDMMYYYY").format("DD/MM/YYYY")} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                                    <TextWithLabelContainer label={t("texts.absence_date")} value={<LabelDateDebut dateDebut={demande.dateDebut} dateReprise={demande.dateReprise} />} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                                    <TextWithLabelContainer label={t("texts.status")} value={demande.statut} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                                    <TextWithLabelContainer label={t("texts.nature")} value={demande.nature} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                                    <TextWithLabelContainer label={t("texts.absence_type")} value={demande.libelleAbsence} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                                </Stack>
                                            </Box>
                                            <Stack direction="row" spacing={0.5} borderRadius={2} height={35.5} display="flex" alignItems="center" justifyContent="center" bgcolor={whiteColor[5]}>
                                                <Tooltip title="Selectionner">
                                                    <CheckBoxButton 
                                                        variant="grey7White5"
                                                        size="small" 
                                                        checked={demandesChecked.includes(demande.id)} 
                                                        value={demande.id} 
                                                        onChange={select} 
                                                    />
                                                </Tooltip>
                                                <IconButtonBase 
                                                    variant="grey7White5"
                                                    icon={<Info fontSize="small" />} 
                                                    title={t("buttons.show")} 
                                                    action={() => openInfos(demande)} 
                                                />
                                                {demande.supressionPossible === "O" &&
                                                    <IconButtonBase 
                                                        variant="red7White5"
                                                        icon={<Close fontSize="small" />} 
                                                        title={t("buttons.delete")} 
                                                        action={() => openDelete(demande)} 
                                                    />
                                                }
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                </Grow>
                            ))}  
                        </PanelListContainer>
                    </PerfectScrollbar>
                </Box>
            }
        </Box>
    );
}

HistoriqueDemandesList.propTypes = {
    data: PropTypes.array, 
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool, 
    error: PropTypes.object, 
    demandesChecked: PropTypes.array, 
    openInfos: PropTypes.func, 
    openDelete: PropTypes.func, 
    select: PropTypes.func, 
    listed: PropTypes.bool
}

export default HistoriqueDemandesList;