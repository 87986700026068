import { Box, Stack, Typography } from "@mui/material";
import { useChampDesignation } from "@hooks/gestionEntretiens/hooksUtils";
import { TextHookFormInput } from "@components/Inputs";
import { greenColor, whiteColor } from "@styles";

const ChampDesignation = () => {
    const { displayA, displayV, designation1, designation2 } = useChampDesignation();

    if(displayA) {
        return (
            <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                <Stack spacing={0.5} borderRadius={2} bgcolor={whiteColor[7]} p={0.5}>
                    <TextHookFormInput name="designation1" label="Designation 1" maxLength={80} />
                    <TextHookFormInput name="designation2" label="Designation 2" maxLength={80} />
                </Stack>
            </Box>
        );
    }

    if(displayV) {
        return (
            <>
                <Box>
                    <Typography fontSize={"small"}>{designation1}</Typography>
                </Box>
                <Box>
                    <Typography fontSize={"small"}>{designation2}</Typography>
                </Box>
            </>
        )
    }

    return null;
}

export default ChampDesignation;