import { Box, Grid, Stack } from "@mui/material";
import { ListAlt } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { DialogFullScreenContainer, HeaderDialogContainer, PanelListContainer, TextContainer, TextWithLabelContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { greenColor, redColor, whiteColor } from "@styles";

const AnomaliesTransactionsDialogMobile = (props) => {
    const { open, setOpen, transaction, openAllAnomalies } = props;

    return (
        <DialogFullScreenContainer open={open} placement="top" taille="md">
            <HeaderDialogContainer 
                actionsLeft={
                    <IconButtonBase icon={<ListAlt fontSize="small" />} action={openAllAnomalies} />
                }
                title="Anomalie(s)"
                close={() => setOpen(false)}
            />
            {transaction && 
                <>
                    <Stack spacing={0.5} bgcolor={greenColor["015"]} padding={0.5} borderRadius={2}>
                        <Box borderRadius={2} p={1} bgcolor={whiteColor[5]}>
                            <Stack spacing={0}>
                                <TextWithLabelContainer label="Mouvement" value={transaction.libelleMouvement} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                <TextWithLabelContainer label="Date" value={moment([transaction.anneeMouvement, transaction.moisMouvement - 1, transaction.jourMouvement]).format("DD/MM/YYYY")} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                <TextWithLabelContainer label="Heure Réelle" value={`${moment(transaction.heureMouvement, "H").format("HH")}h${moment(transaction.minuteMouvement, "m").format("mm")}`} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                <TextWithLabelContainer label="Heure Ajustée" value={`${moment(transaction.heureMouvementAjustee, "H").format("HH")}h${moment(transaction.minuteMouvementAjustee, "m").format("mm")}`} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                <TextWithLabelContainer label="Lecteur" value={transaction.lecteurPresence} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                <TextWithLabelContainer label="Activité" value={transaction.libelleActivite} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                <TextWithLabelContainer label="Type Travail" value={transaction.libelleTypeTravail} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                            </Stack>
                        </Box>
                    </Stack>
                    <PanelListContainer length={transaction.anomalies.length}>
                        <Stack spacing={0.5} bgcolor={greenColor["015"]} p={0.5} borderRadius={2}>
                            <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold" horizontalAlign="center">Anomalie(s)</TextContainer>
                            {transaction.anomalies.map((anomalie) => (
                                <Grid key={`ANO${anomalie.code}`} item xs={12}>
                                    <Stack spacing={0.5}>
                                        <Box borderRadius={2} p={1} bgcolor={redColor[1]}>
                                            <Stack spacing={0}>
                                                <TextWithLabelContainer label="Code" value={anomalie.codeAnomalie} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                                <TextWithLabelContainer label="Désignation" value={anomalie.libelleAnomalie} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                                <TextWithLabelContainer label="Gravité" value={anomalie.gravite} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                            </Stack>
                                        </Box>
                                    </Stack>
                                </Grid>
                            ))}
                        </Stack>
                    </PanelListContainer>
                </>
            }
        </DialogFullScreenContainer>
    );
}

AnomaliesTransactionsDialogMobile.defaultProps = {
    transaction: null
}

AnomaliesTransactionsDialogMobile.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    transaction: PropTypes.object,
    openAllAnomalies: PropTypes.func.isRequired
}

export default AnomaliesTransactionsDialogMobile;