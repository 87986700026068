import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

const PlagesVariables = (props) => {
    const { debutVariable, debutFixe, repriseFixe, repriseVariable } = props;

    return (
        <Stack direction="row">
            <Box width={"15%"} justifyContent="left" display="flex">
                <Typography fontSize="11px">{debutVariable}</Typography>
            </Box>
            <Stack direction="row" width={"70%"}>
                <Box width="50%" justifyContent="left"  display="flex">
                    <Typography fontSize="11px">{debutFixe}</Typography>
                </Box>
                <Box width="50%" justifyContent="right"  display="flex">
                    <Typography fontSize="11px">{repriseFixe}</Typography>
                </Box>
            </Stack>
            <Box width={"15%"} justifyContent="right" display="flex">
                <Typography fontSize="11px">{repriseVariable}</Typography>
            </Box>
        </Stack>
    );
}

PlagesVariables.defaultProps = {
    debutVariable: "",
    debutFixe: "",
    repriseFixe: "",
    repriseVariable: ""
}

PlagesVariables.propTypes = {
    debutVariable: PropTypes.string,
    debutFixe: PropTypes.string,
    repriseFixe: PropTypes.string,
    repriseVariable: PropTypes.string
}

export default PlagesVariables;