import { filter, tidy } from "@tidyjs/tidy";
import moment from "moment";

export const filtrerDabGesAbsMan = (data, dataBrut) => {
    let dataFiltered = [...dataBrut];
    switch(data.colonne) {
        case "dateEmission":
            switch(data.operateur) {
                case "egale":
                    return tidy(dataFiltered, filter(demandeAbsence => moment(demandeAbsence.date, "DDMMYYYY").isSame(data.date)));
                case "superieur":
                    return tidy(dataFiltered, filter(demandeAbsence => moment(demandeAbsence.date, "DDMMYYYY").isSameOrAfter(data.date)));
                case "inferieur":
                    return tidy(dataFiltered, filter(demandeAbsence => moment(demandeAbsence.date, "DDMMYYYY").isSameOrBefore(data.date)));
                default: 
                    return dataFiltered
            }
        case "dateAbsence":
            switch(data.operateur) {
                case "egale":
                    return tidy(dataFiltered, filter(demandeAbsence => moment(demandeAbsence.dateDebut, "DDMMYYYY").isSame(data.date)));
                case "superieur":
                    return tidy(dataFiltered, filter(demandeAbsence => moment(demandeAbsence.dateDebut, "DDMMYYYY").isSameOrAfter(data.date)));
                case "inferieur":
                    return tidy(dataFiltered, filter(demandeAbsence => moment(demandeAbsence.dateDebut, "DDMMYYYY").isSameOrBefore(data.date)));
                default: 
                    return dataFiltered
            }
        case "emetteur":
            return tidy(dataFiltered, filter(demandeAbsence => demandeAbsence.matricule === data.txt));
        case "nature":
            return  tidy(dataFiltered, filter(demandeAbsence => demandeAbsence.nature === data.txt));
        case "statut":
            return tidy(dataFiltered, filter(demandeAbsence => demandeAbsence.statut ===  data.txt));
        default:
            return dataFiltered
    }
}
