import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Check } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { selectCltApp, selectSecVarApp, selectUsrApp } from "@selectors";
import { usePassword } from "@hooks/securite/hooksQueries";
import * as userActions from "@reducers/user/usrAppReducer"
import { Loader } from "@components/Loaders";
import { IconButtonBase } from "@components/Buttons";
import { greenColor, greyColor } from "@styles";
import { SecretHookFormInput } from "@components/Inputs";
import { schemaLoginPassword } from "../validations";
import { HeaderDialogContainer } from "@components/Containers";
import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

const defaultValues = {
    newPassword1: "",
    newPassword2: ""
}

const PasswordLoginForm = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const stateSecureVariable = useSelector(selectSecVarApp);
    const dispatch = useDispatch();
    const methods = useForm({ mode: "onChange", defaultValues: defaultValues, resolver: joiResolver(schemaLoginPassword(stateSecureVariable.parametres)) });
    const { isLoadingPwd, editPwdActionner } = usePassword(); 
    const { t } = useTranslation("global");

    const handleUpdatePassword = useCallback((data) => {
        const dataRequest = {
            matricule: stateUser.matricule,
            oldPassword: stateUser.password,
            password: data.newPassword1,
            email: stateUser.email, 
            token: stateUser.token,
            client: stateClient.client
        }
        editPwdActionner(dataRequest);
    }, [editPwdActionner, stateClient.client, stateUser.email, stateUser.matricule, stateUser.password, stateUser.token]);

    const handleEnter = useCallback((event) => {
        if(event.key === "Enter" || event.key === undefined) {
            methods.handleSubmit(handleUpdatePassword)();
        }
    }, [handleUpdatePassword, methods]);

    return (
        <Stack spacing={1}>
            <HeaderDialogContainer 
                actionsLeft={
                    <IconButtonBase 
                        variant="dialogButton"
                        icon={<Check fontSize="small" />} 
                        title={t("buttons.validate")} 
                        action={() => methods.handleSubmit(handleUpdatePassword)()} 
                    />
                } 
                title={t("titles.edit_password")}
                close={() => dispatch(userActions.openPassword(false))}
            />
            {isLoadingPwd ? 
                <Box p={2}>
                    <Loader colorContainer={greyColor[1]} colorLoader={greenColor["1"]} />
                </Box>
            :
                <FormProvider {...methods}>
                    <Stack spacing={1} onKeyUp={handleEnter}>
                        <SecretHookFormInput 
                            name="newPassword1"
                            label={t("inputs.password_new")}  
                            autoFocus={true}
                        /> 
                        <SecretHookFormInput 
                            name="newPassword2"
                            label={t("inputs.password_repeat")} 
                        /> 
                    </Stack>
                </FormProvider>
            }
        </Stack>
    );
}

export default PasswordLoginForm;