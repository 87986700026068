import axios from "axios";
import { urls } from "@constants/appConstants";

const arrayToTree = (array, parentId) => {
    return array.filter(item => item.parentId === parentId).map(child => ({ ...child, children: arrayToTree(array, child.id) }))
}

export const fetchOrg = async (societe, client) => {
    const header = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetPostes", 
    };
    const body = {
        societe:  societe
    };
    const response = await axios.post(urls.getOrganigramme, body, { headers: header, params: { client: client } });
    
    let data = {};
    if(response?.data?.postes.length > 0) {
        const codesPoste = [...new Set(response.data.postes.map((poste) => poste.id))];
        const nodes = response.data.postes.map((poste) => ({parentId: poste.parentId, id: poste.id, attributes: {...poste}, children: []}))
        if(codesPoste.length === response.data.postes.length) {
            data = arrayToTree(nodes, "");
        }
    }
  
    return {brut: response.data.postes, built: data};
}
