import { urls } from "@constants/appConstants";
import axios from "axios";

export const fetchRes = async (client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetResponsables"
    };
    const response = await axios.get(urls.getResponsables, { headers: headers, params: { client: client }, signal: signal });
    const responsables = response?.data?.responsables?.map((responsable) => ({ value: responsable.nom, label: responsable.prenomNom }))
    return responsables;
}