import { Stack } from "@mui/material";
import { FilterList, MobiledataOff, SwapVert } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useResetTrieCollaborateurs } from "@hooks/collaborateurs/hooksUtils";
import { IconButtonBase } from "@components/Buttons";

const CollaborateursFwmv01Actions = ({ 
    stateType = "",
    isLoading = false, 
    tried = false, 
    toggleTrie = () => {}, 
    setOpenFiltre = () => {}
}) => {
    const resetTrie = useResetTrieCollaborateurs(stateType);

    return (
        <Stack direction={"row"} spacing={0.5}>
            <IconButtonBase variant="white5Grey9White9" icon={tried ? <MobiledataOff fontSize="small" /> : <SwapVert fontSize="small" />} disabled={isLoading} title={tried ? "Enlever le trie" : "Trier"} action={tried ? resetTrie : toggleTrie} />
            <IconButtonBase variant="white5Grey9White9" icon={<FilterList fontSize="small" />} disabled={isLoading} title="Filtrer" action={() => setOpenFiltre((prevOpen) => !prevOpen)} />
        </Stack>
    );
}

CollaborateursFwmv01Actions.propTypes = {
    stateType: PropTypes.string,
    isLoading: PropTypes.bool,
    tried: PropTypes.bool,
    toggleTrie: PropTypes.func,
    setOpenFiltre: PropTypes.func,
}

export default CollaborateursFwmv01Actions;