import { Box, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { DialogFullScreenContainer, HeaderDialogContainer } from "@components/Containers";
import PerfectScrollbar from "react-perfect-scrollbar";
import { TempsInformationsList } from "../Lists";

const InformationsDialogMobile = (props) => {
    const { open, setOpen, isLoading, userType } = props;

    return (
        <DialogFullScreenContainer open={open} placement="top" taille="md">
            <HeaderDialogContainer 
                title="Informations"
                close={() => setOpen(false)}
            />
            <Stack spacing={0.5}>
                <Box overflow={"auto"} boxSizing={"border-box"}>
                    <PerfectScrollbar>
                        <TempsInformationsList 
                            isLoading={isLoading} 
                            userType={userType} 
                        />
                    </PerfectScrollbar>
                </Box>
            </Stack>
        </DialogFullScreenContainer>
    );
}

InformationsDialogMobile.propTypes = {
    isLoading: false
}

InformationsDialogMobile.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
}

export default InformationsDialogMobile;