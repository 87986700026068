import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EventAvailable, HighlightAlt, People } from "@mui/icons-material";
import { useDlgApp } from "@hooks/delegations/hooksQueries";
import { selectDlgApp } from "@selectors";
import * as dlgAppActions from "@reducers/delegations/dlgAppReducer"
import { MenuFiltreContainer, MenuTrieContainer } from "@components/Containers";
import DelegationsActionsBar from "./ActionsBar/DelegationsActionsBar";
import { trierDlgApp } from "../tries";
import { FiltreDelegationsForm } from "./Forms";
import { AddDialog, AddDialogMobile, DeleteDialog, DeleteDialogMobile, DetailDialog, DetailDialogMobile, EditDialog, EditDialogMobile } from "./Dialogs";
import { DelegationsList } from "./Lists";
import { useMedia } from "react-use";

const optionsTrie = [
    {id: 1, label: "Par nature", keyAsc: "natureAsc", keyDesc: "natureDesc", icon: <HighlightAlt fontSize="small" />},
    {id: 2, label: "Par date début", keyAsc: "dateDebutAsc", keyDesc: "dateDebutDesc", icon: <EventAvailable fontSize="small" />},
    {id: 3, label: "Par date fin", keyAsc: "dateRepriseAsc", keyDesc: "dateRepriseDesc", icon: <EventAvailable fontSize="small" />},
    {id: 4, label: "Par délégataire", keyAsc: "delegataireAsc", keyDesc: "delegataireDesc", icon: <People fontSize="small" />}
];

const ListPage = () => {
    const [openWarningDelete, setOpenWarningDelete] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDetailDialog, setOpenDetailDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openFiltre, setOpenFiltre] = useState(false);
    const stateDelegations = useSelector(selectDlgApp);
    const { isLoading, isFetching, isError, error, refetch } = useDlgApp(stateDelegations.type);
    const dispatch = useDispatch();
    const isMobile = useMedia('(max-width: 650px)');

    const lister = useCallback(() => {
        dispatch(dlgAppActions.lister());
    }, [dispatch]);

    const toggleType = useCallback(() => {
        dispatch(dlgAppActions.toggleType());
    }, [dispatch]);

    const goTo = useCallback((page) => {
        dispatch(dlgAppActions.goTo(page))
    }, [dispatch])

    const deleteTrie = useCallback(() => {
        dispatch(dlgAppActions.deleteTrie());
    }, [dispatch])

    const deleteFiltre = useCallback(() => {
        dispatch(dlgAppActions.deleteFiltre());
    }, [dispatch])

    const openAjout = useCallback(() => {
        setOpenAddDialog(true);
    }, []);

    const openDetail = useCallback((delegation) => {
        dispatch(dlgAppActions.selectDelegation(delegation));
        setOpenDetailDialog(true);
    }, [dispatch]);

    const openEdit = useCallback((delegation) => {
        dispatch(dlgAppActions.selectDelegation(delegation));
        setOpenEditDialog(true);
    }, [dispatch]);

    const openWarning = useCallback((delegation) => {
        dispatch(dlgAppActions.selectDelegation(delegation));
        setOpenWarningDelete(true);
    }, [dispatch]);

    const trier = useCallback((keyTrie) => {
        const data = [...stateDelegations.delegations];
        const dataTried = trierDlgApp(keyTrie, data);
        dispatch(dlgAppActions.trier(dataTried));
    }, [dispatch, stateDelegations.delegations]);

    return (
        <>
            <DelegationsActionsBar 
                isLoading={isLoading || isFetching}
                isError={isError}
                listed={stateDelegations.listed}
                tried={stateDelegations.tried}
                filtered={stateDelegations.filtered}
                type={stateDelegations.type}
                setAnchorEl={setAnchorEl}
                deleteFiltre={deleteFiltre}
                deleteTrie={deleteTrie}
                goTo={goTo}
                openAjout={openAjout}
                lister={lister}
                setOpenFiltre={setOpenFiltre}
                toggleType={toggleType}
                refetch={refetch}
            />
            <DelegationsList 
                data={stateDelegations.delegationsDisplayed} 
                isLoading={isLoading || isFetching}
                isError={isError}
                errorMessage={error?.message}
                listed={stateDelegations.listed} 
                refetch={refetch}
                openDetail={openDetail} 
                openEdit={openEdit} 
                openWarning={openWarning} 
            />
            <MenuTrieContainer 
                anchorEl={anchorEl} 
                setAnchorEl={setAnchorEl} 
                options={optionsTrie} 
                trier={trier} 
            />
            <MenuFiltreContainer open={openFiltre} setOpen={setOpenFiltre}>
                <FiltreDelegationsForm setOpen={setOpenFiltre} />
            </MenuFiltreContainer>
            {isMobile ? 
                <>
                    <DeleteDialogMobile 
                        open={openWarningDelete} 
                        setOpen={setOpenWarningDelete} 
                        setOpenWarningDelete={setOpenWarningDelete}
                        refetch={refetch}
                    />
                    <AddDialogMobile 
                        open={openAddDialog}
                        setOpen={setOpenAddDialog}
                        setOpenAddDialog={setOpenAddDialog}
                        refetch={refetch}
                    />
                    <EditDialogMobile 
                        open={openEditDialog}
                        setOpen={setOpenEditDialog}
                        setOpenEditDialog={setOpenEditDialog}
                        refetch={refetch}
                    />
                    <DetailDialogMobile
                        open={openDetailDialog}
                        setOpen={setOpenDetailDialog}
                    />
                </>
            :
                <>
                    <DeleteDialog 
                        open={openWarningDelete} 
                        setOpen={setOpenWarningDelete} 
                        setOpenWarningDelete={setOpenWarningDelete}
                        refetch={refetch}
                    />
                    <AddDialog 
                        open={openAddDialog}
                        setOpen={setOpenAddDialog}
                        setOpenAddDialog={setOpenAddDialog}
                        refetch={refetch}
                    />
                    <EditDialog 
                        open={openEditDialog}
                        setOpen={setOpenEditDialog}
                        setOpenEditDialog={setOpenEditDialog}
                        refetch={refetch}
                    />
                    <DetailDialog
                        open={openDetailDialog}
                        setOpen={setOpenDetailDialog}
                    />
                </>
            }
        </>
    );
}

export default ListPage;