import axios from "axios";
import { urls } from "@constants/appConstants";

/**
 * * Appel de la procédure PWTABS
 * * Url : get/types/absence
 * @param {*} matricule matricule du demandeur
 * @param {*} date date de la demande d'absence
 * @param {*} client 
 * @param {*} signal 
 * @returns liste des types d'absences
 */
export const fetchTypAbs = async (matricule, date, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetTypesAbsence"
    };
    const body = {
        matricule: matricule,
        date: date
    };
    const response = await axios.post(urls.getTypesAbsence, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.typesAbsences;
}