import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { whiteColor } from "@styles";

const TextValue = (props) => {
    const { title, value, icon } = props;

    return (
        <Stack direction="row" spacing={0.5}>
            <Box bgcolor={whiteColor[5]} width={90} borderRadius={2} display="flex" justifyContent="center" alignItems="center" paddingX={1}>
                {icon}
            </Box>
            <Stack spacing={0.5} flexGrow={1}>
                <Box bgcolor={whiteColor[5]} borderRadius={2} height={34} display="flex" alignItems="center" justifyContent="center">
                    <Typography fontWeight="bold" fontSize="1.2rem">{title}</Typography>
                </Box>
                <Box bgcolor={whiteColor[5]} borderRadius={2} height={34} display="flex" alignItems="center" justifyContent="center">
                    <Typography fontWeight={400}>{value}</Typography>
                </Box>
            </Stack>
        </Stack>
    );
}

TextValue.defaultProps = {
    title: "",
    value: "",
    icon: <></>
}

TextValue.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array])
}

export default TextValue;