import { useSelector, useDispatch } from "react-redux";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { Check, ChevronLeft, ChevronRight, Close, Event, Refresh, Save } from "@mui/icons-material";
import PropTypes from "prop-types";
import { selectPlaGesTem } from "@selectors";
import * as plaGesTemActions from "@reducers/gestionTemps/planning/plaGesTemReducer";
import { IconButtonBase, MenuButton } from "@components/Buttons";
import { datify, heurify } from "@utils";
import { whiteColor } from "@styles";
import { CheckInput } from "@components/Inputs";
import { useEffect, useState } from "react";
import moment from "moment";
import { WeekPickerPlanningButton } from "@components/Buttons/GestionTemps";


const PlanningActionsBar = (props) => {
    const { isLoading, prevWeek, nextWeek, verificationPlanning, selectDate, refetch } = props;
    const [authorized, setAuthorized] = useState(false);
    const statePlaGesTem = useSelector(selectPlaGesTem);
    const dispatch = useDispatch();

    useEffect(() => {
        const authLundi = statePlaGesTem.authorizationLundi === "0";
        const authMardi = statePlaGesTem.authorizationMardi === "0";
        const authMercredi = statePlaGesTem.authorizationMercredi === "0";
        const authJeudi = statePlaGesTem.authorizationJeudi === "0";
        const authVendredi = statePlaGesTem.authorizationVendredi === "0";
        const authSamedi = statePlaGesTem.authorizationSamedi === "0";
        const authDimanche = statePlaGesTem.authorizationDimanche === "0";
        const authWeek = authLundi || authMardi || authMercredi || authJeudi || authVendredi || authSamedi || authDimanche;
        setAuthorized(authWeek);
    }, [statePlaGesTem.authorizationDimanche, statePlaGesTem.authorizationJeudi, statePlaGesTem.authorizationLundi, statePlaGesTem.authorizationMardi, statePlaGesTem.authorizationMercredi, statePlaGesTem.authorizationSamedi, statePlaGesTem.authorizationVendredi]);

    return (
        <Stack spacing={0.5} direction="row" bgcolor={whiteColor[5]} borderRadius={2} px={2} py={1} alignItems="center">
            <Box flexGrow={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    {isLoading ? 
                        <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={251} height={19.5} />
                    :
                        <Typography component="div" fontSize="small">
                            Semaine <Box fontWeight="bold" display="inline">{statePlaGesTem.numeroSemaine} </Box> 
                            Du <Box fontWeight="bold" display="inline">{datify(statePlaGesTem.periodeDebut)} </Box>
                            Au <Box fontWeight="bold" display="inline">{datify(statePlaGesTem.periodeFin)} </Box>
                        </Typography> 
                    }
                    <Box>
                        <MenuButton icon={<ChevronLeft fontSize="small" />} title="Semaine précédente" noClick={isLoading} options={[
                                {id: 1, icon: <Save fontSize="small" />, label: "Sauvergarder les modifications", action: () => prevWeek(true)},
                                {id: 2, icon: <Close fontSize="small" />, label: "Ne pas sauvegarder les modifications", action: () => prevWeek()}
                            ]} 
                        />
                        <MenuButton icon={<ChevronRight fontSize="small" />} title="Semaine suivante" noClick={isLoading} options={[
                                {id: 1, icon: <Save fontSize="small" />, label: "Sauvergarder les modifications", action: () => nextWeek(true)},
                                {id: 2, icon: <Close fontSize="small" />, label: "Ne pas sauvegarder les modifications", action: () => nextWeek()}
                            ]}     
                        />
                        <WeekPickerPlanningButton icon={<Event fontSize="small" />} title="Choisir une semaine" timeout={600} noClick={isLoading} date={statePlaGesTem.dateLundi ? moment(statePlaGesTem.dateLundi, "YYYYMMDD") : null} action={selectDate} />
                        <IconButtonBase icon={<Check fontSize="small" />} title="Valider les modifications" noClick={isLoading} action={() => verificationPlanning()} />
                        <MenuButton icon={<Refresh fontSize="small" />} title="Rafraichier le planning" noClick={isLoading} options={[
                                {id: 1, icon: <Save fontSize="small" />, label: "Sauvergarder les modifications", action: () => refetch(true)},
                                {id: 2, icon: <Close fontSize="small" />, label: "Ne pas sauvegarder les modifications", action: () => refetch()}
                            ]} 
                        />
                        {authorized &&
                            <Box display={"inline"} px={1}>
                                <CheckInput label="Modification des affectations horaires" checked={statePlaGesTem.modificationAffectationsHoraires} onCheck={(event) => dispatch(plaGesTemActions.setModificationAffectationsHoraires(event.target.checked))} noClick={isLoading} />
                            </Box>
                        }
                    </Box>
                </Stack>      
            </Box>  
            {(statePlaGesTem.baseContrat && statePlaGesTem.baseContrat !== "0") &&
                <Box>
                    {isLoading ? 
                        <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={115} height={19.5} />
                    :
                        <Typography component="div" fontSize="small">
                            Base contrat <Box fontWeight="bold" display="inline">{heurify(statePlaGesTem.baseContrat, "h", false)}</Box> 
                        </Typography> 
                    }
                </Box>   
            }
        </Stack>
    );
}

PlanningActionsBar.propTypes = {
    isLoading: PropTypes.bool.isRequired, 
    prevWeek: PropTypes.func.isRequired, 
    nextWeek: PropTypes.func.isRequired, 
    verificationPlanning: PropTypes.func.isRequired, 
    selectDate: PropTypes.func.isRequired,
    refetch: PropTypes.func.isRequired
}

export default PlanningActionsBar;