import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { ArrowLeft, Check, Close } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import PropTypes from "prop-types";
import * as entSecColActions from "@reducers/gestionEntretiens/entSecColReducer";
import { useAddSousSectionCol } from "@hooks/gestionEntretiens/hooksQueries";
import { useMappingActionsCol } from "@hooks/gestionEntretiens/hooksUtils";
import { IconButtonBase } from "@components/Buttons";
import { Loader } from "@components/Loaders";
import { ChampCommentaire, ChampDesignation } from "@components/GestionEntretiens/Collaborateur/Forms/Champs";
import { greenColor, greyColor, whiteColor } from "@styles";
import { schemaAjoutSousSectionCollaborateur } from "@components/GestionEntretiens/validations";

const defaultValues = {
    designation1: "",
    designation2: "",
    commentaire: ""
}

const AjoutSectionForm = ({ 
    goBack = () => {} 
}) => {
    const methods = useForm({ mode: "onSubmit", defaultValues: defaultValues, resolver: zodResolver(schemaAjoutSousSectionCollaborateur) })
    const { designation1, designation2 } = useMappingActionsCol()
    const { isLoading, addSousSection } = useAddSousSectionCol(); 
    const dispatch = useDispatch();

    useEffect(() => {
        methods.reset();
    }, [methods]);

    const validate = useCallback(async (dataForm) => {
        await addSousSection(dataForm);
        goBack();
    }, [addSousSection, goBack]);

    if(isLoading) {
        return (
            <>
                <Box borderRadius={2} bgcolor={greenColor["015"]} height={50} p={0.5}>
                    <Skeleton variant="rounded" animation="wave" width="100%" height="100%" sx={{borderRadius: 2}} />
                </Box>
                <Box justifyContent="center" display="flex" alignItems="center" height="calc(100vh - 140px)" p={2}>
                    <Box bgcolor={whiteColor[5]} borderRadius={2} width={50} height={50} justifyContent="center" display="flex" alignItems="center">
                        <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                    </Box>
                </Box>
            </>
        );
    }

    return (
        <>
            <Box borderRadius={2} bgcolor={greenColor["05"]} height={50} p={0.5}>
                <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[7]} height="100%" alignItems="center" px={1}>
                    <IconButtonBase icon={<ArrowLeft fontSize="small" />} title="Retour" action={() => goBack()} />
                    <Box flexGrow={1}>
                        <Typography fontSize="small" fontWeight="bold">{designation1}</Typography>
                        <Typography fontSize="small" fontWeight="bold">{designation2}</Typography>
                    </Box>
                    <IconButtonBase 
                        icon={<Close fontSize="small" />} 
                        title="Annuler" 
                        action={() => dispatch(entSecColActions.setModeAjout(false))} 
                    />
                    <IconButtonBase 
                        icon={<Check fontSize="small" />} 
                        title="Valider" 
                        action={() => methods.handleSubmit(validate)()} 
                    />
                </Stack>
            </Box>
            <Stack spacing={0.5}>
                <FormProvider {...methods}>
                    <ChampDesignation />
                    <ChampCommentaire />
                </FormProvider>
            </Stack>
        </>
    );
}

AjoutSectionForm.propTypes = {
    goBack: PropTypes.func
}

export default AjoutSectionForm;