import { Box, Grid, Stack, Typography } from "@mui/material";
import { useChampsComplementaires2 } from "@hooks/gestionEntretiens/hooksUtils"
import { SelectHookFormInput, TextHookFormInput } from "@components/Inputs";
import { greenColor, whiteColor } from "@styles";

const ChampsComplementaires = () => {
    const { 
        displayV, displayA, displayE, displayCC12, displayCC34, 
        displayCC1, displayCC2, displayCC3, displayCC4,
        codeTableCC1, codeTableCC2, codeTableCC3, codeTableCC4,
        designationCC1, designationCC2, designationCC3, designationCC4,
        complementCC1, complementCC2, complementCC3, complementCC4,
        optionsCC1, optionsCC2, optionsCC3, optionsCC4 
    } = useChampsComplementaires2(); 

    if(displayA || displayE) {
        return (
            <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                <Box borderRadius={2} bgcolor={whiteColor[7]} p={0.5} height="100%" whiteSpace={"pre-wrap"}>
                    <Grid container spacing={0.5}>
                        {displayCC12 && 
                            <>
                                {displayCC1 && 
                                    <Grid item xs={12} lg={6}>
                                        {codeTableCC1 ? 
                                            <SelectHookFormInput name="complement1" label={designationCC1} options={optionsCC1} />
                                        :
                                            <TextHookFormInput name="complement1" label={designationCC1} maxLength={30} />
                                        }
                                    </Grid>
                                }
                                {displayCC2 && 
                                    <Grid item xs={12} lg={6}>
                                        {codeTableCC2 ? 
                                            <SelectHookFormInput name="complement2" label={designationCC2} options={optionsCC2} />
                                        :
                                            <TextHookFormInput name="complement2" label={designationCC2} maxLength={30} />
                                        }
                                    </Grid>
                                }
                            </>
                        }
                        {displayCC34 && 
                            <>
                                {displayCC3 &&
                                    <Grid item xs={12} lg={6}>
                                        {codeTableCC3 ? 
                                            <SelectHookFormInput name="complement3" label={designationCC3} options={optionsCC3} />
                                        :
                                            <TextHookFormInput name="complement3" label={designationCC3} maxLength={30} />
                                        }
                                    </Grid>
                                }
                                {displayCC4 &&
                                    <Grid item xs={12} lg={6}>
                                        {codeTableCC4 ? 
                                            <SelectHookFormInput name="complement4" label={designationCC4} options={optionsCC4} />
                                        :
                                            <TextHookFormInput name="complement4" label={designationCC4} maxLength={30} />
                                        }
                                    </Grid>
                                }
                            </>
                        }
                    </Grid>
                </Box>
            </Box>
        )
    }

    if(displayV) {
        return (
            <Box height="100%" bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                <Stack spacing={0.5}>
                    {displayCC12 && 
                        <Stack spacing={0.5} direction="row">
                            {displayCC1 &&
                                <Box borderRadius={2} bgcolor={whiteColor[7]} p={1} flexGrow={1} display="inline">
                                    <Typography fontSize={"small"} fontWeight="bold" display="inline">{designationCC1} : </Typography>
                                    <Typography fontSize={"small"} display="inline">{complementCC1}</Typography>
                                </Box>
                            }
                            {displayCC2 &&
                                <Box borderRadius={2} bgcolor={whiteColor[7]} p={1} flexGrow={1} display="inline">
                                    <Typography fontSize={"small"} fontWeight="bold" display="inline">{designationCC2} : </Typography>
                                    <Typography fontSize={"small"} display="inline">{complementCC2}</Typography>
                                </Box>
                            }
                        </Stack>
                    }
                    {displayCC34 &&
                        <Stack spacing={0.5} direction="row" display="flex" alignItems="center">
                            {displayCC3 &&
                                <Box borderRadius={2} bgcolor={whiteColor[7]} p={1} flexGrow={1} display="inline">
                                    <Typography fontSize={"small"} fontWeight="bold" display="inline">{designationCC3} : </Typography>
                                    <Typography fontSize={"small"} display="inline">{complementCC3}</Typography>
                                </Box>
                            }
                            {displayCC4 &&
                                <Box borderRadius={2} bgcolor={whiteColor[7]} p={1} flexGrow={1} display="inline">
                                    <Typography fontSize={"small"} fontWeight="bold" display="inline">{designationCC4} : </Typography>
                                    <Typography fontSize={"small"} display="inline">{complementCC4}</Typography>
                                </Box>
                            }
                        </Stack>
                    }
                </Stack>
            </Box>
        )
    }

    return null;
}

export default ChampsComplementaires;