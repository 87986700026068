import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Grow, Tooltip, Typography } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { Stack } from "@mui/system";
import { useSnackbar } from "notistack";
import { images } from "@constants/appConstants";
import { selectUsrApp } from "@selectors";
import * as userActions from "@reducers/user/usrAppReducer"
import { greenColor, greyColor, whiteColor } from "@styles";
import { editBkg } from "@api/background";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";

const BackgroundsList = () => {
    const stateUser = useSelector(selectUsrApp);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation("global");

    const saveBackground = useCallback(async (event) => {
        const image = event.currentTarget.dataset.image;
        dispatch(userActions.openBackground(false));
        try {
            const response = await editBkg(image, stateUser.token);
            if(response.data.succeed) {
                sessionStorage.setItem("currentUser", JSON.stringify({...JSON.parse(sessionStorage.getItem('currentUser')), background: image}));
                dispatch(userActions.setBackground(image));
                enqueueSnackbar(response.data.details, { variant: "success", anchorOrigin: { vertical: "bottom", horizontal: "center" } });
            } else {
                enqueueSnackbar(response.data.details, { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "center" } });
            }
        } catch(e) {
            enqueueSnackbar(t("errors.edit_background"), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "center" } });
        }
    }, [dispatch, enqueueSnackbar, stateUser.token, t]);

    return (
        <Stack spacing={0.5}>
            <Box bgcolor={greenColor["07"]} borderRadius={2} p={0.5}>
                <Box bgcolor={whiteColor[7]} borderRadius={2} p={1}>
                    <Typography fontSize="small" fontWeight="bold">{t("texts.choose_background")}</Typography>
                </Box>
            </Box>
            <PerfectScrollbar>
                <Box height="calc(100vh - 177px)">
                    <Grid container spacing={1}>
                        {images.map((image, id) => (
                            image.id === 1 ?
                                <Grow key={`IMG${image.id}`} in={true} timeout={300}>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Tooltip title={t("no_data.background")} placement="top">
                                            <Box data-image={"image0"} onClick={saveBackground} sx={styles.image0ListItem}>
                                                <Cancel />
                                            </Box>
                                        </Tooltip>
                                    </Grid> 
                                </Grow>
                            :
                                <Grow key={`IMG${image.id}`} in={true} timeout={300*id}>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Box data-image={image.alt} onClick={saveBackground} sx={styles.imagesListItem}>
                                            <img src={image.src} alt={image.alt} style={styles.imageStyle}/>
                                        </Box>
                                    </Grid>     
                                </Grow>   
                        ))} 
                    </Grid>  
                </Box> 
            </PerfectScrollbar>
        </Stack>
    );
}

const styles = {
    boxMessage: {
        backgroundColor: greenColor["02"],
        padding: 1,
        borderRadius: 2
    },
    imagesListItem: {
        cursor: "pointer", 
        borderRadius: 2,
        height: 120
    },
    image0ListItem: {
        cursor: "pointer", 
        justifyContent: "center", 
        alignItems: "center",
        display: "flex",
        backgroundColor: greyColor[0],
        height: "120px",
        borderRadius: 2
    },
    imageStyle: { 
        height: 120, 
        borderRadius: 8,
        width: "100%"
    }
}

export default BackgroundsList;