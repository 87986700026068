import { Box, Stack, Typography } from "@mui/material";
import { Check } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { Loader } from "@components/Loaders";
import { DialogContainer, HeaderDialogContainer } from "@components/Containers";
import { greenColor, greyColor, whiteColor, yellowColor } from "@styles";
import { useTranslation } from "react-i18next";
import { IconButtonBase } from "@components/Buttons";

const PointageDialog = ({
    isLoading = false, 
    dialogType = "pointage", 
    activite = "", 
    actionSuivante = "", 
    open = false, 
    setOpen = () => {}, 
    pointer = () => {}
}) => {
    const { t } = useTranslation("global");

    return (
        <DialogContainer open={open} placement="top" taille="sm">
            <HeaderDialogContainer 
                actionsLeft={
                    <IconButtonBase variant="white5Grey9White9" icon={<Check fontSize="small" />} title="Valider" disabled={isLoading} placement="top" action={() => pointer()} />
                }
                title={t("titles.confirm_movement")}
                close={() => setOpen(false)}
                variant="warning"
            />
            {isLoading ? 
                <Box sx={styles.loaderStyle}>
                    <Loader size={27} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                </Box>
            :
                <Stack spacing={0.5} bgcolor={yellowColor[5]} borderRadius={2} p={0.5}>
                    <Box bgcolor={whiteColor[5]} borderRadius={2} p={1}>
                        <Typography fontSize="small">{t("texts.validation_movement")}</Typography>
                    </Box>
                    {{
                        pointage: 
                            <Box bgcolor={whiteColor[5]} borderRadius={2} p={1}>
                                <Typography fontSize="small" fontWeight="bold">{actionSuivante === "E" ? t("texts.entrance") : t("texts.departure")} {t("texts.the")} {moment().format("DD/MM/YYYY")} {t("texts.at")} {moment().format("HH:mm:ss")}</Typography>
                            </Box>,
                        activites: 
                            <Stack spacing={0.5} bgcolor={whiteColor[5]} borderRadius={2} p={1}>
                                <Box>
                                    <Typography fontSize="small" fontWeight="bold">{t("texts.activity_change_the")} {moment().format("DD/MM/YYYY")} {t("texts.at")} {moment().format("HH:mm:ss")}</Typography>
                                </Box>
                                <Box>
                                    <Typography fontSize="small" fontWeight="bold">{t("texts.activity")} : {activite}</Typography>
                                </Box>
                            </Stack>
                    }[dialogType]}
                </Stack>
            }
        </DialogContainer>
    );
}

PointageDialog.propTypes = {
    isLoading: PropTypes.bool,
    dialogType: PropTypes.string,
    activite: PropTypes.string,
    actionSuivante: PropTypes.string,
    open: PropTypes.bool, 
    setOpen: PropTypes.func, 
    pointer: PropTypes.func
}

const styles = {
    loaderStyle: {
        height: 52,
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
    },
    boxItemStyle: {
        backgroundColor: greyColor[0], 
        borderRadius: 2,
        p:1
    },
    boxWarningItemStyle: {
        backgroundColor: yellowColor[3], 
        borderRadius: 2,
        p:1
    },
}

export default PointageDialog;