import { useSelector, useDispatch } from "react-redux";
import { DemandesAbsenceActionsBar } from "./ActionsBars";
import { DemandesAbsenceList } from "./Lists";
import { selectDabGesAbsMan } from "@selectors";
import * as dabGesAbsManActions from "@reducers/gestionAbsences/demandes/dabGesAbsManReducer"
import * as plaDabManActions from "@reducers/planning/plaDabManReducer"
import { useCallback, useState } from "react";
import { MenuFiltreContainer, MenuTrieContainer } from "@components/Containers";
import { trierDabGesAbsMan } from "../tries";
import { EventAvailable, HighlightAlt, HistoryEdu, QuestionAnswerOutlined } from "@mui/icons-material";
import { useDabGesAbsMan } from "@hooks/gestionAbsences/demandes/hooksQueries";
import { FiltreDemandesAbsenceForm } from "./Forms";

const optionsTrie = [
    {id: 1, label: "Par date d'émission", keyAsc: "dateEmissionAsc", keyDesc: "dateEmissionDesc", icon: <EventAvailable fontSize="small" />},
    {id: 2, label: "Par date d'absence", keyAsc: "dateAbsenceAsc", keyDesc: "dateAbsenceDesc", icon: <EventAvailable fontSize="small" />},
    {id: 3, label: "Par emetteur", keyAsc: "emetteurAsc", keyDesc: "emetteurDesc", icon: <HistoryEdu fontSize="small" />},
    {id: 4, label: "Par nature", keyAsc: "natureAsc", keyDesc: "natureDesc", icon: <HighlightAlt fontSize="small" />},
    {id: 5, label: "Par statut", keyAsc: "statutAsc", keyDesc: "statutDesc", icon: <QuestionAnswerOutlined fontSize="small" />}
];

const ListPage = () => {
    const stateAbsencesManager = useSelector(selectDabGesAbsMan);
    const [openFiltre, setOpenFiltre] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { isLoading, isFetching, isError, error, refetch } = useDabGesAbsMan();
    const dispatch = useDispatch();

    const lister = useCallback(() => {
        dispatch(dabGesAbsManActions.lister());
    }, [dispatch]);

    const openTrie = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);
    
    const goTo = useCallback((page) => {
        dispatch(dabGesAbsManActions.goTo(page))
    }, [dispatch])

    const deleteTrie = useCallback(() => {
        dispatch(dabGesAbsManActions.deleteTrie());
    }, [dispatch])

    const deleteFiltre = useCallback(() => {
        dispatch(dabGesAbsManActions.deleteFiltre());
    }, [dispatch])

    const filtrerParReponse = useCallback(() => {
        dispatch(dabGesAbsManActions.filtrerParReponse());
    }, [dispatch])

    const trier = useCallback((keyTrie) => {
        const data = [...stateAbsencesManager.demandesAbsence];
        const dataTried = trierDabGesAbsMan(keyTrie, data);
        dispatch(dabGesAbsManActions.trier(dataTried));
    }, [dispatch, stateAbsencesManager.demandesAbsence]);

    const goToWithSelection = useCallback((page, demande) => {
        dispatch(plaDabManActions.setDate(demande.dateDebut));
        dispatch(dabGesAbsManActions.goToWithSelection({page: page, demande: demande}));
    }, [dispatch]);

    return (
        <>
            <DemandesAbsenceActionsBar 
                isLoading={isLoading || isFetching}
                isError={isError}
                listed={stateAbsencesManager.listed}
                all={stateAbsencesManager.all}
                filtered={stateAbsencesManager.filtered}
                tried={stateAbsencesManager.tried}
                deleteFiltre={deleteFiltre}
                deleteTrie={deleteTrie}
                goTo={goTo}
                setOpenFiltre={setOpenFiltre}
                openTrie={openTrie}
                filtrerParReponse={filtrerParReponse}
                lister={lister}
                refetch={refetch}
            /> 
            <DemandesAbsenceList 
                data={stateAbsencesManager.demandesAbsenceDisplayed} 
                isLoading={isLoading || isFetching} 
                isError={isError} 
                errorMessage={error?.message} 
                listed={stateAbsencesManager.listed}
                all={stateAbsencesManager.all}
                goToWithSelection={goToWithSelection}
                filtrerParReponse={filtrerParReponse}
                refetch={refetch}
            />
            <MenuTrieContainer 
                anchorEl={anchorEl} 
                setAnchorEl={setAnchorEl} 
                options={optionsTrie} 
                trier={trier} 
            />
            <MenuFiltreContainer open={openFiltre} setOpen={setOpenFiltre}>
                <FiltreDemandesAbsenceForm setOpen={setOpenFiltre} />
            </MenuFiltreContainer>
        </>
    )
}

export default ListPage;