import { Skeleton, Stack } from "@mui/material";
import { Event, FilterList, FilterListOff, MobiledataOff, Refresh, SwapVert, ViewList, ViewModule } from "@mui/icons-material";
import PropTypes from "prop-types";
import { DatePickerButton, IconButtonBase } from "@components/Buttons";
import { HeaderPanelContainer } from "@components/Containers";
import moment from "moment";
import { whiteColor } from "@styles";
import { useMedia } from "react-use";

const CompteursActionsBar = (props) => {
    const { 
        date,
        isLoading, 
        isError, 
        listed, 
        tried,
        filtered, 
        toggleList, 
        openTrie, 
        selectDate,
        setOpenFiltre,
        deleteTrie, 
        deleteFiltre,
        refetch 
    } = props;
    const isMobile = useMedia('(max-width: 650px)');

    if(isLoading) {
        return (
            <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={"100%"} height={46} />
        )
    }

    return (
        <HeaderPanelContainer 
            actionsLeft={
                !isError &&
                    <Stack direction="row" spacing={0.5}>
                        {tried ? 
                            <IconButtonBase icon={<MobiledataOff fontSize="small" />} title="Enlever le trie" action={() => deleteTrie()} /> 
                        : 
                            <IconButtonBase icon={<SwapVert fontSize="small" />} title="Trier" action={openTrie} />
                        }
                        {filtered ? 
                            <IconButtonBase icon={<FilterListOff fontSize="small" />} title="Enlever le filtre" action={() => deleteFiltre()} /> 
                        : 
                            <IconButtonBase icon={<FilterList fontSize="small" />} title="Filtrer" action={() => setOpenFiltre(true)} />
                        }
                        <DatePickerButton icon={<Event fontSize="small" />} title="Choisir une date" value={moment(date, "DDMMYYYY")} action={selectDate} />
                    </Stack>
            }
            actionsRight={
                <Stack direction="row" spacing={0.5} justifyContent="right">
                    {!isError &&
                        <>
                            {!isMobile && <IconButtonBase icon={listed ? <ViewModule fontSize="small" /> : <ViewList fontSize="small" />} title={listed ? "Panneaux" : "Liste"} action={() => toggleList()} />}        
                            <IconButtonBase icon={<Refresh fontSize="small" />} title="Rafraichir" action={() => refetch()} />   
                        </>                
                    }
                </Stack>
            }   
            bgColor={whiteColor[5]}
            title={!isMobile && `Compteurs à partir du ${moment(date, "DDMMYYYY").format("DD/MM/YYYY")}`}
        />
    );
}

CompteursActionsBar.defaultProps = {
    date: "",
    isLoading: true, 
    isError: false, 
    listed: true, 
    tried: false,
    filtered: false, 
    toggleList: () => {}, 
    openTrie: () => {}, 
    selectDate: () => {},
    setOpenFiltre: () => {},
    deleteTrie: () => {},
    deleteFiltre: () => {},
    refetch: () => {}
}

CompteursActionsBar.propTypes = {
    date: PropTypes.string,
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool, 
    listed: PropTypes.bool,
    tried: PropTypes.bool,
    filtered: PropTypes.bool, 
    toggleList: PropTypes.func, 
    openTrie: PropTypes.func, 
    selectDate: PropTypes.func,
    setOpenFiltre: PropTypes.func, 
    deleteTrie: PropTypes.func,
    deleteFiltre: PropTypes.func, 
    refetch: PropTypes.func
}

export default CompteursActionsBar;