import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { redColor } from "@styles";

const ErrorBase = (props) => {
    const { children, p, height, textSize, textWeight, textAlign, horizontalAlign, verticalAlign } = props;

    return(
        <Box bgcolor={redColor[1]} padding={p} borderRadius={2} height={height} boxSizing="border-box" display="flex" textAlign={textAlign} alignItems={verticalAlign} justifyContent={horizontalAlign}>
            <Typography fontSize={textSize} fontWeight={textWeight}>
                {children}
            </Typography>
        </Box>
    );
}

ErrorBase.defaultProps = {
    children: <></>, 
    p: 1, 
    height: "100%", 
    textSize: "small", 
    textWeight: "normal",
    textAlign: "center", 
    horizontalAlign: "center", 
    verticalAlign: "center"
}

ErrorBase.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]), 
    p: PropTypes.number, 
    height: PropTypes.string, 
    textSize: PropTypes.string,
    textWeight: PropTypes.string, 
    textAlign: PropTypes.string, 
    horizontalAlign: PropTypes.string, 
    verticalAlign: PropTypes.string
}

export default ErrorBase;