import { Box, Grid, IconButton } from "@mui/material";
import { Info } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { ListContainer, TextContainer } from "@components/Containers";
import { ErrorBase } from "@components/Errors";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, whiteColor } from "@styles";

const TransactionsList = (props) => {
    const { data, isLoading, isError, errorMessage, openAnomalies } = props;

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader size={20} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return (<ErrorBase>{errorMessage}</ErrorBase>);
    }

    return (
        <ListContainer spacing={0.5} length={data?.length} noDataLabel="Aucune transaction">
            {data.map((transaction, id) => (
                <Box key={`TR${transaction.id}`} bgcolor={greenColor["015"]} borderRadius={2} p={0.25}>
                    <Grid container spacing={0.5}>
                        <Grid item xs={1.5}>
                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{transaction.libelleMouvement}</TextContainer>
                        </Grid>
                        <Grid item xs={1.5}>
                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{moment([transaction.anneeMouvement, transaction.moisMouvement - 1, transaction.jourMouvement]).format("DD/MM/YYYY")}</TextContainer>
                        </Grid>
                        <Grid item xs={1.5}>
                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{moment(transaction.heureMouvement, "H").format("HH")}h{moment(transaction.minuteMouvement, "m").format("mm")}</TextContainer>
                        </Grid>
                        <Grid item xs={1.5}>
                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{moment(transaction.heureMouvementAjustee, "H").format("HH")}h{moment(transaction.minuteMouvementAjustee, "m").format("mm")}</TextContainer>
                        </Grid>
                        <Grid item xs={1}>
                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{transaction.lecteurPresence}</TextContainer>
                        </Grid>
                        <Grid item xs={1.75}>
                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{transaction.libelleActivite}</TextContainer>
                        </Grid> 
                        <Grid item xs={2}>
                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{transaction.libelleTypeTravail}</TextContainer>
                        </Grid>
                        <Grid item xs={1.25} textAlign="center" display="flex" alignItems="center" justifyContent="center">
                            {transaction.anomalies.length > 0 && 
                                <IconButton size="small" onClick={() => openAnomalies(transaction)}>
                                    <Info fontSize="small" />
                                </IconButton>
                            }
                        </Grid>
                    </Grid>
                </Box>
            ))}
        </ListContainer>
    );
}

TransactionsList.defaultProps = {
    data: [],
    isLoading: true,
    isError: false,
    errorMessage: "",
    openAnomalies: () => {}
}

TransactionsList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
    openAnomalies: PropTypes.func
}

const styles = {
    loaderStyle: {
        backgroundColor: greenColor["015"],
        borderRadius: 2,
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        p: 1,
    }
}

export default TransactionsList;