import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { HeaderDialogContainer } from "@components/Containers";
import { useTranslation } from "react-i18next";

const ActionsContrainte = ({
    isLoading = true, 
    close = () => {},
    postDemandeAbsenceContrainte = () => {}
}) => {
    const { t } = useTranslation("global");

    return (
        <HeaderDialogContainer  
            actionsLeft={
                <IconButtonBase 
                    variant="white5Grey9White9"
                    icon={<Check fontSize="small" />} 
                    title={t("buttons.validation_despite_constraint")} 
                    disabled={isLoading} 
                    action={() => postDemandeAbsenceContrainte()} 
                />
            }
            title={t("titles.validation_absence_long")}
            close={() => close()} 
            variant="error"
        />
    )
}

ActionsContrainte.propTypes = {
    isLoading: PropTypes.bool,
    close: PropTypes.func,
    postDemandeAbsenceContrainte: PropTypes.func
}

export default ActionsContrainte;