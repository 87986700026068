import { useSelector } from "react-redux";
import { selectPlaGesTem, selectTypTraApp } from "@selectors";

export const usePlanGesTemDay = (idJour) => {
    const statePlaGesTem = useSelector(selectPlaGesTem);
    const stateTypesTravail = useSelector(selectTypTraApp);
    let horaire;
    let horaireInitial;
    let absence;
    let totalHeuresAbsence;
    let totalHeures;
    let authorized;
    let authorizedTypeTravail;
    let typesTravails;
    let plages;

    switch (idJour) {
        case 0:
            horaire = statePlaGesTem.horaireLundi;
            horaireInitial = statePlaGesTem.horaireInitialLundi;
            absence = statePlaGesTem.absenceLundi;
            totalHeuresAbsence = statePlaGesTem.totalHeuresAbsenceLundi;
            totalHeures = statePlaGesTem.totalHeuresLundi;
            authorized = statePlaGesTem.authorizationLundi === "0";
            authorizedTypeTravail = statePlaGesTem.authorizationTypeTravailLundi === "O";
            typesTravails = {
                plage1: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.lundi.typeTravail1)?.designation, 
                plage2: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.lundi.typeTravail2)?.designation, 
                plage3: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.lundi.typeTravail3)?.designation, 
                plage4: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.lundi.typeTravail4)?.designation
            };
            plages = {
                debut1: statePlaGesTem.lundi.debut1,
                arret1: statePlaGesTem.lundi.arret1, 
                debut2: statePlaGesTem.lundi.debut2, 
                arret2: statePlaGesTem.lundi.arret2,
                debut3: statePlaGesTem.lundi.debut3, 
                arret3: statePlaGesTem.lundi.arret3,
                debut4: statePlaGesTem.lundi.debut4, 
                arret4: statePlaGesTem.lundi.arret4
            };
            break;
        case 1:
            horaire = statePlaGesTem.horaireMardi;
            horaireInitial = statePlaGesTem.horaireInitialMardi;
            absence = statePlaGesTem.absenceMardi;
            totalHeuresAbsence = statePlaGesTem.totalHeuresAbsenceMardi;
            totalHeures = statePlaGesTem.totalHeuresMardi;
            authorized = statePlaGesTem.authorizationMardi === "0";
            authorizedTypeTravail = statePlaGesTem.authorizationTypeTravailMardi === "O";
            typesTravails = {
                plage1: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.mardi.typeTravail1)?.libelle, 
                plage2: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.mardi.typeTravail2)?.libelle, 
                plage3: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.mardi.typeTravail3)?.libelle, 
                plage4: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.mardi.typeTravail4)?.libelle
            };
            plages = {
                debut1: statePlaGesTem.mardi.debut1,
                arret1: statePlaGesTem.mardi.arret1, 
                debut2: statePlaGesTem.mardi.debut2, 
                arret2: statePlaGesTem.mardi.arret2,
                debut3: statePlaGesTem.mardi.debut3, 
                arret3: statePlaGesTem.mardi.arret3,
                debut4: statePlaGesTem.mardi.debut4, 
                arret4: statePlaGesTem.mardi.arret4
            };
            break;
        case 2:
            horaire = statePlaGesTem.horaireMercredi;
            horaireInitial = statePlaGesTem.horaireInitialMercredi;
            absence = statePlaGesTem.absenceMercredi;
            totalHeuresAbsence = statePlaGesTem.totalHeuresAbsenceMercredi;
            totalHeures = statePlaGesTem.totalHeuresMercredi;
            authorized = statePlaGesTem.authorizationMercredi === "0";
            authorizedTypeTravail = statePlaGesTem.authorizationTypeTravailMercredi === "O";
            typesTravails = {
                plage1: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.mercredi.typeTravail1)?.designation, 
                plage2: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.mercredi.typeTravail2)?.designation, 
                plage3: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.mercredi.typeTravail3)?.designation, 
                plage4: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.mercredi.typeTravail4)?.designation
            };
            plages = {
                debut1: statePlaGesTem.mercredi.debut1,
                arret1: statePlaGesTem.mercredi.arret1, 
                debut2: statePlaGesTem.mercredi.debut2, 
                arret2: statePlaGesTem.mercredi.arret2,
                debut3: statePlaGesTem.mercredi.debut3, 
                arret3: statePlaGesTem.mercredi.arret3,
                debut4: statePlaGesTem.mercredi.debut4, 
                arret4: statePlaGesTem.mercredi.arret4
            };
            break;
        case 3:
            horaire = statePlaGesTem.horaireJeudi;
            horaireInitial = statePlaGesTem.horaireInitialJeudi;
            absence = statePlaGesTem.absenceJeudi;
            totalHeuresAbsence = statePlaGesTem.totalHeuresAbsenceJeudi;
            totalHeures = statePlaGesTem.totalHeuresJeudi;
            authorized = statePlaGesTem.authorizationJeudi === "0";
            authorizedTypeTravail = statePlaGesTem.authorizationTypeTravailJeudi === "O";
            typesTravails = {
                plage1: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.jeudi.typeTravail1)?.designation, 
                plage2: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.jeudi.typeTravail2)?.designation, 
                plage3: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.jeudi.typeTravail3)?.designation, 
                plage4: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.jeudi.typeTravail4)?.designation
            };
            plages = {
                debut1: statePlaGesTem.jeudi.debut1,
                arret1: statePlaGesTem.jeudi.arret1, 
                debut2: statePlaGesTem.jeudi.debut2, 
                arret2: statePlaGesTem.jeudi.arret2,
                debut3: statePlaGesTem.jeudi.debut3, 
                arret3: statePlaGesTem.jeudi.arret3,
                debut4: statePlaGesTem.jeudi.debut4, 
                arret4: statePlaGesTem.jeudi.arret4
            };
            break;
        case 4:
            horaire = statePlaGesTem.horaireVendredi;
            horaireInitial = statePlaGesTem.horaireInitialVendredi;
            absence = statePlaGesTem.absenceVendredi;
            totalHeuresAbsence = statePlaGesTem.totalHeuresAbsenceVendredi;
            totalHeures = statePlaGesTem.totalHeuresVendredi;
            authorized = statePlaGesTem.authorizationVendredi === "0";
            authorizedTypeTravail = statePlaGesTem.authorizationTypeTravailVendredi === "O";
            typesTravails = {
                plage1: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.vendredi.typeTravail1)?.designation, 
                plage2: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.vendredi.typeTravail2)?.designation, 
                plage3: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.vendredi.typeTravail3)?.designation, 
                plage4: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.vendredi.typeTravail4)?.designation
            };
            plages = {
                debut1: statePlaGesTem.vendredi.debut1,
                arret1: statePlaGesTem.vendredi.arret1, 
                debut2: statePlaGesTem.vendredi.debut2, 
                arret2: statePlaGesTem.vendredi.arret2,
                debut3: statePlaGesTem.vendredi.debut3, 
                arret3: statePlaGesTem.vendredi.arret3,
                debut4: statePlaGesTem.vendredi.debut4, 
                arret4: statePlaGesTem.vendredi.arret4
            };
            break;
        case 5:
            horaire = statePlaGesTem.horaireSamedi;
            horaireInitial = statePlaGesTem.horaireInitialSamedi;
            absence = statePlaGesTem.absenceSamedi;
            totalHeuresAbsence = statePlaGesTem.totalHeuresAbsenceSamedi;
            totalHeures = statePlaGesTem.totalHeuresSamedi;
            authorized = statePlaGesTem.authorizationSamedi === "0";
            authorizedTypeTravail = statePlaGesTem.authorizationTypeTravailSamedi === "O";
            typesTravails = {
                plage1: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.samedi.typeTravail1)?.designation, 
                plage2: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.samedi.typeTravail2)?.designation, 
                plage3: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.samedi.typeTravail3)?.designation, 
                plage4: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.samedi.typeTravail4)?.designation
            };
            plages = {
                debut1: statePlaGesTem.samedi.debut1,
                arret1: statePlaGesTem.samedi.arret1, 
                debut2: statePlaGesTem.samedi.debut2, 
                arret2: statePlaGesTem.samedi.arret2,
                debut3: statePlaGesTem.samedi.debut3, 
                arret3: statePlaGesTem.samedi.arret3,
                debut4: statePlaGesTem.samedi.debut4, 
                arret4: statePlaGesTem.samedi.arret4
            };
            break;
        case 6:
            horaire = statePlaGesTem.horaireDimanche;
            horaireInitial = statePlaGesTem.horaireInitialDimanche;
            absence = statePlaGesTem.absenceDimanche;
            totalHeuresAbsence = statePlaGesTem.totalHeuresAbsenceDimanche;
            totalHeures = statePlaGesTem.totalHeuresDimanche;
            authorized = statePlaGesTem.authorizationDimanche === "0";
            authorizedTypeTravail = statePlaGesTem.authorizationTypeTravailDimanche === "O";
            typesTravails = {
                plage1: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.dimanche.typeTravail1)?.designation, 
                plage2: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.dimanche.typeTravail2)?.designation, 
                plage3: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.dimanche.typeTravail3)?.designation, 
                plage4: stateTypesTravail.typesTravail.find((typeTravail) => typeTravail.codeArgument === statePlaGesTem.dimanche.typeTravail4)?.designation
            };
            plages = {
                debut1: statePlaGesTem.dimanche.debut1,
                arret1: statePlaGesTem.dimanche.arret1, 
                debut2: statePlaGesTem.dimanche.debut2, 
                arret2: statePlaGesTem.dimanche.arret2,
                debut3: statePlaGesTem.dimanche.debut3, 
                arret3: statePlaGesTem.dimanche.arret3,
                debut4: statePlaGesTem.dimanche.debut4, 
                arret4: statePlaGesTem.dimanche.arret4
            };
            break;
        default:
            horaire = "";
            horaireInitial = "";
            absence = "";
            totalHeuresAbsence = "";
            totalHeures = "";
            authorized = false;
            authorizedTypeTravail = false;
            typesTravails = {plage1: "", plage2: "", plage3: "", plage4: ""};
            plages = {
                debut1: "",
                arret1: "", 
                debut2: "", 
                arret2: "",
                debut3: "", 
                arret3: "",
                debut4: "", 
                arret4: ""
            }
    }

    const colorHoraire = (horaire === horaireInitial || !statePlaGesTem.modificationAffectationsHoraires) ? undefined : "red"

    return { colorHoraire, horaire, absence, totalHeuresAbsence, totalHeures, authorized, authorizedTypeTravail, typesTravails, plages }
}

export const usePlanGesTemMdl = (idJour) => {
    const statePlaGesTem = useSelector(selectPlaGesTem);
    let societe;
    let date;
    
    switch (idJour) {
        case 0:
            societe = statePlaGesTem.societeLundi;
            date = statePlaGesTem.dateLundi;
            break;
        case 1:
            societe = statePlaGesTem.societeMardi;
            date = statePlaGesTem.dateMardi;
            break;
        case 2:
            societe = statePlaGesTem.societeMercredi;
            date = statePlaGesTem.dateMercredi;
            break;
        case 3:
            societe = statePlaGesTem.societeJeudi;
            date = statePlaGesTem.dateJeudi;
            break;
        case 4:
            societe = statePlaGesTem.societeVendredi;
            date = statePlaGesTem.dateVendredi;
            break;
        case 5:
            societe = statePlaGesTem.societeSamedi;
            date = statePlaGesTem.dateSamedi;
            break;
        case 6:
            societe = statePlaGesTem.societeDimanche;
            date = statePlaGesTem.dateDimanche;
            break;
        default:
            societe = "";
            date = "";
    }

    return { societe, date }
}
