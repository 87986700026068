import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Close, Restaurant } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { selectDsh } from "@selectors";
import { getWidgetRemoved } from "@utils";
import * as dshActions from "@reducers/dashboard/dshReducer"
import { IconButtonBase } from "@components/Buttons";
import { greenColor, redColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const PlagesHoraireWidgetConfigurationDelete = () => {
    const stateDashboard = useSelector(selectDsh);
    const dispatch = useDispatch();
    const { t } = useTranslation("global");

    const remove = useCallback(() => {
        const updateState = getWidgetRemoved("wPlagesHoraire", stateDashboard.widgetsActives, stateDashboard.widgetsNonActives, stateDashboard.widgetsConfiguration);
        dispatch(dshActions.editWidget(updateState));
    }, [dispatch, stateDashboard.widgetsActives, stateDashboard.widgetsConfiguration, stateDashboard.widgetsNonActives]);

    return (
        <Stack spacing={0.5} padding={0.5} borderRadius={2} bgcolor={whiteColor[5]} height="200px" boxSizing="border-box" position="relative">
            <Box bgcolor={greenColor["05"]} borderRadius={2} p={1} textAlign="center">
                <Typography fontSize="small" fontWeight="bold" color="white">Plages Horaire</Typography>
            </Box>
            <Stack direction="row" px={1} py={0.5} bgcolor={greenColor["05"]} borderRadius={2}>
                <Box flexGrow={1}>
                    <Typography fontSize="small" fontWeight="bold" color="white">{t("texts.date_week")}</Typography>
                </Box>
                <Box>
                    <Typography fontSize="small" fontWeight="bold" color="white">{t("texts.public_holiday")}</Typography>
                </Box>
            </Stack>
            <Box p={1.75} bgcolor={greenColor["05"]} borderRadius={2} height="100%">
                <Stack spacing={0.5}>
                    <Box paddingX={1} paddingTop={1}>
                        <Box width="100%" bgcolor={whiteColor[9]} height={5} borderRadius={6} justifyContent={"center"} display="flex">
                            <Box width={"75%"} bgcolor={redColor[7]} height={5} borderRadius={6}></Box>
                        </Box>
                    </Box>
                    <Stack direction="row">
                        <Box width={"15%"} justifyContent="left" display="flex">
                            <Typography fontSize="11px" color="white">8h30</Typography>
                        </Box>
                        <Stack direction="row" width={"70%"}>
                            <Box width="50%" justifyContent="left"  display="flex">
                                <Typography fontSize="11px" color="white">9h00</Typography>
                            </Box>
                            <Box width="50%" justifyContent="right"  display="flex">
                                <Typography fontSize="11px" color="white">12h00</Typography>
                            </Box>
                        </Stack>
                        <Box width={"15%"} justifyContent="right" display="flex">
                            <Typography fontSize="11px" color="white">12h30</Typography>
                        </Box>
                    </Stack>
                </Stack>
                <Box display={"inline-flex"} alignItems="center" justifyContent="center" width="100%">
                    <Restaurant fontSize="small" sx={{ color: "white" }} />
                    <Typography fontSize={"11px"} paddingX={1} color="white">1h00</Typography> 
                </Box>
                <Stack spacing={0.5}>
                    <Box paddingX={1} paddingTop={1}>
                        <Box width="100%" bgcolor={whiteColor[9]} height={5} borderRadius={6} justifyContent={"center"} display="flex">
                            <Box width={"75%"} bgcolor={redColor[7]} height={5} borderRadius={6}></Box>
                        </Box>
                    </Box>
                    <Stack direction="row">
                        <Box width={"15%"} justifyContent="left" display="flex">
                            <Typography fontSize="11px" color="white">13h00</Typography>
                        </Box>
                        <Stack direction="row" width={"70%"}>
                            <Box width="50%" justifyContent="left"  display="flex">
                                <Typography fontSize="11px" color="white">13h30</Typography>
                            </Box>
                            <Box width="50%" justifyContent="right"  display="flex">
                                <Typography fontSize="11px" color="white">18h00</Typography>
                            </Box>
                        </Stack>
                        <Box width={"15%"} justifyContent="right" display="flex">
                            <Typography fontSize="11px" color="white">18h45</Typography>
                        </Box>
                    </Stack>
                </Stack>
            </Box>      
            <Box position="absolute" right={6} top={3}>
                <IconButtonBase 
                    variant="deleteListButton"
                    icon={<Close fontSize="small" />} 
                    title={t("buttons.delete")} 
                    action={remove} 
                />
            </Box>
        </Stack>
    );
}

export default PlagesHoraireWidgetConfigurationDelete;