import { useCallback } from "react";
import { Stack, Switch, Typography } from "@mui/material";
import { useController, useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import { greenColor, greyColor } from "@styles";

const SwitchHookFormInput = (props) => {
    const { name, labelLeft, labelRight } = props;
    const { control } = useFormContext();
    const { field } = useController({ name, control, defaultValue: false });

    const handleSwitch = useCallback((event) => {
        field.onChange(event.target.checked)
    }, [field]);

    return (
        <Stack sx={styles.stackInputSwitchStyle} direction="row">
            {labelLeft && <Typography fontSize={"small"}>{labelLeft}</Typography>}
            <Switch sx={styles.inputSwitchStyle} {...field} onChange={handleSwitch} checked={field.value} />
            {labelRight && <Typography fontSize={"small"}>{labelRight}</Typography>}
        </Stack> 
    );
}

SwitchHookFormInput.propTypes = {
    labelLeft: "",
    labelRight: ""
}

SwitchHookFormInput.propTypes = {
    name: PropTypes.string.isRequired,
    labelLeft: PropTypes.string,
    labelRight: PropTypes.string
}

const styles = {
    stackInputSwitchStyle: {
        width: "100%", 
        minHeight: 40, 
        alignItems: "center", 
        justifyContent: "center", 
        backgroundColor: greenColor["01"], 
        borderRadius: 2
    },
    inputSwitchStyle: {
        padding: 1,
        "& .MuiSwitch-track": {
            borderRadius: 11,
            backgroundColor: greyColor[6]
        },
        "& .MuiSwitch-thumb": {
            boxShadow: "none",
        }
    },
}

export default SwitchHookFormInput;