import { Box, Grid, Grow, Stack, Typography } from "@mui/material";
import { Close, Edit, Info, Refresh } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";
import { HeaderListContainer, ItemContainer, ListContainer, PanelListContainer, TextWithLabelContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { Loader } from "@components/Loaders";
import { useMedia } from "react-use";

const headerItems = [
    {id: 1, label: "Nature", xs: 3, display: true, firstData: true, lastData: false},
    {id: 2, label: "Du", xs: 2, display: true, firstData: false, lastData: false},
    {id: 3, label: "Au", xs: 2, display: true, firstData: false, lastData: false},
    {id: 4, label: "Délégataire", xs: 3, display: true, firstData: false, lastData: true}
];

const DelegationsList = ({ 
    data = [], 
    isLoading = true, 
    isError = false,
    errorMessage = "",
    listed = false, 
    refetch = () => {},
    openDetail = () => {}, 
    openEdit = () => {}, 
    openWarning  = () => {}
}) => {
    const isMobile = useMedia('(max-width: 650px)');

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return (
            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center"> 
                <Stack direction="row" spacing={2} bgcolor={redColor[5]} py={1} px={2} borderRadius={2} alignItems="center">
                    <Typography fontSize="small" fontWeight="bold" color={whiteColor[9]}>
                        {errorMessage}
                    </Typography>
                    <IconButtonBase 
                        variant="white5Grey9White9"
                        icon={<Refresh fontSize="small" />}
                        title="Rafraichir"
                        action={() => refetch()}
                    />
                </Stack>
            </Box>
        );
    }

    if(data?.length === 0) {
        return (
            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center"> 
                <Stack direction="row" spacing={2} borderRadius={2} bgcolor={greenColor["07"]} py={1} px={2} alignItems="center"> 
                    <Typography fontSize="small" fontWeight="bold" color={whiteColor[9]}>
                        Aucune donnée
                    </Typography>
                </Stack>
            </Box>
        )
    }

    return (
        (listed && !isMobile) ?
            <Stack spacing={0.5}>
                <HeaderListContainer itemIdReference="HDELEG" headerItems={headerItems} />
                <PerfectScrollbar>
                    <Box maxHeight="calc(100vh - 238px)">
                        <ListContainer spacing={0.5} length={data.length}>
                            {data.map((delegation, id) => (
                                <Grow key={`DELEG${delegation.id}`} in={true} timeout={400+(200*id)}>
                                    <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.3}>
                                        <Grid container spacing={0.3}>
                                            <ItemContainer firstData data={delegation.libelleNature} xs={3} />
                                            <ItemContainer data={moment(delegation.dateDebut, "DDMMYYYY").format("DD/MM/YYYY")} xs={2} />
                                            <ItemContainer data={delegation.dateReprise !== "00000000" ? moment(delegation.dateReprise, "DDMMYYYY").format("DD/MM/YYYY") : "Indeterminé"} xs={2} />
                                            <ItemContainer lastData data={delegation.delegataireLong} xs={3} />
                                            <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                                                <Stack direction="row" spacing={0.5}>
                                                    <IconButtonBase 
                                                        variant="listButton" 
                                                        icon={<Info fontSize="small" />} 
                                                        title="Afficher" 
                                                        action={() => openDetail(delegation)} 
                                                    />
                                                    <IconButtonBase 
                                                        variant="listButton" 
                                                        icon={<Edit fontSize="small" />} 
                                                        title="Modifier" 
                                                        action={() => openEdit(delegation)} 
                                                    />
                                                    <IconButtonBase 
                                                        variant="deleteListButton" 
                                                        icon={<Close fontSize="small" />} 
                                                        title="Supprimer" 
                                                        action={() => openWarning(delegation)} 
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grow>
                            ))}
                        </ListContainer>
                    </Box>
                </PerfectScrollbar>
            </Stack>
        :
            <PerfectScrollbar>
                <PanelListContainer length={data.length}>
                    {data.map((delegation, id) => (
                        <Grow key={`DELEGPANEL${delegation.id}`} in={true} timeout={400+(200*id)}>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                <Stack spacing={0.5} bgcolor={greenColor["05"]} padding={0.5} borderRadius={2}>
                                    <Box borderRadius={2} p={1} bgcolor={whiteColor[7]}>
                                        <Stack spacing={0}>
                                            <TextWithLabelContainer label="Nature" value={delegation.libelleNature} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label={delegation.dateReprise === "00000000" ? "À partir du" : "Du"} value={moment(delegation.dateDebut, "DDMMYYYY").format("DD/MM/YYYY")} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                            {delegation.dateReprise !== "00000000" &&
                                                <TextWithLabelContainer label="Au" value={moment(delegation.dateReprise, "DDMMYYYY").format("DD/MM/YYYY")} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                            }
                                            <TextWithLabelContainer label="Délégataire" value={delegation.delegataireLong} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                        </Stack>
                                    </Box>
                                    <Box borderRadius={2} height={35.5} display="flex" alignItems="center" justifyContent="center" bgcolor={whiteColor[7]}>
                                        <IconButtonBase 
                                            variant="panelButton"
                                            icon={<Info fontSize="small" />} 
                                            title="Afficher" 
                                            action={() => openDetail(delegation)} 
                                        />
                                        <IconButtonBase 
                                            variant="panelButton"
                                            icon={<Edit fontSize="small" />} 
                                            title="Modifier" 
                                            action={() => openEdit(delegation)} 
                                        />
                                        <IconButtonBase 
                                            variant="deletePanelButton"
                                            icon={<Close fontSize="small" />} 
                                            title="Supprimer" 
                                            action={() => openWarning(delegation)} 
                                        />                               
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grow>
                    ))}
                </PanelListContainer>
            </PerfectScrollbar>
    );
}

DelegationsList.propTypes = {
    data: PropTypes.array, 
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
    listed: PropTypes.bool, 
    refetch: PropTypes.func, 
    openDetail: PropTypes.func, 
    openEdit: PropTypes.func, 
    openWarning: PropTypes.func 
}

const styles = {
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        height: "100%",
    }
}

export default DelegationsList;