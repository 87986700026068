import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useFetchAnoGesTem } from "@hooks/anomalies/hooksQueries";
import { DialogContainer, HeaderDialogContainer, HeaderListContainer } from "@components/Containers";
import { AnomaliesList } from "@components/GestionTemps/Temps/Lists";

const headers = [
    {id: 1, label: "Code", xs: 2, display: true, firstData: true, lastData: false},
    {id: 2, label: "Libellé", xs: 8, display: true, firstData: false, lastData: false},
    {id: 3, label: "Gravité", xs: 2, display: true, firstData: false, lastData: true}
];

const AnomaliesDialog = ({
    open = false, 
    setOpen = () => {}
}) => {
    const { data, isLoading, isFetching, isError, error } = useFetchAnoGesTem(open);

    return (
        <DialogContainer open={open} placement="top" taille="sm">
            <HeaderDialogContainer
                title="Liste complète des anomalies"
                close={() => setOpen(false)}
            />
            <Stack spacing={0.5} maxHeight={"calc(100vh - 138px)"}>
                <HeaderListContainer itemIdReference="ANOS" headerItems={headers} />
                <AnomaliesList
                    data={data}
                    isLoading={isLoading || isFetching}
                    isError={isError}
                    errorMessage={error?.message}
                />
            </Stack>
        </DialogContainer>
    );
}

AnomaliesDialog.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func
}

export default AnomaliesDialog;