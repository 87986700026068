import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const TextNombreJoursAbsenceContainer = (props) => {
    const { nombreJours, demiJournee } = props;
    const libelleDemiJournee = demiJournee === "PM" ? ", après-midi" : ", matin";
    const { t } = useTranslation("global");

    return (
        Number(nombreJours) > 0 &&
            <Typography fontSize="small">{t("texts.number_days")} : {Number(nombreJours).toLocaleString("fr-FR")}{demiJournee && libelleDemiJournee}</Typography>
    );
}

TextNombreJoursAbsenceContainer.defaultProps = {
    nombreJours: 0,
    demiJournee: false
}

TextNombreJoursAbsenceContainer.propTypes = {
    nombreJours: PropTypes.number,
    demiJournee: PropTypes.bool
}

export default TextNombreJoursAbsenceContainer;