import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { greenColor, greyColor, whiteColor } from "@styles";
import { ChampDate, ChampQuestion, ChampsComplementaires } from "./Champs";
import { selectEntMan, selectEntSecMan } from "@selectors";
import { useComQueEntMan, usePostQuestionnaire } from "@hooks/gestionEntretiens/hooksQueries";
import { Loader } from "@components/Loaders";
import { zodResolver } from "@hookform/resolvers/zod";
import PerfectScrollbar from "react-perfect-scrollbar";
import { schemaQuestionnaire } from "@components/GestionEntretiens/validations";
import { IconButtonBase } from "@components/Buttons";
import { ArrowLeft, Check } from "@mui/icons-material";
import { useChampDate, useTablesEntretiensEvaluations } from "@hooks/gestionEntretiens/hooksUtils";
import moment from "moment";

const defaultValues = {
    complement1: "",
    complement2: "",
    complement3: "",
    complement4: "",
    date: null,
    reponseQuestion1: "",
    reponseQuestion2: "",
    reponseQuestion3: "",
    reponseQuestion4: "",
    reponseQuestion5: "",
    reponseQuestion6: "",
    commentaire1: "",
    commentaire2: "", 
    commentaire3: "", 
    commentaire4: "", 
    commentaire5: "", 
    commentaire6: "",
    displayQuestion1: false,
    displayQuestion2: false,
    displayQuestion3: false,
    displayQuestion4: false,
    displayQuestion5: false,
    displayQuestion6: false
}

const QuestionnaireForm = ({ 
    open = false, 
    goBack = () => {} 
}) => {
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;
    const methods = useForm({ mode: "onSubmit", defaultValues: defaultValues, resolver: zodResolver(schemaQuestionnaire) })
    const { data, isLoading, isFetching, isError } = useComQueEntMan(open);
    const { isLoadingQuestionnaire, postQuestionnaire } = usePostQuestionnaire(); 
    const options = useTablesEntretiensEvaluations();
    const { displayE: displayED } = useChampDate();  

    useEffect(() => {
        for(let i = 1; i <= 6; i++) {
            const questionKey = `question${i}`;
            const reponseQuestionKey = `reponseQuestion${i}`;
            const commentaireKey = `commentaire${i}`;
            const displayKey = `displayQuestion${i}`;
            if(sectionSelected?.[questionKey]) {
                if(sectionSelected?.[reponseQuestionKey]) {
                    const ligneCible1 = options?.find((t) => t.value === sectionSelected?.[reponseQuestionKey]);
                    const reponseQuestion1 = { label: ligneCible1?.label, value: sectionSelected?.[reponseQuestionKey], classement: ligneCible1?.classement }
                    methods.setValue(reponseQuestionKey, JSON.stringify(reponseQuestion1));
                } else {
                    methods.resetField(reponseQuestionKey);
                }
                if(data?.commentaireManager?.[commentaireKey]) {
                    methods.setValue(commentaireKey, data?.commentaireManager?.[commentaireKey]);
                } else {
                    methods.resetField(commentaireKey);
                }
                methods.setValue(displayKey, true);
            } else {
                methods.resetField(reponseQuestionKey);
                methods.resetField(commentaireKey);
                methods.setValue(displayKey, false);
            }
        }
        const champComplementaire1 = sectionSelected?.["contenuComplement1"] || "";
        const champComplementaire2 = sectionSelected?.["contenuComplement2"] || "";
        const champComplementaire3 = sectionSelected?.["contenuComplement3"] || "";
        const champComplementaire4 = sectionSelected?.["contenuComplement4"] || "";
        methods.setValue("complement1", champComplementaire1);
        methods.setValue("complement2", champComplementaire2);
        methods.setValue("complement3", champComplementaire3);
        methods.setValue("complement4", champComplementaire4);
        if(displayED) {
            const date = sectionSelected?.date === "0" ? moment([stateEntretiens?.entretienSelected?.annee, stateEntretiens?.entretienSelected?.mois - 1, stateEntretiens?.entretienSelected?.jour]) : moment(sectionSelected?.date, "DDMMYYYY");
            methods.setValue("date", date);
            methods.setValue("displayDate", true);
        } else {
            methods.resetField("date");
            methods.setValue("displayDate", false);
        }
    }, [data?.commentaireManager, displayED, methods, options, sectionSelected, stateEntretiens?.entretienSelected?.annee, stateEntretiens?.entretienSelected?.jour, stateEntretiens?.entretienSelected?.mois]);

    const validate = useCallback(async (dataForm) => {
        await postQuestionnaire(dataForm);
        goBack();
    }, [postQuestionnaire, goBack]);

    if(isLoadingQuestionnaire) {
        return (
            <>
                <Box borderRadius={2} bgcolor={greenColor["015"]} height={50} p={0.5}>
                    <Skeleton variant="rounded" animation="wave" width="100%" height="100%" sx={{borderRadius: 2}} />
                </Box>
                <Box justifyContent="center" display="flex" alignItems="center" height="calc(100vh - 140px)" p={2}>
                    <Box bgcolor={whiteColor[5]} borderRadius={2} width={50} height={50} justifyContent="center" display="flex" alignItems="center">
                        <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                    </Box>
                </Box>
            </>
        );
    }

    return (
        <FormProvider {...methods}>
            <Box borderRadius={2} bgcolor={greenColor["015"]} height={50} p={0.5}>
                <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[5]} height="100%" alignItems="center" px={1}>
                    <IconButtonBase icon={<ArrowLeft fontSize="small" />} title="Retour" action={() => goBack()} />
                    <Box flexGrow={1}>
                        <Typography fontSize="small" fontWeight="bold">{sectionSelected?.designationSection1}</Typography>
                        <Typography fontSize="small" fontWeight="bold">{sectionSelected?.designationSection2}</Typography>
                    </Box>
                    {stateEntretiens?.entretienSelected?.statut === "P" && <IconButtonBase icon={<Check fontSize="small" />} title="Valider" action={() => methods.handleSubmit(validate)()} />}
                </Stack>
            </Box>
            <PerfectScrollbar> 
                <Stack spacing={0.5} bgcolor={greenColor["015"]} borderRadius={2} p={0.5}>
                    <Box borderRadius={2} bgcolor={whiteColor[5]} p={1}>
                        <Typography fontSize={"small"} fontWeight={"bold"}>{sectionSelected?.designationSousSection1}</Typography>
                        <Typography fontSize={"small"} fontWeight={"bold"}>{sectionSelected?.designationSousSection2}</Typography>
                    </Box>
                    <ChampsComplementaires />
                    <ChampDate /> 
                    <Box bgcolor={whiteColor[5]} borderRadius={2} p={1} flexGrow={1}>
                        <Typography fontSize="small" fontWeight="bold">{sectionSelected?.questionDesignation}</Typography>
                    </Box>
                    <ChampQuestion idQuestion={1} commentaires={data} isLoading={isLoading || isFetching} isError={isError} />
                    <ChampQuestion idQuestion={2} commentaires={data} isLoading={isLoading || isFetching} isError={isError} />
                    <ChampQuestion idQuestion={3} commentaires={data} isLoading={isLoading || isFetching} isError={isError} />
                    <ChampQuestion idQuestion={4} commentaires={data} isLoading={isLoading || isFetching} isError={isError} />
                    <ChampQuestion idQuestion={5} commentaires={data} isLoading={isLoading || isFetching} isError={isError} />
                    <ChampQuestion idQuestion={6} commentaires={data} isLoading={isLoading || isFetching} isError={isError} />
                </Stack>
            </PerfectScrollbar>
        </FormProvider>
    );
}

QuestionnaireForm.propTypes = {
    open: PropTypes.bool,
    goBack: PropTypes.func
}

export default QuestionnaireForm;