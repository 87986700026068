import { useCallback, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { DetailActionsBar } from "./ActionsBars";
import * as dabGesAbsManActions from "@reducers/gestionAbsences/demandes/dabGesAbsManReducer"
import { selectCltApp, selectDabGesAbsMan, selectUsrApp } from "@selectors";
import { useDabGesAbsDetMan, useDabGesAbsRep } from "@hooks/gestionAbsences/demandes/hooksQueries";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { WarningAmberRounded } from "@mui/icons-material";
import { greenColor, redColor, whiteColor, yellowColor } from "@styles";
import { Recapitulatif } from "./Recapitulatif";
import { Soldes } from "./Soldes";
import Planning from "./Planning";
import { SuiviList } from "./Lists";
import { TextContainer } from "@components/Containers";
import { ContrainteDialog, MotifRefusDialog } from "./Dialogs";

const colorStatut = {
    "Acceptée": greenColor["05"],
    "Non traitée": yellowColor[6],
    "En cours": yellowColor[6],
    "Refusée": redColor[5]
}

const DetailPage = () => {
    const [openMotifRefusManager, setOpenMotifRefusManager] = useState(false);
    const [openContrainteManager, setOpenContrainteManager] = useState(false);
    const [contrainte, setContrainte] = useState(null);
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const stateAbsencesManager = useSelector(selectDabGesAbsMan);    
    const { data, isLoading, isFetching, isError, error } = useDabGesAbsDetMan(stateAbsencesManager?.demandeAbsenceSelected?.matricule, stateAbsencesManager?.demandeAbsenceSelected?.date, stateAbsencesManager?.demandeAbsenceSelected?.heure, true);
    const { isLoading: isLoadingReponse, postReponseDemandeAbsenceActionner } = useDabGesAbsRep();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const infoRef = useRef(null);

    const sendReponse = useCallback(async (data) => {
        const dataFormated = {
            matriculeHierarchique: stateUser.matricule,
            matriculeDemandeur: stateAbsencesManager?.demandeAbsenceSelected?.matricule,
            date: stateAbsencesManager?.demandeAbsenceSelected?.date,
            heure: stateAbsencesManager?.demandeAbsenceSelected?.heure,
            reponse: data?.reponse,
            motif: data?.motif,
            validationContrainte: data?.contrainteValidee,
            client: stateClient.client
        };
        const { isSucceed, codeErreur, details, libelleContrainte } = await postReponseDemandeAbsenceActionner(dataFormated);
        if(isSucceed) {
            if(data?.reponse === "A") {
                setOpenContrainteManager(false);
                enqueueSnackbar("Demande acceptée avec succès.", { variant: "success" });
                dispatch(dabGesAbsManActions.goTo("list"));
            }
            if(data?.reponse === "R") {
                setOpenMotifRefusManager(false);
                enqueueSnackbar("Demande refusée avec succès.", { variant: "success" });
                dispatch(dabGesAbsManActions.goTo("list"));
            }
        } else {
            if(codeErreur === 6 && data?.reponse === "A") {
                setOpenContrainteManager(true);
                const contrainteMessage = {message: details, libelle: libelleContrainte};
                setContrainte(contrainteMessage)
            } else {
                enqueueSnackbar(details, { variant: "error" });
            }
        }
    }, [dispatch, enqueueSnackbar, postReponseDemandeAbsenceActionner, stateAbsencesManager?.demandeAbsenceSelected?.date, stateAbsencesManager?.demandeAbsenceSelected?.heure, stateAbsencesManager?.demandeAbsenceSelected?.matricule, stateClient.client, stateUser.matricule]);

    const goTo = useCallback((page) => {
        dispatch(dabGesAbsManActions.goTo(page))
    }, [dispatch])

    return (
        <>
            <DetailActionsBar 
                isLoading={isLoading || isFetching}
                isError={isError}
                demandeReponse={stateAbsencesManager?.demandeAbsenceSelected?.demandeReponse}
                goTo={goTo}
                sendReponse={sendReponse}
                setOpenMotifRefusManager={setOpenMotifRefusManager}
            />
            <Stack spacing={0.5} height="100%" overflow="auto">
                {stateAbsencesManager.demandeAbsenceSelected ?
                    <>
                        <Box>
                            <Grid container item spacing={1} maxHeight={"100%"}>
                                <Grid item xs={12} lg={6}>
                                    <Box bgcolor={colorStatut[stateAbsencesManager?.demandeAbsenceSelected?.statut]} borderRadius={2} p={0.5} ref={infoRef}> 
                                        <Recapitulatif 
                                            data={data}
                                            isLoading={isLoading || isFetching}
                                            isError={isError}
                                            errorMessage={error?.message}
                                            demandeAbsenceSelected={stateAbsencesManager.demandeAbsenceSelected}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} lg={6} >
                                    <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.5} height={infoRef?.current?.offsetHeight} overflow={"auto"} boxSizing={"border-box"}>
                                        <Stack spacing={0.5}>
                                            <TextContainer bgColor={whiteColor[7]} p={1} textWeight="bold" horizontalAlign="center">Suivi</TextContainer>
                                            <SuiviList 
                                                data={data?.suivi}
                                                isLoading={isLoading || isFetching}
                                                isError={isError}
                                                errorMessage={error?.message}  
                                            />
                                        </Stack>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box bgcolor={greenColor["07"]} borderRadius={2} p={0.5}>
                                        <Soldes />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box bgcolor={greenColor["07"]} borderRadius={2} p={0.5}>
                            <Planning />
                        </Box>
                    </>
                :
                    <Box sx={styles.boxErrorStyle} display="flex" justifyContent="center" alignItems="center">
                        <Stack spacing={1}>
                            <Box><WarningAmberRounded /></Box>
                            <Typography>Aucune demande selectionnée.</Typography>
                        </Stack>
                    </Box>
                }
            </Stack>
            <MotifRefusDialog 
                isLoading={isLoadingReponse}
                open={openMotifRefusManager} 
                setOpen={setOpenMotifRefusManager} 
                sendReponse={sendReponse}
            /> 
            <ContrainteDialog 
                isLoading={isLoadingReponse}
                open={openContrainteManager} 
                setOpen={setOpenContrainteManager} 
                contrainte={contrainte} 
                sendReponse={sendReponse}
            />
        </>
    )
}

const styles = {
    boxPanelStyle: {
        backgroundColor: greenColor["015"], 
        borderRadius: 2,
        p: 0.5,
    },
    boxErrorStyle: {
        backgroundColor: "rgba(255,0,0,0.1)", 
        textAlign: "center",
        p: 2, 
        height: "100%",
        borderRadius: 2
    }
}

export default DetailPage;