import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    listed: false,
    filtered: false,
    tried: false,
    affectations: [],
    affectationsDisplayed: [],
    affecationSelected: null
};

const affHorIndSlice = createSlice({
    name: 'affHorInd',
    initialState: initialState,
    reducers: {
        setAffectations: (state, action) => {
            state.affectations = action.payload;
            state.affectationsDisplayed = action.payload;
        },
        lister: (state) => {
            state.listed = !state.listed;
        },
        filtrer: (state, action) => {
            state.affectationsDisplayed = action.payload;
            state.filtered = true;
        },
        trier: (state, action) => {
            state.affectationsDisplayed = action.payload;
            state.tried = true;
        },
        deleteFiltre: (state) => {
            state.affectationsDisplayed = state.affectations;
            state.filtered = false
        },
        deleteTrie: (state) => {
            state.affectationsDisplayed = state.affectations;
            state.tried = false
        },
        selectAffectation: (state, action) => {
            state.affecationSelected = action.payload;
        },
        reset: () => initialState
    }
})

const { actions, reducer } = affHorIndSlice
export const { 
    setAffectations, 
    lister, 
    filtrer, 
    trier, 
    deleteFiltre, 
    deleteTrie, 
    selectAffectation, 
    reset 
} = actions
export default reducer
