import { Typography, Stack, Grow, Box } from '@mui/material'; 
import { greenColor, whiteColor } from '@styles';
import { useTranslation } from 'react-i18next';

const About = () => {
    const { t } = useTranslation("global");

    return (
        <Grow in={true} timeout={500}> 
            <Stack p={0.5} borderRadius={2} bgcolor={whiteColor[9]}>
                <Box p={1} bgcolor={greenColor["05"]} borderRadius={2}>
                    <Typography fontSize="medium" fontWeight="bold">{t("texts.about_1")}</Typography>
                    <Typography fontSize="medium" fontWeight="bold">{t("texts.about_2")}</Typography>
                    <Typography fontSize="medium" fontWeight="bold">{t("texts.about_3")}</Typography>
                </Box>
            </Stack>
        </Grow>
    )
}

export default About;