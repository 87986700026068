import { Tooltip } from "@mui/material";
import { useController, useFormContext, useFormState } from "react-hook-form";
import PropTypes from "prop-types";
import { redColor, TextFieldStyled } from "@styles";
import { useTranslation } from "react-i18next";

const TextInput = ({
    name = "", 
    label = "", 
    autoFocus = false, 
    maxLength = 50, 
    readOnly = false, 
    multiline = false,
    errorPlacement = "left",
    variant = "white"
}) => {
    const { control } = useFormContext();
    const { field } = useController({ name, control });
    const { errors } = useFormState({ control });
    const { t } = useTranslation("global");

    return (  
        <Tooltip 
            open={Boolean(errors?.[name])} 
            title={t(errors?.[name]?.message)} 
            slotProps={{
                tooltip: {
                    sx: {
                        backgroundColor: redColor[5]
                    }
                },
                arrow: {
                    sx: {
                        color: redColor[5]
                    }
                }
            }}
            placement={errorPlacement}
            arrow
        >
            <TextFieldStyled 
                fullWidth 
                label={label} 
                size="small"
                autoFocus={autoFocus} 
                multiline={multiline}
                inputProps={{ maxLength: maxLength, readOnly: readOnly }}
                variantApp={variant}
                {...field}
            />
        </Tooltip>
    );
}

TextInput.propTypes = {
    name: PropTypes.string.isRequired, 
    label: PropTypes.string, 
    autoFocus: PropTypes.bool, 
    maxLength: PropTypes.number, 
    readOnly: PropTypes.bool,
    multiline: PropTypes.bool,
    errorPlacement: PropTypes.string,
    variant: PropTypes.oneOf([
        "white",
        "grey",
        "green"
    ])
}

export default TextInput;