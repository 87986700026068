import { Box, Grid, Stack } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { PanelListContainer, TextWithLabelContainer } from "@components/Containers";
import { Loader } from "@components/Loaders";
import { ErrorBase } from "@components/Errors";
import { greenColor, greyColor, whiteColor } from "@styles";

const AnomaliesListMobile = (props) => {
    const { data, isLoading, isError, errorMessage } = props;

    if(isLoading) {
        return (
            <Box>
                <Loader size={17} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return <ErrorBase>{errorMessage}</ErrorBase>;
    }
    
    return (
        <PerfectScrollbar>
            <PanelListContainer length={data.length}>
                {data.map((anomalie) => (
                    <Grid key={`ANO${anomalie.code}`} item xs={12}>
                        <Stack spacing={0.5} bgcolor={greenColor["015"]} borderRadius={2} p={0.5}>                       
                            <Box bgcolor={whiteColor[5]} borderRadius={2} p={1}>
                                <Stack spacing={0}>
                                    <TextWithLabelContainer label="Code" value={anomalie.code} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                    <TextWithLabelContainer label="Libellé" value={anomalie.libelle} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                    <TextWithLabelContainer label="Gravité" value={anomalie.gravite} size={{label: 11, value: 11}} weight={{label: "bold", value: "normal"}} />
                                </Stack>
                            </Box>  
                        </Stack>
                    </Grid>
                ))}
            </PanelListContainer>
        </PerfectScrollbar>
    )
}  

AnomaliesListMobile.defaultProps = {
    data: [],
    isLoading: true,
    isError: false,
    errorMessage: ""
}

AnomaliesListMobile.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.string
}

export default AnomaliesListMobile;