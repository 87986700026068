import { useCallback, useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { Event } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { useStateTemGesTem } from "@hooks/gestionTemps/temps/hooksStates";
import { useHisTraTemGesTem } from "@hooks/gestionTemps/transactions/hooksQueries";
import { DialogContainer, HeaderDialogContainer, HeaderListContainer } from "@components/Containers";
import { TransactionsList } from "@components/App/Transactions";
import { DatePickerButton } from "@components/Buttons";

const headers = [
    {id: 1, label: "Mouvement", xs: 1.5, display: true},
    {id: 2, label: "Date", xs: 1.5, display: true},
    {id: 3, label: "Heure Réelle", xs: 1.5, display: true},
    {id: 4, label: "Heure Ajustée", xs: 1.5, display: true},
    {id: 5, label: "Lecteur", xs: 1, display: true},
    {id: 6, label: "Activité", xs: 1.75, display: true},
    {id: 7, label: "Type Travail", xs: 2, display: true},
    {id: 8, label: "Anomalie", xs: 1.25, display: true}
];

const HistoriqueTransactionsDialog = (props) => {
    const { open, setOpen, openAnomalies, userType } = props;
    const [date, setDate] = useState();
    const stateTemps = useStateTemGesTem(userType);
    const { data, isLoading, isFetching, isError, error } = useHisTraTemGesTem(userType, date, open);

    useEffect(() => {
        if(open) {
            setDate(stateTemps.dateTemps);
        }
    }, [open, stateTemps.dateTemps]);

    const selectDate =  useCallback((date) => {
        setDate(date.format("DDMMYYYY"));
    }, []);

    const close = useCallback(() => {
        setOpen(false);
        setDate("")
    }, [setOpen]);

    return (
        <DialogContainer open={open} placement="top" taille="lg">
            <HeaderDialogContainer 
                actionsLeft={
                    <DatePickerButton icon={<Event fontSize="small" />} title="Choisir une date" timeout={600} action={selectDate} value={moment(date, "DDMMYYYY")} />
                }
                title={`Historique des transactions à partir du ${moment(date, "DDMMYYYY").format("dddd DD MMMM YYYY")}`}
                close={() => close()}
            />
            <Stack spacing={0.5}>
                <HeaderListContainer itemIdReference="HISTOTRGT" headerItems={headers} />
                <Box overflow={"auto"} maxHeight={"calc(100vh - 194px)"}>
                    <TransactionsList 
                        data={data} 
                        isLoading={isLoading || isFetching} 
                        isError={isError} 
                        errorMessage={error?.message} 
                        openAnomalies={openAnomalies}
                    />
                </Box>
            </Stack>
        </DialogContainer>
    );
}

HistoriqueTransactionsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    openAnomalies: PropTypes.func.isRequired,
    userType: PropTypes.string.isRequired
}

export default HistoriqueTransactionsDialog;