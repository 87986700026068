import { Box, Grid, Stack, Typography } from "@mui/material";
import { ListAlt } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { DialogContainer, HeaderDialogContainer, HeaderListContainer, ItemContainer, ListContainer } from "@components/Containers";
import { redColor, whiteColor } from "@styles";
import { IconButtonBase } from "@components/Buttons";

const headerItems = [
    {id: 1, label: "Code", xs: 2, display: true, firstData: true, lastData: false},
    {id: 2, label: "Désignation", xs: 8, display: true, firstData: false, lastData: false},
    {id: 3, label: "Gravité", xs: 2, display: true, firstData: false, lastData: true}
];

const headerItemsRegularisation = [
    {id: 1, label: "Mouvement", xs: 2, display: true, firstData: true, lastData: false},
    {id: 2, label: "Date", xs: 2, display: true, firstData: false, lastData: false},
    {id: 3, label: "Heure", xs: 2, display: true, firstData: false, lastData: false},
    {id: 4, label: "Activité", xs: 3, display: true, firstData: false, lastData: false},
    {id: 5, label: "Type Travail", xs: 3, display: true, firstData: false, lastData: true}
];

const AnomaliesRegularisationsDialog = ({ 
    regularisation = null,
    open = false, 
    setOpen = () => {}, 
    openAllAnomalies = () => {}
}) => {
    return (
        <DialogContainer open={open} placement="top" taille="sm">
            <HeaderDialogContainer 
                actionsLeft={
                    <IconButtonBase variant="white5Grey9White9" icon={<ListAlt fontSize="small" />} title="Afficher la liste complète des anomalies" action={openAllAnomalies} />
                } 
                title="Anomalie(s)" 
                close={() => setOpen(false)}
                variant="error"
            />
            {regularisation && 
                <>
                    <Stack spacing={0.5} bgcolor={redColor[5]} p={0.5} borderRadius={2}>
                        <Box bgcolor={whiteColor[7]} borderRadius={2} p={1}>
                            <Typography fontSize="small" fontWeight="bold" textAlign="center">Régularisation</Typography>
                        </Box>
                        <HeaderListContainer itemIdReference="HANORE" variant="light" headerItems={headerItemsRegularisation}/>
                        <ListContainer spacing={0.5} length={1}>
                            {regularisation.anomalies.map((anomalie) => (
                                <Box key={`ANORE${anomalie.code}`} bgcolor={whiteColor[5]} borderRadius={2} p={0.3}>
                                    <Grid container spacing={0.3}>
                                        <ItemContainer firstData data={regularisation.libelleMouvement} xs={2} />
                                        <ItemContainer data={moment([regularisation.anneeRegularisation, regularisation.moisRegularisation - 1, regularisation.jourRegularisation]).format("DD/MM/YYYY")} xs={2} />
                                        <ItemContainer data={`${moment(regularisation.heureRegularisation, "H").format("HH")}h${moment(regularisation.minuteRegularisation, "m").format("mm")}`} xs={2} />
                                        <ItemContainer data={regularisation.libelleActivite} xs={3} />
                                        <ItemContainer lastData data={regularisation.libelleTypeTravail} xs={3} />
                                    </Grid>
                                </Box>
                            ))}
                        </ListContainer>
                    </Stack>
                    <Stack spacing={0.5} bgcolor={redColor[5]} p={0.5} borderRadius={2}>
                        <Box bgcolor={whiteColor[7]} borderRadius={2} p={1}>
                            <Typography fontSize="small" fontWeight="bold" textAlign="center">Anomalie(s)</Typography>
                        </Box>             
                        <HeaderListContainer itemIdReference="HANOP" variant="light" headerItems={headerItems}/>
                        <ListContainer spacing={0.5} length={regularisation.anomalies.length} noDataLabel="Aucune anomalie">
                            {regularisation.anomalies.map((anomalie) => (
                                <Box key={`ANO${anomalie.code}`} bgcolor={whiteColor[5]} borderRadius={2} p={0.3}>
                                    <Grid container spacing={0.3}>
                                        <ItemContainer firstData data={anomalie.codeAnomalie} xs={2} />
                                        <ItemContainer data={anomalie.libelleAnomalie} xs={8} />
                                        <ItemContainer lastData data={anomalie.gravite} xs={2} />
                                    </Grid>
                                </Box>
                            ))}
                        </ListContainer>
                    </Stack>
                </>
            }
        </DialogContainer> 
    );
}

AnomaliesRegularisationsDialog.propTypes = {
    open: PropTypes.bool,
    regularisation: PropTypes.object,
    setOpen: PropTypes.func,
    openAllAnomalies: PropTypes.func
}

export default AnomaliesRegularisationsDialog;