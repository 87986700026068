import { useSelector, useDispatch } from "react-redux";
import { Box, Stack } from "@mui/material";
import { selectHeuGesTem } from "@selectors";
import * as heuGesTemActions from "@reducers/gestionTemps/heures/heuGesTemReducer"
import { TabContentContainer, TextContainer } from "@components/Containers";
import HeuresCumulees from "@components/GestionTemps/Heures/HeuresCumulees";
import HeuresDetaillees from "@components/GestionTemps/Heures/HeuresDetaillees";
import { greenColor } from "@styles";
import { MenuButton } from "@components/Buttons";
import { useCallback, useState } from "react";
import { HourglassFull, QueryBuilder } from "@mui/icons-material";

const HeuresMobile = () => {
    const [titreMobile, setTitreMobile] = useState("Heures Cumulées")
    const stateHeures = useSelector(selectHeuGesTem);
    const dispatch = useDispatch();

    const handleTabs = useCallback((_, tab) => {
        let titre = "";
        if(tab === "HEUC") {
            titre = "Heures Cumulées";
        }
        if(tab === "HEUD") {
            titre = "Heures Détaillées";
        }
        setTitreMobile(titre);
        dispatch(heuGesTemActions.setActiveTab(tab));
    }, [dispatch]);

    return (
        <Stack spacing={0.5} borderRadius={2} height="100%" boxSizing="border-box">
            <Stack spacing={1} direction="row" bgcolor={greenColor["015"]} borderRadius={2} p={0.5} height={46} boxSizing={"border-box"} display={"flex"} alignItems="center">
                <Box flexGrow={1} boxSizing="border-box" height="100%">
                    <TextContainer bgColor={greenColor["015"]} textSize={12} p={2} height="100%" verticalAlign="center" horizontalAlign="center" textWeight="bold">
                        {titreMobile}
                    </TextContainer>
                </Box>
                <Box pr={1} pl={0.5}>
                    <MenuButton txtId="BTDABSMOB" options={[
                        {id: 1, icon: <HourglassFull fontSize="small" />, label: "Heures Cumulées", action: (e) => handleTabs(e, "HEUC")},
                        {id: 2, icon: <QueryBuilder fontSize="small" />, label: "Heures Détaillées", action: (e) => handleTabs(e, "HEUD")}
                    ]} />
                </Box>
            </Stack>
            <TabContentContainer value={stateHeures.activeTab} id="HEUC">
                <HeuresCumulees />
            </TabContentContainer>
            <TabContentContainer value={stateHeures.activeTab} id="HEUD" overflow="auto">
                <HeuresDetaillees />
            </TabContentContainer>
        </Stack>
    );
};

export default HeuresMobile;