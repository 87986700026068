import { Box } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import PropTypes from "prop-types";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor } from "@styles";
import { DialogFullScreenContainer } from "@components/Containers";
import { AddActions } from "./Actions";
import { schemaDelegation } from "@components/Delegations/validations";
import moment from "moment";
import { DelegationFormMobile } from "../Forms";
import { usePostDelegation } from "@hooks/delegations/hooksQueries";
import { useCallback, useEffect } from "react";

const defaultValues = {
    absence: false, 
    formation: false, 
    noteFrais: false, 
    dateDebut: moment(), 
    dateReprise: null, 
    typeDelegation: "immediate",
    avertissementMail: false, 
    delaiAttente: "", 
    destinataire: null
}

const AddDialogMobile = (props) => {
    const { open, setOpen, setOpenAddDialog, refetch } = props;
    const methods = useForm({ mode: "all", defaultValues: defaultValues, resolver: joiResolver(schemaDelegation) });
    const { isLoading, postDelegationActionner } = usePostDelegation();

    useEffect(() => {
        if(open) {
            methods.reset(defaultValues);
        }
    }, [methods, open]);

    const addDelegation = useCallback(async (data) => {
        const { isSucceed } = await postDelegationActionner(data);
        if(isSucceed) {
            setOpenAddDialog(false);
            refetch();
        }
    }, [postDelegationActionner, refetch, setOpenAddDialog]);

    return (
        <DialogFullScreenContainer open={open} taille="lg">
            <AddActions 
                isLoading={isLoading}
                setOpen={setOpen}
                addDelegation={() => methods.handleSubmit(addDelegation)()}
            />
            {isLoading ? 
                <Box sx={styles.loaderStyle}>
                    <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                </Box> 
            : 
                <FormProvider {...methods}>
                    <DelegationFormMobile mode="ajout" />
                </FormProvider>
            }
            </DialogFullScreenContainer>
    );
}

AddDialogMobile.defaultValues = {
    open: false,
    setOpen: () => {},
    setOpenAddDialog: () => {},
    refetch: () => {}
}

AddDialogMobile.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    setOpenAddDialog: PropTypes.func,
    refetch: PropTypes.func
}

const styles = {
    itemInterneNonCheckBoxStyle: {
        backgroundColor: "rgba(255,0,0,0.3)",
        borderRadius: 2,
        paddingX: 1,
        paddingY: 1
    },
    headerListStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.5)",
        color: "white",
        borderRadius: 2,
        padding: 2
    },
    itemListStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.2)",
        borderRadius: 2,
        paddingX: 2,
        paddingY: 1
    },
    loaderStyle: {
        height: 100,
        alignItems: "center",
        display: "flex"
    },
}

export default AddDialogMobile;