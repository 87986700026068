import { useSelector } from "react-redux";
import { DangerousRounded, Login, Logout } from "@mui/icons-material";
import PropTypes from "prop-types";
import { selectStaUsr } from "@selectors";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor } from "@styles";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@components/Buttons";

const PointageShortcut = (props) => {
    const { 
        isLoadingStatut, 
        isErrorStatut,
        handleOpenConfirmationPointage
    } = props;
    const stateStatut = useSelector(selectStaUsr);
    const { t } = useTranslation("global");

    if(isLoadingStatut) {
        return (
            <ButtonBase variant="green25Grey5Green7" sx={{minWidth: 115}}>
                <Loader size={17} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </ButtonBase>
        );
    }

    if(isErrorStatut) {
        return (
            <ButtonBase variant="red2Grey5Red5" startIcon={<DangerousRounded fontSize="small" />}>
                {t("errors.unknown_status")}
            </ButtonBase>
        );
    }

    if(!stateStatut.statut) {
        return (
            <ButtonBase variant="red2Grey5Red5" startIcon={<DangerousRounded fontSize="small" />}>
                {t("errors.unknown_status")}
            </ButtonBase>
        );
    }

    return (
        <ButtonBase variant="green25Grey5Green7" startIcon={stateStatut.statut.actionSuivante === "D" ? <Logout /> : <Login />} action={() => handleOpenConfirmationPointage("pointage")}>
            {stateStatut.statut.actionSuivante === "D" ? t("texts.departure") : t("texts.entrance") }
        </ButtonBase> 
    );
}

PointageShortcut.defaultProps = {
    isLoadingStatut: true, 
    isErrorStatut: false,
    handleOpenConfirmationPointage: () => {}
}

PointageShortcut.propTypes = {
    isLoadingStatut: PropTypes.bool, 
    isErrorStatut: PropTypes.bool,
    handleOpenConfirmationPointage: PropTypes.func
}

export default PointageShortcut;
