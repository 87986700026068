import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";
import { Check } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import PropTypes from "prop-types";
import { selectCltApp, selectSecVarApp, selectUsrApp } from "@selectors";
import { useLostPassword, useVerifierReponse } from "@hooks/securite/hooksQueries";
import * as userActions from "@reducers/user/usrAppReducer"
import { SecretVariableHookFormInput, TextInput } from "@components/Inputs";
import { Loader } from "@components/Loaders";
import { HeaderDialogContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { schemaPassword, schemaReponse } from "../validations";
import { greenColor, greyColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const defaultValuesReponse = {
    reponse: ""
}

const defaultValuesPassword = {
    newPassword1: "",
    newPassword2: ""
}


const FormReponse = (props) => {
    const { isLoading, handleEnter } = props;
    const stateUser = useSelector(selectUsrApp);
    const { t } = useTranslation("global");

    return (
        isLoading ? 
            <Box p={2}>
                <Loader colorContainer={greyColor[1]} colorLoader={greenColor["1"]} />
            </Box>
        :
            <Stack spacing={1} onKeyUp={handleEnter}>
                <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                    <Box bgcolor={whiteColor[7]} borderRadius={2} p={1}>
                        <Typography fontSize="small" fontWeight="bold">{stateUser.question}</Typography>
                    </Box>
                </Box>
                <TextInput 
                    name="reponse"
                    label={t("inputs.response")} 
                    autoFocus={true} 
                    maxLength={30}
                />
            </Stack>
    );
}

const FormEditPassword = (props) => {
    const { isLoading, handleEnter } = props;
    const { t } = useTranslation("global");

    return (
        isLoading ? 
            <Box p={2}>
                <Loader colorContainer={greyColor[1]} colorLoader={greenColor["1"]} />
            </Box>
        :
            <Stack spacing={1} onKeyUp={handleEnter}>
                <SecretVariableHookFormInput 
                    name="newPassword1"
                    label={t("inputs.password_new")} 
                    autoFocus={true}
                /> 
                <SecretVariableHookFormInput 
                    name="newPassword2"
                    label={t("inputs.password_repeat")} 
                /> 
            </Stack>
    );
}

const LostPasswordForm = () => {
    const [reponseValide, setReponseValide] = useState(false);
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const stateSecureVariable = useSelector(selectSecVarApp);
    const dispatch = useDispatch();
    const methodsReponse = useForm({ mode: "onChange", defaultValues: defaultValuesReponse, resolver: joiResolver(schemaReponse) });
    const methodsPassword = useForm({ mode: "onChange", defaultValues: defaultValuesPassword, resolver: joiResolver(schemaPassword(stateSecureVariable.parametres)) });
    const { isLoadingVerification, verifierReponseActionner } = useVerifierReponse();
    const { isLoadingLostPwd, editLostPwdActionner } = useLostPassword(); 
    const { t } = useTranslation("global"); 
    const handleCompareReponse = useCallback(async (data) => {
        const dataRequest = {
            matricule: stateUser.matricule,
            reponse: data.reponse,
            client: stateClient.client
        }
        const { isSucceed } = await verifierReponseActionner(dataRequest);
        if(isSucceed) {
            setReponseValide(true);
        }
    }, [stateClient.client, stateUser.matricule, verifierReponseActionner]);

    const handleUpdatePassword = useCallback((data) => {
        const dataRequest = {
            matricule: stateUser.matricule,
            reponse: stateUser.reponse,
            password: data.newPassword1,
            email: stateUser.email, 
            token: stateUser.token,
            client: stateClient.client
        }
        editLostPwdActionner(dataRequest);
    }, [editLostPwdActionner, stateClient.client, stateUser.email, stateUser.matricule, stateUser.reponse, stateUser.token]);

    const handleEnterPassword = useCallback((event) => {
        if(event.key === "Enter" || event.key === undefined) {
            methodsPassword.handleSubmit(handleUpdatePassword)();
        }
    }, [handleUpdatePassword, methodsPassword]);
    
    const handleEnterReponse = useCallback((event) => {
        if(event.key === "Enter" || event.key === undefined) {
            methodsReponse.handleSubmit(handleCompareReponse)();
        }
    }, [handleCompareReponse, methodsReponse]);

    const handleSubmit = useCallback(() => {
        if(reponseValide) {
            methodsPassword.handleSubmit(handleUpdatePassword)()
        } else {
            methodsReponse.handleSubmit(handleCompareReponse)()
        }
    }, [handleCompareReponse, handleUpdatePassword, methodsPassword, methodsReponse, reponseValide]);

    return (
        <Stack spacing={1}>
            <HeaderDialogContainer 
                actionsLeft={
                    <IconButtonBase 
                        variant="dialogButton"
                        icon={<Check fontSize="small" />} 
                        title={t("buttons.validate")} 
                        action={handleSubmit} 
                    />
                } 
                title={t("titles.edit_password")}
                close={() => dispatch(userActions.openLostPassword(false))}
            />
            {reponseValide ? 
                <FormProvider {...methodsPassword}>
                    <FormEditPassword isLoading={isLoadingLostPwd} handleEnter={handleEnterPassword} />
                </FormProvider>
            :
                <FormProvider {...methodsReponse}>
                    <FormReponse isLoading={isLoadingVerification} handleEnter={handleEnterReponse} />
                </FormProvider>
            }
        </Stack>
    );
}

FormReponse.defaultProps = {
    isLoading: false, 
    handleEnter: () => {}
}

FormReponse.propTypes = {
    isLoading: PropTypes.bool, 
    handleEnter: PropTypes.func
}

FormEditPassword.defaultProps = {
    isLoading: false, 
    handleEnter: () => {}
}

FormEditPassword.propTypes = {
    isLoading: PropTypes.bool, 
    handleEnter: PropTypes.func
}

export default LostPasswordForm;