import { urls } from "@constants/appConstants";
import axios from "axios";

export const fetchEmpPla = async (body, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetEmplois"
    };
    const response = await axios.post(urls.getEmplois, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.emplois;
}
