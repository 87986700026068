import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { TextHookFormInput } from "@components/Inputs";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { useChampCommentaire } from "@hooks/gestionEntretiens/hooksUtils";
import { greenColor, redColor, whiteColor } from "@styles";
import { useComEntMan } from "@hooks/gestionEntretiens/hooksQueries";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { IconButtonBase } from "@components/Buttons";
import { Refresh } from "@mui/icons-material";
import { selectEntMan } from "@selectors";

const ChampCommentaire = ({ 
    open = false, 
    codeQuestion = 0 
}) => {
    const stateEntretiens = useSelector(selectEntMan);
    const { data, isLoading, isFetching, isError, refetch } = useComEntMan(codeQuestion, open);    
    const { displayA, displayE } = useChampCommentaire();
    const { setValue } = useFormContext();

    useEffect(() => {
        if(displayA) {
            setValue("commentaire", "");
        }
        if(displayE) {
            setValue("commentaire", data?.commentaireManager);
        }
    }, [data, displayA, displayE, setValue]);

    if(displayA) {
        return (
            <Stack spacing={0.5} bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                <Box borderRadius={2} bgcolor={whiteColor[7]} p={0.5}>
                    <TextHookFormInput name="commentaire" label="Commentaire" multiline maxLength={null} />
                </Box>
            </Stack>
        );
    }

    if(isLoading || isFetching) {
        return (
            <Box>
                <Skeleton variant="rounded" animation="wave" width="100%" height={48} sx={{borderRadius: 2}} />
            </Box>
        );
    }

    if(isError) {
        return (
            <Box borderRadius={2} bgcolor={redColor[5]} p={0.5}>
                <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[7]} height={48} alignItems="center" px={1}>
                    <Box flexGrow={1}>
                        <Typography fontSize="small" display="inline" fontWeight="bold">Une erreur est survenue lors de la récupération du commentaire</Typography>
                    </Box>
                    <Box>
                        <IconButtonBase icon={<Refresh fontSize="small" />} title="Rafraichir" action={() => refetch()} />
                    </Box>
                </Stack>
            </Box>
        );
    }

    if(displayE) {
        return (
            <Stack spacing={0.5} bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                <Box borderRadius={2} bgcolor={whiteColor[7]} p={0.5}>
                    <TextHookFormInput name="commentaire" label="Commentaire" multiline maxLength={null} />
                </Box>
                {!!data?.commentaireCollaborateur && 
                    <Box borderRadius={2} bgcolor={whiteColor[7]} p={1}>
                        <Typography fontSize="small" fontWeight="bold">Commentaire de {stateEntretiens?.entretienSelected?.prenomNom}</Typography>
                        <Typography fontSize="small">{data?.commentaireCollaborateur}</Typography>
                    </Box>
                }
            </Stack>
        );
    }

    return (
        <Box>
            {(!!data?.commentaireManager || !!data?.commentaireCollaborateur) ? 
                <Stack spacing={0.5} height="100%" whiteSpace={"pre-wrap"} bgcolor={greenColor["05"]} p={0.5} borderRadius={2}>
                    {!!data?.commentaireManager && 
                        <Box borderRadius={2} bgcolor={whiteColor[7]} p={1}>
                            <Typography fontSize="small" fontWeight="bold">Mon commentaire</Typography>
                            <Typography fontSize="small">{data?.commentaireManager}</Typography>
                        </Box>
                    }
                    {!!data?.commentaireCollaborateur && 
                        <Box borderRadius={2} bgcolor={whiteColor[7]} p={1}>
                            <Typography fontSize="small" fontWeight="bold">Commentaire de {stateEntretiens?.entretienSelected?.prenomNom}</Typography>
                            <Typography fontSize="small">{data?.commentaireCollaborateur}</Typography>
                        </Box>
                    }
                </Stack>
            :
                <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.5} height="100%" whiteSpace={"pre-wrap"}> 
                    <Box borderRadius={2} bgcolor={whiteColor[7]} p={1}>
                        <Typography fontSize="small" fontWeight="bold">Aucun commentaire</Typography>
                    </Box>
                </Box>
            }
        </Box>
    );
}

ChampCommentaire.propTypes = {
    open: PropTypes.bool,
    codeQuestion: PropTypes.string
}

export default ChampCommentaire;