import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Grow, Stack } from "@mui/material";
import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import moment from "moment";
import { selectEntMan } from "@selectors";
import { useDeleteEntretien } from "@hooks/gestionEntretiens/hooksQueries";
import { heurifyBuild } from "@utils";
import { DialogContainer, HeaderDialogContainer, HeaderListContainer, ListContainer, TextContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";

const headerItems = [
    {id: 1, label: "Date", xs: 1, display: true},
    {id: 2, label: "Heure", xs: 0.5, display: true},
    {id: 3, label: "Société", xs: 2, display: true},
    {id: 4, label: "Matricule", xs: 1, display: true},
    {id: 5, label: "Nom", xs: 1.5, display: true},
    {id: 6, label: "Entretien", xs: 5, display: true},
    {id: 7, label: "Statut", xs: 1, display: true}
];

const statutsRoutes = {
    P: "Planifié",
    E: "Effectué"
}

const DeleteDialog = ({ 
    open = false, 
    setOpen = () => {}, 
    refetch = () => {} 
}) => {
    const stateEntretien = useSelector(selectEntMan);
    const { isLoadingDelete, deleteEntretienActionner } = useDeleteEntretien(); 

    const deleteEntretien = useCallback(async () => {
        const { isSucceed } = await deleteEntretienActionner()
        if(isSucceed) {
            setOpen(false);
            refetch();
        }
    }, [deleteEntretienActionner, refetch, setOpen]);

    return (
        <DialogContainer open={open} taille="lg" placement="top">
            <HeaderDialogContainer  
                actionsLeft={
                    <IconButtonBase icon={<Check fontSize="small" />} title="Valider" disabled={isLoadingDelete} action={() => deleteEntretien()} />   
                }
                title="Suppression d'un entretien"
                close={() => setOpen(false)}
            />
            {isLoadingDelete ? 
                <Box sx={styles.loaderStyle}>
                    <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                </Box> 
            : 
                <Stack spacing={0.5} bgcolor={redColor[1]} borderRadius={2} p={0.5}>
                    <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold">Voulez-vous vraiment supprimer l'entretien ci-dessous ?</TextContainer>
                    <HeaderListContainer itemIdReference="DDELEG" variant="light" headerItems={headerItems} />
                    <ListContainer spacing={0.5} length={1}>
                        {stateEntretien.entretienSelected &&
                            <Grow in={true} timeout={600}>
                                <Box bgcolor={whiteColor[5]} borderRadius={2} p={0.25}>
                                    <Grid container spacing={0.5}>
                                        <Grid item xs={1}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" horizontalAlign="center" textSize={11} height="100%">{moment([stateEntretien.entretienSelected.annee, stateEntretien.entretienSelected.mois - 1, stateEntretien.entretienSelected.jour]).format("DD/MM/YYYY")}</TextContainer>
                                        </Grid>
                                        <Grid item xs={0.5}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" horizontalAlign="center" textSize={11} height="100%">{heurifyBuild(stateEntretien.entretienSelected.heure, stateEntretien.entretienSelected.minute, "h")}</TextContainer>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height="100%">{stateEntretien.entretienSelected.libelleSociete}</TextContainer>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height="100%">{stateEntretien.entretienSelected.matricule}</TextContainer>
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height="100%">{stateEntretien.entretienSelected.prenomNom}</TextContainer>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height="100%">{stateEntretien.entretienSelected.designation}</TextContainer>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height="100%">{statutsRoutes[stateEntretien.entretienSelected.statut]}</TextContainer>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grow>
                        }
                    </ListContainer>
                </Stack>
            }
        </DialogContainer>
    );
}

DeleteDialog.propTypes = {
    open: PropTypes.bool, 
    setOpen: PropTypes.func, 
    refetch: PropTypes.func
}

const styles = {
    itemInterneNonCheckBoxStyle: {
        backgroundColor: "rgba(255,0,0,0.3)",
        borderRadius: 2,
        paddingX: 1,
        paddingY: 1
    },
    headerListStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.5)",
        color: "white",
        borderRadius: 2,
        padding: 2
    },
    itemListStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.2)",
        borderRadius: 2,
        paddingX: 2,
        paddingY: 1
    },
    loaderStyle: {
        height: 100,
        alignItems: "center",
        display: "flex"
    },
}

export default DeleteDialog;