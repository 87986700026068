import { arrange, asc, desc, tidy } from "@tidyjs/tidy";
import moment from "moment";

export const trierDabGesAbsMan = (keyTrie, data) => {
    switch(keyTrie) {
        case "dateEmissionAsc":
            return tidy(data, arrange((daa, dab) => moment(daa.date, "DDMMYYYY").utc() - moment(dab.date, "DDMMYYYY").utc()));
        case "dateEmissionDesc": 
            return tidy(data, arrange((daa, dab) => moment(dab.date, "DDMMYYYY").utc() - moment(daa.date, "DDMMYYYY").utc()));
        case "dateAbsenceAsc":
            return tidy(data, arrange((daa, dab) => moment(daa.dateDebut, "DDMMYYYY").utc() - moment(dab.dateDebut, "DDMMYYYY").utc()));
        case "dateAbsenceDesc": 
            return tidy(data, arrange((daa, dab) => moment(dab.dateDebut, "DDMMYYYY").utc() - moment(daa.dateDebut, "DDMMYYYY").utc()));
        case "natureAsc": 
            return tidy(data, arrange([asc("nature")]));
        case "natureDesc":
            return tidy(data, arrange([desc("nature")]));
        case "emetteurAsc":
            return tidy(data, arrange([asc("prenomNom")]));
        case "emetteurDesc":
            return tidy(data, arrange([desc("prenomNom")]));
        case "statutAsc":
            return tidy(data, arrange([asc("statut")]));
        case "statutDesc":
            return tidy(data, arrange([desc("statut")]));
        default: 
            return data;
    }
};
