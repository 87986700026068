import { filter, tidy } from "@tidyjs/tidy";
import moment from "moment";

export const filtrerDlgApp = (data, dataBrut) => {
    switch(data.colonne) {
        case "nature":
            return tidy(dataBrut, filter(delegation => delegation.codeNature === data.txt));
        case "dateDebut":
            switch(data.operateur) {
                case "egale":
                    return tidy(dataBrut, filter(delegation => moment(delegation.dateDebut, "DDMMYYYY").isSame(data.date)));
                case "superieur":
                    return tidy(dataBrut, filter(delegation => moment(delegation.dateDebut, "DDMMYYYY").isSameOrAfter(data.date)));
                case "inferieur":
                    return tidy(dataBrut, filter(delegation => moment(delegation.dateDebut, "DDMMYYYY").isSameOrBefore(data.date)));
                default: 
                    return dataBrut
            }
        case "dateReprise":
            switch(data.operateur) {
                case "egale":
                    return tidy(dataBrut, filter(delegation => moment(delegation.dateReprise, "DDMMYYYY").isSame(data.date)));
                case "superieur":
                    return tidy(dataBrut, filter(delegation => moment(delegation.dateReprise, "DDMMYYYY").isSameOrAfter(data.date)));
                case "inferieur":
                    return tidy(dataBrut, filter(delegation => moment(delegation.dateReprise, "DDMMYYYY").isSameOrBefore(data.date)));
                default: 
                    return dataBrut
            }
        case "delegataire":
            return tidy(dataBrut, filter(delegation => delegation.delegataire === data.txt.id));
        default:
            return dataBrut
    }
}
