import { useCallback, useState } from "react";
import { TextField, Autocomplete, autocompleteClasses, Typography, Box, Tooltip, tooltipClasses, Paper, Grid, List, Popper } from "@mui/material";
import { useController, useFormContext, useFormState } from "react-hook-form";
import { greenColor, greyColor, redColor, yellowColor } from "@styles";
import { Loader } from "@components/Loaders";
import PropTypes from "prop-types";
import { TextContainer } from "@components/Containers";

const sizeMapping = {
    xsmall: { muiLabel: "small", height: 32, pt: 0.25, top: "-5px", topShrink: 0 },
    small: { muiLabel: "small", height: undefined, pt: undefined, top: undefined, topShrink: undefined }
}

const OptionsComponent = (props, option, headerOptions) => {
    return (
        <Box key={option.id} component="li" {...props} sx={styles.optionsStyle} borderRadius={2} mb={0.5}>
            <Grid container spacing={0.5}>
                {headerOptions.map((headerOption, id) => (
                    <Grid item xs={headerOption.xs} key={`AUTOCOPTIONOPT${id}`} display="flex" justifyContent="center">
                        <TextContainer bgColor="none" p={0}>{option[headerOption.key]}</TextContainer>
                    </Grid>
                ))}
            </Grid>
        </Box> 
    );
}

const ListboxComponent = (props, headerOptions, query, enableHeaders) => {
    return (
        query.isFetching ? 
            <Box py={2}>
                <Loader size={17} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        :
            <Box px={0.5} pb={0}>
                {enableHeaders && 
                    <Box py={0.5}>
                        <Grid container spacing={0.5}>
                            {headerOptions.map((headerOption) => (
                                <Grid item xs={headerOption.xs} key={`AUTOCOPTIONHEAD${headerOption.id}`}>
                                    <TextContainer bgColor={greenColor["015"]} p={0.75} height={"100%"} textWeight="bold" horizontalAlign="center" verticalAlign="center" textAlign="center" textDecoration={"none"}>{headerOption.title}</TextContainer>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                }
                <List {...props} />
            </Box>
    );
}

const PopperComponent = (props, placement) => {
    return (
        <Popper {...props} placement={placement} />
    );
}

const PaperComponent = (props) => {
    return (
        <Paper {...props} style={{minWidth: "max-content"}}  />
    );
}

const InputComponent = (props, name, label, size, errors, showErrors, disabled, disabledText, tooltipPlacement, openTooltip, handleOpenTooltip, handleCloseTooltip) => {
    return (
        disabled ? 
            <Tooltip placement={tooltipPlacement} open={openTooltip} onOpen={handleOpenTooltip} onClose={handleCloseTooltip} arrow PopperProps={{sx: {[`& .${tooltipClasses.tooltip}`]: {backgroundColor: yellowColor[9], fontSize: 12, fontWeight: "bold"}, [`& .${tooltipClasses.arrow}`]: {color: yellowColor[9],}}}} title={disabledText}>
                <TextField {...props} error={errors[name]} label={label} sx={{ "& .MuiInputBase-root": { height: sizeMapping[size].height, "&.MuiOutlinedInput-root": { pt: sizeMapping[size].pt } } }} InputLabelProps={{ sx: { top: sizeMapping[size].top, "&[data-shrink='true']": { top: sizeMapping[size].topShrink } } }} />
            </Tooltip>
        :
            <Tooltip placement={tooltipPlacement} open={!!errors[name]} arrow PopperProps={{sx: {[`& .${tooltipClasses.tooltip}`]: {backgroundColor: redColor[8], fontSize: 12, fontWeight: "bold"}, [`& .${tooltipClasses.arrow}`]: {color: redColor[8],}}}} title={showErrors ? errors[name]?.message : ""}>
                <TextField {...props} error={errors[name]} label={label} sx={{ "& .MuiInputBase-root": { height: sizeMapping[size].height, "&.MuiOutlinedInput-root": { pt: sizeMapping[size].pt } } }} InputLabelProps={{ sx: { top: sizeMapping[size].top, "&[data-shrink='true']": { top: sizeMapping[size].topShrink } } }} />
            </Tooltip>
    );
}

const NoOptions = (query) => {
    return (
        query.isError ? 
            <Typography fontSize={"small"} color={redColor[9]}>Erreur de récupération des données</Typography> 
        : 
            <Typography fontSize={"small"}>Aucune donnée</Typography>
            
    );
}

const Loading = () => {
    return (
        <Box>
            <Loader size={17} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
        </Box>
    );
}


const optionsMapping = (option, value) => {
    return option.id === value.id
}

const labelsMapping = (option) => option.libelle ? option.libelle : "";

const AutocompleteAsyncInput = (props) => {
    const { 
        name, 
        label, 
        headerOptions, 
        open, 
        showErrors, 
        popperPlacement, 
        tooltipPlacement, 
        size, 
        disabled, 
        disabledText, 
        enableHeaders, 
        required, 
        query, 
        setOpen, 
        onChange, 
        optionsMapping, 
        labelsMapping 
    } = props;
    const { control } = useFormContext();
    const { field } = useController({ name: name, control, rules: { required: required } });
    const { errors } = useFormState({ control });
    const [openTooltip, setOpenTooltip] = useState(false);

    const handleCloseTooltip = () => {
        setOpenTooltip(false);
    };
  
    const handleOpenTooltip = () => {
        setOpenTooltip(true);
    };
    const handleOpen = useCallback(() => {
        query.refetch();
        setOpen(true)
    }, [query, setOpen]);

    const handleChange = useCallback((d) => {
        onChange(d);
        field.onChange(d);
    }, [field, onChange]);

    return (
        <Autocomplete {...field} fullWidth label={label} size="small" options={query.data ? query.data : []} open={open} loading={query.isFetching}
            onOpen={() => handleOpen()}
            onClose={() => setOpen(false)}
            getOptionLabel={labelsMapping}      
            isOptionEqualToValue={optionsMapping}
            loadingText={<Loading />}
            noOptionsText={<NoOptions query={query} />}
            PopperComponent={(props) => PopperComponent(props, popperPlacement)}
            disabled={disabled}
            ListboxProps={{sx: { [`&.${autocompleteClasses.listbox}`]: {p: 0}}}}
            PaperComponent={(props) => PaperComponent(props)}
            ListboxComponent={(props) => ListboxComponent(props, headerOptions, query, enableHeaders)}
            renderInput={(props) => InputComponent(props, name, label, size, errors, showErrors, disabled, disabledText, tooltipPlacement, openTooltip, handleOpenTooltip, handleCloseTooltip)}
            renderOption={(props, option) =>  OptionsComponent(props, option, headerOptions)}
            onChange={(_, d) => handleChange(d)}
        />
    )
}

AutocompleteAsyncInput.defaultProps = {
    label: "",
    headerOptions: [],
    open: false,
    showErrors: false,
    popperPlacement: "bottom-start",
    popperWidth: 200,
    tooltipPlacement: "top",
    size: "small",
    disabled: false,
    disabledText: "",
    enableHeaders: true,
    required: false,
    query: {},
    setOpen: () => {},
    onChange: () => {},
    optionsMapping: optionsMapping,
    labelsMapping: labelsMapping
}

AutocompleteAsyncInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    headerOptions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        xs: PropTypes.number,
        key: PropTypes.string
    })),
    open: PropTypes.bool,
    showErrors: PropTypes.bool,
    popperPlacement: PropTypes.string,
    popperWidth: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    tooltipPlacement: PropTypes.string,
    size: PropTypes.oneOf(["xsmall", "small"]),
    disabled: PropTypes.bool,
    disabledText: PropTypes.string,
    enableHeaders: PropTypes.bool,
    required: PropTypes.bool,
    query: PropTypes.object,
    setOpen: PropTypes.func,
    onChange: PropTypes.func,
    optionsMapping: PropTypes.func,
    labelsMapping: PropTypes.func
}

const styles = {
    optionsStyle: {
        [`&.${autocompleteClasses.option}`]: {
            px: 0, 
            backgroundColor: greenColor["015"],
            '&:hover': {
                backgroundColor: greenColor["035"]
            },
            '&[aria-selected="true"]': {
                border: "1px solid",
                borderColor: greyColor[2],
                backgroundColor: greenColor["035"]
            } 
        }
    }
}

export default AutocompleteAsyncInput;