import { ChevronLeft, ChevronRight, Event, ListAlt, OfflinePin, Refresh } from "@mui/icons-material";
import PropTypes from "prop-types";
import { DatePickerButton, IconButtonBase } from "@components/Buttons";
import { HeaderDialogContainer } from "@components/Containers";

const HierarchieActions = (props) => {
    const { 
        isLoading, 
        isError, 
        setOpen, 
        affichage, 
        precedent, 
        suivant, 
        selectDate, 
        refetch, 
        toggleAffichage 
    } = props;

    return (
        <HeaderDialogContainer
            actionsLeft={
                <>
                    <IconButtonBase icon={<ChevronLeft fontSize="small" />} title="Semaine Précédente" disabled={isLoading || isError} action={() => precedent()} />
                    <IconButtonBase icon={<ChevronRight fontSize="small" />} title="Semaine Suivante" disabled={isLoading || isError} action={() => suivant()} />
                    <DatePickerButton icon={<Event fontSize="small" />} title="Choisir une date" disabled={isLoading || isError} action={() => selectDate()} />
                </> 
            }
            actionsRight={
                <>
                    <IconButtonBase icon={affichage === "T" ? <OfflinePin fontSize="small" /> : <ListAlt fontSize="small" />} title={affichage === "T" ? "Absences validées" : "Toutes les absences"} disabled={isLoading || isError} action={() => toggleAffichage()} />
                    <IconButtonBase icon={<Refresh fontSize="small" />} title="Rafraichir" disabled={isLoading} action={() => refetch()} />
                </>
            }
            title="Planning N-1"
            close={() => setOpen(false)}
        />
    );
}

HierarchieActions.defaultProps = {
    isLoading: true, 
    isError: false, 
    setOpen: () => {}, 
    affichage: "", 
    precedent: () => {}, 
    suivant: () => {}, 
    selectDate: () => {}, 
    refetch: () => {}, 
    toggleAffichage: () => {} 
}

HierarchieActions.propTypes = {
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool, 
    setOpen: PropTypes.func, 
    affichage: PropTypes.string, 
    precedent: PropTypes.func, 
    suivant: PropTypes.func, 
    selectDate: PropTypes.func, 
    refetch: PropTypes.func, 
    toggleAffichage: PropTypes.func 
}

export default HierarchieActions;