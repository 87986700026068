import { Box, Skeleton, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { greenColor, whiteColor } from "@styles";
import { heurify } from "@utils";

const PlanningFooterTableMobile = (props) => {
    const { isLoading, totalHeuresSemaine, totalHeuresAbsence } = props; 

    return (
        <Stack spacing={0.25} bgcolor={whiteColor[5]} borderRadius={2} p={0.25}>
            <Box p={1} flexGrow={1} bgcolor={greenColor["015"]} borderRadius={2}>
                {isLoading ? 
                    <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={251} height={19.5} />
                :
                    <Typography component="div" fontSize="small">
                        Total de la semaine : <Box fontWeight="bold" display="inline">{heurify(totalHeuresSemaine, "h", false)} </Box> 
                    </Typography>       
                }
            </Box>  
            <Box p={1} flexGrow={1} bgcolor={greenColor["015"]} borderRadius={2}>
                {isLoading ? 
                    <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={251} height={19.5} />
                :
                    <Typography component="div" fontSize="small">
                        Total des absences : <Box fontWeight="bold" display="inline">{heurify(totalHeuresAbsence, "h", false)} </Box> 
                    </Typography>  
                }     
            </Box>  
        </Stack>
    );
}

PlanningFooterTableMobile.defaultProps = {
    isLoading: false,
    totalHeuresSemaine: "",
    totalHeuresAbsence: ""
}

PlanningFooterTableMobile.propTypes = {
    isLoading: PropTypes.bool,
    totalHeuresSemaine: PropTypes.string,
    totalHeuresAbsence: PropTypes.string
}

export default PlanningFooterTableMobile;