import { Box, Skeleton, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { greenColor, redColor, whiteColor } from "@styles";

const CommentaireQuestionContent = ({ 
    idCommentaire = 1, 
    commentaires = [], 
    isLoading = true, 
    isError = false 
}) => {
    if(isLoading) {
        return (
            <Box>
                <Skeleton variant="rounded" animation="wave" width="100%" height={48} sx={{borderRadius: 2}} />
            </Box>
        );
    }

    if(isError) {
        return (
            <Box borderRadius={2} bgcolor={redColor[5]} p={0.5}>
                <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[7]} height={48} alignItems="center" px={1}>
                    <Box flexGrow={1}>
                        <Typography fontSize="small" display="inline" fontWeight="bold">Une erreur est survenue lors de la récupération du commentaire</Typography>
                    </Box>
                </Stack>
            </Box>
        );
    }

    return (
        <Box>
            {(!!commentaires?.commentaireManager?.[`commentaire${idCommentaire}`] || !!commentaires?.commentaireCollaborateur?.[`commentaire${idCommentaire}`]) ? 
                <Stack spacing={0.5} height="100%" whiteSpace={"pre-wrap"} bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                    {!!commentaires?.commentaireManager?.[`commentaire${idCommentaire}`] && 
                        <Box borderRadius={2} bgcolor={whiteColor[7]} p={1}>
                            <Typography fontSize="small" fontWeight="bold">Commentaire de votre manager</Typography>
                            <Typography fontSize="small">{commentaires?.commentaireManager?.[`commentaire${idCommentaire}`]}</Typography>
                        </Box>
                    }
                    {!!commentaires?.commentaireCollaborateur?.[`commentaire${idCommentaire}`] && 
                        <Box borderRadius={2} bgcolor={whiteColor[7]} p={1}>
                            <Typography fontSize="small" fontWeight="bold">Votre commentaire</Typography>
                            <Typography fontSize="small">{commentaires?.commentaireCollaborateur?.[`commentaire${idCommentaire}`]}</Typography>
                        </Box>
                    }
                </Stack>
            :
                <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.5} height="100%" whiteSpace={"pre-wrap"}> 
                    <Box borderRadius={2} bgcolor={whiteColor[7]} p={1}>
                        <Typography fontSize="small" fontWeight="bold">Aucun commentaire</Typography>
                    </Box>
                </Box>
            }
        </Box>
    );
}

CommentaireQuestionContent.propTypes = {
    idCommentaire: PropTypes.number,
    commentaires: PropTypes.array,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool
}

export default CommentaireQuestionContent;