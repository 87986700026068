import { Box } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import PropTypes from "prop-types";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor } from "@styles";
import { DialogContainer } from "@components/Containers";
import { AddActions } from "./Actions";
import { schemaDelegation } from "@components/Delegations/validations";
import moment from "moment";
import { DelegationForm } from "../Forms";
import { usePostDelegation } from "@hooks/delegations/hooksQueries";
import { useCallback, useEffect } from "react";

const defaultValues = {
    absence: false, 
    formation: false, 
    noteFrais: false, 
    dateDebut: moment(), 
    dateReprise: null, 
    typeDelegation: "immediate",
    avertissementMail: false, 
    delaiAttente: "", 
    destinataire: null
}

const AddDialog = ({ 
    open = false, 
    setOpen = () => {}, 
    setOpenAddDialog = () => {}, 
    refetch = () => {}
}) => {
    const methods = useForm({ mode: "all", defaultValues: defaultValues, resolver: joiResolver(schemaDelegation) });
    const { isLoading, postDelegationActionner } = usePostDelegation();

    useEffect(() => {
        if(open) {
            methods.reset(defaultValues);
        }
    }, [methods, open]);

    const addDelegation = useCallback(async (data) => {
        const { isSucceed } = await postDelegationActionner(data);
        if(isSucceed) {
            setOpenAddDialog(false);
            refetch();
        }
    }, [postDelegationActionner, refetch, setOpenAddDialog]);

    return (
        <DialogContainer open={open} taille="md" placement="top">
            <AddActions 
                isLoading={isLoading}
                setOpen={setOpen}
                addDelegation={() => methods.handleSubmit(addDelegation)()}
            />
            {isLoading ? 
                <Box p={2}>
                    <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                </Box> 
            : 
                <FormProvider {...methods}>
                    <DelegationForm mode="ajout" />
                </FormProvider>
            }
            </DialogContainer>
    );
}

AddDialog.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    setOpenAddDialog: PropTypes.func,
    refetch: PropTypes.func
}

export default AddDialog;