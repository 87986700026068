import { useSelector, useDispatch } from "react-redux";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import { selectHeuGesTem } from "@selectors";
import * as heuGesTemActions from "@reducers/gestionTemps/heures/heuGesTemReducer"
import { TabContentContainer } from "@components/Containers";
import HeuresCumulees from "@components/GestionTemps/Heures/HeuresCumulees";
import HeuresDetaillees from "@components/GestionTemps/Heures/HeuresDetaillees";
import { greenColor, greyColor, whiteColor } from "@styles";

const Heures = () => {
    const stateHeures = useSelector(selectHeuGesTem);
    const dispatch = useDispatch();

    return (
        <Stack spacing={0.5} sx={styles.stackStyle}>
            <Box>
                <Tabs value={stateHeures.activeTab} onChange={(_, tab) => dispatch(heuGesTemActions.setActiveTab(tab))}
                    indicatorColor=""
                    sx={styles.tabsStyles} 
                    centered
                >            
                    <Tab label="Heures Cumulées" value="HEUC" sx={styles.tabStyle} />               
                    <Tab label="Heures Détaillées" value="HEUD" sx={styles.tabStyle} />                             
                </Tabs>
            </Box>
            <TabContentContainer value={stateHeures.activeTab} id="HEUC">
                <HeuresCumulees />
            </TabContentContainer>
            <TabContentContainer value={stateHeures.activeTab} id="HEUD" overflow="auto">
                <HeuresDetaillees />
            </TabContentContainer>
        </Stack>
    );
};

const styles = {
    stackStyle: {
        borderRadius: 2,
        position: "relative",
        height: "100%"
    },
    tabStyle: {
        color: greyColor[9],
        fontWeight: "bold",
        marginRight: 0.5,
        minHeight: "",
        border: "none",
        borderRadius: 2,
        flexGrow: 1,
        textTransform: "none",
        "&:hover": {
            backgroundColor: whiteColor[5],
            color: greyColor[9]
        },
        "&.Mui-selected": {
            backgroundColor: whiteColor[5],
            color: greyColor[9]
        }
    },
    tabsStyles: {
        height: 41,
        minHeight: "",
        paddingLeft: 0.5,
        paddingY: 0.5,
        backgroundColor: greenColor["015"], 
        borderRadius: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    }
};

export default Heures;