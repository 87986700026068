import { Box, Grid, Grow, Stack } from "@mui/material";
import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { heurify } from "@utils";
import { HeaderListContainer, ItemContainer, ListContainer } from "@components/Containers";
import { greenColor } from "@styles";
import PerfectScrollbar from "react-perfect-scrollbar";
import { IconButtonBase } from "@components/Buttons";

const CollaborateursFwgtp3List = ({
    horaire = false, 
    data = [], 
    headers = [], 
    collaborateurSelected = null, 
    openFiltre = false,
    selectCollaborateur = () => {}
}) => {
    return (
        <Stack spacing={0.5}>
            <HeaderListContainer itemIdReference="CBODYGT" headerItems={headers} />
            <PerfectScrollbar> 
                <Box maxHeight={openFiltre ? "calc(100vh - 385px)" : "calc(100vh - 206px)"}>
                    <ListContainer spacing={0.5} length={data.length} noDataLabel="Aucune personne">
                        {data.map((collaborateur, id) => (
                            <Grow key={`PRS${collaborateur.id}`} in={true} timeout={400+(200*id)}>
                                <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.3}>
                                    <Grid container spacing={0.3}>
                                        <ItemContainer firstData data={collaborateur.prenomNom} xs={2} />
                                        <ItemContainer data={collaborateur.matricule} xs={0.75} />
                                        <ItemContainer data={collaborateur.badge} xs={0.5} />
                                        <ItemContainer data={collaborateur.typePersonnel === "P" ? "Salarié" : "Intérimaire"} xs={0.75} />
                                        {horaire ? 
                                            collaborateur.horaires.map((horaire, id) => (
                                                <ItemContainer key={`DAT${id}`} data={horaire} xs={1} />
                                            ))
                                        :
                                            collaborateur.zones.map((zone, id) => (
                                                <ItemContainer key={`DAT${id}`} data={zone} xs={1} />
                                            ))
                                        }
                                        <ItemContainer lastData data={heurify(collaborateur.totalSemaine, "h")} xs={0.5} />
                                        <Grid item xs={0.5} textAlign="center" display="flex" alignItems="center" justifyContent="center">
                                            <IconButtonBase variant="white5Grey9White9" icon={<Check fontSize="small" />} disabled={collaborateurSelected?.matricule === collaborateur.matricule} title={collaborateurSelected?.matricule === collaborateur.matricule ? "Collaborateur déjà sélectionné" : "Selectionner"} action={() => selectCollaborateur(collaborateur)} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grow>
                        ))}
                    </ListContainer>
                </Box>
            </PerfectScrollbar>
        </Stack>
    );
}

CollaborateursFwgtp3List.propTypes = {
    horaire: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.object),
    headers: PropTypes.array,
    collaborateurSelected: PropTypes.object,
    openFiltre: PropTypes.bool,
    selectCollaborateur: PropTypes.func
}

export default CollaborateursFwgtp3List;