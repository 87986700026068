import { TextHookFormInput } from "@components/Inputs";
import { Box, Stack } from "@mui/material";
import { greenColor, whiteColor } from "@styles";

const ChampDesignation = () => {
    return (
        <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
            <Stack spacing={0.5} borderRadius={2} bgcolor={whiteColor[7]} p={0.5}>
                <TextHookFormInput name="designation1" label="Designation 1" maxLength={80} />
                <TextHookFormInput name="designation2" label="Designation 2" maxLength={80} />
            </Stack>
        </Box>
    )
    
}

export default ChampDesignation;