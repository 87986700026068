import { useQuery } from "@tanstack/react-query";
import { fetchMto } from "@api/meteo";

export const useMeteo = (coords) => {
    const query = useQuery(['meteo'], ({ signal }) => fetchMto(coords, signal), {
        retry: false, 
        refetchOnWindowFocus: false
    });
    
    return query;
}

