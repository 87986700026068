import { Box, Grid, Typography } from "@mui/material";
import { AutoFixHigh, CompareArrows, Info, Surfing } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useStateTemGesTem } from "@hooks/gestionTemps/temps/hooksStates";
import { chiffreUSVersChiffreFR, heurify } from "@utils";
import { blueColor, greenColor, whiteColor } from "@styles";
import { MenuButton } from "@components/Buttons";

const jours = ["Lu", "Ma", "Me", "Je", "Ve", "Sa", "Di"];

const TempsItemsTableMobile = (props) => {
    const { userType, openTransactions, openRegularisations, openJour, openInformationsJour } = props;
    const stateTemps = useStateTemGesTem(userType);

    return (
        stateTemps?.semaine.map((jour, id) => (
            <Box key={`JOURMT${jour.id}`} bgcolor={greenColor["015"]} borderRadius={2} p={0.25}>
                <Grid container spacing={0.5}>
                    <Grid item xs={2}>
                        <Box sx={styles.boxJourDateStyle} bgcolor={jour.libelleFerie ? blueColor["015"] : whiteColor[5]} height={"100%"} display="flex" boxSizing="border-box" alignItems="center">
                            <Typography fontSize={"small"} fontWeight="bold">{jours[id]}</Typography>
                        </Box>
                    </Grid>
                    <Grid item container xs={4} spacing={0.5}>
                        <Grid item xs={6}>
                            <Box bgcolor={whiteColor[5]} p={1} borderRadius={2} height={"100%"} boxSizing="border-box">
                                <Typography fontSize={"small"}>{stateTemps.horaire ? heurify(jour?.heuresJournee?.badgees?.heure, "h", false) : chiffreUSVersChiffreFR(jour?.heuresJournee?.badgees?.chiffre)}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box bgcolor={whiteColor[5]} p={1} borderRadius={2} height={"100%"} boxSizing="border-box">
                                <Typography fontSize={"small"}>{stateTemps.horaire ? heurify(jour?.heuresJournee?.payees?.heure, "h", false) : chiffreUSVersChiffreFR(jour?.heuresJournee?.payees?.chiffre)}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item container xs={4} spacing={0.5}>
                        <Grid item xs={6}>
                            <Box bgcolor={whiteColor[5]} p={1} borderRadius={2} height={"100%"} boxSizing="border-box">
                                <Typography fontSize={"small"}>{stateTemps.horaire ? heurify(jour?.heuresNuit?.badgees?.heure, "h", false) : chiffreUSVersChiffreFR(jour?.heuresNuit?.badgees?.chiffre)}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box bgcolor={whiteColor[5]} p={1} borderRadius={2} height={"100%"} boxSizing="border-box">
                                <Typography fontSize={"small"}>{stateTemps.horaire ? heurify(jour?.heuresNuit?.payees?.heure, "h", false) : chiffreUSVersChiffreFR(jour?.heuresNuit?.payees?.chiffre)}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                            {jour.codeAbsence ?
                                <MenuButton txtId="BTDABSMOB" options={[
                                    {id: 1, icon: <Info fontSize="small" />, label: "Informations", action: () => openInformationsJour(jour.date, jour.libelleFerie)},
                                    {id: 2, icon: <CompareArrows fontSize="small" />, label: "Transactions", action: () => openTransactions(jour.date)},
                                    {id: 3, icon: <AutoFixHigh fontSize="small" />, label: "Régulariser", action: () => openRegularisations(jour.date)},
                                    {id: 4, icon: <Surfing fontSize="small" />, label: "Absences", action: () => openJour(jour.date)},
                                ]} />
                            :
                                <MenuButton txtId="BTDABSMOB" options={[
                                    {id: 1, icon: <Info fontSize="small" />, label: "Informations", action: () => openInformationsJour(jour.date, jour.libelleFerie)},
                                    {id: 2, icon: <CompareArrows fontSize="small" />, label: "Transactions", action: () => openTransactions(jour.date)},
                                    {id: 3, icon: <AutoFixHigh fontSize="small" />, label: "Régulariser", action: () => openRegularisations(jour.date)},
                                ]} />
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        ))
    );
}

TempsItemsTableMobile.propTypes = {
    userType: PropTypes.string.isRequired,
    openTransactions: PropTypes.func.isRequired,
    openRegularisations: PropTypes.func.isRequired,
    openJour: PropTypes.func.isRequired,
    openInformationsJour: PropTypes.func.isRequired,
}

const styles = {
    boxJourDateStyle: {
        p: 1,
        borderRadius: 2,
    },
    boxJourAvecAbsenceDateStyle: {
        p: 0.5,
        borderRadius: 2
    },
    boxJourHeuresStyle: {
        backgroundColor: whiteColor[5],
        p: 1,
        borderRadius: 2,
    },
}

export default TempsItemsTableMobile;