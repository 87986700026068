import { useCallback } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { CheckHookFormInput, DateHookFormInput } from "@components/Inputs";
import { TextContainer } from "@components/Containers";
import { whiteColor } from "@styles";
import { SecteursHookFormInput } from "@components/Inputs/Secteurs";
import { ServicesHookFormInput } from "@components/Inputs/Services";
import { DirectionsHookFormInput } from "@components/Inputs/Directions";
import { EtablissementsHookFormInput } from "@components/Inputs/Etablissements";
import { SocietesHookFormInput } from "@components/Inputs/Societes";

const HeuresCumuleesForm = () => {
    const { watch, resetField } = useFormContext();
    const societeSelected = watch("societe");
    const dateDebut = watch("dateDebut");

    const reset = useCallback(() => {
        resetField("etablissement");
        resetField("direction");
        resetField("service");
    }, [resetField]);

    return (
        <Stack spacing={1.5}>
            <Stack spacing={1}>
                <TextContainer p={1.25} bgColor={whiteColor[5]} horizontalAlign="center" textWeight="bold" textSize={15}>Période d'analyse</TextContainer>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <DateHookFormInput name="dateDebut" label={"Date de début"} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateHookFormInput name="dateFin" label={"Date de fin"} minDate={dateDebut} displayError />
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
            <Stack spacing={1}>
                <TextContainer p={1.25} bgColor={whiteColor[5]} horizontalAlign="center" textWeight="bold" textSize={15}>Critères de sélection</TextContainer>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4}>
                            <SocietesHookFormInput action={reset} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <EtablissementsHookFormInput codeSociete={societeSelected?.codeSociete} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <DirectionsHookFormInput codeSociete={societeSelected?.codeSociete} />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4}>
                            <ServicesHookFormInput codeSociete={societeSelected?.codeSociete} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SecteursHookFormInput />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box borderRadius={2} bgcolor={whiteColor[5]} height={40} display="flex" alignItems="center" px={2}>
                                <CheckHookFormInput name="present" label="Collaborateur Présent ?" />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
        </Stack>
    );
}

export default HeuresCumuleesForm;