import { useSelector } from "react-redux";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Group, ViewTimeline } from "@mui/icons-material";
import PropTypes from "prop-types";
import moment from "moment";
import { selectDrgGesTem } from "@selectors";
import { DialogContainer, HeaderDialogContainer, HeaderListContainer, HeaderPanelContainer, TextContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { TransactionsList } from "@components/App/Transactions";
import { RegularisationsList } from "@components/App/Regularisations";
import { heurify } from "@utils";
import { ROLE_UTILISATEUR } from "@constants/userConstants";
import { greenColor, redColor, whiteColor, yellowColor } from "@styles";
import { useDetDrg } from "@hooks/gestionTemps/demandesRegularisations/hooksQueries";

const headersTransactions = [
    {id: 1, label: "Mouvement", xs: 1.5, display: true},
    {id: 2, label: "Date", xs: 1.5, display: true},
    {id: 3, label: "Heure Réelle", xs: 1.5, display: true},
    {id: 4, label: "Heure Ajustée", xs: 1.5, display: true},
    {id: 5, label: "Lecteur", xs: 1, display: true},
    {id: 6, label: "Activité", xs: 1.75, display: true},
    {id: 7, label: "Type Travail", xs: 2, display: true},
    {id: 8, label: "Anomalie", xs: 1.25, display: true}
]

const headersRegularisations = [
    {id: 1, label: "Mouvement", xs: 2, display: true},
    {id: 2, label: "Date", xs: 2, display: true},
    {id: 3, label: "Heure", xs: 2, display: true},
    {id: 4, label: "Activité", xs: 2.25, display: true},
    {id: 5, label: "Type Travail", xs: 2.25, display: true},
    {id: 6, label: "Anomalie", xs: 1.5, display: true}
]

const colorStatut = {
    A: greenColor["015"],
    R: redColor[1]
}

const DemandeRegularisationDetailDialog = (props) => {
    const { 
        userType, 
        transactions,
        regularisations,
        open, 
        setOpen, 
        openPlagesHoraires, 
        setOpenSuivi, 
        openAnomalieTransaction, 
        openAnomaliesRegularisation,
        anomaliesDisplay
    } = props;
    const stateDemandeRegularisation = useSelector(selectDrgGesTem);
    const { isLoading, isFetching, isError, error } = useDetDrg(userType, open);

    return (
        <DialogContainer open={open} placement="top" taille="xl">
            <HeaderDialogContainer 
                actionsLeft={
                    <>
                        {userType === ROLE_UTILISATEUR.MANAGER && 
                            <IconButtonBase 
                                icon={<ViewTimeline fontSize="small" />} 
                                placement="top" 
                                title={"Plages horaires"} 
                                disabled={isLoading || isFetching || isError} 
                                action={() => openPlagesHoraires()} 
                            />
                        }
                        <IconButtonBase 
                            icon={<Group fontSize="small" />} 
                            placement="top"
                            title="Suivi" 
                            disabled={isLoading || isFetching || isError} 
                            action={() => setOpenSuivi(true)} 
                        />
                    </>
                }   
                title="Détail d'un demande de régularisation"
                close={() => setOpen(false)}
            />
            <Box>
                <Grid spacing={0.5} container>
                    <Grid item xs={12}>
                        <Box bgcolor={greenColor["045"]} borderRadius={2}>  
                            <Stack spacing={0.5} bgcolor={whiteColor[5]} p={0.5} borderRadius={2}>
                                <Box>
                                    <Grid container spacing={0.5}>
                                        <Grid item xs={12} sm={9}>
                                            {userType === ROLE_UTILISATEUR.COLLABORATEUR ?
                                                <TextContainer bgColor={whiteColor[5]} p={1}>
                                                    Demande émise le <Box component={"span"} fontWeight="bold" display="inline">{moment(stateDemandeRegularisation?.demandeRegularisationSelected?.dateDemande, "YYYYMMDD").format("DD/MM/YYYY")}</Box> à <Box component={"span"} fontWeight="bold" display="inline">{moment(stateDemandeRegularisation?.demandeRegularisationSelected?.heureDemande, "HHmmss").format("HH:mm:ss")}</Box>
                                                </TextContainer>
                                            :
                                                <TextContainer bgColor={whiteColor[5]} p={1}>
                                                    Demande émise par <Box component={"span"} fontWeight="bold" display="inline">{stateDemandeRegularisation?.demandeRegularisationSelected?.prenomNom}</Box> le <Box component={"span"} fontWeight="bold" display="inline">{moment(stateDemandeRegularisation?.demandeRegularisationSelected?.dateDemande, "YYYYMMDD").format("DD/MM/YYYY")}</Box> à <Box component={"span"} fontWeight="bold" display="inline">{heurify(stateDemandeRegularisation?.demandeRegularisationSelected?.heureDemande, ":", true, true)}</Box>
                                                </TextContainer>
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextContainer 
                                                bgColor={colorStatut[stateDemandeRegularisation?.demandeRegularisationSelected?.codeStatut] ? colorStatut[stateDemandeRegularisation?.demandeRegularisationSelected?.codeStatut] : yellowColor[1]} 
                                                p={1} 
                                                horizontalAlign="center" 
                                                textWeight="bold"
                                            >
                                                {stateDemandeRegularisation?.demandeRegularisationSelected?.libelleStatut}
                                            </TextContainer>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box bgcolor={whiteColor[5]} borderRadius={2} p={1}>
                                    <Typography component="div" fontSize="small">
                                        Régularisation de la journée du <Box component={"span"} fontWeight="bold" display="inline">{moment([stateDemandeRegularisation?.demandeRegularisationSelected?.anneeRattachement, stateDemandeRegularisation?.demandeRegularisationSelected?.moisRattachement - 1, stateDemandeRegularisation?.demandeRegularisationSelected?.jourRattachement]).format("dddd DD MMMM YYYY")}</Box>
                                    </Typography>
                                </Box>
                                {stateDemandeRegularisation?.demandeRegularisationSelected?.observation && 
                                    <TextContainer bgColor={whiteColor[5]} p={1}>
                                        Motif de la demande : {stateDemandeRegularisation?.demandeRegularisationSelected?.observation}
                                    </TextContainer>
                                }
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack spacing={0.5} bgcolor={greenColor["015"]} p={0.5} borderRadius={2}>
                            <HeaderPanelContainer bgColor={whiteColor[5]} title="Transactions" minHeight={30} /> 
                            <HeaderListContainer itemIdReference="TRTMPS" headerItems={headersTransactions} />
                            <TransactionsList 
                                data={transactions} 
                                isLoading={isLoading || isFetching} 
                                isError={isError} 
                                errorMessage={error?.message} 
                                openAnomalies={openAnomalieTransaction} 
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack spacing={0.5} bgcolor={greenColor["015"]} p={0.5} borderRadius={2}>
                            <HeaderPanelContainer bgColor={whiteColor[5]} title="Regularisations" minHeight={30} /> 
                            <HeaderListContainer itemIdReference="RETMPS" headerItems={headersRegularisations} />
                            <RegularisationsList 
                                data={regularisations} 
                                isLoading={isLoading || isFetching} 
                                isError={isError} 
                                errorMessage={error?.message} 
                                openAnomalies={openAnomaliesRegularisation}
                                anomalies={anomaliesDisplay}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </DialogContainer>
    );
}

DemandeRegularisationDetailDialog.defaultProps = {
    userType: ROLE_UTILISATEUR.COLLABORATEUR,
    transactions: [],
    regularisations: [],
    openPlagesHoraires: () => {}, 
    setOpenSuivi: () => {}, 
    openAnomalieTransaction: () => {}, 
    openAnomaliesRegularisation: () => {},
    anomaliesDisplay: false
}

DemandeRegularisationDetailDialog.propTypes = {
    userType: PropTypes.string,
    transactions: PropTypes.array,
    regularisations: PropTypes.array,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    openPlagesHoraires: PropTypes.func, 
    setOpenSuivi: PropTypes.func, 
    openAnomalieTransaction: PropTypes.func, 
    openAnomaliesRegularisation: PropTypes.func,
    anomaliesDisplay: PropTypes.bool
}

export default DemandeRegularisationDetailDialog;