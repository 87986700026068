import { Box, Stack } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { Loader } from "@components/Loaders";
import { TextContainer } from "@components/Containers";
import { PiecesJointesList, SuiviList } from "@components/GestionAbsences/Collaborateur/HistoriqueDemandes/Lists";
import { greenColor, greyColor, redColor, whiteColor, yellowColor } from "@styles";
import { TextDureeAbsenceContainer, TextMotifAbsenceContainer, TextNombreHeuresAbsenceContainer, TextNombreJoursAbsenceContainer } from "@components/Containers/GestionAbsences";
import { useTranslation } from "react-i18next";

const colorStatut = {
    "Acceptée": greenColor["07"],
    "Non traitée": yellowColor[6],
    "En cours": yellowColor[6],
    "Refusée": redColor[5]
}

const HistoriqueDemandesContent = ({
    isLoading = true, 
    data = null, 
    demandes = [], 
    idDemande = 0
}) => {    
    const { t } = useTranslation("global");

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    return (
        <Stack spacing={0.5}>
            <Stack spacing={0.5} bgcolor={colorStatut[demandes[idDemande]?.statut]} p={0.5} borderRadius={2}>
                <TextContainer bgColor={whiteColor[7]} p={1}>
                    {t("texts.request_issued", {date: moment(demandes[idDemande]?.date, "DDMMYYYY").format("DD/MM/YYYY"), heure: moment(demandes[idDemande]?.heure, "HHmmss").format("HH:mm:ss")})}
                </TextContainer>
                <TextMotifAbsenceContainer libelleAbsence={demandes[idDemande]?.libelleAbsence} />
                <Box bgcolor={whiteColor[7]} p={1} borderRadius={2}>
                    <TextDureeAbsenceContainer dateDebut={demandes[idDemande]?.dateDebut} dateReprise={demandes[idDemande]?.dateReprise} nature={demandes[idDemande]?.nature} nombreJours={demandes[idDemande]?.nombreJours} />
                    <TextNombreJoursAbsenceContainer nombreJours={demandes[idDemande]?.nombreJours} demiJournee={demandes[idDemande]?.demiJournee} />
                    <TextNombreHeuresAbsenceContainer heureDebut={demandes[idDemande]?.heureDebut} heureReprise={demandes[idDemande]?.heureReprise} nombreJours={demandes[idDemande]?.nombreJours} nombreHeures={demandes[idDemande]?.nombreHeures} />
                </Box>
                {demandes[idDemande]?.motifDemande && 
                    <TextContainer bgColor={whiteColor[7]} p={1}>Motif de la demande : {demandes[idDemande]?.motifDemande}</TextContainer>
                }
                {demandes[idDemande]?.conditionFirst && 
                    <TextContainer bgColor={whiteColor[7]} p={1}>Condition acceptée : {demandes[idDemande]?.conditionFirst} {demandes[idDemande]?.conditionSecond}</TextContainer>
                }
            </Stack> 
            <Stack spacing={0.5} bgcolor={colorStatut[demandes[idDemande]?.statut]} p={0.5} borderRadius={2}>
                <SuiviList suivi={data?.suivi} />
            </Stack>
            <Stack spacing={0.5} bgcolor={colorStatut[demandes[idDemande]?.statut]} p={0.5} borderRadius={2}>
                <PiecesJointesList piecesJointes={data?.piecesJointes} />
            </Stack>
        </Stack>
    );
}

HistoriqueDemandesContent.propTypes = {
    isLoading: PropTypes.bool,
    data: PropTypes.object, 
    demandes: PropTypes.array, 
    idDemande: PropTypes.number
}

const styles = {
    boxSuiviTitleStyle: {
        backgroundColor: "rgba(0,0,0,0.2)", 
        borderRadius: 2,
        p:1
    },
    boxListItemStyle: {
        backgroundColor: "rgba(0,0,0,0.1)", 
        borderRadius: 2,
        p:1
    },
    boxStatutStyle: { 
        borderRadius: 2,
        p:1
    },
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        height: "218px",
    },
    boxRecapTitleStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.3)", 
        borderRadius: 2,
        p:1
    },
    boxRecapItemStyle: {
        backgroundColor: "rgba(0,0,0,0.1)", 
        borderRadius: 2,
        p:1
    },
};

export default HistoriqueDemandesContent;