import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { generateTableurMouvement } from "@tableurs";
import { downloadMvt } from "@api/mouvements";

export const useDownloadMvt = () => {
    const { isLoading, mutateAsync } = useMutation(downloadMvt);
    const { enqueueSnackbar } = useSnackbar();

    const downloadActionner = async (data) => {
        try {
            const response = await mutateAsync(data);
            generateTableurMouvement("Mouvements Gestion du Temps", response, data.detailMouvements);
        } catch(e) {
            if(e?.response?.data?.details) {
                enqueueSnackbar(e?.response?.data?.details, { variant: "error" });
            } else {
                enqueueSnackbar("Erreur inconnue", { variant: "error" });
            }
        }
    };

    return { isLoading, downloadActionner };
}