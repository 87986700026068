import { useSelector } from "react-redux";
import { selectPlaDabCol, selectPlaDabMan, selectPlaGlo, selectPlaHrc } from "@selectors";
import { TYPES_PLANNING } from "@constants/planningConstants";

export const useStatePlanning = (type) => {
    const statePlaDabCol = useSelector(selectPlaDabCol);
    const statePlaDabMan = useSelector(selectPlaDabMan);
    const statePlaGlo = useSelector(selectPlaGlo);
    const statePlaHrc = useSelector(selectPlaHrc);

    if(type === TYPES_PLANNING.ABSENCE_COLLABORATEUR) {
        return statePlaDabCol;
    }
    if(type === TYPES_PLANNING.ABSENCE_MANAGER) {
        return statePlaDabMan;
    }
    if(type === TYPES_PLANNING.GENERAL) {
        return statePlaGlo;
    }
    if(type === TYPES_PLANNING.HIERARCHIE) {
        return statePlaHrc;
    }
    
    return null;
}