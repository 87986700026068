import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import moment from "moment";
import { fetchDrgCol, fetchDrgMan, fetchDetDrgCol, fetchDetDrgMan, addDrg, editRepDrg, deleteDrg } from "@api/demandesRegularisation";
import { fetchRegCol } from "@api/regularisations";
import { selectCltApp, selectDrgGesTem, selectUsrApp } from "@selectors";
import * as drgGesTemActions from "@reducers/gestionTemps/regularisations/drgGesTemReducer";
import { hasAnomalies, heurify } from "@utils";
import { formToDataDemandeRegularisation } from "@integrations";
import { ROLE_UTILISATEUR } from "@constants/userConstants";
import { trierDrgGesTemCol } from "@components/GestionTemps/DemandesRegularisation/tries";

export const useDrgQryCol = (userType, enable) => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const stateDemandeRegularisation = useSelector(selectDrgGesTem);
    const dispatch = useDispatch();

    const query = useQuery(['drgQryCol'], ({ signal }) => fetchDrgCol(stateUser.matricule, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: enable
    });

    useEffect(() => {
        if(query.data) {
            dispatch(drgGesTemActions.setDemandesRegularisation({ userType: userType, demandesRegularisation: query.data }))
            if(stateDemandeRegularisation.tried) {
                const dataTried = trierDrgGesTemCol(stateDemandeRegularisation.colonneTrie, query.data);
                dispatch(drgGesTemActions.trier({demandes: dataTried, colonne: stateDemandeRegularisation.colonneTrie}));
            }
        }
    }, [dispatch, query.data, stateDemandeRegularisation.colonneTrie, stateDemandeRegularisation.tried, userType]);
    
    return query;
}

export const useDrgQryMan = (userType, enable) => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const dispatch = useDispatch();

    const query = useQuery(['drgQryMan'], ({ signal }) => fetchDrgMan(stateUser.utilisateurAS400, stateUser.matricule, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: enable
    });

    useEffect(() => {
        if(query.data) {
            dispatch(drgGesTemActions.setDemandesRegularisation({ userType: userType, demandesRegularisation: query.data }));
        }
    }, [dispatch, query.data, userType]);
    
    return query;
}

export const useDetDrg = (userType, open) => {
    const stateDemandesRegularisation = useSelector(selectDrgGesTem);
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const matricule = userType === ROLE_UTILISATEUR.MANAGER ? stateDemandesRegularisation?.demandeRegularisationSelected?.emetteur : stateUser.matricule;
    const date = stateDemandesRegularisation?.demandeRegularisationSelected?.dateDemande;
    const dateRattachement = moment([stateDemandesRegularisation?.demandeRegularisationSelected?.anneeRattachement, stateDemandesRegularisation?.demandeRegularisationSelected?.moisRattachement - 1, stateDemandesRegularisation?.demandeRegularisationSelected?.jourRattachement]);
    const heureDemande = stateDemandesRegularisation?.demandeRegularisationSelected?.heureDemande;
    const jourDemande = moment(date, "YYYYMMDD").format("DD");
    const moisDemande = moment(date, "YYYYMMDD").format("MM");
    const anneeDemande = moment(date, "YYYYMMDD").format("YYYY");
    const jourRattachement = dateRattachement.format("DD");
    const moisRattachement = dateRattachement.format("MM");
    const anneeRattachement = dateRattachement.format("YYYY");
    const dispatch = useDispatch();

    const query = useQuery(['detDrg'], ({ signal }) => fetchDetDrgCol(matricule, "P", jourDemande, moisDemande, anneeDemande, heureDemande, jourRattachement, moisRattachement, anneeRattachement, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!date && open
    });

    useEffect(() => {
        if(query.data) {
            dispatch(drgGesTemActions.setDetailRegularisation(query.data));
        }
    }, [dispatch, query.data]);
    
    return query;
}

export const useDetDrgTemQryCol = (open, enable) => {
    const stateClient = useSelector(selectCltApp);
    const stateUser = useSelector(selectUsrApp);
    const stateDemandesRegularisation = useSelector(selectDrgGesTem);
    const date = stateDemandesRegularisation.dateDemandeRegularisation;
    const dispatch = useDispatch();

    const query = useQuery(['detDrgTemQryCol', date], ({ signal }) => fetchRegCol(stateUser.matricule, "P", date, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!date && open && enable
    });

    useEffect(() => {
        if(query.data) {
            dispatch(drgGesTemActions.setDetailRegularisation(query.data));
        }
    }, [dispatch, query.data])

    return query;
}

export const useDetDrgQryMan = (enable, open) => {
    const stateDemandesRegularisation = useSelector(selectDrgGesTem);
    const stateClient = useSelector(selectCltApp);
    const date = stateDemandesRegularisation?.demandeRegularisationSelected?.dateDemande;
    const dateRattachement = moment([stateDemandesRegularisation?.demandeRegularisationSelected?.anneeRattachement, stateDemandesRegularisation?.demandeRegularisationSelected?.moisRattachement - 1, stateDemandesRegularisation?.demandeRegularisationSelected?.jourRattachement]);
    const heureDemande = stateDemandesRegularisation?.demandeRegularisationSelected?.heureDemande;
    const jourDemande = moment(date, "YYYYMMDD").format("DD");
    const moisDemande = moment(date, "YYYYMMDD").format("MM");
    const anneeDemande = moment(date, "YYYYMMDD").format("YYYY");
    const jourRattachement = dateRattachement.format("DD");
    const moisRattachement = dateRattachement.format("MM");
    const anneeRattachement = dateRattachement.format("YYYY");
    const dispatch = useDispatch();
    
    const query = useQuery(['detDrgQryMan'], ({ signal }) => fetchDetDrgMan(stateDemandesRegularisation?.demandeRegularisationSelected?.emetteur, "P", jourDemande, moisDemande, anneeDemande, heureDemande, jourRattachement, moisRattachement, anneeRattachement, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open && enable
    });

    useEffect(() => {
        if(query.data) {
            dispatch(drgGesTemActions.setDetailRegularisation(query.data));
        }
    }, [dispatch, query.data]);
    
    return query;
}

export const useDrgRepGesTem = () => {
    const stateClient = useSelector(selectCltApp);
    const stateUser = useSelector(selectUsrApp);
    const stateDemandesRegularisation = useSelector(selectDrgGesTem);
    const { isLoading, mutateAsync } = useMutation(editRepDrg);
    const { enqueueSnackbar } = useSnackbar();

    const postReponseActionner = async (codeReponse, niveauValidation = "1", motifRefus = "") => {
        let codeRetour = "";
        const data = {
            matriculeHierarchique: stateUser.matricule,
            matriculeDemandeur: stateDemandesRegularisation?.demandeRegularisationSelected?.emetteur,
            dateDemande: moment(stateDemandesRegularisation?.demandeRegularisationSelected?.dateDemande, "YYYYMMDD").format("DDMMYYYY"),
            heureDemande: heurify(stateDemandesRegularisation?.demandeRegularisationSelected?.heureDemande, ""),
            reponse: codeReponse,
            motifRefus: motifRefus,
            niveauValidation: niveauValidation,
            numeroEnregistrement: "",
            client: stateClient.client
        }
        try {
            await mutateAsync(data);
            enqueueSnackbar("Demande acceptée avec succès", { variant: "success",  anchorOrigin: {vertical: 'bottom', horizontal: 'center'} });
        } catch(e) {
            codeRetour = e?.response?.data?.codeRetour
            enqueueSnackbar(e?.response?.data?.details, { variant: "error",  anchorOrigin: {vertical: 'bottom', horizontal: 'center'} })
        }

        return { codeRetour };
    }

    return { isLoading, postReponseActionner };
}

export const useDrgDemGesTem = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const stateDemandesRegularisation = useSelector(selectDrgGesTem);
    const { isLoading, mutateAsync } = useMutation(addDrg);

    const postDemande = async (data, niveauValidation = "N", code = "") => {
        let openDAnomalie = false;
        let codeRetour = "";
        let details = "";
        let isSuccess = false;
        try {
            const formatedDataCheck = formToDataDemandeRegularisation(stateUser.matricule, stateDemandesRegularisation.dateDemandeRegularisation, data, stateClient.client, niveauValidation, code);
            const resultCheck = await mutateAsync(formatedDataCheck);
            isSuccess = resultCheck.succeed;
            openDAnomalie = hasAnomalies(resultCheck.regularisations);
            codeRetour = resultCheck.codeRetour;
            details = resultCheck.details;
        } catch(e) {
            if(e?.response?.data?.codeRetour === "AN") {
                openDAnomalie = true;
            }
            codeRetour = e?.response?.data?.codeRetour;
            details = e?.response?.data?.details;
        }

        return { openDAnomalie, codeRetour, details, isSuccess };
    }

    return { isLoading, postDemande };
}

export const useDrgGesTemDel = () => {
    const stateDemandeRegularisation = useSelector(selectDrgGesTem);
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const { isLoading, mutateAsync } = useMutation(deleteDrg);
    const { enqueueSnackbar } = useSnackbar();

    const deleteDemandeRegularisationActionner = async () => {
        let isSuccess = false;
        const data = {
            utilisateur: stateUser.utilisateurAS400,
            matricule: stateUser.matricule,
            date: stateDemandeRegularisation?.demandeRegularisationSelected?.dateDemande,
            heure: moment(stateDemandeRegularisation?.demandeRegularisationSelected?.heureDemande, "Hmmss").format("HHmmss"),
            client: stateClient.client
        }
        try {
            const response = await mutateAsync(data);
            if(response.succeed) {
                enqueueSnackbar("Demande de régularisation supprimée avec succès", { variant: "success" });
                isSuccess = true;
            } else {
                enqueueSnackbar(response.details, { variant: "error" });
            }
        } catch(e) {
            enqueueSnackbar(e?.data?.response.details, { variant: "error" });
        }

        return { isSuccess };
    };
    
    return { isLoading, deleteDemandeRegularisationActionner };
}
