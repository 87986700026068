import { Box, Grid, Grow, Stack } from "@mui/material";
import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { PanelListContainer, TextWithLabelContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { greenColor, whiteColor } from "@styles";
import PerfectScrollbar from "react-perfect-scrollbar";

const CollaborateursFwdc01ListMobile = (props) => {
    const { data, selectCollaborateur } = props;

    return (
        <Stack spacing={0.5}>
            <Box overflow="auto" maxHeight="calc(100vh - 140px)">
                <PerfectScrollbar> 
                    <PanelListContainer length={data.length}>
                        {data.map((collaborateur, id) => (
                            <Grow key={`PRS${collaborateur.id}`} in={true} timeout={400+(200*id)}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Stack spacing={0.25} padding={0.5} borderRadius={2} bgcolor={greenColor["015"]}>
                                        <Box borderRadius={2} p={1} bgcolor={whiteColor[5]}>
                                            <Stack spacing={0}>
                                                <TextWithLabelContainer label="Nom" value={collaborateur.prenomNom} weight={{label: "bold", value: "normal"}} />
                                                <TextWithLabelContainer label="Matricule" value={collaborateur.matricule} weight={{label: "bold", value: "normal"}} />
                                                <TextWithLabelContainer label="Badge" value={collaborateur.badge} weight={{label: "bold", value: "normal"}} />
                                                <TextWithLabelContainer label="Société" value={collaborateur.libelleSociete} weight={{label: "bold", value: "normal"}} />
                                                <TextWithLabelContainer label="Service" value={collaborateur.libelleService} weight={{label: "bold", value: "normal"}} />
                                                <TextWithLabelContainer label="Type" value={collaborateur.typePersonnel === "P" ? "Salarié" : "Intérimaire"} weight={{label: "bold", value: "normal"}} />
                                            </Stack>
                                        </Box>
                                        <Box borderRadius={2} height={35.5} display="flex" alignItems="center" justifyContent="center" bgcolor={whiteColor[5]}>
                                            <IconButtonBase icon={<Check fontSize="small" />} placement="left" title="Selectionner" action={() => selectCollaborateur(collaborateur)} />
                                        </Box>
                                    </Stack>
                                </Grid>
                            </Grow>
                        ))}
                    </PanelListContainer>
                </PerfectScrollbar>
            </Box>
        </Stack>
    );
}

CollaborateursFwdc01ListMobile.defaultProps = {
    data: [],
    selectCollaborateur: () => {}
}

CollaborateursFwdc01ListMobile.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    selectCollaborateur: PropTypes.func
}

export default CollaborateursFwdc01ListMobile;