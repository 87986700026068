import axios from "axios";
import { AES } from "crypto-js";
import { urls } from "@constants/appConstants";

export const verificationClt = async (client) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteCheckClient", 
    };
    const response = await axios.get(urls.getCheckClient, { headers: headers, params: { client: client } });  
    return response.data
}

export const fetchSecVar = async (client) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetParametresSecurites"
    };
    const response = await axios.get(urls.getParametresSecurite, { headers: headers, params: { client: client } });
    return response.data.parametresSecurites;
}

export const verificationIdt = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteCheckIdentifiant"
    };
    const body = {
        identifiant: data.identifiant
    };
    const response = await axios.post(urls.getCheckIdentifiant, body, { headers: headers, params: { client: data.client } });
    return response.data;
}

export const login = async (dataLogin) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteLogin"
    };
    const body = {
        identifiant: dataLogin.identifiant, 
        password: dataLogin.password,
        mail: dataLogin.mail,
        date: dataLogin.date,
        gestionTemps: dataLogin.gestionTemps
    };  
    const response = await axios.post(urls.postConnexion, body, { headers: headers, params: { client: dataLogin.client } });

    const passwordEncrypt = AES.encrypt(dataLogin.password, "Alphasys@Encryption69").toString();
    
    const data = {
        auth: response.data.auth,
        identifiant: dataLogin.identifiant, 
        matricule: response.data.matricule, 
        utilisateurAS400: response.data.utilisateur,
        societe: response.data.societe, 
        menu: [...response.data.menu], 
        prenom: response.data.prenom, 
        nom: response.data.nom,  
        email: dataLogin.mail,
        token: response.data.token, 
        background: response.data.background,
        police: response.data.police, 
        horloges: response.data.horloges ? response.data.horloges.list : [],
        autorisationEd: response.data.autorisationEd,
        autorisationAc: response.data.autorisationAc,
        autorisationRe: response.data.autorisationRe,
        password: passwordEncrypt,
        edtPassword: response.data.edtPassword,
        edtQuestion: response.data.edtQuestion,
        edtPasswordQuestion: response.data.edtPasswordQuestion,
        typesTravail: response.data.typesTravail ? response.data.typesTravail : [],
        droitSalarie: response.data.droitSalarie,
        droitInterimaire: response.data.droitInterimaire,
        lang: response.data.lang
    }
    return data;
}

export const editLosPwd = async (data) => {
    const headers = {
        "Content-Type": "application/json",
        "codeAlphasysWeb": "Alphasys@WebService-RequeteEditLostPassword", 
        "HttpToken": data.token
    };
    const body = {
        matricule: data.matricule, 
        typePersonnel: "P", 
        password: data.password, 
        reponseSecrete: data.reponse,
        mail: data.email 
    };
    const response = await axios.post(urls.postSecureLostPassword, body, { headers: headers, params: { client: data.client } });
    return response.data;
}

export const editPwd = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteEditPassword", 
        "HttpToken": data.token
    };
    const body = {
        matricule: data.matricule, 
        typePersonnel: "P", 
        oldPassword: data.oldPassword, 
        password: data.password, 
        mail: data.email 
    };
    const response = await axios.post(urls.postSecurePassword, body, { headers: headers, params: { client: data.client } });
    return response.data;
}

export const editQst = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteEditQuestionReponse", 
        "HttpToken": data.token
    };
    const body = {
        matricule: data.matricule, 
        typePersonnel: "P", 
        password: data.password, 
        questionSecrete: data.question, 
        reponseSecrete: data.reponse, 
        mail: data.email
    };
    const response = await axios.post(urls.postSecureQuestion, body, { headers: headers, params: { client: data.client } });  
    return response.data;
}

export const editPwdQst = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteEditPasswordQuestionReponse", 
        "HttpToken": data.token
    };
    const body = {
        matricule: data.matricule, 
        typePersonnel: "P", 
        oldPassword: data.oldPassword, 
        password: data.password, 
        questionSecrete: data.question, 
        reponseSecrete: data.reponse,
        mail: data.email
    };
    const response = await axios.post(urls.postSecurePasswordQuestion, body, { headers: headers, params: { client: data.client } });
    return response.data;
}

export const compareRep = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteCompareReponse"
    };
    const body = {
        matricule: data.matricule, 
        typePersonnel: "P", 
        reponseSecrete: data.reponse
    };
    const response = await axios.post(urls.postCompareReponse, body, { headers: headers, params: { client: data.client } });
    return response.data;
}