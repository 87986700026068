import { Box, Skeleton, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { whiteColor } from "@styles";

const InformationsBarMobile = (props) => {
    const { data, isLoading, matricule, prenomNom } = props;
    
    if(isLoading) {
        return <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={"100%"} height={74.5} />;
    }

    return (
        <Box sx={styles.boxBandeauInformationsStyle}>
            {(matricule && prenomNom) &&
                <Stack spacing={1} direction="row">
                    <Typography fontSize="small" fontWeight="bold">Collaborateur</Typography>
                    <Typography fontSize="small">{matricule} {prenomNom}</Typography>
                </Stack>
            }
            {(data?.infos?.codeHoraire || data?.infos?.libelleHoraire) && 
                <Stack spacing={1} direction="row">
                    <Typography fontSize="small" fontWeight="bold">Horaire</Typography>
                    <Typography fontSize="small">{data?.infos?.codeHoraire} {data?.infos?.libelleHoraire}</Typography>
                </Stack>
            }
            {data?.infos?.tempsContractuel && 
                <Stack spacing={1} direction="row">
                    <Typography fontSize="small" fontWeight="bold">Temps contractuel</Typography>
                    <Typography fontSize="small">{data?.infos?.tempsContractuel}</Typography>
                </Stack>
            }
            {data?.infos?.equipe &&
                <Stack spacing={1} direction="row">
                    <Typography fontSize="small" fontWeight="bold">Equipe</Typography>
                    <Typography fontSize="small">{data?.infos?.equipe === "O" ? "Oui" : "Non"}</Typography>
                </Stack>
            }
        </Box>
    );
}

InformationsBarMobile.propTypes = {
    data: null,
    isLoading: true,
    matricule: "",
    prenomNom: ""
}

InformationsBarMobile.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    matricule: PropTypes.string,
    prenomNom: PropTypes.string
}

const styles = {
    boxBandeauInformationsStyle: {
        backgroundColor: whiteColor[5], 
        p:1,
        borderRadius: 2,
    }
}

export default InformationsBarMobile;