import { Box, Grid, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { greenColor, greyColor, whiteColor } from "@styles";

const bgColor = {
    normale: greenColor["01"],
    green07: greenColor["07"],
    white08: whiteColor[8]
}

const color = {
    normale: greyColor[9],
    green07: whiteColor[9],
    white08: greyColor[9]
}

const HeaderPanelContainer = ({
    positionnement = [3, 6, 3], 
    actionsLeft = null, 
    actionsRight = null, 
    title = "", 
    variant = "normale", 
    padding = 1, 
    textAlign = "center", 
    verticalAlign = "center", 
    minHeight = undefined
}) => {
    return (  
        <Box bgcolor={bgColor[variant]} padding={padding} textAlign={textAlign} borderRadius={2} minHeight={minHeight} alignItems={verticalAlign} display={"flex"}>
            <Grid container item xs={12} alignItems="center">
                <Grid item xs={positionnement[0]} textAlign="left">
                    <Stack direction="row" spacing={0.5}>
                        {actionsLeft}
                    </Stack>
                </Grid>
                <Grid item xs={positionnement[1]}>
                    <Typography color={color[variant]} fontWeight="bold">{title}</Typography>
                </Grid>
                <Grid item xs={positionnement[2]}>
                    <Stack direction="row" spacing={0.5} justifyContent="right">
                        {actionsRight}
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
}


HeaderPanelContainer.propTypes = { 
    actionsLeft: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.array, PropTypes.bool]), 
    actionsRight: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.array, PropTypes.bool]),
    positionnement: PropTypes.arrayOf(PropTypes.number), 
    title: PropTypes.string, 
    variant: PropTypes.string, 
    padding: PropTypes.number, 
    textAlign: PropTypes.string,
    verticalAlign: PropTypes.string,
    minHeight: PropTypes.number
}

export default HeaderPanelContainer;