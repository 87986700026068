import { validateDate } from "@validation";
import Joi from "joi";

export const schemaMouvementsIndividuels = Joi.object({
    dateDebut: validateDate.dateMoment().valide().messages({ 
        "object.base": "Date obligatoire", 
        "dateMoment.valide": "Date invalide",
    }),
    dateFin: validateDate.dateMoment().valide().minDateDebut().messages({ 
        "object.base": "Date obligatoire", 
        "dateMoment.valide": "Date invalide",
        "dateMoment.minDateDebut": "La date de fin doit être supérieure ou égale à la date de début"
    }),
    societe: Joi.object().allow(null),
    etablissement: Joi.object().allow(null),
    direction: Joi.object().allow(null),
    service: Joi.object().allow(null),
    secteur: Joi.object().allow(null),
    emploi: Joi.object().allow(null),
    horaire: Joi.object().allow(null),
    jour: Joi.string().required(),
    evenement: Joi.string().required().messages({"string.empty": "Evénement obligatoire"}),
    heureLimite: Joi.string().required().messages({"string.empty": "Heure événement obligatoire"}),
    detail: Joi.bool()
});

export const schemaMouvementsCollectifs = Joi.object({
    dateDebut: validateDate.dateMoment().valide().messages({ 
        "object.base": "Date obligatoire", 
        "dateMoment.valide": "Date invalide",
    }),
    dateFin: validateDate.dateMoment().valide().minDateDebut().messages({ 
        "object.base": "Date obligatoire", 
        "dateMoment.valide": "Date invalide",
        "dateMoment.minDateDebut": "La date de fin doit être supérieure ou égale à la date de début"
    }),
    societe: Joi.object().allow(null),
    etablissement: Joi.object().allow(null),
    direction: Joi.object().allow(null),
    service: Joi.object().allow(null),
    secteur: Joi.object().allow(null),
    emploi: Joi.object().allow(null),
    horaire: Joi.object().allow(null),
    jour: Joi.string().required(),
    evenement: Joi.string().required().messages({"string.empty": "Evénement obligatoire"}),
    heureLimite: Joi.string().required().messages({"string.empty": "Heure événement obligatoire"}),
    detail: Joi.bool()
});