import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { selectEntSecCol } from "@selectors";
import { greenColor, whiteColor } from "@styles";
import { useChampEvaluationCollaborateur } from "@hooks/gestionEntretiens/hooksUtils";

const EvaluationContent = () => {
    const stateSections = useSelector(selectEntSecCol);
    const sectionSelected = stateSections?.sectionSelected;
    const { display } = useChampEvaluationCollaborateur();    

    if(display) {
        return (
            <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                <Box borderRadius={2} bgcolor={whiteColor[7]} p={1}>
                    {sectionSelected?.evaluation ? 
                        <Typography fontSize="small">Evaluation : {sectionSelected?.designationEvaluation}</Typography>
                    :
                        <Typography fontSize="small" fontWeight="bold">Aucune évaluation</Typography>
                    }
                </Box>
            </Box>
        );
    }

    return null;
}

export default EvaluationContent;