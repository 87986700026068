import { forwardRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { SnackbarContent, useSnackbar } from "notistack";
import { Close, Info, VisibilityRounded } from "@mui/icons-material";
import * as tabAppActions from "@reducers/tabs/tabAppReducer";
import { selectTabApp } from "@selectors";

const SnackbarWarningNotificationDemandesAbsence = forwardRef((props, ref) => {
    const { id, message } = props
    const { closeSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const stateTabs = useSelector(selectTabApp);
    
    const handleAction = useCallback(() => {
        let tabs = [...stateTabs.tabs]
        
        if(!tabs.some(tabR => tabR.action === "AB020003")) {
            tabs.push({label: "Les demandes d'absences de mes collaborateurs", action: "AB020003"});
        } 
		dispatch(tabAppActions.editTabs(tabs));
		dispatch(tabAppActions.setActiveTab("AB020003"));
		
        closeSnackbar(id);
    }, [stateTabs.tabs, dispatch, closeSnackbar, id])

    const handleDismiss = useCallback(() => {
        closeSnackbar(id);
      }, [id, closeSnackbar]);

    return (
        <SnackbarContent ref={ref} role="alert" sx={styles.snackbarContentStyle}>
            <Stack spacing={1} direction="row" padding={2} alignItems="center" backgroundColor="rgba(232,161,7,1)" width="100%" borderRadius={2}>
                <Box display="flex">
                    <Info fontSize="small" sx={styles.iconStyle} />
                </Box> 
                <Box flexGrow={1}>
                    <Typography fontSize="small" color="white">{message}</Typography>
                </Box>
                <Box>
                    <Tooltip title="Afficher">
                        <IconButton size="small" sx={{color: "white"}} onClick={handleAction}>
                            <VisibilityRounded fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Fermer">
                        <IconButton size="small" sx={{color: "white"}} onClick={handleDismiss}>
                            <Close fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Stack>
        </SnackbarContent>
    )
});

const styles = {
    snackbarContentStyle: {
        backgroundColor: "rgba(255,255,255,0.7)", 
        borderRadius: 2
    },
    iconStyle: {
        color: "white"
    }
}

export default SnackbarWarningNotificationDemandesAbsence;