import { Typography, Stack, Grow, Box } from "@mui/material"; 
import { greenColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const Confidentiality = () => {
    const { t } = useTranslation("global");

    return (
        <Grow in={true} timeout={500}>
            <Stack p={0.5} borderRadius={2} bgcolor={whiteColor[9]}>
                <Box p={1} bgcolor={greenColor["05"]} borderRadius={2}>
                    <Typography fontSize="medium" fontWeight="bold">{t("texts.confidentiality_1")}</Typography>
                    <Typography fontSize="medium" fontWeight="bold">{t("texts.confidentiality_2")}</Typography>
                </Box>
            </Stack>
        </Grow>
    )
}

export default Confidentiality;