export const CODE_TABS_ABSENCES = {
    TAB_DEMANDE_ABSENCE: "DABS",
    TAB_DEMANDE_ANNULATION_ABSENCE: "DANU"
}

export const CODE_TABS_ABSENCES_MOBILE = {
    TAB_DEMANDE_ABSENCE: "DABS",
    TAB_DEMANDE_ANNULATION_ABSENCE: "DANU",
    TAB_SOLDES: "SOLD",
    TAB_CALENDRIER: "CALE",
    TAB_HISTORIQUE_ABSENCE: "HIST"
}

export const CODE_TABS_GESTION_TEMPS = {
    TAB_MON_TEMPS: "TMPS",
    TAB_PLAGES_HORAIRE: "PLH",
    TAB_DEMANDES_REGULARISATION: "REGU",
    TAB_COMPTEURS: "CMPS"
}

export const CODE_TABS_GESTION_TEMPS_MANAGER = {
    TAB_TEMPS_COLLABORATEUR: "TMPS",
    TAB_PLANNING_COLLABORATEUR: "PLAN",
    TAB_AFFECTATIONS: "AFFE",
    TAB_ANOMALIES: "ANOM",
    TAB_HEURES: "HEUR",
    TAB_MOUVEMENTS: "MOUV",
    TAB_CREDIT: "CRED",
    TAB_DEMANDES_REGULARISATION: "REGU"
}

export const CODE_ACTION = {
    ACCUEIL: "ACC010000",
    PARAMETRES: "PAR010000",
    CONFIDENTIALITE: "COF010000",
    ABOUT: "ABO010000",
    DOCUMENTS: "DO010014",
    ABSENCES: "AB020002",
    ABSENCES_MANAGER: "AB020003",
    PLANNING: "PL010063",
    DELEGATION: "DE010013",
    ORGANIGRAMME: "OR010065",
    GESTION_TEMPS: "GT020030",
    GESTION_TEMPS_MANAGER: "GT020031",
    ENTRETIENS_MANAGER: "EN020017",
    ENTRETIENS_COLLABORATEUR: "EN020016",
    PERSONNALISER_DASHBOARD: "PER010000",
    POLICE: "PPO010000",
    BACKGROUND: "PBA010000",
    DECONNEXION: "DEC010000"
}

export const LABLE_PAGE = {
    ACC010000: "Accueil",
    PAR010000: "Paramètres",
    COF010000: "Confidentialité",
    ABO010000: "À Propos",
    DO010014: "Mes Documents",
    AB020002: "Mes demandes d'absences",
    AB020003: "Les demandes d'absences de mes collaborateurs",
    PL010063: "Planning",
    DE010013: "Délégation",
    OR010065: "Organigramme",
    GT020030: "Gérer mon temps",
    GT020031: "Gérer le temps de mes collaborateurs",
    PER010000: "Personnaliser",
    PPO010000: "Police",
    PBA010000: "Fond d'écran",
    DEC010000: "Déconnexion"
}