import { useSelector } from "react-redux";
import { Box, Grid, Grow, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { greenColor, whiteColor } from "@styles";
import { DialogFullScreenContainer, HeaderDialogContainer, TextContainer } from "@components/Containers";
import { selectDlgApp } from "@selectors";
import { datify } from "@utils";

const natureRoute = {
    A: "Temps et absences",
    F: "Formation",
    P: "Frais et ordres de mission"
}

const DetailDialogMobile = (props) => {
    const { open, setOpen } = props;
    const stateDelegations = useSelector(selectDlgApp);

    return (
        <DialogFullScreenContainer open={open} taille="lg">
            <HeaderDialogContainer
                title="Détail de la délégation"
                close={() => setOpen(false)}
            />
            <Stack spacing={0.5} height="100%" overflow="auto">
                {stateDelegations.delegationSelected ?
                    <>
                        <Grow in={true} timeout={400}>
                            <Box bgcolor={greenColor["015"]} p={0.5} borderRadius={2}>
                                <Stack spacing={0.5}>
                                    <Box bgcolor={whiteColor[5]} p={1} borderRadius={2}>
                                        <Typography fontSize="small" fontWeight="bold" textAlign="center">Nature de la délégation</Typography>
                                    </Box>
                                    <Box bgcolor={whiteColor[5]} p={1} borderRadius={2}>
                                        <Typography fontSize="small">{natureRoute[stateDelegations.delegationSelected.codeNature]}</Typography>
                                    </Box>
                                </Stack>
                            </Box>
                        </Grow>
                        <Grow in={true} timeout={600}>
                            <Box bgcolor={greenColor["015"]} p={0.5} borderRadius={2}>
                                <Stack spacing={0.5}>
                                    <Box bgcolor={whiteColor[5]} p={1} borderRadius={2}>
                                        <Typography fontSize="small" fontWeight="bold" textAlign="center">Période de la délégation</Typography>
                                    </Box>
                                    <Box bgcolor={whiteColor[5]} p={1} borderRadius={2}>
                                        <Typography fontSize="small">
                                            {stateDelegations.delegationSelected.dateReprise !== "00000000" ? 
                                                `Du ${datify(stateDelegations.delegationSelected.dateDebut, "ddddDDmmmmyyyyw")} au ${datify(stateDelegations.delegationSelected.dateReprise, "ddddDDmmmmyyyyw")}`
                                            :
                                                `À partir du ${datify(stateDelegations.delegationSelected.dateDebut, "ddddDDmmmmyyyyw")}`
                                            } 
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Box>
                        </Grow>
                        <Grow in={true} timeout={800}>
                            <Box bgcolor={greenColor["015"]} p={0.5} borderRadius={2}>
                                <Stack spacing={0.5}>
                                    <Box bgcolor={whiteColor[5]} p={1} borderRadius={2}>
                                        <Typography fontSize="small" fontWeight="bold" textAlign="center">Type de la délégation</Typography>
                                    </Box>
                                    <Box bgcolor={whiteColor[5]} p={0.5} borderRadius={2}>
                                        <Stack spacing={0.5}>
                                            {Number(stateDelegations.delegationSelected.delaiAttente) === 0 &&
                                                <TextContainer bgColor={greenColor["015"]} p={1}>Délégation immédiate</TextContainer>
                                            }
                                            {Number(stateDelegations.delegationSelected.delaiAttente) > 0 &&
                                                <>
                                                    <TextContainer bgColor={greenColor["015"]} p={1}>Délégation après un délai d'attente</TextContainer>
                                                    <TextContainer bgColor={greenColor["015"]} p={1}>Délai d'attente de {stateDelegations.delegationSelected.delaiAttente} jour{Number(stateDelegations.delegationSelected.delaiAttente) > 1 && "s"}</TextContainer>
                                                </>
                                            }
                                            {stateDelegations.delegationSelected.avertissementParMail === "O" &&
                                                <TextContainer bgColor={greenColor["015"]} p={1}>Je souhaite être aussi informé par mail</TextContainer>
                                            }
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Box>
                        </Grow>
                        <Grow in={true} timeout={1000}>
                            <Box bgcolor={greenColor["015"]} p={0.5} borderRadius={2}>
                                <Stack spacing={0.5}>
                                    <Box bgcolor={whiteColor[5]} p={1} borderRadius={2}>
                                        <Typography fontSize="small" fontWeight="bold" textAlign="center">Délégataire</Typography>
                                    </Box>
                                    <Box bgcolor={whiteColor[5]} p={1} borderRadius={2}>
                                        <Typography fontSize="small">{stateDelegations.delegationSelected.delegataireLong}</Typography>
                                    </Box>
                                </Stack>
                            </Box>
                        </Grow>
                    </>
                :
                    <Grid container height={"100%"}>
                        <Grow in={true} timeout={500}>
                            <Box borderRadius={2} bgcolor={greenColor["015"]} width="100%" p={1} display="flex" alignItems="center" justifyContent="center"> 
                                <Typography fontSize="small">Aucune délégation sélectionnée</Typography>
                            </Box>
                        </Grow>
                    </Grid>
                }
            </Stack>
        </DialogFullScreenContainer>
    );
}

DetailDialogMobile.defaultValues = {
    open: false, 
    setOpen: () => {}
}

DetailDialogMobile.propTypes = {
    open: PropTypes.bool, 
    setOpen: PropTypes.func
}

export default DetailDialogMobile;