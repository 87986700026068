import { Box, Stack, Typography } from "@mui/material";
import { Warning } from "@mui/icons-material";
import PropTypes from "prop-types";
import { GlobalContainer } from "@components/Containers";
import { redColor, whiteColor } from "@styles";
import { default as Fr } from 'country-flag-icons/react/3x2/FR';
import { default as Gb } from 'country-flag-icons/react/3x2/GB';
import { default as De } from 'country-flag-icons/react/3x2/DE';
import { default as It } from 'country-flag-icons/react/3x2/IT';
import { default as Nl } from 'country-flag-icons/react/3x2/NL';
import { default as Pt } from 'country-flag-icons/react/3x2/PT';
import { default as Es } from 'country-flag-icons/react/3x2/ES';
import { useTranslation } from "react-i18next";

const GlobalError = (props) => {
    const { error } = props;
    const { t, i18n } = useTranslation("global");

    return(
        <GlobalContainer bgcolor={redColor[7]}>
            <Stack spacing={0.5}>
                <Stack spacing={1} bgcolor={whiteColor[7]} p={1} textAlign="center" sx={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }} display="flex" alignItems="center" justifyContent="center">
                    <Warning fontSize="large" />
                    <Typography fontSize="small" fontWeight="bold">{error}</Typography>
                </Stack>
                <Stack bgcolor={whiteColor[7]} p={1} sx={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }} spacing={1} direction="row" display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Box sx={{ cursor: "pointer" }} height={27} onClick={() => i18n.changeLanguage("fr")}><Fr title={t("langs.fr")} width={40} style={{borderRadius: 8}} /></Box>
                    <Box sx={{ cursor: "pointer" }} height={27} onClick={() => i18n.changeLanguage("en")}><Gb title={t("langs.en")} width={40} style={{borderRadius: 8}} /></Box>
                    <Box sx={{ cursor: "pointer" }} height={27} onClick={() => i18n.changeLanguage("de")}><De title={t("langs.de")} width={40} style={{borderRadius: 8}} /></Box>
                    <Box sx={{ cursor: "pointer" }} height={27} onClick={() => i18n.changeLanguage("pt")}><Pt title={t("langs.pt")} width={40} style={{borderRadius: 8}} /></Box>
                    <Box sx={{ cursor: "pointer" }} height={27} onClick={() => i18n.changeLanguage("nl")}><Nl title={t("langs.nl")} width={40} style={{borderRadius: 8}} /></Box>
                    <Box sx={{ cursor: "pointer" }} height={27} onClick={() => i18n.changeLanguage("it")}><It title={t("langs.it")} width={40} style={{borderRadius: 8}} /></Box>
                    <Box sx={{ cursor: "pointer" }} height={27} onClick={() => i18n.changeLanguage("es")}><Es title={t("langs.es")} width={40} style={{borderRadius: 8}} /></Box>
                </Stack>
            </Stack>
        </GlobalContainer>
    );
}

GlobalError.defaultProps = {
    error: ""
}

GlobalError.propTypes = {
    error: PropTypes.string
}

export default GlobalError;