import { Stack } from "@mui/material";
import { Add, FilterList, FilterListOff, MobiledataOff, Refresh, SwapVert, ViewList, ViewModule } from "@mui/icons-material";
import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { HeaderPanelContainer } from "@components/Containers";
import { whiteColor } from "@styles";
import { useMedia } from "react-use";

const ActionsAffectationsActivitesIndividuelles = (props) => {
    const { 
        readOnly,
        tried,
        filtered,
        listed,
        lister,
        deleteFiltre,
        deleteTrie,
        setOpenAdd,
        setAnchorEl,
        setOpenFiltre,
        refetch
    } = props;
    const isLarge = useMedia('(min-width: 651px)');

    return (
        <HeaderPanelContainer 
            actionsLeft={
                <Stack direction={"row"} spacing={0.5}>
                    {tried ? 
                        <IconButtonBase icon={<MobiledataOff fontSize="small" />} title="Enlever le trie" action={() => deleteTrie()} /> 
                    : 
                        <IconButtonBase icon={<SwapVert fontSize="small" />} title="Trier" action={(e) => setAnchorEl(e.currentTarget)} />
                    }
                    {filtered ? 
                        <IconButtonBase icon={<FilterListOff fontSize="small" />} title="Enlever le filtre"  action={() => deleteFiltre()} /> 
                    : 
                        <IconButtonBase title="Filtrer" icon={<FilterList fontSize="small" />} action={() => setOpenFiltre(true)} />
                    }
                    {!readOnly &&
                        <IconButtonBase icon={<Add fontSize="small" />} title="Ajouter une affectation" action={() => setOpenAdd(true)} />
                    }
                </Stack> 
            }
            actionsRight={
                <Stack direction={"row"} spacing={0.5} justifyContent={"right"}>
                    {isLarge && <IconButtonBase icon={listed ? <ViewModule fontSize="small" /> : <ViewList fontSize="small" />} title={listed ? "Panneaux" : "Liste"} action={() => lister()} />}
                    <IconButtonBase icon={<Refresh fontSize="small" />} title="Rafraichir" action={() => refetch()} />
                </Stack>
            }
            bgColor={whiteColor[5]}
        />
    )
}

ActionsAffectationsActivitesIndividuelles.defaultProps = { 
    readOnly: true, 
    tried: false,
    filtered: false,
    listed: false,
    lister: () => {},
    deleteFiltre: () => {},
    deleteTrie: () => {},
    setOpenAdd: () => {}, 
    setAnchorEl: () => {}, 
    setOpenFiltre: () => {}, 
    refetch: () => {}
}

ActionsAffectationsActivitesIndividuelles.propTypes = {
    readOnly: PropTypes.bool, 
    tried: PropTypes.bool,
    filtered: PropTypes.bool,
    listed: PropTypes.bool,
    lister: PropTypes.func,
    deleteFiltre: PropTypes.func,
    deleteTrie: PropTypes.func,
    setOpenAdd: PropTypes.func, 
    setAnchorEl: PropTypes.func, 
    setOpenFiltre: PropTypes.func, 
    refetch: PropTypes.func
}

export default ActionsAffectationsActivitesIndividuelles;