import axios from "axios";
import { urls } from "@constants/appConstants";

/**
 * * Appel de la procédure PWACAL
 * * Url : get/calendrier
 * @param {*} matricule Matricule de l'utilisateur
 * @param {*} date Date du calendrier
 * @param {*} client 
 * @param {*} signal 
 * @returns Semaines du calendrier
 */
export const fetchCal = async (matricule, date, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetCalendrier"
    };
    const body = {
        matricule: matricule, 
        date: date
    };
    const response = await axios.post(urls.getCalendrier, body, { headers: headers, params: { client: client}, signal: signal });
    return response.data.semaines;
}