import { Box, Stack } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { useStateTemGesTem } from "@hooks/gestionTemps/temps/hooksStates";
import { TempsItemsTable, TempsHeaderTable, TempsFooterTable } from "@components/GestionTemps/Temps/Tables";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor } from "@styles";

const TempsTable = (props) => {  
    const { isLoading, userType, openTransactions, openRegularisations, openJour } = props;
    const stateTemps = useStateTemGesTem(userType);
    
    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    return (
        stateTemps?.semaine?.length > 0 &&
            <Stack spacing={0.5} height={"100%"}>
                <Box bgcolor={greenColor["015"]} borderRadius={2} p={0.25}>
                    <TempsHeaderTable userType={userType} />
                </Box>         
                <PerfectScrollbar>      
                    <Stack spacing={0.5} height={"100%"}>
                        <TempsItemsTable userType={userType} openTransactions={openTransactions} openRegularisations={openRegularisations} openJour={openJour} />
                        <Box bgcolor={greenColor["015"]} borderRadius={2} p={0.25}>
                            <TempsFooterTable userType={userType} />
                        </Box>
                    </Stack>
                </PerfectScrollbar>
            </Stack>
    );
}

TempsTable.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    userType: PropTypes.string.isRequired,
    openTransactions: PropTypes.func.isRequired,
    openRegularisations: PropTypes.func.isRequired,
    openJour: PropTypes.func.isRequired
}

const styles = {
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        height: "100%",
    },
};

export default TempsTable; 