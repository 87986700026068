import { useSelector } from "react-redux";
import { selectUsrApp } from "@selectors";
import { DialogContainer } from "@components/Containers";
import { QuestionPasswordLoginForm } from "../Forms";

const QuestionPasswordLoginDialog = () => {
    const stateUser = useSelector(selectUsrApp);

    return (
        <DialogContainer open={stateUser.openQuestionPassword} placement="top" taille="sm">
            <QuestionPasswordLoginForm />
        </DialogContainer>
    );
}

export default QuestionPasswordLoginDialog;