import { useEffect, useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { TextContainer, TextTooltipContainer } from "@components/Containers";
import { redColor, whiteColor, yellowColor } from "@styles";
import { useStatePlanning } from "@hooks/planning/hooksStates";
import { useTranslation } from "react-i18next";

const verifyEffectif = (seuil, effectif, we = false) => {
    let statut = {bgColor: whiteColor[5], message: ""};
    if(seuil === effectif) {
        statut.bgColor = yellowColor[2];
        statut.message = "Effectif minimum atteint";
    } else if(seuil > effectif && !we) {
        statut.bgColor = redColor[1];
        statut.message = "Effectif minimum dépassé";
    } 
    return statut;
};

const TotauxMobile = (props) => {
    const { type } = props;
    const [effectifs, setEffectifs] = useState([]);
    const statePlanning = useStatePlanning(type);
    const { t } = useTranslation("global");

    useEffect(() => {
        if(statePlanning?.totaux) {
            if(statePlanning.allServices) {
                setEffectifs(statePlanning.totaux.effectifDisponible.map((effectifDisponible, id) => {
                    return {id: id, content: effectifDisponible, statut: {bgColor: whiteColor[5], message: ""}};
                })); 
            } else {
                setEffectifs(statePlanning.totaux.effectifDisponibleUnService.map((effectifDisponibleUnService, id) => {
                    if(id < 5) {
                        return {id: id, content: `${effectifDisponibleUnService}/${statePlanning.totaux.seuilContrainteAbsence}`, statut: verifyEffectif(statePlanning.totaux.seuilContrainteAbsence, effectifDisponibleUnService)}
                    } else {
                        return {id: id, content: `${effectifDisponibleUnService}/${statePlanning.totaux.seuilContrainteAbsence}`, statut: verifyEffectif(statePlanning.totaux.seuilContrainteAbsence, effectifDisponibleUnService, true)}
                    }
                })); 
            }
        }
    }, [statePlanning.totaux, statePlanning.allServices]);

    
    return (  
        <Box>
            {statePlanning?.totaux && 
                <Stack spacing={0.5}>
                    <TextContainer bgColor={whiteColor[5]} p={1} height={"100%"} verticalAlign="center" textSize="small" textWeight="bold">
                        {t("texts.available_staff")}
                    </TextContainer>
                    <Box>
                        <Grid container spacing={0.5}>
                            {effectifs.map((effectif) => (
                                <Grid item xs={1.5} key={"EFF"+effectif.id}>
                                    <TextTooltipContainer tooltipTitle={effectif.statut.message} tooltipPlacement="top" bgColor={effectif.statut.bgColor} p={1} height="100%" verticalAlign="center" horizontalAlign="center" textSize="small" textWeight="bold">
                                        {effectif.content}
                                    </TextTooltipContainer>
                                </Grid>
                            ))}
                            <Grid item xs={1.5}></Grid>
                        </Grid>
                    </Box>
                </Stack>
            }
        </Box>
    );
}

TotauxMobile.defaultProps = {
    type: ""
}

TotauxMobile.propTypes = {
    type: PropTypes.string
}

export default TotauxMobile;