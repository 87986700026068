import { Box, Grid, Stack } from "@mui/material";
import { ListAlt } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { DialogContainer, HeaderDialogContainer, HeaderListContainer, HeaderPanelContainer, ListContainer, TextContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { greenColor, redColor, whiteColor } from "@styles";

const headersAnomalies = [
    {id: 1, label: "Code", xs: 2, display: true},
    {id: 2, label: "Désignation", xs: 8, display: true},
    {id: 3, label: "Gravité", xs: 2, display: true},
];

const headersTransaction = [
    {id: 1, label: "Mouvement", xs: 1.5, display: true},
    {id: 2, label: "Date", xs: 1.5, display: true},
    {id: 3, label: "Heure Réelle", xs: 1.5, display: true},
    {id: 4, label: "Heure Ajustée", xs: 1.5, display: true},
    {id: 5, label: "Lecteur", xs: 2, display: true},
    {id: 6, label: "Activité", xs: 2, display: true},
    {id: 7, label: "Type Travail", xs: 2, display: true}
];

const AnomaliesTransactionsDialog = (props) => {
    const { open, setOpen, transaction, openAllAnomalies } = props;

    return (
        <DialogContainer open={open} placement="top" taille="md">
            <HeaderDialogContainer 
                actionsLeft={
                    <IconButtonBase icon={<ListAlt fontSize="small" />} title="Afficher la liste complète des anomalies" action={openAllAnomalies} />
                }
                title="Anomalie(s)"
                close={() => setOpen(false)}
            />
            {transaction && 
                <>
                    <Stack spacing={0.5} bgcolor={greenColor["015"]} p={0.5} borderRadius={2}>
                        <HeaderPanelContainer bgColor={whiteColor[5]} title="Transaction" />
                        <HeaderListContainer itemIdReference="HISTOANOGT1" headerItems={headersTransaction} />
                        <ListContainer spacing={0.5} length={1}>
                            <Box bgcolor={greenColor["015"]} borderRadius={2} p={0.25}>
                                <Grid container spacing={0.5}>
                                    <Grid item xs={1.5}>
                                        <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{transaction.libelleMouvement}</TextContainer>
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{moment([transaction.anneeMouvement, transaction.moisMouvement - 1, transaction.jourMouvement]).format("DD/MM/YYYY")}</TextContainer>
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{moment(transaction.heureMouvement, "H").format("HH")}h{moment(transaction.minuteMouvement, "m").format("mm")}</TextContainer>
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{moment(transaction.heureMouvementAjustee, "H").format("HH")}h{moment(transaction.minuteMouvementAjustee, "m").format("mm")}</TextContainer>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{transaction.lecteurPresence}</TextContainer>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{transaction.libelleActivite}</TextContainer>
                                    </Grid> 
                                    <Grid item xs={2}>
                                        <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{transaction.libelleTypeTravail}</TextContainer>
                                    </Grid>
                                </Grid>
                            </Box>
                        </ListContainer>
                    </Stack>
                    <Stack spacing={0.5} bgcolor={greenColor["015"]} p={0.5} borderRadius={2}>
                        <HeaderPanelContainer bgColor={whiteColor[5]} title="Anomalie(s)" />
                        <HeaderListContainer itemIdReference="HISTOANOGT2" headerItems={headersAnomalies} />
                        <ListContainer spacing={0.5} length={transaction.anomalies.length} noDataLabel="Aucune anomalie">
                            {transaction.anomalies.map((anomalie) => (
                                <Box key={`ANO${anomalie.code}`} bgcolor={redColor[1]} borderRadius={2} p={0.25}>
                                    <Grid container spacing={0.5}>
                                        <Grid item xs={2}>
                                            <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold">{anomalie.codeAnomalie}</TextContainer>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold">{anomalie.libelleAnomalie}</TextContainer>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold">{anomalie.gravite}</TextContainer>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))}
                        </ListContainer>
                    </Stack>
                </>
            }
        </DialogContainer>
    );
}

AnomaliesTransactionsDialog.defaultProps = {
    transaction: null
}

AnomaliesTransactionsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    transaction: PropTypes.object,
    openAllAnomalies: PropTypes.func.isRequired
}

export default AnomaliesTransactionsDialog;