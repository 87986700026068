import { validateDate } from "@validation";
import Joi from "joi";

export const schemaFilterDemandeAbsence = Joi.object({
    colonne: Joi.string().required().messages({ "string.empty": "Vous devez choisir un colonne pour filtrer" }),
    operateur: Joi
        .when("colonne", {is: "dateDemande", then: Joi.string()})
        .when("colonne", {is: "dateAbsence", then: Joi.string()}),
    date: Joi
        .when("colonne", {is: "dateDemande", then: validateDate.dateMoment().valide().messages({ "dateMoment.valide": "Date invalide" })})
        .when("colonne", {is: "dateAbsence", then: validateDate.dateMoment().valide().messages({ "dateMoment.valide": "Date invalide" })}),
    txt: Joi
        .when("colonne", {is: "type", then: Joi.string().required().messages({ "string.empty": "Vous devez choisir un type pour filtrer" })})
        .when("colonne", {is: "nature", then: Joi.string().required().messages({ "string.empty": "Vous devez choisir une valeur pour filtrer" })})
        .when("colonne", {is: "statut", then: Joi.string().required().messages({ "string.empty": "Vous devez choisir une valeur pour filtrer" })})
});

