import { Box, Stack } from "@mui/material";
import { DeleteOutline, Save } from "@mui/icons-material";
import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { Loader } from "@components/Loaders";
import { DialogContainer, HeaderDialogContainer, TextContainer } from "@components/Containers";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";

const AnomaliesReponseTransactionsDialog = (props) => {
    const { open, setOpen, isLoading, postReponseWithAnomalies } = props;
    
    return (
        <DialogContainer open={open} taille="sm">
            <HeaderDialogContainer  
                actionsLeft={
                    <Stack direction={"row"} spacing={0.5}>
                        <IconButtonBase icon={<DeleteOutline fontSize="small" />} title="Oui" disabled={isLoading} action={() => postReponseWithAnomalies("4")} />
                        <IconButtonBase icon={<Save fontSize="small" />} title="Non" disabled={isLoading} action={() => postReponseWithAnomalies("5")} />
                    </Stack>    
                }
                title="Transactions en anomalie"
                close={() => setOpen(false)}
            />
            {isLoading ?
                <Box sx={styles.loaderStyle}>
                    <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                </Box> 
            :
                <Stack spacing={0.5} bgcolor={redColor[1]} borderRadius={2} p={0.5}>
                    <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold">Les transactions de la journée comportent des anomalies.</TextContainer>
                    <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold">Voulez-vous les supprimer ?</TextContainer>
                </Stack>
            }
        </DialogContainer>
    );
}

AnomaliesReponseTransactionsDialog.defaultProps = {
    open: false,
    isLoading: false,
    setOpen: () => {},
    postReponseWithAnomalies: () => {}
}

AnomaliesReponseTransactionsDialog.propTypes = {
    open: PropTypes.bool,
    isLoading: PropTypes.bool,
    setOpen: PropTypes.func,
    postReponseWithAnomalies: PropTypes.func
}

const styles = {
    loaderStyle: {
        height: 100,
        alignItems: "center",
        display: "flex"
    }
}

export default AnomaliesReponseTransactionsDialog;