import { Stack } from "@mui/material";
import { Event, FilterList, MobiledataOff, QueryBuilder, SwapVert } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useResetTrieCollaborateurs, useSelectDateCollaborateurs } from "@hooks/collaborateurs/hooksUtils";
import { IconButtonBase, WeekPickerLabelizedButtonMobile } from "@components/Buttons";
import { HeaderDialogContainer } from "@components/Containers";
import { greenColor } from "@styles";

const CollaborateursFwgtp3ActionsMobile = (props) => {
    const { 
        stateType, 
        dateValidite, 
        isLoading, 
        tried, 
        setOpenFiltre,  
        setOpenTri,  
        setHoraire,
        setOpen
    } = props;
    const resetTrie = useResetTrieCollaborateurs(stateType);
    const selectDate = useSelectDateCollaborateurs(stateType);

    return (
        <>
            <HeaderDialogContainer 
                actionsLeft={
                    <Stack direction={"row"} spacing={0.5}>
                        {tried ? 
                            <IconButtonBase icon={<MobiledataOff fontSize="small" />} action={resetTrie} /> 
                        : 
                            <IconButtonBase icon={<SwapVert fontSize="small" />} action={() => setOpenTri(true)} />
                        }
                        <IconButtonBase icon={<FilterList fontSize="small" />} action={() => setOpenFiltre((prevOpen) => !prevOpen)} />
                        <IconButtonBase icon={<QueryBuilder fontSize="small" />} action={() => setHoraire((prevOpen) => !prevOpen)} />
                    </Stack>
                }
                p={1}
                close={() => setOpen(false)}
            />
            <Stack p={1} bgcolor={greenColor["015"]} borderRadius={2}>
                <WeekPickerLabelizedButtonMobile 
                    icon={<Event fontSize="small" />} 
                    timeout={600} 
                    noClick={isLoading} 
                    date={dateValidite} 
                    action={selectDate} 
                />
            </Stack>
        </>
    );
}

CollaborateursFwgtp3ActionsMobile.defaultProps = {
    setHoraire: () => {}
}

CollaborateursFwgtp3ActionsMobile.propTypes = {
    stateType: PropTypes.string.isRequired,
    dateValidite: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    isLoading: PropTypes.bool.isRequired,
    tried: PropTypes.bool.isRequired,
    setOpenTri: PropTypes.func.isRequired,
    setOpenFiltre: PropTypes.func.isRequired,
    setHoraire: PropTypes.func,
    setOpen: PropTypes.func.isRequired,
}

export default CollaborateursFwgtp3ActionsMobile;