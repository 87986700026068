import { ROLE_UTILISATEUR } from "@constants/userConstants";
import { createSlice } from "@reduxjs/toolkit";
import { filter, tidy } from "@tidyjs/tidy";

const initialState = {
    dateDemandeRegularisation: "",
    activites: [],
    typesTravail: [],
    transactions: [],
    regularisations: [],
    suivi: [],
    listed: false,
    filtered: false,
    tried: false,
    colonneTrie: "",
    all: false,
    demandesRegularisation: [],
    demandesRegularisationDisplayed: [],
    demandeRegularisationSelected: null,
    autoriserTypesTravail: false
};

const drgGesTemSlice = createSlice({
    name: 'drgGesTem',
    initialState: initialState,
    reducers: {
        setDateDemandeRegularisation: (state, action) => {
            state.dateDemandeRegularisation = action.payload;
        },
        setDetailRegularisation: (state, action) => {
            state.transactions = action.payload.transactions;
            state.regularisations = action.payload.regularisations;
            state.suivi = action.payload.suivi;
        },
        setDemandesRegularisation: (state, action) => {
            if(action.payload.userType === ROLE_UTILISATEUR.COLLABORATEUR) {
                state.demandesRegularisation = action.payload.demandesRegularisation;
                state.demandesRegularisationDisplayed = action.payload.demandesRegularisation;
            }
            if(action.payload.userType === ROLE_UTILISATEUR.MANAGER) {
                const result = state.all ? action.payload.demandesRegularisation : tidy(action.payload.demandesRegularisation, filter((demande) => demande.reponse === ""));
                state.demandesRegularisation = action.payload.demandesRegularisation;
                state.demandesRegularisationDisplayed = result;
            }
        },
        lister: (state) => {
            state.listed = !state.listed;
        },
        filtrerParReponse: (state) => {
            const result = state.all ? tidy(state.demandesRegularisation, filter((demande) => demande.reponse === "")) : state.demandesRegularisation;
            state.demandesRegularisationDisplayed = result;
            state.all = !state.all
            state.filtered = false;
            state.tried = false;
        },
        filtrer: (state, action) => {
            state.demandesRegularisationDisplayed = action.payload;
            state.filtered = true;
        },
        deleteFiltre: (state, action) => {
            if(action.payload === ROLE_UTILISATEUR.COLLABORATEUR) {
                state.demandesRegularisationDisplayed = state.demandesRegularisation
                state.filtered = false
            }
            if(action.payload === ROLE_UTILISATEUR.MANAGER) {
                const result = state.all ? state.demandesRegularisation : tidy(state.demandesRegularisation, filter((demande) => demande.reponse === ""));
                state.demandesRegularisationDisplayed = result;
                state.filtered = false;
            }
        },
        trier: (state, action) => {
            state.demandesRegularisationDisplayed = action.payload.demandes;
            state.tried = true;
            state.colonneTrie = action.payload.colonne;
        },
        deleteTrie: (state, action) => {
            if(action.payload === ROLE_UTILISATEUR.COLLABORATEUR) {
                state.demandesRegularisationDisplayed = state.demandesRegularisation;
            }
            if(action.payload === ROLE_UTILISATEUR.MANAGER) {
                const result = state.all ? state.demandesRegularisation : tidy(state.demandesRegularisation, filter((demande) => demande.reponse === ""));
                state.demandesRegularisationDisplayed = result;
            }
            state.tried = false;
            state.colonneTrie = "";
        },
        selectDemandeRegularisation: (state, action) => {
            state.demandeRegularisationSelected = action.payload;
        },
        setActivites: (state, action) => {
            state.activites = action.payload;
        },
        setTypesTravail: (state, action) => {
            state.typesTravail = action.payload.typesTravail;
            state.autoriserTypesTravail = action.payload.authoriser;
        },
        reset: () => initialState
    }
})

const { actions, reducer } = drgGesTemSlice
export const { 
    setDateDemandeRegularisation,
    setDetailRegularisation,
    setDemandesRegularisation,
    lister,
    filtrerParReponse,
    filtrer,
    deleteFiltre,
    trier,
    deleteTrie,
    selectDemandeRegularisation,
    setActivites,
    setTypesTravail,
    reset 
} = actions
export default reducer
