import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import moment from "moment";
import PropTypes from "prop-types";
import * as dlgAppActions from "@reducers/delegations/dlgAppReducer"
import { selectDlgApp } from "@selectors";
import { DateHookFormInput, SelectHookFormInput } from "@components/Inputs";
import { schemaFiltreDelegations } from "@components/Delegations/validations";
import { filtrerDlgApp } from "@components/Delegations/filtres";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";

const colonneOptions = [
    { id: 1, value: "nature", label: "Nature" },
    { id: 2, value: "dateDebut", label: "Date de début" },
    { id: 3, value: "dateReprise", label: "Date de fin" },
    { id: 4, value: "delegataire", label: "Délégataire" },
]

const operateurOptions = [
    { id: 1, value: "egale", label: "Egale" },
    { id: 2, value: "superieur", label: "Supérieure" },
    { id: 3, value: "inferieur", label: "Inférieure" }
]

const optionsNature = [
    { id: 1, value: "A", label: "Temps et absences" },
    { id: 2, value: "F", label: "Formation" },
    { id: 3, value: "P", label: "Frais et ordre de mission" },
]

const defaultValues = {
    colonne: "",
    operateur: "egale",
    date: null,
    txt: ""
}

const FiltreDelegationsForm = ({
    setOpen = () => {}
}) => {
    const [delegataires, setDelegataires] = useState([]);
    const stateDelegations = useSelector(selectDlgApp);
    const methods = useForm({ mode: "all", defaultValues: defaultValues, resolver: joiResolver(schemaFiltreDelegations) });
    const colonne = methods.watch("colonne");
    const dispatch = useDispatch();

    useEffect(() => {
        if(colonne === "dateDebut" || colonne === "dateReprise") {
            methods.resetField("date", moment());
        }
        if(colonne === "nature" || colonne === "delegataire") {
            methods.resetField("txt", "");
        }
    }, [colonne, methods]);

    useEffect(() => {
        const delegatairesLong = [...new Set(stateDelegations.delegations.map((delegataire) => (JSON.stringify({id: delegataire.delegataire, label: delegataire.delegataireLong}))))];
        setDelegataires(delegatairesLong.map((delegataire) => (JSON.parse(delegataire))));
    }, [stateDelegations.delegations]);

    const filtrer = useCallback((data) => {
        setOpen(false);
        const delegationsFiltered = filtrerDlgApp(data, stateDelegations.delegations);
        dispatch(dlgAppActions.filtrer(delegationsFiltered));
    }, [dispatch, setOpen, stateDelegations.delegations]); 
    
    return (
        <FormProvider {...methods}>
            <Stack spacing={1} width="100%">
                <SelectHookFormInput name="colonne" label="Colonnes" options={colonneOptions} />
                {{
                    dateDebut: 
                        <>
                            <SelectHookFormInput name="operateur" label="Opérateur" options={operateurOptions} />
                            <DateHookFormInput name="date" label="Date de début de délégation" />
                        </>,
                        dateReprise: 
                        <>
                            <SelectHookFormInput name="operateur" label="Opérateur" options={operateurOptions} />
                            <DateHookFormInput name="date" label="Date de fin de délégation" />
                        </>,
                    nature: <SelectHookFormInput name="txt" label="Nature" options={optionsNature} />,
                    delegataire: <SelectHookFormInput name="txt" label="Emetteur" options={delegataires} />
                }[colonne]}
            </Stack>
            <Box sx={styles.boxButtonsMenuFiltre}>
                <Stack direction="row" spacing={1}>
                    <Tooltip title="Valider">
                        <IconButton sx={styles.buttonStyleGreen} onClick={() => methods.handleSubmit(filtrer)()}>
                            <Check fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Fermer">
                        <IconButton sx={styles.buttonStyleRed} onClick={() => setOpen(false)}>
                            <Close fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Box>
        </FormProvider>
    );
}

FiltreDelegationsForm.propTypes = {
    setOpen: PropTypes.func
}

const styles = {
    boxMenuFiltre: {
        backgroundColor: whiteColor[9],
        borderRadius: 2,
        display: "flex",
        justifyContent: "center",
        width: "100%"
    },
    subBoxMenuFiltre: {
        padding: 2, 
        width: "50%"
    },
    paperStyle: {
        sx: {
            backgroundColor: "rgba(0,0,0,0)", 
            boxShadow: "none",
            padding: 1
        }
    },
    skeletonStyle: {
        borderRadius: 2
    },
    boxButtonsMenuFiltre: {
        justifyContent: "center",
        display: "flex"
    },
    buttonStyleGreen: {
        boxShadow: "none", 
        backgroundColor: greenColor["02"],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: greenColor["03"],
            color: whiteColor[9],
        }
    },
    buttonStyleRed: {
        boxShadow: "none", 
        backgroundColor: redColor[1],
        color: greyColor[5],
        "&:hover": {
            backgroundColor: redColor[2],
            color: whiteColor[9],
        }
    }
}

export default FiltreDelegationsForm;