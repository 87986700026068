import { useSelector } from "react-redux";
import { Box, IconButton } from "@mui/material";
import { DangerousRounded, Loop } from "@mui/icons-material";
import PropTypes from "prop-types";
import { selectStaUsr } from "@selectors";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, redColor } from "@styles";

const ActivitesShortcut = (props) => {
    const { 
        isLoadingStatut, 
        isErrorStatut,
        setOpenActivitesDialog
    } = props;
    const stateStatut = useSelector(selectStaUsr);

    if(isLoadingStatut) {
        return (
            <IconButton sx={{bgcolor: greenColor["015"]}}>
                <Box width={24} height={24}>
                    <Loader size={24} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                </Box>
            </IconButton>
        );
    }

    if(isErrorStatut) {
        return (
            <IconButton sx={{bgcolor: redColor[1]}}>
                <DangerousRounded />
            </IconButton>
        );
    }

    if(!stateStatut.statut) {
        return (
            <IconButton sx={{bgcolor: redColor[1]}}>
                <DangerousRounded />
            </IconButton>
        );
    }

    return (
        <IconButton sx={{bgcolor: greenColor["015"]}} onClick={() => setOpenActivitesDialog(true)}>
            <Loop />
        </IconButton> 
    );
}

ActivitesShortcut.defaultProps = {
    isLoadingStatut: true, 
    isErrorStatut: false,
    setOpenActivitesDialog: () => {}
}

ActivitesShortcut.propTypes = {
    isLoadingStatut: PropTypes.bool, 
    isErrorStatut: PropTypes.bool,
    setOpenActivitesDialog: PropTypes.func
}

export default ActivitesShortcut;