import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useStateTemGesTem } from "@hooks/gestionTemps/temps/hooksStates";
import { chiffreUSVersChiffreFR, heurify } from "@utils";
import { greenColor } from "@styles";

const TempsFooterTable = (props) => {
    const { userType } = props;
    const stateTemps = useStateTemGesTem(userType);

    return (
        <Grid container spacing={0.5}>
            <Grid item xs={5}></Grid>
            <Grid item xs={2.5}>
                <Box bgcolor={greenColor["015"]} borderRadius={2} p={1} minHeight="calc(35.5px - 16px)" textAlign="center">
                    <Grid container>    
                        <Grid item xs={6}>
                            <Typography fontSize={"small"} fontWeight="bold">{stateTemps.horaire ? heurify(stateTemps?.totaux?.heuresJournee?.badgees?.heure, "h", false) : chiffreUSVersChiffreFR(stateTemps?.totaux?.heuresJournee?.badgees?.chiffre)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontSize={"small"} fontWeight="bold">{stateTemps.horaire ? heurify(stateTemps?.totaux?.heuresJournee?.payees?.heure, "h", false) : chiffreUSVersChiffreFR(stateTemps?.totaux?.heuresJournee?.payees?.chiffre)}</Typography>
                        </Grid>
                    </Grid>                   
                </Box>
            </Grid>
            <Grid item xs={2.5}>
                <Box bgcolor={greenColor["015"]} borderRadius={2} p={1} minHeight="calc(35.5px - 16px)" textAlign="center">
                    <Grid container>        
                        <Grid item xs={6}>
                            <Typography fontSize={"small"} fontWeight="bold">{stateTemps.horaire ? heurify(stateTemps?.totaux?.heuresNuit?.badgees?.heure, "h", false) : chiffreUSVersChiffreFR(stateTemps?.totaux?.heuresNuit?.badgees?.chiffre)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontSize={"small"} fontWeight="bold">{stateTemps.horaire ? heurify(stateTemps?.totaux?.heuresNuit?.payees?.heure, "h", false) : chiffreUSVersChiffreFR(stateTemps?.totaux?.heuresNuit?.payees?.chiffre)}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
}

TempsFooterTable.propTypes = {
    userType: PropTypes.string.isRequired
}

export default TempsFooterTable;