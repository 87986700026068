import { useSelector } from "react-redux";
import { selectTabApp } from "@selectors";
import Dashboard from "@views/Dashboard";
import Parametres from "@views/Parametres";
import Confidentiality from "@views/Confidentiality";
import About from "@views/About";
import AbsencesCollaborateur from "@views/GestionAbsences/Collaborateur";
import AbsencesManager from "@views/GestionAbsences/Manager";
import Planning from "@views/Planning";
import Delegation from "@views/Delegations";
import Organigramme from "@views/Organigramme";
import GestionTempsCollaborateur from "@views/GestionTemps/Collaborateur";
import Documents from "@views/Documents";
import GestionTempsManager from "@views/GestionTemps/Manager";
import { TabContentContainer } from "@components/Containers";
import { CODE_ACTION } from "@constants/tabsConstants";

const AppTabsMobileContent = () => {
    const stateTabs = useSelector(selectTabApp);

    return(
        <>
            <TabContentContainer value={stateTabs.activeTab} id={CODE_ACTION.ACCUEIL}>  
                <Dashboard mobile={true} />
            </TabContentContainer>
            <TabContentContainer value={stateTabs.activeTab} id={CODE_ACTION.PARAMETRES}>
                <Parametres mobile={true} />
            </TabContentContainer>
            <TabContentContainer value={stateTabs.activeTab} id={CODE_ACTION.CONFIDENTIALITE}>
                <Confidentiality />
            </TabContentContainer>
            <TabContentContainer value={stateTabs.activeTab} id={CODE_ACTION.ABOUT}>
                <About />
            </TabContentContainer>
            <TabContentContainer value={stateTabs.activeTab} id={CODE_ACTION.DOCUMENTS}>
                <Documents mobile={true} />
            </TabContentContainer>
            <TabContentContainer value={stateTabs.activeTab} id={CODE_ACTION.ABSENCES}>
                <AbsencesCollaborateur />
            </TabContentContainer>
            <TabContentContainer value={stateTabs.activeTab} id={CODE_ACTION.ABSENCES_MANAGER}>
                <AbsencesManager />
            </TabContentContainer>
            <TabContentContainer value={stateTabs.activeTab} id={CODE_ACTION.PLANNING}>
                <Planning />
            </TabContentContainer>
            <TabContentContainer value={stateTabs.activeTab} id={CODE_ACTION.DELEGATION}>
                <Delegation />
            </TabContentContainer>
            <TabContentContainer value={stateTabs.activeTab} id={CODE_ACTION.ORGANIGRAMME}>
                <Organigramme />
            </TabContentContainer>
            <TabContentContainer value={stateTabs.activeTab} id={CODE_ACTION.GESTION_TEMPS}>
                <GestionTempsCollaborateur />
            </TabContentContainer>
            <TabContentContainer value={stateTabs.activeTab} id={CODE_ACTION.GESTION_TEMPS_MANAGER}>
                <GestionTempsManager />
            </TabContentContainer>
        </>
    );
}

export default AppTabsMobileContent;