import { combineReducers, configureStore } from '@reduxjs/toolkit';
import usrAppReducer from "../reducers/user/usrAppReducer";
import cltAppReducer from "../reducers/client/cltAppReducer";
import secVarAppReducer from "../reducers/secureVariables/secVarAppReducer";
import dlgAppReducer from "../reducers/delegations/dlgAppReducer";
import staUsrReducer from "../reducers/statut/staUsrReducer";
import temGesTemColReducer from "../reducers/gestionTemps/temps/temGesTemColReducer";
import temGesTemManReducer from "../reducers/gestionTemps/temps/temGesTemManReducer";
import plaDabColReducer from "../reducers/planning/plaDabColReducer";
import plaDabManReducer from "../reducers/planning/plaDabManReducer";
import plaGloReducer from "../reducers/planning/plaGloReducer";
import plaHrcReducer from "../reducers/planning/plaHrcReducer";
import plaGesTemReducer from "../reducers/gestionTemps/planning/plaGesTemReducer";
import dshReducer from "../reducers/dashboard/dshReducer";
import plaHorReducer from "../reducers/plagesHoraires/plaHorReducer";
import dabGesAbsColReducer from "../reducers/gestionAbsences/demandes/dabGesAbsColReducer";
import dabGesAbsManReducer from "../reducers/gestionAbsences/demandes/dabGesAbsManReducer";
import calGesAbsReducer from "../reducers/gestionAbsences/calendrier/calGesAbsReducer";
import solGesAbsReducer from "../reducers/soldes/solGesAbsReducer";
import sitSolAppReducer from "../reducers/soldes/sitSolAppReducer";
import hisDabGesAbsReducer from "../reducers/gestionAbsences/historique/hisDabGesAbsReducer";
import docAppReducer from "../reducers/documents/docAppReducer";
import tabAppReducer from "../reducers/tabs/tabAppReducer";
import orgAppReducer from "../reducers/organigramme/orgAppReducer";
import drgGesTemReducer from "../reducers/gestionTemps/regularisations/drgGesTemReducer";
import regGesTemReducer from "../reducers/gestionTemps/regularisations/regGesTemReducer";
import comGesTemReducer from "../reducers/gestionTemps/compteurs/comGesTemReducer";
import creGesTemReducer from "../reducers/gestionTemps/credits/creGesTemReducer";
import mvtGesTemReducer from "../reducers/gestionTemps/mouvements/mvtGesTemReducer";
import colTemGesTemReducer from "../reducers/collaborateurs/colTemGesTemReducer";
import colPlaGesTemReducer from "../reducers/collaborateurs/colPlaGesTemReducer";
import colAffHorIndReducer from "../reducers/collaborateurs/colAffHorIndReducer";
import colAffHorColReducer from "../reducers/collaborateurs/colAffHorColReducer";
import colAffActIndReducer from "../reducers/collaborateurs/colAffActIndReducer";
import colAffActColReducer from "../reducers/collaborateurs/colAffActColReducer";
import colCreGesTemReducer from "../reducers/collaborateurs/colCreGesTemReducer";
import colMvtGesTemReducer from "../reducers/collaborateurs/colMvtGesTemReducer";
import entManReducer from "../reducers/gestionEntretiens/entManReducer";
import entColReducer from "../reducers/gestionEntretiens/entColReducer";
import entTabManReducer from "../reducers/gestionEntretiens/entTabManReducer";
import entTabColReducer from "../reducers/gestionEntretiens/entTabColReducer";
import entSecManReducer from "../reducers/gestionEntretiens/entSecManReducer";
import entSecColReducer from "../reducers/gestionEntretiens/entSecColReducer";
import actPoiGesTemReducer from "../reducers/activites/actPoiGesTemReducer";
import actAffGesTemReducer from "../reducers/activites/actAffGesTemReducer";
import typTraAppReducer from "../reducers/typesTravail/typTraAppReducer";
import anoGesTemReducer from "../reducers/gestionTemps/anomalies/anoGesTemReducer";
import affGesTemReducer from "../reducers/gestionTemps/affectations/affGesTemReducer";
import affHorIndReducer from "../reducers/gestionTemps/affectations/affHorIndReducer";
import affActIndReducer from "../reducers/gestionTemps/affectations/affActIndReducer";
import socAffHorColReducer from "../reducers/societes/socAffHorColReducer";
import socAffActColReducer from "../reducers/societes/socAffActColReducer";
import horAffGesTemReducer from "../reducers/gestionTemps/horaires/horAffGesTemReducer";
import socOrgReducer from "../reducers/societes/socOrgReducer";
import heuGesTemReducer from "../reducers/gestionTemps/heures/heuGesTemReducer";
import notAppReducer from "../reducers/notifications/notAppReducer";
import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
    key: "root",
    storage: storageSession,
    whitelist: ["user", "tabs", "typesTravail"]
}

const appReducers = combineReducers({
    usrApp: usrAppReducer,
    cltApp: cltAppReducer,
    secVarApp: secVarAppReducer,
    dlgApp: dlgAppReducer,
    staUsr: staUsrReducer,
    temGesTemCol: temGesTemColReducer,
    temGesTemMan: temGesTemManReducer,
    plaDabCol: plaDabColReducer,
    plaDabMan: plaDabManReducer,
    plaGlo: plaGloReducer,
    plaHrc: plaHrcReducer,
    plaGesTem: plaGesTemReducer,
    dsh: dshReducer,
    plaHor: plaHorReducer,
    dabGesAbsCol: dabGesAbsColReducer,    
    dabGesAbsMan: dabGesAbsManReducer,
    calGesAbs: calGesAbsReducer,
    solGesAbs: solGesAbsReducer,
    sitSolApp: sitSolAppReducer,
    hisDabGesAbs: hisDabGesAbsReducer,
    docApp: docAppReducer,
    tabApp: tabAppReducer,
    orgApp: orgAppReducer,
    drgGesTem: drgGesTemReducer,
    regGesTem: regGesTemReducer,
    comGesTem: comGesTemReducer,
    creGesTem: creGesTemReducer,
    mvtGesTem: mvtGesTemReducer,
    colTemGesTem: colTemGesTemReducer,    
    colPlaGesTem: colPlaGesTemReducer,
    colAffHorInd: colAffHorIndReducer,    
    colAffHorCol: colAffHorColReducer,
    colAffActInd: colAffActIndReducer,
    colAffActCol: colAffActColReducer,
    colCreGesTem: colCreGesTemReducer,
    colMvtGesTem: colMvtGesTemReducer,
    actPoiGesTem: actPoiGesTemReducer,
    actAffGesTem: actAffGesTemReducer,
    entMan: entManReducer,
    entCol: entColReducer,
    entTabMan: entTabManReducer,
    entTabCol: entTabColReducer,
    entSecMan: entSecManReducer,
    entSecCol: entSecColReducer,
    typTraApp: typTraAppReducer,
    anoGesTem: anoGesTemReducer,
    affGesTem: affGesTemReducer,
    affHorInd: affHorIndReducer,
    affActInd: affActIndReducer,
    horAffGesTem: horAffGesTemReducer,
    socAffHorCol: socAffHorColReducer,
    socAffActCol: socAffActColReducer,
    socOrg: socOrgReducer,
    heuGesTem: heuGesTemReducer,
    notApp: notAppReducer,
});

const rootReducer = (state, action) => {
    if(action.type === "usrApp/logoutUser") {
        const { cltApp, secVarApp } = state;
        state = { cltApp, secVarApp }
    }
    return appReducers(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
});

export const persistor = persistStore(store);