import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { whiteColor } from "@styles";
import { useAddTab } from "@hooks/tabs/hooksUtils";
import { appMenu } from "@constants/appConstants";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@components/Buttons";
import { selectUsrApp } from "@selectors";

const MenuItem = ({
    item = null, 
    mobile = false
}) => {
    const stateUser = useSelector(selectUsrApp);
    const addTabActionner = useAddTab(mobile);	
    const { t } = useTranslation("global");
    const noBackground = stateUser.background === "image0";

    return (
        <Box bgcolor={noBackground ? undefined : whiteColor[9]} borderRadius={2}>
            {item?.niveau%2 === 0 ?
                <ButtonBase 
                    variant={noBackground ? "sideBarNoBackgroundSecondary" : "sideBarSecondary"}
                    fullWidth
                    alignement="left"
                    startIcon={(item?.niveau === 1) && (appMenu[item?.action?.substr(0, 2)])} 
                    action={() => addTabActionner(item?.action)}
                >
                    {t(`navigation.${item?.action}`)}
                </ButtonBase>
            :
                <ButtonBase
                    variant={noBackground ? "sideBarNoBackgroundPrimary" : "sideBarPrimary"}
                    fullWidth
                    startIcon={(item?.niveau === 1) && (appMenu[item?.action?.substr(0, 2)])} 
                    alignement="left"
                    action={() => addTabActionner(item?.action)}
                >
                    {t(`navigation.${item?.action}`)}
                </ButtonBase>
            }
        </Box>
    );
}

MenuItem.propTypes = {
    item: PropTypes.any, 
    mobile: PropTypes.bool
}

export default MenuItem;