import { Box, Grid, Grow, Stack } from "@mui/material";
import { Check } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { DialogContainer, HeaderDialogContainer, HeaderListContainer, ListContainer, TextContainer } from "@components/Containers";
import { Loader } from "@components/Loaders";
import { IconButtonBase } from "@components/Buttons";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";
import { TYPES_AFFECTATIONS } from "@constants/affectationsConstants";

const headerItemsHoraire = {
    P: [
        {id: 1, label: "Date", xs: 4, display: true},
        {id: 2, label: "Horaire", xs: 4, display: true},
        {id: 3, label: "Statut", xs: 4, display: true},
    ],
    I: [
        {id: 1, label: "Date", xs: 6, display: true},
        {id: 2, label: "Horaire", xs: 6, display: true}
    ]
};

const headerItemsActivite = [
    {id: 1, label: "Date", xs: 6, display: true},
    {id: 2, label: "Activité", xs: 6, display: true},
];

const DeleteDialog = (props) => {
    const { 
        isLoading, 
        typeAffectations, 
        anneeEffet, 
        moisEffet, 
        jourEffet, 
        codeHoraire, 
        libelleStatut, 
        codeActivite, 
        typePersonnel,
        open, 
        setOpen, 
        deleteAffectation 
    } = props;
    
    return (
        <DialogContainer open={open} taille="sm">
            <HeaderDialogContainer  
                actionsLeft={
                    <IconButtonBase icon={<Check fontSize="small" />} title="Valider" disabled={isLoading} action={() => deleteAffectation()} />   
                }
                title="Suppression d'une affectation"
                close={() => setOpen(false)}
            />
            {isLoading ? 
                <Box sx={styles.loaderStyle}><Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} /></Box> 
            : 
                <Stack spacing={0.5} bgcolor={redColor[1]} borderRadius={2} p={0.5}>
                    <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold">Voulez-vous vraiment supprimer l'affectation suivante ?</TextContainer>
                    <HeaderListContainer itemIdReference="AFFDEL" variant="light" headerItems={typeAffectations === TYPES_AFFECTATIONS.HORAIRES_INDIVIDUEL ? headerItemsHoraire[typePersonnel] : headerItemsActivite} />
                    <ListContainer spacing={0.5} length={1}>
                        {{
                            [TYPES_AFFECTATIONS.HORAIRES_INDIVIDUEL]: 
                                <Grow in={true} timeout={600}>
                                    <Box bgcolor={whiteColor[5]} borderRadius={2} p={0.25}>
                                        <Grid container spacing={0.5}>
                                            <Grid item xs={typePersonnel === "P" ? 4 : 6}>
                                                <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{moment([anneeEffet, moisEffet - 1, jourEffet]).format("DD/MM/YYYY")}</TextContainer>
                                            </Grid>
                                            <Grid item xs={typePersonnel === "P" ? 4 : 6}>
                                                <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{codeHoraire}</TextContainer>
                                            </Grid>
                                            {typePersonnel === "P" && 
                                                <Grid item xs={4}>
                                                    <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{libelleStatut}</TextContainer>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Box>
                                </Grow>,
                            [TYPES_AFFECTATIONS.ACTIVITES_INDIVIDUEL]:
                                (<Grow in={true} timeout={600}>
                                    <Box bgcolor={whiteColor[5]} borderRadius={2} p={0.25}>
                                        <Grid container spacing={0.5}>
                                            <Grid item xs={6}>
                                                <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{moment([anneeEffet, moisEffet - 1, jourEffet]).format("DD/MM/YYYY")}</TextContainer>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{codeActivite}</TextContainer>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grow>)
                        }[typeAffectations]}
                    </ListContainer>
                </Stack>
            }
        </DialogContainer>
    )
}

DeleteDialog.defaultValues = {
    isLoading: false, 
    typeAffectations: TYPES_AFFECTATIONS.HORAIRES_INDIVIDUEL,
    anneeEffet: "", 
    moisEffet: "", 
    jourEffet: "", 
    codeHoraire: "", 
    libelleStatut: "", 
    codeActivite: "", 
    typePersonnel: "P",
    open: false,
    setOpen: () => {},
    deleteAffectation: () => {}
}

DeleteDialog.propTypes = {
    isLoading: PropTypes.bool,
    typeAffectations: PropTypes.string,
    anneeEffet: PropTypes.string, 
    moisEffet: PropTypes.string, 
    jourEffet: PropTypes.string, 
    codeHoraire: PropTypes.string, 
    libelleStatut: PropTypes.string, 
    codeActivite: PropTypes.string, 
    typePersonnel: PropTypes.string, 
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    deleteAffectation: PropTypes.func
}

const styles = {
    itemInterneNonCheckBoxStyle: {
        backgroundColor: "rgba(255,0,0,0.3)",
        borderRadius: 2,
        paddingX: 1,
        paddingY: 1
    },
    headerListStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.5)",
        color: "white",
        borderRadius: 2,
        padding: 2
    },
    itemListStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.2)",
        borderRadius: 2,
        paddingX: 2,
        paddingY: 1
    },
    loaderStyle: {
        height: 100,
        alignItems: "center",
        display: "flex"
    },
}

export default DeleteDialog;