import { Box, Grid, Grow, IconButton, Stack } from "@mui/material";
import { Close, Edit } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { HeaderListContainer, ListContainer, PanelListContainer, TextContainer, TextWithLabelContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { Loader } from "@components/Loaders";
import { ErrorBase } from "@components/Errors";
import { greenColor, greyColor, whiteColor } from "@styles";
import { useMedia } from "react-use";

const headerItemsModif = [
    {id: 1, label: "Date", xs: 4, display: true},
    {id: 2, label: "Secteur Activite", xs: 4, display: true},
];

const headerItemsVisu = [
    {id: 1, label: "Date", xs: 6, display: true},
    {id: 2, label: "Secteur Activite", xs: 6, display: true},
];

const AffectationsAcitivesIndividuellesList = (props) => {
    const { data, isLoading, isError, listed, readOnly, openEdit, openDelete } = props;
    const isLarge = useMedia('(min-width: 651px)');

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader size={20} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return (<ErrorBase>Erreur lors de la récupération des affectations.</ErrorBase>);
    }

    return (
        <Box overflow="auto" height="100%">
            {(listed && isLarge) ?
                <Stack spacing={0.5} height="calc(100% - 39.5px)"> 
                    <HeaderListContainer itemIdReference="AFFACL1" headerItems={readOnly ? headerItemsVisu : headerItemsModif} />
                    <Box height="100%">
                        <PerfectScrollbar>
                            <ListContainer spacing={0.5} length={data.length} noDataLabel="Aucune personne">
                                {data.map((affectation, id) => (
                                    <Grow key={`PRS${affectation.id}`} in={true} timeout={400+(200*id)}>
                                        <Box bgcolor={greenColor["015"]} borderRadius={2} p={0.25}>
                                            <Grid container spacing={0.5}>
                                                <Grid item xs={readOnly ? 6 : 4 }>
                                                    <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{moment([affectation.anneeEffet, affectation.moisEffet - 1, affectation.jourEffet]).format("DD/MM/YYYY")}</TextContainer>
                                                </Grid>
                                                <Grid item xs={readOnly ? 6 : 4 }>
                                                    <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{affectation.codeActivite}</TextContainer>
                                                </Grid>
                                                {!readOnly && 
                                                    <Grid item xs={4} textAlign="center" display="flex" alignItems="center" justifyContent="center"> 
                                                        <IconButton size="small" onClick={() => openEdit(affectation)}>
                                                            <Edit fontSize="small" />
                                                        </IconButton>
                                                        <IconButton size="small" onClick={() => openDelete(affectation)}>
                                                            <Close fontSize="small" />
                                                    </IconButton>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Box>
                                    </Grow>
                                ))}
                            </ListContainer>
                        </PerfectScrollbar>
                    </Box>
                </Stack>
            :
                <PerfectScrollbar>
                    <PanelListContainer length={data.length}>
                        {data.map((affectation, id) => (
                            <Grow key={`AFFE${affectation.id}`} in={true} timeout={200+(200*id)}>
                                <Grid item xs={12} sm={3}>
                                    <Stack spacing={0.5} bgcolor={greenColor["025"]} padding={0.5} borderRadius={2}>
                                        <Box borderRadius={2} p={1} bgcolor={whiteColor[5]}>
                                            <Stack spacing={0}>
                                                <TextWithLabelContainer label="Date" value={moment([affectation.anneeEffet, affectation.moisEffet - 1, affectation.jourEffet]).format("DD/MM/YYYY")} weight={{label: "bold", value: "normal"}} />
                                                <TextWithLabelContainer label="Secteur Activité" value={affectation.codeActivite} weight={{label: "bold", value: "normal"}} />
                                            </Stack>
                                        </Box>
                                        {!readOnly &&
                                            <Box borderRadius={2} height={35.5} display="flex" alignItems="center" justifyContent="center" bgcolor={whiteColor[5]}>
                                                <IconButtonBase icon={<Edit fontSize="small" />} title="Modifier" action={() => openEdit(affectation)} />
                                                <IconButtonBase icon={<Close fontSize="small" />} title="Supprimer" action={() => openDelete(affectation)} />
                                            </Box>
                                        }
                                    </Stack>
                                </Grid>
                            </Grow>
                        ))}  
                    </PanelListContainer>
                </PerfectScrollbar>
            }
        </Box>
    )
}

AffectationsAcitivesIndividuellesList.defaultProps = {
    data: [],
    isLoading: true,
    isError: false,
    listed: false,
    readOnly: true,
    openEdit: () => {},
    openDelete: () => {}
}

AffectationsAcitivesIndividuellesList.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    listed: PropTypes.bool,
    readOnly: PropTypes.bool,
    openEdit: PropTypes.func,
    openDelete: PropTypes.func
}

const styles = {
    loaderStyle: {
        height: "100vh",
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
    }
}

export default AffectationsAcitivesIndividuellesList;