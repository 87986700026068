import { Box, Grid, Grow, Stack } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { HeaderListContainer, ListContainer, PanelListContainer, TextContainer, TextWithLabelContainer } from "@components/Containers";
import { Loader } from "@components/Loaders";
import { ErrorBase } from "@components/Errors";
import { greenColor, greyColor, whiteColor } from "@styles";
import { chiffreUSVersChiffreFR, chiffreUSVersHeure, datify } from "@utils";
import { useMedia } from "react-use";

const headers1 = [
    {id: 1, label: "", xs: 1, display: false},
    {id: 2, label: "Crédit", xs: 2.25, display: true},
    {id: 3, label: "Régularisation", xs: 3.25, display: true},
];

const headers2 = [
    {id: 1, label: "Date", xs: 1, display: true},
    {id: 2, label: "En cours", xs: 0.75, display: true},
    {id: 3, label: "Acquis jour", xs: 0.75, display: true},
    {id: 4, label: "Acquis cumul", xs: 0.75, display: true},
    {id: 5, label: "Valeur", xs: 0.75, display: true},
    {id: 6, label: "Date", xs: 1, display: true},
    {id: 7, label: "Utilisateur", xs: 1.5, display: true},
    {id: 8, label: "Commentaire", xs: 5.5, display: true},
];

const CreditsList = (props) => {
    const { data, isLoading, isError, horaire } = props;    
    const isLarge = useMedia('(min-width: 650px)');

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader size={20} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return <ErrorBase>Erreur lors de la récupération des crédits.</ErrorBase>
    }

    return (
        isLarge ?
            <>
                <HeaderListContainer itemIdReference="CREGT1" headerItems={headers1} />
                <HeaderListContainer itemIdReference="CREGT2" headerItems={headers2} />
                <PerfectScrollbar>
                    <ListContainer spacing={0.5} length={data.length} noDataLabel="Aucune donnée">
                        {data.map((credit, id) => (
                            <Grow key={`CRES${credit.id}`} in={true} timeout={400+(200*id)}>
                                <Box bgcolor={greenColor["025"]} borderRadius={2} p={0.25}>
                                    <Grid container spacing={0.5}>
                                        <Grid item xs={1}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height="100%">{datify(credit.date)}</TextContainer>
                                        </Grid>
                                        <Grid item xs={0.75}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height="100%">{horaire ? chiffreUSVersHeure(credit.enCoursHeure) : chiffreUSVersChiffreFR(credit.enCoursChiffre)}</TextContainer>
                                        </Grid>
                                        <Grid item xs={0.75}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height="100%">{horaire ? chiffreUSVersHeure(credit.acquisJourHeure) : chiffreUSVersChiffreFR(credit.acquisJourChiffre)}</TextContainer>
                                        </Grid>
                                        <Grid item xs={0.75}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height="100%">{horaire ? chiffreUSVersHeure(credit.acquisCumulHeure) : chiffreUSVersChiffreFR(credit.acquisCumulChiffre)}</TextContainer>
                                        </Grid>
                                        <Grid item xs={0.75}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height="100%">{horaire ? chiffreUSVersHeure(credit.valeurRegularisationHeure) : chiffreUSVersChiffreFR(credit.valeurRegularisationChiffre)}</TextContainer>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height="100%">{datify(credit.dateRegularisation)}</TextContainer>
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height="100%">{credit.utilisateurRegularisation}</TextContainer>
                                        </Grid>
                                        <Grid item xs={5.5}>
                                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" textSize={11} height="100%">{credit.commentaire}</TextContainer>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grow>
                        ))}
                    </ListContainer>
                </PerfectScrollbar>
            </>
        :
            <PerfectScrollbar>
                <PanelListContainer length={data.length}>
                    {data.map((credit, id) => (
                        <Grow key={`LCOMS${credit.id}`} in={true} timeout={400+(200*id)}>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                <Stack spacing={0.5} bgcolor={greenColor["025"]} padding={0.5} borderRadius={2}>
                                    <Box borderRadius={2} p={1} bgcolor={whiteColor[5]}>
                                        <Stack spacing={0}>
                                            <TextWithLabelContainer label="Date" value={datify(credit.date)} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Crédit en cours" value={horaire ? chiffreUSVersHeure(credit.enCoursHeure) : chiffreUSVersChiffreFR(credit.enCoursChiffre)} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Crédit acquis jour" value={horaire ? chiffreUSVersHeure(credit.acquisJourHeure) : chiffreUSVersChiffreFR(credit.acquisJourChiffre)} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Crédit acquis cumul" value={horaire ? chiffreUSVersHeure(credit.acquisCumulHeure) : chiffreUSVersChiffreFR(credit.acquisCumulChiffre)} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Régularisation valeur" value={horaire ? chiffreUSVersHeure(credit.valeurRegularisationHeure) : chiffreUSVersChiffreFR(credit.valeurRegularisationChiffre)} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Régularisation date" value={datify(credit.dateRegularisation)} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Régularisation utilisateur" value={credit.utilisateurRegularisation} weight={{label: "bold", value: "normal"}} />
                                            <TextWithLabelContainer label="Commentaire" value={credit.commentaire} weight={{label: "bold", value: "normal"}} />
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grow>
                    ))}
                </PanelListContainer>
            </PerfectScrollbar>                
    )
}


CreditsList.defaultProps = {
    data: [],
    isLoading: true,
    isError: false,
    horaire: false
}

CreditsList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    horaire: PropTypes.bool
}

const styles = {
    loaderStyle: {
        height: "100%",
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
    }
}

export default CreditsList;