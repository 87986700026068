import { Box, Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { AutoFixHigh, CompareArrows } from "@mui/icons-material";
import PropTypes from "prop-types";
import moment from "moment";
import { useStateTemGesTem } from "@hooks/gestionTemps/temps/hooksStates";
import { chiffreUSVersChiffreFR, heurify } from "@utils";
import { blueColor, greenColor, whiteColor } from "@styles";

const jours = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];

const TempsItemsTable = (props) => {
    const { userType, openTransactions, openRegularisations, openJour } = props;
    const stateTemps = useStateTemGesTem(userType);

    return (
        stateTemps?.semaine.map((jour, id) => (
            <Box key={`JOURMT${jour.id}`} bgcolor={greenColor["015"]} borderRadius={2} p={0.25}>
                <Grid container spacing={0.5}>
                    <Grid item xs={5}>
                        <Tooltip title={jour.libelleFerie} placement="top">
                            <Box sx={jour.codeAbsence ? styles.boxJourAvecAbsenceDateStyle : styles.boxJourDateStyle} bgcolor={jour.libelleFerie ? blueColor["015"] : whiteColor[5]}>
                                <Grid container spacing={0.5} px={jour.codeAbsence ? 0.5 : 0} display="flex" alignItems="center">
                                    <Grid item xs={3}>
                                        <Typography fontSize={"small"} fontWeight="bold">{jours[id]}</Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography fontSize={"small"}>{(jour?.heuresJournee?.badgees?.heure || jour?.heuresJournee?.payees?.heure || jour?.heuresNuit?.badgees?.heure || jour?.heuresNuit?.payees?.heure) && moment(jour.date, "DDMMYYYY").format("DD/MM/YYYY")}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        {jour.codeAbsence && 
                                            <Tooltip title="Détails">
                                                <Button size="small" onClick={() => {openJour(jour.date)}}>
                                                    <Typography fontSize="small">{jour.codeAbsence}</Typography>
                                                </Button>
                                            </Tooltip>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={2.5}>
                        <Box sx={styles.boxJourHeuresStyle} height="calc(100% - 16px)" textAlign="center">
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography fontSize={"small"}>{stateTemps.horaire ? heurify(jour?.heuresJournee?.badgees?.heure, "h", false) : chiffreUSVersChiffreFR(jour?.heuresJournee?.badgees?.chiffre)}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography fontSize={"small"}>{stateTemps.horaire ? heurify(jour?.heuresJournee?.payees?.heure, "h", false) : chiffreUSVersChiffreFR(jour?.heuresJournee?.payees?.chiffre)}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={2.5}>
                        <Box sx={styles.boxJourHeuresStyle} height="calc(100% - 16px)" textAlign="center">
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography fontSize={"small"}>{stateTemps.horaire ? heurify(jour?.heuresNuit?.badgees?.heure, "h", false) : chiffreUSVersChiffreFR(jour?.heuresNuit?.badgees?.chiffre)}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography fontSize={"small"}>{stateTemps.horaire ? heurify(jour?.heuresNuit?.payees?.heure, "h", false) : chiffreUSVersChiffreFR(jour?.heuresNuit?.payees?.chiffre)}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                            {jour.date !== "00000000" && (<>
                                <Tooltip title="Transactions">
                                    <IconButton size="small" onClick={() => {openTransactions(jour.date)}}>
                                        <CompareArrows fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Régulariser">
                                    <IconButton size="small" onClick={() => {openRegularisations(jour.date)}}>
                                        <AutoFixHigh fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </>)}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        ))
    );
}

TempsItemsTable.propTypes = {
    userType: PropTypes.string.isRequired,
    openTransactions: PropTypes.func.isRequired,
    openRegularisations: PropTypes.func.isRequired,
    openJour: PropTypes.func.isRequired
}

const styles = {
    boxJourDateStyle: {
        p: 1,
        borderRadius: 2,
    },
    boxJourAvecAbsenceDateStyle: {
        p: 0.5,
        borderRadius: 2
    },
    boxJourHeuresStyle: {
        backgroundColor: whiteColor[5],
        p: 1,
        borderRadius: 2,
    },
}

export default TempsItemsTable;