import { IconButtonBase, MonthPickerButton } from "@components/Buttons";
import { HeaderPanelContainer } from "@components/Containers";
import { ChevronLeft, ChevronRight, Event, Refresh } from "@mui/icons-material";
import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const CalendrierActionsBar = ({ 
    isLoading = true, 
    isError = false, 
    mois = "",
    annee = "",
    date = null,
    precedent = () => {}, 
    suivant = () => {},
    selectDate = () => {},
    refetch = () => {} 
}) => {
    const { t } = useTranslation("global");

    return (
        <HeaderPanelContainer 
            actionsLeft={
                <Stack direction="row" spacing={0.5}> 
                    <IconButtonBase 
                        variant="white5Grey9White9" 
                        icon={<ChevronLeft fontSize="small" />} 
                        title={t("buttons.previous")} 
                        disabled={isLoading || isError} 
                        action={precedent} 
                    />
                    <IconButtonBase 
                        variant="white5Grey9White9" 
                        icon={<ChevronRight fontSize="small" />} 
                        title={t("buttons.next")} 
                        disabled={isLoading ||isError} 
                        action={suivant} 
                    />
                    <MonthPickerButton 
                        icon={<Event fontSize="small" />} 
                        title={t("buttons.select_month")} 
                        timeout={400} 
                        value={date} 
                        variant="white5Grey9White9"
                        noClick={isLoading || isError}
                        action={selectDate} 
                    />
                </Stack>
            } 
            actionsRight={
                <IconButtonBase 
                    variant="white5Grey9White9" 
                    icon={<Refresh fontSize="small" />} 
                    title={t("buttons.refresh")} 
                    disabled={isLoading}
                    action={refetch} 
                />
            }   
            title={`${mois} ${annee}`}
            variant="green07"
        />
    )
}


CalendrierActionsBar.defaultProps = {
    isLoading: true, 
    isError: false, 
    mois: "",
    annee: "",
    date: null,
    precedent: () => {}, 
    suivant: () => {},
    selectDate: () => {},
    refetch: () => {}
}

CalendrierActionsBar.propTypes = {
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool, 
    mois: PropTypes.string,
    annee: PropTypes.string,
    date: PropTypes.object,
    precedent: PropTypes.func, 
    suivant: PropTypes.func,
    selectDate: PropTypes.func,
    refetch: PropTypes.func
}

export default CalendrierActionsBar;