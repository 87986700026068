import { Box, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { TextContainer } from "@components/Containers";
import { greenColor, whiteColor } from "@styles";

const ServicesContents = (props) => {
    const { services, servicesSelectedInterne, handleCheckService } = props;

    return (
        <Stack spacing={0.5}>
            {services.map((societe) => (
                <Stack spacing={0.5} key={`SOSEDS${societe.societe}`} p={0.5} bgcolor={greenColor["015"]} borderRadius={2}>
                    <TextContainer bgColor={whiteColor[5]} p={1} horizontalAlign="center" textWeight="bold">{societe.societe}</TextContainer>
                    {societe.services.map((service) => (
                        <Box key={`SESEDS${service.service}`} px={2} py={1} bgcolor={whiteColor[5]} borderRadius={2}>
                            <FormControlLabel 
                                sx={styles.formControlStyle} 
                                label={<Typography fontSize="small" fontWeight="bold">{service.libelleService}</Typography>} 
                                control={
                                    <Checkbox 
                                        sx={styles.checkBoxStyle} 
                                        id={service.service + societe.societe} 
                                        value={service.service + societe.societe} 
                                        checked={servicesSelectedInterne.some((item) => item.id === service.service + societe.societe)} 
                                        onChange={handleCheckService} 
                                    />
                                } 
                            />
                        </Box>
                    ))}
                </Stack>
            ))}
        </Stack>
    );
}

ServicesContents.defaultProps = {
    services: [],
    servicesSelectedInterne: [],
    handleCheckService: () => {}
}

ServicesContents.propTypes = {
    services: PropTypes.array,
    servicesSelectedInterne: PropTypes.array,
    handleCheckService: PropTypes.func
}


const styles = {
    formControlStyle: {
        width: "100%"
    },
    checkBoxStyle: { 
        "& .MuiSvgIcon-root": { 
            fontSize: 16 
        } 
    },
};

export default ServicesContents;