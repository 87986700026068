import { useSelector, useDispatch } from "react-redux";
import { Box, Stack } from "@mui/material";
import { selectAnoGesTem } from "@selectors";
import * as anoGesTemActions from "@reducers/gestionTemps/anomalies/anoGesTemReducer"
import { TabContentContainer, TextContainer } from "@components/Containers";
import AnomaliesBase from "@components/GestionTemps/Anomalies/AnomaliesBase";
import AnomaliesCredit from "@components/GestionTemps/Anomalies/AnomaliesCredit";
import AnomaliesActivite from "@components/GestionTemps/Anomalies/AnomaliesActivite";
import { greenColor } from "@styles";
import { useCallback, useState } from "react";
import { MenuButton } from "@components/Buttons";
import { NewReleases, Report, Warning } from "@mui/icons-material";

const AnomaliesMobile = () => {
    const [titreMobile, setTitreMobile] = useState("Liste Anomalies")
    const stateAnomalies = useSelector(selectAnoGesTem);
    const dispatch = useDispatch();

    const handleTabs = useCallback((_, tab) => {
        let titre = "";
        if(tab === "ANOS") {
            titre = "Liste Anomalies";
        }
        if(tab === "ANOC") {
            titre = "Liste Anomalies Crédit Acquis";
        }
        if(tab === "ANOA") {
            titre = "Liste Anomalies d'Activité";
        }
        setTitreMobile(titre);
        dispatch(anoGesTemActions.setActiveTab(tab));
    }, [dispatch]);

    return (
        <Stack spacing={0.5} borderRadius={2} height="100%" boxSizing="border-box">
            <Stack spacing={1} direction="row" bgcolor={greenColor["015"]} borderRadius={2} p={0.5} height={46} boxSizing={"border-box"} display={"flex"} alignItems="center">
                <Box flexGrow={1} boxSizing="border-box" height="100%">
                    <TextContainer bgColor={greenColor["015"]} textSize={12} p={2} height="100%" verticalAlign="center" horizontalAlign="center" textWeight="bold">
                        {titreMobile}
                    </TextContainer>
                </Box>
                <Box pr={1} pl={0.5}>
                    <MenuButton txtId="BTDABSMOB" options={[
                        {id: 1, icon: <Warning fontSize="small" />, label: "Liste Anomalies", action: (e) => handleTabs(e, "ANOS")},
                        {id: 2, icon: <Report fontSize="small" />, label: "Liste Anomalies Crédit Acquis", action: (e) => handleTabs(e, "ANOC")},
                        {id: 3, icon: <NewReleases fontSize="small" />, label: "Liste Anomalies d'Activité", action: (e) => handleTabs(e, "ANOA")}
                    ]} />
                </Box>
            </Stack>
            <TabContentContainer value={stateAnomalies.activeTab} id="ANOS">
                <AnomaliesBase />
            </TabContentContainer>
            <TabContentContainer value={stateAnomalies.activeTab} id="ANOC">
                <AnomaliesCredit />
            </TabContentContainer>
            <TabContentContainer value={stateAnomalies.activeTab} id="ANOA">
                <AnomaliesActivite />
            </TabContentContainer>
        </Stack>
    );
};

export default AnomaliesMobile;