import { Box, Grid, Grow, Stack } from "@mui/material";
import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { heurify } from "@utils";
import { PanelListContainer, TextWithLabelContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { greenColor, whiteColor } from "@styles";
import PerfectScrollbar from "react-perfect-scrollbar";

const CollaborateursFwgtp3ListMobile = (props) => {
    const { horaire, data, headers, collaborateurSelected, selectCollaborateur } = props;

    return (
        <Stack spacing={0.5}>
            <Box overflow="auto" maxHeight="calc(100vh - 140px)">
                <PerfectScrollbar> 
                    <PanelListContainer length={data.length}>
                        {data.map((collaborateur, id) => (
                            <Grow key={`PRS${collaborateur.id}`} in={true} timeout={400+(200*id)}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Stack spacing={0.25} padding={0.5} borderRadius={2} bgcolor={greenColor["015"]}>
                                        <Box borderRadius={2} p={1} bgcolor={whiteColor[5]}>
                                            <Stack spacing={0}>
                                                <TextWithLabelContainer label="Nom" value={collaborateur.prenomNom} weight={{label: "bold", value: "normal"}} />
                                                <TextWithLabelContainer label="Matricule" value={collaborateur.matricule} weight={{label: "bold", value: "normal"}} />
                                                <TextWithLabelContainer label="Badge" value={collaborateur.badge} weight={{label: "bold", value: "normal"}} />
                                                <TextWithLabelContainer label="Type" value={collaborateur.typePersonnel === "P" ? "Salarié" : "Intérimaire"} weight={{label: "bold", value: "normal"}} />
                                                {horaire ? 
                                                    collaborateur.horaires.map((horaire, id) => (                                                
                                                        <TextWithLabelContainer key={`DAT${id}`} label={headers[id]} value={horaire || "0h00"} weight={{label: "bold", value: "normal"}} />
                                                    ))
                                                :
                                                    collaborateur.zones.map((zone, id) => (
                                                        <TextWithLabelContainer key={`DAT${id}`} label={headers[id]} value={zone || "0h00"} weight={{label: "bold", value: "normal"}} />
                                                    ))
                                                }                                                
                                                <TextWithLabelContainer label="Total" value={heurify(collaborateur.totalSemaine, "h")} weight={{label: "bold", value: "normal"}} />
                                            </Stack>
                                        </Box>
                                        <Box borderRadius={2} height={35.5} display="flex" alignItems="center" justifyContent="center" bgcolor={whiteColor[5]}>
                                            <IconButtonBase icon={<Check fontSize="small" />} placement="left" title={collaborateurSelected?.matricule === collaborateur.matricule ? "Collaborateur déjà sélectionné" : "Selectionner"} noClick={collaborateurSelected?.matricule === collaborateur.matricule} action={() => selectCollaborateur(collaborateur)} />
                                        </Box>
                                    </Stack>
                                </Grid>
                            </Grow>
                        ))}
                    </PanelListContainer>
                </PerfectScrollbar>
            </Box>
        </Stack>
    );
}

CollaborateursFwgtp3ListMobile.defaultProps = {
    horaire: false,
    data: [],
    collaborateurSelected: null,
    selectCollaborateur: () => {}
}

CollaborateursFwgtp3ListMobile.propTypes = {
    horaire: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.object),
    headers: PropTypes.array.isRequired,
    collaborateurSelected: PropTypes.object,
    selectCollaborateur: PropTypes.func
}

export default CollaborateursFwgtp3ListMobile;