import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import moment from "moment";
import { selectCalGesAbs } from "@selectors";
import * as calGesAbsActions from "@reducers/gestionAbsences/calendrier/calGesAbsReducer"
import { whiteColor } from "@styles";
import { CalendrierActionsBar, CalendrierTable, JourCalendrierDialog } from "@components/GestionAbsences/Collaborateur/Calendrier";
import { useCalGesAbs } from "@hooks/gestionAbsences/calendrier/hooksQueries";
import voca from "voca";

const Calendrier = () => {
    const [jour, setJour] = useState();
    const [openDialogJour, setOpenDialogJour] = useState(false);
    const stateCalendrier = useSelector(selectCalGesAbs);
    const { data, isLoading, isFetching, isError, error, refetch } = useCalGesAbs(stateCalendrier.date);
    const date = moment(stateCalendrier.date, "MMYYYY");
    const dispatch = useDispatch();

    const openJour = useCallback((jour) => {
        setJour(jour);
        setOpenDialogJour(true);
    }, []);

    const precedent = useCallback(async () => {
        const date = moment(stateCalendrier.date, "MMYYYY");
        if(date.isValid()) {
            dispatch(calGesAbsActions.setDate(date.add(-1, "months").format("MMYYYY")))
        }
    }, [dispatch, stateCalendrier.date]);

    const suivant = useCallback(async () => {
        const date = moment(stateCalendrier.date, "MMYYYY");
        if(date.isValid()) {
            dispatch(calGesAbsActions.setDate(date.add(1, "months").format("MMYYYY")))
        }
    }, [dispatch, stateCalendrier.date]);

    const selectDate = useCallback(async (date) => {
        if(date.isValid()) {
            const dateFormated = date.format("MMYYYY");
            dispatch(calGesAbsActions.setDate(dateFormated))
        }
    }, [dispatch]);

    return (
        <>
            <Box bgcolor={whiteColor[9]} borderRadius={2} p={0.5} boxSizing="border-box">
                <Stack spacing={0.5}>
                    <CalendrierActionsBar 
                        isLoading={isLoading || isFetching}
                        isError={isError}
                        mois={date?.isValid() ? voca.capitalize(date.format("MMMM")) : ""}
                        annee={date?.isValid() ? voca.capitalize(date.format("YYYY")) : ""}
                        precedent={precedent}
                        suivant={suivant}
                        selectDate={selectDate}
                        date={date}
                        refetch={refetch}
                    />
                    <Box>
                        <CalendrierTable 
                            isLoading={isLoading || isFetching}
                            isError={isError}
                            errorMessage={error?.message}
                            openJour={openJour}
                            semaines={data}
                        />
                    </Box>
                </Stack>
            </Box>
            <JourCalendrierDialog 
                open={openDialogJour} 
                setOpen={setOpenDialogJour} 
                jour={jour} 
            />
        </>
    )
}

export default Calendrier;