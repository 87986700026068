import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Loader } from "@components/Loaders";
import { Header } from "./Header";
import { Emetteur } from "./Emetteur";
import { Collaborateurs } from "./Collaborateurs";
import { Totaux } from "./Totaux";
import { greenColor, redColor, whiteColor } from "@styles";
import PerfectScrollbar from "react-perfect-scrollbar";

const variants = {
    planning: { bgHeader: greenColor["02"], bgContent: greenColor["02"], bgTotaux: greenColor["02"] },
    absenceManager: { bgHeader: whiteColor[5], bgContent: whiteColor[5], bgTotaux: whiteColor[5] }
}

const PlanningContainer = ({ 
    isLoading = true,
    isError = false,
    errorMessage = "",
    type = "", 
    emetteur = true,
    displayEmetteur = true, 
    hierarchie = false, 
    displayCollaborateur = true, 
    displayTotaux = true, 
    maxHeight = undefined, 
    variant = "planning",
    jourCourt = false, 
    openJour = () => {}
}) => {
    if(isLoading) {
        return (
            <Box justifyContent={"center"} display={"flex"} alignItems={"center"} height={100}>
                <Loader size={27} />
            </Box>
        );
    }

    if(isError) {
        return (
            <Box bgcolor={redColor[5]} py={1} px={2} borderRadius={2} width="100%" boxSizing="border-box" height="100%" display="flex" alignItems="center" justifyContent="center"> 
                <Typography fontSize="small" fontWeight="bold" color={whiteColor[9]}>
                    {errorMessage}
                </Typography>
            </Box>
        );
    }
    
    return (
        <Stack spacing={0.5}>
            <Box>
                <Stack spacing={0.3} bgcolor={variants[variant]?.bgHeader} sx={styles.stackEmetteurStyle}>
                    <Header 
                        type={type} 
                        variant={variant}
                        displayEmetteur={displayEmetteur}
                        jourCourt={jourCourt} 
                    />
                    {displayEmetteur && 
                        <Emetteur 
                            type={type} 
                            variant={variant}
                            openJour={openJour} 
                        />
                    }
                </Stack>
            </Box>
            <Box overflow="auto" height="100%" maxHeight={maxHeight}>
                <PerfectScrollbar>
                    <Stack spacing={0.5} bgcolor={variants[variant]?.bgContent} sx={styles.stackCollaborateurStyle}>
                        {displayCollaborateur && 
                            <Collaborateurs 
                                type={type} 
                                emetteur={emetteur}
                                hierarchie={hierarchie} 
                                variant={variant}
                                openJour={openJour} 
                            />
                        }
                    </Stack>
                </PerfectScrollbar>
            </Box> 
            <Box>
                {displayTotaux && 
                    <Stack spacing={0.5} bgcolor={variants[variant]?.bgTotaux} sx={styles.stackTotauxStyle}>
                        <Totaux type={type} variant={variant} />         
                    </Stack>
                }
            </Box>       
        </Stack>
    );
}

PlanningContainer.propTypes = {
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
    type: PropTypes.string, 
    emetteur: PropTypes.bool,
    displayEmetteur: PropTypes.bool, 
    hierarchie: PropTypes.bool, 
    displayCollaborateur: PropTypes.bool, 
    displayTotaux: PropTypes.bool, 
    maxHeight: PropTypes.string, 
    variant: PropTypes.string, 
    jourCourt: PropTypes.bool, 
    openJour: PropTypes.func 
}


const styles = {
    boxJourStyle: {
        backgroundColor: "rgba(0,0,0,0.1)", 
        p:1,
        borderRadius: 2,
    },
    boxJourBackgroundBlackStyle: {
        backgroundColor: "rgba(255,255,255,0.5)", 
        p:1,
        borderRadius: 2,
    },
    boxJourFerieStyle: {
        backgroundColor: "rgba(0,128,255,0.3)", 
        p:1,
        borderRadius: 2,
    },
    boxJourFerieBackgroundBlackStyle: {
        backgroundColor: "rgba(0,128,255,0.7)", 
        p:1,
        borderRadius: 2,
    },
    stackEmetteurStyle: {
        position: "sticky",
        p: 0.5,
        borderRadius: 2,
    },
    stackCollaborateurStyle: {
        p: 0.5,
        borderRadius: 2,
    },
    stackTotauxStyle: {
        p: 0.5,
        borderRadius: 2,
    },
    boxNomEmetteurStyle: {
        backgroundColor: "rgba(49, 133, 156, 0.3)", 
        p:1,
        borderRadius: 2,
    },
    boxJourEmetteurStyle: {
        backgroundColor: "rgba(0,0,0,0.1)", 
        p:1,
        borderRadius: 2,
    },
    boxJourEmetteurBackgroundLightStyle: {
        backgroundColor: "rgba(255,255,255,0.5)", 
        p:1,
        borderRadius: 2,
    },
}

export default PlanningContainer;