import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    periodeDebut: "",
    periodeFin: "",
    matricule: "",
    numeroBadge: "",
    prenomNom: "",
    baseContrat: "",
    totalHeuresSemaine: "",
    totalHeuresAbsence: "",
    numeroSemaine: "",
    authorizationLundi: "",
    authorizationMardi: "",
    authorizationMercredi: "",
    authorizationJeudi: "",
    authorizationVendredi: "",
    authorizationSamedi: "",
    authorizationDimanche: "",
    lundi: {
        debut1: "",
        arret1: "",
        debut2: "",
        arret2: "",
        debut3: "",
        arret3: "",
        debut4: "",
        arret4: "",
        typeTravail1: "",
        typeTravail2: "",
        typeTravail3: "",
        typeTravail4: ""
    },
    mardi: {
        debut1: "",
        arret1: "",
        debut2: "",
        arret2: "",
        debut3: "",
        arret3: "",
        debut4: "",
        arret4: "",
        typeTravail1: "",
        typeTravail2: "",
        typeTravail3: "",
        typeTravail4: "",
    },
    mercredi: {
        debut1: "",
        arret1: "",
        debut2: "",
        arret2: "",
        debut3: "",
        arret3: "",
        debut4: "",
        arret4: "",
        typeTravail1: "",
        typeTravail2: "",
        typeTravail3: "",
        typeTravail4: "",
    },
    jeudi: {
        debut1: "",
        arret1: "",
        debut2: "",
        arret2: "",
        debut3: "",
        arret3: "",
        debut4: "",
        arret4: "",
        typeTravail1: "",
        typeTravail2: "",
        typeTravail3: "",
        typeTravail4: "",
    },
    vendredi: {
        debut1: "",
        arret1: "",
        debut2: "",
        arret2: "",
        debut3: "",
        arret3: "",
        debut4: "",
        arret4: "",
        typeTravail1: "",
        typeTravail2: "",
        typeTravail3: "",
        typeTravail4: "",
    },
    samedi: {
        debut1: "",
        arret1: "",
        debut2: "",
        arret2: "",
        debut3: "",
        arret3: "",
        debut4: "",
        arret4: "",
        typeTravail1: "",
        typeTravail2: "",
        typeTravail3: "",
        typeTravail4: "",
    },
    dimanche: {
        debut1: "",
        arret1: "",
        debut2: "",
        arret2: "",
        debut3: "",
        arret3: "",
        debut4: "",
        arret4: "",
        typeTravail1: "",
        typeTravail2: "",
        typeTravail3: "",
        typeTravail4: "",
    },
    absenceLundi: "",
    absenceMardi: "",
    absenceMercredi: "",
    absenceJeudi: "",
    absenceVendredi: "",
    absenceSamedi: "",
    absenceDimanche: "",
    totalHeuresLundi: "",
    totalHeuresMardi: "",
    totalHeuresMercredi: "",
    totalHeuresJeudi: "",
    totalHeuresVendredi: "",
    totalHeuresSamedi: "",
    totalHeuresDimanche: "",
    horaireLundi: "",
    horaireMardi: "",
    horaireMercredi: "",
    horaireJeudi: "",
    horaireVendredi: "",
    horaireSamedi: "",
    horaireDimanche: "",
    horaireInitialLundi: "",
    horaireInitialMardi: "",
    horaireInitialMercredi: "",
    horaireInitialJeudi: "",
    horaireInitialVendredi: "",
    horaireInitialSamedi: "",
    horaireInitialDimanche: "",
    modeleLundi: "",
    modeleMardi: "",
    modeleMercredi: "",
    modeleJeudi: "",
    modeleVendredi: "",
    modeleSamedi: "",
    modeleDimanche: "",
    totalHeuresAbsenceLundi: "",
    totalHeuresAbsenceMardi: "",
    totalHeuresAbsenceMercredi: "",
    totalHeuresAbsenceJeudi: "",
    totalHeuresAbsenceVendredi: "",
    totalHeuresAbsenceSamedi: "",
    totalHeuresAbsenceDimanche: "",
    rattachementLundi: "",
    rattachementMardi: "",
    rattachementMercredi: "",
    rattachementJeudi: "",
    rattachementVendredi: "",
    rattachementSamedi: "",
    rattachementDimanche: "",
    societeLundi: "",
    societeMardi: "",
    societeMercredi: "",
    societeJeudi: "",
    societeVendredi: "",
    societeSamedi: "",
    societeDimanche: "",
    authorizationTypeTravailLundi: "",
    authorizationTypeTravailMardi: "",
    authorizationTypeTravailMercredi: "",
    authorizationTypeTravailJeudi: "",
    authorizationTypeTravailVendredi: "",
    authorizationTypeTravailSamedi: "",
    authorizationTypeTravailDimanche: "",
    dateLundi: "",
    dateMardi: "",
    dateMercredi: "",
    dateJeudi: "",
    dateVendredi: "",
    dateSamedi: "",
    dateDimanche: "",
    modificationAffectationsHoraires: false,
}

const plaGesTemSlice = createSlice({
    name: 'plaGesTem',
    initialState: initialState,
    reducers: {
        setFwgtp4: (state, action) => {
            state.periodeDebut = action.payload.periodeDebut;
            state.periodeFin = action.payload.periodeFin;
            state.matricule = action.payload.matricule;
            state.numeroBadge = action.payload.numeroBadge;
            state.prenomNom = action.payload.prenomNom;
            state.baseContrat = action.payload.baseContrat;
            state.totalHeuresSemaine = action.payload.totalHeuresSemaine;
            state.totalHeuresAbsence = action.payload.totalHeuresAbsence;
            state.numeroSemaine = action.payload.numeroSemaine;
            state.authorizationLundi = action.payload.authorizationLundi;
            state.authorizationMardi = action.payload.authorizationMardi;
            state.authorizationMercredi = action.payload.authorizationMercredi;
            state.authorizationJeudi = action.payload.authorizationJeudi;
            state.authorizationVendredi = action.payload.authorizationVendredi;
            state.authorizationSamedi = action.payload.authorizationSamedi;
            state.authorizationDimanche = action.payload.authorizationDimanche;
            state.lundi = action.payload.lundi;
            state.mardi = action.payload.mardi;
            state.mercredi = action.payload.mercredi;
            state.jeudi = action.payload.jeudi;
            state.vendredi = action.payload.vendredi;
            state.samedi = action.payload.samedi;
            state.dimanche = action.payload.dimanche;
            state.absenceLundi = action.payload.absenceLundi;
            state.absenceMardi = action.payload.absenceMardi;
            state.absenceMercredi = action.payload.absenceMercredi;
            state.absenceJeudi = action.payload.absenceJeudi;
            state.absenceVendredi = action.payload.absenceVendredi;
            state.absenceSamedi = action.payload.absenceSamedi;
            state.absenceDimanche = action.payload.absenceDimanche;
            state.totalHeuresLundi = action.payload.totalHeuresLundi;
            state.totalHeuresMardi = action.payload.totalHeuresMardi;
            state.totalHeuresMercredi = action.payload.totalHeuresMercredi;
            state.totalHeuresJeudi = action.payload.totalHeuresJeudi;
            state.totalHeuresVendredi = action.payload.totalHeuresVendredi;
            state.totalHeuresSamedi = action.payload.totalHeuresSamedi;
            state.totalHeuresDimanche = action.payload.totalHeuresDimanche;
            state.horaireLundi = action.payload.horaireLundi;
            state.horaireMardi = action.payload.horaireMardi;
            state.horaireMercredi = action.payload.horaireMercredi;
            state.horaireJeudi = action.payload.horaireJeudi;
            state.horaireVendredi = action.payload.horaireVendredi;
            state.horaireSamedi = action.payload.horaireSamedi;
            state.horaireDimanche = action.payload.horaireDimanche;
            state.horaireInitialLundi = action.payload.horaireLundi;
            state.horaireInitialMardi = action.payload.horaireMardi;
            state.horaireInitialMercredi = action.payload.horaireMercredi;
            state.horaireInitialJeudi = action.payload.horaireJeudi;
            state.horaireInitialVendredi = action.payload.horaireVendredi;
            state.horaireInitialSamedi = action.payload.horaireSamedi;
            state.horaireInitialDimanche = action.payload.horaireDimanche;
            state.modeleLundi = action.payload.modeleLundi;
            state.modeleMardi = action.payload.modeleMardi;
            state.modeleMercredi = action.payload.modeleMercredi;
            state.modeleJeudi = action.payload.modeleJeudi;
            state.modeleVendredi = action.payload.modeleVendredi;
            state.modeleSamedi = action.payload.modeleSamedi;
            state.modeleDimanche = action.payload.modeleDimanche;
            state.totalHeuresAbsenceLundi = action.payload.totalHeuresAbsenceLundi;
            state.totalHeuresAbsenceMardi = action.payload.totalHeuresAbsenceMardi;
            state.totalHeuresAbsenceMercredi = action.payload.totalHeuresAbsenceMercredi;
            state.totalHeuresAbsenceJeudi = action.payload.totalHeuresAbsenceJeudi;
            state.totalHeuresAbsenceVendredi = action.payload.totalHeuresAbsenceVendredi;
            state.totalHeuresAbsenceSamedi = action.payload.totalHeuresAbsenceSamedi;
            state.totalHeuresAbsenceDimanche = action.payload.totalHeuresAbsenceDimanche;
            state.rattachementLundi = action.payload.rattachementLundi;
            state.rattachementMardi = action.payload.rattachementMardi;
            state.rattachementMercredi = action.payload.rattachementMercredi;
            state.rattachementJeudi = action.payload.rattachementJeudi;
            state.rattachementVendredi = action.payload.rattachementVendredi;
            state.rattachementSamedi = action.payload.rattachementSamedi;
            state.rattachementDimanche = action.payload.rattachementDimanche;
            state.societeLundi = action.payload.societeLundi;
            state.societeMardi = action.payload.societeMardi;
            state.societeMercredi = action.payload.societeMercredi;
            state.societeJeudi = action.payload.societeJeudi;
            state.societeVendredi = action.payload.societeVendredi;
            state.societeSamedi = action.payload.societeSamedi;
            state.societeDimanche = action.payload.societeDimanche;
            state.authorizationTypeTravailLundi = action.payload.authorizationTypeTravailLundi;
            state.authorizationTypeTravailMardi = action.payload.authorizationTypeTravailMardi;
            state.authorizationTypeTravailMercredi = action.payload.authorizationTypeTravailMercredi;
            state.authorizationTypeTravailJeudi = action.payload.authorizationTypeTravailJeudi;
            state.authorizationTypeTravailVendredi = action.payload.authorizationTypeTravailVendredi;
            state.authorizationTypeTravailSamedi = action.payload.authorizationTypeTravailSamedi;
            state.authorizationTypeTravailDimanche = action.payload.authorizationTypeTravailDimanche;
            state.dateLundi = action.payload.dateLundi;
            state.dateMardi = action.payload.dateMardi;
            state.dateMercredi = action.payload.dateMercredi;
            state.dateJeudi = action.payload.dateJeudi;
            state.dateVendredi = action.payload.dateVendredi;
            state.dateSamedi = action.payload.dateSamedi;
            state.dateDimanche = action.payload.dateDimanche;
            state.modificationAffectationsHoraires = action.payload.modificationAffectationsHoraires;
        },
        updateFwgtp4: (state, action) => {
            state.lundi = action.payload.lundi;
            state.mardi = action.payload.mardi;
            state.mercredi = action.payload.mercredi;
            state.jeudi = action.payload.jeudi;
            state.vendredi = action.payload.vendredi;
            state.samedi = action.payload.samedi;
            state.dimanche = action.payload.dimanche;
            state.modeleLundi = action.payload.modeleLundi;
            state.modeleMardi = action.payload.modeleMardi;
            state.modeleMercredi = action.payload.modeleMercredi;
            state.modeleJeudi = action.payload.modeleJeudi;
            state.modeleVendredi = action.payload.modeleVendredi;
            state.modeleSamedi = action.payload.modeleSamedi;
            state.modeleDimanche = action.payload.modeleDimanche;
            state.rattachementLundi = action.payload.rattachementLundi;
            state.rattachementMardi = action.payload.rattachementMardi;
            state.rattachementMercredi = action.payload.rattachementMercredi;
            state.rattachementJeudi = action.payload.rattachementJeudi;
            state.rattachementVendredi = action.payload.rattachementVendredi;
            state.rattachementSamedi = action.payload.rattachementSamedi;
            state.rattachementDimanche = action.payload.rattachementDimanche;
        },
        setModificationAffectationsHoraires: (state, action) => {
            state.modificationAffectationsHoraires = action.payload;
        }
    },
})

const { actions, reducer } = plaGesTemSlice
export const { setFwgtp4, updateFwgtp4, setModificationAffectationsHoraires } = actions
export default reducer