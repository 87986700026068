import { Box, Stack } from "@mui/material";
import { selectColAffActCol, selectSocAffActCol } from "@selectors";
import { greenColor, whiteColor } from "@styles";
import { useSelector, useDispatch } from "react-redux";
import * as colAffActColActions from "@reducers/collaborateurs/colAffActColReducer";
import * as socAffActColActions from "@reducers/societes/socAffActColReducer";
import { SocietesBar } from "@components/App/Societes/Bars";
import { CollaborateursBar } from "@components/App/Collaborateurs/Bars";
import { SocietesDialog } from "@components/App/Societes/Dialogs";
import { CollaborateursDialog } from "@components/App/Collaborateurs/Dialogs";
import { HeaderPanelContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { Check } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { AffectationsCollectivesActiviteForm } from "./Forms";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { TYPES_COLLABORATEURS, TYPES_FICHIER } from "@constants/collaborateursConstants";
import { innerJoin, tidy } from "@tidyjs/tidy";
import { useSocAffActCol } from "@hooks/societes/hooksQueries";
import { useAddAffActCol } from "@hooks/gestionTemps/affectations/hooksQueries";
import { TYPES_SOCIETES } from "@constants/societesConstants";

const headersFwgtp2 = [
    {id: 1, label: "Nom", xs: 1.5, display: true, firstData: true, lastData: false},
    {id: 2, label: "Matricule", xs: 0.75, display: true, firstData: false, lastData: false},
    {id: 3, label: "Badge", xs: 0.75, display: true, firstData: false, lastData: false},
    {id: 4, label: "Société", xs: 1, display: true, firstData: false, lastData: false},
    {id: 5, label: "Etablissement", xs: 1, display: true, firstData: false, lastData: false},
    {id: 6, label: "Direction", xs: 1.5, display: true, firstData: false, lastData: false},
    {id: 7, label: "Service", xs: 1.5, display: true, firstData: false, lastData: false},
    {id: 8, label: "Secteur", xs: 1, display: true, firstData: false, lastData: false},
    {id: 9, label: "Type", xs: 1, display: true, firstData: false, lastData: false},
    {id: 10, label: "Contôle horaire", xs: 0.75, display: true, firstData: false, lastData: false},
    {id: 11, label: "Présent", xs: 0.75, display: true, firstData: false, lastData: true}
];

const defaultValues = {
    affectations: [{ dateEffet: null, activite: null }]
}

const AffectationsActivitesCollectif = () => {
    const [openSocietesDialog, setOpenSocietesDialog] = useState(false);
    const [openCollaborateursDialog, setOpenCollaborateursDialog] = useState(false);
    const [collaborateursChecked, setCollaborateursChecked] = useState([]);
    const [collaborateurs, setCollaborateurs] = useState([]);
    const [all, setAll] = useState(false);
    const stateColAffActCol = useSelector(selectColAffActCol); 
    const stateSocAffActCol = useSelector(selectSocAffActCol); 
    const { isLoading, isFetching, isError } = useSocAffActCol();
    const { isLoadingActivite, addAffActColActionner } = useAddAffActCol();
    const methods = useForm({ mode: "onChange", defaultValues: defaultValues });
    const dispatch = useDispatch();

    useEffect(() => {
        if(stateSocAffActCol.societes.length > 1) {
            dispatch(socAffActColActions.resetSelection());
            dispatch(colAffActColActions.resetSelection());
        }
        if(stateSocAffActCol.societes.length === 1) {
            dispatch(socAffActColActions.selectSociete(stateSocAffActCol.societes[0]));
            dispatch(colAffActColActions.resetSelection());
        }
    }, [dispatch, stateSocAffActCol.societes]);

    useEffect(() => {
        if(openCollaborateursDialog) {
            setCollaborateursChecked(stateColAffActCol.collaborateursSelected);
            setAll(false);
        }
    }, [openCollaborateursDialog, stateColAffActCol.collaborateursSelected]);

    useEffect(() => {
        let ids = [];
        let collaborateursSelected = [];
        ids = stateColAffActCol.collaborateursSelected.map((value) => ({ id: value }));
        collaborateursSelected = tidy(stateColAffActCol.collaborateurs, innerJoin(ids, { by: ['id'] }));
        setCollaborateurs(collaborateursSelected);
    }, [stateColAffActCol.collaborateurs, stateColAffActCol.collaborateursSelected]);

    const selectSociete = useCallback((societe) => {
        dispatch(socAffActColActions.selectSociete(societe));
        dispatch(colAffActColActions.resetSelection());
        setOpenSocietesDialog(false);
    }, [dispatch]);

    const selectCollaborateur = useCallback((e) => {
        let collaborateursSelected = [...collaborateursChecked]
        if(e.target.checked) {
            collaborateursSelected = [...collaborateursChecked, Number(e.target.value)];
        } else {
            collaborateursSelected.splice(collaborateursChecked.indexOf(Number(e.target.value)), 1);
        }
        setCollaborateursChecked(collaborateursSelected);
    }, [collaborateursChecked]);

    const selectAllCollaborateurs = useCallback(() => {
        if(all) {
            setCollaborateursChecked([]);
            setAll(false);
        } else {
            const idCollaborateurs = stateColAffActCol.collaborateursDisplayed.map((collaborateur) => collaborateur.id);
            setCollaborateursChecked(idCollaborateurs);
            setAll(true);
        }
    }, [all, stateColAffActCol.collaborateursDisplayed]);

    const selectCollaborateursChecked = useCallback(() => {
        dispatch(colAffActColActions.selectCollaborateurs(collaborateursChecked));
        setOpenCollaborateursDialog(false);
    }, [collaborateursChecked, dispatch]);

    const postAffectations = useCallback(async (data) => {
        const { isSucceed } = await addAffActColActionner(data);
        if(isSucceed) {
            methods.reset(defaultValues);
        }
    }, [methods, addAffActColActionner]);
    
    return (
        <Stack spacing={0.5} bgcolor={greenColor["015"]} borderRadius={2} p={0.5} height="100%" boxSizing="border-box">
            <SocietesBar 
                typeSociete={TYPES_SOCIETES.AFFECTATIONS_COLLECTIVES_ACTIVITES} 
                isLoading={isLoading || isFetching}
                isError={isError}
                setOpenSociete={setOpenSocietesDialog}
                societes={stateSocAffActCol.societes}
                societe={stateSocAffActCol.societeSelected}
            />
            {stateSocAffActCol.societeSelected && 
                <CollaborateursBar 
                    buttonTitle="Selectionner un ou plusieurs collaborateurs"
                    collaborateurs={collaborateurs}
                    multiple={true} 
                    openDialog={() => setOpenCollaborateursDialog(true)}
                />
            }
            {stateColAffActCol?.collaborateursSelected?.length > 0 && 
                <Stack bgcolor={whiteColor[5]} borderRadius={2} p={0.5} spacing={0.5}>
                    <Box>
                        <HeaderPanelContainer 
                            actionsLeft={
                                <Stack direction={"row"} spacing={0.5}>
                                    <IconButtonBase icon={<Check fontSize="small" />} title="Valider" noClick={isLoading} action={() => methods.handleSubmit(postAffectations)()} />
                                </Stack>
                            }
                            bgColor={greenColor["015"]}
                        />
                    </Box>
                    <FormProvider {...methods}>
                        <AffectationsCollectivesActiviteForm 
                            isLoading={isLoadingActivite} 
                        />
                    </FormProvider>
                </Stack>
            } 
            <SocietesDialog 
                open={openSocietesDialog}
                setOpen={setOpenSocietesDialog}
                societes={stateSocAffActCol.societesDisplayed}
                selectSociete={selectSociete}
            />
            {stateSocAffActCol.societeSelected && 
                <CollaborateursDialog 
                    stateType={TYPES_COLLABORATEURS.AFFECTATIONS_ACTIVITES_COLLECTIVES}
                    listType={TYPES_FICHIER.FWGTP2} 
                    open={openCollaborateursDialog}  
                    codeSociete={stateSocAffActCol?.societeSelected?.codeSociete}
                    dateValidite={stateColAffActCol?.dateValidite ? moment(stateColAffActCol.dateValidite, "DDMMYYYY").format("DDMMYYYY") : moment()}
                    headers={headersFwgtp2}
                    tried={stateColAffActCol.tried}
                    allChecked={all}
                    collaborateursChecked={collaborateursChecked}
                    setOpen={setOpenCollaborateursDialog}  
                    selectCollaborateursChecked={selectCollaborateursChecked}
                    selectCollaborateur={selectCollaborateur}
                    selectAllCollaborateurs={selectAllCollaborateurs}
                />
            }
        </Stack>
    );
};

export default AffectationsActivitesCollectif;