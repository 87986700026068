import { Restaurant } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { TextContainer } from "@components/Containers";
import { getWhiteColor, greenColor, redColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const PlagesHoraireWidgetConfigurationAdd = () => {
    const { t } = useTranslation("global");

    return (
        <Stack spacing={0.5} padding={0.5} borderRadius={2} bgcolor={greenColor["015"]} height="200px" boxSizing="border-box">
                <TextContainer p={0.5} horizontalAlign="center" bgColor={whiteColor[5]} textWeight="bold">Plages Horaire</TextContainer>
                <Stack direction="row" p={0.5} bgcolor={whiteColor[5]} borderRadius={2}>
                    <Box flexGrow={1}>
                        <Typography fontSize={"small"} fontWeight="bold">{t("texts.date_week")}</Typography>
                    </Box>
                    <Box>
                        <Typography fontSize={"small"} fontWeight="bold">{t("texts.public_holiday")}</Typography>
                    </Box>
                </Stack>
                <Box sx={styles.boxPlagesHoraireStyle} bgcolor={whiteColor[5]} borderRadius={2} overflow="auto">
                    <Stack spacing={0.5}>
                        <Box paddingX={1} paddingTop={1}>
                            <Box width="100%" bgcolor={greenColor["03"]} height={5} borderRadius={6} justifyContent={"center"} display="flex">
                                <Box width={"75%"} bgcolor={redColor[3]} height={5} borderRadius={6}></Box>
                            </Box>
                        </Box>
                        <Stack direction="row">
                            <Box width={"15%"} justifyContent="left" display="flex">
                                <Typography fontSize="11px">8h30</Typography>
                            </Box>
                            <Stack direction="row" width={"70%"}>
                                <Box width="50%" justifyContent="left"  display="flex">
                                    <Typography fontSize="11px">9h00</Typography>
                                </Box>
                                <Box width="50%" justifyContent="right"  display="flex">
                                    <Typography fontSize="11px">12h00</Typography>
                                </Box>
                            </Stack>
                            <Box width={"15%"} justifyContent="right" display="flex">
                                <Typography fontSize="11px">12h30</Typography>
                            </Box>
                        </Stack>
                    </Stack>
            
                    <Box display={"inline-flex"} alignItems="center" justifyContent="center" width="100%">
                        <Restaurant fontSize="small" />
                        <Typography fontSize={"11px"} paddingX={1}>1h00</Typography> 
                    </Box>
            
                    <Stack spacing={0.5}>
                        <Box paddingX={1} paddingTop={1}>
                            <Box width="100%" bgcolor={greenColor["03"]} height={5} borderRadius={6} justifyContent={"center"} display="flex">
                                <Box width={"75%"} bgcolor={redColor[3]} height={5} borderRadius={6}></Box>
                            </Box>
                        </Box>
                        <Stack direction="row">
                            <Box width={"15%"} justifyContent="left" display="flex">
                                <Typography fontSize="11px">13h00</Typography>
                            </Box>
                            <Stack direction="row" width={"70%"}>
                                <Box width="50%" justifyContent="left"  display="flex">
                                    <Typography fontSize="11px">13h30</Typography>
                                </Box>
                                <Box width="50%" justifyContent="right"  display="flex">
                                    <Typography fontSize="11px">18h00</Typography>
                                </Box>
                            </Stack>
                            <Box width={"15%"} justifyContent="right" display="flex">
                                <Typography fontSize="11px">18h45</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                </Box>      

        </Stack>
    );
}

const styles = {
    paperStyleNoBackground: {
        backdropFilter: "blur(3px)",
        backgroundColor: greenColor["02"],
        width: "100%",
        height: "200px",
        borderRadius: 2
    },
    boxBandeauDateStyle: {
        backgroundColor: greenColor["015"], 
        paddingX: 1,
        paddingY: 1,
        borderRadius: 2,
    },
    noPlageStyle: {  
        height: "102px",
        background: greenColor["02"],
        borderRadius: 2,
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
    },
    boxTitleStyle: {
        backgroundColor: "rgba(0,0,0,0.1)", 
        textAlign: "center",
        p: 0.5,
        borderRadius: 2,
    },
    boxPlagesHoraireStyle: {
        
        p: 1,
    },
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        height: "calc(200px - 46px)"
    },
    paperStyle: {
        backdropFilter: "blur(3px)",
        backgroundColor: getWhiteColor("0.95"),
        width: "100%",
        height: "200px",
        borderRadius: 2
    }
};

export default PlagesHoraireWidgetConfigurationAdd;