import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    listed: false,
    periode: ""
}

const solGesAbsSlice = createSlice({
    name: 'solGesAbs',
    initialState: initialState,
    reducers: {
        setPeriode: (state, action) => {
            state.periode = action.payload;
        },
        lister: (state) => {
            state.listed = !state.listed;
        },
    },
})

const { actions, reducer } = solGesAbsSlice
export const { lister, setPeriode } = actions
export default reducer