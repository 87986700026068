import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack } from "@mui/material";
import { whiteColor } from "@styles";
import * as entColActions from "@reducers/gestionEntretiens/entColReducer";
import { selectEntCol } from "@selectors";
import { EntretiensActionsBar } from "@components/GestionEntretiens/Collaborateur/ActionsBars";
import { useEntCol } from "@hooks/gestionEntretiens/hooksQueries";
import { trierEntCol } from "@components/GestionEntretiens/tries";
import { EntretiensList } from "@components/GestionEntretiens/Collaborateur/Lists";
import { MenuFiltreContainer, MenuTrieContainer } from "@components/Containers";
import { Badge, CalendarMonth, EventAvailable } from "@mui/icons-material";
import { FiltreEntretiensForm } from "@components/GestionEntretiens/Collaborateur/Forms";
import { useMedia } from "react-use";
import { ContenuDialog, DetailDialog, DetailDialogMobile, SectionsAccessiblesDialog } from "@components/GestionEntretiens/Collaborateur/Dialogs";
// import { DeleteDialog, DeleteDialogMobile, DetailDialog, DetailDialogMobile, ContenuDialog } from "@components/GestionEntretiens/Dialogs";

const optionsTrie = [
    {id: 1, label: "Par date", keyAsc: "dateAsc", keyDesc: "dateDesc", icon: <CalendarMonth fontSize="small" />},
    {id: 2, label: "Par nom", keyAsc: "nomAsc", keyDesc: "nomDesc", icon: <Badge fontSize="small" />},
    {id: 3, label: "Par statut", keyAsc: "statutAsc", keyDesc: "statutDesc", icon: <EventAvailable fontSize="small" />}
];

const EntretiensCollaborateur = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openFiltre, setOpenFiltre] = useState(false);
    const [openDetailDialog, setOpenDetailDialog] = useState(false);
    const [openSectionsDialog, setOpenSectionsDialog] = useState(false);
    const [openSectionsAccessiblesDialog, setOpenSectionsAccessiblesDialog] = useState(false);
    const stateEntretiens = useSelector(selectEntCol);
    const { isLoading, isFetching, isError, refetch } = useEntCol();
    const dispatch = useDispatch();
    const isMobile = useMedia('(max-width: 650px)');

    const openTrie = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const trier = useCallback((keyTrie) => {
        const data = [...stateEntretiens.entretiensDisplayed];
        const dataTried = trierEntCol(keyTrie, data);
        dispatch(entColActions.trier(dataTried));
    }, [dispatch, stateEntretiens.entretiensDisplayed]);

    const deleteTrie = useCallback(() => {
        dispatch(entColActions.deleteTrie());
    }, [dispatch]);

    const deleteFiltre = useCallback(() => {
        dispatch(entColActions.deleteFiltre());
    }, [dispatch]);

    const openDetail = useCallback((entretien) => {
        dispatch(entColActions.selectEntretien(entretien));
        setOpenDetailDialog(true);
    }, [dispatch]);

    const openSections = useCallback((entretien) => {
        dispatch(entColActions.selectEntretien(entretien));
        setOpenSectionsDialog(true);
    }, [dispatch]);

    const openSectionsAccessibles = useCallback((entretien) => {
        dispatch(entColActions.selectEntretien(entretien));
        setOpenSectionsAccessiblesDialog(true);
    }, [dispatch]);

    return (
        <>
            <Box bgcolor={whiteColor[9]} borderRadius={2} p={0.5} height="100%" boxSizing="border-box">
                <Stack spacing={0.5} height={"100%"}>
                    <EntretiensActionsBar
                        isLoading={isLoading || isFetching}
                        isError={isError}
                        filtered={stateEntretiens.filtered}
                        tried={stateEntretiens.tried}
                        openTrie={openTrie}
                        setOpenFiltre={setOpenFiltre}
                        deleteTrie={deleteTrie}
                        deleteFiltre={deleteFiltre}
                        refetch={refetch}
                    />
                    <EntretiensList 
                        data={stateEntretiens.entretiensDisplayed}
                        isLoading={isLoading || isFetching}
                        isError={isError}
                        openDetail={openDetail}
                        openSections={openSections}
                        openSectionsAccessibles={openSectionsAccessibles}
                    />
                    <MenuTrieContainer 
                        anchorEl={anchorEl} 
                        setAnchorEl={setAnchorEl} 
                        options={optionsTrie} 
                        trier={trier} 
                    />
                    <MenuFiltreContainer open={openFiltre} setOpen={setOpenFiltre}>
                        <FiltreEntretiensForm setOpen={setOpenFiltre} />
                    </MenuFiltreContainer>
                </Stack>
            </Box>
            {isMobile ? 
                <>
                    <DetailDialogMobile
                        open={openDetailDialog}
                        setOpen={setOpenDetailDialog}
                    />
                    <ContenuDialog
                        open={openSectionsDialog}
                        setOpen={setOpenSectionsDialog}
                    />
                    <SectionsAccessiblesDialog
                        open={openSectionsAccessiblesDialog}
                        setOpen={setOpenSectionsAccessiblesDialog}
                    />
                </>
            :
                <>
                    <DetailDialog
                        open={openDetailDialog}
                        setOpen={setOpenDetailDialog}
                    />
                    <ContenuDialog
                        open={openSectionsDialog}
                        setOpen={setOpenSectionsDialog}
                    />
                    <SectionsAccessiblesDialog
                        open={openSectionsAccessiblesDialog}
                        setOpen={setOpenSectionsAccessiblesDialog}
                    />
                </>
            }
        </>
    );
}

export default EntretiensCollaborateur;