import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getActAff, getActGesTemCol, getActGesTemMan, getActInp } from "@api/activites";
import { selectCltApp, selectColTemGesTem, selectDrgGesTem, selectRegGesTem, selectUsrApp } from "@selectors";
import * as drgGesTemActions from "@reducers/gestionTemps/regularisations/drgGesTemReducer";
import * as regGesTemActions from "@reducers/gestionTemps/regularisations/regGesTemReducer";
import * as actPoiGesTemActions from "@reducers/activites/actPoiGesTemReducer";
import * as actAffGesTemActions from "@reducers/activites/actAffGesTemReducer";
import moment from "moment";

export const useActRegGesTemCol = (open) => {
    const stateClient = useSelector(selectCltApp);
    const stateUser = useSelector(selectUsrApp);
    const stateDemandesRegularisation = useSelector(selectDrgGesTem);
    const date = stateDemandesRegularisation.dateDemandeRegularisation;
    const dispatch = useDispatch(); 

    const query =  useQuery(['actRegGesTemCol', date], ({ signal }) => getActGesTemCol("P", stateUser.matricule, date, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open
    });

    useEffect(() => {
        if(query.data) {
            let data = [{id: 1, value: "", label: "Aucune"}];
            query.data.forEach((activite) => {data.push({id: activite.id + 1, value: activite.activite, label: activite.libelle})});
            dispatch(drgGesTemActions.setActivites(data));
        }
    }, [dispatch, query.data]);

    return query;
}

export const useActRegGesTemMan = (open) => {
    const stateClient = useSelector(selectCltApp);
    const stateUser = useSelector(selectUsrApp);
    const stateRegularisation = useSelector(selectRegGesTem);
    const stateCollaborateurs = useSelector(selectColTemGesTem);
    const date = stateRegularisation.dateRegularisation;
    const dispatch = useDispatch(); 

    const query =  useQuery(['actRegGesTemMan', date], ({ signal }) => getActGesTemMan(stateUser.matricule, stateCollaborateurs?.collaborateurSelected?.typePersonnel, stateCollaborateurs?.collaborateurSelected?.matricule, date, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open
    });

    useEffect(() => {
        if(query.data) {
            let data = [{id: 1, value: "", label: "Aucune"}];
            query.data.forEach((activite) => {data.push({id: activite.id + 1, value: activite.activite, label: activite.libelle})});
            dispatch(regGesTemActions.setActivites(data));
        }
    }, [dispatch, query.data]);

    return query;
}

export const useActPoiGesTem = (type, enabled) => {
    const stateClient = useSelector(selectCltApp);
    const stateUser = useSelector(selectUsrApp);
    const date = moment().format("DDMMYYYY");
    const dispatch = useDispatch(); 

    const query =  useQuery(['actPoiGesTem', date], ({ signal }) => getActGesTemCol(type, stateUser.matricule, date, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: enabled
    });

    useEffect(() => {
        if(query.data) {
            const data = query.data.map((activite) => ({id: activite.id, value: activite.activite, label: activite.libelle}));
            dispatch(actPoiGesTemActions.setActivitesPointage(data));
        }
    }, [dispatch, query.data]);

    return query;
}

export const useActAffColInp = (codeSociete, date, open) => {
    const stateClient = useSelector(selectCltApp);
    const moisEffet = date ? date.format("MM") : "";
    const anneeEffet = date ? date.format("YYYY") : "";

    const query =  useQuery(['actAffColInp', date], ({ signal }) => getActAff(codeSociete, moisEffet, anneeEffet, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!codeSociete && !!date && open
    });

    return query;
}

export const useActAffIndInp = (codeSociete, date) => {
    const stateClient = useSelector(selectCltApp);
    const moisEffet = date ? date.format("MM") : "";
    const anneeEffet = date ? date.format("YYYY") : "";
    const dispatch = useDispatch();

    const query =  useQuery(['actAffIndInp', date], ({ signal }) => getActAff(codeSociete, moisEffet, anneeEffet, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!codeSociete && !!date && date?.isValid()
    });

    useEffect(() => {
        if(query.data) {
            dispatch(actAffGesTemActions.setActivitesAffectations(query.data));
        }
    }, [dispatch, query.data]);

    return query;
}

export const useActInp = (codeSociete, open) => {
    const stateClient = useSelector(selectCltApp);

    return useQuery(['actInp', codeSociete], ({ signal }) => getActInp(codeSociete, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open && !!codeSociete
    });
}
