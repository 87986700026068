import axios from "axios";
import { urls } from "@constants/appConstants";

/**
 * * Appel de la procédure PWASOL
 * * Url : get/soldes
 * @param {*} societe Société de l'utilisateur
 * @param {*} matricule Matricule de l'utilisateur
 * @returns Données soldes
 */
export const fetchSol = async (societe, matricule, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetSoldes"
    };
    const body = {
        societe: societe, 
        matricule: matricule
    };
    const response = await axios.post(urls.getSoldes, body, { headers: headers, params: { client: client }, signal: signal });
    return { periode: response.data.periode, soldes: response.data.soldes };
}

export const fetchMulSol = async (matricule, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetMultiSoldes"
    };
    const body = {
        matricule: matricule
    };
    const response = await axios.post(urls.getMultiSoldes, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.collaborateurs;
}

/**
 * * Appel de la procédure PWASIT
 * * Url : get/situation/solde
 * @param {*} matricule Matricule de l'utilisateur
 * @param {*} absence Absence du solde
 * @param {*} date Date du solde (période)
 * @param {*} client 
 * @param {*} signal 
 * @returns Situation du solde à une date
 */
export const fetchSitSol = async (matricule, absence, date, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetSituationSolde"
    };
    const body = {
        matricule: matricule, 
        codeAbsence: absence, 
        periodePaie: date
    };
    const response = await axios.post(urls.getSituationSolde, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.soldes;
}