import { useSelector, useDispatch } from "react-redux";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import * as mvtGesTemActions from "@reducers/gestionTemps/mouvements/mvtGesTemReducer"
import { TabContentContainer } from "@components/Containers";
import { MouvementsCollectifs, MouvementsIndividuels } from "@components/GestionTemps/Mouvements";
import { greenColor, greyColor, whiteColor } from "@styles";
import { selectMvtGesTem } from "@selectors";

const Mouvement = () => {
    const stateMouvements = useSelector(selectMvtGesTem);
    const dispatch = useDispatch();

    return (
        <Stack spacing={0.5} sx={styles.stackStyle}>
            <Box>
                <Tabs value={stateMouvements.activeTab} onChange={(_, tab) => dispatch(mvtGesTemActions.setActiveTab(tab))}
                    indicatorColor=""
                    sx={styles.tabsStyles} 
                    centered
                >            
                    <Tab label="Traitement Individuel" value="MVTIND" sx={styles.tabStyle} />               
                    <Tab label="Traitement Collectif" value="MVTCOL" sx={styles.tabStyle} />                        
                </Tabs>
            </Box>
            <TabContentContainer value={stateMouvements.activeTab} id="MVTIND">
                <MouvementsIndividuels />
            </TabContentContainer>
            <TabContentContainer value={stateMouvements.activeTab} id="MVTCOL">
                <MouvementsCollectifs />
            </TabContentContainer>
        </Stack>
    );
};

const styles = {
    stackStyle: {
        borderRadius: 2,
        position: "relative",
        height: "100%"
    },
    tabStyle: {
        color: greyColor[9],
        fontWeight: "bold",
        marginRight: 0.5,
        minHeight: "",
        border: "none",
        borderRadius: 2,
        flexGrow: 1,
        textTransform: "none",
        "&:hover": {
            backgroundColor: whiteColor[5],
            color: greyColor[9]
        },
        "&.Mui-selected": {
            backgroundColor: whiteColor[5],
            color: greyColor[9]
        }
    },
    tabsStyles: {
        height: 41,
        minHeight: "",
        paddingLeft: 0.5,
        paddingY: 0.5,
        backgroundColor: greenColor["015"], 
        borderRadius: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    }
};

export default Mouvement;