import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { Restaurant } from "@mui/icons-material";
import PropTypes from "prop-types";
import { PlagesDebutVariables, PlagesNonVariables, PlagesRepriseVariables, PlagesVariables } from "../PlagesTypes";
import { greenColor, redColor, whiteColor } from "@styles";

const Plages = (props) => {
    const { data, isLoading } = props;
    const plagesMatinExist = data?.matin?.plages?.debutVariable || data?.matin?.plages?.debutFixe || data?.matin?.plages?.repriseFixe || data?.matin?.plages?.repriseVariable;
    const plagesApresMidiExist = data?.apresMidi?.plages?.debutVariable || data?.apresMidi?.plages?.debutFixe || data?.apresMidi?.plages?.repriseFixe || data?.apresMidi?.plages?.repriseVariable;

    if(isLoading) {
        return <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={"100%"} height={144} />;
    }

    if(data?.noPlage) {
        return (
            <Box sx={styles.noPlageStyle}>
                <Typography fontSize="small">Aucune plage horaire</Typography>
            </Box>
        )
    }

    return (
        <Box sx={styles.boxPlagesHoraireStyle}>
            {plagesMatinExist && 
                <Stack spacing={0.5}>
                    <Box paddingX={1} paddingTop={1}>
                        <Box width="100%" bgcolor={greenColor["03"]} height={12} borderRadius={6} justifyContent={data?.matin?.position} display="flex">
                            <Box width={data?.matin?.longueur} bgcolor={redColor[3]} height={12} borderRadius={6}></Box>
                        </Box>
                    </Box>
                    {{
                        variable: <PlagesVariables debutVariable={data?.matin?.plages?.debutVariable} debutFixe={data?.matin?.plages?.debutFixe} repriseFixe={data?.matin?.plages?.repriseFixe} repriseVariable={data?.matin?.plages?.repriseVariable} />,
                        variableDebut: <PlagesDebutVariables debutVariable={data?.matin?.plages?.debutVariable} debutFixe={data?.matin?.plages?.debutFixe} repriseFixe={data?.matin?.plages?.repriseFixe} />,
                        variableReprise: <PlagesRepriseVariables debutFixe={data?.matin?.plages?.debutFixe} repriseFixe={data?.matin?.plages?.repriseFixe} repriseVariable={data?.matin?.plages?.repriseVariable} />,
                        noVariable: <PlagesNonVariables debutFixe={data?.matin?.plages?.debutFixe} repriseFixe={data?.matin?.plages?.repriseFixe} />
                    }[data?.matin?.type]}
                </Stack>
            }
            {data?.infos?.repas &&
                <Box textAlign={"center"}>
                    <Restaurant />
                    <Typography fontSize={"small"}>{data?.infos?.repas}</Typography> 
                </Box>
            }
            {plagesApresMidiExist && 
                <Stack spacing={0.5}>
                    <Box paddingX={1} paddingTop={1}>
                        <Box width="100%" bgcolor={greenColor["03"]} height={12} borderRadius={6} justifyContent={data?.apresMidi?.position} display="flex">
                            <Box width={data?.apresMidi?.longueur} bgcolor={redColor[3]} height={12} borderRadius={6}></Box>
                        </Box>
                    </Box>
                    {{
                        variable: <PlagesVariables debutVariable={data?.apresMidi?.plages?.debutVariable} debutFixe={data?.apresMidi?.plages?.debutFixe} repriseFixe={data?.apresMidi?.plages?.repriseFixe} repriseVariable={data?.apresMidi?.plages?.repriseVariable} />,
                        variableDebut: <PlagesDebutVariables debutVariable={data?.apresMidi?.plages?.debutVariable} debutFixe={data?.apresMidi?.plages?.debutFixe} repriseFixe={data?.apresMidi?.plages?.repriseFixe} />,
                        variableReprise: <PlagesRepriseVariables debutFixe={data?.apresMidi?.plages?.debutFixe} repriseFixe={data?.apresMidi?.plages?.repriseFixe} repriseVariable={data?.apresMidi?.plages?.repriseVariable} />,
                        noVariable: <PlagesNonVariables debutFixe={data?.apresMidi?.plages?.debutFixe} repriseFixe={data?.apresMidi?.plages?.repriseFixe} />
                    }[data?.apresMidi?.type]}
                </Stack>
            }
        </Box>
    )
}

Plages.defaultProps = {
    data: null,
    isLoading: true
}

Plages.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool
}

const styles = {
    boxPlagesHoraireStyle: {
        backgroundColor: whiteColor[5], 
        p:1,
        borderRadius: 2,
    },
    noPlageStyle: {  
        height: "144px",
        background: greenColor["02"],
        borderRadius: 2,
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
    }
}

export default Plages;