import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { greenColor, greyColor, whiteColor } from "@styles";
import { TextContainer } from "@components/Containers";
import { Loader } from "@components/Loaders";
import { ErrorBase } from "@components/Errors";
import PhotoCollaborateur from "@components/App/PhotoCollaborateur";
import { Fragment } from "react";

const modeRoute = {
    D: "Validation directe",
    H: "Validation par hiérarchie",
    C: "Niveau de contrôle"
};

const DroitsListMobile = (props) => {
    const { 
        data, 
        isLoading, 
        isError,
        errorMessage
    } = props;

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return <ErrorBase>{errorMessage}</ErrorBase>;
    }

    return (
        <Box height="100%" overflow="auto">   
            <Stack spacing={0.5} bgcolor={greenColor["015"]} p={0.5} borderRadius={2}>
                {data.map((societe) => (
                    <Box key={`DDROITSOCLIST${societe.codeSociete}`}>
                        <Stack spacing={0.5}>
                            <TextContainer bgColor={whiteColor[5]} p={1} textWeight="bold" horizontalAlign="center">{societe.libelleSociete}</TextContainer>
                            <Box>                                    
                                <Stack spacing={0.5}>
                                    {societe.servicesCentreCouts.map((serviceCentreCout) => (
                                        serviceCentreCout.natures.map((nature) => (
                                            <Fragment key={`DDROITNATLIST${serviceCentreCout.codeService}${serviceCentreCout.codeCentreCout}${nature.nature}`}>
                                                <TextContainer bgColor={whiteColor[5]} p={0.5} height={"100%"} horizontalAlign="center" verticalAlign="center">
                                                    {serviceCentreCout.codeService ? 
                                                        <Typography fontSize="small" fontWeight="bold">{serviceCentreCout.codeService} {serviceCentreCout.libelleService}</Typography> 
                                                    : 
                                                        <Typography fontSize="small" fontWeight="bold">{serviceCentreCout.codeCentreCout} {serviceCentreCout.libelleCentreCout}</Typography> 
                                                    }
                                                </TextContainer>
                                                <TextContainer bgColor={whiteColor[5]} p={0.5} height={"100%"} horizontalAlign="center" verticalAlign="center">{nature.nature}</TextContainer>
                                                <Stack spacing={0.5} direction="row" overflow="auto">
                                                    {nature.gestionnaires.map((gestionnaire) => (
                                                        <Stack direction="row" spacing={1} key={`DDROITGESTLIST${gestionnaire.id}`} bgcolor={whiteColor[5]} p={0.5} borderRadius={2} minWidth={"230px"} display="flex" alignItems="center">
                                                            <Box>
                                                                <PhotoCollaborateur photo={gestionnaire.photo} nom={gestionnaire.prenomNom} buffered={false} />
                                                            </Box>
                                                            <Box>
                                                                <Typography fontSize="11px">{gestionnaire.prenomNom}</Typography>
                                                                <Typography fontSize="11px">Niveau {gestionnaire.niveau}</Typography>
                                                                <Typography fontSize="11px">{modeRoute[gestionnaire.mode]}</Typography>
                                                            </Box>
                                                        </Stack>
                                                    ))}
                                                </Stack>
                                            </Fragment>
                                        ))
                                    ))}
                                </Stack>
                            </Box>
                        </Stack>
                    </Box>
                ))}
            </Stack>
        </Box>
    );
}

DroitsListMobile.defaultProps = {
    data: [], 
    isLoading: true, 
    isError: false,
    errorMessage: ""
}

DroitsListMobile.propTypes = {
    data: PropTypes.array, 
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool,
    errorMessage: PropTypes.string
}

const styles = {
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        height: "100%",
    }
}

export default DroitsListMobile;