import { useStatePlanning } from "./hooksStates";

export const useCollaborateur = (type, emetteur, hierarchie) => {
    const statePlanning = useStatePlanning(type);

    if(hierarchie) {
        return statePlanning.collaborateurs;
    }

    if(emetteur) {
        if(statePlanning.allServices) {
            return statePlanning.societes;
        } else {
            if(statePlanning.societes[0].societe.trim() === statePlanning.emetteur.societe.trim() && statePlanning.societes[0].services[0].service.trim() === statePlanning.emetteur.service.trim()) {
                return([
                    {
                        societe: statePlanning.societes[0].societe, 
                        services: [statePlanning.societes[0].services[0]]
                    }
                ])
            } else {
                return ([]);
            }
        }
    }

    return statePlanning.societes;
}