import { Box, Grid, IconButton } from "@mui/material";
import { Info } from "@mui/icons-material";
import moment from "moment";
import PropTypes from "prop-types";
import { ListContainer, TextContainer } from "@components/Containers";
import { ErrorBase } from "@components/Errors";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, whiteColor } from "@styles";

const RegularisationsList = (props) => {
    const { data, isLoading, isError, errorMessage, anomalies, openAnomalies } = props;

    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader size={20} colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return (<ErrorBase>{errorMessage}</ErrorBase>);
    }

    return (
        <ListContainer spacing={0.5} length={data?.length} noDataLabel="Aucune régularisation">
            {data.map((regularisation, id) => (
                <Box key={`REGU${regularisation.id}`} bgcolor={greenColor["015"]} borderRadius={2} p={0.25}>
                    <Grid container spacing={0.5}>
                        <Grid item xs={2}>
                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{regularisation.libelleMouvement}</TextContainer>
                        </Grid>
                        <Grid item xs={2}>
                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{moment([regularisation.anneeRegularisation, regularisation.moisRegularisation - 1, regularisation.jourRegularisation]).format("DD/MM/YYYY")}</TextContainer>
                        </Grid>
                        <Grid item xs={2}>
                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{moment(regularisation.heureRegularisation, "H").format("HH")}h{moment(regularisation.minuteRegularisation, "m").format("mm")}</TextContainer>
                        </Grid>
                        <Grid item xs={anomalies ? 2.25 : 3}>
                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{regularisation.libelleActivite}</TextContainer>
                        </Grid>
                        <Grid item xs={anomalies ? 2.25 : 3}>
                            <TextContainer bgColor={whiteColor[5]} p={0.75} verticalAlign="center" height={"100%"}>{regularisation.libelleTypeTravail}</TextContainer>
                        </Grid>
                        {anomalies &&
                            <Grid item xs={1.5} textAlign="center" display="flex" alignItems="center" justifyContent="center">
                                {regularisation?.anomalies?.length > 0 &&
                                    <IconButton size="small" onClick={() => openAnomalies(regularisation)}>
                                        <Info fontSize="small" />
                                    </IconButton>
                                }
                            </Grid>
                        }
                    </Grid>
                </Box>
            ))}
        </ListContainer>
    );
}

RegularisationsList.defaultProps = {
    data: [],
    isLoading: true,
    isError: false,
    errorMessage: "",
    anomalies: true,
    openAnomalies: () => {}
}

RegularisationsList.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
    anomalies: PropTypes.bool,
    openAnomalies: PropTypes.func
}

const styles = {
    loaderStyle: {
        backgroundColor: greenColor["015"],
        borderRadius: 2,
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        p: 1,
    }
}

export default RegularisationsList;