export const selectUsrApp = (state) => state.usrApp
export const selectCltApp = (state) => state.cltApp
export const selectSecVarApp = (state) => state.secVarApp
export const selectDlgApp = (state) => state.dlgApp
export const selectStaUsr = (state) => state.staUsr
export const selectTemGesTemCol = (state) => state.temGesTemCol
export const selectTemGesTemMan = (state) => state.temGesTemMan
export const selectPlaDabCol = (state) => state.plaDabCol
export const selectPlaDabMan = (state) => state.plaDabMan
export const selectPlaGlo = (state) => state.plaGlo
export const selectPlaHrc = (state) => state.plaHrc
export const selectPlaGesTem = (state) => state.plaGesTem
export const selectPlaHor = (state) => state.plaHor
export const selectDsh = (state) => state.dsh
export const selectDabGesAbsCol = (state) => state.dabGesAbsCol
export const selectDabGesAbsMan = (state) => state.dabGesAbsMan
export const selectCalGesAbs = (state) => state.calGesAbs
export const selectSolGesAbs = (state) => state.solGesAbs
export const selectSitSolApp = (state) => state.sitSolApp
export const selectHisDabGesAbs = (state) => state.hisDabGesAbs
export const selectDocApp = (state) => state.docApp
export const selectTabApp = (state) => state.tabApp
export const selectOrgApp = (state) => state.orgApp
export const selectDrgGesTem = (state) => state.drgGesTem
export const selectRegGesTem = (state) => state.regGesTem
export const selectComGesTem = (state) => state.comGesTem
export const selectCreGesTem = (state) => state.creGesTem
export const selectMvtGesTem = (state) => state.mvtGesTem
export const selectColTemGesTem = (state) => state.colTemGesTem
export const selectColPlaGesTem = (state) => state.colPlaGesTem
export const selectColAffHorInd = (state) => state.colAffHorInd
export const selectColAffHorCol = (state) => state.colAffHorCol
export const selectColAffActInd = (state) => state.colAffActInd
export const selectColAffActCol = (state) => state.colAffActCol
export const selectColCreGesTem = (state) => state.colCreGesTem
export const selectColMvtGesTem = (state) => state.colMvtGesTem
export const selectEntMan = (state) => state.entMan
export const selectEntCol = (state) => state.entCol
export const selectEntTabMan = (state) => state.entTabMan
export const selectEntTabCol = (state) => state.entTabCol
export const selectEntSecMan = (state) => state.entSecMan
export const selectEntSecCol = (state) => state.entSecCol
export const selectActPoiGesTem = (state) => state.actPoiGesTem
export const selectActAffGesTem = (state) => state.actAffGesTem
export const selectTypTraApp = (state) => state.typTraApp
export const selectAnoGesTem = (state) => state.anoGesTem
export const selectAffGesTem = (state) => state.affGesTem
export const selectAffHorInd = (state) => state.affHorInd
export const selectAffActInd = (state) => state.affActInd
export const selectHorAffGesTem = (state) => state.horAffGesTem
export const selectSocAffHorCol = (state) => state.socAffHorCol
export const selectSocAffActCol = (state) => state.socAffActCol
export const selectSocOrg = (state) => state.socOrg
export const selectHeuGesTem = (state) => state.heuGesTem
export const selectNotApp = (state) => state.notApp