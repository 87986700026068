import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Stack, Typography, TabScrollButton, Tabs, Tab } from "@mui/material";
import ContainerTabs from '@mui/base/Tabs';
import { Close } from "@mui/icons-material";
import { routePolices } from "@constants/policesConstants";
import { selectTabApp, selectUsrApp } from "@selectors";
import * as tabAppActions from "@reducers/tabs/tabAppReducer";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";
import { useRmvTab } from "@hooks/tabs/hooksUtils";
import AppTabsContent from "./AppTabsContent";
import { useTranslation } from "react-i18next";

const AppTabs = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateTabs = useSelector(selectTabApp);
    const dispatch = useDispatch(); 
    const removeTabActionner = useRmvTab();
    const { t } = useTranslation("global");
    const noBackground = stateUser.background === "image0";

    const handleTabs = useCallback((_event, tab) => {
        dispatch(tabAppActions.setActiveTab(tab));
    }, [dispatch]);

    const ScrollButtons = useCallback((props) => (<TabScrollButton {...props} sx={noBackground ? styles.tabScrollButtonNoBackgroundStyle : styles.tabScrollButtonStyle} />), [noBackground]);

    return(   
        <ContainerTabs value={stateTabs.activeTab}>
            <Stack spacing={1}>
                <Box width="100%">
                    <Tabs 
                        value={stateTabs.activeTab} 
                        onChange={handleTabs} 
                        indicatorColor=""
                        sx={noBackground ? styles.tabsNoBackgroundStyles : styles.tabsStyles} 
                        variant="scrollable"
                        scrollButtons="auto"
                        ScrollButtonComponent={ScrollButtons}
                    >
                        {stateTabs.tabs.map((tab, id) => (  
                            <Tab 
                                key={`TAB${tab.action}`}
                                value={tab.action}  
                                sx={noBackground ? styles.tabNoBackgroundStyle : styles.tabStyle} 
                                label={
                                    <Grid container alignItems="center">
                                        <Grid item xs={1.25}></Grid>
                                        <Grid textOverflow="ellipsis" item xs={9.5}>
                                            <Typography fontSize="small" fontWeight="bold" lineHeight={1.3} fontFamily={routePolices[stateUser.police]}>{t(`navigation.${tab.action}`)}</Typography>
                                        </Grid>
                                        <Grid item xs={1.25}>
                                            {(tab.action !== "ACC010000" && stateTabs.activeTab === tab.action) && 
                                                <Box position="absolute" top={7} right={7} onClick={(event) => removeTabActionner(event, id, tab.action)}>
                                                    <Box bgcolor={whiteColor[9]} borderRadius={12.5} height={25} width={25} justifyContent="center" alignItems="center" display="flex" sx={styles.buttonStyle}>
                                                        <Close fontSize="small" sx={{color: redColor[5]}} />
                                                    </Box>
                                                </Box> 
                                            }
                                        </Grid>
                                    </Grid>
                                }
                            >
                            </Tab> 
                        ))}
                    </Tabs>
                </Box>
                <Box width="100%" height={"calc(100vh - 136px)"}>
                    <AppTabsContent />
                </Box>
            </Stack>
        </ContainerTabs> 
    );
}

const styles = {
    tabsStyles: {
        height: 40,
        minHeight: "",
        paddingLeft: 0.5,
        paddingY: 0.5,
        backgroundColor: whiteColor[9], 
        borderRadius: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    tabsNoBackgroundStyles: {
        height: 40,
        minHeight: "",
        paddingLeft: 0.5,
        paddingY: 0.5,
        backgroundColor: greyColor[2], 
        borderRadius: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    tabScrollButtonStyle: {
        height: 40, 
        marginX: 0.5, 
        borderRadius: 2,
        backgroundColor: greenColor["025"],
        "&.MuiTabScrollButton-root:first-of-type": {
            marginX: 0,
            marginRight: 0.5
        },
        "&:hover": {
            backgroundColor: greenColor["07"],
            color: whiteColor[9]
        }
    },
    tabScrollButtonNoBackgroundStyle: {
        color: whiteColor[9],
        height: 40, 
        marginX: 0.5, 
        borderRadius: 2,
        backgroundColor: greyColor[2],
        "&.MuiTabScrollButton-root:first-of-type": {
            marginX: 0,
            marginRight: 0.5
        },
        "&:hover": {
            backgroundColor: whiteColor[5],
            color: greyColor[9]
        }
    },
    tabStyle: {
        color: greyColor[9],
        fontWeight: "bold",
        backgroundColor: greenColor["025"],
        marginRight: 0.5,
        padding: 0,
        minHeight: 40,
        minWidth: "25%",
        border: "none",
        borderRadius: 2,
        flexGrow: 1,
        "&:hover": {
            backgroundColor: greenColor["07"],
            color: whiteColor[9]
        },
        "&.Mui-selected": {
            backgroundColor: greenColor["07"],
            color: whiteColor[9]
        }
    },
    tabNoBackgroundStyle: {
        color: whiteColor[9],
        fontWeight: "bold",
        backgroundColor: greyColor[2],
        marginRight: 0.5,
        padding: 0,
        minHeight: 40,
        minWidth: "25%",
        border: "none",
        borderRadius: 2,
        flexGrow: 1,
        "&:hover": {
            backgroundColor: whiteColor[5],
            color: greyColor[9]
        },
        "&.Mui-selected": {
            backgroundColor: whiteColor[5],
            color: greyColor[9]
        }
    },
    buttonStyle: {
        "&:hover": {
            backgroundColor: redColor[2],
            "svg": {
                color: whiteColor[9]
            }
        }
    },
    boxOnglets: {
        zIndex: (theme) => theme.zIndex.drawer
    }
};

export default AppTabs;