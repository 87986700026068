import * as ExcelJS from 'exceljs'
import { saveAs } from "file-saver";
import { modelizeAnomalies, modelizeAnomaliesActivite, modelizeAnomaliesCredit, modelizeHeuresCumulees, modelizeHeuresDetailleesAvecMouvements, modelizeHeuresDetailleesSansMouvements, modelizeMouvements, modelizePlanning, modelizePlanningGestionTemps } from "@modeles";

export const generateTableurAnomalie = (title, anomalies) => {
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet(title);

    modelizeAnomalies(worksheet, anomalies);

    workbook.xlsx.writeBuffer().then(function(buffer) {
        saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Anomalies.xlsx"
        );
    });
}

export const generateTableurAnomalieCredit = (title, anomalies) => {
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet(title);

    modelizeAnomaliesCredit(worksheet, anomalies);

    workbook.xlsx.writeBuffer().then(function(buffer) {
        saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Anomalies Crédit.xlsx"
        );
    });
}

export const generateTableurAnomalieActivite = (title, anomalies) => {
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet(title);

    modelizeAnomaliesActivite(worksheet, anomalies);

    workbook.xlsx.writeBuffer().then(function(buffer) {
        saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Anomalies Activité.xlsx"
        );
    });
}

export const generateTableurHeuresCumulees = (title, heures) => {
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet(title);

    modelizeHeuresCumulees(worksheet, heures);

    workbook.xlsx.writeBuffer().then(function(buffer) {
        saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Liste_Heures_Cumulées.xlsx"
        );
    });
}

export const generateTableurHeuresDetailleesAvecMouvements = (title, heures) => {
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet(title);

    modelizeHeuresDetailleesAvecMouvements(worksheet, heures);

    workbook.xlsx.writeBuffer().then(function(buffer) {
        saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Liste_Heures_Détaillées.xlsx"
        );
    });
}

export const generateTableurHeuresDetailleesSansMouvements = (title, heures) => {
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet(title);

    modelizeHeuresDetailleesSansMouvements(worksheet, heures);

    workbook.xlsx.writeBuffer().then(function(buffer) {
        saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Liste_Heures_Détaillées.xlsx"
        );
    });
}

export const generateTableurPlanning = (title, semaines, societes) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(title);

    modelizePlanning(worksheet, semaines, societes);

    workbook.xlsx.writeBuffer().then(function(buffer) {
        saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Planning.xlsx"
        );
    });
}

export const generateTableurPlanningGestionTemps = (title, headers, collaborateurs, tri) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(title);

    modelizePlanningGestionTemps(worksheet, headers, collaborateurs, tri);

    workbook.xlsx.writeBuffer().then(function(buffer) {
        saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Planning.xlsx"
        );
    });
}

export const generateTableurMouvement = (title, mouvements, detail = true) => {
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet(title);

    modelizeMouvements(worksheet, mouvements, detail);

    workbook.xlsx.writeBuffer().then(function(buffer) {
        saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Mouvements.xlsx"
        );
    });
}