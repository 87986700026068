import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BaseButton } from "@components/Buttons";
import { Box, Stack, Typography } from "@mui/material";
import * as userActions from "@reducers/user/usrAppReducer";
import { selectUsrApp } from "@selectors";
import { whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const PoliceValue = () => {
    const stateUser = useSelector(selectUsrApp);
    const dispatch = useDispatch();
    const { t } = useTranslation("global");

    const handlePolice = useCallback(() => {
        dispatch(userActions.openPolice(true));
    }, [dispatch]);

    return (
        <Stack direction="row" spacing={0.5}>
            <Box bgcolor={whiteColor[5]} borderRadius={2} width={90} display="flex" justifyContent="center" alignItems="center" paddingX={1}>
                <Typography fontSize="small" fontFamily={stateUser.police}>{stateUser.police}</Typography>
            </Box>
            <Stack spacing={0.5} flexGrow={1}>
                <Box bgcolor={whiteColor[5]} borderRadius={2} height={34} display="flex" alignItems="center" justifyContent="center">
                    <Typography fontWeight="bold" fontSize="1.2rem">{t("titles.font")}</Typography>
                </Box>
                <Box flexGrow={1}>
                    <BaseButton fontSize="medium" height={34} action={handlePolice}>{t("buttons.edit_font")}</BaseButton>
                </Box>
            </Stack>
        </Stack>
    );
}

export default PoliceValue;