import { Box, Stack } from "@mui/material";
import { greenColor, whiteColor } from "@styles";
import { FormProvider, useForm } from "react-hook-form";
import { MouvementsCollectifsForm } from "./Forms";
import { joiResolver } from "@hookform/resolvers/joi";
import { schemaMouvementsCollectifs } from "./validations";
import { useDownloadMvt } from "@hooks/gestionTemps/mouvements/hooksQueries";
import { HeaderPanelContainer } from "@components/Containers";
import { IconButtonBase, LoaderButton } from "@components/Buttons";
import { DownloadForOffline, FilterListOff } from "@mui/icons-material";
import moment from "moment";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { formToDataMouvement } from "@integrations";
import { selectCltApp, selectUsrApp } from "@selectors";

const defaultValues = {
    dateDebut: moment(),
    dateFin: moment(),
    societe: null,
    etablissement: null,
    direction: null,
    service: null,
    secteur: null,
    emploi: null,
    horaire: null,
    jour: "0",
    evenement: "",
    heureLimite: "",
    detail: true
};

const MouvementsCollectifs = () => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const methods = useForm({ mode: "all", defaultValues: defaultValues, resolver: joiResolver(schemaMouvementsCollectifs) });
    const { isLoading, downloadActionner } = useDownloadMvt();

    const downloadMouvement = useCallback((data) => {
        methods.reset();
        const droitSalarie = stateUser.droitSalarie ? "O" : "N";
        const droitInterimaire = stateUser.droitInterimaire ? "O" : "N";
        const formatedData = formToDataMouvement(data, stateUser.utilisateurAS400, stateUser.matricule, "", droitSalarie, droitInterimaire, stateClient.client);
        downloadActionner(formatedData);
    }, [downloadActionner, methods, stateClient.client, stateUser.droitInterimaire, stateUser.droitSalarie, stateUser.matricule, stateUser.utilisateurAS400]);

    return (
        <Stack spacing={0.5} sx={styles.stackStyle}>
            <HeaderPanelContainer 
                actionsLeft={
                    <IconButtonBase icon={<FilterListOff fontSize="small" />} title={"Reset filtres"} action={() => methods.reset()} />
                }
                actionsRight={
                    <Box display="flex" justifyContent="right" alignItems="center">
                        <LoaderButton title="Exporter les mouvements au format excel" isLoading={isLoading} icon={<DownloadForOffline fontSize="small" />} action={() => methods.handleSubmit(downloadMouvement)()} />
                    </Box>  
                }   
                bgColor={whiteColor[5]}
            /> 
            <FormProvider {...methods}>
                <MouvementsCollectifsForm />
            </FormProvider>
        </Stack>
    );
};

const styles = {
    stackStyle: {
        overflow: "auto",
        bgcolor: greenColor["015"],
        borderRadius: 2,
        boxSizing: "border-box",
        padding: 0.5,
        position: "relative",
        height: "100%"
    }
};

export default MouvementsCollectifs;