import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Stack } from "@mui/material";
import { EventAvailable, QuestionAnswerOutlined } from "@mui/icons-material";
import { selectComGesTem, selectTemGesTemCol } from "@selectors";
import * as comGesTemActions from "@reducers/gestionTemps/compteurs/comGesTemReducer"
import { MenuFiltreContainer, MenuTrieContainer, TextContainer } from "@components/Containers";
import { CompteursActionsBar } from "@components/GestionTemps/Compteurs/ActionsBars";
import { useComGesTem } from "@hooks/gestionTemps/compteurs/hooksQueries";
import { CompteursList } from "@components/GestionTemps/Compteurs/Lists";
import { trierComGesTem } from "@components/GestionTemps/Compteurs/tries";
import { FiltreCompteursForm } from "@components/GestionTemps/Compteurs/Forms";
import { greenColor, whiteColor } from "@styles";
import numeral from "numeral";
import "numeral/locales/fr";
import moment from "moment";

const optionsTrie = [
    {id: 1, label: "Par désignation", keyAsc: "designationAsc", keyDesc: "designationDesc", icon: <EventAvailable fontSize="small" />},
    {id: 2, label: "Par valeur", keyAsc: "valeurAsc", keyDesc: "valeurDesc", icon: <EventAvailable fontSize="small" />},
    {id: 3, label: "Par valeur", keyAsc: "maximumAsc", keyDesc: "maximumDesc", icon: <QuestionAnswerOutlined fontSize="small" />},
];

numeral.locale("fr");

const CompteursMobile = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openFiltre, setOpenFiltre] = useState(false);
    const [date, setDate] = useState()
    const stateCompteurs = useSelector(selectComGesTem);
    const stateTemps = useSelector(selectTemGesTemCol);
    const { isLoading, isFetching, isError, refetch } = useComGesTem(date);
    const dispatch = useDispatch();
    
    useEffect(() => {
        setDate(stateTemps.dateTemps);
    }, [dispatch, stateTemps.dateTemps]);

    const openTrie = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const trier = useCallback((keyTrie) => {
        const data = [...stateCompteurs.compteursDisplayed];
        const dataTried = trierComGesTem(keyTrie, data);
        dispatch(comGesTemActions.trier(dataTried));
    }, [dispatch, stateCompteurs.compteursDisplayed]);

    const toggleList = useCallback(() => {
        dispatch(comGesTemActions.lister());
    }, [dispatch]);

    const deleteTrie = useCallback(() => {
        dispatch(comGesTemActions.deleteTrie());
    }, [dispatch]);

    const deleteFiltre = useCallback(() => {
        dispatch(comGesTemActions.deleteFiltre());
    }, [dispatch]);
    
    const selectDate =  useCallback((date) => {
        setDate(date.format("DDMMYYYY"));
    }, []);
    
    return (
        <Stack spacing={0.5}  bgcolor={greenColor["015"]} borderRadius={2} p={0.5} height="100%" boxSizing="border-box">
            <TextContainer p={1} bgColor={whiteColor[5]} textWeight="bold">
                Compteurs à partir du {moment(date, "DDMMYYYY").format("DD/MM/YYYY")}
            </TextContainer>
            <CompteursList 
                data={stateCompteurs?.compteursDisplayed}
                isLoading={isLoading || isFetching}
                isError={isError}
                listed={stateCompteurs.listed}
                horaire={stateTemps.horaire}
            />
            <CompteursActionsBar 
                date={date}
                isLoading={isLoading || isFetching} 
                isError={isError} 
                listed={stateCompteurs.listed} 
                tried={stateCompteurs.tried}
                filtered={stateCompteurs.filtered}
                toggleList={toggleList} 
                openTrie={openTrie} 
                setOpenFiltre={setOpenFiltre} 
                selectDate={selectDate}
                deleteTrie={deleteTrie}
                deleteFiltre={deleteFiltre} 
                refetch={refetch}
            />
            <MenuTrieContainer 
                anchorEl={anchorEl} 
                setAnchorEl={setAnchorEl} 
                options={optionsTrie} 
                trier={trier} 
            />
            <MenuFiltreContainer open={openFiltre} setOpen={setOpenFiltre}>
                <FiltreCompteursForm setOpen={setOpenFiltre} />
            </MenuFiltreContainer>
        </Stack>
    );
};

export default CompteursMobile;