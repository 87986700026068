import { useCallback, useState } from "react";
import { Backdrop, Box, Stack } from "@mui/material";
import { WarningRounded } from "@mui/icons-material";
import { DatePicker, LocalizationProvider, PickersDay, PickersLayoutContentWrapper, PickersLayoutRoot, frFR, pickersLayoutClasses, usePickerLayout } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import IconDatePickerButton from "../IconDatePickerButton";
import BaseButton from "@components/Buttons/BaseButton";
import { greenColor, greyColor } from "@styles";

const CustomPickersDay = styled(PickersDay, {shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered'})(({ isSelected, isHovered, day }) => ({
    borderRadius: 0,
    ...(isSelected && {
        backgroundColor: greenColor["025"],
        '&:hover, &:focus': {
            backgroundColor: greenColor["025"],
        },
    }),
    ...(isHovered && {
        backgroundColor: greenColor["025"],
        '&:hover, &:focus': {
            backgroundColor: greenColor["025"],
        },
    }),
    ...(day.day() === 0 && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
    ...(day.day() === 1 && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
}));

const isInSameWeek = (dayA, dayB) => {
    if (dayB == null) {
        return false;
    }
  
    return dayA.isSame(dayB, 'week');
};

const Day = (props) => {
    const { day, selectedDay, hoveredDay, selectDay, ...other } = props;
  
    return (
        <CustomPickersDay
            {...other}
            day={day}
            sx={{ px: 2.5 }}
            disableMargin
            selected={false}
            isSelected={isInSameWeek(day, selectedDay)}
            isHovered={isInSameWeek(day, hoveredDay)}
            onDaySelect={(date) => selectDay(date)}
        />
    );
}

const LayoutPicker = (props) => {
    const { openWarning, setOpenWarning, action, dateSelected, ...other } = props;
    const { tabs, content } = usePickerLayout({...other});

    return (
        <PickersLayoutRoot className={pickersLayoutClasses.root} ownerState={{...other}}>
            <PickersLayoutContentWrapper className={pickersLayoutClasses.contentWrapper}>
                {tabs}
                {content}
                <Backdrop open={openWarning} sx={{borderRadius: 2, bgcolor: greyColor[7]}}>
                    <Stack spacing={1} alignItems="center">
                        <Box>
                            <WarningRounded fontSize="large" sx={{color: "white"}} />
                        </Box>
                        <BaseButton color="verywhite2" height={50} action={() => action(dateSelected, true)}>
                            Sauvergarder les modifications
                        </BaseButton>
                        <BaseButton color="verywhite2" height={50} action={() => action(dateSelected)}>
                            Ne pas sauvegarder les modifications
                        </BaseButton>
                        <BaseButton color="verywhite2" height={50} action={() => setOpenWarning(false)}>
                            Fermer
                        </BaseButton>
                    </Stack>
                </Backdrop>
            </PickersLayoutContentWrapper>
        </PickersLayoutRoot>
    )
}

const WeekPickerPlanningButton = (props) => {
    const { date, icon, title, placement, timeout, noClick, action } = props;
    const [open, setOpen] = useState(false);
    const [dateSelected, setDateSelected] = useState(null);
    const [openWarning, setOpenWarning] = useState(false);
    const [hoveredDay, setHoveredDay] = useState(null);

    const handleClose = () => {
        setOpen(false);
        setOpenWarning(false);
    };

    const handleOpen = useCallback((date) => {
        setOpenWarning(true);
        setDateSelected(date);
    }, []);

    const actionSelection = useCallback((date) => {
        setOpen(false);
        setOpenWarning(false);
        action(date);
    }, [action]);

    return (  
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"frFR"} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
            <DatePicker
                slots={{ field: IconDatePickerButton, day: Day, layout: LayoutPicker }}
                slotProps={{ 
                    field: { icon, title, placement, timeout, noClick, setOpen }, 
                    day: (ownerState) => ({
                        selectedDay: date,
                        hoveredDay,
                        onPointerEnter: () => setHoveredDay(ownerState.day),
                        onPointerLeave: () => setHoveredDay(null),
                        selectDay: handleOpen
                    }), 
                    layout: 
                        {
                            openWarning: openWarning,
                            setOpenWarning: setOpenWarning,
                            action: actionSelection,
                            dateSelected: dateSelected
                        }
                    ,
                    desktopPaper: {sx: { px:1 }}
                }}
                showDaysOutsideCurrentMonth
                displayWeekNumber
                open={open}
                views={["year", "month", "day"]}
                value={date}
                closeOnSelect={false}
                onClose={() => handleClose()}
                onOpen={() => setOpen(true)}
            />
        </LocalizationProvider>
    );
}

WeekPickerPlanningButton.propTypes = {
    date: null,
    icon: null, 
    title: "", 
    placement: "bottom", 
    timeout: 200, 
    noClick: false, 
    action: () => {}
}

WeekPickerPlanningButton.propTypes = {
    date: PropTypes.object, 
    icon: PropTypes.element, 
    title: PropTypes.string, 
    placement: PropTypes.string, 
    timeout: PropTypes.number, 
    noClick: PropTypes.bool, 
    action: PropTypes.func
}

export default WeekPickerPlanningButton;