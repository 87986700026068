import { useDispatch } from "react-redux";
import * as colTemGesTemActions from "@reducers/collaborateurs/colTemGesTemReducer";
import * as colPlaGesTemActions from "@reducers/collaborateurs/colPlaGesTemReducer";
import * as colAffHorIndActions from "@reducers/collaborateurs/colAffHorIndReducer";
import * as colAffHorColActions from "@reducers/collaborateurs/colAffHorColReducer";
import * as colAffActIndActions from "@reducers/collaborateurs/colAffActIndReducer";
import * as colAffActColActions from "@reducers/collaborateurs/colAffActColReducer";
import * as colCreGesTemActions from "@reducers/collaborateurs/colCreGesTemReducer";
import * as colMvtGesTemActions from "@reducers/collaborateurs/colMvtGesTemReducer";
import { TYPES_COLLABORATEURS } from "@constants/collaborateursConstants";

export const useSelectDateCollaborateurs = (type) => {
    const dispatch = useDispatch();

    return (date) => {
        const dateToStore = date.format("DDMMYYYY");
        if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_TEMPS) {
            dispatch(colTemGesTemActions.setDate(dateToStore));
        }
        if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_PLANNING) {
            const dateFormated = date.weekday(0).format("DDMMYYYY")
            dispatch(colPlaGesTemActions.setDate(dateFormated));
            dispatch(colPlaGesTemActions.resetCollaborateur());
        }
        if(type === TYPES_COLLABORATEURS.AFFECTATIONS_HORAIRES_INDIVIDUELLES) {
            dispatch(colAffHorIndActions.setDate(dateToStore));
        }
        if(type === TYPES_COLLABORATEURS.AFFECTATIONS_HORAIRES_COLLECTIVES) {
            dispatch(colAffHorColActions.setDate(dateToStore));
        }
        if(type === TYPES_COLLABORATEURS.AFFECTATIONS_ACTIVITES_INDIVIDUELLES) {
            dispatch(colAffActIndActions.setDate(dateToStore));
        }
        if(type === TYPES_COLLABORATEURS.AFFECTATIONS_ACTIVITES_COLLECTIVES) {
            dispatch(colAffActColActions.setDate(dateToStore));
        }
        if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_CREDIT) {
            dispatch(colCreGesTemActions.setDate(dateToStore));
        }
    }
}

export const useResetTrieCollaborateurs = (type) => {
    const dispatch = useDispatch();

    return () => {
        if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_TEMPS) {
            dispatch(colTemGesTemActions.resetTrie());
        }
        if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_PLANNING) {
            dispatch(colPlaGesTemActions.resetTrie());
        }
        if(type === TYPES_COLLABORATEURS.AFFECTATIONS_HORAIRES_INDIVIDUELLES) {
            dispatch(colAffHorIndActions.resetTrie());
        }
        if(type === TYPES_COLLABORATEURS.AFFECTATIONS_HORAIRES_COLLECTIVES) {
            dispatch(colAffHorColActions.resetTrie());        
        }
        if(type === TYPES_COLLABORATEURS.AFFECTATIONS_ACTIVITES_INDIVIDUELLES) {
            dispatch(colAffActIndActions.resetTrie());
        }
        if(type === TYPES_COLLABORATEURS.AFFECTATIONS_ACTIVITES_COLLECTIVES) {
            dispatch(colAffActColActions.resetTrie());
        }
        if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_CREDIT) {
            dispatch(colCreGesTemActions.resetTrie());
        }
        if(type === TYPES_COLLABORATEURS.GESTION_TEMPS_MOUVEMENT) {
            dispatch(colMvtGesTemActions.resetTrie());
        }
    }
};
