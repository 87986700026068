import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMedia } from "react-use";
import { Box, Stack, Tab, Tabs } from '@mui/material';
import { AccessTime, AssignmentInd, AutoFixHigh, CalendarMonth, CompareArrows, ErrorOutline, HourglassBottom, QueryBuilder } from "@mui/icons-material";
import { selectTemGesTemMan } from '@selectors';
import * as temGesTemManActions from "@reducers/gestionTemps/temps/temGesTemManReducer"
import { TabContentContainer, TextContainer } from "@components/Containers";
import { Temps, TempsMobile } from "./Temps";
import { Planning, PlanningMobile } from "./Planning";
import { Affectations, AffectationsMobile } from "./Affectations";
import { Anomalies, AnomaliesMobile } from "./Anomalies";
import { Heures, HeuresMobile } from "./Heures";
import { Mouvement, MouvementMobile } from "./Mouvement";
import { Credit, CreditMobile } from "./Credit";
import { DemandesRegularisation, DemandesRegularisationMobile } from "./DemandesRegularisation";
import { CODE_TABS_GESTION_TEMPS_MANAGER } from "@constants/tabsConstants";
import { MenuButton } from "@components/Buttons";
import { greenColor, greyColor, whiteColor } from '@styles';

const GestionTempsManager = () => { 
    const [titreMobile, setTitreMobile] = useState("Temps")
    const stateTempsManager = useSelector(selectTemGesTemMan);
    const dispatch = useDispatch();
    const isMobile = useMedia('(max-width: 1115px)');

    const handleTabs = useCallback((_, tab) => {
        let titre = "";
        if(tab === CODE_TABS_GESTION_TEMPS_MANAGER.TAB_TEMPS_COLLABORATEUR) {
            titre = "Temps";
        }
        if(tab === CODE_TABS_GESTION_TEMPS_MANAGER.TAB_PLANNING_COLLABORATEUR) {
            titre = "Planning";
        }
        if(tab === CODE_TABS_GESTION_TEMPS_MANAGER.TAB_AFFECTATIONS) {
            titre = "Affectations";
        }
        if(tab === CODE_TABS_GESTION_TEMPS_MANAGER.TAB_ANOMALIES) {
            titre = "Anomalies";
        }
        if(tab === CODE_TABS_GESTION_TEMPS_MANAGER.TAB_HEURES) {
            titre = "Heures";
        }
        if(tab === CODE_TABS_GESTION_TEMPS_MANAGER.TAB_MOUVEMENTS) {
            titre = "Mouvements";
        }
        if(tab === CODE_TABS_GESTION_TEMPS_MANAGER.TAB_CREDIT) {
            titre = "Crédit";
        }
        if(tab === CODE_TABS_GESTION_TEMPS_MANAGER.TAB_DEMANDES_REGULARISATION) {
            titre = "Demandes de régularisation";
        }
        setTitreMobile(titre);
        dispatch(temGesTemManActions.setTabActive(tab));
    }, [dispatch]);

    return (
        <Stack spacing={0.5} bgcolor={whiteColor[9]} borderRadius={2} p={1} height="100%" boxSizing="border-box">
            {isMobile ?
                <>
                    <Stack spacing={1} direction="row" bgcolor={greenColor["015"]} borderRadius={2} p={0.5} height={46} boxSizing={"border-box"} display={"flex"} alignItems="center">
                        <Box flexGrow={1} boxSizing="border-box" height="100%">
                            <TextContainer bgColor={greenColor["015"]} textSize={15} p={2} height="100%" verticalAlign="center" horizontalAlign="center" textWeight="bold">
                                {titreMobile}
                            </TextContainer>
                        </Box>
                        <Box pr={1} pl={0.5}>
                            <MenuButton txtId="BTDABSMOB" options={[
                                {id: 1, icon: <AccessTime fontSize="small" />, label: "Temps", action: (e) => handleTabs(e, CODE_TABS_GESTION_TEMPS_MANAGER.TAB_TEMPS_COLLABORATEUR)},
                                {id: 2, icon: <CalendarMonth fontSize="small" />, label: "Planning", action: (e) => handleTabs(e, CODE_TABS_GESTION_TEMPS_MANAGER.TAB_PLANNING_COLLABORATEUR)},
                                {id: 3, icon: <AssignmentInd fontSize="small" />, label: "Affectations", action: (e) => handleTabs(e, CODE_TABS_GESTION_TEMPS_MANAGER.TAB_AFFECTATIONS)},
                                {id: 4, icon: <ErrorOutline fontSize="small" />, label: "Anomalies", action: (e) => handleTabs(e, CODE_TABS_GESTION_TEMPS_MANAGER.TAB_ANOMALIES)},
                                {id: 5, icon: <QueryBuilder fontSize="small" />, label: "Heures", action: (e) => handleTabs(e, CODE_TABS_GESTION_TEMPS_MANAGER.TAB_HEURES)},
                                {id: 6, icon: <CompareArrows fontSize="small" />, label: "Mouvements", action: (e) => handleTabs(e, CODE_TABS_GESTION_TEMPS_MANAGER.TAB_MOUVEMENTS)},
                                {id: 7, icon: <HourglassBottom fontSize="small" />, label: "Crédit", action: (e) => handleTabs(e, CODE_TABS_GESTION_TEMPS_MANAGER.TAB_CREDIT)},
                                {id: 8, icon: <AutoFixHigh fontSize="small" />, label: "Demandes de régularisation", action: (e) => handleTabs(e, CODE_TABS_GESTION_TEMPS_MANAGER.TAB_DEMANDES_REGULARISATION)},
                            ]} />
                        </Box>
                    </Stack>
                    <TabContentContainer value={stateTempsManager.tabActive} id={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_TEMPS_COLLABORATEUR}>
                        <TempsMobile />
                    </TabContentContainer>
                    <TabContentContainer value={stateTempsManager.tabActive} id={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_PLANNING_COLLABORATEUR}>
                        <PlanningMobile /> 
                    </TabContentContainer>
                    <TabContentContainer value={stateTempsManager.tabActive} id={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_AFFECTATIONS}>
                        <AffectationsMobile />
                    </TabContentContainer>
                    <TabContentContainer value={stateTempsManager.tabActive} id={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_ANOMALIES}>
                        <AnomaliesMobile />
                    </TabContentContainer>
                    <TabContentContainer value={stateTempsManager.tabActive} id={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_HEURES}>
                        <HeuresMobile />
                    </TabContentContainer>
                    <TabContentContainer value={stateTempsManager.tabActive} id={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_MOUVEMENTS}>
                        <MouvementMobile />
                    </TabContentContainer>
                    <TabContentContainer value={stateTempsManager.tabActive} id={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_CREDIT}>
                        <CreditMobile />
                    </TabContentContainer>
                    <TabContentContainer value={stateTempsManager.tabActive} id={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_DEMANDES_REGULARISATION}>
                        <DemandesRegularisationMobile />
                    </TabContentContainer>
                </>
            :
                <>
                    <Box>
                        <Tabs value={stateTempsManager.tabActive} onChange={(_, tab) => dispatch(temGesTemManActions.setTabActive(tab))}
                            indicatorColor=""
                            sx={styles.tabsStyles} 
                            centered
                        >
                            <Tab label="Temps" value={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_TEMPS_COLLABORATEUR} sx={styles.tabStyle} />     
                            <Tab label="Planning" value={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_PLANNING_COLLABORATEUR} sx={styles.tabStyle} />               
                            <Tab label="Affectations" value={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_AFFECTATIONS} sx={styles.tabStyle} />               
                            <Tab label="Anomalies" value={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_ANOMALIES} sx={styles.tabStyle} />               
                            <Tab label="Heures" value={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_HEURES} sx={styles.tabStyle} />               
                            <Tab label="Mouvements" value={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_MOUVEMENTS} sx={styles.tabStyle} />
                            <Tab label="Crédit" value={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_CREDIT} sx={styles.tabStyle} />
                            <Tab label="Demandes de régularisations" value={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_DEMANDES_REGULARISATION} sx={styles.tabStyle} />
                        </Tabs>
                    </Box>
                    <TabContentContainer value={stateTempsManager.tabActive} id={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_TEMPS_COLLABORATEUR}>
                        <Temps />
                    </TabContentContainer>
                    <TabContentContainer value={stateTempsManager.tabActive} id={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_PLANNING_COLLABORATEUR}>
                        <Planning />
                    </TabContentContainer>
                    <TabContentContainer value={stateTempsManager.tabActive} id={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_AFFECTATIONS}>
                        <Affectations />
                    </TabContentContainer>
                    <TabContentContainer value={stateTempsManager.tabActive} id={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_ANOMALIES}>
                        <Anomalies />
                    </TabContentContainer>
                    <TabContentContainer value={stateTempsManager.tabActive} id={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_HEURES}>
                        <Heures />
                    </TabContentContainer>
                    <TabContentContainer value={stateTempsManager.tabActive} id={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_MOUVEMENTS}>
                        <Mouvement />
                    </TabContentContainer>
                    <TabContentContainer value={stateTempsManager.tabActive} id={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_CREDIT}>
                        <Credit />
                    </TabContentContainer>
                    <TabContentContainer value={stateTempsManager.tabActive} id={CODE_TABS_GESTION_TEMPS_MANAGER.TAB_DEMANDES_REGULARISATION}>
                        <DemandesRegularisation />
                    </TabContentContainer>
                </>
            }
        </Stack>
    )
}

const styles = {
    tabTypographyStyle: {
        xs: "11px", 
        sm: "small"
    },
    tabStyle: {
        color: greyColor[9],
        fontWeight: "bold",
        marginRight: 0.5,
        minHeight: "",
        border: "none",
        borderRadius: 2,
        flexGrow: 1,
        textTransform: "none",
        "&:hover": {
            backgroundColor: whiteColor[5],
            color: greyColor[9]
        },
        "&.Mui-selected": {
            backgroundColor: whiteColor[5],
            color: greyColor[9]
        }
    },
    tabsStyles: {
        height: 41,
        minHeight: "",
        paddingLeft: 0.5,
        paddingY: 0.5,
        backgroundColor: greenColor["015"], 
        borderRadius: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}

export default GestionTempsManager;