import { useSelector } from "react-redux";
import moment from "moment";
import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { CalendrierHeaderTable } from "./Header";
import { greenColor, greyColor, yellowColor } from "@styles";
import { ErrorBase } from "@components/Errors";
import { Loader } from "@components/Loaders";
import { selectCalGesAbs } from "@selectors";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@components/Buttons";

const verifyAbsencesValidees = (absences) => {
    let retour = false;
    absences.forEach(absence => {
        if(Number(absence.codeStatut)) {
            retour = true;
        }
    });
    return retour;
}

const verifyAbsencesEnAttentes = (absences) => {
    let retour = false;
    absences.forEach(absence => {
        if(!Number(absence.codeStatut)) {
            retour = true;
        }
    });
    return retour;
}

const CalendrierTable = (props) => {
    const { isLoading, isError, errorMessage, semaines, openJour } = props;
    const stateCalendrier = useSelector(selectCalGesAbs);
    const { t } = useTranslation("global");

    const jours = [
        {id: 1, libelle: t("calendar.day1"), libelleSmall: t("calendar.day1Small")}, 
        {id: 2, libelle: t("calendar.day2"), libelleSmall: t("calendar.day2Small")}, 
        {id: 3, libelle: t("calendar.day3"), libelleSmall: t("calendar.day3Small")},
        {id: 4, libelle: t("calendar.day4"), libelleSmall: t("calendar.day4Small")}, 
        {id: 5, libelle: t("calendar.day5"), libelleSmall: t("calendar.day5Small")}, 
        {id: 6, libelle: t("calendar.day6"), libelleSmall: t("calendar.day6Small")}, 
        {id: 7, libelle: t("calendar.day7"), libelleSmall: t("calendar.day7Small")}
    ];

    if(isLoading) {
        return (
            <Box justifyContent={"center"} display={"flex"} alignItems={"center"} height={`calc(${stateCalendrier.hauteur}px - 61.5px)`}>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return <ErrorBase>{errorMessage}</ErrorBase>;
    }

    return (
        <Stack spacing={0.25}>
            <CalendrierHeaderTable jours={jours} />         
            {semaines.map((semaine) => (
                <Box key={`CALS${semaine.semaine}`} p={0.3} borderRadius={2} bgcolor={greenColor["03"]}>
                    <Grid container spacing={0.3}>
                        <Grid item xs={1.5}>
                            <Box p={0.75} bgcolor={greenColor["05"]} sx={{borderTopLeftRadius: 8, borderBottomLeftRadius: 8}} boxSizing="border-box" display="flex" alignItems="center" justifyContent="center">
                                <Typography fontSize="small" fontWeight="bold" color="white">{semaine.semaine}</Typography>
                            </Box>
                        </Grid>
                        {semaine.jours.map((jour) => (
                            <Grid key={`CALJ${jour.id}`} item xs={1.5}>
                                <Tooltip title={jour.type ? `${t(`texts.${jour.type}`)} : ${jour.libelleFerie}` : ""} placement="top">
                                    <Box component="span">
                                        <ButtonBase 
                                            variant={jour.type ? "blue2Grey5Blue5" : "white5Grey5White9"}
                                            fullWidth  
                                            height={31.5}
                                            borders={jour.id%7 === 0 ? "right" : "none"}
                                            action={() => openJour(jour)}
                                        >
                                            <Stack spacing={0}>
                                                <Box p={0}>
                                                    <Typography fontSize="small" fontWeight="bold">{moment(jour.date, "DDMMYYYY").format("DD")}</Typography>
                                                </Box>
                                                {jour.absences.length > 0 &&
                                                    <Stack spacing={0.2} direction="row">
                                                        {verifyAbsencesValidees(jour.absences) && <Box width={"100%"} height={4} bgcolor={greenColor["1"]} borderRadius={1}></Box>}
                                                        {verifyAbsencesEnAttentes(jour.absences) && <Box width={"100%"} height={4} bgcolor={yellowColor[9]} borderRadius={1}></Box>}
                                                    </Stack>
                                                }
                                            </Stack>
                                        </ButtonBase>
                                    </Box>
                                </Tooltip>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            ))}
        </Stack>
    );
}

CalendrierTable.defaultProps = {
    isLoading: true, 
    isError: false, 
    errorMessage: "",
    semaines: [],
    openJour: () => {}
}

CalendrierTable.propTypes = {
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
    semaines: PropTypes.array,
    openJour: PropTypes.func
}

export default CalendrierTable;