import { useSelector } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";
import { WarningAmberRounded } from "@mui/icons-material";
import { selectDsh, selectPlaHor, selectStaUsr, selectUsrApp } from "@selectors";
import { Loader } from "@components/Loaders";
import { greenColor, greyColor, whiteColor } from "@styles";
import { usePlaHorDsh } from "@hooks/gestionTemps/plagesHoraires/hooksQueries";
import { useStaPoi, useStaUsrApp } from "@hooks/statut/hooksQueries";
import { Widgets } from "@components/Dashboard/Widgets";
import { Shortcuts, ShortcutsMobile } from "@components/Dashboard/Shortcuts";
import { ConfigurationDialog } from "@components/Dashboard/Dialogs";
import { WidgetsMenu } from "@components/Dashboard/Menus";
import { useCallback, useState } from "react";
import { ActivitesDialog, PointageDialog } from "@components/App/Statut";
import { useDshApp } from "@hooks/dashboard/hooksQueries";
import { useMedia } from "react-use";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
    const [openWidgetsMenu, setOpenWidgetsMenu] = useState(false);
    const [openPointageDialog, setOpenPointageDialog] = useState(false);
    const [openActivitesDialog, setOpenActivitesDialog] = useState(false);
    const [position, setPosition] = useState(0);
    const [activite, setActivite] = useState("");
    const [dialogType, setDialogType] = useState("");
    const stateUser = useSelector(selectUsrApp);
    const stateDashboard = useSelector(selectDsh);
    const statePlagesHoraire = useSelector(selectPlaHor);
    const stateStatut = useSelector(selectStaUsr);
    const { isLoading: isLoadingDashboard, isFetching: isFecthingDashboard, isError: isErrorDashboard, refetch: refetchDashboard } = useDshApp(stateUser.token);
    const { isLoading: isLoadingStatut, isFetching: isFetchingStatut, isError: isErrorStatut, error: errorStatut, refetch: refetchStatut } = useStaUsrApp("P");
    const { isLoading: isLoadingPlagesHoraires, isFetching: isFetchingPlagesHoraires, isError: isErrorPlagesHoraires, error: errorPlagesHoraires, refetch: refetchPlagesHoraires } = usePlaHorDsh("P", statePlagesHoraire.datePlagesHoraires);
    const { isLoading: isLoadingPointage, pointer, pointerActivites } = useStaPoi();
    const isMobile = useMedia('(max-width: 480px)');
    const { t } = useTranslation("global");

    const openMenuWidgets = useCallback((id) => {
        setPosition(id);
        setOpenWidgetsMenu(true);
    }, [setOpenWidgetsMenu]);

    const pointerActionner = useCallback(async () => {
        if(dialogType === "pointage") {
            await pointer();
        }
        if(dialogType === "activites") {
            await pointerActivites(activite);
        }
        setOpenPointageDialog(false);
        setOpenActivitesDialog(false);
        refetchStatut();
    }, [activite, dialogType, pointer, pointerActivites, refetchStatut]);

    const handleOpenConfirmationPointage = useCallback((dialogType, act = "") => {
        setDialogType(dialogType);
        setActivite(act);
        setOpenPointageDialog(true);
    }, []);

    if(isLoadingDashboard || isFecthingDashboard) {
        return (
            <Box height="100%" display="flex" alignItems="center" justifyContent="center">       
                <Box bgcolor={whiteColor[9]} borderRadius={2} width="70px" height="70px" display="flex" alignItems="center" justifyContent="center">
                    <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                </Box>
            </Box>
        );
    }

    if(isErrorDashboard) {
        return (
            <Box textAlign="center" bgcolor="rgba(255,255,255,1)" height="100%" borderRadius={2}>
                <Stack spacing={1} bgcolor="rgba(255,0,0,0.1)" height="100%" width="100%" borderRadius={2} display="flex" justifyContent="center" alignItems="center">
                    <Box><WarningAmberRounded /></Box>
                    <Typography>{t("errors.generic_data")}</Typography>
                    <Typography>{t("errors.generic_token")}</Typography>
                    <Typography>{t("errors.generic_logout")}</Typography>
                </Stack>
            </Box>
        )
    }

    return (
        <>
            {isMobile ? 
                <Stack spacing={1} height={"100%"}>
                    {(stateDashboard.shortcuts.length > 0 && (stateUser.autorisationEd || stateUser.autorisationAc)) && 
                        <Box>
                            <ShortcutsMobile 
                                isLoadingStatut={isLoadingStatut || isFetchingStatut} 
                                isErrorStatut={isErrorStatut} 
                                refetchStatut={refetchStatut} 
                                handleOpenConfirmationPointage={handleOpenConfirmationPointage} 
                                setOpenActivitesDialog={setOpenActivitesDialog} 
                            />
                        </Box>
                    }
                    <Box overflow="auto" borderRadius={2}>
                        <PerfectScrollbar>
                            <Widgets 
                                isLoadingStatut={isLoadingStatut || isFetchingStatut} 
                                isErrorStatut={isErrorStatut} 
                                errorStatutMessage={errorStatut?.message}
                                refetchStatut={refetchStatut} 
                                isLoadingPlagesHoraires={isLoadingPlagesHoraires || isFetchingPlagesHoraires} 
                                isErrorPlagesHoraires={isErrorPlagesHoraires}
                                errorPlagesHoraireMessage={errorPlagesHoraires?.message}
                                refetchPlagesHoraires={refetchPlagesHoraires}
                            />
                        </PerfectScrollbar>
                    </Box>
                </Stack>
            :
                <Stack spacing={1} height={"100%"}>
                    {(stateDashboard?.shortcuts?.length > 0 && (stateUser.autorisationEd || stateUser.autorisationAc)) && 
                        <Box>
                            <Shortcuts 
                                isLoadingStatut={isLoadingStatut || isFetchingStatut} 
                                isErrorStatut={isErrorStatut} 
                                refetchStatut={refetchStatut}
                                handleOpenConfirmationPointage={handleOpenConfirmationPointage} 
                                setOpenActivitesDialog={setOpenActivitesDialog} 
                            />
                        </Box>
                    }
                    <Box overflow="auto" borderRadius={2}>
                        <PerfectScrollbar>
                            <Widgets 
                                isLoadingStatut={isLoadingStatut || isFetchingStatut} 
                                isErrorStatut={isErrorStatut} 
                                errorStatutMessage={errorStatut?.message}
                                refetchStatut={refetchStatut} 
                                isLoadingPlagesHoraires={isLoadingPlagesHoraires || isFetchingPlagesHoraires} 
                                isErrorPlagesHoraires={isErrorPlagesHoraires}
                                errorPlagesHoraireMessage={errorPlagesHoraires?.message}
                                refetchPlagesHoraires={refetchPlagesHoraires} 
                            />
                        </PerfectScrollbar>
                    </Box>
                </Stack>
            }
            <ConfigurationDialog 
                refetch={refetchDashboard} 
                openMenuWidgets={openMenuWidgets} 
            />
            <WidgetsMenu 
                open={openWidgetsMenu} 
                setOpen={setOpenWidgetsMenu} 
                position={position} 
            />
            <PointageDialog 
                isLoading={isLoadingPointage} 
                actionSuivante={stateStatut?.statut?.actionSuivante}
                open={openPointageDialog} 
                setOpen={setOpenPointageDialog} 
                dialogType={dialogType}
                activite={activite?.libelle}
                pointer={pointerActionner} 
            />
            <ActivitesDialog 
                open={openActivitesDialog} 
                actionSuivante={stateStatut?.statut?.actionSuivante}
                setOpen={setOpenActivitesDialog} 
                handleOpenConfirmationPointage={handleOpenConfirmationPointage}
            />
        </>
    )
}

export default Dashboard;