import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { ArrowLeft, Check, Close, Refresh } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import PerfectScrollbar from "react-perfect-scrollbar";
import { zodResolver } from "@hookform/resolvers/zod";
import PropTypes from "prop-types";
import { selectEntMan, selectEntSecMan } from "@selectors";
import * as entSecManActions from "@reducers/gestionEntretiens/entSecManReducer";
import { useAddSousSection, useQueEntMan } from "@hooks/gestionEntretiens/hooksQueries";
import { useChampDate, useChampQuestionAjout, useMappingActions } from "@hooks/gestionEntretiens/hooksUtils";
import { DateHookFormInput, SelectObjectHookFormInput, TextHookFormInput } from "@components/Inputs";
import { IconButtonBase } from "@components/Buttons";
import { ErrorBase } from "@components/Errors";
import { Loader } from "@components/Loaders";
import { ChampsComplementaires } from "./Champs";
import { schemaAjoutQuestionnaire } from "@components/GestionEntretiens/validations";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";
import moment from "moment";

const defaultValues = {
    designation1: "",
    designation2: "",
    complement1: "",
    complement2: "",
    complement3: "",
    complement4: "",
    date: null,
    reponseQuestion1: "",
    reponseQuestion2: "",
    reponseQuestion3: "",
    reponseQuestion4: "",
    reponseQuestion5: "",
    reponseQuestion6: "",
    commentaire1: "",
    commentaire2: "", 
    commentaire3: "", 
    commentaire4: "", 
    commentaire5: "", 
    commentaire6: "",
    displayQuestion1: false,
    displayQuestion2: false,
    displayQuestion3: false,
    displayQuestion4: false,
    displayQuestion5: false,
    displayQuestion6: false
}

const AjoutQuestionnaireForm = ({ 
    open = false, 
    goBack = () => {} 
}) => {
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const methods = useForm({ mode: "onSubmit", defaultValues: defaultValues, resolver: zodResolver(schemaAjoutQuestionnaire) })
    const { data, isLoading, isFetching, isError, refetch } = useQueEntMan(open);
    const { designation1, designation2 } = useMappingActions()
    const { optionsCQ } = useChampQuestionAjout();  
    const { displayA: displayAD } = useChampDate();
    const { isLoadingAjout, addSousSectionQuestionnaire } = useAddSousSection(); 
    const dispatch = useDispatch();

    useEffect(() => {
        methods.reset();
        if(data?.question1) {
            methods.setValue("displayQuestion1", true);
        } else {
            methods.setValue("displayQuestion1", false);
        }
        if(data?.question2) {
            methods.setValue("displayQuestion2", true);
        } else {
            methods.setValue("displayQuestion2", false);
        }
        if(data?.question3) {
            methods.setValue("displayQuestion3", true);
        } else {
            methods.setValue("displayQuestion3", false);
        }
        if(data?.question4) {
            methods.setValue("displayQuestion4", true);
        } else {
            methods.setValue("displayQuestion4", false);
        }
        if(data?.question5) {
            methods.setValue("displayQuestion5", true);
        } else {
            methods.setValue("displayQuestion5", false);
        }
        if(data?.question6) {
            methods.setValue("displayQuestion6", true);
        } else {
            methods.setValue("displayQuestion6", false);
        }
        if(displayAD) {
            methods.setValue("displayDate", true);
            methods.setValue("date",  moment([stateEntretiens?.entretienSelected?.annee, stateEntretiens?.entretienSelected?.mois - 1, stateEntretiens?.entretienSelected?.jour]))
        } else {
            methods.setValue("displayDate", false);
        }
    }, [data?.question1, data?.question2, data?.question3, data?.question4, data?.question5, data?.question6, displayAD, methods, stateEntretiens?.entretienSelected?.annee, stateEntretiens?.entretienSelected?.jour, stateEntretiens?.entretienSelected?.mois]);

    const validate = useCallback(async (dataForm) => {
        await addSousSectionQuestionnaire(dataForm);
        goBack();
    }, [addSousSectionQuestionnaire, goBack]);

    if(isLoading || isFetching|| isLoadingAjout) {
        return (
            <>
                <Box borderRadius={2} bgcolor={greenColor["015"]} height={50} p={0.5}>
                    <Skeleton variant="rounded" animation="wave" width="100%" height="100%" sx={{borderRadius: 2}} />
                </Box>
                <Box justifyContent="center" display="flex" alignItems="center" height="calc(100vh - 140px)" p={2}>
                    <Box bgcolor={whiteColor[5]} borderRadius={2} width={50} height={50} justifyContent="center" display="flex" alignItems="center">
                        <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                    </Box>
                </Box>
            </>
        );
    }

    if(isError) {
        return (
            <>
                <Box borderRadius={2} bgcolor={redColor[5]} height={50} p={0.5}>
                    <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[5]} height="100%" alignItems="center" px={1}>
                        <Box flexGrow={1}>
                            <Typography fontSize="small" display="inline" fontWeight="bold">Une erreur est survenue lors de la récupération des données</Typography>
                        </Box>
                        <Box>
                            <IconButtonBase icon={<Refresh fontSize="small" />} title="Rafraichir" action={() => refetch()} />
                        </Box>
                    </Stack>
                </Box>
                <ErrorBase>Erreur lors de la récupération des données</ErrorBase>
            </>
        );
    }

    return (
        <FormProvider {...methods}>
            <Box borderRadius={2} bgcolor={greenColor["015"]} height={50} p={0.5}>
                <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[5]} height="100%" alignItems="center" px={1}>
                    <IconButtonBase icon={<ArrowLeft fontSize="small" />} title="Retour" action={() => goBack()} />
                    <Box flexGrow={1}>
                        <Typography fontSize="small" fontWeight="bold">{designation1}</Typography>
                        <Typography fontSize="small" fontWeight="bold">{designation2}</Typography>
                    </Box>
                    {(stateEntretiens?.entretienSelected?.statut === "P" && stateSections.modeAjout) && 
                        <IconButtonBase icon={<Close fontSize="small" />} title="Annuler" action={() => dispatch(entSecManActions.setModeAjout(false))} />
                    }
                    {stateEntretiens?.entretienSelected?.statut === "P" && 
                        <IconButtonBase icon={<Check fontSize="small" />} title="Valider" action={() => methods.handleSubmit(validate)()} />
                    }
                </Stack>
            </Box>
            <PerfectScrollbar> 
                <Stack spacing={0.5} bgcolor={greenColor["015"]} borderRadius={2} p={0.5}>
                    <Stack spacing={1} borderRadius={2} bgcolor={whiteColor[5]} p={0.5}>
                        <TextHookFormInput name="designation1" label="Designation 1" maxLength={80} />
                        <TextHookFormInput name="designation2" label="Designation 2" maxLength={80} />
                    </Stack>
                    <ChampsComplementaires />
                    {displayAD &&
                        <Stack spacing={1} borderRadius={2} bgcolor={whiteColor[5]} p={0.5}>
                            <DateHookFormInput name="date" label="Date" displayError />
                        </Stack>       
                    } 
                    {data?.question1 &&
                        <Stack spacing={1} borderRadius={2} bgcolor={whiteColor[5]} p={0.5}>
                            <Box borderRadius={2} bgcolor={greenColor["015"]} p={1}>
                                <Typography fontSize="small" fontWeight="bold">{data?.designationQuestion1}</Typography>
                            </Box>
                            <Box>
                                <SelectObjectHookFormInput name="reponseQuestion1" label="Réponse" options={optionsCQ} />
                            </Box>
                            <Box>  
                                <TextHookFormInput name="commentaire1" label="Commentaire" multiline maxLength={null} />
                            </Box> 
                        </Stack>
                    
                    }
                    {data?.question2 &&
                        <Stack spacing={1} borderRadius={2} bgcolor={whiteColor[5]} p={0.5}>
                            <Box borderRadius={2} bgcolor={greenColor["015"]} p={1}>
                                <Typography fontSize="small" fontWeight="bold">{data?.designationQuestion2}</Typography>
                            </Box>
                            <Box>
                                <SelectObjectHookFormInput name="reponseQuestion2" label="Réponse" options={optionsCQ} />
                            </Box>
                            <Box>  
                                <TextHookFormInput name="commentaire2" label="Commentaire" multiline maxLength={null} />
                            </Box>
                        </Stack>
                    }
                    {data?.question3 &&
                        <Stack spacing={1} borderRadius={2} bgcolor={whiteColor[5]} p={0.5}>
                            <Box borderRadius={2} bgcolor={greenColor["015"]} p={1}>
                                <Typography fontSize="small" fontWeight="bold">{data?.designationQuestion3}</Typography>
                            </Box>
                            <Box>
                                <SelectObjectHookFormInput name="reponseQuestion3" label="Réponse" options={optionsCQ} />
                            </Box>
                            <Box>  
                                <TextHookFormInput name="commentaire3" label="Commentaire" multiline maxLength={null} />
                            </Box>
                        </Stack>
                    }
                    {data?.question4 &&
                        <Stack spacing={1} borderRadius={2} bgcolor={whiteColor[5]} p={0.5}>
                            <Box borderRadius={2} bgcolor={greenColor["015"]} p={1}>
                                <Typography fontSize="small" fontWeight="bold">{data?.designationQuestion4}</Typography>
                            </Box>
                            <Box>
                                <SelectObjectHookFormInput name="reponseQuestion4" label="Réponse" options={optionsCQ} />
                            </Box>
                            <Box>  
                                <TextHookFormInput name="commentaire4" label="Commentaire" multiline maxLength={null} />
                            </Box>
                        </Stack>
                    }
                    {data?.question5 &&
                        <Stack spacing={1} borderRadius={2} bgcolor={whiteColor[5]} p={0.5}>
                            <Box borderRadius={2} bgcolor={greenColor["015"]} p={1}>
                                <Typography fontSize="small" fontWeight="bold">{data?.designationQuestion5}</Typography>
                            </Box>
                            <Box>
                                <SelectObjectHookFormInput name="reponseQuestion5" label="Réponse" options={optionsCQ} />
                            </Box>
                            <Box>  
                                <TextHookFormInput name="commentaire5" label="Commentaire" multiline maxLength={null} />
                            </Box>
                        </Stack>
                    }
                    {data?.question6 &&
                        <Stack spacing={1} borderRadius={2} bgcolor={whiteColor[5]} p={0.5}>
                            <Box borderRadius={2} bgcolor={greenColor["015"]} p={1}>
                                <Typography fontSize="small" fontWeight="bold">{data?.designationQuestion6}</Typography>
                            </Box>
                            <Box>
                                <SelectObjectHookFormInput name="reponseQuestion6" label="Réponse" options={optionsCQ} />
                            </Box>
                            <Box>  
                                <TextHookFormInput name="commentaire6" label="Commentaire" multiline maxLength={null} />
                            </Box>
                        </Stack>
                    }
                </Stack>
            </PerfectScrollbar>
        </FormProvider>
    );
}

AjoutQuestionnaireForm.propTypes = {
    open: PropTypes.bool,
    goBack: PropTypes.func
}

export default AjoutQuestionnaireForm;