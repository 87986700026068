import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { fetchCom } from "@api/compteurs";
import { selectCltApp, selectUsrApp } from "@selectors";
import * as comGesTemActions from "@reducers/gestionTemps/compteurs/comGesTemReducer";

export const useComGesTem = (date) => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const dispatch = useDispatch();

    const query = useQuery(['comGesTem', date], ({ signal }) => fetchCom(stateUser.matricule, date, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: !!date
    });

    useEffect(() => {
        if(query.data) {
            dispatch(comGesTemActions.setCompteurs(query.data));
        }
    }, [dispatch, query.data]);

    return query;
}