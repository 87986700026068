import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack } from '@mui/material';
import { MenuFiltreContainer } from "@components/Containers";
import { whiteColor } from "@styles";
import { HistoriqueDemandesList } from "@components/GestionAbsences/Collaborateur/HistoriqueDemandes/Lists";
import { selectHisDabGesAbs } from "@selectors";
import * as hisDabGesAbsActions from "@reducers/gestionAbsences/historique/hisDabGesAbsReducer";
import { HistoriqueDemandesActionsBar } from "@components/GestionAbsences/Collaborateur/HistoriqueDemandes/ActionsBar";
import { FiltreHistoriqueDemandesForm } from "@components/GestionAbsences/Collaborateur/HistoriqueDemandes/Forms";
import { useHisDabGesAbs } from "@hooks/gestionAbsences/historique/hooksQueries";
import { DeleteDemandesDialog, HistoriqueDemandesDialog } from "@components/GestionAbsences/Collaborateur/HistoriqueDemandes/Dialogs";

const HistoriqueDemandes = () => {
    const [openHistorique, setOpenHistorique] = useState(false);
    const [openDeleteHistorique, setOpenDeleteHistorique] = useState(false);
    const [openFiltre, setOpenFiltre] = useState(false);
    const [demandes, setDemandes] = useState([]);
    const [demandesChecked, setDemandesChecked] = useState([]);
    const [all, setAll] = useState(false);
    const { isLoading, isFetching, isError, error, refetch } = useHisDabGesAbs();
    const stateHistoriqueAbsence = useSelector(selectHisDabGesAbs);
    const dispatch = useDispatch();

    const toggleList = useCallback(() => {
        dispatch(hisDabGesAbsActions.lister());
    }, [dispatch]);

    const deleteFiltre = useCallback(() => {
        dispatch(hisDabGesAbsActions.deleteFiltre());
    }, [dispatch]);

    const selectDemande = useCallback((e) => {
        let demandesSelected = [...demandesChecked];
        if(e.target.checked) {
            demandesSelected = [...demandesChecked, Number(e.target.value)];
        } else {
            demandesSelected.splice(demandesChecked.indexOf(Number(e.target.value)), 1);
        }
        setDemandesChecked(demandesSelected);
    }, [demandesChecked]);

    const selectDemandesChecked = useCallback((type = "H") => {
        const demandes = demandesChecked.map((idDemande) => {
            const id = stateHistoriqueAbsence.demandesAbsenceDisplayed.findIndex((demande) => demande.id === idDemande);
            return stateHistoriqueAbsence.demandesAbsenceDisplayed[id];
        });
        setDemandes(demandes);
        if(type === "H") {
            setOpenHistorique(true);
        }
        if(type === "D") {
            setOpenDeleteHistorique(true); 
        }
    }, [demandesChecked, stateHistoriqueAbsence.demandesAbsenceDisplayed]);

    const selectAllDemandes = useCallback(() => {
        if(all) {
            setDemandesChecked([]);
            setAll(false);
        } else {
            const idDemandes = stateHistoriqueAbsence.demandesAbsenceDisplayed.map((demande) => demande.id);
            setDemandesChecked(idDemandes);
            setAll(true);
        }
    }, [all, stateHistoriqueAbsence.demandesAbsenceDisplayed]);

    const openInfos = useCallback((demande) => {
        setDemandes([demande]);
        setOpenHistorique(true);
    }, [setOpenHistorique]);

    const openDelete = useCallback((demande) => {
        setDemandes([demande]);
        setOpenDeleteHistorique(true); 
    }, [setOpenDeleteHistorique]);

    const refetchDemandes = useCallback(() => {
        refetch();
        setDemandesChecked([]);
        setAll(false);
    }, [refetch]);

    const resetSelection = useCallback(() => {
        setDemandesChecked([]);
        setAll(false);
    }, []);

    return (
        <Box bgcolor={whiteColor[9]} borderRadius={2} p={0.5} boxSizing="border-box" height={312}>   
            <Stack spacing={0.5}>
                <HistoriqueDemandesActionsBar
                    isLoading={isLoading || isFetching} 
                    isError={isError} 
                    listed={stateHistoriqueAbsence.listed}
                    filtered={stateHistoriqueAbsence.filtered}
                    historique={stateHistoriqueAbsence.demandesAbsenceDisplayed}
                    demandesChecked={demandesChecked}
                    allChecked={all}
                    setOpenFiltre={setOpenFiltre}
                    toggleList={toggleList}
                    deleteFiltre={deleteFiltre}
                    selectAllDemandes={selectAllDemandes}
                    selectDemandesChecked={selectDemandesChecked}
                    refetch={refetchDemandes}
                />
                <HistoriqueDemandesList 
                    data={stateHistoriqueAbsence.demandesAbsenceDisplayed}
                    isLoading={isLoading || isFetching} 
                    isError={isError} 
                    error={error} 
                    demandesChecked={demandesChecked}
                    listed={stateHistoriqueAbsence.listed}
                    openInfos={openInfos}
                    openDelete={openDelete}
                    select={selectDemande}
                    refetch={refetchDemandes}                    
                />
            </Stack>  
            <MenuFiltreContainer open={openFiltre} setOpen={setOpenFiltre}>
                <FiltreHistoriqueDemandesForm setOpen={setOpenFiltre} />
            </MenuFiltreContainer>   
            <HistoriqueDemandesDialog 
                open={openHistorique} 
                setOpen={setOpenHistorique} 
                demandes={demandes} 
                resetSelection={resetSelection}
            />
            <DeleteDemandesDialog 
                open={openDeleteHistorique} 
                setOpen={setOpenDeleteHistorique} 
                demandes={demandes} 
                refetch={refetch} 
                resetSelection={resetSelection}
            />
        </Box>
    );
}

export default HistoriqueDemandes;