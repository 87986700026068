import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Grow, Skeleton, Stack, Typography } from "@mui/material";
import { Add, ArrowLeft, ArrowRight, Refresh } from "@mui/icons-material";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { selectEntMan, selectEntSecMan } from "@selectors";
import * as entSecManActions from "@reducers/gestionEntretiens/entSecManReducer";
import { useCmpNiv2EntMan, useCmpNiv3EntMan } from "@hooks/gestionEntretiens/hooksQueries";
import { useMappingActions } from "@hooks/gestionEntretiens/hooksUtils";
import { IconButtonBase } from "@components/Buttons";
import { ErrorBase } from "@components/Errors";
import { Loader } from "@components/Loaders";
import { AjoutSectionForm } from "@components/GestionEntretiens/Manager/Forms";
import { getGreyColor, greenColor, greyColor, redColor, whiteColor } from "@styles";

const CompetencesList = ({ 
    goSection = () => {},
    goBack = () => {}, 
    validate = () => {} 
}) => {
    const stateEntretiens = useSelector(selectEntMan);
    const stateSections = useSelector(selectEntSecMan);
    const sectionSelected = stateSections?.sectionSelected;
    const { isFetching: isFetchingNiv2, isError: isErrorNiv2, refetch: refetchSectionNiv2 } = useCmpNiv2EntMan();
    const { isFetching: isFetchingNiv3, isError: isErrorNiv3, refetch: refetchSectionNiv3 } = useCmpNiv3EntMan();
    const isLoading = isFetchingNiv2 || isFetchingNiv3;
    const isError = isErrorNiv2 || isErrorNiv3;
    const { designation1, designation2 } = useMappingActions()
    const dispatch = useDispatch()

    if(stateSections.modeAjout) {
        return (
            <AjoutSectionForm goBack={goBack} validate={validate} />
        )
    }

    if(isLoading) {
        return (
            <>
                <Box borderRadius={2} bgcolor={greenColor["05"]} height={50} p={0.5}>
                    <Skeleton variant="rounded" animation="wave" width="100%" height="100%" sx={{borderRadius: 2}} />
                </Box>
                <Box justifyContent="center" display="flex" alignItems="center" height="calc(100vh - 140px)" p={2}>
                    <Box bgcolor={whiteColor[7]} borderRadius={2} width={50} height={50} justifyContent="center" display="flex" alignItems="center">
                        <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
                    </Box>
                </Box>
            </>
        );
    }

    if(isError) {
        return (
            <>
                <Box borderRadius={2} bgcolor={redColor[5]} height={50} p={0.5}>
                    <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[7]} height="100%" alignItems="center" px={1}>
                        <Box flexGrow={1}>
                            <Typography fontSize="small" display="inline" fontWeight="bold">Une erreur est survenue lors de la récupération des données</Typography>
                        </Box>
                        <Box>
                            {isErrorNiv2 && <IconButtonBase icon={<Refresh fontSize="small" />} title="Rafraichir" action={() => refetchSectionNiv2()} />}
                            {isErrorNiv3 && <IconButtonBase icon={<Refresh fontSize="small" />} title="Rafraichir" action={() => refetchSectionNiv3()} />}
                        </Box>
                    </Stack>
                </Box>
                <ErrorBase>Erreur lors de la récupération des données</ErrorBase>
            </>
        );
    }

    return (
        <>
            <Box borderRadius={2} bgcolor={greenColor["05"]} height={50} p={0.5}>
                <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[7]} height="100%" alignItems="center" px={1}>
                    <IconButtonBase icon={<ArrowLeft fontSize="small" />} title="Retour" action={() => goBack()} />
                    <Box flexGrow={1}>
                        <Typography fontSize="small" fontWeight="bold">{designation1}</Typography>
                        <Typography fontSize="small" fontWeight="bold">{designation2}</Typography>
                    </Box>
                    {(stateEntretiens?.entretienSelected?.statut === "P" && sectionSelected?.ajoutSousSectionManager) && 
                        <IconButtonBase icon={<Add fontSize="small" />} 
                            title="Ajouter une sous section" 
                            action={() => dispatch(entSecManActions.setModeAjout(true))} 
                        />
                    }
                </Stack>
            </Box>
            <PerfectScrollbar>
                <Grow in={true} timeout={300}>
                    <Stack spacing={0.5}>
                        <Stack spacing={0.5} bgcolor={greenColor["05"]} borderRadius={2} p={0.5}>
                            {stateSections?.sections?.map((competence, id) => (
                                <Button key={`COMP${id}`} sx={{ color: getGreyColor("0.7"), bgcolor: whiteColor[7], height: 50, borderRadius: 2, "&:hover": { color: whiteColor[9], backgroundColor: greenColor["07"], boxShadow: 2 }}} onClick={() => goSection(stateSections?.niveau + 1, competence)}>
                                    <Stack direction="row" alignItems="center" px={1} height={"100%"} width={"100%"}>
                                        <Box flexGrow={1}>
                                            <Typography fontSize="small" sx={{flexGrow: 1, textAlign: "left", textTransform: "none"}}>{competence?.designationCompetence}</Typography>
                                        </Box>
                                        <Box bgcolor={competence?.succesEvaluation ? greenColor["05"] : redColor[5]} borderRadius={2} px={1}>
                                            <Typography fontSize="small" fontWeight="bold" sx={{textAlign: "left", textTransform: "none"}}>{competence?.designationEvaluation}</Typography>
                                        </Box>
                                        <Box alignItems="center" display="flex">
                                            <ArrowRight fontSize="small" />
                                        </Box>
                                    </Stack>
                                </Button>
                            ))}
                        </Stack>
                    </Stack>
                </Grow>
            </PerfectScrollbar>
        </>
    )
}

CompetencesList.propTypes = {
    goSection: PropTypes.func,
    goBack: PropTypes.func,
    validate: PropTypes.func
}

export default CompetencesList;