import { Box, Stack } from "@mui/material";
import { DownloadForOffline, Person } from "@mui/icons-material";
import PropTypes from "prop-types";
import { IconButtonBase } from "@components/Buttons";
import { CollaborateursText } from "../Texts";
import { whiteColor } from "@styles";

const CollaborateursBar = (props) => {
    const { 
        multiple, 
        downloadButton, 
        buttonTitle, 
        bgColor,
        collaborateur, 
        collaborateurs, 
        openDialog, 
        setOpenDownloadDialog 
    } = props;

    return (
        <Stack direction="row" spacing={1} bgcolor={bgColor} alignItems="center" borderRadius={2} padding={1} position="relative">
            <Box px={1} flexGrow={1}>
                <CollaborateursText multiple={multiple} collaborateur={collaborateur} collaborateurs={collaborateurs} />
            </Box>
            <Box>
                {downloadButton && <IconButtonBase icon={<DownloadForOffline fontSize="small" />} title={"Exporter le planning au format xlsx"} action={() => setOpenDownloadDialog(true)} />}
                <IconButtonBase icon={<Person fontSize="small" />} title={buttonTitle} action={openDialog} />
            </Box>
        </Stack>
    );
};

CollaborateursBar.defaultProps= {
    multiple: false,
    downloadButton: false,
    buttonTitle: "Selectionner un collaborateur",
    bgColor: whiteColor[5],
    collaborateur: null,
    collaborateurs: [],
    setOpenDownloadDialog: () => {}
}

CollaborateursBar.propTypes = {
    multiple: PropTypes.bool,
    downloadButton: PropTypes.bool,
    buttonTitle: PropTypes.string,
    bgColor: PropTypes.string,
    collaborateur: PropTypes.object,
    collaborateurs: PropTypes.arrayOf(PropTypes.object),
    openDialog: PropTypes.func.isRequired,
    setOpenDownloadDialog: PropTypes.func
}

export default CollaborateursBar;