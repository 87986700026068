import { Box, Stack, Typography } from "@mui/material";
import { HeaderPanelContainer, TextContainer } from "@components/Containers";
import { getWhiteColor, greenColor, greyColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const data = {
    city: "Ville", 
    data: [
        { id: 1, date: "JJ/MM/AAAA", weather: [
            {dt: 1677855600, main: {temp: 10}, weather: [{icon: "01d"}]},
            {dt: 1677855601, main: {temp: 10}, weather: [{icon: "02d"}]},
            {dt: 1677855602, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855603, main: {temp: 10}, weather: [{icon: "04d"}]},
        ] }, 
        { id: 2, date: "JJ/MM/AAAA", weather: [
            {dt: 1677855604, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855605, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855606, main: {temp: 10}, weather: [{icon: "03d"}]},
        ] },
        { id: 3, date: "JJ/MM/AAAA", weather: [
            {dt: 1677855607, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855608, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855609, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855610, main: {temp: 10}, weather: [{icon: "03d"}]}
        ] },
        { id: 4, date: "JJ/MM/AAAA", weather: [
            {dt: 1677855611, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855612, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855613, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855614, main: {temp: 10}, weather: [{icon: "03d"}]},
        ] },
        { id: 5, date: "JJ/MM/AAAA", weather: [
            {dt: 1677855615, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855616, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855617, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855618, main: {temp: 10}, weather: [{icon: "03d"}]},
        ] },
        { id: 6, date: "JJ/MM/AAAA", weather: [
            {dt: 1677855619, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855620, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855621, main: {temp: 10}, weather: [{icon: "03d"}]},
            {dt: 1677855622, main: {temp: 10}, weather: [{icon: "03d"}]}
        ] }
    ]
};

const MeteoWidgetConfigurationAdd = () => {
    const { t } = useTranslation("global");

    return (
        <Stack spacing={0.5} padding={0.5} height="200px" boxSizing="border-box" borderRadius={2} bgcolor={greenColor["015"]}>
           <HeaderPanelContainer 
                padding={0.5}
                bgColor={whiteColor[5]}
                title={t("texts.city")}
            />
            <Stack spacing={0.5} direction="row" sx={{overflowX: "scroll"}} height="100%">
                {data.data.map((item) => (
                    <Box key={`DMETEO${item.id}`}>
                        <Stack spacing={0.5} height="100%">
                            <TextContainer bgColor={whiteColor[5]} p={1}>{item.date}</TextContainer>
                            <Stack spacing={0.5} direction="row" height="100%">
                                {item.weather.map((item, id) => (
                                    <Stack textAlign="center" justifyContent="center" display="flex" key={`METEO${id}`} sx={styles.boxMeteoConfigurationStyle}>
                                        <Typography fontSize="small">hh:mm</Typography>
                                        <Typography fontSize="small">
                                            <img src={`${process.env.REACT_APP_ICON_URL}/${item.weather[0].icon}.png`} style={{verticalAlign: "middle"}} alt="Ico weather" width={30}></img>
                                        </Typography>
                                        <Typography fontSize="small">
                                                {Math.round(item.main.temp)} °C
                                        </Typography>
                                    </Stack>
                                ))}
                            </Stack>
                        </Stack>
                    </Box>
                ))}
            </Stack>
        </Stack>
    );
}

const styles = {
    paperStyle: {
        backgroundColor: getWhiteColor("0.95"),
        backdropFilter: "blur(3px)",
        width: "100%",
        height: "200px",
        borderRadius: 2
    },
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        height: "100%"
    },
    boxMeteoStyle: {
        p: 1.5, 
        borderRadius: 2, 
        backgroundColor: greenColor["015"]
    },
    boxMeteoConfigurationStyle: {
        p: 1.5, 
        borderRadius: 2, 
        backgroundColor: whiteColor[5]
    },
    widgetTitleStyle: {
        backgroundColor: greyColor[0], 
        pl: 1,
        alignItems: "center",
        display: "flex", 
        borderRadius: 1
    },
    paperStyleNoBackground: {
        backdropFilter: "blur(3px)",
        backgroundColor: greenColor["02"],
        width: "100%",
        height: "200px",
        borderRadius: 2
    },
    widgetTitleConfigurationStyle: {
        backgroundColor: whiteColor[5], 
        pl: 1,
        alignItems: "center",
        display: "flex", 
        borderRadius: 1
    }
};

export default MeteoWidgetConfigurationAdd;