import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffectOnce } from "react-use";
import { Box, Stack } from "@mui/material";
import moment from "moment";
import { selectPlaHor, selectTemGesTemCol, selectUsrApp } from "@selectors";
import * as plaHorActions from "@reducers/plagesHoraires/plaHorReducer"
import { greenColor } from "@styles";
import { ErrorBase } from "@components/Errors";
import { usePlaHor } from "@hooks/gestionTemps/plagesHoraires/hooksQueries";
import { DateBar, InformationsBar, LegendeBar, Plages, PlagesHorairesActionsBar } from "@components/App/PlagesHoraires";
import PerfectScrollbar from "react-perfect-scrollbar";

const PlagesHoraire = () => {
    const stateUser = useSelector(selectUsrApp);
    const statePlagesHoraire = useSelector(selectPlaHor);
    const stateTemps = useSelector(selectTemGesTemCol);
    const dispatch = useDispatch();
    const { data, isLoading, isFetching, isError, refetch } = usePlaHor(stateUser.matricule, "P", true);
    
    useEffectOnce(() => {
        dispatch(plaHorActions.setDatePlagesHoraires(moment(stateTemps.dateTemps, "DDMMYYYY").format("DDMMYYYY")));
    });

    const precedent = useCallback(async () => {
        dispatch(plaHorActions.setDatePlagesHoraires(moment(statePlagesHoraire.datePlagesHoraires, "DDMMYYYY").add(-1, "day").format("DDMMYYYY")));
    }, [dispatch, statePlagesHoraire.datePlagesHoraires]);

    const suivant = useCallback(async () => {
        dispatch(plaHorActions.setDatePlagesHoraires(moment(statePlagesHoraire.datePlagesHoraires, "DDMMYYYY").add(1, "day").format("DDMMYYYY")));
    }, [dispatch, statePlagesHoraire.datePlagesHoraires]);

    return (
        <Stack spacing={0.5} bgcolor={greenColor["015"]} borderRadius={2} p={0.5} height="100%" boxSizing="border-box">
            <PlagesHorairesActionsBar 
                isLoading={isLoading || isFetching}
                isError={isError}
                precedent={precedent}
                suivant={suivant}
                refetch={refetch}
            />
            <Box height={"100%"} overflow="auto">
                <PerfectScrollbar>
                    <Stack spacing={0.5}>
                        {isError ?
                            <ErrorBase>Erreur lors de la récupération des données</ErrorBase>
                        :
                            <>
                                <InformationsBar 
                                    data={data}
                                    isLoading={isLoading || isFetching}
                                    isError={isError}
                                    matricule={stateUser.matricule} 
                                    prenomNom={`${stateUser.nom} ${stateUser.prenom}`} 
                                />
                                <DateBar 
                                    data={data} 
                                    isLoading={isLoading || isFetching} 
                                />
                                <Plages 
                                    data={data}
                                    isLoading={isLoading || isFetching}
                                /> 
                                {!data?.noPlage && <LegendeBar isLoading={isLoading || isFetching} />}
                            </>
                        }
                    </Stack>
                </PerfectScrollbar>
            </Box>
        </Stack>
    );
}

export default PlagesHoraire;