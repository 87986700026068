import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    champsComplementaires: [],
    grilleEvaluation: [],
    grilleCompetencesEvaluation: []
};

const entTabColSlice = createSlice({
    name: 'entTabCol',
    initialState: initialState,
    reducers: {
        setTables: (state, action) => {
            state.champsComplementaires = action.payload.champsComplementaires;
            state.grilleEvaluation = action.payload.grilleEvaluation;
            state.grilleCompetencesEvaluation = action.payload.grilleCompetencesEvaluation;
        }
    }
})

const { actions, reducer } = entTabColSlice
export const { 
    setTables
} = actions
export default reducer
