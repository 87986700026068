import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { selectEntSecCol } from "@selectors";
import { CompetencesList, EmptyList, SectionsAccessiblesList, SousSectionsCompetencesList, SousSectionsList } from "@components/GestionEntretiens/Collaborateur/Lists";
import { CommentaireForm } from "@components/GestionEntretiens/Collaborateur/Forms";

const MappingSectionsAccessibles = ({ 
    open = false, 
    goSection = () => {}, 
    goBack = () => {},
    openWarningSousSection = () => {} 
}) => {
    const stateSections = useSelector(selectEntSecCol);
    const typeNiv2 = stateSections?.typeNiv2;
    const typeNiv3 = stateSections?.typeNiv3;
    const typeNiv4 = stateSections?.typeNiv4;

    if(stateSections.niveau === 1) {
        return (<SectionsAccessiblesList goSection={goSection} />);
    }    

    if(stateSections.niveau === 2) {
        if(typeNiv2 === "AS") {
            return (<EmptyList open={open} goBack={goBack} accessibles />);
        }
        if(typeNiv2 === "CS") {
            return (<CommentaireForm open={open} goBack={goBack} />);
        }
        if(typeNiv2 === "SL") {
            return (<SousSectionsList open={open} goSection={goSection} goBack={goBack} accessibles openWarning={openWarningSousSection} />);
        }
        if(typeNiv2 === "GL") {
            return (<SousSectionsCompetencesList goSection={goSection} goBack={goBack} accessibles openWarning={openWarningSousSection} />);
        }
        if(typeNiv2 === "CL") {
            return (<CompetencesList goSection={goSection} goBack={goBack} openWarning={openWarningSousSection} />);
        }
    }

    if(stateSections.niveau === 3) {
        if(typeNiv3 === "SF") {
            return (<CommentaireForm open={open} goBack={goBack} />);
        }
        if(typeNiv3 === "CF") {
            return (<CommentaireForm open={open} goBack={goBack} />);
        }
        if(typeNiv3 === "QF") {
            return (<CommentaireForm open={open} goBack={goBack} />);
        }
        if(typeNiv3 === "CL") {
            return (<CommentaireForm goSection={goSection} goBack={goBack} />);
        }
    }       

    if(stateSections.niveau === 4) {
        if(typeNiv4 === "CF") {
            return (<CommentaireForm open={open} goBack={goBack} />);
        }
    }       
                                    
    return null;
}

MappingSectionsAccessibles.propTypes = {
    open: PropTypes.bool, 
    goSection: PropTypes.func,
    goBack: PropTypes.func,
    openWarningSousSection: PropTypes.func
}

export default MappingSectionsAccessibles;