import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { redColor, whiteColor } from "@styles";

const variants = {
    red: { bg: redColor[5], color: whiteColor[9] }
}

const ErrorData = ({
    label = "Erreur lors de la récupération des données",
    variant = "red",
    buttons = <></>
}) => {
    return(
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center"> 
            <Stack direction="row" spacing={2} bgcolor={variants[variant]?.bg} py={1} px={2} borderRadius={2} alignItems="center">
                <Typography fontSize="small" fontWeight="bold" color={variants[variant]?.color}>
                    { label }
                </Typography>
                { buttons }
            </Stack>
        </Box>
    );
}

ErrorData.propTypes = {
    label: PropTypes.string,
    variant: PropTypes.string,
    buttons: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array])
}

export default ErrorData;