import { arrange, asc, desc, tidy } from "@tidyjs/tidy";
import { datify } from "@utils";
import numeral from "numeral";

export const trierCreGesTem = (keyTrie, data) => {
    switch(keyTrie) {
        case "dateAsc":
            return tidy(data, arrange((da, db) => datify(da.date, "moment").utc() - datify(db.date, "moment").utc()));
        case "dateDesc": 
            return tidy(data, arrange((da, db) => datify(db.date, "moment").utc() - datify(da.date, "moment").utc()));
        case "creditEnCoursAsc":
            return tidy(data, arrange((ca, cb) => numeral(ca.enCoursChiffre).value() - numeral(cb.enCoursChiffre).value()));
        case "creditEnCoursDesc": 
            return tidy(data, arrange((ca, cb) => numeral(cb.enCoursChiffre).value() - numeral(ca.enCoursChiffre).value()));
        case "creditAcquisJourAsc": 
            return tidy(data, arrange((ca, cb) => numeral(ca.acquisJourChiffre).value() - numeral(cb.acquisJourChiffre).value()));
        case "creditAcquisJourDesc":
            return tidy(data, arrange((ca, cb) => numeral(cb.acquisJourChiffre).value() - numeral(ca.acquisJourChiffre).value()));
        case "creditAcquisCumulAsc": 
            return tidy(data, arrange((ca, cb) => numeral(ca.acquisCumulChiffre).value() - numeral(cb.acquisCumulChiffre).value()));
        case "creditAcquisCumulDesc":
            return tidy(data, arrange((ca, cb) => numeral(cb.acquisCumulChiffre).value() - numeral(ca.acquisCumulChiffre).value()));
        case "valeurRegularisationAsc": 
            return tidy(data, arrange((ca, cb) => numeral(ca.valeurRegularisationChiffre).value() - numeral(cb.valeurRegularisationChiffre).value()));
        case "valeurRegularisationDesc":
            return tidy(data, arrange((ca, cb) => numeral(cb.valeurRegularisationChiffre).value() - numeral(ca.valeurRegularisationChiffre).value()));
        case "dateRegularisationAsc": 
            return tidy(data, arrange((da, db) => datify(da.dateRegularisation, "moment").utc() - datify(db.dateRegularisation, "moment").utc()));
        case "dateRegularisationDesc":
            return tidy(data, arrange((da, db) => datify(db.dateRegularisation, "moment").utc() - datify(da.dateRegularisation, "moment").utc()));
        case "utilisateurRegularisationAsc": 
            return tidy(data, arrange([asc("utilisateurRegularisation")]));
        case "utilisateurRegularisationDesc":
            return tidy(data, arrange([desc("utilisateurRegularisation")]));
        default: 
            return data;
    }
};
