import { Box, Tooltip } from "@mui/material";
import { IconButtonApp } from "@styles";
import PropTypes from "prop-types";

const IconDatePickerButton = ({
    icon = null, 
    title = "", 
    placement = "bottom", 
    noClick = false, 
    variant = undefined,
    setOpen =  () => {}, 
    InputProps: { ref } = {}
}) => {
    return (  
        <Tooltip title={title} placement={placement}>
            <Box component="span"> 
                <IconButtonApp ref={ref} variant={variant} size="small" disabled={noClick} onClick={() => setOpen?.((prev) => !prev)}>
                    { icon }
                </IconButtonApp>
            </Box>
        </Tooltip>
    );
}

IconDatePickerButton.propTypes = {
    icon: PropTypes.element, 
    title: PropTypes.string, 
    placement: PropTypes.string, 
    noClick: PropTypes.bool, 
    variant: PropTypes.string, 
    setOpen: PropTypes.func
}

export default IconDatePickerButton;