import { Box, Grid, Grow, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Info } from "@mui/icons-material";
import { HeaderListContainer, ListContainer, PanelListContainer, TextWithLabelContainer, ItemContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import PerfectScrollbar from "react-perfect-scrollbar";
import { greenColor, greyColor, whiteColor } from "@styles";
import { ErrorBase } from "@components/Errors";
import { Loader } from "@components/Loaders";
import { useMedia } from "react-use";
import { useTranslation } from "react-i18next";

const variants = {
    solde: { bgItems: greenColor["05"], bgLabels: whiteColor[5] },
    absenceManager: { bgItems: whiteColor[2], bgLabels: whiteColor[7] }
}

const SoldesList = ({
    data = [], 
    isLoading = true, 
    isError = false, 
    errorMessage = "", 
    listed = false, 
    variant = "solde",
    openSituation = () => {}
}) => {
    const isMobile = useMedia('(max-width: 650px)');
    const { t } = useTranslation("global");

    const headerItems = [
        {id: 1, label: "", xs: 3, display: false, firstData: false, lastData: false},
        {id: 2, label: t("texts.balance"), xs: 2, display: true, firstData: true, lastData: false},
        {id: 3, label: t("texts.validated_request"), xs: 2, display: true, firstData: false, lastData: false},
        {id: 4, label: t("texts.pending_request"), xs: 2, display: true, firstData: false, lastData: false},
        {id: 5, label: t("texts.new_balance"), xs: 2, display: true, firstData: false, lastData: true}
    ];

    if(isLoading) {
        return (
            <Box justifyContent="center" display="flex" alignItems="center" height="100%">
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return (<ErrorBase>{errorMessage}</ErrorBase>);
    }

    return (
        <Box>
            {(listed && !isMobile) ? 
                <Stack spacing={0.5}> 
                    <HeaderListContainer itemIdReference="SOL" headerItems={headerItems} />
                    <PerfectScrollbar>
                        <Box maxHeight="calc(100vh - 278px)">
                            <ListContainer spacing={0.5} length={data.length} noDataLabel="Aucun solde">
                                {data.map((solde, id) => (
                                    <Grow key={`SOL${solde.id}`} in={true} timeout={400+(200*id)}>
                                        <Box bgcolor={variants[variant]?.bgItems} borderRadius={2} p={0.3}>
                                            <Grid container spacing={0.3}>   
                                                <ItemContainer firstData data={solde.libelleSolde} xs={3} /> 
                                                <ItemContainer data={Number(solde.solde).toLocaleString("fr-FR")} xs={2} /> 
                                                <ItemContainer data={Number(solde.demandesValidees).toLocaleString("fr-FR")} xs={2} /> 
                                                <ItemContainer data={Number(solde.demandesEnCours).toLocaleString("fr-FR")} xs={2} /> 
                                                <ItemContainer lastData data={Number(solde.nouveauSolde).toLocaleString("fr-FR")} xs={2} /> 
                                                <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
                                                    <IconButtonBase variant="white5Grey9White9" icon={<Info fontSize="small" />} title={t("buttons.show")} placement="right" action={() => openSituation(solde)} />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grow>
                                ))}
                            </ListContainer>
                        </Box>
                    </PerfectScrollbar>
                </Stack>
            :
                <PerfectScrollbar>
                    <Box maxHeight="calc(100vh - 210px)">
                        <PanelListContainer length={data.length}>
                            {data.map((solde, id) => (
                                <Grow key={`SOLDE${solde.id}`} in={true} timeout={200+(200*id)}>
                                    <Grid item xs={12} sm={4}>
                                        <Stack spacing={0.5} padding={0.5} borderRadius={2} bgcolor={variants[variant]?.bgItems}>
                                            <Box p={1} bgcolor={variants[variant]?.bgLabels} borderRadius={2}>
                                                <Typography fontSize="small" fontWeight="bold" textAlign="center">{solde.libelleSolde}</Typography>
                                            </Box>
                                            <Box borderRadius={2} p={1} bgcolor={variants[variant]?.bgLabels}>
                                                <Stack spacing={0}>
                                                    <TextWithLabelContainer label={t("texts.balance")} value={Number(solde.solde).toLocaleString("fr-FR")} labelWidth={true} twoPoints={false} />
                                                    <TextWithLabelContainer label={t("texts.validated_request")} value={Number(solde.demandesValidees).toLocaleString("fr-FR")} labelWidth={true} twoPoints={false} />
                                                    <TextWithLabelContainer label={t("texts.pending_request")} value={Number(solde.demandesEnCours).toLocaleString("fr-FR")} labelWidth={true} twoPoints={false} />
                                                    <TextWithLabelContainer label={t("texts.new_balance")} value={Number(solde.nouveauSolde).toLocaleString("fr-FR")} labelWidth={true} twoPoints={false} />
                                                </Stack>
                                            </Box>
                                            <Box borderRadius={2} height={35.5} display="flex" alignItems="center" justifyContent="center" bgcolor={variants[variant]?.bgLabels}>
                                                <IconButtonBase variant="grey7White5" icon={<Info fontSize="small" />} title={t("buttons.show")} action={() => openSituation(solde)} />
                                            </Box>
                                        </Stack> 
                                    </Grid>
                                </Grow>
                            ))}
                        </PanelListContainer>
                    </Box>
                </PerfectScrollbar>
            }
        </Box>
    );
}

SoldesList.propTypes = {
    data: PropTypes.array, 
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool, 
    errorMessage: PropTypes.string,
    listed: PropTypes.bool, 
    variant: PropTypes.string, 
    openSituation: PropTypes.func
}

export default SoldesList;