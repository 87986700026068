import { useCallback, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { Event, History, HistoryToggleOff } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useStateTemGesTem } from "@hooks/gestionTemps/temps/hooksStates";
import { useHisDebCreTemGesTem } from "@hooks/gestionTemps/debitsCredits/hooksQueries";
import { DialogContainer, HeaderDialogContainer } from "@components/Containers";
import { DatePickerButton, IconButtonBase } from "@components/Buttons";
import { DebitCreditsList, HistoriqueDebitCreditsList } from "@components/GestionTemps/Temps/Lists";
import moment from "moment";

const DebitCreditsDialog = (props) => {
    const { open, setOpen, userType, origine } = props;
    const [historiser, setHistoriser] = useState(false);
    const [date, setDate] = useState();
    const stateTemps = useStateTemGesTem(userType);
    const { data, isLoading, isFetching, isError, error } = useHisDebCreTemGesTem(origine, userType, date, historiser, open);

    useEffect(() => {
        if(open) {
            setDate(stateTemps.dateTemps);
        }
    }, [open, stateTemps]);

    const selectDate =  useCallback((date) => {
        setDate(date.format("DDMMYYYY"));
    }, []);

    const close = useCallback(() => {
        setOpen(false);
        setHistoriser(false);
        setDate("");
    }, [setOpen]);

    return (
        <DialogContainer open={open} placement="top" taille="md">
            <HeaderDialogContainer
                actionsLeft={
                    <Stack direction={"row"} spacing={0.5}>
                        <IconButtonBase icon={historiser ? <HistoryToggleOff fontSize="small" /> : <History fontSize="small" />} title={historiser ? "Semaine" : "Historique"} action={() => {setHistoriser((prevHistorique) => !prevHistorique);}} />
                        {historiser && <DatePickerButton icon={<Event fontSize="small" />} title="Choisir une date" timeout={600} action={selectDate} value={moment(date, "DDMMYYYY")} />}
                    </Stack>
                }
                title={historiser ? "Historique du débit/crédit" : "Débit/Crédit de la semaine"}
                close={() => close()}
            />
            {historiser ? 
                <HistoriqueDebitCreditsList userType={userType} data={data} date={date} isLoading={isLoading || isFetching} isError={isError} errorMessage={error} />
            : 
                <DebitCreditsList userType={userType} />
            }
        </DialogContainer>
    );
}

DebitCreditsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    userType: PropTypes.string.isRequired,
    origine: PropTypes.string.isRequired
}

export default DebitCreditsDialog;