import axios from "axios";
import { urls } from "@constants/appConstants";

/**
 * * Appel de la procédure PHDABS
 * * Url : get/demandes/absence/manager
 * @param {*} matricule Matricule de l'utilisateur
 * @param {*} client 
 * @param {*} signal 
 * @returns Liste des demandes d'absence
 */
export const fetchDab = async (matricule, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetDemandesAbsenceManager"
    };
    const body = {
        matricule: matricule
    };
    const response = await axios.post(urls.getDemandesAbsenceManager, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.demandesAbsences;
};

/**
 * * Appel de la procédure PWABSD
 * * Url : post/demande/absence
 * @param {*} data Données du formulaire de demande d'absence
 * @returns Données informationnelles retournées par la procédure
 */
export const addDab = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostDemandeAbsenceCollaborateur"
    };
    const body = { 
        matricule: data.matricule, 
        type: data.type,
        dateDebut: data.dateDebut, 
        dateReprise: data.dateReprise,
        ampm: data.ampm,
        heureDebut: data.heureDebut,
        heureReprise: data.heureReprise,
        nombreHeures: data.nombreHeures,
        motifDemande: data.motifDemande,
        validationDemande: data.validationDemande,
        validationContrainte: data.validationContrainte,
        validationCondition: data.validationCondition,
        codeAbsence: data.type,
        libelleAbsence: data.libelleAbsence
    };
    const response = await axios.post(urls.postDemandeAbsence, body, { headers: headers, params: { client: data.client } });
    return response.data;
}


export const editRepDab = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequetePostReponseDemandeAbsenceManager"
    };
    const body = {
        matriculeHierarchique: data.matriculeHierarchique,
        matriculeDemandeur: data.matriculeDemandeur,
        date: data.date,
        heure: data.heure,
        reponse: data.reponse,
        motifRefus: data.motif,
        validationContrainte: data.validationContrainte
    };
    const response = await axios.post(urls.postReponseDemandeAbsence, body, { headers: headers, params: { client: data.client } });
    return response.data;
}

/**
 * * Appel de la procédure PWDABS
 * * Url : get/demandes/absence/historique
 * @param {*} matricule Matricule de l'utilisateur
 * @param {*} client 
 * @param {*} signal 
 * @returns Liste des demandes d'absence
 */
export const fetchHisDab = async (matricule, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetDemandesAbsenceCollaborateur"
    };
    const body = {
        matricule: matricule
    };
    const response = await axios.post(urls.getDemandesAbsenceHistorique, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.demandesAbsences;
}

/**
 * * Appel de la procédure PWDABD (traitement = V ou P)
 * * Url : get/detail/demande/absence/historique
 * @param {*} matricule Matricule de l'utilisateur
 * @param {*} date Date de la demande
 * @param {*} heure Heure de la demande
 * @param {*} client 
 * @param {*} signal 
 * @returns Détail de la demande d'absence
 */
export const fetchDetDab = async (matricule, date, heure, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetDetailDemandeAbsenceCollaborateur"
    };

    const body = { 
        matricule: matricule, 
        date: date, 
        heure: heure
    };
    const response = await axios.post(urls.getDetailDemandeAbsenceCollaborateur, body, { headers: headers, params: { client: client }, signal: signal });
    return { suivi: response.data.suivi, piecesJointes: response.data.piecesJointes };
}

/**
 * * Appel de la procédure PWDABH (traitement = V ou P)
 * * Url : get/detail/demande/absence/manager
 * @param {*} matriculeHierarchique Matricule de l'utilisateur hierarchique
 * @param {*} matricule Matricule du demandeur
 * @param {*} date Date de la demande
 * @param {*} heure Heure de la demande
 * @param {*} client 
 * @param {*} signal 
 * @returns Détail de la demande d'absence
 */
export const fetchDetDabMan = async (matriculeHierarchique, matricule, date, heure, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetDetailDemandeAbsenceManager"
    };

    const body = { 
        matriculeHierarchique: matriculeHierarchique,
        matriculeDemandeur: matricule, 
        date: date, 
        heure: heure
    };
    const response = await axios.post(urls.getDetailDemandeAbsenceManager, body, { headers: headers, params: { client: client }, signal: signal });
    return { suivi: response.data.suivi, piecesJointes: response.data.piecesJointes };
}

/**
 * * Appel de la procédure PWDABD (traitement = S)
 * * Url : delete/demande/absence/historique
 * @param {*} data Données de la ou des demandes à supprimer 
 * @returns Données informationnelles sur la réussite de l'appel
 */
export const deleteHisDab = async (data) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteDeleteDemandeAbsenceCollaborateur"
    };
    
    const body = {
        matricule: data.matricule,
        demandesAbsences: data.demandes, 
        tailleDemandes: data.taille
    };

    const response = await axios.post(urls.deleteDemandeAbsenceHistorique, body, { headers: headers, params: { client: data.client} });
    return response.data;
}