import { validateDate } from "@validation";
import Joi from "joi";

export const schemaFilterCredits = Joi.object({
    colonne: Joi.string().required().messages({ "string.empty": "Vous devez choisir un colonne pour filtrer" }),
    operateur: Joi
        .when("colonne", {is: "number", then: Joi.string()}),
    date: Joi
        .when("colonne", {is: "date", then: validateDate.dateMoment().valide().messages({ "dateMoment.valide": "Date invalide" })}),
    number: Joi
        .when("colonne", {is: "valeur", then: Joi.number().messages({ "number.base": "Valeur obligatoire", "number.integer": "Valeur incorrecte" })})
        .when("colonne", {is: "maximum", then: Joi.number().messages({ "number.base": "Valeur obligatoire", "number.integer": "Valeur incorrecte" })}),
    txt: Joi    
        .when("colonne", {is: "libelle", then: Joi.object().messages({ "string.empty": "Désignation obligatoire", "object.base": "Désignation obligatoire" })})
});