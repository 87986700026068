import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Check } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import PropTypes from "prop-types";
import { selectDabGesAbsMan } from "@selectors";
import { IconButtonBase } from "@components/Buttons";
import { DialogContainer, HeaderDialogContainer } from "@components/Containers";
import { MotifRefusForm } from "../Forms";
import { schemaMotifRefus } from "../../validations";

const defaultValues = {
    motif: ""
}

const TitleDialog = () => {
    const stateAbsencesManager = useSelector(selectDabGesAbsMan);
    const title = stateAbsencesManager.demandeAbsenceSelected.nature === "Demande d'absence" ? "Motif du refus de la demande d'absence" : "Motif du refus de la demande d'annulation d'absence";

    return (
        title
    )
}

const MotifRefusDialog = (props) => {
    const { isLoading, open, setOpen, sendReponse } = props;
    const methods = useForm({ mode: "all", defaultValues: defaultValues, resolver: joiResolver(schemaMotifRefus) });

    useEffect(() => {
        if(open) {
            methods.reset(defaultValues);
        }
    }, [methods, open])

    return (
        <DialogContainer open={open} taille="sm">
            <HeaderDialogContainer 
                actionsLeft={
                    <IconButtonBase icon={<Check fontSize="small" />} title="Valider" disabled={isLoading} action={() => methods.handleSubmit((data) => sendReponse({reponse: "R", contrainteValidee: "N", ...data}))()} />
                }
                title={<TitleDialog />}
                close={() => setOpen(false)}
            />
            <FormProvider {...methods}>
                <MotifRefusForm isLoading={isLoading} />
            </FormProvider>
        </DialogContainer>
    );
}

MotifRefusDialog.defaultProps = {
    open: false,
    isLoading: false,
    setOpen: () => {},
    sendReponse: () => {}
}

MotifRefusDialog.propTypes = {
    open: PropTypes.bool,
    isLoading: PropTypes.bool,
    setOpen: PropTypes.func,
    sendReponse: PropTypes.func
}

export default MotifRefusDialog;