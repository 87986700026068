import { useCallback, useState } from "react";
import { Autocomplete, Box, Grid, List, Paper, Stack, TextField, Typography, autocompleteClasses } from "@mui/material";
import { useController, useFormContext, useFormState } from "react-hook-form";
import PropTypes from 'prop-types';
import { TextContainer } from "@components/Containers";
import { greenColor, greyColor, redColor } from "@styles";

const sizeMapping = {
    xsmall: { muiLabel: "small", height: 32, pt: 0.25, top: "-5px", topShrink: 0 },
    small: { muiLabel: "small", height: undefined, pt: undefined, top: undefined, topShrink: undefined }
}

const OptionsComponent = (props, option, label = true, textAlign = "left") => {
    return (
        <Box key={option.id} component="li" {...props} sx={styles.optionsStyle} borderRadius={2} mb={0.5}>
            <Grid container spacing={0.5}>
                <Grid item xs={12} display="flex" justifyContent={textAlign}>
                    {label ? 
                        <TextContainer bgColor="none" p={0}>{option.label}</TextContainer>
                    :
                        <TextContainer bgColor="none" p={0}>{option.libelle}</TextContainer>
                    }
                </Grid>
            </Grid>
        </Box> 
    );
}

const ListboxComponent = (props) => {
    return (
        <Box px={0.5} pb={0} pt={0.5}>
            <List {...props} />
        </Box>
    );
}

const PaperComponent = (props) => {
    return (
        <Paper {...props} style={{minWidth: "fit-content"}} />
    );
}

const InputComponent = (props, name, label, size, errors) => {
    return (
        <TextField 
            {...props} 
            error={errors[name]} 
            label={label} 
            sx={{ "& .MuiInputBase-root": { height: sizeMapping[size].height, "&.MuiOutlinedInput-root": { pt: sizeMapping[size].pt } } }} 
            InputLabelProps={{ sx: { top: sizeMapping[size].top, "&[data-shrink='true']": { top: sizeMapping[size].topShrink } } }} 
        />
    );
}

const NoOptions = () => {
    return (
        <Typography fontSize={"small"}>Aucune donnée</Typography>
    );
}

const optionsMapping = (option, value) => {
    return option.value === value.value
}

const labelsMapping = (option) => option.label ? option.label : "";

const AutocompleteInput = (props) => {
    const { name, label, options, size, labelized, disabled, onChange, optionsMapping, labelsMapping } = props;
    const { control } = useFormContext();
    const { field } = useController({ name: name, control });
    const { errors } = useFormState({ control });
    const [open, setOpen] = useState(false);

    const handleChange = useCallback((d) => {
        onChange(d);
        field.onChange(d);
    }, [field, onChange]);

    return (
        <Stack sx={styles.stackInputStyle}>
            <Autocomplete 
                {...field} 
                fullWidth 
                label={label} 
                size={sizeMapping[size].muiLabel} 
                options={options} 
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                getOptionLabel={labelsMapping}      
                isOptionEqualToValue={optionsMapping}
                noOptionsText={<NoOptions />}
                disabled={disabled}
                ListboxProps={styles.listBoxStyle}
                ListboxComponent={(props) => ListboxComponent(props)}
                PaperComponent={(props) => PaperComponent(props)}
                renderInput={(props) => InputComponent(props, name, label, size, errors)}
                renderOption={(props, option) => OptionsComponent(props, option, labelized)}
                onChange={(_, d) => handleChange(d)}
            />
            {errors[name] && 
                <Box sx={styles.boxInputErrorStyle}>
                    <Typography sx={styles.typoInputErrorStyle}>{errors[name].message}</Typography>
                </Box>
            }
        </Stack>
    )
}

AutocompleteInput.defaultProps = {
    label: "",
    options: [],
    popperPlacement: "bottom-start",
    popperWidth: 200,
    size: "small",
    labelized: false,
    disabled: false,
    onChange: () => {},
    optionsMapping: optionsMapping,
    labelsMapping: labelsMapping
}

AutocompleteInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        value: PropTypes.string,
        label: PropTypes.string
    })),
    popperPlacement: PropTypes.string,
    popperWidth: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    size: PropTypes.oneOf(["xsmall", "small"]),
    labelized: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    optionsMapping: PropTypes.func,
    labelsMapping: PropTypes.func
}

const styles = {
    stackInputStyle: {
        borderRadius: 2,
        backgroundColor: redColor[1]
    },
    optionsStyle: {
        [`&.${autocompleteClasses.option}`]: {
            px: 1, 
            backgroundColor: greenColor["015"],
            '&:hover': {
                backgroundColor: greenColor["035"]
            },
            '&[aria-selected="true"]': {
                border: "1px solid",
                borderColor: greyColor[2],
                backgroundColor: greenColor["035"]
            } 
        }
    },
    listBoxStyle: {
        sx: { 
            [`&.${autocompleteClasses.listbox}`]: {
                p: 0
            }
        }
    },
    boxInputErrorStyle: {
        textAlign: "left",
        borderBottomLeftRadius: 8, 
        borderBottomRightRadius: 8,
        padding: 1
    },
    typoInputErrorStyle: {
        fontSize: "small", 
        color: greyColor[5]
    }
}

export default AutocompleteInput;