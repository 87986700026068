import { useCallback, useState } from "react";
import { Box, Collapse, Stack } from "@mui/material";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import PropTypes from "prop-types";
import { appMenu } from "@constants/appConstants";
import Menu from "./Menu";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@components/Buttons";
import { useBgLayersSideBar } from "@hooks/app/hooksUtils";

const MenuLevel = ({ 
    item = null, 
    mobile = false
}) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation("global");
    const itemNiveauPair = item.niveau%2 === 0;
    const itemNiveau1 = item?.niveau === 1;
    const { layer1, layer2, buttonPair, buttonNotPair } = useBgLayersSideBar(itemNiveauPair, open);

    const handleToggle = useCallback(() => {
        setOpen((prev) => !prev);
    }, []);

    return (
        <Box bgcolor={layer1} borderRadius={2}>
            <Box bgcolor={layer2} borderRadius={2}>
                {itemNiveauPair ?
                    <ButtonBase
                        variant={buttonPair}
                        fullWidth
                        startIcon={itemNiveau1 && appMenu[item?.action.substr(0, 2)]} 
                        endIcon={open ? <ArrowDropDown fontSize="small"/> : <ArrowRight fontSize="small"/>} 
                        alignement="left"
                        action={handleToggle}
                    >
                        {t(`navigation.${item?.action}`)}
                    </ButtonBase>
                :
                    <ButtonBase 
                        variant={buttonNotPair}
                        fullWidth
                        startIcon={itemNiveau1 && appMenu[item?.action.substr(0, 2)]} 
                        endIcon={open ? <ArrowDropDown fontSize="small"/> : <ArrowRight fontSize="small"/>} 
                        alignement="left"
                        action={handleToggle}
                    >
                        {t(`navigation.${item?.action}`)}
                    </ButtonBase>
                }
            </Box>
            <Collapse in={open}>
                <Stack spacing={0.5} padding={0.5}>
                    {item?.options?.map((node) => <Menu key={`NODE${node.action}`} nodes={node} mobile={mobile} />)}
                </Stack>
            </Collapse>
        </Box>
    );
}

MenuLevel.propTypes = {
    item: PropTypes.any, 
    mobile: PropTypes.bool
}

export default MenuLevel;