import { ChevronLeft, ChevronRight, Event, Hub, ListAlt, OfflinePin, Refresh, Share, SquareFoot } from "@mui/icons-material";
import PropTypes from "prop-types";
import { DatePickerButton, IconButtonBase, MenuButton } from "@components/Buttons";
import { HeaderPanelContainer } from "@components/Containers";
import { Stack } from "@mui/material";

const PlanningPanelActionsMobile = (props) => {
    const { 
        isLoading, 
        isError, 
        allServices,
        affichage,
        precedent,
        suivant,
        selectDate,
        setOpenContraintes,
        toggleAffichage,
        toggleAll,
        refetch 
    } = props;

    return (
        <HeaderPanelContainer
            actionsLeft={
                <Stack direction="row" spacing={0.5}>
                    <IconButtonBase icon={<ChevronLeft fontSize="small" />} title="Semaine Précédente" disabled={isLoading || isError} action={() => precedent()} />
                    <IconButtonBase icon={<ChevronRight fontSize="small" />} title="Semaine Suivante" disabled={isLoading || isError} action={() => suivant()} />
                    <DatePickerButton icon={<Event fontSize="small" />} title="Choisir une date" timeout={600} noClick={isLoading || isError} action={selectDate} />
                </Stack>
            } 
            actionsRight={
                !allServices ? 
                    <MenuButton txtId="BTHISTO" options={[
                        {id: 1, icon: <SquareFoot fontSize="small" />, label: "Afficher les contraintes", action: () => setOpenContraintes(true)},
                        {id: 2, icon: affichage === "T" ? <OfflinePin fontSize="small" /> : <ListAlt fontSize="small" />, label: affichage === "T" ? "Absences validées" : "Toutes les absences", action: () => toggleAffichage()},
                        {id: 2, icon: allServices ? <Share fontSize="small" /> : <Hub fontSize="small" />, label: allServices ? "Un service" : "Tous les services", action: () => toggleAll()},
                        {id: 2, icon: <Refresh fontSize="small" />, label: "Rafraichir", action: () => refetch()}
                    ]} />
                :
                    <MenuButton txtId="BTHISTO" options={[
                        {id: 1, icon: affichage === "T" ? <OfflinePin fontSize="small" /> : <ListAlt fontSize="small" />, label: affichage === "T" ? "Absences validées" : "Toutes les absences", action: () => toggleAffichage()},
                        {id: 2, icon: allServices ? <Share fontSize="small" /> : <Hub fontSize="small" />, label: allServices ? "Un service" : "Tous les services", action: () => toggleAll()},
                        {id: 2, icon: <Refresh fontSize="small" />, label: "Rafraichir", action: () => refetch()}
                    ]} />
            }
        />
    )
}

PlanningPanelActionsMobile.defaultProps = {
    isLoading: true, 
    isError: false,
    allServices: true,
    affichage: "V",
    precedent: () => {},
    suivant: () => {},
    selectDate: () => {},
    setOpenContraintes: () => {},
    toggleAffichage: () => {},
    toggleAll: () => {},
    refetch: () => {}
}

PlanningPanelActionsMobile.propTypes = {
    isLoading: PropTypes.bool, 
    isError: PropTypes.bool,
    allServices: PropTypes.bool,
    affichage: PropTypes.string,
    precedent: PropTypes.func,
    suivant: PropTypes.func,
    selectDate: PropTypes.func,
    setOpenContraintes: PropTypes.func,
    toggleAffichage: PropTypes.func,
    toggleAll: PropTypes.func,
    refetch: PropTypes.func
}

export default PlanningPanelActionsMobile;