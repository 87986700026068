import { Box, Stack } from "@mui/material";
import { DownloadForOffline, FilterListOff } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";
import { IconButtonBase, LoaderButton } from "@components/Buttons";
import { HeaderPanelContainer } from "@components/Containers";
import { AnomaliesBaseForm } from "./Forms";
import { greenColor, whiteColor } from "@styles";
import { schemaAnomalieBase } from "./validations";
import { joiResolver } from "@hookform/resolvers/joi";
import { useDownloadAnoGesTemBas } from "@hooks/anomalies/hooksQueries";

const defaultValues = {
    dateDebut: moment(),
    dateFin: moment(),
    societe: null,
    etablissement: null,
    direction: null,
    service: null,
    secteur: null,
    typeMouvement: "T",
    anomalie: null,
    gravite1: "1",
    gravite2: "1",
    anomalieAReactualiser: false
}

const AnomaliesBase = () => {
    const methods = useForm({ mode: "all", defaultValues: defaultValues, resolver: joiResolver(schemaAnomalieBase) });
    const { isLoading, downloadActionner } = useDownloadAnoGesTemBas();

    return (
        <Stack spacing={1} sx={styles.stackStyle}>
            <HeaderPanelContainer 
                actionsLeft={
                    <IconButtonBase icon={<FilterListOff fontSize="small" />} title={"Reset filtres"} action={() => methods.reset()} />
                }
                actionsRight={
                    <Box display="flex" justifyContent="right" alignItems="center">
                        <LoaderButton title="Exporter les anomalies au format excel" isLoading={isLoading} icon={<DownloadForOffline fontSize="small" />} action={() => methods.handleSubmit(downloadActionner)()} />
                    </Box>  
                }   
                bgColor={whiteColor[5]}
            /> 
            <FormProvider {...methods}>
                <AnomaliesBaseForm />
            </FormProvider>
        </Stack>
    );
};

const styles = {
    stackStyle: {
        overflow: "auto",
        bgcolor: greenColor["015"],
        borderRadius: 2,
        boxSizing: "border-box",
        padding: 0.5,
        position: "relative",
        minHeight: "100%"
    }
};

export default AnomaliesBase;