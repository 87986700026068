import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    dateTemps: "",
    dateRegularisation: "",
    dateDemandeRegularisation: "",
    semaine: [],
    totaux: null,
    repas: [],
    debitCredits: [],
    horaire: false,
    jourSelected: "",
    tabActive: "TMPS"
}

const temGesTemManSlice = createSlice({
    name: 'temGesTemMan',
    initialState: initialState,
    reducers: {
        setTemps: (state, action) => {
            state.dateTemps = action.payload.semaine?.[0]?.date ? action.payload.semaine[0].date : action.payload.date
            state.semaine = action.payload.semaine
            state.totaux = action.payload.totaux
            state.repas = action.payload.repas
            state.debitCredits = action.payload.debitCredits
        },
        setDateTemps: (state, action) => {
            state.dateTemps = action.payload
        },
        toggleHoraire: (state) => {
            state.horaire = !state.horaire
        },
        selectJour: (state, action) => {
            state.jourSelected = action.payload
        },
        setTabActive: (state, action) => {
            state.tabActive = action.payload;
        },
        reset: () => initialState
    },
})

const { actions, reducer } = temGesTemManSlice
export const { 
    setTemps, 
    setDateTemps, 
    toggleHoraire, 
    selectJour, 
    setTabActive, 
    reset 
} = actions
export default reducer