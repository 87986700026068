import { validateDate } from "@validation";
import Joi from "joi";

export const schemaFilterAffectationsHorairesIndividuelles = Joi.object({
    colonne: Joi.string().required().messages({ "string.empty": "Vous devez choisir un colonne pour filtrer" }),
    operateur: Joi
        .when("colonne", {is: "date", then: Joi.string()})
        .when("colonne", {is: "heure", then: Joi.string()}),
    date: Joi
        .when("colonne", {is: "date", then: validateDate.dateMoment().valide().messages({ "dateMoment.valide": "Date invalide" })}),
    txt: Joi    
        .when("colonne", {is: "horaire", then: Joi.string().required().messages({ "string.empty": "Vous devez selectionner une valeur pour filtrer" })})
        .when("colonne", {is: "statut", then: Joi.string().required().messages({ "string.empty": "Vous devez selectionner une valeur pour filtrer" })})
});

export const schemaFilterAffectationsActivitesIndividuelles = Joi.object({
    colonne: Joi.string().required().messages({ "string.empty": "Vous devez choisir un colonne pour filtrer" }),
    operateur: Joi
        .when("colonne", {is: "date", then: Joi.string()})
        .when("colonne", {is: "heure", then: Joi.string()}),
    date: Joi
        .when("colonne", {is: "date", then: validateDate.dateMoment().valide().messages({ "dateMoment.valide": "Date invalide" })}),
    txt: Joi    
        .when("colonne", {is: "activite", then: Joi.string().required().messages({ "string.empty": "Vous devez selectionner une valeur pour filtrer" })})
});

export const schemaAffectationsHorairesIndividuelles = Joi.object({
        date: validateDate.dateMoment().valide().messages({ 
            "object.base": "Date obligatoire", 
            "dateMoment.valide": "Date invalide",
        }),
        horaire: Joi.object().required().messages({ "object.base": "Vous devez choisir un horaire" }),
        statut: Joi.string().valid("D", "P").required()
});

const optionsCollectives = Joi.object().keys({
    dateEffet: Joi.object().allow(null).allow(""),
    horaire: Joi.object().allow(null).allow(""),
})

export const schemaAffectationsHorairesCollectives = Joi.object({
    affectations: Joi.array().items(optionsCollectives).has(Joi.object().keys({
        dateEffet: Joi.object().required(),
        horaire: Joi.object().required(),
    })).messages({"array.hasUnknown": "Vous devez selectionner au moins une date et un horaire"})
});

export const schemaAffectationsActivitesIndividuelles = Joi.object({
    date: validateDate.dateMoment().valide().messages({ 
        "object.base": "Date obligatoire", 
        "dateMoment.valide": "Date invalide",
    }),
    activite: Joi.object().required().messages({ "object.base": "Vous devez choisir un horaire" }),
});

const optionsHoraires = Joi.object().keys({
    horaire: Joi.object().allow(null).allow(""),
})

export const schemaCycleAffectation = Joi.object({
    dateDebut: validateDate.dateMoment().valide().messages({ 
        "object.base": "Date obligatoire", 
        "dateMoment.valide": "Date invalide",
    }),
    dateFin: validateDate.dateMoment().valide().greaterMoment().messages({ 
        "object.base": "Date obligatoire", 
        "dateMoment.valide": "Date invalide",
        "dateMoment.greaterMoment": "La date de fin doit être supérieure ou égale à la date de début"
    }),
    horaires: Joi.array().items(optionsHoraires).has(Joi.object().keys({
        horaire: Joi.object().required(),
    })).messages({"array.hasUnknown": "Vous devez selectionner au moins un horaire"}),
    cycleMin: Joi.number().required(),
    cycle: Joi.number().required().min(Joi.ref("cycleMin")).max(15).messages({ 
        "number.base": "Vous devez entrer un cycle supérieur ou égale au nombre d'horaire à répartir et inférieur à 16",
        "number.max": "Le nombre de cycle doit être inférieur à 16", 
        "number.min": "Le nombre de cycle doit être supérieur ou égale au nombre d'horaire selectionné"
    })
});
