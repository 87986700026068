import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query"
import { fetchCal } from "@api/calendrier";
import { selectCltApp, selectUsrApp } from "@selectors";
import * as calGesAbsActions from "@reducers/gestionAbsences/calendrier/calGesAbsReducer";

export const useCalGesAbs = (date) => {
    const stateUser = useSelector(selectUsrApp);
    const stateClient = useSelector(selectCltApp);
    const dispatch = useDispatch();

    const query = useQuery(["calGesAbs", date], ({ signal }) => fetchCal(stateUser.matricule, date, stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if(query.data) {
            dispatch(calGesAbsActions.setDate(date));
        }
    }, [date, dispatch, query.data]);

    return query;
}