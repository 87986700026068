import axios from "axios";
import { urls } from "@constants/appConstants";

export const fetchCom = async (matricule, date, client, signal) => {
    const headers = {
        "Content-Type": "application/json", 
        "codeAlphasysWeb": "Alphasys@WebService-RequeteGetCompteurs"
    };
    const body = {
        matricule: matricule,
        date: date
    };
    const response = await axios.post(urls.getCompteurs, body, { headers: headers, params: { client: client }, signal: signal });
    return response.data.compteurs;
}