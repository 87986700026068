import { useState } from "react";
import { useController, useFormContext, useFormState } from "react-hook-form";
import { Box, Stack, Typography } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import PropTypes from "prop-types";
import { greyColor, redColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const TimeHookFormInput = (props) => {
    const { name, label, readOnly, displayError } = props;
    const { control } = useFormContext();
    const { field } = useController({ name, control, defaultValue: null });
    const { errors } = useFormState({ control });
    const [open, setOpen] = useState(false);
    const { t } = useTranslation("global");

    return (
        <Stack sx={styles.stackInputStyle}>
            <Box sx={styles.boxInputStyle}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker 
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        label={label} 
                        ampm={false} 
                        timeSteps={{minutes: 1}}
                        {...field}
                        slotProps={{ 
                        textField: { 
                            fullWidth: true,
                            error: errors[name],
                            size: "small", 
                            readOnly: readOnly, 
                            onClick: () => setOpen(true) 
                        }
                    }}
                    />
                </LocalizationProvider>
            </Box>
            {(errors[name] && displayError) && 
                <Box sx={styles.boxInputErrorStyle}>
                    <Typography sx={styles.typoInputErrorStyle}>{t(errors[name].message)}</Typography>
                </Box>
            }
        </Stack>
    );
}

TimeHookFormInput.defaultProps = {
    label: "", 
    readOnly: false,
    displayError: false
}

TimeHookFormInput.propTypes = {
    name: PropTypes.string.isRequired, 
    label: PropTypes.string, 
    readOnly: PropTypes.bool,
    displayError: PropTypes.bool
}

const styles = {
    stackInputStyle: {
        borderRadius: 2,
        backgroundColor: redColor[1]
    },
    boxInputStyle: {
        borderRadius: 2,
        backgroundColor: whiteColor[9]
    },
    boxInputErrorStyle: {
        textAlign: "left",
        borderBottomLeftRadius: 8, 
        borderBottomRightRadius: 8,
        padding: 1
    },
    typoInputErrorStyle: {
        fontSize: "small", 
        color: greyColor[5]
    }
}

export default TimeHookFormInput;