import { DialogContainer } from "@components/Containers";
import PropTypes from "prop-types";
import { PasswordForm } from "../Forms";

const PasswordDialog = (props) => {
    const { open, setOpen } = props;
    
    return (
        <DialogContainer open={open} placement="top" taille="sm">
            <PasswordForm setOpen={setOpen} />
        </DialogContainer>
    );
}

PasswordDialog.defaultProps = {
    open: false,
    setOpen: () => {}
}

PasswordDialog.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func
}


export default PasswordDialog;