import { useSelector } from "react-redux";
import { selectUsrApp } from "@selectors";
import { DialogContainer } from "@components/Containers";
import { PasswordLoginForm } from "../Forms";

const PasswordLoginDialog = () => {
    const stateUser = useSelector(selectUsrApp);

    return (
        <DialogContainer open={stateUser.openPassword} placement="top" taille="sm">
            <PasswordLoginForm />
        </DialogContainer>
    );
    
}

export default PasswordLoginDialog;