import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { Close, Info } from "@mui/icons-material";
import { selectDsh } from "@selectors";
import { getWidgetRemoved } from "@utils";
import * as dshActions from "@reducers/dashboard/dshReducer"
import { IconButtonBase } from "@components/Buttons";
import { getWhiteColor, greenColor, greyColor, redColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const StatutWidgetConfigurationDelete = () => {
    const stateDashboard = useSelector(selectDsh);
    const dispatch = useDispatch();
    const { t } = useTranslation("global");

    const remove = useCallback(() => {
        const updateState = getWidgetRemoved("wStatut", stateDashboard.widgetsActives, stateDashboard.widgetsNonActives, stateDashboard.widgetsConfiguration);
        dispatch(dshActions.editWidget(updateState));
    }, [dispatch, stateDashboard.widgetsActives, stateDashboard.widgetsConfiguration, stateDashboard.widgetsNonActives]);

    return (
        <Stack spacing={0.5} padding={0.5} borderRadius={2} bgcolor={whiteColor[5]} height="200px"  boxSizing="border-box" position="relative">
            <Box bgcolor={greenColor["05"]} borderRadius={2} p={1} textAlign="center">
                <Typography fontSize="small" fontWeight="bold" color="white">{t("titles.status")}</Typography>
            </Box>
            <Stack spacing={0.5} height="100%">
                <Box>
                    <Stack p={1.5} spacing={2} direction="row" bgcolor={greenColor["05"]} borderRadius={2}>
                        <Box>
                            <Skeleton sx={styles.skeletonGreenStyle} animation="pulse" variant="circular" width={18} height={18} />
                        </Box>
                        <Box>
                            <Typography fontSize="small" color="white">{t("texts.present_absent")}</Typography>
                        </Box>
                    </Stack>
                </Box>
                <Box height="100%" borderRadius={2} bgcolor={greenColor["05"]} p={1} color={greyColor[5]} display="flex" alignItems="center">
                    <Stack spacing={2} direction="row" alignItems="center">
                        <Info fontSize="small" sx={{ color: "white" }} />
                        <Typography fontSize="small" color="white">{t("texts.example_widget_status")}</Typography>
                    </Stack>
                </Box>
            </Stack>
            <Box position="absolute" right={6} top={3}>
                <IconButtonBase 
                    variant="deleteListButton"
                    icon={<Close fontSize="small" />} 
                    title={t("buttons.delete")} 
                    action={remove} 
                />
            </Box>
        </Stack>
    );
}

const styles = {
    paperStyleNoBackground: {
        backdropFilter: "blur(3px)",
        backgroundColor: greenColor["02"],
        width: "100%",
        height: "200px",
        borderRadius: 2
    },
    paperStyle: {
        backdropFilter: "blur(3px)",
        backgroundColor: getWhiteColor("0.95"),
        width: "100%",
        height: "200px",
        borderRadius: 2
    },
    boxTitleStyle: {
        backgroundColor: greyColor[0], 
        textAlign: "center",
        p: 0.5,
        borderRadius: 2,
    },
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        height: "130px"
    },
    boxStyle: {
        backgroundColor: greyColor[0], 
        p:0.5,
        borderRadius: 2
    },
    boxStatutConfigurationStyle: {
        borderRadius: 2, 
        boxShadow: "none", 
        backgroundColor: whiteColor[5],
        color: greyColor[5],
        justifyContent: "start",
        alignItems: "center"
    },
    boxStatutStyle: {
        borderRadius: 2, 
        boxShadow: "none", 
        backgroundColor: greenColor["015"],
        color: greyColor[5],
        justifyContent: "start",
        alignItems: "center"
    },
    skeletonGreenStyle: {
        backgroundColor: greenColor["06"]
    },
    skeletonRedStyle: {
        backgroundColor: redColor[5]
    }
};

export default StatutWidgetConfigurationDelete;