import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { selectEntSecCol } from "@selectors";
import { CompetencesList, EmptyList, SectionsList, SousSectionsCompetencesList, SousSectionsList } from "@components/GestionEntretiens/Collaborateur/Lists";
import { Commentaire, Competence, Questionnaire, SousSection } from "../Content";

const Mapping = ({ 
    open = false, 
    goSection = () => {}, 
    goBack = () => {} 
}) => {
    const stateSections = useSelector(selectEntSecCol);
    const typeNiv2 = stateSections?.typeNiv2;
    const typeNiv3 = stateSections?.typeNiv3;
    const typeNiv4 = stateSections?.typeNiv4;

    if(stateSections.niveau === 1) {
        return (<SectionsList goSection={goSection} />);
    }    

    if(stateSections.niveau === 2) {console.log(typeNiv2)
        if(typeNiv2 === "AS") {
            return (<EmptyList open={open} goBack={goBack} />);
        }
        if(typeNiv2 === "CS") {
            return (<Commentaire open={open} goBack={goBack} />);
        }
        if(typeNiv2 === "SL") {
            return (<SousSectionsList open={open} goSection={goSection} goBack={goBack} />);
        }
        if(typeNiv2 === "GL") {
            return (<SousSectionsCompetencesList goSection={goSection} goBack={goBack} />);
        }
        if(typeNiv2 === "CL") {
            return (<CompetencesList goSection={goSection} goBack={goBack} />);
        }
    }

    if(stateSections.niveau === 3) {
        if(typeNiv3 === "SF") {
            return (<SousSection open={open} goBack={goBack} />);
        }
        if(typeNiv3 === "CF") {
            return (<Competence open={open} goBack={goBack} />);
        }
        if(typeNiv3 === "QF") {
            return (<Questionnaire open={open} goBack={goBack} />);
        }
        if(typeNiv3 === "CL") {
            return (<CompetencesList goSection={goSection} goBack={goBack} />);
        }
    }       

    if(stateSections.niveau === 4) {
        if(typeNiv4 === "CF") {
            return (<Competence open={open} goBack={goBack} />);
        }
    }       
                                    
    return null;
}

Mapping.propTypes = {
    open: PropTypes.bool, 
    goSection: PropTypes.func,
    goBack: PropTypes.func
}

export default Mapping;