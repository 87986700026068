import PropTypes from "prop-types";
import TextContainer from "@components/Containers/TextContainer";
import { whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const TextMotifAbsenceContainer = (props) => {
    const { libelleAbsence } = props;
    const { t } = useTranslation("global");

    return (
        libelleAbsence && 
            <TextContainer bgColor={whiteColor[7]} p={1}>{t("texts.absence_reason")} : {libelleAbsence}</TextContainer>
    );
}

TextMotifAbsenceContainer.defaultProps = {
    libelleAbsence: ""
}

TextMotifAbsenceContainer.propTypes = {
    libelleAbsence: PropTypes.string
}

export default TextMotifAbsenceContainer;
