import { Check, ChevronLeft, Close } from "@mui/icons-material";
import PropTypes from "prop-types";
import { HeaderPanelContainer } from "@components/Containers";
import { IconButtonBase } from "@components/Buttons";
import { PAGES_GESTION_ABSENCES } from "@constants/demandesAbsenceConstants";

const DetailActionsBar = ({ 
    isLoading = true, 
    isError = false, 
    demandeReponse = "",
    goTo = () => {}, 
    sendReponse = () => {}, 
    setOpenMotifRefusManager = () => {}
}) => {
    return (
        <HeaderPanelContainer 
            actionsLeft={
                <IconButtonBase 
                    variant="white5Grey9White9"
                    icon={<ChevronLeft fontSize="small" />} 
                    title="Retour" 
                    disabled={isLoading}
                    action={() => goTo(PAGES_GESTION_ABSENCES.LIST)} 
                /> 
            }
            actionsRight={
                (demandeReponse === "O") && 
                    <>
                        <IconButtonBase 
                            variant="white5Grey9White9"
                            icon={<Check fontSize="small" />} 
                            title="Accepter la demande" 
                            disabled={isLoading || isError}
                            action={() => sendReponse({reponse: "A", motif: "", contrainteValidee: "N"})} 
                        />
                        <IconButtonBase 
                            variant="white5Grey9White9"
                            icon={<Close fontSize="small" />} 
                            title="Refuser la demande" 
                            disabled={isLoading || isError} 
                            action={() => setOpenMotifRefusManager(true)}  
                        />
                    </>       
            }
            title={demandeReponse === "O" ? "Répondre à la demande" : "Détail de la demande"}
            variant="green07"
        />
    )
}

DetailActionsBar.propTypes = {
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    demandeReponse: PropTypes.string,
    goTo: PropTypes.func,
    sendReponse: PropTypes.func,
    setOpenMotifRefusManager: PropTypes.func
}

export default DetailActionsBar;