import { Stack } from "@mui/material";
import { Check, DoneAll, Event, FilterList, MobiledataOff, RemoveDone, SwapVert } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useResetTrieCollaborateurs, useSelectDateCollaborateurs } from "@hooks/collaborateurs/hooksUtils";
import { DatePickerLabelizedButtonMobile, IconButtonBase } from "@components/Buttons";
import moment from "moment";
import { HeaderDialogContainer } from "@components/Containers";
import { greenColor } from "@styles";

const CollaborateursFwgtp2ActionsMobile = (props) => {
    const { 
        stateType, 
        dateValidite, 
        isLoading, 
        allChecked, 
        tried, 
        setOpenFiltre,  
        setOpenTri,
        selectCollaborateursChecked,
        selectAllCollaborateurs, 
        setOpen
    } = props;
    const resetTrie = useResetTrieCollaborateurs(stateType);
    const selectDate = useSelectDateCollaborateurs(stateType);

    return (
        <>
            <HeaderDialogContainer 
                actionsLeft={
                    <Stack direction={"row"} spacing={0.5}>
                        {tried ? 
                            <IconButtonBase icon={<MobiledataOff fontSize="small" />} action={resetTrie} /> 
                        : 
                            <IconButtonBase icon={<SwapVert fontSize="small" />} action={() => setOpenTri(true)} />
                        }
                        <IconButtonBase icon={<FilterList fontSize="small" />} action={() => setOpenFiltre((prevOpen) => !prevOpen)} />
                        <IconButtonBase icon={<Check fontSize="small" />} title="Valider la selection" action={() => selectCollaborateursChecked()} />
                        <IconButtonBase icon={allChecked ? <RemoveDone fontSize="small" /> : <DoneAll fontSize="small" />} title={allChecked ? "Tout décocher" : "Tout cocher"} timeout={400} noClick={isLoading} action={selectAllCollaborateurs} />
                    </Stack>
                }
                p={1}
                close={() => setOpen(false)}
            />
            <Stack p={1} bgcolor={greenColor["015"]} borderRadius={2}>
                <DatePickerLabelizedButtonMobile 
                    icon={<Event fontSize="small" />} 
                    title="Choisir une date" 
                    timeout={600} 
                    noClick={isLoading} 
                    date={dateValidite ? moment(dateValidite, "DDMMYYYY").format("DD/MM/YYYY") : moment().format("DD/MM/YYYY")} 
                    action={selectDate} 
                />
            </Stack>
        </>
    );
}

CollaborateursFwgtp2ActionsMobile.defaultProps = {
    allChecked: false,
    selectCollaborateursChecked: () => {},
    selectAllCollaborateurs: () => {},
    setOpen: () => {}
}

CollaborateursFwgtp2ActionsMobile.propTypes = {
    stateType: PropTypes.string.isRequired,
    dateValidite: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    isLoading: PropTypes.bool.isRequired,
    allChecked: PropTypes.bool,
    tried: PropTypes.bool.isRequired,
    selectCollaborateursChecked: PropTypes.func,
    setOpenTri: PropTypes.func.isRequired,
    setOpenFiltre: PropTypes.func.isRequired,
    selectAllCollaborateurs: PropTypes.func,
    setOpen: PropTypes.func
}

export default CollaborateursFwgtp2ActionsMobile;