import PropTypes from "prop-types";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { greenColor, redColor, whiteColor } from "@styles";
import { useComEntManCol } from "@hooks/gestionEntretiens/hooksQueries";
import { IconButtonBase } from "@components/Buttons";
import { Refresh } from "@mui/icons-material";

const CommentaireContent = ({ 
    open = false, 
    codeQuestion = 0 
}) => {
    const { data, isLoading, isFetching, isError, refetch } = useComEntManCol(codeQuestion, open);    

    if(isLoading || isFetching) {
        return (
            <Box>
                <Skeleton variant="rounded" animation="wave" width="100%" height={48} sx={{borderRadius: 2}} />
            </Box>
        );
    }

    if(isError) {
        return (
            <Box borderRadius={2} bgcolor={redColor[5]} p={0.5}>
                <Stack direction="row" spacing={1} borderRadius={2} bgcolor={whiteColor[7]} height={35.5} alignItems="center" px={1}>
                    <Box flexGrow={1}>
                        <Typography fontSize="small" display="inline" fontWeight="bold">Une erreur est survenue lors de la récupération du commentaire</Typography>
                    </Box>
                    <Box>
                        <IconButtonBase icon={<Refresh fontSize="small" />} title="Rafraichir" action={() => refetch()} />
                    </Box>
                </Stack>
            </Box>
        );
    }

    return (
        <Box>
            {(!!data?.commentaireManager || !!data?.commentaireCollaborateur) ? 
                <Stack spacing={0.5} height="100%" whiteSpace={"pre-wrap"} bgcolor={greenColor["05"]} p={0.5} borderRadius={2}>
                    {!!data?.commentaireManager && 
                        <Box borderRadius={2} bgcolor={whiteColor[7]} p={1}>
                            <Typography fontSize="small" fontWeight="bold">Commentaire de votre manager</Typography>
                            <Typography fontSize="small">{data?.commentaireManager}</Typography>
                        </Box>
                    }
                    {!!data?.commentaireCollaborateur && 
                        <Box borderRadius={2} bgcolor={whiteColor[7]} p={1}>
                            <Typography fontSize="small" fontWeight="bold">Mon commentaire</Typography>
                            <Typography fontSize="small">{data?.commentaireCollaborateur}</Typography>
                        </Box>
                    }
                </Stack>
            :
                <Box bgcolor={greenColor["05"]} borderRadius={2} p={0.5} height="100%" whiteSpace={"pre-wrap"}> 
                    <Box borderRadius={2} bgcolor={whiteColor[7]} p={1}>
                        <Typography fontSize="small" fontWeight="bold">Aucun commentaire</Typography>
                    </Box>
                </Box>
            }
        </Box>
    );
}

CommentaireContent.propTypes = {
    open: PropTypes.bool,
    codeQuestion: PropTypes.number
}

export default CommentaireContent;