import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Stack, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import moment from "moment";
import { routePolices } from "@constants/policesConstants";
import { getWidgetRemoved } from "@utils";
import { selectDsh, selectUsrApp } from "@selectors";
import * as dshActions from "@reducers/dashboard/dshReducer"
import { IconButtonBase } from "@components/Buttons";
import { whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

export const DateWidgetConfigurationDelete = () => {
    const stateDashboard = useSelector(selectDsh);
    const dispatch = useDispatch();
    const [heure, setHeure] = useState(moment().format("HH:mm"));
    const stateUser = useSelector(selectUsrApp);
    const { t } = useTranslation("global");
    const date = `${moment().format("dddd")} ${moment().format("DD")} ${moment().format("MMMM")} ${moment().format("YYYY")}`;

    useEffect(() => {
        const timer = setTimeout(() => {
            setHeure(moment().format("HH:mm"));
        }, 60000);
        return () => clearTimeout(timer);
    }, [heure]);

    const remove = useCallback(() => {
        const updateState = getWidgetRemoved("wDate", stateDashboard.widgetsActives, stateDashboard.widgetsNonActives, stateDashboard.widgetsConfiguration);
        dispatch(dshActions.editWidget(updateState));
    }, [dispatch, stateDashboard.widgetsActives, stateDashboard.widgetsConfiguration, stateDashboard.widgetsNonActives]);

    return (
        <Stack spacing={0.5} padding={0.5} borderRadius={2} bgcolor={whiteColor[5]} height="200px" boxSizing="border-box" position="relative">
            <Box height="100%" paddingX={2} display="flex" alignItems="center">
                <Box textAlign="center" width="100%" boxSizing="border-box">
                    <Typography variant="h6" fontFamily={routePolices[stateUser.police]}>
                        { date }
                    </Typography>
                    <Typography variant="h6" fontFamily={routePolices[stateUser.police]}>
                        { heure }
                    </Typography>
                </Box>
            </Box>
            <Box position="absolute" right={6} top={3}>
                <IconButtonBase 
                    variant="deleteListButton"
                    icon={<Close fontSize="small" />} 
                    title={t("buttons.delete")} 
                    action={remove} 
                />
            </Box>
        </Stack>
    );
}

export default DateWidgetConfigurationDelete;