import { PAGES_GESTION_ABSENCES } from '@constants/demandesAbsenceConstants';
import { createSlice } from '@reduxjs/toolkit'
import { filter, tidy } from '@tidyjs/tidy';

const initialState = {
    listed: false,
    filtered: false,
    tried: false,
    all: false,
    page: PAGES_GESTION_ABSENCES.LIST,
    demandesAbsence: [],
    demandesAbsenceDisplayed: [],
    demandeAbsenceSelected: null,
}

const dabGesAbsManSlice = createSlice({
    name: 'dabGesAbsMan',
    initialState: initialState,
    reducers: { 
        setDemandesAbsence: (state, action) => {
            const result = state.all ? action.payload : tidy(action.payload, filter((demande) => demande.demandeReponse === "O"));
            state.demandesAbsence = action.payload;
            state.demandesAbsenceDisplayed = result;
        },
        lister: (state) => {
            state.listed = !state.listed
        },
        filtrer: (state, action) => {
            state.demandesAbsenceDisplayed = action.payload;
            state.filtered = true;
        },
        filtrerParReponse: (state) => {
            const result = state.all ? tidy(state.demandesAbsence, filter((demande) => demande.demandeReponse === "O")) : state.demandesAbsence;
            state.demandesAbsenceDisplayed = result;
            state.all = !state.all
            state.filtered = false;
            state.tried = false;
        },
        deleteFiltre: (state) => {
            const result = state.all ? state.demandesAbsence : tidy(state.demandesAbsence, filter((demande) => demande.demandeReponse === "O"));
            state.demandesAbsenceDisplayed = result;
            state.filtered = false;
        },
        trier: (state, action) => {
            state.demandesAbsenceDisplayed = action.payload;
            state.tried = true;
        },
        deleteTrie: (state) => {
            const result = state.all ? state.demandesAbsence : tidy(state.demandesAbsence, filter((demande) => demande.demandeReponse === "O"));
            state.demandesAbsenceDisplayed = result;
            state.tried = false;
        },
        goToWithSelection: (state, action) => {
            state.page = action.payload.page;
            state.demandeAbsenceSelected = action.payload.demande;
        },
        goTo: (state, action) => {
            state.page = action.payload;
        },
        reset: () => initialState
    },
})

const { actions, reducer } = dabGesAbsManSlice
export const { 
    setDemandesAbsence, 
    lister, 
    filtrer, 
    filtrerParReponse, 
    deleteFiltre, 
    trier, 
    deleteTrie, 
    goToWithSelection, 
    goTo,
    reset 
} = actions
export default reducer