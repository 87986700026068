import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { fetchSec } from "@api/secteurs";
import { selectCltApp } from "@selectors";

export const useSecInp = (open) => {
    const stateClient = useSelector(selectCltApp);

    return useQuery(['secInp'], ({ signal }) => fetchSec(stateClient.client, signal), {
        retry: false, 
        refetchOnWindowFocus: false,
        enabled: open
    });
}
