import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useUnmount } from "react-use";
import voca from "voca";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { selectColPlaGesTem, selectPlaGesTem, selectTypTraApp } from "@selectors";
import { dataToFormPlanningGestionTemps, formToDataPlanningGestionTemps } from "@integrations";
import * as plaGesTemActions from "@reducers/gestionTemps/planning/plaGesTemReducer";
import * as colPlaGesTemActions from "@reducers/collaborateurs/colPlaGesTemReducer";
import { usePlaGesTem, usePlaGesTemMod, usePlaGesTemNav, usePlaGesTemPos } from "@hooks/gestionTemps/planning/hooksQueries";
import { PlanningTableMobile } from "@components/GestionTemps/Planning/Tables";
import { PlanningActionsBarMobile } from "@components/GestionTemps/Planning/ActionsBars";
import { PlanningAlertesDialog, PlanningDownloadDialog } from "@components/GestionTemps/Planning/Dialogs";
import { greenColor, whiteColor } from "@styles";
import { TYPES_COLLABORATEURS, TYPES_FICHIER } from "@constants/collaborateursConstants";
import { CollaborateursBar } from "@components/App/Collaborateurs/Bars";
import { CollaborateursDialogMobile } from "@components/App/Collaborateurs/Dialogs";
import { datify, heurify } from "@utils";
import PerfectScrollbar from "react-perfect-scrollbar";

const defaultValues = {
    debut11: "",
    arret11: "",
    debut21: "",
    arret21: "",
    debut31: "",
    arret31: "",
    debut41: "",
    arret41: "",
    debut12: "",
    arret12: "",
    debut22: "",
    arret22: "",
    debut32: "",
    arret32: "",
    debut42: "",
    arret42: "",
    debut13: "",
    arret13: "",
    debut23: "",
    arret23: "",
    debut33: "",
    arret33: "",
    debut43: "",
    arret43: "",
    debut14: "",
    arret14: "",
    debut24: "",
    arret24: "",
    debut34: "",
    arret34: "",
    debut44: "",
    arret44: "",
    debut15: "",
    arret15: "",
    debut25: "",
    arret25: "",
    debut35: "",
    arret35: "",
    debut45: "",
    arret45: "",
    debut16: "",
    arret16: "",
    debut26: "",
    arret26: "",
    debut36: "",
    arret36: "",
    debut46: "",
    arret46: "",
    debut17: "",
    arret17: "",
    debut27: "",
    arret27: "",
    debut37: "",
    arret37: "",
    debut47: "",
    arret47: "",
    tt11: null,
    tt21: null,
    tt31: null,
    tt41: null,
    tt12: null,
    tt22: null,
    tt32: null,
    tt42: null,
    tt13: null,
    tt23: null,
    tt33: null,
    tt43: null,
    tt14: null,
    tt24: null,
    tt34: null,
    tt44: null,
    tt15: null,
    tt25: null,
    tt35: null,
    tt45: null,
    tt16: null,
    tt26: null,
    tt36: null,
    tt46: null,
    tt17: null,
    tt27: null,
    tt37: null,
    tt47: null,
    model1: "",
    model2: "",
    model3: "",
    model4: "",
    model5: "",
    model6: "",
    model7: "",
    rattachement1: false,
    rattachement2: false,
    rattachement3: false,
    rattachement4: false,
    rattachement5: false,
    rattachement6: false,
    rattachement7: false,
}

const Planning = () => {
    const [openCollaborateursDialog, setOpenCollaborateursDialog] = useState(false);
    const [openAlertesDialog, setOpenAlertesDialog] = useState(false);
    const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
    const [headersFwgtp3, setHeadersFwgtp3] = useState([]);
    const statePlaGesTem = useSelector(selectPlaGesTem);
    const stateCollaborateursPlanning = useSelector(selectColPlaGesTem);
    const stateTypesTravail = useSelector(selectTypTraApp);
    const methods = useForm({ mode: "onChange", defaultValues: defaultValues }); 
    const { isLoading: isLoadingGetPlanning, getPlanning } = usePlaGesTem();   
    const { isLoadingPost, isLoadingPostWithVerification, alertes, bloquant, postPlanning, postPlanningWithVerification } = usePlaGesTemPos();
    const { isLoadingNavigation, navigatePlanning } = usePlaGesTemNav();
    const { isLoadingModelization, modelizePlanning } = usePlaGesTemMod();
    const isLoading = isLoadingPost || isLoadingGetPlanning || isLoadingPostWithVerification || isLoadingNavigation || isLoadingModelization;
    const dispatch = useDispatch();

    useEffect(() => {
        if(stateCollaborateursPlanning?.dateValidite) {
            const dateValidite = moment(stateCollaborateursPlanning?.dateValidite, "DDMMYYYY");
            const lundiLabel = `Lundi ${dateValidite.weekday(0).format("DD/MM/YYYY")}`;
            const mardiLabel = `Mardi ${dateValidite.weekday(1).format("DD/MM/YYYY")}`;
            const mercrediLabel = `Mercredi ${dateValidite.weekday(2).format("DD/MM/YYYY")}`;
            const jeudiLabel = `Jeudi ${dateValidite.weekday(3).format("DD/MM/YYYY")}`;
            const vendrediLabel = `Vendredi ${dateValidite.weekday(4).format("DD/MM/YYYY")}`;
            const samediLabel = `Samedi ${dateValidite.weekday(5).format("DD/MM/YYYY")}`;
            const dimancheLabel = `Dimanche ${dateValidite.weekday(6).format("DD/MM/YYYY")}`;
            setHeadersFwgtp3([lundiLabel, mardiLabel, mercrediLabel, jeudiLabel, vendrediLabel, samediLabel, dimancheLabel]);
        }
    }, [stateCollaborateursPlanning?.dateValidite]);

    useEffect(() => {
        const data = dataToFormPlanningGestionTemps({ 
            lundi: statePlaGesTem.lundi, 
            mardi: statePlaGesTem.mardi, 
            mercredi: statePlaGesTem.mercredi, 
            jeudi: statePlaGesTem.jeudi, 
            vendredi: statePlaGesTem.vendredi, 
            samedi: statePlaGesTem.samedi, 
            dimanche: statePlaGesTem.dimanche, 
            modeleLundi: statePlaGesTem.modeleLundi, 
            modeleMardi: statePlaGesTem.modeleMardi, 
            modeleMercredi: statePlaGesTem.modeleMercredi, 
            modeleJeudi: statePlaGesTem.modeleJeudi, 
            modeleVendredi: statePlaGesTem.modeleVendredi, 
            modeleSamedi: statePlaGesTem.modeleSamedi, 
            modeleDimanche: statePlaGesTem.modeleDimanche, 
            rattachementLundi: statePlaGesTem.rattachementLundi, 
            rattachementMardi: statePlaGesTem.rattachementMardi, 
            rattachementMercredi: statePlaGesTem.rattachementMercredi, 
            rattachementJeudi: statePlaGesTem.rattachementJeudi, 
            rattachementVendredi: statePlaGesTem.rattachementVendredi, 
            rattachementSamedi: statePlaGesTem.rattachementSamedi, 
            rattachementDimanche: statePlaGesTem.rattachementDimanche, 
            modificationAffectationsHoraires: statePlaGesTem.modificationAffectationsHoraires 
        }, stateTypesTravail.typesTravail);
        methods.reset(data);
    }, [methods, statePlaGesTem, stateTypesTravail.typesTravail]);
    
    useUnmount(() => {
        const data = methods.getValues();
        const dataFormated = formToDataPlanningGestionTemps(data);
        dispatch(plaGesTemActions.updateFwgtp4(dataFormated))
    });
    
    const selectCollaborateur = useCallback(async (collaborateur) => {
        setOpenCollaborateursDialog(false);
        dispatch(colPlaGesTemActions.selectCollaborateur(collaborateur));
        await getPlanning(collaborateur);
    }, [dispatch, getPlanning]);

    const verificationPlanning = useCallback(async () => {
        methods.clearErrors();
        const data = methods.getValues();
        const dataFormated = formToDataPlanningGestionTemps(data);
        const { codeRetour, zoneErreur } = await postPlanningWithVerification(dataFormated);
        if(codeRetour === "AL") {
            setOpenAlertesDialog(true);
        }
        if(zoneErreur) {
            let zone = "";
            const numeroZone = voca.substr(zoneErreur, 4, 2);
            const idZone = voca.substr(zoneErreur, 0, 1);
            if(idZone === "A") {
                zone = `arret${numeroZone}`;
            }
            if(idZone === "D") {
                zone = `debut${numeroZone}`;
            }
            methods.setError(zone);
        }
    }, [methods, postPlanningWithVerification]);

    const navigationNext = useCallback(async () => {
        let date = moment(statePlaGesTem.dateLundi, "YYYYMMDD");
        let dateNext = date.add(7, "days");
        const numeroSemaine = dateNext.isoWeek();
        const anneeSemaine = dateNext.year();
        navigatePlanning(numeroSemaine, anneeSemaine);
    }, [navigatePlanning, statePlaGesTem.dateLundi]);

    const navigationPrev = useCallback(async () => {
        let date = moment(statePlaGesTem.dateLundi, "YYYYMMDD");
        let datePrev = date.add(-7, "days");
        const numeroSemaine = datePrev.isoWeek();
        const anneeSemaine = datePrev.year();
        navigatePlanning(numeroSemaine, anneeSemaine);
    }, [navigatePlanning, statePlaGesTem.dateLundi]);

    const navigationSelect = useCallback(async (date) => {
        const numeroSemaine = date.isoWeek();
        const anneeSemaine = date.year();
        navigatePlanning(numeroSemaine, anneeSemaine);
    }, [navigatePlanning]);

    const nextWeek = useCallback(async (save = false) => {
        if(save) {
            const data = methods.getValues();
            const dataFormated = formToDataPlanningGestionTemps(data);
            const { codeRetour, zoneErreur } = await postPlanningWithVerification(dataFormated);
            if(!codeRetour) {
                await navigationNext();
            } else {
                if(codeRetour === "AL") {
                    setOpenAlertesDialog(true);
                }
                if(zoneErreur) {
                    methods.setError(voca.lowerCase(zoneErreur));
                }
            }
        } else {
            await navigationNext();
        }
    }, [methods, navigationNext, postPlanningWithVerification]);

    const prevWeek = useCallback(async (save = false) => {
        if(save) {
            const data = methods.getValues();
            const dataFormated = formToDataPlanningGestionTemps(data);
            const { codeRetour, zoneErreur } = await postPlanningWithVerification(dataFormated);
            if(!codeRetour) {
                await navigationPrev();
            } else {
                if(codeRetour === "AL") {
                    setOpenAlertesDialog(true);
                }
                if(zoneErreur) {
                    methods.setError(voca.lowerCase(zoneErreur));
                }
            }
        } else {
            await navigationPrev();
        }
    }, [methods, navigationPrev, postPlanningWithVerification]);

    const selectWeek = useCallback(async (date, save = false) => {
        if(save) {
            const data = methods.getValues();
            const dataFormated = formToDataPlanningGestionTemps(data);
            const { codeRetour, zoneErreur } = await postPlanningWithVerification(dataFormated);
            if(!codeRetour) {
                await navigationNext();
            } else {
                if(codeRetour === "AL") {
                    setOpenAlertesDialog(true);
                }
                if(zoneErreur) {
                    methods.setError(voca.lowerCase(zoneErreur));
                }
            }
        } else {
            await navigationSelect(date);
        }
    }, [methods, navigationNext, navigationSelect, postPlanningWithVerification]);

    const refresh = useCallback(async (save = false) => {
        if(save) {
            const data = methods.getValues();
            const dataFormated = formToDataPlanningGestionTemps(data);
            const { codeRetour, zoneErreur } = await postPlanningWithVerification(dataFormated);
            if(!codeRetour) {
                await getPlanning(stateCollaborateursPlanning?.collaborateurSelected);
            } else {
                if(codeRetour === "AL") {
                    setOpenAlertesDialog(true);
                }
                if(zoneErreur) {
                    methods.setError(voca.lowerCase(zoneErreur));
                }
            }
        } else {
            await getPlanning(stateCollaborateursPlanning?.collaborateurSelected);
        }
    }, [getPlanning, methods, postPlanningWithVerification, stateCollaborateursPlanning?.collaborateurSelected]);

    const modelize = useCallback(async (idJour, modele) => {
        const data = methods.getValues();
        data[`model${idJour + 1}`] = modele
        const dataFormated = formToDataPlanningGestionTemps(data);
        await modelizePlanning(dataFormated);
    }, [methods, modelizePlanning]);

    return (
        <Stack spacing={0.5} bgcolor={greenColor["015"]} borderRadius={2} p={0.5} height="100%" boxSizing="border-box">
            {stateCollaborateursPlanning.collaborateurSelected && 
                <Stack spacing={0.5} height={"calc(100% - 50px)"}>
                    <PerfectScrollbar>
                        <Stack spacing={0.5} overflow="auto">
                            {isLoading ? 
                                <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={251} height={19.5} />
                            :
                                <Box p={1} bgcolor={whiteColor[5]} borderRadius={2}>
                                    <Typography component="div" fontSize="small">
                                        Semaine <Box fontWeight="bold" display="inline">{statePlaGesTem.numeroSemaine} </Box> 
                                        Du <Box fontWeight="bold" display="inline">{datify(statePlaGesTem.periodeDebut)} </Box>
                                        Au <Box fontWeight="bold" display="inline">{datify(statePlaGesTem.periodeFin)} </Box>
                                    </Typography> 
                                </Box>
                            }
                            {(statePlaGesTem.baseContrat && statePlaGesTem.baseContrat !== "0") &&
                                <Box p={1} bgcolor={whiteColor[5]} borderRadius={2}>
                                    {isLoading ? 
                                        <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width={115} height={19.5} />
                                    :
                                        <Typography component="div" fontSize="small">
                                            Base contrat <Box fontWeight="bold" display="inline">{heurify(statePlaGesTem.baseContrat, "h", false)}</Box> 
                                        </Typography> 
                                    }
                                </Box>   
                            }
                            <FormProvider {...methods}>
                                <PlanningTableMobile 
                                    isLoading={isLoading} 
                                    totalHeuresSemaine={statePlaGesTem.totalHeuresSemaine} 
                                    totalHeuresAbsence={statePlaGesTem.totalHeuresAbsence} 
                                    modelize={modelize} 
                                />
                            </FormProvider>
                        </Stack>
                    </PerfectScrollbar>
                    <PlanningActionsBarMobile 
                        isLoading={isLoading} 
                        prevWeek={prevWeek} 
                        nextWeek={nextWeek} 
                        refetch={refresh} 
                        verificationPlanning={verificationPlanning} 
                        setOpenDownloadDialog={setOpenDownloadDialog}
                        selectDate={selectWeek}
                    />
                </Stack>
            }
            <CollaborateursBar 
                collaborateur={stateCollaborateursPlanning?.collaborateurSelected}  
                openDialog={() => setOpenCollaborateursDialog(true)} 
                setOpenDownloadDialog={setOpenDownloadDialog}
                downloadButton
            />
            <CollaborateursDialogMobile 
                stateType={TYPES_COLLABORATEURS.GESTION_TEMPS_PLANNING}
                listType={TYPES_FICHIER.FWGTP3} 
                open={openCollaborateursDialog}  
                dateValidite={stateCollaborateursPlanning?.dateValidite ? moment(stateCollaborateursPlanning.dateValidite, "DDMMYYYY") : moment()}
                headers={headersFwgtp3}
                tried={stateCollaborateursPlanning.tried}
                setOpen={setOpenCollaborateursDialog}  
                selectCollaborateur={selectCollaborateur}
            />
            <PlanningAlertesDialog 
                open={openAlertesDialog} 
                setOpen={setOpenAlertesDialog} 
                postPlanning={postPlanning} 
                alertes={alertes} 
                bloquant={bloquant}
            />
            <PlanningDownloadDialog 
                open={openDownloadDialog} 
                setOpen={setOpenDownloadDialog} 
            />
        </Stack>
    );
};

export default Planning;