import { Box, Grid, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useStateTemGesTem } from "@hooks/gestionTemps/temps/hooksStates";
import { Loader } from "@components/Loaders";
import { chiffreUSVersChiffreFR, heurify, heurifySigned } from "@utils";
import { greenColor, greyColor, whiteColor } from "@styles";

const TempsInformationsList = (props) => {
    const { userType, isLoading } = props;
    const stateTemps = useStateTemGesTem(userType);
    
    if(isLoading) {
        return (
            <Box sx={styles.loaderStyle}>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    return (
        stateTemps.totaux &&
            <Stack spacing={0.5}>
                {stateTemps?.totaux?.heuresHebdomadaire?.chiffre !== 0 && 
                    <Box bgcolor={greenColor["015"]} borderRadius={2} p={0.25}>
                        <Box sx={styles.boxBandeauInformationsStyle}>
                            <Grid container>
                                <Grid item xs={9}>
                                    <Typography fontSize="small" fontWeight="bold">Heures hebdomadaire </Typography>
                                </Grid>
                                <Grid item xs={3} textAlign="right">
                                    <Typography fontSize="small">{stateTemps.horaire ? heurify(stateTemps?.totaux?.heuresHebdomadaire?.heure, "h", false) : chiffreUSVersChiffreFR(stateTemps?.totaux?.heuresHebdomadaire?.chiffre)}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                }            
                {stateTemps?.totaux?.heuresPayees?.chiffre !== 0 && 
                    <Box bgcolor={greenColor["015"]} borderRadius={2} p={0.25}>
                        <Box sx={styles.boxBandeauInformationsStyle}>
                            <Grid container>
                                <Grid item xs={9}>
                                    <Typography fontSize="small" fontWeight="bold">Heures payées</Typography>
                                </Grid>
                                <Grid item xs={3} textAlign="right">
                                    <Typography fontSize="small">{stateTemps.horaire ? heurify(stateTemps?.totaux?.heuresPayees?.heure, "h", false) : chiffreUSVersChiffreFR(stateTemps?.totaux?.heuresPayees?.chiffre)}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                }
                {stateTemps?.totaux?.heuresAbsences?.chiffre !== 0 && 
                    <Box bgcolor={greenColor["015"]} borderRadius={2} p={0.25}>
                        <Box sx={styles.boxBandeauInformationsStyle}>
                            <Grid container>
                                <Grid item xs={9}>
                                    <Typography fontSize="small" fontWeight="bold">Heures d'absences </Typography>
                                </Grid>
                                <Grid item xs={3} textAlign="right">
                                    <Typography fontSize="small">{stateTemps.horaire ? heurify(stateTemps?.totaux?.heuresAbsences?.heure, "h", false) : chiffreUSVersChiffreFR(stateTemps?.totaux?.heuresAbsences?.chiffre)}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                }
                {stateTemps?.totaux?.ecart?.chiffre !== 0 && 
                    <Box bgcolor={greenColor["015"]} borderRadius={2} p={0.25}>
                        <Box sx={styles.boxBandeauInformationsStyle}>
                            <Grid container>
                                <Grid item xs={9}>
                                    <Typography fontSize="small" fontWeight="bold">Ecart de la semaine </Typography>
                                </Grid>
                                <Grid item xs={3} textAlign="right">
                                    <Typography fontSize="small">{stateTemps.horaire ? heurifySigned(stateTemps?.totaux?.ecart?.heure) : chiffreUSVersChiffreFR(stateTemps?.totaux?.ecart?.chiffre)}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                }
                {stateTemps?.totaux?.debitCredit?.permanent?.libelle && 
                    <Box bgcolor={greenColor["015"]} borderRadius={2} p={0.25}>
                        <Box sx={styles.boxBandeauInformationsStyle}>
                            <Grid container>
                                <Grid item xs={9}>
                                    <Typography fontSize="small" fontWeight="bold">{stateTemps?.totaux?.debitCredit?.permanent?.libelle} </Typography>
                                </Grid>
                                <Grid item xs={3} textAlign="right">
                                    <Typography fontSize="small">{stateTemps.horaire ? heurifySigned(stateTemps?.totaux?.debitCredit?.permanent?.heure) : chiffreUSVersChiffreFR(stateTemps?.totaux?.debitCredit?.permanent?.chiffre)}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                }
                {stateTemps?.totaux?.debitCredit?.hebdomadaire?.libelle && 
                    <Box bgcolor={greenColor["015"]} borderRadius={2} p={0.25}>
                        <Box sx={styles.boxBandeauInformationsStyle}>
                            <Grid container>
                                <Grid item xs={9}>
                                    <Typography fontSize="small" fontWeight="bold">{stateTemps?.totaux?.debitCredit?.hebdomadaire?.libelle} </Typography>
                                </Grid>
                                <Grid item xs={3} textAlign="right">
                                    <Typography fontSize="small">{stateTemps.horaire ? heurifySigned(stateTemps?.totaux?.debitCredit?.hebdomadaire?.heure) : chiffreUSVersChiffreFR(stateTemps?.totaux?.debitCredit?.hebdomadaire?.chiffre)}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                }
            </Stack>
    );
}

TempsInformationsList.propTypes = {
    userType: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired
}

const styles = {
    boxBandeauInformationsStyle: {
        minWidth: 200,
        backgroundColor: whiteColor[5], 
        p:1,
        borderRadius: 2,
    },
    loaderStyle: {
        justifyContent: "center", 
        display: "flex" , 
        alignItems: "center", 
        height: "100%",
    }
}

export default TempsInformationsList;