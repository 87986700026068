import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import { DialogFullScreenContainer, HeaderDialogContainer, TextContainer } from "@components/Containers";
import moment from "moment";
import { useStateTemGesTem } from "@hooks/gestionTemps/temps/hooksStates";
import { greenColor } from "@styles";

const InformationsJourDialogMobile = (props) => {
    const { open, setOpen, libelleFerie, userType } = props;
    const stateTemps = useStateTemGesTem(userType);

    return (
        <DialogFullScreenContainer open={open} placement="top" taille="md">
            <HeaderDialogContainer 
                title="Informations"
                close={() => setOpen(false)}
            />
            <Stack spacing={0.5}>
                <TextContainer bgColor={greenColor["015"]} p={1} textWeight="bold">
                    Date : {moment(stateTemps.jourSelected, "DDMMYYYY").format("DD/MM/YYYY")}
                </TextContainer>
                {libelleFerie && 
                    <TextContainer bgColor={greenColor["015"]} p={1} textWeight="bold">
                        Férié : {libelleFerie}
                    </TextContainer>
                }
            </Stack>
        </DialogFullScreenContainer>
    );
}

InformationsJourDialogMobile.propTypes = {
    userType: "",
    libelleFerie: ""
}

InformationsJourDialogMobile.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    libelleFerie: PropTypes.string,
    userType: PropTypes.string
}

export default InformationsJourDialogMobile;