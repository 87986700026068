import { Box, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { TextContainer, TextWithLabelContainer } from "@components/Containers";
import { DialogError } from "@components/Errors";
import { Loader } from "@components/Loaders";
import { useCntDabGesAbs } from "@hooks/gestionAbsences/demandes/hooksQueries";
import { greenColor, greyColor, redColor, whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const Contrainte = ({ 
    codeSociete = "",
    codeService = "",
    codeContrainte = ""
}) => {
    const { t } = useTranslation("global");

    const { data, isLoading, isError, isFetching, error } = useCntDabGesAbs(codeSociete, codeService, codeContrainte);

    if(isLoading || isFetching) { 
        return (
            <Box sx={styles.loaderStyle}>
                <Loader colorLoader={greenColor["1"]} colorContainer={greyColor[2]} />
            </Box>
        );
    }

    if(isError) {
        return <DialogError error={error.response.data.details} />;
    }

    return (
        <Stack spacing={0.5} bgcolor={redColor[5]} p={0.5} borderRadius={2}>
            <TextContainer bgColor={whiteColor[7]} p={1} textWeight="bold" horizontalAlign="center">{t("texts.constraint_non_respected")}</TextContainer>
            {data &&
                <>
                    <Stack bgcolor={whiteColor[7]} p={1} borderRadius={2}>
                        {data.libelleContrainte && <TextWithLabelContainer label={t("texts.constraint")} value={data.libelleContrainte} weight={{label: "bold", value: "normal"}} />}
                        {data.libelleService && <TextWithLabelContainer label={t("texts.service")} value={data.libelleService} weight={{label: "bold", value: "normal"}} />}
                        {data.definitionContrainte1 && <TextWithLabelContainer label={t("texts.definition")} value={`${data.definitionContrainte1} ${data.definitionContrainte2} ${data.definitionContrainte3} ${data.definitionContrainte4} ${data.definitionContrainte5}`} weight={{label: "bold", value: "normal"}} />}
                        {data.effectifMinimum && <TextWithLabelContainer label={t("texts.min_workforce")} value={data.effectifMinimum} weight={{label: "bold", value: "normal"}} />}
                    </Stack>
                    {data.collaborateurs.length > 0 &&
                        <>
                            <TextContainer bgColor={whiteColor[7]} p={1} textWeight="bold" horizontalAlign="center">{t("texts.collaborators")}</TextContainer>
                            {data.collaborateurs.map((collaborateur) => (
                                <TextContainer key={`COLCTS${collaborateur.id}`} bgColor={whiteColor[7]} p={1}>{collaborateur.prenomNom} : {collaborateur.type}</TextContainer>
                            ))}
                        </>
                    }
                </>
            }   
        </Stack>
    );
}

Contrainte.propTypes = {
    codeSociete: PropTypes.string,
    codeService: PropTypes.string,
    codeContrainte: PropTypes.string
}

const styles = {
    loaderStyle: {
        height: 100,
        alignItems: "center",
        display: "flex"
    }
};

export default Contrainte;