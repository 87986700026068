import PropTypes from "prop-types";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useController, useFormContext } from "react-hook-form";

const CheckHookFormInput = (props) => {
    const { name, label, size, noClick } = props;
    const { control } = useFormContext();
    const { field } = useController({ name: name, control });

    return (
        <FormControlLabel 
            control={
                <Checkbox 
                    sx={{"& .MuiSvgIcon-root": { fontSize: size } }} 
                    disabled={noClick} 
                    checked={field.value} 
                    onChange={(event) => field.onChange(event.target.checked)} 
                />
            } 
            componentsProps={{typography: { fontSize: "small" }}} 
            sx={{ mr: label ? undefined : 0, ml: label ? undefined : 0 }}
            label={label} 
        />
    );
}

CheckHookFormInput.defaultProps = {
    label: "",
    size: 16,
    noClick: false
}

CheckHookFormInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    size: PropTypes.number,
    noClick: PropTypes.bool
}

export default CheckHookFormInput;