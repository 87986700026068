import { useSelector } from "react-redux";
import { Box, Collapse, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { selectDsh } from "@selectors";
import { PointageShortcutConfigurationAdd } from "../Shortcuts/Pointage";
import { ActivitesShortcutConfigurationAdd } from "../Shortcuts/Activites";
import { whiteColor } from "@styles";
import { useTranslation } from "react-i18next";

const ShortcutsMenu = ({ 
    open = false 
}) => {
    const stateDashboard = useSelector(selectDsh);
    const { t } = useTranslation("global");
    
    const routeShortcuts = {
        sPointage: <PointageShortcutConfigurationAdd />,
        sActivite: <ActivitesShortcutConfigurationAdd />,
    };

    return (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Box bgcolor={whiteColor[7]} borderRadius={2} p={0.5}>
                <Grid container spacing={0.5}>
                    {stateDashboard.shortcutsNonActives.length > 0 ? 
                        stateDashboard.shortcutsNonActives.map((shortcut, id) => (
                            <Grid item key={`SHORTCUTNOACT${id}`}>{routeShortcuts[shortcut]}</Grid>
                        ))
                    : 
                        <Grid item xs={12}>
                            <Box padding={1}>
                                <Typography fontSize="small" fontWeight="bold">{t("texts.all_shortcuts_added")}</Typography>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Collapse>
    );
}

ShortcutsMenu.propTypes = {
    open: PropTypes.bool
}

export default ShortcutsMenu;