import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider, frFR } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import PropTypes from "prop-types";
import IconDatePickerButton from "./IconDatePickerButton";
import { greyColor, whiteColor } from "@styles";

const bgColor = {
    white5Green9White9: whiteColor[5],
    white2Grey9White9: whiteColor[3]
}

const color = {
    white5Green9White9: greyColor[5],
    white2Grey9White9: greyColor[9]
}

const DatePickerLabelizedButton = ({
    icon = <></>, 
    title = "", 
    placement = "bottom", 
    noClick = false, 
    minDate = null, 
    date = "", 
    variant = "white5Green9White9",
    action = () => {}
}) => {
    const [open, setOpen] = useState(false);

    return (  
        <Box bgcolor={noClick ? "none" : bgColor[variant]} borderRadius={20} display="flex" alignItems="center">
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"frFR"} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DatePicker
                    slots={{ field: IconDatePickerButton }}
                    slotProps={{ field: { icon, title, placement, noClick, variant, setOpen } }}
                    open={open}
                    minDate={minDate}
                    views={["year", "month", "day"]}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    onAccept={action}
                />
            </LocalizationProvider>
            <Box color={color[variant]} pr={2} pl={1} m={0} display="flex" alignItems="center">
                <Typography fontSize="small" fontWeight="bold">{date}</Typography>
            </Box>
        </Box>
    );
}

DatePickerLabelizedButton.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]), 
    title: PropTypes.string, 
    placement: PropTypes.string, 
    noClick: PropTypes.bool,
    minDate: PropTypes.object,
    date: PropTypes.string,
    variant: PropTypes.string,
    action: PropTypes.func 
}

export default DatePickerLabelizedButton;