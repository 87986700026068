import { Box, IconButton } from "@mui/material";
import PropTypes from "prop-types";

const ChiffreButton = (props) => {
    const { chiffre, noClick, action } = props;

    return (  
        <Box component="span">
            <IconButton size="small" disabled={noClick} onClick={action}>
                <Box width={22.4}>{ chiffre }</Box>
            </IconButton>
        </Box>
    );
}

ChiffreButton.defaultProps = {
    chiffre: "", 
    noClick: false, 
    action: () => {}
}

ChiffreButton.propTypes = {
    chiffre: PropTypes.string, 
    noClick: PropTypes.bool, 
    action: PropTypes.func
}

export default ChiffreButton;